import React, { ReactElement } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { Text12300 } from "../../Shared";

export interface BottomBannerProps {
  label: string;
  icon?: IconProp;
  rightElement?: ReactElement;
  disabled?: boolean;
  tagline?: string;
  onClick: () => void;
}

const ContentContainer = styled.div<{ hasTagline: boolean }>`
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0px 16px 8px rgba(28, 29, 33, 0.3);
  padding: 15px;
  background-color: white;
  opacity: 1;
  z-index: 3;
  flex-grow: 1;
`;

const Tagline = styled(Text12300)`
  margin-bottom: 4px;
`;
type ButtonProps = { disabled: boolean };
const Button = styled.button<ButtonProps>`
  &&& {
    /* border-radius: 48px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    /* width: calc(100% - 30px); */
    width: 100%;
    max-width: 400px;
    opacity: ${(props) => (props.disabled === true ? 0.6 : 1)};
    padding: 12px 20px;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 1px;
    height: 65px;
    pointer-events: ${(props) => (props.disabled === true ? "none" : null)};
    svg {
      margin-right: 10px;
    }
    margin: 0px;
    /* box-shadow: 0 0px 16px 8px rgba(28, 29, 33, 0.3); */
  }
`;

export const BottomBanner: React.FC<BottomBannerProps> = ({
  icon,
  label,
  rightElement,
  disabled,
  onClick,
  tagline,
}: BottomBannerProps): ReactElement => {
  return (
    <ContentContainer hasTagline={tagline && tagline.length > 0}>
      {tagline ? <Tagline>{tagline}</Tagline> : null}
      <Button
        style={{ backgroundColor: "black", color: "white" }}
        disabled={disabled}
        onClick={onClick}
      >
        {icon != null ? <FontAwesomeIcon icon={icon} /> : <div />}
        <span>{label}</span>
        {rightElement ?? <div />}
      </Button>
    </ContentContainer>
  );
};
