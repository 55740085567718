import React, { Component } from "react";
import { connect } from "react-redux";
import { LOGGING } from ".";
import { readUserStats } from "../../store/actions";
import { Loading } from "../shared";
import { UserStats } from "./components/UserStats";

class PageUserStats extends Component {
  constructor() {
    super();
    LOGGING && console.log("PageUserStats constructor");
    this.state = {
      loading: true,
      stats: null,
    };
  }

  componentDidMount() {
    const { userId } = this.props.match.params;
    LOGGING &&
      console.log("PageUserStats componentdidmount with userId:", userId);
    this.setState({ loading: true }, () => {
      this.props
        .readUserStats(userId)
        .then(
          ({
            orderStats,
            sortedOrderRestaurants,
            cartStats,
            sortedCartRestaurants,
          }) => {
            this.setState({
              orderStats,
              sortedOrderRestaurants,
              cartStats,
              sortedCartRestaurants,
              loading: false,
            });
          }
        );
    });
  }

  render() {
    const {
      loading,
      orderStats,
      sortedOrderRestaurants,
      cartStats,
      sortedCartRestaurants,
    } = this.state;
    LOGGING &&
      console.log("PageUserStats rendering with", {
        props: this.props,
        state: this.state,
      });

    return (
      <div className="page">
        {loading === true ? (
          <Loading />
        ) : (
          <div>
            <div>
              <h3>Orders</h3>
              <UserStats
                stats={orderStats}
                sortedRestaurants={sortedOrderRestaurants}
              />
            </div>
            <div>
              <h3>Carts</h3>
              <UserStats
                stats={cartStats}
                sortedRestaurants={sortedCartRestaurants}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log("PageUserStats got redux state:", state);
  return {
    // orders: state.orders,
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  readUserStats,
})(PageUserStats);
