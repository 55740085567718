import { LOGGING } from 'src/constants';
import { ObjectId } from 'src/constants/types';
import { apiCall } from 'src/store/api';

export type SerializedCategory = {
  _id: ObjectId;
  name: string;
  restaurant: ObjectId;
  priority?: number;
};
export type GetCategoriesByRestaurantIdResponse = { categories: SerializedCategory[] };
export const GetCategoriesByRestaurantId = async (
  restaurantId: ObjectId
): Promise<GetCategoriesByRestaurantIdResponse> => {
  try {
    return await apiCall('POST', `/categories/GetCategoriesByRestaurantId`, {
      restaurantId: restaurantId,
    });
  } catch (err) {
    LOGGING && console.log('GetCategories got err', err);
    return { categories: [] };
  }
};
