import { useCallback } from "react";
import { faHeart as faNominate } from "@fortawesome/free-solid-svg-icons";
import {
  faChevronSquareRight as faEnter,
  faCalendarAlt as faCalendar,
} from "@fortawesome/pro-light-svg-icons";
import {
  faCalendar as IconMenu,
  faCartPlus as IconBuy,
  faCar as IconDelivered,
  faPiggyBank as IconSaving,
  faCalendarCheck as faUpvote,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import "./HowItWorks.scss";
import { Link } from "react-router-dom";

const HowItWorks = ({ howItWorksRef, show, onToggleShow }) => {
  const history = useHistory();
  const goToFaq = useCallback(() => {
    history.push("faq");
  }, [history]);

  return (
    <div className="how-it-works" ref={howItWorksRef}>
      <div className="title">
        <span>how it works</span>
      </div>
      {/* <button className="title" onClick={onToggleShow}>
        <span>how it works</span>
        <FontAwesomeIcon icon={show ? faChevronUp : faChevronDown} />
      </button> */}
      {show ? (
        <>
          <div className="steps">
            <div className="step-body">
              <div className="step one">
                <div className="step-count">
                  <FontAwesomeIcon icon={IconMenu} />
                </div>
                <div className="step-content">Browse the weekly menu.</div>
              </div>
              <div className="step two">
                <div className="step-count">
                  <FontAwesomeIcon icon={IconBuy} />
                </div>
                <div className="step-content">
                  Order lunch by 10:30A, dinner by 4:30P.
                </div>
              </div>
              <div className="step three">
                <div className="step-count">
                  <FontAwesomeIcon icon={IconDelivered} />
                </div>
                <div className="step-content">
                  Get lunch 11:30A-12:30P, dinner 6-7P.
                </div>
              </div>
              <div className="step four">
                <div className="step-count">
                  <FontAwesomeIcon icon={IconSaving} />
                </div>
                <div className="step-content">
                  Pay 30% less than on DoorDash.
                </div>
              </div>
            </div>

            <Link className="more-details" to="/faq">
              FAQ <FontAwesomeIcon icon={faEnter} />
            </Link>
          </div>
        </>
      ) : null}
    </div>
  );
};
export const ScheduledMeals = () => (
  <div className="scheduled-meals">
    <FontAwesomeIcon icon={faCalendar} />
    <span>schedule orders</span>
  </div>
);

export const NominateYourFavorite = () => (
  <div className="scheduled-meals nominate">
    <span className="nominate-title">Foodie Nomination</span>
    <FontAwesomeIcon icon={faNominate} />
    <span className="nominate-tagline">
      Don't see your favorites?<br></br>Introduce them to us!
    </span>
  </div>
);

export const NominateYourFavoriteMeal = () => (
  <div className="scheduled-meals upvote">
    <span className="nominate-title">Upvote Restaurants</span>
    <FontAwesomeIcon icon={faUpvote} />
    <span className="nominate-tagline">
      Don't find the restaurant you like?<br></br>Upvote! We will list highly
      voted ones for upcoming meals.
    </span>
  </div>
);

export default HowItWorks;
