import { useCallback, useMemo, useState, useEffect } from 'react';
import { faFilter as faFiltered } from '@fortawesome/free-solid-svg-icons';
import {
  faRobot,
  faFilter,
  faCalculator,
  faSms,
  faTimesCircle,
} from '@fortawesome/pro-light-svg-icons';
import { faCar, faChevronUp, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { Button } from 'src/components/Button';
import { DriverSwapModal } from 'src/components/DriverSwapModal';
import { Flex } from 'src/components/Layout';
import { sumArr } from 'src/util/array';
import { getCompleteWindowsForTheDay } from '../../../util/time';

export const AdminAcceptedDriverControls = ({
  drivers,
  acceptedDriverIds,
  stopDetailsByDriverId,
  displayDriverOnly,
  driversWithRoute,
  restaurant,
  orders,
  isLunch,
  onNotifyPickMeal,
  onDriverSwap,
  onFilterDriver,
  onFindDriverRoutes,
  onGetDriverTime,
  onCancelDriver,
  onFinalizeDriverRoute,
}) => {
  const [showAcceptedDrivers, setShowAcceptedDrivers] = useState(true);
  const [isDriverSwapModalOpen, setIsDriverSwapModalOpen] = useState(false);
  const [driverPickTimes, setDriverPickTimes] = useState({});

  const toggleShowAcceptedDrivers = useCallback(() => {
    setShowAcceptedDrivers(!showAcceptedDrivers);
  }, [showAcceptedDrivers]);
  const openDriverSwapModel = useCallback(() => {
    setIsDriverSwapModalOpen(true);
  }, []);
  const closeDriverSwapModel = useCallback(() => {
    setIsDriverSwapModalOpen(false);
  }, []);
  const notifyPickupTime = useCallback(() => {
    onNotifyPickMeal(restaurant._id, driverPickTimes);
  }, [onNotifyPickMeal, restaurant._id, driverPickTimes]);
  const onDriverMultiPickTimeChange = useCallback(
    (e) => {
      const pickTime = e.target.value;
      const copiedDriverPickTimes = cloneDeep(driverPickTimes);
      for (const driverId in copiedDriverPickTimes) {
        copiedDriverPickTimes[driverId] = pickTime;
      }
      setDriverPickTimes(copiedDriverPickTimes);
    },
    [driverPickTimes]
  );
  const swapDriverOptions = useMemo(() => {
    const result = {};
    Object.entries(drivers).forEach(([driverId, driver]) => {
      if (acceptedDriverIds?.includes(driverId) && !driversWithRoute.has(driverId)) {
        result[driverId] = driver.name;
      }
    });
    return result;
  }, [drivers, driversWithRoute, acceptedDriverIds]);

  useEffect(() => {
    const driverPickTimes = {};
    if (acceptedDriverIds && acceptedDriverIds.length > 0) {
      acceptedDriverIds.forEach((driverId) => {
        if (orders.find((order) => order.driver?._id === driverId)) {
          driverPickTimes[driverId] = isLunch ? '11:15' : '17:15';
        }
      });
    }
    setDriverPickTimes(driverPickTimes);
  }, [isLunch, orders, acceptedDriverIds]);

  if (acceptedDriverIds == null || acceptedDriverIds.length === 0) {
    return null;
  }

  const etaSumOfAllDrivers = sumArr(
    acceptedDriverIds.map((driverId) => stopDetailsByDriverId[driverId].eta)
  );

  return (
    <>
      <div className="drivers-on-duty">
        <Flex gap={12} align="center">
          <Button
            style={{ marginTop: 6, marginBottom: 12, marginLeft: 16 }}
            onClick={notifyPickupTime}
          >
            Send Pickup Time
          </Button>
          <input placeholder="enter pick time" onChange={onDriverMultiPickTimeChange} />
          <Button onClick={openDriverSwapModel}>Swap</Button>
          <Button onClick={toggleShowAcceptedDrivers}>
            <span>{`${showAcceptedDrivers ? 'hide' : 'show'} drivers`}</span>
            <FontAwesomeIcon icon={showAcceptedDrivers ? faChevronUp : faChevronDown} />
          </Button>
        </Flex>
        <div>
          <span>
            <b>Total ETA:</b> {etaSumOfAllDrivers}
          </span>
          <span style={{ marginLeft: '8px' }}>
            <b>Total Cost:</b> ${((etaSumOfAllDrivers / 60) * 40).toFixed(2)}
          </span>
        </div>
        {showAcceptedDrivers
          ? acceptedDriverIds
              .filter((driverId) => driverId in drivers)
              .map((driverId, index) => {
                const eta = stopDetailsByDriverId[driverId].eta;
                const numStops = stopDetailsByDriverId[driverId].numStops;
                return (
                  <div className="driver-actions" key={index}>
                    <div className="info-section">
                      <div
                        className="driver-name"
                        style={
                          numStops > 0
                            ? {
                                backgroundColor: drivers[driverId].color,
                                color: 'white',
                                border: 'none',
                              }
                            : {
                                backgroundColor: 'white',
                                color: drivers[driverId].color,
                                border: `1px solid ${drivers[driverId].color}`,
                              }
                        }
                      >
                        <FontAwesomeIcon icon={faCar} />
                        <span>{drivers[driverId].name}</span>
                      </div>

                      <span className="number-of-stops">
                        {`${numStops} Stop${numStops > 1 ? 's' : ''}`}
                      </span>
                      <span className="eta">{`${eta || 'N/A'} min`}</span>
                    </div>
                    <div className="action-section">
                      <button
                        className={`driver-toggle ${
                          displayDriverOnly === driverId ? 'display-driver-only' : ''
                        }`}
                        onClick={(e) => {
                          onFilterDriver(driverId, e);
                        }}
                        key={index}
                        style={
                          displayDriverOnly === driverId
                            ? {
                                color: drivers[driverId].color,
                              }
                            : {}
                        }
                      >
                        <FontAwesomeIcon
                          icon={displayDriverOnly === driverId ? faFiltered : faFilter}
                        />
                        <span>filter</span>
                      </button>
                      {orders.length > 0 && (
                        <button
                          className="auto-route"
                          onClick={(e) => {
                            onFindDriverRoutes(orders[0].window.start, driverId, e);
                          }}
                        >
                          <FontAwesomeIcon icon={faRobot} />
                          <span>route</span>
                        </button>
                      )}
                      <button
                        className="calculate-driver-time"
                        onClick={(e) => {
                          onGetDriverTime(
                            driverId,
                            restaurant.address || restaurant.name,
                            isLunch
                              ? getCompleteWindowsForTheDay(
                                  moment().add(
                                    moment().tz('America/Los_Angeles').hour() > 12 ? 1 : 0,
                                    'd'
                                  )
                                ).lunch.deliveryStart
                              : getCompleteWindowsForTheDay().dinner.deliveryStart,
                            e
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faCalculator} />
                        <span>estimate</span>
                      </button>

                      <button
                        className="calculate-driver-time"
                        onClick={(e) => {
                          onCancelDriver(
                            driverId,
                            orders.filter((o) => o.driver?._id === driverId).map((o) => o._id),
                            e
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                        <span>cancel</span>
                      </button>
                      {driversWithRoute.has(driverId) ? (
                        <button className="finalize-driver-route" disabled={true}>
                          <FontAwesomeIcon icon={faSms} />
                          <span>notify</span>
                        </button>
                      ) : (
                        <button
                          className="finalize-driver-route"
                          onClick={(e) => {
                            onFinalizeDriverRoute(driverId, e);
                          }}
                        >
                          <FontAwesomeIcon icon={faSms} />
                          <span>notify</span>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })
          : null}
      </div>
      <DriverSwapModal
        width={500}
        driverOptions={swapDriverOptions}
        isOpen={isDriverSwapModalOpen}
        showClose
        onSwap={(driver1, driver2) => {
          onDriverSwap(
            driver1,
            driver2,
            orders.map((order) => order._id)
          );
        }}
        onClose={closeDriverSwapModel}
      />
    </>
  );
};
