import React, { ReactElement } from 'react';
import { faThumbsUp as faTrendy } from '@fortawesome/pro-light-svg-icons';
import { faMinus, faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DishType } from 'src/api/dish';
import { useDishCountInCart } from 'src/hooks/useDishCountInCart';
import { Dish } from 'src/models';
import { getDishCountInCart } from 'src/util/cart';
import styled from 'styled-components';
import { LOGGING } from '../..';
import {
  TextUPPER,
  Text12300,
  text12300,
  textTitle,
  Centered,
  Card,
  card,
  textOverflow,
  Beige,
  Yellow,
  Pink,
  ActionTransparent,
  ActionBlack,
  ActionGrey,
  CenteredByColumn,
} from '../../Shared';
import { CenteredByColumnLeft } from '../../Shared';

interface DishProps {
  dish: Dish;
  isMobile: boolean;
}

const IMAGE_SIZE = 120;
const IMAGE_WIDTH = 160;
const TEXT_CONTAINER_MIN_WIDTH = 120;

const MOBILE_DISH_CONTAINER_MARGIN = 6;

const DESKTOP_DISH_CONTAINER_MARGIN = 8;
const DESKTOP_DISH_CONTAINER_MIN_WIDTH =
  TEXT_CONTAINER_MIN_WIDTH + IMAGE_SIZE + DESKTOP_DISH_CONTAINER_MARGIN * 2;
const DESKTOP_DISH_CONTAINER_MAX_WIDTH =
  TEXT_CONTAINER_MIN_WIDTH * 2 + IMAGE_SIZE + DESKTOP_DISH_CONTAINER_MARGIN * 2;

const WHITE_HOVER_COLOR = '#dbdbdb';

type ContainerProps = { isMobile: boolean };
const Container = styled.div<ContainerProps>`
  // Positioning
  position: relative;
  display: flex;
  flex-direction: row;
  margin: ${(props) =>
    props.isMobile ? MOBILE_DISH_CONTAINER_MARGIN : DESKTOP_DISH_CONTAINER_MARGIN}px;
  padding: 0px;

  // Width
  width: ${(props) =>
    props.isMobile ? '100%' : `calc(50% - ${DESKTOP_DISH_CONTAINER_MARGIN * 2}px)`};
  max-width: 400px;
  height: ${IMAGE_SIZE}px;
  // Border
  /* border: 1px solid #e7e7e7; */
  /* border-radius: 8px; */
  /* overflow: hidden; */

  // For text
  color: black;
  word-spacing: -1px;
  letter-spacing: -0.5px;
  ${card}
  background-color: ${Beige};
  box-shadow: 0 0px 6px 4px rgba(0, 0, 0, 0.2);
`;

const ButtonContainer = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  width: 100%;
  align-content: stretch;
`;

const ItemDescriptionContainer = styled(CenteredByColumnLeft)`
  // Positioning

  /* justify-content: flex-start; */
  /* margin-left: 16px; */
  /* margin-bottom: 16px; */

  // Size
  /* min-width: ${TEXT_CONTAINER_MIN_WIDTH}px; */
  width: calc(100% - ${IMAGE_WIDTH}px);
  height: 100%;
  text-align: left;
  position: absolute;
  align-self: stretch;
  flex-grow: 1;
  top: 0px;
  right: 0px;
  /* padding: 10px; */
`;

const Title = styled(TextUPPER)`
  font-size: 11px;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 10px 12px;
  ${textOverflow}
  position: absolute;
  top: 0px;
  left: 0px;
  @supports (-webkit-line-clamp: 2) {
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
const Price = styled.div`
  font-size: 13px;
  font-weight: 500;
  /* line-height: 20px; */
  letter-spacing: 1px;
  color: black;
  position: absolute;
  bottom: 16px;
  left: 0px;
  margin-left: 12px;
`;
const Description = styled(Text12300)`
  font-size: 10px;
  margin: 10px 12px;
  ${textOverflow}
  line-height: 1;
  max-height: 12px;
  text-transform: none;
  /* color: #767676; */
  /* padding-left: 10px; */
  @supports (-webkit-line-clamp: 1) {
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

const Sold = styled(Text12300)`
  /* color: grey; */
  background-color: gold;
  font-weight: 400;
  /* width: 50px;
  height: 50px;
  border-radius: 50%; */
  position: absolute;
  top: 8px;
  left: 8px;
  /* box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.2); */
  svg {
    margin-right: 5px;
  }
  padding: 0px 8px;
  border-radius: 5px;
`;

const CartButtonContainer = styled.div`
  // Positioning
  position: absolute;
  bottom: 8px;
  right: 0px;
  z-index: 1;
  margin-right: 8px;

  font-weight: 700;
  font-size: 12px;
`;

const ModifyItemCountContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

type FloatingButtonProps = {
  height: number;
  backgroundColor: string;
  hoverBgColor: string;
  color: string;
};
const FloatingButton = styled.button<FloatingButtonProps>`
  /* box-shadow: transparent 0px 0px 0px 1px inset, rgb(0 0 0 / 20%) 0px 2px 8px; */
  border-radius: 16px;
  background-color: ${(props) => props.backgroundColor};
  height: ${(props) => props.height}px;
  width: ${(props) => props.height}px;
  color: ${(props) => props.color};
  border: 1px solid rgba(217, 217, 217, 1);
  transition: background-color 0.2s ease;
  &:hover {
    background-color: ${(props) => props.hoverBgColor};
  }
`;

const AddToCartButton = styled(ActionBlack)`
  /* border-radius: 16px; */
  padding: 0px 12px;
  transition: background-color 0.2s ease;
  margin: 5px;
  height: 25px;
  font-size: 10px;
  letter-spacing: 0.6px;
  /* border-bottom: 1px solid black;
  color: black;
  background-color: transparent; */
  &:hover {
    background-color: #dbdbdb;
  }
`;

const DishCountInCartButton = styled(FloatingButton)`
  pointer-events: none;
  margin: 0 4px;
`;

type ImageProps = { backgroundImageUrl: string };
const Image = styled.div<ImageProps>`
  background-image: url(${(props) => props.backgroundImageUrl});
  background-size: cover;
  min-height: ${IMAGE_SIZE}px;
  min-width: ${IMAGE_WIDTH}px;
  background-position: center;
  position: absolute;
  top: 0px;
  left: 0px;
`;

export const DishV: React.FC<DishProps> = ({ dish, isMobile }: DishProps): ReactElement => {
  return (
    <Container isMobile={isMobile}>
      <ButtonContainer>
        <Image backgroundImageUrl={dish.imageURL} />
        {dish?.mustTry ? (
          <Sold>
            <FontAwesomeIcon icon={faTrendy} />
            {/* {`sold ${dish?.orderedRange}`} */}
            must try
          </Sold>
        ) : null}
        <ItemDescriptionContainer>
          <Title>{dish.name}</Title>
          <Description>{dish.description}</Description>
          <Price>
            {dish.priceFoodieListed != null && dish.priceFoodieListed > 0
              ? `$${dish.priceFoodieListed.toFixed(2)}`
              : 'Price Variable'}
          </Price>
        </ItemDescriptionContainer>
      </ButtonContainer>
    </Container>
  );
};
