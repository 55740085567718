import { SET_NOMINATION, RESET_NOMINATION } from '../actionTypes';

const DEFAULT_STATE = {
  restaurant: { name: '' },
  dish: '',
  submitted: true,
};

const nomination = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_NOMINATION:
      return {
        ...action.nomination,
        submitted: false,
      };
    case RESET_NOMINATION:
      return { ...DEFAULT_STATE };
    default:
      return state;
  }
};

export default nomination;
