import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useStore } from "react-redux";
import { DriverTaskDetails } from "src/screens/tasting_menu/components/DriverTaskDetails";
import { completeTaskForDriver } from "src/store/actions/tasks";
import styled from "styled-components";
import { DriverOrderTask } from "./DriverOrderTask";

const Warning = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: red;
  width: 100%;

  font-weight: 700;

  margin-top: 12px;
  padding: 30px;
`;

export const DriverOrderStopTask = ({
  task,
  totalNumTasks,
  showTaskDetails,
  canCompleteTask,
}) => {
  const [orderIdsDelivered, setOrderIdsDelivered] = useState({});
  const [isCompleteTaskLoading, setIsCompleteTaskLoading] = useState(false);

  const remainingOrdersToDeliver = task.ordersToDeliver.filter(
    (order) => orderIdsDelivered[order._id] == null
  );

  const deliverOrder = useCallback(
    (orderId) => {
      setOrderIdsDelivered({ ...orderIdsDelivered, [orderId]: true });
    },
    [orderIdsDelivered]
  );
  const orderTaskConfirmMessageGenerator = useCallback((orderName) => {
    return `You delivered ${orderName}'s order?`;
  }, []);

  const dispatch = useDispatch();
  const store = useStore();
  const completeTask = useCallback(async () => {
    setIsCompleteTaskLoading(true);

    try {
      await completeTaskForDriver(task.taskId)(dispatch, store.getState);
    } catch (error) {
      setOrderIdsDelivered({});
      if (error == null) {
        toast.error(
          "An error occurred, check your network connection and try again"
        );
      } else {
        toast.error(error.message);
      }
    }

    setIsCompleteTaskLoading(false);
  }, [dispatch, store.getState, task.taskId]);

  return (
    <>
      {(task.ordersToDeliver.length ?? 0) > 1 ? (
        <Warning>
          NOTE: {task.ordersToDeliver.length} orders at this stop!
        </Warning>
      ) : null}
      {showTaskDetails && <h3 style={{ marginTop: "12px" }}>Task Details</h3>}
      {showTaskDetails && (
        <DriverTaskDetails
          taskNum={task.taskNum}
          address={task.address}
          addressMap={task.addressMap}
          ringDoorbell={task.shouldRing}
          totalNumTasks={totalNumTasks}
        />
      )}
      <h3 style={{ marginBottom: "10px", color: "blue" }}>Orders to Deliver</h3>
      {remainingOrdersToDeliver.map((order) => (
        <DriverOrderTask
          key={order._id}
          orderId={order._id}
          name={order.name}
          orderHash={order.orderHash}
          restaurantName={order.meal?.restaurant?.name}
          phone={order.phone}
          instructions={order.note}
          orderItems={order.goods}
          onComplete={deliverOrder}
          onCompleteButtonLabel="Deliver"
          confirmMessageGenerator={orderTaskConfirmMessageGenerator}
        />
      ))}
      {remainingOrdersToDeliver.length === 0 ? (
        !canCompleteTask ? null : isCompleteTaskLoading ? (
          <div>Loading...</div>
        ) : (
          <button className="deliver-button" onClick={completeTask}>
            <span>Complete Task</span>
          </button>
        )
      ) : null}
    </>
  );
};
