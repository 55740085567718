import { SET_DELIVERY_ZONES } from '../actionTypes';

const INITIALIZE_STATE = {};

const deliveryZones = (state = { INITIALIZE_STATE }, action) => {
  switch (action.type) {
    case SET_DELIVERY_ZONES:
      return [...action.deliveryZones];
    default:
      return state;
  }
};

export default deliveryZones;
