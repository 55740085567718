import moment from "moment-timezone";
import { Link } from "react-router-dom";
import "./OrderThumbnail.scss";
import styled from "styled-components";
import { MealDate, CATimeZone, receiptTimeFormat, LOGGING } from ".";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown, faUsers } from "@fortawesome/pro-regular-svg-icons";
import { Centered, Peach, text12300 } from "./Shared";
const OrderHeader = ({ order, mealType }) => (
  <div className="meal-type">
    <MealDate time={moment(order.window.start).tz(CATimeZone)} />
    <span className="order-type">{`${mealType}`}</span>
  </div>
);
export const OrderHighlight = ({ order }) => {
  LOGGING && console.log("OrderHighlight rendering with: ", order);
  return (
    <div className="receipt-section order-highlight">
      {order.groupMainOrder && (
        <div className="receipt-row type">
          <label>Type</label>
          <span className="highlight">Group Order</span>
        </div>
      )}
      <div className="receipt-row restaurant">
        <label>restaurant</label>
        <span className="highlight">
          {order?.goods[0]?.dish?.restaurant?.name.split(",")[0]}
        </span>
      </div>
      <div className="receipt-row dishes">
        <label>dishes</label>
        {order?.goods.length > 0 ? (
          <span className="highlight">{`${order?.goods[0]?.dish?.name}${
            order?.goods?.length > 1 ? ", ..." : ""
          }`}</span>
        ) : null}
      </div>
      <div className="receipt-row total">
        <label>total</label>
        <span className="highlight">
          ${(order.total || order.sum || 0).toFixed(2)}
        </span>
      </div>
      <div className="receipt-row pay-time">
        <label>due</label>
        <span className="highlight">${(order.due || 0).toFixed(2)}</span>
      </div>
      {order.payTime > 0 ? (
        <div className="receipt-row pay-time">
          <label>paid</label>
          <span className="highlight">
            {moment(order.payTime).tz(CATimeZone).format(receiptTimeFormat)}
          </span>
        </div>
      ) : null}
      {(order?.serviceFee === order?.serviceFeeMember &&
        order?.isMember !== false &&
        order?.memberSaving > 0) ||
      order?.meal?.restaurant?.memberOnly ? (
        <div className="receipt-row saving">
          <label>member saving</label>
          {order?.meal?.restaurant?.memberOnly ? (
            <span className="highlight member-only">
              <FontAwesomeIcon icon={faCrown} />
              member only
            </span>
          ) : (
            <span className="highlight saving">
              ${order?.memberSaving?.toFixed(2)}
            </span>
          )}
        </div>
      ) : null}
    </div>
  );
};
export const TeamOrderHighlight = ({ order }) => (
  <div className="receipt-section order-highlight">
    {order.goods.map((g) => (
      <>
        <div className="receipt-row">
          <label>dish</label>
          <span className="highlight">{g.dish.name}</span>
        </div>
        <div className="receipt-row">
          <label>quantity</label>
          <span className="highlight">{`x${g.quantity}`}</span>
        </div>
      </>
    ))}
    <div className="receipt-row total">
      <label>total</label>
      <span className="highlight">
        ${(order.total || order.sum).toFixed(2)}
      </span>
    </div>
    <div className="receipt-row total">
      <label>company paid</label>
      <span className="highlight">${order.companyPay?.toFixed(2)}</span>
    </div>
    <div className="receipt-row pay-time">
      <label>employee paid</label>
      <span className="highlight">${(order.due || 0).toFixed(2)}</span>
    </div>
  </div>
);
const OrderThumbnail = ({ order, gotoOrderRef }) => {
  const hour = moment(order.window.start).tz(CATimeZone).hour();
  const mealType =
    hour < 13 ? "lunch" : hour < 18 ? "express-dinner" : "dinner";
  return (
    <div
      className={`order-thumbnail ${mealType} ${
        gotoOrderRef ? "highlight" : "plain"
      }`}
      ref={gotoOrderRef}
    >
      <div className="receipt">
        <OrderHeader order={order} mealType={mealType} />
        <div className="receipt-body">
          <OrderHighlight order={order} />
        </div>
        <div className="order-action">
          <Link to={`/order/${order._id}`} className="view-order">
            <span>view details</span>
          </Link>
        </div>
      </div>
    </div>
  );
};
const GroupOrderLabel = styled(Centered)`
  width: 100%;
  justify-content: flex-start;
  padding: 0px 20px;
  span {
    background-color: ${Peach};
    ${text12300}
    font-weight: 400;
    text-transform: capitalize;
    padding: 0px 10px;
    border-radius: 3px;
    svg {
      margin-right: 5px;
    }
  }
`;
const GroupOrder = ({ organizer }) => (
  <GroupOrderLabel>
    <span>
      <FontAwesomeIcon icon={faUsers} /> group order by {organizer}
    </span>
  </GroupOrderLabel>
);

export const UnpaidCartThumbnail = ({ cart, gotoOrderRef }) => {
  const hour = moment(cart.window.start).tz(CATimeZone).hour();
  const isGroupOrder = cart?.groupOrderType > -1;
  const mealType =
    hour < 13 ? "lunch" : hour < 18 ? "express-dinner" : "dinner";
  return (
    <div
      className={`order-thumbnail ${isGroupOrder ? "group " : ""}${mealType} ${
        gotoOrderRef ? "highlight" : "plain"
      }`}
      ref={gotoOrderRef}
    >
      <div className="receipt">
        <OrderHeader order={cart} mealType={mealType} />
        {isGroupOrder ? <GroupOrder organizer={cart.user?.firstName} /> : null}

        <div className="receipt-body">
          <OrderHighlight order={cart} />
        </div>
        <div className="order-action">
          <Link
            to={`${cart?.meal?.pathUrl}${
              isGroupOrder ? `/groupOrder/${cart._id}` : ""
            }`}
            className="view-order"
          >
            <span>continue</span>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default OrderThumbnail;
