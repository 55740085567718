import React, { Component } from 'react';
import creditCardType from 'credit-card-type';
import { GoogleApiWrapper } from 'google-maps-react';
import { connect } from 'react-redux';
import validator from 'validator';
import { LOGGING, Loading, GroupSetting, NewCard, IsAddressSupportedForDelivery } from '.';
import {
  readUserGroup,
  deleteUserGroupCard,
  saveGroupSetting,
  inviteNewGroupUsers,
} from '../../store/actions';

class PageUserGroupAdmin extends Component {
  constructor(props) {
    super();
    const { currentUser } = props;
    if (typeof currentUser.user._id == 'undefined') {
      props.history.push({
        pathname: '/signin',
        state: { next: '/userGroupAdmin' },
      });
    }
    if (!currentUser.user.isUserGroupAdmin) {
      props.history.push({
        pathname: '/',
        state: { next: '/' },
      });
    }
    this.state = {
      loading: true,
      groupName: '',
      deliveryInfo: {
        phone: '',
        name: '',
        address: '',
        address_room_info: '',
        address_supported_for_delivery: false,
        address_validated: false,
        email: '',
        note: '',
      },
      budget: {
        lunch: '',
        dinner: '',
      },
      existingCard: null,
      newCard: NewCard,
      addNewCard: false,
      showCardAction: false,
      cardType: null,
      error: {},
      groupUsers: [],
      newGroupUserEmails: '',
      inviteNewGroupUsersError: '',
    };

    this.handleHome = this.handleHome.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleEditAddress = this.handleEditAddress.bind(this);
    this.handleSelectAddress = this.handleSelectAddress.bind(this);
    this.handleToggleShowCardAction = this.handleToggleShowCardAction.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeNumber = this.handleChangeNumber.bind(this);
    this.handleDeleteCard = this.handleDeleteCard.bind(this);
    this.handleAddNewCard = this.handleAddNewCard.bind(this);
    this.handleCancelNewCard = this.handleCancelNewCard.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeNewGroupUserEmails = this.handleChangeNewGroupUserEmails.bind(this);
    this.handleInviteNewGroupUsers = this.handleInviteNewGroupUsers.bind(this);
  }
  handleAddNewCard() {
    this.setState({ addNewCard: true });
  }
  handleCancelNewCard() {
    this.setState({ addNewCard: false });
  }

  handleEditAddress(address) {
    const { deliveryInfo } = this.state;
    LOGGING && console.log('PageUserGroupAdmin handleEditAddress called with ', address);
    const { error } = this.state;
    const updatedError = error && 'address' === error.param ? {} : error;
    this.setState({
      deliveryInfo: {
        ...deliveryInfo,
        address,
        address_supported_for_delivery: false,
        address_validated: false,
      },
      error: updatedError,
    });
  }

  handleSelectAddress(address) {
    LOGGING && console.log('PageUserGroupAdmin called handleSelectAddress with address:', address);
    if (address) {
      const city = address.gmaps.address_components.find((c) =>
        c.types.includes('locality')
      ).short_name;
      const number = address.gmaps.address_components.find((c) =>
        c.types.includes('street_number')
      );
      const number_str = typeof number === 'undefined' ? '' : number.short_name;
      const street = address.gmaps.address_components.find(
        (c) => c.types.includes('route') || c.types.includes('premise')
      ).short_name;
      const state = address.gmaps.address_components.find((c) =>
        c.types.includes('administrative_area_level_1')
      ).short_name;
      const zip = address.gmaps.address_components.find((c) =>
        c.types.includes('postal_code')
      ).short_name;
      const resultAddress = `${number_str} ${street}, ${city}, ${state} ${zip}`;
      // const placeId = address.gmaps.place_id;
      LOGGING && console.log('selected: ', resultAddress);
      const { deliveryInfo } = this.state;

      this.setState({
        deliveryInfo: {
          ...deliveryInfo,
          address: resultAddress,
          address_supported_for_delivery: IsAddressSupportedForDelivery(
            address.gmaps.address_components
          ),
          address_validated: true,
        },
      });
    }
  }

  handleClose() {
    this.props.history.goBack();
  }

  handleHome() {
    this.props.history.push('/');
  }

  handleToggleShowCardAction(e) {
    e.preventDefault();
    this.setState({ showCardAction: !this.state.showCardAction });
  }

  handleChange(sectionName, e) {
    e.preventDefault();
    const { error } = this.state;
    const updatedField = { [e.target.name]: e.target.value };
    const oldFields = this.state[sectionName];
    LOGGING &&
      console.log('handleChange with:', {
        sectionName,
        oldFields,
        updatedField,
      });
    const updatedError = error && e.target.name === error.param ? {} : error;

    this.setState({
      [sectionName]: {
        ...oldFields,
        ...updatedField,
      },
      error: updatedError,
    });
  }

  handleChangeNewGroupUserEmails(e) {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
      inviteNewGroupUsersError: '',
    });
  }
  handleInviteNewGroupUsers(e) {
    e.preventDefault();
    console.log('handleInviteNewGroupUsers', this.state);
    this.setState({ loading: true, inviteNewGroupUsersError: '' });
    this.props
      .inviteNewGroupUsers(this.state.newGroupUserEmails)
      .then(() => {
        this.props.readUserGroup().then((result) => {
          const { group, users, invites } = result;
          this.setState({
            deliveryInfo: group.deliveryInfo ? group.deliveryInfo : this.state.deliveryInfo,
            groupName: group.name,
            existingCard: group.stripeInfo ? group.stripeInfo.card : null,
            loading: false,
            newGroupUserEmails: '',
            groupUsers: users,
            groupInvites: invites,
          });
        });
      })
      .catch((error) => {
        LOGGING && console.log('inviteNewGroupUsers returned with error:', error);
        this.setState({
          inviteNewGroupUsersError: error.message,
          loading: false,
        });
      });
  }

  handleChangeNumber = (sectionName, field, values) => {
    const { error } = this.state;
    const updatedField = { [field]: field === 'phone' ? values : values.value };
    const oldFields = this.state[sectionName];
    LOGGING &&
      console.log('handleChangeNumber with:', {
        sectionName,
        error,
        field,
        values,
        updatedField,
        oldFields,
      });
    const updatedError = error && field === error.param ? {} : error;
    LOGGING && console.log('handleChangeNumber updatedError:', updatedError);
    if (field === 'number') {
      const { lengths, gaps, code, type, niceType } = creditCardType(values.value)[0];

      let cardFormat = lengths.length === 1 ? Array(lengths[0]).fill('#') : [];
      gaps.reverse().forEach((position) => cardFormat.splice(position, 0, '-'));
      LOGGING &&
        console.log('handleChangeNumber creditCardType:', {
          lengths,
          gaps,
          code,
          type,
          niceType,
        });
      this.setState({
        [sectionName]: { ...oldFields, ...updatedField },
        error: updatedError,
        cardType: {
          format: null, //cardFormat.join(""),
          length: lengths.length === 1 ? lengths[0] : null,
          code: { ...code, format: Array(code.size).fill('#').join('') },
          type: values.value.length === 0 ? null : type,
          niceType: values.value.length === 0 ? null : niceType,
        },
      });
    } else {
      this.setState({
        [sectionName]: { ...oldFields, ...updatedField },
        error: updatedError,
      });
    }
  };

  handleDeleteCard(e) {
    e.preventDefault();
    this.setState({ loading: true, showCardAction: false });
    this.props.deleteUserGroupCard().then(() => {
      this.setState({
        loading: false,
        existingCard: null,
      });
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { addNewCard, newCard, deliveryInfo, budget, cardType, existingCard } = this.state;
    LOGGING &&
      console.log('handleSubmit called with: ', {
        addNewCard,
        newCard,
        deliveryInfo,
        budget,
        cardType,
        existingCard,
      });

    const {
      phone,
      address,
      address_room_info,
      name,
      email,
      address_supported_for_delivery,
      address_validated,
      note,
    } = deliveryInfo;
    if (typeof name === 'undefined' || name.length === 0) {
      this.setState({
        error: { param: 'name', message: "name can't be empty" },
      });
      return;
    }

    if (typeof email === 'undefined' || email.length === 0) {
      this.setState({
        error: { param: 'email', message: "email can't be empty" },
      });
      return;
    }

    if (!validator.isEmail(email)) {
      this.setState({
        error: { param: 'email', message: 'Please provide a valid email' },
      });
      return;
    }

    if (typeof phone === 'undefined') {
      this.setState({
        error: { param: 'phone', message: "phone can't be empty" },
      });
      return;
    }
    if (!validator.isMobilePhone(phone, ['en-US'])) {
      this.setState({
        error: { param: 'phone', message: 'Please provide a valid phone' },
      });
      return;
    }

    if (typeof address !== 'undefined' && address.length > 0) {
      if (!address_validated) {
        this.setState({
          error: {
            param: 'address',
            message: 'Please select an address from the validated address list.',
          },
        });
        return;
      }
    }

    if (addNewCard) {
      const { number, cvc, exp_month, exp_year } = newCard;
      if (cardType && number.length < cardType.length) {
        this.setState({
          error: {
            param: 'number',
            message: `${cardType.niceType} card has to be ${cardType.length}-digit`,
          },
        });
        return;
      }

      if (cardType && cvc.length < cardType.code.size) {
        this.setState({
          error: {
            param: 'cvc',
            message: `${cardType.code.name} is ${cardType.code.size}-digit`,
          },
        });
        return;
      }
      if (exp_month.length < 2 || Number(exp_month) > 12 || Number(exp_month) < 1) {
        this.setState({
          error: { param: 'exp_month', message: 'exp_month is invalid' },
        });
        return;
      }
      if (exp_year.length < 2 || 2000 + Number(exp_year) < 2020) {
        this.setState({
          error: { param: 'exp_year', message: 'exp_year is invalid' },
        });
        return;
      }
    }
    this.setState({ loading: true });
    LOGGING &&
      console.log('saveSetting about to call: ', {
        existingCard,
        address,
        address_room_info,
        address_supported_for_delivery,
        address_validated,
        name,
        phone,
        email,
        note,
        budget,
      });
    this.props
      .saveGroupSetting(
        addNewCard ? newCard : null,
        {
          address,
          address_room_info,
          address_supported_for_delivery,
          address_validated,
          phone,
          name,
          email,
          note,
        },
        budget
      )
      .then(() => {
        this.setState({ loading: false, newGroupUserEmails: '' }, () => {
          this.props.history.push('/');
        });
      })
      .catch((error) => {
        LOGGING && console.log('saveSetting returned with error:', error);
        this.setState({ error, loading: false });
      });
  }

  componentDidMount() {
    LOGGING && console.log('PageUserGroupAdmin componentdidmount called');
    this.props.readUserGroup().then((result) => {
      LOGGING &&
        console.log('PageUserGroupAdmin readUserGroup returned from backend with result:', result);
      const { group: userGroup, users, invites } = result;
      if (userGroup) {
        LOGGING && console.log("found userGroup and i'm the admin");
        this.setState(
          {
            deliveryInfo: userGroup.deliveryInfo ? userGroup.deliveryInfo : this.state.deliveryInfo,
            budget: userGroup.budget ?? this.state.budget,
            serviceFee: userGroup.serviceFee ?? '',
            groupName: userGroup.name,
            existingCard: userGroup.stripeInfo ? userGroup.stripeInfo.card : null,
            loading: false,
            groupUsers: users,
            groupInvites: invites,
          },
          () => {
            window.scrollTo(0, 0);
          }
        );
      } else {
        const { currentUser } = this.props;
        const { user } = currentUser;
        const { deliveryInfo, stripeInfo, firstName } = user;
        const groupName = `${firstName}'s team/family`;
        LOGGING && console.log("found no userGroup and i'm about to create one:", groupName);
        this.setState(
          {
            deliveryInfo,
            groupName,
            existingCard: stripeInfo ? stripeInfo.card : null,
            loading: false,
            groupUsers: [],
          },
          () => {
            window.scrollTo(0, 0);
          }
        );
      }
    });
  }

  render() {
    const {
      deliveryInfo,
      budget,
      serviceFee,
      groupName,
      loading,
      newGroupUserEmails,
      groupUsers,
      groupInvites,
      inviteNewGroupUsersError,
      existingCard,
      showCardAction,
      addNewCard,
      newCard,
      error,
      cardType,
    } = this.state;
    const { currentUser } = this.props;
    LOGGING &&
      console.log('PageUserGroupAdmin rendering with', {
        props: this.props,
        state: this.state,
      });
    return (
      <div className="page">
        {loading ? (
          <Loading />
        ) : (
          <GroupSetting
            adminUser={currentUser.user}
            addNewCard={addNewCard}
            cardType={cardType}
            deliveryInfo={deliveryInfo}
            budget={budget}
            serviceFee={serviceFee}
            enableGroupSettings={true}
            error={error}
            existingCard={existingCard}
            groupName={groupName}
            groupUsers={groupUsers}
            groupInvites={groupInvites}
            inviteNewGroupUsersError={inviteNewGroupUsersError}
            newCard={newCard}
            newGroupUserEmails={newGroupUserEmails}
            onAddNewCard={this.handleAddNewCard}
            onBack={this.handleClose}
            onCancelNewCard={this.handleCancelNewCard}
            onChange={this.handleChange}
            onChangeNewGroupUserEmails={this.handleChangeNewGroupUserEmails}
            onChangeNumber={this.handleChangeNumber}
            onDeleteCard={this.handleDeleteCard}
            onEditAddress={this.handleEditAddress}
            onHome={this.handleHome}
            onInviteNewGroupUsers={this.handleInviteNewGroupUsers}
            onSelectAddress={this.handleSelectAddress}
            onSubmit={this.handleSubmit}
            onToggleShowCardAction={this.handleToggleShowCardAction}
            showCardAction={showCardAction}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log('PageUserGroupAdmin got redux state:', state);
  return {
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  readUserGroup,
  deleteUserGroupCard,
  saveGroupSetting,
  inviteNewGroupUsers,
})(
  GoogleApiWrapper({
    apiKey: 'AIzaSyBjfTfgJdRah5wXvVj8U7fH4ACoqKry9HI',
  })(PageUserGroupAdmin)
);
