import React, { ReactElement, useCallback } from 'react';
import { useAsync } from 'react-async';
import { GetRouteSummaryForMealByDriver } from 'src/api/meals';
import { ObjectId } from 'src/constants/types';
import { Loading } from '.';

type DriverRoutesSummaryContainerProps = {
  mealId: ObjectId;
  driverIds: ObjectId[];
};

export const DriverRoutesSummaryContainer: React.FC<DriverRoutesSummaryContainerProps> = ({
  mealId,
}): ReactElement => {
  const fetch = useCallback(async () => {
    if (mealId == null) return [];

    return await GetRouteSummaryForMealByDriver(mealId);
  }, [mealId]);
  const { data } = useAsync({ promiseFn: fetch, watch: mealId });

  if (data == null) return <Loading />;

  return (
    <div className="current-batch drivers">
      {data.map((driverRouteSummary) => (
        <div className="route" key={driverRouteSummary.driverId}>
          <div className="driver-name">{driverRouteSummary.driverName}</div>
          <div className="customers">
            {driverRouteSummary.stops.map((stop) => (
              <div className="customer" key={stop._id}>
                <span className="stop">{stop.mapViewAttributes.stopNum + 1}</span>
                <span className="name">{stop.mapViewAttributes.label}</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
