import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LOGGING, Loading, RestaurantsMap, RestaurantLegends } from '.';
import { readRestaurants } from '../../store/actions';

class PageRestaurantMap extends Component {
  constructor() {
    super();
    LOGGING && console.log('PageRestaurantMap constructor');

    this.state = {
      loading: true,
      zoom: 15,
      center: { lat: 37.3858165, lng: -122.0945878 },
      // restaurantId,
      // restaurant: {},
    };
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose(e) {
    LOGGING && console.log('handleClose called');
    e.preventDefault();
    this.props.history.goBack();
    // const zoom = fitBounds({});
    // LOGGING && console.log('handleZoomChange called with:', zoom);
  }

  componentDidMount() {
    LOGGING && console.log('PageRestaurantMap componentdidmount');

    this.props.readRestaurants().then((restaurants) => {
      // const { restaurants } = this.props;
      LOGGING && console.log('PageRestaurantMap readRestaurants done with:', restaurants);
      const { restaurantId } = this.props.match.params;
      const restaurant = restaurants.find((r) => r._id === restaurantId);
      this.setState(
        {
          loading: false,
          center: { lat: restaurant.map.lat, lng: restaurant.map.lng },
          restaurantId,
        },
        () => {
          window.scrollTo(0, 0);
        }
      );
    });
  }

  render() {
    const { currentUser, restaurants } = this.props;

    const { loading, center, zoom, restaurantId } = this.state;

    LOGGING &&
      console.log('PageRestaurantMap rendering with:', {
        props: this.props,
        state: this.state,
      });

    if (!currentUser.isAuthenticated || (!currentUser.user.isAdmin && !currentUser.user.isOps)) {
      return <Redirect to="/" />;
    }

    return (
      <div className="page">
        <RestaurantLegends />
        {loading ? (
          <Loading />
        ) : (
          <RestaurantsMap
            restaurants={restaurants.filter((r) => r.map)}
            center={center}
            zoom={zoom}
            onClose={this.handleClose}
            restaurantId={restaurantId}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log('PageRestaurantMap got redux state:', state);
  return {
    currentUser: state.currentUser,
    restaurants: state.restaurants,
  };
}

export default connect(mapStateToProps, {
  readRestaurants,
})(PageRestaurantMap);
