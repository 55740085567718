import React, { ReactElement, useCallback } from 'react';
import { useAsync } from 'react-async';
import Select from 'react-dropdown-select';
import { GetCuisines, SerializedCuisine } from 'src/api/cuisines';
import { Loading } from '.';

type CuisineSelectorProps = {
  selectedCuisines: SerializedCuisine[];
  onSelectCuisines: (cuisines: SerializedCuisine[]) => void;
};

export const CuisineSelector: React.FC<CuisineSelectorProps> = ({
  selectedCuisines,
  onSelectCuisines,
}): ReactElement => {
  const fetch = useCallback(async () => {
    return await GetCuisines();
  }, []);
  const { data } = useAsync({ promiseFn: fetch });

  if (data == null) return <Loading />;

  const cuisines = data.cuisines;

  return (
    <Select
      options={cuisines}
      onChange={onSelectCuisines}
      labelField="name"
      valueField="_id"
      values={selectedCuisines}
      multi={true}
    />
  );
};
