import React, { Component } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LOGGING, Loading, RestaurantOrders } from '.';
import { fetchRestaurantOrders } from '../../store/actions';

class PageRestaurantOrders extends Component {
  constructor() {
    super();
    LOGGING && console.log('PageRestaurantOrders constructor');
    this.state = {
      restaurantOrderMatrix: null,
      loading: true,
    };
  }

  componentDidMount() {
    LOGGING &&
      console.log(
        'PageRestaurantOrders componentdidmount with this.props.match.params:',
        this.props.match.params
      );
    this.setState({ loading: true }, () => {
      const { restaurantId } = this.props.match.params;
      this.props.fetchRestaurantOrders(restaurantId).then(() => {
        const { orders } = this.props;
        LOGGING && console.log('got from backend:', orders);
        let dates = [],
          names = [];
        orders.forEach((order) => {
          const { window, user } = order;
          const { start } = window;
          const name = user ? `${user.firstName} ${user.lastName}` : `${order.name}`;
          const date = moment(start).format('LLLL');
          if (dates.findIndex((d) => d === date) < 0) {
            dates.push(date);
          }
          if (names.findIndex((n) => n === name) < 0) {
            names.push(name);
          }
        });
        let restaurantOrderMatrix = {}; //key(date + meal, user firstName)
        dates.forEach((date) => {
          restaurantOrderMatrix[date] = {};
          names.forEach((name) => {
            restaurantOrderMatrix[date][name] = [];
          });
        });

        orders.forEach((order) => {
          const { window, user, goods } = order;
          const { start } = window;
          const name = user ? `${user.firstName} ${user.lastName}` : `${order.name}`;
          const date = moment(start).format('LLLL');
          restaurantOrderMatrix[date][name] = goods;
        });
        this.setState({ loading: false, restaurantOrderMatrix, names, dates }, () => {
          window.scrollTo(0, 0);
        });
      });
    });
  }

  render() {
    const { currentUser } = this.props;
    const { loading, restaurantOrderMatrix, names, dates } = this.state;

    LOGGING &&
      console.log(
        'PageRestaurantOrders rendering with restaurantOrderMatrix:',
        restaurantOrderMatrix
      );

    if (!currentUser.isAuthenticated || !currentUser.user.isAdmin) {
      return <Redirect to="/" />;
    }

    return (
      <div className="page">
        {loading || !restaurantOrderMatrix ? (
          <Loading />
        ) : (
          <RestaurantOrders matrix={restaurantOrderMatrix} names={names} dates={dates} />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log('PageRestaurantOrders got redux state:', state);
  return {
    currentUser: state.currentUser,
    orders: state.orders,
  };
}

export default connect(mapStateToProps, { fetchRestaurantOrders })(PageRestaurantOrders);
