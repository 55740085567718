import { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

type SelectProps = {
  options: object;
} & InputHTMLAttributes<HTMLSelectElement>;

export const Select = (props: SelectProps) => {
  const { options, ...rest } = props;

  return (
    <Container>
      <select {...rest}>
        {Object.entries(options).map(([value, label]) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    </Container>
  );
};

const Container = styled.div`
  select {
    padding: 6px 12px;
    font-size: 16px;
  }
`;
