import React, { ReactElement, useCallback, useState, useEffect } from "react";
import { faHeart, faStar, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faEmpty } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { keyframes } from "styled-components";
import {
  ALLCAPTEXT,
  Beige,
  BorderGrey,
  card,
  centered,
  Centered,
  centeredByColumn,
  CenteredByColumn,
  CenteredByColumnLeft,
  GoogleMapBlank,
  text12300,
  TextGrey,
  textTitle,
} from "../../Shared";
const ImageHeight = 100;
const ImageWidth = 150;
const TextHeight = 50;
const RestaurantContainer = styled(CenteredByColumn)`
  ${card}
  width: 30%;
  /* width: ${ImageWidth}px; */
  /* width: 100%; */
  /* height: ${ImageHeight + TextHeight}px; */
  /* height: auto; */
  position: relative;
  flex-shrink: 0;
  flex-grow: 1;
  align-self: stretch;
  padding: 5px;
  background-color: white;
  /* margin: 2%; */
  margin: 2px;
  border: 1px solid ${BorderGrey};
  /* border: 1px solid ${GoogleMapBlank}; */
  justify-content: flex-start;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.16) 0px 0px 5px 0px;
  > img {
    /* position: absolute;
    top: 0;
    left: 0; */
    width: 100%;
    height: ${ImageHeight}px;
    object-fit: cover;
  }
  @media (min-width: 400px) {
    width: 24%;
  }
  @media (min-width: 500px) {
    width: 19%;
  }
  @media (min-width: 800px) {
    width: 120px;
    flex-grow: 0;
  }
`;
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${ImageHeight}px;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 1;
`;
const Text = styled(CenteredByColumn)`
  /* position: absolute;
  bottom: 0;
  left: 0; */
  width: 100%;
  /* height: 100px; */
  /* height: 40%; */
  margin: 0px;
  /* height: ${TextHeight}px; */
  /* padding: 10px; */
  padding: 8px 10px;
  /* position: relative; */
  justify-content: space-between;
  flex-grow: 1;
  /* color: white; */
  z-index: 2;
`;
const RestaurantName = styled(Centered)`
  /* ${textTitle} */
  ${ALLCAPTEXT}
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  /* text-align: left; */
  line-height: 1.2;
  /* width: 100%; */
  /* height: ${ImageHeight}px; */
  /* position: absolute;
  top: 0;
  left: 0; */
  /* color: white; */
  /* z-index: 2; */
  /* padding-right: 50px; */
`;
const Rating = styled(Centered)`
  ${text12300}
  margin-top: 3px;
  line-height: 1.6;
  letter-spacing: 0.4px;
  font-size: 10px;
  font-weight: 400;
  /* background-color: red; */
  svg {
    margin: 0px 1px;
    font-size: 8px;
    margin-bottom: 1px;
  }
  text-align: center;
`;
const Heart = styled.button<{ isOn: boolean }>`
  position: absolute;
  /* bottom: 0px; */
  right: 0px;
  top: 0px;
  /* width: 10%; */
  /* width: 30%; */
  flex-grow: 1;
  align-self: stretch;
  width: 100%;
  height: 100%;
  ${centeredByColumn}
  padding: 20px 10px;
  /* justify-content: space-between; */

  /* border: 1px solid blue; */
  svg {
    font-size: 30px;
    color: ${(props) => (props.isOn ? "white" : "transparent")} !important;
    z-index: 3;
    /* margin-bottom: 10px; */
    position: absolute;
    top: 40px;
  }
  background-color: ${(props) =>
    props.isOn ? "rgba(0,0,0,0.7)" : "transparent"};
  z-index: 3;
  /* padding-bottom: 40px; */
`;
export const TasteSurveyRestaurant = ({
  name,
  _id,
  image,
  isFavorite = false,
  onToggleFavorite,
  rating = 0,
  reviews = 0,
}) => (
  <RestaurantContainer>
    <img src={image} alt={name} />
    {/* <Overlay /> */}
    <Text>
      <RestaurantName>{name}</RestaurantName>
      <Rating>
        {/* <label>Yelp</label> */}
        <span>
          {`Yelp ${rating}`}
          <FontAwesomeIcon icon={faStar} />({reviews.toLocaleString()})
        </span>
      </Rating>
    </Text>
    <Heart isOn={isFavorite} onClick={onToggleFavorite}>
      <FontAwesomeIcon icon={faThumbsUp} />
    </Heart>
  </RestaurantContainer>
);
