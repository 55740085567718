import { LOGGING } from "../../constants";
import { LOAD_STATS, RESET_STATS, SET_MILEAGE } from "../actionTypes";
import { apiCall } from "../api";
import mileage from "../reducers/mileage";
export const loadStats = (stats) => ({
  type: LOAD_STATS,
  stats,
});

const setMileage = (mileage) => ({
  type: SET_MILEAGE,
  mileage,
});

export const resetStats = () => ({
  type: RESET_STATS,
});

export const readGrowth = (growthWindow) => {
  LOGGING && console.log("readGrowth called with growthWindow:", growthWindow);
  return (dispatch, getState) => {
    const { stats } = getState();
    return apiCall(
      "GET",
      `/dashboard/stats/${growthWindow}`,
      {},
      getState().currentUser
    )
      .then((result) => {
        LOGGING && console.log("readGrowth got result:", result);
        dispatch(loadStats({ ...stats, growth: { ...result } }));
      })
      .catch((err) => {
        LOGGING && console.log("readGrowth got err", err);
      });
  };
};

export const readRoutes = () => {
  LOGGING && console.log("readRoutes");
  return (dispatch, getState) => {
    const { stats } = getState();
    return apiCall("GET", `/dashboard/routes/raw`, {}, getState().currentUser)
      .then((result) => {
        LOGGING && console.log("readRoutes got result:", result);
        dispatch(loadStats({ ...stats, routes: { ...result } }));
      })
      .catch((err) => {
        LOGGING && console.log("readRoutes got err", err);
      });
  };
};

export const readRestaurantsStats = () => {
  LOGGING && console.log("readRestaurantsStats");
  return (dispatch, getState) => {
    const { stats } = getState();
    return apiCall(
      "GET",
      `/dashboard/restaurant/revenue`,
      {},
      getState().currentUser
    )
      .then((result) => {
        LOGGING && console.log("readRestaurantsStats got result:", result);
        dispatch(loadStats({ ...stats, restaurants: { ...result } }));
      })
      .catch((err) => {
        LOGGING && console.log("readRestaurantsStats got err", err);
      });
  };
};

export const readUsersStats = () => {
  LOGGING && console.log("readUsersStats called");
  return (dispatch, getState) => {
    const { stats } = getState();
    const userId = "602a37a7c114240017325c1d";
    const windowSize = "sliding 30 days";
    return apiCall(
      "GET",
      `/dashboard/stats/byUser/${userId}/${windowSize}`,
      {},
      getState().currentUser
    )
      .then((result) => {
        LOGGING && console.log("readUsersStats got result:", result);
        dispatch(loadStats({ ...stats, users: [result] }));
      })
      .catch((err) => {
        LOGGING && console.log("readUsersStats got err", err);
      });
  };
};

export const readOrderStats = () => {
  LOGGING && console.log("readOrderStats called");
  return (dispatch, getState) => {
    const { stats } = getState();
    return apiCall(
      "GET",
      `/admin/dashboard/stats/order/month`,
      {},
      getState().currentUser
    )
      .then((result) => {
        LOGGING && console.log("readOrderStats got result:", result);
        dispatch(loadStats({ ...stats, ordersByUser: result }));
      })
      .catch((err) => {
        LOGGING && console.log("readOrderStats got err", err);
      });
  };
};

export const readTotalMileage = () => {
  return (dispatch, getState) => {
    return apiCall("GET", `/metrics/distanceSaved/`)
      .then((result) => {
        LOGGING && console.log("readTotalMileage got result:", result);
        dispatch(setMileage(result));
      })
      .catch((err) => {
        LOGGING && console.log("readTotalMileage got err", err);
      });
  };
};
