import { LOGGING } from '../../constants';
import { SET_CONFIG_ADDRESS_LIST } from '../actionTypes';
import { apiCall } from '../api';

export const setAddressList = (addressWhiteList, addressBlackList) => ({
  type: SET_CONFIG_ADDRESS_LIST,
  payload: {
    addressWhiteList,
    addressBlackList,
  },
});

export const getAddressList = () => {
  return async (dispatch) => {
    LOGGING && console.log('getAddressList called');
    try {
      const { addressWhiteList, addressBlackList } = await apiCall('get', `/config/addressList`);
      dispatch(setAddressList(addressWhiteList, addressBlackList));
    } catch (err) {
      LOGGING && console.log('getAddressList got err', err);
    }
  };
};

export const updateAddressList = (newAddressWhiteList, newAddressBlackList) => {
  return async (dispatch) => {
    LOGGING && console.log('updateAddressList called', newAddressWhiteList);
    try {
      const { addressWhiteList, addressBlackList } = await apiCall('put', `/config/addressList`, {
        addressWhiteList: newAddressWhiteList,
        addressBlackList: newAddressBlackList,
      });
      dispatch(setAddressList(addressWhiteList, addressBlackList));
    } catch (err) {
      LOGGING && console.log('updateAddressList got err', err);
    }
  };
};
