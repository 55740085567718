import React, { useEffect } from "react";
import {
  faExclamationTriangle,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GetMealLink } from "src/constants";
import { RootState } from "src/store";
import styled from "styled-components";
import { Container, Field } from "./Contact";
import { actionGrey, text12300, WarningRed } from "../../Shared";
const DEFAULT_ORDER_MINIMUM = 15;
const WarningContainer = styled(Container)`
  color: ${WarningRed};
  div {
    justify-content: flex-start;
  }
  b {
    margin: 0px 7px;
  }
`;
const AddMoreItems = styled(Link)`
  ${actionGrey}
  ${text12300}
  text-transform: capitalize;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 10px;
  margin-top: 5px;
  margin-right: 10px;
  svg {
    margin-right: 7px;
  }
`;
export const MinValueCheck = ({ onSetIsCartValid }) => {
  const cart = useSelector((state: RootState) => state?.cart?.payLoad);
  const [minOrderValue, setMinOrderValue] = React.useState(
    DEFAULT_ORDER_MINIMUM
  );
  const [mealLink, setMealLink] = React.useState("");
  const [isCartValid, setIsCartValid] = React.useState(true);
  useEffect(() => {
    const minOrderValue = cart?.promo?.isActive
      ? 0
      : Math.max(
          DEFAULT_ORDER_MINIMUM,
          cart?.couponCode?.minTotal || 0,
          (!cart?.userGroup && cart?.meal?.restaurant?.minOrderValue) || 0
        );
    const isCartValid = cart?.total >= minOrderValue;
    setMinOrderValue(minOrderValue);
    setIsCartValid(isCartValid);
    onSetIsCartValid(isCartValid);
    const { meal } = cart;
    const mealLink = GetMealLink(meal);
    setMealLink(mealLink);
  }, [cart]);
  if (isCartValid) {
    return null;
  }
  return (
    <WarningContainer>
      <Field>
        <FontAwesomeIcon icon={faExclamationTriangle} /> Your order must be at
        least <b>${minOrderValue}</b> to proceed.
      </Field>
      <AddMoreItems to={mealLink}>
        <FontAwesomeIcon icon={faPlus} />
        <span>Add more items</span>
      </AddMoreItems>
    </WarningContainer>
  );
};
