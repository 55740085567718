import { LOGGING } from "src/constants";
import { ObjectId } from "src/constants/types";
import { apiCall } from "src/store/api";

type SerializedCouponCode = {
  _id: ObjectId;
  code: string;
  discountDollar: number;
  commissionDollar: number;
  minTotal: number;
};
export type GetReferralCodeDetailsResponse = {
  userId: ObjectId;
  couponCode: SerializedCouponCode | null;
};

type SentAlertResponse = {};
export const GetReferralCodeDetails = async (
  userId
): Promise<GetReferralCodeDetailsResponse> => {
  try {
    return await apiCall("POST", `/referrals/GetReferralCodeDetails`, {
      userId: userId,
    });
  } catch (err) {
    LOGGING && console.log("GetReferralCodeDetails got err", err);
    return { userId: userId, couponCode: null };
  }
};

export type GetReferralLinkDetailsResponse = {
  userId: ObjectId;
  couponCode: SerializedCouponCode | null;
  promoCode: String;
};
export const GetReferralLinkDetails = async (
  userId
): Promise<GetReferralLinkDetailsResponse> => {
  try {
    return await apiCall("POST", `/referrals/GetReferralLinkDetails`, {
      userId: userId,
    });
  } catch (err) {
    LOGGING && console.log("GetReferralLinkDetails got err", err);
    return { userId: userId, couponCode: null, promoCode: "" };
  }
};
export type GetReferrerFromCodeResponse = {
  firstName: String;
  lastName: String;
};
export const GetReferrerFromCode = async (
  code
): Promise<GetReferrerFromCodeResponse> => {
  try {
    return await apiCall("POST", `/referrals/GetReferrerFromCode`, {
      code,
    });
  } catch (err) {
    LOGGING && console.log("GetReferrerFromCode got err", err);
    throw err;
  }
};

export const SendAlert = async (name, event): Promise<SentAlertResponse> => {
  try {
    return await apiCall("POST", `/users/visit`, {
      name,
      event,
    });
  } catch (err) {
    LOGGING && console.log("SendAlert got err", err);
    return { name, event, err };
  }
};

export type GetReferralLinkSenderResponse = {
  code: string;
  sender: Object | null;
};
export const GetReferralLinkSender = async (
  referralCode
): Promise<GetReferralLinkSenderResponse> => {
  try {
    return await apiCall("POST", `/referrals/GetReferralLinkSender`, {
      referralCode,
    });
  } catch (err) {
    LOGGING && console.log("GetReferralLinkSender got err", err);
    return { code: referralCode, sender: null };
  }
};

export type SerializedReferral = {
  date: string; // yyyy-mm-dd
  referreeName: string;
  creditEarned: number;
};
export type GetUserReferralsResponse = {
  userId: ObjectId;
  referrals: SerializedReferral[];
};
export const GetUserReferrals = async (
  userId
): Promise<GetUserReferralsResponse> => {
  try {
    return await apiCall("POST", `/referrals/GetUserReferrals`, {
      userId: userId,
    });
  } catch (err) {
    LOGGING && console.log("GetUserReferrals got err", err);
    return { userId: userId, referrals: [] };
  }
};
