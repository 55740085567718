import React, { ReactElement, useCallback, useState, useEffect } from "react";
import { faCircle as faEmpty } from "@fortawesome/pro-light-svg-icons";
import { faCheckCircle as faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";
import { useSelector, useDispatch, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { RootState } from "src/store";
import { cancelMembership } from "src/store/actions";
import styled from "styled-components";
import { Back, LOGGING } from "../..";
import healthy_image from "../../../../../healthy.jpg";
import cancel1_image from "../../../../../saving.png";
import initial_image from "../../../../../travel.png";
import { Overlay } from "../../Shared";
import {
  ActionBlack,
  BackgroundGrey,
  ActionGrey,
  ActionTransparent,
  BorderGrey,
  Centered,
  CenteredByColumn,
  Gold,
  NavHeight,
  text12300,
  text13500,
  textTitle,
  TextGrey,
} from "../../Shared";
import { LoadingSpinner } from "../survey/Taste";
// import from travel.png

const BreakPoint = 800;
const ImageWidth = 120;
const Image = styled.img`
  width: ${ImageWidth}px;
  height: ${ImageWidth}px;
  object-fit: cover;
  /* border-radius: 50%; */
  margin-bottom: 10px;
  @media only screen and (max-height: 600px) {
    width: ${ImageWidth - 20}px;
    height: ${ImageWidth - 20}px;
    margin-bottom: 0px;
  }
`;
// add animation to enter sliding in from right

const CancelContainer = styled(CenteredByColumn)`
  width: 100%;
  min-height: 100vh;
  padding: 60px 20px 120px 20px;
  /* padding-top: 50px; */
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-height: 600px) {
    padding: 30px 15px 50px 15px;
  }
  @media only screen and (min-width: ${BreakPoint}px) {
    > button:first-child {
      left: 8vw;
    }
  }
  animation: slideInRight 0.5s forwards;
  @keyframes slideInRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
`;
const Content = styled(CenteredByColumn)`
  width: 100%;
  max-width: 600px;
  /* padding: 0px 10px; */
  > textarea {
    flex-grow: 1;
    align-self: stretch;
    margin: 0px;
    border: none;
    background-color: ${BackgroundGrey};
    box-sizing: border-box;
    ${text12300}
  }
  > textarea::placeholder {
    ${text12300}
    color: ${TextGrey};
  }
  // style direct child h1 and h2

  h1 {
    ${textTitle}
    text-align: center;
    text-transform: none;
    line-height: 1.3;
    margin: 20px 20px 10px 20px;
    /* padding: 0px ${NavHeight}px; */
  }
  h1:first-child {
    margin-top: 20px;
  }
  h2 {
    ${text12300}
    font-weight: 400;
    font-size: 13px;
    line-height: 1.5;
  }
  @media only screen and (max-height: 600px) {
    h1 {
      font-size: 18px;
    }
  }
`;
const Bullet = styled(Centered)`
  justify-content: flex-start;
  /* padding: 20px 10px; */
  padding: 0px 5px 0px 5px;
  align-self: stretch;
  span {
    ${text12300}
    line-height: 1.5;
    font-weight: 400;
    border-bottom: 1px solid ${BorderGrey};
    padding: 0px 0px 15px 0px;
    flex-grow: 1;
  }

  &:last-child {
    span {
      border-bottom: none;
    }
  }
  @media only screen and (max-height: 600px) {
    padding: 10px 5px 0px 5px;
    span {
      font-size: 11px;
    }
  }
`;
const Icon = styled(Centered)`
  /* width: 20px;
    height: 20px; */
  font-size: 40px;
  margin-right: 10px;
  margin-bottom: 10px;
  @media only screen and (max-height: 600px) {
    font-size: 30px;
  }
`;
const ButtonContainer = styled(CenteredByColumn)`
  align-content: stretch;
  /* flex-grow: 1; */
  /* align-self: stretch; */
  max-width: 600px;
  button {
    min-width: 200px;
  }
`;
const ButtonPause = styled(ActionBlack)`
  align-self: stretch;
  margin: 0 0 10px 0;
  flex-grow: 1;
  height: 50px;
`;
const ButtonStay = styled(ActionGrey)`
  align-self: stretch;
  margin: 0 0 10px 0;
  background-color: ${Gold};
  height: 50px;
`;
const ButtonFinal = styled(ActionGrey)`
  align-self: stretch;
  margin: 20px;
  background-color: ${Gold};
  flex-grow: 1;
  height: 50px;
`;
const ButtonReason = styled(ActionBlack)`
  align-self: stretch;
  margin: 0 0 10px 0;
  height: 50px;
  &:disabled {
    background-color: ${BackgroundGrey};
    color: black;
  }
`;
const ButtonContinue = styled(ActionTransparent)`
  align-self: stretch;
  border: none;
  height: 50px;
`;
const BulletList = styled(CenteredByColumn)`
  align-self: stretch;
  flex-grow: 1;
`;
const SelectionList = styled(CenteredByColumn)`
  align-self: stretch;
  flex-grow: 1;
  margin-top: 20px;
`;
const Selection = styled(ActionTransparent)`
  align-self: stretch;
  margin: 0 0 20px 0;
  flex-grow: 1;
  justify-content: flex-start;
  text-transform: none;
  text-align: left;
  border: none;
  padding: 0px 10px;
  height: auto;
  line-height: 20px;
  align-items: flex-start;
  svg {
    margin-right: 10px;
    font-size: 20px;
  }
`;
const CANCEL_STATES = {
  INITIAL: {
    title: "Taking a break? Pause membership instead",
    image: initial_image,
    bullets: [
      {
        text: "Membership fees stop now, keep benefits through this billing period.",
        icon: "⏸️",
      },
      {
        text: "Easy reactivation on your next order so you can quickly start saving again.",
        icon: "🥡",
      },
    ],
  },
  CONTINUE_TO_CANCEL_1: {
    title: "Foodie members save an average of $6~8 per order",
    image: cancel1_image,
    // subTitle: "Keep Foodie membership to continue enjoying your benefits:",
    bullets: [
      {
        text: "20% cheaper food price",
        icon: "💰",
      },
      {
        text: "flat service fee + free delivery",
        icon: "🚛",
      },
      {
        text: "100+ member-only restaurants",
        icon: "🔒",
      },
    ],
  },
  SHARE_REASON: {
    title: "Share your reason for canceling membership",
    selections: [
      {
        text: "I can't find the food I like",
      },
      {
        text: "Delivery is too late",
      },
      {
        text: "Food arrives cold",
      },
      {
        text: "The membership is too expensive",
      },
      {
        text: "I want to change my eating habits",
      },
      {
        text: "Others",
      },
    ],
  },
  LAST_TRY: {
    title: "Foodie offers hundreds of options for every lifestyle",
    image: healthy_image,
    bullets: [
      {
        text: "Many member-only heathy choices",
        icon: "🥗",
      },
      {
        text: "10~20 new restaurants to try each month",
        icon: "🌟",
      },
      {
        text: "flat service fee + free delivery",
        icon: "🚛",
      },
    ],
  },
  FINAL: {
    title: "Foodie membership",
    subTitle: "We're sorry to see you go!",
  },
};
export interface CancelMembershipProps {}
export const CancelMembership: React.FC<CancelMembershipProps> =
  ({}: CancelMembershipProps): ReactElement => {
    const currentUser = useSelector((state: RootState) => state.currentUser);
    const [cancelState, setCancelState] = useState("INITIAL");
    const [reason, setReason] = useState("");
    const [feedback, setFeedback] = useState("");
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const onGoBack = useCallback(() => {
      // go back to /membership, and pop the current page
      // toast needs to be at top of the page
      history.goBack();
    }, [history]);

    const onCancelMembership = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        await dispatch(cancelMembership({ reason, feedback, isPaused: false }));
        setLoading(false);
        toast.success("Canceled!", {
          duration: 1000,
          position: "top-center",
          style: {
            zIndex: 1000,
          },
        });
        history.goBack();
      } catch (error) {
        // Handle error
        setLoading(false);
        console.error("Cancellation failed:", error);
      }
    };

    const onPauseMembership = async (e) => {
      setLoading(true);
      try {
        await dispatch(cancelMembership({ isPaused: true }));
        setLoading(false);
        toast.success("Paused!", {
          duration: 1000,
          position: "top-center",
          style: {
            zIndex: 1000,
          },
        });
        history.goBack();
      } catch (error) {
        // Handle error
        setLoading(false);
        console.error("Pause failed:", error);
      }
    };

    const onKeepMembership = async (e) => {
      toast.success("Glad you stay w/ us!", {
        duration: 1000,
        position: "top-center",
        style: {
          zIndex: 1000,
        },
      });
      history.goBack();
    };
    LOGGING &&
      console.log("CancelMembership rendering with", {
        cancelState,
        reason,
        feedback,
      });
    return (
      <CancelContainer>
        {loading && (
          <Overlay>
            <LoadingSpinner />
          </Overlay>
        )}
        <Back onClick={onGoBack} />
        <Content>
          {CANCEL_STATES[cancelState].image ? (
            <Image src={CANCEL_STATES[cancelState].image} />
          ) : null}
          <h1>{CANCEL_STATES[cancelState].title}</h1>
          {CANCEL_STATES[cancelState].subTitle ? (
            <h2>{CANCEL_STATES[cancelState].subTitle}</h2>
          ) : null}
          {CANCEL_STATES[cancelState].bullets && (
            <BulletList>
              {CANCEL_STATES[cancelState].bullets?.map((bullet, index) => {
                return (
                  <Bullet key={index}>
                    <Icon>{bullet.icon}</Icon>
                    <span>{bullet.text}</span>
                  </Bullet>
                );
              })}
            </BulletList>
          )}
          {CANCEL_STATES[cancelState].selections && (
            <SelectionList>
              {CANCEL_STATES[cancelState].selections.map((selection) => (
                // define Selection as input, clicking toggles the selection.text in reasons
                <Selection
                  key={selection.text}
                  onClick={(e) => {
                    e.preventDefault();
                    setReason(selection.text);
                  }}
                >
                  <span>
                    {reason === selection.text ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : (
                      <FontAwesomeIcon icon={faEmpty} />
                    )}
                  </span>
                  <span>{selection.text}</span>
                </Selection>
              ))}
            </SelectionList>
          )}
          {cancelState === "FINAL" ? (
            <textarea
              placeholder="Please share your feedback"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              style={{ width: "100%", height: "100px", padding: "10px" }}
            ></textarea>
          ) : null}
        </Content>
        <ButtonContainer>
          {cancelState === "INITIAL" ||
          cancelState === "CONTINUE_TO_CANCEL_1" ? (
            <ButtonPause onClick={onPauseMembership}>
              Pause Membership
            </ButtonPause>
          ) : null}
          {cancelState === "INITIAL" || cancelState == "LAST_TRY" ? (
            <ButtonStay onClick={onKeepMembership}>Keep Membership</ButtonStay>
          ) : null}
          {cancelState === "INITIAL" ||
          cancelState === "LAST_TRY" ||
          cancelState === "CONTINUE_TO_CANCEL_1" ? (
            <ButtonContinue
              onClick={(e) => {
                e.preventDefault();
                setCancelState(
                  cancelState === "INITIAL"
                    ? "CONTINUE_TO_CANCEL_1"
                    : cancelState === "CONTINUE_TO_CANCEL_1"
                    ? "SHARE_REASON"
                    : cancelState === "LAST_TRY"
                    ? "FINAL"
                    : "INITIAL"
                );
              }}
            >
              Continue to cancel
            </ButtonContinue>
          ) : null}
          {cancelState === "FINAL" && (
            <ButtonFinal onClick={onCancelMembership}>got it</ButtonFinal>
          )}
          {cancelState === "SHARE_REASON" ? (
            <ButtonReason
              onClick={(e) => {
                e.preventDefault();
                setCancelState("LAST_TRY");
              }}
              disabled={reason === ""}
            >
              select reason
            </ButtonReason>
          ) : null}
        </ButtonContainer>
      </CancelContainer>
    );
  };
