import React, { useCallback } from "react";
import { faPrint } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { CSVLink } from "react-csv";
import { useDispatch, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import "./MealOrderList.scss";
import { CancelDishForMeal } from "src/api/meals";
import { LOGGING, receiptTimeFormat, Summary } from ".";
import { OrderReceipt } from "./restaurants/OrderReceipt";
import {
  PrintOrderLabelsForMeal,
  ProcessedType,
} from "./restaurants/PrintOrderLabelsForMeal";

const PrintableOrders = React.forwardRef(
  ({ orders, showDetails, onCancelItem, onCancelSelectionItem }, ref) => {
    return (
      <div
        className={`current-batch ${
          showDetails === 0
            ? "show-new"
            : showDetails === 1
            ? "show-confirmed"
            : "show-summary"
        }`}
        ref={ref}
      >
        {orders.map((order, i) => (
          <OrderReceipt
            key={order._id}
            order={order}
            onCancelItem={onCancelItem}
            onCancelSelectionItem={onCancelSelectionItem}
          />
        ))}
      </div>
    );
  }
);
const MealOrderList = ({
  orders,
  onOrder,
  showDetails,
  onShowDetails,
  meal,
  onCancelItem,
  onCancelSelectionItem,
}) => {
  LOGGING && console.log("MealOrderList rendering with:", { meal, orders });

  const dispatch = useDispatch();
  const store = useStore();

  const cancelDish = useCallback(
    (mealId, dishId) => {
      CancelDishForMeal(mealId, dishId, meal?.windowStart)(dispatch, store);
    },
    [dispatch, meal?.windowStart, store]
  );

  const history = useHistory();
  const navToOrderReceiptsPage = useCallback(() => {
    history.push(`/restaurants/meals/${meal._id}/order-receipts`);
  }, [history, meal._id]);

  const printAndProcessOrders = useCallback(() => {
    window.print();
    onOrder();
  }, [onOrder]);

  if (!meal) {
    return null;
  }
  const discount = (meal.restaurant.commission || 0) / 100;
  const ordersNew = orders.filter((o) => !o.restaurantConfirmTime);
  const ordersConfirmed = orders
    .filter((o) => o.restaurantConfirmTime)
    .sort((a, b) => b.restaurantConfirmTime - a.restaurantConfirmTime);
  const ordersToShow =
    showDetails === 0
      ? ordersNew
      : showDetails === 1
      ? ordersConfirmed
      : orders;

  let summary = {},
    totalItems = 0,
    isPreOrdered = meal && meal.preOrderedDishes.length > 0;
  ordersToShow.forEach((order) => {
    const { goods } = order;
    LOGGING && console.log("MealOrderList got goods:", goods);

    goods.forEach((good) => {
      const { dish, quantity, cancelQuantity = 0, price } = good;
      const totalSelectionPrice = good.selections.reduce((acc, selection) => {
        const selectionTotal = selection.selectedItems.reduce(
          (acc, selectedItem) => {
            return (
              acc +
                (selectedItem.item.priceFoodieRestaurant ||
                  selectedItem.item.price ||
                  0) *
                  selectedItem.count || 0
            );
          },
          0
        );
        return acc + selectionTotal;
      }, 0);
      const key = dish.name;
      if (quantity <= cancelQuantity) {
        return;
      }
      if (!(key in summary)) {
        let preOrderedQuantity = 0;
        if (isPreOrdered) {
          preOrderedQuantity = meal.preOrderedDishes.find(
            (preOrderedDish) => preOrderedDish.dish._id === dish._id
          ).quantity;
        }
        summary[key] = {
          dishId: dish._id,
          quantity: 0,
          cancelQuantity: 0,
          preOrderedQuantity,
          priceFoodieListed: dish.priceFoodieListed,
          priceFoodieRestaurant: dish.priceFoodieRestaurant,
          totalSelectionPrice,
        };
      }
      summary[key].quantity += quantity - cancelQuantity;
      totalItems += quantity - cancelQuantity;
    });
  });
  LOGGING && console.log("MealOrderList got summary:", summary);
  const total = Object.values(summary).reduce((acc, dish) => {
    return (
      acc +
      ((dish.priceFoodieRestaurant || dish.priceFoodieListed) +
        dish.totalSelectionPrice) *
        (dish.quantity - dish.cancelQuantity)
    );
  }, 0);
  LOGGING && console.log("MealOrderList got total:", total);
  let csvData = [["dish", "price", "quantity", "sub-total"]];
  for (const dish in summary) {
    csvData.push([
      dish,
      summary[dish].priceFoodieRestaurant || summary[dish].priceFoodieListed,
      summary[dish].quantity,
      (summary[dish].priceFoodieRestaurant || summary[dish].priceFoodieListed) *
        summary[dish].quantity,
    ]);
  }

  const currentEpochTimeMs = new Date().valueOf();
  const areOrdersFinal = currentEpochTimeMs > meal?.orderCutoffTime;
  const orderCutoffTime = moment(meal?.orderCutoffTime)
    .tz("America/Los_Angeles")
    .format("ddd, MMM D h:mmA");
  const canRestaurantEditOrders =
    currentEpochTimeMs < meal?.editOrderCutoffTime;

  return (
    <div className="restaurant-orders">
      <div className="meal-info">
        <b>pick-up</b>
        {` ${moment(meal?.windowStart)
          .add(-15, "m")
          .format(receiptTimeFormat)}`}
      </div>
      <div className="show-details-toggles">
        <button
          className={`show-details ${showDetails === 0 ? "selected" : ""}`}
          onClick={onShowDetails.bind(this, 0)}
        >
          <span>{`new (${ordersNew.length})`}</span>
        </button>
        <button
          className={`show-details ${showDetails === 1 ? "selected" : ""}`}
          onClick={onShowDetails.bind(this, 1)}
        >
          <span>{`processed (${ordersConfirmed.length})`}</span>
        </button>
        <button
          className={`show-details ${showDetails === 2 ? "selected" : ""}`}
          onClick={onShowDetails.bind(this, 2)}
        >
          <span>{`summary (${orders.length})`}</span>
        </button>
      </div>

      <div
        style={{
          padding: "0 12px",
          fontSize: "16px",
          backgroundColor: "red",
          width: "100%",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {areOrdersFinal ? null : (
          <div>
            <p>THIS ORDER LIST IS NOT FINAL.</p>
            <p>
              <b>ORDERS ARE FINALIZED AT {orderCutoffTime}</b>
            </p>
          </div>
        )}
        <p>Note: Please make sure to bag all orders separately.</p>
      </div>

      {showDetails === 0 || showDetails === 1 ? (
        <>
          {showDetails === 0 ? (
            <>
              <PrintOrderLabelsForMeal
                mealId={meal._id}
                orderIds={ordersToShow.map((o) => o._id)}
                processedType={ProcessedType.NOT_PROCESSED}
              />
              {/* <button className="print-order" onClick={navToOrderReceiptsPage}>
                <FontAwesomeIcon icon={faPrint} />
                <span>Print Order Receipts</span>
              </button> */}
              <button className="print-order" onClick={printAndProcessOrders}>
                <FontAwesomeIcon icon={faPrint} />
                <span>PRINT ORDERS</span>
              </button>
            </>
          ) : (
            <>
              <PrintOrderLabelsForMeal
                mealId={meal._id}
                orderIds={ordersToShow.map((o) => o._id)}
                processedType={ProcessedType.PROCESSED}
              />
              <button className="print-order" onClick={printAndProcessOrders}>
                <FontAwesomeIcon icon={faPrint} />
                <span>PRINT ORDERS</span>
              </button>
            </>
          )}
          <PrintableOrders
            orders={ordersToShow}
            onCancelItem={canRestaurantEditOrders ? onCancelItem : null}
            onCancelSelectionItem={
              canRestaurantEditOrders ? onCancelSelectionItem : null
            }
            showDetails={showDetails}
          />
        </>
      ) : (
        <>
          <CSVLink
            className="download-summary"
            data={csvData}
            filename="summary"
          >
            Download summary
          </CSVLink>
          <Summary
            mealId={meal?._id}
            summary={summary}
            totalItems={totalItems}
            isPreOrdered={isPreOrdered}
            total={total}
            markup={0}
            discount={discount}
            onCancelDish={canRestaurantEditOrders ? cancelDish : null}
          />
        </>
      )}
    </div>
  );
};

export default MealOrderList;
