import React, { ReactElement, useCallback, useState } from "react";
import {
  // faCommentDots as faChat,
  faHeadset as faChat,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { centered, Yellow } from "./../../Shared";
const width = 60;
const LiveChatContainer = styled.a.attrs((props) => ({
  hasShadow: props.theme.hasShadow,
}))`
  /* margin: 10px 0px; */
  ${centered}
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 200;
  width: ${width}px;
  height: ${width}px;
  background-color: ${Yellow};
  border-radius: 50%;
  box-shadow: ${(props) =>
    props.theme.hasShadow ? " 0 8px 32px 0 rgba(28, 29, 33, 0.8)" : "none"};
  flex-direction: column;
  span {
    font-size: 8px;
    letter-spacing: 1px;
    font-weight: 500;
    /* position: absolute; */
    top: 6px;
  }
  svg {
    font-size: 23px;
    color: black;
    /* margin-top: 5px; */
  }
  /* @media only screen and (min-width: 800px) {
    display: none;
  } */
`;
export interface LiveChatProps {
  hasShadow: boolean;
}
export const LiveChat: React.FC<LiveChatProps> = ({
  hasShadow,
}: LiveChatProps): ReactElement => {
  return (
    <LiveChatContainer href="sms:+14085200627" theme={{ hasShadow }}>
      <FontAwesomeIcon icon={faChat} />
      <span>24/7</span>
    </LiveChatContainer>
  );
};
