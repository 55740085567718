// Filter null elements
export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

// Given an array, returns a dictionary with the elements of the array
// grouped by the reduced attribute.
//
// For example, if we wanted to group RouteStops by stop IDs, we could use this function.
// @return { [key]: Array[value] }
export const groupArrayBy = (arr, keyReducer, valueReducer) => {
  const dict = {};
  arr.forEach((el) => {
    const key = keyReducer(el);
    const value = valueReducer(el);
    if (dict[key] == null) {
      dict[key] = [];
    }

    dict[key].push(value);
  });
  return dict;
};

// Given an array, returns a dictionary with specified key and value reducers
// @return { [key]: value }
export const arrayToDict = (arr, keyReducer, valueReducer) => {
  const dict = {};
  arr.forEach((el) => {
    const key = keyReducer(el);
    const value = valueReducer(el);
    dict[key] = value;
  });
  return dict;
};

// Given an Array[Number], return the sum of all elements
// @return Number
export const sumArr = (arr) => {
  return arr.reduce((partialSum, el) => partialSum + el, 0);
};

export function updateItemInArr<T, R>(arr: T[], item: T, attrReducer: (el: T) => R): T[] {
  const index = arr.findIndex((el) => attrReducer(item) === attrReducer(el));
  return [...arr.slice(0, index), item, ...arr.slice(index + 1, arr.length)];
}

export function dedupArr<T>(arr: T[], comparator: (elem1: T, elem2: T) => boolean): T[] {
  return arr.filter((value, index) => index === arr.findIndex((t) => comparator(value, t)));
}

export function findAndReplaceArr<T, V>(arr: T[], val: T, reducer: (el: T) => V): T[] {
  const index = arr.findIndex((el: T) => reducer(el) === reducer(val));
  const first = arr.slice(0, index);
  const last = arr.slice(index + 1, arr.length);

  return [...first, val, ...last];
}

export function removeArr<T, V>(arr: T[], val: T, reducer: (el: T) => V): T[] {
  return arr.filter((el) => reducer(el) !== reducer(val));
}
