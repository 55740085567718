import { InputHTMLAttributes } from 'react';
import { Flex } from 'src/components/Layout';
import styled from 'styled-components';

export type CheckboxProps = {
  label: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const Checkbox = (props: CheckboxProps) => {
  const { label, ...inputProps } = props;

  const checkboxId = `${inputProps.name}_${label}`;

  return (
    <Container $disabled={inputProps.disabled}>
      <Flex align="center" gap={6} inline>
        <input id={checkboxId} {...inputProps} type="checkbox" />
        <label htmlFor={checkboxId}>{label}</label>
      </Flex>
    </Container>
  );
};

const Container = styled.div<{ $disabled?: boolean }>`
  input {
    margin: 0;
  }

  label {
    font-size: 14px;
  }

  ${({ $disabled }) =>
    $disabled
      ? `
        label {
          color: #888888;
        }
      `
      : ''}
`;
