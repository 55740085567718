import React, { ReactElement } from "react";
import { debounce } from "lodash";
import { SerializedDish } from "src/api/dish";
import {
  DishSelectionAction,
  DishSelectionActionProps,
} from "src/components/DishSelectionModal/DishSelectionAction";
import { BREAK_POINTS } from "src/constants";
import { LOGGING } from "src/constants";
import styled from "styled-components";
import { DishVegetarianIcon } from "../DishVegetarianIcon";

export type Good = {
  dish: SerializedDish;
  selections: {
    dishSelection: string;
    selectedItems: {
      item: string;
      count: number;
    }[];
  }[];
  price: number;
  priceNonMember: number;
  quantity: number;
  comment: string;
  addedBy?: any;
};

export type Props = {
  dish: SerializedDish;
  countInCart: number;
  onClickCountInCart?: () => void;
  children: ReactElement;
  isMember: boolean;
  isAuthenticated?: boolean;
  // Visual props
  modalify: boolean;
  isNotOrganizer?: boolean;
  quantityCap: number;
  goodKey?: string;
} & DishSelectionActionProps;

export const PureDishChooser: React.FC<Props> = (
  props: Props
): ReactElement => {
  const {
    dish,
    countInCart,
    onClickCountInCart,
    modalify,
    isMember,
    isAuthenticated,
    bottom,
    quantityCap,
    isNotOrganizer,
    price,
    priceNonMember,
    quantity,
    onChangeDishQuantity,
    onAddDishToCart,
    isValid,
    onStartMembership,
    goodKey,
    onUpdateGoodInCart,
  } = props;

  // Debounce the onClickCountInCart function with a delay of, for example, 1000 milliseconds (1 second)

  LOGGING &&
    console.log("PureDishChooser rendering with:", {
      onStartMembership,
      isMember,
      isAuthenticated,
      isNotOrganizer,
    });
  const { children } = props;
  return (
    <Container screenHeight={modalify}>
      <DishVegetarianIcon dish={dish} />
      {countInCart > 0 && (
        <CountInCart onClick={onClickCountInCart}>
          <div>
            <span className="count">{countInCart}</span>
            <span className="already">already</span>
            <span className="in-cart">in cart</span>
          </div>
        </CountInCart>
      )}
      <Overlay></Overlay>
      <img src={dish.imageURL} alt={dish.name} />
      {children}
      <DishSelectionAction
        price={price}
        priceNonMember={priceNonMember}
        quantity={quantity}
        quantityCap={quantityCap}
        onChangeDishQuantity={onChangeDishQuantity}
        onAddDishToCart={onAddDishToCart}
        isValid={isValid}
        fixToBottom={modalify}
        isMember={isMember}
        isAuthenticated={isAuthenticated}
        bottom={bottom}
        isNotOrganizer={isNotOrganizer}
        onStartMembership={onStartMembership}
        goodKey={goodKey}
        onUpdateGoodInCart={onUpdateGoodInCart}
      />
    </Container>
  );
};

type ContainerProps = { screenHeight: boolean };
const Container = styled.div<ContainerProps>`
  width: 500px;
  max-width: 100%;
  box-sizing: border-box;
  font-weight: normal;
  padding-bottom: ${(props) => (props.screenHeight ? "180px" : 0)};
  min-height: ${(props) => (props.screenHeight ? "100vh" : "inherit")};
  position: relative;
  p {
    font-size: 13px;
    line-height: 18px;
  }
  img {
    padding: 0px;
    width: 100vw;
    height: 60vw;
    max-width: 500px;
    max-height: 300px;
    object-fit: cover;
  }
`;

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 60vw;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  max-width: 500px;
  max-height: 300px;
`;

const CountInCart = styled.div`
  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(60vw - 110px);
    left: 20px;
    background-color: rgba(0, 128, 0, 0.8);
    color: white;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    box-shadow: 0 0 8px 2px rgba(28, 29, 33, 0.3);
    span {
      display: inline-block;
      text-align: center;
    }
    span.count {
      font-family: "Theano Didot", serif;
      font-weight: 500;
      font-size: 24px;
      letter-spacing: 0.6px;
    }
    span.in-cart,
    span.already {
      font-family: Montserrat;
      font-size: 10px;
      font-weight: 300;
      line-height: 15px;
      letter-spacing: 0.6px;
    }
    span.already {
      margin-top: 2px;
    }
  }

  @media (min-width: ${BREAK_POINTS.xs}px) {
    bottom: 0;
  }

  @media (min-width: ${BREAK_POINTS.md}px) {
    div {
      top: calc(300px - 110px);
    }
  }
`;
