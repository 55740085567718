import './MenuLink.scss';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Close } from '.';
const MenuLink = ({ link, onCancel }) => (
  <div className="menu-link">
    <Close onClick={onCancel} />
    <span className="link-to-menu">share this menu</span>
    <span className="link">{`https://foodie.earth/meal/${link}`}</span>
    <button
      className="copy-link"
      onClick={(e) => {
        navigator.clipboard.writeText(`https://foodie.earth/meal/${link}`);
        onCancel(e);
      }}
    >
      <FontAwesomeIcon icon={faCopy} />
      <span>copy link</span>
    </button>
  </div>
);

export default MenuLink;
