import { random } from "lodash";

export const SurveyRestaurants = [
  {
    name: "Din Tai Fung",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/QzJM3TwY2Hy40S-OnYttpQ/o.jpg",
    _id: "617294325ef91245210e7628",
    reviews: 4590,
    rating: 3.5,
  },
  {
    name: "Cheesecake Factory",
    _id: "63a936a2be038100162faef3",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/23i48N3NUnb6LOCY9shGAQ/o.jpg",
    reviews: 2902,
    rating: 2.9,
  },
  {
    name: "dishdash",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/zMfYsLbY-GKTLOY2PQDSpA/o.jpg",
    _id: "5fc3dfaf8dec33602073ea1f",
    reviews: 4494,
    rating: 4.4,
  },
  {
    _id: "6585e66f37d4c200145245cf",
    name: "Wei's Fish 鱼你在一起",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/pvhF8y8GiSjDlgn1SCyumw/o.jpg",
    reviews: 127,
    rating: 4.3,
  },

  {
    name: "Mendocino Farms",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/IJwLbaOFB6kdgY0VnwzJiw/o.jpg",
    _id: "6420ecbba86bc5007fb19450",
    reviews: 715,
    rating: 4.2,
  },
  {
    _id: "642224d39850bd3159b33320",
    name: "Chicken Meets Rice",
    image:
      "https://d1xqwss0spk56d.cloudfront.net/34eeeb78-8f54-445e-a108-75176234e32b.jpg",
    reviews: 1144,
    rating: 4.5,
  },
  {
    name: "Zareen's",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/NoJou4WmxPeB3qVLmXkQyw/o.jpg",
    _id: "63b79cea4214cec2cd71bac2",
    reviews: 2127,
    rating: 4.5,
  },
  {
    _id: "660a4a4d1adb05c84af12361",
    name: "ike's sandwiches",
    image:
      "https://d1xqwss0spk56d.cloudfront.net/597ca4ed-1749-4e29-a929-726bc1e8ff8e.jpg",
    reviews: 1526,
    rating: 4,
  },
  {
    _id: "64eeedc9a24a0500141ea1be",
    name: "New Krung Thai",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/TsMDIBVlQDtU3TZwu7bWIQ/o.jpg",
    reviews: 2452,
    rating: 3.5,
  },
  {
    name: "Maggiano's Little Italy",
    image:
      "https://olo-images-live.imgix.net/6b/6bb61584cb18435fb0014d5e7c369aff.jpg?auto=format%2Ccompress&q=60&cs=tinysrgb&w=600&h=500&fit=crop&fm=png32&s=7cd5f233f30077fc3a5489585713576c",
    _id: "64deccce2dd364001406c348",
    reviews: 4290,
    rating: 3.4,
  },
  {
    _id: "65e1152929c9070015c85c6f",
    name: "Sushi Tomi",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/jjQi31R2-6I3MWSRE0LkSg/o.jpg",
    reviews: 1405,
    rating: 3.9,
  },
  {
    _id: "657e0cbd9f0d1f001436e89f",
    name: "Croissanté",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/GCnlLTqFNO7oeXpRqnj94Q/o.jpg",
    reviews: 525,
    rating: 4.0,
  },

  {
    name: "The Boiling Crab",
    image:
      "https://saltatkakaako.com/wp-content/uploads/2020/10/boiling-crab-web-gallery-2-1024x683.jpg",
    _id: "60d8fdf3784c0c001583ebee",
    reviews: 4138,
    rating: 4,
  },
  {
    _id: "6073387096b9393f7d649a0c",
    name: "Poke Bar",
    image: "https://media-cdn.grubhub.com/image/upload/pcyf3b0ny2n6tuorvjpw",
    reviews: 451,
    rating: 4.0,
  },
  {
    _id: "606dd9f931aca17290bda711",
    name: "kunjip",
    image:
      "https://d1xqwss0spk56d.cloudfront.net/26d80563-a94c-47f6-a3bf-f7f64dcb12ca.jpg",
    reviews: 1863,
    rating: 4,
  },
  {
    _id: "5fff9072ffe3eb0e042d9b81",
    name: "Chipotle Mexican Grill",
    image:
      "https://cloudfront-us-east-1.images.arcpublishing.com/dmn/OZTHIGRNZ5DMBKBG5MF5OLZ5UM.jpg",
    reviews: 428,
    rating: 2.8,
  },
  {
    _id: "61e71a698b4e05506b32cc19",
    name: "Pho Ha Noi",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/PHYv6Z4zO4ZFCDGy5Mbj1Q/o.jpg",
    reviews: 1770,
    rating: 3.8,
  },
  {
    _id: "5fb4c28dcba05a10f0396652",
    name: "falafel stop",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/yXALHUV52UjCKzfh4_Yldw/o.jpg",
    reviews: 2927,
    rating: 4.2,
  },
  {
    _id: "6624063809f49e0015c76cfd",
    name: "Alexander's Patisserie",
    image:
      "https://img.cdn4dd.com/cdn-cgi/image/fit=contain,width=1920,format=auto,quality=50/https://doordash-static.s3.amazonaws.com/media/photosV2/a624f0ee-98ba-4a05-acd8-e4032268b525-retina-large.jpg",
    reviews: 1351,
    rating: 3.9,
  },
  {
    _id: "617dff7bf1ace10016942a32",
    name: "roll house",
    image:
      "https://d1xqwss0spk56d.cloudfront.net/713fd5b9-fb26-4eb5-b030-91da49eeeaaf.jpg",
    reviews: 542,
    rating: 3.7,
  },
  {
    _id: "628bc7d27c19ee001680c8a8",
    name: "Lazy Dog Restaurant & Bar",
    reviews: 3478,
    rating: 3.9,
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/Rh59aEp57g17mnFEQAWgug/o.jpg",
  },
  // {
  //   name: "Wei's Fish 鱼你在一起",
  //   _id: '6585e66f37d4c200145245cf',
  //   image: 'https://img.cdn4dd.com/cdn-cgi/image/fit=contain,width=1366,format=auto,quality=50/https://doordash-static.s3.amazonaws.com/media/photosV2/d689860b-bbb4-4aad-ae4e-6832c150a442-retina-large.jpg',
  //   sum: 2016.5,
  //   average: 252.0625
  // },
  {
    _id: "6392601b4214cec2cd7478d6",
    name: "Galpão Gaucho Brazilian Steakhouse",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/ebrhHGri3OJmsSX4fHadZw/o.jpg",
    reviews: 1387,
    rating: 4.5,
  },
  {
    _id: "63a0ef224214cec2cda404ab",
    name: "Hui Lau Shan",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/e9kUePtiSq9BLWNDChkm7A/o.jpg",
    reviews: 1399,
    rating: 3,
  },
  {
    _id: "6151345a6cb30738027c3a83",
    name: "Ulavacharu Indian Restaurant",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/4xn_J5WGh6ss3JORv83_EA/o.jpg",
    reviews: 2204,
    rating: 4,
  },

  {
    _id: "5faba0b57e03aa0017e2a834",
    name: "Sweetgreen",
    reviews: 404,
    rating: 3.4,
    image:
      "https://images.ctfassets.net/eum7w7yri3zr/57c1vaLIB4WtuQXQblhUa5/5dff4499a0010839ff5ef682d889f9cf/PlatesLaunch-Web-Menu-Shroomami.jpg?w=600&q=75",
  },
  {
    name: "Smoking Pig BBQ",
    image:
      "https://149355992.v2.pressablecdn.com/wp-content/uploads/2015/06/Smoking-Pig-San-Jose-3.jpg",
    _id: "64768d0f82cd1a0014d143db",
    reviews: 3363,
    rating: 4,
  },
  {
    _id: "65b5a44fe5f3e8001560800d",
    name: "Corazon Mexican Food",
    rating: 4.2,
    reviews: 261,
    image:
      "https://131720287.cdn6.editmysite.com/uploads/1/3/1/7/131720287/s361966685877781841_p16_i1_w720.jpeg?dpr=1",
  },
  {
    _id: "64e278d1e45fd50014b855e2",
    name: "Darda Seafood清真一條龍",
    image:
      "https://tokyo-kitchen.icook.network/uploads/recipe/cover/397898/5d6ff1d8b34a0ea4.jpg",
    reviews: 1168,
    rating: 3.5,
  },
  {
    _id: "6292c67807cd23a3cccb36e8",
    name: "Crepevine",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/StvSYcLvbU_uhkv-df7ZVw/o.jpg",
    reviews: 1517,
    rating: 4,
  },
  {
    name: "Banana Leaf",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/hw6-tHp1aeFMqMx584VvTg/o.jpg",
    _id: "64ff660950ca9300149fd312",
    reviews: 3022,
    rating: 3.6,
  },
  {
    name: "kura revolving sushi bar",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/gQ3OoEa23eK86eEJOJUlWg/o.jpg",
    _id: "60696e354f62377ba972fa53",
    reviews: 2530,
    rating: 3.5,
  },
  {
    name: "Back A Yard Caribbean Grill",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/qCfV7BymD4KwgGBKXIFqVg/o.jpg",
    _id: "6473df12c5fe3f8e46bdb7be",
    reviews: 2530,
    rating: 4.5,
  },
  {
    name: "hironori craft ramen",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/SQUsZKjwYuI19WbLHeq51g/o.jpg",
    _id: "6157d2081fb9ed001639cd8a",
    reviews: 2423,
    rating: 4.5,
  },
  {
    _id: "6606484f919de60015fddd76",
    name: "Cicero's Pizza",
    image:
      "https://img.cdn4dd.com/cdn-cgi/image/fit=contain,width=1920,format=auto,quality=50/https://doordash-static.s3.amazonaws.com/media/photos/3b30449d-1784-4ca8-87ae-1f8fd75ac18c-retina-large.jpg",
    reviews: 1148,
    rating: 4.2,
  },
  {
    name: "Gochi Japanese Fusion Tapas",
    _id: "6507e49ce0fbe18df173dbc9",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/3iq0ZBtjC6AqXrWzfwpprw/o.jpg",
    reviews: 2224,
    rating: 4.2,
  },
  {
    _id: "627233d0711c419ad6dd2c59",
    name: "鯉魚門 Koi Palace",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/0_BuW63Vb_q1_Wn8PmaLrw/o.jpg",
    reviews: 1479,
    rating: 3,
  },
  {
    _id: "650d30cd1b2c1c0014903ba2",
    name: "Chaat Bhavan",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/hXhj02FViDW6JFYUtA21Xg/o.jpg",
    reviews: 231,
    rating: 4.7,
  },
  {
    name: "Jang Su Jang",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/tJ0XxNzpazTWjPto977hmw/o.jpg",
    _id: "64fa0fda06a16e0014bb971b",
    reviews: 2229,
    rating: 3.8,
  },
  {
    name: "Sundance The Steakhouse",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/yETyAF4t0LvETEY9M5rQ7w/o.jpg",
    _id: "62bf8b35591fcc3e5700ada7",
    reviews: 1567,
    rating: 4,
  },
  {
    _id: "620c70130406c2d74c0c55dc",
    name: "Hunan Impression",
    reviews: 487,
    rating: 3.8,
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/ggrskpg1ZUQ3EDrn2Sy8bA/o.jpg",
  },
  {
    name: "eureka!",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/fmW47uBL4bBsCG61XKYEAQ/o.jpg",
    _id: "5fb4c2b6cba05a10f0396655",
    reviews: 1409,
    rating: 3.8,
  },
  {
    name: "O2 Valley",
    _id: "6146f00c5a0f1209f5ff8b5c",
    image:
      "https://d1xqwss0spk56d.cloudfront.net/28dea52f-572a-4f86-b733-1a792e5072a2.jpg",
    reviews: 510,
    rating: 4.4,
  },
  {
    _id: "615759371fb9ed001639cd4e",
    name: "The City Fish",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/DOubFC2hBAZbeKVw0t68tw/o.jpg",
    reviews: 1089,
    rating: 4.5,
  },
  {
    name: "Pho Lover",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/qbrJE91XDrsR9TerOLScYg/o.jpg",
    _id: "62492a65d7bb9f9cce4e5cb5",
    rating: 3.9,
    reviews: 797,
  },
  {
    name: "Chick Fil A",
    image:
      "https://media-cdn.tripadvisor.com/media/photo-p/19/65/d7/a0/img-20190925-162512-693.jpg",
    _id: "660dd5191adb05c84a46194a",
    rating: 3.8,
    reviews: 675,
  },
  {
    _id: "62e01aae16001ce96ea1c85c",
    name: "Amami Shima Sushi",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/r2GXyUlICiNrfkINd3creA/o.jpg",
    reviews: 1075,
    rating: 4,
  },
  {
    _id: "62bf4bbf591fcc3e57fa5cbd",
    name: "Lanzhou Hand Pull Noodles",
    reviews: 644,
    rating: 4.0,
    image:
      "https://kuponseedstorage.blob.core.windows.net:443/bizf/CE794055-5AF1-41F2-B3B1-69CF007373D5.jpg",
  },
  {
    name: "Curry Hyuga",
    _id: "64f6231e0ab1c00014cefabd",
    reviews: 845,
    rating: 4.6,
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/3pfxIWjBLAvlSkizy3-HZg/o.jpg",
  },
  {
    _id: "618f3e1f56d1ed6484c82a85",
    name: "Speedy Tacos",
    reviews: 1019,
    ratiing: 4.4,
    image:
      "https://d1xqwss0spk56d.cloudfront.net/ba196376-4511-420f-8455-ea7325ef8417.jpg",
  },
  {
    _id: "5fa469bdb73b054fba349e90",
    name: "Chef Chu's",
    reviews: 2235,
    rating: 3.9,
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/OckG7MZ5ZV7-qhJ0IKvcMg/o.jpg",
  },
  {
    _id: "616f8a0a9002e100167197f9",
    name: "Pacific Catch",
    reviews: 1029,
    rating: 3.8,
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/DgWM2P8tF5schAmI3iNX1g/o.jpg",
  },
  {
    _id: "63a376d24214cec2cdd8ebea",
    name: "Ramen hajime",
    reviews: 432,
    rating: 4.6,
    image:
      "https://www.ramenbegins.com/uploads/1/3/4/1/134170495/s776786478107043178_p3_i3_w1000.jpeg",
  },
  {
    _id: "62201757a2b6f000161fa94e",
    name: "Sweet Maple",
    reviews: 996,
    rating: 4.2,
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/GFDCIWltoZefy2qTaeWvng/o.jpg",
  },
  {
    _id: "5fb4e680869bb215eb00678b",
    name: "Los Altos Grill",
    reviews: 1773,
    rating: 3.8,
    image:
      "https://d1xqwss0spk56d.cloudfront.net/78aca1b4-50e9-4e9f-b9d7-37054dd7e8af.jpg",
  },
  {
    _id: "653873d8b205ee0014e13958",
    name: "Sunright Tea Studio",
    reviews: 725,
    rating: 4.3,
    image:
      "https://d2s742iet3d3t1.cloudfront.net/restaurants/restaurant-67848000000000000/menu/items/8/item-5740030862189108_1695395516.jpg?size=medium",
  },
  {
    _id: "62d9ba06591fcc3e57476c0e",
    name: "Taiwan Porridge小劉清粥",
    reviews: 470,
    rating: 4.0,
    image:
      "https://d1xqwss0spk56d.cloudfront.net/648e9591-e654-4263-87b8-411fd7a5e885.jpg",
  },
  {
    _id: "60d35620a523920015fd807a",
    name: "Amber India",
    reviews: 486,
    rating: 3.5,
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/BXgnkYb4eEqxVgaZCLu4zg/o.jpg",
  },
  {
    name: "Sumiya Yakitori",
    reviews: 1447,
    rating: 3.9,
    _id: "65cc469f94d4f6001516af91",
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/iGityYbEcdHFGPZCcg7k8w/o.jpg",
  },
  {
    _id: "62e1a09e16001ce96ec5823b",
    name: "SGD Tofu House",
    reviews: 1426,
    rating: 4.0,
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/EaOjj7rH1Z-H79qvN2uoZQ/o.jpg",
  },
  {
    _id: "6228d842abc5e64da33a76ef",
    name: "Telefèric Barcelona",
    reviews: 1742,
    rating: 4.2,
    image:
      "https://d1xqwss0spk56d.cloudfront.net/38cc1761-784b-4a6d-83b4-1d41dfc41dca.jpeg",
  },
];
