import "./StopsMap.scss";
import {
  faMapMarkerAlt as faMap,
  faCarSide,
  faUser,
  faCheck,
  faDiamond,
  faGlass,
  faPhone as faMobile,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { LOGGING } from "./";
import { Centered, TextTitle, NavHeight } from "./Shared";
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100vw;
  /* margin-top: 10px; */
  padding: 0px 10px;
`;
const RestaurantContainer = styled(Centered)`
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;
const PickUpRestaurantContent = styled(Centered)`
  position: sticky;
  top: 0px;
  background-color: transparent;
  width: 100%;
  justify-content: flex-start;
  height: 50px;
  /* border-bottom: 1px solid grey; */
`;
const DriverContainer = styled(Centered)`
  flex-direction: column;
  align-items: flex-start;
  /* margin-top: 10px; */
  /* padding-left: 10px; */
  border: 1px solid #ccc;
  width: calc(100% - 20px);
  margin: 10px;
  box-shadow: 0 4px 32px 0 rgba(28, 29, 33, 0.25);
`;
const DriverOrders = styled(Centered)`
  flex-direction: column;
  width: 100%;
  /* margin: 5px 0px; */
`;
const DriverInfo = styled(Centered)`
  justify-content: flex-start;
  align-items: flex-start;
  color: blue;
  background-color: black;
  padding: 10px;
  width: 100%;
  svg {
    margin-right: 5px;
  }
`;
const Order = styled(Centered)`
  flex-direction: column;
  width: 100%;
  padding: 10px;
  &:nth-child(even) {
    background-color: #eee;
  }
`;
const CustomerName = styled(TextTitle)`
  color: green;
  font-size: 14px;
  text-align: left;
  width: 100%;

  svg {
    margin-right: 5px;
  }
`;
const CustomerItems = styled(Centered)`
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: 15px;
`;
const RestaurantName = styled(TextTitle)`
  color: red;
  svg {
    margin-right: 10px;
  }
`;
const Item = styled(Centered)`
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 8px 0px 0px 0px;
`;
const DishName = styled(TextTitle)`
  font-size: 12px;

  svg {
    margin-right: 5px;
  }
`;
const Drink = styled.span`
  color: blue;
`;
const DriverName = styled(TextTitle)`
  font-size: 14px;
  color: white;
`;
const DriverPhone = styled(TextTitle)`
  color: white;
  margin-left: 10px;
  font-size: 14px;
`;
const DriverOrderCount = styled(TextTitle)`
  color: white;
  margin-left: 10px;
  font-size: 14px;
  text-transform: lowercase;
  svg {
    margin-right: 5px;
  }
`;

const DishQuantity = styled(TextTitle)`
  font-size: 14px;
  color: red;
  text-transform: lowercase;
  font-weight: 700;
`;
const DishComment = styled(TextTitle)`
  font-size: 14px;
  color: red;
  text-transform: lowercase;
  font-weight: 700;
`;
const DishSelection = styled(TextTitle)`
  font-size: 12px;
  margin-left: 30px;
  color: grey;
  svg {
    margin-right: 5px;
    font-size: 8px;
    margin-bottom: 1px;
  }
`;
const PickUpStop = ({ order }) => {
  LOGGING && console.log("PickUpStop rendering with order:", order);
  return (
    <Order>
      <CustomerName>
        <FontAwesomeIcon icon={faUser} />
        {order.name}
      </CustomerName>
      <CustomerName>{`$${order.sum.toFixed(2)}`}</CustomerName>
      <CustomerItems>
        {order.goods
          .filter((g) => g.quantity > 0)
          .map((g) => (
            <Item>
              <DishName>
                <FontAwesomeIcon icon={faCheck} />
                <Drink>
                  {g.isDrink ? <FontAwesomeIcon icon={faGlass} /> : null}
                </Drink>
                {g.quantity > 1 ? (
                  <DishQuantity>{`${g.quantity} x `}</DishQuantity>
                ) : null}
                {`${g.name} $${(g.price * g.quantity).toFixed(2)}`}
              </DishName>
              {g.selections.map((s) => (
                <DishSelection>
                  <FontAwesomeIcon icon={faDiamond} />
                  {s}
                </DishSelection>
              ))}
              {g.comment ? (
                <DishComment>special requests: {g.comment}</DishComment>
              ) : null}
            </Item>
          ))}
      </CustomerItems>
    </Order>
  );
};
const PickUpDriver = ({ driver }) => {
  LOGGING && console.log("PickUpDriver rendering with driver:", driver);
  return (
    <DriverContainer>
      <DriverInfo>
        <DriverName>
          <FontAwesomeIcon icon={faCarSide} />
          {driver.driver.name}
        </DriverName>

        {driver?.driver?.phone ? (
          <DriverPhone>
            <FontAwesomeIcon icon={faMobile} />
            {driver.driver.phone}
          </DriverPhone>
        ) : null}
        <DriverOrderCount>
          <FontAwesomeIcon icon={faUser} />x {driver.orders.length}
        </DriverOrderCount>
      </DriverInfo>
      <DriverOrders>
        {driver.orders.map((o) => (
          <PickUpStop order={o} />
        ))}
      </DriverOrders>
    </DriverContainer>
  );
};

const PickUpRestaurant = ({ restaurant, address }) => {
  LOGGING &&
    console.log("PickUpRestaurant rendering with restaurant:", {
      restaurant,
      address,
    });
  return (
    <div>
      <div>
        <PickUpRestaurantContent>
          <RestaurantName>{restaurant}</RestaurantName>
        </PickUpRestaurantContent>
      </div>
      <div>
        <PickUpRestaurantContent>
          <RestaurantName>
            <FontAwesomeIcon icon={faMap} />
            {address}
          </RestaurantName>
        </PickUpRestaurantContent>
      </div>
    </div>
  );
};

export const PickUpStops = ({ restaurants }) => {
  LOGGING && console.log("PickUpStops got restaurants:", restaurants);

  return (
    <ContentContainer>
      {Object.keys(restaurants).map((restaurant) => {
        return (
          <RestaurantContainer>
            <PickUpRestaurant
              restaurant={restaurant}
              address={restaurants[restaurant].address}
            />
            {restaurants[restaurant].drivers.map((d) => (
              <PickUpDriver driver={d} />
            ))}
          </RestaurantContainer>
        );
      })}
    </ContentContainer>
  );
};
