import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useStore } from "react-redux";
import { DriverTaskDetails } from "src/screens/tasting_menu/components/DriverTaskDetails";
import { completeTaskForDriver } from "src/store/actions/tasks";
import { DriverOrderTask } from "./DriverOrderTask";

export const DriverMealStopTask = ({
  task,
  pickupFromDriver,
  giveToDriver,
  totalNumTasks,
  showTaskDetails,
  canCompleteTask,
}) => {
  const [orderIdsPickedUp, setOrderIdsPickedUp] = useState({});
  const [isCompleteTaskLoading, setIsCompleteTaskLoading] = useState(false);

  const allOrdersToPickup = pickupFromDriver
    ? task.ordersToPickupFromDriver
    : giveToDriver
    ? task.ordersToGiveToDriver
    : task.ordersToPickup;
  const remainingOrdersToPickup = allOrdersToPickup.filter(
    (order) => orderIdsPickedUp[order._id] == null
  );
  const ordersAlreadyPickedUp = allOrdersToPickup.filter(
    (order) => orderIdsPickedUp[order._id] != null
  );

  const pickupOrder = useCallback(
    (orderId) => {
      setOrderIdsPickedUp({ ...orderIdsPickedUp, [orderId]: true });
    },
    [orderIdsPickedUp]
  );
  const orderTaskConfirmMessageGenerator = useCallback((orderName) => {
    return `You have ${orderName}'s order?`;
  }, []);

  const dispatch = useDispatch();
  const store = useStore();
  const completeTask = useCallback(async () => {
    setIsCompleteTaskLoading(true);

    try {
      await completeTaskForDriver(task.taskId)(dispatch, store.getState);
    } catch (error) {
      setOrderIdsPickedUp({});
      if (error == null) {
        toast.error(
          "An error occurred, check your network connection and try again"
        );
      } else {
        toast.error(error.message);
      }
    }

    setIsCompleteTaskLoading(false);
  }, [dispatch, store.getState, task.taskId]);

  const remainingOrdersLength = remainingOrdersToPickup.length;
  const allOrdersLength = allOrdersToPickup.length;
  return (
    <>
      {showTaskDetails && <h3 style={{ marginTop: "12px" }}>Task Details</h3>}
      {showTaskDetails && (
        <DriverTaskDetails
          taskNum={task.taskNum}
          address={task.address}
          addressName={task.addressName}
          addressMap={task.addressMap}
          totalNumTasks={totalNumTasks}
        />
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: "18px",
        }}
      >
        {pickupFromDriver ? (
          <h3 style={{ marginBottom: "10px", marginTop: "20px", color: "red" }}>
            Pick up orders from {task.transitDrivers}
          </h3>
        ) : giveToDriver ? (
          <h3
            style={{ marginBottom: "10px", marginTop: "20px", color: "green" }}
          >
            Give orders to {task.transitDrivers}
          </h3>
        ) : (
          <h3
            style={{ marginBottom: "10px", marginTop: "20px", color: "blue" }}
          >
            Pick up orders from restaurant
          </h3>
        )}
        <h3 style={{ marginBottom: "10px", marginTop: "20px" }}>
          Orders {giveToDriver ? "Given " : "Picked Up "}
          {`(${allOrdersLength - remainingOrdersLength}/${allOrdersLength})`}
        </h3>
        {remainingOrdersLength === allOrdersLength ? (
          <span>Nothing {giveToDriver ? "given" : "picked up"} yet...</span>
        ) : (
          ordersAlreadyPickedUp.map((order) => (
            <DriverOrderTask
              key={order._id}
              orderId={order._id}
              name={order.name}
              orderItems={order.goods}
              orderHash={order.orderHash}
            />
          ))
        )}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {remainingOrdersToPickup.length > 0 && (
          <h3 style={{ marginBottom: "10px", marginTop: "20px" }}>
            Orders to {giveToDriver ? "Give" : "Pickup"}
          </h3>
        )}
        {remainingOrdersToPickup.length === 0 ? (
          !canCompleteTask ? null : isCompleteTaskLoading ? (
            <div>Loading...</div>
          ) : (
            <button className="deliver-button" onClick={completeTask}>
              <span>Complete Task</span>
            </button>
          )
        ) : (
          remainingOrdersToPickup.map((order) => (
            <DriverOrderTask
              key={order._id}
              orderId={order._id}
              name={order.name}
              orderHash={order.orderHash}
              orderItems={order.goods}
              onComplete={pickupOrder}
              onCompleteButtonLabel={giveToDriver ? "Give" : "Pickup"}
              confirmMessageGenerator={orderTaskConfirmMessageGenerator}
            />
          ))
        )}
      </div>
    </>
  );
};
