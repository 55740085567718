import { SET_STATS_TYPE } from '../actionTypes';

const statsType = (state = 'growth', action) => {
  switch (action.type) {
    case SET_STATS_TYPE:
      return action.statsType;
    default:
      return state;
  }
};

export default statsType;
