import './AccountingCalendar.scss';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/pro-light-svg-icons';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LOGGING, CommissionNames, CommissionStyleNames } from '.';
const EditRestaurantCommission = ({ restaurant, commissionType, onSave, onChange }) => {
  const commissionName = CommissionNames[commissionType];
  const commissionValue = restaurant[commissionName];
  const commissionStyle = CommissionStyleNames[commissionType];
  return (
    <div className="edit-commission">
      <span className="form-title">{`${restaurant?.name}`}</span>
      <div className="commission-type">{`${commissionStyle} commission`}</div>
      <div className={`restaurant-commission commission-value`}>
        {commissionValue.toLocaleString(undefined, { maximumFractionDigits: 0 })}%
      </div>
      <input
        defaultValue={commissionValue}
        onChange={onChange.bind(this, restaurant, commissionType)}
        name="commission"
        type="number"
        step={1}
      />
      <button onClick={onSave}>save</button>
    </div>
  );
};
const MealMenu = ({ meal, isLunch, onShowEditCommission }) => {
  const { restaurant, total } = meal;

  return (
    <div
      className={`meal-accounting ${isLunch ? 'lunch' : 'dinner'} ${restaurant.negotiationStage}`}
    >
      <span className="restaurant-name">
        {`${(restaurant && restaurant.name.split(',')[0]) || ''} `}
      </span>

      <div className="stats-section restaurant">
        <div className="stats-row weekly-total">
          <span>${total.toLocaleString(undefined, { maximumFractionDigits: 0 })}</span>
        </div>
        <div className="commission-progress">
          {[0, 1, 2].map((commissionType) => (
            <>
              <button
                className={`restaurant-commission ${CommissionStyleNames[commissionType]} ${
                  restaurant[CommissionNames[commissionType]] > 0
                    ? 'has-commission'
                    : 'no-commission'
                }`}
                onClick={onShowEditCommission.bind(this, restaurant, commissionType)}
              >
                <span>{`${restaurant[CommissionNames[commissionType]]}%`}</span>
              </button>
              <div className="connector"></div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

const WeeklySummary = ({ week, onToggleShowDetails, showDetails, summary }) => {
  LOGGING && console.log('WeeklySummary rendering with:', { week, summary });
  const { total, commissionRate, commissionRateBefore, commissionRateAfter } = summary;
  return (
    <div className="meal-wrapper weekly">
      <div className="weekly-summary">
        <div className="weekly-name">
          <span>{`${week}`}</span>
        </div>
        <div className="stats-section weekly">
          <div className="stats-row">
            <span>${total.toLocaleString(undefined, { maximumFractionDigits: 0 })}</span>
          </div>
          <div className="commission-progress">
            <span className="restaurant-commission original">{`${commissionRateBefore?.toLocaleString(
              undefined,
              {
                maximumFractionDigits: 1,
                minimumFractionDigits: 1,
              }
            )}%`}</span>
            <div className="connector"></div>
            <span className="restaurant-commission now">{`${commissionRate?.toLocaleString(
              undefined,
              {
                maximumFractionDigits: 1,
                minimumFractionDigits: 1,
              }
            )}%`}</span>
            <div className="connector"></div>
            <span className="restaurant-commission target">{`${commissionRateAfter?.toLocaleString(
              undefined,
              {
                maximumFractionDigits: 1,
                minimumFractionDigits: 1,
              }
            )}%`}</span>
          </div>
        </div>
        <button onClick={onToggleShowDetails}>
          <FontAwesomeIcon icon={showDetails ? faChevronCircleUp : faChevronCircleDown} />
        </button>
      </div>
    </div>
  );
};

const Range = ({ range, meals, onShowEditCommission }) => (
  <div className="range-group">
    <div className="range-name">{range}</div>
    {meals.map((meal) => (
      <MealMenu meal={meal} onShowEditCommission={onShowEditCommission} />
    ))}
  </div>
);
const ranges = ['$800+', '$500~$800', '$300~$500', '$300-'];
const WeeklyMenu = ({
  week,
  meals,
  onToggleShowDetails,
  showDetails,
  summary,
  onShowEditCommission,
}) => {
  LOGGING && console.log('WeeklyMenu rendering with:', { week, meals });
  return (
    <div className="calendar-row week-menu stats">
      <WeeklySummary
        week={week}
        summary={summary}
        onToggleShowDetails={onToggleShowDetails}
        showDetails={showDetails}
      />

      {/* {showDetails ? ( */}
      <div
        className={`weekly-details ${showDetails ? 'show' : 'hide'}`}
        // style={{ height: `${ranges.length * 160}px` }}
      >
        {ranges.map((range, i) => (
          <Range
            meals={meals.filter((m) => m.range === range)}
            range={range}
            onShowEditCommission={onShowEditCommission}
            key={i}
          />
        ))}
      </div>
      {/* ) : null} */}
    </div>
  );
};

const AccountingCalendar = ({
  month,
  meals,
  weeks,
  currentMonth,
  onMoveMonth,
  showDetails,
  onToggleShowDetails,
  onShowEditCommission,
}) => {
  LOGGING && console.log('AccountingCalendar rendering with: ', { month, meals, weeks });
  return (
    <div className="calendar">
      <div className="calendar-header">
        <div className="current-month">
          <button className="move-month prev-month" onClick={onMoveMonth.bind(this, -1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <span>{currentMonth}</span>
          <button className="move-month next-month" onClick={onMoveMonth.bind(this, 1)}>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>
      <div className="menu-calendar">
        {Object.keys(weeks).map((week, index) => (
          <WeeklyMenu
            week={week}
            summary={weeks[week]}
            key={index}
            showDetails={showDetails[index]}
            meals={meals.filter((m) => m.week === week)}
            onToggleShowDetails={onToggleShowDetails.bind(this, index)}
            onShowEditCommission={onShowEditCommission}
          />
        ))}
      </div>
    </div>
  );
};
export { EditRestaurantCommission, AccountingCalendar };
