import { LOGGING } from '../../constants';
import { SET_COUPON_CODES } from '../actionTypes';
import { apiCall } from '../api';

export const setCouponCodes = (couponCodes) => ({
  type: SET_COUPON_CODES,
  payload: couponCodes,
});

export const getCouponCodes = () => {
  return async (dispatch) => {
    LOGGING && console.log('getCouponCodes called');
    try {
      const couponCodes = await apiCall('get', '/couponCodes');
      dispatch(setCouponCodes(couponCodes));
    } catch (err) {
      LOGGING && console.log('getCouponCodes got err', err);
    }
  };
};

export const createCouponCode = (couponCode) => {
  return async (dispatch) => {
    LOGGING && console.log('createCouponCode called');
    try {
      await apiCall('post', '/couponCodes', couponCode);
      dispatch(getCouponCodes());
    } catch (err) {
      LOGGING && console.log('createCouponCode got err', err);
    }
  };
};

export const updateCouponCode = (id, couponCode) => {
  return async (dispatch) => {
    LOGGING && console.log('updateCouponCode called');
    try {
      await apiCall('put', `/couponCodes/${id}`, couponCode);
      dispatch(getCouponCodes());
    } catch (err) {
      LOGGING && console.log('updateCouponCode got err', err);
    }
  };
};
