import { SET_CURRENT_USER, SET_PHONE_ONLY, SET_FEATURES } from '../actionTypes';

const DEFAULT_STATE = {
  isAuthenticated: false, // hopefully be true, when logged in
  features: [],
  user: {}, // all the user info when logged in
};

const currentUser = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        // turn empty object into false or if there are keys, true.
        isAuthenticated: !!Object.keys(action.user).length,
        user: { ...action.user },
      };
    case SET_PHONE_ONLY:
      return {
        ...state,
        isAuthenticated: false,
        user: { phone: action.phone },
      };
    case SET_FEATURES:
      return {
        ...state,
        features: [...action.features],
      };
    default:
      return state;
  }
};

export default currentUser;
