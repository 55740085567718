import { ChangeEvent, useCallback, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Button } from 'src/components/Button';
import { Flex } from 'src/components/Layout';
import { Modal } from 'src/components/Modal';
import { useForm } from 'src/hooks/useForm';
import styled from 'styled-components';

type DishEditModalProps = {
  isOpen: boolean;
  dish?: any;
  onSave: (formData: FormData) => void;
  onClose: () => void;
};

type FormData = {
  _id?: string;
  name?: string;
  description?: string;
  priceFoodieListed?: string;
  imageURL?: string;
};

export const DishEditModal = (props: DishEditModalProps) => {
  const { isOpen, dish, onSave, onClose } = props;

  const { formData, setFormData, onInputChange } = useForm<FormData>();

  const onDishSave = useCallback(() => {
    // Validations
    const price = parseFloat(formData.priceFoodieListed);
    if (!isNaN(price)) {
      if (price < 0 || price > 200) {
        toast.error(
          `Invalid price $${price}. The maximum price we allow is $200. Please contact support for questions`
        );
        return;
      }
    }
    onSave(formData);
  }, [formData, onSave]);

  useEffect(() => {
    if (isOpen) {
      setFormData(dish);
    }
  }, [dish, isOpen, setFormData]);

  return (
    <Modal isOpen={isOpen} title="Dish Edit" showClose onClose={onClose}>
      <Form>
        <div className="form-row">
          <div className="form-field-wrapper">
            <Flex gap={12} align="baseline">
              <FormLabel>Name</FormLabel>
              <input
                name="name"
                value={formData.name || ''}
                onChange={onInputChange}
                placeholder="Enter Name"
              />
            </Flex>
          </div>
        </div>
        <div className="form-row">
          <div className="form-field-wrapper">
            <Flex gap={12} align="baseline">
              <FormLabel>Description</FormLabel>
              <input
                name="description"
                value={formData.description || ''}
                onChange={onInputChange}
                placeholder="Enter Description"
              />
            </Flex>
          </div>
        </div>
        <div className="form-row">
          <div className="form-field-wrapper">
            <Flex gap={12} align="baseline">
              <FormLabel>Price</FormLabel>
              <input
                name="priceFoodieListed"
                value={formData.priceFoodieListed || ''}
                onChange={onInputChange}
                placeholder="Enter Price"
              />
            </Flex>
          </div>
        </div>
        <div className="form-row">
          <div className="form-field-wrapper">
            <Flex gap={12} align="baseline">
              <FormLabel>Image</FormLabel>
              <input
                name="imageURL"
                value={formData.imageURL || ''}
                onChange={onInputChange}
                placeholder="Enter imageURL"
              />
            </Flex>
          </div>
        </div>
        <Flex justify="center">
          <StyledButton onClick={onDishSave}>Save</StyledButton>
          <StyledButton variant="secondary" onClick={onClose}>
            Cancel
          </StyledButton>
        </Flex>
      </Form>
    </Modal>
  );
};

const Form = styled.div`
  font-size: 12px;
  width: 650px;
  margin-top: 24px;

  input {
    width: 400px;
  }

  input[type='radio'] {
    width: auto;
    margin: 0;
  }
`;

const StyledButton = styled(Button)`
  margin: 24px;
`;

const FormLabel = styled.label`
  font-size: 12px;
  width: 170px;
  text-align: right;
  padding-right: 6px;
  flex-shrink: 0;
`;
