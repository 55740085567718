import { Link } from "react-router-dom";
import "./Hero2.scss";
// import { faRoute } from '@fortawesome/pro-regular-svg-icons';
// import { faRoute } from '@fortawesome/pro-duotone-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// export const Hero = ({ isAnonymous }) => (
//   <div className="hero">
//     <div className="slogan">
//       <p>Restaurant prices,</p>
//       <p>no fees,</p>
//     </div>
//     <div className="notes">when you share menu with your neighbors.</div>
//     {isAnonymous && <button>sign up</button>}
//     <div className="deco">
//       <FontAwesomeIcon icon={faRoute} />
//     </div>
//   </div>
// );

export const Hero2 = () => (
  <div className="hero2">
    <div className="overlay"></div>
    <div className="text-border">
      <div className="text">
        <div className="slogan">
          {/* <p>Discover Bay Area's best cuisines</p> */}
          <p>Discover local gems, and enjoy </p>
          {/* <p>Eat with your neighbors,</p> */}
        </div>
        <div className="notes">lowest price + free delivery.</div>
        {/* <div className="notes">carpool delivery.</div> */}
        {/* <button className="learn-more" onClick={onClick}>
          How It Works
        </button> */}
        <Link className="learn-more" to="/favorites">
          view restaurants
        </Link>
      </div>
    </div>

    {/* {isAnonymous && <button>sign up</button>} */}
  </div>
);

export default Hero2;
