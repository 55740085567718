import React, { ReactElement, useMemo } from 'react';
import { Column, useTable } from 'react-table';
import { SerializedReferral } from 'src/api/referrals';
import { sumArr } from 'src/util/array';
import styled from 'styled-components';
import { Centered, card, textTitle, text12300, Beige } from '../Shared';
import { SectionHeader, SectionNote, SectionTitle } from '../Shared';
export interface MyReferralsTableProps {
  referrals: SerializedReferral[];
}

type TableData = {
  date: string;
  referreeName: string;
  creditEarned: string;
};

const TotalCredit = styled(Centered)`
  margin-bottom: 20px;
  ${textTitle}
  font-size: 16px;
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 200px 0;
  width: calc(100% - 40px);
`;

export const CreditHistory = styled(Centered)`
  ${card}
  width: 100%;
  padding: 20px;
  ${text12300}
  margin-top:20px;
  /* cellpadding:0; cellspacing="0" */
  table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid rgb(217, 217, 217);
    tbody {
      /* border-collapse: collapse; */
      tr {
        border-collapse: collapse;
        text-transform: capitalize;
      }
      tr:nth-child(odd) {
        background-color: ${Beige};
      }
    }
  }
`;

export const MyReferralsTable: React.FC<MyReferralsTableProps> = ({
  referrals,
}: MyReferralsTableProps): ReactElement => {
  const totalCreditEarned = sumArr(referrals.map((r) => r.creditEarned));
  const tableData = useMemo<TableData[]>(() => {
    return referrals.map((r) => {
      const { creditEarned, ...restData } = r;
      return {
        creditEarned: `$${creditEarned.toFixed(2)}`,
        ...restData,
      };
    });
  }, [referrals]);

  const columns = useMemo<Column<TableData>[]>(
    () => [
      {
        Header: 'Person Referred',
        accessor: 'referreeName',
      },
      {
        Header: 'Credit Earned',
        accessor: 'creditEarned',
      },
      {
        Header: 'Date',
        accessor: 'date',
      },
    ],
    []
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: tableData,
  });

  if (tableData.length <= 0) return <CenterContainer>No past referrals...</CenterContainer>;

  return (
    <CenterContainer>
      <SectionHeader>
        <SectionTitle>My Referral History</SectionTitle>
        <SectionNote>Total Credit Earned: ${totalCreditEarned.toFixed(2)}</SectionNote>
      </SectionHeader>
      <CreditHistory>
        <table {...getTableProps()}>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: '8px',
                          textAlign: cell.column.id === 'referreeName' ? 'left' : 'right',
                          fontWeight: cell.column.id === 'referreeName' ? '500' : '300',
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </CreditHistory>
    </CenterContainer>
  );
};
