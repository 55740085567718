import { useState, useRef } from "react";
import "./DayReceipts.scss";
import {
  faChevronUp,
  faChevronDown,
  faUser,
  faEnvelope,
  faBarcode,
  faPhone,
  faClock,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import toast from "react-hot-toast";
import { Button } from "src/components/Button";
import { Flex } from "src/components/Layout";
import { Back, receiptDateFormat, PhoneFormat, LOGGING } from ".";
import { RecordMealPaymentButton } from "./admin/RecordMealPaymentButton";

const DishDetails = ({
  dish,
  price,
  taxRate,
  quantity,
  cancelQuantity,
  selections,
  onCancelItem,
}) => {
  const [cancelItemFormIsOpen, setCancelItemFormIsOpen] = useState(false);
  const cancelItemInputRef = useRef();

  const priceFinal = price || dish.priceFoodieListed || dish.price || 0;

  const selectionItemCache = {};
  dish.selections?.forEach((selection) => {
    selection.items?.forEach((selectionItem) => {
      selectionItemCache[selectionItem._id] = selectionItem;
    });
  });

  const selectionItems = [];
  selections?.forEach((selection) => {
    selection.selectedItems?.forEach((selectionItem) => {
      LOGGING &&
        console.log(
          "DishDetails looping:",
          selectionItem.item,
          selectionItemCache,
          selectionItemCache[selectionItem.item]
        );
      selectionItems.push(selectionItemCache[selectionItem.item]?.name);
    });
  });

  const maxCancelQuantity = quantity - (cancelQuantity || 0);

  const cancelItem = () => {
    const newCancelQuantity = Number(cancelItemInputRef.current?.value);
    if (
      isNaN(newCancelQuantity) ||
      newCancelQuantity <= 0 ||
      newCancelQuantity > maxCancelQuantity
    ) {
      toast.error("Invalid cancel quantity");
      return;
    }
    onCancelItem(newCancelQuantity);
  };

  LOGGING &&
    console.log("DishDetails rendering with selectionItems:", {
      selectionItemCache,
      selectionItems,
    });

  return (
    <div className="dish-details">
      <div className="field dish-name">
        <label>name</label>
        <span>{dish.name}</span>
      </div>
      {selectionItems.length > 0 && (
        <div className="field">
          <label>selections</label>
          <span>
            <Flex direction="column">
              {selectionItems.map((selectionItem, index) => (
                <div key={index}>{selectionItem}</div>
              ))}
            </Flex>
          </span>
        </div>
      )}
      <div className="field dish-price">
        <label>price</label>
        <span>${priceFinal.toFixed(2)}</span>
      </div>
      <div className="field dish-quantity">
        <label>quantity</label>
        <span>x{quantity}</span>
      </div>
      {cancelQuantity !== undefined && cancelQuantity > 0 && (
        <div className="field cancel-quantity">
          <label>cancelled quantity</label>
          <span>x{cancelQuantity}</span>
        </div>
      )}
      <div className="field dish-total">
        <label>dish total</label>
        <span>${(priceFinal * quantity * (1 + taxRate)).toFixed(2)}</span>
      </div>
      <div className="cancel-item">
        <div className="field cancel-toggle">
          <label>cancel item form</label>
          <button
            type="button"
            onClick={() => setCancelItemFormIsOpen(!cancelItemFormIsOpen)}
          >
            <FontAwesomeIcon
              icon={cancelItemFormIsOpen ? faChevronUp : faChevronDown}
            />
          </button>
        </div>
        {cancelItemFormIsOpen && (
          <div className="cancel-item-form">
            <div className="field cancel-item-quantity">
              <label>quantity</label>
              <input
                ref={cancelItemInputRef}
                defaultValue={maxCancelQuantity}
                min="1"
                max={maxCancelQuantity}
                step="1"
                type="number"
              />
            </div>
            <button onClick={cancelItem}>cancel item</button>
          </div>
        )}
      </div>
    </div>
  );
};
const OrderDetails = ({
  order,
  onRefund,
  commission,
  taxRate,
  onCancelItem,
  onCancelOrder,
}) => {
  LOGGING &&
    console.log("OrderDetails rendering with order.goods:", order.goods);
  if (order.refunds && order.refunds.length > 0) {
    LOGGING &&
      console.log("OrderDetails rendering with order.refunds:", order.refunds);
  }
  const refunds = order.refunds?.reduce((a, b) => a + b.refundAmount, 0);
  const maxRefund = order.due - (refunds || 0);
  const [refundFormIsOpen, setRefundFormIsOpen] = useState(false);
  const refundInputRef = useRef();
  const adjustedSum = order.sum - refunds;
  const commissionAmount = (commission * adjustedSum) / 100;
  const adjustedTax = (adjustedSum - commissionAmount) * taxRate;
  const refund = () => {
    const newRefund = Number(refundInputRef.current?.value);
    if (isNaN(newRefund) || newRefund <= 0 || newRefund > maxRefund) {
      toast.error("Invalid refund amount");
      return;
    }
    onRefund(order._id, newRefund);
  };

  const theoreticalSum = order.goods.reduce(
    (b, a) => a.price * (a.quantity - (a.cancelQuantity || 0)) + b,
    0
  );
  const mismatch = theoreticalSum.toFixed(2) !== order?.sum?.toFixed(2);

  LOGGING &&
    console.log("OrderDetails rendering with theoreticalSum:", theoreticalSum);

  return (
    <div className="order-details">
      <div className="customer">
        <div className="field name">
          <FontAwesomeIcon icon={faUser} />
          <span>{order.name}</span>
        </div>
        <div className="field email">
          <FontAwesomeIcon icon={faEnvelope} />
          <span>{order.email}</span>
        </div>
        <div className="field id">
          <FontAwesomeIcon icon={faBarcode} />
          <span>{order._id}</span>
        </div>
        <div className="field phone">
          <FontAwesomeIcon icon={faPhone} />
          <span>{PhoneFormat(order.phone)}</span>
        </div>
        <div className="field phone">
          <FontAwesomeIcon icon={faClock} />
          <span>{`Paid ${moment(order.payTime).format("LLLL")}`}</span>
        </div>
      </div>
      <div className="dishes">
        {Object.values(order.goods).map((g, i) => (
          <DishDetails
            dish={g.dish}
            key={i}
            price={g.price}
            taxRate={taxRate}
            quantity={g.quantity}
            cancelQuantity={g.cancelQuantity}
            selections={g.selections}
            onCancelItem={(quantity) =>
              onCancelItem(order._id, i, g.dish._id, quantity)
            }
          />
        ))}
      </div>
      <div className="order-summary">
        {mismatch && (
          <div className="field mismatch">
            <label>mismatch</label>
            <span>${(order.sum - theoreticalSum).toFixed(2)}</span>
          </div>
        )}
        {refunds !== undefined && refunds > 0 && (
          <div className="field">
            <label>refunds</label>
            <span>${refunds.toFixed(2)}</span>
          </div>
        )}
        {order.sum !== undefined && order.sum > 0 && (
          <div className="field">
            <label>order.sum</label>
            <span>${order.sum.toFixed(2)}</span>
          </div>
        )}

        {/* {order.sum !== undefined && order.sum > 0 && (
          <div className="field">
            <label>adjusted Sum</label>
            <span>${adjustedSum.toFixed(2)}</span>
          </div>
        )} */}
        {order.sum !== undefined && order.sum > 0 && (
          <div className="field">
            <label>theoretical sum</label>
            <span>${theoreticalSum}</span>
          </div>
        )}
        {commissionAmount ? (
          <div className="field">
            <label>commission</label>
            <span>-${commissionAmount.toFixed(2)}</span>
          </div>
        ) : null}
        {order.tax !== undefined && order.tax > 0 && (
          <div className="field">
            <label>tax</label>
            <span>+${adjustedTax.toFixed(2)}</span>
          </div>
        )}
        {order.sum !== undefined &&
          order.sum > 0 &&
          order.tax !== undefined &&
          order.tax > 0 && (
            <div className="field">
              <label>in-store total (we pay)</label>
              <span className="in-store-total">
                $
                {((theoreticalSum - commissionAmount) * (1 + taxRate)).toFixed(
                  2
                )}
              </span>
            </div>
          )}
      </div>
      <div className="order-summary">
        {order.sum !== undefined && order.sum > 0 && (
          <div className="field">
            <label>sum</label>
            <span>${order.sum.toFixed(2)}</span>
          </div>
        )}
        {order.tax !== undefined && order.tips > 0 && (
          <div className="field">
            <label>tax</label>
            <span>+${order.tax.toFixed(2)}</span>
          </div>
        )}
        {order.tips !== undefined && order.tips > 0 && (
          <div className="field">
            <label>tip</label>
            <span>+${order.tips.toFixed(2)}</span>
          </div>
        )}
        {order.credit !== undefined && order.credit > 0 && (
          <div className="field">
            <label>credit</label>
            <span>${order.credit.toFixed(2)}</span>
          </div>
        )}

        <div className="field order-total">
          <label>total</label>
          <span>${order.total.toFixed(2)}</span>
        </div>
        {order.discount !== undefined && order.discount > 0 && (
          <div className="field">
            <label>discount</label>
            <span>-${order.discount.toFixed(2)}</span>
          </div>
        )}
        {order.due !== order.total && (
          <div className="field">
            <label>due</label>
            <span>${order.due.toFixed(2)}</span>
          </div>
        )}
      </div>
      <div className="refunds">
        <div className="field">
          <label>refunds</label>
        </div>
        {order.refunds?.map((refund) => (
          <div className="field refund-item">
            <label>${refund.refundAmount.toFixed(2)}</label>
            <span>{`${moment(refund.refundTime).format(
              "ddd, MMM DD YYYY, hh:mm A"
            )}`}</span>
          </div>
        ))}
        <div className="field refund-toggle">
          <label>refund form</label>
          <button
            type="button"
            onClick={() => setRefundFormIsOpen(!refundFormIsOpen)}
          >
            <FontAwesomeIcon
              icon={refundFormIsOpen ? faChevronUp : faChevronDown}
            />
          </button>
        </div>
        {refundFormIsOpen && (
          <div className="refund-form">
            <div className="field refund-amount">
              <label>$</label>
              <input
                ref={refundInputRef}
                min="0.01"
                step="0.01"
                max={maxRefund}
                defaultValue={maxRefund}
                type="number"
              />
            </div>
            <button type="button" onClick={refund}>
              send refund
            </button>
          </div>
        )}
        <Flex widthFull justify="center">
          <Button onClick={() => onCancelOrder(order._id)}>Cancel Order</Button>
        </Flex>
      </div>
    </div>
  );
};
const MealDetails = ({
  meal,
  onRefund,
  onCancelItem,
  onCancelOrder,
  onToggleRefund,
  refundingOrder,
  refundAmount,
  onEditRefundAmount,
}) => {
  LOGGING && console.log("MealDetails rendering with meal:", meal);
  const commission = meal?.restaurant?.commission || 0;
  const taxRate = meal.restaurant.taxRate;
  // const mealSum = meal.orders.reduce(
  //   (prev, curr) => prev + curr.sum - curr.refunds.reduce((a, b) => a + b.refundAmount, 0),
  //   0
  // );
  const mealSum = meal.orders.reduce(
    (prev, curr) =>
      prev +
      curr.goods.reduce(
        (a, b) => a + b.price * (b.quantity - (b.cancelQuantity || 0)),
        0
      ),
    0
  );
  const mealCommission = (mealSum * commission) / 100;
  const mealTotal = mealSum - mealCommission;
  const mealTax = mealTotal * taxRate;
  const mealDue = mealTax + mealTotal;

  return (
    <div className="meal-details">
      <div className="meal-header">
        <div className="meal-type">{`${moment(meal.windowStart).format(
          "ddd, MMM DD, YYYY | "
        )} ${meal.mealType}`}</div>
        <div className="meal-restaurant">
          <label>{`${meal?.restaurant.name} (${meal.orders.length} orders, bill `}</label>
          <span>${mealDue.toFixed(2)}</span>
        </div>
        <RecordMealPaymentButton
          mealId={meal?._id}
          dueAmountByFoodie={mealDue}
          recordedPayment={meal?.payment}
          recordedPayTime={meal?.payTime}
        />
      </div>
      {meal.orders.map((order, index) => (
        <OrderDetails
          commission={commission}
          taxRate={taxRate}
          order={order}
          key={index}
          onRefund={onRefund}
          onCancelItem={onCancelItem}
          onCancelOrder={onCancelOrder}
          onToggleRefund={onToggleRefund}
          isRefunding={refundingOrder === order._id}
          refundAmount={refundAmount}
          onEditRefundAmount={onEditRefundAmount}
        />
      ))}
    </div>
  );
};
const DayReceipts = ({
  day,
  ordersByMeal,
  onClose,
  onRefund,
  onCancelItem,
  onCancelOrder,
  refundingOrder,
  onToggleRefund,
  refundAmount,
  onEditRefundAmount,
}) => {
  LOGGING && console.log("DayReceipts rendering with: ", ordersByMeal);
  return (
    <div className="list day-details">
      <div className="list-title">
        <span>{day.date.format(receiptDateFormat)}</span>
        <Back onClick={onClose} />
      </div>
      <div className="meal-wrapper">
        {ordersByMeal
          .filter((m) => m.restaurant)
          .map((meal, index) => (
            <MealDetails
              meal={meal}
              key={index}
              onRefund={onRefund}
              onCancelItem={onCancelItem}
              onCancelOrder={onCancelOrder}
              refundingOrder={refundingOrder}
              onToggleRefund={onToggleRefund}
              refundAmount={refundAmount}
              onEditRefundAmount={onEditRefundAmount}
            />
          ))}
        {/*
      <MealDetails
        meal={dinnerMeal}
        key={1}
        isLunch={false}
        onRefund={onRefund}
        onCancelItem={onCancelItem}
        onCancelOrder={onCancelOrder}
        refundingOrder={refundingOrder}
        onToggleRefund={onToggleRefund}
        refundAmount={refundAmount}
        onEditRefundAmount={onEditRefundAmount}
      /> */}
      </div>
    </div>
  );
};

export default DayReceipts;
