import { SET_IS_LUNCH } from '../actionTypes';

const LOGGING = process.env.NODE_ENV === 'development';

export const setIsLunch = (isLunch) => ({
  type: SET_IS_LUNCH,
  isLunch,
});

export const selectIsLunch = (isLunch) => {
  LOGGING && console.log('selectIsLunch called with: ', isLunch);
  return (dispatch, getState) => {
    dispatch(setIsLunch(isLunch));
  };
};
