import moment from 'moment-timezone';
import { PURGE } from 'redux-persist';
import { LOAD_POSTS, RESET_POSTS } from '../actionTypes';

const posts = (state = [], action) => {
  switch (action.type) {
    case LOAD_POSTS:
      return { payLoad: action.posts, loadedAt: moment() };
    case RESET_POSTS:
      return [];
    case PURGE:
      return [];
    default:
      return state;
  }
};

export default posts;
