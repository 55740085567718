import { SET_MILEAGE, RESET_MILEAGE } from "../actionTypes";

const DEFAULT_STATE = null;

const mileage = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_MILEAGE:
      return action.mileage;
    case RESET_MILEAGE:
      return DEFAULT_STATE;
    default:
      return state;
  }
};

export default mileage;
