import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import WebFont from "webfontloader";
// eslint-disable-next-line no-unused-vars
import _ from "./globalTypes";
import reportWebVitals from "./reportWebVitals";
import App from "./screens/App";

WebFont.load({
  google: {
    families: [
      "Montserrat:200,300,400,500,600,700",
      "Cardo:200,300,400,500,600,700",
      "Jacques Francois:200,300,400,500,600,700",
      "sans-serif",
      "Font Awesome",
      "Satisfy:200,300,400,500,600,700",
      "Noto Sans:200,300,400,500,600,700",
      "Quando: 200,300,400,500,600,700",
      "Great Vibes:200,300,400,500,600,700",
      "Crimson Text:200,300,400,500,600,700",
      "Rock Salt:200,300,400,500,600,700",
      "Theano Didot: 200,300,400,500,600,700",
    ],
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
