import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { fetchPosts, signOut, fetchBookmarks } from '../../store/actions';
import { Loading, ConfirmAlert, Footer } from '../shared';
import { LOGGING, PageSize } from './';
import { Post, Nav, QuickPostMobile } from './components';

class PageRecommendations extends Component {
  constructor(props) {
    super();
    const { posts, location } = props;
    const { state } = location;
    const forceReload = !(state && state.isFromRecommendation);
    LOGGING &&
      console.log('PageRecommendations constructed with:', {
        state,
        forceReload,
      });
    this.state = {
      loading: forceReload,
      showUserMenu: false,
      pageIndex:
        !posts || posts.length === 0 || forceReload ? 0 : Math.floor(posts.length / PageSize),
      allRead: false,
    };
    this.handleToggleUserMenu = this.handleToggleUserMenu.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.handleDeletePost = this.handleDeletePost.bind(this);
    this.handleConfirmDeletePost = this.handleConfirmDeletePost.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.pageRef = React.createRef();
  }

  handleScroll() {
    if (this.pageRef.current) {
      const { bottom } = this.pageRef.current.getBoundingClientRect();
      const { innerHeight } = window;
      const { pageIndex, allRead, loading } = this.state;
      const { currentUser } = this.props;
      if (bottom <= innerHeight && !allRead && !loading) {
        LOGGING && console.log('PageRecommendations bottom hit, reading pageIndex:', pageIndex);
        this.setState({ loading: true });
        this.props
          .fetchPosts({ userId: currentUser.user._id, pageIndex, fresh: false })
          .then((result) => {
            LOGGING && console.log('PageRecommendations fetchPosts got from backend:', result);
            this.setState({
              loading: false,
              pageIndex: pageIndex + 1,
              allRead: result.length < PageSize,
            });
          });
      }
    }
  }

  handleToggleUserMenu(e) {
    e.preventDefault();
    const { showUserMenu } = this.state;
    this.setState({ showUserMenu: !showUserMenu });
  }
  handleSignOut(e) {
    e.preventDefault();
    this.setState({ showUserMenu: false }, () => {
      this.props.signOut();
      this.props.history.push('/');
    });
  }

  handleDeletePost(postId, e) {
    e.preventDefault();
    LOGGING && console.log('handleDeletePost called with postId:', postId);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmAlert
            onConfirm={this.handleConfirmDeletePost.bind(this, postId)}
            onClose={onClose}
            actionName="delete"
          />
        );
      },
    });
  }

  handleConfirmDeletePost(postId) {
    this.setState({ loading: true }, () => {
      this.props.deletePost(postId).then(() => {
        this.setState({ loading: false });
      });
    });
  }

  componentDidMount() {
    const { currentUser } = this.props;
    const { pageIndex } = this.state;
    LOGGING &&
      console.log('PageRecommendations componentDidMount with posts:', {
        pageIndex,
      });
    window.addEventListener('scroll', this.handleScroll);
    if (pageIndex === 0) {
      this.setState({ loading: true }, () => {
        this.props
          .fetchPosts({
            userId: currentUser.user._id,
            pageIndex,
            fresh: true,
          })
          .then((result) => {
            LOGGING && console.log('PageRecommendations fetchPosts returned with', result);
            this.setState({
              loading: false,
              pageIndex: pageIndex + 1,
              allRead: result.length < PageSize,
            });
          });
      });
    }
    window.scrollTo(0, 0);
  }

  render() {
    const { loading, showUserMenu, pageIndex } = this.state;
    const { currentUser, posts, bookmarks } = this.props;
    LOGGING &&
      console.log('PageRecommendations rendering with', {
        props: this.props,
        state: this.state,
        currentUser,
      });

    if (!currentUser.isAuthenticated) return <Redirect to="/auth" />;
    const activeBookmarks = bookmarks.filter((b) => b.status > 0);
    return (
      <div className="page recommendations with-title" ref={this.pageRef}>
        <Nav
          showUserMenu={showUserMenu}
          onToggleUserMenu={this.handleToggleUserMenu}
          currentUser={currentUser}
          onSignOut={this.handleSignOut}
          isBookmark={false}
          isFeed={false}
          isRecommendation={true}
        />
        <div className="page-title">
          <span>my recommendations</span>
        </div>
        {pageIndex === 0 ? (
          <Loading />
        ) : posts.length > 0 ? (
          <div className="list-wrapper">
            {posts.map((post) => (
              <Post
                key={post._id}
                post={post}
                bookmarks={activeBookmarks.filter((b) => b.post._id === post._id)}
                createdByUser={
                  currentUser.isAuthenticated && post.user._id === currentUser.user._id
                }
                onDelete={this.handleDeletePost.bind(this, post._id)}
                showRecommenderTitle={true}
              />
            ))}
          </div>
        ) : (
          <div className="empty-list">
            <span>{`You haven't recommended anything yet.`}</span>
          </div>
        )}
        {pageIndex !== 0 && loading ? <Loading /> : null}
        <QuickPostMobile currentUser={currentUser} />
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log('PageRecommendations mapStateToProps got state:', state);
  return {
    posts: state.posts.payLoad,
    bookmarks: state.bookmarks,
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  fetchPosts,
  fetchBookmarks,
  signOut,
})(PageRecommendations);
