import { LOGGING } from "src/constants";
import { Dish } from "src/models";
import { dedupArr, groupArrayBy, nonNullable } from "./array";

export const getSortedCategoryNamesFromDishes = (dishes: Dish[]): string[] => {
  // Feel free to remove this if clause after 2/20/2023
  if (dishes.length > 0 && dishes[0]?.categories == null) {
    return dedupArr(
      dishes.map((dish) => dish.categoryName),
      (a, b) => a === b
    );
  }

  const categories = dishes
    .map((d) => d.categories)
    .flat()
    .filter(nonNullable);
  const dedupedCategories = dedupArr(categories, (a, b) => a._id === b._id);

  // Order categories by priority.
  //
  // If there is no priority, then order categories by the first dish
  // that has the category.
  // E.g If dish categories are [Appetizer, Entree, Appetizer, Dessert]
  // Then order all Appetizers first, then Entrees, then Desserts
  const sortedCategories = dedupedCategories.sort((a, b) => {
    if (a.priority === null && b.priority === null) {
      return 0;
    } else if (a.priority === null) {
      return 1;
    } else if (b.priority === null) {
      return -1;
    } else {
      return a.priority - b.priority;
    }
  });
  // LOGGING &&
  //   console.log("getSortedCategoryNamesFromDishes got: ", {
  //     dedupedCategories,
  //     sortedCategories,
  //   });

  return sortedCategories.map((category) => category.name);
};

export const getDishesByCategoryName = (
  dishes: Dish[]
): { [categoryName: string]: Dish[] } => {
  // Feel free to remove this if clause after 2/20/2023
  if (dishes.length > 0 && dishes[0]?.categories == null) {
    return groupArrayBy(
      dishes,
      (dish) => dish.categoryName,
      (dish) => dish
    );
  }

  return dishes.reduce((dict, dish) => {
    const { categoryName, name } = dish;
    dish.categories.forEach((category) => {
      if (dict[category.name] == null) {
        dict[category.name] = [];
      }

      dict[category.name].push(dish);
    });
    return dict;
  }, {} as { [categoryName: string]: Dish[] });
};
