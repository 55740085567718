import React, { ReactElement } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

interface NavbarPopUpListItemProps {
  label: string;
  icon?: IconProp;
  redirectTo?: string;
  onClick?: () => void;
}

export const NavbarPopUpListItem: React.FC<NavbarPopUpListItemProps> = ({
  label,
  icon,
  redirectTo,
  onClick,
}): ReactElement => {
  return (
    <li>
      {icon != null ? <FontAwesomeIcon icon={icon} /> : null}
      {onClick != null ? (
        <button type="button" onClick={onClick}>
          {label}
        </button>
      ) : (
        <Link to={redirectTo}>{label}</Link>
      )}
    </li>
  );
};
