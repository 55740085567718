import React, { useState } from "react";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faSearch, faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Restaurant } from "src/screens/social_network/components";
import styled from "styled-components";
import { RestaurantUpcomingMealsContainer } from "../restaurants/RestaurantUpcomingMealsContainer";
import {
  Centered,
  Grey,
  BannerWhite,
  BackgroundGrey,
  TextGrey,
  BorderGrey,
  NavHeight,
  centered,
  text12300,
  Beige,
  Text12300,
  Card,
  CenteredByColumn,
  centeredByColumn,
  CenteredByColumnLeft,
  DownwardShadow,
  textTitle,
  SubNavHeight,
} from "../Shared";

interface ContainerProps {
  isActive?: boolean;
}
export const Container = styled(DownwardShadow)<ContainerProps>`
  ${centeredByColumn}
  margin: 0px;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  z-index: 100;
  width: 100vw;
  top: 0px;
  box-sizing: content-box;
  background-color: white;
  /* border-top: 1px solid ${BorderGrey}; */
  /* padding: 10px 0px 20px 0px; */
  /* align-content: stretch; */
  /* border-top: 1px solid
    ${(props) => (props.isActive ? "transparent" : BorderGrey)}; */
  margin-bottom: ${(props) => (props.isActive ? "20px" : "0px")} ${text12300};
  @media only screen and (min-width: 500px) {
    /* flex-direction: row; */
    /* margin-bottom: 40px; */
    /* justify-content: flex-start; */
    /* width: 360px; */
    /* margin-top: 0px; */
    align-items: center;
  }
`;
const PlaceholderSearchIcon = styled(Centered)`
  position: absolute;
  top: 100px;
  left: 20px;
  height: 42px;
  width: 40px;
  /* padding-top: 20px;   */
  /* background-color: yellow; */
  color: ${TextGrey};
  svg {
    margin-top: 3px;
    margin-left: 3px;
  }
  @media only screen and (min-width: 500px) {
    left: calc(50vw - 265px);
  }
`;
interface SearchInputProps {
  isActive: boolean;
}

const SearchInput = styled.input<SearchInputProps>`
  padding: 10px; // 10px 10px 20px;
  padding-left: ${(props) => (props.isActive ? "10px" : "20px")};
  border: none;
  background-color: ${BackgroundGrey};
  ${text12300}
  font-weight: 400;
  font-size: 16px;
  align-self: stretch;
  margin: 20px;
  &::placeholder {
    ${text12300}
    color: ${TextGrey};
    font-weight: 400;
    padding-left: ${(props) => (props.isActive ? "0px" : "20px")};
    /* padding-left: 20px; */
    letter-spacing: 1px;
    font-size: 16px;
  }
  &:focus {
    outline: none;
    border-color: #007bff;
  }
  @media only screen and (min-width: 500px) {
    width: 500px;
    align-self: auto;
  }
`;

const ResultsContainer = styled.div`
  position: fixed;
  top: ${NavHeight}px;
  left: 0px;
  width: 100vw;
  padding: 10px;
  max-width: 400px;
  height: calc(100vh - ${NavHeight}px);
  overflow-y: auto;
  z-index: 100;
  background-color: ${Beige};
  border-top: 1px solid ${BorderGrey};
  padding: 0px 15px;
`;

const ResultItem = styled(Centered)`
  padding: 10px 10px 10px 30px;
  cursor: pointer;
  justify-content: flex-start;
  ${text12300}
  font-weight: 500;
  position: relative;
  &:hover {
    background-color: #f5f5f5;
  }
  position: relative;
  svg {
    width: 40px;
    position: absolute;
    left: 5px;
    top: 20px;
  }
  /* text-transform: capitalize; */
  span {
    white-space: pre;
  }
  span.highlight {
    font-weight: 300;
  }
`;

interface RestaurantContainerProps {
  // restaurant: any;
  // query: string;
}
const RestaurantContainer = styled(Centered)<RestaurantContainerProps>`
  justify-content: flex-start;
  margin: 10px 0px;
  height: 45px;
`;
interface RestaurantImageProps {
  url: string;
}
const RestaurantImage = styled.div<RestaurantImageProps>`
  background-image: url(${(props) => props.url});
  width: 35px;
  height: 35px;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  /* border: 1px solid ${BorderGrey}; */
  margin-right: 10px;
  /* box-shadow: rgba(28, 29, 33, 0.3) 0px 2px 4px 0px; */
`;
const RestaurantImageOverlay = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 15px;
`;
const RestaurantName = styled(Text12300)`
  text-transform: capitalize;
`;
const RestaurantItem = ({ restaurant }) => (
  <RestaurantContainer>
    <RestaurantImage
      url={restaurant.heroImage || restaurant.heroImages[0]}
    ></RestaurantImage>
    <RestaurantImageOverlay></RestaurantImageOverlay>
    <RestaurantName>{restaurant.name}</RestaurantName>
  </RestaurantContainer>
);
const BackButton = styled.button`
  position: fixed;
  top: 20px;
  left: 0px;
  width: 50px;
  height: calc(${NavHeight}px - 40px);
  ${centered}
  align-self: stretch;
`;
const ClearButton = styled.button`
  position: absolute;
  top: 113px;
  right: 30px;
  width: 18px;
  height: 18px;
  ${centered}
  align-self: stretch;
  border-radius: 50%;
  background-color: ${TextGrey};
  svg {
    font-size: 13px;
    color: white;
  }
  @media only screen and (min-width: 500px) {
    right: calc(50vw - 250px);
  }
`;
export const NumberOfResults = styled(Text12300)`
  /* position: absolute;
  bottom: 0px;
  left: 0px; */
  margin-left: 20px;
  padding-left: 10px;
  padding-top: 5px;
`;
const renderSuggestion = (suggestion, query) => {
  const suggestionLowerCase = suggestion.toLowerCase();
  const index = suggestionLowerCase.indexOf(query.toLowerCase());
  const beforeHighlight = suggestion.slice(0, index);
  const highlightedPart = suggestion.slice(index, index + query.length);
  const afterHighlight = suggestion.slice(index + query.length);
  console.log({ beforeHighlight, highlightedPart, afterHighlight, suggestion });
  return (
    <ResultItem>
      <FontAwesomeIcon icon={faSearch} />
      <span>{beforeHighlight}</span>
      <span className="highlight">{highlightedPart}</span>
      <span>{afterHighlight}</span>
    </ResultItem>
  );
};
const Title = styled(Centered)`
  ${textTitle}
  width: 100vw;
  height: ${NavHeight}px;
  border-bottom: 1px solid ${BorderGrey};
  position: relative;
  button {
    position: absolute;
    height: 100%;
    width: ${NavHeight}px;
    right: 0px;
    top: 0px;
    svg {
      font-size: 24px;
    }
  }
`;
export const SearchFavorites = ({
  query,
  onCloseSearch,
  onSearch,
  onClear,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = () => {
    console.log("handleInputFocus called");
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const hasQuery = query.length > 0;
  const isActive = hasQuery || isFocused;

  return (
    <Container isActive={hasQuery}>
      <Title>
        <span>Search</span>
        <button onClick={onCloseSearch}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </Title>
      {isActive ? null : ( // </BackButton> //   <FontAwesomeIcon icon={faChevronLeft} /> // <BackButton onClick={handleExitSearch}>
        <PlaceholderSearchIcon>
          <FontAwesomeIcon icon={faSearch} />
        </PlaceholderSearchIcon>
      )}
      {hasQuery && (
        <ClearButton onClick={onClear}>
          <FontAwesomeIcon icon={faTimes} />
        </ClearButton>
      )}
      <SearchInput
        type="text"
        placeholder="Type restaurant name"
        value={query}
        onChange={onSearch}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        isActive={isActive}
      />
    </Container>
  );
};

interface FavoritesContainerProps {
  mode: string;
}

export const FavoritesContainer = styled(
  CenteredByColumn
)<FavoritesContainerProps>`
  width: 100%;
  padding-top: ${(props) =>
    props.mode === "search" ? `${SubNavHeight + 20}px` : `${SubNavHeight}px`};
  position: relative;
  @media only screen and (min-width: 500px) {
    align-items: flex-start;
  }
`;
