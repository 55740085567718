import { useCallback } from 'react';
import { readDrivers } from 'src/store/actions';
import { LOAD_ADMIN_DELIVERY_V2_DRIVERS } from 'src/store/actionTypes';
import { AdminDeliveryV2ControlPanel } from './AdminDeliveryV2ControlPanel';
import { AwaitServerResponseContainer } from './AwaitServerResponseContainer';

export const AdminDeliveryV2ControlPanelContainer = ({ mealType }) => {
  const fetchDataQuery = useCallback(async (dispatch, getState) => {
    // Get all drivers
    const drivers = await readDrivers()(dispatch, getState);
    dispatch({
      type: LOAD_ADMIN_DELIVERY_V2_DRIVERS,
      drivers,
    });
  }, []);

  return (
    <AwaitServerResponseContainer awaitable={fetchDataQuery}>
      <AdminDeliveryV2ControlPanel mealType={mealType} />
    </AwaitServerResponseContainer>
  );
};
