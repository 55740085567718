// import toast from 'react-hot-toast';
import { LOGGING } from '../../constants';
import { SET_DRIVERS } from '../actionTypes';
import { apiCall } from '../api';

export const setDrivers = (drivers) => ({
  type: SET_DRIVERS,
  payload: drivers,
});

export const getDrivers = () => {
  LOGGING && console.log('getDrivers is called');
  return async (dispatch) => {
    LOGGING && console.log('getDrivers called');
    try {
      const drivers = await apiCall('get', '/drivers');
      dispatch(setDrivers(drivers));
    } catch (err) {
      LOGGING && console.log('getDrivers got err', err);
    }
  };
};

export const createDriver = ({
  firstName,
  phone,
  weeklySchedule,
  active,
  profileColor,
  driverPaypalAccount,
  notes,
}) => {
  LOGGING &&
    console.log('createDriver is called with: ', {
      firstName,
      phone,
      weeklySchedule,
      active,
      profileColor,
      driverPaypalAccount,
      notes,
    });
  return async (dispatch, getState) => {
    try {
      await apiCall(
        'post',
        `/drivers`,
        {
          firstName,
          phone,
          weeklySchedule,
          active,
          profileColor,
          driverPaypalAccount,
          notes,
        },
        getState().currentUser
      );
      dispatch(getDrivers());
    } catch (err) {
      LOGGING && console.log('createDriver got err', err);
    }
  };
};

export const updateDriver = (
  driverId,
  {
    firstName,
    phone,
    weeklySchedule,
    active,
    profileColor,
    driverPaypalAccount,
    notes,
    driverPenalties,
  }
) => {
  LOGGING &&
    console.log('updateDriver is called with: ', {
      driverId,
      firstName,
      phone,
      weeklySchedule,
      active,
      profileColor,
      driverPaypalAccount,
      notes,
      driverPenalties,
    });
  return async (dispatch, getState) => {
    try {
      await apiCall(
        'put',
        `/drivers/${driverId}`,
        {
          firstName,
          phone,
          weeklySchedule,
          active,
          profileColor,
          driverPaypalAccount,
          notes,
          driverPenalties,
        },
        getState().currentUser
      );
      dispatch(getDrivers());
    } catch (err) {
      LOGGING && console.log('createDriver got err', err);
    }
  };
};
