import { LOAD_CHECKOUTS, RESET_CHECKOUTS } from "../actionTypes";

const checkouts = (state = [], action) => {
  switch (action.type) {
    case LOAD_CHECKOUTS:
      return [...action.checkouts];
    case RESET_CHECKOUTS:
      return [];
    default:
      return state;
  }
};

export default checkouts;
