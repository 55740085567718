import { SET_REFERRAL_CODE /* CLEAN_REFERRAL_CODE */ } from '../actionTypes';
import { apiCall } from '../api';

const LOGGING = process.env.NODE_ENV === 'development';

export const setReferralCode = (referralCode) => ({
  type: SET_REFERRAL_CODE,
  referralCode,
});

export const getReferralCode = (code) => {
  LOGGING && console.log('getReferralCode called with code: ', code);
  return (dispatch, getState) => {
    return apiCall('get', `/referrals/code/${code}`, getState().currentUser)
      .then((referralCode) => {
        LOGGING && console.log('getReferralCode got from server:', referralCode);
        // dispatch(setReferralCode(referralCode));
        // if (referralCode) {
        //   return true;
        // }
        return referralCode;
      })
      .catch((err) => {
        LOGGING && console.log('getReferralCode got err', err);
      });
  };
};
