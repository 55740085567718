import React, { ReactElement } from "react";
import styled from "styled-components";
import { Back } from "../..";
import { FavoriteButton } from "../../favorites/FavoriteButton";
import { TextUPPER, textTitle, Centered, Card } from "../../Shared";

const Container = styled(Centered)`
  width: 100vw;
  height: 35vh;
  position: relative;
  padding: 20px 20px 60px 20px;
  margin-bottom: 100px;
  > button {
    color: white;
  }
  button:first-of-type {
    position: fixed;
    top: 10px;
    left: 10px;
    width: 40px;
    height: 40px;
    background-color: rgba(127, 127, 127, 0.5);
    border-radius: 50%;
  }
`;
type ImageProps = { url: string };
const Image = styled.div<ImageProps>`
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
const Overlay = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.24);
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1;
`;

const RestaurantName = styled(TextUPPER)`
  color: white;
  z-index: 2;
  text-align: center;
`;
const RestaurantContact = styled(Card)`
  width: 80%;
  height: 132px;
  position: absolute;
  top: calc(100% - 60px);
  z-index: 1;
  padding: 16px;
`;
const RestaurantAddress = styled(Centered)`
  border: 2px solid orange;
  ${textTitle}
  padding: 20px;
  text-align: center;
  align-self: stretch;
  flex-grow: 1;
  height: 100px;
  line-height: 1.4;
  font-size: 20px;
`;
interface RestaurantHeroProps {
  name: string;
  heroImage?: string;
  address?: string;
  restaurantId?: string;
  onBack?: () => void;
}

export const RestaurantHero: React.FC<RestaurantHeroProps> = ({
  name,
  heroImage,
  address,
  onBack,
  restaurantId,
}: RestaurantHeroProps): ReactElement => {
  return (
    <Container>
      <Overlay />
      <Back onClick={onBack} />
      <Image url={heroImage} />
      <FavoriteButton restaurantId={restaurantId} restaurantName={name} />
      <RestaurantName>{name}</RestaurantName>
      <RestaurantContact>
        <RestaurantAddress>{address}</RestaurantAddress>
      </RestaurantContact>
    </Container>
  );
};
