import { BREAK_POINTS } from 'src/constants';
import styled from 'styled-components';

type GridPadding = number | string;
type GridFr = number | string;
type GridGapItem = number | string;
type GridGap = GridGapItem | [GridGapItem, GridGapItem];

type GridResponsiveProps = {
  columns?: GridFr;
  rows?: GridFr;
  padding?: GridPadding;
  gap?: GridGap;
  justify?: 'start' | 'end' | 'center' | 'stretch';
  align?: 'start' | 'end' | 'center' | 'stretch';
  flow?: 'row' | 'column' | 'row dense' | 'column dense';
  areas?: string;
};

type GridProps = GridResponsiveProps & {
  widthFull?: boolean;
  heightFull?: boolean;
  xs?: GridResponsiveProps;
  sm?: GridResponsiveProps;
  md?: GridResponsiveProps;
  lg?: GridResponsiveProps;
  xl?: GridResponsiveProps;
  xxl?: GridResponsiveProps;
};

const parseGridPadding = (gridPadding: GridPadding) => {
  return typeof gridPadding === 'number' ? `${gridPadding}px` : gridPadding;
};

const parseGridFr = (gridFr: GridFr): string => {
  return typeof gridFr === 'number' ? `repeat(${gridFr}, minmax(0, 1fr))` : gridFr;
};

const parseGridItem = (gridGapItem: GridGapItem): string => {
  return typeof gridGapItem === 'number'
    ? gridGapItem > 0
      ? `${gridGapItem}px`
      : '0'
    : gridGapItem;
};

const parseGridGap = (gridGap: GridGap): string => {
  if (gridGap instanceof Array) {
    return gridGap.map(parseGridItem).join(' ');
  }
  return `${parseGridItem(gridGap)}`;
};

const parseResponsiveProps = (responsiveProps: GridResponsiveProps) => {
  const { flow, rows, columns, padding, gap, justify, align, areas } = responsiveProps;
  return `
    ${flow ? `grid-auto-flow: ${flow};` : ''}
    ${rows ? `grid-template-rows: ${parseGridFr(rows)};` : ''}
    ${columns ? `grid-template-columns: ${parseGridFr(columns)};` : ''}
    ${gap ? `gap: ${parseGridGap(gap)};` : ''}
    ${padding ? `padding: ${parseGridPadding(padding)};` : ''}
    ${justify ? `justify-items: ${justify};` : ''}
    ${align ? `align-items: ${align};` : ''}
    ${areas ? `grid-template-areas: ${areas};` : ''}
  `;
};

export const Grid = styled.div<GridProps>`
  display: grid;

  ${({ widthFull, heightFull, xs, sm, md, lg, xl, xxl, ...commonResponsiveProps }) => `
    ${widthFull ? 'width: 100%;' : ''}
    ${heightFull ? 'height: 100%;' : ''}
    ${parseResponsiveProps(commonResponsiveProps)}

    @media (min-width: ${BREAK_POINTS.xs}px) {
      ${xs ? parseResponsiveProps(xs) : ''}
    }
    @media (min-width: ${BREAK_POINTS.sm}px) {
      ${sm ? parseResponsiveProps(sm) : ''}
    }
    @media (min-width: ${BREAK_POINTS.md}px) {
      ${md ? parseResponsiveProps(md) : ''}
    }
    @media (min-width: ${BREAK_POINTS.lg}px) {
      ${lg ? parseResponsiveProps(lg) : ''}
    }
    @media (min-width: ${BREAK_POINTS.xl}px) {
      ${xl ? parseResponsiveProps(xl) : ''}
    }
    @media (min-width: ${BREAK_POINTS.xxl}px) {
      ${xxl ? parseResponsiveProps(xxl) : ''}
    }
  `};
`;
