import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { GetStatsForMeal } from "src/api/meals";
import { AwaitServerResponseContainer } from "./AwaitServerResponseContainer";
import { MealMetricSummaryCard } from "./MealMetricSummaryCard";

export const MealMetricSummaryCardContainer = ({
  showDetails,
  onToggleShowDetails,
}) => {
  const mealType = useSelector(
    (state) => state.adminDeliveryV2.filters.mealType
  );
  // Use stops instead of state.adminDeliveryV2.meals so that this updates
  // when stops are updated.
  const stops = useSelector((state) => state.adminDeliveryV2.stops);
  const [mealStats, setMealStats] = useState({});

  const fetchDataQuery = useCallback(
    async (dispatch, getState) => {
      const mealIdsToSummarize = stops
        .map((stop) => {
          if (mealType !== stop.mealType) return null;
          return stop.meal;
        })
        .filter(Boolean);

      const fetchedMealStats = await GetStatsForMeal(mealIdsToSummarize)(
        dispatch,
        getState
      );
      setMealStats(fetchedMealStats);
    },
    [stops, mealType]
  );

  return (
    <AwaitServerResponseContainer awaitable={fetchDataQuery}>
      <MealMetricSummaryCard
        {...mealStats}
        showDetails={showDetails}
        onToggleShowDetails={onToggleShowDetails}
      />
    </AwaitServerResponseContainer>
  );
};
