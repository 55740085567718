import { useCallback, useState } from 'react';
import { PopUp, Select } from '.';
import { DriverSection } from './DriverSection';

export const AdminDeliveryHeader = ({
  isLunch,
  numLunchOrders,
  numDinnerOrders,
  drivers,
  acceptedDriverIds,
  waitingDriverIds,
  expiredDriverIds,
  rejectedDriverIds,
  unAskedDriverIds,
  restaurant,
  selectedRestaurantIndex,
  restaurantIndices,
  restaurantList,
  onSelectMeal,
  onSelectRestaurant,
  onAskDriverAvailability, // TODO: Remove
}) => {
  const [showDriversAvailability, setShowDriversAvailability] = useState(false);
  const [showRestaurantOptions, setShowRestaurantOptions] = useState(false);

  const toggleShowDriversAvailability = useCallback(() => {
    setShowDriversAvailability(!showDriversAvailability);
  }, [showDriversAvailability]);
  const toggleShowRestaurantOptions = useCallback(() => {
    setShowRestaurantOptions(!showRestaurantOptions);
  }, [showRestaurantOptions]);
  const selectLunchMeal = useCallback(
    (e) => {
      onSelectMeal(true, e);
    },
    [onSelectMeal]
  );
  const selectDinnerMeal = useCallback(
    (e) => {
      onSelectMeal(false, e);
    },
    [onSelectMeal]
  );
  const selectRestaurant = useCallback(
    (restaurantIndex) => {
      onSelectRestaurant(restaurantIndex);
      toggleShowRestaurantOptions();
    },
    [onSelectRestaurant, toggleShowRestaurantOptions]
  );
  return (
    <>
      <div className="meal-select-wrapper">
        <button
          type="button"
          className={`${isLunch ? 'selected-meal' : ''}`}
          onClick={selectLunchMeal}
        >
          {`lunch (${numLunchOrders})`}
        </button>
        <button
          type="button"
          className={`${isLunch ? '' : 'selected-meal'}`}
          onClick={selectDinnerMeal}
        >
          {`dinner (${numDinnerOrders})`}
        </button>
        <button onClick={toggleShowDriversAvailability} className="ask-drivers">
          <span>ask drivers</span>
        </button>

        <Select
          selected={selectedRestaurantIndex}
          onSelect={selectRestaurant}
          options={restaurantIndices}
          optionWords={restaurantList.map((restaurant) => {
            return restaurant.name;
          })}
          showOptions={showRestaurantOptions}
          onToggleShowOptions={toggleShowRestaurantOptions}
          onHideOptions={toggleShowRestaurantOptions}
          className={'view-mode selected restaurant-list'}
        />
      </div>
      <PopUp
        isPoppedUp={showDriversAvailability}
        componentToDisplay={
          <div className="drivers-availability">
            <div className="available-drivers-title">
              <span>driver list</span>
            </div>
            <DriverSection drivers={drivers} driverIds={acceptedDriverIds} status="accepted" />
            <DriverSection drivers={drivers} driverIds={waitingDriverIds} status="waiting" />
            <DriverSection drivers={drivers} driverIds={expiredDriverIds} status="expired" />
            <DriverSection drivers={drivers} driverIds={rejectedDriverIds} status="rejected" />
            {restaurant ? (
              <DriverSection
                inviteRestaurantName={restaurant.name}
                drivers={drivers}
                driverIds={unAskedDriverIds}
                status="uninvited"
                onInvite={onAskDriverAvailability}
              />
            ) : null}
          </div>
        }
        hidePopUp={toggleShowDriversAvailability}
      />
    </>
  );
};
