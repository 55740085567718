import { LOGGING } from '../../constants';
import { apiCall } from '../api';

export const createUserGroup = (name) => {
  LOGGING && console.log('createUserGroup called');
  return (dispatch, getState) => {
    return apiCall('POST', `/userGroup/create`, {
      name: name,
    });
  };
};

export const readUserGroups = () => {
  console.log('readUserGroups called');
  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall('GET', `/userGroup/all/${currentUser.user._id}`, {}, getState().currentUser)
      .then((userGroups) => {
        LOGGING && console.log('readUserGroups got', userGroups);
        return userGroups;
      })
      .catch((err) => {
        LOGGING && console.log('readUserGroups got err', err);
        throw err;
      });
  };
};

export const readUserGroup = () => {
  console.log('readUserGroup called');
  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall('GET', `/userGroup/${currentUser.user._id}`, {}, getState().currentUser)
      .then((userGroup) => {
        LOGGING && console.log('readUserGroup got', userGroup);
        return userGroup;
      })
      .catch((err) => {
        LOGGING && console.log('readUserGroup got err', err);
        throw err;
      });
  };
};

export const changeUserGroupInfoForUser = ({ userId, userGroupId, isUserGroupAdmin }) => {
  console.log('changeUserGroupInfoForUser called', userId, userGroupId, isUserGroupAdmin);
  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall(
      'POST',
      `/userGroup/updateUser/${currentUser.user._id}`,
      {
        userId,
        userGroupId,
        isUserGroupAdmin,
      },
      getState().currentUser
    );
  };
};

export const deleteUserGroupCard = () => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING && console.log('deleteUserGroupCard called.');
    return apiCall('delete', `/userGroup/card/${currentUser.user._id}`, {}, getState().currentUser)
      .then(({ userGroup }) => {
        LOGGING &&
          console.log('deleteUserGroupCard got result from backend:', {
            userGroup,
          });
        return userGroup;
      })
      .catch((err) => {
        LOGGING && console.log('deleteUserGroupCard got err', err);
      });
  };
};

export const saveGroupSetting = (card, deliveryInfo, budget) => {
  console.log('saveGroupSetting called', card, deliveryInfo);
  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall(
      'POST',
      `/userGroup/${currentUser.user._id}`,
      { card, deliveryInfo, budget },
      getState().currentUser
    );
  };
};

export const inviteNewGroupUsers = (newGroupUserEmails) => {
  console.log('inviteNewGroupUsers called', newGroupUserEmails);
  return (dispatch, getState) => {
    const { currentUser } = getState();
    const { user } = currentUser;
    return apiCall(
      'POST',
      `/userGroup/inviteNewUsers/${user.userGroup._id}`,
      {
        newGroupUserEmails,
        inviter: `${user.firstName} ${user.lastName}`,
      },
      getState().currentUser
    );
  };
};
