import React from 'react';
import './BookmarkDetails.scss';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dish } from '.';
import { BookmarkStatus } from '../../../constants';
import { Back } from '../../shared';

const BookmarkDetails = ({ bookmark, onChangeReview, onSaveReview, onClose, onSelectStatus }) => (
  <form className="bookmark-details" onSubmit={onSaveReview}>
    <Back onClick={onClose} />
    <Dish dish={bookmark.post.dish} restaurant={bookmark.post.restaurant} />
    <div className="status-wrapper">
      <button
        className={`select-status ${bookmark.status === BookmarkStatus.LIKED ? 'selected' : ''}`}
        type="button"
        onClick={onSelectStatus.bind(this, BookmarkStatus.LIKED)}
      >
        <FontAwesomeIcon icon={faCheck} />
        liked it
      </button>
      <button
        className={`select-status ${bookmark.status === BookmarkStatus.REVIEWED ? 'selected' : ''}`}
        type="button"
        onClick={onSelectStatus.bind(this, BookmarkStatus.REVIEWED)}
      >
        <FontAwesomeIcon icon={faCheck} />
        didn't like it
      </button>
    </div>
    <textarea
      className="review-content"
      value={bookmark.review.content}
      onChange={onChangeReview}
    />

    <div className="button-wrapper">
      <button className="action-transparent" type="submit">
        <span>save review</span>
      </button>
    </div>
  </form>
);

export default BookmarkDetails;
