// Given a number, turn it into money format.
// @param num [Number]
// @return Number to two decimal points
export function moneyfy(num: number): number {
  return parseFloat(num.toFixed(2));
}

export function moneyfyStr(str: string): number {
  const parsedNum = parseFloat(str);

  if (isNaN(parsedNum)) throw new Error(`${str} is not a number`);

  return moneyfy(parsedNum);
}
