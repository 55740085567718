import { ReactElement, useCallback, useMemo, useState } from "react";
import { isMACAddress } from "class-validator";
import { Flex } from "src/components/Layout";
import { useSerializedGoodsToGoods } from "src/hooks/useHydrateGoodDishes";
import { sumArr } from "src/util/array";
import styled from "styled-components";
import { DishChooserFactoryProps } from "./DishChooserFactory";
import { GoodsContainer } from "./GoodsContainer";
import { DishContent, DishDescription, DishName } from "./NormalDishChooser";
import { PureDishChooser } from "./PureDishChooser";
import { LOGGING } from "..";

type Props = DishChooserFactoryProps;

export const GroupDishChooser = (props: Props): ReactElement => {
  const { dish, isMember, modalify, onAddGoodsToCart } = props;
  const [selectedGoods, setSelectedGoods] = useState(dish.preSelectedGoods);
  const [quantity, setQuantity] = useState(1);

  const price = useMemo(() => {
    return sumArr(selectedGoods.map((g) => g.price * g.quantity));
  }, [selectedGoods]);

  const priceNonMember = useMemo(() => {
    return sumArr(selectedGoods.map((g) => g.priceNonMember * g.quantity));
  }, [selectedGoods]);

  const changeDishQuantity = useCallback((qty: number) => {
    setQuantity(qty);
  }, []);

  const supplementedGoods = useSerializedGoodsToGoods(selectedGoods);
  const addIndividualDishesToCart = useCallback(() => {
    supplementedGoods.forEach((good) => {
      good.quantity *= quantity;
    });
    onAddGoodsToCart(supplementedGoods);
  }, [onAddGoodsToCart, quantity, supplementedGoods]);
  LOGGING && console.log("GroupDishChooser got dish:", dish);
  return (
    //@ts-ignore
    <PureDishChooser
      dish={dish}
      countInCart={0} // Intentionally 0, since group dishes technically can't be added to cart
      onClickCountInCart={() => {}} // Intentionally no-op
      modalify={modalify}
      price={price}
      priceNonMember={priceNonMember}
      quantity={quantity}
      onAddDishToCart={addIndividualDishesToCart}
      onChangeDishQuantity={changeDishQuantity}
      isValid={true}
      isMember={isMember}
      quantityCap={-1}
    >
      <DishContent paddingBottom={0}>
        <DishName>
          <Flex direction="column" gap={12}>
            <span>{dish.name}</span>
          </Flex>
        </DishName>
        <DishDescription>
          <p>{dish.description}</p>
        </DishDescription>

        <GoodsContainerDiv>
          <GroupDishDescription>
            Add all items into your cart as separate items.
          </GroupDishDescription>
          <GoodsDiv>
            <GoodsContainer
              goods={selectedGoods}
              onSelectPreSelectedGoods={setSelectedGoods}
            />
          </GoodsDiv>
        </GoodsContainerDiv>
      </DishContent>
    </PureDishChooser>
  );
};

const GroupDishDescription = styled.div`
  margin-left: 24px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
`;

const GoodsContainerDiv = styled.div`
  margin-top: 40px;
  width: 100%;
`;

const GoodsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -60px;
`;
