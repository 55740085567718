import { Fragment, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { Cell, Flex, Grid } from 'src/components/Layout';
import { Navbar } from 'src/components/Navbar';
import { Loading } from 'src/screens/shared';
import { getCredit } from 'src/store/actions';
import styled from 'styled-components';

export const Credit = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const { credit, records } = useSelector((state: any) => state.credit);

  useEffect(() => {
    dispatch(
      getCredit({
        success: () => {
          setLoading(false);
        },
      })
    );
  }, [dispatch]);

  return (
    <div className="page">
      <Navbar title="My Credit" noShadow />
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <CreditContainer>
            <Flex justify="center" align="center" widthFull heightFull>
              <h2>$ {credit}</h2>
            </Flex>
          </CreditContainer>
          <RecordsContainer>
            <Grid columns="1fr 2fr 1fr" gap={12}>
              <Label>Change</Label>
              <Label>Note</Label>
              <Label>Date</Label>
              {records.map((record: any) => (
                <Fragment key={record._id}>
                  <Cell>
                    {record.change > 0 ? '+' : ''}
                    {record.change}
                  </Cell>
                  <Cell>{record.notes}</Cell>
                  <Cell>{moment(record.createdAt).format('MM-DD')}</Cell>
                </Fragment>
              ))}
            </Grid>
          </RecordsContainer>
        </Container>
      )}
    </div>
  );
};

const Container = styled.div`
  /* padding: 20px; */
  width: 100%;
`;

const CreditContainer = styled.div`
  width: 100%;
  height: 100px;
  background-color: #e8e02b;
`;

const RecordsContainer = styled.div`
  padding: 20px;
  font-size: 13px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
`;
