import { LOGGING } from '../../constants';
import { LOAD_POSTS, RESET_POSTS } from '../actionTypes';
import { apiCall } from '../api';

export const loadPosts = (posts) => ({
  type: LOAD_POSTS,
  posts,
});

export const resetPosts = () => ({
  type: RESET_POSTS,
});

export const fetchPosts = ({ postIds, userId, restaurantId, pageIndex, fresh }) => {
  LOGGING &&
    console.log('fetchPosts: ', {
      postIds,
      userId,
      restaurantId,
      pageIndex,
      fresh,
    });
  return (dispatch, getState) => {
    const { posts } = getState();
    LOGGING && console.log('fetchPosts called with  ', { posts, fresh });
    let body = postIds ? { postIds } : userId ? { userId } : restaurantId ? { restaurantId } : {};
    if (pageIndex !== undefined) {
      body.pageIndex = pageIndex;
    }
    LOGGING && console.log('fetchPosts body: ', body);
    return apiCall('PUT', `/posts`, body)
      .then((result) => {
        LOGGING && console.log('fetchPosts got result from server', result);
        const { posts: newPosts, time4, time3 } = result;
        if (fresh) {
          dispatch(loadPosts(newPosts));
        } else {
          const updatedPosts = pageIndex === 0 ? newPosts : [...posts.payLoad, ...newPosts];
          dispatch(loadPosts(updatedPosts));
        }
        return { time4, time3 };
      })
      .catch((err) => {
        LOGGING && console.log('fetchPosts got err', err);
      });
  };
};
export const fetchPost = (postId) => {
  LOGGING && console.log('fetchPost got:', postId);
  return (dispatch, getState) => {
    return apiCall('GET', `/posts/${postId}`)
      .then((post) => {
        LOGGING && console.log('fetchPost got post', post);
        return post;
      })
      .catch((err) => {
        LOGGING && console.log('fetchPost got err', err);
      });
  };
};

export const createPost = (post) => {
  LOGGING && console.log('createPost');
  return (dispatch, getState) => {
    const { posts } = getState();
    LOGGING && console.log('createPost called with:', { posts, post });
    return apiCall('POST', '/posts', post)
      .then((createdPost) => {
        LOGGING && console.log('createPost got createdPost', createdPost);
        const updatedPosts = [{ ...createdPost, bookmarks: [] }, ...posts.payLoad];
        LOGGING && console.log('createPost got updatedPosts', updatedPosts);
        dispatch(loadPosts(updatedPosts));
      })
      .catch((err) => {
        LOGGING && console.log('createPost got err', err);
      });
  };
};

export const updatePost = ({ _id, post }) => {
  LOGGING && console.log('updatePost called with:', { _id, post });
  return (dispatch, getState) => {
    const { posts } = getState();
    return apiCall('POST', `/posts/${_id}`, post)
      .then((updatedPost) => {
        LOGGING && console.log('updatedPost:', updatedPost);
        LOGGING && console.log('original posts:', posts);
        const updatedPosts = posts.payLoad.map((p) => (p._id === _id ? updatedPost : p));
        LOGGING && console.log('updated posts:', updatedPosts);
        dispatch(loadPosts(updatedPosts));
        return updatedPost;
      })
      .catch((err) => {
        LOGGING && console.log('updatePost got err', err);
      });
  };
};

export const deletePost = (postId) => {
  LOGGING && console.log('deletePost called with postId:', postId);
  return (dispatch, getState) => {
    const { posts } = getState();
    return apiCall('DELETE', `/posts/${postId}`)
      .then((res) => {
        LOGGING && console.log('delete with result:', res);
        const updatedPosts = posts.filter((p) => p._id !== postId);
        LOGGING && console.log('updated posts:', updatedPosts);
        dispatch(loadPosts(updatedPosts));
      })
      .catch((err) => {
        LOGGING && console.log('updatePost got err', err);
      });
  };
};
