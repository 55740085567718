import { LOGGING } from 'src/constants';
import { ObjectId } from 'src/constants/types';
import { apiCall } from 'src/store/api';

export type SerializedCuisine = {
  _id: ObjectId;
  name: string;
};
export type GetCuisinesResponse = { cuisines: SerializedCuisine[] };
export const GetCuisines = async (): Promise<GetCuisinesResponse> => {
  try {
    return await apiCall('POST', `/cuisines/GetCuisines`, {});
  } catch (err) {
    LOGGING && console.log('GetCuisines got err', err);
    return { cuisines: [] };
  }
};
