import React, { ReactElement, useCallback, useState, useEffect } from "react";
import { faRocket as faExpressDinner } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { SiteHeroImage } from "src/screens/shared";
import styled, { keyframes } from "styled-components";
import {
  Centered,
  VividBlue as Backgroud,
  Neon,
  TextTitle,
  text12300,
  CenteredByColumnLeft,
  Text12300,
  CenteredByColumn,
  Yellow,
  centered,
} from "../../Shared";
export interface MembershipAnonymousProps {}

const ContentContainer = styled(Centered)`
  /* justify-content: flex-start; */
  left: 0px;
  width: 100%;
  max-width: 100vw;
  height: 400px;
  overflow-x: hidden;
  /* padding: 20px 0px 40px 0px; */
  background-color: white;
  flex-direction: column;
  position: relative;
  color: white;
  @media only screen and (min-width: 500px) {
    background-position: 0px -200px;
  }
  @media only screen and (min-width: 1000px) {
    background-position: 0px -300px;
  }
  @media only screen and (min-width: 1200px) {
    background-position: 0px -400px;
    align-items: flex-start;
    padding-left: calc(10vw + 90px);
  }
`;
const Overlay = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;
const TextContainer = styled(CenteredByColumn)`
  z-index: 1;
  align-items: flex-start;
  width: 92%;
  @media only screen and (min-width: 500px) {
    align-items: flex-start;
    width: 450px;
  }
  /* margin-right: 30px; */
  /* margin-bottom: 20px; */
`;
const FAQLink = styled.a`
  ${text12300}
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 24px;
  color: white;
  border: 1px solid white;
  padding: 10px 30px;
  margin-top: 20px;
  text-transform: uppercase;
`;
const Title = styled(CenteredByColumnLeft)`
  ${text12300}
  font-size: 28px;
  /* margin-top: 20px; */
  line-height: 1.1;
  text-transform: uppercase;
  font-weight: 400;
  width: 92%;
  text-align: left;
  letter-spacing: 0.6px;
  span {
    white-space: nowrap;
  }
  @media only screen and (min-width: 500px) {
    text-align: left;
    font-size: 32px;
    /* width: 100%; */
  }
`;
const Title2 = styled(Text12300)`
  /* font-size: 16px; */
  margin-top: 5px;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 15px;
  /* text-transform: uppercase; */
  /* text-transform: capitalize; */
`;
const Tagline = styled.span`
  ${text12300}
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  /* top: 40px;
  position: absolute; */
`;
const Logo = styled(Centered)`
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 40px;
`;
export const MembershipAnonymous: React.FC<MembershipAnonymousProps> =
  ({}: MembershipAnonymousProps): ReactElement => {
    return (
      <ContentContainer>
        <Overlay />
        <SiteHeroImage images={images} />
        <TextContainer>
          <Tagline>Carpool with foodies, and enjoy </Tagline>
          <Title>
            <span>Lower Price,</span>
            <span>Expanded Range,</span>
            <span>& free delivery.</span>
          </Title>
          <FAQLink href="/favorites">Vendor List</FAQLink>
          {/* <Title2>Lower Food Price and Larger Delivery Range</Title2>           */}
        </TextContainer>
      </ContentContainer>
    );
  };
const images = [
  // "https://images.unsplash.com/photo-1565299624946-b28f40a0ae38?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1081&q=80",
  "https://images.unsplash.com/photo-1555939594-58d7cb561ad1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80",
  "https://images.unsplash.com/photo-1563612116625-3012372fccce?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=882&q=80",
  // "https://images.unsplash.com/photo-1595424265370-3e02d3e6c10c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1626&q=80",
  "https://images.unsplash.com/photo-1637806930600-37fa8892069d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=770&q=80", //thai
  // "https://images.unsplash.com/photo-1669340781012-ae89fbac9fc3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80",
];
export const MembershipMember: React.FC<MembershipAnonymousProps> =
  ({}: MembershipAnonymousProps): ReactElement => {
    return (
      <ContentContainer>
        <Overlay />
        <SiteHeroImage images={images} />
        <TextContainer>
          <Tagline>Wecome, Member! Enjoy</Tagline>
          <Title>
            <span>Lower Price,</span>
            <span>Longer Menus,</span>
            <span>More Restaurants,</span>
            <span>& Lower Fees.</span>
          </Title>
          {/* <FAQLink href="/membership">Join now</FAQLink> */}
          {/* <Title2>Lower Food Price and Larger Delivery Range</Title2>           */}
        </TextContainer>
      </ContentContainer>
    );
  };

export const MembershipNonMember: React.FC<MembershipAnonymousProps> =
  ({}: MembershipAnonymousProps): ReactElement => {
    return (
      <ContentContainer>
        <Overlay />
        <SiteHeroImage images={images} />
        <TextContainer>
          <Tagline>Foodie Membership Program</Tagline>
          <Title>
            <span>Lower Price,</span>
            <span>Longer Menus,</span>
            <span>More Restaurants,</span>
            <span>& Lower Fees.</span>
          </Title>
          <FAQLink href="/membership">Join now</FAQLink>
          {/* <Title2>Lower Food Price and Larger Delivery Range</Title2>           */}
        </TextContainer>
      </ContentContainer>
    );
  };
