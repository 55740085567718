import './Form.scss';
const FormRecommendation = ({ restaurant, dish, comments, onSave, onEdit, error }) => (
  <form className="form-container" onSubmit={onSave}>
    {error ? <div className="form-error">{error.message}</div> : null}
    <div className="form-body">
      <div className="form-header">
        <div className="form-field">{restaurant.name}</div>
      </div>
      <label>Dish/Goods (Required)</label>
      <textarea
        placeholder="Type dish"
        value={dish}
        onChange={onEdit}
        className="form-content"
        name="dish"
      />
      <label>Personal Testimonial</label>
      <textarea
        placeholder="Type comments"
        value={comments}
        onChange={onEdit}
        className="form-content"
        name="comments"
      />
      {/* <input
        placeholder="Type comments"
        defaultValue={comments}
        onChange={onEditComments}
        className="form-field"
        name="comments"
      /> */}
    </div>
    <button type="submit">Post</button>
  </form>
);

export default FormRecommendation;
