import React from 'react';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Geosuggest from 'react-geosuggest';
import './Form.scss';
import { Link } from 'react-router-dom';
import { Avatar } from '.';

const FormPost = ({
  restaurant,
  placeId,
  dish,
  comments,
  onSave,
  onEditRestaurant,
  onResetRestaurant,
  onEditDish,
  onSelectRestaurant,
  onEditComments,
  error,
}) => (
  <form className="form-container" onSubmit={onSave}>
    <div className="form-error">{error ? error.message : null}</div>
    <div className="form-body">
      <div className="form-header">
        {placeId ? (
          <button className="reset-restaurant form-field" type="button" onClick={onResetRestaurant}>
            {restaurant}
          </button>
        ) : (
          <React.Fragment>
            <label>Local Restaurant/Shop (Required)</label>
            <Geosuggest
              name={`restaurant-${Math.random()}`}
              placeholder="Type restaurant name"
              initialValue={restaurant}
              onSuggestSelect={onSelectRestaurant}
              onChange={onEditRestaurant}
            />
          </React.Fragment>
        )}
        {/* <input
          placeholder="Type dish name"
          defaultValue={dish}
          onChange={onEditDish}
          className="form-field"
          name="dish"
        /> */}
      </div>
      <label>Dish/Goods (Required)</label>
      <textarea
        placeholder="Type dish name"
        value={dish}
        onChange={onEditDish}
        className="form-content"
        name="dish"
      />
      {/* <div className="form-header">
        <input
          placeholder="Type comments"
          defaultValue={comments}
          onChange={onEditComments}
          className="form-field"
          name="comments"
        />
      </div> */}
      <label>Personal Testimonial</label>
      <textarea
        placeholder="Type comments"
        value={comments}
        onChange={onEditComments}
        className="form-content"
        name="comments"
      />
    </div>
    <button type="submit">save</button>
  </form>
);

export const QuickPostMobile = ({ currentUser }) => (
  <Link className="quick-form-mobile" to="/post/new">
    <div className="avatar-wrapper">
      {currentUser.isAuthenticated ? (
        <Avatar user={currentUser.user} />
      ) : (
        <div className="avatar letter-only" style={{ background: 'transparent' }}>
          <FontAwesomeIcon icon={faUser} />
        </div>
      )}
    </div>
    <div className="recommend-a-restaurant">
      <span>What is your favorite local restaurant?</span>
    </div>
  </Link>
);
export default FormPost;
