import { ReactElement } from "react";
import moment from "moment-timezone";
import styled from "styled-components";
import {
  ListTitle,
  CenteredByColumnLeft,
  TextTitle,
  text12300,
  Centered,
  Text12300,
} from "./Shared";

export type UserStatsProps = {
  sortedRestaurants: [string];
  stats: any;
};

const Container = styled(CenteredByColumnLeft)`
  width: 100%;
  padding: 20px;
`;
const RestaurantName = styled(TextTitle)`
  margin-bottom: 10px;
`;
const OrderList = styled.div`
  padding-left: 10px;
`;
const RestaurantContainer = styled(CenteredByColumnLeft)`
  margin-bottom: 20px;
`;
const Order = styled(Centered)`
  justify-content: flex-start;
`;

const OrderTime = styled.div`
  ${text12300}
`;

const OrderTotal = styled.div`
  ${text12300}
  margin-left: 10px;
`;

const DeliveryTime = styled(Text12300)`
  margin-left: 5px;
`;
const MealType = styled.div`
  ${text12300}
  text-transform: capitalize;
  margin-left: 5px;
`;

export const UserStats: React.FC<UserStatsProps> = ({
  sortedRestaurants,
  stats,
}): ReactElement => {
  return (
    <Container>
      {sortedRestaurants.map((r) => (
        <RestaurantContainer>
          <RestaurantName>{`${r} (${stats[r].length} orders)`}</RestaurantName>
          <OrderList>
            {stats[r].map((o) => (
              <Order>
                <OrderTime>
                  {moment(o.window.start).format("ddd MMM DD, YYYY")}
                </OrderTime>
                <MealType>
                  {moment(o.window.start).hour() < 12
                    ? "lunch"
                    : moment(o.window.start).hour() < 17
                    ? "express dinner"
                    : "dinner"}
                </MealType>
                {o.deliveryTime ? (
                  <DeliveryTime>
                    Delivered at {moment(o.deliveryTime).format("HH:mm")}
                  </DeliveryTime>
                ) : null}
                {o.total ? (
                  <OrderTotal>Total ${o.total.toFixed(2)}</OrderTotal>
                ) : null}
                {o.tips ? (
                  <OrderTotal>Tip ${o.tips.toFixed(2)}</OrderTotal>
                ) : null}
                <OrderTotal>
                  New Service Fee ${(o?.sum * 0.035).toFixed(2)}
                </OrderTotal>
              </Order>
            ))}
          </OrderList>
        </RestaurantContainer>
      ))}
    </Container>
  );
};
