import { LOAD_TRANSIENT_MESSAGE } from '../actionTypes';

const transientMessage = (state = null, action) => {
  switch (action.type) {
    case LOAD_TRANSIENT_MESSAGE:
      return action.transientMessage;
    default:
      return state;
  }
};

export default transientMessage;
