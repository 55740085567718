import { useCallback, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { Select } from ".";

export const DEFAULT_MEAL_FILTER_ID = "all";

export const MealFilter = ({ mealMarkers }) => {
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const selectedMealFilterId = useSelector((state) => {
    return state.adminDeliveryV2.filters?.meal ?? DEFAULT_MEAL_FILTER_ID;
  });
  const meals = useSelector((state) => state.adminDeliveryV2.meals);
  const dispatch = useDispatch();

  // const isLunch = useSelector((state) => state.adminDeliveryV2.filters.isLunch);
  const mealType = useSelector(
    (state) => state.adminDeliveryV2.filters.mealType
  );
  const filteredMeals = meals.filter((meal) => meal.mealType === mealType);

  const options = useMemo(() => {
    return [
      { _id: DEFAULT_MEAL_FILTER_ID, name: "All Restaurants" },
      ...filteredMeals.map((m) => {
        return {
          _id: m._id,
          name: m.restaurant.name,
          marker: mealMarkers[m._id],
        };
      }),
    ];
  }, [filteredMeals]);
  const selectedIndex = options.findIndex(
    (opt) => opt._id === selectedMealFilterId
  );

  const toggleShowOptions = useCallback(() => {
    setShowFilterOptions(!showFilterOptions);
  }, [showFilterOptions, setShowFilterOptions]);

  const setMealFilterIdWithOptionsIndex = useCallback(
    (index) => {
      const mealId = options[index]._id;
      dispatch({ type: "ADMIN_DELIVERY_V2_SET_MEAL_FILTER", mealId: mealId });
    },
    [options, dispatch]
  );

  return (
    <Select
      selected={selectedIndex}
      onSelect={setMealFilterIdWithOptionsIndex}
      options={options.map((_opt, i) => i)}
      optionWords={options.map((opt) => opt.name)}
      showOptions={showFilterOptions}
      optionIcons={options.map((opt) => opt.marker)}
      onToggleShowOptions={toggleShowOptions}
      onHideShowOptions={toggleShowOptions}
    />
  );
};
