import { Component } from "react";
import moment from "moment-timezone";
import { confirmAlert } from "react-confirm-alert";
import toast, { Toaster } from "react-hot-toast";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { FoodieEvent, FoodieSource, TrackEvent } from "src/api/tracker";
import { LOGGING, CartDetails, ConfirmAlert, CATimeZone } from "./";
import { LiveChat } from "./components/customers/livechat/chat";
import { InviteGroupOrder } from "./components/customers/mealMenu/InviteGroupOrder";
import { SavingScreen } from "./components/customers/survey/Taste";
import {
  fetchUser,
  updateOrder,
  updateOrderOnCart,
  updateDishInCart,
  emptyCart,
  changeDishQuantityInCart,
  changeTips,
  setDishCommentInCart,
  applyCouponCode,
  checkPreorderInventoryAvailablity,
  checkBudget,
  startMembership,
  resumeMembership,
} from "../../store/actions";

class PageCart extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: true,
      isEditingTip: false,
      couponCodeError: "",
      cart: props?.cart || {},
      stickyError: false,
      showInviteGroupOrder: false,
      initialTip: null,
      submitting: false,
    };
    this.handleHome = this.handleHome.bind(this);
    this.handleChangeDishQuantity = this.handleChangeDishQuantity.bind(this);
    this.handleChangeTips = this.handleChangeTips.bind(this);
    this.handleEmptyCart = this.handleEmptyCart.bind(this);
    this.handleConfirmEmptyCart = this.handleConfirmEmptyCart.bind(this);
    this.handleEditDishComment = this.handleEditDishComment.bind(this);
    this.handleSaveDish = this.handleSaveDish.bind(this);
    this.handleShowAuth = this.handleShowAuth.bind(this);
    this.handleToggleShowJoin = this.handleToggleShowJoin.bind(this);
    this.handleApplyCouponCode = this.handleApplyCouponCode.bind(this);
    this.handleRemoveCoupon = this.handleRemoveCoupon.bind(this);
    this.handleStartMembership = this.handleStartMembership.bind(this);
    this.handleResumeMembership = this.handleResumeMembership.bind(this);
    this.handleShowInviteGroupOrder =
      this.handleShowInviteGroupOrder.bind(this);
    this.handleSubmitOrderDirectly = this.handleSubmitOrderDirectly.bind(this);
  }
  handleSubmitOrderDirectly() {
    LOGGING && console.log("PageCart handleSubmitOrderDirectly called");
    this.setState({ submitting: true });
  }

  handleShowInviteGroupOrder(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showInviteGroupOrder: true });
  }

  handleStartMembership(level1, level2, level3) {
    let page = "";
    if (level1) {
      page = page + "=>" + level1;
    }
    if (level2) {
      page = page + "=>" + level2;
    }
    if (level3) {
      page = page + "=>" + level3;
    }
    LOGGING &&
      console.log("PageCart handleStartMembership called with: ", {
        page,
        level1,
        level2,
        level3,
      });
    this.setState({ loading: true }, () => {
      this.props.startMembership(page).then(() => {
        this.setState({ loading: true }, () => {
          LOGGING &&
            console.log(
              "PageCart startMembership done with:",
              this.props,
              this.state
            );
          this.props.fetchUser().then(() => {
            this.props.updateOrder().then((cart) => {
              LOGGING &&
                console.log("PageCart updateOrder returned with cart", cart);
              const { couponCode } = cart;
              if (couponCode) {
                this.props
                  .applyCouponCode(cart, couponCode.code, "cart", false)
                  .then((updatedCart) => {
                    const { totalNotEnoughForCoupon } = updatedCart;
                    this.setState({
                      couponCodeError:
                        totalNotEnoughForCoupon > 0
                          ? `Cart needs to be $${totalNotEnoughForCoupon}+ to use ${couponCode.code}!`
                          : "",
                      stickyError: true,
                      cart: updatedCart,
                      loading: false,
                    });
                  })
                  .catch((err) => {
                    LOGGING && console.log("pagecart got err:", err);
                    this.setState({
                      couponCodeError: err.message,
                      stickyError: false,
                      cart: { ...cart, couponCode: null },
                      loading: false,
                    });
                  });
              } else {
                this.setState({
                  cart: { ...cart },
                  loading: false,
                  couponCodeError: "",
                });
              }

              window.scroll(0, 0);
              LOGGING &&
                console.log(
                  "PageCart componentdidmount final2:",
                  this.props,
                  this.state
                );
            });
          });
        });
      });
    });
  }

  handleResumeMembership() {
    this.setState({ loading: true }, () => {
      this.props.resumeMembership().then(() => {
        this.setState({ loading: true }, () => {
          LOGGING &&
            console.log(
              "PageCart handleResumeMembership:",
              this.props,
              this.state
            );
          this.props.fetchUser().then(() => {
            this.props.updateOrder().then((cart) => {
              LOGGING &&
                console.log("PageCart updateOrder returned with cart", cart);
              const { couponCode } = cart;
              if (couponCode) {
                this.props
                  .applyCouponCode(cart, couponCode.code, "cart", false)
                  .then((updatedCart) => {
                    const { totalNotEnoughForCoupon } = updatedCart;
                    this.setState({
                      couponCodeError:
                        totalNotEnoughForCoupon > 0
                          ? `Cart needs to be $${totalNotEnoughForCoupon}+ to use ${couponCode.code}!`
                          : "",
                      stickyError: true,
                      cart: updatedCart,
                      loading: false,
                    });
                  })
                  .catch((err) => {
                    LOGGING && console.log("pagecart got err:", err);
                    this.setState({
                      couponCodeError: err.message,
                      stickyError: false,
                      cart: { ...cart, couponCode: null },
                      loading: false,
                    });
                  });
              } else {
                this.setState({
                  cart: { ...cart },
                  loading: false,
                  couponCodeError: "",
                });
              }

              window.scroll(0, 0);
              LOGGING &&
                console.log(
                  "PageCart componentdidmount final3:",
                  this.props,
                  this.state
                );
            });
          });
        });
      });
    });
  }

  handleRemoveCoupon(e) {
    e.preventDefault();
    this.setState({ couponCodeError: "" });
  }

  handleShowAuth(e) {
    e?.preventDefault();
    this.props.history.push({
      pathname: "/signuponly",
      state: {
        // title: "Sign In To Continue",
        next: "/cart",
        secondary: { title: "Create an Account", next: "/signup" },
      },
    });
  }

  handleToggleShowJoin(e) {
    e?.preventDefault();
    const { showJoin } = this.state;
    this.setState({ showJoin: !showJoin });
  }

  handleConfirmEmptyCart() {
    this.props.emptyCart().then((updatedCart) => {
      LOGGING &&
        console.log("handleConfirmEmptyCart got updatedCart", updatedCart);
      this.setState({ cart: updatedCart });
    });
  }

  handleEmptyCart(e) {
    e.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmAlert
            onConfirm={this.handleConfirmEmptyCart.bind(this)}
            onClose={onClose}
            actionName="empty"
          />
        );
      },
    });
  }

  handleChangeDishQuantity(goodsKey, change, e) {
    e.preventDefault();
    LOGGING &&
      console.log("PageCart handleChangeDishQuantity: ", {
        goodsKey,
        change,
      });
    this.props
      .changeDishQuantityInCart({
        goodsKey,
        change,
      })
      .then((updatedCart) => {
        LOGGING && console.log("PageCart updatedCart2: ", updatedCart);
        const { couponCode } = updatedCart;
        if (couponCode) {
          this.props
            .applyCouponCode(updatedCart, couponCode.code, "cart", false)
            .then((updatedCart) => {
              const { totalNotEnoughForCoupon } = updatedCart;
              this.setState({
                couponCodeError:
                  totalNotEnoughForCoupon > 0
                    ? `Cart needs to be $${totalNotEnoughForCoupon}+ to use ${couponCode.code}!`
                    : "",
                cart: updatedCart,
                stickyError: true,
              });
            })
            .catch((err) => {
              this.setState({
                cart: { ...updatedCart, couponCode: null },
                couponCodeError: err.message,
                stickyError: false,
              });
            });
        } else {
          this.setState({
            couponCodeError: "",
            cart: updatedCart,
          });
        }
        this.setState({ cart: updatedCart }, () => {
          try {
            this.props.updateOrderOnCart();
          } catch (err) {
            LOGGING && console.log("updateOrderOnCart got err:", err);
            toast.error(err.message);
          }
        });
      });
  }

  handleChangeTips(tips, tipRate, tipCustom, isEditingTip = false) {
    LOGGING &&
      console.log("PageCart handleChangeTips: ", {
        tips,
        tipRate,
        tipCustom,
        isEditingTip,
      });
    this.setState({ isEditingTip });
    this.props.changeTips(tips, tipRate, tipCustom).then((updatedCart) => {
      LOGGING &&
        console.log("PageCart updatedCart: ", {
          updatedCart,
        });
      const { couponCode } = updatedCart;
      if (couponCode) {
        this.props
          .applyCouponCode(updatedCart, couponCode.code, "cart", false)
          .then((updatedCart) => {
            const { totalNotEnoughForCoupon } = updatedCart;
            this.setState({
              couponCodeError:
                totalNotEnoughForCoupon > 0
                  ? `Cart needs to be $${totalNotEnoughForCoupon}+ to use ${couponCode.code}!`
                  : "",
              cart: updatedCart,
              stickyError: true,
            });
          })
          .catch((err) => {
            this.setState({
              cart: { ...updatedCart, couponCode: null },
              couponCodeError: err.message,
              stickyError: false,
            });
          });
      } else {
        LOGGING && console.log("PageCart updatedCart2: ", updatedCart);
        this.setState({
          cart: { ...updatedCart },
        });
      }
      this.setState({ cart: updatedCart }, () => {
        try {
          this.props.updateOrderOnCart().then(() => {
            LOGGING && console.log("PageCart updatedCart3: ", updatedCart);
            this.setState({ isEditingTip: false });
          });
        } catch (err) {
          LOGGING && console.log("updateOrderOnCart got err:", err);
          toast.error(err.message);
          this.setState({ isEditingTip: false });
        }
      });
    });
  }

  handleEditDishComment(goodsKey, e) {
    const comment = e.target.value;
    LOGGING &&
      console.log("handleEditDishComment called with: ", {
        goodsKey,
        comment,
      });
    const { cart } = this.state;
    const { goods } = cart;

    const updatedCart = {
      ...cart,
      goods: { ...goods, [goodsKey]: { ...goods[goodsKey], comment } },
    };
    this.setState({ cart: updatedCart });
  }

  handleSaveDish(goodsKey, e) {
    e.preventDefault();
    const { cart } = this.state;
    const { goods } = cart;
    LOGGING &&
      console.log("handleSaveDish called with: ", {
        goodsKey,
        goods,
      });
    this.props
      .updateDishInCart({ goodsKey, dish: goods[goodsKey] })
      .then((updatedCart) => {
        this.setState({
          cart: updatedCart,
        });
      });
  }

  async handleApplyCouponCode(code) {
    const { cart } = this.state;
    this.props
      .applyCouponCode(cart, code, "cart", true)
      .then((updatedCart) => {
        const { totalNotEnoughForCoupon } = updatedCart;
        this.setState({
          couponCodeError:
            totalNotEnoughForCoupon > 0
              ? `Cart needs to be $${totalNotEnoughForCoupon}+ to use ${code}!`
              : "",
          cart: updatedCart,
          stickyError: true,
        });
      })
      .catch((err) => {
        const { message } = err;
        this.setState({
          cart: { ...cart, couponCode: null },
          couponCodeError: message,
        });
      });
  }

  handleHome() {
    LOGGING && console.log("PageCart handleHome with meal:", this.props);
    // const { windowStart } = meal;
    if (this.props?.location?.state?.treatPath) {
      this.props?.history?.push({
        pathname: this.props?.location?.state?.treatPath,
        state: { fromCart: true },
      });
    } else {
      this.props.history.goBack();
    }
  }

  trackPageFinalizeCartEvent(endAt, startAt) {
    const uuid = `${FoodieEvent.PAGE_CART}${startAt}`;
    TrackEvent(
      FoodieEvent.PAGE_CART,
      uuid,
      FoodieSource.UNKNOWN,
      this.props.currentUser?.user?._id,
      this.props.cart?.meal?._id,
      endAt > startAt ? endAt - startAt : -1
    );
  }

  componentDidMount() {
    const startAt = moment().valueOf();
    this.trackPageFinalizeCartEvent(-1, startAt);

    window.scrollTo(0, 0);
    // Empty cart case
    if (Object.values(this.props.cart.goods ?? {}).length === 0) {
      this.setState({ cart: this.props.cart, loading: false });
      return;
    }
    const { cart } = this.props;
    LOGGING &&
      console.log(
        "PageCart componentdidmount initial:",
        this.props,
        this.state
      );
    this.setState({ initializing: true }, () => {
      this.props
        .updateOrderOnCart()
        .then((updatedCart) => {
          this.setState({
            cart: updatedCart,
            initializing: false,
            initialTip: updatedCart?.tips,
          });
        })
        .catch((err) => {
          LOGGING && console.log("updateOrderOnCart got err:", err);
          toast.error(err.message);
        });
    });

    const { couponCode } = cart;
    if (couponCode) {
      this.props
        .applyCouponCode(
          cart,
          couponCode?.code ? couponCode.code : couponCode,
          "cart",
          false
        )
        .then((updatedCart) => {
          const { totalNotEnoughForCoupon } = updatedCart;
          this.setState({
            couponCodeError:
              totalNotEnoughForCoupon > 0
                ? `Cart needs to be $${totalNotEnoughForCoupon}+ to use ${couponCode.code}!`
                : "",
            stickyError: true,
            cart: updatedCart,
            loading: false,
          });
          this.trackPageFinalizeCartEvent(moment().valueOf(), startAt);
        })
        .catch((err) => {
          LOGGING && console.log("pagecart got err:", err);
          this.setState({
            couponCodeError: err.message,
            stickyError: false,
            cart: { ...cart, couponCode: null },
            loading: false,
          });
        });
    } else {
      this.setState({
        cart: { ...cart },
        loading: false,
        couponCodeError: "",
      });
      this.trackPageFinalizeCartEvent(moment().valueOf(), startAt);
    }

    LOGGING &&
      console.log("PageCart componentdidmount final1:", this.props, this.state);
  }

  render() {
    LOGGING &&
      console.log("PageCart rendering with", {
        props: this.props,
        state: this.state,
        newUserOnly: this.props?.location?.state?.newUserOnly,
      });
    const { currentUser, isLunch } = this.props;
    const { isAuthenticated, user } = currentUser;
    const {
      loading,
      cart,
      couponCodeError,
      stickyError,
      showJoin,
      showInviteGroupOrder,
      initialTip,
      initializing,
      isEditingTip,
      submitting,
    } = this.state;
    const isGroupOrder = cart?.groupOrderType > -1;

    if (!currentUser?.isAuthenticated || !currentUser?.user) {
      return (
        <Redirect
          to={{
            pathname: "/auth",
            // his.props.cart?.couponCode
            //   ? `/referrals/${this.props.cart.couponCode}`
            //   : `/login`,
            state: {
              title: "To See Cart",
              next: this.props.location.pathname,
              newUserOnly: this.props?.location?.state?.newUserOnly,
              inviteCode: this.props?.cart?.couponCode,
              inviter: this.props?.location?.state?.referrer,
              treatPath: this.props?.location?.state?.treatPath,
            },
          }}
        />
      );
    }

    const mealHour = moment(cart?.window?.start).tz(CATimeZone).hour();
    const mealType =
      mealHour < 12 ? "lunch" : mealHour < 17 ? "earlyDinner" : "dinner";
    const cutOffBeforeDelivery =
      mealType === "lunch" ? 60 : mealType === "earlyDinner" ? 30 : 90;
    const orderBy = moment(cart?.window?.start)
      .subtract(cutOffBeforeDelivery, "minutes")
      .add(5, "minute")
      .valueOf();

    return (
      <div className="page">
        <Toaster />
        {submitting ? (
          <SavingScreen isFullScreen={true} message="submitting..." />
        ) : null}
        <CartDetails
          onDirectOrder={this.handleSubmitOrderDirectly}
          cart={cart}
          orderBy={orderBy}
          initialTip={initialTip}
          initializing={initializing}
          referralCode={cart?.couponCode ? cart.couponCode : null}
          couponCodeError={couponCodeError}
          isAuthenticated={isAuthenticated}
          isLunch={isLunch}
          loading={loading}
          onChangeDishQuantity={this.handleChangeDishQuantity}
          onChangeTips={this.handleChangeTips}
          onEditDishComment={this.handleEditDishComment}
          onEmptyCart={this.handleEmptyCart}
          onHome={this.handleHome}
          onSaveDish={this.handleSaveDish}
          onShowAuth={this.handleShowAuth}
          onToggleShowJoin={this.handleToggleShowJoin}
          showJoin={showJoin}
          onApplyCouponCode={this.handleApplyCouponCode}
          onRemoveCoupon={this.handleRemoveCoupon}
          user={user}
          stickyError={stickyError}
          isMember={
            cart?.isMember || cart?.promo?.isActive
              ? true
              : isGroupOrder
              ? cart?.user?.membership?.status === "active" ||
                cart?.user?.membership?.status === "trialing"
              : user?.membership?.status === "active" ||
                user?.membership?.status === "trialing"
          }
          wasMember={currentUser?.user?.membership?.canceled_at ? true : false}
          onStartMembership={this.handleStartMembership.bind(this, "PageCart")}
          onResumeMembership={this.handleResumeMembership}
          isGroupOrder={isGroupOrder}
          onOpenInvite={this.handleShowInviteGroupOrder}
          isEditingTip={isEditingTip}
        />
        {isGroupOrder ? (
          <InviteGroupOrder
            onClose={this.handleHideInviteGroupOrder}
            show={showInviteGroupOrder}
            groupOrderId={cart?._id}
            restaurantName={cart?.meal?.restaurant?.name}
            myName={`${user?.firstName} ${user?.lastName}`}
            organizerName={`${cart?.user?.firstName} ${cart?.user?.lastName}`}
          />
        ) : null}
        {/* <LiveChat /> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log("PageCart got redux state:", state);
  return {
    cart: state.cart.payLoad,
    couponCode: state.couponCode,
    currentUser: state.currentUser,
    isLunch: state.isLunch.payLoad,
    supportLunchEarlyDelivery: state.supportLunchEarlyDelivery,
    // referralCode: state.referralCode.payLoad,
  };
}

export default connect(mapStateToProps, {
  fetchUser,
  updateOrder,
  updateOrderOnCart,
  emptyCart,
  updateDishInCart,
  changeDishQuantityInCart,
  changeTips,
  setDishCommentInCart,
  checkPreorderInventoryAvailablity,
  applyCouponCode,
  checkBudget,
  startMembership,
  resumeMembership,
})(PageCart);
