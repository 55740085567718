import React, { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { SearchUsers } from "./components/admin/SearchUsers";
import { CenteredByColumnLeft } from "./components/Shared";
interface PageUserTasteProps {}
const Container = styled(CenteredByColumnLeft)`
  position: fixed;
  justify-content: flex-start;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  overflow-y: hidden;
`;
export const PageUserTasteSearch: React.FC<PageUserTasteProps> =
  (): ReactElement => {
    const history = useHistory();
    const onSelectUser = (userId: string) => {
      history.push(`/tastes/${userId}`);
    };

    return (
      <Container>
        <SearchUsers onSelect={onSelectUser} />
      </Container>
    );
  };
