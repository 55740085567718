import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { createBookmark, fetchPost, createPost } from '../../store/actions';
import { Back, Loading, LOGGING } from './';
import { FormRecommendation } from './components';

class PageRecommendation extends Component {
  constructor(props) {
    super();
    this.state = {
      comments: '',
      loading: true,
      post: {},
      error: null,
    };
    this.handleSaveRecommendation = this.handleSaveRecommendation.bind(this);
    this.handleCancelRecommendation = this.handleCancelRecommendation.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }

  handleSaveRecommendation(e) {
    e.preventDefault();
    const { comments, dish, post } = this.state;
    const { currentUser } = this.props;
    const { postId } = this.props.match.params;
    LOGGING &&
      console.log('handleSaveRecommendation called with:', {
        comments,
        postId,
        currentUser,
        dish,
      });
    if (dish.trim().length > 0) {
      this.props
        .createPost({
          placeId: post.restaurant.googlePlaceId,
          restaurantName: post.restaurant.name,
          dish,
          comment: comments,
          userId: currentUser.user._id,
        })
        .then((result) => {
          LOGGING && console.log('PageRecommendation createPost got result:', result);
          // this.setState({ loading: false }, () => {
          this.props.history.goBack();
          // });
        });
    } else {
      this.setState({ error: { message: "dish can't be empty" } });
    }
  }

  handleCancelRecommendation(e) {
    e.preventDefault();
    LOGGING && console.log('handleCancelRecommendation called');
    this.props.history.goBack();
  }

  handleEdit(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  componentDidMount() {
    LOGGING && console.log('PageRecommendation componentDidMount with:', this.props);
    const { postId } = this.props.match.params;
    LOGGING && console.log('PageRecommendation componentDidMount with postId:', postId);
    if (postId !== 'new') {
      this.props.fetchPost(postId).then((post) => {
        LOGGING && console.log('PageRecommendation got post:', post);
        this.setState({
          post,
          dish: '',
          loading: false,
        });
      });
    }
  }

  render() {
    const { currentUser } = this.props;
    const { post, comments, loading, dish, error } = this.state;

    LOGGING &&
      console.log('PageRecommendation got', {
        state: this.state,
        props: this.props,
      });

    if (!currentUser.isAuthenticated) return <Redirect to="/auth" />;

    return (
      <div className="page new-entity">
        <Back onClick={this.handleCancelRecommendation} />

        {loading ? (
          <Loading />
        ) : (
          <FormRecommendation
            dish={dish}
            restaurant={post.restaurant}
            comments={comments}
            onSave={this.handleSaveRecommendation}
            onEdit={this.handleEdit}
            error={error}
          />
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  createBookmark,
  fetchPost,
  createPost,
})(PageRecommendation);
