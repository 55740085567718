import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getTasksForRoute } from 'src/store/actions/tasks';
import { AwaitServerResponseContainer } from './AwaitServerResponseContainer';
import { RouteInProgress } from './RouteInProgress';

export const RouteInProgressScreen = () => {
  const route = useSelector((state) => state.routeV2);
  const fetchDataQuery = useCallback(
    async (dispatch, getState) => {
      await getTasksForRoute(route._id)(dispatch, getState);
    },
    [route._id]
  );

  return (
    <AwaitServerResponseContainer awaitable={fetchDataQuery}>
      <RouteInProgress />
    </AwaitServerResponseContainer>
  );
};
