import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signUp, updateUser } from '../../store/actions';
import { Back, PopUp, LOGGING } from './';
import { NewUser, FormEnterPassword } from './components';

class PageSetting extends Component {
  constructor(props) {
    super();

    const { currentUser } = props;

    this.state = {
      email: currentUser.user.email.toLowerCase(),
      firstName: currentUser.user.firstName,
      lastName: currentUser.user.lastName,
      password: '',
      error: null,
      showPasswordForSave: false,
    };

    this.handleToggleShowPasswordForSave = this.handleToggleShowPasswordForSave.bind(this);
    this.handleSaveConfirm = this.handleSaveConfirm.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }
  handleToggleChangePassword(e) {
    e.preventDefault();
    const { changePassword } = this.state;
    this.setState({ changePassword: !changePassword });
  }
  handleCancel(e) {
    e.preventDefault();
    LOGGING && console.log('handleCancel called');
    this.props.history.goBack();
  }

  handleToggleShowPasswordForSave(e) {
    e.preventDefault();
    const { showPasswordForSave } = this.state;
    this.setState({ showPasswordForSave: !showPasswordForSave });
  }

  handleSaveConfirm(e) {
    e.preventDefault();
    const { currentUser } = this.props;
    const { email, firstName, lastName, password } = this.state;
    const { user } = currentUser;

    LOGGING &&
      console.log('handleSaveConfirm got :', {
        user,
        email,
        firstName,
        lastName,
      });
    this.props
      .updateUser(user._id, {
        email: email.toLowerCase(),
        firstName,
        lastName,
        password,
      })
      .then(() => {
        this.props.history.push('/');
      })
      .catch((error) => {
        LOGGING && console.log('updateUser got err from backend:', error);
        this.setState({ error });
      });
  }

  handleEdit(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { firstName, lastName, password, error, email, showPasswordForSave } = this.state;
    const { currentUser } = this.props;
    LOGGING &&
      console.log('PageSignUpWithEmail got', {
        state: this.state,
      });

    return (
      <div className="page new-entity">
        <Back onClick={this.handleCancel} />
        <NewUser
          email={email}
          firstName={firstName}
          lastName={lastName}
          password={password}
          error={error}
          onSave={this.handleToggleShowPasswordForSave}
          onEdit={this.handleEdit}
          isChangingOldUser={currentUser.isAuthenticated}
        />
        <PopUp
          isPoppedUp={showPasswordForSave}
          componentToDisplay={
            <FormEnterPassword
              password={password}
              onSubmit={this.handleSaveConfirm}
              onChange={this.handleEdit}
            />
          }
          hidePopUp={this.handleToggleShowPasswordForSave}
          // backgroundColor="transparent"
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, { signUp, updateUser }, null, {
  forwardRef: true,
})(PageSetting);
