import { useState, useEffect } from "react";
import { useStore, useDispatch } from "react-redux";
import { readUsersSimple } from "src/store/actions";
import styled from "styled-components";
import { LOGGING } from "../../../shared";
import {
  Centered,
  CenteredByColumnLeft,
  TextTitle,
  centered,
  BorderGrey,
  text12300,
  BackgroundGrey,
} from "../Shared";

// const searchWidth = "100vw";
const SearchBox = styled.input`
  height: 60px;
  line-height: 60px;
  align-self: stretch;
  flex-grow: 0;
  background-color: ${BackgroundGrey};
  padding: 10px;
  border-bottom: none;
  box-sizing: border-box;
  margin: 0px;
  position: sticky;
  top: 0px;
  border-bottom: none !important;
`;
const ContentContainer = styled(Centered)`
  padding: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  border: 1px solid ${BorderGrey};
  width: calc(100% - 80px);
  position: absolute;
  top: 100%;
  background-color: white;
  // boxshadow downward
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-top: none;
`;
const EntryContainer = styled.button`
  ${centered}
  width: 100%;
  justify-content: flex-start;
  background-color: white;
  padding: 10px 20px;
  margin: 0px;
  justify-content: space-between;
`;
const PageContainer = styled(CenteredByColumnLeft)`
  align-self: stretch;
  justify-content: flex-start;
  padding: 0px 40px;
  box-sizing: border-box;
  position: relative;
  z-index: 101;
`;
const Name = styled(TextTitle)`
  width: 100%;
  /* height: 80px; */
  /* line-height: 40px; */
  text-align: left;
  font-size: 20px;
`;
const Email = styled(Name)`
  ${text12300}
  font-size: 18px;
  font-weight: 400;
  text-transform: lowercase;
`;
const Phone = styled(Email)`
  text-align: right;
`;
const Entry = ({ user, keyword, onSelect }) => {
  // highlight the keyword in the name, email, or phone number
  const nameIndex = user?.name?.toLowerCase().indexOf(keyword);
  const emailIndex = user?.email?.toLowerCase().indexOf(keyword);
  const phoneIndex = user?.phone?.indexOf(keyword);
  return (
    <EntryContainer
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onSelect(user._id);
      }}
    >
      <Name>
        {nameIndex >= 0 ? (
          <>
            {user?.name.substring(0, nameIndex)}
            <span style={{ background: "yellow" }}>
              {user?.name?.substring(nameIndex, nameIndex + keyword.length)}
            </span>
            {user?.name?.substring(nameIndex + keyword.length)}
          </>
        ) : (
          user?.name
        )}
      </Name>
      <Email>
        {emailIndex >= 0 ? (
          <>
            {user.email.substring(0, emailIndex)}
            <span style={{ background: "yellow" }}>
              {user.email.substring(emailIndex, emailIndex + keyword.length)}
            </span>
            {user.email.substring(emailIndex + keyword.length)}
          </>
        ) : (
          user.email
        )}
      </Email>
      <Phone>
        {phoneIndex >= 0 ? (
          <>
            {user.phone.substring(0, phoneIndex)}
            <span style={{ background: "yellow" }}>
              {user.phone.substring(phoneIndex, phoneIndex + keyword.length)}
            </span>
            {user.phone.substring(phoneIndex + keyword.length)}
          </>
        ) : (
          user.phone
        )}
      </Phone>
    </EntryContainer>
  );
};
export const SearchUsers = ({ onSelect }) => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [keyword, setKeyword] = useState("");
  const dispatch = useDispatch();
  const store = useStore();
  useEffect(() => {
    readUsersSimple()(dispatch, store.getState).then((res) => {
      setUsers(res);
    });
  }, []);

  const onSearch = (e) => {
    const keyword = e.target.value.toLowerCase();
    LOGGING && console.log("onSearch called keyword", keyword);
    setKeyword(keyword);
    // filter users whose name, email, or phone number contains the keyword
    const filteredUsers = users.filter((user) => {
      return (
        user.name?.includes(keyword) ||
        user.email?.includes(keyword) ||
        user.phone?.includes(keyword)
      );
    });
    setFilteredUsers(filteredUsers);
  };
  const onChoose = (id) => {
    setKeyword("");
    setFilteredUsers([]);
    onSelect(id);
  };
  return (
    <PageContainer>
      <SearchBox
        placeholder="Search by name, email, or phone number"
        value={keyword}
        onChange={onSearch}
      />
      {filteredUsers?.length > 0 ? (
        <ContentContainer>
          {filteredUsers.map((user) => (
            // highlight the keyword in the name, email, or phone number
            <Entry
              key={user.id}
              user={user}
              keyword={keyword}
              onSelect={onChoose}
            />
          ))}
        </ContentContainer>
      ) : null}
    </PageContainer>
  );
};
