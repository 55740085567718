import React, { ReactElement, useEffect, useState } from "react";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import {
  faChevronDown,
  faChevronUp,
  faPlus,
  faBook as faRecommedation,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useStore } from "react-redux";
import { MealVisited } from "src/api/meals";
import { LOGGING } from "src/constants";
import { getRecommendationByRestaurant } from "src/store/actions";
import styled from "styled-components";
import { Carousel } from "./Carousel";
import { BreakPoint } from "./MealHeader";
import { ContentContainer } from "./OrderHistoryInMeal";
import {
  ActionBlack,
  BackgroundGrey,
  BorderGrey,
  card,
  centered,
  Centered,
  CenteredByColumn,
  CenteredByColumnLeft,
  PinkPeach,
  text12300,
  textTitle,
  upper14500,
} from "../../Shared";
interface RecommendationProps {
  restauarantId?: string;
  onAddGoodsToCart?: (goods: any) => void;
  isMember?: boolean;
  onShowCartOnMeal?: () => void;
}
const borderRadius = 10;
const imageWidthMobile = 200;
const imageWidthDesktop = 150;
const textWidthDesktop = 180;
const imageHeightMobile = 140;
const imageHeightDesktop = 180;
const padding = 0;
const Container = styled(ContentContainer)`
  background-color: ${PinkPeach};
  padding: 20px;
  padding-top: 60px;
  margin-bottom: 10px;
  overflow-x: auto;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  align-items: stretch;
  @media screen and (min-width: ${BreakPoint}px) {
    padding-left: 70px;
  }
`;
const Content = styled(CenteredByColumn)`
  width: ${imageWidthMobile}px;
  border: 1px solid ${BorderGrey};
  box-shadow: 2px 2px 4px 0 rgba(28, 29, 33, 0.12);
  position: relative;
  border-radius: ${borderRadius}px;
  background-color: white;
  overflow: hidden;
  align-self: stretch;
  justify-content: flex-start;
  margin-right: 15px;
  flex-shrink: 0;
  @media screen and (min-width: ${BreakPoint}px) {
    width: ${imageWidthDesktop + textWidthDesktop}px;
    flex-direction: row;
  }
`;
const Header = styled(Centered)`
  align-self: stretch;
  justify-content: space-between;
  margin-bottom: 10px;
  position: absolute;
  top: 20px;
  left: 20px;
  h1 {
    margin: 0px !important;
  }
  @media screen and (min-width: ${BreakPoint}px) {
    left: 70px;
  }
`;
const DishList = styled(CenteredByColumn)`
  align-self: stretch;
  flex-grow: 1;
  /* background-color: white; */
  ${text12300}
  font-size: 12.5px;
  font-weight: 400;
  padding: 10px 20px 70px 20px;
  position: relative;
  justify-content: flex-start;
  @media screen and (min-width: ${BreakPoint}px) {
    /* width: 200px; */
    flex-grow: 0 !important;
    /* align-self: auto; */
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 50px;
    width: calc(100% - 150px);
  }
`;
const DishTitle = styled(Centered)`
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 5px;
`;
const DishName = styled.span`
  margin-right: 10px;

  svg {
    margin: 0px 4px;
    font-size: 11px;
  }

  // max 1 line with ellipsis
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
`;
const DishPrice = styled.span`
  line-height: 1.2;
`;
const DishContainer = styled(CenteredByColumnLeft)`
  align-self: stretch;
  justify-content: space-between;
`;

const CartTitle = styled(Centered)`
  span {
    ${upper14500}
    /* ${textTitle} */
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    line-height: 1.3;
  }
  margin: 10px 0px 10px 0px;
  @media screen and (min-width: ${BreakPoint}px) {
    span {
      font-size: 16px !important;
      line-height: 1.4;
    }
    margin-bottom: 5px;
  }
`;
const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${imageHeightMobile}px;
  @media screen and (min-width: ${BreakPoint}px) {
    width: 150px;
    height: 100%;
    /* border-top-right-radius: 0px; */
  }
`;
const Overlay = styled(Centered)`
  position: absolute;
  top: ${padding}px;
  left: ${padding}px;
  width: calc(100% - ${padding * 2}px);
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  color: white;
  z-index: 1;
  padding: 10px;
  align-items: flex-end;
  @media screen and (min-width: ${BreakPoint}px) {
  }
`;
const Notes = styled(Centered)`
  align-self: stretch;
  justify-content: flex-end;
  margin-bottom: 5px;
  span {
    font-size: 11px;
    line-height: 1;
    font-weight: 500;

    /* border: 1px solid blue; */
  }
  svg {
    margin-left: 3px;
  }
`;
const Image = styled.div<{ image: string }>`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  width: calc(100% - ${padding * 2}px);
  margin: ${padding}px;
  margin-bottom: 0px;
  height: ${imageHeightMobile}px;

  @media screen and (min-width: ${BreakPoint}px) {
    width: 100%;
    height: 100%;
  }
`;
const AddToCartButton = styled(ActionBlack)`
  /* margin-top: 20px; */
  position: absolute;
  /* right: 10px; */
  bottom: 20px;
  @media screen and (min-width: ${BreakPoint}px) {
    bottom: 10px;
    align-self: stretch;
    flex-grow: 1;
    width: calc(100% - 30px);
    font-size: 12px;
    padding: 0px 5px;
    height: 35px !important;
  }
`;
const DishSelections = styled.p`
  ${centered}
  ${text12300}
  line-height: 1;
  font-size: 9px;
  margin-top: 0px;
  margin-bottom: 10px;
  b {
    font-weight: 400;
    display: inline;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-height: 1.2;
`;
const Dish = ({ dish }) => {
  return (
    <DishContainer>
      <DishTitle>
        <DishName>
          {dish.dish.name}
          {dish.quantity > 1 ? (
            <>
              <FontAwesomeIcon icon={faTimes} />
              {dish.quantity}
            </>
          ) : null}
        </DishName>
        <DishPrice>{dish.dish.priceFoodieListed * dish.quantity}</DishPrice>
      </DishTitle>
      {dish?.selections?.length > 0 ? (
        <DishSelections>
          {/* Selected:{" "} */}
          {dish.selections.map((s) => s.selectedItems[0].item.name).join(", ")}
        </DishSelections>
      ) : null}
    </DishContainer>
  );
};
export const Recommendation: React.FC<RecommendationProps> = ({
  restauarantId,
  onAddGoodsToCart,
  isMember,
  onShowCartOnMeal,
}): ReactElement => {
  const dispatch = useDispatch();
  const store = useStore();
  const [recommendations, setRecommendations] = useState([]);
  const currentUser = store.getState().currentUser.user;
  const [showAll, setShowAll] = useState(false);
  const user = store.getState().currentUser.user;

  const onToggleShowAll = (e) => {
    e.preventDefault();
    setShowAll(!showAll);
  };
  const onAddToCart = (dishes, e) => {
    e.preventDefault();
    LOGGING &&
      console.log(
        "Recommendation.tsx onAddToCart called with dishes",
        dishes,
        user
      );

    MealVisited(
      "added recommendation to cart",
      currentUser.firstName,
      "",
      "",
      0
    );

    const goods = dishes.map(({ dish, quantity, selections }) => ({
      dish: dish,
      price: isMember ? dish.priceFoodieListed : dish.priceDoordashListed,
      quantity: quantity,
      addedBy: user?._id,
      selections: selections.map(({ dishSelection, selectedItems }) => ({
        dishSelection: dishSelection,
        selectedItems: selectedItems.map(({ item, count }) => ({
          item: item._id,
          count: count,
        })),
      })),
    }));
    onAddGoodsToCart(goods);
    onShowCartOnMeal();
  };
  useEffect(() => {
    LOGGING &&
      console.log(
        "Recommendation.tsx useEffect called with restauarantId:",
        restauarantId
      );
    if (restauarantId) {
      getRecommendationByRestaurant(restauarantId)(
        dispatch,
        store.getState
      ).then((response) => {
        setRecommendations(response);
      });
    }
  }, [restauarantId]);
  if (!recommendations?.length) return null;
  LOGGING && console.log("Recommendation.tsx recommendations", recommendations);
  return (
    <Container>
      <Header>
        <h1>
          <FontAwesomeIcon icon={faRecommedation} />
          the FOODIE Guide
        </h1>
        {/* <h1>
          <FontAwesomeIcon icon={faUser} /><FontAwesomeIcon icon={faTimes} />4
          serves 4
        </h1> */}
      </Header>
      {recommendations.map((r) => (
        <Content>
          <ImageContainer>
            <Overlay>
              <CartTitle>
                <span>{r.title || `Tasting menu for ${r?.dishes.length}`}</span>
              </CartTitle>
            </Overlay>
            <Image image={r?.dishes[0].dish.imageURL} />
          </ImageContainer>

          <DishList>
            {(showAll ? r?.dishes : r?.dishes.slice(0, 2)).map((dish) => (
              <Dish key={dish.id} dish={dish} />
            ))}
            {r.dishes.length > 2 ? (
              <Notes onClick={onToggleShowAll}>
                {!showAll ? (
                  <span>
                    {`and ${r.dishes.length - 2} more item${
                      r.dishes.length > 3 ? "s" : ""
                    }`}
                    <FontAwesomeIcon icon={faChevronDown} />
                  </span>
                ) : (
                  <span>
                    {`show less`}
                    <FontAwesomeIcon icon={faChevronUp} />
                  </span>
                )}
              </Notes>
            ) : null}
            <AddToCartButton onClick={onAddToCart.bind(this, r.dishes)}>
              add to cart
            </AddToCartButton>
          </DishList>
        </Content>
      ))}
    </Container>
  );
};
