import {
  MEAL_MENU_LOAD_RESTAURANT,
  MEAL_MENU_LOAD_RESTAURANT_DISHES,
  MEAL_MENU_LOAD_SELECTED_DISHES,
} from '../actionTypes';

export const mealMenu = (
  state = { restaurant: null, restaurantDishes: null, selectedDishes: null },
  action
) => {
  switch (action.type) {
    case MEAL_MENU_LOAD_RESTAURANT:
      return { ...state, restaurant: action.restaurant };
    case MEAL_MENU_LOAD_RESTAURANT_DISHES:
      return { ...state, restaurantDishes: action.restaurantDishes };
    case MEAL_MENU_LOAD_SELECTED_DISHES:
      return { ...state, selectedDishes: action.selectedDishes };

    default:
      return state;
  }
};
