import React, { ReactElement, useCallback, useState, useEffect } from "react";
import { faInfoCircle, faTimes } from "@fortawesome/pro-light-svg-icons";
import {
  faShareAll as faCopy,
  faChevronLeft as faBack,
  faArrowToRight as faPopUp,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { useAsync } from "react-async";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GetReferralLinkDetails } from "src/api/referrals";
import { updateInteraction } from "src/store/actions";
import styled, { keyframes } from "styled-components";
import { ShareActions } from "./ShareActions";
import { IconImages, LOGGING, Loading, PopUp } from "..";
import { HeroImage } from "..";
import {
  ActionGrey,
  Text12300,
  TextUPPER,
  TextTagline,
  Centered,
  Yellow,
  Beige,
  Grey,
  Pink,
  SubNav,
  card,
  textTitle,
  ALLCAPTEXT,
  TextTitle,
  SectionHeader,
  SectionNote,
  SectionTitle,
  text12300,
  CenteredByColumn,
  NavHeight,
  CenteredByColumnLeft,
  BorderGrey,
  actionBlack,
  centered,
  actionUnderline,
  WinterBlue,
  withCurls,
  SpringYellow,
  SummerBlue,
  FallOrange,
} from "../Shared";
export interface MyReferralCodeEventNonMemberProps {
  onClose?: (e) => void;
  onProceed?: (e) => void;
}
export interface HeroProps {
  src: string;
}

const ShareButtonText = styled(ActionGrey)`
  // margin: 5px;
  // margin-bottom: 10px;
  width: 100%;
  height: 60px;
  position: relative;
  & svg {
    /* position: absolute; */
    /* right: 20px; */
    font-size: 18px;
    margin-left: 10px;
  }
`;

const TextShareButtonText = styled(ShareButtonText)`
  background: black;
  /* color: black; */
  border: 1px solid black;
  /* background-color: transparent; */
  font-size: 13px;
  padding: 15px 30px;
  color: white;
`;

const copyButtonHeight = 100;
const breakPoint = 700;
const ReferralLink = styled(ActionGrey)`
  ${text12300}
  font-weight: 500;
  ${card}
  /* background-color: ${BorderGrey}; */
  /* border: 1px solid ${BorderGrey}; */
  background-color: ${BorderGrey};
  /* border-bottom: 1px solid black; */
  text-transform: none;
  svg {
    margin-left: 10px;
  }
  @media only screen and (min-width: ${breakPoint}px) {
    display: none;
  }
`;
const CopyLink = styled(Centered)`
  display: none;
  width: 100%;
  span {
    background: rgba(228, 228, 228, 0.5);
    font-family: "Montserrat", sans-serif;
    /* text-transform: uppercase; */
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1.8px;
    padding: 0px 20px;
    line-height: 40px;
    box-shadow: none;
    box-sizing: border-box;
    margin-right: 10px;
  }
  button {
    ${actionBlack}
    margin-left: 10px;
  }
  @media only screen and (min-width: ${breakPoint}px) {
    display: flex;
  }
`;
export const CopyButton = styled.div`
  margin: 20px 0px 0px 0px;
  /* width: calc(100% - 40px); */
  width: 100vw;
  /* padding: 0 20px; */
  /* background-color: white; */
  /* position: fixed;
  bottom: 200px; */
  /* height: ${copyButtonHeight}px; */
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 1px solid rgb(217, 217, 217); */
  /* background-color: ${Beige}; */
  align-items: center;
  /* background-color: rgba(255, 255, 255, 0.8); */
  /* flex-direction: row; */
  /* flex-wrap: nowrap; */
  /* padding-right: 40px; */

  > svg {
    /* display: none; */
    font-size: 20px;
    /* margin-left: 10px;       */
    /* margin-bottom: 0px; */
    position: absolute;
    right: 30px;
  }
`;
const ShareLink = styled.a`
  margin: 10px 0px;
`;

export const SendReferralContentContainer = styled(CenteredByColumn)`
  width: 100vw;
  height: 100vh;
  text-align: center;
  line-height: 1.5;
  /* position: fixed;
  top: 0px;
  left: 0px; */
  justify-content: flex-start;
  position: relative;

  .container {
    width: 100%;
    max-width: 100%;
    display: block;
    flex-shrink: 0;
    height: 40vh;
    /* height:  calc(40vh - 16px); */
    align-self: stretch;
    flex-grow: 0;
    /* max-height: 40%; */
  }
  /* padding: 0px 40px 200px 40px; */
`;
const BANNER_HEIGHT = 30;
const animation = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

interface TreatsProps {
  isSmall?: boolean;
}

const Treats = styled(CenteredByColumnLeft)<TreatsProps>`
  margin: 20px 0px;
  align-items: ${(props) => (props.isSmall ? "center" : "flex-start")};
  span {
    ${text12300};
    text-transform: ${(props) => (props.isSmall ? "normal" : "uppercase")};
    position: relative;
    font-size: ${(props) => (props.isSmall ? "14px" : "20px")};
    font-weight: 400;
    text-align: left;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.8);
    b {
      font-weight: 600;
      color: black;
    }
  }
  span:before {
    content: "✓";
    width: 5px;
    height: 5px;
    /* background-color: black; */
    /* border-radius: 50%; */
    position: absolute;
    left: -20px;
    top: -2px;
    /* display: none; */
  }
  label {
    ${text12300}
    /* margin-bottom: 20px; */
    text-align: center;
    position: relative;
    /* padding: 0px 20px; */
    b {
      font-weight: 600;
    }
  }
  @media only screen and (max-width: 330px) {
    span {
      font-size: 12px;
    }
  }
`;
const MemberAction = styled.button`
  ${actionBlack}
  /* position: absolute;
  bottom: 100px; */
  /* z-index: 1; */
  @media only screen and (max-width: 330px) {
    font-size: 14px;
  }
`;
const MemberAction2 = styled(Link)`
  ${actionBlack}
  /* position: absolute;
  bottom: 10px;
  z-index: 1; */
  @media only screen and (max-width: 330px) {
    font-size: 12px;
  }
`;
const MemberNotes = styled(CenteredByColumnLeft)`
  ${text12300}
`;
const CopiedNote = styled(TextTagline)`
  position: absolute;
  ${card}
  padding:0px 20px;
  width: 100px;
  font-size: 12px;
  background: green;
  color: white;
  line-height: ${BANNER_HEIGHT}px;
  height: ${BANNER_HEIGHT}px;
  top: 5px;
  left: calc(50% - 70px);
  animation: ${animation} 1s ease-in-out 0.5s 1 normal forwards;
`;
export const ButtonClose = styled(Link)`
  position: fixed;
  /* left: 15px;
  top: 15px; */
  top: 0px;
  left: 0px;
  width: ${NavHeight}px;
  height: ${NavHeight}px;
  /* width: 60px;
  height: 60px; */
  /* border-radius: 50%; */
  ${centered}
  z-index: 2;
  /* background-color: rgba(0, 0, 0, 0.2); */
  svg {
    font-size: 30px;
    color: white;
  }
`;
export const HeroContainer = styled(CenteredByColumn)`
  width: 100vw;
  position: relative;
  /* border: 1px solid black; */
  .container {
    height: 40vh;
    min-width: 100vw;
    max-width: 100vw;
  }
`;
export const HeroOverlay = styled(CenteredByColumn)`
  width: 100%;
  height: 40vh;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 0px 20px;
  text-align: center;
`;
const Hero = styled(Centered)<HeroProps>`
  width: 100vw;
  height: 50vh;
  /* border-radius: 50%; */
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  /* border: 1px solid red; */
  @media only screen and (min-width: ${breakPoint}px) {
    width: 200px;
    height: 200px;
  }
`;
const InfoIcon = styled.button`
  margin: 0px;
  padding: 0px;
  ${textTitle}
  text-transform: lowercase;
  font-size: 13px;
  font-style: italic;
  line-height: 10px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 5px;
`;
const Line = styled(Centered)`
  position: relative;
  padding: 5px;
`;
const HeroText = styled(CenteredByColumn)`
  margin-top: 10px;
  padding: 0px 30px;
  max-width: 500px;
  /* top: ${NavHeight}px;
  left: 20px; */
  /* position: fixed; */
  /* width: calc(100vw - 40px); */

  span {
    ${text12300}
    font-size: 13px;
    text-transform: none;
    font-weight: 300;
    line-height: 1.6;
  }

  @media only screen and (min-width: ${breakPoint}px) {
  }
`;
export const Expire = styled.div`
  ${textTitle}
  font-size: 16px;
  line-height: 40px;
  font-weight: 700;
  /* background-color: orange; */
  background-color: ${FallOrange};
  color: black;
  padding: 10px 20px;
  margin-top: 0px;
  margin-bottom: 30px;
  line-height: 1.4;
  label {
    font-size: 20px;
    margin-right: 10px;
    line-height: 40px;
    display: none;
  }
  @media only screen and (max-width: 330px) {
    font-size: 14px;
    line-height: 1.6;
  }
`;
const TextContainer = styled(CenteredByColumn)`
  ${card}
  width: calc(100vw - 40px);
  position: relative;
  top: -40px;
  z-index: 1;
  padding: 15px;
  /* min-height: 50vh; */
  max-width: 600px;
  /* flex-grow: 1; */
  justify-content: flex-start;
  margin-bottom: 20px;
`;
const TextContainer2 = styled(TextContainer)`
  flex-grow: 0;
`;
const TextBorder = styled(CenteredByColumn)`
  padding: 20px;
  border: 2px solid ${FallOrange};
  flex-grow: 1;
  align-self: stretch;
  justify-content: flex-start;
  h1 {
    ${text12300}
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 400;
    line-height: 1.2;
    margin: 0 0 20px 0;
    /* background-color: #82caff;
    padding: 10px 20px; */
  }
`;
const Sticker = styled(Centered)`
  position: absolute;
  bottom: -40px;
  left: -10px;
  /* left: calc(50% - 40px); */
  transform: rotate(0deg);
  font-size: 90px;
`;

const DetailsContainer = styled(CenteredByColumn)`
  position: relative;
  ${card}
  ${text12300}
  z-index: 1;
  margin: 20px;
  padding: 40px 15px;
  justify-content: flex-start;

  label {
    font-size: 40px;
  }
  button {
    /* position: absolute;
    top: 5px;
    right: 5px;
    margin:0px;
    padding: 0px;
    width: 40px;
    height: 40px;
    ${centered}
    font-size: 20px; */
    ${actionBlack}
    margin-top: 30px;
  }
  @media only screen and (min-width: ${breakPoint}px) {
    padding: 40px;
    max-width: 800px;
  }
`;
export interface TaglineProps {
  color?: string;
}

export const Tagline = styled.span<TaglineProps>`
  ${textTitle}
  /* ${withCurls} */
  font-size: 20px;
  font-weight: 500;
  /* text-transform: uppercase; */
  /* margin-bottom: 15px; */
  color: ${(props) => props.color || "white"};
  /* border-bottom: 1px solid ${(props) => props.color || "white"}; */
  line-height: 1.4;
  margin-top: 20px;
  margin-bottom: 10px;
  &:before,
  &:after {
    background-color: black;
  }
`;
const BecomeMember = styled(Tagline)`
  color: rgba(0, 0, 0, 0.9);
  ${textTitle}
  text-transform: none;
  line-height: 1.25;
  font-size: 20px;
`;
export const Title = styled.span`
  ${text12300}
  color: white;
  font-size: 32px;
  font-weight: 500;
  line-height: 1.1;
  text-transform: uppercase;
  margin-bottom: 20px;
`;
const Benefits = styled(CenteredByColumnLeft)`
  max-width: 600px;
  margin: 0px 0px 10px 0px;
  background-color: ${Beige};
  padding: 10px 10px;
  @media only screen and (min-width: ${breakPoint}px) {
    padding: 20px 40px;
  }
`;
const BenefitTitle = styled(TextTitle)`
  font-size: 20px;
  line-height: 1.4;
  text-transform: none;
  margin-bottom: 30px;
  align-self: stretch;
  /* text-align: left; */
`;
const Benefit = styled.span`
  ${text12300}
  text-align: left;
  position: relative;
  &:before {
    content: "✓";
    width: 5px;
    height: 5px;
    /* background-color: black; */
    /* border-radius: 50%; */
    position: absolute;
    left: -20px;
    top: -2px;
    display: none;
  }
  b {
    font-size: 600;
  }
  margin-bottom: 10px;
  line-height: 1.5;
  @media only screen and (min-width: ${breakPoint}px) {
  }
`;
const Header = styled.span`
  ${text12300}
  font-size: 32px;
  text-transform: uppercase;
  margin-bottom: 20px;
  line-height: 1.1;
`;
const Details = ({ onClose }) => (
  <DetailsContainer>
    {/* <label>💝</label> */}
    <Header>
      11 <b>FREE</b> TREATS
    </Header>
    <BenefitTitle>As a valued member, you can</BenefitTitle>
    <Benefits>
      <Benefit>
        1. Gift <b>10 FREE</b> treats to any 10 friends who are new to Foodie.
      </Benefit>
      <Benefit>
        2. Claim <b>1 FREE</b> treat for yourself.
      </Benefit>
      <Benefit>
        3. Get <b>$10</b> credit for each friend who places an order of $20 or
        more.
      </Benefit>
    </Benefits>
    <button onClick={onClose}>
      Got it!
      {/* <FontAwesomeIcon icon={faTimes}/> */}
    </button>
  </DetailsContainer>
);

// TP-Tea
// export const heroImages = [
//   "https://res.cloudinary.com/ddhetmqtt/image/upload/c_lfill,h_220/dpr_2.0/q_auto/f_auto/v1/menus-s3/TP%2520TEA%2520%2528Berkeley%252C%25202383%2520Telegraph%2520Ave%2529/1666703585182.png",
//   "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=600,height=400,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/9d9c0b0b-0edb-41fa-aa7b-538137b91e3f.jpg",
//   "https://images.prismic.io/taste/4ea61de9-efc4-4fcf-acbf-1148aae2e05c_207848851_139078328322555_3504931628606531660_n.jpg",
// ];

// Sunright
export const heroImages = [
  "https://www.snrtea.com/wp-content/uploads/2024/06/Sunright_homepage_banner-jpg.webp",
  // "https://d2s742iet3d3t1.cloudfront.net/restaurants/restaurant-67848000000000000/banner_1613122578.jpg?size=medium",
  "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEj7m8_tm4qStASfwVst2Pp9TuZRMjNav2lLi3_YdNEPN3H6eNH83crb1mL4rq2aouE9LJrfUclb8qW3UB4N_7u0h9AEAQNDTCmun_ZHWe6RHapAEjm52jfiHXBNv1R9MCX2S_sCj9FYFZA/s1600/sunrighttea.JPG",
  "https://s3-media0.fl.yelpcdn.com/bphoto/p1vdCNlYY0KtQML7a4aliw/o.jpg",
];

export const MyReferralCodeEventNonMember: React.FC<MyReferralCodeEventNonMemberProps> =
  ({ onClose, onProceed }: MyReferralCodeEventNonMemberProps): ReactElement => {
    const [showDetails, setShowDetails] = useState(false);

    const currentUser = useSelector((state: any) => state.currentUser);
    const isCanceledMember =
      currentUser?.user?.membership?.status?.includes("cancel");
    const code = currentUser?.user?.socialInviteCode?.code;
    if (code == null) return null;
    const link = `https://foodie.earth/treat/${code}`;

    const closeDetails = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setShowDetails(false);
    };

    return (
      <SendReferralContentContainer>
        <ButtonClose to="/">
          <FontAwesomeIcon icon={faBack} />
        </ButtonClose>
        <HeroImage images={heroImages} isEvent={true} />
        <HeroOverlay>
          <Title>
            Fall treats<br></br>on us!
          </Title>
        </HeroOverlay>
        {/*@ts-ignore*/}
        <PopUp
          isPoppedUp={showDetails}
          componentToDisplay={<Details onClose={closeDetails} />}
          hidePopUp={closeDetails}
        />
        <TextContainer2>
          <TextBorder>
            {/* <Sticker>☃️</Sticker> */}
            <Sticker>🍁</Sticker>
            <Tagline color="black">member only</Tagline>
            <Treats isSmall={true}>
              <label>
                Become a member today and enjoy<br></br>
                {isCanceledMember ? null : <b>30-day FREE trial</b>}
                {`${isCanceledMember ? "" : " &"}`} <b>11 FREE treats</b>.
                <InfoIcon
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowDetails(true);
                  }}
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                </InfoIcon>
              </label>
            </Treats>

            <Expire>ends Mon 9/16/2024</Expire>
            <MemberAction2
              to={{
                pathname: "/membership",
                state: { next: `/treat/${code}` },
              }}
            >
              <span>{isCanceledMember ? "resume" : "start"} membership</span>
            </MemberAction2>
          </TextBorder>
        </TextContainer2>
      </SendReferralContentContainer>
    );
  };
const SkipButton = styled.button`
  ${actionUnderline}
  /* text-transform: uppercase; */
  /* position: absolute;
  bottom: 155px; */
  z-index: 1;
  margin-bottom: 20px;
  @media only screen and (max-height: 650px) {
    display: none;
  }
`;
const CloseButton = styled.button`
  position: absolute;
  right: 0px;
  top: 0px;
  color: white;
  margin: 0px;
  padding: 0px;
  z-index: 1;
  svg {
    font-size: 30px;
    line-height: 55px;
  }
  width: 55px;
  height: 55px;
  /* border: 1px solid white;
  border-radius: 50%; */
`;
const MyDrinkBlockingContainer = styled(CenteredByColumn)`
  width: calc(100vw - 40px);
  height: calc(100vh - 40px);
  top: 20px;
  left: max(20px, calc(50vw - 300px));
  justify-content: flex-start;
  position: fixed;
  max-width: 600px;
  .container {
    width: 100%;
    max-width: 100%;
    display: block;
    flex-shrink: 0;
    height: 40vh;
    /* height:  calc(40vh - 16px); */
    align-self: stretch;
    flex-grow: 0;
    /* max-height: 40%; */
  }
`;
export const MyDrinkBlockingNonMember: React.FC<MyReferralCodeEventNonMemberProps> =
  ({ onClose, onProceed }): ReactElement => {
    const [showDetails, setShowDetails] = useState(false);
    const currentUser = useSelector((state: any) => state.currentUser);

    const isCanceledMember =
      currentUser?.user?.membership?.status?.includes("cancel");
    const isActive = currentUser?.user?.membership?.isActive;
    LOGGING &&
      console.log(
        "MyDrinkBlockingNonMember rendering with isCanceledMember:",
        isCanceledMember
      );
    const code = currentUser?.user?.socialInviteCode?.code;
    if (code == null) return null;
    const link = `https://foodie.earth/treat/${code}`;
    const closeDetails = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setShowDetails(false);
    };

    return (
      <MyDrinkBlockingContainer>
        <HeroImage images={heroImages} isEvent={true} />
        <HeroOverlay>
          <CloseButton onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </CloseButton>
          <Title>
            fall treats<br></br>on us!
          </Title>
        </HeroOverlay>
        {/*@ts-ignore*/}
        <PopUp
          isPoppedUp={showDetails}
          componentToDisplay={<Details onClose={closeDetails} />}
          hidePopUp={closeDetails}
        />
        <TextContainer>
          {/* <Sticker>☃️</Sticker> */}
          {/* <Sticker>🏂</Sticker> */}
          {/* 🌼🌿🍃🌱🍉*/}
          <Sticker>🍁</Sticker>
          <Tagline color="black">member only</Tagline>
          {isActive ? (
            <Treats isSmall={true}>
              <span>
                1 <b>FREE</b> treat for yourself
              </span>
              <span>
                10 <b>FREE</b> treats for 10 friends
              </span>
            </Treats>
          ) : (
            <Treats isSmall={true}>
              <label>
                Become a member today and enjoy<br></br>
                {isCanceledMember ? null : <b>30-day FREE trial</b>}
                {`${isCanceledMember ? "" : " &"}`} <b>11 FREE treats</b>.
                <InfoIcon
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowDetails(true);
                  }}
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                </InfoIcon>
              </label>
            </Treats>
          )}

          <Expire>ends Mon 9/16/2024</Expire>
          <MemberNotes>
            {/* <span>access to 300 restaurants with in-store prices.</span> */}
            {/* <span>free trial for 30 days!</span> */}
          </MemberNotes>
          <SkipButton onClick={onClose}>Skip</SkipButton>
          {isActive ? (
            // <MemberAction2 to={`/treat/${code}`}>
            <MemberAction onClick={onProceed}>
              <span>View Details</span>
            </MemberAction>
          ) : (
            <MemberAction onClick={onProceed}>
              <span>{isCanceledMember ? "resume" : "start"} membership</span>
            </MemberAction>
          )}
        </TextContainer>
      </MyDrinkBlockingContainer>
    );
  };
