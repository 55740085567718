function deg2rad(deg: number): number {
  return deg * (Math.PI / 180);
}

// Given two locations {lat: xxx, lng: xxx}, return their distance in miles.
export function calcDistance(locationA: any, locationB: any): number {
  var R = 3958.8; // Radius of the earth in miles
  var dLat = deg2rad(locationA?.lat - locationB?.lat);
  var dLng = deg2rad(locationA?.lng - locationB?.lng);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(locationA?.lat)) *
      Math.cos(deg2rad(locationB?.lat)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);

  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in miles
  return d;
}
