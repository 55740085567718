import React from 'react';
import './Stats.scss';
import { faArrowToBottom } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Bar } from 'react-chartjs-2';
import {
  exportComponentAsJPEG,
  // exportComponentAsPDF,
  exportComponentAsPNG,
} from 'react-component-export-image';
import { Loading, LOGGING } from './';

const options = {
  responsive: true,
  stacked: false,
  maintainAspectRatio: false,
  aspectRatio: 1,
  plugins: {
    legend: {
      display: 'auto',
      labels: {
        boxWidth: 5,
        boxHeight: 5,
      },
    },
  },

  scales: {
    y: {
      type: 'linear',
      display: 'auto',
      position: 'left',
      title: {
        text: 'Weekly Revenue (USD)',
        color: 'rgb(95, 92, 255)',
      },
      ticks: {
        display: 'auto',
        color: 'rgb(95, 92, 255)',
      },
    },
    y1: {
      type: 'linear',
      display: 'auto',
      position: 'right',
      ticks: {
        display: 'auto',
        color: 'rgb(157, 220, 255)',
      },
      title: {
        text: 'Weekly Orders',
        color: 'rgb(157, 220, 255)',
      },
      grid: {
        drawOnChartArea: false, // only want the grid lines for one axis to show up
      },
    },
    xUsers: {
      ticks: {
        display: true,
        color: 'rgb(157, 220, 255)',
        autoSkip: false,
        maxRotation: 90,
        minRotation: 90,
      },
      grid: {
        display: false,
      },
    },
    yUsers: {
      type: 'linear',
      display: 'auto',
      position: 'right',
      ticks: {
        display: 'auto',
        color: 'rgb(157, 220, 255)',
      },
      grid: {
        display: 'auto',
        // drawOnChartArea: false, // only want the grid lines for one axis to show up
      },
    },
  },
};

const BarHeight = 380;
const BarWidth = 480;
const optionsRouteChart = {
  responsive: true,
  stacked: false,
  plugins: {
    legend: {
      display: false,
    },
  },

  scales: {
    xETA: {
      type: 'linear',
      display: 'auto',
      grid: {
        display: false,
      },
      title: {
        display: 'auto',
        text: 'Estimated Driving Time Between Adjacent Stops (Minutes)',
      },
      ticks: {
        display: 'auto',
      },
    },
    xStops: {
      type: 'linear',
      display: 'auto',
      grid: {
        display: false,
      },
      title: {
        display: 'auto',
        text: '# Stops Per Route',
      },
    },
    yStops: {
      type: 'linear',
      display: 'auto',
      position: 'left',
      min: 0,
      ticks: {
        display: 'auto',
        stepSize: 0.1,
        callback: function (value) {
          return (value * 100).toFixed(0) + '%'; // convert it to percentage
        },
      },
    },
    yETA: {
      type: 'linear',
      display: 'auto',
      position: 'left',
      min: 0,
      // max: 1,
      ticks: {
        display: 'auto',
        stepSize: 0.04,
        callback: function (value) {
          return (value * 100).toFixed(0) + '%'; // convert it to percentage
        },
      },
    },
  },
};

const optionsRestaurantsChart = {
  responsive: true,
  stacked: false,
  plugins: {
    legend: {
      display: 'auto',
      labels: {
        boxWidth: 10,
        boxHeight: 10,
      },
    },
  },

  scales: {
    xRestaurants: {
      type: 'linear',
      display: 'auto',
      // labels: ["January", "February", "March", "April", "May", "June"],
      grid: {
        // display: "auto",
        display: false,
      },
      title: {
        display: 'auto',
        text: '# Orders Per Meal Per Restaurant',
      },
      ticks: {
        min: 0,
        max: 30, // Your absolute max value
        callback: function (value) {
          return `${value - 5}~${value}`; // convert it to percentage
        },
        display: 'auto',
      },
    },
    yRestaurants: {
      type: 'linear',
      display: 'auto',
      position: 'left',
      // title: {
      //   display: "auto",
      //   text: "%",
      // },
      ticks: {
        min: 0,
        max: 1, // Your absolute max value
        callback: function (value) {
          return (value * 100).toFixed(0) + '%'; // convert it to percentage
        },
        display: 'auto',
      },
      grid: {
        display: 'auto',
      },
    },
  },
};

const GrowthCharts = ({ data, refs, growthWindow, onSelectGrowthWindow }) => {
  LOGGING && console.log('GrowthCharts with data.orders', data.orders);
  return (
    <div className="growth-chart">
      <div className="meal-select-wrapper">
        <button
          className={`${growthWindow === 'week' ? 'selected' : ''}`}
          onClick={onSelectGrowthWindow.bind(this, 'week')}
        >
          <span>week</span>
        </button>
        <button
          className={`${growthWindow === 'month' ? 'selected' : ''}`}
          onClick={onSelectGrowthWindow.bind(this, 'month')}
        >
          <span>month</span>
        </button>
        <button
          className={`${growthWindow === 'sliding week' ? 'selected' : ''}`}
          onClick={onSelectGrowthWindow.bind(this, 'sliding week')}
        >
          <span>sliding week</span>
        </button>
        <button
          className={`${growthWindow === 'sliding 30 days' ? 'selected' : ''}`}
          onClick={onSelectGrowthWindow.bind(this, 'sliding 30 days')}
        >
          <span>sliding 30 days</span>
        </button>
      </div>
      <div className="chart-container">
        <div className="weekly-chart" ref={refs.revenue}>
          {/* <Line data={data.revenue} options={options} /> */}
          <Bar data={data.revenue} options={options} height={BarHeight} />
        </div>
        <button onClick={() => exportComponentAsJPEG(refs.revenue)}>
          <FontAwesomeIcon icon={faArrowToBottom} />
        </button>
      </div>
      <div className="chart-container">
        <div className="weekly-chart" ref={refs.users}>
          <Bar data={data.users} options={options} height={BarHeight} />
        </div>
        <button onClick={() => exportComponentAsPNG(refs.users)}>
          <FontAwesomeIcon icon={faArrowToBottom} />
        </button>
      </div>
      <div className="chart-container">
        <div className="weekly-chart" ref={refs.orders}>
          <Bar data={data.orders} options={options} height={BarHeight} />
        </div>
        <button onClick={() => exportComponentAsJPEG(refs.orders)}>
          <FontAwesomeIcon icon={faArrowToBottom} />
        </button>
      </div>

      {/* <div className="chart-container">
      <div className="weekly-chart" ref={refs.returning}>
        <Bar data={data.returningOrdersPerUser} options={options} height={BarHeight} />
      </div>
      <button onClick={() => exportComponentAsPNG(refs.returning)}>
        <FontAwesomeIcon icon={faArrowToBottom} />
      </button>
    </div> */}
      <div className="chart-container">
        <div className="weekly-chart" ref={refs.growth}>
          <Bar data={data.growth} options={options} height={BarHeight} />
        </div>
        <button onClick={() => exportComponentAsPNG(refs.growth)}>
          <FontAwesomeIcon icon={faArrowToBottom} />
        </button>
      </div>
    </div>
  );
};

const RouteCharts = ({ data, refs }) => (
  <React.Fragment>
    <div className="chart-container">
      <div className="weekly-chart" ref={refs.stops}>
        <Bar data={data.stops} options={optionsRouteChart} />
      </div>
      <button onClick={() => exportComponentAsJPEG(refs.stops)}>
        <FontAwesomeIcon icon={faArrowToBottom} />
      </button>
    </div>
    <div className="chart-container">
      <div className="weekly-chart" ref={refs.eta}>
        <Bar data={data.eta} options={optionsRouteChart} />
      </div>
      <button onClick={() => exportComponentAsJPEG(refs.eta)}>
        <FontAwesomeIcon icon={faArrowToBottom} />
      </button>
    </div>
  </React.Fragment>
);

// const PowerUserCharts = ({ data, refs }) => (
//   <React.Fragment>
//     <div className="chart-container">
//       <div className="weekly-chart" ref={refs.powerUsers}>
//         <Bar data={data.powerUsers} options={optionsPowerUserChart} />
//       </div>
//       <button onClick={() => exportComponentAsJPEG(refs.powerUsers)}>
//         <FontAwesomeIcon icon={faArrowToBottom} />
//       </button>
//     </div>
//   </React.Fragment>
// );

const UserCharts = ({ data, refs }) => {
  LOGGING && console.log('UserCharts rendering with data:', data);
  return data.map((user) => (
    <React.Fragment>
      <div className="chart-container">
        <div className="weekly-chart" ref={refs.users}>
          <Bar data={user} options={options} />
        </div>
        <button onClick={() => exportComponentAsJPEG(refs.users)}>
          <FontAwesomeIcon icon={faArrowToBottom} />
        </button>
      </div>
    </React.Fragment>
  ));
};

const RestaurantCharts = ({ data, refs }) => (
  <React.Fragment>
    <div className="chart-container">
      <div className="weekly-chart" ref={refs.users}>
        <Bar data={data} options={optionsRestaurantsChart} />
      </div>
      <button onClick={() => exportComponentAsJPEG(refs.users)}>
        <FontAwesomeIcon icon={faArrowToBottom} />
      </button>
    </div>
  </React.Fragment>
);

const Stats = ({
  data,
  loading,
  refs,
  statsType,
  growthWindow,
  onSelectGrowthWindow,
  onSelectStatsType,
}) => {
  return (
    <div className="list stats">
      <div className="meal-select-wrapper">
        <button
          className={`${statsType === 'growth' ? 'selected' : ''}`}
          onClick={onSelectStatsType.bind(this, 'growth')}
        >
          <span>growth</span>
        </button>
        <button
          className={`${statsType === 'routes' ? 'selected' : ''}`}
          onClick={onSelectStatsType.bind(this, 'routes')}
        >
          <span>routes</span>
        </button>
        <button
          className={`${statsType === 'restaurants' ? 'selected' : ''}`}
          onClick={onSelectStatsType.bind(this, 'restaurants')}
        >
          <span>restaurants</span>
        </button>
        <button
          className={`${statsType === 'users' ? 'selected' : ''}`}
          onClick={onSelectStatsType.bind(this, 'users')}
        >
          <span>users</span>
        </button>
      </div>
      {loading ? (
        <Loading />
      ) : statsType === 'growth' ? (
        <GrowthCharts
          data={data}
          refs={refs}
          onSelectGrowthWindow={onSelectGrowthWindow}
          growthWindow={growthWindow}
        />
      ) : statsType === 'routes' ? (
        <RouteCharts data={data} refs={refs} />
      ) : statsType === 'restaurants' ? (
        <RestaurantCharts data={data} refs={refs} />
      ) : statsType === 'users' ? (
        <UserCharts data={data} refs={refs} />
      ) : null}
    </div>
  );
};

export default Stats;
