import React, { ReactElement, useCallback } from "react";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAsync } from "react-async";
import { useSelector } from "react-redux";
import { GetFavoritesOverview } from "src/api/restaurants";
import { RootState } from "src/store";
import styled from "styled-components";
import { LOGGING } from "../shared";
import { Loading } from "./components";
import {
  Centered,
  CenteredByColumn,
  CenteredByColumnLeft,
  Text12300,
  TextTitle,
  centered,
  card,
} from "./components/Shared";
interface PageFavoritesOverviewProps {}

const ContentContainer = styled(Centered)`
  width: 100%;
  padding: 24px;
  align-items: flex-start;
  justify-content: flex-start;
`;
const CloseButton = styled.a`
  position: fixed;
  top: 20px;
  right: 40px;
  svg {
    font-size: 25px;
  }
`;
const List = styled(CenteredByColumnLeft)`
  ${card}
  margin: 10px;
  padding: 20px;
  width: 50%;
`;
const Entry = styled(CenteredByColumnLeft)`
  margin-bottom: 20px;
`;

const Name = styled(TextTitle)`
  margin-bottom: 10px;
`;
const SubList = styled(CenteredByColumnLeft)``;
const BulletPoint = styled(Text12300)`
  text-transform: capitalize;
  text-align: left;
`;
export const PageFavoritesOverview: React.FC<PageFavoritesOverviewProps> =
  (): ReactElement => {
    const currentUser = useSelector((state: RootState) => state.currentUser);
    // const userType = useUserType();

    const fetch = useCallback(async () => {
      return await GetFavoritesOverview(currentUser?.user?._id);
    }, [currentUser?.user?._id]);

    const { data } = useAsync({ promiseFn: fetch });
    LOGGING && console.log("PageFavoritesOverview got data:", data);
    if (data == null) return <Loading />;

    const { favoritesByRestaurant, favoritesByUser } = data;
    LOGGING &&
      console.log("PageFavoritesOverview got:", {
        favoritesByRestaurant,
        favoritesByUser,
      });

    return (
      <div className="page">
        <CloseButton href={`/`}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
        <h1>Favorites</h1>
        <ContentContainer>
          <List>
            {favoritesByRestaurant.map((f) => (
              <Entry>
                <Name>{f.restaurant.name}</Name>
                <SubList>
                  {f.users.map((u) => (
                    <BulletPoint>{`${u.firstName} ${u.lastName}`}</BulletPoint>
                  ))}
                </SubList>
              </Entry>
            ))}
          </List>
          <List>
            {favoritesByUser.map((u) => (
              <Entry>
                <Name>{u.user.name}</Name>
                <SubList>
                  {u.favorites.map((f) => (
                    <BulletPoint>{f.name}</BulletPoint>
                  ))}
                </SubList>
              </Entry>
            ))}
          </List>
        </ContentContainer>
      </div>
    );
  };
