import React from "react";
import "./Checkout.scss";
import { faGlassCheers } from "@fortawesome/pro-light-svg-icons";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OrderTips } from "src/components/OrderTips";
import {
  LoadingFull,
  Back,
  DeliveryInfoCompany,
  DeliveryInfoForm,
  OrderTotal,
  NewCardForm,
  ExistingCard,
  LOGGING,
} from "./";
import { CheckoutWithPaypalContainer } from "./CheckoutWithPaypal";
import { BottomBanner } from "./customers/banners/BottomBanner";
import { MemberSavingShort } from "./customers/banners/MemberSaving";
import { ToSaveByMembership } from "./customers/banners/MemberSaving";
import { BenefitsComparedToDoordash } from "./customers/checkout/BenefitsComparedToDoordash";
import { CartSummary } from "./customers/checkout/CartSummary";
import { CheckoutNav } from "./customers/checkout/CheckoutNav";
import { Contact } from "./customers/checkout/Contact";
import {
  Delivery,
  EditDeliveryPayemntInfo,
  SaveDeliveryPayemntInfo,
} from "./customers/checkout/Delivery";
import { Fees } from "./customers/checkout/Fees";
import { FreeMeal } from "./customers/checkout/FreeMeal";
import { ToSaveByMembershipOnCheckout } from "./customers/checkout/MemberSaving";
import { MinValueCheck } from "./customers/checkout/MinValueCheck";
import {
  CheckoutContainer,
  LeftPanel,
  RightPanel,
} from "./customers/checkout/PageCheckout";
import { PayButton } from "./customers/checkout/PayButton";
import { Payment } from "./customers/checkout/Payment";
import { Total } from "./customers/checkout/Total";
import { CountDown } from "./customers/mealMenu/CountDown";
import { OrderDiscount } from "../../../components/OrderDiscount";

const CheckoutWithCard = ({
  onToggleShowCardAction,
  initializing,
  existingCard,
  showCardAction,
  onDeleteCard,
  onChangeNumber,
  newCard,
  error,
  isAuthenticated,
  cardType,
  saveCard,
  onToggleSaveCard,
  deliveryInfo,
  onChange,
  onEditAddress,
  onToggleRingBell,
  onSelectAddress,
  onSubmit,
  isCompanyOrder,
  order,
  onStartMembership,
  onResumeMembership,
  isMember,
  wasMember,
  isNotOrganizer,
}) => {
  return (
    <>
      {" "}
      {existingCard ? (
        <div className="form-section">
          <span className="form-section-title">Card</span>
          <ExistingCard
            onToggleShowCardAction={onToggleShowCardAction}
            showCardAction={showCardAction}
            onDeleteCard={onDeleteCard}
            existingCard={existingCard}
          />
        </div>
      ) : (
        <React.Fragment>
          <div className="form-section">
            <span className="form-section-title">Card</span>
            <NewCardForm
              onChangeNumber={onChangeNumber}
              newCard={newCard}
              error={error}
              cardType={cardType}
            />
          </div>
          {isAuthenticated && (
            <div className="form-section save-card">
              <div className="form-row">
                <button
                  className={`save-card ${saveCard ? "checked" : "unchecked"}`}
                  onClick={onToggleSaveCard}
                >
                  <FontAwesomeIcon icon={faCheck} />
                  <span>save card</span>
                </button>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
      {isCompanyOrder ? (
        <DeliveryInfoCompany deliveryInfo={deliveryInfo} />
      ) : (
        <DeliveryInfoForm
          deliveryInfo={deliveryInfo}
          onChange={onChange}
          onChangeNumber={onChangeNumber}
          error={error}
          onEditAddress={onEditAddress}
          onToggleRingBell={onToggleRingBell}
          onSelectAddress={onSelectAddress}
        />
      )}
      {/* {!isMember ? (
        <ToSaveByMembership
          cart={order}
          card={existingCard}
          onStartMembership={onStartMembership}
          onResumeMembership={onResumeMembership}
          wasMember={wasMember}
          isAuthenticated={isAuthenticated}
        />
      ) : null} */}
      {/* <BottomBanner
      label={`${initializing ? "connecting..." : "place order"}`}
      onClick={onSubmit}
      disabled={initializing}
    /> */}
    </>
  );
};

const CheckoutFree = ({
  deliveryInfo,
  onChange,
  onChangeNumber,
  onEditAddress,
  onToggleRingBell,
  onSelectAddress,
  onSubmit,
  error,
  isCompanyOrder,
  initializing,
}) => (
  <div className="checkout">
    <div className="free-order-title-wrapper">
      <div className="free-order-title">
        <div className="icon-wrapper">
          <FontAwesomeIcon icon={faGlassCheers} />
        </div>
        <span>Enjoy your free meal!</span>
      </div>
    </div>
    {isCompanyOrder ? (
      <DeliveryInfoCompany deliveryInfo={deliveryInfo} />
    ) : (
      <DeliveryInfoForm
        deliveryInfo={deliveryInfo}
        onChange={onChange}
        onChangeNumber={onChangeNumber}
        error={error}
        onEditAddress={onEditAddress}
        onToggleRingBell={onToggleRingBell}
        onSelectAddress={onSelectAddress}
      />
    )}
    <button className="place-order" onClick={onSubmit} disabled={initializing}>
      {`${initializing ? "connecting..." : "place order"}`}
    </button>
  </div>
);

const Checkout = ({
  isCompanyOrder,
  onChange,
  onChangeNumber,
  order,
  orderBy,
  onSubmit,
  onSubmitFreeMeal,
  newCard,
  loading,
  initializing,
  error,
  onToggleSaveCard,
  saveCard,
  existingCard,
  deliveryInfo,
  showCardAction,
  onToggleShowCardAction,
  onDeleteCard,
  cardType,
  onBack,
  isAuthenticated,
  onPaypalSuccess,
  onPaypalAuthorize,
  paypalInfo,
  onTogglePaymentMethod,
  paymentMethod,
  onPaypalCapturing,
  onEditAddress,
  onToggleRingBell,
  onSelectAddress,
  payPalOrderAddressConfirmed,
  onConfirmPaypalAddress,
  onEditPaypalAddress,
  canMakeGroupOrder,
  onSubmitOrderWithCompanyCard,
  isMember,
  wasMember,
  onStartMembership,
  onResumeMembership,
  onChangeTips,
  isNotOrganizer,
  initialTip,
  isEditingTip,
  couponCodeError,
  isEditingDeliveryPaymentInfo,
  onToggleEditDeliveryPaymentInfo,
  onApplyCouponCode,
  applyingCoupon,
  paying,
  isCartValid,
  onSetIsCartValid,
}) => {
  LOGGING &&
    console.log(
      "Checkout rendering with: ",
      order.due,
      isEditingDeliveryPaymentInfo
    );
  // <div className={`checkout-form  ${loading ? "loading-container" : ""}`}>
  return (
    <CheckoutContainer>
      <CheckoutNav
        onSubmit={onSubmit}
        onSubmitFreeMeal={onSubmitFreeMeal}
        paying={paying}
        initializing={initializing}
        isCartValid={isCartValid}
      />
      {/* <Back onClick={onBack} style={{ left: "9vw" }} /> */}
      {/* {loading ? <LoadingFull text="Submitting..." /> : null}
      {error?.message ? (
        <div style={{ margin: "20px", color: "red" }}>
          There was an error: {error?.message}
        </div>
      ) : null} */}
      {/* <div className="list-title cart-title">
        <Back onClick={onBack} />
        <span>Checkout</span>
        
      </div>
      */}
      {!isEditingDeliveryPaymentInfo ? (
        <LeftPanel>
          <EditDeliveryPayemntInfo onEdit={onToggleEditDeliveryPaymentInfo} />
          {order?.due > 0 ? <Payment /> : null}
          <Contact />
          <Delivery />
        </LeftPanel>
      ) : (
        <LeftPanel isEditing={true}>
          {order.due !== 0 ? (
            <div className={`list-body ${loading ? "loading-content" : ""}`}>
              {canMakeGroupOrder ? (
                <div className="payment-method group-order">
                  {error.param === "company" ? (
                    <div className="error-message">
                      {error && error.message ? error.message : ""}
                    </div>
                  ) : null}
                  <button
                    className={`select-payment-method ${
                      paymentMethod === "group"
                        ? "selected-method"
                        : "unselected-method"
                    }`}
                    onClick={onTogglePaymentMethod.bind(this, "group")}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                    <span>pay by company card</span>
                  </button>{" "}
                  <button
                    className={`select-payment-method ${
                      paymentMethod === "card"
                        ? "selected-method"
                        : "unselected-method"
                    }`}
                    onClick={onTogglePaymentMethod.bind(this, "card")}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                    <span>pay by personal card</span>
                  </button>
                  <button
                    className={`select-payment-method ${
                      paymentMethod === "paypal"
                        ? "selected-method"
                        : "unselected-method"
                    }`}
                    onClick={onTogglePaymentMethod.bind(this, "paypal")}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                    <span>pay by personal paypal</span>
                  </button>
                </div>
              ) : (
                <div className="payment-method personal-order">
                  <button
                    className={`select-payment-method ${
                      paymentMethod === "card"
                        ? "selected-method"
                        : "unselected-method"
                    }`}
                    onClick={onTogglePaymentMethod.bind(this, "card")}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                    <span>pay by card</span>
                  </button>
                  <button
                    className={`select-payment-method ${
                      paymentMethod === "paypal"
                        ? "selected-method"
                        : "unselected-method"
                    }`}
                    onClick={onTogglePaymentMethod.bind(this, "paypal")}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                    <span>pay by paypal</span>
                  </button>
                </div>
              )}
              {paymentMethod === "card" ? (
                <CheckoutWithCard
                  onToggleShowCardAction={onToggleShowCardAction}
                  existingCard={existingCard}
                  showCardAction={showCardAction}
                  onDeleteCard={onDeleteCard}
                  onChangeNumber={onChangeNumber}
                  newCard={newCard}
                  error={error}
                  order={order}
                  initializing={initializing}
                  isAuthenticated={isAuthenticated}
                  cardType={cardType}
                  saveCard={saveCard}
                  onToggleSaveCard={onToggleSaveCard}
                  deliveryInfo={deliveryInfo}
                  onChange={onChange}
                  onSubmit={onSubmit}
                  onEditAddress={onEditAddress}
                  onToggleRingBell={onToggleRingBell}
                  onSelectAddress={onSelectAddress}
                  isCompanyOrder={isCompanyOrder}
                  onStartMembership={onStartMembership}
                  onResumeMembership={onResumeMembership}
                  isMember={isMember}
                  wasMember={wasMember}
                />
              ) : null}
              {paymentMethod === "paypal" ? (
                <CheckoutWithPaypalContainer
                  deliveryInfo={deliveryInfo}
                  initializing={initializing}
                  paypalInfo={paypalInfo}
                  onPaypalSuccess={onPaypalSuccess}
                  onPaypalAuthorize={onPaypalAuthorize}
                  order={order}
                  onChange={onChange}
                  onChangeNumber={onChangeNumber}
                  error={error}
                  onPaypalCapturing={onPaypalCapturing}
                  payPalOrderAddressConfirmed={payPalOrderAddressConfirmed}
                  onConfirmPaypalAddress={onConfirmPaypalAddress}
                  onEditPaypalAddress={onEditPaypalAddress}
                  onSelectPaypalAddress={onSelectAddress}
                  onEditAddress={onEditAddress}
                  onToggleRingBell={onToggleRingBell}
                  isCompanyOrder={isCompanyOrder}
                />
              ) : null}
              {paymentMethod === "group" && (
                <div>
                  <button
                    className="place-order"
                    onClick={onSubmitOrderWithCompanyCard}
                  >
                    PLACE ORDER
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className={`list-body ${loading ? "loading-content" : ""}`}>
              <CheckoutFree
                deliveryInfo={deliveryInfo}
                onChange={onChange}
                onSubmit={onSubmitFreeMeal}
                onEditAddress={onEditAddress}
                onToggleRingBell={onToggleRingBell}
                onSelectAddress={onSelectAddress}
                onChangeNumber={onChangeNumber}
                error={error}
                isCompanyOrder={isCompanyOrder}
                initializing={initializing}
              />
            </div>
          )}
          {/* <SaveDeliveryPayemntInfo /> */}
        </LeftPanel>
      )}
      <RightPanel>
        {/* <PayButton /> */}
        {!initializing && <FreeMeal />}
        {initializing ? null : (
          <MinValueCheck onSetIsCartValid={onSetIsCartValid} />
        )}
        <BenefitsComparedToDoordash />
        {isMember ? null : (
          <ToSaveByMembershipOnCheckout
            wasMember={wasMember}
            onStartMembership={onStartMembership}
            onResumeMembership={onResumeMembership}
          />
        )}
        <CartSummary />
        {isNotOrganizer ? null : (
          <OrderDiscount
            couponCode={order?.couponCode?.code}
            error={couponCodeError}
            onApplyCouponCode={onApplyCouponCode}
            applyingCoupon={applyingCoupon}
          />
        )}
        {/* <CountDown deadline={orderBy} /> */}
        <Fees />

        {isNotOrganizer ? null : (
          <OrderTips
            onChangeTips={onChangeTips}
            sum={order.sum}
            tax={order.tax}
            defaultTips={order.tips}
            initialTip={initialTip}
            tipRate={order.tipRate}
            tipCustom={order.tipCustom}
            customerName={`${order?.user?.firstName} ${order?.user?.lastName}`}
            isEditingTip={isEditingTip}
          />
        )}
        <Total />
      </RightPanel>
    </CheckoutContainer>
  );
};
export default Checkout;
