import { LOGGING } from 'src/constants';
import { apiCall } from 'src/store/api';

export const GetIsRouteDirtyForDriverAndStops = (
  driverId: string,
  stopIds: Array<string>
): ((dispatch, getState) => Promise<boolean>) => {
  return async (_, getState): Promise<boolean> => {
    const { currentUser } = getState();
    try {
      const result = await apiCall(
        'post',
        `/routes/GetIsRouteDirtyForDriverAndStops`,
        {
          driverId: driverId,
          stopIds: stopIds,
        },
        currentUser
      );
      return result.isDirty;
    } catch (err) {
      LOGGING && console.log('GetStatsForMeal got err', err);
    }
  };
};
