import { useEffect, useState, useMemo } from "react";
import { LOGGING } from "src/constants";
import { Centered } from "src/screens/tasting_menu/components/Shared";
import styled from "styled-components";
const CustomTip = styled.input`
  padding-left: 22px !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  padding: 10px;
  box-sizing: content-box;
`;
type DebounceInputProps = {
  placeholder: string;
  initialTip: number;
  onChange: (
    tips: number,
    tipRate: number,
    tipCustom: number,
    isEditingTip: boolean
  ) => void;
  setShowDefaultTips: (boolean) => void;
};
export const DebounceInput = (props: DebounceInputProps) => {
  const { onChange, placeholder, setShowDefaultTips, initialTip } = props;
  LOGGING &&
    console.log("DebounceInput rendering with", { placeholder, initialTip });
  const [pinCode, setPinCode] = useState("");
  const [isEdited, setIsEdited] = useState(false);
  const [isFocused, setIsFocused] = useState(false); // State to track focus
  useEffect(() => {
    const getData = setTimeout(() => {
      LOGGING && console.log("DebounceInput useEffect pinCode:", pinCode);
      if (pinCode.length > 0) {
        const newTip = Number(Number(pinCode).toFixed(2));
        LOGGING && console.log("DebounceInput useEffect newTip:", newTip);
        onChange(newTip, null, newTip, isEdited);
      }
      // If there is not input, no need to call backend
      // else {
      //   // const newTip = Number(Number(initialTip).toFixed(2));
      //   const newTip = Number(
      //     Number(isEdited ? initialTip : placeholder).toFixed(2)
      //   );
      //   LOGGING && console.log("DebounceInput useEffect newTip2:", newTip);
      //   onChange(newTip, null, newTip, isEdited);
      // }
    }, 300);

    return () => clearTimeout(getData);
  }, [pinCode]);

  return (
    <CustomTip
      // showPlaceHolderOnFocus={showPlaceHolderOnFocus}
      // beforeContent={"$"+placeholder}
      // placeholder={"$"+placeholder}
      // allowNegative={false}
      value={pinCode}
      defaultValue={placeholder}
      // decimalScale={2}
      onFocus={() => {
        setIsFocused(true); // Set isFocused to true when input is focused
      }}
      onBlur={() => setIsFocused(false)}
      onChange={(e) => {
        e.preventDefault();
        if (!isEdited) {
          setIsEdited(true);
        }
        const inputValue = e.target.value;
        // Regex pattern to match non-negative numbers with exactly 2 decimal places
        const regex = /^\d*\.?\d{0,2}$/;
        setShowDefaultTips(inputValue.length === 0);
        // setPinCode(string(initialTip);
        if (inputValue.length === 0 || regex.test(inputValue)) {
          setPinCode(inputValue);
        }
      }}
    />
  );
};
