import React, { ReactElement, useEffect, useState } from "react";
import {
  faHouse,
  faTruck,
  faChevronRight,
  faChevronLeft,
  faInfoCircle,
  faCheck,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { useAsync } from "react-async";
import { useSelector, useDispatch, useStore } from "react-redux";
import { Link } from "react-router-dom";
import { GetReferrerFromCode } from "src/api/referrals";
import { CheckoutBanner } from "src/screens/tasting_menu/components";
import { RootState } from "src/store";
import { addGoodsToCart } from "src/store/actions";
import styled, { keyframes } from "styled-components";
import { LOGGING, SampleRestaurantsMobile, CoverImages } from "..";
import { DishChooserContainer } from "../dish/DishChooserContainer";
import { breakPoint } from "../referrals/MyReferralCodeEventMember";
import {
  CenteredByColumn,
  Text12300,
  CenteredByColumnLeft,
  BorderGrey,
  Centered,
  Yellow,
  Beige,
  Grey,
  Pink,
  text12300,
  card,
  centeredByColumn,
  NavHeight,
  textTitle,
  centered,
  ActionBlack,
  actionBlack,
  actionGrey,
  TextTitle,
} from "../Shared";
const screenWidth = 250;
const screenHeightRatio = 2;
const screenShiftH = 100;
const screenShiftV = 15;

const ContentContainer = styled.div`
  position: relative;
  display: none;
  @media only screen and (min-width: 800px) {
    display: block;
    width: ${2 * screenShiftH + screenWidth}px;
    height: ${3 * screenShiftV + screenWidth * screenHeightRatio}px;
    margin-right: 20px;
  }
  @media only screen and (min-width: 840px) {
    margin-right: 40px;
  }
`;
const TagLineContainer = styled(Text12300)`
  background-color: red;
`;
export const TagLine = () => (
  <TagLineContainer>
    {/* Getting restaurant food delivered for the same price as eating at restaurant. */}
    30% cheaper
  </TagLineContainer>
);
export interface ImageProps {
  src: string;
  index: number;
}
const Image = styled.div<ImageProps>`
  flex-shrink: 0;
  background-image: url(${(props) => props.src});
  width: ${screenWidth}px;
  height: ${screenWidth * screenHeightRatio}px;
  background-position: center;
  background-size: cover;
  display: block;
  position: absolute;
  top: ${(props) => `${props.index * screenShiftV}px`};
  left: ${(props) => `${props.index * screenShiftH}px`};
  z-index: ${(props) => props.index};
  background-color: transparent;
  border-radius: 40px;
  /* box-shadow: 0 16px 4px 4px rgba(28, 29, 33, 0.5); */
`;

export interface CoverProps {}
export const Cover: React.FC<CoverProps> = ({}: CoverProps): ReactElement => {
  return (
    <ContentContainer>
      {CoverImages.map((image, index) => (
        <Image src={image} key={index} index={index}></Image>
      ))}
    </ContentContainer>
  );
};

const SlideSize = 130;
const SlidesContainer = styled(Centered)`
  width: ${SlideSize}px;
  height: ${SlideSize}px;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  @media only screen and (min-width: 800px) {
    display: none;
  }
  @media only screen and (max-height: 700px) and (max-width: 400px) {
    margin-bottom: 10px;
  }
`;
export interface RestaurantCoverProps {
  src: string;
  visible: boolean;
  // name: string;
  // index: number;
}
const RestaurantCover = styled(Centered)<RestaurantCoverProps>`
  width: ${SlideSize}px;
  height: ${SlideSize}px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  flex-shrink: 0;
  border: 1px;
  position: absolute;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 1s ease-in-out;
`;

export interface OverlayProps {
  visible: boolean;
}
const Overlay = styled(Centered)<OverlayProps>`
  width: ${SlideSize}px;
  height: ${SlideSize}px;
  border-radius: 50%;
  flex-shrink: 0;
  border: 1px;
  position: absolute;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 1s ease-in-out;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  /* ${textTitle}
  font-size: 12px; */
  ${text12300}
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 1px;
  text-align: center;
  line-height: 1.3;
  padding: 10px;
`;
export const AnonymousRestaurantSlides = ({ noText }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const duration = 3000;
  useEffect(() => {
    const slideShowInterval = setInterval(() => {
      setCurrentSlide((currentSlide + 1) % SampleRestaurantsMobile.length);
    }, duration);

    return () => clearInterval(slideShowInterval);
  }, [currentSlide, duration, SampleRestaurantsMobile.length]);

  return (
    <SlidesContainer>
      {SampleRestaurantsMobile.map(({ image, name }, index) => (
        <RestaurantCover
          key={index}
          src={image}
          // alt={`Slide ${index}`}
          visible={index === currentSlide}
        >
          <Overlay visible={index === currentSlide}>
            {noText ? "" : name}
          </Overlay>
        </RestaurantCover>
      ))}
    </SlidesContainer>
  );
};
