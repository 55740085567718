import moment from 'moment-timezone';
import { LOAD_DISHES, RESET_DISHES } from '../actionTypes';

const dishes = (state = { payLoad: [], loadedAt: moment() }, action) => {
  switch (action.type) {
    case LOAD_DISHES:
      return { payLoad: action.dishes, loadedAt: moment() };
    case RESET_DISHES:
      return { payLoad: [], loadedAt: moment() };
    default:
      return state;
  }
};

export default dishes;
