import './Change.scss';
import { faCheck, faChevronUp, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PopUp } from './';

export const Change = ({
  options,
  onChange,
  selected,
  showOptions,
  onToggleShowOptions,
  className,
  disabled,
  icon,
}) => (
  <div className={`change${className ? ' ' + className : ''}`}>
    <button className="selected" onClick={onToggleShowOptions} disabled={disabled}>
      <span>{selected || 'Please select'}</span>
      <FontAwesomeIcon icon={icon || (showOptions ? faChevronUp : faChevronDown)} />
    </button>
    <PopUp
      isPoppedUp={showOptions}
      componentToDisplay={
        <ul className="option-list">
          {options.map((option, index) => (
            <li key={index} className={option === selected ? 'selected' : ''}>
              <button onClick={onChange.bind(this, index)}>
                <span>{option}</span>
                {option === selected ? <FontAwesomeIcon icon={faCheck} /> : null}
              </button>
            </li>
          ))}
        </ul>
      }
      hidePopUp={onToggleShowOptions}
      backgroundColor="transparent"
    />
  </div>
);
export default Change;
