import { useEffect, useState } from "react";
import {
  faFlowerTulip,
  faGrinHearts,
  faGrinWink,
  faSparkles,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberFormat from "react-number-format";
import { MealVisited } from "src/api/meals";
import { Flex, Grid } from "src/components/Layout";
import { LOGGING } from "src/constants";
import {
  Field,
  Container,
} from "src/screens/tasting_menu/components/customers/checkout/Contact";
import styled from "styled-components";
import { DebounceInput } from "./DebounceInput";
import {
  BackgroundGrey,
  BorderGrey,
  Centered,
  CenteredByColumn,
  Text12300,
  TextGrey,
  text12300,
} from "../../screens/tasting_menu/components/Shared";

type OrderTipsProps = {
  sum: number;
  tax: number;
  defaultTips: number;
  initialTip: number;
  tipRate: number;
  tipCustom: number;
  onChangeTips: (tips: number, tipRate: number, tipCustom: number) => void;
  customerName?: string;
  isEditingTip?: boolean;
};

const PRESET_TIP_PERCENTAGE = [
  {
    title: "10%",
    rate: 0.1,
    absoluteTitle: "$0",
    absoluteAmount: 0,
    message: "Appreciate it!",
    messageIcon: faGrinWink,
  },
  {
    title: "15%",
    rate: 0.15,
    absoluteTitle: "$1",
    absoluteAmount: 1,
    isDefault: true,
    message: "Thank you!",
    messageIcon: faFlowerTulip,
  },
  {
    title: "18%",
    rate: 0.18,
    absoluteTitle: "$2",
    absoluteAmount: 2,
    message: "You are so awesome!!",
    messageIcon: faGrinHearts,
  },
  {
    title: "20%",
    rate: 0.2,
    absoluteTitle: "$3",
    absoluteAmount: 3,
    message: "You are the best!!!",
    messageIcon: faSparkles,
  },
];
const PercentTips = styled(Centered)`
  align-self: stretch;
  align-content: stretch;
  /* border: 1px solid ${BorderGrey}; */
  background-color: ${BorderGrey} !important;
`;
const CustomTip = styled.button`
  ${text12300}
  font-weight: 500;
  margin-top: 12px;
  margin: 15px 0px 10px 0px;
  /* background-color: pink; */
`;
const CUSTOM_MESSAGE = "Appreciate it!";
const CUSTOM_MESSAGE_ICON = faGrinWink;
const Placeholder = styled(Centered)`
  position: absolute;
  left: 0px;
  top: 5px;
  justify-content: flex-start;
  height: 42px;
  /* background-color: yellow; */
  span {
    ${text12300}
    font-size: 16px;
    line-height: 16px;
    padding-left: 0px;
    font-weight: 500;
    /* color: ${TextGrey}; */
  }
  label {
    ${text12300};
    line-height: 16px;
    font-weight: 500;
    font-size: 16px;
    width: 24px;
    padding-left: 2px;
    box-sizing: border-box;
    padding-bottom: 1px;
    text-align: center;
    /* background: purple; */
  }
`;
const CustomTipContainer = styled(Centered)`
  align-self: stretch;
`;
const CustomTipContent = styled(Centered)`
  position: relative;
  border: none;
  /* width: 60px; */
  border: 1px solid black;
  /* background-color: ${BackgroundGrey}; */
  > input {
    margin: 5px 0px 0px 0px;
    padding: 10px;
    border: none;
  }
`;
const EditingTipContainer = styled(CenteredByColumn)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  ${text12300}
  text-transform: capitalize;
  font-size: 16px;
  z-index: 100;
  font-weight: 500;
`;
/// create a new component called WaitingCircle that has an animation that spins a 3/4 dotted circle
const EditingTipCircle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid white;
  border-top: 2px solid black;
  border-left: 2px solid black;
  margin-bottom: 20px;
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const EditingTip = () => (
  <EditingTipContainer>
    <EditingTipCircle />
    <span>updating tip...</span>
  </EditingTipContainer>
);

export const OrderTips = (props: OrderTipsProps) => {
  const {
    sum,
    tax,
    defaultTips,
    tipRate,
    tipCustom,
    onChangeTips,
    customerName,
    isEditingTip,
  } = props;

  // const subtotalWithTax = sum + tax;
  const subtotalWithoutTax = sum;
  const presetTipRates = PRESET_TIP_PERCENTAGE.map((tip) => tip.rate);
  const formula =
    tipCustom !== null && tipCustom !== undefined && tipCustom >= 0;
  // LOGGING &&
  //   console.log("OrderTips called with", {
  //     sum,
  //     tax,
  //     defaultTips,
  //     tipRate,
  //     tipCustom,
  //     presetTipRates,
  //     formula,
  //     isEditingTip,
  //   });

  const [showCustomTip, setShowCustomTip] = useState<boolean>(false);
  const [isCustom, setIsCustom] = useState<boolean>(formula);
  const [selectedTips, setSelectedTips] = useState<number>(-1);
  const [tips, setTips] = useState<number>(defaultTips);
  const [showDefaultTips, setShowDefaultTips] = useState<boolean>(true);

  useEffect(() => {
    // LOGGING && console.log("OrderTips useEffect1 called with", defaultTips);
    setIsCustom(
      tipCustom !== null && tipCustom !== undefined && tipCustom >= 0
    );
    setSelectedTips(presetTipRates.indexOf(tipRate));
    setTips(
      tipCustom !== null && tipCustom !== undefined
        ? tipCustom
        : Number((tipRate * sum).toFixed(2))
    );
    // setShowDefaultTips()
  }, [tipCustom, tipRate]);

  useEffect(() => {
    setTips(defaultTips);
  }, [defaultTips]);

  // LOGGING &&
  //   console.log("OrderTips rendering with props: ", {
  //     sum,
  //     defaultTips,
  //     selectedTips,
  //     isCustom,
  //     tipRate,
  //     tipCustom,
  //   });
  return (
    <Container>
      {isEditingTip && <EditingTip />}
      <Field>
        <label>Tip</label>
        <span>${tips?.toFixed(2)}</span>
      </Field>
      {/* 
      <Message>
        <span>
          {isCustom || selectedTips === -1
            ? CUSTOM_MESSAGE
            : PRESET_TIP_PERCENTAGE[selectedTips].message}
        </span>
        <FontAwesomeIcon
          icon={
            isCustom || selectedTips === -1
              ? CUSTOM_MESSAGE_ICON
              : PRESET_TIP_PERCENTAGE[selectedTips].messageIcon
          }
        />
      </Message> */}

      <TipsContainer>
        <PercentTips>
          {PRESET_TIP_PERCENTAGE.map((tips, index) => (
            <Tips
              // key={subtotalWithoutTax <= 10 ? tips.absoluteTitle : tips.title}
              key={tips.title}
              $selected={!isCustom && selectedTips === index}
              onClick={() => {
                setIsCustom(false);
                setShowCustomTip(false);
                setSelectedTips(index);
                onChangeTips(subtotalWithoutTax * tips.rate, tips.rate, null);
              }}
              $isLast={false}
            >
              {/* {subtotalWithoutTax > 10 ? (
                <span className="tip-rate">{tips.title}</span>
              ) : null} */}
              <span className="tip-rate">{tips.title}</span>
              <span className="tip-amount">
                $
                {
                  // subtotalWithoutTax <= 10
                  // ? tips.absoluteAmount
                  // :
                  (subtotalWithoutTax * tips.rate).toFixed(2)
                }
              </span>
            </Tips>
          ))}
        </PercentTips>
        <CustomTip
          onClick={async (e) => {
            e.preventDefault();
            setShowCustomTip(true);
            // onChangeTips(defaultTips, null, defaultTips);
            MealVisited("custom_tip", customerName, "", "", 0);
          }}
        >
          custom tip
        </CustomTip>
      </TipsContainer>
      <CustomTipContainer>
        {(isCustom || showCustomTip) && (
          <CustomTipContent>
            <DebounceInput
              initialTip={defaultTips}
              placeholder={String(defaultTips)}
              onChange={onChangeTips}
              setShowDefaultTips={setShowDefaultTips}
            />
            <Placeholder>
              <label>$</label>
              <span>{String(defaultTips)}</span>
            </Placeholder>
          </CustomTipContent>
        )}
      </CustomTipContainer>
    </Container>
  );
};

const TipsContainer = styled(CenteredByColumn)`
  margin-top: 15px;
  align-self: stretch;
  /* align-items: flex-end; */
`;
const Message = styled.div`
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 5px 0px 0px 0px;
  left: 0px;
  span {
    font-weight: 500;
    font-family: "Theano Didot", serif;
    font-size: 13px;
    line-height: 15px;
  }
  svg {
    margin-left: 5px;
    font-size: 13px;
    line-height: 15px;
  }
`;
const Tips = styled.div<{ $selected?: boolean; $isLast?: boolean }>`
  &:last-child {
    border-right: none;
  }
  height: 100%;
  &:hover {
    cursor: pointer;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
  align-self: stretch;
  flex-grow: 1;
  span {
    line-height: 1.5;
  }
  span.tip-rate {
    font-size: 10px;
  }
  span.tip-amount {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.2px;
  }
  background-color: ${BorderGrey};
  ${({ $selected }) =>
    $selected
      ? `color: white;
        background: black;
        opacity:0.8;
        `
      : `
        background: ${BackgroundGrey};
      `}
  ${({ $isLast }) =>
    $isLast
      ? `width: calc(400% + 18px);

          `
      : `

        `}
`;

const CustomInput = styled(NumberFormat)`
  margin-top: 12px;
  width: 100%;
  background: white;
  font-size: 12px;
  border: 1px solid rgb(118, 118, 118);
  box-sizing: border-box;
  padding: 10px;
`;
