import { SET_VIEW_BY_WEEK } from '../actionTypes';

const viewByWeek = (state = true, action) => {
  switch (action.type) {
    case SET_VIEW_BY_WEEK:
      return action.viewByWeek;
    default:
      return state;
  }
};

export default viewByWeek;
