import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LOGGING, Loading, NominationList, Navbar } from '.';
import { readNominations, deleteNomination } from '../../store/actions';
import { readRestaurants } from '../../store/actions';

class PageNominations extends Component {
  constructor() {
    super();
    LOGGING && console.log('PageNominations constructor');
    this.state = {
      loading: true,
      nominations: [],
      userFilter: null,
      restaurantFilter: null,
      currentRestaurantsFilter: null,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleFilterByUser = this.handleFilterByUser.bind(this);
    this.handleFilterByRestaurant = this.handleFilterByRestaurant.bind(this);
    this.handleFilterCurrentRestaurants = this.handleFilterCurrentRestaurants.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }
  handleDelete(nominationId, e) {
    e.preventDefault();
    this.setState({ loading: true }, () => {
      this.props.deleteNomination(nominationId);
      const { nominations } = this.state;
      this.setState({
        nominations: nominations.filter((n) => n._id !== nominationId),
        loading: false,
      });
    });
  }
  handleFilterByUser(userId, e) {
    e.preventDefault();
    const { userFilter } = this.state;
    if (userFilter === userId) {
      this.setState({ userFilter: null });
    } else {
      this.setState({ userFilter: userId });
    }
  }

  handleFilterByRestaurant(restaurantName, e) {
    e.preventDefault();
    const { restaurantFilter } = this.state;
    if (restaurantFilter === restaurantName) {
      this.setState({ restaurantFilter: null });
    } else {
      this.setState({ restaurantFilter: restaurantName });
    }
  }

  handleFilterCurrentRestaurants(e) {
    e.preventDefault();
    const { currentRestaurantsFilter } = this.state;
    if (currentRestaurantsFilter) {
      this.setState({ currentRestaurantsFilter: null });
    } else {
      const activeRestaurantAddresses = this.props.restaurants
        .filter((restaurant) => restaurant.level === 2)
        .map((restaurant) =>
          restaurant?.address
            ?.toLowerCase()
            .replace('#', '')
            .replace('.', '')
            .replace('  ', ' ')
            .trim()
        )
        .map((restaurantAddress) => trimRestaurantAddress(restaurantAddress));
      this.setState({ currentRestaurantsFilter: activeRestaurantAddresses });
    }
  }

  handleClose() {
    LOGGING && console.log('PageNominations handleClose');
    this.props.history.goBack();
  }

  componentDidMount() {
    LOGGING && console.log('PageNominations componentdidmount');
    this.setState({ loading: true }, () => {
      this.props.readNominations().then((nominations) => {
        LOGGING && console.log('PageNominations got:', nominations);
        this.setState({ loading: false, nominations }, () => {
          window.scrollTo(0, 0);
        });
      });
    });
  }

  render() {
    const { currentUser, restaurants } = this.props;
    const { loading, nominations, userFilter, restaurantFilter, currentRestaurantsFilter } =
      this.state;
    LOGGING &&
      console.log('PageNominations rendering with', {
        props: this.props,
        state: this.state,
      });

    if (!currentUser.isAuthenticated || !currentUser.user.isAdmin) {
      return <Redirect to="/" />;
    }

    return (
      <div className="page">
        <Navbar />
        {loading ? (
          <Loading />
        ) : (
          <NominationList
            nominations={nominations}
            onBack={this.handleClose}
            userFilter={userFilter}
            restaurantFilter={restaurantFilter}
            restaurants={restaurants}
            currentRestaurantsFilter={currentRestaurantsFilter}
            onFilterCurrentRestaurants={this.handleFilterCurrentRestaurants}
            onFilterByUser={this.handleFilterByUser}
            onFilterByRestaurant={this.handleFilterByRestaurant}
            onDelete={this.handleDelete}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log('PageNominations got redux state:', state);
  return {
    currentUser: state.currentUser,
    restaurants: state.restaurants,
  };
}

export function trimRestaurantAddress(address) {
  if (address && address.includes(',')) {
    return address.slice(0, address.indexOf(','));
  } else {
    return address;
  }
}

export default connect(mapStateToProps, {
  readNominations,
  readRestaurants,
  deleteNomination,
})(PageNominations);
