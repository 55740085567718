import moment from "moment-timezone";
import { LOGGING } from "../../constants";
import { SET_HIGHLIGHT, RESET_HIGHLIGHT } from "../actionTypes";
import { apiCall } from "../api";

const setHighlight = (highlight) => ({
  type: SET_HIGHLIGHT,
  highlight,
});

const resetHighlight = () => ({
  type: RESET_HIGHLIGHT,
});

export const readHighlight = () => {
  return (dispatch, getState) => {
    const { highlight } = getState();
    // LOGGING && console.log("readHighlight already has highlight:", highlight);
    if (
      highlight?.meal?.windowStart &&
      highlight?.meal?.windowStart < moment().valueOf()
    ) {
      return;
    }
    // LOGGING && console.log("readHighlight needs to read from server");
    return apiCall(
      "get",
      "/meals/GetLatestHighlightMeal",
      getState().currentUser
    )
      .then((result) => {
        // LOGGING && console.log("readHighlight got result:", result);
        dispatch(setHighlight(result));
      })
      .catch((err) => {
        LOGGING && console.log("readHighlight got err", err);
      });
  };
};
