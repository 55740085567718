import { SET_GIFT_CARD_TEMPLATES } from "../actionTypes";

const giftCardTemplates = (state = {}, action) => {
  switch (action.type) {
    case SET_GIFT_CARD_TEMPLATES:
      return { ...action.giftCardTemplates };
    default:
      return state;
  }
};

export default giftCardTemplates;
