import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "src/components/Button";
import { setIsLunch, setMealType } from "src/store/actions/adminDeliveryV2";
import { mealMarkerByMealId } from "src/util/stopMap";
import styled from "styled-components";
import { AskDriverListContainer } from "./AskDriverListContainer";
import { MealFilter } from "./MealFilter";
import { SwapDriversContainer } from "./SwapDriversContainer";
import { LOGGING } from "..";
const MealSelectionButton = styled.button`
  color: ${(props) => (props.isSelected ? "black" : "gray")};
  border-bottom: ${(props) => (props.isSelected ? "2px solid black" : null)};
  font-weight: ${(props) => (props.isSelected ? "500" : "300")};
  margin: 0 8px;
  padding: 12px 0;
`;

export const AdminDeliveryHeaderV2 = ({
  numLunchStops,
  numEarlyDinnerStops,
  numDinnerStops,
  dailyStats,
}) => {
  const [showDriversAvailability, setShowDriversAvailability] = useState(false);
  const [showSwapDrivers, setSwapDrivers] = useState(false);
  const mealType = useSelector(
    (state) => state.adminDeliveryV2.filters.mealType
  );
  const allStops = useSelector((state) => state.adminDeliveryV2.stops);
  const stops = allStops.filter((s) => s.mealType === mealType);
  const mealMarkers = mealMarkerByMealId(stops);
  LOGGING &&
    console.log("AdminDeliveryHeaderV2 rendering with:", {
      mealType,
      allStops,
      stops,
      mealMarkers,
    });

  const dispatch = useDispatch();
  const setMealToLunch = useCallback(() => {
    setIsLunch(true)(dispatch);
    setMealType("lunch")(dispatch);
  }, [dispatch]);
  const setMealToEarlyDinner = useCallback(() => {
    setIsLunch(false)(dispatch);
    setMealType("earlyDinner")(dispatch);
  }, [dispatch]);
  const setMealToDinner = useCallback(() => {
    setIsLunch(false)(dispatch);
    setMealType("dinner")(dispatch);
  }, [dispatch]);

  const popUpDriversAvailability = useCallback(() => {
    setShowDriversAvailability(true);
  }, []);
  const hideDriversAvailability = useCallback(() => {
    setShowDriversAvailability(false);
  }, []);

  const popUpSwapDrivers = useCallback(() => {
    setSwapDrivers(true);
  }, []);
  const hideSwapDrivers = useCallback(() => {
    setSwapDrivers(false);
  }, []);

  console.log("AdminDeliveryHeaderV2 rendering with:", dailyStats);
  return (
    <div style={{ margin: "4px 0" }}>
      <div
        style={{
          position: "fixed",
          top: "0px",
          left: "0px",
          width: "100%",
          textAlign: "center",
          zIndex: "100",
          lineHeight: "70px",
        }}
      >
        <label style={{ fontWeight: "700", marginRight: "10px" }}>
          Daily: GMV
        </label>
        <span>${dailyStats?.orderTotal.toFixed(2)}</span>
        <label
          style={{ fontWeight: "700", marginRight: "10px", marginLeft: "10px" }}
        >
          Revenue
        </label>
        <span>${dailyStats?.revenue.toFixed(2)}</span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <div style={{ margin: "8px" }}>
          <MealSelectionButton
            isSelected={mealType === "lunch"}
            type="button"
            onClick={setMealToLunch}
          >
            {`LUNCH (${numLunchStops})`}
          </MealSelectionButton>
          <MealSelectionButton
            isSelected={mealType === "earlyDinner"}
            type="button"
            onClick={setMealToEarlyDinner}
          >
            {`EXPRESS DINNER (${numEarlyDinnerStops})`}
          </MealSelectionButton>
          <MealSelectionButton
            isSelected={mealType === "dinner"}
            type="button"
            onClick={setMealToDinner}
          >
            {`DINNER (${numDinnerStops})`}
          </MealSelectionButton>
        </div>
        <div style={{ margin: "8px", display: "flex" }}>
          <Button onClick={popUpDriversAvailability}>
            <span>ask drivers</span>
          </Button>
          <div style={{ marginLeft: "8px" }}>
            <Button onClick={popUpSwapDrivers}>
              <span>swap drivers</span>
            </Button>
          </div>
        </div>
        <MealFilter mealMarkers={mealMarkers} />
      </div>
      <AskDriverListContainer
        isShown={showDriversAvailability}
        mealType={mealType}
        hide={hideDriversAvailability}
      />
      <SwapDriversContainer isShown={showSwapDrivers} hide={hideSwapDrivers} />
    </div>
  );
};
