import { useSelector } from "react-redux";
import styled from "styled-components";
import { PickUpHeader } from "./PickUpHeader";
import { PickUpRoutes } from "./PickUpRoutes";
import { PickUpStops } from "./PickUpStops";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 160px);
`;

export const PickUp = () => {
  const mealType = useSelector(
    (state) => state.adminDeliveryV2.filters.mealType
  );
  const allRestaurants = useSelector((state) => state.pickUpRestaurants);
  const allRoutes = useSelector((state) => state.routeList);
  console.log("PickUp got data:", { allRestaurants, allRoutes });

  let restaurants = {};
  for (const restaurant in allRestaurants) {
    if (allRestaurants[restaurant].mealType === mealType) {
      restaurants[restaurant] = allRestaurants[restaurant];
    }
  }
  const routes = allRoutes.filter((r) => r.mealType === mealType);
  console.log("PickUp got filtered data:", { mealType, restaurants, routes });

  return (
    <Container>
      <PickUpHeader
        numLunchStops={0}
        numEarlyDinnerStops={0}
        numDinnerStops={0}
      />
      <PickUpStops restaurants={restaurants} />
      <PickUpRoutes mealType={mealType} routes={routes} />
    </Container>
  );
};
