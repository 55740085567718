import { LOAD_CANCELED_ORDERS, RESET_CANCELED_ORDERS } from "../actionTypes";

const canceledOrders = (state = [], action) => {
  switch (action.type) {
    case LOAD_CANCELED_ORDERS:
      return [...action.canceledOrders];
    case RESET_CANCELED_ORDERS:
      return [];
    default:
      return state;
  }
};

export default canceledOrders;
