import { LOAD_STATS, RESET_STATS } from '../actionTypes';

const stats = (state = {}, action) => {
  switch (action.type) {
    case LOAD_STATS:
      return { ...action.stats };
    case RESET_STATS:
      return {};
    default:
      return state;
  }
};

export default stats;
