import React, { ReactElement, useCallback, useState } from "react";
import {
  faArrowSquareRight,
  faCheck,
  faInfoCircle,
} from "@fortawesome/pro-light-svg-icons";
import {
  faShareAll as faCopy,
  faChevronLeft as faBack,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAsync } from "react-async";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GetReferralLinkDetails, SendAlert } from "src/api/referrals";
import styled, { keyframes } from "styled-components";
import {
  SendReferralContentContainer,
  ButtonClose,
  HeroOverlay,
  Tagline,
  heroImages,
  Title,
  HeroContainer,
} from "./MyReferralCodeEventNonMember";
import { ShareActions } from "./ShareActions";
import { Loading, IconImages, PopUp, SupportedSanJoseZipCodes } from "..";
import { HeroImage } from "..";
import {
  ActionGrey,
  Text12300,
  TextUPPER,
  TextTagline,
  Centered,
  Yellow,
  Beige,
  Grey,
  Pink,
  Card,
  card,
  textTitle,
  ALLCAPTEXT,
  TextTitle,
  SectionHeader,
  SectionNote,
  SectionTitle,
  text12300,
  CenteredByColumn,
  NavHeight,
  CenteredByColumnLeft,
  BorderGrey,
  actionBlack,
  centered,
  actionGrey,
  centeredByColumn,
  WinterBlue,
  SpringYellow,
  SummerBlue,
  FallOrange,
} from "../Shared";
const heroHeight = 200;
export interface MyReferralCodeEventMemberProps {
  code: string;
  firstName: string;
}
export interface HeroProps {
  src: string;
}

const ShareButtonText = styled(ActionGrey)`
  // margin: 5px;
  // margin-bottom: 10px;
  width: 100%;
  height: 60px;
  position: relative;
  & svg {
    /* position: absolute; */
    /* right: 20px; */
    font-size: 18px;
    margin-left: 10px;
  }
`;

const Expire = styled(Centered)`
  ${textTitle}
  font-size: 20px;
  /* color: white;
  border: 1px solid white; */
  padding: 10px 20px;
  background-color: ${FallOrange};
`;

const TextShareButtonText = styled(ShareButtonText)`
  background: black;
  /* color: black; */
  border: 1px solid black;
  /* background-color: transparent; */
  font-size: 13px;
  padding: 15px 30px;
  color: white;
`;

const copyButtonHeight = 100;
export const breakPoint = 700;
const ReferralLink = styled.button`
  ${text12300}
  padding: 0px;
  font-weight: 500;
  /* ${card} */
  /* background-color: ${BorderGrey}; */
  /* border: 1px solid ${BorderGrey}; */
  /* background-color: ${BorderGrey}; */
  /* border-bottom: 1px solid black; */
  text-transform: uppercase;
  border-bottom: 1px solid black;
  background-color: transparent;
  margin: 20px 0 0 0;
  svg {
    display: none;
    margin-left: 10px;
  }
  @media only screen and (min-width: ${breakPoint}px) {
    /* position: absolute;
    bottom: 30px; */
    display: none;
  }
`;
const HowItWorks = styled(CenteredByColumn)`
  /* margin-top: ${heroHeight}px;; */
  width: 100%;
  justify-content: flex-start;
  padding: 0px 20px;
  > div:first-child {
    margin-top: 20px;
  }
  > div:last-child {
    margin-bottom: 40px;
    &:after {
      display: none;
    }
  }
  @media only screen and (min-width: ${breakPoint}px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    > div:last-child {
      margin-bottom: 20px;
    }
  }
`;
const Step = styled(Centered)`
  width: 100%;
  margin-top: 40px;
  position: relative;
  ${card}
  &:after {
    width: 1px;
    height: 40px;
    content: "";
    border-left: 2px dashed ${FallOrange};
    position: absolute;
    right: 20px;
    top: 100%;
  }
  max-width: ${breakPoint - 40}px;
  @media only screen and (min-width: ${breakPoint}px) {
    flex-direction: column;
    width: 250px;
    margin: 20px;
    align-self: stretch;
    justify-content: flex-start;
    flex-grow: 1;
    max-width: 350px;
    &:after {
      width: 40px;
      height: 0px;
      content: "";
      border-top: 2px dashed ${FallOrange};
      position: absolute;
      left: 100%;
      top: 60px;
    }
  }
`;
export interface StepImageProps {
  src: {
    portrait: string;
    landscape: string;
  };
}
// const StepImage = styled(Centered)<StepImageProps>`
//   width: 40%;
//   background-image: url(${(props) => props.src.portrait});
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
//   align-self: stretch;
//   @media only screen and (min-width: ${breakPoint}px) {
//     width: 100%;
//     height: 120px;
//     font-size: 80px;
//     background-image: url(${(props) => props.src.landscape});
//   }
// `;
const StepImage = styled(Centered)`
  width: 40%;
  /* background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
   */
  align-self: stretch;
  font-size: 80px;
  background-color: ${FallOrange};
  @media only screen and (min-width: ${breakPoint}px) {
    width: 100%;
    height: 120px;
    font-size: 80px;
  }
`;
const StepCount = styled(TextTitle)`
  font-size: 20px;
  margin-bottom: 20px;
  text-transform: none;
  width: 100%;
  @media only screen and (min-width: ${breakPoint}px) {
    margin-bottom: 15px;
  }
`;
const StepContent = styled(CenteredByColumnLeft)`
  width: 60%;
  padding: 30px 20px;
  text-align: left;
  position: relative;
  @media only screen and (min-width: ${breakPoint}px) {
    width: 100%;
    text-align: center;
    padding: 20px 20px 70px 20px;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-grow: 1;
  }
`;
const StepExplain = styled.div`
  ${text12300}
  padding: 0px;
`;
// const Title = styled(TextTitle)`
//   font-size: 15px;
// `;
const ClaimLink = styled(Link)`
  /* ${actionGrey} */
  ${text12300}
  font-weight: 500;
  /* ${card} */
  border-bottom: 1px solid black;
  margin-top: 20px;
  text-transform: uppercase;
  /* background-color: ${BorderGrey}; */
  /* border: 1px solid ${BorderGrey}; */
  /* background-color: ${BorderGrey}; */
  /* border-bottom: 1px solid black; */
  svg {
    margin-left: 10px;
    display: none;
  }
  @media only screen and (min-width: ${breakPoint}px) {
    position: absolute;
    bottom: 30px;
  }
`;
const CopyLink = styled.button`
  /* ${centered}
  display: none;
  width: 100%;
  span {
    background: rgba(228, 228, 228, 0.5);
    font-family: "Montserrat", sans-serif;    
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1.8px;
    padding: 0px 20px;
    line-height: 40px;
    box-shadow: none;
    box-sizing: border-box;
    margin-right: 10px;
  }
  button {
    ${actionBlack}
    margin-left: 10px;
  } */
  ${text12300}
  font-weight: 500;
  border-bottom: 1px solid black;
  margin-top: 20px;
  text-transform: uppercase;
  position: absolute;
  bottom: 30px;
  padding: 0px;
  display: none;
  @media only screen and (min-width: ${breakPoint}px) {
    display: flex;
  }
`;
export const CopyButton = styled.div`
  ${card}
  margin: 20px 0px 0px 0px;
  width: 100%;
  padding: 20px;
  /* padding: 0 20px; */
  /* background-color: white; */
  /* position: fixed;
  bottom: 200px; */
  /* height: ${copyButtonHeight}px; */
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 1px solid rgb(217, 217, 217); */
  /* background-color: ${Beige}; */
  align-items: center;
  /* background-color: rgba(255, 255, 255, 0.8); */
  /* flex-direction: row; */
  /* flex-wrap: nowrap; */
  /* padding-right: 40px; */

  > svg {
    /* display: none; */
    font-size: 20px;
    /* margin-left: 10px;       */
    /* margin-bottom: 0px; */
    position: absolute;
    right: 30px;
  }
`;
const VisitButton = styled(CopyButton)``;
const ShareLink = styled.a`
  margin: 10px 0px;
`;

const BANNER_HEIGHT = 30;
const animation = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;
const HowItWorksTitle = styled.h1`
  ${text12300}
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 500;
  margin: 30px 0px 0 0;
  @media only screen and (min-width: ${breakPoint}px) {
    margin: 30px 0 10px 0;
  }
`;
const CopiedNote = styled(TextTagline)`
  position: absolute;
  ${card}
  padding:0px 20px;
  width: 100px;
  font-size: 12px;
  background: green;
  color: white;
  line-height: ${BANNER_HEIGHT}px;
  height: ${BANNER_HEIGHT}px;
  top: 5px;
  left: calc(50% - 70px);
  animation: ${animation} 1s ease-in-out 0.5s 1 normal forwards;
`;

const imagePercent = 40;
const HeroImageOverlay = styled(Centered)`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: ${heroHeight}px;
  background-color: rgba(0, 0, 0, 0.3);
`;

const HeroText = styled(Centered)`
  width: calc(100% - 40px);
  margin-top: ${heroHeight - 50}px;
  padding: 15px;
  ${card}
  z-index: 2;
  align-items: stretch;
  background-color: rgba(255, 255, 255, 0.9);
  position: relative;
  max-width: ${breakPoint - 40}px;
  @media only screen and (min-width: ${breakPoint}px) {
    margin-top: ${heroHeight - 120}px;
    /* background-color: white; */
  }
`;
const HeroTextBorder = styled(CenteredByColumn)`
  position: relative;
  border: 2px solid orange;
  justify-content: flex-start;
  align-self: stretch;
  flex-grow: 1;
  padding: 40px 40px 30px 40px;
  h1 {
    ${text12300}
    text-transform: uppercase;
    font-size: 26px;
    line-height: 1.2;
    font-weight: 400;
    margin: 0px 0 10px 0;
  }

  b {
    /* expire date */
    ${text12300}
    font-size: 15px;
    font-weight: 700;
    padding: 0px 20px;
    margin-top: 10px;
    text-transform: uppercase;
  }

  span {
    /* member only  */
    ${textTitle}
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 300;
    line-height: 2;
  }
  @media only screen and (min-width: ${breakPoint}px) {
    padding: 30px 40px;
  }
`;
const HeroSlogan = styled(Centered)`
  ${text12300}
  text-transform: uppercase;
  align-items: flex-end;
  @media only screen and (min-width: ${breakPoint}px) {
    width: 150px;
    height: 150px;
    background-color: white;
    border-radius: 50%;
    padding-bottom: 50px;
    position: fixed;
    right: calc(50vw + 40px);
    top: calc(${NavHeight}px + 40px);
  }
`;
const L1 = styled.span`
  font-weight: 500;
  font-size: 40px;
  line-height: 1;
`;
const L2 = styled.span`
  margin-left: 5px;
  line-height: 1;
  margin-bottom: 3px;
`;
const HeroExplain = styled(CenteredByColumnLeft)`
  margin-top: 20px;
  @media only screen and (min-width: ${breakPoint}px) {
    height: calc(50vh - ${NavHeight / 2}px);
    /* width: 50vw; */
    position: fixed;
    top: ${NavHeight}px;
    /* border: 1px solid black; */
  }
`;
const HeroItem = styled(Text12300)`
  text-align: left;
  align-self: stretch;
  line-height: 1.8;
  margin-bottom: 5px;
  padding-left: 10px;
  position: relative;
  font-size: 13px;
  &:before {
    content: "";
    width: 4px;
    height: 4px;
    background-color: black;
    border-radius: 50%;
    position: absolute;
    left: 0px;
    top: 9px;
  }
  b {
    font-weight: 600;
  }
`;
const InfoIcon = styled.button`
  margin: 0px;
  padding: 0px;
  ${textTitle}
  text-transform: lowercase;
  font-size: 14px;
  line-height: 10px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 5px;
  position: relative;
  top: 2px;
`;

const CuteStamp = styled.div`
  background-color: transparent;
  width: 80px;
  height: 80px;
  transform: rotate(15deg);
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
  font-size: 80px;
  line-height: 80px;
  position: absolute;
  left: -10px;
  bottom: -10px;
`;
const DeliveryRange = styled(CenteredByColumn)`
  ${card}
  z-index: 2;
  ${text12300}
  padding: 20px;
  position: fixed;
  width: 300px;
  left: calc(50vw - 150px);
  top: ${NavHeight}px;
  ul {
    width: 100%;
    ${CenteredByColumnLeft}
    /* background-color: red; */
    background-color: white;
    li {
      align-self: stretch;
      text-align: left;
      svg {
        margin-right: 10px;
      }
    }
  }
  button {
    ${actionBlack}
    margin-top: 40px;
  }
`;
export const MyReferralCodeEventMember: React.FC<MyReferralCodeEventMemberProps> =
  ({ code, firstName }: MyReferralCodeEventMemberProps): ReactElement => {
    const [isCodeCopied, setIsCodeCopied] = useState(false);

    const link = `https://foodie.earth/treat/${code}`;
    const relativelink = `/treat/${code}`;
    const currentUser = useSelector((state: any) => state.currentUser);
    const name = `${currentUser?.user?.firstName} ${currentUser?.user?.lastName}`;
    const [showActions, setShowActions] = useState(false);
    const [showRange, setShowRange] = useState(false);
    const onCloseShare = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setShowActions(false);
    };
    const fetch = useCallback(async () => {
      return await GetReferralLinkDetails(currentUser?.user?._id);
    }, [currentUser?.user?._id]);

    const copyLink = useCallback(() => {
      navigator.clipboard.writeText(link);
      setIsCodeCopied(true);
      SendAlert(name, "copied referral link!");
      setTimeout(() => {
        setIsCodeCopied(false);
      }, 1000);
    }, [link]);

    const { data } = useAsync({ promiseFn: fetch });

    if (data == null) return <Loading />;

    const couponCode = data.couponCode;
    const shareContent = {
      url: link,
      title: "Free Treats from Foodie.Earth",
      text: "invite your friends",
    };

    const handleShareClick = async () => {
      try {
        if (navigator.share) {
          await navigator.share(shareContent);
        } else {
          console.log("Web Share API not supported in this browser.");
          // Fallback for browsers that don't support the Web Share API
          // You can implement your own sharing mechanism here.
        }
      } catch (error) {
        console.error("Error sharing:", error);
      }
    };

    if (couponCode == null) return null;
    return (
      <SendReferralContentContainer>
        {/* @ts-ignore */}
        <PopUp
          isPoppedUp={showRange}
          componentToDisplay={
            <DeliveryRange>
              <ul>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  Mountain View
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  Los Altos
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  Los Altos Hills
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  Stanford
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  Palo Alto
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  East Palo Alto
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  Menlo Park
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  Sunnyvale
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  Cupertino
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  Santa Clara
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  Saratoga
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  San Jose ({`${SupportedSanJoseZipCodes.join(", ")}`})
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  Campbell
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  Milpitas
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  Monte Sereno
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  Atherton
                </li>
              </ul>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowRange(false);
                }}
              >
                Got it!
              </button>
            </DeliveryRange>
          }
          hidePopUp={() => {
            setShowRange(false);
          }}
        />
        <ButtonClose to="/">
          <FontAwesomeIcon icon={faBack} />
        </ButtonClose>
        <HeroContainer>
          <HeroOverlay>
            <Tagline>member only</Tagline>
            <Title>fall treats on us!</Title>
            <Expire>ends Mon 9/16/2024</Expire>
          </HeroOverlay>
          <HeroImage images={heroImages} isEvent={true} />
        </HeroContainer>

        <HowItWorksTitle>How It Works</HowItWorksTitle>
        <HowItWorks>
          <Step>
            <StepImage>🎁</StepImage>
            <StepContent>
              <StepCount>Step 1. </StepCount>
              <StepExplain>
                Gift the FREE drink to at least 1 friend who is new to Foodie
                and in the delivery range.
                <InfoIcon
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowRange(true);
                  }}
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                </InfoIcon>
              </StepExplain>
              {isCodeCopied ? <CopiedNote>Link copied!</CopiedNote> : null}
              <ReferralLink
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  // setShowActions(true);
                  handleShareClick();
                  SendAlert(name, "clicked refer button!");
                }}
              >
                click to share
                <FontAwesomeIcon icon={faCopy} />
              </ReferralLink>
              <CopyLink onClick={copyLink}>
                {/* <span>{link}</span> */}
                copy link
              </CopyLink>
            </StepContent>
          </Step>
          <Step>
            <StepImage>🧋</StepImage>
            <StepContent>
              <StepCount>Step 2. </StepCount>
              <StepExplain>Claim your own FREE drink.</StepExplain>
              <ClaimLink to={relativelink}>
                Click to claim
                <FontAwesomeIcon icon={faArrowSquareRight} />
              </ClaimLink>
            </StepContent>
          </Step>
          <Step>
            <StepImage>💵</StepImage>
            <StepContent>
              <StepCount>Step 3. </StepCount>
              <StepExplain>
                Receive $10 credit for each friend who places an order of $20 or
                more.
              </StepExplain>
            </StepContent>
          </Step>
          {/* <ShareActions link={link} firstName={currentUser?.user?.firstName} /> */}
        </HowItWorks>
      </SendReferralContentContainer>
    );
  };
