import React from 'react';
import './SelectStop.scss';
import { faCar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SelectStopIndex = ({ selectedStop, onSelectStop, driverTotalStops, driverColor }) => (
  <div className="select-stop">
    {Array.from(Array(driverTotalStops).keys()).map((stop, index) => (
      <button
        key={index}
        className={`stop ${selectedStop === stop ? 'selected' : 'unselected'}`}
        onClick={onSelectStop.bind(this, stop)}
        style={
          stop === selectedStop
            ? {
                background: driverColor,
              }
            : {
                color: driverColor,
                borderColor: driverColor,
              }
        }
      >
        <span>{stop + 1}</span>
      </button>
    ))}
  </div>
);

const SelectStopDriver = ({ selectedDriver, onSelectDriver, onResetDriver, drivers }) => {
  return (
    <div className="select-stop">
      {drivers.map((driver, index) => {
        const { _id, color } = driver;
        const selected = selectedDriver && selectedDriver._id === _id;
        return (
          <button
            className={`driver ${selected ? 'selected' : 'unselected'}`}
            onClick={selected ? onResetDriver : onSelectDriver.bind(this, driver._id)}
            style={
              selected
                ? {
                    background: color,
                  }
                : {
                    color,
                    borderColor: color,
                  }
            }
            key={index}
          >
            <FontAwesomeIcon icon={faCar} />
            <span>{driver.name}</span>
          </button>
        );
      })}
    </div>
  );
};

export { SelectStopIndex, SelectStopDriver };
