import React, { Component } from 'react';
import { connect } from 'react-redux';
import { continueWithFacebook, continueWithGoogle } from '../../store/actions';
import { Back, LOGGING, Auth } from './';

class PageSignUp extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      facebookAutoload: false,
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleClickContinueWithFacebook = this.handleClickContinueWithFacebook.bind(this);
    this.handleContinueWithFacebook = this.handleContinueWithFacebook.bind(this);
    this.handleGoogleSuccess = this.handleGoogleSuccess.bind(this);
    this.handleGoogleFailure = this.handleGoogleFailure.bind(this);
    this.handleGoogleReady = this.handleGoogleReady.bind(this);
  }
  handleGoogleReady() {
    this.setState({ loading: false });
  }
  handleGoogleSuccess(response) {
    LOGGING && console.log('handleGoogleSuccess response:', response);
    const { profileObj } = response;
    const {
      googleId,
      givenName: firstName,
      familyName: lastName,
      imageUrl: profilePictureURL,
    } = profileObj;
    this.props
      .continueWithGoogle({ googleId, firstName, lastName, profilePictureURL })
      .then(() => {
        LOGGING && console.log('handleGoogleSuccess got success from backend');
        this.props.history.push('/');
      })
      .catch((e) => {
        LOGGING && console.log('handleGoogleSuccess got err from backend:', e);
      });
  }

  handleGoogleFailure(response) {
    LOGGING && console.log('handleGoogleFailure response:', response);
  }

  handleClickContinueWithFacebook(e) {
    e.preventDefault();
    LOGGING && console.log('handleClickContinueWithFacebook called');
    this.setState({ facebookAutoload: true, loading: true });
  }

  handleContinueWithFacebook(response) {
    LOGGING && console.log('handleContinueWithFacebook got:', response);
    const { first_name, last_name, id, picture } = response;
    this.props
      .continueWithFacebook({
        firstName: first_name,
        lastName: last_name,
        facebookId: id,
        profilePictureURL: picture && picture.data ? picture.data.url : '',
      })
      .then(() => {
        this.props.history.push('/');
      });
  }

  handleCancel(e) {
    e.preventDefault();
    LOGGING && console.log('handleCancel called');
    this.props.history.goBack();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { facebookAutoload, loading } = this.state;
    LOGGING &&
      console.log('PageSignUp got', {
        state: this.state,
        googleclientId: process.env.REACT_APP_DEV_GOOGLE_CLIENT_ID,
      });

    return (
      <div className="page new-entity">
        <Back onClick={this.handleCancel} />
        <Auth
          signUpByEmail={true}
          loading={loading}
          facebookAutoload={facebookAutoload}
          onContinueWithFacebook={this.handleContinueWithFacebook}
          onClickContinueWithFacebook={this.handleClickContinueWithFacebook}
          onGoogleSuccess={this.handleGoogleSuccess}
          onGoogleFailure={this.handleGoogleFailure}
          onGoogleReady={this.handleGoogleReady}
        />
      </div>
    );
  }
}

export default connect(null, { continueWithFacebook, continueWithGoogle }, null, {
  forwardRef: true,
})(PageSignUp);
