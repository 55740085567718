import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { faYelp } from "@fortawesome/free-brands-svg-icons";
import {
  faBell,
  faBellSlash,
  faUserPlus,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch, useStore } from "react-redux";
import { LOGGING } from "src/constants";
import { RootState } from "src/store";
import { toggleFavorite } from "src/store/actions";
import styled, { css } from "styled-components";
import {
  Centered,
  textUpper,
  BorderGrey,
  BreakPoint,
  Pink,
  Beige,
} from "../../Shared";
const Container = styled(Centered)`
  justify-content: space-evenly;
  width: 100%;
  border-bottom: 1px solid ${BorderGrey};
  @media screen and (min-width: ${BreakPoint}px) {
    border-bottom: none;
    width: 600px;
  }
`;
const Icon = styled(Centered)``;

export const actionContainer = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  align-self: stretch;
  ${textUpper}
  font-size: 12px;
  font-weight: 500;
  margin: 20px 0px;
  padding: 0px 10px;
  border-right: 1px solid ${BorderGrey};
  width: 33%;
  box-sizing: border-box;
  span {
    margin-top: 15px;
    line-height: 1.3;
    text-align: center;
  }
  svg {
    font-size: 20px;
  }
  &:last-child {
    border-right: none;
  }
  @media screen and (min-width: ${BreakPoint}px) {
    border-right: none;
  }
`;
const ActionYelp = styled.a`
  ${actionContainer}
  flex-grow: 0;
`;
const ActionGroupOrder = styled.button`
  ${actionContainer}
`;
const ActionToggleReminder = styled.button`
  ${actionContainer}
  //hover pointer
    cursor: pointer;
`;
interface MealQuickLinksProps {
  yelp: any;
  isMember?: boolean;
  onShowGroupOrder?: () => void;
  onOpenInvite?: () => void;
  groupOrderId?: string;
  name?: string;
  groupOrderLeader?: any;
  isExclusive?: boolean;
  restaurantId?: string;
  restaurantName?: string;
}
export const MealQuickLinks: React.FC<MealQuickLinksProps> = ({
  yelp,
  isMember,
  onShowGroupOrder,
  onOpenInvite,
  groupOrderLeader,
  isExclusive,
  restaurantId,
  restaurantName,
}): ReactElement => {
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const cart = useSelector((state: RootState) => state.cart.payLoad);
  const isLeader = groupOrderLeader?._id === currentUser?.user?._id;
  const [loading, setLoading] = useState(true);
  const [groupOrderId, setGroupOrderId] = useState(null);
  const [isFavorite, setIsFavorite] = useState(
    currentUser?.user?.favorites?.includes(String(restaurantId))
  );
  const dispatch = useDispatch();
  const store = useStore();
  const toggle = useCallback(
    async (restaurantId, e) => {
      e.preventDefault();
      setIsFavorite(!isFavorite);
      await toggleFavorite(restaurantId)(dispatch, store.getState);
    },
    [currentUser]
  );
  useEffect(() => {
    // hook called first render
    setIsFavorite(currentUser?.user?.favorites?.includes(String(restaurantId))); // state updated with same value
    setGroupOrderId(cart?.groupOrderType > -1 ? cart._id : null);
    setLoading(false);
  }, [restaurantId, currentUser, cart]); //
  LOGGING &&
    console.log("MealQuickLinks rendering with:", {
      isMember,
      groupOrderLeader,
      groupOrderId,
      isLeader,
    });
  return (
    <Container>
      {yelp?.url && yelp?.rating && yelp?.reviews && (
        <ActionYelp href={yelp?.url} target="_blank">
          <Icon>
            <FontAwesomeIcon icon={faYelp} />
          </Icon>
          <span>{`Yelp ${yelp?.rating?.toFixed(1)} (${yelp?.reviews})`}</span>
        </ActionYelp>
      )}

      <ActionToggleReminder onClick={toggle.bind(this, restaurantId)}>
        <Icon>
          <FontAwesomeIcon icon={isFavorite ? faBell : faBellSlash} />
        </Icon>
        <span>{`reminder ${isFavorite ? "on" : "off"}`}</span>
      </ActionToggleReminder>
      {loading ? null : groupOrderId ? (
        <ActionGroupOrder onClick={onOpenInvite}>
          <Icon>
            <FontAwesomeIcon icon={faUserPlus} />
          </Icon>
          <span>Invite</span>
        </ActionGroupOrder>
      ) : (
        <ActionGroupOrder onClick={onShowGroupOrder}>
          <Icon>
            <FontAwesomeIcon icon={faUsers} />
          </Icon>
          <span>group order</span>
        </ActionGroupOrder>
      )}
      {/* <AlertButton
          restaurantId={restaurantId}
          restaurantName={restaurantName}
          isNav={true}
        /> */}
    </Container>
  );
};
