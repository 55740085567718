import React from "react";
import "./OrderForm.scss";
import moment from "moment-timezone";
import {
  Goods,
  OrderTotal,
  TimeStamp,
  CATimeZone,
  Back,
  DishList,
  LOGGING,
  getItemNumberInCart,
  CartThumbnail,
} from ".";
import { MealMenuScreen } from "./customers/mealMenu/MealMenuScreen";
import { DishesToCategories } from "../";
import { Feature } from "../../../components/Feature";
import { OrderDiscount } from "../../../components/OrderDiscount";
import { OrderTips } from "../../../components/OrderTips";
import { FEATURES } from "../../../constants";
import { getDateKey } from "../../../util/time";

const OrderFormCheckout = ({
  loading,
  order,
  originalOrder,
  couponCodeError,
  onChangeDishQuantity,
  onChangeTips,
  onEditDishComment,
  onSaveOrder,
  onCancelOrder,
  onShowMenu,
  onApplyCouponCode,
}) => {
  LOGGING &&
    console.log("OrderFormCheckout rendering with:", { order, originalOrder });
  const {
    goods,
    groupSubOrders,
    clientSideGoods,
    window,
    deliveryTime,
    _id,
    due: newDue,
  } = order;
  const { due: originalDue } = originalOrder;
  const { start, end } = window;
  const isLunch = moment(start).tz(CATimeZone).hour() < 13;
  const deltaDue = newDue - originalDue;
  LOGGING &&
    console.log("OrderFormCheckout rendering with:", {
      window,
      goods,
      groupSubOrders,
      clientSideGoods,
      deltaDue,
      newDue,
      originalDue,
      originalOrder,
    });

  const minOrderValue = order.meal?.restaurant?.minOrderValue;
  const isCartValid = minOrderValue == null || order.total >= minOrderValue;
  const confirmMessage =
    (deltaDue > 0
      ? `You'll be charged an additional `
      : deltaDue < 0
      ? `You'll be refunded `
      : `Order total hasn't changed, no additional charge will be made.`) +
    (deltaDue > 0
      ? `$${deltaDue.toFixed(2)}`
      : deltaDue < 0
      ? `$${(-deltaDue).toFixed(2)}`
      : "") +
    (deltaDue > 0
      ? " for the change."
      : deltaDue < 0
      ? " for the change."
      : "");
  return (
    <div className="checkout order-form list">
      <div className="list-title">
        <Back onClick={onShowMenu} />
        <span>checkout</span>
      </div>
      <div className="list-body">
        <form className="receipt goods">
          <div className="receipt-body">
            <div className="meal-type">
              <span className="order-type">
                {`${isLunch ? "Lunch" : "Dinner"} order`}{" "}
              </span>
            </div>
            {!isCartValid ? (
              <div style={{ color: "red", margin: "4px" }}>
                Orders need to be <strong>${minOrderValue}+</strong> for this
                restaurant. Spend{" "}
                <strong>${(minOrderValue - order.total).toFixed(2)}</strong>{" "}
                more to checkout.
              </div>
            ) : null}
            {groupSubOrders && groupSubOrders.length > 0 ? (
              groupSubOrders.map((o) => (
                <>
                  <span>{o.name}</span>
                  <Goods goods={o.goods} />
                </>
              ))
            ) : (
              <Goods
                goods={goods
                  .filter((g) => g.quantity > 0)
                  .map((g, index) => ({ ...g, key: index }))}
                onChangeDishQuantity={onChangeDishQuantity}
                onEditDishComment={onEditDishComment}
              />
            )}
            <Feature name={FEATURES.ORDER_TIPS}>
              <OrderTips
                onChangeTips={onChangeTips}
                sum={order.sum}
                tax={order.tax}
                defaultTips={order.tips}
                initialTip={order.tips}
                tipRate={order.tipRate}
                tipCustom={order.tipCustom}
                customerName={`${order?.user?.firstName} ${order?.user?.lastName}`}
              />
            </Feature>
            <Feature name={FEATURES.COUPON_CODE}>
              <OrderDiscount
                error={couponCodeError}
                onApplyCouponCode={onApplyCouponCode}
              />
            </Feature>
            <div className="receipt-section cart-summary">
              <OrderTotal order={order} />
              {deliveryTime ? (
                <TimeStamp action="delivered" time1={deliveryTime} />
              ) : (
                <TimeStamp action="arriving" time1={start} time2={end} />
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="list-actions">
        <button className="discard-changes" onClick={onShowMenu}>
          view menu
        </button>
        <button
          className="submit-changes"
          style={{
            opacity: isCartValid ? 1 : 0.5,
            pointerEvents: isCartValid ? null : "none",
          }}
          onClick={
            goods.length > 0
              ? onSaveOrder.bind(this, confirmMessage)
              : onCancelOrder.bind(this, _id, originalDue === 0)
          }
          disabled={loading}
        >
          {loading ? "submitting..." : "submit"}
        </button>
      </div>
    </div>
  );
};

const OrderFormWithMenu = ({
  order,
  onDiscard,
  onConfirm,
  onChangeDishQuantity,
  onEditDishComment,
  onClickCountInCart,
  onGoToCategory,
  onAddDishToCart,
  onRemoveDishFromCart,
  isMember,
  isAuthenticated,
}) => {
  LOGGING && console.log("OrderFormWithMenu rendering with order:", order);
  const { meal, clientSideGoods, window } = order;
  const { dishesV2, preOrderedDishes } = meal;
  const { start } = window;
  const isLunch = moment(start).tz(CATimeZone).hour() < 13;
  const date = getDateKey(moment(start).tz(CATimeZone));
  const dishesToDisplay =
    dishesV2 && dishesV2.length > 0 ? dishesV2 : preOrderedDishes;
  return (
    <div className="restaurant-menu-full order-form list">
      <div className="list-title">
        <Back onClick={onDiscard} />
        <span className="edit-title">
          editing order<br></br>
          {`${date} (${isLunch ? "lunch" : "dinner"})`}
        </span>
        <CartThumbnail
          cart={order}
          numberOfItems={getItemNumberInCart(order)}
          onBack={onConfirm}
        />
      </div>
      <MealMenuScreen
        restaurant={order.goods[0]?.dish?.restaurant}
        dishes={dishesToDisplay}
        onOpenDishDetails={onChangeDishQuantity}
        onAddDishToCart={onAddDishToCart}
        cart={{ ...order, goods: clientSideGoods }}
        onRemoveDishFromCart={onRemoveDishFromCart}
        isMember={isMember}
        isAuthenticated={isAuthenticated}
        isEditing={true}
      />
    </div>
  );
};

export { OrderFormCheckout, OrderFormWithMenu };
