import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { BookmarkDetails, BookmarkList, Nav, Tabs, Loading, Footer } from '.';
import { fetchBookmarks, updateBookmark, reviewBookmark, signOut } from '../../store/actions';
import {
  BookmarkStatus,
  LOGGING,
  sortBookmarks,
  BookmarkStatusActionName,
  PageSize,
  BookmarkStatusDisplayName,
} from './';

class PageBookmarks extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      showUserMenu: false,
      showDetails: null, //a bookmark object
      displayTab: null,
      showRecommenders: null,
      allRead: {
        [BookmarkStatus.LIKED]: false,
        [BookmarkStatus.BOOKMARKED]: false,
        [BookmarkStatus.REVIEWED]: false,
      },
      pageIndices: {
        [BookmarkStatus.LIKED]: 0,
        [BookmarkStatus.BOOKMARKED]: 0,
        [BookmarkStatus.REVIEWED]: 0,
      },
    };
    this.handleToggleUserMenu = this.handleToggleUserMenu.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.handleShowBookmarkDetails = this.handleShowBookmarkDetails.bind(this);
    this.handleHideBookmarkDetails = this.handleHideBookmarkDetails.bind(this);
    this.handleClickTab = this.handleClickTab.bind(this);
    this.handleSaveReview = this.handleSaveReview.bind(this);
    this.handleChangeReview = this.handleChangeReview.bind(this);
    this.handleSelectBookmarkStatus = this.handleSelectBookmarkStatus.bind(this);
    this.handleShowRecommenders = this.handleShowRecommenders.bind(this);
    this.handleHideRecommenders = this.handleHideRecommenders.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.pageRefs = {
      [BookmarkStatus.LIKED]: React.createRef(),
      [BookmarkStatus.REVIEWED]: React.createRef(),
      [BookmarkStatus.BOOKMARKED]: React.createRef(),
    };
  }
  handleShowRecommenders(bookmarkId, e) {
    e.preventDefault();
    LOGGING && console.log('handleShowRecommenders called with:', bookmarkId);
    this.setState({ showRecommenders: bookmarkId });
  }
  handleHideRecommenders(e) {
    e.preventDefault();
    e.stopPropagation();
    LOGGING && console.log('handleHideRecommenders called');
    this.setState({ showRecommenders: null });
  }
  handleSelectBookmarkStatus(status, e) {
    e.preventDefault();
    const { showDetails } = this.state;
    LOGGING &&
      console.log('handleSelectBookmarkStatus called with:', {
        status,
        showDetails,
      });
    this.setState({ showDetails: { ...showDetails, status } });
  }

  handleChangeReview(e) {
    const { showDetails } = this.state;
    const { review } = showDetails;
    this.setState({
      showDetails: {
        ...showDetails,
        review: { ...review, content: e.target.value },
      },
    });
  }

  handleSaveReview(e) {
    e.preventDefault();
    const { showDetails } = this.state;
    LOGGING && console.log('handleSaveReview called with: ', { showDetails });
    const { status, review } = showDetails;
    this.setState({ loading: true }, () => {
      this.props.updateBookmark({
        _id: showDetails._id,
        bookmark: { status, review },
        updateAfterDone: 'bookmarks',
      });
      this.setState({
        isWritingView: false,
        loading: false,
        showDetails: null,
      });
    });
  }

  handleClickTab(clickedTab, e) {
    e.preventDefault();
    const { displayTab } = this.state;
    if (clickedTab !== displayTab) {
      this.setState({ displayTab: clickedTab });
    }
  }

  handleShowBookmarkDetails(bookmarkId, e) {
    e.preventDefault();
    LOGGING && console.log('handleShowBookmarkDetails called with: ', bookmarkId);
    const bookmark = this.props.bookmarks.find((b) => b._id === bookmarkId);
    const { review, status } = bookmark;
    this.setState({
      showDetails: {
        ...bookmark,
        review: review || { content: '' },
        status: status < BookmarkStatus.REVIEWED ? BookmarkStatus.LIKED : status,
      },
    });
  }

  handleHideBookmarkDetails(e) {
    e.preventDefault();
    LOGGING && console.log('handleHideBookmarkDetails called.');
    this.setState({ showDetails: null });
  }

  handleToggleUserMenu(e) {
    e.preventDefault();
    const { showUserMenu } = this.state;
    this.setState({ showUserMenu: !showUserMenu });
  }

  handleSignOut(e) {
    e.preventDefault();
    this.setState({ showUserMenu: false }, () => {
      this.props.signOut();
      this.props.history.push('/');
    });
  }
  handleScroll() {
    const { displayTab } = this.state;
    const { currentUser } = this.props;
    LOGGING && console.log('handleScroll called by tab:', displayTab);
    if (displayTab && this.pageRefs[displayTab].current) {
      const { bottom } = this.pageRefs[displayTab].current.getBoundingClientRect();
      const { innerHeight } = window;
      const { pageIndices, allRead } = this.state;
      LOGGING &&
        console.log('handleScroll called with state:', {
          pageIndices,
          allRead,
        });
      if (bottom <= innerHeight && !allRead[displayTab]) {
        LOGGING && console.log('bottom hit, reading pageIndex:', pageIndices[displayTab]);
        this.setState({ loading: true });
        this.props
          .fetchBookmarks({
            userId: currentUser.user._id,
            statuses: [displayTab],
            pageIndices,
          })
          .then((result) => {
            LOGGING && console.log('fetchBookmarks got from backend:', result);
            this.setState(
              {
                loading: false,
                pageIndices: {
                  ...pageIndices,
                  [displayTab]: pageIndices[displayTab] + 1,
                },
                allRead: { ...allRead, [displayTab]: result === 0 },
              }
              // ,
              // () => {
              //   // window.scrollTo(0, 0);
              //   LOGGING && console.log("no more:", result);
              // }
            );
          });
      }
    }
  }
  componentDidMount() {
    LOGGING && console.log('PageBookmarks componentDidMount');
    const { currentUser } = this.props;
    const { pageIndices } = this.state;
    window.addEventListener('scroll', this.handleScroll);
    this.setState({ loading: true }, () => {
      this.props
        .fetchBookmarks({
          userId: currentUser.user._id,
          statuses: [BookmarkStatus.BOOKMARKED, BookmarkStatus.LIKED, BookmarkStatus.REVIEWED],
          pageIndices,
        })
        .then(() => {
          const bookmarks = sortBookmarks(this.props.bookmarks);
          // const { bookmarks } = this.props;
          LOGGING && console.log('fetchbookmarks got bookmarks:', bookmarks);
          let { pageIndices, allRead } = this.state;

          [BookmarkStatus.BOOKMARKED, BookmarkStatus.LIKED, BookmarkStatus.REVIEWED].forEach(
            (status) => {
              if (bookmarks[status].length > 0) {
                pageIndices[status] += 1;
                if (bookmarks[status].length < PageSize) {
                  allRead[status] = true;
                }
              }
            }
          );

          this.setState(
            {
              loading: false,
              displayTab:
                bookmarks[BookmarkStatus.BOOKMARKED].length > 0
                  ? BookmarkStatus.BOOKMARKED
                  : bookmarks[BookmarkStatus.LIKED].length > 0
                  ? BookmarkStatus.LIKED
                  : bookmarks[BookmarkStatus.REVIEWED].length > 0
                  ? BookmarkStatus.REVIEWED
                  : BookmarkStatus.BOOKMARKED,
            },
            () => {
              window.scrollTo(0, 0);
            }
          );
        });
    });
    // }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const {
      loading,
      showUserMenu,
      showDetails, //a bookmark object
      displayTab,
      showRecommenders,
    } = this.state;
    const { currentUser, bookmarks } = this.props;
    LOGGING &&
      console.log('PageBookmarks rendering with', {
        props: this.props,
        state: this.state,
        currentUser,
      });

    if (!currentUser.isAuthenticated) return <Redirect to="/auth" />;
    const sortedBookmarks = sortBookmarks(bookmarks);
    return showDetails ? (
      <BookmarkDetails
        bookmark={showDetails}
        onClose={this.handleHideBookmarkDetails}
        onChangeReview={this.handleChangeReview}
        onSaveReview={this.handleSaveReview}
        onSelectStatus={this.handleSelectBookmarkStatus}
      />
    ) : (
      <div className="page bookmarks with-title" ref={this.pageRefs[displayTab]}>
        <Nav
          showUserMenu={showUserMenu}
          onToggleUserMenu={this.handleToggleUserMenu}
          currentUser={currentUser}
          onSignOut={this.handleSignOut}
          isBookmark={true}
          isFeed={false}
        />
        <Tabs
          tabs={['BOOKMARKED', 'LIKED', 'REVIEWED']}
          tabNames={BookmarkStatusDisplayName}
          tabTable={BookmarkStatus}
          selected={displayTab}
          onSelect={this.handleClickTab}
        />
        {loading ? (
          <Loading />
        ) : sortedBookmarks[displayTab].length > 0 ? (
          <BookmarkList
            bookmarks={sortedBookmarks[displayTab]}
            onShowBookmarkDetails={this.handleShowBookmarkDetails}
            onShowRecommenders={this.handleShowRecommenders}
            onHideRecommenders={this.handleHideRecommenders}
            showRecommenders={showRecommenders}
          />
        ) : (
          <div className="empty-list">
            <span>{`You haven't ${BookmarkStatusActionName[displayTab]} anything yet.`}</span>
          </div>
        )}
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log('PageBookmarks mapStateToProps got state:', state);
  return {
    bookmarks: state.bookmarks,
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  fetchBookmarks,
  updateBookmark,
  reviewBookmark,
  signOut,
})(PageBookmarks);
