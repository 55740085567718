import React, { ReactElement, useState, useEffect } from "react";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { set } from "lodash";
import { useDispatch, useStore } from "react-redux";
import { LOGGING } from "src/constants";
import { getUserTasteProfile, getUserTastes } from "src/store/actions";
import styled from "styled-components";
import { Nav } from ".";
import {
  CenteredByColumnLeft,
  Centered,
  centered,
  card,
  text12300,
  BorderGrey,
  centeredByColumn,
  NavHeight,
  TextTitle,
  Beige,
  Text12300,
} from "./components/Shared";
import { PopUp } from "../shared";
const UserContainer = styled.button<{ isShown?: boolean }>`
  ${centeredByColumn}
  margin: 5px;
  padding: 0px;
  position: relative;
  height: ${(props) => (props.isShown ? "500px" : "auto")};
  overflow-y: hidden;
  justify-content: flex-start;
  z-index: ${(props) => (props.isShown ? 2 : 1)};
`;
const UserName = styled(Centered)<{ isShown: boolean }>`
  ${text12300}
  text-transform: capitalize;
  padding: 10px;
  border: 1px solid ${BorderGrey};
  font-weight: 400;
  margin: 0px;
  background-color: ${(props) => (props.isShown ? "black" : "white")};
  color: ${(props) => (props.isShown ? "white" : "black")};
  svg {
    margin-left: 10px;
  }
`;
const FavoriteRestaurantList = styled.div`
  position: fixed;
  top: 10px;
  ${centeredByColumn}
  margin: 10px;
  padding: 10px;
  border: 1px solid ${BorderGrey};
  z-index: 1;
  background-color: white;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
  height: calc(100vh - ${NavHeight}px - 20px);
  overflow-y: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  span {
    text-transform: capitalize;
    margin: 5px;
  }
`;
const RestaurantContainer = styled(CenteredByColumnLeft)`
  align-self: stretch;
  flex-grow: 1;
`;
const RestaurantName = styled(TextTitle)`
  text-transform: capitalize;
  margin: 5px;
`;
const Dishes = styled(Centered)`
  align-self: stretch;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
`;
const DishContainer = styled(CenteredByColumnLeft)`
  margin: 5px;
  padding: 2px 5px;
  width: 200px;
  text-transform: capitalize;
  border: 1px solid ${BorderGrey};
  align-self: stretch;
  background-color: ${Beige};
  /* flex-grow: 1; */
  ${text12300}
  line-height: 1.5;
  justify-content: space-between;
  align-items: flex-start;
`;
const OrderCount = styled(Text12300)`
  margin: 5px;
`;
const RestaurantList = styled(CenteredByColumnLeft)`
  align-self: stretch;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
  height: 300px;
`;

const Restaurant = ({ restaurant, orderCount, favoriteDishes }) => {
  const { name } = restaurant;
  LOGGING && console.log("Restaurant", restaurant);
  favoriteDishes.sort((a, b) => b.orderCount - a.orderCount);
  return (
    <RestaurantContainer>
      <RestaurantName>{name}</RestaurantName>
      <OrderCount>
        ordered <b>{orderCount}</b> times
      </OrderCount>
      <Dishes>
        {favoriteDishes.map(({ dish, orderCount }) => (
          <DishContainer key={dish._id}>
            <span>{dish?.name}</span>
            <OrderCount>
              ordered <b>{orderCount}</b> times
            </OrderCount>
          </DishContainer>
        ))}
      </Dishes>
    </RestaurantContainer>
  );
};
const User = ({ user, onToggle, isShown }) => {
  const { firstName, lastName } = user;
  return (
    <UserContainer onClick={onToggle} isShown={isShown}>
      <UserName isShown={isShown}>
        <span>{`${firstName} ${lastName}`}</span>
        <FontAwesomeIcon icon={isShown ? faChevronUp : faChevronDown} />
      </UserName>
    </UserContainer>
  );
};
const ProfileTitle = styled(TextTitle)`
  display: inline-block;
  align-self: stretch;
  flex-grow: 1;
  width: 100%;
  background-color: white;
  margin: 5px;
  font-size: 20px;
  line-height: 20px;
  /* border-bottom: 1px solid ${BorderGrey}; */
  position: absolute;
  top: 0px;
`;
const Profile = styled(CenteredByColumnLeft)`
  padding-top: 30px;
  height: 200px;
  overflow-y: auto;
  justify-content: flex-start;
  span:last-child {
    border: 1px solid ${BorderGrey};
    padding: 10px;
    background-color: ${Beige};
    ${text12300}
    white-space: pre-wrap;
    line-height: 1.5;
    text-transform: none;
  }
`;
interface PageUserTasteProps {}
const Container = styled(Centered)`
  position: fixed;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding: 10px 20px;
  overflow-y: hidden;
`;
export const PageUserTasteOverview: React.FC<PageUserTasteProps> =
  (): ReactElement => {
    const dispatch = useDispatch();
    const store = useStore();
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [favoriteRestaurants, setFavoriteRestaraunts] = useState([]);
    const [profile, setProfile] = useState("");
    const [userTasteProfiles, setUserTasteProfiles] = useState({});
    const onToggleShowDetails = (user, e) => {
      e.preventDefault();
      LOGGING && console.log("onToggleShowDetails called with user:", user);
      if (selectedUser?._id === user._id) {
        setSelectedUser(null);
        setFavoriteRestaraunts([]);
        setProfile("");
        return;
      }
      setSelectedUser(user);
      if (!userTasteProfiles[user._id]) {
        getUserTasteProfile(user._id)(dispatch, store.getState).then(
          (response) => {
            console.log("User taste profile", response);
            let { favoriteRestaurants, profile } = response;
            favoriteRestaurants.sort((a, b) => b.orderCount - a.orderCount);
            setFavoriteRestaraunts(favoriteRestaurants);
            setProfile(profile);
          }
        );
      } else {
        setFavoriteRestaraunts(userTasteProfiles[user._id].favoriteRestaurants);
        setProfile(userTasteProfiles[user._id].profile);
      }
    };

    useEffect(() => {
      getUserTastes()(dispatch, store.getState).then((response) => {
        setUsers(response);
      });
    }, []);
    console.log("selectedUser", selectedUser);
    return (
      <Container>
        {users?.map(({ user }) => (
          <User
            user={user}
            isShown={selectedUser === user._id}
            onToggle={onToggleShowDetails.bind(this, user)}
          />
        ))}
        <PopUp
          isPoppedUp={selectedUser}
          hidePopUp={() => setSelectedUser(null)}
          backgroundColor="rgba(0,0,0,0.5)"
          componentToDisplay={
            <FavoriteRestaurantList>
              {/* <UserName isShown={true}>
                <span>{`${selectedUser?.firstName} ${selectedUser?.lastName}`}</span>
              </UserName> */}
              <Profile>
                <ProfileTitle>Profile:</ProfileTitle>
                <span>{profile}</span>
              </Profile>
              <RestaurantList>
                {favoriteRestaurants.map(
                  ({ restaurant, orderCount, favoriteDishes }) => (
                    <Restaurant
                      key={restaurant._id}
                      restaurant={restaurant}
                      orderCount={orderCount}
                      favoriteDishes={favoriteDishes}
                    />
                  )
                )}
              </RestaurantList>
            </FavoriteRestaurantList>
          }
        />
      </Container>
    );
  };
