import React, { ReactElement, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useDispatch, useStore, useSelector } from "react-redux";
import { readAllRoutes } from "src/store/actions";
import { mealMarkerByMealId } from "src/util/stopMap";
import styled from "styled-components";
import { LOGGING } from "..";
import {
  Centered,
  CenteredByColumnLeft,
  Text12300,
  TextGrey,
  Beige,
  text12300,
  Purple,
  BorderGrey,
} from "../Shared";

export interface RoutesOverviewProps {
  initialRoutes?: any[];
}

const Container = styled(CenteredByColumnLeft)`
  width: 100%;
  padding: 20px 0px;
`;
const RouteContainer = styled(Centered)`
  justify-content: flex-start;
  padding: 10px 20px;
  align-self: stretch;
  &:nth-child(odd) {
    background-color: white;
  }
  &:nth-child(even) {
    background-color: ${Beige};
  }
`;
const Driver = styled(Centered)<{ color: string }>`
  ${text12300}
  background-color: ${(props) => props.color};
  color: white;
  font-weight: 500;
  align-self: stretch;
  border-radius: 5px;
  margin-right: 15px;
  width: 100px;
  line-height: 1.5;
  text-align: center;
`;
const Progress = styled(Centered)`
  justify-content: flex-start;
`;
const StopContainer = styled(Centered)`
  position: relative;
  padding: 25px 0px;
`;

const Time = styled(Text12300)<{ delivered?: boolean }>`
  position: absolute;
  bottom: 0px;
  left: -5px;
  font-weight: 500;
  color: ${(props) => (props.delivered ? "green" : "orange")};
`;
const Delay = styled(Time)`
  left: 28px;
  color: red;
  width: auto;
  text-align: left;
`;

const StopLineLength = 60;
const Name = styled(Text12300)`
  position: absolute;
  top: 0px;
  left: 0px;
  /* background-color: red; */
  width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  color: ${TextGrey};
  text-transform: capitalize;
`;
const StatusDot = styled(Centered)<{
  delivered?: boolean;
  isFirstStop?: boolean;
}>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  /* background-color: ${(props) =>
    props.delivered ? "green" : "transparent"}; */
  /* border: 2px solid ${(props) => (props.delivered ? "green" : "orange")}; */
  margin-right: ${StopLineLength}px;

  color: ${(props) => (props.delivered ? "green" : "orange")};

  position: relative;
  box-sizing: border-box;
  svg {
    font-size: 24px;
  }
  span {
    font-size: 12px;
    position: absolute;
    color: white;
  }
  &:before {
    content: "";
    position: absolute;
    width: ${StopLineLength + 2}px;
    height: 0px;
    border-bottom: 2px ${(props) => (props.delivered ? "solid" : "dashed")}
      ${(props) => (props.delivered ? "green" : "orange")};
    box-sizing: content-box;
    top: calc(50% - 1px);
    right: 20px;
    /* transform: translate(-50%, -50%); */
  }
  // isLastStop is true, no line after
  &:before {
    display: ${(props) => (props.isFirstStop ? "none" : "block")};
  }
`;

const Status = ({ delivered, isFirstStop, icon }) => (
  <StatusDot delivered={delivered} isFirstStop={isFirstStop}>
    <FontAwesomeIcon icon={icon} />
    <span>{delivered ? "✓" : ""}</span>
  </StatusDot>
);
const Stop = ({ stop, isFirstStop, eta, delay, updateDelay, marker }) => {
  // LOGGING && console.log('Stop', stop);
  const delivered = stop?.deliveryTime > 0;
  const now = moment().valueOf();
  let newDelay = 0;
  if (stop.eta && stop.eta < now) {
    newDelay = Math.floor((now - stop.eta) / 60000);
    if ((!delay && newDelay > 0) || newDelay > delay) {
      LOGGING && console.log("calling onUpdateETAs with delay:", newDelay);
      updateDelay(newDelay);
    }
  }

  const name = stop?.names?.[0]?.split(" ")[0];
  return (
    <StopContainer>
      <Name>{name}</Name>
      <Status delivered={delivered} isFirstStop={isFirstStop} icon={marker} />
      {delivered ? (
        <Time delivered={delivered}>
          {moment(stop.deliveryTime).format("h:mm")}
        </Time>
      ) : eta ? (
        <>
          <Time delivered={false}>{moment(eta).format("h:mm")}</Time>
          {delay ? <Delay>{delay}m</Delay> : null}
        </>
      ) : null}
    </StopContainer>
  );
};
const Route = ({ route, updateDelay, mealMarkers }) => {
  const { driverFirstName, driverColor, stops, delay } = route;
  const sortedStops = stops.sort((a, b) => a.stopNum - b.stopNum);
  const stopsWithETAs = sortedStops.map((stop, index) => {
    if (index === 0 || stop.deliveryTime > 0) {
      return stop;
    } else {
      const { deliveryTime, eta } = sortedStops[index - 1];
      stop.eta = (deliveryTime || eta) + stop.estimatedTimeSpent * 60 * 1000;
    }
    return stop;
  }, []);

  return (
    <RouteContainer>
      <Driver color={driverColor}>{driverFirstName}</Driver>
      <Progress>
        {stopsWithETAs.map((stop, index) => (
          <Stop
            stop={stop}
            key={stop._id}
            isFirstStop={index === 0}
            eta={stop.eta}
            delay={delay}
            updateDelay={updateDelay.bind(this, route._id)}
            marker={mealMarkers[stop.meal]}
          />
        ))}
      </Progress>
    </RouteContainer>
  );
};
export const RoutesOverview: React.FC<RoutesOverviewProps> = ({
  initialRoutes,
}: RoutesOverviewProps): ReactElement => {
  const [routes, setRoutes] = React.useState([]);
  const [mealMarkers, setMealMarkers] = React.useState({});
  const store = useStore();
  const dispatch = useDispatch();
  const onUpdateDelay = (routeId, delay) => {
    LOGGING && console.log("onUpdateEtas called", routeId, delay);
    const routeToUpdate = routes.find((route) => route._id === routeId);
    const updatedRoute = { ...routeToUpdate, delay };
    const updatedRoutes = routes.map((route) =>
      route._id === routeId ? updatedRoute : route
    );
    setRoutes(updatedRoutes);
  };
  // useEffect(() => {
  //   getAdminDeliveryV2StopsForDay()(dispatch, store.getState).then(() => {
  //     const stops = useSelector((state: RootState) => state.adminDeliveryV2.stops);
  //   });
  // }, [dispatch]);

  useEffect(() => {
    //start a set interval to update the routes every 10 seconds

    const validRoutes = initialRoutes.filter(
      (route) => route.stops.length > 0 && !route.endTime
    );
    validRoutes.sort((a, b) => {
      if (!a.startTime) return -1;
      if (!b.startTime) return 1;
      return b.startTime - a.startTime;
    });
    const stops = validRoutes.reduce((acc, route) => {
      return acc.concat(
        route.stops.map((stop) => {
          stop.routeId = route._id;
          return stop;
        })
      );
    }, []);
    const mealMarkers = mealMarkerByMealId(stops);
    setMealMarkers(mealMarkers);
    LOGGING &&
      console.log("RoutesOverview got mealMarkers", { stops, mealMarkers });

    setRoutes(validRoutes);
    if (validRoutes?.length > 0) {
      const interval = setInterval(() => {
        readAllRoutes(true)(dispatch, store.getState).then((routes) => {
          const validRoutes = routes.filter(
            (route) => route.stops.length > 0 && !route.endTime
          );
          // sort route by starttime in a descending order, if no start time, put it to the beginning
          validRoutes.sort((a, b) => {
            if (!a.startTime) return -1;
            if (!b.startTime) return 1;
            return b.startTime - a.startTime;
          });
          setRoutes(validRoutes);
        });
      }, 30000);
    }
  }, [dispatch]);

  LOGGING && console.log("RoutesOverview got routes", routes);
  return (
    <Container>
      {routes.map((route) => (
        <Route
          key={route._id}
          route={route}
          updateDelay={onUpdateDelay}
          mealMarkers={mealMarkers}
        />
      ))}
    </Container>
  );
};
