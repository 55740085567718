import { useSelector } from "react-redux";
import { LOGGING } from "src/constants";
import { ObjectId } from "src/constants/types";
import { getDishCountInCart } from "src/util/cart";
import { getMyGoods } from "src/util/order";

export const useDishCountInCart = (dishId: ObjectId): number => {
  const cart = useSelector((state: any) => state.cart);
  const currentUser = useSelector((state: any) => state.currentUser);
  if (cart?.payLoad == null) return 0;
  const { goods } = cart.payLoad;
  const myGoods = getMyGoods(goods, currentUser?.user?._id);
  // LOGGING &&
  //   console.log("useDishCountInCart rendering with:", { goods, myGoods });
  return getDishCountInCart(dishId, { ...cart.payLoad, goods: { ...myGoods } });
};
