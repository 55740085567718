// import index.scss

import { useEffect, useState } from "react";
import moment from "moment";
import { useStore, useDispatch } from "react-redux";
import styled from "styled-components";
import {
  Centered,
  Text12300,
  ActionBlack,
  CenteredByColumn,
  CenteredByColumnLeft,
  Beige,
  BorderGrey,
  card,
  TextGrey,
  BackgroundGrey,
  text12300,
} from "./components/Shared";
import { readUnansweredMessages, sendResponse } from "../../store/actions";
import { LOGGING } from "../shared";

const From = styled(Centered)`
  ${text12300}

  color: ${TextGrey};
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
  span {
    text-transform: capitalize;
  }
  label {
    margin-left: 20px;
    font-weight: 400;
  }
`;
const MessageList = styled(Centered)`
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
`;
const MessageBody = styled(Text12300)`
  background-color: ${BackgroundGrey};
  font-weight: 400;
  padding: 5px 15px;
  text-align: left;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  // style it so the lower left corner has a tail like a speech bubble
  position: relative;
  height: auto;
  line-height: 2;
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: -10px;
    border: 10px solid transparent;
    border-right-color: ${BackgroundGrey};
    display: none;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -8px;
    border: 4px solid transparent;
    border-right-color: ${BackgroundGrey};
    /* display: none; */
  }
`;
const MessageContainer = styled(CenteredByColumnLeft)`
  ${card}
  border: 1px solid ${BorderGrey};
  padding: 20px;
  max-width: 300px;
  margin: 10px;
  align-self: stretch;
  flex-grow: 1;
  input {
    width: 100%;
    margin-top: 10px;
  }
`;
const SendResponse = styled(ActionBlack)`
  // when disabled it should be grey
  &:disabled {
    background-color: ${BackgroundGrey};
    color: ${TextGrey};
  }
`;
const Message = ({ message, remove }) => {
  const [response, setResponse] = useState("");
  const [isSending, setIsSending] = useState(false);
  const dispatch = useDispatch();
  const store = useStore();

  const formatTimestamp = (timestamp) => {
    const now = moment();
    const time = moment(timestamp);
    const duration = moment.duration(now.diff(time));

    if (duration.asHours() < 1) {
      return time.fromNow(); // "5 min ago" format
    } else {
      return time.format("ddd MMM DD HH:mm:ss");
    }
  };
  const handleSendResponse = (e) => {
    e.preventDefault();
    setIsSending(true);
    sendResponse(message._id, response)(dispatch, store.getState).then(
      (response) => {
        console.log("response sent", response);
        setIsSending(false);
        remove();
      }
    );
  };
  return (
    <MessageContainer>
      <From>
        <span>{`${message?.user?.firstName} ${message?.user?.lastName}`}</span>
        <label>{formatTimestamp(message?.sendTime)}</label>
      </From>
      <MessageBody>{message.text}</MessageBody>
      <input
        type="text"
        value={response}
        onChange={(e) => setResponse(e.target.value)}
      />
      <SendResponse onClick={handleSendResponse} disabled={isSending}>
        {`${isSending ? "Sending..." : "reply"}`}
      </SendResponse>
    </MessageContainer>
  );
};
export const PageMessages = () => {
  const [messages, setMessages] = useState([]);
  const dispatch = useDispatch();
  const store = useStore();
  const removeMessage = (messageId) => {
    setMessages((prevMessages) =>
      prevMessages.filter((message) => message._id !== messageId)
    );
  };
  // readUnansweredMessages() when landed and save them to messages
  useEffect(() => {
    setInterval(() => {
      readUnansweredMessages()(dispatch, store.getState).then((response) => {
        setMessages(response);
      });
    }, 10000);
  }, []);

  //   LOGGING && console.log("Pagemessages rendering with", messages);
  return (
    <div className="page">
      <MessageList>
        {messages?.map((message) => (
          <Message
            message={message}
            remove={removeMessage.bind(this, message._id)}
          />
        ))}
      </MessageList>
    </div>
  );
};
