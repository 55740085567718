import { useCallback } from 'react';
import { readDrivers } from 'src/store/actions';
import { LOAD_ADMIN_DELIVERY_V2_DRIVERS } from 'src/store/actionTypes';
import { PopUp } from '..';
import { AwaitServerResponseContainer } from './AwaitServerResponseContainer';
import { SwapDrivers } from './SwapDrivers';

export const SwapDriversContainer = ({ isShown, hide }) => {
  const fetchData = useCallback(async (dispatch, getState) => {
    // Get all drivers
    const drivers = await readDrivers()(dispatch, getState);
    dispatch({
      type: LOAD_ADMIN_DELIVERY_V2_DRIVERS,
      drivers,
    });
  }, []);
  return (
    <AwaitServerResponseContainer awaitable={fetchData}>
      <PopUp
        isPoppedUp={isShown}
        componentToDisplay={<SwapDrivers onSubmit={hide} />}
        hidePopUp={hide}
      />
    </AwaitServerResponseContainer>
  );
};
