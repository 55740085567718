import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { UserType, useUserType } from 'src/hooks/useUserType';
import { RootState } from 'src/store';
import styled from 'styled-components';
import { RestaurantPaymentSummaryContainer } from './components/restaurants/RestaurantPaymentSummaryContainer';
import Navbar from './Navbar';

interface PageRestaurantFinancialsProps {}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 36px;
`;

export const PageRestaurantFinancials: React.FC<PageRestaurantFinancialsProps> =
  (): ReactElement => {
    const currentUser = useSelector((state: RootState) => state.currentUser);
    const userType = useUserType();

    if (userType !== UserType.Restaurant) {
      return <Redirect to="/" />;
    }

    const restaurantId = currentUser.user.restaurant._id;
    return (
      <div className="page">
        <Navbar noShadow={false} />
        <ContentContainer>
          <h1>Financials</h1>
          <div>
            <h2>Payment Summary</h2>
            <RestaurantPaymentSummaryContainer restaurantId={restaurantId} />
          </div>
        </ContentContainer>
      </div>
    );
  };
