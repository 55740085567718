import { Component } from 'react';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { faCar, faCheck, faMapMarkerExclamation } from '@fortawesome/pro-regular-svg-icons';
import { faHouse } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GoogleMapReact from 'google-map-react';
import { SelectStopIndex, LOGGING } from './';
import './StopsMap.scss';

const RestaurantMarker = ({ restaurant }) => (
  <div className="address-marker restaurant-marker">
    <FontAwesomeIcon icon={faHouse} />
    <span>{restaurant}</span>
  </div>
);

const DeliveryAddressMarker = ({
  eta,
  color,
  name,
  groupName,
  nameList,
  stopIndex,
  showDriverList,
  showInBackground,
  onToggleShowDriverList,
  drivers,
  selecteDriver,
  selectedDriverTotalStops,
  onSelectDriver,
  selectedStop,
  onSelectStop,
  isEarlyDelivery,
  unassignedOrders,
}) => {
  const driverList = drivers ? Object.keys(drivers) : [];
  const selectedDriverList = driverList.filter((d) => d === selecteDriver);
  const otherDriverList = driverList.filter((d) => d !== selecteDriver);
  const sortedDriverList = [...selectedDriverList, ...otherDriverList];
  // LOGGING &&
  //   console.log('DeliveryAddressMarker GOT:', { eta, drivers, sortedDriverList, stopIndex });
  return (
    <div className="stop-on-map">
      <div className={`address-marker home-marker ${showInBackground ? 'show-in-background' : ''}`}>
        <button onClick={onToggleShowDriverList} className="toggle-details">
          <FontAwesomeIcon
            icon={isEarlyDelivery ? faMapMarkerExclamation : faMapMarker}
            style={{ color }}
          />
        </button>
        {/* <div className="address-point" style={{ backgroundColor: color }}></div> */}
        <div className="order-name">
          {stopIndex !== undefined ? (
            <div className="order-stop-index">{String(stopIndex + 1)}</div>
          ) : null}
          <div className="order-firstName" style={{ color }}>
            {`${groupName || name} ${nameList.length > 1 ? `(${nameList.length})` : ''}`}
            {unassignedOrders ? (
              <p style={{ color: 'red', margin: 0, padding: 0 }}>Needs Reassign</p>
            ) : null}
          </div>
          {eta ? <div className="order-eta" style={{ color }}>{`${eta}m`}</div> : null}
        </div>
      </div>
      {showDriverList ? (
        <div className="driver-list-on-map">
          {sortedDriverList.map((driverId, index) => (
            <div className="driver-for-stop" key={index}>
              <button
                className={`select-driver ${
                  driverId === selecteDriver ? 'selected' : 'unselected'
                }`}
                style={
                  driverId === selecteDriver
                    ? { backgroundColor: drivers[driverId].color }
                    : {
                        color: drivers[driverId].color,
                        border: `1px solid ${drivers[driverId].color}`,
                        backgroundColor: 'white',
                      }
                }
                onClick={onSelectDriver.bind(this, driverId)}
              >
                <FontAwesomeIcon icon={faCar} />
                <span>{drivers[driverId].name}</span>
                {driverId === selecteDriver ? <FontAwesomeIcon icon={faCheck} /> : null}
              </button>
              {driverId === selecteDriver && selectedDriverTotalStops > 0 ? (
                <SelectStopIndex
                  selectedStop={selectedStop}
                  onSelectStop={onSelectStop}
                  driverTotalStops={selectedDriverTotalStops}
                  driverColor={drivers[driverId].color}
                />
              ) : null}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

class StopsMap extends Component {
  constructor(props) {
    super();
    LOGGING && console.log('StopsMap constructor got:', props);
    const { restaurant } = props;
    this.state = {
      center: { ...restaurant.center },
      zoom: 11,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return { ...state, center: { ...props.restaurant.center } };
  }

  render() {
    const {
      restaurant,
      stops,
      showDriverListForOrder,
      onToggleShowDriverListForOrder,
      drivers,
      onSelectDriverForOrder,
      onSelectStopForOrder,
    } = this.props;
    const { center, zoom } = this.state;

    LOGGING &&
      console.log(
        'StopsMap rendering with: ',
        {
          stops,
          drivers,
          center,
          restaurant,
          zoom,
        },
        this
      );
    return (
      // Important! Always set the container height explicitly
      <div className="stops-map">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyB-0SwH76HPTJGm_mhM1Rg0ACcpYGpAt1Q',
          }}
          // defaultCenter={center}
          defaultZoom={zoom}
          center={[center.lat, center.lng]}
        >
          <RestaurantMarker restaurant={restaurant.name} lat={center.lat} lng={center.lng} />
          {stops.map((stop, index) => (
            <DeliveryAddressMarker
              address={stop.address}
              name={stop.name}
              nameList={stop.nameList}
              groupName={stop.groupName}
              key={index}
              lat={stop.map && stop.map.lat}
              lng={stop.map && stop.map.lng}
              color={stop.driverColor}
              stopIndex={stop.stopIndex}
              eta={stop.eta}
              showInBackground={showDriverListForOrder && showDriverListForOrder !== stop._id}
              showDriverList={showDriverListForOrder === stop._id}
              onToggleShowDriverList={
                onToggleShowDriverListForOrder
                  ? onToggleShowDriverListForOrder.bind(this, stop._id)
                  : null
              }
              drivers={drivers}
              selecteDriver={stop.driverId}
              onSelectDriver={
                onSelectDriverForOrder ? onSelectDriverForOrder.bind(this, stop.nameList) : null
              }
              onSelectStop={
                onSelectStopForOrder ? onSelectStopForOrder.bind(this, stop.nameList) : null
              }
              selectedDriverTotalStops={stop.selectedDriverTotalStops}
              selectedStop={stop.stopIndex}
              isEarlyDelivery={stop.isEarlyDelivery}
              unassignedOrders={stop.unassignedOrders}
            />
          ))}
        </GoogleMapReact>
      </div>
    );
  }
}

export default StopsMap;
