import moment from 'moment-timezone';
import { SET_IS_LUNCH } from '../actionTypes';

const isLunch = (state = { payLoad: null, loadedAt: moment() }, action) => {
  switch (action.type) {
    case SET_IS_LUNCH:
      return { payLoad: action.isLunch, loadedAt: moment() };
    default:
      return state;
  }
};

export default isLunch;
