import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import Geocode from 'react-geocode';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { driverHourlyWage } from 'src/constants';
import {
  LOGGING,
  Loading,
  DriverList,
  Navbar,
  getNextDeliveryTime,
  CATimeZone,
  getCurrentDayDeliveryWindows,
} from '.';
import {
  readOrders,
  markOrderAsOrdered,
  markOrderAsDelivered,
  markOrderAsDispatched,
  readDrivers,
  assignDriverToOrder,
  assignStopToOrder,
  getDriverTime,
  askDriverAvailability,
  createRoute,
  createDriver,
  notifyPickMeal,
  calculateDriverRoutes,
  swapDrivers,
  cancelDriver,
} from '../../store/actions';
import { getNextWindow } from '../../util/time';
// Geocode.setApiKey('AIzaSyDMShXhqw3W4RWi3Xgzom2NUamTAdfZ0pc');
const sortDrivers = ({ drivers, driverIds, restaurant }) => {
  LOGGING && console.log('sortDrivers got', { drivers, driverIds, restaurant });
  const acceptedDriverIds = driverIds.filter(
    (d) =>
      drivers[d].askedAvailability === true &&
      drivers[d].routeAcceptanceResponse === true &&
      drivers[d].restaurantName === restaurant.name
  );
  const rejectedDriverIds = driverIds.filter(
    (d) => drivers[d].askedAvailability === true && drivers[d].routeAcceptanceResponse === false
  );

  const expiredDriverIds = driverIds.filter(
    (d) => drivers[d].askedAvailability === true && drivers[d].askedAvailabilityExpired === true
  );

  const waitingDriverIds = driverIds.filter(
    (d) => drivers[d].askedAvailability === true && drivers[d].askedAvailabilityExpired === false
  );
  const unAskedDriverIds = driverIds.filter((d) => drivers[d].askedAvailability === false);
  return {
    acceptedDriverIds,
    waitingDriverIds,
    expiredDriverIds,
    rejectedDriverIds,
    unAskedDriverIds,
  };
};
class PageAdmin extends Component {
  constructor() {
    super();
    LOGGING && console.log('PageAdmin constructor');
    this.state = {
      processingDeliveryId: null,
      isLunch: true,
      loading: false,
      initialized: false,
      ordersByRestaurant: null,
      showDriversForOrder: -1,
      showStopsForOrder: -1,
      showDriversAvailability: false,
      showCreateDriver: false,
      newDriverName: '',
      newDriverPhone: '',
      creatingNewDriver: false,
      displayDriverOnly: -1,
      viewAddress: true,
      viewDishes: true,
      center: {},
      driverIds: [],
      allDriverIds: {},
      showDriversForOrderOnMap: null,
      driversWithRoute: new Map(),
      selectedDriverForRouting: null,
      selectedRestaurant: [],
      restaurantList: [],
      showRestaurantOptions: false,
      showAcceptedDrivers: true,
    };
    this.loadData = this.loadData.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDeliver = this.handleDeliver.bind(this);
    this.handleDispatch = this.handleDispatch.bind(this);
    this.handlePlaceOrder = this.handlePlaceOrder.bind(this);
    this.handleSelectMeal = this.handleSelectMeal.bind(this);
    this.handleToggleShowDriversForOrder = this.handleToggleShowDriversForOrder.bind(this);
    this.handleToggleShowDriversAvailability = this.handleToggleShowDriversAvailability.bind(this);
    this.handleSelectDriverForOrder = this.handleSelectDriverForOrder.bind(this);
    this.handleFilterDriver = this.handleFilterDriver.bind(this);
    this.handleSelectStopForOrder = this.handleSelectStopForOrder.bind(this);
    this.handleToggleShowStopsForOrder = this.handleToggleShowStopsForOrder.bind(this);
    this.handleToggleViewAddress = this.handleToggleViewAddress.bind(this);
    this.handleToggleViewDishes = this.handleToggleViewDishes.bind(this);
    this.handleBackToTop = this.handleBackToTop.bind(this);
    this.handleGoToBottom = this.handleGoToBottom.bind(this);
    this.handleGetDriverTime = this.handleGetDriverTime.bind(this);
    this.handleAskDriverAvailability = this.handleAskDriverAvailability.bind(this);
    this.handleResetDriverForOrder = this.handleResetDriverForOrder.bind(this);
    this.handleToggleShowDriversForOrderOnMap =
      this.handleToggleShowDriversForOrderOnMap.bind(this);
    this.handleFinalizeDriverRoute = this.handleFinalizeDriverRoute.bind(this);
    this.handleToggleShowCreateDriver = this.handleToggleShowCreateDriver.bind(this);
    this.handleChangeDriverName = this.handleChangeDriverName.bind(this);
    this.handleChangeDriverPhone = this.handleChangeDriverPhone.bind(this);
    this.handleCreateNewDriver = this.handleCreateNewDriver.bind(this);
    this.handleNotifyPickMeal = this.handleNotifyPickMeal.bind(this);
    this.handleFindDriverRoutes = this.handleFindDriverRoutes.bind(this);
    this.bottomRef = React.createRef();
    this.handleSelectRestaurant = this.handleSelectRestaurant.bind(this);
    this.handleToggleShowRestaurantOptions = this.handleToggleShowRestaurantOptions.bind(this);
    this.handleToggleShowAcceptedDrivers = this.handleToggleShowAcceptedDrivers.bind(this);
    this.handleWrap = this.handleWrap.bind(this);
    this.handleCancelDriver = this.handleCancelDriver.bind(this);
  }
  handleCancelDriver(driverId, orderIds, e) {
    e.preventDefault();
    let { drivers, allDriverIds } = this.state;
    const { acceptedDriverIds, unAskedDriverIds } = allDriverIds;
    LOGGING &&
      console.log('handleCancelDriver called with:', {
        driverId,
        orderIds,
        drivers,
        allDriverIds,
        acceptedDriverIds,
        unAskedDriverIds,
      });
    allDriverIds.acceptedDriverIds = acceptedDriverIds.filter((i) => i !== driverId);
    allDriverIds.unAskedDriverIds = [...unAskedDriverIds, driverId];
    LOGGING && console.log('handleCancelDriver got new allDriverIds:', allDriverIds);
    LOGGING && console.log('handleCancelDriver got  drivers[driverId]:', drivers[driverId]);

    this.props.cancelDriver(driverId, orderIds).then(() => {
      const { color, name, phone, weeklySchedule } = drivers[driverId];
      drivers[driverId] = {
        askedAvailability: false,
        color,
        name,
        phone,
        weeklySchedule,
        askedAvailabilityExpired: undefined,
        askedAvailabilityTime: undefined,
        restaurantName: undefined,
        routeAcceptanceResponse: undefined,
        weeklySchedule: undefined,
      };

      this.setState({ drivers, allDriverIds });
    });
  }
  handleToggleShowAcceptedDrivers() {
    const { showAcceptedDrivers } = this.state;
    this.setState({ showAcceptedDrivers: !showAcceptedDrivers });
  }
  handleToggleShowRestaurantOptions(e) {
    LOGGING && console.log('handleToggleShowRestaurantOptions called');
    e.preventDefault();
    const { showRestaurantOptions } = this.state;
    this.setState({ showRestaurantOptions: !showRestaurantOptions });
  }

  handleSelectRestaurant(restaurantIndex) {
    const { restaurantList, selectedRestaurant, drivers, driverIds } = this.state;
    LOGGING &&
      console.log('handleSelectRestaurant called with:', {
        restaurantList,
        restaurantIndex,
        selectedRestaurant,
        drivers,
        driverIds,
      });
    this.setState({
      selectedRestaurant: [restaurantList[restaurantIndex]],
      showRestaurantOptions: false,
      allDriverIds: sortDrivers({
        drivers,
        driverIds,
        restaurant: restaurantList[restaurantIndex],
      }),
    });
  }

  handleChangeDriverName(e) {
    e.preventDefault();
    LOGGING && console.log('handleChangeDriverName', e);
    this.setState({ newDriverName: e.target.value });
  }

  handleChangeDriverPhone(e) {
    e.preventDefault();
    LOGGING && console.log('handleChangeDriverPhone', e);
    this.setState({ newDriverPhone: e.target.value });
  }

  handleCreateNewDriver(e) {
    e.preventDefault();
    LOGGING && console.log('handleCreateNewDriver', e, this.state);
    this.setState({ creatingNewDriver: true });
    this.props.createDriver(this.state.newDriverName, this.state.newDriverPhone).then(() => {
      this.setState({ newDriverName: '', newDriverPhone: '' });
      this.loadData();
      this.setState({ creatingNewDriver: false });
    });
  }

  handleToggleShowDriversForOrderOnMap(orderId, e) {
    e.preventDefault();
    const { showDriversForOrderOnMap } = this.state;
    LOGGING &&
      console.log('handleToggleShowDriversForOrderOnMap called with:', {
        showDriversForOrderOnMap,
        orderId,
      });
    if (orderId === showDriversForOrderOnMap) {
      this.setState({ showDriversForOrderOnMap: null });
    } else {
      this.setState({ showDriversForOrderOnMap: orderId });
    }
  }

  loadData() {
    const { windows } = getNextDeliveryTime();
    this.setState({ loading: true }, () => {
      this.props.readDrivers(windows.default.start).then((drivers) => {
        LOGGING && console.log('PageAdmin got drivers from backend:', drivers, windows);
        let driverIds = [];
        let result = {};
        drivers.forEach((driver) => {
          driverIds.push(driver._id);
          result[driver._id] = {
            name: driver.firstName,
            phone: driver.phone,
            color: driver.color,
            weeklySchedule: driver.weeklySchedule,
            askedAvailability: driver.askedAvailability,
            askedAvailabilityExpired: driver.askedAvailabilityExpired,
            routeAcceptanceResponse: driver.routeAcceptanceResponse,
            askedAvailabilityTime: driver.askedAvailabilityTime,
            restaurantName: driver.restaurantName,
          };
        });
        const { initialized } = this.state;
        LOGGING && console.log('xiaohuizhou1:', { initialized, drivers, result });
        this.setState(
          {
            drivers: result,
            driverIds,
          },
          () => {
            const { windows } = getCurrentDayDeliveryWindows(
              moment().tz(CATimeZone).add(-2, 'h').valueOf(),
              false
            );

            const lunchTime = windows[true].default.start;
            const dinnerTime = windows[false].default.start;

            this.props.readOrders(true, lunchTime, dinnerTime).then(() => {
              const { orders } = this.props;
              let { drivers } = this.state;
              let ordersByRestaurant = {};
              let driversWithRoute = new Map();
              orders.forEach((o) => {
                const { goods, meal, email } = o;

                if (o.route != null) {
                  driversWithRoute.set(o.route.driver, o.route._id);
                }
                goods.forEach((g) => {
                  const { dish, comment, quantity } = g;
                  const { restaurant } = dish;
                  const { name } = restaurant;
                  if (name in ordersByRestaurant) {
                    ordersByRestaurant[name].push({
                      email,
                      meal,
                      dish,
                      comment,
                      quantity,
                    });
                  } else {
                    ordersByRestaurant[name] = [
                      {
                        email,
                        meal,
                        dish,
                        comment,
                        quantity,
                      },
                    ];
                  }
                });
              });

              this.setState({ driversWithRoute });

              let { selectedRestaurant, isLunch } = this.state;
              // only auto change tab when first initialize
              if (!this.state.initialized) {
                isLunch = orders.filter((o) => o.isLunch).length > 0;
              }
              LOGGING &&
                console.log('loaddata called with: ', {
                  orders,
                  isLunch,
                  initialized: this.state.initialized,
                });
              if (orders.length > 0) {
                const allRestaurants = orders
                  .filter(
                    (order) => order.goods[0].dish.restaurant.name && order.isLunch === isLunch
                  )
                  .map((order) => order.goods[0].dish.restaurant);
                const restaurantList = Array.from(new Set(allRestaurants.map((a) => a._id)))
                  .map((id) => {
                    return allRestaurants.find((a) => a._id === id);
                  })
                  .map((restaurant) => {
                    return {
                      _id: restaurant._id,
                      name: restaurant.name,
                      address: restaurant.address,
                      center: restaurant.map,
                    };
                  });
                LOGGING &&
                  console.log('loaddata got: ', {
                    restaurantList,
                    selectedRestaurant,
                  });
                selectedRestaurant =
                  selectedRestaurant.length === 0 ? [restaurantList[0]] : selectedRestaurant;

                this.setState(
                  {
                    loading: false,
                    initialized: true,
                    ordersByRestaurant,
                    drivers,
                    isLunch,
                    restaurantList,
                    selectedRestaurant,
                    allDriverIds: sortDrivers({
                      drivers: result,
                      driverIds,
                      restaurant: selectedRestaurant[0],
                    }),
                  },
                  () => {
                    window.scrollTo(0, 0);
                  }
                );
              } else {
                this.setState({
                  loading: false,
                  initialized: true,
                  ordersByRestaurant,
                  drivers,
                  isLunch,
                });
              }
            });
          }
        );
      });
    });
  }

  handleToggleShowDriversAvailability(e) {
    e.preventDefault();
    const { showDriversAvailability } = this.state;
    this.setState({ showDriversAvailability: !showDriversAvailability });
  }
  handleToggleShowCreateDriver(e) {
    e.preventDefault();
    const { showCreateDriver } = this.state;
    this.setState({ showCreateDriver: !showCreateDriver });
  }
  handleAskDriverAvailability(driverId, e) {
    e.preventDefault();
    const { driverIds, selectedRestaurant, ordersByRestaurant } = this.state;
    LOGGING &&
      console.log(
        'handleAskDriverAvailability called with driverId: ',
        driverId,
        driverIds,
        ordersByRestaurant
      );
    const mealID = ordersByRestaurant[selectedRestaurant[0].name][0].meal._id;
    this.props
      .askDriverAvailability(driverId, mealID, selectedRestaurant[0], getNextWindow().driverPickup)
      .then(() => {
        let { drivers, selectedRestaurant } = this.state;
        drivers[driverId] = {
          ...drivers[driverId],
          askedAvailability: true,
          askedAvailabilityTime: moment().valueOf(),
          askedAvailabilityExpired: false,
          restaurantName: selectedRestaurant[0].name,
        };
        this.setState({
          drivers,
          allDriverIds: sortDrivers({ drivers, driverIds, restaurant: selectedRestaurant }),
        });
      });
  }

  handleGetDriverTime(driverId, restaurantAddress, windowStartTime, e) {
    e.preventDefault();
    this.props.getDriverTime(driverId, restaurantAddress, windowStartTime).then((driverTime) => {
      let { drivers } = this.state;
      drivers[driverId] = { ...drivers[driverId], driverTime };
      this.setState({ drivers });
    });
  }

  handleFinalizeDriverRoute(driverId, e) {
    // need to create combined orders here
    e.preventDefault();
    LOGGING && console.log('handleFinalizeDriverRoute: ', driverId, this.props.orders);
    this.setState({ loading: true });
    const orders = this.props.orders.filter(
      (o) =>
        o.driver &&
        o.driver._id === driverId &&
        o.isLunch === this.state.isLunch &&
        !o.deliveryTime &&
        !o.cancelTime
    );
    const highestOrderStop = Math.max(...orders.map((order) => order.stop));
    // ^ find the highest stop number out of all orders; prevents error for multiple orders at same address
    LOGGING && console.log('These are orders: ', orders);
    LOGGING && console.log('This is the highestOrderStop: ', highestOrderStop);
    const estimatedRouteTime = orders.find(
      (order) => order.stop === highestOrderStop
    ).etaAtRestaurant;
    LOGGING && console.log('Found the estimatedRouteTime: ', estimatedRouteTime);
    if (orders.length === 0) {
      console.log('no order for this driver in this meal');
      this.setState({ loading: false });
      return;
    }
    const restaurantId = orders[0].goods[0].dish.restaurant._id;
    const orderIds = orders.map((o) => o._id);
    const { selectedRestaurant, ordersByRestaurant } = this.state;
    const mealID = ordersByRestaurant[selectedRestaurant[0].name][0].meal._id;
    this.props
      .createRoute({
        driverId,
        restaurantId,
        orderIds,
        estimatedRouteTime,
        driverHourlyWage,
        mealID,
      })
      .then(() => {
        this.loadData();
        this.setState({ route: this.props.route });
        this.setState({ loading: false });
      });
  }

  handleNotifyPickMeal(restaurantId, driverPickTimes) {
    LOGGING && console.log('handleNotifyPickMeal: ', restaurantId, driverPickTimes);
    this.props.notifyPickMeal(restaurantId, driverPickTimes).then(() => {
      toast.success('Drivers has been notified!');
    });
  }

  handleGoToBottom(e) {
    e.preventDefault();
    this.bottomRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  handleBackToTop(e) {
    e.preventDefault();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  handleToggleViewDishes(e) {
    e.preventDefault();
    const { viewDishes } = this.state;
    LOGGING &&
      console.log('handleToggleViewDishes called with: ', {
        viewDishes,
      });
    this.setState({ viewDishes: !viewDishes });
  }
  handleToggleViewAddress(e) {
    e.preventDefault();
    const { viewAddress } = this.state;
    LOGGING &&
      console.log('handleToggleViewAddress called with: ', {
        viewAddress,
      });
    this.setState({ viewAddress: !viewAddress });
  }

  handleToggleShowStopsForOrder(orderId, e) {
    e.preventDefault();
    const { showStopsForOrder } = this.state;
    LOGGING &&
      console.log('handleToggleShowStopsForOrder called with: ', {
        orderId,
        showStopsForOrder,
      });
    this.setState({
      showStopsForOrder: showStopsForOrder === orderId ? -1 : orderId,
    });
  }

  handleSelectStopForOrder(orderIds, stopId) {
    LOGGING &&
      console.log('handleSelectStopForOrder called with: ', {
        orderIds,
        stopId,
      });
    this.props.assignStopToOrder(orderIds, stopId).then(() => {
      this.setState({ showStopsForOrder: -1, showDriversForOrderOnMap: null });
    });
  }

  handleFilterDriver(driverId, e) {
    e.preventDefault();
    const { displayDriverOnly } = this.state;
    LOGGING &&
      console.log('handleFilterDriver called with:', {
        driverId,
        displayDriverOnly,
      });
    if (displayDriverOnly === driverId) {
      this.setState({ displayDriverOnly: -1 });
    } else {
      this.setState({ displayDriverOnly: driverId });
    }
  }

  handleToggleShowDriversForOrder(orderId, e) {
    e.preventDefault();
    const { showDriversForOrder } = this.state;
    LOGGING &&
      console.log('handleToggleShowDriversForOrder called with: ', {
        orderId,
        showDriversForOrder,
      });
    this.setState({
      showDriversForOrder: showDriversForOrder === orderId ? -1 : orderId,
    });
  }

  handleSelectDriverForOrder(orderIds, driverId) {
    LOGGING &&
      console.log('handleSelectDriverForOrder called with: ', {
        orderIds,
        driverId,
      });
    const routeId = this.state.driversWithRoute.get(driverId);
    this.setState({ loading: true });
    this.props.assignDriverToOrder(orderIds, driverId, routeId).then(() => {
      this.loadData();
      this.setState({ loading: false });
      this.setState({
        showDriversForOrder: -1,
        showDriversForOrderOnMap: null,
      });
    });
  }

  handleResetDriverForOrder(orderId) {
    LOGGING && console.log('handleResetDriverForOrder called with: ', { orderId });
    this.props.assignDriverToOrder(orderId, null).then(() => {
      this.setState({ showDriversForOrder: -1 });
    });
  }

  handleSelectMeal(isLunch, e) {
    e.preventDefault();

    const { orders } = this.props;

    const { _id, name, address, map } = Object.values(
      orders.filter((o) => o.isLunch === isLunch)[0].goods
    )?.[0]?.dish?.restaurant;
    LOGGING &&
      console.log('handleSelectMeal called with:', { orders, isLunch, _id, name, address, map });

    const allRestaurants = orders
      .filter((order) => order.goods[0].dish.restaurant.name && order.isLunch === isLunch)
      .map((order) => order.goods[0].dish.restaurant);
    LOGGING && console.log('handleSelectMeal got allRestaurants:', allRestaurants);
    const restaurantList = Array.from(new Set(allRestaurants.map((a) => a._id)))
      .map((id) => {
        return allRestaurants.find((a) => a._id === id);
      })
      .map((restaurant) => {
        return {
          _id: restaurant._id,
          name: restaurant.name,
          address: restaurant.address,
          center: restaurant.map,
        };
      });
    LOGGING && console.log('handleSelectMeal got restaurantList:', restaurantList);
    this.setState({
      isLunch,
      restaurantList,
      selectedRestaurant: [restaurantList[0]],
    });

    LOGGING && console.log('handleSelectMeal got:', name, address, map);
  }

  handleDeliver(orderId, e) {
    LOGGING &&
      console.log('PageAdmin handleDeliver called with:', {
        orderId,
      });
    e.preventDefault();
    this.setState({ processingOrderId: orderId }, () => {
      this.props.markOrderAsDelivered(orderId).then(() => {
        this.setState({ processingOrderId: null });
      });
    });
  }

  handlePlaceOrder(orderId, e) {
    e.preventDefault();
    LOGGING && console.log('handlePlaceOrder called with orderId:', orderId);
    this.props.markOrderAsOrdered(orderId);
  }

  handleDispatch(orderId, e) {
    LOGGING &&
      console.log('PageAdmin handleDispatch called with:', {
        orderId,
      });
    e.preventDefault();
    this.setState({ processingOrderId: orderId }, () => {
      this.props.markOrderAsDispatched(orderId).then(() => {
        this.setState({ processingOrderId: null });
      });
    });
  }

  handleFindDriverRoutes(windowStartTime, force, e) {
    LOGGING && console.log('PageAdmin handleFindDriverRoutes', windowStartTime, force, e);
    e.preventDefault();
    this.props.calculateDriverRoutes(windowStartTime, force).then(() => {
      this.loadData();
    });
  }

  handleChangeSelectedDriverForRouting() {}

  handleWrap(driver1, driver2, orders) {
    this.props.swapDrivers(driver1, driver2, orders, {
      success: () => {
        toast.success('Driver swap success!');
        this.loadData();
      },
      error: () => {
        toast.error('Driver swap failed!');
      },
    });
  }

  handleClose() {
    LOGGING && console.log('PageAdmin handleClose');
    this.props.history.goBack();
  }

  componentDidMount() {
    LOGGING && console.log('PageAdmin componentdidmount');
    this.loadData();
  }

  render() {
    const { orders, currentUser } = this.props;
    const {
      processingOrderId,
      isLunch,
      loading,
      drivers,
      allDriverIds,
      showDriversForOrder,
      showDriversAvailability,
      showDriversForOrderOnMap,
      displayDriverOnly,
      showStopsForOrder,
      viewAddress,
      viewDishes,
      restaurantList,
      selectedRestaurant,
      initialized,
      showRestaurantOptions,
      showAcceptedDrivers,
    } = this.state;
    LOGGING &&
      console.log('xiaohuizhou PageAdmin rendering with', {
        props: this.props,
        state: this.state,
        user: currentUser.user,
      });

    if (!currentUser.isAuthenticated || !currentUser.user.isAdmin) {
      return <Redirect to="/" />;
    }

    let date = moment();
    if (orders?.length > 0) {
      const order = orders[0];
      date = moment(order.window.start);
    }
    const day = date.format('dddd').toLocaleLowerCase();
    const availableDrivers = cloneDeep(drivers || {});
    LOGGING &&
      console.log('PageAdmin got', {
        availableDrivers,
        drivers,
      });
    Object.entries(availableDrivers).forEach(([driverId, driver]) => {
      if (driver.weeklySchedule?.[day]?.[isLunch ? 'lunch' : 'dinner'] !== true) {
        delete availableDrivers[driverId];
      }
    });

    return (
      <div className="page">
        <Navbar noShadow={true} />
        {loading ? <Loading /> : null}
        {initialized ? (
          <DriverList
            isLunch={isLunch}
            restaurantList={restaurantList}
            selectedRestaurant={selectedRestaurant}
            lunchNumber={orders.filter((o) => o.isLunch).length}
            dinnerNumber={orders.filter((o) => !o.isLunch).length}
            onSelectMeal={this.handleSelectMeal}
            orders={orders.filter(
              (o) =>
                o.isLunch === isLunch &&
                !o.deliveryTime &&
                !o.cancelTime &&
                selectedRestaurant
                  .map((restaurant) => restaurant._id)
                  .includes(o.goods[0].dish.restaurant._id)
            )}
            onBack={this.handleClose}
            onMarkAsOrdered={this.handlePlaceOrder}
            onDeliver={this.handleDeliver}
            onDispatch={this.handleDispatch}
            processingOrderId={processingOrderId}
            onSelectDriverForOrder={this.handleSelectDriverForOrder}
            onResetDriverForOrder={this.handleResetDriverForOrder}
            driversOnDuty={Array.from(
              new Set(orders.filter((o) => o.driver).map((o) => o.driver._id))
            )}
            displayDriverOnly={displayDriverOnly}
            onFilterDriver={this.handleFilterDriver}
            drivers={availableDrivers}
            allDriverIds={allDriverIds}
            showDriversForOrder={showDriversForOrder}
            showDriversAvailability={showDriversAvailability}
            onToggleShowDriversForOrder={this.handleToggleShowDriversForOrder}
            onToggleShowDriversAvailability={this.handleToggleShowDriversAvailability}
            onToggleShowCreateDriver={this.handleToggleShowCreateDriver}
            showCreateDriver={this.state.showCreateDriver}
            onSelectStopForOrder={this.handleSelectStopForOrder}
            showStopsForOrder={showStopsForOrder}
            onToggleShowStopsForOrder={this.handleToggleShowStopsForOrder}
            onToggleViewAddress={this.handleToggleViewAddress}
            onToggleViewDishes={this.handleToggleViewDishes}
            viewAddress={viewAddress}
            viewDishes={viewDishes}
            onBackToTop={this.handleBackToTop}
            onGoToBottom={this.handleGoToBottom}
            bottomRef={this.bottomRef}
            onGetDriverTime={this.handleGetDriverTime}
            onAskDriverAvailability={this.handleAskDriverAvailability}
            showDriversForOrderOnMap={showDriversForOrderOnMap}
            onToggleShowDriversForOrderOnMap={this.handleToggleShowDriversForOrderOnMap}
            onFinalizeDriverRoute={this.handleFinalizeDriverRoute}
            driversWithRoute={this.state.driversWithRoute}
            newDriverPhone={this.state.newDriverPhone}
            newDriverName={this.state.newDriverName}
            onChangeDriverName={this.handleChangeDriverName}
            onChangeDriverPhone={this.handleChangeDriverPhone}
            onCreateNewDriver={this.handleCreateNewDriver}
            onFindDriverRoutes={this.handleFindDriverRoutes}
            onNotifyPickMeal={this.handleNotifyPickMeal}
            creatingNewDriver={this.state.creatingNewDriver}
            selectedDriverForRouting={this.state.selectedDriverForRouting}
            onChangeSelectedDriverForRouting={this.handleChangeSelectedDriverForRouting}
            onSelectRestaurant={this.handleSelectRestaurant}
            showRestaurantOptions={showRestaurantOptions}
            onToggleShowRestaurantOptions={this.handleToggleShowRestaurantOptions}
            onToggleShowAcceptedDrivers={this.handleToggleShowAcceptedDrivers}
            showAcceptedDrivers={showAcceptedDrivers}
            onSwap={this.handleWrap}
            onCancelDriver={this.handleCancelDriver}
          />
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log('PageDriver got redux state:', state);
  return {
    orders: state.orders,
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  askDriverAvailability,
  readOrders,
  createRoute,
  readDrivers,
  markOrderAsOrdered,
  markOrderAsDelivered,
  markOrderAsDispatched,
  assignDriverToOrder,
  assignStopToOrder,
  getDriverTime,
  createDriver,
  notifyPickMeal,
  calculateDriverRoutes,
  swapDrivers,
  cancelDriver,
})(PageAdmin);
