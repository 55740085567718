import { Fragment, useCallback, useMemo, useState } from 'react';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { GiftCardTemplate, GIFT_CARD_TEMPLATES } from 'src/components/GiftCard';
import { Cell, Flex, Grid } from 'src/components/Layout';
import { Navbar } from 'src/components/Navbar';
import { Pay, PayMethod, validateNewCard } from 'src/components/Pay';
import { useForm } from 'src/hooks';
import { createGiftCard } from 'src/store/actions';
import styled from 'styled-components';
import validator from 'validator';

type FormData = {
  recipientName?: string;
  recipientEmail?: string;
  sum?: any;
  template?: string;
  message?: string;
};

const INITIALIZE_VALUES: FormData = {
  recipientName: '',
  recipientEmail: '',
  sum: '',
  template: GIFT_CARD_TEMPLATES[0],
  message: '',
};

enum Step {
  RecipientInfo,
  ChooseATemplate,
  Preview,
  Payment,
}

export const CreateGiftCard = () => {
  const currentUser = useSelector((state: any) => state.currentUser);
  const dispatch = useDispatch();
  const [error, setError] = useState<any>({});
  const { formData, updateFormData, onInputChange } = useForm<FormData>(INITIALIZE_VALUES, {
    onChange: (name: string) => error.param === name && setError({}),
  });
  const [newCard, setNewCard] = useState<any>({});
  const [newCardType, setNewCardType] = useState<any>({});
  const [newCardError, setNewCardError] = useState<any>({});
  const [step, setStep] = useState<Step>(Step.RecipientInfo);
  const [payMethod, setPayMethod] = useState<PayMethod>(PayMethod.Stripe);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const history = useHistory();

  const { card } = currentUser.user?.stripeInfo || {};

  const title = useMemo(() => {
    switch (step) {
      case Step.RecipientInfo:
        return 'Recipient Info';
      case Step.ChooseATemplate:
        return 'Choose A Template';
      case Step.Preview:
        return 'Preview';
      case Step.Payment:
        return 'Payment';
    }
  }, [step]);

  const validateRecipientInfo = useCallback(() => {
    if (!formData.recipientName) {
      setError({ param: 'recipientName', message: "Recipient name can't be empty" });
      return false;
    }
    if (!formData.recipientEmail) {
      setError({ param: 'recipientEmail', message: "Recipient email can't be empty" });
      return false;
    }
    if (!validator.isEmail(formData.recipientEmail)) {
      setError({
        param: 'recipientEmail',
        message: 'Recipient email is not a valid email address',
      });
      return false;
    }
    if (!formData.sum) {
      setError({ param: 'sum', message: "Sum can't be empty" });
      return false;
    }
    if (formData.sum < 20) {
      setError({ param: 'sum', message: 'The minimum sum is $20' });
      return false;
    }
    return true;
  }, [formData]);

  const validatePreview = useCallback(() => {
    if (!formData.message) {
      setError({ param: 'message', message: "Message can't be empty" });
      return false;
    }
    return true;
  }, [formData]);

  const onGiftCardTemplateChange = (template: string) => {
    updateFormData((formData) => {
      formData.template = template;
    });
  };

  const onNewCardChange = (name: string, value: any) => {
    if (newCardError.param === name) {
      setNewCardError({});
    }
    setNewCard((newCard: any) => ({
      ...newCard,
      [name]: value,
    }));
  };

  const onCreateGiftCard = useCallback(
    (giftCard: any) => {
      setSubmitting(true);
      dispatch(
        createGiftCard(giftCard, {
          success: () => {
            setSubmitting(false);
            toast.success('Gift Card Created!');
            history.push('/');
          },
          error: (err: any) => {
            setSubmitting(false);
            toast.error(err.message);
          },
        })
      );
    },
    [dispatch, history]
  );

  const onSubmit = useCallback(() => {
    const giftCard: any = cloneDeep(formData);
    giftCard.payMethod = 'stripe';
    if (!card) {
      const result = validateNewCard(newCard, newCardType);
      if (result !== true) {
        setNewCardError(result);
        return;
      }
      giftCard.newCard = cloneDeep(newCard);
    }
    onCreateGiftCard(giftCard);
  }, [card, formData, newCard, newCardType, onCreateGiftCard]);

  const onPaypalPaid = useCallback(
    (paypalCaptureId: string) => {
      const giftCard: any = cloneDeep(formData);
      giftCard.payMethod = 'paypal';
      giftCard.paypalCaptureId = paypalCaptureId;
      onCreateGiftCard(giftCard);
    },
    [formData, onCreateGiftCard]
  );

  if (!currentUser.isAuthenticated) {
    return <Redirect to="/signup" />;
  }

  return (
    <div className="page">
      <StyledNavbar title={title} noBack showClose noShadow />
      <Container>
        {step === Step.RecipientInfo && (
          <>
            <div className="form-section">
              <div className="form-section-title">Recipient Name</div>
              <div className="form-row">
                <div
                  className={`form-field-wrapper ${error.param === 'recipientName' ? 'wrong' : ''}`}
                >
                  <input
                    name="recipientName"
                    value={formData.recipientName}
                    placeholder="Enter Recipient Name"
                    onChange={onInputChange}
                  />
                  {error.param === 'recipientName' && (
                    <div className="error-message">{error.message}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-section">
              <div className="form-section-title">Recipient Email</div>
              <div className="form-row">
                <div
                  className={`form-field-wrapper ${
                    error.param === 'recipientEmail' ? 'wrong' : ''
                  }`}
                >
                  <input
                    name="recipientEmail"
                    value={formData.recipientEmail}
                    placeholder="Enter Recipient Email"
                    onChange={onInputChange}
                  />
                  {error.param === 'recipientEmail' && (
                    <div className="error-message">{error.message}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-section">
              <div className="form-section-title">Sum</div>
              <div className="form-row">
                <div className={`form-field-wrapper ${error.param === 'sum' ? 'wrong' : ''}`}>
                  <input
                    name="sum"
                    value={formData.sum}
                    placeholder="Enter Sum"
                    type="number"
                    onChange={onInputChange}
                  />
                  {error.param === 'sum' && <div className="error-message">{error.message}</div>}
                </div>
              </div>
            </div>
            <Flex justify="center" gap={24}>
              <StyledButton
                onClick={() => {
                  if (validateRecipientInfo()) {
                    setStep(Step.ChooseATemplate);
                  }
                }}
              >
                Next
              </StyledButton>
            </Flex>
          </>
        )}
        {step === Step.ChooseATemplate && (
          <>
            <Grid columns="1fr 30px" align="center" gap={12}>
              {GIFT_CARD_TEMPLATES.map((template) => (
                <Fragment key={template}>
                  <Cell>
                    <GiftCardTemplate
                      template={template}
                      message="your message"
                      onClick={() => onGiftCardTemplateChange(template)}
                    />
                  </Cell>
                  <Cell>
                    <Checker
                      onClick={() => onGiftCardTemplateChange(template)}
                      $checked={formData.template === template}
                    >
                      <Flex justify="center" align="center" widthFull heightFull>
                        {formData.template === template && <FontAwesomeIcon icon={faCheck} />}
                      </Flex>
                    </Checker>
                  </Cell>
                </Fragment>
              ))}
            </Grid>
            <Flex justify="center" gap={24}>
              <StyledButton variant="secondary" onClick={() => setStep(Step.RecipientInfo)}>
                Prev
              </StyledButton>
              <StyledButton onClick={() => setStep(Step.Preview)}>Next</StyledButton>
            </Flex>
          </>
        )}
        {step === Step.Preview && (
          <>
            <GiftCardTemplate
              template={formData.template}
              message={formData.message || 'your message'}
            />
            <div className="form-section">
              <div className="form-section-title">Message</div>
              <div className="form-row">
                <div className={`form-field-wrapper ${error.param === 'message' ? 'wrong' : ''}`}>
                  <input
                    name="message"
                    value={formData.message}
                    placeholder="Enter Message"
                    onChange={onInputChange}
                  />
                  {error.param === 'message' && (
                    <div className="error-message">{error.message}</div>
                  )}
                </div>
              </div>
            </div>
            <Flex justify="center" gap={24}>
              <StyledButton variant="secondary" onClick={() => setStep(Step.ChooseATemplate)}>
                Prev
              </StyledButton>
              <StyledButton
                onClick={() => {
                  if (validatePreview()) {
                    setStep(Step.Payment);
                  }
                }}
              >
                Next
              </StyledButton>
            </Flex>
          </>
        )}
        {step === Step.Payment && (
          <>
            <Pay
              onChangePayMethod={setPayMethod}
              newCard={newCard}
              newCardType={newCardType}
              newCardError={newCardError}
              onNewCardChange={onNewCardChange}
              onNewCardTypeChange={setNewCardType}
              amount={Number(formData.sum)}
              onPaypalPaid={onPaypalPaid}
            />
            <Flex justify="center" gap={24}>
              <StyledButton
                variant="secondary"
                disabled={submitting}
                onClick={() => setStep(Step.Preview)}
              >
                Prev
              </StyledButton>
              {payMethod === PayMethod.Stripe && (
                <StyledButton loading={submitting} onClick={onSubmit}>
                  Submit
                </StyledButton>
              )}
            </Flex>
          </>
        )}
      </Container>
    </div>
  );
};

const StyledNavbar = styled(Navbar)`
  background-color: #fe4945;
  color: white;
  svg {
    color: white;
  }
`;

const Container = styled.div`
  padding: 20px;
  width: 100%;

  .form-section {
    margin: 20px 0 0 0;

    input {
      width: 100%;
      font-size: 16px;
    }
  }

  img {
    max-width: 100%;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 40px;
  margin-bottom: 40px;
`;

const Checker = styled.div<{ $checked?: boolean }>`
  width: 30px;
  height: 30px;
  border: solid 2px #fe4945;
  border-radius: 50%;

  svg {
    color: white;
  }

  ${({ $checked }) => ($checked ? 'background: #fe4945;' : '')}
`;
