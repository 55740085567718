import { SerializedRestaurant } from 'src/api/restaurants';
import {
  MEAL_MENU_LOAD_RESTAURANT,
  MEAL_MENU_LOAD_RESTAURANT_DISHES,
  MEAL_MENU_LOAD_SELECTED_DISHES,
} from '../actionTypes';

export const loadRestaurant = (restaurant: SerializedRestaurant) => ({
  type: MEAL_MENU_LOAD_RESTAURANT,
  restaurant,
});

export const loadRestaurantDishes = (restaurantDishes: any[]) => ({
  type: MEAL_MENU_LOAD_RESTAURANT_DISHES,
  restaurantDishes,
});

export const loadSelectedDishes = (selectedDishes: string[]) => ({
  type: MEAL_MENU_LOAD_SELECTED_DISHES,
  selectedDishes,
});
