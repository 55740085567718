import { ReactElement, useCallback, useState, useEffect } from "react";
import { faMapMarkerAlt as faMap } from "@fortawesome/free-solid-svg-icons";
import {
  faChevronUp,
  faChevronDown,
  faCheck,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GoogleMapReact from "google-map-react";
import { capitalize, set } from "lodash";
import toast from "react-hot-toast";
import { useSelector, useDispatch, useStore } from "react-redux";
import { MealVisited } from "src/api/meals";
import {
  SupportedDeliveryCities,
  CityCoordinatesMobile,
  CityCoordinatesDesktop,
  LOGGING,
  CityStyles,
} from "src/constants";
import { RootState } from "src/store";
import { updateDeliveryCity, nominateDeliveryCity } from "src/store/actions";
import styled from "styled-components";
import { SavingScreen } from "../customers/survey/Taste";
import {
  BorderGrey,
  card,
  Centered,
  centered,
  openingPopUp,
  text12300,
  textTitle,
  NavHeight,
  CenteredByColumn,
  actionBlack,
} from "../Shared";

export interface ContentWrapperProps {
  isMobile: boolean;
}

const ContentWrapper = styled(Centered)<ContentWrapperProps>`
  ${openingPopUp}
  left: ${(props) => (props.isMobile ? "0px" : "max(40px, 50vw - 400px)")};
  top: ${(props) =>
    props.isMobile ? "0px" : "max(${NavHeight}px, calc(50vh - 400px))"};
  width: ${(props) => (props.isMobile ? "100vw" : "calc(100vw - 80px)")};
  height: ${(props) =>
    props.isMobile ? "100vh" : "calc(100vh - ${NavHeight}px -96px)"};
  max-width: 800px;
  /* max-height: 800px; */
  padding: 10px;
  border: 10px solid white;
  padding-top: ${NavHeight + 10}px;
  a {
    display: none;
  }
  .gmnoprint {
    display: none;
  }
  @media only screen and (min-width: 800px) {
  }
`;

export interface CityProps {
  wrap: boolean;
  isMobile: boolean;
  isSelected?: boolean;
}

const CityFuture = styled(CenteredByColumn)<ContentWrapperProps>`
  position: fixed;
  z-index: 5;
  background-color: white;
  ${centered}
  flex-direction: column;
  bottom: ${(props) =>
    props.isMobile ? `${NavHeight}px` : `${2 * NavHeight}px`};
  border-top: 1px solid ${BorderGrey};
  padding: 20px;
  width: ${(props) => (props.isMobile ? "100vw" : "calc(100vw - 80px)")};
  max-width: 800px;
  input {
    align-self: stretch;
    margin: 20px;
  }
  button {
    ${actionBlack}
  }
`;
const City = styled.div<CityProps>`
  ${centered}
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.16) 0px 0px 5px 0px;
  button {
    margin: 0px;
    ${card}
    width: auto;
    padding: 8px 12px;
    ${text12300}
    text-transform: uppercase;
    line-height: 1.3;
    font-weight: 500;
    text-align: center;
    border: 1px solid ${BorderGrey};
    white-space: ${(props) => (props.wrap ? "normal" : "nowrap")};
    // isSelected background black color white
    background-color: ${(props) => (props.isSelected ? "black" : "white")};
    color: ${(props) => (props.isSelected ? "white" : "black")};
  }
  /* svg {
    margin-right: 5px;
    font-size: 20px;
    background-color: white;
  } */
`;
export const OnboardingTitle = styled(CenteredByColumn)<ContentWrapperProps>`
  /* ${card} */
  ${textTitle}
  text-transform: none;
  font-size: 20px;
  /* font-size: 13px;
  font-weight: 500; */
  background-color: white;
  z-index: 5;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  /* height: ${NavHeight}px; */
  padding: 20px 10px 10px 10px;
  /* border-bottom: 1px solid ${BorderGrey}; */
  left: ${(props) => (props.isMobile ? "0px" : "max(40px, 50vw - 400px)")};
  top: ${(props) => (props.isMobile ? "0px" : `${NavHeight}px`)};
  width: ${(props) => (props.isMobile ? "100vw" : "calc(100vw - 80px)")};
  max-width: 800px;
  label {
    ${text12300};
    line-height: 1.5;
    text-align: center;
    margin: 10px 0px;
  }
`;
const HorizontalLine = styled.div<ContentWrapperProps>`
  width: ${(props) => (props.isMobile ? "100vw" : "calc(100vw - 80px)")};
  left: ${(props) => (props.isMobile ? "0px" : "max(40px, 50vw - 400px)")};
  height: 10px;
  background-color: white;
  position: fixed;
  display: block;
  z-index: 4;
  max-width: 800px;
`;

const HorizontalLineTop = styled(HorizontalLine)`
  top: ${NavHeight}px;
`;
const HorizontalLineBottom = styled(HorizontalLine)`
  bottom: ${NavHeight}px;
`;

const Footer = styled.div<ContentWrapperProps>`
  /* height: ${NavHeight}px; */
  background-color: white;
  position: fixed;
  display: flex;
  z-index: 4;
  /* border-top: 1px solid ${BorderGrey}; */
  left: ${(props) => (props.isMobile ? "0px" : "max(40px, 50vw - 400px)")};
  bottom: ${(props) => (props.isMobile ? "0px" : `${NavHeight}px`)};
  width: ${(props) => (props.isMobile ? "100vw" : "calc(100vw - 80px)")};
  max-width: 800px;
  padding: 10px;
  button {
    margin: 0px;
    padding: 0px;
    ${text12300}
    text-align: center;
    flex-grow: 1;
    align-self: stretch;
    /* width: ${(props) =>
      props.isMobile ? "100vw" : "calc(100vw - 80px)"}; */
    height: 65px;
    max-width: 800px;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 1px;
    height: 65px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    svg {
      position: fixed;
      right: ${(props) =>
        props.isMobile ? "40px" : "max(68px, 50vw - 400px + 28px)"};
      bottom: ${(props) =>
        props.isMobile ? "35px" : `calc(28px + ${NavHeight}px`};
      font-size: 18px;
    }
  }
  @media screen and (min-height: 840px) {
    button {
      height: calc(100vh - 800px);
      svg {
        bottom: calc(50vh - 400px);
      }
    }
  }
`;

export const CitySelector = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const deliveryZoneByCity = useSelector(
    (state: any) => state.deliveryZoneByCity
  );
  const [showCityFuture, setShowCityFuture] = useState(false);
  const [futureCityName, setFutureCityName] = useState("");
  const [selectingCity, setSelectingCity] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");
  LOGGING && console.log("deliveryZoneByCity:", deliveryZoneByCity);
  const onSelect = async (city) => {
    const selectedDeliveryZone = deliveryZoneByCity[city];
    setSelectingCity(true);
    setSelectedCity(city);
    // delay 300 ms
    setTimeout(async () => {
      await updateDeliveryCity(selectedDeliveryZone)(dispatch, store.getState);
      setSelectingCity(false);
      MealVisited(
        `selected city ${city}`,
        `${currentUser?.user?.firstName} ${currentUser?.user?.lastName}`,
        "",
        "",
        0
      );
    }, 300);
  };
  const onToggleFooter = (e) => {
    e.preventDefault();
    setFutureCityName("");
    setShowCityFuture(!showCityFuture);
  };
  const onNominate = useCallback(async (city) => {
    LOGGING && console.log("onNominate called with:", city);
    await nominateDeliveryCity(city)(dispatch, store.getState);

    setShowCityFuture(false);
  }, []);

  const onChangeFutureCityName = (e) => {
    e.preventDefault();
    setFutureCityName(e.target.value);
  };
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  const isMobile = vw < 500;
  const CityCoordinates = isMobile
    ? CityCoordinatesMobile
    : CityCoordinatesDesktop;
  LOGGING &&
    console.log("City Selector rendering with:", {
      isMobile,
      futureCityName,
      selectedCity,
      selectingCity,
      SupportedDeliveryCities,
    });
  return (
    <ContentWrapper isMobile={isMobile}>
      {selectingCity ? <SavingScreen message={`Saving your city...`} /> : null}
      <OnboardingTitle isMobile={isMobile}>
        {`${capitalize(currentUser.user.firstName)}, select your city.`}
        <label>
          It will help us route. You will have access to all restaurants.
        </label>
      </OnboardingTitle>
      {/* <HorizontalLineTop isMobile={isMobile}></HorizontalLineTop> */}
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyB-0SwH76HPTJGm_mhM1Rg0ACcpYGpAt1Q",
          sensor: false,
        }}
        defaultZoom={isMobile ? 10 : 11}
        disableDefaultUI={true}
        style={{ text: "none" }}
        center={
          isMobile ? [37.3457349, -122.0488378] : [37.3607349, -122.0088378]
        }
        // layerTypes={['TrafficLayer', 'TransitLayer']}
        options={{
          panControl: false,
          mapTypeControl: false,
          scrollwheel: false,
          fullscreenControl: false,
          zoomControl: false,
          styles: [
            // {
            //   stylers: [{ saturation: -100 }],
            // },
            {
              elementType: "labels.text",
              stylers: [{ visibility: "off" }],
            },
            // {
            //   featureType: "landscape",
            //   stylers: [{ visibility: "off" }],
            // },
            // {
            //   featureType: "poi",
            //   stylers: [{ visibility: "off" }],
            // },
          ],
        }}
      >
        {SupportedDeliveryCities.map((city) => (
          <City
            //@ts-ignore
            lat={CityCoordinates[city][0]}
            lng={CityCoordinates[city][1]}
            wrap={CityStyles[city].wrap}
            isMobile={isMobile}
            isSelected={city === selectedCity}
            // position = {{
            //   lat: Number(37.4764661),
            //   lng:  -Number(122.1963602)
            // }}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                onSelect(city);
              }}
            >
              {city}
              {/* {city === selectedCity ? <FontAwesomeIcon icon={faCheck} /> : null} */}
            </button>
          </City>
        ))}
      </GoogleMapReact>
      {showCityFuture ? (
        <CityFuture isMobile={isMobile}>
          <input
            onChange={onChangeFutureCityName}
            value={futureCityName}
            placeholder="your city name"
          />
          <button
            onClick={(e) => {
              e.preventDefault();
              onNominate(futureCityName);
            }}
          >
            submit
          </button>
        </CityFuture>
      ) : null}
      {/* <HorizontalLineBottom isMobile={isMobile}></HorizontalLineBottom> */}
      <Footer isMobile={isMobile}>
        <button onClick={onToggleFooter}>
          <span>Don't see your city?</span>
          <FontAwesomeIcon
            icon={showCityFuture ? faChevronDown : faChevronUp}
          />
        </button>
      </Footer>
    </ContentWrapper>
  );
};
