import { SET_MEALS, RESET_MEALS } from '../actionTypes';

const DEFAULT_STATE = [];

const meals = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_MEALS:
      return [...action.meals];
    case RESET_MEALS:
      return DEFAULT_STATE;
    default:
      return state;
  }
};

export default meals;
