import React, { useEffect, useState } from "react";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { MealVisited } from "src/api/meals";
import { RootState } from "src/store";
import { readUpcomingFavorites } from "src/store/actions";
import styled from "styled-components";
import { LOGGING, GetMealCutOff, GetMealLink } from "../..";
import {
  CenteredByColumnLeft,
  Centered,
  Text12300,
  card,
  centered,
  textTitle,
  BorderGrey,
  Beige,
  text12300,
  TextGrey,
  centeredByColumn,
  actionBlack,
  BackgroundGrey,
  CenteredByColumn,
  PinkPeach,
} from "../../Shared";

const Container = styled(CenteredByColumnLeft)`
  width: 100vw;
  overflow-x: hidden;
  padding: 45px 0px 15px 15px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${BackgroundGrey};
  position: relative;
  /* border-bottom: 1px solid ${BorderGrey}; */
  // big screens over 800px wide
  @media screen and (min-width: 800px) {
    padding-left: 14vw;
    flex-direction: row;
    padding-bottom: 10px;
  }
  // big screens over 1200px wide
  @media screen and (min-width: 1200px) {
    padding-left: 9vw;
    padding-bottom: 15px;
    margin-top: 0px;

    /* padding-left: calc(50vw - 250px); */
    /* border-bottom: 1px solid ${BorderGrey}; */
  }
`;
const Header = styled(Centered)`
  text-transform: capitalize;
  position: absolute;
  top: 15px;
  left: 20px;
  svg {
    font-size: 12px;
    margin-right: 5px;
  }
  span {
    ${text12300}
    padding: 0px 10px;
    line-height: 25px;
    height: 25px;
    background-color: ${PinkPeach};
    font-weight: 500;
    border-radius: 12px;
  }
  @media (min-width: 800px) {
    left: 13vw;
  }
  @media (min-width: 1200px) {
    left: calc(9vw + 20px);
  }
`;

const FavoriteList = styled(Centered)`
  max-width: 100vw;
  overflow-x: scroll;
  white-space: nowrap;
  justify-content: flex-start;
  padding-right: 30px;
  // hide scrollbar
  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and (min-width: 800px) {
    padding-right: 15vw;
    padding-left: 12px;
  }
`;
const Favorite = styled.button`
  ${centeredByColumn}
  /* align-items: flex-start;   */
  margin: 10px 5px;
  align-self: stretch;
  justify-content: flex-start;
  /* background-color: ${Beige}; */
  /* box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.2); */
  ${card}
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 150px;
  /* height: 130px; */
  flex-shrink: 0;
  padding: 5px 10px;
  border: 1px solid ${BorderGrey};
  border-radius: 10px;
`;

const MealTime = styled(Centered)`
  ${textTitle}
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 5px;
  /* background-color: green; */
  width: 100%;
  text-align: left;
  justify-content: flex-start;
`;

const HeroImage = styled.img`
  width: 100%;
  height: 100px;
  object-fit: cover;
  box-sizing: content-box;
  flex-shrink: 0;
`;

const FavoriteText = styled(CenteredByColumn)`
  width: 100%;
  height: 85px;
  padding: 10px 0px 30px 0px;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
`;
const Restaurant = styled.span`
  ${text12300}
  font-weight: 500;
  line-height: 1.3;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
  // cap at 3 lines
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const FakeButton = styled(Centered)`
  ${actionBlack}
  position: absolute;
  /* width: calc(100% - 20px); */
  bottom: 8px;
  padding: 0px 10px;
  height: 30px;
  font-size: 12px;
  max-width: 80px;
  /* flex-grow: 1; */
  /* align-self: stretch; */
  // if width < 80px, left 10px, else center
  /* left: max(10px, calc(50% - 40px)); */
`;

const TOP_N = 6;

export const UpcomingFavorites = () => {
  const weeklyMenu = useSelector((state: RootState) => state.weeklyMenu);
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const [favoriteMeals, setFavoriteMeals] = useState([]);
  const history = useHistory();
  const onClickFavorite = (mealLink, e) => {
    e.preventDefault();
    LOGGING && console.log("UpcomingFavorites onClickFavorite", mealLink);
    MealVisited(
      `upcoming ${mealLink}`,
      `${currentUser?.user?.firstName} ${currentUser?.user?.lastName}`,
      "",
      "",
      0
    );
    history.push(mealLink);
  };
  useEffect(() => {
    let { favorites } = currentUser?.user || { favorites: [] };
    const { tastes } = currentUser?.user || { tastes: [] };
    favorites = Array.from(new Set([...favorites, ...tastes]));
    const meals = weeklyMenu?.payLoad
      ? weeklyMenu?.payLoad
          .map(({ lunch, earlyDinner, dinner }) => [
            ...lunch,
            ...earlyDinner,
            ...dinner,
          ])
          .flat()
      : [];
    const restaurantIds = Array.from(
      new Set(meals.map((meal) => meal?.restaurant?._id))
    );

    const simpleMeals = meals
      .filter((meal) => favorites.includes(meal?.restaurant?._id))
      .map((meal) => {
        const { restaurant, windowStart } = meal;
        const mealLink = GetMealLink(meal);
        const deadline = GetMealCutOff(meal);
        return {
          restaurant: {
            name: restaurant.name,
            heroImage:
              restaurant.heroImages?.[2] ||
              restaurant.heroImages?.[1] ||
              restaurant.heroImage,
          },
          windowStart,
          mealLink,
          deadline,
        };
      })
      .filter((meal) => meal.deadline > moment().valueOf());

    if (simpleMeals.length < TOP_N) {
      // if user has less than 6 favorites, call server to get 6 favorites
      readUpcomingFavorites(restaurantIds, currentUser?.user?._id, TOP_N).then(
        (favoriteRestaurantIds) => {
          LOGGING &&
            console.log(
              "UpcomingFavorites get favorite restaurants from backend: ",
              favoriteRestaurantIds
            );

          const simpleMeals = meals
            .filter((meal) =>
              favoriteRestaurantIds.includes(meal?.restaurant?._id)
            )
            .map((meal) => {
              const { restaurant, windowStart } = meal;
              const mealLink = GetMealLink(meal);
              const deadline = GetMealCutOff(meal);
              return {
                restaurant: {
                  name: restaurant.name,
                  heroImage:
                    restaurant.heroImages?.[2] ||
                    restaurant.heroImages?.[1] ||
                    restaurant.heroImage,
                },
                windowStart,
                mealLink,
                deadline,
              };
            })
            .filter((meal) => meal.deadline > moment().valueOf());

          if (favoriteMeals.length != simpleMeals.length) {
            MealVisited(
              `menu with ${simpleMeals?.length} favorite meals`,
              `${currentUser?.user?.firstName} ${currentUser?.user?.lastName}`,
              "",
              "",
              0
            );
          }
          setFavoriteMeals(simpleMeals);
        }
      );
    } else {
      // if user has more than 6 favorites, use the favorites to filter meals
      if (favoriteMeals.length != simpleMeals.length) {
        MealVisited(
          `menu with ${simpleMeals?.length} favorite meals`,
          `${currentUser?.user?.firstName} ${currentUser?.user?.lastName}`,
          "",
          "",
          0
        );
      }
      setFavoriteMeals(simpleMeals);
    }
  }, [weeklyMenu, currentUser]);

  LOGGING && console.log("UpcomingFavorites favoriteMeals", favoriteMeals);
  if (!favoriteMeals || favoriteMeals.length === 0) return null;
  return (
    <Container>
      {/* <Header>{`${currentUser.user.firstName}'s favorites`}</Header> */}
      {/* {favoriteMeals?.length > 0 ? (
        <Header>{`${favoriteMeals.length} favorites upcoming`}</Header>
      ) : null} */}
      <Header>
        <span>
          <FontAwesomeIcon icon={faHeart} />
          Upcoming Favorites
        </span>
      </Header>
      <FavoriteList>
        {favoriteMeals?.map((meal) => (
          <Favorite onClick={onClickFavorite.bind(this, meal.mealLink)}>
            <MealTime>{moment(meal.windowStart).format("ddd h:mma")}</MealTime>
            <HeroImage src={meal.restaurant.heroImage} />
            <FavoriteText>
              <Restaurant>{meal?.restaurant?.name.split("-")[0]}</Restaurant>
              <FakeButton>Order</FakeButton>
            </FavoriteText>
          </Favorite>
        ))}
      </FavoriteList>
    </Container>
  );
};
