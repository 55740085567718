import React, { useCallback, useState } from "react";
import "./OrderTotal.scss";
import { faInfoCircle, faTimes } from "@fortawesome/pro-light-svg-icons";
import { faCrown, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { Flex } from "src/components/Layout";
import { receiptDateFormat, receiptHourFormat, PopUp, LOGGING } from "./";
const OrderTotal = ({
  order,
  couponCodeError,
  isMember,
  onToggleShowJoin,
  wasMember,
  isExclusive,
}) => {
  const [isServiceFeeInfoShown, setIsServiceFeeInfoShown] = useState(false);
  const showServiceFeeInfo = useCallback(() => {
    setIsServiceFeeInfoShown(true);
  }, []);
  const hideServiceFeeInfo = useCallback(() => {
    setIsServiceFeeInfoShown(false);
  }, []);
  const serviceFee = order.serviceFee?.toFixed(2);

  const [isDeliveryFeeInfoShown, setIsDeliveryFeeInfoShown] = useState(false);
  const showDeliveryFeeInfo = useCallback(() => {
    setIsDeliveryFeeInfoShown(true);
  }, []);
  const hideDeliveryFeeInfo = useCallback(() => {
    setIsDeliveryFeeInfoShown(false);
  }, []);
  const deliveryFee = order.deliveryFee?.toFixed(2);

  if (order.total == null) {
    return null;
  }
  const isMemberOnly = order?.meal?.restaurant?.memberOnly;
  const doordashSaving = isMember
    ? order?.doordashComparables?.memberSaving?.totalSaving
    : order?.doordashComparables?.nonMemberSaving?.totalSaving;
  // LOGGING &&
  //   console.log("OrderTotal rendering with:", {
  //     isMember,
  //     isMemberOnly,
  //     order,
  //     wasMember,
  //     isExclusive,
  //   });

  // add key to fix the render issue
  return (
    <React.Fragment key={order.due}>
      <div className="receipt-row tax">
        <label>subtotal</label>
        <div className={`fee-container ${isMember ? "member" : ""}`}>
          {/* hide member benefit when doordash saving are shown in the same spot */}
          {isMember &&
          order?.subtotalNonMember >= order?.subtotalMember + 0.01 &&
          !order?.doordashComparables?.isOutOfRange &&
          !isExclusive ? (
            <span className={`cross-out ${isMember ? "regular" : ""}`}>
              Doordash ${order?.subtotalNonMember?.toFixed(2)}
            </span>
          ) : null}
          <div className={`fee ${isMember ? "member" : ""}`}>
            {isMember ? (
              <div className="member-icon">
                <FontAwesomeIcon icon={faCrown} />
              </div>
            ) : null}
            <span>${order?.sum?.toFixed(2)}</span>
          </div>
        </div>
      </div>

      {serviceFee != null ? (
        <>
          <div className="receipt-row service-fee">
            <button onClick={showServiceFeeInfo}>
              {`${order?.isExpress ? "Express " : ""}`}Service <b>Fee</b>
              <FontAwesomeIcon icon={faInfoCircle} />
            </button>
            <div className="fee-container">
              {!order?.doordashComparables?.isOutOfRange &&
              !isExclusive &&
              order?.doordashComparables?.serviceFee &&
              order?.doordashComparables?.serviceFee >=
                order?.serviceFee + 0.01 ? (
                <span className="cross-out regular">
                  Doordash ${order?.doordashComparables?.serviceFee.toFixed(2)}
                </span>
              ) : null}

              <div className="fee">
                {isMember ? (
                  <div className="member-icon">
                    <FontAwesomeIcon icon={faCrown} />
                  </div>
                ) : null}
                ${order.serviceFee.toFixed(2)}
              </div>
            </div>
            {isServiceFeeInfoShown ? (
              <div
                className={`service-fee-explanation ${
                  order?.isExpress ? "express" : "regular"
                } ${order.payTime > 0 ? "paid" : "to-pay"}`}
              >
                <div className="explanation-row">
                  {/* <label>{`${
                    order?.isExpress ? "express " : ""
                  }service fee`}</label> */}
                  {isMember ? (
                    <span>{`This member-only flat ${
                      order?.isExpress ? "express" : ""
                    } service fee covers operating agents.`}</span>
                  ) : (
                    <span>
                      {`This service fee (15% with $3 minimum) covers operating
                  agents.`}
                    </span>
                  )}
                  <div className="service-fees">
                    <div className="service-fee-container">
                      <span>${order.serviceFeeNonMember}</span>
                      <label>regular</label>
                      {isMember ? null : <FontAwesomeIcon icon={faCheck} />}
                    </div>
                    <div className="service-fee-container">
                      <span>${order.serviceFeeMember}</span>
                      <label>member</label>
                      {isMember ? <FontAwesomeIcon icon={faCheck} /> : null}
                    </div>
                  </div>
                </div>
                {order?.serviceFee === order?.serviceFeeMember ? null : (
                  <button className="join" onClick={onToggleShowJoin}>
                    Join
                  </button>
                )}
                <button className="got-it" onClick={hideServiceFeeInfo}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            ) : null}
          </div>
        </>
      ) : null}
      <div className="receipt-row service-fee">
        <button onClick={showDeliveryFeeInfo}>
          delivery <b>fee</b>
          {order?.doordashComparables?.distance
            ? ` (${order?.doordashComparables?.distance.toFixed(0)}
          mi)`
            : ""}
          <FontAwesomeIcon icon={faInfoCircle} />
        </button>
        <div className="fee-container">
          {order?.doordashComparables?.isOutOfRange ||
          isExclusive ||
          !order?.doordashComparables?.deliveryFee ||
          order?.doordashComparables?.deliveryFee <
            order?.deliveryFee + 0.01 ? null : (
            <span className="cross-out regular">
              {`Doordash $${order?.doordashComparables?.deliveryFee.toFixed(
                2
              )}`}
            </span>
          )}
          <div className="fee">
            {/* <span>${order.window.cost.toFixed(2)}</span> */}
            {isMember ? (
              <div className="member-icon">
                <FontAwesomeIcon icon={faCrown} />
              </div>
            ) : null}
            ${(order?.deliveryFee || 0).toFixed(2)}
          </div>
        </div>
        {isDeliveryFeeInfoShown ? (
          <div
            className={`service-fee-explanation ${
              order?.isExpress ? "express" : "regular"
            } ${order.payTime > 0 ? "paid" : "to-pay"}`}
          >
            <div className="explanation-row">
              {/* <label>{`${
                order?.isExpress ? "express " : ""
              }service fee`}</label> */}
              {isMember ? (
                <span>{`Free delivery for Foodie member!`}</span>
              ) : (
                <span>
                  {`Flat delivery fee regardless restaurant location.`}
                </span>
              )}
              <div className="service-fees">
                <div className="service-fee-container">
                  <span>${order.deliveryFeeNonMember}</span>
                  <label>regular</label>
                  {isMember ? null : <FontAwesomeIcon icon={faCheck} />}
                </div>
                <div className="service-fee-container">
                  <span>${order.deliveryFeeMember}</span>
                  <label>member</label>
                  {isMember ? <FontAwesomeIcon icon={faCheck} /> : null}
                </div>
              </div>
            </div>
            {order?.serviceFee === order?.serviceFeeMember &&
            order?.isMember !== false ? null : (
              <button className="join" onClick={onToggleShowJoin}>
                Join
              </button>
            )}
            <button className="got-it" onClick={hideDeliveryFeeInfo}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        ) : null}
      </div>
      {!order?.doordashComparables?.isOutOfRange &&
      order?.doordashComparables?.expandedRangeFee &&
      !isMemberOnly ? (
        <div className="receipt-row service-fee">
          <label>expanded range</label>
          <div className="fee-container">
            <span className="cross-out regular">
              Doordash $
              {order?.doordashComparables?.expandedRangeFee?.toFixed(2) ||
                "0.00"}
            </span>
            <div className="fee">
              <span>${order?.window?.cost?.toFixed(2)}</span>
            </div>
          </div>
        </div>
      ) : null}
      {order.tax !== undefined ? (
        <div className="receipt-row tax">
          <label>tax</label>
          <div className="fee-container">
            {order?.totalTaxNonMember > order?.totalTaxMember &&
            !order?.doordashComparables?.isOutOfRange &&
            !isMemberOnly &&
            isMember ? (
              <span className={`cross-out ${isMember ? "regular" : ""}`}>
                Doordash ${order?.totalTaxNonMember?.toFixed(2)}
              </span>
            ) : null}
            <span>${order.tax.toFixed(2)}</span>
          </div>
        </div>
      ) : null}
      {/* <div className="receipt-row tax">
        <div className="button-with-info">
          <label>Fees & Tax</label>
          <button
            style={{
              // To vertically center since I'm OCD but
              // don't want to figure out how to do it properly
              marginBottom: "2px",
            }}
            onClick={showServiceFeeInfo}
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </button>
          {isServiceFeeInfoShown ? (
            <div className="service-fee-explanation">
              <div className="explanation-row">
                <label>service fee: ${order.serviceFee.toFixed(2)}</label>
                <span>
                  {`This ${
                    isMember ? "3.5% " : "10% non-"
                  }member service fee (minimum $${
                    isMember ? "1" : "3"
                  }) covers operating
                  agents.`}
                </span>
              </div>
              <div className="explanation-row">
                <label>Tax: ${order.tax.toFixed(2)}</label>
                <span>
                  Tax rate is based on the city where the restaurant is located.
                </span>
              </div>
              <button className="got-it" onClick={hideServiceFeeInfo}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          ) : null}
        </div>
        <div className={`fee-container ${isMember ? "member" : ""}`}>
          {isMember ? (
            <span className="regular">
              $
              {(order?.totalTaxNonMember + order?.serviceFeeNonMember).toFixed(
                2
              )}
            </span>
          ) : null}

          <div className={`fee ${isMember ? "member" : ""}`}>
            <div className="member-icon">
              <FontAwesomeIcon icon={faCrown} />
            </div>
            <span>${(order?.tax + order?.serviceFee).toFixed(2)}</span>
          </div>
        </div>
      </div> */}

      {order.tips != null ? (
        <div className="receipt-row tax">
          <label>tip</label>
          <span>${order.tips.toFixed(2)}</span>
        </div>
      ) : null}
      <div
        className={`receipt-row total ${
          order.credit && order.due ? "has-credit" : ""
        }`}
      >
        <label>total</label>
        <div className="fee-container">
          {!order?.doordashComparables?.isOutOfRange &&
          !isExclusive &&
          doordashSaving >= 0.01 ? (
            <span className={`cross-out regular`}>
              Doordash ${(order?.total + doordashSaving).toFixed(2)}
            </span>
          ) : null}
          <span className="highlight">
            ${(order.total || order.sum).toFixed(2)}
          </span>
        </div>
      </div>
      {order.due !== order.total && <div className="split-line" />}
      {order.credit > 0 && (
        <div className="receipt-row">
          <label>credit used</label>
          <span className="highlight">-${order.credit.toFixed(2)}</span>
        </div>
      )}
      {order.couponCode &&
      order.discount &&
      (!couponCodeError || order.totalNotEnoughForCoupon) ? (
        <div className="receipt-row offer-code">
          <label>
            <span>
              Offer <b>{order.couponCode.code}</b>
            </span>
          </label>
          <span className="highlight">-${order.discount.toFixed(2)}</span>
        </div>
      ) : null}

      {order.companyPay ? (
        <div className="receipt-row">
          <label>Company Budget</label>
          <span className="highlight">-${order.companyPay.toFixed(2)}</span>
        </div>
      ) : null}
      {order.due !== order.total && (
        <div className="receipt-row due">
          <label>due</label>
          <span className="highlight">${order.due.toFixed(2)}</span>
        </div>
      )}
      <div className="receipt-row banner-title">
        <label>due</label>
        <span className="highlight">
          ${(order.due !== undefined ? order.due : order.total).toFixed(2)}
        </span>
      </div>
      {/* {!isMember && order.payTime < 0 && order.memberSaving > 0 ? (
        <>
          <div className="receipt-row saving regular already">
            <label>You already saved:</label>
            <span>${order?.doordashComparables?.nonMemberSaving?.totalSaving.toFixed(2)}</span>            
          </div>
          <div className="receipt-row saving regular">
            <label>Member can save this much more:</label>
            <span>${order.memberSaving.toFixed(2)}</span>
            <button className="join" onClick={onToggleShowJoin}>
              {`${wasMember?"Resume":"Start"} Membership`}
            </button>
          </div>
        </>
      ) : null} */}
      {/* {isMember && !isMemberOnly && order.memberSaving > 0 ? (
        <div className="receipt-row saving member">
          <label>Member saving</label>
          <span>${order.memberSaving.toFixed(2)}</span>
        </div>
      ) : null} */}

      {order.payTime > 0 ? (
        <React.Fragment>
          <div className="receipt-row time-stamps">
            <label className="arrive-time">paid</label>
            <span>{moment(order.payTime).format(receiptDateFormat)}</span>
          </div>
          <div className="receipt-row window">
            <span>{moment(order.payTime).format(receiptHourFormat)}</span>
          </div>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default OrderTotal;
