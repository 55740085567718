import { SET_DELIVERY_ZONE_BY_CITY } from '../actionTypes';

const INITIALIZE_STATE = {};

const deliveryZoneByCity = (state = { INITIALIZE_STATE }, action) => {
  switch (action.type) {
    case SET_DELIVERY_ZONE_BY_CITY:
      return action.deliveryZoneByCity;
    default:
      return state;
  }
};

export default deliveryZoneByCity;
