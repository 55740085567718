import { useState } from 'react';
import creditCardType from 'credit-card-type';
import { useDispatch, useSelector } from 'react-redux';
import { ExistingCard, NewCardForm } from 'src/screens/tasting_menu/components';
import { deleteCard } from 'src/store/actions';
import styled from 'styled-components';

export type PayWithStripeProps = {
  newCard?: any;
  newCardType?: any;
  newCardError?: any;
  onNewCardChange?: (name: string, value: any) => void;
  onNewCardTypeChange?: (newCardType: any) => void;
};

export const validateNewCard = (newCard: any, newCardType: any) => {
  const { number = '', cvc = '', exp_month = '', exp_year = '' } = newCard;
  if (!number || !newCardType.code) {
    return {
      param: 'number',
      message: `Card number can't empty`,
    };
  }
  if (number.length < newCardType.length) {
    return {
      param: 'number',
      message: `${newCardType.niceType} card has to be ${newCardType.length}-digit`,
    };
  }
  if (cvc.length < newCardType.code.size) {
    return {
      param: 'cvc',
      message: `${newCardType.code.name} is ${newCardType.code.size}-digit`,
    };
  }
  if (exp_month.length < 2 || Number(exp_month) > 12 || Number(exp_month) < 1) {
    return { param: 'exp_month', message: 'exp_month is invalid' };
  }
  if (exp_year.length < 2 || 2000 + Number(exp_year) < 2020) {
    return { param: 'exp_year', message: 'exp_year is invalid' };
  }
  return true;
};

export const PayWithStripe = (props: PayWithStripeProps) => {
  const { newCard, newCardType, newCardError, onNewCardChange, onNewCardTypeChange } = props;

  const currentUser = useSelector((state: any) => state.currentUser);
  const [showCardAction, setShowCardAction] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { stripeInfo } = currentUser.user;

  const onChange = (_: any, name: string, values: any) => {
    const value = values.value;
    if (name === 'number') {
      const { lengths, gaps, code, type, niceType } = creditCardType(value)[0];
      const cardFormat = lengths.length === 1 ? Array(lengths[0]).fill('#') : [];
      gaps.reverse().forEach((position) => cardFormat.splice(position, 0, '-'));
      onNewCardTypeChange({
        format: null,
        length: lengths.length === 1 ? lengths[0] : null,
        code: { ...code, format: Array(code.size).fill('#').join('') },
        type: value.length === 0 ? null : type,
        niceType: value.length === 0 ? null : niceType,
      });
    }
    onNewCardChange?.(name, value);
  };

  return (
    <div className="form-section">
      <div className="form-section-title">Card</div>
      {stripeInfo?.card ? (
        <ExistingCard
          onToggleShowCardAction={() => setShowCardAction((showCardAction) => !showCardAction)}
          showCardAction={showCardAction}
          onDeleteCard={() => {
            dispatch(deleteCard());
          }}
          existingCard={{ ...stripeInfo.card }}
        />
      ) : (
        <NewCard>
          <NewCardForm
            onChangeNumber={onChange}
            newCard={newCard}
            cardType={newCardType}
            error={newCardError}
          />
        </NewCard>
      )}
    </div>
  );
};

export const NewCard = styled.div`
  .form-field-wrapper {
    position: relative;
  }

  .card-icon {
    position: absolute;
    right: 6px;
    top: 6px;
    font-size: 24px;
  }
`;
