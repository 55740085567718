import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
// import { Redirect } from 'react-router-dom';
import { LOGGING, OrderList } from '.';
import {
  readGroupOrders,
  cancelOrder,
  giveOrderFeedback,
  saveOrderFeedbackComment,
} from '../../store/actions';
import { ConfirmAlert } from '../shared';

class PageUserGroupOrders extends Component {
  constructor(props) {
    super();
    const { currentUser } = props;
    if (typeof currentUser.user._id == 'undefined') {
      props.history.push({
        pathname: '/signin',
        state: { next: '/userGroupAdmin' },
      });
    }
    if (!currentUser.user.isUserGroupAdmin) {
      props.history.push({
        pathname: '/',
        state: { next: '/' },
      });
    }

    this.state = {
      orders: [],
      loading: true,
      view: 'upcoming',
      saving: null,
      cancelOrderId: null,
      editedComments: new Map(),
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleCancelOrder = this.handleCancelOrder.bind(this);
    this.handleSetView = this.handleSetView.bind(this);
    this.handleUp = this.handleUp.bind(this);
    this.handleDown = this.handleDown.bind(this);
    this.handleEditComment = this.handleEditComment.bind(this);
    this.handleSaveComment = this.handleSaveComment.bind(this);
  }
  handleUp(orderId, e) {
    console.log('handleUp', orderId, e);
    this.setState({ saving: { orderId, isBinary: true, binaryAnswer: true } }, () => {
      this.props.giveOrderFeedback(orderId, true).then(() => {
        this.props.readGroupOrders().then((orders) => {
          this.setState({ loading: false, orders }, () => {
            window.scrollTo(0, 0);
          });
        });
      });
    });
  }
  handleDown(orderId, e) {
    console.log('handleDown', orderId, e);
    this.setState({ saving: { orderId, isBinary: true, binaryAnswer: false } }, () => {
      this.props.giveOrderFeedback(orderId, false).then(() => {
        this.props.readGroupOrders().then((orders) => {
          this.setState({ loading: false, orders }, () => {
            window.scrollTo(0, 0);
          });
        });
      });
    });
  }
  handleEditComment(orderId, e) {
    console.log('handleEditComment', orderId, e);
    let editedComments = this.state.editedComments;
    editedComments.set(orderId, e.target.value);
    this.setState({ editedComments: editedComments });
  }
  handleSaveComment(orderId, e) {
    console.log('handleSaveComment', orderId, e);
    this.props.saveOrderFeedbackComment(orderId, this.state.editedComments.get(orderId));
    this.setState({ saving: { orderId, isBinary: false } }, () => {
      this.props.readGroupOrders().then((orders) => {
        this.setState({ loading: false, orders }, () => {
          window.scrollTo(0, 0);
        });
      });
    });
  }
  handleClose() {
    this.props.history.goBack();
  }
  handleCancelOrder(orderId, e) {
    e.preventDefault();
    console.log('handleCancelOrder');
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmAlert
            onConfirm={this.handleConfirmCancelOrder.bind(this, orderId)}
            onClose={onClose}
            actionName="cancel"
          />
        );
      },
    });
  }
  handleConfirmCancelOrder(orderId) {
    console.log('cancelling..');
    this.setState({ cancelOrderId: orderId });
    this.props.cancelOrder(orderId).then(() => {
      this.setState({ cancelOrderId: null });
    });
  }
  handleSetView(view, e) {
    e.preventDefault();
    this.setState({ view });
  }

  componentDidMount() {
    LOGGING && console.log('PageOrderList componentdidmount');
    this.setState({ loading: true }, () => {
      this.props.readGroupOrders().then((orders) => {
        this.setState({ loading: false, orders }, () => {
          window.scrollTo(0, 0);
        });
      });
    });
  }
  render() {
    const { loading, orders, view, cancelOrderId, saving } = this.state;
    const { currentUser } = this.props;
    LOGGING &&
      console.log('PageOrders rendering with', {
        props: this.props,
        state: this.state,
      });

    return (
      <div className="page">
        <OrderList
          loading={loading}
          orders={orders}
          view={view}
          onSetView={this.handleSetView}
          onCancelOrder={this.handleCancelOrder}
          onBack={this.handleClose}
          cancelOrderId={cancelOrderId}
          onUp={this.handleUp}
          onDown={this.handleDown}
          editedComments={this.state.editedComments}
          onEditComment={this.handleEditComment}
          onSaveComment={this.handleSaveComment}
          saving={saving}
          groupName={currentUser.user.userGroup.name}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log('PageOrderList got redux state:', state);
  return {
    // orders: state.orders,
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  readGroupOrders,
  cancelOrder,
  giveOrderFeedback,
  saveOrderFeedbackComment,
})(PageUserGroupOrders);
