import { PURGE } from 'redux-persist';
import { LOAD_MENUS } from '../actionTypes';

const menus = (state = [], action) => {
  switch (action.type) {
    case LOAD_MENUS:
      return [...action.menus];
    case PURGE:
      return [];
    default:
      return state;
  }
};

export default menus;
