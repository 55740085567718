import { useEffect, useState } from "react";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import {
  faChevronRight,
  faTimes as faClose,
  faMinus,
  faPlus,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch, useStore } from "react-redux";
import { Link } from "react-router-dom";
import { LOGGING } from "src/constants";
import { RootState } from "src/store";
import {
  GetItemNamesByIds,
  updateOrderOnMeal,
  changeGoodQuantityInCart,
} from "src/store/actions";
import styled from "styled-components";
import { NextActionContainer } from "./CheckoutThumbnail";
import {
  CenteredByColumn,
  Centered,
  textTitle,
  card,
  BorderGrey,
  centered,
  actionBlack,
  text12300,
  Text12300,
  BackgroundGrey,
  TextGrey,
  CenteredByColumnLeft,
  mobileNextAction,
  NavHeight,
} from "../../Shared";
import { BreakPoint } from "../mealMenu/MealHeader";
import { Prices } from "../mealMenu/Prices";
const imageHeight = 40;
export const Image = styled.img`
  width: ${imageHeight}px;
  height: ${imageHeight}px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  flex-shrink: 0;
`;
export const Name = styled(Text12300)`
  font-size: 11px;
  line-height: 1.3;
  text-align: right;
  // max 2 lines
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; // Limits the text to 2 lines
  line-height: 1.5; // Adjust this value as needed
  max-height: calc(2 * 1.5em); // 2 lines times the line-height
  svg {
    font-size: 10px;
    margin: 0px 5px;
  }
`;
const Name3 = styled(Name)`
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  -webkit-line-clamp: 1; // Limits the text to 2 lines
  line-height: 1.5; // Adjust this value as needed
  max-height: calc(1 * 1.5em); // 2 lines times the line-height
`;
const Item = styled.button`
  ${centered}
  align-self: stretch;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid ${BorderGrey};
  padding: 15px 0px;
  margin: 0px;
  height: auto;
  position: relative;
  //if its the last item, remove the border
  &:last-child {
    border-bottom: none;
  }

  // if disabled, make the button unclickable, and hover effect is disabled
  &:disabled {
    pointer-events: none;
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }
  @media screen and (max-width: ${BreakPoint}px) {
    padding-right: 5px;
  }
`;
const ItemContent = styled(CenteredByColumnLeft)`
  width: calc(100% - 110px);
  /* margin-bottom: 10px; */
  /* justify-content:flex-start; */
  align-items: flex-start;
  padding-left: 55px;
  /* flex-grow: 1; */
  /* background-color: pink; */
`;
const quanityButtonSize = 28;
const ButtonEditSelections = styled(Centered)`
  ${text12300}
  position: absolute;
  bottom: 10px;
  right: 10px;
`;
const ItemCount = styled(Centered)`
  text-align: right;
  width: 100px;
  svg {
    margin-right: 2px;
  }
  padding: 0px;
  justify-content: space-between;
  background-color: ${BackgroundGrey};
  height: ${quanityButtonSize}px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
  border-radius: ${quanityButtonSize / 2}px;
  span {
    ${text12300}
    font-weight: 500;
    svg {
      margin-left: 3px;
      font-size: 10px;
    }
  }
`;

const ItemCountReadOnly = styled(Centered)`
  text-align: right;
  ${text12300}
  font-weight: 500;
  svg {
    margin-right: 5px;
    /* font-size: 10px; */
  }
`;

const closeButtomSize = 50;
const ButtonClose = styled.button`
  background-color: transparent;
  border: none;
  width: ${closeButtomSize}px;
  /* height: ${closeButtomSize}px; */
  align-self: stretch;
  ${centered}
  margin: 0px 10px 0px 0px;
  svg {
    font-size: 20px !important;
  }
  @media screen and (max-width: ${BreakPoint}px) {
    height: ${NavHeight}px;
    width: ${NavHeight}px;
    position: fixed;
    top: 0px !important;
    left: 0px;
    margin: 0px;
    bottom: auto;
  }
`;
const Container = styled(CenteredByColumn)<{ show?: boolean }>`
  position: fixed;
  top: 105px;
  right: 0px;
  /* ${card} */
  width: calc(170px + 18vw);
  height: calc(100vh - 105px);
  color: black;
  justify-content: flex-start;
  align-items: flex-end;
  z-index: 102;
  transition: all 0.3s;
  transform: ${(props) => (props.show ? "translateX(0)" : "translateX(500px)")};
  background-color: transparent;
  /* opacity: ${(props) => (props.show ? 1 : 0)}; */
  @media screen and (max-width: ${BreakPoint}px) {
    width: 100vw;
    flex-shrink: 0;
    transition: all 0.3s;
    transform: translateX(0)
      ${(props) => (props.show ? "translateY(0)" : "translateY(100vh)")};
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
  }
`;
const CartContent = styled(CenteredByColumn)<{ show?: boolean }>`
  width: calc(200px + 20vw);
  height: auto;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 20px;
  margin: 0px;
  position: relative;
  z-index: 2;
  background-color: white;
  box-shadow: ${(props) =>
    props.show ? "0px 5px 5px 2px rgba(0, 0, 0, 0.2)" : "none"};
  max-height: 100vh;
  overflow-y: hidden;
  /* a {
    display: none;
  } */
  @media screen and (max-width: ${BreakPoint}px) {
    width: 100vw;
    height: 100%;
    position: relative;
    justify-content: flex-start;
    padding-bottom: 100px;
    padding-top: ${NavHeight}px;
    /* a {
      display: flex;
      flex-grow: 0;
      width: calc(100vw - 20px);
      height: 60px;
      border-radius: 30px;
      position: absolute;
      bottom: 20px;
      left: 10px;
    } */
  }
`;
const FullScreenClose = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100vw;
  height: 100vh;
  padding: 0px;
  margin: 0px;
  /* z-index: 10;     */
  flex-shrink: 0;
  background-color: transparent;
`;

const ButtonChangeQuantity = styled.button`
  ${centered}
  background-color: transparent;
  border: none;
  color: black;
  font-size: 13px;
  cursor: pointer;
  width: ${quanityButtonSize}px;
  height: ${quanityButtonSize}px;
  border-radius: 50%;

  padding: 0px;
  line-height: ${quanityButtonSize}px;
  text-align: center;
  background-color: white;
  svg {
    margin: 0px;
  }
`;
const Image2 = styled(Image)`
  position: absolute;
  left: 0px;
  top: 15px;
  height: 45px;
  width: 45px;
  margin-right: 5px;
`;
const Name2 = styled(Name)`
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; // Limits the text to 2 lines
  line-height: 1.5; // Adjust this value as needed
  max-height: calc(2 * 1.5em); // 2 lines times the line-height
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1.5px solid ${TextGrey};
`;
const Selections = styled(Name)`
  text-align: left;
  font-size: 10px;
  /* line-height: 1.3; */
  margin-bottom: 8px;
`;
const CheckoutButton = styled(Link)`
  ${actionBlack}
  margin: 0px;
  opacity: 1;
  align-self: stretch;
  border-radius: 20px;
  height: 40px;
  flex-grow: 1;
  padding: 0px 20px;
  position: relative;
  > label {
    /* position: absolute; */
    margin-left: 10px;
  }
  svg {
    position: absolute;
    right: 30px;
    font-size: 20px;
  }
  display: none;
  @media screen and (max-width: ${BreakPoint}px) {
    ${mobileNextAction}
  }
  /* justify-content: space-between; */
`;

const Title = styled(Centered)`
  ${textTitle}
  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px solid ${BorderGrey};
  align-self: stretch;
  /* margin: 0px 20px; */
  z-index: 2;
  height: 60px;
  flex-shrink: 0;
  > button {
    position: absolute;
    right: 0px;
    top: 20px;
  }
  @media screen and (max-width: ${BreakPoint}px) {
    font-size: 24px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: ${NavHeight}px;
    background-color: white;
    z-index: 2;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 0 70px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    justify-content: center;
  }
`;
const Content = styled(CenteredByColumn)`
  width: 100%;
  height: calc(100% -100px);
  overflow-y: auto;
  /* padding: 5px 15px 10px 15px; */
  justify-content: flex-start;
  z-index: 2;
`;

export const CartDetails = ({ onClose, show, isMember, onEditGoodInCart }) => {
  const cart = useSelector((state: RootState) => state.cart.payLoad);
  const [itemNames, setItemNames] = useState({});
  const dispatch = useDispatch();
  const store = useStore();
  useEffect(() => {
    const itemIds = Object.values(cart?.goods)
      ?.map((g) =>
        // @ts-ignore
        g?.selections?.map((s) =>
          s?.selectedItems?.map((si) => si?.item?._id || si?.item)
        )
      )
      .flat(Infinity);
    // dedup itemIds
    LOGGING && console.log("itemIds", itemIds);
    const uniqueItemIds = Array.from(new Set(itemIds));
    LOGGING && console.log("uniqueItemIds", uniqueItemIds);
    if (uniqueItemIds.length === 0) return;
    GetItemNamesByIds(uniqueItemIds)(dispatch, store.getState).then((res) => {
      setItemNames(res);
    });
  }, [cart]);
  const onAddGood = (goodKey, delta, e) => {
    e.preventDefault();
    e.stopPropagation();
    changeGoodQuantityInCart(goodKey, delta)(dispatch, store.getState);
    updateOrderOnMeal()(dispatch, store.getState);
  };
  const onOpen = (goodKey, e) => {
    e.preventDefault();
    onEditGoodInCart(goodKey);
  };
  return (
    <Container show={show}>
      {show && <FullScreenClose onClick={onClose}></FullScreenClose>}
      <CartContent show={show}>
        <Title>
          <span>Your cart</span>
          <ButtonClose onClick={onClose}>
            <FontAwesomeIcon icon={faClose} />
          </ButtonClose>
        </Title>
        <Content>
          {Object.keys(cart?.goods)?.map((goodKey: string, index) => {
            const good = cart?.goods[goodKey];
            return (
              <Item key={index} onClick={onOpen.bind(this, goodKey)}>
                <ItemContent>
                  <Image2 src={good?.dish?.imageURL} />
                  <Name2>{good?.dish?.name}</Name2>
                  {/* @ts-ignore */}
                  {good?.selections?.length > 0 && (
                    <Selections>
                      {/* @ts-ignore */}
                      {good?.selections
                        ?.map((s) => {
                          return s?.selectedItems
                            .filter(
                              (si) => itemNames?.[si?.item?._id || si?.item]
                            )
                            ?.map(
                              (si) => itemNames?.[si?.item?._id || si?.item]
                            )
                            .join(", ");
                        })
                        .join(", ")}
                    </Selections>
                  )}
                  <Prices
                    priceFoodieListed={
                      good?.dish?.priceFoodieListed +
                      good?.selections?.reduce(
                        (acc, s) =>
                          acc +
                          s?.selectedItems?.reduce(
                            (acc2, si) => acc2 + si?.item?.price || 0,
                            0
                          ),
                        0
                      )
                    }
                    priceDoordashListed={
                      good?.dish?.priceDoordashListed +
                      good?.selections?.reduce(
                        (acc, s) =>
                          acc +
                          s?.selectedItems?.reduce(
                            (acc2, si) =>
                              acc2 + si?.item?.priceDoordashListed || 0,
                            0
                          ),
                        0
                      )
                    }
                    priceFoodieNonMember={
                      good?.dish?.priceFoodieNonMember +
                      good?.selections?.reduce(
                        (acc, s) =>
                          acc +
                          s?.selectedItems?.reduce(
                            (acc2, si) =>
                              acc2 + si?.item?.priceDoordashListed || 0,
                            0
                          ),
                        0
                      )
                    }
                    isMember={isMember}
                    isItem
                  />
                </ItemContent>
                <ItemCount>
                  <ButtonChangeQuantity
                    onClick={onAddGood.bind(this, goodKey, -1)}
                  >
                    <FontAwesomeIcon
                      icon={good?.quantity === 1 ? faTrash : faMinus}
                    />
                  </ButtonChangeQuantity>
                  <span>
                    {good?.quantity}
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                  <ButtonChangeQuantity
                    onClick={onAddGood.bind(this, goodKey, 1)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </ButtonChangeQuantity>
                </ItemCount>
              </Item>
            );
          })}
        </Content>
        <NextActionContainer>
          <CheckoutButton to="/checkout">
            <span>Checkout</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </CheckoutButton>
        </NextActionContainer>
      </CartContent>
    </Container>
  );
};

export const CartDetailsViewOnly = ({ show, isMember }) => {
  const cart = useSelector((state: RootState) => state.cart.payLoad);
  const [itemNames, setItemNames] = useState({});
  const dispatch = useDispatch();
  const store = useStore();
  useEffect(() => {
    const itemIds = Object.values(cart?.goods)
      ?.map((g) =>
        // @ts-ignore
        g?.selections?.map((s) =>
          s?.selectedItems?.map((si) => si?.item?._id || si?.item)
        )
      )
      .flat();
    // dedup itemIds
    const uniqueItemIds = Array.from(new Set(itemIds)).flat();
    GetItemNamesByIds(uniqueItemIds)(dispatch, store.getState).then((res) => {
      setItemNames(res);
    });
  }, [cart]);

  return (
    show && (
      <Content>
        {Object.keys(cart?.goods)?.map((goodKey: string, index) => {
          const good = cart?.goods[goodKey];
          return (
            <Item key={index} disabled={true}>
              <ItemContent>
                <Image2 src={good?.dish?.imageURL} />
                <Name3>{good?.dish?.name}</Name3>
                {/* @ts-ignore */}
                {good?.selections?.length > 0 && (
                  <Selections>
                    {/* @ts-ignore */}
                    {good?.selections
                      ?.map((s) => {
                        return s?.selectedItems
                          .filter(
                            (si) => itemNames?.[si?.item?._id || si?.item]
                          )
                          ?.map((si) => itemNames?.[si?.item?._id || si?.item])
                          .join(", ");
                      })
                      .join(", ")}
                  </Selections>
                )}
                <Prices
                  priceFoodieListed={
                    good?.dish?.priceFoodieListed +
                    good?.selections?.reduce(
                      (acc, s) =>
                        acc +
                        s?.selectedItems?.reduce(
                          (acc2, si) => acc2 + si?.item?.price || 0,
                          0
                        ),
                      0
                    )
                  }
                  priceDoordashListed={
                    good?.dish?.priceDoordashListed +
                    good?.selections?.reduce(
                      (acc, s) =>
                        acc +
                        s?.selectedItems?.reduce(
                          (acc2, si) =>
                            acc2 + si?.item?.priceDoordashListed || 0,
                          0
                        ),
                      0
                    )
                  }
                  priceFoodieNonMember={
                    good?.dish?.priceFoodieNonMember +
                    good?.selections?.reduce(
                      (acc, s) =>
                        acc +
                        s?.selectedItems?.reduce(
                          (acc2, si) =>
                            acc2 + si?.item?.priceDoordashListed || 0,
                          0
                        ),
                      0
                    )
                  }
                  isMember={isMember}
                  isItem
                />
              </ItemContent>
              <ItemCountReadOnly>
                <FontAwesomeIcon icon={faTimes} />
                {good?.quantity}
              </ItemCountReadOnly>
            </Item>
          );
        })}
      </Content>
    )
  );
};
