import { useEffect, useState } from 'react';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { Cell, Flex, Grid } from 'src/components/Layout';
import { Navbar } from 'src/screens/tasting_menu';
import { getCouponCodes } from 'src/store/actions';
import styled from 'styled-components';
import { CouponModal } from './CouponModal';

export const Coupons = () => {
  const currentUser = useSelector((state: any) => state.currentUser);
  const couponCodes = useSelector((state: any) => state.couponCodes);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCouponCodes());
  }, [dispatch]);

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [selectedCouponCode, setSelectedCouponCode] = useState<any>();

  if (!currentUser.isAuthenticated || !currentUser.user.isAdmin) {
    return <Redirect to="/" />;
  }

  return (
    <div className="page">
      <Navbar />
      <CouponCodeContainer>
        <Grid columns={1} gap={24} md={{ columns: 2 }} xxl={{ columns: 4 }}>
          <>
            <CreateCouponCodeButton
              onClick={() => {
                setSelectedCouponCode(undefined);
                setModalIsOpen(true);
              }}
            >
              Create Coupon Code
            </CreateCouponCodeButton>
            {couponCodes.map((couponCode: any) => (
              <CouponCode key={couponCode._id}>
                <h3>{couponCode.code}</h3>
                <Grid columns={2} align="center" gap={4} widthFull>
                  {couponCode.user && (
                    <>
                      <Cell>Created By:</Cell>
                      <Cell justify="end">{`${couponCode.user.firstName} ${couponCode.user.lastName}`}</Cell>
                    </>
                  )}
                  <Cell>Discount Type:</Cell>
                  <Cell justify="end">{couponCode.discountType}</Cell>
                  {couponCode.discountType === 'dollar' && (
                    <>
                      <Cell>Discount Dollar:</Cell>
                      <Cell justify="end">$ {couponCode.discountDollar}</Cell>
                    </>
                  )}
                  {couponCode.discountType === 'percentage' && (
                    <>
                      <Cell>Discount Percentage:</Cell>
                      <Cell justify="end">{couponCode.discountPercentage} %</Cell>
                    </>
                  )}
                  <Cell>Commission Type:</Cell>
                  <Cell justify="end">{couponCode.commissionType}</Cell>
                  {couponCode.commissionType === 'dollar' && (
                    <>
                      <Cell>Commission Dollar:</Cell>
                      <Cell justify="end">$ {couponCode.commissionDollar}</Cell>
                    </>
                  )}
                  {couponCode.commissionType === 'percentage' && (
                    <>
                      <Cell>Commission Percentage:</Cell>
                      <Cell justify="end">{couponCode.commissionPercentage} %</Cell>
                    </>
                  )}
                  <Cell>Min Total:</Cell>
                  <Cell justify="end">$ {couponCode.minTotal || 0}</Cell>
                  <Cell>Meal ID:</Cell>
                  <Cell justify="end">{couponCode.mealId}</Cell>
                  {couponCode.expireType === 'time' && (
                    <>
                      <Cell>Expire Time:</Cell>
                      <Cell justify="end">
                        {moment(couponCode.expireTime).format('MM/DD/YYYY')}
                      </Cell>
                    </>
                  )}
                  {couponCode.expireType === 'count' && (
                    <>
                      <Cell>Expire Count:</Cell>
                      <Cell justify="end">{couponCode.expireCount}</Cell>
                    </>
                  )}
                  <Cell>Order Count:</Cell>
                  <Cell justify="end">{couponCode.orderCount}</Cell>
                  <Cell>Order Total:</Cell>
                  <Cell justify="end">$ {couponCode.orderTotal}</Cell>
                  <Cell>Commission:</Cell>
                  <Cell justify="end">$ {couponCode.commission}</Cell>
                  <Cell>Settlement:</Cell>
                  <Cell justify="end">$ {couponCode.settlement}</Cell>
                  <Cell>Only for new user:</Cell>
                  <Cell justify="end">{couponCode.onlyForNewUser ? 'Yes' : 'No'}</Cell>
                  <Cell>Notes:</Cell>
                  <Cell justify="end">{couponCode.notes}</Cell>
                </Grid>
                <EditButton
                  variant="secondary"
                  onClick={() => {
                    setSelectedCouponCode(couponCode);
                    setModalIsOpen(true);
                  }}
                >
                  <Flex align="center" gap={6}>
                    <FontAwesomeIcon icon={faEdit} />
                    <div>Edit</div>
                  </Flex>
                </EditButton>
              </CouponCode>
            ))}
          </>
        </Grid>
        <CouponModal
          isOpen={modalIsOpen}
          couponCode={selectedCouponCode}
          onClose={() => setModalIsOpen(false)}
        />
      </CouponCodeContainer>
    </div>
  );
};

const CouponCodeContainer = styled.div`
  width: 100%;
  padding: 32px;
  padding-bottom: 132px;

  h3 {
    margin: 6px 0 12px 0;
    padding: 0;
  }
`;

const CreateCouponCodeButton = styled.button`
  color: #ffffff;
  background-color: #000000;
  opacity: 0.8;
  border-radius: 5px;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.6px;
  box-shadow: 0 4px 32px 0 rgb(28 29 33 / 12%);
  min-height: 150px;
`;

const CouponCode = styled.div`
  font-size: 13px;
  box-shadow: 0 4px 32px 0 rgb(28 29 33 / 12%);
  padding: 12px 24px;
  background-color: #ffffff;
  border-radius: 5px;
`;

const EditButton = styled(Button)`
  margin-top: 16px;
`;
