import { LOGGING } from "src/constants";
import { ObjectId } from "src/constants/types";
import { apiCall } from "src/store/api";

export type SerializedRestaurant = {
  _id: ObjectId;
  heroImage: string;
  commission: number;
  name: string;
  yelpUrl: string;
  disallowSpecialInstruction: boolean;
};
export type GetRestaurantByIdResponse = {
  restaurant: SerializedRestaurant | null;
};
export type GetGiftTemplatesResponse = {
  headline?: string;
  amount?: number;
  background?: string;
};
export type SetFavoritesResponse = String[];
export type GetRestaurantsByAlphabetResponse = {
  restaurantsByAlphabet: {};
  restaurantsByCuisine: {};
  cuisines: [];
  alphabet: [];
  favorites: [];
  restaurants: [{ _id: String; name: String }];
};
export type GetFavoritesOverviewResponse = {
  favoritesByRestaurant: any[];
  favoritesByUser: any[];
};
export type GetMostOrderedDishesResponse = {
  dishes: any[];
};
export type GetSurveyResultsResponse = {
  users: any[];
};
export const GetRestaurantById = async (
  restaurantId: string
): Promise<GetRestaurantByIdResponse> => {
  try {
    return await apiCall("POST", `/restaurants/GetRestaurantById`, {
      restaurantId,
    });
  } catch (err) {
    LOGGING && console.log("GetRestaurantByIdResponse got err", err);
    return { restaurant: null };
  }
};
export const GetRestaurantsByAlphabet = async (
  userId: string
): Promise<GetRestaurantsByAlphabetResponse> => {
  try {
    return await apiCall("GET", `/restaurants/alphabet/${userId}`);
  } catch (err) {
    LOGGING && console.log("GetRestaurantsByAlphabetResponse got err", err);
    return {
      restaurantsByAlphabet: {},
      restaurantsByCuisine: {},
      alphabet: [],
      cuisines: [],
      favorites: [],
      restaurants: [null],
    };
  }
};

export const GetGiftTemplates = async (
  userId: string
): Promise<GetGiftTemplatesResponse> => {
  try {
    return await apiCall("GET", `/giftCards/template`);
  } catch (err) {
    LOGGING && console.log("GetGiftTemplates got err", err);
    return {};
  }
};
export const GetFavoritesOverview = async (
  userId: string
): Promise<GetFavoritesOverviewResponse> => {
  try {
    return await apiCall("GET", `/restaurants/favorites/${userId}`);
  } catch (err) {
    LOGGING && console.log("GetFavoritesOverviewResponse got err", err);
    return { favoritesByRestaurant: [], favoritesByUser: [] };
  }
};

export const GetSurveyResults = async (
  userId: string
): Promise<GetSurveyResultsResponse> => {
  try {
    return await apiCall("GET", `/users/surveyresults/${userId}`);
  } catch (err) {
    LOGGING && console.log("GetSurveyResults got err", err);
    return { users: [] };
  }
};

export const GetMostOrderedDishes = async (
  userId: string
): Promise<GetMostOrderedDishesResponse> => {
  try {
    return await apiCall("GET", `/dishes/ordered/${userId}`);
  } catch (err) {
    LOGGING && console.log("GetMostOrdered got err", err);
    return { dishes: [] };
  }
};

export const SetFavorites = async (
  userId: string,
  favorites: string[]
): Promise<SetFavoritesResponse> => {
  try {
    const updatedUser = await apiCall("POST", `/users/${userId}`, {
      favorites,
    });
    return updatedUser.favorites;
  } catch (err) {
    LOGGING && console.log("SetFavorites got err", err);
    return [];
  }
};

type SaveRestaurantHeroImagePayload = {
  heroImage: string;
};
export const saveRestaurantHeroImage = async (
  restaurantId: string,
  payload: SaveRestaurantHeroImagePayload,
  currentUser: any
): Promise<SerializedRestaurant> => {
  const path = `/restaurants/${restaurantId}`;
  return await apiCall("post", path, payload, currentUser);
};
