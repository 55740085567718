import "./Goods.scss";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faMinus, faPlus, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextareaAutosize from "react-textarea-autosize";
import { Flex } from "src/components/Layout";
import { LOGGING } from "./";
import { Prices } from "./customers/mealMenu/Prices";
const Goods = ({
  goods,
  onChangeDishQuantity,
  onEditDishComment,
  onSaveDish,
  isMember,
  hideDishImage,
}) => {
  return (
    <>
      {goods.map((g, i) => {
        LOGGING && console.log("goods looping through: ", g);
        const {
          dish,
          price,
          quantity,
          comment,
          selections,
          key,
          cancelQuantity,
        } = g;
        const { selections: dishSelections } = dish;
        const { priceFoodieListed, priceFoodieNonMember, priceDoordashListed } =
          dish;
        // if no price, fallback to dish price
        const priceFinal = price || dish.priceFoodieListed || dish.price || 0;

        const selectionItemCache = {};
        dishSelections?.forEach((selection) => {
          selection.items?.forEach((selectionItem) => {
            selectionItemCache[selectionItem._id] = selectionItem;
          });
        });

        const selectionItems = [];
        selections?.forEach((selection) => {
          selection.selectedItems?.forEach((selectionItem) => {
            if (
              selectionItemCache[
                selectionItem?.item?._id || selectionItem?.item
              ]
            ) {
              selectionItems.push(
                selectionItemCache[
                  selectionItem?.item?._id || selectionItem?.item
                ]
              );
            }
          });
        });

        LOGGING &&
          console.log("goods rendering with selectionItems:", selectionItems);
        return (
          <div className="receipt-section goods" key={i}>
            {/* <img className='dish-image' url={dish.imageURL}/> */}
            <div className="receipt-row dish-name">
              <img
                className={`dish-image ${hideDishImage ? "hidden" : ""}`}
                src={dish.imageURL}
                alt={dish.shortName || dish.name}
              ></img>
              <span className="highlight">{dish.shortName || dish.name}</span>
              {dish.description ? (
                <span className="highlight">{dish.description}</span>
              ) : null}
            </div>
            <div className="receipt-row prices">
              <label>Price</label>
              <Prices
                priceFoodieListed={priceFoodieListed}
                priceFoodieNonMember={priceFoodieNonMember}
                priceDoordashListed={priceDoordashListed}
                isMember={isMember}
              />
            </div>
            {/* <div className={`receipt-row ${isMember ? "" : "selected"}`}>
              <label>price</label>
              <span>${dish.priceDoordashListed.toFixed(2)} regular</span>
            </div>
            <div className={`receipt-row ${isMember ? "selected" : ""}`}>
              <label></label>
              <span>${dish.priceFoodieListed.toFixed(2)} member</span>
            </div> */}
            <div className="receipt-row">
              <label>quantity</label>
              <div className="dish-quantity">
                {onChangeDishQuantity ? (
                  <button
                    onClick={onChangeDishQuantity.bind(this, key, -1)}
                    disabled={quantity < 1}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </button>
                ) : (
                  <FontAwesomeIcon icon={faTimes} />
                )}
                <span>{quantity}</span>
                {onChangeDishQuantity ? (
                  <button
                    onClick={onChangeDishQuantity.bind(this, key, 1)}
                    disabled={quantity > 9}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                ) : null}
              </div>
            </div>
            {cancelQuantity ? (
              <div className="receipt-row cancelled-quantity">
                <label>cancelled</label>
                <span>
                  <FontAwesomeIcon icon={faTimes} />
                  {cancelQuantity}
                </span>
              </div>
            ) : null}
            {selectionItems.length > 0 && (
              <div className="receipt-row">
                <Flex direction="column" className="selection-items">
                  {selectionItems.map((selectionItem) => (
                    <div className="item-details" key={selectionItem.name}>
                      <label>{selectionItem.name}</label>
                      {selectionItem.price > 0 ||
                      selectionItem.priceDoordashListed > 0 ? (
                        <Prices
                          priceFoodieListed={selectionItem.price}
                          priceDoordashListed={
                            selectionItem.priceDoordashListed
                          }
                          isMember={isMember}
                          isItem={true}
                        />
                      ) : null}
                    </div>
                  ))}
                </Flex>
              </div>
            )}
            {onEditDishComment ? (
              <div className="receipt-row comment editting">
                <label>special requests:</label>
                <TextareaAutosize
                  onChange={onEditDishComment.bind(this, key)}
                  onBlur={onSaveDish?.bind(this, key)}
                  placeholder={"e.g. no onion, etc."}
                  value={g.comment}
                  style={{ height: "60px" }}
                />
              </div>
            ) : comment && comment.length > 0 ? (
              <div className="receipt-row comment">
                <span>{`Special Requests: "${comment}"`}</span>
              </div>
            ) : null}
          </div>
        );
      })}
    </>
  );
};
export const GroupGoods = ({
  goods,
  onChangeDishQuantity,
  onEditDishComment,
  onSaveDish,
  isMember,
  myself,
  organizer,
  isNotOrganizer,
}) => {
  LOGGING &&
    console.log("GroupGoods rendering with:", {
      isNotOrganizer,
      myself,
      goods,
      organizer,
    });
  let goodsByName = {};
  let goodsByMyself = [];

  goods.forEach((good) => {
    const { addedBy } = good;
    // LOGGING &&
    // console.log("GroupGoods looping good:", { good, goodsByMyself, goodsByName });
    if (addedBy?._id === myself?._id) {
      // console.log("GroupGoods looping case 1 before:", goodsByMyself);
      goodsByMyself = [...goodsByMyself, good];
    } else if (addedBy?._id in goodsByName) {
      // console.log("GroupGoods looping case 2 before:", goodsByName);
      goodsByName[addedBy?._id] = [...goodsByName[addedBy?._id], good];
    } else {
      // console.log("GroupGoods looping case 3 before:", goodsByName);
      goodsByName[addedBy?._id] = [good];
    }
  });
  // LOGGING &&
  //   console.log("GroupGoods loop ended with:", { goodsByMyself, goodsByName });
  const goodsByUser = [goodsByMyself, ...Object.values(goodsByName)];
  // LOGGING &&
  //   console.log("GroupGoods rendering with:", { goodsByUser, goodsByName });
  return (
    <>
      {goodsByUser.map((userGoods, i) => {
        const addedBy = i === 0 ? myself : userGoods[0]?.addedBy;
        const canEdit = !isNotOrganizer || myself._id === addedBy._id;
        LOGGING &&
          console.log("goodsByUser looping: ", {
            canEdit,
            userGoods,
            addedBy,
            isNotOrganizer,
            myself,
          });

        return (
          <div className="receipt-by-user">
            <div className="receipt-user-header">
              <span className="receipt-user-name">
                <FontAwesomeIcon icon={faUser} />
                {`${addedBy?.firstName} ${addedBy?.lastName}`}
              </span>
              <div className="user-role">
                {i === 0 ? <span className="myself">myself</span> : null}
                {addedBy?._id === (organizer?._id || organizer) ? (
                  <span className="organizer">organizer</span>
                ) : null}
              </div>
            </div>
            {userGoods.map((g) => {
              // LOGGING && console.log("GroupGoods looping through: ", g);
              const {
                dish,
                price,
                quantity,
                comment,
                selections,
                key,
                cancelQuantity,
              } = g;
              const { selections: dishSelections } = dish;
              const {
                priceFoodieListed,
                priceFoodieNonMember,
                priceDoordashListed,
              } = dish;
              // if no price, fallback to dish price
              const priceFinal =
                price || dish.priceFoodieListed || dish.price || 0;

              const selectionItemCache = {};
              dishSelections?.forEach((selection) => {
                selection.items?.forEach((selectionItem) => {
                  selectionItemCache[selectionItem._id] = selectionItem;
                });
              });

              const selectionItems = [];
              selections?.forEach((selection) => {
                selection.selectedItems?.forEach((selectionItem) => {
                  selectionItems.push(
                    selectionItemCache[
                      selectionItem?.item?._id || selectionItem?.item
                    ]
                  );
                });
              });

              return (
                <div className="receipt-section" key={i}>
                  {/* <img className='dish-image' url={dish.imageURL}/> */}
                  <div className="receipt-row dish-name">
                    <img
                      className="dish-image"
                      src={dish.imageURL}
                      alt={dish.shortName || dish.name}
                    ></img>
                    <span className="highlight">
                      {dish.shortName || dish.name}
                    </span>
                  </div>
                  <div className="receipt-row prices">
                    <label>Price</label>
                    <Prices
                      priceFoodieListed={priceFoodieListed}
                      priceFoodieNonMember={priceFoodieNonMember}
                      priceDoordashListed={priceDoordashListed}
                      isMember={isMember}
                    />
                  </div>
                  {/* <div className={`receipt-row ${isMember ? "" : "selected"}`}>
              <label>price</label>
              <span>${dish.priceDoordashListed.toFixed(2)} regular</span>
            </div>
            <div className={`receipt-row ${isMember ? "selected" : ""}`}>
              <label></label>
              <span>${dish.priceFoodieListed.toFixed(2)} member</span>
            </div> */}
                  <div className="receipt-row">
                    <label>quantity</label>
                    <div className="dish-quantity">
                      {onChangeDishQuantity ? (
                        <button
                          onClick={onChangeDishQuantity.bind(this, key, -1)}
                          disabled={!canEdit || quantity < 1}
                        >
                          <FontAwesomeIcon icon={faMinus} />
                        </button>
                      ) : (
                        <FontAwesomeIcon icon={faTimes} />
                      )}
                      <span>{quantity}</span>
                      {onChangeDishQuantity ? (
                        <button
                          onClick={onChangeDishQuantity.bind(this, key, 1)}
                          disabled={!canEdit || quantity > 9}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  {cancelQuantity ? (
                    <div className="receipt-row cancelled-quantity">
                      <label>cancelled</label>
                      <span>
                        <FontAwesomeIcon icon={faTimes} />
                        {cancelQuantity}
                      </span>
                    </div>
                  ) : null}
                  {selectionItems.length > 0 && (
                    <div className="receipt-row">
                      <Flex direction="column" className="selection-items">
                        {selectionItems.map((selectionItem) => (
                          <div
                            className="item-details"
                            key={selectionItem.name}
                          >
                            <label>{selectionItem.name}</label>
                            {selectionItem.price > 0 ||
                            selectionItem.priceDoordashListed > 0 ? (
                              <Prices
                                priceFoodieListed={selectionItem.price}
                                priceDoordashListed={
                                  selectionItem.priceDoordashListed
                                }
                                isMember={isMember}
                                isItem={true}
                              />
                            ) : null}
                          </div>
                        ))}
                      </Flex>
                    </div>
                  )}
                  {canEdit && onEditDishComment ? (
                    <div className="receipt-row comment editting">
                      <label>special requests:</label>
                      <TextareaAutosize
                        onChange={onEditDishComment.bind(this, key)}
                        onBlur={onSaveDish?.bind(this, key)}
                        placeholder={"e.g. no onion, etc."}
                        value={g.comment}
                      />
                    </div>
                  ) : comment && comment.length > 0 ? (
                    <div className="receipt-row comment">
                      <span>{`Special Requests: "${comment}"`}</span>
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export default Goods;
