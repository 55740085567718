import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'src/components/Button';
import { Flex } from 'src/components/Layout';
import { Modal } from 'src/components/Modal';
import { WeeklySchedule, WeeklyScheduleData } from 'src/components/WeeklySchedule';
import { useForm } from 'src/hooks/useForm';
import { createDriver, updateDriver } from 'src/store/actions';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

type DriverModalProps = {
  isOpen: boolean;
  driver?: any;
  onClose: () => void;
};

type DriverFormData = {
  firstName?: string;
  phone?: string;
  weeklySchedule?: WeeklyScheduleData;
  active?: any;
  profileColor?: string;
  driverPaypalAccount?: string;
  notes?: string;
  driverPenalties?: number;
};

const INITIALIZE_VALUES: DriverFormData = {
  firstName: '',
  phone: '',
  weeklySchedule: {},
  active: 'true',
  profileColor: '',
  driverPaypalAccount: '',
  notes: '',
  driverPenalties: 0,
};

export const DriverModal = (props: DriverModalProps) => {
  const { isOpen, driver, onClose } = props;

  const { formData, updateFormData, onInputChange } = useForm<DriverFormData>(INITIALIZE_VALUES);

  useEffect(() => {
    if (driver) {
      updateFormData((formData) => {
        formData.firstName = driver.firstName || '';
        formData.phone = driver.phone || '';
        formData.weeklySchedule = driver.weeklySchedule;
        formData.active = String(driver.active || false);
        formData.profileColor = driver.profileColor || '';
        formData.driverPaypalAccount = driver.driverPaypalAccount || '';
        formData.notes = driver.notes || '';
        formData.driverPenalties = driver.driverPenalties ?? INITIALIZE_VALUES.driverPenalties;
      });
    } else {
      updateFormData((formData) => {
        formData.firstName = INITIALIZE_VALUES.firstName;
        formData.phone = INITIALIZE_VALUES.phone;
        formData.weeklySchedule = INITIALIZE_VALUES.weeklySchedule;
        formData.active = INITIALIZE_VALUES.active;
        formData.profileColor = INITIALIZE_VALUES.profileColor;
        formData.driverPaypalAccount = INITIALIZE_VALUES.driverPaypalAccount;
        formData.notes = INITIALIZE_VALUES.notes;
        formData.driverPenalties = INITIALIZE_VALUES.driverPenalties;
      });
    }
  }, [driver, updateFormData]);

  const dispatch = useDispatch();

  const saveDriver = async () => {
    const copiedFormData = { ...formData };
    copiedFormData.active = formData.active === 'true';
    if (driver) {
      dispatch(updateDriver(driver._id, copiedFormData as any));
    } else {
      dispatch(createDriver(copiedFormData as any));
    }
    onClose();
  };

  const onWeeklyScheduleChange = (value: WeeklyScheduleData) => {
    updateFormData((formData) => {
      formData.weeklySchedule = value;
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      title={driver ? 'Update Driver' : 'Create Driver'}
      showClose
      onClose={onClose}
    >
      <DriverForm>
        <div className="form-row">
          <div className="form-field-wrapper">
            <Flex gap={12} align="baseline">
              <FormLabel>Name</FormLabel>
              <input
                name="firstName"
                value={formData.firstName}
                onChange={onInputChange}
                placeholder="Enter Name"
              />
            </Flex>
          </div>
        </div>
        <div className="form-row">
          <div className="form-field-wrapper">
            <Flex gap={12} align="baseline">
              <FormLabel>Phone</FormLabel>
              <input
                name="phone"
                value={formData.phone}
                onChange={onInputChange}
                placeholder="Enter Phone"
              />
            </Flex>
          </div>
        </div>
        <div className="form-row">
          <div className="form-field-wrapper">
            <Flex gap={12} align="baseline">
              <FormLabel>Weekly Schedule</FormLabel>
              <WeeklyScheduleContainer>
                <WeeklySchedule value={formData.weeklySchedule} onChange={onWeeklyScheduleChange} />
              </WeeklyScheduleContainer>
            </Flex>
          </div>
        </div>
        <div className="form-row">
          <div className="form-field-wrapper">
            <Flex gap={12} align="baseline">
              <FormLabel>Active</FormLabel>
              <RadioWrapper>
                <Flex gap={24}>
                  <Flex align="center" gap={6}>
                    <input
                      id="activeYes"
                      type="radio"
                      name="active"
                      onChange={onInputChange}
                      value="true"
                      checked={formData.active === 'true'}
                    />
                    <label htmlFor="activeYes">Yes</label>
                  </Flex>
                  <Flex align="center" gap={6}>
                    <input
                      id="activeNo"
                      type="radio"
                      name="active"
                      onChange={onInputChange}
                      value="false"
                      checked={formData.active === 'false'}
                    />
                    <label htmlFor="activeNo">No</label>
                  </Flex>
                </Flex>
              </RadioWrapper>
            </Flex>
          </div>
        </div>
        <div className="form-row">
          <div className="form-field-wrapper">
            <Flex gap={12} align="baseline">
              <FormLabel>Color</FormLabel>
              <Flex justify="space-between" align="center">
                <input
                  name="profileColor"
                  value={formData.profileColor}
                  onChange={onInputChange}
                  placeholder="Enter Color"
                  style={{ width: 200 }}
                />
                <div style={{ width: 200, height: 30, background: formData.profileColor }}></div>
              </Flex>
            </Flex>
          </div>
        </div>
        <div className="form-row">
          <div className="form-field-wrapper">
            <Flex gap={12} align="baseline">
              <FormLabel>Paypal</FormLabel>
              <input
                name="driverPaypalAccount"
                value={formData.driverPaypalAccount}
                onChange={onInputChange}
                placeholder="Enter Paypal"
              />
            </Flex>
          </div>
        </div>
        <div className="form-row">
          <div className="form-field-wrapper">
            <Flex gap={12} align="baseline">
              <FormLabel>Notes</FormLabel>
              <input
                name="notes"
                value={formData.notes}
                onChange={onInputChange}
                placeholder="Enter Notes"
              />
            </Flex>
          </div>
        </div>
        <div className="form-row">
          <div className="form-field-wrapper">
            <Flex gap={12} align="baseline">
              <FormLabel>Penalties</FormLabel>
              <input
                name="driverPenalties"
                type="number"
                value={formData.driverPenalties}
                onChange={onInputChange}
              />
            </Flex>
          </div>
        </div>
        <Flex justify="center">
          <StyledButton onClick={saveDriver}>
            {driver ? 'Update Driver' : 'Create Driver'}
          </StyledButton>
        </Flex>
      </DriverForm>
    </Modal>
  );
};

const DriverForm = styled.div`
  font-size: 12px;
  width: 650px;
  margin-top: 24px;

  input {
    width: 400px;
  }

  input[type='radio'] {
    width: auto;
    margin: 0;
  }
`;

const StyledButton = styled(Button)`
  margin: 24px 0 0;
`;

const FormLabel = styled.label`
  font-size: 12px;
  width: 170px;
  text-align: right;
  padding-right: 6px;
  flex-shrink: 0;
`;

const WeeklyScheduleContainer = styled.div`
  margin-bottom: 16px;
`;

const RadioWrapper = styled.div`
  padding: 4px 0 18px;
`;
