import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

type FeatureProps = {
  name: string;
  children?: ReactNode;
};

export const Feature = (props: FeatureProps) => {
  const { name, children } = props;
  const features = useSelector((state: any) => state.currentUser.features);

  if (!features.includes(name)) {
    return null;
  }

  return <>{children}</>;
};
