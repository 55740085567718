import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { LOGGING, Footer, Loading } from ".";
import { getRestaurantsByAlphabet, changeFavorites } from "../../store/actions";
import {
  Controls,
  CuisineFilters,
  FilterSummary,
} from "./components/favorites/CuisineFilters";
import { FilterByFavorite } from "./components/favorites/FilterByFavorite";
import {
  MyFavorites,
  FavoriteHeader,
} from "./components/favorites/MyFavorites";
import {
  SearchFavorites,
  FavoritesContainer,
} from "./components/favorites/SearchFavorites";
import { centeredByColumn, SubNavHeight } from "./components/Shared";
import { SimpleNavBar2 } from "./components/SimpleNavBar2";

class PageFavorites extends Component {
  constructor(props) {
    LOGGING &&
      console.log("PageFavorites constructor called with props:", props);
    LOGGING &&
      console.log(
        "PageFavorites props?.location?.state?.PageFavoritesPos:",
        props?.location?.state?.PageFavoritesPos
      );
    super();
    window.scrollTo(0, props?.location?.state?.PageFavoritesPos);
    this.state = {
      showCuisineFilter: {},
      numberOfCuisines: 0,
      toggleFilters: false,
      scrollY: 0,
      restaurants: [],
      query: "",
      selectedMode: null,
    };

    this.handleToggleFavorite = this.handleToggleFavorite.bind(this);
    this.handleToggleCuisine = this.handleToggleCuisine.bind(this);
    this.handleToggleFilters = this.handleToggleFilters.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleClearQuery = this.handleClearQuery.bind(this);
    this.handleSelectMode = this.handleSelectMode.bind(this);
    this.handleCancelMode = this.handleCancelMode.bind(this);
    this.handleConfirmFilter = this.handleConfirmFilter.bind(this);
    this.handleClearAllFilters = this.handleClearAllFilters.bind(this);
    this.handleCloseSearch = this.handleCloseSearch.bind(this);
    this.handleCloseFavorites = this.handleCloseFavorites.bind(this);
  }
  handleCloseFavorites(e) {
    e.preventDefault();
    this.setState({ selectedMode: null }, () => {
      window.scrollTo(0, 0);
    });
  }

  handleCloseSearch(e) {
    e.preventDefault();
    const { restaurants } = this.props;
    this.setState({ selectedMode: null, restaurants, query: "" }, () => {
      window.scrollTo(0, 0);
    });
  }
  handleClearAllFilters(e) {
    e.preventDefault();
    this.setState({ showCuisineFilter: {}, numberOfCuisines: 0 });
  }
  handleConfirmFilter() {
    this.setState({ selectedMode: null });
  }
  handleCancelMode() {
    // e.preventDefault();
    LOGGING && console.log("handleCancelMode called");
    this.setState({ selectedMode: null, showCuisineFilter: {} }, () => {
      window.scrollTo(0, 0);
    });
  }
  handleSelectMode(selectedMode, e) {
    e.preventDefault();
    LOGGING &&
      console.log("handleSelectMode called with selectedMode:", selectedMode);
    this.setState({ selectedMode }, () => {
      window.scrollTo(0, 0);
    });
  }
  handleClearQuery(e) {
    e.preventDefault();
    e.stopPropagation();
    const { restaurants } = this.props;
    this.setState({ query: "", restaurants });
  }
  handleSearch(e) {
    const query = e.target.value;
    const { restaurants } = this.props;
    this.setState({
      query,
      restaurants: restaurants.filter((item) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      ),
    });
  }
  handleScroll() {
    this.setState({ scrollY: window.scrollY });
  }
  handleToggleFavorite(restaurantId) {
    LOGGING && console.log("handleToggleFavorite called with:", restaurantId);
    const { currentUser } = this.props;
    const { favorites } = currentUser.user;
    const updatedFavorites = favorites.includes(restaurantId)
      ? favorites.filter((f) => f !== restaurantId)
      : [...favorites, restaurantId];
    this.props.changeFavorites(updatedFavorites);
  }
  handleToggleFilters(e) {
    e.preventDefault();
    const { toggleFilters } = this.state;
    this.setState({ toggleFilters: !toggleFilters });
  }
  handleToggleCuisine(cuisine, e) {
    e.preventDefault();
    LOGGING && console.log("handleToggleCuisine called with:", cuisine);
    const { showCuisineFilter, numberOfCuisines } = this.state;
    this.setState({
      showCuisineFilter: {
        ...showCuisineFilter,
        [cuisine]: !showCuisineFilter[cuisine],
      },
      numberOfCuisines:
        numberOfCuisines + (showCuisineFilter[cuisine] ? -1 : 1),
    });
  }

  componentDidMount() {
    const { restaurants, cuisines, location } = this.props;
    window.addEventListener("scroll", this.handleScroll);
    LOGGING && console.log("componentDidMount ", location);
    if (
      !restaurants ||
      !cuisines ||
      restaurants.length === 0 ||
      cuisines.length === 0
    ) {
      this.setState({ loading: true });
      this.props.getRestaurantsByAlphabet().then((cuisines) => {
        let initShowCuisineFilter = {};
        cuisines.forEach((c) => {
          initShowCuisineFilter[c] = false;
        });

        LOGGING &&
          console.log("got initShowCuisineFilter: ", initShowCuisineFilter);
        this.setState(
          {
            showCuisineFilter: { ...initShowCuisineFilter },
            loading: false,
            restaurants: this.props.restaurants,
          },
          () => {
            window.scrollTo(0, location?.state?.PageFavoritesPos);
          }
        );
      });
    } else {
      this.setState({ restaurants: this.props.restaurants }, () => {
        window.scrollTo(0, location?.state?.PageFavoritesPos);
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    LOGGING &&
      console.log("PageFavorites rendering with", {
        props: this.props,
        state: this.state,
      });

    const {
      showCuisineFilter,
      numberOfCuisines,
      toggleFilters,
      loading,
      scrollY,
      restaurants,
      query,
      selectedMode,
    } = this.state;
    const { currentUser, cuisines } = this.props;
    const filteredRestaurants =
      selectedMode === "saved"
        ? restaurants.filter((r) =>
            currentUser?.user?.favorites?.includes(r._id)
          )
        : restaurants.filter(
            (r) =>
              numberOfCuisines === 0 ||
              r.cuisines.some((c) => showCuisineFilter[c.name])
          );
    if (!currentUser.isAuthenticated && selectedMode == "saved") {
      return (
        <Redirect
          to={{
            pathname: "/auth",
            state: {
              title: "To Edit Your Favorites",
              next: this.props.location.pathname,
            },
          }}
        />
      );
    }
    return (
      <div className="page with-footer">
        <SimpleNavBar2 title="Restaurants" noShadow={true} />
        {loading ? (
          <Loading />
        ) : (
          <FavoritesContainer mode={selectedMode}>
            {/* <FilterContainer
              toggleFilters={toggleFilters}
              onToggleFilters={this.handleToggleFilters}
              numberOfCuisines={numberOfCuisines}
            /> */}

            <Controls
              selected={selectedMode}
              onSelect={this.handleSelectMode}
            />
            {selectedMode === "filter" ? (
              <CuisineFilters
                cuisines={cuisines}
                showCuisineFilter={showCuisineFilter}
                onToggleCuisine={this.handleToggleCuisine}
                onCancel={this.handleCancelMode}
                onConfirm={this.handleConfirmFilter}
                show={selectedMode === "filter"}
                results={filteredRestaurants.length}
                onClearAll={this.handleClearAllFilters}
              />
            ) : null}
            {selectedMode === "search" ? (
              <SearchFavorites
                query={query}
                onSearch={this.handleSearch}
                onClear={this.handleClearQuery}
                onCloseSearch={this.handleCloseSearch}
              />
            ) : null}
            {selectedMode === "saved" ? (
              <FilterByFavorite onClose={this.handleCloseFavorites} />
            ) : null}
            <FilterSummary
              cuisines={cuisines.filter((c) => showCuisineFilter[c])}
              results={
                selectedMode === "search"
                  ? restaurants.length
                  : filteredRestaurants.length
              }
              onClearAll={this.handleClearAllFilters}
              onToggleCuisine={this.handleToggleCuisine}
            />
            <MyFavorites
              restaurants={filteredRestaurants}
              scrollY={scrollY}
              alphabet={cuisines}
              favorites={currentUser?.user?.favorites}
              isLoggedIn={currentUser?.user?._id}
              showCuisineFilter={showCuisineFilter}
              onToggleFavorite={this.handleToggleFavorite}
              query={query}
            />
          </FavoritesContainer>
        )}
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log("PageFavorites got redux state:", state);
  return {
    currentUser: state.currentUser,
    restaurants: state.restaurants,
    cuisines: state.cuisines,
  };
}

export default connect(mapStateToProps, {
  getRestaurantsByAlphabet,
  changeFavorites,
})(PageFavorites);
