import './Profile.scss';
import { Back } from '../../shared';
import { Avatar } from './';

const Profile = ({ user, likeCount, recommendationCount, showTab, onSelectTab, onBack }) => (
  <div className="profile-banner">
    <Back onClick={onBack} />
    <div className="profile-body">
      <Avatar user={user} />
      <div className="name">
        {user.firstName && user.lastName
          ? `${user.firstName} ${user.lastName}`
          : `${user.userName}`}
      </div>
    </div>
    <div className="counts">
      <button
        type="button"
        onClick={onSelectTab.bind(this, 'recommendations')}
        className={`count ${showTab === 'recommendations' ? 'selected' : ''}`}
      >{`${recommendationCount} recommendation${recommendationCount > 1 ? 's' : ''}`}</button>
      <button
        type="button"
        onClick={onSelectTab.bind(this, 'likes')}
        className={`count ${showTab === 'likes' ? 'selected' : ''}`}
      >{`${likeCount} like${likeCount > 1 ? 's' : ''}`}</button>
    </div>
  </div>
);

export default Profile;
