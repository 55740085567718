import './Dish.scss';
import { Link } from 'react-router-dom';
const Dish = ({ dish, restaurant }) => (
  <div className="dish">
    {/* {imageURL ? <img src={imageURL} alt="new" /> : null} */}
    {dish && dish.length > 0 ? (
      <div className="dish-info dish-name">
        <label>dish</label>
        <span>{dish}</span>
      </div>
    ) : null}
    <Link className="dish-info restaurant-name" to={`/restaurant/${restaurant.googlePlaceId}`}>
      <label>restaurant</label>
      <span>{restaurant.name}</span>
    </Link>
  </div>
);

export default Dish;
