import "./CheckoutList.scss";
import moment from "moment-timezone";
import { LOGGING, PhoneFormat } from ".";

const Checkout = ({
  checkout,
  onFinishCheckout,
  onFinishCheckoutNoPayment,
}) => {
  LOGGING && console.log("rendering checkout:", checkout);
  const totalItems = checkout.goods.reduce((acc, g) => acc + g.quantity, 0);
  return (
    <div className="checkout">
      {checkout._id && (
        <div className="checkout-field id">{`${checkout._id}`}</div>
      )}
      {checkout.createdAt && (
        <div className="checkout-field created">{`Created: ${moment(
          checkout.createdAt
        ).format("ddd MMM DD h:mmA")}`}</div>
      )}
      {checkout.createdAt && (
        <div className="checkout-field updated">{`Updated: ${moment(
          checkout.updatedAt
        ).format("ddd MMM DD h:mmA")}`}</div>
      )}
      {checkout.mealType && (
        <div className="checkout-field meal type">{`${checkout.mealType}`}</div>
      )}
      {checkout.meal?.restaurant?.name && (
        <div className="checkout-field restaurant">{`${checkout.meal.restaurant.name}`}</div>
      )}
      {checkout.goods && (
        <div className="checkout-field goods">{`Total Items: ${totalItems}`}</div>
      )}
      {checkout?.goods?.length
        ? checkout?.goods.map((g) => (
            <div className="checkout-field goods">-{g.dish.name}</div>
          ))
        : null}
      {checkout?.tips ? (
        <div className="checkout-field goods">tip: ${checkout.tips}</div>
      ) : null}
      {checkout.due && (
        <div className="checkout-field due">{`Due: $${checkout.due}`}</div>
      )}
      <div className="checkout-field name">{`${checkout.user?.firstName} ${checkout.user?.lastName}`}</div>
      {(checkout.user?.phone ||
        (checkout.user?.deliveryInfo && checkout.user.deliveryInfo?.phone)) && (
        <div className="checkout-field phone">
          {`${PhoneFormat(
            checkout.user.phone ||
              (checkout.user.deliveryInfo && checkout.user.deliveryInfo.phone)
          )}`}
        </div>
      )}
      {(checkout.user?.email ||
        (checkout.user?.deliveryInfo && checkout.user.deliveryInfo?.email)) && (
        <div className="checkout-field email">{`${
          checkout.user.email ||
          (checkout.user.deliveryInfo && checkout.user.deliveryInfo.email)
        }`}</div>
      )}
      {(checkout.user?.address ||
        (checkout.user?.deliveryInfo &&
          checkout.user.deliveryInfo?.address)) && (
        <div className="checkout-field address">{`${
          checkout.user.address ||
          (checkout.user.deliveryInfo && checkout.user.deliveryInfo.address)
        }`}</div>
      )}
      <button onClick={onFinishCheckout} style={{ margin: "10px 0px" }}>
        <span>Finish Checkout</span>
      </button>
      <button
        onClick={onFinishCheckoutNoPayment}
        style={{ margin: "10px 0px" }}
      >
        <span>Finish Checkout w/o Payment</span>
      </button>
    </div>
  );
};

const CheckoutList = ({
  checkouts,
  onFinishCheckout,
  onFinishCheckoutNoPayment,
}) => {
  return (
    <div className="checkout-list">
      <div className="checkouts">
        {checkouts
          .sort((a, b) => a.window?.start - b.window?.start)
          .map((checkout) => (
            <Checkout
              checkout={checkout}
              onFinishCheckout={onFinishCheckout.bind(this, checkout)}
              onFinishCheckoutNoPayment={onFinishCheckoutNoPayment.bind(
                this,
                checkout
              )}
            />
          ))}
      </div>
    </div>
  );
};

export default CheckoutList;
