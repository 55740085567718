import { DriverMealStopTask } from "./DriverMealStopTask";
import { DriverOrderStopTask } from "./DriverOrderStopTask";

export const CurrentDriverTask = ({ task, totalNumTasks }) => {
  if (task.taskType === "DELIVERY_AND_TRANSIT") {
    return (
      <div className="list driver-tasks" style={{ paddingBottom: "20px" }}>
        {task.ordersToPickupFromDriver?.length > 0 && (
          <DriverMealStopTask
            task={task}
            pickupFromDriver={true}
            totalNumTasks={totalNumTasks}
            showTaskDetails={true}
            canCompleteTask={
              task.ordersToGiveToDriver?.length === 0 &&
              task.ordersToDeliver?.length === 0
            }
          />
        )}
        {task.ordersToGiveToDriver?.length > 0 && (
          <DriverMealStopTask
            task={task}
            giveToDriver={true}
            totalNumTasks={totalNumTasks}
            showTaskDetails={task.ordersToPickupFromDriver?.length === 0}
            canCompleteTask={task.ordersToDeliver?.length === 0}
          />
        )}
        {task.ordersToDeliver?.length > 0 && (
          <DriverOrderStopTask
            task={task}
            totalNumTasks={totalNumTasks}
            showTaskDetails={
              task.ordersToPickupFromDriver?.length === 0 &&
              task.ordersToGiveToDriver?.length === 0
            }
            canCompleteTask={true}
          />
        )}
      </div>
    );
  } else if (task.taskType === "PICKUP_AND_TRANSIT") {
    return (
      <div className="list driver-tasks" style={{ paddingBottom: "20px" }}>
        {task.ordersToPickupFromDriver?.length > 0 && (
          <DriverMealStopTask
            task={task}
            pickupFromDriver={true}
            totalNumTasks={totalNumTasks}
            showTaskDetails={true}
            canCompleteTask={
              task.ordersToGiveToDriver?.length === 0 &&
              task.ordersToPickup?.length === 0
            }
          />
        )}
        {task.ordersToGiveToDriver?.length > 0 && (
          <DriverMealStopTask
            task={task}
            giveToDriver={true}
            totalNumTasks={totalNumTasks}
            showTaskDetails={task.ordersToPickupFromDriver?.length === 0}
            canCompleteTask={task.ordersToPickup?.length === 0}
          />
        )}
        {task.ordersToPickup?.length > 0 && (
          <DriverMealStopTask
            task={task}
            totalNumTasks={totalNumTasks}
            showTaskDetails={
              task.ordersToPickupFromDriver?.length === 0 &&
              task.ordersToGiveToDriver?.length === 0
            }
            canCompleteTask={true}
          />
        )}
      </div>
    );
  } else if (task.taskType === "PICKUP") {
    return (
      <div className="list driver-tasks" style={{ paddingBottom: "20px" }}>
        <DriverMealStopTask
          task={task}
          totalNumTasks={totalNumTasks}
          showTaskDetails={true}
          canCompleteTask={true}
        />
      </div>
    );
  } else if (task.taskType === "TRANSIT") {
    return (
      <div className="list driver-tasks" style={{ paddingBottom: "20px" }}>
        {task.ordersToPickupFromDriver?.length > 0 && (
          <DriverMealStopTask
            task={task}
            pickupFromDriver={true}
            totalNumTasks={totalNumTasks}
            showTaskDetails={true}
            canCompleteTask={task.ordersToGiveToDriver?.length === 0}
          />
        )}
        {task.ordersToGiveToDriver?.length > 0 && (
          <DriverMealStopTask
            task={task}
            giveToDriver={true}
            totalNumTasks={totalNumTasks}
            showTaskDetails={task.ordersToPickupFromDriver?.length === 0}
            canCompleteTask={true}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className="list driver-tasks" style={{ paddingBottom: "20px" }}>
        <DriverOrderStopTask
          task={task}
          totalNumTasks={totalNumTasks}
          showTaskDetails={true}
          canCompleteTask={true}
        />
      </div>
    );
  }
};
