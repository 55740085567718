import React from "react";
import moment from "moment-timezone";
import ReactGA from "react-ga";
import { Toaster } from "react-hot-toast";
import HttpsRedirect from "react-https-redirect";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { LOGGING } from "../constants";
import { store, persistor } from "../store";
// import { BrowserRouter as Router } from "react-router-dom";
import history from "./history";
import Root from "./Root";
// import { createBrowserHistory } from "history";

// const history = createHistory();
ReactGA.initialize("UA-188162054-1");
history.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search);
  LOGGING && console.log(`react ga got ${location.pathname}`);
});
// const history = createBrowserHistory();

// Set default timezone to America/Los_Angeles
moment.tz.setDefault("America/Los_Angeles");

// // Initialize google analytics page view tracking
// history.listen((location) => {
//   ReactGA.set({ page: location.pathname }); // Update the user's current page
//   ReactGA.pageview(location.pathname); // Record a pageview for the given page
// });

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HttpsRedirect>
        <Router history={history}>
          <Root persistor={persistor} />
        </Router>
        <Toaster
          containerStyle={{
            zIndex: "9999 !important",
          }}
        />
      </HttpsRedirect>
    </PersistGate>
  </Provider>
);

export default App;
