import "./Share.scss";
// import { faGift } from '@fortawesome/pro-duotone-svg-icons';
import { faEnvelope, faShare, faSms } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { CopyToClipboard } from 'react-copy-to-clipboard';
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import {
  EmailShareButton,
  FacebookShareButton,
  // HatenaShareButton,
  // InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  // LivejournalShareButton,
  // MailruShareButton,
  // OKShareButton,
  PinterestShareButton,
  // PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  // TumblrShareButton,
  TwitterShareButton,
  // ViberShareButton,
  // VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  FacebookIcon,
  TwitterIcon,
  RedditIcon,
  PinterestIcon,
  WhatsappIcon,
  LinkedinIcon,
  EmailIcon,
  LineIcon,
  WorkplaceIcon,
  TelegramIcon,
} from "react-share";
import { Back, socialText } from ".";
const SocialActions = ({ code, onSuccess }) => {
  const quote = `Try my favorite food delivery service with code ${code} to get $5 off your first order of $20 or more!`;
  const buttonWidth = 50;
  return (
    <div className="social-actions">
      <FacebookShareButton
        className="social-action"
        url={"https://foodie.earth"}
        quote={quote}
        hashtag="foodie.earth"
      >
        <FacebookIcon size={buttonWidth} round={true} />
        {/* <div className="share-action">
share <FontAwesomeIcon icon={faFacebook} />
</div> */}
      </FacebookShareButton>
      <TwitterShareButton
        className="social-action"
        url={"https://foodie.earth"}
        hashtags={["foodies", "bayarea"]}
        title={quote}
        // onShareWindowClose={onSuccess.bind(this)}
      >
        <TwitterIcon size={buttonWidth} round />
        {/* <div className="share-action">
share <FontAwesomeIcon icon={faFacebook} />
</div> */}
      </TwitterShareButton>
      <RedditShareButton
        url={"https://foodie.earth"}
        title={quote}
        className="social-action"
        // onShareWindowClose={onSuccess.bind(this)}
      >
        <RedditIcon size={buttonWidth} round />
      </RedditShareButton>
      <WhatsappShareButton
        url={"https://foodie.earth"}
        media={
          "https://i.pinimg.com/originals/68/99/31/6899316cff06ab1a1731cc978c282e66.jpg"
        }
        title={quote}
        className="social-action"
        // onShareWindowClose={onSuccess.bind(this)}
      >
        <WhatsappIcon size={buttonWidth} round />
      </WhatsappShareButton>
    </div>
  );
};
const ShareCard = ({ onHideShare, onSuccess, code, firstName }) => {
  // const code = 'EMILY438';
  const quote = `Try my favorite food delivery service with code ${code} to get $5 off your first order of $20 or more!`;
  const body =
    `Hey,\n\nCheck out my favorite food delivery service, Foodie Earth! They offer free delivery with no ` +
    `fees, using optimized routes to minimize driving for eco-friendly delivery.\n\nGet $5 off your first order of ` +
    `$20+ by ordering on https://foodie.earth/ using my code ${code} at checkout!`;

  return (
    <div className="share-card">
      <Helmet>
        <title>Foodie.earth</title>
      </Helmet>
      {/* <ShareBanner code={code} noNotes={true} /> */}
      <Back onClick={onHideShare} />
      {/* <div className="share-title">Spread the word</div> */}
      <div className="share-rules">
        <span>
          {`Share code ${code}. Each time someone applies this code to their 1st order, we will reward you both with $5.`}
        </span>
        {/* <ul>
          <li>
            Code <b>{code}</b> is for new user only.
          </li>
          <li>For each code usage you will receive $5 credit when the order is delivered.</li>
        </ul> */}
      </div>
      <div className="preview-section">
        <div className="preview-title">post preview</div>
        <div className="preview">
          <div className="quote">{quote}</div>
          <div className="image"></div>
          <div className="preview-footer">
            <div className="slogan">
              Explore local cuisines, with free delivery and lower food prices.
            </div>
            <div className="website">foodie.earth</div>
          </div>
        </div>
      </div>
      <div className="social-buttons">
        <EmailShareButton
          body={`${body}\n\n Yours,\n${firstName}`}
          subject={"Try Foodie Earth with $5 off and $0 delivery!"}
          url=""
          separator=""
          onShareWindowClose={onSuccess.bind(this)}
        >
          <EmailIcon size={40} round={true} />
        </EmailShareButton>
        <FacebookShareButton
          url={"https://foodie.earth"}
          quote={quote}
          hashtag="foodie.earth"
          onShareWindowClose={onSuccess.bind(this)}
        >
          <FacebookIcon size={40} round={true} />
          {/* <div className="share-action">
          share <FontAwesomeIcon icon={faFacebook} />
        </div> */}
        </FacebookShareButton>
        <TwitterShareButton
          url={"https://foodie.earth"}
          hashtags={["foodies", "bayarea"]}
          title={quote}
          onShareWindowClose={onSuccess.bind(this)}
        >
          <TwitterIcon size={40} round />
          {/* <div className="share-action">
          share <FontAwesomeIcon icon={faFacebook} />
        </div> */}
        </TwitterShareButton>
        <RedditShareButton
          url={"https://foodie.earth"}
          title={quote}
          onShareWindowClose={onSuccess.bind(this)}
        >
          <RedditIcon size={40} round />
        </RedditShareButton>
        <PinterestShareButton
          url={"https://foodie.earth"}
          media={
            "https://i.pinimg.com/originals/68/99/31/6899316cff06ab1a1731cc978c282e66.jpg"
          }
          description={quote}
          title={quote}
          onShareWindowClose={onSuccess.bind(this)}
        >
          <PinterestIcon size={40} round />
        </PinterestShareButton>
        <WhatsappShareButton
          url={"https://foodie.earth"}
          media={
            "https://i.pinimg.com/originals/68/99/31/6899316cff06ab1a1731cc978c282e66.jpg"
          }
          title={quote}
          onShareWindowClose={onSuccess.bind(this)}
        >
          <WhatsappIcon size={40} round />
        </WhatsappShareButton>
        <LinkedinShareButton
          url={"https://foodie.earth"}
          media={
            "https://i.pinimg.com/originals/68/99/31/6899316cff06ab1a1731cc978c282e66.jpg"
          }
          title={quote}
          onShareWindowClose={onSuccess.bind(this)}
        >
          <LinkedinIcon size={40} round />
        </LinkedinShareButton>
        <LineShareButton
          url={"https://foodie.earth"}
          media={
            "https://i.pinimg.com/originals/68/99/31/6899316cff06ab1a1731cc978c282e66.jpg"
          }
          title={quote}
          onShareWindowClose={onSuccess.bind(this)}
        >
          <LineIcon size={40} round />
        </LineShareButton>
        <WorkplaceShareButton
          url={"https://foodie.earth"}
          quote={quote}
          onShareWindowClose={onSuccess.bind(this)}
        >
          <WorkplaceIcon size={40} round />
        </WorkplaceShareButton>
        <TelegramShareButton
          url={"https://foodie.earth"}
          media={
            "https://i.pinimg.com/originals/68/99/31/6899316cff06ab1a1731cc978c282e66.jpg"
          }
          title={quote}
          onShareWindowClose={onSuccess.bind(this)}
        >
          <TelegramIcon size={40} round />
        </TelegramShareButton>
      </div>
    </div>
  );
};
const ShareBanner = ({ code, onShowSocialActions, firstName }) => {
  const body =
    `Hey,\n\nCheck out my favorite food delivery service, Foodie Earth! They offer free delivery with no ` +
    `fees, using optimized routes to minimize driving for eco-friendly delivery.\n\nGet $5 off your first order of ` +
    `$20+ by ordering on https://foodie.earth/ using my code ${code} at checkout!`;
  return (
    <div className="share-section">
      <div className="share-overlay"></div>
      <div className="smiley-star"></div>
      <div className="redeem-title">redeem $200</div>
      <span className="share-notes">
        Invite 20 friends. For each friend's 1st order, we give you both $10.
      </span>
      <div className="share-banner">
        <span className="share-code">{`${code || "1 Click earns $100"}`}</span>
      </div>

      <div className="share-actions">
        <a
          className="share-action"
          href={`sms:${
            /Android/i.test(navigator.userAgent) ? "?" : "&"
          }body=${socialText(code)}`}
        >
          <FontAwesomeIcon icon={faSms} />
          <span>text</span>
        </a>

        <EmailShareButton
          className="share-action"
          body={`${body}\n\n Yours,\n${firstName}`}
          subject={"Try Foodie Earth with $5 off and $0 delivery!"}
          url=""
          separator=""
          // style={{ backgroundColor: '#f1f1f1' }}
          // onShareWindowClose={onSuccess.bind(this)}
        >
          <FontAwesomeIcon icon={faEnvelope} />
          <span>email</span>
        </EmailShareButton>
        <button className="share-action" onClick={onShowSocialActions}>
          <FontAwesomeIcon icon={faShare} />
          <span>share</span>
        </button>
      </div>
    </div>
  );
};
const ShareButton = ({ code }) => (
  <Link to="/share" className="share-button">
    <ShareBanner code={code} />
  </Link>
);

const HeadTags = (props) => {
  const {
    title = "Portfolio - your name",
    metaDescription = "defaul tdescription",
  } = props;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="description" key="description" content={metaDescription} />
      <meta name="title" key="title" content={title} />
      <meta property="og:title" key="og:title" content={title} />
      <meta property="og:locale" key="og:locale" content="en_US" />
      <meta charSet="utf-8" />
      <meta property="og:type" key="og:type" content="website" />
      <meta
        property="og:description"
        key="og:description"
        content={metaDescription}
      />
      <meta
        property="og:image"
        key="og:image"
        content="https://i.pinimg.com/originals/68/99/31/6899316cff06ab1a1731cc978c282e66.jpg"
      />
    </Helmet>
  );
};
export { ShareCard, ShareButton, ShareBanner, SocialActions };
