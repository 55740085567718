import "./UserList.scss";
import {
  faUsers,
  faUsersCog,
  faCopy,
  faSignIn,
  faChartBar,
  faStar,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import { Button } from "src/components/Button";
import { Flex } from "src/components/Layout";
import { useForm } from "src/hooks";
import { PhoneFormat } from "./";
// import { LOGGING, Back, PopUp } from './';

const User = ({
  user,
  onStopFavorites,
  onStopMarketingEmail,
  onBlockUser,
  onSaveUser,
  onDelegate,
  onStopMarketingText,
  onStopFavoriteAlertText,
  onStopFavoriteAlertEmail,
}) => {
  const { formData, onInputChange } = useForm({
    credit: user.credit,
    addressMap: user.addressMap,
  });

  return (
    <div
      className={`user ${user?.deliveryInfo?.address_room_info ? "apt" : "sfh"}
     ${user?.membership?.status}`}
    >
      <div className="user-section">
        {user._id && <div className="user-field id">{`${user._id}`}</div>}
        <div className="user-field city">{`${user?.deliveryZone?.name}`}</div>
        <div className="user-field name">{`${user.firstName} ${user.lastName}`}</div>
        {(user.email || (user.deliveryInfo && user.deliveryInfo.email)) && (
          <div className="user-field email">{`${
            user.email || (user.deliveryInfo && user.deliveryInfo.email)
          }`}</div>
        )}
        {(user.phone || (user.deliveryInfo && user.deliveryInfo.phone)) && (
          <div className="user-field phone">
            {`${PhoneFormat(
              user.phone || (user.deliveryInfo && user.deliveryInfo.phone)
            )}`}
          </div>
        )}
        {(user.address || (user.deliveryInfo && user.deliveryInfo.address)) && (
          <div className="user-field address">{`${
            user.address || (user.deliveryInfo && user.deliveryInfo.address)
          }`}</div>
        )}
        {user.deliveryInfo && user.deliveryInfo.address_room_info && (
          <div className="user-field room-info">{`Room ${user.deliveryInfo.address_room_info}`}</div>
        )}
        {user.deliveryInfo && user.deliveryInfo.note && (
          <div className="user-field room-info">{`Notes: "${user.deliveryInfo.note}"`}</div>
        )}
        {user?.deliveryInfo?.ring_doorbell !== undefined ? (
          <div className="user-field room-info">
            Ring Bell? {`${user?.deliveryInfo?.ring_doorbell ? "Yes" : "No"}`}
          </div>
        ) : null}
        <div className="user-field blockUser">
          <div className="user-subfield">
            <label>user status: </label>
            <span className={user?.isBlocked ? "off" : "on"}>{`${
              user?.isBlocked ? "blocked" : "active"
            }`}</span>
          </div>
          <div className="user-subfield">
            <label>block</label>
            <input
              type="checkbox"
              checked={user.isBlocked}
              onChange={() => {
                onBlockUser(user._id, !user.isBlocked);
              }}
              name="blockUser"
            />
          </div>
        </div>
        <div className="user-field favorite">
          <div className="user-subfield">
            <label>fav alerts [Temp]:</label>
            <span
              className={!user?.interaction?.stop_favorites ? "on" : "off"}
            >{`${!user?.interaction?.stop_favorites ? "on" : "off"}`}</span>
          </div>
          <div className="user-subfield">
            <label>stop</label>
            <input
              type="checkbox"
              checked={user.interaction?.stop_favorites}
              onChange={() => {
                onStopFavorites(user._id, !user.interaction?.stop_favorites);
              }}
              name="stopFavoriteAlerts"
            />
          </div>
        </div>
      </div>
      <div className="user-section">
        <div className="user-field created-at">
          {`signed up ${moment(user.createdAt).format(
            "HH:mmA, ddd, MMM Do, YYYY"
          )}`}
        </div>
        {user?.membership ? (
          <div className="user-field membership">
            {/* <label>membership</label> */}
            <span>{`${user?.membership?.status} member since ${moment(
              user?.membership?.start_date * 1000
            ).format("MMM DD, YYYY")}`}</span>
          </div>
        ) : null}
      </div>
      <div className="user-section">
        <div className="user-field marketingEmails">
          <div className="user-subfield">
            <label>marketing email: </label>
            <span
              className={user?.receiveMarketingEmail === false ? "off" : "on"}
            >{`${user?.receiveMarketingEmail === false ? "off" : "on"}`}</span>
          </div>
          <div className="user-subfield">
            <label>stop</label>
            <input
              type="checkbox"
              checked={user.receiveMarketingEmail === false}
              onChange={() => {
                onStopMarketingEmail(
                  user._id,
                  user.receiveMarketingEmail === null
                    ? false
                    : !user.receiveMarketingEmail
                );
              }}
              name="stopMarketingEmails"
            />
          </div>
        </div>
        <div className="user-field marketingText">
          <div className="user-subfield">
            <label>marketing text: </label>
            <span
              className={user?.receiveMarketingText === false ? "off" : "on"}
            >{`${user?.receiveMarketingText === false ? "off" : "on"}`}</span>
          </div>
          <div className="user-subfield">
            <label>stop</label>
            <input
              type="checkbox"
              checked={user.receiveMarketingText === false}
              onChange={() => {
                onStopMarketingText(
                  user._id,
                  user.receiveMarketingText === null
                    ? false
                    : !user.receiveMarketingText
                );
              }}
              name="stopMarketingText"
            />
          </div>
        </div>
        <div className="user-field favoriteText">
          <div className="user-subfield">
            <label>favorite text: </label>
            <span
              className={
                user?.receiveFavoriteAlertText === false ? "off" : "on"
              }
            >{`${
              user?.receiveFavoriteAlertText === false ? "off" : "on"
            }`}</span>
          </div>
          <div className="user-subfield">
            <label>stop</label>
            <input
              type="checkbox"
              checked={user.receiveFavoriteAlertText === false}
              onChange={() => {
                onStopFavoriteAlertText(
                  user._id,
                  user.receiveFavoriteAlertText === null
                    ? false
                    : !user.receiveFavoriteAlertText
                );
              }}
              name="stopFavoriteAlertText"
            />
          </div>
        </div>
        <div className="user-field favoriteEmail">
          <div className="user-subfield">
            <label>favorite email: </label>
            <span
              className={
                user?.receiveFavoriteAlertEmail === false ? "off" : "on"
              }
            >{`${
              user?.receiveFavoriteAlertEmail === false ? "off" : "on"
            }`}</span>
          </div>
          <div className="user-subfield">
            <label>stop</label>
            <input
              type="checkbox"
              checked={user.receiveFavoriteAlertEmail === false}
              onChange={() => {
                onStopFavoriteAlertEmail(
                  user._id,
                  user.receiveFavoriteAlertEmail === null
                    ? false
                    : !user.receiveFavoriteAlertEmail
                );
              }}
              name="receiveFavoriteAlertEmail"
            />
          </div>
        </div>
      </div>

      {user.userGroup && (
        <div className="user-field userGroup">
          <FontAwesomeIcon
            icon={user.isUserGroupAdmin ? faUsersCog : faUsers}
          />
          {user.isUserGroupAdmin && "(Admin) "}
          {`${user.userGroup.name}`}
        </div>
      )}
      <div className="user-section">
        <div className="user-field num-orders">
          {`number of orders: ${user?.stats?.num_orders || 0}`}
        </div>
        <div className="user-field total-gmv">
          {`total spend: $${user?.stats?.total_gmv.toFixed(2) || 0}`}
        </div>
        <div className="user-field tips">
          {`tip rate: ${
            user?.stats?.total_gmv > 0
              ? ((user.stats.total_tips / user.stats.total_gmv) * 100).toFixed(
                  1
                )
              : 0
          }%`}
        </div>
        <div className="user-field total-foodie-distance">
          {`total foodie distance: ${(
            user?.stats?.total_foodie_distance || 0
          ).toFixed(1)} mi`}
        </div>
        <div className="user-field total-doordash-distance">
          {`total doordash distance: ${(
            user?.stats?.total_doordash_distance || 0
          ).toFixed(1)} mi`}
        </div>
      </div>
      {/* <div className="user-field assign-group">
        <Select
          options={userGroups}
          onChange={onChangeGroupForUser.bind(this, user)}
          labelField="name"
          valueField="_id"
          values={user.userGroup ? [user.userGroup] : []}
        />
        {user.userGroup && (
          <label for="isUserGroupAdmin">
            Is Group Admin
            <input
              type="checkbox"
              name="isUserGroupAdmin"
              checked={user.isUserGroupAdmin}
              onChange={onChangeisGroupAdminForUser.bind(this, user)}
            />
          </label>
        )}
      </div> */}
      <div className="user-section">
        <div className="user-field credit">
          <Flex align="center">
            <div>credit：</div>
            <input
              name="credit"
              type="number"
              value={formData.credit}
              onChange={onInputChange}
            />
            <Button
              size="small"
              onClick={() => {
                onSaveUser(user._id, { ...formData });
              }}
            >
              Save
            </Button>
          </Flex>
        </div>
        <div className="user-field">credit spent: {user.creditSpent || 0}</div>
      </div>
      <div className="user-section">
        <div className="user-field credit">
          <Flex align="center">
            <div>map：</div>
            <input
              name="addressMap"
              type="string"
              value={formData.addressMap}
              onChange={onInputChange}
            />
            <Button
              size="small"
              onClick={() => {
                onSaveUser(user._id, { ...formData });
              }}
            >
              Save
            </Button>
          </Flex>
        </div>
      </div>

      {/* //i would like to add a textarea and a submit button here, i type something
      //in the textarea and click submit, it will send the text to the user
      // using this server call put https://foodie-watch-api.herokuapp.com/api/messages/
      // with the body {To: user.phone, Body: "the text i typed in the textarea"} */}
      <div className="user-section">
        {/* // can you align the textarea and the button vertically? */}
        <div className="user-field message">
          <label>send a text</label>
          <textarea
            name="message"
            placeholder="Type your message here"
            onChange={onInputChange}
          />
          <br />
          <label>attach a picture</label>
          <textarea
            name="picture"
            placeholder="Type the link to the picture here"
            onChange={onInputChange}
          />
          <Button
            onClick={(e) => {
              e.preventDefault();
              fetch("https://foodie-watch-api.herokuapp.com/api/messages/", {
                method: "put",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  To: user.phone,
                  Body: formData.message,
                  mediaUrl: formData.picture,
                }),
              });
            }}
          >
            Send
          </Button>
        </div>
      </div>
      <button className="delegate-user" onClick={onDelegate}>
        <FontAwesomeIcon icon={faSignIn} /> <span>delegate</span>
      </button>
      <Link className="view-stats" to={`/stats/${user._id}`}>
        <FontAwesomeIcon icon={faChartBar} />
        <span>stats</span>
      </Link>
    </div>
  );
};

const UserList = ({
  users,
  onBack,
  newUserGroupName,
  onCreateNewUserGroup,
  onEdit,
  usersMarketing,
  userGroups,
  onChangeGroupForUser,
  onChangeisGroupAdminForUser,
  onStopFavorites,
  onStopMarketingEmail,
  onStopMarketingText,
  onStopFavoriteAlertText,
  onStopFavoriteAlertEmail,
  onBlockUser,
  onSaveUser,
  onDelegate,
}) => {
  return (
    <div className="user-list">
      {/* <div className="user-count">
        <span>{`${users.length} users`}</span>
        <CopyToClipboard text={usersMarketing} >
          <FontAwesomeIcon icon={faCopy} />
        </CopyToClipboard>
      </div> */}
      {/* <div className="create-group">
        <input
          name="newUserGroupName"
          placeholder="Group Name"
          defaultValue={newUserGroupName}
          onChange={onEdit}
        />
        <button onClick={onCreateNewUserGroup}>Create a User Group</button>
      </div> */}
      <div className="users">
        {/* <div className="list-title">
          <Back onClick={onBack} />
          <span>{`user list (${users.length})`}</span>
        </div> */}
        {users
          .sort((a, b) => b.createdAt - a.createdAt)
          .map((user) => (
            <User
              user={user}
              key={user._id}
              userGroups={userGroups}
              onChangeGroupForUser={onChangeGroupForUser}
              onChangeisGroupAdminForUser={onChangeisGroupAdminForUser}
              onStopFavorites={onStopFavorites}
              onStopMarketingEmail={onStopMarketingEmail}
              onStopMarketingText={onStopMarketingText}
              onStopFavoriteAlertText={onStopFavoriteAlertText}
              onStopFavoriteAlertEmail={onStopFavoriteAlertEmail}
              onBlockUser={onBlockUser}
              onSaveUser={onSaveUser}
              onDelegate={onDelegate.bind(this, user._id)}
            />
          ))}
      </div>
    </div>
  );
};

export const LeaderBoardConsole = ({
  period,
  onSelectPeriod,
  rankBy,
  onSelectRankBy,
}) => (
  <div className="leader-board-console">
    <div className="period-console">
      <button
        className={`select-period ${period === "1w" ? "selected" : ""}`}
        onClick={onSelectPeriod.bind(this, "1w")}
      >
        <span>1w</span>
      </button>
      <button
        className={`select-period ${period === "1m" ? "selected" : ""}`}
        onClick={onSelectPeriod.bind(this, "1m")}
      >
        <span>1m</span>
      </button>
      <button
        className={`select-period ${period === "2m" ? "selected" : ""}`}
        onClick={onSelectPeriod.bind(this, "2m")}
      >
        <span>2m</span>
      </button>
      <button
        className={`select-console ${period === "3m" ? "selected" : ""}`}
        onClick={onSelectPeriod.bind(this, "3m")}
      >
        <span>3m</span>
      </button>
      <button
        className={`select-period ${period === "6m" ? "selected" : ""}`}
        onClick={onSelectPeriod.bind(this, "6m")}
      >
        <span>6m</span>
      </button>
    </div>
    <div className="rank-console">
      <button
        className={`select-rank ${rankBy === "spend" ? "selected" : ""}`}
        onClick={onSelectRankBy.bind(this, "spend")}
      >
        <span>spend</span>
      </button>
      <button
        className={`select-rank ${rankBy === "orders" ? "selected" : ""}`}
        onClick={onSelectRankBy.bind(this, "orders")}
      >
        <span>orders</span>
      </button>
      <button
        className={`select-rank ${rankBy === "average" ? "selected" : ""}`}
        onClick={onSelectRankBy.bind(this, "average")}
      >
        <span>average</span>
      </button>

      <button
        className={`select-rank ${rankBy === "joined" ? "selected" : ""}`}
        onClick={onSelectRankBy.bind(this, "joined")}
      >
        <span>joined</span>
      </button>
    </div>
  </div>
);
export const UserListByGMV = ({
  users,
  period,
  rankBy,
  onSelectPeriod,
  onSelectRankBy,
}) => (
  <div className="user-list-by-gmv ">
    <LeaderBoardConsole
      period={period}
      rankBy={rankBy}
      onSelectPeriod={onSelectPeriod}
      onSelectRankBy={onSelectRankBy}
    />
    {users.map((u, index) => (
      <div className="user " key={index}>
        <div className="name">
          <label>#{index + 1}</label>
          <span>
            {u.name}
            {u?.membership?.isActive ? <FontAwesomeIcon icon={faStar} /> : null}
          </span>
        </div>
        <div className={`joined ${rankBy === "joined" ? "ranked" : ""}`}>
          <label>joined</label>
          <span>{moment(u.joined).format("MMM Do, YYYY")}</span>
        </div>
        {u?.membership?.start_date ? (
          <div className={`joined ${rankBy === "joined" ? "ranked" : ""}`}>
            <label>became member</label>
            <span>
              {moment(u.membership.start_date * 1000).format("MMM Do, YYYY")}
            </span>
          </div>
        ) : null}
        <div className={`spend ${rankBy === "spend" ? "ranked" : ""}`}>
          <label>spend</label>
          <span>
            $
            {u.spend.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </span>
        </div>
        <div className={`orders ${rankBy === "orders" ? "ranked" : ""}`}>
          <label>orders</label>
          <span>{u.orders}</span>
        </div>
        <div className={`average ${rankBy === "average" ? "ranked" : ""}`}>
          <label>average</label>
          <span>${u.average.toFixed(0)}</span>
        </div>
        <div className={`address ${rankBy === "address" ? "ranked" : ""}`}>
          <label>address</label>
          <span>{u.address}</span>
        </div>
      </div>
    ))}
  </div>
);
export default UserList;
