import { LOAD_RESTAURANTS, RESET_RESTAURANTS } from '../actionTypes';

const restaurants = (state = [], action) => {
  switch (action.type) {
    case LOAD_RESTAURANTS:
      return [...action.restaurants];
    case RESET_RESTAURANTS:
      return [];
    default:
      return state;
  }
};

export default restaurants;
