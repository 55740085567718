import { useCallback } from 'react';
import { useAsync } from 'react-async';
import { GetDishListByIds, SerializedGood } from 'src/api/dish';
import { getDishKey } from 'src/util/order';
import { Good } from '../screens/tasting_menu/components/dish/PureDishChooser';

export const useSerializedGoodsToGoods = (goods: SerializedGood[]): Good[] | null => {
  const fetch = useCallback(async () => {
    return await GetDishListByIds(goods.map((good) => good.dish));
  }, [goods]);
  const { data } = useAsync({ promiseFn: fetch, watch: goods });

  if (data?.dishes == null) return null;
  if (data.dishes.length === 0) return null;

  const dishes = data.dishes;
  return goods
    .map((good) => {
      const dish = dishes.find((dish) => dish._id === good.dish);
      if (dish == null) return null;

      return {
        ...good,
        dish: dish,
        key: getDishKey(dish, good.selections),
      };
    })
    .filter((good) => good?.dish != null);
};
