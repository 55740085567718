import { text } from "stream/consumers";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  faHome as faPlatform,
  faCar as faDelivery,
  faCrown as faMember,
  faHamburger as faFood,
  faUtensils as faRestaurant,
  faHouseUser as faHome,
  faCarSide as faDistance,
  faTags as faPrice,
  faDotCircle,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import {
  faImage as faReceipt,
  faLightbulb as faExplain,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch, useStore } from "react-redux";
import { RootState } from "src/store";
import styled from "styled-components";
import { LOGGING } from "../..";
import cmr from "../../../../../cmr.jpg";
import meal_for_3 from "../../../../../cmr.jpg";
import meal_for_3_2 from "../../../../../cmr_3_2.jpeg";
import doordash_dashpass from "../../../../../doordash_dashpass.png";
import doordash_regular from "../../../../../doordash_regular.png";
import foodie_member from "../../../../../foodie_member.png";
import foodie_regular from "../../../../../foodie_regular.png";
import { visit } from "../../../../../store/actions";
import toasttab from "../../../../../toasttab.png";
import { PopUp } from "../../../../shared";
import {
  CenteredByColumn,
  NavHeight,
  Centered,
  textTitle,
  Beige,
  card,
  CenteredByColumnLeft,
  text12300,
  actionUnderline,
  BorderGrey,
  actionBlack,
  BackgroundPink,
  Card,
  centered,
  centeredByColumn,
  actionGrey,
} from "../../Shared";
import { CompetitorReceipts } from "./CompetitorReceipts";
import { SkipButton, SeeDetails } from "./Membership30DTrial";
const margin = 30;
const containerMargin = 20;
const containerPadding = 18;
const containerPaddingShort = 12;
const imageHeight = "25vh";
const imageHeightShort = "15vh";
const HeroImageLink =
  "https://i.pinimg.com/originals/26/21/76/2621764263a178d056382e272cd745fd.jpg";
const ContentContainer = styled(CenteredByColumn)`
  z-index: 200;
  width: 100vw;
  /* max-width: 500px; */
  height: 100vh;
  /* min-height: 100%; */
  padding: 20px;
  /* padding-top: ${NavHeight + 20}px; */
  justify-content: flex-start;
  /* position: fixed;
  top: 0px;
  left: 0px; */
  background: white;
  position: fixed;
  top: 0px;
  /* overflow-y: hidden; */
  @media only screen and (min-width: 500px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
    padding-top: ${NavHeight + 10}px;
    /* max-height: 400px; */
    /* top: calc(max(${margin}px, 50vh - 200px)); */
  }
`;
const Nav = styled(Centered)`
  height: ${NavHeight}px;
  width: 100vw;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 3;
  ${textTitle}
  font-size: 20px;
  background-color: white;
  border-bottom: 1px solid ${BorderGrey};
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.16) 0px 0px 5px 0px;
`;
const CloseButton = styled.button`
  height: ${NavHeight}px;
  width: ${NavHeight}px;
  padding: 0px;
  margin: 0px;
  right: 0px;
  top: 0px;
  position: fixed;
  svg {
    font-size: 25px;
  }
`;
export interface CompetitorComparisonPopUpProps {
  onClose: (e) => void;
}
const ExampleContainer = styled(CenteredByColumn)`
  /* background-color: ${Beige}; */
  align-self: stretch;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.16) 0px 0px 5px 0px;
  border: 1px solid ${BorderGrey};
  padding: 10px;
  max-width: 500px;
  position: relative;
  @media only screen and (min-width: 800px) {
    margin: 5px;
    width: 33%;
    justify-content: flex-start;
    align-self: auto;
  }
`;
const Row2 = styled(Centered)`
  width: 100%;
  ${text12300}
  justify-content:flex-start;

  label {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 16px;
  }
  span {
    /* text-transform: capitalize; */
    padding: 0px 5px;
    line-height: 1;
  }
`;
const ExampleCheck = styled(ExampleContainer)`
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.16) 0px 0px 5px 0px;
  border: 1px solid ${BorderGrey};
  padding: 18px;
`;
const PriceBreakdown = styled(ExampleContainer)`
  /* background-color: white; */
  padding: 10px 15px;
  box-shadow: none;
  border: none;
  background-color: ${Beige};
  ${text12300};
  /* box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.16) 0px 0px 5px 0px;
  border: 1px solid ${BorderGrey}; */
`;
const PriceSelect = styled(Centered)`
  justify-content: flex-start;
  margin-bottom: 15px;
`;
export interface SelectProps {
  isSelected: boolean;
}
const Select = styled.button<SelectProps>`
  border: 1px solid rgb(219, 217, 217);
  ${text12300};
  text-transform: uppercase;
  padding: 12px 9px;
  margin-right: 8px;
  background-color: ${(props) =>
    props.isSelected ? "rgb(117, 117, 117)" : "transparent"};
  color: ${(props) => (props.isSelected ? "white" : "black")};
`;

const ExamplePicture = styled.img`
  width: 100%;
  margin-bottom: 15px;
`;
const ReceiptOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Beige};
  opacity: 0.2;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
`;
const ReceiptExplanation = styled(CenteredByColumnLeft)`
  align-self: stretch;
  margin-top: 18px;
`;

const PriceDetails = styled(CenteredByColumnLeft)`
  ${card};
  padding: 15px 18px 0px 18px;
  border: 1px solid ${BorderGrey};
  margin-bottom: 20px;
`;
const Platform = styled.span`
  ${text12300};
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 1.7px;
  line-height: 1.4;
  border-bottom: 1px solid black;
  margin-bottom: 12px;
`;
const PriceContent = styled(CenteredByColumnLeft)`
  background-color: ${Beige};
  align-self: stretch;
  padding: 18px 18px;
  margin-top: 12px;
  position: relative;
  button {
    ${actionUnderline}
    position: absolute;
    right: 18px;
    bottom: 15px;
    padding: 0px;
    text-transform: uppercase;
    font-size: 12px;
    svg {
      /* font-size: 20px; */
      margin-left: 5px;
      display: none;
    }
  }
  span {
    position: relative;
    /* margin-left: 15px; */
    /* padding-left: 20px; */
    svg {
      position: absolute;
      left: 0px;
      top: 5px;
      display: none;
    }
  }
  > span {
    line-height: 1.3;
    margin-bottom: 10px;
    /* border-bottom: 1px solid black; */
  }
  > b {
    line-height: 1.2;
  }
  /* span:before {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: black;
    content: "";
    left: -12px;
    top: 9px;
  } */
`;
const Row = styled(Centered)`
  align-self: stretch;
  justify-content: space-between;
  line-height: 2;
  width: 100%;
  text-transform: capitalize;
  ${text12300}
  label {
    margin-right: 10px;
    font-weight: 500;
    /* text-transform: uppercase; */
  }
  /* span {
    ${text12300}
    
  } */
`;
const FeeRow = styled(Row)``;
const SubTitle = styled(Centered)`
  ${text12300};
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: capitalize;
  svg {
    margin-right: 8px;
    font-size: 14px;
  }
`;
const ExamplePrice = styled(CenteredByColumnLeft)`
  /* margin: 0 0 5px 0; */
  ${text12300}
  align-self: stretch;
  h1 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    margin: 0px;
    /* border-bottom: 1px solid black; */
    /* margin-bottom: 10px; */
    svg {
      margin-right: 7px;
    }
  }
  > span {
    /* border-bottom: 1px solid black; */
    display: none;
  }
  span {
    svg {
      /* font-size: 10px; */
      margin-right: 5px;
    }
  }
  /* label {
    font-weight: 500;
    border-bottom: 1px solid black;
  } */
  b {
    font-weight: 500;
    font-size: 18px;
  }
  > div:last-child {
    margin-bottom: 0px;
  }
`;
export const receiptImages = {
  dine_in: toasttab,
  foodie_member: foodie_member,
  foodie_regular: foodie_regular,
  doordash_dashpass: doordash_dashpass,
  doordash_regular: doordash_regular,
};
const ReceiptContainer = styled(CenteredByColumn)`
  background-color: ${Beige};
  position: fixed;
  top: ${NavHeight}px;
  left: max(15px, calc(50vw - 250px));
  padding: 40px 20px;
  width: calc(100% - 30px);
  max-width: 500px;
  max-height: calc(100vh - ${NavHeight + 5}px);
  button {
    ${actionBlack}
    margin-top: 40px;
  }
`;
const Tagline = styled(Centered)`
  ${textTitle};
  text-transform: capitalize;
  margin: 20px 0px 10px 0px;
  font-weight: 300;
  font-size: 14px;
`;
const Receipt = styled.img`
  width: 100%;
  /* box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.16) 0px 0px 5px 0px; */
  border: 1px solid ${BorderGrey};
  z-index: 0;
`;
const ReceiptTitle = styled(Centered)`
  ${text12300}
  text-transform: uppercase;
  font-weight: 500;
  font-size: 20px;
`;
const ReceiptDetail = ({ name, onClose }) => (
  <PopUp
    isPoppedUp={true}
    componentToDisplay={
      <ReceiptContainer>
        <ReceiptTitle>{name.split("_").join(" ")}</ReceiptTitle>
        <Tagline>real receipt screenshot</Tagline>
        <Receipt src={receiptImages[name]} />
        <button onClick={onClose}>close</button>
      </ReceiptContainer>
    }
    hidePopUp={onClose}
    backgroundColor={"#333"}
  />
);
const ComparisonActions = ({ onClose }) => (
  <Nav>
    <span>price example</span>
    <CloseButton onClick={onClose}>
      <FontAwesomeIcon icon={faTimes} />
    </CloseButton>
  </Nav>
);

const Scrollable = styled(CenteredByColumn)`
  overflow-y: auto;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
`;

export const CompetitorComparison = ({ actionComponent }): ReactElement => {
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const dispatch = useDispatch();
  LOGGING &&
    console.log("CompetitorComparison rendering with: ", {
      currentUser,
      actionComponent,
    });
  const [showReceipt, setShowReceipt] = useState(null);
  // 0:toasttab, 1: foodie member, 2: foodie regular, 3: doordash member, 4: doordash regular
  const onCloseReceipt = (e) => {
    e.preventDefault();
    setShowReceipt(null);
  };
  const onShowReceipt = (receiptName, e) => {
    e.preventDefault();
    setShowReceipt(receiptName);
  };
  useEffect(() => {
    // hook called first render
    // useCallback(async (answer1, answer2, answer3) => {
    //   // @ts-ignore
    window.scrollTo(0, 0);
    dispatch(visit("comparison"));
  }, []); //
  // const handleSubmit = useCallback(async (answer1, answer2, answer3) => {
  //   // @ts-ignore

  // }, []);

  return (
    <ContentContainer>
      <Scrollable>
        {showReceipt ? (
          <ReceiptDetail name={showReceipt} onClose={onCloseReceipt} />
        ) : null}
        <ExampleContainer>
          <ExamplePicture src={HeroImageLink}></ExamplePicture>

          <ExamplePrice>
            <Row>
              <label>dinner</label>
              <span>3 chicken boxes</span>
            </Row>
            <Row>
              <label>restaurant</label>
              <span>Cupertino, CA</span>
            </Row>
            <Row>
              <label>home</label>
              <span>Mountain View, CA</span>
            </Row>
            <Row>
              <label>distance</label>
              <span>5.8 miles</span>
            </Row>
          </ExamplePrice>
        </ExampleContainer>
        <ExampleCheck>
          <ExamplePrice>
            <h1>
              <FontAwesomeIcon icon={faPlatform} />
              Pickup
            </h1>
            <span>You drive 11.6 miles.</span>
            <PriceContent>
              <span>
                <FontAwesomeIcon icon={faPlatform} />
                In Store
              </span>
              {/* <span><FontAwesomeIcon icon={faTotal}/>Total</span>           */}
              <b>$52.10</b>
              <button onClick={onShowReceipt.bind(this, "toasttab")}>
                see receipt
                <FontAwesomeIcon icon={faReceipt} />
              </button>
            </PriceContent>
          </ExamplePrice>
        </ExampleCheck>
        <ExampleCheck>
          <ExamplePrice>
            <h1>
              <FontAwesomeIcon icon={faDelivery} />
              Delivery
            </h1>
            <span>You drive 0 mile.</span>
            <PriceContent>
              <span>
                <FontAwesomeIcon icon={faDelivery} />
                Foodie Member
              </span>
              {/* <span><FontAwesomeIcon icon={faTotal}/>Total</span>           */}
              <b>$53.20</b>
              <button onClick={onShowReceipt.bind(this, "foodie_member")}>
                see receipt
                <FontAwesomeIcon icon={faReceipt} />
              </button>
            </PriceContent>
            <PriceContent>
              <span>
                <FontAwesomeIcon icon={faDelivery} />
                Foodie Regular
              </span>
              {/* <span><FontAwesomeIcon icon={faTotal}/>Total</span>           */}
              <b>$61.64</b>
              <button onClick={onShowReceipt.bind(this, "foodie_regular")}>
                see receipt
                <FontAwesomeIcon icon={faReceipt} />
              </button>
            </PriceContent>

            <PriceContent>
              <span>
                <FontAwesomeIcon icon={faDelivery} />
                Doordash Dashpass
              </span>
              {/* <span><FontAwesomeIcon icon={faTotal}/>Total</span>           */}
              <b>$70.74</b>
              <button onClick={onShowReceipt.bind(this, "doordash_dashpass")}>
                see receipt
                <FontAwesomeIcon icon={faReceipt} />
              </button>
            </PriceContent>

            <PriceContent>
              <span>
                <FontAwesomeIcon icon={faDelivery} />
                Doordash Regular
              </span>
              {/* <span><FontAwesomeIcon icon={faTotal}/>Total</span>           */}
              <b>$84.53</b>
              <button onClick={onShowReceipt.bind(this, "doordash_regular")}>
                see receipt
                <FontAwesomeIcon icon={faReceipt} />
              </button>
            </PriceContent>
          </ExamplePrice>
        </ExampleCheck>
        {actionComponent}
      </Scrollable>
    </ContentContainer>
  );
};

export const CompetitorComparisonPopUp: React.FC<CompetitorComparisonPopUpProps> =
  ({ onClose }: CompetitorComparisonPopUpProps): ReactElement => {
    return (
      <CompetitorComparison
        actionComponent={<ComparisonActions onClose={onClose} />}
      />
    );
  };
const ActionContainer = styled(CenteredByColumn)`
  align-self: stretch;
  position: absolute;
  width: calc(100% - ${containerPadding * 2}px);
  bottom: ${containerPadding}px;
  left: ${containerPadding}px;
  button {
    line-height: 1.2;
  }
`;
const SurveyInteractions = ({ onClick }) => (
  <ActionContainer>
    <SkipButton onClick={onClick.bind(this, 0)}>skip for now</SkipButton>
    <SeeDetails onClick={onClick.bind(this, -1)}>manage membership</SeeDetails>
  </ActionContainer>
);
export interface CompetitorComparisonBlockingProps {
  onClick: () => {};
}
export const CompetitorComparisonBlocking: React.FC<CompetitorComparisonBlockingProps> =
  ({ onClick }: CompetitorComparisonBlockingProps): ReactElement => {
    return (
      <CompetitorComparison
        actionComponent={<SurveyInteractions onClick={onClick} />}
      />
    );
  };
const PriceName = styled.span<PriceBubbleProps>`
  ${text12300}
  text-transform: capitalize;
  line-height: 1.75;
  box-sizing: border-box;
  position: relative;
`;
export interface PriceProps {
  isTransparent?: boolean;
  highlight?: boolean;
}
const Price = styled.span<PriceProps>`
  /* ${textTitle} */
  ${text12300}
  font-size: 14px;
  font-weight: 500;
  position: relative;
  svg {
    display: ${(props) => (props.highlight ? "block" : "none")};
    position: absolute;
    left: -21px;
    top: 7px;
    font-size: 12px;
  }
`;
export interface PriceBubbleProps {
  highlight?: boolean;
  hasBorder?: boolean;
}
const PriceBubble = styled(Centered)<PriceBubbleProps>`
  justify-content: space-between;
  border-bottom: 1px solid
    ${(props) => (props.hasBorder ? `${BorderGrey}` : "transparent")};
  margin-bottom: ${(props) => (props.hasBorder ? "12px" : "0px")};
  align-self: stretch;
`;
const DineInBubble = styled(Centered)`
  span:nth-child(2) {
    ${text12300}
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1.1;
  }
  justify-content: space-between;
  border-bottom: 1px solid ${BorderGrey};
  align-self: stretch;
  padding-bottom: 12px;
  margin-bottom: 12px;
`;

const ComparisonContainer = styled.div`
  ${centeredByColumn}
  width: calc(100vw - ${containerMargin * 2}px);
  max-width: 360px;
  z-index: 1;
  background-color: white;
  justify-content: flex-start;
  padding: ${containerPadding}px;
  padding-top: calc(${imageHeight} + ${2 * containerPadding}px);
  padding-bottom: calc(${containerPadding + 90}px);
  position: relative;
  margin: 0px;
  position: absolute;
  top: ${containerMargin}px;
  overflow-y: scroll;
  @media only screen and (max-height: 700px) {
    padding: ${containerPaddingShort}px;
    padding-top: calc(${imageHeightShort} + ${2 * containerPaddingShort}px);
    padding-bottom: calc(${containerPaddingShort + 70}px);
    top: ${containerPaddingShort}px;
  }
`;
export const priceTable = {
  // "dine-in": 52,
  "foodie member": 53,
  foodie: 62,
  dashpass: 71,
  doordash: 85,
};

const Image = styled.img`
  width: calc(100% - ${containerPadding * 2}px);
  height: ${imageHeight};
  object-fit: cover;
  align-self: stretch;
  position: absolute;
  top: ${containerPadding}px;
  left: ${containerPadding}px;
  @media only screen and (max-height: 700px) {
    height: ${imageHeightShort};
    top: ${containerPaddingShort}px;
    left: ${containerPaddingShort}px;
    width: calc(100% - ${containerPaddingShort * 2}px);
  }
`;

const Overlay = styled(CenteredByColumn)`
  width: calc(100% - ${containerPadding * 2}px);
  height: ${imageHeight};
  position: absolute;
  top: ${containerPadding}px;
  left: ${containerPadding}px;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
  p {
    color: white;
    ${text12300}
    text-transform: uppercase;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.1;
    text-align: center;
  }
  @media only screen and (max-height: 700px) {
    height: ${imageHeightShort};
    top: ${containerPaddingShort}px;
    left: ${containerPaddingShort}px;
    width: calc(100% - ${containerPaddingShort * 2}px);
  }
`;

const TitleContainer = styled(Centered)`
  width: 100%;
  justify-content: space-between;
`;
const SectionTitle = styled.span`
  ${textTitle}
  font-size: 12px;
  line-height: 1.4;
  text-transform: capitalize;
  /* border-bottom: 1px solid black; */
`;
const Quotes = styled(CenteredByColumnLeft)`
  padding: 16px 12px;
  background-color: ${Beige};
  align-self: stretch;
  margin-bottom: 20px;
  margin-top: 12px;
  div:last-child {
    border-bottom: none;
  }
`;
export interface CompetitorComparisonFullScreenProps {
  onClick?: (e) => void;
  onHide?: (e) => void;
}
const HideButton = styled.button`
  ${actionBlack}
`;

const MealContent = styled(Centered)`
  ${text12300}
  align-self: stretch;
  margin-bottom: 12px;
  align-items: flex-start;
  justify-content: space-between;
`;
const AddressContent = styled(MealContent)`
  flex-direction: row;
  justify-content: space-between;
  span {
    font-size: 14px;
  }
`;
const AddressLine = styled(CenteredByColumnLeft)`
  ${text12300}
  align-self: stretch;
  margin: 12px 0px;
  span {
    white-space: nowrap;
    text-transform: capitalize;
    b {
      font-weight: 500;
      margin-left: 5px;
    }
  }
  span:first-child {
    padding-left: 0px;
  }
  span:last-child {
    padding-right: 0px;
    border-right: none;
  }
  flex-wrap: wrap;
  justify-content: flex-start;
`;
const DeliveryTitle = styled(Centered)`
  align-self: stretch;
  margin-bottom: 12px;
  justify-content: space-between;
`;
const DeliveryText = styled.span`
  /* ${textTitle} */
  ${text12300}
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: 1.7px;
  text-transform: uppercase;
`;
const ItemList = styled(CenteredByColumn)`
  align-items: flex-end;
  span {
    white-space: nowrap;
    svg {
      /* display: none; */
      font-size: 10px;
      line-height: 24px;
      margin: 0px 5px;
    }
    text-transform: capitalize;
    /* border-right: 1px solid black; */
  }
`;
const ComparisonText = styled(CenteredByColumn)`
  width: 100%;
  position: relative;
`;
const TransparentContainer = styled.div`
  ${centered}
  padding:0px;
  margin: 0px;
  height: 100vh;
  width: 100vw;
  background-color: transparent;
  position: fixed;
  top: 0px;
  left: 0px;
  overflow: hidden;
`;
const ToggleReceipt = styled.button`
  ${actionUnderline}
  padding: 0px;
  font-size: 12px;
`;
export const PriceExample = ({ turnOnReceipt }) => (
  <ComparisonText>
    <MealContent>
      <SectionTitle> food</SectionTitle>
      <ItemList>
        <span>
          Fried chicken box
          <FontAwesomeIcon icon={faTimes} />1
        </span>
        <span>
          Poached chicken box
          <FontAwesomeIcon icon={faTimes} />1
        </span>
        <span>
          fried tofu box
          <FontAwesomeIcon icon={faTimes} />1
        </span>
      </ItemList>
    </MealContent>
    <TitleContainer>
      <SectionTitle>prices</SectionTitle>
      <ToggleReceipt onClick={turnOnReceipt}>see receipts</ToggleReceipt>
    </TitleContainer>
    <Quotes>
      <DineInBubble>
        <DeliveryText>Dine-in</DeliveryText>
        <span>$52</span>
      </DineInBubble>
      <DeliveryTitle>
        <DeliveryText>Delivery</DeliveryText>
      </DeliveryTitle>
      {Object.keys(priceTable).map((priceName, index) => (
        <PriceBubble highlight={index === 0}>
          <PriceName highlight={index === 0}>{priceName}</PriceName>
          <Price highlight={index === 0}>
            <FontAwesomeIcon icon={faMember} />${priceTable[priceName]}
          </Price>
        </PriceBubble>
      ))}
    </Quotes>
  </ComparisonText>
);
export const CompetitorComparisonFullScreen: React.FC<CompetitorComparisonFullScreenProps> =
  ({ onClick, onHide }: CompetitorComparisonFullScreenProps): ReactElement => {
    const [showReceipt, setShowReceipt] = useState(false);
    const turnOnReceipt = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setShowReceipt(true);
    };
    const turnOffReceipt = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setShowReceipt(false);
    };
    return (
      <TransparentContainer onClick={onHide || onClick.bind(this, 0)}>
        {/* {showReceipt ? (
          <CompetitorReceipts onClose={turnOffReceipt} />
        ) : ( */}
        <ComparisonContainer
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {/* <OverallComparison /> */}
          <Image src={meal_for_3_2}></Image>
          <Overlay>
            <p>price comparison</p>
          </Overlay>
          <PriceExample turnOnReceipt={turnOnReceipt} />
          {onClick ? <SurveyInteractions onClick={onClick} /> : null}
          {onHide ? (
            <ActionContainer>
              <HideButton onClick={onHide}>Close</HideButton>
            </ActionContainer>
          ) : null}
        </ComparisonContainer>
        {/* )} */}
      </TransparentContainer>
    );
  };
