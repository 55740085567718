import "./RestaurantDirectory.scss";
import {
  faStar,
  faSquare as faDoordash,
  faCircle as faUberEats,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCheck,
  faPlus,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  RestaurantLevels,
  CompetitorPlatforms,
  CompetitorMarkupTypes,
  LOGGING,
} from ".";
const icons = {
  doordash: faDoordash,
  ubereats: faUberEats,
};
const RestaurantItem = ({
  restaurant,
  // markup,
  showMarkUp,
  onToggleShowMarkUp,
  // onEditMarkUp,
  onSaveMarkUp,
}) => {
  LOGGING &&
    showMarkUp &&
    console.log("RestaurantItem rendering with: ", { restaurant });
  const { markups, _id, name } = restaurant;
  return (
    <div className="restaurant-item">
      {/* <FontAwesomeIcon icon={faCheck} /> */}
      <Link className="restaurant-name" to={`/restaurant/${_id}`}>
        {name && name.split(",")[0]}{" "}
      </Link>
      <button onClick={onToggleShowMarkUp}>
        {<FontAwesomeIcon icon={showMarkUp ? faChevronUp : faChevronDown} />}{" "}
      </button>
      {/* <div className="restaurant-last-selected-time">
      {restaurant.lastMeal?.windowStart
        ? moment(restaurant.lastMeal.windowStart).format('MM/DD HH:mm')
        : '-'}
    </div> */}
      {CompetitorPlatforms.map((platform) => (
        <div className={`restaurant-markup ${platform}`}>
          <FontAwesomeIcon icon={icons[platform]} />
          <span>{markups[platform]?.amount}</span>
        </div>
      ))}
      {showMarkUp ? (
        <div className="restaurant-markups">
          {CompetitorPlatforms.map((platform) => (
            <div className="platform">
              <label for="vehicle1">
                listed
                <input
                  type="checkbox"
                  name={`${platform}-isAvailable`}
                  onChange={onSaveMarkUp}
                  checked={markups[platform].isAvailable ? "checked" : ""}
                ></input>
              </label>
              <label>
                {" "}
                <span className="platform-name">{platform}</span>
                <input
                  defaultValue={markups[platform]?.amount}
                  type="number"
                  id={`${_id}-${platform}-amount`}
                  // onChange={onEditMarkUp.bind(this, platform)}
                  // onBlur={onSaveMarkUp.bind(this, platform)}
                  onChange={onSaveMarkUp}
                  name={`${platform}-amount`}
                />{" "}
                -{" "}
                <input
                  defaultValue={markups[platform]?.amount2}
                  type="number"
                  id={`${_id}-${platform}-amount2`}
                  onChange={onSaveMarkUp}
                  name={`${platform}-amount2`}
                />
              </label>
              {CompetitorMarkupTypes.map((type, index) => (
                <label>
                  <input
                    type="radio"
                    value={type}
                    id={`${_id}-${platform}-type-${index}`}
                    name={`${platform}-type`}
                    checked={markups[platform].type === type ? "checked" : ""}
                    onChange={onSaveMarkUp}
                  />{" "}
                  {type}{" "}
                </label>
              ))}{" "}
            </div>
          ))}{" "}
        </div>
      ) : null}
      {restaurant.commission > 0 ? (
        <div className="restaurant-commission">
          <FontAwesomeIcon icon={faStar} />
          <span>{restaurant.commission}</span>
        </div>
      ) : null}{" "}
    </div>
  );
};

const RestaurantDirectory = ({
  restaurants,
  showMarkUp,
  onToggleShowMarkUp,
  onSaveMarkUp,
}) => (
  <div className="list restaurants">
    <div className="restaurant-list">
      <div className="legends">
        <div className="legend commission">
          <span>
            <FontAwesomeIcon icon={faStar} />
            commission %
          </span>
        </div>
        <div className="legend doordash">
          <span>
            <FontAwesomeIcon icon={faDoordash} />
            doordash markup %
          </span>
        </div>
        <div className="legend ubereats">
          <span>
            <FontAwesomeIcon icon={faUberEats} />
            ubereats markup %
          </span>
        </div>
      </div>
      {[2, 1, 0].map((level) => (
        <div
          className={`restaurant-section ${RestaurantLevels[level]}`}
          key={level}
        >
          <div className="level-title">
            <span>{`${RestaurantLevels[level]} (${
              restaurants.filter((r) => r.level === level).length
            })`}</span>
          </div>
          <div className="level-restaurants">
            {level === 1 ? (
              <Link
                className="restaurant-item add-restaurant"
                key={-1}
                to={`/restaurant/new`}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Link>
            ) : null}
            {restaurants
              .filter((r) => r.level === level)
              .map((restaurant, index) =>
                restaurant.name && restaurant.name.length > 0 ? (
                  <RestaurantItem
                    restaurant={restaurant}
                    key={index}
                    // markup={markups[restaurant._id]}
                    showMarkUp={showMarkUp === restaurant._id}
                    onToggleShowMarkUp={onToggleShowMarkUp.bind(
                      this,
                      restaurant._id
                    )}
                    onSaveMarkUp={onSaveMarkUp.bind(this, restaurant)}
                  />
                ) : null
              )}{" "}
          </div>
        </div>
      ))}{" "}
    </div>
  </div>
);

export default RestaurantDirectory;
