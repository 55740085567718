import React, { ReactElement, useCallback, useState, useEffect } from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faEmpty } from "@fortawesome/pro-light-svg-icons";
import { faCog, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import toast from "react-hot-toast";
import { useSelector, useDispatch, useStore } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Config } from "src/pages/Config";
import AutoResizeTextarea from "src/screens/shared/AutoResizeTextArea";
import ToggleSwitch from "src/screens/shared/SwitchToggle";
import { RootState } from "src/store";
import styled, { keyframes } from "styled-components";
import { Close, LOGGING, PopUp } from "../..";
import { setPreferences } from "../../../../../store/actions";
import {
  ActionUnderline,
  ActionTransparent,
  Beige,
  BorderGrey,
  card,
  Centered,
  Gold,
  centered,
  CenteredByColumn,
  CenteredByColumnLeft,
  Grey,
  MealHeight,
  text12300,
  Text12300,
  TextTitle,
  textTitle,
  NavHeight,
  LightShadow,
  CardTitle,
  CardSubTitle,
} from "../../Shared";
import { BottomBanner } from "../banners/BottomBanner";
import { HeroHeight } from "../banners/MealHighlights";
import { SkipButton } from "../banners/Membership30DTrial";
import { SubmitButton } from "../giftCards/GiftTemplate";
const shrinkAnimation = keyframes`
  from {
    width: 100%;
    height: 100%;    
    transform: translate(0, 0);
  }
  to {
    width: 0px;
    height: 0px;    
    transform: translate(calc(100vw - 40px), calc(${HeroHeight}vh + ${NavHeight}px + 20px));
  }
`;
const Container = styled(CenteredByColumn)<{ shrink: boolean }>`
  width: 100vw;
  background-color: ${Beige};
  justify-content: flex-start;
  height: 100vh;
  z-index: 201;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  /* padding-top: ${NavHeight}px; */
  animation: ${({ shrink }) => (shrink ? shrinkAnimation : "none")} 0.5s
    forwards;
`;
const Body = styled(CenteredByColumn)`
  width: 100vw;
  height: 100vh;
  justify-content: flex-start;
  overflow-y: auto;
  margin-top: ${NavHeight}px;
  padding-bottom: 200px;
`;

const Title = styled(TextTitle)``;
// add a textbox that collect user's feedback
const Feedback = styled.textarea`
  width: 80%;
  height: 200px;
  border: 1px solid ${BorderGrey};
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px;
  ${text12300}
  text-transform: none;
  &::placeholder {
    text-transform: none;
  }
`;
const SectionContainer = styled(CenteredByColumn)`
  justify-content: flex-start;
  width: 100%;
  padding: 10px 15px 0px 15px;
  position: relative;
  margin-bottom: 10px;
  max-width: 600px;
  > h1 {
    ${textTitle}
    text-align: left;
    font-size: 16px;
    width: 100%;
    margin: 0px 0 5px 0;
    text-transform: none;
  }
  textarea {
    display: flex;
    align-self: stretch;
    margin: 0px;
    flex-grow: 1;
    width: calc(100% - 20px);
  }
  label {
    ${text12300}
    font-size: 10px;
    line-height: 1.2;
    text-transform: none;
    margin-bottom: 10px;
    display: flex;
    align-self: stretch;
    /* width:calc(100% - 20px); */
    text-align: left;
    /* padding-left: 5px; */
  }
  //first child is the title
  & > *:first-child {
    span {
      color: red;
    }
  }
`;
const DayOfWeek = styled(Centered)`
  /* height: 50px; */
  /* background-color: white; */
  /* border: 1px solid ${Grey}; */
  /* border-radius: 5px; */
  padding: 10px 0px 0px 0px;
  /* margin: 5px; */
  cursor: pointer;
  /* justify-content: flex-start; */
  align-self: stretch;
  > div {
    margin-right: 30px;
  }
`;
const Day = styled(Text12300)`
  width: 25%;
  height: 24px;
  line-height: 24px;
  text-align: center;
`;
const MealButton = styled(CenteredByColumn)`
  white-space: nowrap;
  padding: 0px;
  margin: 0px;
  width: 25%;
  > span {
    ${text12300}
    text-align: center;
    width: 100%;
    display: inline-block;
  }
  > label {
    margin: 0px;
    padding: 0px;
    text-align: center;
    width: 100%;
    display: inline-block;
  }
`;

const MealTimes = ({ mealTimes, onToggleMealTime }) => {
  LOGGING && console.log("mealTimes: ", mealTimes);
  return (
    <SectionContainer>
      <h1>When do you need food?</h1>
      <DayOfWeek>
        <Day></Day>
        <MealButton>
          <span>Lunch</span>
          <label>11:30~12:30P</label>
        </MealButton>
        <MealButton>
          <span>Early Dinner</span>
          <label>4:30~5:30P</label>
        </MealButton>
        <MealButton>
          <span>Dinner</span>
          <label>6~7P</label>
        </MealButton>
      </DayOfWeek>
      {mealTimes.map(({ dayOfWeek, lunch, earlyDinner, dinner, index }) => {
        LOGGING &&
          console.log("time: ", { dayOfWeek, lunch, earlyDinner, dinner });
        return (
          <DayOfWeek key={index}>
            <Day>{dayOfWeek.slice(0, 3)}</Day>
            <MealButton key={"lunch"}>
              <ToggleSwitch
                key={`${dayOfWeek}-lunch`}
                checked={lunch}
                onChange={onToggleMealTime}
                dayOfWeek={dayOfWeek}
                mealType="lunch"
              />
            </MealButton>
            <MealButton key={"earlyDinner"}>
              <ToggleSwitch
                key={`${dayOfWeek}-earlyDinner`}
                checked={earlyDinner}
                onChange={onToggleMealTime}
                dayOfWeek={dayOfWeek}
                mealType="earlyDinner"
              />
            </MealButton>
            <MealButton key={"dinner"}>
              <ToggleSwitch
                key={`${dayOfWeek}-dinner`}
                checked={dinner}
                onChange={onToggleMealTime}
                dayOfWeek={dayOfWeek}
                mealType="dinner"
              />
            </MealButton>
          </DayOfWeek>
        );
      })}
    </SectionContainer>
  );
};
const textAreaMinHeight = 70;
const Taste = ({ taste, onSetTaste }) => (
  <SectionContainer>
    <h1>What does your family like?</h1>
    {/* <label>E.g. favorite dishes, cuisines, restaurants. Recommendations.</label> */}
    <AutoResizeTextarea
      // placeholder="Write as much as you can."
      value={taste}
      onChange={onSetTaste}
      minHeight={textAreaMinHeight}
    />
  </SectionContainer>
);

const Others = ({ others, onSetOthers }) => (
  <SectionContainer>
    <h1>Anything else?</h1>
    <AutoResizeTextarea
      value={others}
      onChange={onSetOthers}
      minHeight={textAreaMinHeight}
    />
  </SectionContainer>
);
const Time = ({ time, onSetTime }) => (
  <SectionContainer>
    <h1>When do you want food delivered?</h1>
    <label>E.g. days of week, meals of day, hours of day, etc.</label>
    <AutoResizeTextarea
      // placeholder="Write as much as you can."
      value={time}
      onChange={onSetTime}
      minHeight={textAreaMinHeight}
    />
  </SectionContainer>
);
const defaultValue = false;
const DefaultMealTimes = [
  {
    dayOfWeek: "Sunday",
    lunch: defaultValue,
    earlyDinner: defaultValue,
    dinner: defaultValue,
  },
  {
    dayOfWeek: "Monday",
    lunch: defaultValue,
    earlyDinner: defaultValue,
    dinner: defaultValue,
  },
  {
    dayOfWeek: "Tuesday",
    lunch: defaultValue,
    earlyDinner: defaultValue,
    dinner: defaultValue,
  },
  {
    dayOfWeek: "Wednesday",
    lunch: defaultValue,
    earlyDinner: defaultValue,
    dinner: defaultValue,
  },
  {
    dayOfWeek: "Thursday",
    lunch: defaultValue,
    earlyDinner: defaultValue,
    dinner: defaultValue,
  },
  {
    dayOfWeek: "Friday",
    lunch: defaultValue,
    earlyDinner: defaultValue,
    dinner: defaultValue,
  },
  {
    dayOfWeek: "Saturday",
    lunch: defaultValue,
    earlyDinner: defaultValue,
    dinner: defaultValue,
  },
];
export const Nav = styled(CenteredByColumn)`
  ${card}
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${NavHeight}px;
  z-index: 1;
  span {
    ${textTitle}
    svg {
      margin-right: 10px;
    }
  }
  label {
    ${text12300}
    font-size:9px;
    text-transform: capitalize;
  }
`;
const Header = ({ onSkip, lastUpdated }) => (
  <Nav>
    <span>
      {/* <FontAwesomeIcon icon={faCog} /> */}
      Preferences
    </span>
    {lastUpdated ? (
      <label>
        last edit: {moment(lastUpdated).format("MMM-DD-YYYY h:mmA")}
      </label>
    ) : null}
    <Close onClick={onSkip} />
  </Nav>
);
const Icon = styled(Centered)<{
  top?: Number;
  left?: Number;
  right?: Number;
  bottom?: Number;
}>`
  position: absolute;
  left: ${(props) => (props.left ? `${props.left}px` : "auto")};
  top: ${(props) => (props.top ? `${props.top}px` : "auto")};
  bottom: ${(props) => (props.top ? `${props.bottom}px` : "auto")};
  right: ${(props) => (props.top ? `${props.right}px` : "auto")};
  font-size: 45px;
  transform: rotate(-10deg);
  margin-bottom: 10px;
`;
const CheesyContainer = styled(CenteredByColumn)`
  width: calc(100vw - 30px);
  padding: 15px 25px;
  margin: 15px;
  position: relative;
  border: 2px solid ${Gold};
  background-color: ${Gold};
  max-width: 570px;
`;
const CheesyTitle = styled(CardTitle)`
  font-size: 20px;
  margin: 10px 0px;
`;
const CheesySubTitle = styled(CardSubTitle)`
  margin: 0px;
`;
const CheesyBorder = styled(Centered)`
  flex-direction: column;
  /* padding: 40px 40px 30px 40px; */
  padding: 20px;
  border: 2px solid ${Gold};
  position: relative;
`;
const Ice = styled(Centered)`
  ${text12300}
  padding: 10px;
`;
const ConfirmationContainer = styled(Centered)`
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  ${text12300}
  color: white;
  font-size: 16px;
  font-weight: 400;
`;
const Confirmation = ({ text }) => (
  <ConfirmationContainer>
    <span>{text}...</span>
  </ConfirmationContainer>
);
const Cheesy = () => (
  <CheesyContainer>
    {/* <CheesyBorder> */}
    {/* 🍆🌽🥑🌯🍣🥘🥗🌮 */}
    <Icon left={4} top={-5}>
      🥗
    </Icon>
    {/* <Icon right={5} top={40}>
      🌯
    </Icon> */}
    {/* <Icon left={40} top={60}>
      🍣
    </Icon> */}
    <Icon right={20} top={50}>
      🍝
    </Icon>
    {/* <Icon right={10} top={-20}>
      🧁
    </Icon> */}
    {/* <span>
      We're here to make your life easier. Let us know your preferences so we
      can customize your meals.
    </span> */}
    {/* <CheesySubTitle> get</CheesySubTitle> */}
    <CheesyTitle>get better menus</CheesyTitle>
    <CheesySubTitle>just for your family</CheesySubTitle>
    {/* <Icon>🍽️</Icon> */}
    {/* </CheesyBorder> */}
  </CheesyContainer>
);
export interface TasteAndTimeProps {
  onClose: () => void;
}
export const TasteAndTime: React.FC<TasteAndTimeProps> = ({
  onClose,
}: TasteAndTimeProps): ReactElement => {
  const currentUser = useSelector((state: RootState) => state.currentUser);
  LOGGING && console.log("currentUser: ", currentUser.user);
  const [mealTimes, setMealTimes] = useState(
    currentUser?.user?.preferences?.mealTimes?.length > 0
      ? currentUser?.user?.preferences?.mealTimes
      : DefaultMealTimes
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useStore();
  const [shrink, setShrink] = useState(false);
  const [confirmation, setConfirmation] = useState(null);
  const [taste, setTaste] = useState(
    currentUser?.user?.preferences?.taste || ""
  );
  const [time, setTime] = useState(currentUser?.user?.preferences?.time || "");
  const [others, setOthers] = useState(
    currentUser?.user?.preferences?.others || ""
  );

  const onSkip = () => {
    setConfirmation("Closing");
    setShrink(true);
    if (onClose) {
      setPreferences({})(dispatch, store.getState).then(() => {
        setTimeout(() => {
          onClose();
        }, 1000);
      });
    } else {
      setPreferences({})(dispatch, store.getState).then(() => {
        setConfirmation(null);
        history.push("/");
      });
    }
  };
  const toggleMealTime = (dayOfWeek, mealName) => {
    LOGGING &&
      console.log("toggleMealTime called with: ", { dayOfWeek, mealName });
    const newMealTimes = mealTimes.map((mt) => {
      if (mt.dayOfWeek === dayOfWeek) {
        return {
          ...mt,
          [mealName]: !mt[mealName],
        };
      } else {
        return mt;
      }
    });
    setMealTimes(newMealTimes);
    // setPreference("mealTimes", newMealTimes)(dispatch, store.getState);
  };
  const sendAnswer = async (preferences) => {
    LOGGING && console.log("setAnswer called with preferences: ", preferences);
    setConfirmation("Saving");
    setPreferences(preferences)(dispatch, store.getState).then(() => {
      setConfirmation(false);
      history.push("/");
      // set toast z-index to be above the confirmation modal
      // toast.success("Preferences saved.", {
      //   duration: 2000,
      //   // center the toast in the page
      //   position: "top-center",
      //   style: {
      //     zIndex: 1000,
      //   },
      // });
      // setTimeout(() => {
      //   history.push("/");
      // }, 2000);
    });
  };
  return (
    <Container shrink={shrink}>
      {confirmation && <Confirmation text={confirmation} />}
      <Header
        onSkip={onSkip}
        lastUpdated={currentUser?.user?.preferences?.lastUpdated}
      />
      <Body>
        <Cheesy />
        <Taste taste={taste} onSetTaste={setTaste} />
        {/* <Time time={time} onSetTime={setTime} /> */}
        <MealTimes mealTimes={mealTimes} onToggleMealTime={toggleMealTime} />
        <Others others={others} onSetOthers={setOthers} />
      </Body>

      <BottomBanner
        onClick={() => sendAnswer({ taste, time, others, mealTimes })}
        label="save"
        tagline="You can make edits any time."
      />
    </Container>
  );
};
