import React, {
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { Beige, TextGrey } from "../../Shared";

interface CategoryCarouselV2Props {
  activeCategoryName: string;
  width: number;
  categoryNames: string[]; // Sorted list
  categoryRefs: { [categoryName: string]: RefObject<HTMLElement> };
  onScrollToCategory: (ref: RefObject<HTMLElement>) => void;
}

const TOP_OFFSET = 105;
const HEIGHT = 42;
const MARGIN_BOTTOM = 0;
export const CATEGORY_CAROUSEL_COMPONENT_HEIGHT = HEIGHT + MARGIN_BOTTOM;
export const CATEGORY_CAROUSEL_SCREEN_TOP_OFFSET =
  CATEGORY_CAROUSEL_COMPONENT_HEIGHT + TOP_OFFSET;

const SCROLL_HORIZONTAL_PADDING = 16;

const BorderContainer = styled.div`
  position: -webkit-sticky; /* Safari & IE */
  position: sticky;
  top: ${TOP_OFFSET - 1}px;
  /* top: 0px; */
  z-index: 101;
  margin: 0px ${MARGIN_BOTTOM}px 0px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
  background-color: ${Beige};
  justify-content: flex-start;
`;
type ContentContainerProps = { width: number };
const ContentContainer = styled.div<ContentContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  margin: 0;
  color: black;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  > button:first-child {
    padding-left: ${(props) => (props.width > 500 ? 70 : 10)}px;
  }
`;

type ButtonProps = {
  isSelected: boolean;
};
const Button = styled.button<ButtonProps>`
  white-space: nowrap;
  height: ${HEIGHT}px;
  margin: 0 4px;
  color: ${(props) => (props.isSelected === true ? "black" : `${TextGrey}`)};
  font-weight: 600;
  line-height: 35.2px;
  letter-spacing: 0.6px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 12px;
`;

export const CategoryCarouselV2: React.FC<CategoryCarouselV2Props> = ({
  activeCategoryName,
  categoryNames,
  categoryRefs,
  onScrollToCategory,
  width,
}: CategoryCarouselV2Props): ReactElement => {
  // Scrolling the horizontal categorcy bar to match position of window
  const scrollContainerRef = useRef(null);
  const [categoryButtonRefs, setCategoryButtonRefs] = useState({});
  const setCategoryButtonRefCallback = useCallback(
    (categoryName: string, ref: RefObject<HTMLElement>) => {
      setCategoryButtonRefs((prevData) => {
        return { ...prevData, [categoryName]: ref };
      });
    },
    []
  );

  useEffect(() => {
    scrollContainerRef?.current?.scroll({
      left:
        categoryButtonRefs[activeCategoryName]?.current.offsetLeft -
        SCROLL_HORIZONTAL_PADDING,
      behavior: "smooth",
    });
  }, [activeCategoryName, categoryButtonRefs]);
  // End scrolling

  if (categoryNames.length === 0) return null;

  return (
    <BorderContainer>
      <ContentContainer width={width} ref={scrollContainerRef}>
        {categoryNames.map((categoryName) => {
          return (
            <CategoryButton
              key={categoryName}
              categoryName={categoryName}
              categoryRef={categoryRefs[categoryName]}
              isSelected={categoryName === activeCategoryName}
              onSetCategoryButtonRef={setCategoryButtonRefCallback}
              onScrollToCategory={onScrollToCategory}
            />
          );
        })}
      </ContentContainer>
    </BorderContainer>
  );
};

type CategoryButtonProps = {
  categoryName: string;
  categoryRef: RefObject<HTMLElement>;
  isSelected: boolean;
  onScrollToCategory: (ref: RefObject<HTMLElement>) => void;
  onSetCategoryButtonRef: (
    categoryName: string,
    ref: RefObject<HTMLElement>
  ) => void;
};
const CategoryButton: React.FC<CategoryButtonProps> = ({
  categoryName,
  categoryRef,
  isSelected,
  onSetCategoryButtonRef,
  onScrollToCategory,
}: CategoryButtonProps): ReactElement => {
  const ref = useRef(null);
  const scrollToCategory = useCallback(() => {
    onScrollToCategory(categoryRef);
  }, [categoryRef, onScrollToCategory]);

  useEffect(() => {
    onSetCategoryButtonRef(categoryName, ref);
  }, [categoryName, onSetCategoryButtonRef, ref]);

  return (
    <Button ref={ref} isSelected={isSelected} onClick={scrollToCategory}>
      {categoryName}
    </Button>
  );
};
