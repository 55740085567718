import { faInstagram, faYelp } from "@fortawesome/free-brands-svg-icons";
import { faGlobeAmericas } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import {
  faChevronDown,
  faChevronUp,
  faRoute,
  faHome,
  faReceipt,
  faLink as faShare,
  faChevronCircleLeft,
  // faMapMarkedAlt,
  // faCalendarEdit,
  // faUtensilsAlt,
  // faTrophyAlt,
  // faRoute,
  // faMailBulk,
  // faUsers,
  // faChartBar,
  faCog,
  // faMoneyCheckAlt,
  // faCars,
  // faCalculatorAlt,
  faGift,
  faSignOut,
  faSignIn,
  faUserPlus,
  faMapPin,
  faMapMarker,
} from "@fortawesome/pro-light-svg-icons";
import {
  faArrowToTop,
  faBars,
  faChevronRight,
  faChevronLeft,
  faMarker,
  faMapMarked,
  faMap,
  faMapMarkedAlt,
  faLocation,
  faLocationArrow,
  faMapMarkerAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GoogleApiWrapper } from "google-maps-react";
// import _ from 'lodash';
import moment from "moment-timezone";
import { isMobile } from "react-device-detect";
import Geosuggest from "react-geosuggest";
import { Link } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import { GiftCardEntry } from "src/components/GiftCard";
import { DeliveryWindowDisplay, CutOffDisplay } from "../../../util/time";
import {
  BuyNow,
  Announcement,
  DishInfo,
  DishImage,
  LOGGING,
  CreateReferralCode,
  OrderingWithCode,
  DishVegetarianIcon,
  Back,
  CartThumbnail,
  PopUp,
  Loading,
  getItemNumberInCart,
  NominateYourFavorite,
} from "./";
import { DishListV2 } from "./customers/mealMenu/DishListV2";
import { YelpLink } from "./customers/mealMenu/MealHeader";
import { MealMenuScreen } from "./customers/mealMenu/MealMenuScreen";
import "./MenuTwoChoices.scss";
import { CountDown } from "./customers/mealMenu/CountDown";
import { Yelp } from "./customers/mealMenu/Yelp";
import { FavoriteButton } from "./favorites/FavoriteButton";
export const CategoryCarousel = ({
  isMobile,
  categoryNames,
  categories,
  onGoToCategory,
  categoryNavRef,
  activeIndex,
  isOverflowDesktop,
  onMoveCategoryCarousel,
  isUnderflowDesktop,
}) => (
  <div className={`category-carousel ${isMobile ? "mobile" : "desktop"}`}>
    {isUnderflowDesktop && (
      <button
        onClick={onMoveCategoryCarousel.bind(this, -1)}
        className="move-category prev"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
    )}
    <div className="carousel-container" ref={categoryNavRef}>
      {categoryNames.map((c, i) => {
        LOGGING &&
          console.log("categoryNames looping through:", c, categories[c]);
        return (
          <button
            className={`go-to-category ${activeIndex === i ? "active" : ""}`}
            key={i}
            ref={categories[c].refNav}
            onClick={
              onGoToCategory.bind(this, isMobile, i)
              //   () => {
              //   const categoryRef = isMobile ? categories[c].refMobile : categories[c].refDesktop;
              //   if (!categoryRef.current) {
              //     return;
              //   }
              //   window.scrollTo({
              //     top: categoryRef.current.offsetTop - 149,
              //     left: 0,
              //     behavior: 'smooth',
              //   });
              // }
            }
          >
            {/* <span>{`${c} (${categories[c].count})`}</span> */}
            <span>{`${c}`}</span>
          </button>
        );
      })}
    </div>
    {isOverflowDesktop && (
      <button
        onClick={onMoveCategoryCarousel.bind(this, 1)}
        className="move-category next"
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    )}
  </div>
);

export const DishChoiceMobile = ({
  dish,
  optionIndex,
  optionTotal,
  onChangeQuantity,
  cart,
  onClickCountInCart,
}) => {
  const { imageURL, name, soldQuantity, preOrderedQuantity, _id } = dish;
  // LOGGING &&
  //   console.log('DishChoiceMobile rendering with: ', {
  //     dishId: _id,
  //     goods: cart.goods,
  //   });
  const countInCart = cart?.goods
    ? Object.keys(cart.goods).reduce(
        (a, b) =>
          (String(b).includes(String(_id)) ? cart.goods[b].quantity : 0) + a,
        0
      )
    : 0;

  // LOGGING &&
  //   console.log('DishChoiceMobile rendering with: ', {
  //     countInCart,
  //   });

  return (
    <div className="dish-choice-mobile">
      <div className="dish-content">
        <DishImage
          optionIndex={optionIndex}
          optionTotal={optionTotal}
          imageURL={imageURL}
          name={name}
          countInCart={countInCart}
          onClickCountInCart={onClickCountInCart}
        />
        <div className="dish-text">
          <DishInfo dish={dish} />
          {/* <AddToCart
            quantity={dishInCart && dishInCart.quantity}
            onChangeQuantity={onChangeQuantity}
            soldOut={soldQuantity >= preOrderedQuantity}
          />
           */}
          <BuyNow
            onChangeQuantity={onChangeQuantity}
            soldOut={soldQuantity >= preOrderedQuantity}
            quantity={countInCart}
          />
        </div>
      </div>
    </div>
  );
};

export const DishChoiceDesktop = ({
  dish,
  optionIndex,
  optionTotal,
  onChangeQuantity,
  cart,
  onClickCountInCart,
}) => {
  const { imageURL, name, soldQuantity, preOrderedQuantity, _id } = dish;
  // LOGGING &&
  //   console.log('DishChoiceDesktop rendering with: ', {
  //     dishId: _id,
  //     goods: cart.goods,
  //   });
  const countInCart = cart?.goods
    ? Object.keys(cart.goods).reduce(
        (a, b) =>
          (String(b).includes(String(_id)) ? cart.goods[b].quantity : 0) + a,
        0
      )
    : 0;

  // LOGGING &&
  //   console.log('DishChoiceDesktop rendering with: ', {
  //     countInCart,
  //   });

  return (
    <div className="dish-choice-desktop">
      <DishVegetarianIcon dish={dish} />
      <DishImage
        optionIndex={optionIndex}
        optionTotal={optionTotal}
        imageURL={imageURL}
        name={name}
        countInCart={countInCart}
        onClickCountInCart={onClickCountInCart}
      />
      <DishInfo dish={dish} />
      {/* <AddToCart
        quantity={dishInCart ? dishInCart.quantity : 0}
        onChangeQuantity={onChangeQuantity}
        soldOut={preOrderedQuantity <= soldQuantity}
      /> */}
      <BuyNow
        onChangeQuantity={onChangeQuantity}
        soldOut={soldQuantity >= preOrderedQuantity}
        quantity={countInCart}
      />
    </div>
  );
};

// export const NominateDish = GoogleApiWrapper({
//   apiKey: "AIzaSyBjfTfgJdRah5wXvVj8U7fH4ACoqKry9HI",
//   LoadingContainer: () => null,
// })(
//   ({
//     nomination,
//     nominationErrorMessage,
//     onChangeRestaurantName,
//     onSelectRestaurantAddress,
//     onEdit,
//     onSubmit,
//     isMobile,
//     isLunch,
//   }) => (
//     <div
//       className={`dish-choice-${isMobile ? "mobile" : "desktop"} nominate ${
//         isLunch ? "lunch" : "dinner"
//       }`}
//     >
//       <div className="nominate-form">
//         {isMobile ? null : (
//           <div className="title">
//             <span>Nominate your favorite</span>
//           </div>
//         )}
//         {nominationErrorMessage && (
//           <div style={{ color: "red" }}>{nominationErrorMessage}</div>
//         )}
//         <div className="nominate-field">
//           <label>
//             <span>restaurant</span>
//           </label>
//           <Geosuggest
//             name={`restaurant-address-${Math.random()}`}
//             placeholder={`Type restaurant name...`}
//             initialValue={nomination.restaurant.name}
//             onSuggestSelect={onSelectRestaurantAddress}
//             onChange={onChangeRestaurantName}
//             placeDetailFields={["address_components", "adr_address", "name"]}
//           />
//         </div>
//         <div className="nominate-field">
//           <label>
//             <span>dish</span>
//           </label>
//           <TextareaAutosize
//             onChange={onEdit.bind(this, "dish")}
//             defaultValue={nomination.dish}
//             placeholder="Type dish names..."
//           />
//         </div>
//         <button onClick={onSubmit}>Submit</button>
//       </div>
//     </div>
//   )
// );

export const NominationForm = GoogleApiWrapper({
  apiKey: "AIzaSyBjfTfgJdRah5wXvVj8U7fH4ACoqKry9HI",
  LoadingContainer: () => null,
})(
  ({
    nomination,
    nominationErrorMessage,
    onChangeRestaurantName,
    onSelectRestaurantAddress,
    onSubmit,
    isMobile,
    showHeader,
  }) => (
    <div className="nominate-container">
      {showHeader ? <NominateYourFavorite /> : null}
      <div className="nominate-form in-flow">
        {isMobile ? null : (
          <div className="title">
            <span>Nominate your favorite</span>
          </div>
        )}
        {nominationErrorMessage && (
          <div style={{ color: "red" }}>{nominationErrorMessage}</div>
        )}
        <div className="nominate-field">
          {/* <label>
            <span>restaurant</span>
          </label> */}
          <Geosuggest
            name={`restaurant-address-${Math.random()}`}
            placeholder={`Type restaurant name...`}
            initialValue={nomination?.restaurant?.name}
            onSuggestSelect={onSelectRestaurantAddress}
            onChange={onChangeRestaurantName}
            placeDetailFields={["address_components", "adr_address", "name"]}
          />
          {nomination?.restaurant?.address ? (
            <div className="selected-address">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <span>{nomination?.restaurant?.address}</span>
            </div>
          ) : null}
        </div>
        {/* <div className="nominate-field">
          <label>
            <span>dish</span>
          </label>
          <TextareaAutosize
            onChange={onEdit.bind(this, "dish")}
            defaultValue={nomination.dish}
            placeholder="Type dish names..."
          />
        </div> */}
        <button onClick={onSubmit}>Nominate</button>
      </div>
    </div>
  )
);

export const HomeFromMenu = ({ isAuthenticated }) => (
  <Link to="/" className="home-from-menu">
    <FontAwesomeIcon icon={isAuthenticated ? faChevronLeft : faHome} />
  </Link>
);
export const NavWithDate = ({
  windowStart,
  restaurant,
  mealType,
  onBack,
  cart,
  fromWeekly,
  isToday,
  orderBy,
}) => (
  <nav className="nav-with-date">
    {!windowStart ? null : fromWeekly ? (
      <Back onClick={onBack} />
    ) : (
      <HomeFromMenu />

      // <>
      //   <button className="user" type="button" onClick={onToggleUserMenu}>
      //     <FontAwesomeIcon
      //       icon={currentUser.isAuthenticated ? faUserCircle : faBars}
      //     />
      //   </button>
      //   <PopUp
      //     isPoppedUp={showUserMenu}
      //     componentToDisplay={
      //       currentUser.isAuthenticated ? (
      //         <ul className="user-menu">
      //           <li>
      //             <FontAwesomeIcon icon={faHome} />
      //             <Link to="/">Home</Link>
      //           </li>

      //           <li>
      //             <FontAwesomeIcon icon={faReceipt} />
      //             <Link to="/orders">my orders</Link>
      //           </li>
      //           <li>
      //             <FontAwesomeIcon icon={faGift} />
      //             <Link to="/referralCode">my gift code</Link>
      //           </li>

      //           <li>
      //             <FontAwesomeIcon icon={faCog} />
      //             <Link to="/setting">settings</Link>
      //           </li>

      //           <li>
      //             <FontAwesomeIcon icon={faSignOut} />
      //             <button type="button" onClick={onSignOut}>
      //               sign out
      //             </button>
      //           </li>
      //         </ul>
      //       ) : (
      //         <ul className="user-menu">
      //           <li>
      //             <FontAwesomeIcon icon={faHome} />
      //             <Link to="/">Weekly Menu</Link>
      //           </li>
      //           <li>
      //             <FontAwesomeIcon icon={faUserPlus} />
      //             <Link to="/signup">sign up</Link>
      //           </li>
      //           <li>
      //             <FontAwesomeIcon icon={faSignIn} />
      //             <Link to="/signin">sign in</Link>
      //           </li>
      //         </ul>
      //       )
      //     }
      //     hidePopUp={onToggleUserMenu}
      //     backgroundColor="transparent"
      //   />
      // </>
    )}

    {/* <Yelp restaurant={restaurant} isMobile={false} /> */}
    {windowStart ? (
      <>
        <div className="day-menu-date">
          {/* <FontAwesomeIcon icon={faDate} /> */}

          {restaurant?.name || ""}

          {/* <FavoriteButton
            restaurantId={restaurant?._id}
            restaurantName={restaurant?.name}
            position={{ top: 0, left: 280 }}
            isNav={true}
            isMobile={false}
          /> */}
        </div>

        <div className="view-meal">
          <span className="meal-type">{`${
            mealType === "earlyDinner" ? "early dinner" : mealType
          }, ${moment(windowStart)
            .tz("America/Los_Angeles")
            .format("ddd, MMM Do")}`}</span>
          {/* <span className="delivery-window">{`${isLunch ? 'Lunch' : 'Dinner'}`}</span> */}
        </div>
        {isToday ? (
          <CountDown deadline={orderBy} />
        ) : (
          <div className="delivery-window">
            <div className="order-by">{`Order by ${CutOffDisplay[mealType]}`}</div>
            <div className="receive">{`Receive ${DeliveryWindowDisplay[mealType]}`}</div>
          </div>
        )}
        {/* {distance?<div className="delivery-distance">
        <FontAwesomeIcon icon={faRoute}/>
          <span>{`${distance.toFixed(1)} mi`}</span>
        </div>:null} */}
      </>
    ) : null}
    {/* {isMobile ? null : (
      <CartThumbnail cart={cart} numberOfItems={getItemNumberInCart(cart)} />
    )} */}
  </nav>
);
const RestaurantCard = ({
  restaurant,
  isMobile,
  showRestaurantDetails,
  onToggleRestaurantDetails,
  windowStart,
  isLunch,
  onToggleShowMenuLink,
  isUserGroupMeal,
}) => {
  LOGGING &&
    console.log("restaurant card rendering with:", {
      isUserGroupMeal,
      isMobile,
      windowStart,
      onToggleShowMenuLink,
    });
  return (
    <div
      className={`${
        isMobile ? "mobile" : "dish-choice-desktop"
      } restaurant-card`}
    >
      <div className="restaurant-content">
        <div className="restaurant-border">
          <div className="restaurant-name">
            <button
              className="share-menu mobile"
              onClick={onToggleShowMenuLink}
            >
              <FontAwesomeIcon icon={faShare} />
            </button>
            <div
              className={`${
                showRestaurantDetails ? "show-details" : "hide-details"
              }`}
            >
              {/* <FontAwesomeIcon icon={faRestaurant} /> */}
              <div className="name">{restaurant?.name?.split(",")[0]}</div>
              {isMobile && !isUserGroupMeal && restaurant?.minOrderValue > 0 ? (
                <div className="min">
                  Minimum Order: ${restaurant?.minOrderValue}
                </div>
              ) : null}
            </div>

            {/* {isMobile ? null : <span className="price-disclaimer">{PriceDisclaimer}</span>} */}
            {/* {restaurant?.address && !isMobile ? (
              <div className="restaurant-address">{restaurant?.address}</div>
            ) : null} */}
            {isMobile ? (
              <button
                className="show-restaurant-details"
                onClick={onToggleRestaurantDetails}
              >
                <FontAwesomeIcon
                  icon={showRestaurantDetails ? faChevronUp : faChevronDown}
                />
              </button>
            ) : null}
          </div>
          {showRestaurantDetails ? null : (
            <span className="price-disclaimer">{`Order by ${
              isLunch ? "10:30A" : "4:30P"
            }, Receive ${isLunch ? "11:30A~12:30P" : "6-7P"}`}</span>
          )}
          {isMobile ? null : (
            <span
              className={
                !isUserGroupMeal && restaurant?.minOrderValue > 0
                  ? "show-min-order-value"
                  : "hide-warning"
              }
            >
              This restaurant has an order minimum of $
              {restaurant?.minOrderValue}.
            </span>
          )}
          {showRestaurantDetails ? (
            <div className="restaurant-details">
              {/* {restaurant?.address && isMobile ? (
                <div className="restaurant-address">{restaurant?.address}</div>
              ) : null} */}

              {restaurant?.contact?.yelpUrl ||
              restaurant?.contact?.websiteUrl ||
              restaurant?.contact?.instagramUrl ? (
                <div className="external-links">
                  {restaurant?.contact?.websiteUrl ? (
                    <Link
                      className="website-link"
                      to={{ pathname: restaurant?.contact?.websiteUrl }}
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faGlobeAmericas} />
                      <span>website</span>
                    </Link>
                  ) : null}
                  {restaurant?.contact?.yelpUrl ? (
                    <Link
                      className="yelp-link"
                      to={{ pathname: restaurant?.contact?.yelpUrl }}
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faYelp} />
                      <span>yelp</span>
                    </Link>
                  ) : null}
                  {restaurant?.contact?.instagramUrl ? (
                    <Link
                      className="instagram-link"
                      to={{ pathname: restaurant?.contact?.instagramUrl }}
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                      <span>Instagram</span>
                    </Link>
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : null}
          <button className="share-menu desktop" onClick={onToggleShowMenuLink}>
            <FontAwesomeIcon icon={faShare} />
            {/* <span>Share Menu</span> */}
          </button>
        </div>
      </div>
    </div>
  );
};
const MobileCategoryOfDishes = ({
  cart,
  categoryName,
  hasMultiCategories,
  categories,
  categoryKey,
  isExistCategoryName,
  categoryDishes,
  onChangeQuantity,
  onEditDishComment,
  onClickCountInCart,
}) => (
  <div
    className="category-of-dishes"
    key={categoryName}
    ref={hasMultiCategories ? categories[categoryKey].refMobile : null}
  >
    {!(categoryName === "others" && !isExistCategoryName) && (
      <div className="category-name">
        <span>{`${categoryName}`}</span>
      </div>
    )}
    {categoryDishes.map((dish, i) => (
      <DishChoiceMobile
        dish={dish}
        key={dish._id}
        optionIndex={i}
        optionTotal={categories[categoryName].count}
        onChangeQuantity={onChangeQuantity.bind(this, dish)}
        onEditComment={onEditDishComment.bind(this, dish._id)}
        cart={cart}
        onClickCountInCart={onClickCountInCart}
      />
    ))}
  </div>
);

const MobileList = ({
  dishes,
  categories,
  categoryNames,
  isExistCategoryName,
  cart,
  isLunch,
  onBackToTop,
  onChangeQuantity,
  onClickCountInCart,
  nomination,
  nominationErrorMessage,
  onEditNomination,
  onSubmitNomination,
  onSelectRestaurantAddress,
  onEditDishComment,
  onChangeRestaurantName,
}) => (
  <div className={`dish-choice-list-mobile ${isLunch ? "lunch" : "dinner"}`}>
    {categoryNames.map((categoryName, categoryIndex) => {
      // should handle '' & undefined as same
      const categoryDishes = dishes.filter((dish) =>
        categoryName === "others"
          ? dish.categoryName === "" || dish.categoryName === undefined
          : dish.categoryName === categoryName
      );
      const hasMultiCategories = !(
        categoryName === "others" && !isExistCategoryName
      );
      const categoryKey = categoryName !== undefined ? categoryName : "others";
      LOGGING &&
        console.log("looping through: ", {
          categories,
          categoryKey,
          categoryDishes,
          dishes,
        });
      return (
        <MobileCategoryOfDishes
          cart={cart}
          key={categoryIndex}
          categoryName={categoryName}
          hasMultiCategories={hasMultiCategories}
          categories={categories}
          categoryKey={categoryKey}
          isExistCategoryName={isExistCategoryName}
          categoryDishes={categoryDishes}
          onChangeQuantity={onChangeQuantity}
          onEditDishComment={onEditDishComment}
          onClickCountInCart={onClickCountInCart}
        />
      );
    })}
    {/* {nomination ? (
      <div className="category-of-dishes">
        <div className="category-name">
          <span>Nominate your favorite</span>
        </div>
        <NominateDish
          nomination={nomination}
          nominationErrorMessage={nominationErrorMessage}
          onEdit={onEditNomination}
          onSubmit={onSubmitNomination}
          onChangeRestaurantName={onChangeRestaurantName}
          onSelectRestaurantAddress={onSelectRestaurantAddress}
          isMobile={true}
        />
      </div>
    ) : null} */}
  </div>
);

const DesktopList = ({
  restaurant,
  dishes,
  categories,
  categoryNames,
  isExistCategoryName,
  cart,
  isLunch,
  onBackToTop,
  onChangeQuantity,
  onClickCountInCart,
  nomination,
  nominationErrorMessage,
  onEditNomination,
  onSubmitNomination,
  onSelectRestaurantAddress,
  onEditDishComment,
  onChangeRestaurantName,
  isAnonymous,
  onToggleShowMenuLink,
  isUserGroupMeal,
}) => (
  <div
    className={`dish-choice-list-desktop ${
      categoryNames.length > 1 ? "has-categories" : "no-categories"
    }`}
  >
    <div className="menu-type">
      <span className="menu-is-lunch">{`${
        isLunch ? "lunch" : "dinner"
      } menu`}</span>
      {isAnonymous ? null : (
        <Link to="/orders" className="my-orders">
          <span>my orders</span>
        </Link>
      )}
    </div>
    <RestaurantCard
      restaurant={restaurant}
      isLunch={isLunch}
      isMobile={false}
      showRestaurantDetails={true}
      onToggleShowMenuLink={onToggleShowMenuLink}
      isUserGroupMeal={isUserGroupMeal}
    />

    {categoryNames.map((categoryName, categoryIndex) => {
      // should handle '' & undefined as same
      const categoryDishes = dishes.filter((dish) =>
        categoryName === "others"
          ? dish.categoryName === "" || dish.categoryName === undefined
          : dish.categoryName === categoryName
      );
      const hasMultiCategories = !(
        categoryName === "others" && !isExistCategoryName
      );
      const categoryKey = categoryName !== undefined ? categoryName : "others";
      return (
        <>
          {!(categoryName === "others" && !isExistCategoryName) && (
            <div
              className={`dish-choice-desktop category-name-card ${
                isLunch ? "lunch" : "dinner"
              }`}
              ref={
                hasMultiCategories ? categories[categoryKey].refDesktop : null
              }
            >
              {/* <span>{`${categoryName} (${categories[categoryName].count})`}</span> */}
              <span>{`${categoryName}`}</span>
            </div>
          )}
          {categoryDishes.map((dish, i) => (
            <DishChoiceDesktop
              dish={dish}
              key={dish._id}
              optionIndex={i}
              optionTotal={categoryDishes.length}
              onChangeQuantity={onChangeQuantity.bind(this, dish)}
              onEditComment={onEditDishComment.bind(this, dish._id)}
              cart={cart}
              onClickCountInCart={onClickCountInCart}
            />
          ))}
        </>
      );
    })}
    {/* {nomination ? (
      <NominateDish
        isLunch={isLunch}
        nomination={nomination}
        nominationErrorMessage={nominationErrorMessage}
        onEdit={onEditNomination}
        onSubmit={onSubmitNomination}
        onChangeRestaurantName={onChangeRestaurantName}
        onSelectRestaurantAddress={onSelectRestaurantAddress}
        isMobile={false}
      />
    ) : null} */}
  </div>
);
export const DishList = ({
  loading,
  windowStart,
  dishes,
  restaurant,
  isLunch,
  cart,
  onChangeQuantity,
  onEditDishComment,
  nomination,
  nominationErrorMessage,
  onEditNomination,
  onSubmitNomination,
  onChangeRestaurantName,
  onSelectRestaurantAddress,
  showRestaurantDetailsMobile,
  onToggleMobileRestaurantDetails,
  isAnonymous,
  onBackToTop,
  onClickCountInCart,
  isUserGroupMeal,
  selectedOption,
  onGoToCategory,
  activeIndex,
  categories,
  categoryNavMobileRef,
  categoryNavDesktopRef,
  isOverflowDesktop,
  isUnderflowDesktop,
  onMoveCategoryCarousel,
  onToggleShowMenuLink,
}) => {
  // keep categories
  // const categories = useMemo(() => DishesToCategories(dishes), [dishes]);

  // get category names
  let categoryNames = [];
  dishes.forEach((dish) => {
    const categoryName = dish.categoryName || "others";
    if (!categoryNames.includes(categoryName)) {
      categoryNames.push(categoryName);
    }
  });

  categoryNames = categoryNames.includes("others")
    ? [...categoryNames.filter((c) => c !== "others"), "others"]
    : [...categoryNames];
  const isExistCategoryName = categoryNames.length > 1;
  LOGGING &&
    console.log("DishList rendering with: ", {
      categories,
      activeIndex,
      categoryNames,
      isExistCategoryName,
      dishes,
      windowStart,
    });
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <RestaurantCard
            restaurant={restaurant}
            isLunch={isLunch}
            isMobile={true}
            windowStart={windowStart}
            showRestaurantDetails={showRestaurantDetailsMobile}
            onToggleRestaurantDetails={onToggleMobileRestaurantDetails}
            onToggleShowMenuLink={onToggleShowMenuLink}
            isUserGroupMeal={isUserGroupMeal}
          />

          {categoryNames.length > 1 && (
            <>
              <CategoryCarousel
                categories={categories}
                categoryNames={categoryNames}
                isMobile={false}
                activeIndex={activeIndex}
                categoryNavRef={categoryNavDesktopRef}
                onGoToCategory={onGoToCategory}
                isUnderflowDesktop={isUnderflowDesktop}
                isOverflowDesktop={isOverflowDesktop}
                onMoveCategoryCarousel={onMoveCategoryCarousel}
              />
              <CategoryCarousel
                categories={categories}
                categoryNames={categoryNames}
                isMobile={true}
                activeIndex={activeIndex}
                categoryNavRef={categoryNavMobileRef}
                onGoToCategory={onGoToCategory}
              />
            </>
          )}
          <MobileList
            dishes={dishes}
            categories={categories}
            categoryNames={categoryNames}
            isExistCategoryName={isExistCategoryName}
            cart={cart}
            isLunch={isLunch}
            onBackToTop={onBackToTop}
            onChangeQuantity={onChangeQuantity}
            onClickCountInCart={onClickCountInCart}
            nomination={nomination}
            nominationErrorMessage={nominationErrorMessage}
            onEditNomination={onEditNomination}
            onSubmitNomination={onSubmitNomination}
            onSelectRestaurantAddress={onSelectRestaurantAddress}
            onEditDishComment={onEditDishComment}
            onChangeRestaurantName={onChangeRestaurantName}
            onToggleShowMenuLink={onToggleShowMenuLink}
          />
          <DesktopList
            isUserGroupMeal={isUserGroupMeal}
            restaurant={restaurant}
            isAnonymous={isAnonymous}
            dishes={dishes}
            categories={categories}
            categoryNames={categoryNames}
            isExistCategoryName={isExistCategoryName}
            cart={cart}
            isLunch={isLunch}
            onBackToTop={onBackToTop}
            onChangeQuantity={onChangeQuantity}
            onClickCountInCart={onClickCountInCart}
            nomination={nomination}
            nominationErrorMessage={nominationErrorMessage}
            onEditNomination={onEditNomination}
            onSubmitNomination={onSubmitNomination}
            onSelectRestaurantAddress={onSelectRestaurantAddress}
            onEditDishComment={onEditDishComment}
            onChangeRestaurantName={onChangeRestaurantName}
            onToggleShowMenuLink={onToggleShowMenuLink}
          />
        </>
      )}
    </>
  );
};

const MenuTwoChoices = ({
  hasAnnouncement,
  dishes,
  loading,
  distance,
  categories,
  restaurant,
  isSelection,
  cart,
  onEditDishComment,
  onOpenDishDetails,
  nomination,
  nominationErrorMessage,
  onEditNomination,
  onSubmitNomination,
  onSelectRestaurantAddress,
  onChangeResstaurantName,
  referralCode,
  hideNav,
  showAnnouncementDetails,
  onToggleShowAnnoucementDetails,
  friends,
  onAddFriend,
  onEditFriend,
  onEditPhone,
  onDeleteFriend,
  annoucementRef,
  onSendInvitation,
  errorMessage,
  referrals,
  hasGiftCard,
  showReferralCodeAnnouncement,
  onAddDishToCart,
  onAddGoodsToCart,
  onRemoveDishFromCart,
  trendy,
  isMember,
  isAuthenticated,
  onStartMembership,
  onShowAuth,
  mealId,
  mealGroup,
  promo,
  windowStart,
  mealType,
  onShowGroupOrder,
  onOpenInvite,
  isNotOrganizer,
  addingDish,
  onFlashCartPeek,
  onShowCartOnMeal,
}) => {
  // LOGGING &&
  //   console.log(
  //     "MenuTwoChoices rendering with isNotOrganizer:",
  //     isNotOrganizer
  //   );
  return (
    <div
      className={`menu-two-dishes ${
        hasAnnouncement || referralCode || showReferralCodeAnnouncement
          ? "has-announcement"
          : "no-announcement"
      }
      ${hasGiftCard ? "has-gift-card" : "no-gift-card"}
      ${hideNav ? "hide-nav" : ""} ${
        showAnnouncementDetails ? "show-announcement-details" : ""
      }`}
    >
      {hasAnnouncement ? (
        <Announcement
          hideNav={hideNav}
          showDetails={showAnnouncementDetails}
          onToggleShowDetails={onToggleShowAnnoucementDetails}
          onAddFriend={onAddFriend}
          onEditPhone={onEditPhone}
          onEditFriend={onEditFriend}
          onDeleteFriend={onDeleteFriend}
          friends={friends}
          annoucementRef={annoucementRef}
          onSendInvitation={onSendInvitation}
          errorMessage={errorMessage}
          referrals={referrals}
        />
      ) : null}
      {/* {hasGiftCard ? <GiftCardEntry /> : null} */}
      {/* {referralCode ? <OrderingWithCode code={referralCode} /> : null} */}
      {/* {showReferralCodeAnnouncement ? <CreateReferralCode /> : null} */}
      {restaurant ? (
        <MealMenuScreen
          // bestSellers={bestSellers.sort((a, b) => (a.ordered > b.ordered ? -1 : 1))}
          restaurant={restaurant}
          distance={distance}
          dishes={dishes}
          loading={loading}
          onOpenDishDetails={onOpenDishDetails}
          onAddDishToCart={onAddDishToCart}
          onAddGoodsToCart={onAddGoodsToCart}
          onRemoveDishFromCart={onRemoveDishFromCart}
          trendy={trendy}
          isMember={isMember}
          onStartMembership={onStartMembership.bind(this, "MealMenuScreen")}
          isAuthenticated={isAuthenticated}
          onShowAuth={onShowAuth}
          mealId={mealId}
          mealGroup={mealGroup}
          promo={promo}
          windowStart={windowStart}
          isMobile={isMobile}
          isExpress={mealType.includes("early")}
          onShowGroupOrder={onShowGroupOrder}
          onOpenInvite={onOpenInvite}
          groupOrderLeader={cart?.user}
          isNotOrganizer={isNotOrganizer}
          addingDish={addingDish}
          onFlashCartPeek={onFlashCartPeek}
          onShowCartOnMeal={onShowCartOnMeal}
        />
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default MenuTwoChoices;
