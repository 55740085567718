import { PURGE } from 'redux-persist';
import { LOAD_ROUTES } from '../actionTypes';

const routeList = (state = {}, action) => {
  switch (action.type) {
    case LOAD_ROUTES:
      return [...action.routes];
    case PURGE:
      return [];
    default:
      return state;
  }
};

export default routeList;
