import { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LOGGING, Loading, ReferralCode, Back } from ".";
import { generateReferralCode } from "../../store/actions";

class PageReferralCode extends Component {
  constructor() {
    super();
    LOGGING && console.log("PageReferralCode constructor");
    this.state = {
      loading: true,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose(e) {
    e.preventDefault();
    this.props.history.push("/");
  }

  componentDidMount() {
    const { currentUser } = this.props;
    LOGGING &&
      console.log(
        "PageReferralCode componentdidmount with currentUser:",
        currentUser
      );
    if (currentUser?.user && !currentUser?.user?.socialInviteCode) {
      this.props.generateReferralCode().then(() => {
        this.setState({ loading: false });
      });
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    const { currentUser } = this.props;
    const { loading } = this.state;
    LOGGING &&
      console.log("PageReferralCode rendering with", {
        currentUser,
      });
    const { user, isAuthenticated } = currentUser;
    if (!isAuthenticated || !user) {
      return (
        <Redirect
          to={{
            pathname: "/signin",
            state: { next: "/referralCode" },
          }}
        />
      );
    }

    const { socialInviteCode, totalSpend } = user;

    return (
      <div className="page">
        <Back onClick={this.handleClose} />
        {loading ? (
          <Loading />
        ) : (
          <ReferralCode
            name={user.firstName}
            socialInviteCode={socialInviteCode}
            totalSpend={totalSpend}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log("PageReferralCode got redux state:", state);
  return {
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  generateReferralCode,
})(PageReferralCode);
