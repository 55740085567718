import React, { ReactElement, useCallback } from "react";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import { MealStatus, MealSerialized } from "src/api/meals";
import styled from "styled-components";
import { FoodieCard } from "../FoodieCard";
import { ALLCAPTEXT, Centered, CenteredByColumnLeft } from "../Shared";

const Container = styled(CenteredByColumnLeft)`
  width: 100%;
  padding: 10px 20px;
`;
export interface MealsForRestaurantProps {
  meals: any[];
}

function mealStatusTexts(mealStatus: MealStatus): {
  actionRequired: string | null;
  ctaLabel: string;
} {
  switch (mealStatus) {
    case MealStatus.Unknown:
    case MealStatus.Draft:
    case MealStatus.Complete:
      return { actionRequired: null, ctaLabel: null };
    case MealStatus.NotConfirmed:
      return {
        actionRequired: "Confirm availability and menu",
        ctaLabel: "Review Menu",
      };
    case MealStatus.Confirmed:
      return { actionRequired: null, ctaLabel: "Edit Menu" };
  }
}
const Past = styled(CenteredByColumnLeft)`
  label {
    ${ALLCAPTEXT}
    font-size: 20px;
    margin-bottom: 10px;
  }
  margin-bottom: 20px;
`;
const Future = styled(Past)``;
const Meal = styled(Centered)`
  line-height: 1.5;
`;
export const MealsForRestaurant: React.FC<MealsForRestaurantProps> = ({
  meals,
}): ReactElement => {
  // const mealMomentDate = moment(meal.windowStart).tz('America/Los_Angeles');
  // const relativeDateStr = mealMomentDate.fromNow();
  // const mealDateStr = mealMomentDate.format('ddd, MMM D h:mmA');

  // const { actionRequired, ctaLabel } = mealStatusTexts(meal.mealStatus);
  const futureMeals = meals.filter((meal) =>
    moment(meal.windowStart).isAfter(moment())
  );
  const pastMeals = meals.filter((meal) =>
    moment(meal.windowStart).isBefore(moment())
  );
  const history = useHistory();
  // const reviewMealMenu = useCallback(() => {
  //   history.push(`/restaurant-meals/${meal._id}/menu`);
  // }, [history, meal._id]);
  return (
    <Container>
      <Future>
        <label>upcoming deliveries</label>
        {futureMeals.map((meal) => (
          <Meal>
            {`${moment(meal.windowStart).format("ddd MMM-D-YYYY")} ${moment(
              meal.windowStart
            ).format("h:mm")}-${moment(meal.windowStart)
              .add(1, "h")
              .format("h:mmA")}`}{" "}
          </Meal>
        ))}
      </Future>
      <Past>
        <label>recent deliveries</label>
        {pastMeals.map((meal) => (
          <Meal>
            {`${moment(meal.windowStart).format("ddd MMM-D-YYYY")} ${moment(
              meal.windowStart
            ).format("h:mm")}-${moment(meal.windowStart)
              .add(1, "h")
              .format("h:mmA")}`}{" "}
          </Meal>
        ))}
      </Past>
    </Container>
  );
};
