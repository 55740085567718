import React, { ReactElement, useCallback } from 'react';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { MealStatus, MealSerialized } from 'src/api/meals';
import styled from 'styled-components';
import { FoodieCard } from '../FoodieCard';

export interface RestaurantMealCardProps {
  meal: MealSerialized;
}

const Content = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
`;

const CardRow = styled.div`
  margin: 4px 0;
`;

const ActionCardRow = styled(CardRow)`
  color: red;
`;

function mealStatusTexts(mealStatus: MealStatus): {
  actionRequired: string | null;
  ctaLabel: string;
} {
  switch (mealStatus) {
    case MealStatus.Unknown:
    case MealStatus.Draft:
    case MealStatus.Complete:
      return { actionRequired: null, ctaLabel: null };
    case MealStatus.NotConfirmed:
      return { actionRequired: 'Confirm availability and menu', ctaLabel: 'Review Menu' };
    case MealStatus.Confirmed:
      return { actionRequired: null, ctaLabel: 'Edit Menu' };
  }
}

export const RestaurantMealCard: React.FC<RestaurantMealCardProps> = ({ meal }): ReactElement => {
  const mealMomentDate = moment(meal.windowStart).tz('America/Los_Angeles');
  const relativeDateStr = mealMomentDate.fromNow();
  const mealDateStr = mealMomentDate.format('ddd, MMM D h:mmA');

  const { actionRequired, ctaLabel } = mealStatusTexts(meal.mealStatus);

  const history = useHistory();
  const reviewMealMenu = useCallback(() => {
    history.push(`/restaurant-meals/${meal._id}/menu`);
  }, [history, meal._id]);
  return (
    <FoodieCard
      title={`Meal ${relativeDateStr}`}
      content={
        <Content>
          <CardRow>
            <b>Date:</b> {mealDateStr}
          </CardRow>
          {actionRequired != null ? (
            <ActionCardRow>
              <b>Action Required:</b> {actionRequired}
            </ActionCardRow>
          ) : null}
        </Content>
      }
      ctaLabel={ctaLabel}
      ctaCallback={reviewMealMenu}
      maxWidth={'300px'}
    />
  );
};
