import React from 'react';
import './AddToCart.scss';
import { faPlus, faMinus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const BuyNow = ({ onChangeQuantity, soldOut, quantity }) => (
  <button className="add-to-cart" disabled={soldOut} onClick={onChangeQuantity.bind(this, 1)}>
    {/* add to cart */}
    {soldOut ? 'sold out' : `buy ${quantity > 0 ? 'more' : ''}`}
  </button>
);
const AddToCart = ({ quantity, onChangeQuantity, soldOut, minQuantity = 0, maxQuantity = 10 }) =>
  quantity > 0 ? (
    <div className="add-to-cart">
      <div className="quantity-control">
        <button onClick={onChangeQuantity.bind(this, -1)} disabled={quantity <= minQuantity}>
          <FontAwesomeIcon icon={faMinus} />
        </button>
        <div className={`quantity ${quantity > 0 ? 'non-empty' : ''}`}>{quantity}</div>
        <button onClick={onChangeQuantity.bind(this, 1)} disabled={quantity >= maxQuantity}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
    </div>
  ) : (
    <BuyNow onChangeQuantity={onChangeQuantity} soldOut={soldOut} quantity={quantity} />
  );

export { AddToCart, BuyNow };
