import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { GetMealLink } from "src/constants";
import { RootState } from "src/store";
import styled from "styled-components";
import { Title } from "./Contact";
import { PayButton } from "./PayButton";
import { centered, Centered, NavHeight } from "../../Shared";
import { BreakPoint } from "../mealMenu/MealHeader";
export const NavContainer = styled(Centered)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${NavHeight}px;
  background-color: white;
  z-index: 2;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0 70px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: ${BreakPoint}px) {
    padding: 0 20px;
    justify-content: center;
  }
`;
export const CheckoutTitle = styled(Title)`
  display: none;
  @media screen and (max-width: ${BreakPoint}px) {
    display: flex;
  }
`;
const BackButton = styled.button`
  ${centered}
  height: ${NavHeight}px;
  svg {
    margin-right: 10px;
  }
  @media screen and (max-width: ${BreakPoint}px) {
    position: fixed;
    top: 0px;
    left: 0px;
    width: ${NavHeight}px;
    > span {
      display: none;
    }
    svg {
      font-size: 24px;
    }
  }
`;
export const CheckoutNav = ({
  onSubmit,
  onSubmitFreeMeal,
  paying,
  initializing,
  isCartValid,
}) => {
  const cart = useSelector((state: RootState) => state.cart.payLoad);
  const { meal } = cart;
  const mealLink = GetMealLink(meal);
  const onBack = () => {
    window.location.href = `/${mealLink}`;
  };
  return (
    <NavContainer>
      <BackButton onClick={onBack}>
        <FontAwesomeIcon icon={faChevronLeft} />
        <span>back to menu</span>
      </BackButton>
      <CheckoutTitle>Check out</CheckoutTitle>
      <PayButton
        onSubmit={cart?.due > 0 ? onSubmit : onSubmitFreeMeal}
        paying={paying}
        initializing={initializing}
        isCartValid={isCartValid}
      />
    </NavContainer>
  );
};
