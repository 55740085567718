import React from "react"; // eslint-disable-line no-unused-vars
import "./Disclaimers.scss";
import { Back } from "../shared";
const Privacy = ({ onClose }) => (
  <div className="list disclaimer">
    <div className="list-title">
      <Back onClick={onClose} />
      <span>Privacy Policy</span>
    </div>
    <span className="timestamp">Effective Date: Nov 9, 2020</span>
    <div className="section">
      <p>
        Abbeybot Technologies, Inc ("us", "we", or "our") operates the
        www.foodie.earth website (hereinafter referred to as the "Service").
      </p>
      <p>
        This page informs you of our policies regarding the collection, use and
        disclosure of personal data when you use our Service and the choices you
        have associated with that data.
      </p>
      <p>
        We use your data to provide and improve the Service. By using the
        Service, you agree to the collection and use of information in
        accordance with this policy. Unless otherwise defined in this Privacy
        Policy, the terms used in this Privacy Policy have the same meanings as
        in our Terms and Conditions, accessible from www.foodie.earth
      </p>
    </div>
    <div className="section">
      <span className="section-title">Definitions</span>
      <div className="section2">
        <span className="section-title">Service</span>

        <p>
          Service is the www.foodie.earth website operated by Abbeybot
          Technologies, Inc
        </p>
      </div>
      <div className="section2">
        <span className="section-title">Personal Data</span>

        <p>
          Personal Data means data about a living individual who can be
          identified from those data (or from those and other information either
          in our possession or likely to come into our possession).
        </p>
      </div>
      <div className="section2">
        <span className="section-title">Usage Data</span>
        <p>
          Usage Data is data collected automatically either generated by the use
          of the Service or from the Service infrastructure itself (for example,
          the duration of a page visit).
        </p>
      </div>
      <div className="section2">
        <span className="section-title">Cookies</span>
        <p>
          Cookies are small files stored on your device (computer or mobile
          device).
        </p>
      </div>
      <div className="section2">
        <span className="section-title">Data Controller</span>
        <p>
          Data Controller means the natural or legal person who (either alone or
          jointly or in common with other persons) determines the purposes for
          which and the manner in which any personal information are, or are to
          be, processed.
        </p>

        <p>
          For the purpose of this Privacy Policy, we are a Data Controller of
          your Personal Data.
        </p>
      </div>
      <div className="section2">
        <span className="section-title">
          Data Processors (or Service Providers)
        </span>
        <p>
          Data Processor (or Service Provider) means any natural or legal person
          who processes the data on behalf of the Data Controller.
        </p>

        <p>
          We may use the services of various Service Providers in order to
          process your data more effectively.
        </p>
      </div>
      <div className="section2">
        <span className="section-title">Data Subject (or User)</span>
        <p>
          Data Subject is any living individual who is using our Service and is
          the subject of Personal Data.
        </p>
      </div>
    </div>
    <div className="section">
      <span className="section-title">Information Collection and Use</span>

      <p>
        We collect several different types of information for various purposes
        to provide and improve our Service to you.
      </p>
      <div className="section2">
        <span className="section-title">Types of Data Collected </span>
        <div className="section3">
          <span className="section-title">Personal Data</span>

          <p>
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you ("Personal Data"). Personally identifiable information
            may include, but is not limited to:
            <ul>
              <li>Email address</li>
              <li>First name and last name</li>
              <li>Phone number</li>
              <li>Address, State, Province, ZIP/Postal code, City</li>
              <li>Cookies and Usage Data</li>
            </ul>
            We may use your Personal Data to contact you with newsletters,
            marketing or promotional materials and other information that may be
            of interest to you. You may opt out of receiving any, or all, of
            these communications from us by following the unsubscribe link or
            instructions provided in any email we send or by contacting us.
          </p>
        </div>
        <div className="section3">
          <span className="section-title">Usage Data</span>
          <p>
            We may also collect information on how the Service is accessed and
            used ("Usage Data"). This Usage Data may include information such as
            your computer's Internet Protocol address (e.g. IP address), browser
            type, browser version, the pages of our Service that you visit, the
            time and date of your visit, the time spent on those pages, unique
            device identifiers and other diagnostic data.
          </p>
        </div>
        <div className="section3">
          <span className="section-title">Location Data</span>
          <p>
            We may use and store information about your location if you give us
            permission to do so ("Location Data"). We use this data to provide
            features of our Service, to improve and customise our Service.
          </p>
          <p>
            You can enable or disable location services when you use our Service
            at any time by way of your device settings.
          </p>
        </div>
        <div className="section3">
          <span className="section-title">Tracking & Cookies Data</span>

          <p>
            We use cookies and similar tracking technologies to track the
            activity on our Service and we hold certain information.
          </p>
          <p>
            Cookies are files with a small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Other tracking technologies are
            also used such as beacons, tags and scripts to collect and track
            information and to improve and analyse our Service.
          </p>
          <p>
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our Service.
          </p>
          <p>
            Examples of Cookies we use:
            <ul>
              <li>
                Session Cookies. We use Session Cookies to operate our Service.
              </li>
              <li>
                Preference Cookies. We use Preference Cookies to remember your
                preferences and various settings.
              </li>
              <li>
                Security Cookies. We use Security Cookies for security purposes.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
    <div className="section">
      <span className="section-title">Use of Data</span>

      <p>
        Abbeybot Technologies, Inc uses the collected data for various purposes:
        <ul>
          <li>To provide and maintain our Service</li>
          <li>To notify you about changes to our Service</li>
          <li>
            To allow you to participate in interactive features of our Service
            when you choose to do so
          </li>
          <li>To provide customer support</li>
          <li>
            To gather analysis or valuable information so that we can improve
            our Service
          </li>
          <li>To monitor the usage of our Service</li>
          <li>To detect, prevent and address technical issues</li>
          <li>
            To provide you with news, special offers and general information
            about other goods, services and events which we offer that are
            similar to those that you have already purchased or enquired about
            unless you have opted not to receive such information
          </li>
        </ul>
      </p>
    </div>
    <div className="section">
      <span className="section-title">
        Legal Basis for Processing Personal Data under the General Data
        Protection Regulation (GDPR)
      </span>

      <p>
        If you are from the European Economic Area (EEA), Abbeybot Technologies,
        Inc legal basis for collecting and using the personal information
        described in this Privacy Policy depends on the Personal Data we collect
        and the specific context in which we collect it.
      </p>

      <p>
        Abbeybot Technologies, Inc may process your Personal Data because:
        <ul>
          <li>We need to perform a contract with you</li>
          <li>You have given us permission to do so</li>
          <li>
            The processing is in our legitimate interests and it is not
            overridden by your rights
          </li>
          <li>For payment processing purposes</li>
          <li>To comply with the law</li>
        </ul>
      </p>
    </div>
    <div className="section">
      <span className="section-title">Retention of Data</span>

      <p>
        Abbeybot Technologies, Inc will retain your Personal Data only for as
        long as is necessary for the purposes set out in this Privacy Policy. We
        will retain and use your Personal Data to the extent necessary to comply
        with our legal obligations (for example, if we are required to retain
        your data to comply with applicable laws), resolve disputes and enforce
        our legal agreements and policies.
      </p>

      <p>
        Abbeybot Technologies, Inc will also retain Usage Data for internal
        analysis purposes. Usage Data is generally retained for a shorter period
        of time, except when this data is used to strengthen the security or to
        improve the functionality of our Service, or we are legally obligated to
        retain this data for longer periods.
      </p>
    </div>
    <div className="section">
      <span className="section-title">Transfer of Data</span>

      <p>
        Your information, including Personal Data, may be transferred to — and
        maintained on — computers located outside of your state, province,
        country or other governmental jurisdiction where the data protection
        laws may differ from those of your jurisdiction.
      </p>

      <p>
        If you are located outside United States and choose to provide
        information to us, please note that we transfer the data, including
        Personal Data, to United States and process it there.
      </p>

      <p>
        Your consent to this Privacy Policy followed by your submission of such
        information represents your agreement to that transfer.
      </p>

      <p>
        Abbeybot Technologies, Inc will take all the steps reasonably necessary
        to ensure that your data is treated securely and in accordance with this
        Privacy Policy and no transfer of your Personal Data will take place to
        an organisation or a country unless there are adequate controls in place
        including the security of your data and other personal information.
      </p>
    </div>
    <div className="section">
      <span className="section-title">Disclosure of Data</span>

      <div className="section2">
        <span className="section-title">Business Transaction</span>

        <p>
          If Abbeybot Technologies, Inc is involved in a merger, acquisition or
          asset sale, your Personal Data may be transferred. We will provide
          notice before your Personal Data is transferred and becomes subject to
          a different Privacy Policy.
        </p>
      </div>
      <div className="section2">
        <span className="section-title">Disclosure for Law Enforcement</span>

        <p>
          Under certain circumstances, Abbeybot Technologies, Inc may be
          required to disclose your Personal Data if required to do so by law or
          in response to valid requests by public authorities (e.g. a court or a
          government agency).
        </p>
      </div>
      <div className="section2">
        <span className="section-title">Legal Requirements</span>

        <p>
          Abbeybot Technologies, Inc may disclose your Personal Data in the good
          faith belief that such action is necessary to:
          <ul>
            <li>To comply with a legal obligation</li>
            <li>
              To protect and defend the rights or property of Abbeybot
              Technologies, Inc
            </li>
            <li>
              To prevent or investigate possible wrongdoing in connection with
              the Service
            </li>
            <li>
              To protect the personal safety of users of the Service or the
              public
            </li>
            <li>To protect against legal liability</li>
          </ul>
        </p>
      </div>
    </div>
    <div className="section">
      <span className="section-title">Security of Data</span>

      <p>
        The security of your data is important to us but remember that no method
        of transmission over the Internet or method of electronic storage is
        100% secure. While we strive to use commercially acceptable means to
        protect your Personal Data, we cannot guarantee its absolute security.
      </p>
    </div>
    <div className="section">
      <span className="section-title">
        Our Policy on "Do Not Track" Signals under the California Online
        Protection Act (CalOPPA)
      </span>

      <p>
        We do not support Do Not Track ("DNT"). Do Not Track is a preference you
        can set in your web browser to inform websites that you do not want to
        be tracked.
      </p>

      <p>
        You can enable or disable Do Not Track by visiting the Preferences or
        Settings page of your web browser.
      </p>
    </div>
    <div className="section">
      <span className="section-title">
        Your Data Protection Rights under the General Data Protection Regulation
        (GDPR)
      </span>

      <p>
        If you are a resident of the European Economic Area (EEA), you have
        certain data protection rights. Abbeybot Technologies, Inc aims to take
        reasonable steps to allow you to correct, amend, delete or limit the use
        of your Personal Data.
      </p>

      <p>
        If you wish to be informed about what Personal Data we hold about you
        and if you want it to be removed from our systems, please contact us.
      </p>

      <p>
        In certain circumstances, you have the following data protection rights:
        <ul>
          <li>
            The right to access, update or delete the information we have on
            you. Whenever made possible, you can access, update or request
            deletion of your Personal Data directly within your account settings
            section. If you are unable to perform these actions yourself, please
            contact us to assist you.
          </li>

          <li>
            The right of rectification. You have the right to have your
            information rectified if that information is inaccurate or
            incomplete.
          </li>

          <li>
            The right to object. You have the right to object to our processing
            of your Personal Data.
          </li>

          <li>
            The right of restriction. You have the right to request that we
            restrict the processing of your personal information.
          </li>

          <li>
            The right to data portability. You have the right to be provided
            with a copy of the information we have on you in a structured,
            machine-readable and commonly used format.
          </li>

          <li>
            The right to withdraw consent. You also have the right to withdraw
            your consent at any time where Abbeybot Technologies, Inc relied on
            your consent to process your personal information.
          </li>
        </ul>
      </p>

      <p>
        Please note that we may ask you to verify your identity before
        responding to such requests.
      </p>

      <p>
        You have the right to complain to a Data Protection Authority about our
        collection and use of your Personal Data. For more information, please
        contact your local data protection authority in the European Economic
        Area (EEA).
      </p>
    </div>
    <div className="section">
      <span className="section-title">Service Providers</span>

      <p>
        We may employ third party companies and individuals to facilitate our
        Service ("Service Providers"), provide the Service on our behalf,
        perform Service- related services or assist us in analysing how our
        Service is used.
      </p>

      <p>
        These third parties have access to your Personal Data only to perform
        these tasks on our behalf and are obligated not to disclose or use it
        for any other purpose.
      </p>
      <div className="section2">
        <span className="section-title">Payments</span>

        <p>
          We may provide paid products and/or services within the Service. In
          that case, we use third-party services for payment processing (e.g.
          payment processors).
        </p>

        <p>
          We will not store or collect your payment card details. That
          information is provided directly to our third-party payment processors
          whose use of your personal information is governed by their Privacy
          Policy. These payment processors adhere to the standards set by
          PCI-DSS as managed by the PCI Security Standards Council, which is a
          joint effort of brands like Visa, MasterCard, American Express and
          Discover. PCI-DSS requirements help ensure the secure handling of
          payment information.
        </p>

        <p>
          The payment processors we work with is stripe, their Privacy Policy
          can be viewed at{" "}
          <a href="https://stripe.com/privacy">https://stripe.com/privacy</a>.
        </p>
      </div>
    </div>
    <div className="section">
      <span className="section-title">Links to Other Sites</span>

      <p>
        Our Service may contain links to other sites that are not operated by
        us. If you click a third party link, you will be directed to that third
        party's site. We strongly advise you to review the Privacy Policy of
        every site you visit.
      </p>

      <p>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </p>
    </div>
    <div className="section">
      <span className="section-title">Children's Privacy</span>

      <p>
        Our Service does not address anyone under the age of 18 ("Children").
      </p>

      <p>
        We do not knowingly collect personally identifiable information from
        anyone under the age of 18. If you are a parent or guardian and you are
        aware that your Child has provided us with Personal Data, please contact
        us. If we become aware that we have collected Personal Data from
        children without verification of parental consent, we take steps to
        remove that information from our servers.
      </p>
    </div>
    <div className="section">
      <span className="section-title">Changes to This Privacy Policy</span>
      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
      </p>

      <p>
        We will let you know via email and/or a prominent notice on our Service,
        prior to the change becoming effective and update the "effective date"
        at the top of this Privacy Policy.
      </p>

      <p>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>
    </div>
    <div className="section">
      <span className="section-title">Contact Us</span>
      <p>
        If you have any questions about this Privacy Policy, please contact us.
      </p>
    </div>
  </div>
);
const Terms = ({ onClose }) => (
  <div className="list disclaimer">
    <div className="list-title">
      <Back onClick={onClose} />
      <span>Terms of Service</span>
    </div>
    <span className="timestamp">Effective Date: Nov 9, 2020</span>
    <div className="section">
      <p id="terms-header">
        Please read these Terms of Service ("Terms", "Terms of Service")
        carefully before using the https://www.foodie.earth website (the
        "Service") operated by Abbeybot Technologies, Inc. ("us", "we", or
        "our"). Your access to and use of the Service is conditioned upon your
        acceptance of and compliance with these Terms. These Terms apply to all
        visitors, users and others who wish to access or use the Service. By
        accessing or using the Service you agree to be bound by these Terms. If
        you disagree with any part of the terms then you do not have permission
        to access the Service.
      </p>
    </div>

    <div className="section">
      <span className="section-title">Communications</span>
      <p>
        By creating an Account on our service, you agree to subscribe to
        newsletters, marketing or promotional materials and other information we
        may send. However, you may opt out of receiving any, or all, of these
        communications from us by following the unsubscribe link or instructions
        provided in any email we send.
      </p>
    </div>

    <div className="section">
      <span className="section-title">Purchases</span>
      <p>
        The purchases are all made by your explicit consent. You purchase a meal
        or multiple meals by adding them on the website explicitly. Payment is
        made at delivery time. There is no hidden subscription or automatic
        subscription.
      </p>

      <p>
        If you wish to purchase any product or service made available through
        the Service ("Purchase"), you may be asked to supply certain information
        relevant to your Purchase including, without limitation, your credit
        card number, the expiration date of your credit card, your billing
        address, and your shipping information.
      </p>

      <p>
        You represent and warrant that: (i) you have the legal right to use any
        credit card(s) or other payment method(s) in connection with any
        Purchase; and that (ii) the information you supply to us is true,
        correct and complete.
      </p>

      <p>
        The service may employ the use of third party services for the purpose
        of facilitating payment and the completion of Purchases. By submitting
        your information, you grant us the right to provide the information to
        these third parties subject to our Privacy Policy.
      </p>

      <p>
        We reserve the right to refuse or cancel your order at any time for
        reasons including but not limited to: product or service availability,
        errors in the description or price of the product or service, error in
        your order or other reasons.
      </p>

      <p>
        We reserve the right to refuse or cancel your order if fraud or an
        unauthorized or illegal transaction is suspected.
      </p>
    </div>

    <div className="section">
      <span className="section-title">
        Availability, Errors and Inaccuracies
      </span>
      <p>
        We are constantly updating product and service offerings on the Service.
        We may experience delays in updating information on the Service and in
        our advertising on other web sites. The information found on the Service
        may contain errors or inaccuracies and may not be complete or current.
        Products or services may be mispriced, described inaccurately, or
        unavailable on the Service and we cannot guarantee the accuracy or
        completeness of any information found on the Service.
      </p>

      <p>
        We therefore reserve the right to change or update information and to
        correct errors, inaccuracies, or omissions at any time without prior
        notice.
      </p>
    </div>

    <div className="section">
      <span className="section-title">
        Contests, Sweepstakes and Promotions
      </span>
      <p>
        Any contests, sweepstakes or other promotions (collectively,
        "Promotions") made available through the Service may be governed by
        rules that are separate from these Terms &amp; Conditions. If you
        participate in any Promotions, please review the applicable rules as
        well as our Privacy Policy. If the rules for a Promotion conflict with
        these Terms of Service, the Promotion rules will apply.
      </p>
    </div>

    <div className="section">
      <span className="section-title">Content</span>

      <p>
        Our Service allows you to post, link, store, share and otherwise make
        available certain information, text, graphics, videos, or other material
        ("Content"). You are responsible for the Content that you post on or
        through the Service, including its legality, reliability, and
        appropriateness.
      </p>

      <p>
        By posting Content on or through the Service, You represent and warrant
        that: (i) the Content is yours (you own it) and/or you have the right to
        use it and the right to grant us the rights and license as provided in
        these Terms, and (ii) that the posting of your Content on or through the
        Service does not violate the privacy rights, publicity rights,
        copyrights, contract rights or any other rights of any person or entity.
        We reserve the right to terminate the account of anyone found to be
        infringing on a copyright.
      </p>

      <p>
        You retain any and all of your rights to any Content you submit, post or
        display on or through the Service and you are responsible for protecting
        those rights. We take no responsibility and assume no liability for
        Content you or any third party posts on or through the Service. However,
        by posting Content using the Service you grant us the right and license
        to use, modify, perform, display, reproduce, and distribute such Content
        on and through the Service.
      </p>

      <p>
        Abbeybot Technologies, Inc. has the right but not the obligation to
        monitor and edit all Content provided by users.
      </p>

      <p>
        In addition, Content found on or through this Service are the property
        of Abbeybot Technologies, Inc. or used with permission. You may not
        distribute, modify, transmit, reuse, download, repost, copy, or use said
        Content, whether in whole or in part, for commercial purposes or for
        personal gain, without express advance written permission from us.
      </p>
    </div>

    <div className="section">
      <span className="section-title">Accounts</span>

      <p>
        When you create an account with us, you guarantee that you are above the
        age of 18, and that the information you provide us is accurate,
        complete, and current at all times. Inaccurate, incomplete, or obsolete
        information may result in the immediate termination of your account on
        the Service.
      </p>

      <p>
        You are responsible for maintaining the confidentiality of your account
        and password, including but not limited to the restriction of access to
        your computer and/or account. You agree to accept responsibility for any
        and all activities or actions that occur under your account and/or
        password, whether your password is with our Service or a third-party
        service. You must notify us immediately upon becoming aware of any
        breach of security or unauthorized use of your account.
      </p>

      <p>
        You may not use as a username the name of another person or entity or
        that is not lawfully available for use, a name or trademark that is
        subject to any rights of another person or entity other than you,
        without appropriate authorization. You may not use as a username any
        name that is offensive, vulgar or obscene.
      </p>

      <p>
        We reserve the right to refuse service, terminate accounts, remove or
        edit content, or cancel orders in our sole discretion.
      </p>
    </div>

    <div className="section">
      <span className="section-title">Intellectual Property </span>
      <p>
        The Service and its original content (excluding Content provided by
        users), features and functionality are and will remain the exclusive
        property of Abbeybot Technologies, Inc. and its licensors. The Service
        is protected by copyright, trademark, and other laws of both the United
        States and foreign countries. Our trademarks and trade dress may not be
        used in connection with any product or service without the prior written
        consent of Abbeybot Technologies, Inc..
      </p>
    </div>

    <div className="section">
      <span className="section-title">Links To Other Web Sites</span>
      <p>
        Our Service may contain links to third party web sites or services that
        are not owned or controlled by Abbeybot Technologies, Inc.
      </p>

      <p>
        Abbeybot Technologies, Inc. has no control over, and assumes no
        responsibility for the content, privacy policies, or practices of any
        third party web sites or services. We do not warrant the offerings of
        any of these entities/individuals or their websites.
      </p>

      <p>
        You acknowledge and agree that Abbeybot Technologies, Inc. shall not be
        responsible or liable, directly or indirectly, for any damage or loss
        caused or alleged to be caused by or in connection with use of or
        reliance on any such content, goods or services available on or through
        any such third party web sites or services.
      </p>

      <p>
        We strongly advise you to read the terms and conditions and privacy
        policies of any third party web sites or services that you visit.
      </p>
    </div>

    <div className="section">
      <span className="section-title">Termination</span>
      <p>
        We may terminate or suspend your account and bar access to the Service
        immediately, without prior notice or liability, under our sole
        discretion, for any reason whatsoever and without limitation, including
        but not limited to a breach of the Terms.
      </p>

      <p>
        If you wish to terminate your account, you may simply discontinue using
        the Service.
      </p>

      <p>
        All provisions of the Terms which by their nature should survive
        termination shall survive termination, including, without limitation,
        ownership provisions, warranty disclaimers, indemnity and limitations of
        liability.
      </p>
    </div>

    <div className="section">
      <span className="section-title">Indemnification</span>
      <p>
        You agree to defend, indemnify and hold harmless Abbeybot Technologies,
        Inc. and its licensee and licensors, and their employees, contractors,
        agents, officers and directors, from and against any and all claims,
        damages, obligations, losses, liabilities, costs or debt, and expenses
        (including but not limited to attorney's fees), resulting from or
        arising out of a) your use and access of the Service, by you or any
        person using your account and password; b) a breach of these Terms, or
        c) Content posted on the Service.
      </p>
    </div>

    <div className="section">
      <span className="section-title">Limitation Of Liability</span>
      <p>
        In no event shall Abbeybot Technologies, Inc., nor its directors,
        employees, partners, agents, suppliers, or affiliates, be liable for any
        indirect, incidental, special, consequential or punitive damages,
        including without limitation, loss of profits, data, use, goodwill, or
        other intangible losses, resulting from (i) your access to or use of or
        inability to access or use the Service; (ii) any conduct or content of
        any third party on the Service; (iii) any content obtained from the
        Service; and (iv) unauthorized access, use or alteration of your
        transmissions or content, whether based on warranty, contract, tort
        (including negligence) or any other legal theory, whether or not we have
        been informed of the possibility of such damage, and even if a remedy
        set forth herein is found to have failed of its essential purpose.
      </p>
    </div>

    <div className="section">
      <span className="section-title">Disclaimer</span>
      <p>
        Your use of the Service is at your sole risk. The Service is provided on
        an "AS IS" and "AS AVAILABLE" basis. The Service is provided without
        warranties of any kind, whether express or implied, including, but not
        limited to, implied warranties of merchantability, fitness for a
        particular purpose, non- infringement or course of performance.
      </p>

      <p>
        Abbeybot Technologies, Inc. its subsidiaries, affiliates, and its
        licensors do not warrant that a) the Service will function
        uninterrupted, secure or available at any particular time or location;
        b) any errors or defects will be corrected; c) the Service is free of
        viruses or other harmful components; or d) the results of using the
        Service will meet your requirements.
      </p>

      <p>We reseve the right to refuse our service to anyone.</p>
    </div>

    <div className="section">
      <span className="section-title">Exclusions</span>
      <p>
        Some jurisdictions do not allow the exclusion of certain warranties or
        the exclusion or limitation of liability for consequential or incidental
        damages, so the limitations above may not apply to you.
      </p>
    </div>

    <div className="section">
      <span className="section-title">Governing Law</span>
      <p>
        These Terms shall be governed and construed in accordance with the laws
        of California, United States, without regard to its conflict of law
        provisions.
      </p>

      <p>
        Our failure to enforce any right or provision of these Terms will not be
        considered a waiver of those rights. If any provision of these Terms is
        held to be invalid or unenforceable by a court, the remaining provisions
        of these Terms will remain in effect. These Terms constitute the entire
        agreement between us regarding our Service, and supersede and replace
        any prior agreements we might have had between us regarding the Service.
      </p>
    </div>
    <div className="section">
      <span className="section-title">Changes</span>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material we will provide at least 30
        days notice prior to any new terms taking effect. What constitutes a
        material change will be determined at our sole discretion.
      </p>
      <p>
        By continuing to access or use our Service after any revisions become
        effective, you agree to be bound by the revised terms. If you do not
        agree to the new terms, you are no longer authorized to use the Service.
      </p>
    </div>
    <div className="section">
      <span className="section-title">Contact Us</span>
      <p>
        If you have any questions about these Terms, please contact us at
        info@foodie.earth.
      </p>
    </div>
  </div>
);

export { Privacy, Terms };
