import moment from 'moment-timezone';
import { LOAD_CART, RESET_CART } from '../actionTypes';

const cart = (
  state = {
    payLoad: { goods: {}, sum: 0, paymentIntentId: null },
    loadedAt: -1,
  },
  action
) => {
  // LOGGING && console.log("cart reducer got action: ", action);
  switch (action.type) {
    case LOAD_CART:
      return {
        // ID should be sticky to prevent race conditions removing it,
        // since it is only set when the order is created.
        payLoad: { ...action.cart, _id: action.cart._id ?? state.payLoad._id },
        loadedAt: moment(),
      };
    case RESET_CART:
      return {
        payLoad: {
          goods: {},
          sum: 0,
          paymentIntentId: null,
        },
        loadedAt: -1,
      };
    default:
      return state;
  }
};

export default cart;
