import { LOAD_CARTS, RESET_CARTS } from "../actionTypes";

const carts = (state = [], action) => {
  switch (action.type) {
    case LOAD_CARTS:
      return [...action.carts];
    case RESET_CARTS:
      return [];
    default:
      return state;
  }
};

export default carts;
