import React, { ReactElement, useCallback, useEffect } from "react";
import { useSelector, useDispatch, useStore } from "react-redux";
import { Redirect } from "react-router-dom";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { GetPromoMeals } from "src/api/meals";
import { FoodieEvent, FoodieSource } from "src/api/tracker";
import { STANDARD_CONTENT_WIDTH } from "src/constants/ui";
import { useTrackEventEffect } from "src/hooks/useTrackEventEffect";
import { RootState } from "src/store";
import styled from "styled-components";
import { Loading, LOGGING } from "./components";
import { MyReferralCodeEventContainer } from "./components/referrals/MyReferralCodeEventContainer";
import { MyReferralsTableContainer } from "./components/referrals/MyReferralsTableContainer";
import { SimpleNavBar2 } from "./components/SimpleNavBar2";
export interface PageReferralEventProps {}
const REFERRAL_PROMO_CODE = "REF10";

const ContentContainer = styled.div`
  max-width: ${STANDARD_CONTENT_WIDTH}px;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

export const PageReferralEvent: React.FC<PageReferralEventProps> =
  (): ReactElement => {
    const currentUser = useSelector((state: any) => state.currentUser);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
      if (!currentUser.isAuthenticated) {
        history.push({
          pathname: "/auth",
          state: {
            title: "To Claim Your Treats🧋",
            next: location.pathname,
          },
        });
      }
    }, [history, currentUser.isAuthenticated]);

    return <MyReferralCodeEventContainer />;
  };
