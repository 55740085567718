import { LOAD_PICKUPRESTAURANTS, RESET_PICKUPRESTAURANTS } from '../actionTypes';

const pickUpRestaurants = (state = {}, action) => {
  switch (action.type) {
    case LOAD_PICKUPRESTAURANTS:
      return { ...action.restaurants };
    case RESET_PICKUPRESTAURANTS:
      return {};
    default:
      return state;
  }
};

export default pickUpRestaurants;
