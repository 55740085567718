import React, { ReactElement, useCallback, useState } from "react";
import {
  faFilter as faCuisine,
  faSearch,
  faHeart as faFavorite,
  faList,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { faChevronUp, faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { keyframes } from "styled-components";
import {
  ActionGrey,
  Centered,
  Yellow,
  Beige,
  card,
  Card,
  ALLCAPTEXT,
  TextTitle,
  text12300,
  centeredByColumn,
  TextUPPER,
  Text12300,
  centered,
  ActionBlack,
  textTitle,
  Pink,
  textUpper,
  CenteredByColumn,
  CenteredByColumnLeft,
  NavHeight,
  DownwardShadow,
  BorderGrey,
  SubNavHeight,
  TextGrey,
  actionBlack,
  UpwardShadow,
} from "../Shared";
import { FavoriteHeaderContainer } from "./MyFavorites";
// Define the slide-in animation
const slideInAnimation = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

// Define the slide-out animation
const slideOutAnimation = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;
export interface CuisineFiltersProps {
  cuisines: string[];
  showCuisineFilter: { null };
  onToggleCuisine: () => void;
  onCancel: () => void;
  onConfirm: () => void;
  results: number;
  onClearAll: () => void;
}
export interface CuisineFiltersContainerProps {
  isExiting: boolean;
}

const CuisineFiltersContainer = styled(Centered)<CuisineFiltersContainerProps>`
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: white;
  height: 100vh;
  z-index: 101;
  overflow: hidden;
  justify-content: flex-start;
  animation: ${(props) =>
      props.isExiting ? slideOutAnimation : slideInAnimation}
    0.5s ease;
`;
const Header = styled(DownwardShadow)`
  position: fixed;
  top: 0px;
  left: 0px;
  ${textTitle}
  ${centered}
  font-size: 20px;
  width: 100vw;
  height: ${NavHeight}px;
  background-color: white;
  z-index: 2;
  @media only screen and (min-width: 500px) {
    width: 500px;
  }
`;
const Footer = styled(UpwardShadow)`
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  ${centeredByColumn}
  border-top: 1px solid ${BorderGrey};
  padding: 0px 0px 20px 0px;
  background-color: white;
  z-index: 2;
  @media only screen and (min-width: 500px) {
    width: 500px;
  }
`;
const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0px;
  top: 0px;
`;
const FilterList = styled(Centered)`
  flex-wrap: wrap;
  padding: ${NavHeight + 20}px 20px 160px 20px;
  height: 100vh;
  justify-content: flex-start;
  overflow-y: auto;
  background-color: white;
  z-index: 1;
  @media only screen and (min-width: 500px) {
    width: 500px;
  }
`;
type CuisineFilterProps = {
  selected: boolean;
};

const CuisineFilterButton = styled.button<CuisineFilterProps>`
  ${text12300}
  text-transform: capitalize;
  padding: 6px 10px;
  border-radius: 3px;
  line-height: 1.2;
  background: ${Beige};
  margin: 0px 10px 10px 0px;
  border: 1px solid #e5e7eb;
  background: ${(props) => (props.selected ? "#e5e7eb" : "white")};
`;
const NumberOfCuisines = styled(Text12300)`
  position: absolute;
  bottom: 5px;
  font-size: 8px;
  /* background: ${Beige}; */
  /* padding: 10px 20px; */
  /* box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16); */
  /* margin-bottom: 5px; */
`;
export interface FilterContainerProps {
  toggleFilters: boolean;
  numberOfCuisines: number;
  onToggleFilters: () => void;
}
const ShowFilters = styled.button`
  ${centered}
  flex-direction: column;
  ${text12300}
  font-weight: 500;
  border: 1px solid black;
  height: 60px;
  padding: 0px 0px 10px 0px;
  position: relative;
  width: calc(100% - 40px);
  max-width: 360px;
  margin: 0px 20px;
  svg {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 16px;
  }
`;
export const FilterContainer: React.FC<FilterContainerProps> = ({
  toggleFilters,
  onToggleFilters,
  numberOfCuisines,
}: FilterContainerProps): ReactElement => {
  return (
    <FavoriteHeaderContainer>
      <ShowFilters onClick={onToggleFilters}>
        <span>{`${toggleFilters ? "hide filters" : "cuisine filter"}`}</span>
        <FontAwesomeIcon icon={toggleFilters ? faChevronUp : faChevronDown} />
        <NumberOfCuisines>{`${numberOfCuisines} selected`}</NumberOfCuisines>
      </ShowFilters>
    </FavoriteHeaderContainer>
  );
};
const CancelFitler = styled.button`
  position: fixed;
  top: 0px;
  right: 0px;
  height: ${NavHeight}px;
  width: ${NavHeight}px;
  ${centered}
  padding: 0px;
  margin: 0px;
  font-size: 24px;
  @media only screen and (min-width: 500px) {
    right: calc(100vw - 500px);
  }
`;
const ConfirmFilter = styled.button`
  ${actionBlack}
  flex-grow: 1;
  align-self: stretch;
  margin: 20px 20px 0px 20px;
`;
const SummaryContainer = styled(CenteredByColumn)`
  padding: 0px;
  width: 100%;
  position: relative;
  z-index: 3;
  @media only screen and (min-width: 500px) {
    /* width: 500px; */
  }
`;
const SummaryFooter = styled(Centered)`
  flex-grow: 1;
  align-self: stretch;
  justify-content: space-between;
  padding: 0px 20px;
  margin-top: 20px;
`;

const ResultCount = styled(Text12300)`
  font-size: 11px;
  text-transform: capitalize;
  font-weight: 400;
  /* margin-right: 20px; */
  margin: 0px;
  /* position: absolute;
  right: 20px;
  bottom: 0px; */
`;
const ClearAll = styled.button`
  span {
    ${text12300}
    line-height: 1.4;
    font-weight: 400;
    font-size: 11px;
    border-bottom: 1px solid black;
  }
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  /* margin: 0px 0px 0px 20px;
  position: absolute;
  left: 20px;
  bottom: 0px; */
  /* opacity: ${(props) => (props.disabled ? "0" : "1")}; */
`;
const EditableContainer = styled(Centered)`
  width: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 10px;
  @media only screen and (min-width: 500px) {
    /* width: 500px; */
  }
`;
const EditableContent = styled(Centered)`
  justify-content: flex-start;
  width: 100vw;
  overflow-x: scroll;
  /* padding: 0px 20px 5px 20px; */
  /* margin-left: 20px; */
  margin-bottom: -5px;
  padding-left: 20px;
  /* padding: 20px 20px 0px 0px; */
`;
const SelectedCuisine = styled.button`
  padding: 6px 10px;
  border-radius: 3px;
  margin: 0px 10px 0px 0px;
  border: 1px solid black;
  /* height: 26px; */
  ${centered}
  span {
    ${text12300}
    text-transform: capitalize;
    line-height: 14px;
    font-weight: 400;
    white-space: nowrap;
  }
  svg {
    margin-left: 7px;
    font-size: 14px;
  }
`;
const EditableSelectedCuisines = ({ selectedCuisines, onToggleCuisine }) => (
  <EditableContainer>
    <EditableContent>
      {selectedCuisines.map((c) => (
        <SelectedCuisine onClick={onToggleCuisine.bind(this, c)}>
          <span>{c}</span>
          <FontAwesomeIcon icon={faTimes} />
        </SelectedCuisine>
      ))}
    </EditableContent>
  </EditableContainer>
);
export const FilterSummary = ({
  cuisines,
  results,
  onToggleCuisine,
  onClearAll,
}) => (
  <SummaryContainer>
    {cuisines.length > 0 ? (
      <EditableSelectedCuisines
        selectedCuisines={cuisines}
        onToggleCuisine={onToggleCuisine}
      />
    ) : null}
    <SummaryFooter>
      <ClearAll onClick={onClearAll} disabled={cuisines.length === 0}>
        <span>Clear all filters</span>
      </ClearAll>
      <ResultCount>{`${results} result${results > 1 ? "s" : ""}`}</ResultCount>
    </SummaryFooter>
  </SummaryContainer>
);
export const CuisineFilters: React.FC<CuisineFiltersProps> = ({
  cuisines,
  showCuisineFilter,
  onToggleCuisine,
  onCancel,
  onConfirm,
  results,
  onClearAll,
}: CuisineFiltersProps): ReactElement => {
  const [isExiting, setIsExiting] = useState(false);

  const handleCancel = useCallback(() => {
    setIsExiting(true);

    // // Add a delay before resetting isExiting to false
    const timeoutId = setTimeout(() => {
      setIsExiting(false);
      onCancel();
    }, 500); // Adjust the delay as needed

    // Clear the timeout when the component is unmounted
    return () => clearTimeout(timeoutId);
  }, []);

  const handleConfirm = useCallback(() => {
    setIsExiting(true);

    // // Add a delay before resetting isExiting to false
    const timeoutId = setTimeout(() => {
      setIsExiting(false);
      onConfirm();
    }, 500); // Adjust the delay as needed

    // Clear the timeout when the component is unmounted
    return () => clearTimeout(timeoutId);
  }, []);

  const selectedCuisines = cuisines.filter((c) => showCuisineFilter[c]);
  return (
    <CuisineFiltersContainer isExiting={isExiting}>
      <Overlay></Overlay>
      <Header>
        cuisine filter
        <CancelFitler
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleCancel();
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </CancelFitler>
      </Header>
      <FilterList>
        {cuisines?.map((c, cIndex) => (
          <CuisineFilterButton
            selected={showCuisineFilter[c]}
            key={cIndex}
            onClick={onToggleCuisine.bind(this, c)}
          >
            {c}
          </CuisineFilterButton>
        ))}
      </FilterList>
      <Footer>
        <FilterSummary
          cuisines={cuisines.filter((c) => showCuisineFilter[c])}
          results={results}
          onClearAll={onClearAll}
          onToggleCuisine={onToggleCuisine}
        />
        <ConfirmFilter
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleConfirm();
          }}
        >
          show my results
        </ConfirmFilter>
      </Footer>
    </CuisineFiltersContainer>
  );
};

export interface FavoriteControlProps {
  isSelected?: boolean;
}

const FavoriteControl = styled.button<FavoriteControlProps>`
  svg {
    font-size: 20px;
  }
  /* color: ${(props) => (props.isSelected ? "black" : `#ccc`)}; */
  color: black;
  margin: 0px;
  padding: 0px;
`;

const ControlHeader = styled(DownwardShadow)`
  width: 100%;
  position: fixed;
  top: ${NavHeight}px;
  /* ${card} */
  ${centered}
  /* background-color: ${Beige}; */
  background-color: white;
  height: ${SubNavHeight}px;
  z-index: 100;
  border-bottom: 1px solid ${BorderGrey};
  border-top: 1px solid ${BorderGrey};
  /* z-index: 100; */
  justify-content: space-evenly;
`;
const ControlIcons = {
  // list: faList,
  filter: faCuisine,
  search: faSearch,
  saved: faFavorite,
};
export const Controls = ({ selected, onSelect }) => (
  <ControlHeader>
    {Object.keys(ControlIcons).map((name) => (
      <FavoriteControl
        isSelected={selected === name}
        onClick={onSelect.bind(this, name)}
      >
        <FontAwesomeIcon icon={ControlIcons[name]} />
      </FavoriteControl>
    ))}
  </ControlHeader>
);
