import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { ProgressPlugin } from "webpack";
import { text12300 } from "../tasting_menu/components/Shared";
const AutoResizeTextarea = ({
  placeholder = "",
  value,
  onChange,
  minHeight = 50,
}) => {
  const textareaRef = useRef(null); // Create a ref for the textarea element
  const handleChange = (event) => {
    onChange(event.target.value);
    // Adjust the height of the textarea to fit its content
    event.target.style.height = "auto";
    event.target.style.height = event.target.scrollHeight + "px";
  };
  // useEffect(() => {
  //   if (textareaRef.current) {
  //     // Set the height of the textarea to fit its content
  //     textareaRef.current.style.height = "auto";
  //     textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  //   }
  // }, []);
  return (
    <Textarea
      ref={textareaRef}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      minHeight={minHeight}
    />
  );
};

const Textarea = styled.textarea<{ minHeight?: number }>`
  width: 100%;
  min-height: ${(props) => props.minHeight}px;
  resize: none; /* Disable manual resizing */
  border: 1px solid #ccc;
  /* border: none; */
  padding: 0px 8px;
  ${text12300}
  ::placeholder {
    text-transform: none;
  }
`;

export default AutoResizeTextarea;
