import styled from 'styled-components';
import { card, centered } from './Shared';

export interface PickupOptionProps {
  isSelected: boolean;
}
export const PickupOption = styled.button<PickupOptionProps>`
  ${centered}
  /* ${card} */
  background-color: ${(props) => (props.isSelected ? 'black' : 'white')};
  color: ${(props) => (props.isSelected ? 'white' : 'black')};
  margin: 0 8px 10px 0;
  padding: 5px 10px;
  border: 1px solid grey;
`;
