import React, {
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useState,
} from "react";
import { MOBILE_WIDTH } from "src/constants/ui";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { Dish } from "src/models";
import {
  getDishesByCategoryName,
  getSortedCategoryNamesFromDishes,
} from "src/util/categories";
import styled from "styled-components";
import { LOGGING } from "../..";
import {
  CategoryCarouselV2,
  CATEGORY_CAROUSEL_SCREEN_TOP_OFFSET,
} from "./CategoryCarouselV2";
import { CategoryDishList } from "./CategoryDishList";

export type Category = {
  categoryName: string;
  dishes: Dish[];
};

export interface DishListV2Props {
  cart?: any; // See MealMenuScreen for comments
  dishes: Dish[];
  addingDish?: string;
  onOpenDishDetails: (
    dish: Dish,
    change: number,
    e: React.MouseEvent<HTMLInputElement>
  ) => void;
  onAddDishToCart: (dish: Dish) => void;
  onRemoveDishFromCart: (dish: Dish) => void;
  isMember?: boolean;
  onStartMembership?: () => void;
  restaurantIsMemberOnly?: boolean;
  isAuthenticated?: boolean;
  onShowAuth?: () => void;
  onShowMembershipDetails: () => void;
}

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin-bottom: 64px;
  font-family: Montserrat, sans-serif;
  /* margin-top: 10px; */
`;

type CategoryRefDetails = {
  categoryName: string;
  ref: RefObject<HTMLElement>;
};

export const DishListV2: React.FC<DishListV2Props> = ({
  dishes,
  onOpenDishDetails,
  onAddDishToCart,
  onRemoveDishFromCart,
  cart,
  isMember,
  onStartMembership,
  restaurantIsMemberOnly,
  isAuthenticated,
  onShowAuth,
  onShowMembershipDetails,
  addingDish,
}: DishListV2Props): ReactElement => {
  const { width } = useWindowDimensions();
  // LOGGING &&
  //   console.log("DishListV2 rendering with:", {
  //     isMember,
  //     isAuthenticated,
  //     cart,
  //   });
  // Scrolling and tracking with category bar.
  const [categoryRefs, setCategoryRefs] = useState({});
  const scrollToElement = useCallback((ref) => {
    ref.current.scrollIntoView();
  }, []);
  const setCategoryRef = useCallback(
    (categoryName: string, ref: RefObject<HTMLElement>) => {
      setCategoryRefs((prevData) => {
        return { ...prevData, [categoryName]: ref };
      });
    },
    []
  );

  const trackActiveCategoryOnScroll = useCallback(() => {
    const categoryRefsDetails: CategoryRefDetails[] = Object.entries(
      categoryRefs
    )
      .map((entry: any) => {
        return {
          categoryName: entry[0],
          ref: entry[1],
        };
      })
      .filter((details) => details.ref?.current != null);
    const sortedCategoryRefDetails = [...categoryRefsDetails].sort((a, b) => {
      return a.ref.current.offsetTop - b.ref.current.offsetTop;
    });
    const reverseSortedCategoryRefDetails = [...categoryRefsDetails].sort(
      (a, b) => {
        return b.ref.current.offsetTop - a.ref.current.offsetTop;
      }
    );
    const activeCategoryName = reverseSortedCategoryRefDetails.find(
      (refDetails) =>
        refDetails.ref.current.offsetTop - CATEGORY_CAROUSEL_SCREEN_TOP_OFFSET <
        window.pageYOffset
    )?.categoryName;

    if (activeCategoryName == null) {
      setActiveCategoryName(
        // Last category in reverse order
        sortedCategoryRefDetails[0]?.categoryName
      );
      return;
    }

    setActiveCategoryName(activeCategoryName);
  }, [categoryRefs]);
  useEffect(() => {
    window.addEventListener("scroll", trackActiveCategoryOnScroll);
    return () => {
      window.removeEventListener("scroll", trackActiveCategoryOnScroll);
    };
  }, [trackActiveCategoryOnScroll]);
  // End scrolling

  const orderedCategoryNames = getSortedCategoryNamesFromDishes(dishes);
  const [activeCategoryName, setActiveCategoryName] = useState<string | null>(
    orderedCategoryNames?.[0]
  );

  const dishesByCategoryName = getDishesByCategoryName(dishes);

  const categories: Category[] = orderedCategoryNames.map((cName) => {
    return {
      categoryName: cName,
      dishes: dishesByCategoryName[cName],
    };
  });

  return (
    <ContentContainer>
      <CategoryCarouselV2
        activeCategoryName={activeCategoryName}
        categoryNames={orderedCategoryNames}
        categoryRefs={categoryRefs}
        onScrollToCategory={scrollToElement}
        width={width}
      />
      {categories.map((category) => {
        return (
          <CategoryDishList
            key={category.categoryName}
            category={category}
            isMobile={width < MOBILE_WIDTH}
            onOpenDishDetails={onOpenDishDetails}
            onAddDishToCart={onAddDishToCart}
            onSetCategoryRef={setCategoryRef}
            cart={cart}
            onRemoveDishFromCart={onRemoveDishFromCart}
            isMember={isMember}
            isAuthenticated={isAuthenticated}
            onStartMembership={onStartMembership}
            onShowAuth={onShowAuth}
            restaurantIsMemberOnly={restaurantIsMemberOnly}
            onShowMembershipDetails={onShowMembershipDetails}
            addingDish={addingDish}
          />
        );
      })}
    </ContentContainer>
  );
};
