import { useCallback } from 'react';
import { get, cloneDeep, set } from 'lodash';
import styled from 'styled-components';
import { Cell, Grid } from '../Layout';

type DayScheduleData = {
  lunch?: boolean;
  dinner?: boolean;
};

export type WeeklyScheduleData = {
  sunday?: DayScheduleData;
  monday?: DayScheduleData;
  tuesday?: DayScheduleData;
  wednesday?: DayScheduleData;
  thursday?: DayScheduleData;
  friday?: DayScheduleData;
  saturday?: DayScheduleData;
};

type WeeklyScheduleProps = {
  value?: WeeklyScheduleData;
  onChange?: (value: WeeklyScheduleData) => void;
  disabled?: boolean;
};

export const getWeeklyScheduleKeyFromDayOfWeek = (dayOfWeek: number): string => {
  switch (dayOfWeek) {
    case 0:
      return 'sunday';
    case 1:
      return 'monday';
    case 2:
      return 'tuesday';
    case 3:
      return 'wednesday';
    case 4:
      return 'thursday';
    case 5:
      return 'friday';
    case 6:
      return 'saturday';
  }
};

export const WeeklySchedule = (props: WeeklyScheduleProps) => {
  const { value = {}, onChange, disabled } = props;

  const onFlagChange = useCallback(
    (path: string) => {
      const flagValue = get(value, path);
      const copiedValue = cloneDeep(value);
      set(copiedValue, path, !flagValue);
      onChange?.(copiedValue);
    },
    [onChange, value]
  );

  const renderFlag = (path: string) => {
    const flagValue = get(value, path);
    return (
      <Flag onClick={() => onFlagChange(path)} $disabled={disabled}>
        {flagValue ? '🟩' : '🟥'}
      </Flag>
    );
  };

  return (
    <Container>
      <Grid columns={8} gap={3} justify="center">
        <Cell></Cell>
        <Cell>SU</Cell>
        <Cell>MO</Cell>
        <Cell>TU</Cell>
        <Cell>WE</Cell>
        <Cell>TH</Cell>
        <Cell>FR</Cell>
        <Cell>SA</Cell>
        <Cell>Lunch</Cell>
        <Cell>{renderFlag('sunday.lunch')}</Cell>
        <Cell>{renderFlag('monday.lunch')}</Cell>
        <Cell>{renderFlag('tuesday.lunch')}</Cell>
        <Cell>{renderFlag('wednesday.lunch')}</Cell>
        <Cell>{renderFlag('thursday.lunch')}</Cell>
        <Cell>{renderFlag('friday.lunch')}</Cell>
        <Cell>{renderFlag('saturday.lunch')}</Cell>
        <Cell>Express</Cell>
        <Cell>{renderFlag('sunday.earlyDinner')}</Cell>
        <Cell>{renderFlag('monday.earlyDinner')}</Cell>
        <Cell>{renderFlag('tuesday.earlyDinner')}</Cell>
        <Cell>{renderFlag('wednesday.earlyDinner')}</Cell>
        <Cell>{renderFlag('thursday.earlyDinner')}</Cell>
        <Cell>{renderFlag('friday.earlyDinner')}</Cell>
        <Cell>{renderFlag('saturday.earlyDinner')}</Cell>
        <Cell>Dinner</Cell>
        <Cell>{renderFlag('sunday.dinner')}</Cell>
        <Cell>{renderFlag('monday.dinner')}</Cell>
        <Cell>{renderFlag('tuesday.dinner')}</Cell>
        <Cell>{renderFlag('wednesday.dinner')}</Cell>
        <Cell>{renderFlag('thursday.dinner')}</Cell>
        <Cell>{renderFlag('friday.dinner')}</Cell>
        <Cell>{renderFlag('saturday.dinner')}</Cell>
      </Grid>
    </Container>
  );
};

const Container = styled.div`
  font-size: 11px;
`;

const Flag = styled.div<{ $disabled?: boolean }>`
  ${({ $disabled }) => ($disabled ? 'cursor: default;' : 'cursor: pointer;')}
`;
