import React from 'react';
import './Nav.scss';
import {
  faBookmark as faBookmarkSolid,
  // faUser, // eslint-disable-line no-unused-vars
} from '@fortawesome/free-solid-svg-icons';
import {
  // faBars, //eslint-disable-line no-unused-vars
  faHomeAlt as faHomeEmpty,
  faBookmark as faBookmarkEmpty,
  faFileCertificate as faCertificateEmpty,
  faUserCircle,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faHomeAlt as faHomeSolid,
  faFileCertificate as faCertificateSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Avatar } from '.';
import { PopUp } from '../../shared';

const Nav = ({
  onToggleUserMenu,
  showUserMenu,
  currentUser,
  onSignOut,
  isBookmark,
  isFeed,
  isRecommendation,
}) => (
  <nav className="nav">
    {currentUser.isAuthenticated && (
      <React.Fragment>
        <Link
          to={{
            pathname: '/',
            state: {
              isFromFeed: isFeed,
              isFromRecommendation: isRecommendation,
            },
          }}
          className="page-mode"
        >
          <FontAwesomeIcon icon={isFeed ? faHomeSolid : faHomeEmpty} />
        </Link>
        <Link to="/bookmarks" className="page-mode">
          <FontAwesomeIcon icon={isBookmark ? faBookmarkSolid : faBookmarkEmpty} />
        </Link>
        <Link
          to={{
            pathname: '/recommendations',
            state: { isFromRecommendation: isRecommendation },
          }}
          className="page-mode"
        >
          <FontAwesomeIcon icon={isRecommendation ? faCertificateSolid : faCertificateEmpty} />
        </Link>
      </React.Fragment>
    )}
    <button className="user" type="button" onClick={onToggleUserMenu}>
      {currentUser.isAuthenticated ? (
        <Avatar user={currentUser.user} />
      ) : (
        <FontAwesomeIcon icon={faUserCircle} />
      )}
    </button>
    <PopUp
      isPoppedUp={showUserMenu}
      componentToDisplay={
        currentUser.isAuthenticated ? (
          <ul className="user-menu">
            <li>
              <Link to="/bookmarks">my bookmarks</Link>
            </li>
            <li>
              <Link to="/recommendations">my recommendations</Link>
            </li>
            <li>
              <Link to="/setting">my Settings</Link>
            </li>
            <li>
              <button type="button" onClick={onSignOut}>
                {`sign out`}
              </button>
            </li>
          </ul>
        ) : (
          <ul className="user-menu">
            <li>
              <Link to="/signupall">sign up</Link>
            </li>
            <li>
              <Link to="/signinall">sign in</Link>
            </li>
          </ul>
        )
      }
      hidePopUp={onToggleUserMenu}
      backgroundColor="transparent"
    />
  </nav>
);

export default Nav;
