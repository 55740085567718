import React, { Component } from 'react';
// import { confirmAlert } from 'react-confirm-alert';
import Geocode from 'react-geocode';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LOGGING, Loading, RestaurantHistory } from '.';
import { readRestaurantHistory } from '../../store/actions';
Geocode.setApiKey('AIzaSyDMShXhqw3W4RWi3Xgzom2NUamTAdfZ0pc');
class PageRestaurantHistory extends Component {
  constructor() {
    super();
    LOGGING && console.log('PageRestaurantHistory constructor');
    this.state = {
      loading: true,
      meals: [],
      restaurant: null,
      total: 0,
      average: 0,
    };
    this.handleToggleShowMap = this.handleToggleShowMap.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleClose(e) {
    e.preventDefault();
    this.props.history.goBack();
  }
  handleToggleShowMap(mealIndex, e) {
    LOGGING && console.log('handleToggleShowMap called with:', mealIndex, e);
    e.preventDefault();
    const { meals } = this.state;
    this.setState({
      meals: meals.map((m, i) => ({ ...m, showMap: i === mealIndex ? !m.showMap : m.showMap })),
    });
  }

  componentDidMount() {
    LOGGING && console.log('PageRestaurantHistory componentdidmount');

    const { restaurantId } = this.props.match.params;

    this.props.readRestaurantHistory(restaurantId).then(({ meals, restaurant, total, average }) => {
      LOGGING &&
        console.log('PageRestaurantHistory got from server: ', {
          meals,
          restaurant,
          total,
          average,
        });
      this.setState(
        {
          meals: meals.map((m) => ({ ...m, showMap: false })),
          restaurant: { ...restaurant, center: { ...restaurant.map } },
          loading: false,
          total,
          average,
        },
        () => {
          window.scrollTo(0, 0);
          // });
        }
      );
    });
  }

  render() {
    const { currentUser } = this.props;
    const { loading, meals, restaurant, total, average } = this.state;

    LOGGING &&
      console.log('PageRestaurantHistory rendering with:', {
        meals,
        restaurant,
      });

    if (!currentUser.isAuthenticated || (!currentUser.user.isAdmin && !currentUser.user.isOps)) {
      return <Redirect to="/" />;
    }

    return (
      <div className="page">
        {loading ? (
          <Loading />
        ) : (
          <RestaurantHistory
            meals={meals}
            restaurant={restaurant}
            onToggleShowMap={this.handleToggleShowMap}
            total={total}
            average={average}
            onClose={this.handleClose}
          />
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  LOGGING && console.log('PageRestaurantHistory got redux state:', state);
  return {
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  readRestaurantHistory,
})(PageRestaurantHistory);
