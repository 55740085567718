import { LOAD_TASKS } from '../actionTypes';

const stops = (state = [], action) => {
  switch (action.type) {
    case LOAD_TASKS:
      return [...action.tasks];
    default:
      return state;
  }
};

export default stops;
