import React, { Component, createRef } from "react";
import scriptLoader from "react-async-script-loader";
import ReactDOM from "react-dom";
import "./PaypalButton.scss";
import { Loading, LOGGING } from "./";

let PayPalButton = null;
class PaypalButton extends Component {
  constructor(props) {
    super(props);
    const { deliveryInfo } = props;
    LOGGING && console.log("PaypalButton constructed:", deliveryInfo);
    this.state = {
      showButtons: false,
      loading: true,
      paid: false,
      authorized: false,
      paypalInfo: null,
      // phone: deliveryInfo.phone,
      actions: null,
      onPlaceOrder: null,
    };
    window.React = React;
    window.ReactDOM = ReactDOM;
    this.handleButtonReady = this.handleButtonReady.bind(this);
    this.handleCreateOrder = this.handleCreateOrder.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleAuthorize = this.handleAuthorize.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.paypalButtonContainerRef = createRef();
  }

  handleButtonReady(params) {
    LOGGING && console.log("handleButtonReady called with:", params);
  }

  handleCreateSubscribe(data, actions) {
    LOGGING &&
      console.log("handleCreateSubscribe called with: ", { data, actions });
    // throw error
    return actions.subscription
      .create({
        plan_id: process.env.REACT_APP_PAYPAL_PLAN_ID,
      })
      .then((data) => {
        console.log("data", data);
        return data;
      })
      .catch((err) => {
        console.error("error", err);
      });
  }

  handleCreateSubscribeNoTrial(data, actions) {
    LOGGING &&
      console.log("handleCreateSubscribeNoTrial called with: ", {
        data,
        actions,
      });
    return actions.subscription.create({
      plan_id: process.env.REACT_APP_PAYPAL_PLAN_ID_NOTRIAL,
    });
  }

  handleCreateOrder(data, actions) {
    const { amount } = this.props;
    LOGGING &&
      console.log("handleCreateOrder called with: ", { data, actions, amount });
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: String(amount.toFixed(2)),
          },
        },
      ],
      payment_method: {
        payee_preferred: "UNRESTRICTED",
      },
    });
  }

  handleAuthorize(data, actions) {
    const { amount, isSubscribe } = this.props;
    LOGGING &&
      console.log("handleAuthorize called with: ", {
        data,
        actions,
        amount,
        isSubscribe,
      });
    if (isSubscribe) {
      return this.props.onAuthorize(data.subscriptionID);
    } else {
      this.setState({ loading: true });
      return actions.order.capture().then((captureResult) => {
        LOGGING &&
          console.log("actions.order.capture done with: ", captureResult);
        const captureId =
          captureResult.purchase_units[0].payments.captures[0].id;
        this.props.onAuthorize(captureId);
        this.setState({ loading: false });
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const { onPlaceOrder } = this.state;
    LOGGING &&
      console.log("handleSubmit called with onPlaceOrder:", onPlaceOrder);
    onPlaceOrder();
  }

  handleError() {
    LOGGING && console.log("handleError called");
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;
    LOGGING &&
      console.log("PaypalButton componentDidMount:", {
        isScriptLoaded,
        isScriptLoadSucceed,
      });
    if (isScriptLoaded && isScriptLoadSucceed) {
      LOGGING && console.log("PaypalButton componentDidMount running driver");
      PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
      this.setState({ loading: false, showButtons: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;
    LOGGING &&
      console.log("PaypalButton componentWillReceiveProps:", {
        isScriptLoaded,
        isScriptLoadSucceed,
      });
    const scriptJustLoaded =
      !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

    if (scriptJustLoaded) {
      LOGGING &&
        console.log(
          "PaypalButton componentWillReceiveProps scriptJustLoaded on."
        );
      if (isScriptLoadSucceed) {
        LOGGING &&
          console.log(
            "PaypalButton componentWillReceiveProps isScriptLoadSucceed on."
          );
        PayPalButton = window.paypal.Buttons.driver("react", {
          React,
          ReactDOM,
        });
        this.setState({ loading: false, showButtons: true });
      }
    }
  }

  render() {
    const { showButtons, loading } = this.state;
    const { amount, isSubscribe, noTrialPeriod } = this.props;
    LOGGING &&
      console.log("PaypalButton render:", {
        state: this.state,
        props: this.props,
      });
    return (
      <div
        className="paypal-button-container"
        ref={this.paypalButtonContainerRef}
      >
        {!showButtons ? (
          <Loading />
        ) : (
          <React.Fragment>
            {loading ? (
              <div className="processing">
                <span>Processing, do not click!</span>
              </div>
            ) : null}
            <PayPalButton
              {...(isSubscribe
                ? noTrialPeriod
                  ? {
                      createSubscription: this.handleCreateSubscribeNoTrial,
                    }
                  : {
                      createSubscription: this.handleCreateSubscribe,
                    }
                : {
                    amount,
                    createOrder: this.handleCreateOrder,
                  })}
              onApprove={this.handleAuthorize}
              onSuccess={() => {
                console.log("success!");
              }}
              onError={(err) => {
                console.error("error from the onError callback", err);
              }}
              env={
                process.env.NODE_ENV === "production" ? "production" : "sandbox"
              }
              style={{
                size: "responsive",
                label: isSubscribe ? "subscribe" : "pay",
                height: 55,
                margin: 0,
              }}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default scriptLoader(
  `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&disable-funding=paylater,card&vault=true`
)(PaypalButton);
