import React, { Component } from 'react';
import { GoogleApiWrapper } from 'google-maps-react';
import { connect } from 'react-redux';
import { LOGGING, Loading } from '.';
import { readUserGroup } from '../../store/actions';

class PageGroup extends Component {
  constructor(props) {
    super();
    this.state = {
      userGroup: null,
    };
  }

  componentDidMount() {
    LOGGING && console.log('PageGroup componentdidmount called');
    this.setState({ loading: true });
    this.props.readUserGroup().then((result) => {
      LOGGING && console.log('PageGroup readUserGroup returned from backend with result:', result);
      const { group: userGroup } = result;
      if (userGroup) {
        LOGGING && console.log("found userGroup and i'm the admin");
        this.setState(
          {
            userGroup,
            loading: false,
          },
          () => {
            window.scrollTo(0, 0);
          }
        );
      } else {
        const { currentUser } = this.props;
        const { user } = currentUser;
        const { deliveryInfo, stripeInfo, firstName } = user;
        const groupName = `${firstName}'s team/family`;
        this.setState(
          {
            userGroup: { name: groupName },
            loading: false,
          },
          () => {
            window.scrollTo(0, 0);
          }
        );
      }
    });
  }

  render() {
    const { userGroup } = this.state;
    LOGGING &&
      console.log('PageUserGroupAdmin rendering with', {
        props: this.props,
        state: this.state,
      });
    return <div className="page">{userGroup ? <div>{userGroup.name}</div> : <Loading />}</div>;
  }
}

function mapStateToProps(state) {
  LOGGING && console.log('PageGroup got redux state:', state);
  return {
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  readUserGroup,
})(
  GoogleApiWrapper({
    apiKey: 'AIzaSyBjfTfgJdRah5wXvVj8U7fH4ACoqKry9HI',
  })(PageGroup)
);
