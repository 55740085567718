import React from 'react';
import './MenuCategories.scss';
import { faCheck, faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LOGGING, Loading } from './';
import MenuFooter from './MenuFooter';
const MenuCategories = ({
  dishesByCategory,
  loading,
  onClose,
  selectedDish,
  selectedRef,
  onSelect,
  onSave,
  onToggleCategory,
}) => {
  const categories = Object.keys(dishesByCategory);
  LOGGING &&
    console.log('MenuCategories rendering with: ', {
      categories,
      dishesByCategory,
    });
  return (
    <div className="menu-categories">
      <div className="menu-categories-title">
        <span>Daily menu</span>
      </div>
      {loading || !dishesByCategory || categories.length === 0 ? (
        <Loading />
      ) : (
        <React.Fragment>
          {categories.map((category, index) => (
            <div className="menu-category" key={index}>
              <button className="category-name" onClick={onToggleCategory.bind(this, category)}>
                <span>{category}</span>
                <FontAwesomeIcon
                  icon={dishesByCategory[category].showDetails ? faChevronUp : faChevronDown}
                />
              </button>
              {dishesByCategory[category].showDetails ? (
                <div className="category-dishes">
                  {dishesByCategory[category].dishes
                    .sort((a, b) => (a.ordered && b.ordered && a.ordered > b.ordered ? 1 : -1))
                    .map((dish, index) => (
                      <button
                        className={`dish-name ${
                          selectedDish && selectedDish._id === dish._id ? 'selected' : ''
                        }`}
                        key={dish._id}
                        ref={selectedDish._id === dish._id ? selectedRef : null}
                        onClick={onSelect.bind(this, dish)}
                      >
                        <span>{dish.shortName || dish.name}</span>
                        {selectedDish && selectedDish._id === dish._id ? (
                          <FontAwesomeIcon icon={faCheck} />
                        ) : null}
                      </button>
                    ))}
                </div>
              ) : null}
            </div>
          ))}
        </React.Fragment>
      )}
      <MenuFooter
        buttons={[
          <button key={0} onClick={onClose}>
            cancel
          </button>,
          <button key={1} onClick={onSave.bind(this, selectedDish)}>
            save
          </button>,
        ]}
      />
    </div>
  );
};
export default MenuCategories;
