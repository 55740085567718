import { useState } from "react";
import { faArrowToTop } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { BackgroundGrey, BorderGrey, card } from "../../Shared";
const BackToTopContainer = styled.button<{ bottom: number }>`
  position: fixed;
  bottom: ${(props) => props.bottom || 20}px;
  left: 20px;
  background-color: white;
  border-radius: 50%;
  padding: 0px;
  margin: 0px;
  font-size: 30px;
  cursor: pointer;
  z-index: 100;
  transition: 0.3s;
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
  border: 2px solid ${BorderGrey};
  @media screen and (min-width: 500px) {
    left: auto;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    font-size: 20px;
  }
`;
export const BackToTop = ({ bottom }) => {
  // only show when the user scrolls down, and down more than 100px
  const [show, setShow] = useState(false);
  window.onscroll = function () {
    if (document.documentElement.scrollTop > 150) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  const onClick = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
  };
  return show ? (
    <BackToTopContainer bottom={bottom}>
      <FontAwesomeIcon onClick={onClick} icon={faArrowToTop} />
    </BackToTopContainer>
  ) : null;
};
