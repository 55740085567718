import React, { ReactElement } from "react";
import { faYelp } from "@fortawesome/free-brands-svg-icons";
// import {  as faAlarm } from "@fortawesome/free-solid-svg-icons";
import { faCrown } from "@fortawesome/pro-light-svg-icons";
import { faArrowToRight, faBookOpen } from "@fortawesome/pro-regular-svg-icons";
import {
  faAlarmClock,
  faHeart as faAlarm,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { rest } from "lodash";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SetFavorites } from "src/api/restaurants";
import styled, { keyframes } from "styled-components";
import { LOGGING, alphabet } from "..";
import {
  ActionGrey,
  Centered,
  Yellow,
  Beige,
  card,
  Card,
  ALLCAPTEXT,
  TextTitle,
  text12300,
  CenteredByColumn,
  TextUPPER,
  Text12300,
  centered,
  ActionBlack,
  textTitle,
  Gold,
  textUpper,
  CenteredByColumnLeft,
  Brown,
  actionUnderline,
  BorderGrey,
} from "../Shared";
import { FavoriteButton } from "./FavoriteButton";
export interface MyFavoritesProps {
  restaurants: [
    {
      name: String;
      shortName: String;
      _id: String;
      heroImage: string;
      contact: { yelpUrl: string };
      heroImages?: string[];
      cuisines: [{ name: string }];
      markups: {
        doordash: { isAvailable: boolean; amount: number; amount2: number };
        ubereats: { isAvailable: boolean; amount: number; amount2: number };
      };
    }
  ];
  alphabet: [];
  favorites: String[];
  isLoggedIn: boolean;
  showCuisineFilter?: {};
  onToggleFavorite: () => void;
  scrollY: number;
  query?: string;
}
const IMAGE_SIZE = 140;
const IMAGE_WIDTH = 160;

const ShareButtonText = styled(ActionGrey)`
  // margin: 5px;
  // margin-bottom: 10px;
  width: 100%;
  height: 60px;
  position: relative;
  & svg {
    /* position: absolute; */
    /* right: 20px; */
    font-size: 18px;
    margin-left: 10px;
  }
`;

const TextShareButtonText = styled(ShareButtonText)`
  background: black;
  /* color: black; */
  border: 1px solid black;
  /* background-color: transparent; */
  font-size: 13px;
  padding: 15px 30px;
  color: white;
`;

const EmailShareButtonText = styled(TextShareButtonText)``;
const copyButtonHeight = 80;

export const CopyButton = styled.button`
  margin: 20px 20px 0px 20px;
  width: calc(100% - 40px);
  /* width: 300px; */
  padding: 20px;
  background-color: white;
  position: relative;
  ${card}
  span:last-child {
    border: 1px solid rgb(217, 217, 217);
    display: inline-block;
    width: 100%;
    /* margin: 5px; */
    height: ${copyButtonHeight}px;
    ${ALLCAPTEXT}
    line-height: ${copyButtonHeight}px;
    background-color: ${Beige};
    /* border: 1px solid black; */
    svg {
      font-size: 20px;
      margin-left: 10px;
      /* margin-bottom: 0px; */
    }
  }
`;
const ShareLink = styled.a`
  margin: 10px 0px;
`;

export const SendReferralContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  text-align: center;
  line-height: 1.5;
  // margin-top: 32px;
`;
const BANNER_HEIGHT = 30;
const animation = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;
const AlphabetLetter = styled(TextTitle)`
  text-align: left;
  width: 100%;
  padding: 0px 20px 10px 30px;
`;

const ContentContainer = styled(Centered)`
  justify-content: flex-start;
  left: 0px;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 20px;
  background-color: white;
  flex-direction: column;
`;

const Alphabet = styled(Centered)`
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`;
const RestaurantList = styled(Centered)`
  /* flex-direction: column; */
  ${text12300}
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  position: relative;
  @media only screen and (min-width: 700px) {
    justify-content: flex-start;
  }
`;
const RestaurantContainer = styled(Centered)`
  ${card}
  position: relative;
  height: ${IMAGE_SIZE}px;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
  padding-left: ${IMAGE_WIDTH}px;
  border: 1px solid #e5e7eb;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
  @media only screen and (min-width: 700px) {
    margin-right: 20px;
  }
`;
const RestaurantText = styled(CenteredByColumnLeft)`
  /* position: absolute;
  right: 0px;
  top: 0px;
  width: ${IMAGE_WIDTH}px;
  height: ${IMAGE_SIZE}px; */
  width: 100%;
  height: 100%;
  flex-grow: 1;
  align-self: stretch;
  padding: 14px 40px 14px 18px;
  background-color: ${Beige};
  justify-content: space-between;
  align-items: flex-start;
`;
const RestaurantName = styled.div`
  ${textUpper}
  /* max-width: 100%; */
  box-sizing: border-box;
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
  font-size: 15px;
  line-height: 20px;
  text-align: left;
  /* margin-bottom: 8px; */
  font-weight: 500;
  height: 60px;
  /* overflow: hidden; */
  display: flex;
  flex-wrap: wrap;
  flex-grow: 0;
  align-self: stretch;
  position: relative;
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  span {
    display: inline;
    /* white-space: pre; */
    white-space: normal;
    max-width: 100%;
  }
  span.highlight {
    background-color: yellow;
  }
  @supports (-webkit-line-clamp: 3) {
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;
const RestaurantCuisines = styled(Centered)`
  justify-content: flex-start;
  flex-wrap: wrap;
  position: absolute;
  left: 5px;
  top: 7px;
`;
const RestaurantCuisine = styled(Text12300)`
  text-transform: capitalize;
  /* background-color: white; */
  padding: 2px 5px;
  border-radius: 3px;
  line-height: 1.2;
  background: ${Beige};
  margin: 0px 5px 5px 0px;
  border: 1px solid #e5e7eb;
  font-size: 8px;
`;
const IsFavorite = styled(Centered)`
  width: 25px;
  height: 25px;
  background-color: ${Yellow};
  border-radius: 50%;
  position: absolute;
  right: 0px;
  top: 0px;
  svg {
    /* color: white; */
    font-size: 14px;
  }
`;

const RestaurantLinks = styled(Centered)`
  /* justify-content: flex-start; */
  width: 100%;
  justify-content: space-between;
`;
const RestaurantYelp = styled.a`
  /* background-color: white; */
  /* ${card} */
  /* height: 34px; */
  ${text12300}
  font-size:11px;
  font-weight: 500;
  line-height: 24px;
  border-bottom: 1px solid black;
  margin: 4px 5px 0 0;
  padding: 0px;
  svg {
    font-size: 10px;
    margin-right: 5px;
  }
`;

const RestaurantMenu = styled(Link)`
  /* background-color: white; */
  /* ${card} */
  /* height: 34px; */
  ${text12300}
  font-size:11px;
  font-weight: 500;
  line-height: 24px;
  border-bottom: 1px solid black;
  margin: 4px 5px 0 0;
  padding: 0px;
  svg {
    font-size: 10px;
    margin-right: 5px;
  }
`;
const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
`;
const renderSuggestion = (suggestion, query, isFavorite) => {
  const suggestionLowerCase = suggestion.toLowerCase();
  const index = suggestionLowerCase.indexOf(query.toLowerCase());
  const beforeHighlight = suggestion.slice(0, index);
  const highlightedPart = suggestion.slice(index, index + query.length);
  const afterHighlight = suggestion.slice(index + query.length);
  // console.log({ beforeHighlight, highlightedPart, afterHighlight, suggestion });
  return (
    <RestaurantName theme={{ isFavorite }}>
      <span>{beforeHighlight}</span>
      <span className="highlight">{highlightedPart}</span>
      <span>{afterHighlight}</span>
    </RestaurantName>
  );
};
export const FavoriteHeaderContainer = styled(Centered)`
  /* flex-direction: column; */

  /* justify-content: flex-start; */
  /* align-items: flex-start; */
  width: 100%;
  margin-top: 20px;
  @media only screen and (min-width: 500px) {
    flex-direction: row;
    /* margin-bottom: 40px; */
    justify-content: flex-start;
    width: 360px;
    /* margin-top: 0px; */
  }
`;
const FavoriteNotes = styled(Card)`
  ${text12300}
  line-height: 1.8;
  ${centered}
  padding: 15px;
  position: relative;
  flex-grow: 1;
  align-self: stretch;
  max-width: 360px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
  background-color: ${Beige};
  border: 1px solid #e5e7eb;
  margin: 0px 20px;
  text-align: left;
  font-size: 10px;
  svg {
    font-size: 20px;
    color: #ea4c89;
    margin-right: 10px;
  }
  @media only screen and (min-width: 800px) {
    text-align: center;
  }
`;
const Member = styled(Centered)`
  ${text12300}
  text-transform: capitalize;
  margin-top: 5px;
  background: ${Gold};
  padding: 2px 4px;
  border-radius: 3px;
  font-weight: 400;
  span {
    font-size: 11px;
    line-height: 1.4;
  }
`;
const Icon = styled(Centered)`
  width: 20px;
  height: 20px;
  background-color: ${Brown};
  border-radius: 50%;
  margin-right: 10px;
  display: none;
`;
export const FavoriteHeader = () => (
  <FavoriteHeaderContainer>
    <FavoriteNotes>
      <FontAwesomeIcon icon={faAlarm} />
      <span>You'll get a text when a favorite is scheduled.</span>
    </FavoriteNotes>
  </FavoriteHeaderContainer>
);

type RestaurantHeroImageProps = { backgroundImageUrl: string };
const RestaurantHeroImage = styled.div<RestaurantHeroImageProps>`
  background-image: url(${(props) => props.backgroundImageUrl});
  background-size: cover;
  background-position: center;
  background-size: cover;
  height: ${IMAGE_SIZE}px;
  width: ${IMAGE_WIDTH}px;
  background-position: center;
  position: absolute;
  top: 0px;
  left: 0px;
`;
export const MyFavorites: React.FC<MyFavoritesProps> = ({
  restaurants,
  favorites,
  isLoggedIn,
  showCuisineFilter,
  onToggleFavorite,
  scrollY,
  query,
}: MyFavoritesProps): ReactElement => {
  return (
    <ContentContainer>
      <RestaurantList>
        {restaurants.map((restaurant, restaurantIndex) => {
          const isFavorite = favorites && favorites.includes(restaurant._id);
          return (
            <RestaurantContainer key={restaurantIndex}>
              <RestaurantHeroImage
                backgroundImageUrl={
                  restaurant?.heroImage || restaurant?.heroImages?.[0]
                }
              >
                <Overlay></Overlay>
                <RestaurantCuisines>
                  {restaurant?.cuisines?.map((c, cIndex) => (
                    <RestaurantCuisine key={cIndex}>{c.name}</RestaurantCuisine>
                  ))}
                </RestaurantCuisines>
              </RestaurantHeroImage>
              <RestaurantText>
                {renderSuggestion(restaurant.name, query, isFavorite)}
                {restaurant?.markups?.doordash?.isAvailable ? (
                  restaurant?.markups?.doordash?.amount ? (
                    <Member>
                      <Icon>
                        <FontAwesomeIcon icon={faCrown} />
                      </Icon>
                      <span>
                        member{" "}
                        {`${
                          restaurant?.markups?.doordash?.amount2
                            ? `up to ${restaurant?.markups?.doordash?.amount2}%`
                            : `${restaurant?.markups?.doordash?.amount}%`
                        }`}{" "}
                        off
                      </span>
                    </Member>
                  ) : null
                ) : (
                  <Member>
                    <Icon>
                      <FontAwesomeIcon icon={faCrown} />
                    </Icon>
                    <span>member only</span>
                  </Member>
                )}
                <RestaurantLinks>
                  <RestaurantYelp
                    href={restaurant?.contact?.yelpUrl}
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faYelp} />
                    Yelp
                  </RestaurantYelp>
                  <RestaurantMenu
                    to={{
                      pathname: `/menu/${restaurant.shortName}`,
                      state: { PageFavoritesPos: scrollY },
                    }}
                  >
                    <FontAwesomeIcon icon={faBookOpen} />
                    Menu
                  </RestaurantMenu>
                </RestaurantLinks>
                <FavoriteButton
                  restaurantId={restaurant._id}
                  restaurantName={restaurant.name}
                />
              </RestaurantText>
            </RestaurantContainer>
          );
        })}
      </RestaurantList>
    </ContentContainer>
  );
};
const Entry = styled.a`
  margin-top: 20px;
  /* margin-bottom:5px; */
  ${centered}
  background-color: ${Gold};
  padding: 10px 20px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
  span {
    ${text12300}
    text-transform: uppercase;
    font-weight: 500;
  }
  svg {
    margin-left: 5px;
  }
  @media only screen and (min-width: 800px) {
    position: fixed;
    z-index: 101;
    top: 0px;
    margin-top: 17px;
    right: calc(10vw + 160px);
    box-shadow: none;
    padding: 8px 12px;
    /* svg {
      display: none;
    } */
  }
`;
export const FavoriteEntry = ({}) => (
  <Entry href="/restaurants">
    <span>vendor list</span>
    <FontAwesomeIcon icon={faArrowToRight} />
  </Entry>
);
