import { SET_SUPPORT_LUNCH_EARLY_DELIVERY } from '../actionTypes';

const supportLunchEarlyDelivery = (state = false, action) => {
  switch (action.type) {
    case SET_SUPPORT_LUNCH_EARLY_DELIVERY:
      return action.supportLunchEarlyDelivery;
    default:
      return state;
  }
};

export default supportLunchEarlyDelivery;
