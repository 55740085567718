import React, { useState } from "react";
import "./OrderDetails.scss";
import {
  faCheck,
  faPencil,
  faRocket,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { OrderContractEditModal } from "src/components/OrderContractEditModal";
import {
  LOGGING,
  Goods,
  OrderTotal,
  TimeStamp,
  MealDate,
  CATimeZone,
  Close,
  OrderHighlight,
} from ".";
import {
  GetMealTypeFromWindowStart,
  GetMealTypeDisplayName,
} from "../../../util/time";

const OrderDetails = ({
  order,
  onCancelOrder,
  onEditOrder,
  cancelOrderId,
  saving,
  onUp,
  onDown,
  onEditComment,
  onSaveComment,
  editedComments,
  onBack,
  onSaveOrderContract,
  isMember,
}) => {
  const canEdit = order.canCancel;
  const mealType = GetMealTypeFromWindowStart(order.window.start);
  const mealTypeName = GetMealTypeDisplayName(mealType);
  const [orderContractEditIsOpen, setOrderContractEditIsOpen] = useState(false);
  const orderedAsMember = !order.serviceFeeMember
    ? true
    : order?.serviceFee === order?.serviceFeeMember &&
      order?.isMember !== false;
  LOGGING &&
    console.log("OrderDetails rendering with:", {
      orderedAsMember,
      order,
      canEdit,
    });
  return (
    <div className={`order-details ${mealType}`}>
      <div className="meal-type">
        <MealDate time={moment(order.window.start).tz(CATimeZone)} />
        <span className="order-type">
          {/* {mealType === "earlyDinner" ? (
            <FontAwesomeIcon icon={faRocket} />
          ) : null} */}
          {`${mealTypeName} Order Details ${
            order.isGroupSubOrder ? " (Group)" : ""
          }`}
        </span>
        <Close onClick={onBack} />
      </div>
      {canEdit && (
        <div className="edit-delivery">
          {/*free order can't modify, subGroupOrder can't modify*/}
          {order.paymentIntentId &&
          !order.groupMainOrder &&
          !order.orderTime ? (
            <>
              <button
                className="edit-order"
                onClick={() => setOrderContractEditIsOpen(true)}
              >
                <span>edit contact</span>
                <FontAwesomeIcon icon={faPencil} />
              </button>
              <button className="edit-order" onClick={onEditOrder}>
                <span>edit dishes</span>
                <FontAwesomeIcon icon={faPencil} />
              </button>
            </>
          ) : null}
          {!order.orderTime ? (
            <button
              className="cancel-order"
              onClick={onCancelOrder.bind(this, order._id, order.due === 0)}
            >
              <span>{`${
                cancelOrderId === order._id ? "cancelling..." : "cancel order"
              }`}</span>
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          ) : null}
        </div>
      )}
      <div className="receipt goods">
        <div className="receipt-body">
          <div className="receipt-section">
            <div className="receipt-row">
              <label>Name</label>
              <span className="highlight">{order.name}</span>
            </div>
            <div className="receipt-row">
              <label>Phone</label>
              <span className="highlight">{order.phone}</span>
            </div>
            <div className="receipt-row">
              <label>Email</label>
              <span className="highlight no-transform">{order.email}</span>
            </div>
            <div className="receipt-row">
              <label>Address</label>
              <span className="highlight">{order.address}</span>
            </div>
          </div>
          <OrderHighlight order={order} />
          {order.userGroup &&
          order.groupSubOrders.length > 0 &&
          order.goods.length > 0 ? (
            order.groupSubOrders.map((o) => (
              <React.Fragment>
                <Goods
                  goods={o.goods}
                  isMember={orderedAsMember}
                  hideDishImage={true}
                />
                <div className="edit-delivery">
                  {!o.orderTime ? (
                    <button
                      className="cancel-order"
                      onClick={onCancelOrder.bind(this, o._id, false)}
                    >
                      <span>{`${
                        cancelOrderId === o._id
                          ? "cancelling..."
                          : `cancel order for ${o.name}`
                      }`}</span>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  ) : null}
                </div>
              </React.Fragment>
            ))
          ) : (
            <Goods
              goods={order.goods}
              isMember={orderedAsMember}
              hideDishImage={true}
            />
          )}
          <div className="receipt-section cart-summary">
            <OrderTotal order={order} isMember={orderedAsMember} />
            {order.orderTime ? (
              <TimeStamp action="sent to kitchen" time1={order.orderTime} />
            ) : null}
            {order.deliveryTime ? (
              <TimeStamp action="delivered" time1={order.deliveryTime} />
            ) : (
              <TimeStamp
                action="arriving"
                time1={order.window.start}
                time2={order.window.end}
              />
            )}
            {order.deliveryImage ? (
              <div className="delivery-image">
                <img src={order.deliveryImage[0]} alt="" />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {order.deliveryTime ? (
        <React.Fragment>
          <div className="receipt rating">
            <div className="receipt-body">
              <div className="feedback binary">
                <span className="feedback-question">
                  Will you order from this restaurant again?
                </span>
                <div className="answer-wrapper">
                  {saving &&
                  saving.orderId === order._id &&
                  saving.isBinary &&
                  saving.binaryAnswer === true ? (
                    <button className="saving">
                      <span>Saving...</span>
                    </button>
                  ) : (
                    <button
                      onClick={onUp}
                      className={`${
                        order.feedback === true ? "selected" : "undecided"
                      }`}
                    >
                      <span>Yes</span>
                      <FontAwesomeIcon icon={faCheck} />
                    </button>
                  )}
                  {saving &&
                  saving.orderId === order._id &&
                  saving.isBinary &&
                  saving.binaryAnswer === false ? (
                    <button className="saving">
                      <span>Saving...</span>
                    </button>
                  ) : (
                    <button
                      onClick={onDown}
                      className={`${
                        order.feedback === false ? "selected" : "undecided"
                      }`}
                    >
                      <span>No</span>
                      <FontAwesomeIcon icon={faCheck} />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="receipt rating">
            <div className="receipt-body">
              <div className="feedback words">
                <span className="feedback-question">Any other comments?</span>
                <textarea
                  defaultValue={
                    editedComments.has(order._id)
                      ? editedComments.get(order._id)
                      : order.comment
                  }
                  onChange={onEditComment}
                  name="comment"
                  className="order-comment"
                  placeholder="Type any comments..."
                  disabled={saving && saving.isBinary === false}
                />
                {saving &&
                saving.orderId === order._id &&
                saving.isBinary === false ? (
                  <button className="save-comment saving" disabled>
                    <span>Saving...</span>
                  </button>
                ) : (
                  <button className="save-comment" onClick={onSaveComment}>
                    save
                  </button>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : null}
      <OrderContractEditModal
        isOpen={orderContractEditIsOpen}
        onClose={() => setOrderContractEditIsOpen(false)}
        order={order}
        onSave={onSaveOrderContract}
      />
    </div>
  );
};

export default OrderDetails;
