import React, { ReactElement, useCallback, useState, useEffect } from "react";
import { faHeart as faCheck } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faEmpty } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch, useStore } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "src/store";
import styled from "styled-components";
import { PopUp } from "..";
import { sendFeedback } from "../../../../store/actions";
import {
  ActionBlack,
  Beige,
  card,
  centered,
  centeredByColumn,
  CenteredByColumn,
  MealHeight,
  NavHeight,
  text12300,
  TextTitle,
} from "../Shared";

const FormContainer = styled(CenteredByColumn)`
  margin: 0px;
  padding: 20px;
  position: fixed;
  top: calc(${NavHeight}px * 2);
  width: max(300px, 50vw);
  height: calc(100vh - ${NavHeight}px * 4);
  left: min(25vw, calc(50vw - 150px));
  z-index: 1;
  background-color: white;
  /* height: 45px; */
  form {
    ${centeredByColumn}
    align-self: stretch;
    flex-grow: 1;
    textarea::placeholder {
      text-transform: none;
      line-height: 1.5;
    }
  }
  @media only screen and (min-width: 500px) {
  }
  @media only screen and (min-width: 1200px) {
  }
`;
const Title = styled(TextTitle)`
  /* ... (styles unchanged) */
  margin-top: 0px;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 15px;
`;

const TextArea = styled.textarea`
  /* ... (styles unchanged) */
  ${text12300}
  border: none;
  background-color: ${Beige};
  flex-grow: 1;
  align-self: stretch;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
  text-transform: none;
`;

const SubmitButton = styled(ActionBlack)``;
const AnonymousFavoriteButton = styled(Link)`
  /* background-color: white; */
  background-color: transparent;
  ${card}
  ${centered}
  width: 25px;
  height: 25px;
  margin: 0px;
  padding: 0px;
  border-radius: 6px;
  position: absolute;
  top: 7px;
  right: 7px;
  svg {
    font-size: 20px;
    color: grey;
  }
`;
export interface FeedbackFormProps {
  title?: string;
  placeholder?: string;
  onClose?: () => void;
  onSubmit?: () => void;
}
export const FeedbackForm: React.FC<FeedbackFormProps> = ({
  title,
  placeholder,
  onClose,
  onSubmit,
}: FeedbackFormProps): ReactElement => {
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const dispatch = useDispatch();
  const store = useStore();
  const [text, setText] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const isLoggedIn = currentUser?.isAuthenticated;

  // useEffect(() => {
  //   setIsFavorite(currentUser?.user?.favorites?.includes(String(restaurantId))); // state updated with same value
  // }, [restaurantId, currentUser]);

  const handleTextChange = (e) => {
    setText(e.target.value); // Update the state with the new textarea content
  };

  const handleSubmit = useCallback(async () => {
    const content = text;
    onSubmit();
    // @ts-ignore
    await sendFeedback({ content })(dispatch, store.getState);
  }, [text]);
  return isLoggedIn ? (
    <PopUp
      isPoppedUp={true}
      hidePopUp={onClose}
      backgroundColor={"#333"}
      componentToDisplay={
        <FormContainer>
          {title ? <Title>{title}</Title> : <Title>Help Us Improve 🙏</Title>}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSubmit();
            }}
          >
            <TextArea
              placeholder={placeholder}
              value={text} // Bind the textarea value to the state
              onChange={handleTextChange} // Handle textarea content changes
            />
            <SubmitButton type="submit">Submit</SubmitButton>
          </form>
        </FormContainer>
      }
    />
  ) : null;
  // <AnonymousFavoriteButton
  //   to={{
  //     pathname: "/signin",
  //     state: {
  //       title: "sign in to edit favorites",
  //       next: "/restaurants",
  //       secondary: { title: "Create an Account", next: "/signup" },
  //     },
  //   }}
  // >
  //   <FontAwesomeIcon icon={faCheck} />
  // </AnonymousFavoriteButton>
};
