import React, { ReactElement, useCallback, useEffect } from "react";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { useSelector, useDispatch, useStore } from "react-redux";
import { RootState } from "src/store";
import styled from "styled-components";
import { LOGGING } from "../..";
import {
  Centered,
  NavHeight,
  text12300,
  Overlay,
  textTitle,
  CenteredByColumn,
} from "../../Shared";

const ContentContainer = styled(CenteredByColumn)`
  width: 100vw;
  position: relative;
  /* height: ${NavHeight}px;
   */
  height: 100px;
  background-image: url("https://i.pinimg.com/originals/3b/4f/8a/3b4f8aebeca094aa2c7d0c4a9edae2bc.jpg");
  /* background-image: url("https://i.pinimg.com/originals/f9/b7/c7/f9b7c7330365d20f1877b49e7dc08f42.jpg"); */
  background-size: cover;
  background-position: center;
  @media only screen and (min-width: 800px) {
    /* background-position: top; */
    background-image: url("https://i.pinimg.com/originals/e3/cf/93/e3cf9337bda3f7b1e21384c4043ad7c1.jpg");
    /* background-image: url("https://i.pinimg.com/originals/f9/b7/c7/f9b7c7330365d20f1877b49e7dc08f42.jpg"); */
  }
`;
const Content = styled(CenteredByColumn)`
  position: relative;
  color: white;
  @media only screen and (min-width: 800px) {
    flex-direction: row;
  }
`;
const Line1 = styled.span`
  ${textTitle}
  font-size: 12px;
  line-height: 1;
  z-index: 1;
  text-transform: none;
  @media only screen and (min-width: 800px) {
    /* ${text12300} */
    font-size: 16px;
    line-height: 1.5;
    /* position: absolute; */
    /* right: 100%; */
    width: auto;
    white-space: nowrap;
  }
`;
const Line2 = styled.span`
  ${text12300}
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: uppercase;
  z-index: 1;
  margin: 8px 0px;
  width: auto;
  white-space: nowrap;
  @media only screen and (min-width: 800px) {
    margin: 0px 8px;
    font-size: 20px;
  }
`;
const Line3 = styled.span`
  ${textTitle}
  font-size: 12px;
  line-height: 1;
  z-index: 1;
  text-transform: none;
  @media only screen and (min-width: 800px) {
    font-size: 16px;
    line-height: 1.5;
    /* position: absolute; */
    /* left: 100%; */
    width: auto;
    white-space: nowrap;
  }
`;
export const MileageSaving = () => {
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const firstName = currentUser?.user?.firstName;
  const foodieDistance = currentUser?.user?.stats?.total_foodie_distance;
  const doordashDistance = currentUser?.user?.stats?.total_doordash_distance;
  const totalSaving = doordashDistance - foodieDistance;
  // useEffect(() => {
  //   console.log("MealHighlights rendered with:", highlight);
  //   dispatch(visit("competitor receipts"));
  // }, []);

  LOGGING &&
    console.log("MileageSaving rendered with totalSaving:", {
      currentUser,
      foodieDistance,
      doordashDistance,
      totalSaving,
    });
  if (!doordashDistance || !foodieDistance || totalSaving <= 0) {
    return null;
  }
  const slogan = totalSaving > 500 ? "Green hero!" : "Kudos for going green!";
  return (
    <ContentContainer>
      <Overlay opacity={0.4}></Overlay>
      <Content>
        <Line1>{`${firstName}, you've saved`}</Line1>
        <Line2>
          {totalSaving.toLocaleString("en-US", { maximumFractionDigits: 0 })}{" "}
          miles
        </Line2>
        <Line3>{`by carpooling food delivery with your neighbors.`}</Line3>
      </Content>
    </ContentContainer>
  );
};
