import { useDispatch } from 'react-redux';
import { saveDish } from 'src/store/actions';
import { Checkbox } from '../Form';
import { Flex } from '../Layout';

type DishTypeCheckerProps = {
  dish: any;
};

export const DishTypeChecker = (props: DishTypeCheckerProps) => {
  const { dish } = props;

  const dispatch = useDispatch();

  return (
    <Flex gap={12}>
      <Checkbox
        name={`${dish._id}MustTry`}
        label="MustTry"
        defaultChecked={dish.mustTry}
        onChange={(e) => {
          dispatch(
            saveDish({
              _id: dish._id,
              mustTry: e.target.checked,
            })
          );
        }}
      />
      <Checkbox
        name={`${dish._id}isDrink`}
        label="Drink"
        defaultChecked={dish.isDrink}
        onChange={(e) => {
          dispatch(
            saveDish({
              ...dish,
              isDrink: e.target.checked,
            })
          );
        }}
      />
    </Flex>
  );
};
