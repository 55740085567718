import React, { Component } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LOGGING, Loading, RestaurantsBoard } from '.';
import { readAllOrders } from '../../store/actions';

const dateFormatShort = 'ddd MM/DD hh:mm';
class PageRestaurants extends Component {
  constructor() {
    super();
    LOGGING && console.log('PageRestaurants constructor');
    this.state = {
      matrix: null,
      loading: true,
      freeze: true, //true: freeze dates, false: freeze restaurants
      viewRanked: false,
    };
    this.handleSelectFreeze = this.handleSelectFreeze.bind(this);
    this.handleToggleViewRanked = this.handleToggleViewRanked.bind(this);
  }

  handleToggleViewRanked(e) {
    e.preventDefault();
    const { viewRanked } = this.state;
    this.setState({ viewRanked: !viewRanked });
  }

  handleSelectFreeze(freeze, e) {
    e.preventDefault();
    this.setState({ freeze });
  }

  componentDidMount() {
    LOGGING &&
      console.log(
        'PageRestaurants componentdidmount with this.props.match.params:',
        this.props.match.params
      );
    this.setState({ loading: true }, () => {
      this.props.readAllOrders().then(() => {
        const { orders } = this.props;
        LOGGING && console.log('got from backend:', orders);
        let dates = [],
          restaurants = [];
        orders.forEach((order) => {
          const { window, goods } = order;
          const { start } = window;
          const restaurant = Object.values(goods)[0].dish.restaurant.name;
          const date = moment(start).format(dateFormatShort);
          if (dates.findIndex((d) => d === date) < 0) {
            dates.push(date);
          }
          if (restaurants.findIndex((r) => r === restaurant) < 0) {
            restaurants.push(restaurant);
          }
        });
        let matrix = {}; //key(date + meal, user firstName)
        dates.forEach((date) => {
          matrix[date] = {};
          restaurants.forEach((r) => {
            matrix[date][r] = { orders: 0, dishes: 0 };
          });
        });

        orders.forEach((order) => {
          const { window, goods, isLunch } = order;
          const { start } = window;
          const restaurant = Object.values(goods)[0].dish.restaurant.name;
          const date = moment(start).format(dateFormatShort);
          matrix[date][restaurant].orders++;
          matrix[date][restaurant].dishes += Object.values(goods).length;
          matrix[date][restaurant].restaurant = restaurant;
          matrix[date][restaurant].date = date;
          matrix[date][restaurant].isLunch = isLunch;
        });
        let ordersByDay = [];
        Object.values(matrix).forEach((restaurantsOnDate) => {
          Object.values(restaurantsOnDate).forEach((restaurantOnDate) => {
            if (restaurantOnDate.orders > 0) {
              ordersByDay.push(restaurantOnDate);
            }
          });
        });

        const ranked = ordersByDay.sort((a, b) => (a.orders > b.orders ? -1 : 1));
        LOGGING && console.log('ranked: ', ranked);
        // const ordersByDay = matrix[0]
        //   .map((_, colIndex) => matrix.map((row) => row[colIndex]))
        //   .sort((a, b) => (a.orders > b.orders ? 1 : -1));

        this.setState({ loading: false, matrix, restaurants, dates, ranked }, () => {
          window.scrollTo(0, 0);
        });
      });
    });
  }

  render() {
    const { currentUser } = this.props;
    const { loading, matrix, restaurants, dates, freeze, viewRanked, ranked } = this.state;

    LOGGING && console.log('PageRestaurants rendering with viewRanked:', viewRanked);

    if (!currentUser.isAuthenticated || (!currentUser.user.isAdmin && !currentUser.user.isOps)) {
      return <Redirect to="/" />;
    }

    return (
      <div className="page">
        {loading || !matrix ? (
          <Loading />
        ) : (
          <RestaurantsBoard
            matrix={matrix}
            restaurants={restaurants}
            dates={dates}
            freeze={freeze}
            viewByRanked={viewRanked}
            ranked={ranked}
            onToggleViewByRanked={this.handleToggleViewRanked}
            onSelectFreeze={this.handleSelectFreeze}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log('PageRestaurants got redux state:', state);
  return {
    currentUser: state.currentUser,
    orders: state.orders,
  };
}

export default connect(mapStateToProps, { readAllOrders })(PageRestaurants);
