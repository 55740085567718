import React, { ReactElement } from 'react';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { LOGGING } from '..';
import {
  ActionBlack,
  ActionGrey,
  Centered,
  Beige,
  card,
  text12300,
  textTitle,
  TextTitle,
  centered,
  NavHeight,
  TextTagline,
} from '../Shared';
const DishSelectionItemList = styled(Centered)`
  background-color: ${Beige};
  flex-direction: column;
  width: 100%;
  padding: 20px 20px 0px 20px;
`;
const DishSelectionItem = styled(Centered)`
  /* flex-direction: column; */
  width: 100%;
  /* ${card} */
  justify-content: flex-start;
  margin-bottom: 20px;
  > label {
    text-transform: uppercase;
    margin-right: 15px;
    font-weight: 500;
    /* flex-grow: 1; */
  }
  > span {
    margin: 0px;
    margin-right: 10px;
    /* padding: 0px 5px; */
    width: 150px;
    /* flex-grow: 1; */
    /* width: auto; */
  }
  > input[type='checkbox'] {
    width: 30px;
    flex-grow: 0;
    margin-right: 50px;
  }
  > button {
    margin-right: 10px;
  }
  /* justify-content: flex-start; */
`;
const DishSelectionEntry = styled(Centered)`
  ${card}
  ${text12300}
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  margin: 20px 0px;
  position: relative;
  > label {
    font-weight: 500;
    margin-right: 10px;
    ${textTitle}
  }
  > button {
    position: absolute;
    right: 20px;
    top: 20px;
  }
`;
const DishSelectionItemTitle = styled(TextTitle)`
  margin-bottom: 20px;
`;

const DishSelectionTitle = styled(TextTitle)`
  padding: 20px 0px 0px 20px;
`;
const Notes = styled(Centered)`
  margin-bottom: 10px;
  width: 100%;
`;
const Field = styled(Centered)`
  ${text12300}
  font-size: 14px;
  label {
    font-weight: 500;
    margin: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  span {
    font-weight: 300;
    text-transform: capitalize;
    background-color: ${Beige};
    padding: 5px 10px;
    border-radius: 5px;
  }
  margin: 20px 10px;
`;

const DishSelectionFields = styled(Centered)`
  > button {
    margin-right: 10px;
  }
  padding: 20px 0px 0px 20px;
  margin-bottom: 20px;
  > label {
    text-transform: uppercase;
    margin-right: 15px;
    /* background-color: yellow; */
    font-weight: 500;
  }
  > span {
    margin: 0px;
    margin-right: 20px;
  }
`;
const DishSelectionItems = styled(Centered)`
  flex-direction: column;
  padding: 20px;
  /* background-color: ${Beige}; */

  align-items: flex-start;
  align-self: stretch;
  /* flex-grow: 1; */
  input {
    padding-left: 10px;
    border: 1px solid black;
  }
`;
const SelectionPosition = styled(Centered)`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: green;
  ${textTitle}
  color: white;
  position: absolute;
  top: 25px;
  right: 200px;
`;
const UseForDish = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  label {
    ${textTitle}
    margin-right: 10px;
  }

  input {
    margin-top: 5px;
    width: 20px;
    height: 20px;
    &:hover {
      cursor: pointer;
    }
  }
`;
const ExistingSelectionListContainer = styled.form`
  ${card}
  ${centered}
  z-index:100;
  flex-direction: column;
  position: absolute;
  top: ${NavHeight}px;
  left: 40px;
  padding: 40px 20px;
  box-sizing: border-box;
  width: calc(100vw - 80px);
`;
type ExistingSelectionListProps = {
  selections: any[];
  dish: any;
  onToggleSelection: () => void;
  onSave: () => void;
};

export const ExistingSelectionList: React.FC<ExistingSelectionListProps> = ({
  selections,
  onToggleSelection,
  onSave,
  dish,
}): ReactElement => {
  LOGGING &&
    console.log('ExistingSelectionList rendering with:', {
      dish,
      selections,
    });

  return (
    <ExistingSelectionListContainer>
      <TextTitle>Choosing from selections</TextTitle>
      <Notes>
        <Field key="restaurant">
          <label>Restaurant</label>
          <span>{dish?.restaurant?.name}</span>
        </Field>
        <Field key="dish">
          <label>Dish</label>
          <span>{dish?.name}</span>
        </Field>
      </Notes>
      <ActionBlack onClick={onSave}>Save Selections</ActionBlack>
      {selections.map((selection, index) => {
        const selectionPositionInSelected = dish.selections.findIndex(
          (s) => s._id === selection._id
        );
        LOGGING && console.log({ selectionPositionInSelected, selection, dish });
        return (
          <>
            <DishSelectionTitle key={selection._id}>{selection.name}</DishSelectionTitle>
            <DishSelectionEntry key={index}>
              {selectionPositionInSelected >= 0 ? (
                <SelectionPosition>{selectionPositionInSelected + 1}</SelectionPosition>
              ) : null}
              <UseForDish>
                <label>use for dish</label>
                <input
                  checked={selectionPositionInSelected >= 0}
                  onChange={onToggleSelection.bind(this, selection)}
                  type="checkbox"
                />
              </UseForDish>

              {/* <ActionBlack onClick={onToggleSelection.bind(this, index)}>Add Selection</ActionBlack> */}
              <DishSelectionFields>
                <label>min selections</label>
                <span>{selection.minSelection}</span>
                <label>max selections</label>
                <span>{selection.maxSelection}</span>
              </DishSelectionFields>
              <DishSelectionItems>
                <DishSelectionItemTitle>options</DishSelectionItemTitle>
                <DishSelectionItemList>
                  {selection.items.map((item, itemIndex) => {
                    LOGGING && console.log('DishSelectionItem rendering with item:', item);
                    return (
                      <DishSelectionItem key={itemIndex}>
                        <label>{item.name}</label>
                        <span>${item.price}</span>
                        {item.isDefaultChoice ? <label>is default choice</label> : null}
                        {item.outOfStock ? <label>out of stock</label> : null}
                      </DishSelectionItem>
                    );
                  })}
                </DishSelectionItemList>
              </DishSelectionItems>
            </DishSelectionEntry>
          </>
        );
      })}
    </ExistingSelectionListContainer>
  );
};
