import React, { ReactElement, useEffect, useState } from "react";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useParams, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { SendAlert } from "src/api/referrals";
import { RootState } from "src/store";
import { getGiftCardTemplate } from "src/store/actions";
import styled from "styled-components";
import { Back, LOGGING, Loading } from "../..";
import logo from "../../../../../logo_beige.png";
import {
  Beige,
  BorderGrey,
  ListTitle,
  Centered,
  centered,
  CenteredByColumn,
  card,
  Pink,
  text12300,
  NavHeight,
  ActionBlack,
  centeredByColumn,
  textTitle,
  CenteredByColumnLeft,
  Text12300,
  TextGrey,
} from "../../Shared";
import { BottomBanner } from "../banners/BottomBanner";
import { BreakPoint } from "../mealMenu/MealHeader";
import { GiftCheckout } from "./GiftCheckout";
const GIFT_AMOUNT_OPTIONS = [5, 10, 20, 25, 50, 100];
const MAX_MESSAGE_LENGTH = 160;
interface TemplateContainerProps {
  background: string;
  scale?: number;
  position?: { left?: number; top?: number; rotate?: number };
}

interface CardProps {
  isThumbnail?: boolean;
}

const CardWidth = 343;
const CardHeight = 216;
const LogoWidth = 40;
const LogoImageHeight = 20;
const CardRadius = 10;
const ThumbnailRatio = 0.55;

const TemplateThumbnail = styled.div<TemplateContainerProps>`
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-position: center;
  width: ${(props) => `${ThumbnailRatio * CardWidth * props.scale}px`};
  height: ${(props) => `${ThumbnailRatio * CardHeight * props.scale}px`};
  border-radius: ${(props) => `${ThumbnailRatio * CardRadius * props.scale}px`};
  margin: 0px;
  padding: 0px;
  ${centered}
  align-items: flex-start;
  flex-shrink: 0;
  position: relative;
  span {
    ${textTitle}/* font-size: 30px; */
  }
  box-shadow: 0 4px 12px 0 rgba(28, 29, 33, 0.12);
  transform: ${(props) =>
    `rotate(${props?.position?.rotate || 0}deg) translate(${
      props?.position?.left || 0
    }px, ${props?.position?.top || 0}px)`};
  @media only screen and (min-width: ${BreakPoint}px) {
    width: ${(props) => `${CardWidth * props.scale}px`};
    height: ${(props) => `${CardHeight * props.scale}px`};
    border-radius: ${(props) => `${CardRadius * props.scale}px`};
  }
`;
const ThumbnailOverlay = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: ${ThumbnailRatio * CardRadius}px;
  @media only screen and (min-width: ${BreakPoint}px) {
    border-radius: ${CardRadius}px;
  }
`;
export const CardThumbnail = ({
  background,
  scale = 1,
  position = {
    rotate: 0,
    left: 0,
    top: 0,
  },
}) => (
  <TemplateThumbnail background={background} scale={scale} position={position}>
    <ThumbnailOverlay></ThumbnailOverlay>
    <Logo isThumbnail={true} />
  </TemplateThumbnail>
);
const Image = styled.img<CardProps>`
  width: auto;
  height: ${(props) =>
    props.isThumbnail
      ? `${ThumbnailRatio * LogoImageHeight}px`
      : `${LogoImageHeight}px`};
  background-position: center;
  background-size: cover;
  @media only screen and (min-width: ${BreakPoint}px) {
    height: ${LogoImageHeight}px;
  }
`;
const LogoContainer = styled(Centered)<CardProps>`
  height: ${(props) =>
    props.isThumbnail ? `${ThumbnailRatio * LogoWidth}px` : `${LogoWidth}px`};
  width: ${(props) =>
    props.isThumbnail ? `${ThumbnailRatio * LogoWidth}px` : `${LogoWidth}px`};
  background-position: center;
  background-size: cover;
  background-color: ${Beige};
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  @media only screen and (min-width: ${BreakPoint}px) {
    height: ${LogoWidth}px;
    width: ${LogoWidth}px;
  }
`;
const Logo = ({ isThumbnail }) => (
  <LogoContainer isThumbnail={isThumbnail}>
    <Image src={logo} isThumbnail={isThumbnail}></Image>
  </LogoContainer>
);
const NewGiftWrapper = styled(CenteredByColumn)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* background: rgba(0, 0, 0, 0.5); */
  z-index: 10;
  background-color: white;
  /* padding: ${NavHeight + 20}px 20px 20px 20px; */
  padding-top: ${NavHeight}px;
  justify-content: flex-start;
  /* align-items: flex-start; */
  box-sizing: border-box;
  align-content: stretch;
  @media only screen and (min-width: ${BreakPoint}px) {
    > *:first-child {
      box-shadow: 0 4px 32px 0 rgba(28, 29, 33, 0.25);
    }
  }
`;
const CreateGift = styled.button`
  ${card}
  ${centeredByColumn}
    svg {
    margin-bottom: 10px;
    font-size: 20px;
  }
  span {
    ${textTitle}
  }
  height: 100px;
  width: 200px;
  border-radius: 3px;
  background-color: ${Pink};
`;
const CardField = styled(CenteredByColumnLeft)`
  margin: 0px 20px;
  /* width: 100%; */
  ${text12300}
  /* flex-grow: 1; */
  align-self: stretch;
  label {
    font-weight: 500;
    text-transform: capitalize;
  }
  input {
    /* flex-grow: 1; */
    width: 100%;
    background-color: transparent;
  }
  /* width: 20px; */
  /* flex-grow: 1; */
`;
const CardSubField = styled(CardField)`
  margin-right: 0px;
  align-self: auto;
  flex-grow: 0;
  position: relative;
  /* border: 1px solid red; */
  /* width: 100px; */
  svg {
    position: absolute;
    right: 0px;
    bottom: 10px;
    font-size: 15px;
    color: ${TextGrey};
  }
  label {
    padding-left: 3px;
  }
  select {
    ${text12300}
    font-size: 22px;
    font-weight: 500;
    width: 100%;
    border: none;
    padding: 0px;
    border-bottom: 1px solid ${BorderGrey};
    outline: none;
    line-height: 32px;
    option {
      padding: 0px;
    }
    background-color: transparent;
    appearance: none;
  }
`;
const CardSubsection = styled(Centered)`
  width: 100%;
  padding: 0px 20px;
  justify-content: flex-start;
  input[name="content"] {
    margin: 30px 0 0 0;
  }
  input {
    flex-grow: 1;
  }
`;
const MessageSubsection = styled(CardSubsection)`
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  align-self: stretch;
  input {
    width: 100%;
    background-color: transparent;
    text-transform: none;
  }
  input::placeholder {
    text-transform: none;
  }
`;
const CardSection = styled(CenteredByColumnLeft)`
  ${card}
  ${text12300}  
  /* background-color: ${Beige}; */
  margin-bottom: 15px;
  /* flex-grow: 1; */
  align-self: stretch;
  align-content: stretch;
  justify-content: flex-start;
  padding: 20px 0px 10px 0px;
  position: relative;
  /* border-top: 1px solid ${BorderGrey};
  border-bottom: 1px solid ${BorderGrey}; */
  h1 {
    margin-top: 0px;
    margin-left: 20px;
    ${textTitle}
  }
  &:nth-child(2) {
    border-top: 1px solid ${BorderGrey};
  }
  &:last-child {
    flex-grow: 1;
    margin-bottom: 0px;
    div {
      flex-grow: 0;
    }
  }
  @media only screen and (min-width: ${BreakPoint}px) {
    display: none;
  }
`;
const CloseNewCard = styled.button`
  position: absolute;
  right: 0px;
  top: 0px;
  svg {
    font-size: 18px;
  }
  width: ${NavHeight}px;
  height: ${NavHeight}px;
  margin: 0px;
  padding: 0px;
  ${centered}
`;
const CharacterCount = styled(Text12300)`
  font-weight: 400;
  color: ${TextGrey};
  margin-top: 2px;
  text-align: right;
  width: 100%;
  /* top: 100%;
  right: 0px; */
  @media only screen and (min-width: ${BreakPoint}px) {
    margin-top: 0px;
  }
`;
export const SubmitButton = styled(ActionBlack)`
  position: fixed;
  bottom: 40px;
  width: 200px;
  left: calc(50vw - 100px);
  @media only screen and (min-width: ${BreakPoint}px) {
    /* left: auto;
    right: 40px;
    box-shadow: 0 4px 32px 0 rgba(28, 29, 33, 0.25); */
    position: relative;
    left: 0px;
    bottom: 0px;
    margin: 40px 0px;
  }
`;

const DesktopContainer = styled(Centered)`
  display: none;
  @media only screen and (min-width: ${BreakPoint}px) {
    display: flex;
    align-items: flex-start;
    padding-bottom: 100px;
    max-height: calc(100vh - ${2 * NavHeight}px);
    /* justify-content: flex-start; */
    overflow-y: scroll;
    width: 100vw;
    padding-top: 30px;
  }
`;
const DesktopSection = styled(CenteredByColumn)`
  display: none;
  @media only screen and (min-width: ${BreakPoint}px) {
    display: flex;
    /* max-height: calc(100vh - ${NavHeight}px); */
    /* overflow-y: auto;  */
    justify-content: flex-start;
    margin-left: 30px;
    flex-grow: 1;
    max-width: 400px;
    width: ${CardWidth}px;
    > *:first-child {
      margin-top: 0px;
    }
    /* background-color: ${Beige}; */
    /* ${card} */
    border: 1px solid ${BorderGrey};
    box-shadow: 0px 0px 4px 2px rgba(28, 29, 33, 0.15);
    padding: 40px 30px;
    margin-bottom: ${NavHeight}px;
    /* input {
      background-color: white;
    } */
  }
`;

const DesktopField = styled(CenteredByColumnLeft)`
  ${text12300};
  align-self: stretch;
  margin-top: 30px;
  position: relative;
  label {
    ${textTitle}
    /* font-weight: 500; */
    font-size: 16px;
    margin-bottom: 10px;
  }
  select,
  input {
    height: 50px;
    line-height: 50px;
    padding: 0px 15px;
    border-radius: 0px;
    ${text12300}
    font-size: 13px;
    font-weight: 500;
    width: 100%;
    /* border: none; */

    border: 1px solid ${BorderGrey};
    /* border: none; */
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    option {
      padding: 0px;
    }
    /* background-color: transparent; */
    appearance: none;
    /* background-color: ${Beige}; */
  }
  input {
    margin-right: 0px;
    box-sizing: border-box;
    &:last-child {
      margin-bottom: 0px;
    }
    /* background-color: ${Beige}; */
  }
  input:focus {
    /* background-color: ${Beige}; */
  }

  input::placeholder {
    color: ${TextGrey};
    font-weight: 300;
  }
  svg {
    position: absolute;
    right: 15px;
    bottom: 16px;
    font-size: 16px;
  }
`;
const ErrorContainer = styled(Centered)`
  position: absolute;
  top: 5px;
  width: 100%;
  span {
    ${text12300}
    background-color: red;
    color: white;
    padding: 5px 20px;
    font-weight: 400;
  }
`;
const Error = ({ message }) => (
  <ErrorContainer>
    <span>{message}</span>
  </ErrorContainer>
);

const CardLink = styled(Link)`
  margin: 15px 15px 15px 0px;
`;
export const NewGift = () => {
  const { giftTemplateId } = useParams<{ giftTemplateId: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const giftCardTemplates = useSelector(
    (state: RootState) => state.giftCardTemplates
  );
  const [template, setTemplate] = useState(null);
  const [showCheckout, setShowCheckout] = useState(false);
  const [error, setError] = useState({ message: null });
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    amount: 20,
    content: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      const foundTemplate = Array.from(Object.values(giftCardTemplates))
        .flat()
        .find(
          //@ts-ignore
          (g) => String(g?._id) === String(giftTemplateId)
        );
      if (foundTemplate) {
        setTemplate(foundTemplate);
      } else {
        try {
          const result = await getGiftCardTemplate(giftTemplateId);
          LOGGING && console.log("result:", result);
          setTemplate(result);
        } catch (error) {
          console.error("Error fetching gift card templates:", error);
        }
      }
    };

    fetchData();

    const name = `${currentUser?.user?.firstName} ${currentUser?.user?.lastName} `;
    //@ts-ignore
    SendAlert(name, `gift ${template?.category} #${template?.index}`);
  }, [dispatch]);

  const onClose = (done) => {
    // Go back in history when onClose is called
    LOGGING && console.log("NewGift going back in onClose:", { done });
    // history.goBack();
    if (done) {
      toast.success("gift sent!");
    }
    history.push("/gifts");
  };
  // <NewGift
  //     giftTemplateId={_id}
  //     template={background}
  //     onClose={() => {
  //       setShowNewGift(false);
  //     }}
  //   />

  const handleCloseCheckout = (done) => {
    LOGGING && console.log("handleCloseCheckout called with done:", done);
    setShowCheckout(false);
    if (done) {
      onClose(done);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (error.message) {
      setError({ message: null });
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const checkEmail = () => {
    if (formData.email.trim() !== "") {
      // Check for a valid email address using a regular expression
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        // Invalid email address
        // You can handle this validation error, for example, by setting an error state
        // and displaying an error message to the user.
        // For simplicity, this example just logs an error message to the console.
        setError({ message: "Invalid email address" });
        return false;
      } else {
        return true;
      }
    } else {
      setError({ message: "Email address can't be empty!" });
      return false;
    }
  };

  const checkName = () => {
    if (formData.name.trim() === "") {
      setError({ message: "name can't be empty!" });
      return false;
    } else {
      return true;
    }
  };

  const handleChangeMessage = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    // Limit the content length to 160 characters
    const limitedValue = value.slice(0, MAX_MESSAGE_LENGTH);

    setFormData((prevData) => ({ ...prevData, [name]: limitedValue }));
  };
  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    LOGGING && console.log("handleSelect called with:", { name, value });
    const intValue = parseInt(value, 10);
    setFormData((prevData) => ({ ...prevData, [name]: intValue }));
  };

  LOGGING &&
    console.log("Newgift rendering with:", {
      formData,
      giftTemplateId,
      template,
    });

  if (!currentUser.isAuthenticated || !currentUser.user) {
    return (
      <Redirect
        to={{
          pathname: "/auth",
          state: {
            title: "To See Gift",
            next: location.pathname,
          },
        }}
      />
    );
  }
  if (!template) return <Loading />;
  return (
    <NewGiftWrapper>
      <ListTitle noShadow={false}>
        <span>Create a Gift</span>
        <Back
          onClick={(e) => {
            e.preventDefault();
            onClose(false);
          }}
        />
      </ListTitle>
      <DesktopContainer>
        {/*  @ts-ignore */}
        <CardThumbnail background={template?.background} />
        <DesktopSection>
          <DesktopField>
            <label>Gift amount</label>
            <select
              name="amount"
              value={formData.amount}
              onChange={handleSelect}
              // style={styles.select as React.CSSProperties}
            >
              <option value="" disabled>
                Select amount
              </option>
              {GIFT_AMOUNT_OPTIONS.map((amount) => (
                <option key={amount} value={amount}>
                  ${amount.toFixed(2)}
                </option>
              ))}
            </select>
            <FontAwesomeIcon icon={faChevronDown} />
          </DesktopField>
          <DesktopField>
            <label>Who are you gifting to?</label>
            <input
              placeholder="recipient name"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            <input
              type="text"
              name="email"
              placeholder="recipient email"
              value={formData.email}
              onChange={handleChange}
            />
          </DesktopField>
          <DesktopField>
            <label>Personal Note</label>
            <input
              name="content"
              placeholder="Add a message (optional)"
              value={formData.content}
              onChange={handleChangeMessage}
            />
            <CharacterCount>
              {formData.content.length}/{MAX_MESSAGE_LENGTH}
            </CharacterCount>
          </DesktopField>
          {showCheckout ? (
            <GiftCheckout
              gift={{ ...formData, giftTemplateId }}
              onFinish={handleCloseCheckout}
            />
          ) : (
            <BottomBanner
              label="checkout"
              onClick={() => {
                setShowCheckout(true);
              }}
            />
          )}
        </DesktopSection>
      </DesktopContainer>
      <CardSection>
        <CardSubsection>
          {/*  @ts-ignore */}
          <CardThumbnail background={template.background} />
          <CardSubField>
            <label>gift amount</label>
            <select
              name="amount"
              value={formData.amount}
              onChange={handleSelect}
              // style={styles.select as React.CSSProperties}
            >
              <option value="" disabled>
                Select amount
              </option>
              {GIFT_AMOUNT_OPTIONS.map((amount) => (
                <option key={amount} value={amount}>
                  ${amount.toFixed(2)}
                </option>
              ))}
            </select>
            <FontAwesomeIcon icon={faChevronDown} />
          </CardSubField>
        </CardSubsection>
        <MessageSubsection>
          <input
            name="content"
            placeholder="Add a message (optional)"
            value={formData.content}
            onChange={handleChangeMessage}
          />
          <CharacterCount>
            {formData.content.length}/{MAX_MESSAGE_LENGTH}
          </CharacterCount>
        </MessageSubsection>
      </CardSection>
      <CardSection>
        {error?.message?.length > 0 ? <Error message={error.message} /> : null}
        <h1>To:</h1>
        <CardField>
          <label>Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </CardField>
        <CardField>
          <label>Email</label>
          <input
            type="text"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </CardField>

        {showCheckout ? (
          <GiftCheckout
            gift={{ ...formData, giftTemplateId }}
            onFinish={handleCloseCheckout}
          />
        ) : (
          <SubmitButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (checkEmail() && checkName()) {
                setShowCheckout(true);
              }
            }}
            disabled={error.message}
          >
            check out
          </SubmitButton>
        )}
      </CardSection>
    </NewGiftWrapper>
  );
};
interface GiftTemplateProps {
  background: string;
  _id: string;
}

export const GiftTemplate: React.FC<GiftTemplateProps> = ({
  background,
  _id,
}: GiftTemplateProps): ReactElement => {
  // Check if the success signal is present in the location state
  // @ts-ignore

  return (
    <CardLink to={`/createGift/${_id}`}>
      <CardThumbnail background={background} />
    </CardLink>
  );
};
