import React from 'react';
import './RestaurantOrders.scss';
import { LOGGING, Back } from './';
const Order = ({ order }) => (
  <div className="order">
    <div className="order-field name">{`${order.user.firstName} ${order.user.lastName}`}</div>
    {order.goods.map((g) => (
      <div className="order-field dish">
        <div className="order-field dish-name">{g.dish.name}</div>
        <div className="order-field dish-quantity">{g.dish.quantity}</div>
        <div className="order-field dish-comments">{g.dish.comments}</div>
      </div>
    ))}
  </div>
);

const RestaurantOrdersOnDate = ({ date, orders }) => (
  <div className="restaurant-orders-on-date">
    <div className="date">{date}</div>
    {orders.map((goods, index) => {
      LOGGING && console.log('RestaurantOrdersOnDate looping over goods:', goods);
      return (
        <div className="user" key={index}>
          {goods.map((g) => (
            <div>{g.dish.name}</div>
          ))}
        </div>
      );
    })}
  </div>
);

const RestaurantOrders = ({ matrix, names, dates, onBack }) => {
  LOGGING && console.log('RestaurantOrders rendering with orders:', matrix);

  return (
    <div className="list order-list">
      <div className="list-title">
        <Back onClick={onBack} />
      </div>
      <div className="restaurant-order-matrix">
        <div className="restaurant-orders-on-date">
          <div className="date"></div>
          {names.map((name) => (
            <div className="user">{name}</div>
          ))}
        </div>
        {dates.map((date) => (
          <RestaurantOrdersOnDate date={date} orders={Object.values(matrix[date])} />
        ))}
      </div>
    </div>
  );
};

export default RestaurantOrders;
