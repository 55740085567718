import React, { ReactElement, useCallback } from "react";
import { FoodieEvent, FoodieSource } from "src/api/tracker";
import { STANDARD_CONTENT_WIDTH } from "src/constants/ui";
import { useTrackEventEffect } from "src/hooks/useTrackEventEffect";
import styled from "styled-components";
import { MyReferralCodeRegular } from "./components/referrals/MyReferralCodeRegular";
import { MyReferralsTableContainer } from "./components/referrals/MyReferralsTableContainer";
import { SimpleNavBar2 } from "./components/SimpleNavBar2";
export interface PageReferralsV2Props {}

const ContentContainer = styled.div`
  max-width: ${STANDARD_CONTENT_WIDTH}px;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

const Section = styled.div`
  margin: 0px 0 200px 0px;
`;

export const PageReferralsV2: React.FC<PageReferralsV2Props> =
  (): ReactElement => {
    useTrackEventEffect(FoodieEvent.PAGE_REFERRAL, FoodieSource.UNKNOWN);
    return (
      <div className="page no-nav">
        {/* <SimpleNavBar2 title="refer friends" /> */}
        {/* <MyReferralCodeContainer /> */}
        <MyReferralCodeRegular />
        {/* <MyReferralsTableContainer /> */}
      </div>
    );
  };
