import React, { ReactElement } from "react";
import {
  faReceipt,
  faHome,
  faCog,
  faSignOut,
  faSignIn,
  faUserPlus,
} from "@fortawesome/pro-light-svg-icons";
import { faMoneyBill, faUtensils } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Feature } from "src/components/Feature";
import { FEATURES } from "src/constants";
import { UserType } from "src/hooks/useUserType";
import { assertUnreachable } from "src/util/asserts";
import { NavbarPopUpListItem } from "./NavBarPopUpListItem";

interface NavbarPopUpListProps {
  userType: UserType;
  onSignOut: () => void;
}

export const NavbarPopUpList: React.FC<NavbarPopUpListProps> = ({
  onSignOut,
  userType,
}): ReactElement => {
  // TODO: https://foodieearth.atlassian.net/browse/FOOD-271
  // Guest, Customer, and CustomerAdmin are overriden by NavbarSingleMeal. We
  // should consolidate
  switch (userType) {
    case UserType.Unknown:
    case UserType.Guest:
      return (
        <ul className="user-menu">
          <NavbarPopUpListItem
            label="sign in"
            icon={faSignIn}
            redirectTo="/signin"
          />
          <NavbarPopUpListItem
            label="sign up"
            icon={faUserPlus}
            redirectTo="/signup"
          />
          <NavbarPopUpListItem
            label="sign up as a company"
            icon={faUserPlus}
            redirectTo="/signup-company"
          />
        </ul>
      );
    case UserType.Driver:
    case UserType.Ops:
      return (
        <ul className="user-menu">
          <NavbarPopUpListItem
            label="sign out"
            icon={faSignOut}
            onClick={onSignOut}
          />
        </ul>
      );
    case UserType.Admin:
      return (
        <ul className="user-menu">
          <NavbarPopUpListItem label="home" icon={faHome} redirectTo="/" />
          <NavbarPopUpListItem label="admin view" redirectTo="/admin" />
          <NavbarPopUpListItem label="all users" redirectTo="/users" />
          <NavbarPopUpListItem label="full menu" redirectTo="/menu" />
          {/* <NavbarPopUpListItem label="my orders" icon={faReceipt} redirectTo="/orders" /> */}
          <Feature name={FEATURES.GIFT_CARD}>
            <NavbarPopUpListItem label="my credit" redirectTo="/my/credit" />
          </Feature>
          <NavbarPopUpListItem
            label="settings"
            icon={faCog}
            redirectTo="/setting"
          />
          <NavbarPopUpListItem
            label="sign out"
            icon={faSignOut}
            onClick={onSignOut}
          />
        </ul>
      );
    case UserType.CustomerGroupAdmin:
      return (
        <ul className="user-menu">
          <NavbarPopUpListItem label="home" icon={faHome} redirectTo="/" />
          <NavbarPopUpListItem
            label="Manage Group & Settings"
            redirectTo="/group-management"
          />
          <NavbarPopUpListItem
            label="Group Orders"
            redirectTo="/group-orders"
          />
          <NavbarPopUpListItem
            label="my orders"
            icon={faReceipt}
            redirectTo="/orders"
          />
          <Feature name={FEATURES.GIFT_CARD}>
            <NavbarPopUpListItem label="my credit" redirectTo="/my/credit" />
          </Feature>
          <NavbarPopUpListItem
            label="settings"
            icon={faCog}
            redirectTo="/setting"
          />
          <NavbarPopUpListItem
            label="sign out"
            icon={faSignOut}
            onClick={onSignOut}
          />
        </ul>
      );
    case UserType.Customer:
      return (
        <ul className="user-menu">
          <NavbarPopUpListItem label="home" icon={faHome} redirectTo="/" />
          <NavbarPopUpListItem
            label="my orders"
            icon={faReceipt}
            redirectTo="/orders"
          />
          <Feature name={FEATURES.GIFT_CARD}>
            <NavbarPopUpListItem label="my credit" redirectTo="/my/credit" />
          </Feature>
          <NavbarPopUpListItem
            label="settings"
            icon={faCog}
            redirectTo="/setting"
          />
          <NavbarPopUpListItem
            label="sign out"
            icon={faSignOut}
            onClick={onSignOut}
          />
        </ul>
      );
    case UserType.Restaurant:
      return (
        <ul className="user-menu">
          <NavbarPopUpListItem
            label="upcoming meal"
            icon={faHome}
            redirectTo="/restaurant"
          />
          <NavbarPopUpListItem
            label="manage meals"
            icon={faUtensils}
            redirectTo="/restaurant-meals"
          />
          <NavbarPopUpListItem
            label="financials"
            icon={faMoneyBill}
            redirectTo="/restaurants/financials/"
          />
          <NavbarPopUpListItem
            label="sign out"
            icon={faSignOut}
            onClick={onSignOut}
          />
        </ul>
      );
    default:
      assertUnreachable(userType);
  }
};
