import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "src/components/Button";
import { setIsLunch, setMealType } from "src/store/actions/adminDeliveryV2";
import styled from "styled-components";
import { MealFilter } from "./MealFilter";

const MealSelectionButton = styled.button`
  color: ${(props) => (props.isSelected ? "black" : "gray")};
  border-bottom: ${(props) => (props.isSelected ? "2px solid black" : null)};
  font-weight: ${(props) => (props.isSelected ? "500" : "300")};
  margin: 0 8px;
  padding: 12px 0;
`;

export const PickUpHeader = ({
  numLunchStops,
  numEarlyDinnerStops,
  numDinnerStops,
}) => {
  const mealType = useSelector(
    (state) => state.adminDeliveryV2.filters.mealType
  );

  const dispatch = useDispatch();
  const setMealToLunch = useCallback(() => {
    setMealType("lunch")(dispatch);
  }, [dispatch]);
  const setMealToEarlyDinner = useCallback(() => {
    setMealType("earlyDinner")(dispatch);
  }, [dispatch]);
  const setMealToDinner = useCallback(() => {
    setMealType("dinner")(dispatch);
  }, [dispatch]);

  return (
    <div style={{ margin: "4px 0" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <div style={{ margin: "8px" }}>
          <MealSelectionButton
            isSelected={mealType === "lunch"}
            type="button"
            onClick={setMealToLunch}
          >
            Lunch
          </MealSelectionButton>
          <MealSelectionButton
            isSelected={mealType === "earlyDinner"}
            type="button"
            onClick={setMealToEarlyDinner}
          >
            Early Dinner
          </MealSelectionButton>
          <MealSelectionButton
            isSelected={mealType === "dinner"}
            type="button"
            onClick={setMealToDinner}
          >
            Dinner
          </MealSelectionButton>
        </div>
      </div>
    </div>
  );
};
