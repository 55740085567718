import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
const Card = styled.div`
  background-color: white;
  padding: 16px 10px 8px 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 32px 0 rgb(28 29 33 / 12%);
  z-index: 1;
`;
const Button = styled.button`
  position: absolute;
  right: 10px;
  top: 0px;
  border: 1px solid black;
  padding: 5px 10px;
`;

const MetricRow = ({ label, value }) => {
  return (
    <div style={{ marginTop: "5px" }}>
      <b>{label}</b>: {value}
    </div>
  );
};

export const MealMetricSummaryCard = ({
  estimatedProfit,
  orderTotal,
  avgOrderSize,
  revenue,
  markupTotal,
  tipTotal,
  commissionTotal,
  serviceFeeTotal,
  estimatedCost,
  stripeTotalEstimated,
  driverCostTotal,
  etaTotal,
  avgMinutesPerOrder,
  showDetails,
  onToggleShowDetails,
}) => {
  if (orderTotal == null) return null;

  const pctMetricRowValue = (value) => {
    const valueDisplay = value.toFixed(2);
    const valuePctDisplay = ((value / orderTotal) * 100).toFixed(2);
    return `$${valueDisplay} = ${valuePctDisplay}%`;
  };

  const orderTotalDisplay = orderTotal.toFixed(2);
  const avgOrderSizeDisplay = avgOrderSize.toFixed(2);

  const revenueDisplay = revenue?.toFixed(2);
  const costDisplay = estimatedCost.toFixed(2);
  const profitDisplay = estimatedProfit?.toFixed(2);
  const profitPctDisplay = ((estimatedProfit / orderTotal) * 100).toFixed(2);

  return (
    <Card>
      <div>
        <div style={{ marginBottom: "12px", position: "relative" }}>
          <Button onClick={onToggleShowDetails}>
            <span>{`${showDetails ? "hide" : "show"} details`}</span>{" "}
            <FontAwesomeIcon icon={showDetails ? faChevronUp : faChevronDown} />
          </Button>
          <MetricRow label="Revenue" value={`$${revenueDisplay}`} />
          <MetricRow label="Total" value={`$${orderTotalDisplay}`} />
          {/* <MetricRow label="Basket" value={`$${avgOrderSizeDisplay}`} /> */}
          <MetricRow
            label="Profit"
            // value={`$${revenueDisplay} - ${costDisplay} = $${profitDisplay} = ${profitPctDisplay}%`}
            value={`${profitDisplay > 0 ? "" : "-"}$${Math.abs(
              profitDisplay
            )} (${profitPctDisplay}%)`}
          />
        </div>
        {showDetails ? (
          <>
            <div style={{ marginBottom: "12px" }}>
              <MetricRow
                label="Total Tip"
                value={pctMetricRowValue(tipTotal)}
              />
              <MetricRow
                label="Total Non-Member Markup"
                value={pctMetricRowValue(markupTotal)}
              />
              <MetricRow
                label="Total Commission"
                value={pctMetricRowValue(commissionTotal)}
              />
              <MetricRow
                label="Total Service Fee"
                value={pctMetricRowValue(serviceFeeTotal)}
              />
            </div>

            <div style={{ marginBottom: "12px" }}>
              <MetricRow
                label="Total Est. Stripe Cost"
                value={pctMetricRowValue(stripeTotalEstimated)}
              />
              <MetricRow
                label="Total Driver Cost"
                value={pctMetricRowValue(driverCostTotal)}
              />
            </div>

            <div>
              <MetricRow label="Total ETA" value={`${etaTotal}min`} />
              <MetricRow
                label="Avg Min Per Order"
                value={`${avgMinutesPerOrder.toFixed(2)}min`}
              />
            </div>
          </>
        ) : null}
      </div>
    </Card>
  );
};
