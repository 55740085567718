import React, { ReactElement, RefObject, useEffect, useRef } from 'react';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { Dish } from 'src/models';
import styled from 'styled-components';
import { CenteredByColumnLeft, TextUPPER } from '../../Shared';
// import { CATEGORY_CAROUSEL_SCREEN_TOP_OFFSET } from './CategoryCarouselV2';
import { DishV } from './Dish';
import { Category } from './DishList';

interface CategoryDishListProps {
  category: Category;
  isMobile: boolean; // Used for styling divs differently
}

const ContentContainer = styled(CenteredByColumnLeft)`
  width: 100%;
  margin-bottom: 20px;
`;
const DishListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 16px;
  width: 100%;
`;

const CategoryTitle = styled(TextUPPER)`
  /* font-size: 22px;
  font-weight: 700; */
  margin: 16px 0px 10px 24px;
  color: black;
  font-size: 16px;
`;

export const CategoryDishList: React.FC<CategoryDishListProps> = ({
  category,
  isMobile,
}: CategoryDishListProps): ReactElement => {
  const ref = useRef(null);
  const { width } = useWindowDimensions();
  // useEffect(() => {
  //   onSetCategoryRef(category.categoryName, ref);
  // }, [category.categoryName, onSetCategoryRef]);

  return (
    <ContentContainer>
      <CategoryTitle ref={ref}>{category.categoryName}</CategoryTitle>
      <DishListContainer>
        {category.dishes
          .sort((a, b) => ((a?.ordered || 0) > (b?.ordered || 0) ? -1 : 1))
          .map((dish) => {
            return <DishV key={dish._id} dish={dish} isMobile={isMobile} />;
          })}
      </DishListContainer>
    </ContentContainer>
  );
};
