import { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  getMonth,
  Loading,
  LOGGING,
  AccountingCalendar,
  EditRestaurantCommission,
  Navbar,
  PopUp,
  CommissionNames,
} from '.';
import { fetchMealsCommission, saveRestaurant } from '../../store/actions';
const resetRestaurant = { restaurant: { _id: null, name: null }, commissionType: 0 };
class PageStatsCalendar extends Component {
  constructor() {
    super();
    LOGGING && console.log('PageStatsCalendar constructor');
    this.state = {
      month: null,
      firstDayOfMonth: null,
      loading: true,
      showDetails: [false, false, false, false, false, false],
      editCommissionForRestaurant: { ...resetRestaurant },
    };
    this.handleMoveMonth = this.handleMoveMonth.bind(this);
    this.handleToggleShowDetails = this.handleToggleShowDetails.bind(this);
    this.handleShowEditCommission = this.handleShowEditCommission.bind(this);
    this.handleCancelEditCommission = this.handleCancelEditCommission.bind(this);
    this.handleChangeCommission = this.handleChangeCommission.bind(this);
    this.handleSaveCommission = this.handleSaveCommission.bind(this);
  }
  handleSaveCommission(e) {
    e.preventDefault();
    const {
      editCommissionForRestaurant,
      firstDayOfCalendar,
      lastDayOfCalendar,
      currentMonth,
      firstDayOfMonth,
    } = this.state;
    LOGGING &&
      console.log('handleChangeCommission called with:', {
        editCommissionForRestaurant,
      });

    this.setState({ loading: true, editCommissionForRestaurant: { ...resetRestaurant } }, () => {
      this.props.saveRestaurant(editCommissionForRestaurant.restaurant).then(() => {
        this.props
          .fetchMealsCommission({
            start: firstDayOfCalendar.startOf('d').valueOf(),
            end: lastDayOfCalendar.endOf('d').valueOf(),
          })
          .then(({ meals, weeks }) => {
            this.setState({
              weeks,
              meals,
              loading: false,
              currentMonth,
              firstDayOfMonth,
            });
          });
      });
    });
  }

  handleChangeCommission(restaurant, commissionType, e) {
    LOGGING &&
      console.log('handleChangeCommission called with:', {
        restaurant,
        commissionValue: e.target.value,
        commissionType,
      });
    const commissionName = CommissionNames[commissionType];
    const { meals, editCommissionForRestaurant } = this.state;
    const updatedRestaurant = { ...restaurant, [commissionName]: Number(e.target.value) };
    const updatedMeals = meals.map((m) =>
      m.restaurant._id === restaurant._id
        ? { ...m, restaurant: { ...updatedRestaurant } }
        : { ...m }
    );
    this.setState({
      meals: updatedMeals,
      editCommissionForRestaurant: {
        ...editCommissionForRestaurant,
        restaurant: updatedRestaurant,
      },
    });
  }

  handleShowEditCommission(restaurant, commissionType, e) {
    e.preventDefault();
    LOGGING && console.log('handleShowEditCommission called with:', { restaurant, commissionType });
    this.setState({ editCommissionForRestaurant: { restaurant, commissionType } });
  }

  handleCancelEditCommission(e) {
    e.preventDefault();
    this.setState({ editCommissionForRestaurant: { ...resetRestaurant } });
  }

  handleToggleShowDetails(index, e) {
    const { showDetails } = this.state;
    e.preventDefault();
    this.setState({ showDetails: showDetails.map((s, i) => (i === index ? !s : s)) });
  }

  handleMoveMonth(m, e) {
    LOGGING && console.log('handleMoveMonth called with m:', m);
    e.preventDefault();
    this.setState({ loading: true });
    const { firstDayOfMonth } = this.state;
    const now = moment();
    const firstDayOfNextMonth = firstDayOfMonth.clone().add(m, 'months');
    LOGGING &&
      console.log(
        'handleMoveMonth got firstDayOfNextMonth:',
        firstDayOfNextMonth.format('LLLL'),
        firstDayOfMonth.format('LLLL')
      );
    const { firstDayOfCalendar, lastDayOfCalendar, month, currentMonth } = getMonth(
      firstDayOfNextMonth,
      now
    );
    LOGGING && console.log('handleMoveMonth got currentMonth:', currentMonth);
    this.props
      .fetchMealsCommission({
        start: firstDayOfCalendar.startOf('d').valueOf(),
        end: lastDayOfCalendar.endOf('d').valueOf(),
      })
      .then(({ meals, weeks }) => {
        this.setState(
          {
            month,
            weeks,
            meals,
            currentMonth,
            loading: false,
            firstDayOfMonth: firstDayOfNextMonth,
            firstDayOfCalendar,
            lastDayOfCalendar,
          },
          () => {
            window.scrollTo(0, 0);
          }
        );
      });
  }

  componentDidMount() {
    LOGGING && console.log('PageStatsCalendar componentdidmount');
    const now = moment();
    const firstDayOfMonth = now.clone().startOf('month');
    const { firstDayOfCalendar, lastDayOfCalendar, month, currentMonth } = getMonth(
      firstDayOfMonth,
      now
    );
    this.setState({ loading: true, firstDayOfCalendar, lastDayOfCalendar });

    this.props
      .fetchMealsCommission({
        start: firstDayOfCalendar.startOf('d').valueOf(),
        end: lastDayOfCalendar.endOf('d').valueOf(),
      })
      .then(({ meals, weeks }) => {
        this.setState({
          month,
          weeks,
          meals,
          currentMonth,
          loading: false,
          firstDayOfMonth,
        });
      });
  }

  render() {
    const { currentUser } = this.props;
    const { currentMonth, loading, month, showDetails, meals, weeks, editCommissionForRestaurant } =
      this.state;
    LOGGING &&
      console.log('PageStatsCalendar rendering with:', {
        props: this.props,
        state: this.state,
      });

    if (!currentUser.isAuthenticated || !currentUser.user.isAdmin) {
      return <Redirect to="/" />;
    }

    return (
      <div className="page">
        <Navbar noShadow={true} />
        {loading ? <Loading transparent={true} /> : null}
        {month ? (
          <AccountingCalendar
            currentMonth={currentMonth}
            meals={meals}
            month={month}
            weeks={weeks}
            onMoveMonth={this.handleMoveMonth}
            showDetails={showDetails}
            onToggleShowDetails={this.handleToggleShowDetails}
            onShowEditCommission={this.handleShowEditCommission}
          />
        ) : null}
        <PopUp
          isPoppedUp={editCommissionForRestaurant.restaurant?._id}
          componentToDisplay={
            <EditRestaurantCommission
              restaurant={editCommissionForRestaurant.restaurant}
              commissionType={editCommissionForRestaurant.commissionType}
              onChange={this.handleChangeCommission}
              onSave={this.handleSaveCommission}
            />
          }
          backgroundColor={'#333'}
          hidePopUp={this.handleCancelEditCommission}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log('PageStatsCalendar got redux state:', state);
  return {
    currentUser: state.currentUser,
    restaurants: state.restaurants,
    deliveryZones: state.deliveryZones,
    meals: state.meals,
    dayOrders: state.dayOrders,
  };
}

export default connect(mapStateToProps, {
  fetchMealsCommission,
  saveRestaurant,
})(PageStatsCalendar);
