import React, { ReactElement, useMemo } from "react";
import moment from "moment-timezone";
import { Column, useTable } from "react-table";
import { MealSerialized } from "src/api/meals";
import styled from "styled-components";

const LINK_PREFIX = "https://foodie.earth";

export interface RestaurantPastMealsProps {
  meals: MealSerialized[];
}

type TableData = {
  date: string;
  hour: string;
  numOrders: number;
  netPayout: string;
  link: string;
};

const Container = styled.div`
  display: flex;
`;

export const RestaurantPastMeals: React.FC<RestaurantPastMealsProps> = ({
  meals,
}): ReactElement => {
  const sortedMealsDescendingTime = meals.sort(
    (a, b) => b.windowStart - a.windowStart
  );

  const tableData = useMemo<TableData[]>(
    () =>
      sortedMealsDescendingTime.map((meal) => {
        const date = moment(meal.windowStart)
          .tz("America/Los_Angeles")
          .format("MM/DD/YY");
        const timeOfDay = moment(meal.windowStart)
          .tz("America/Los_Angeles")
          .format("h:mmA");
        return {
          date: date,
          hour: timeOfDay,
          numOrders: meal.stats.numOrders,
          netPayout: `$${meal.stats.netPayout.toFixed(2)}`,
          link: `${LINK_PREFIX}/restaurant-past-meal/${meal.restaurant._id}/${meal._id}`,
        };
      }),
    [sortedMealsDescendingTime]
  );

  const columns = useMemo<Column<TableData>[]>(
    () => [
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Hour",
        accessor: "hour",
      },
      {
        Header: "# Orders",
        accessor: "numOrders",
      },
      {
        Header: "Net Payout",
        accessor: "netPayout",
      },
      {
        Header: "Details",
        accessor: "link",
        Cell: ({ row }) => (
          <a
            href={row.values.link}
            style={{ textDecoration: "underline", color: "blue" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            link
          </a>
        ),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: tableData,
    });

  return (
    <Container>
      {sortedMealsDescendingTime.length > 0 ? (
        <table {...getTableProps()} style={{ border: "solid 1px black" }}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      background: "aliceblue",
                      color: "black",
                      fontWeight: "bold",
                      padding: "8px",
                    }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "8px",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div>No past meals...</div>
      )}
    </Container>
  );
};
