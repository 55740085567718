import React, { ReactElement, useCallback } from 'react';
import { useAsync } from 'react-async';
import Select from 'react-dropdown-select';
import { GetCategoriesByRestaurantId, SerializedCategory } from 'src/api/categories';
import { ObjectId } from 'src/constants/types';
import { Loading } from '..';

type CategorySelectorProps = {
  restaurantId: ObjectId;
  selectedCategories: SerializedCategory[];
  onSelectCategories: (categories: SerializedCategory[]) => void;
};

export const CategorySelector: React.FC<CategorySelectorProps> = ({
  restaurantId,
  selectedCategories,
  onSelectCategories,
}): ReactElement => {
  const fetch = useCallback(async () => {
    return await GetCategoriesByRestaurantId(restaurantId);
  }, [restaurantId]);
  const { data } = useAsync({ promiseFn: fetch });

  if (data == null) return <Loading />;

  const categories = data.categories;

  return (
    <Select
      options={categories}
      onChange={onSelectCategories}
      labelField="name"
      valueField="_id"
      values={selectedCategories}
      multi={true}
    />
  );
};
