import { LOAD_FAVORITES, RESET_FAVORITES } from '../actionTypes';

const favorites = (state = [], action) => {
  switch (action.type) {
    case LOAD_FAVORITES:
      return [...action.favorites];
    case RESET_FAVORITES:
      return [];
    default:
      return state;
  }
};

export default favorites;
