import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getAdminDeliveryV2StopsForDay } from 'src/store/actions/adminDeliveryV2';
import { AwaitServerResponseContainer } from './components/AwaitServerResponseContainer';
import { DeliveryV2 } from './components/DeliveryV2';
import Navbar from './Navbar';

export const PageDeliveryV2 = () => {
  const fetchDataQuery = useCallback(async (dispatch, getState) => {
    await getAdminDeliveryV2StopsForDay()(dispatch, getState);
  }, []);
  const currentUser = useSelector((state) => state.currentUser);

  if (!currentUser.isAuthenticated || !currentUser.user.isAdmin) {
    return <Redirect to="/" />;
  }

  return (
    <div className="page">
      <Navbar noShadow={true} />
      <AwaitServerResponseContainer awaitable={fetchDataQuery}>
        <DeliveryV2 />
      </AwaitServerResponseContainer>
    </div>
  );
};
