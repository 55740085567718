import { Component } from "react";
import creditCardType from "credit-card-type";
import moment from "moment-timezone";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { FoodieEvent, FoodieSource, TrackEvent } from "src/api/tracker";
import { addressIncludedInMealDeliveryZones } from "src/constants";
import validator from "validator";
import {
  LOGGING,
  Checkout,
  SupportedDeliveryCitiesStr,
  SupportedSanJoseZipCodes,
  IsAddressSupportedForDelivery,
} from "./";
import {
  saveSetting,
  payCartWithNewCard,
  payCartWithExistingCard,
  paidCartWithPaypal,
  signOut,
  deleteCardFromOrder,
  updateOrder,
  submitFreeMeal,
  payCartWithCompanyCard,
  checkPreorderInventoryAvailablity,
  getAddressList,
  fetchDeliveryZoneByCity,
  checkOrderCount,
  startMembership,
  resumeMembership,
  applyCouponCode,
  changeTips,
  updateOrderOnCart,
} from "../../store/actions";
class PageCheckout extends Component {
  constructor(props) {
    super();
    const { currentUser, cart } = props;
    LOGGING &&
      console.log("PageCheckout constructor got:", { currentUser, cart });
    const canMakeGroupOrder =
      currentUser.user.userGroup != null &&
      currentUser.user.userGroup.serviceFee === undefined;
    this.state = {
      applyingCoupon: false,
      paying: false,
      initialTip: null,
      isEditingTip: false,
      showSurvey: true,
      saveCard: currentUser.isAuthenticated,
      showCardAction: false,
      error: {},
      couponCodeError: "",
      paypalInfo: null,
      payPalOrderAddressConfirmed: Boolean(
        currentUser.isAuthenticated &&
          currentUser.user.deliveryInfo &&
          currentUser.user.deliveryInfo.address &&
          currentUser.user.deliveryInfo.address.length > 0
      ),
      paymentMethod: canMakeGroupOrder ? "group" : "card",
      existingCard:
        currentUser.isAuthenticated &&
        currentUser.user.stripeInfo &&
        currentUser.user.stripeInfo.card &&
        Object.keys(currentUser.user.stripeInfo.card).length > 0 &&
        currentUser.user.stripeInfo.paymentMethodId
          ? { ...currentUser.user.stripeInfo.card }
          : null,
      newCard: {
        number: "",
        cvc: "",
        exp_month: "",
        exp_year: "",
      },
      cardType: null,
      deliveryInfo:
        cart.companyBudget &&
        currentUser.isAuthenticated &&
        currentUser.user?.userGroup?.deliveryInfo
          ? {
              ...currentUser.user.userGroup.deliveryInfo,
              name: `${currentUser.user?.firstName} ${currentUser.user?.lastName} (${currentUser.user?.userGroup?.deliveryInfo?.name})`,
            }
          : currentUser.isAuthenticated && currentUser.user.deliveryInfo
          ? {
              ...currentUser.user.deliveryInfo,
              ring_doorbell:
                currentUser.user.deliveryInfo.ring_doorbell === false
                  ? false
                  : true,
            }
          : {
              phone: currentUser.user.phone || "",
              name: currentUser.isAuthenticated
                ? `${currentUser.user.firstName} ${currentUser.user.lastName}`
                : "",
              address_room_info: "",
              address_supported_for_delivery: false,
              address_validated: false,
              address: "",
              email:
                currentUser.isAuthenticated && currentUser.user.email
                  ? currentUser.user.email
                  : "",
              ring_doorbell: true,
              note: "",
            },
      canMakeGroupOrder,
      showOrderConfirmation: cart?._id,
      isEditingDeliveryPaymentInfo: false,
    };
    this.handleToggleEditDeliveryPaymentInfo =
      this.handleToggleEditDeliveryPaymentInfo.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeNumber = this.handleChangeNumber.bind(this);
    this.handleSubmitFreeMeal = this.handleSubmitFreeMeal.bind(this);
    this.handleSubmitOrderWithCard = this.handleSubmitOrderWithCard.bind(this);
    this.handleSubmitOrderWithCompanyCard =
      this.handleSubmitOrderWithCompanyCard.bind(this);
    this.handleToggleSaveCard = this.handleToggleSaveCard.bind(this);
    this.handleToggleRingBell = this.handleToggleRingBell.bind(this);
    this.handleToggleShowCardAction =
      this.handleToggleShowCardAction.bind(this);
    this.handleDeleteCard = this.handleDeleteCard.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handlePaypalAuthorize = this.handlePaypalAuthorize.bind(this);
    this.handleTogglePaymentMethod = this.handleTogglePaymentMethod.bind(this);
    this.handlePaypalCapturing = this.handlePaypalCapturing.bind(this);
    this.handleEditAddress = this.handleEditAddress.bind(this);
    this.handleSelectAddress = this.handleSelectAddress.bind(this);
    this.handleConfirmPaypalAddress =
      this.handleConfirmPaypalAddress.bind(this);
    this.handleEditPaypalAddress = this.handleEditPaypalAddress.bind(this);
    this.validateDeliveryZone = this.validateDeliveryZone.bind(this);
    this.handleStartMembership = this.handleStartMembership.bind(this);
    this.handleResumeMembership = this.handleResumeMembership.bind(this);
    this.handleSkipSurvey = this.handleSkipSurvey.bind(this);
    this.handleChangeTips = this.handleChangeTips.bind(this);
    this.handleApplyCouponCode = this.handleApplyCouponCode.bind(this);
    this.handleSetIsCartValid = this.handleSetIsCartValid.bind(this);
  }
  handleSetIsCartValid(isCartValid) {
    this.setState({ isCartValid });
  }
  async handleApplyCouponCode(code) {
    const { cart } = this.props;
    this.setState({ applyingCoupon: true });
    this.props
      .applyCouponCode(cart, code, "cart", true)
      .then((updatedCart) => {
        const { totalNotEnoughForCoupon } = updatedCart;
        this.setState({
          applyingCoupon: false,
          couponCodeError:
            totalNotEnoughForCoupon > 0
              ? `Cart needs to be $${totalNotEnoughForCoupon}+ to use ${code}!`
              : "",
          cart: updatedCart,
          stickyError: true,
        });
      })
      .catch((err) => {
        const { message } = err;
        this.setState({
          cart: { ...cart, couponCode: null },
          couponCodeError: message,
          applyingCoupon: false,
        });
      });
  }
  handleToggleEditDeliveryPaymentInfo(e) {
    e.preventDefault();
    const { isEditingDeliveryPaymentInfo } = this.state;
    this.setState({
      isEditingDeliveryPaymentInfo: !isEditingDeliveryPaymentInfo,
    });
  }
  handleChangeTips(tips, tipRate, tipCustom, isEditingTip = false) {
    LOGGING &&
      console.log("PageCart handleChangeTips: ", {
        tips,
        tipRate,
        tipCustom,
        isEditingTip,
      });
    this.setState({ isEditingTip, initializing: true });
    this.props.changeTips(tips, tipRate, tipCustom).then((updatedCart) => {
      LOGGING &&
        console.log("PageCart updatedCart: ", {
          updatedCart,
        });
      const { couponCode } = updatedCart;
      if (couponCode) {
        this.props
          .applyCouponCode(updatedCart, couponCode.code, "cart", false)
          .then((updatedCart) => {
            const { totalNotEnoughForCoupon } = updatedCart;
            this.setState({
              couponCodeError:
                totalNotEnoughForCoupon > 0
                  ? `Cart needs to be $${totalNotEnoughForCoupon}+ to use ${couponCode.code}!`
                  : "",
              cart: updatedCart,
              stickyError: true,
            });
          })
          .catch((err) => {
            this.setState({
              cart: { ...updatedCart, couponCode: null },
              couponCodeError: err.message,
              stickyError: false,
            });
          });
      } else {
        LOGGING && console.log("PageCart updatedCart2: ", updatedCart);
        this.setState({
          cart: { ...updatedCart },
        });
      }
      this.setState({ cart: updatedCart }, () => {
        try {
          this.props.updateOrder().then(() => {
            LOGGING && console.log("PageCart updatedCart3: ", updatedCart);
            this.setState({ isEditingTip: false, initializing: false });
          });
        } catch (err) {
          LOGGING && console.log("updateOrderOnCart got err:", err);
          toast.error(err.message);
          this.setState({ isEditingTip: false, initializing: false });
        }
      });
    });
  }

  handleSkipSurvey(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showSurvey: false });
  }
  handleStartMembership() {
    this.setState({ loading: true }, () => {
      this.props.startMembership().then(() => {
        this.setState({ loading: false });
      });
    });
  }
  handleResumeMembership() {
    this.setState({ loading: true }, () => {
      this.props.resumeMembership().then(() => {
        this.setState({ loading: false });
      });
    });
  }
  async validateDeliveryZone() {
    const { isWhitelistedAddress, deliveryInfo } = this.state;
    if (isWhitelistedAddress) return true;
    var supportedDeliveryZone = this.props.cart.deliveryZone;
    LOGGING &&
      console.log(
        "validateDeliveryZone called with this.props.cart",
        this.props.cart
      );
    const { address } = deliveryInfo;
    const { userGroup } = this.props.cart;
    var deliveryZone = null;
    await this.props.fetchDeliveryZoneByCity();
    const { deliveryZoneByCity } = this.props;
    LOGGING &&
      console.log("validateDeliveryZone got", {
        deliveryZoneByCity,
        address,
        userGroup,
      });
    for (const city in deliveryZoneByCity) {
      LOGGING && console.log("validateDeliveryZone looping:", city);
      if (
        address.includes(city) ||
        userGroup?.name.toLowerCase() === city.toLowerCase() ||
        (city === "San Jose" &&
          address.includes("San Jose") &&
          SupportedSanJoseZipCodes.some((zip) => address.includes(zip)))
      ) {
        deliveryZone = deliveryZoneByCity[city];
        break;
      }
    }
    const supportedCities = Object.keys(deliveryZoneByCity)
      .filter((city) =>
        supportedDeliveryZone.includes(deliveryZoneByCity[city])
      )
      .join(", ");
    LOGGING &&
      console.log("validateDeliveryZone GOT", {
        supportedCities,
        supportedDeliveryZone,
        deliveryZone,
        deliveryZoneByCity,
      });

    if (
      deliveryZone === null ||
      !supportedDeliveryZone.includes(deliveryZone)
    ) {
      this.setState({
        error: {
          param: "address",
          message: "The selected menu does not deliver to your address.",
        },
      });
      return false;
    }
    return true;
  }

  async validateDeliveryInfo() {
    const {
      phone,
      address,
      name,
      email,
      address_supported_for_delivery,
      address_validated,
    } = this.state.deliveryInfo;
    LOGGING &&
      console.log(
        "validateDeliveryInfo called with: ",
        this.state.deliveryInfo
      );
    if (typeof name === "undefined" || name.length === 0) {
      this.setState({
        error: { param: "name", message: "name can't be empty" },
      });
      return false;
    }
    LOGGING && console.log("validateDeliveryInfo called1");
    if (typeof email === "undefined" || email.length === 0) {
      this.setState({
        error: { param: "email", message: "email can't be empty" },
      });
      return false;
    }
    LOGGING && console.log("validateDeliveryInfo called2");
    if (!validator.isEmail(email)) {
      this.setState({
        error: { param: "email", message: "Please provide a valid email" },
      });
      return false;
    }
    LOGGING && console.log("validateDeliveryInfo called3");
    if (typeof phone === "undefined") {
      this.setState({
        error: { param: "phone", message: "phone can't be empty" },
      });
      return false;
    }
    LOGGING && console.log("validateDeliveryInfo called4");
    if (!validator.isMobilePhone(phone, ["en-US"])) {
      this.setState({
        error: { param: "phone", message: "Please provide a valid phone" },
      });
      return false;
    }
    LOGGING && console.log("validateDeliveryInfo called5");
    if (typeof address === "undefined" || address.length === 0) {
      this.setState({
        error: { param: "address", message: "address can't be empty" },
      });
      return false;
    }
    LOGGING && console.log("validateDeliveryInfo called6");
    if (!address_validated) {
      this.setState({
        error: {
          param: "address",
          message:
            "Please select an address from suggestions to check delivery eligibility.",
        },
      });
      return false;
    }
    LOGGING &&
      console.log(
        "validateDeliveryInfo called7:",
        address_supported_for_delivery
      );
    if (!address_supported_for_delivery) {
      this.setState({
        error: {
          param: "address",
          message:
            "Sorry, your address currently is not supported for delivery yet.",
        },
      });
      return false;
    }
    LOGGING && console.log("validateDeliveryInfo called8");
    if (this.props.addressBlackList.includes(address)) {
      this.setState({
        error: {
          param: "address",
          message: `Sorry your address ${address} does not currently support delivery.`,
        },
      });
      return false;
    }
    LOGGING && console.log("validateDeliveryInfo called9");
    LOGGING && console.log("validateDeliveryZone calling");
    if (!(await this.validateDeliveryZone())) {
      return false;
    }

    return true;
  }
  handleEditPaypalAddress(e) {
    e.preventDefault();
    this.setState({ payPalOrderAddressConfirmed: false });
  }
  async handleConfirmPaypalAddress(e) {
    e.preventDefault();
    if (!(await this.validateDeliveryInfo())) {
      return;
    }
    this.setState({ payPalOrderAddressConfirmed: true }, () => {
      window.scrollTo(0, 0);
    });
  }

  handleEditAddress(address) {
    const { deliveryInfo } = this.state;
    LOGGING && console.log("handleEditAddress called with ", address);
    const { error } = this.state;
    LOGGING && console.log(error);
    const updatedError = error && "address" === error.param ? {} : error;
    this.setState({
      deliveryInfo: {
        ...deliveryInfo,
        address,
        address_supported_for_delivery: false,
        address_validated: false,
      },
      error: updatedError,
    });
  }
  handleSelectAddress(address) {
    LOGGING &&
      console.log(
        "PageCheckout called handleSelectAddress with address:",
        address
      );

    const { error } = this.state;
    const updatedError = error && "address" === error.param ? {} : error;
    this.setState({
      error: updatedError,
    });
    if (address) {
      const city = address.gmaps.address_components.find((c) =>
        c.types.includes("locality")
      )?.short_name;
      const number = address.gmaps.address_components.find((c) =>
        c.types.includes("street_number")
      );
      const number_str = typeof number === "undefined" ? "" : number.short_name;
      const street = address.gmaps.address_components.find(
        (c) => c.types.includes("route") || c.types.includes("premise")
      )?.short_name;
      const state = address.gmaps.address_components.find((c) =>
        c.types.includes("administrative_area_level_1")
      )?.short_name;
      const zip = address.gmaps.address_components.find((c) =>
        c.types.includes("postal_code")
      )?.short_name;
      const resultAddress = `${number_str} ${street || ""}, ${city || ""}, ${
        state || ""
      } ${zip || ""}`;
      LOGGING &&
        console.log("handleSelectAddress got resultAddress:", resultAddress);
      if (this.props.addressBlackList.includes(address.label)) {
        this.setState({
          error: {
            param: "address",
            message: `Sorry your address ${address.label} does not support delivery now`,
          },
          address_validated: true,
          address_supported_for_delivery: false,
        });
        return;
      }
      const isWhitelistedAddress = this.props.addressWhiteList.includes(
        address.label
      );
      if (
        !isWhitelistedAddress &&
        !IsAddressSupportedForDelivery(address.gmaps.address_components)
      ) {
        this.setState({
          error: {
            param: "address",
            message: `Sorry, we currently only deliver to ${SupportedDeliveryCitiesStr}. We look forward to delivering to your area in ${city} soon!`,
          },
          address_validated: true,
          address_supported_for_delivery: false,
        });
        return;
      }
      if (
        !isWhitelistedAddress &&
        !addressIncludedInMealDeliveryZones(
          this.props.cart?.meal?.supportedDeliveryZone,
          address.gmaps.address_components
        )
      ) {
        this.setState({
          error: {
            param: "address",
            message: `Sorry, we are not delivering this meal to ${city} today.`,
          },
          address_validated: true,
          address_supported_for_delivery: false,
        });
        return;
      }
      // const placeId = address.gmaps.place_id;
      LOGGING && console.log("selected: ", resultAddress);
      const { deliveryInfo } = this.state;
      this.setState({
        isWhitelistedAddress,
        deliveryInfo: {
          ...deliveryInfo,
          address: resultAddress,
          address_supported_for_delivery: true,
          address_validated: true,
        },
      });
    }
  }

  handlePaypalCapturing() {
    LOGGING && console.log("handlePaypalCapturing called");
    this.setState({ loading: true });
  }
  handleTogglePaymentMethod(method) {
    console.log("handleTogglePaymentMethod: ", method);
    this.setState({ paymentMethod: method });
  }
  handlePaypalAuthorize(captureId) {
    LOGGING &&
      console.log("PageCheckout handlePaypalAuthorize called with:", captureId);
    const { deliveryInfo } = this.state;
    this.props.paidCartWithPaypal(captureId, deliveryInfo).then((result) => {
      LOGGING &&
        console.log("PageCheckout paidCartWithPaypal done with:", result);
      this.props.history.push("/post-checkout");
    });
  }
  handleClose() {
    LOGGING && console.log("PageCheckout handleClose");
    this.props.history.goBack();
  }
  handleDeleteCard(e) {
    e.preventDefault();
    this.setState({ loading: true, showCardAction: false });
    this.props.deleteCardFromOrder().then(() => {
      this.setState({
        loading: false,
        existingCard: null,
      });
    });
  }
  handleToggleShowCardAction(e) {
    e.preventDefault();
    const { showCardAction } = this.state;
    this.setState({ showCardAction: !showCardAction });
  }
  handleChange(sectionName, e) {
    e.preventDefault();
    const { error } = this.state;
    const updatedField = { [e.target.name]: e.target.value };
    const oldFields = this.state[sectionName];
    LOGGING &&
      console.log("handleChange with:", {
        sectionName,
        oldFields,
        updatedField,
      });
    const updatedError = error && e.target.name === error.param ? {} : error;

    this.setState({
      [sectionName]: {
        ...oldFields,
        ...updatedField,
      },
      error: updatedError,
    });
  }

  handleChangeNumber(sectionName, field, values) {
    const { error } = this.state;
    LOGGING &&
      console.log("handleChangeNumber called with", {
        sectionName,
        field,
        values,
      });

    const updatedField = { [field]: field === "phone" ? values : values.value };
    const oldFields = this.state[sectionName];
    LOGGING &&
      console.log("handleChangeNumber with:", {
        sectionName,
        error,
        field,
        values,
        updatedField,
        oldFields,
      });
    const updatedError = error && field === error.param ? {} : error;
    LOGGING && console.log("handleChangeNumber updatedError:", updatedError);
    if (field === "number") {
      const cardTypes = creditCardType(values.value);
      if (cardTypes.length === 0) {
        return;
      }
      const { lengths, gaps, code, type, niceType } = cardTypes[0];

      let cardFormat = lengths.length === 1 ? Array(lengths[0]).fill("#") : [];
      gaps.reverse().forEach((position) => cardFormat.splice(position, 0, "-"));
      LOGGING &&
        console.log("handleChangeNumber creditCardType:", {
          lengths,
          gaps,
          code,
          type,
          niceType,
        });
      this.setState({
        [sectionName]: { ...oldFields, ...updatedField },
        error: updatedError,
        cardType: {
          format: null, //cardFormat.join(""),
          length: lengths.length === 1 ? lengths[0] : null,
          code: { ...code, format: Array(code.size).fill("#").join("") },
          type: values.value.length === 0 ? null : type,
          niceType: values.value.length === 0 ? null : niceType,
        },
      });
    } else {
      this.setState({
        [sectionName]: { ...oldFields, ...updatedField },
        error: updatedError,
      });
    }
  }

  async handleSubmitFreeMeal(e) {
    e.preventDefault();
    const { deliveryInfo } = this.state;
    LOGGING &&
      console.log(
        "handleSubmitFreeMeal called with deliveryInfo:",
        deliveryInfo
      );
    if (!(await this.validateDeliveryInfo())) {
      return;
    }

    this.setState({ loading: true, paying: true });
    this.props
      .submitFreeMeal(deliveryInfo)
      .then(() => {
        this.setState({ loading: false, paying: false }, () => {
          this.props.history.push("/post-checkout");
        });
      })
      .catch((error) => {
        LOGGING &&
          console.log("handleSubmitFreeMeal returned with error:", error);
        this.setState({ error, loading: false, paying: false });
      });
  }

  handleSubmitOrderWithCompanyCard(e) {
    e.preventDefault();
    this.setState({ loading: true });
    this.props
      .payCartWithCompanyCard()
      .then(() => {
        this.setState({ loading: false }, () => {
          this.props.history.push("/");
        });
      })
      .catch((error) => {
        LOGGING &&
          console.log(
            "handleSubmitOrderWithCompanyCard returned with error:",
            error
          );
        this.setState({ error, loading: false });
      });
  }

  async handleSubmitOrderWithCard(e) {
    e.preventDefault();
    const { newCard, saveCard, existingCard, deliveryInfo, cardType } =
      this.state;
    LOGGING &&
      console.log("handleSubmit called with: ", {
        newCard,
        saveCard,
        existingCard,
        deliveryInfo,
        cardType,
      });
    const { number, cvc, exp_month, exp_year } = newCard;
    const {
      phone,
      address,
      address_room_info,
      name,
      email,
      address_supported_for_delivery,
      address_validated,
      note,
    } = deliveryInfo;

    if (!(await this.validateDeliveryInfo())) {
      return;
    }
    if (existingCard) {
      this.setState({ loading: true, paying: true });
      this.props
        .payCartWithExistingCard(deliveryInfo)
        .then(() => {
          LOGGING && console.log("done with payCartWithExistingCard");
          // toast.success("Order placed!");
          this.props.history.push("/post-checkout");
        })
        .catch((error) => {
          LOGGING &&
            console.log("payCartWithExistingCard returned with error:", error);
          toast.error(error.message);
          this.setState({
            error,
            loading: false,
            paying: false,
          });
        });
    } else {
      LOGGING && console.log("paying with new card");
      if (cardType && number.length < cardType.length) {
        this.setState({
          error: {
            param: "number",
            message: `${cardType.niceType} card has to be ${cardType.length}-digit`,
          },
        });
        return;
      }

      if (cardType && cvc.length < cardType.code.size) {
        this.setState({
          error: {
            param: "cvc",
            message: `${cardType.code.name} is ${cardType.code.size}-digit`,
          },
        });
        return;
      }
      if (
        exp_month.length < 2 ||
        Number(exp_month) > 12 ||
        Number(exp_month) < 1
      ) {
        this.setState({
          error: { param: "exp_month", message: "exp_month is invalid" },
        });
        return;
      }
      if (exp_year.length < 2 || 2000 + Number(exp_year) < 2020) {
        this.setState({
          error: { param: "exp_year", message: "exp_year is invalid" },
        });
        return;
      }

      this.setState({ loading: true, paying: true });
      this.props
        .payCartWithNewCard(
          newCard,
          {
            address,
            address_room_info,
            phone,
            name,
            email,
            address_supported_for_delivery,
            address_validated,
            note,
          },
          saveCard
        )
        .then(() => {
          this.setState({ loading: false, paying: false }, () => {
            this.props.history.push("/post-checkout");
          });
        })
        .catch((error) => {
          LOGGING &&
            console.log("payCartWithNewCard returned with error:", error);
          window.scrollTo(0, 0);
          this.setState({ error, loading: false, paying: false });
        });
    }
  }

  handlePaypalSuccess = (paypalInfo) => {
    LOGGING &&
      console.log("handlePaypalSuccess called with paypalInfo:", paypalInfo);
  };

  handleToggleSaveCard(e) {
    LOGGING && console.log("handleToggleSaveCard called");
    e.preventDefault();

    const { saveCard } = this.state;
    LOGGING &&
      console.log("handleToggleSaveCard called with saveCard:", saveCard);
    this.setState({ saveCard: !saveCard });
  }

  handleToggleRingBell(e) {
    LOGGING && console.log("handleToggleRingBell called");
    e.preventDefault();
    const { ring_doorbell } = this.state.deliveryInfo;
    LOGGING &&
      console.log("handleToggleRingBell called with ringBell:", ring_doorbell);
    if (ring_doorbell == null) {
      const { deliveryInfo } = this.state;
      this.setState({
        deliveryInfo: {
          ...deliveryInfo,
          ring_doorbell: true,
        },
      });
    } else {
      const { deliveryInfo } = this.state;
      this.setState({
        deliveryInfo: {
          ...deliveryInfo,
          ring_doorbell: !ring_doorbell,
        },
      });
    }
  }

  trackPageCheckoutEvent(endAt, startAt) {
    const uuid = `${FoodieEvent.PAGE_CHECKOUT}${startAt}`;
    TrackEvent(
      FoodieEvent.PAGE_CHECKOUT,
      uuid,
      FoodieSource.UNKNOWN,
      this.props.currentUser?.user?._id,
      this.props.cart?.meal?._id,
      endAt > startAt ? endAt - startAt : -1
    );
  }

  componentDidMount() {
    const startAt = moment().valueOf();
    this.trackPageCheckoutEvent(-1, startAt);
    window.scrollTo(0, 0);

    LOGGING && console.log("PageCheckout componentdidmount");
    this.setState({ initializing: true }, () => {
      this.props
        .updateOrder()
        .then((cart) => {
          LOGGING && console.log("PageCheckout done with updateOrder", cart);
          const { couponCode } = cart;
          if (couponCode?.user) {
            this.props.checkOrderCount().then((orderCount) => {
              LOGGING &&
                console.log("PageCheckout got orderCount:", orderCount);
              if (orderCount > 0) {
                this.props.history.push("/cart");
              }
            });
          }
          window.scrollTo(0, 0);
          this.setState({ initializing: false, initialTip: cart?.tips });
          this.trackPageCheckoutEvent(moment().valueOf(), startAt);
        })
        .catch((error) => {
          LOGGING && console.log("PageCheckout updateOrder error:", error);
          toast.error(error.message);
        });
    });

    // this.props
    //   .checkPreorderInventoryAvailablity()
    //   .then(() => {})
    //   .catch((error) => {
    //     this.setState({
    //       error: { param: 'name', message: error.message },
    //     });
    //   });
    this.props.getAddressList();
  }
  render() {
    const { cart, currentUser } = this.props;
    const {
      newCard,
      loading,
      error,
      saveCard,
      existingCard,
      deliveryInfo,
      showCardAction,
      cardType,
      paypalInfo,
      paymentMethod,
      payPalOrderAddressConfirmed,
      canMakeGroupOrder,
      showOrderConfirmation,
      showSurvey,
      initializing,
      initialTip,
      isEditingTip,
      isEditingDeliveryPaymentInfo,
      couponCodeError,
      applyingCoupon,
      paying,
      isCartValid,
    } = this.state;

    LOGGING &&
      console.log("PageCheckout rendering with", {
        props: this.props,
        state: this.state,
      });
    // if (
    //   !cart.promo &&
    //   (!cart.total ||
    //     (!cart.userGroup &&
    //       cart?.meal?.restaurant?.minOrderValue &&
    //       cart?.total < cart?.meal?.restaurant?.minOrderValue))
    // ) {
    //   // For whatever reason, we use this to redirect to home after an order has been made,
    //   // since we reset the cart after the order is paid.
    //   // Anyways, we're trying to differentiate an actual bug from "intended behavior".
    //   // So if the cart's sum is not 0, thus there is an existing cart but for whatever
    //   // reason other data could not be loaded, we'll log an error
    //   if (cart.sum !== 0) {
    //     reportBug(
    //       `PageCheckout no cart total or minOrderValue for cart ${cart?._id} and user ${currentUser?.user?._id} and cart total ${cart?.total}`
    //     );
    //   }
    //   LOGGING && console.log("PageCheckout redirecting to postcheckout");
    //   return (
    //     <Redirect
    //       to={{
    //         pathname: "/post-checkout",
    //         // pathname: "/",
    //         // state: { showOrderConfirmation },
    //       }}
    //     />
    //   );
    // }

    const mealHour = moment(cart?.window?.start).hour();
    const mealType =
      mealHour < 12 ? "lunch" : mealHour < 17 ? "earlyDinner" : "dinner";
    const cutOffBeforeDelivery =
      mealType === "lunch" ? 60 : mealType === "earlyDinner" ? 30 : 90;
    const orderBy = moment(cart?.window?.start)
      .subtract(cutOffBeforeDelivery, "minutes")
      .add(5, "minute")
      .valueOf();
    const isNotOrganizer = currentUser?.user?._id !== cart?.user?._id;
    if (Object.keys(cart?.goods).length === 0) {
      return <Redirect to="/" />;
    }
    return (
      // <div className="page">
      //   <Toaster />
      <Checkout
        cardType={cardType}
        deliveryInfo={deliveryInfo}
        error={error}
        existingCard={existingCard}
        isAuthenticated={currentUser.isAuthenticated}
        loading={loading}
        paying={paying}
        initializing={initializing}
        newCard={newCard}
        onBack={this.handleClose}
        onChange={this.handleChange}
        onChangeNumber={this.handleChangeNumber}
        onDeleteCard={this.handleDeleteCard}
        onEditAddress={this.handleEditAddress}
        onPaypalAuthorize={this.handlePaypalAuthorize}
        onPaypalCapturing={this.handlePaypalCapturing}
        onPaypalSuccess={this.handlePaypalSuccess}
        onSelectAddress={this.handleSelectAddress}
        onSubmitFreeMeal={this.handleSubmitFreeMeal}
        onSubmit={this.handleSubmitOrderWithCard}
        onTogglePaymentMethod={this.handleTogglePaymentMethod}
        onToggleSaveCard={this.handleToggleSaveCard}
        onToggleRingBell={this.handleToggleRingBell}
        onToggleShowCardAction={this.handleToggleShowCardAction}
        order={cart}
        orderBy={orderBy}
        paypalInfo={paypalInfo}
        saveCard={saveCard}
        showCardAction={showCardAction}
        paymentMethod={paymentMethod}
        payPalOrderAddressConfirmed={payPalOrderAddressConfirmed}
        onConfirmPaypalAddress={this.handleConfirmPaypalAddress}
        onEditPaypalAddress={this.handleEditPaypalAddress}
        canMakeGroupOrder={canMakeGroupOrder}
        onSubmitOrderWithCompanyCard={this.handleSubmitOrderWithCompanyCard}
        isCompanyOrder={cart.companyBudget}
        isCartValid={isCartValid}
        onSetIsCartValid={this.handleSetIsCartValid}
        isMember={
          cart.isMember || cart.promo?.isActive
            ? true
            : currentUser?.user?.membership?.status === "active" ||
              currentUser?.user?.membership?.status === "trialing"
        }
        wasMember={currentUser?.user?.membership?.canceled_at ? true : false}
        onStartMembership={this.handleStartMembership}
        onResumeMembership={this.handleResumeMembership}
        onChangeTips={this.handleChangeTips}
        isNotOrganizer={isNotOrganizer}
        initialTip={cart?.tips || initialTip}
        isEditingTip={isEditingTip}
        isEditingDeliveryPaymentInfo={
          isEditingDeliveryPaymentInfo ||
          !existingCard ||
          !deliveryInfo?.address
        }
        onToggleEditDeliveryPaymentInfo={
          this.handleToggleEditDeliveryPaymentInfo
        }
        onApplyCouponCode={this.handleApplyCouponCode}
        couponCodeError={couponCodeError}
        applyingCoupon={applyingCoupon}
      />
      // </div>
    );
  }
}

// const WrappedPageCheckout = GoogleApiWrapper({
//   apiKey: "AIzaSyBjfTfgJdRah5wXvVj8U7fH4ACoqKry9HI",
// })(PageCheckout);

function mapStateToProps(state) {
  LOGGING && console.log("PageCheckout got redux state:", state);
  return {
    cart: state.cart.payLoad,
    currentUser: state.currentUser,
    addressWhiteList: state.config?.addressWhiteList || [],
    addressBlackList: state.config?.addressBlackList || [],
    deliveryZoneByCity: state.deliveryZoneByCity,
  };
}

export default connect(mapStateToProps, {
  saveSetting,
  payCartWithNewCard,
  payCartWithExistingCard,
  payCartWithCompanyCard,
  paidCartWithPaypal,
  signOut,
  deleteCardFromOrder,
  updateOrder,
  submitFreeMeal,
  checkPreorderInventoryAvailablity,
  getAddressList,
  fetchDeliveryZoneByCity,
  checkOrderCount,
  startMembership,
  resumeMembership,
  applyCouponCode,
  changeTips,
  updateOrderOnCart,
})(PageCheckout);
//})(WrappedPageCheckout);
