import React from 'react';
import './RestaurantsBoard.scss';
import { LOGGING, Back } from './';

const OrdersOnDate = ({ date, orders, freeze }) => (
  <div className="restaurant-orders-on-date">
    <div className={`date ${freeze ? 'freeze-date' : ''}`}>{date}</div>
    {orders.map((order, index) => (
      <div
        className={`restaurant ${order.isLunch ? 'lunch' : 'dinner'} ${
          order.orders ? '' : 'empty'
        }`}
        key={index}
      >
        <div className="order-date">{order.date}</div>
        <div className="order-restaurant">{order.restaurant}</div>
        <div className="order-details">
          <div className="order-orders">{order.orders} </div>
          <div className="order-dishes"> ({order.dishes}) </div>
        </div>
      </div>
    ))}
  </div>
);

const RestaurantsBoard = ({
  matrix,
  restaurants,
  dates,
  onBack,
  freeze,
  onSelectFreeze,
  ranked,
  viewByRanked,
  onToggleViewByRanked,
}) => {
  LOGGING && console.log('RestaurantsBoard rendering with orders:', matrix);

  return (
    <div className="list order-list">
      <div className="list-title">
        <div className="freeze-selector">
          <button
            onClick={onToggleViewByRanked}
            className={`${viewByRanked ? 'view-by-ranked' : ''}`}
          >
            view by ranked
          </button>
        </div>
        <Back onClick={onBack} />
      </div>
      {viewByRanked ? (
        <div className="restaurant-order-matrix">
          <div className="restaurant-orders-on-date">
            {ranked.map((order, index) => (
              <div
                className={`restaurant ${order.isLunch ? 'lunch' : 'dinner'} ${
                  order.orders ? '' : 'empty'
                }`}
                key={index}
              >
                <div className="order-date">{order.date}</div>
                <div className="order-restaurant">{order.restaurant}</div>
                <div className="order-details">
                  <div className="order-orders">{order.orders} </div>
                  <div className="order-dishes"> ({order.dishes}) </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="restaurant-order-matrix">
          <div
            className={`restaurant-orders-on-date restaurant-names  ${
              freeze ? '' : 'freeze-restaurant'
            }`}
          >
            <div className={`date ${freeze ? 'freeze-date' : ''}`}></div>
            {restaurants.map((restaurant) => (
              <div className="restaurant">{restaurant}</div>
            ))}
          </div>
          {dates.map((date) => (
            <OrdersOnDate date={date} orders={Object.values(matrix[date])} freeze={freeze} />
          ))}
        </div>
      )}
    </div>
  );
};

export default RestaurantsBoard;
