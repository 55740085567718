import React, { Component } from 'react';
import { LOGGING, Footer, About } from '.';

class PageAbout extends Component {
  constructor(props) {
    LOGGING && console.log('PageAbout constructor called with props:', props);
    super();
    this.state = {};
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose(e) {
    e.preventDefault();
    this.props.history.push('/');
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    LOGGING &&
      console.log('PageFAQ rendering with', {
        props: this.props,
        state: this.state,
      });

    return (
      <div className="page with-footer">
        {/* <Navbar /> */}
        <About onClose={this.handleClose} />
        <Footer />
      </div>
    );
  }
}

export default PageAbout;
