import { DriverStatus } from "./DriverStatus";
import { LOGGING } from "..";

export const DriverSectionV2 = ({
  drivers,
  status,
  onInvite,
  inviteRestaurantName,
  resetDriver,
  resetDriverId,
  acceptDriver,
}) => {
  if (drivers == null || drivers.length === 0) {
    return null;
  }
  // LOGGING && console.log('DriverSectionV2 is called with resetDriver:', resetDriver);
  return (
    <div className="driver-section">
      <div className="section-title">{status}</div>
      <div className="driver-list">
        {drivers.map((driver) => (
          <DriverStatus
            driver={driver}
            driverId={driver._id}
            status={status}
            key={driver._id}
            onInvite={onInvite}
            inviteRestaurantName={inviteRestaurantName}
            resetDriver={resetDriver}
            isResetting={resetDriverId === driver._id}
            acceptDriver={acceptDriver}
          />
        ))}
      </div>
    </div>
  );
};
