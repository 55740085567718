import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LOGGING, Loading } from '.';
import { getTeamMealOrders } from '../../store/actions';
import { TeamOrderList } from './components';

class PageTeamMealDetails extends Component {
  constructor() {
    super();
    LOGGING && console.log('PageTeamMealDetails constructor');
    this.state = {};
    this.handleClose = this.handleClose.bind(this);
    this.handleShowDetails = this.handleShowDetails.bind(this);
    this.handleHideDetails = this.handleHideDetails.bind(this);
  }

  handleShowDetails(orderId, e) {
    e.preventDefault();
    const { orders } = this.state;
    LOGGING && console.log('PageTeamMealDetails called with orderId:', orderId);
    this.setState({
      orders: orders.map((o) => ({ ...o, showDetails: orderId === o._id ? true : o.showDetails })),
    });
  }

  handleHideDetails(orderId, e) {
    e.preventDefault();
    const { orders } = this.state;
    this.setState({
      orders: orders.map((o) => ({ ...o, showDetails: orderId === o._id ? false : o.showDetails })),
    });
  }

  componentDidMount() {
    const { mealId } = this.props.match.params;
    LOGGING && console.log('PageTeamMealDetails componentdidmount with orderId:', mealId);
    this.props.getTeamMealOrders(mealId).then(({ orders, windowStart, restaurant }) => {
      LOGGING && console.log('PageTeamMealDetails got orders:', orders);
      this.setState({ orders, windowStart, restaurant }, () => {
        window.scrollTo(0, 0);
      });
    });
  }
  handleClose() {
    LOGGING && console.log('PageTeamMealDetails handleClose');
    this.props.history.goBack();
  }
  render() {
    const { orders, windowStart, restaurant } = this.state;
    const { currentUser } = this.props;
    LOGGING &&
      console.log('PageTeamMealDetails rendering with', {
        props: this.props,
        state: this.state,
      });

    if (!currentUser.isAuthenticated) {
      return <Redirect to="/" />;
    }

    return (
      <div className="page">
        {orders ? (
          <TeamOrderList
            orders={orders}
            onBack={this.handleClose}
            onShowDetails={this.handleShowDetails}
            onHideDetails={this.handleHideDetails}
            restaurant={restaurant}
            windowStart={windowStart}
          />
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log('PageTeamMealDetails got redux state:', state);
  return {
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  getTeamMealOrders,
})(PageTeamMealDetails);
