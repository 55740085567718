import { LOAD_CUISINES, RESET_CUISINES } from '../actionTypes';

const cuisines = (state = [], action) => {
  switch (action.type) {
    case LOAD_CUISINES:
      return [...action.cuisines];
    case RESET_CUISINES:
      return [];
    default:
      return state;
  }
};

export default cuisines;
