import React, { ReactElement, useCallback } from 'react';
import FileSaver from 'file-saver';
import toast from 'react-hot-toast';
import { useDispatch, useStore } from 'react-redux';
import { ObjectId } from 'src/constants/types';
import { markOrdersAsRestaurantConfirmed } from 'src/store/actions';
import { getOrderLabelsPdfForMealBlob } from 'src/store/actions/meals';

export enum ProcessedType {
  UNKNOWN = 'UNKNOWN',
  NOT_PROCESSED = 'NOT_PROCESSED',
  PROCESSED = 'PROCESSED',
}

interface PrintOrderLabelsForMealProps {
  mealId: ObjectId;
  orderIds: ObjectId[];
  processedType: ProcessedType;
}

export const PrintOrderLabelsForMeal: React.FC<PrintOrderLabelsForMealProps> = ({
  mealId,
  orderIds,
  processedType,
}): ReactElement => {
  const dispatch = useDispatch();
  const store = useStore();
  const fetchDataQuery = useCallback(async () => {
    const pdfBlob = await getOrderLabelsPdfForMealBlob(mealId, processedType);
    FileSaver.saveAs(pdfBlob, 'foodie-labels.pdf');

    markOrdersAsRestaurantConfirmed(orderIds)(dispatch, store.getState);

    switch (processedType) {
      case ProcessedType.UNKNOWN:
      case ProcessedType.NOT_PROCESSED:
        toast.success('Downloaded PDF and processed orders');
        break;
      case ProcessedType.PROCESSED:
        toast.success('Downloaded PDF');
        break;
    }
  }, [mealId, processedType, orderIds, dispatch, store.getState]);

  return (
    <button className="print-order" onClick={fetchDataQuery}>
      PDF 2X4 ORDER LABELS
    </button>
  );
};
