import React from "react";
import "./DeliveryWindow.scss";
// import { faCheck } from '@fortawesome/pro-regular-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment-timezone";
// import { Link } from 'react-router-dom';
import { CATimeZone } from "../../../constants";
import {
  // shiftComment,
  receiptDateFormat,
  receiptHourFormat,
  receiptHourFormatShort,
  receiptHourFormatHourOnly,
  LOGGING,
  receiptHourFormatSuperShort,
} from "./";

const TimeStamp = ({ action, time1, time2, dateOnly }) => (
  <React.Fragment>
    <div className="receipt-row">
      <label>
        <span className="arrive-time">{action}</span>
      </label>
      <span>{moment(time1).format(receiptDateFormat)}</span>
    </div>
    {dateOnly ? null : (
      <div className="receipt-row window">
        <span>{moment(time1).format(receiptHourFormat)}</span>
        {time2 && <span>~{moment(time2).format(receiptHourFormat)}</span>}
      </div>
    )}
  </React.Fragment>
);

const DeliveryTime = ({ orderWindow }) => {
  // LOGGING && console.log("DeliveryTime:", { orderWindow });
  const deliveryTimeEnd = moment(orderWindow.deliveryEnd).format(
    moment(orderWindow.deliveryEnd).minute() > 0
      ? receiptHourFormatShort
      : receiptHourFormatHourOnly
  );
  return (
    <div className="delivery-window">
      <div className="delivery-window-current">
        <span>
          {`${moment(orderWindow.deliveryStart).format(
            moment(orderWindow.deliveryStart).minute() > 0
              ? receiptHourFormatShort
              : receiptHourFormatSuperShort
          )}~${deliveryTimeEnd.slice(0, deliveryTimeEnd.length)}`}
        </span>
      </div>
    </div>
  );
};

const OldDeliveryTime = ({
  windows,
  selectedWindowIndex,
  onToggleShowOptions,
  showPrice,
  isLunch,
}) => {
  const deliveryTimeEnd = moment(windows[selectedWindowIndex].end).format(
    moment(windows[selectedWindowIndex].end).minute() > 0
      ? receiptHourFormat
      : receiptHourFormatHourOnly
  );
  return (
    <div className="delivery-window">
      <div className="delivery-window-current">
        <span>
          {`${moment(windows[selectedWindowIndex].start).format(
            moment(windows[selectedWindowIndex].start).minute() > 0
              ? receiptHourFormatShort
              : receiptHourFormatSuperShort
          )}~${deliveryTimeEnd.slice(0, deliveryTimeEnd.length - 1)}`}
        </span>
      </div>
    </div>
  );
};

const MealDate = ({ time, onSelect }) =>
  onSelect ? (
    <button className="meal-date" onClick={onSelect}>
      <div className="day-of-week">
        <span>{time.format("ddd")}</span>
      </div>
      <div className="date">
        <span>{time.format("M/D")}</span>
      </div>
    </button>
  ) : (
    <div className="meal-date">
      <div className="day-of-week">
        <span>{time.format("ddd")}</span>
      </div>
      <div className="date">
        <span>{time.format("M/D")}</span>
      </div>
    </div>
  );

const DeliveryWindowBanner = ({
  cartIsFull,
  hideNav,
  isLunch,
  onSelectMealEmptyCart,
  onSelectMealSimple,
  onToggleShowOptions,
  orderTime,
  selectedWindowIndex,
  hasAnnouncement,
  hasGiftCard,
  windows,
  isAnonymous,
  showOrderConfirmation,
  hasReferralCode,
}) => {
  const orderBy = windows[selectedWindowIndex].by;
  const now = moment().tz(CATimeZone).valueOf();
  const lunchBy = isLunch
    ? windows[selectedWindowIndex].by
    : windows[selectedWindowIndex].by - 6 * 60 * 60 * 1000;
  LOGGING &&
    console.log("DeliveryWindowBanner rendering with: ", {
      isLunch,
      windows,
      selectedWindowIndex,
      by: windows[selectedWindowIndex].by,
      orderTime: orderTime.valueOf(),
      orderBy,
      now,
      lunchBy,
    });
  const orderByMoment = moment(orderBy);
  const orderByTime = orderByMoment.format("h:mmA");
  const lunchIsClosed = (isLunch ? orderTime.valueOf() : now) > lunchBy;
  return (
    <div
      className={`delivery-window-banner ${hideNav ? "hide-nav" : ""} ${
        hasReferralCode ? "has-referral-code" : "no-referral-code"
      } ${
        hasAnnouncement || hasGiftCard ? "has-announcement" : "no-announcement"
      } ${isAnonymous ? "is-anonymous" : "is-signed-in"} ${
        showOrderConfirmation
          ? "show-order-confirmation"
          : "hide-order-confirmation"
      }`}
    >
      <div className="meal-select-wrapper">
        <button
          className={`${isLunch ? "selected-meal" : ""}`}
          onClick={
            cartIsFull && !isLunch
              ? onSelectMealEmptyCart.bind(this, true)
              : onSelectMealSimple.bind(this, true)
          }
          disabled={lunchIsClosed}
        >
          <span>lunch</span>
        </button>
        <button
          className={`${isLunch ? "" : "selected-meal"}`}
          onClick={
            cartIsFull && isLunch
              ? onSelectMealEmptyCart.bind(this, false)
              : onSelectMealSimple.bind(this, false)
          }
        >
          <span>Dinner</span>
          {/* <span className="preview">doha</span> */}
        </button>
      </div>

      <div className="delivery-time-wrapper">
        <span className="order-by">
          Order by <b>{orderByTime.slice(0, orderByTime.length - 1)}</b>,
          receive
        </span>
        <OldDeliveryTime
          windows={windows}
          selectedWindowIndex={selectedWindowIndex}
          onToggleShowOptions={onToggleShowOptions}
          showPrice={true}
        />
      </div>

      {/* {isAnonymous ? null : (
        <Link to="/orders" className="my-orders">
          <span>my orders</span>
        </Link>
      )} */}
    </div>
  );
};

const DeliveryWindowInReceipt = ({ orderWindow }) => (
  <React.Fragment>
    <TimeStamp
      action="arrives"
      time1={orderWindow.deliveryStart}
      dateOnly={true}
    />
    <div className="delivery-window-in-receipt">
      <DeliveryTime orderWindow={orderWindow} />
    </div>
  </React.Fragment>
);

const DeliveryStatusBanner = ({
  isDelivered,
  isSummary,
  onSelectDeliveryStatus,
  numberOfDelivered,
  numberOfToDeliver,
}) => {
  LOGGING &&
    console.log(
      "DeliveryStatusBanner rendering with isDelivered:",
      isDelivered
    );
  return (
    <div className="meal-select-wrapper">
      <button
        className={`${
          isDelivered && !isSummary ? "selected-meal" : "unselected-meal"
        }`}
        onClick={onSelectDeliveryStatus.bind(this, true, false)}
      >
        <span>{`delivered (${numberOfDelivered})`}</span>
      </button>
      <button
        className={`${
          !isDelivered && !isSummary ? "selected-meal" : "unselected-meal"
        }`}
        onClick={onSelectDeliveryStatus.bind(this, false, false)}
      >
        {numberOfToDeliver > 0 ? (
          <span>{`to deliver (${numberOfToDeliver})`}</span>
        ) : (
          <span>{`to deliver`}</span>
        )}
      </button>
      {/* <button
        className={`${isSummary ? 'selected-meal' : 'unselected-meal'}`}
        onClick={onSelectDeliveryStatus.bind(this, false, true)}
      >
        <span>weekly summary</span>
      </button> */}
    </div>
  );
};

export {
  DeliveryWindowBanner,
  DeliveryWindowInReceipt,
  DeliveryStatusBanner,
  TimeStamp,
  MealDate,
};
