import React, { Fragment, useMemo } from "react";
import "./OrderList.scss";
import moment from "moment-timezone";
import { Grid, Cell } from "../../../components/Layout";
import {
  Back,
  Loading,
  OrderThumbnail,
  UnpaidCartThumbnail,
  LOGGING,
} from "./";

const OrderList = ({
  orders,
  onBack,
  view,
  onSetView,
  loadingFuture,
  loadingPast,
  groupName,
  gotoOrderId,
  gotoOrderRef,
}) => {
  LOGGING &&
    console.log("OrderList rendering with:", {
      gotoOrderId,
      gotoOrderRef,
      orders,
    });

  const orderByWeekly = useMemo(() => {
    const now = moment();
    const ordersToDisplay =
      view === "upcoming"
        ? orders.filter((o) => moment(o.window.end).valueOf() > now)
        : orders.filter((o) => moment(o.window.end).valueOf() <= now);
    const weekOrders = {};
    ordersToDisplay.forEach((order) => {
      const week = moment(order.window.start).startOf("week").valueOf();
      if (!weekOrders.hasOwnProperty(week)) {
        weekOrders[week] = [];
      }
      weekOrders[week].push(order);
    });
    const thisWeek = moment().startOf("week").valueOf();
    const lastWeek = moment().subtract(1, "week").startOf("week").valueOf();
    return Object.entries(weekOrders).map(([week, orders]) => {
      let weekTitle = "";
      if (Number(week) === thisWeek) {
        weekTitle = "This week";
      } else if (Number(week) === lastWeek) {
        weekTitle = "Last week";
      } else {
        weekTitle = `${moment(Number(week))
          .startOf("week")
          .format("MM/DD")} - ${moment(Number(week))
          .endOf("week")
          .format("MM/DD")}`;
      }
      return {
        weekTitle,
        orders: orders.sort((o1, o2) => o1.window.start - o2.window.start),
      };
    });
  }, [view, orders]);

  LOGGING && console.log("OrderList orderByWeekly:", { orderByWeekly });

  return (
    <div className="list with-banner-on-top order-list">
      <div className="list-title">
        <Back onClick={onBack} />
        <span>{groupName ? `orders for group ${groupName}` : "my orders"}</span>
      </div>
      <div className="set-view">
        <button
          className={`view-button ${view === "upcoming" ? "selected" : ""}`}
          onClick={onSetView.bind(this, "upcoming")}
        >
          <span>upcoming</span>
        </button>
        <button
          className={`view-button ${view === "delivered" ? "selected" : ""}`}
          onClick={onSetView.bind(this, "delivered")}
        >
          <span>delivered</span>
        </button>
      </div>
      {(loadingFuture && view === "upcoming") ||
      (loadingPast && view === "delivered") ? (
        <Loading />
      ) : (
        <Grid
          columns={1}
          padding={6}
          gap={6}
          md={{ columns: 2 }}
          lg={{ columns: 4 }}
          widthFull
        >
          {orderByWeekly.map((orderWeekly, index) => {
            const { orders } = orderWeekly;
            if (view === "upcoming") {
              orders.sort((a, b) => (a.window.start > b.window.start ? 1 : -1));
            } else {
              orders.sort((a, b) => (b.window.start > a.window.start ? 1 : -1));
            }
            return (
              <Fragment key={index}>
                <Cell
                  span={1}
                  md={{ span: 2 }}
                  lg={{ span: 4 }}
                  justify="center"
                  className="week-title"
                >
                  {orderWeekly.weekTitle}
                </Cell>
                {orders.map((order) => (
                  <OrderThumbnail
                    order={order}
                    key={order._id}
                    gotoOrderRef={
                      String(order._id) === String(gotoOrderId)
                        ? gotoOrderRef
                        : null
                    }
                  />
                ))}
              </Fragment>
            );
          })}
        </Grid>
      )}
    </div>
  );
};
export const CartList = ({ carts, gotoOrderId, gotoOrderRef, onBack }) => {
  LOGGING &&
    console.log("CartList rendering with:", {
      carts,
    });

  return (
    <div className="list order-list">
      <div className="list-title">
        <Back onClick={onBack} />
        <span>my carts</span>
      </div>

      {carts.map((cart) => (
        <UnpaidCartThumbnail
          cart={cart}
          key={cart._id}
          gotoOrderRef={
            String(cart._id) === String(gotoOrderId) ? gotoOrderRef : null
          }
        />
      ))}
    </div>
  );
};
export default OrderList;
