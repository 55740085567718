import { ReactElement } from "react";
import { faChevronLeft, faHome } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ListTitle } from "./Shared";

type SimpleNavBar2Props = {
  title?: string;
  prev?: string;
};

export const SimpleNavBar2: React.FC<SimpleNavBar2Props> = ({
  title,
  prev,
}): ReactElement => {
  const history = useHistory();
  return (
    <ListTitle>
      <button type="button" onClick={() => history.push(prev || "/")}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <span>{title}</span>
    </ListTitle>
  );
};
