import { SET_CREDIT } from '../actionTypes';

const INITIALIZE_STATE = {
  credit: 0,
  records: [],
};

const credit = (state = { INITIALIZE_STATE }, action) => {
  switch (action.type) {
    case SET_CREDIT:
      return action.payload;
    default:
      return state;
  }
};

export default credit;
