import { ReactElement } from 'react';
import styled from 'styled-components';
import { FoodieButton } from './FoodieButton';

export type FoodieCardProps = {
  title: string;
  content: ReactElement;
  ctaLabel?: string;
  ctaCallback?: () => void;
} & CardProps;

type CardProps = {
  maxWidth: string;
};

const Card = styled.div<CardProps>`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  display: inline-block;
  margin: 12px;
  display: flex;
  flex-direction: column;
  max-width: ${(props) => props.maxWidth};
`;

const Title = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
`;

const CardButton = styled(FoodieButton)`
  margin-top: 20px;
`;

export const FoodieCard: React.FC<FoodieCardProps> = ({
  title,
  content,
  ctaLabel,
  ctaCallback,
  maxWidth,
}): ReactElement => {
  return (
    <Card maxWidth={maxWidth}>
      <Title>
        <b>{title}</b>
      </Title>
      {content}
      {ctaLabel != null ? (
        <CardButton type="button" onClick={ctaCallback}>
          {ctaLabel}
        </CardButton>
      ) : null}
    </Card>
  );
};
