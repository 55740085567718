import { useCallback, useRef } from 'react';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';
import { Button } from '../Button';
import { Flex } from '../Layout';

type EmailScheduleProps = {
  emailSchedule: any;
  onEmailScheduleChange: (emailSchedule: any) => void;
};

export const EmailSchedule = (props: EmailScheduleProps) => {
  const { emailSchedule, onEmailScheduleChange } = props;

  const emailScheduleLunchInput = useRef<HTMLInputElement>();
  const emailScheduleDinnerInput = useRef<HTMLInputElement>();

  const onEmailScheduleAdd = useCallback(
    (type) => {
      const result = cloneDeep(emailSchedule) || { lunch: [], dinner: [] };
      switch (type) {
        case 'lunch':
          {
            const time = emailScheduleLunchInput.current.value;
            result.lunch.push(time);
            emailScheduleLunchInput.current.value = '';
          }
          break;
        case 'dinner':
          {
            const time = emailScheduleDinnerInput.current.value;
            result.dinner.push(time);
            emailScheduleDinnerInput.current.value = '';
          }
          break;
        default:
          break;
      }
      onEmailScheduleChange(result);
    },
    [emailSchedule, onEmailScheduleChange]
  );

  const onEmailScheduleDelete = useCallback(
    (type, index) => {
      const result = cloneDeep(emailSchedule);
      switch (type) {
        case 'lunch':
          result.lunch.splice(index, 1);
          break;
        case 'dinner':
          result.dinner.splice(index, 1);
          break;
        default:
          break;
      }
      onEmailScheduleChange(result);
    },
    [emailSchedule, onEmailScheduleChange]
  );

  return (
    <EmailScheduleContainer>
      <div>
        <Flex gap={10} align="center">
          <EmailScheduleType>Lunch</EmailScheduleType>
          {emailSchedule?.lunch?.map((time, index) => (
            <EmailScheduleTime key={index}>
              <Flex gap={6} align="center">
                <span>{time}</span>
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() => onEmailScheduleDelete('lunch', index)}
                />
              </Flex>
            </EmailScheduleTime>
          ))}
          <input ref={emailScheduleLunchInput} placeholder="enter time" />
          <Button
            type="button"
            size="small"
            variant="secondary"
            onClick={() => onEmailScheduleAdd('lunch')}
          >
            Add
          </Button>
        </Flex>
      </div>
      <div>
        <Flex gap={10} align="center">
          <EmailScheduleType>Dinner</EmailScheduleType>
          {emailSchedule?.dinner?.map((time, index) => (
            <EmailScheduleTime key={index}>
              <Flex gap={6} align="center">
                <span>{time}</span>
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() => onEmailScheduleDelete('dinner', index)}
                />
              </Flex>
            </EmailScheduleTime>
          ))}
          <input ref={emailScheduleDinnerInput} placeholder="enter time" />
          <Button
            type="button"
            size="small"
            variant="secondary"
            onClick={() => onEmailScheduleAdd('dinner')}
          >
            Add
          </Button>
        </Flex>
      </div>
    </EmailScheduleContainer>
  );
};

const EmailScheduleContainer = styled.div`
  margin-top: 12px;
  font-size: 12px;
  letter-spacing: 1px;

  > div + div {
    margin-top: 10px;
  }

  input {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    padding: 5px 0 !important;
    flex-grow: 0 !important;
    width: 80px !important;
  }
`;

const EmailScheduleType = styled.div`
  font-weight: bold;
`;

const EmailScheduleTime = styled.div`
  padding: 5px 10px;
  background-color: var(--background-beige);

  svg {
    cursor: pointer;
  }
`;
