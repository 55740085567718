import {
  faHourglass,
  faComment,
  faCommentCheck,
  faCommentTimes,
  faCommentExclamation,
  faCommentDots,
  faPhoneAlt,
  faClock,
  faUser as faDriver,
} from "@fortawesome/pro-light-svg-icons";
import { faStar } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import Countdown from "react-countdown";
import { LOGGING, PhoneFormat, receiptHourFormat } from "./";

const statusIcon = {
  accepted: faCommentCheck,
  rejected: faCommentTimes,
  expired: faCommentExclamation,
  waiting: faCommentDots,
  uninvited: faComment,
};
const statusStatement = {
  accepted: "Job accepted.",
  rejected: "Job rejected.",
  expired: "Job expired.",
  waiting: "Waiting for response.",
  uninvited: "Would you deliver the next meal?",
};

export const DriverStatus = ({
  driver,
  status,
  onInvite,
  driverId,
  inviteRestaurantName,
  resetDriver,
  isResetting,
  acceptDriver,
}) => {
  // LOGGING && console.log("DriverStatus is called with resetDriver:", driver);
  return (
    <div className="driver">
      <div
        className="driver-name"
        style={{
          backgroundColor: driver.color,
        }}
      >
        <FontAwesomeIcon icon={faDriver} />
        <span>{driver.name || driver.firstName}</span>
      </div>
      <div className="driver-status">
        {driver.askedAvailabilityTime && (
          <div className="field time">
            <FontAwesomeIcon icon={faClock} />
            <span>
              {moment(driver.askedAvailabilityTime).format(receiptHourFormat)}
            </span>
          </div>
        )}
        {driver.askedAvailabilityExpired === false &&
          driver.askedAvailabilityTime && (
            <div className="field countDown">
              <FontAwesomeIcon icon={faHourglass} />
              <Countdown
                date={
                  driver.askedAvailabilityTime +
                  (process.env.NODE_ENV === "production" ? 1800000 : 600000)
                }
              />
            </div>
          )}
        {driver.score != null ? (
          <div style={{ marginLeft: "-2px" }} className="field phone">
            <FontAwesomeIcon icon={faStar} />
            <span>{driver.score.toFixed(2)}</span>
          </div>
        ) : null}
        <div className="field phone">
          <FontAwesomeIcon icon={faPhoneAlt} />
          <span>{PhoneFormat(driver.phone)}</span>
        </div>
        <div className={`field status ${status}`}>
          <FontAwesomeIcon icon={statusIcon[status]} />
          <span>{statusStatement[status]}</span>
        </div>
        {status === "uninvited" ? (
          <div>
            <div className="action-wrapper">
              <button
                className="send-msg"
                onClick={onInvite.bind(this, driverId)}
              >
                invite to {`${inviteRestaurantName}`}
              </button>
            </div>
            <div className="action-wrapper">
              <button
                className="send-msg"
                onClick={acceptDriver.bind(this, driverId)}
              >
                accept to {`${inviteRestaurantName}`}
              </button>
            </div>
          </div>
        ) : null}
        {["accepted", "waiting"].includes(status) && (
          <div className={`field restaurant`}>
            <span>Restaurant: {driver.restaurantName}</span>
          </div>
        )}
      </div>
      {resetDriver ? (
        <button
          className="reset-driver"
          disabled={isResetting}
          onClick={(e) => {
            e.preventDefault();
            LOGGING && console.log("reset driver called:", driverId);
            resetDriver(driverId);
          }}
        >{`reset${isResetting ? "ting..." : ""}`}</button>
      ) : null}
    </div>
  );
};
