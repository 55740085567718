import React, {
  ReactElement,
  useCallback,
  useState,
  useEffect,
  useRef,
} from "react";
import moment from "moment-timezone";
import "react-toggle/style.css"; // for ES6 modules
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { LOGGING } from "../..";
import { trackOrders } from "../../../../../store/actions";
import { AwaitServerResponseContainer } from "../../AwaitServerResponseContainer";
import { Tracking } from "./Tracking";

export interface TodaysOrdersProps {}
export const TodaysOrders: React.FC<TodaysOrdersProps> =
  ({}: TodaysOrdersProps): ReactElement => {
    const fetchDataQuery = useCallback(async (dispatch, getState) => {
      await trackOrders()(dispatch, getState);
    }, []);

    return (
      <AwaitServerResponseContainer awaitable={fetchDataQuery}>
        <Tracking />
      </AwaitServerResponseContainer>
    );
  };
