import React, { Component } from 'react';
import moment from 'moment-timezone';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import {
  fetchPosts,
  createBookmark,
  updateBookmark,
  signOut,
  deletePost,
} from '../../store/actions';
import { LOGGING, PageSize, PageRefreshMinutes, Footer, ConfirmAlert } from './';
import { Post, Nav, QuickPostMobile } from './components';

class PageFeed extends Component {
  constructor(props) {
    super();
    LOGGING && console.log('PageFeed constructed with: ', props, new Date());
    const { posts, location } = props;
    const { state } = location;

    // if (state && state.isFromFeed) {
    this.state = {
      loading: false,
      showUserMenu: false,
      showDetails: null,
      showRecommenders: null,
      review: '',
      isWritingView: false,
      pageIndex:
        !posts || posts.length === 0 || (state && state.isFromRecommendation)
          ? 0
          : Math.floor(posts.length / PageSize),
      allRead: false,
    };
    this.handleToggleUserMenu = this.handleToggleUserMenu.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.handleSetBookmark = this.handleSetBookmark.bind(this);
    this.handleCreateBookmark = this.handleCreateBookmark.bind(this);
    this.handleShowBookmarkDetails = this.handleShowBookmarkDetails.bind(this);
    this.handleHideBookmarkDetails = this.handleHideBookmarkDetails.bind(this);
    this.handleDeletePost = this.handleDeletePost.bind(this);
    this.handleConfirmDeletePost = this.handleConfirmDeletePost.bind(this);
    this.handleShowRecommenders = this.handleShowRecommenders.bind(this);
    this.handleHideRecommenders = this.handleHideRecommenders.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.getData = this.getData.bind(this);
    this.pageRef = React.createRef();
    this.intervalId = 0;
  }

  handleScroll() {
    if (this.pageRef.current) {
      const { bottom, top } = this.pageRef.current.getBoundingClientRect();
      const { innerHeight } = window;
      LOGGING && console.log('scrolled at: ', { bottom, innerHeight });
      const { pageIndex, allRead, loading } = this.state;
      LOGGING &&
        console.log('scrolled at: ', {
          bottom,
          innerHeight,
          pageIndex,
          allRead,
          loading,
        });
      if (bottom < 2 * innerHeight && !allRead && !loading) {
        LOGGING &&
          console.log('bottom hit, with:', {
            bottom,
            top,
            innerHeight,
            allRead,
            loading,
          });
        LOGGING && console.log('bottom hit, reading pageIndex:', pageIndex);
        this.setState({ loading: true });
        this.props.fetchPosts({ pageIndex, fresh: false }).then((result) => {
          LOGGING && console.log('fetchPosts got from backend:', result);
          this.setState(
            {
              loading: false,
              pageIndex: pageIndex + 1,
              // allRead: result.length < PageSize,
              allRead: false,
            },
            () => {
              // window.scrollTo(0, bottom);
              LOGGING && console.log('stop loading:', result);
            }
          );
        });
      }
    }
  }

  handleShowRecommenders(postId, e) {
    e.preventDefault();
    LOGGING && console.log('handleShowRecommenders called with:', postId);
    this.setState({ showRecommenders: postId });
  }
  handleHideRecommenders(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showRecommenders: null });
  }
  handleShowBookmarkDetails(postId, e) {
    e.preventDefault();
    LOGGING && console.log('handleShowBookmarkDetails called with: ', postId);
    this.setState({ showDetails: postId });
  }

  handleHideBookmarkDetails(e) {
    e.preventDefault();
    e.stopPropagation();
    LOGGING && console.log('handleHideBookmarkDetails called.');
    this.setState({ showDetails: null });
  }

  handleSetBookmark(bookmark, status, e) {
    e.preventDefault();
    LOGGING && console.log('handleSetBookmark called with bookmark: ', bookmark);
    const { _id } = bookmark;
    this.setState({ loading: true }, () => {
      this.props
        .updateBookmark({
          _id,
          bookmark: { status },
          updateAfterDone: 'posts',
        })
        .then(() => {
          this.setState({ loading: false });
        });
    });
  }

  handleCreateBookmark(userId, postId, e) {
    e.preventDefault();
    LOGGING && console.log('handleCreateBookmark called with: ', { userId, postId });
    this.setState({ loading: true }, () => {
      this.props.createBookmark({ userId, postId, updateAfterDone: 'posts' }).then(() => {
        this.setState({ loading: false });
      });
    });
  }

  handleToggleUserMenu(e) {
    e.preventDefault();
    const { showUserMenu } = this.state;
    this.setState({ showUserMenu: !showUserMenu });
  }

  handleSignOut(e) {
    e.preventDefault();
    this.setState({ showUserMenu: false }, () => {
      this.props.signOut();
      this.props.history.push('/');
    });
  }

  handleDeletePost(postId, e) {
    e.preventDefault();
    LOGGING && console.log('handleDeletePost called with postId:', postId);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmAlert
            onConfirm={this.handleConfirmDeletePost.bind(this, postId)}
            onClose={onClose}
            actionName="delete"
          />
        );
      },
    });
  }

  handleConfirmDeletePost(postId) {
    this.setState({ loading: true }, () => {
      this.props.deletePost(postId).then(() => {
        this.setState({ loading: false });
      });
    });
  }

  getData() {
    this.setState({ loading: true, pageIndex: 0, time2: moment() }, () => {
      this.props.fetchPosts({ pageIndex: 0, fresh: true }).then(({ time3, time4 }) => {
        LOGGING && console.log('fetchposts returned with: ', { time3, time4 });
        this.setState({
          loading: false,
          pageIndex: 1,
          // allRead: result.length < PageSize,
          posts: [],
          time5: moment(),
          time3,
          time4,
        });
      });
    });
    // this.props.fetchPosts({ pageIndex: 0, fresh: true }).then((result) => {
    //   LOGGING && console.log("get data got result from backend:", result);
    //   LOGGING && console.log("get data got result.length:", result.length);
    //   LOGGING && console.log("get data got PageSize:", PageSize);
    //   LOGGING &&
    //     console.log(
    //       "get data got result.length < PageSize:",
    //       result.length < PageSize
    //     );
    // const end = moment();
    // LOGGING && console.log("loadingEnd:", end);
    // const loadingTime = end.diff(this.state.loadingStart);
    // LOGGING && console.log("loadingTime:", loadingTime);
    // this.setState(
    //   {
    //     loading: false,
    //     pageIndex: 1,
    //     allRead: result.length < PageSize,
    //     loadingTime,
    //   },
    //   () => {
    //     window.scrollTo(0, 0);
    //   }
    // );
    // });

    this.intervalId = setTimeout(this.getData.bind(this), 1000 * 60 * PageRefreshMinutes);
  }
  componentDidMount() {
    LOGGING && console.log('PageFeed componentdidmount');
    window.addEventListener('scroll', this.handleScroll);
    if (this.state.pageIndex === 0) {
      this.setState({ time1: moment() }, () => {
        this.getData();
      });
    } else {
      const { state } = this.props.location;
      if (state && state.isFromFeed) {
        window.scrollTo(0, 0);
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    clearTimeout(this.intervalId);
  }
  render() {
    const { loading, showUserMenu, showRecommenders, pageIndex } = this.state;
    const { posts, currentUser } = this.props;

    LOGGING &&
      console.log('PageFeed rendering with', {
        props: this.props,
        state: this.state,
        currentUser,
      });

    // if (loading)
    //   return (
    //     <div className="page feed">
    //       <Nav
    //         showUserMenu={showUserMenu}
    //         onToggleUserMenu={this.handleToggleUserMenu}
    //         currentUser={currentUser}
    //         onSignOut={this.handleSignOut}
    //       />
    //       <Loading />
    //     </div>
    //   );

    return (
      <div className="page feed" ref={this.pageRef}>
        <Nav
          showUserMenu={showUserMenu}
          onToggleUserMenu={this.handleToggleUserMenu}
          currentUser={currentUser}
          onSignOut={this.handleSignOut}
          isBookmark={false}
          isFeed={true}
        />
        {/* <TimeMonitor
          time1={time1}
          time2={time2}
          time3={time3}
          time4={time4}
          time5={time5}
        /> */}
        {posts ? (
          <React.Fragment>
            {loading ? <span>loading to refresh</span> : null}
            {posts.map((post) => (
              <Post
                key={post._id}
                post={post}
                currentUser={currentUser}
                createdByUser={
                  currentUser.isAuthenticated && post.user && post.user._id === currentUser.user._id
                }
                onSetBookmark={this.handleSetBookmark}
                onCreateBookmark={this.handleCreateBookmark}
                onDelete={this.handleDeletePost.bind(this, post._id)}
                showStatement={true}
                showRecommenderTitle={false}
                showRecommenders={showRecommenders === post._id}
                // onShowRecommenders={this.handleShowRecommenders.bind(
                //   this,
                //   post._id
                // )}
                // onHideRecommenders={this.handleHideRecommenders}
              />
            ))}
          </React.Fragment>
        ) : (
          <span>loading from scratch</span>
        )}
        {pageIndex > 0 && loading ? <span>loading more</span> : null}
        {currentUser.isAuthenticated ? <QuickPostMobile currentUser={currentUser} /> : null}
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log('pagefeed got redux state:', state);
  return {
    posts: state.posts.payLoad,
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  fetchPosts,
  deletePost,
  createBookmark,
  updateBookmark,
  signOut,
})(PageFeed);
