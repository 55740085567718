import { useMemo, useState, KeyboardEvent } from "react";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { useForm } from "src/hooks/useForm";
import {
  Container,
  Field,
} from "src/screens/tasting_menu/components/customers/checkout/Contact";
import { BreakPoint } from "src/screens/tasting_menu/components/customers/mealMenu/MealHeader";
import {
  Centered,
  CenteredByColumn,
  BorderGrey,
  CenteredByColumnLeft,
} from "src/screens/tasting_menu/components/Shared";
import { RootState } from "src/store";
import styled from "styled-components";
import { Button } from "../Button";

type OrderDiscountProps = {
  error?: string;
  couponCode?: string;
  onApplyCouponCode: (couponCode: string) => void;
  applyingCoupon?: boolean;
};

type OrderDiscountFormData = {
  couponCode?: string;
};
const CouponContainer = styled(CenteredByColumnLeft)``;
const CouponTitle = styled(Centered)`
  position: relative;
  flex-grow: 1;
  align-self: stretch;
  justify-content: space-between;
  label {
    font-weight: 500;
  }
`;
const ToggleButton = styled.button`
  position: absolute;
  right: 30px;
  padding: 0px;
`;
const CouponBody = styled(CenteredByColumn)`
  width: 100%;
  padding-top: 20px;
  align-items: flex-end;
  position: relative;
  input {
    font-size: 16px;
    line-height: 2;
    align-self: stretch;
    margin: 0px;
    &::placeholder {
      font-size: 16px;
    }
  }
  // input place holder size 16px
`;
export const OrderDiscount = (props: OrderDiscountProps) => {
  const { error, applyingCoupon, couponCode, onApplyCouponCode } = props;
  const [showInput, setShowInput] = useState(false);
  const currentUser = useSelector((state: RootState) => state.currentUser);

  const OrderDiscountFormTransform = (formData: OrderDiscountFormData) => {
    formData.couponCode = formData.couponCode?.toLocaleUpperCase();
  };

  const { formData, updateFormData, onInputChange } =
    useForm<OrderDiscountFormData>(
      {},
      {
        transform: OrderDiscountFormTransform,
      }
    );

  const toggleShowInput = (e) => {
    e.preventDefault();
    setShowInput(!showInput);
  };
  const onApply = () => {
    onApplyCouponCode(formData.couponCode || "");
    // clear input when apply
    // updateFormData((formData) => {
    //   formData.couponCode = "";
    // });
  };

  const applyCodeButtonDisabled = useMemo(() => {
    return !formData.couponCode || formData.couponCode.trim().length === 0;
  }, [formData]);

  const onInputKeydown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      !applyCodeButtonDisabled && onApply();
    }
  };

  // enforcing the rule that coupon code are only for new users
  if (currentUser?.user?.stats?.num_orders > 0) {
    return null;
  }

  return (
    <OrderDiscountContainer>
      <Field>
        <label>Coupon Code</label>
        <ToggleButton onClick={toggleShowInput}>
          <FontAwesomeIcon icon={showInput ? faChevronUp : faChevronDown} />
        </ToggleButton>
      </Field>

      {showInput ? (
        <CouponBody>
          <input
            value={formData.couponCode || couponCode}
            onChange={onInputChange}
            onKeyDown={onInputKeydown}
            name="couponCode"
            placeholder="Enter Code"
          />
          {error ? (
            <OrderDiscountError $display={Boolean(error)}>
              {error}
            </OrderDiscountError>
          ) : null}
          <ApplyCodeButton
            disabled={applyCodeButtonDisabled || applyingCoupon}
            onClick={onApply}
            type="button"
          >
            {`${applyingCoupon ? "Applying..." : "Apply"}`}
          </ApplyCodeButton>
          <OrderDiscountNotice>One offer code per order</OrderDiscountNotice>
        </CouponBody>
      ) : null}
    </OrderDiscountContainer>
  );
};

const OrderDiscountContainer = styled(Container)`
  position: relative;
  @media screen and (max-width: ${BreakPoint}px) {
    border: none;
    margin: 0px 10px;
    padding: 10px 0px;
    border-bottom: 1px solid ${BorderGrey};
    &:last-child {
      border-bottom: none;
    }
  }
`;

const ApplyCodeButton = styled(Button)`
  box-sizing: border-box;
  margin: 20px 0px 0px 0px;
  &:disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
`;

const OrderDiscountError = styled.div<{ $display: boolean }>`
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: #dc0938;
  visibility: ${({ $display }) => ($display ? "visible" : "hide")};
`;

const OrderDiscountNotice = styled.div`
  display: none;
  font-size: 12px;
`;
