import { faSnowflake } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Feature } from 'src/components/Feature';
import { Flex } from 'src/components/Layout';
import { FEATURES } from 'src/constants';
import styled from 'styled-components';

export const GiftCardEntry = () => {
  return (
    <Feature name={FEATURES.GIFT_CARD}>
      <Link to="/giftCard">
        <Container>
          <Flex justify="center" align="center" heightFull>
            <FontAwesomeIcon icon={faSnowflake} />
            <span>gift card</span>
          </Flex>
        </Container>
      </Link>
    </Feature>
  );
};

export const Container = styled.div`
  position: fixed;
  top: var(--nav-height);
  left: 0px;
  z-index: 2;
  background-color: #fe4945;
  box-shadow: 0 4px 4px 0 rgb(28 29 33 / 50%);
  width: 100vw;
  height: var(--announcement-height-short);
  color: white;
  svg {
    margin-right: 15px;
    font-size: 20px;
  }
  svg:last-child {
    position: absolute;
    right: 0px;
  }
  span {
    font-family: 'Theano Didot', serif;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 0.6px;
    text-transform: capitalize;
  }
`;
