import React, { ReactElement } from "react";
import { DishType, SerializedDish } from "src/api/dish";
import { GroupDishChooser } from "./GroupDishChooser";
import { NormalDishChooser } from "./NormalDishChooser";
import { Good } from "./PureDishChooser";
import { LOGGING } from "../";
export type DishChooserFactoryProps = {
  dish: SerializedDish;
  goodKey: string;
  good: Good;
  countInCart: number;
  onAddGoodsToCart: (goods: Good[]) => void;
  onUpdateGoodInCart: (goodKey: string, updatedGood: Good) => void;
  onClickCountInCart?: () => void;
  modalify: boolean;
  isMember?: boolean;
  isAuthenticated?: boolean;
  onStartMembership?: () => void;
  bottom?: number;
  quantityCap: number;
  isNotOrganizer?: boolean;
};

export const DishChooserFactory: React.FC<DishChooserFactoryProps> = (
  props: DishChooserFactoryProps
): ReactElement => {
  const { good, goodKey, dish } = props;
  LOGGING &&
    console.log("DishChooserFactory got :", {
      good,
      goodKey,
    });
  // const { dish } = data;
  switch (dish.dishType) {
    case DishType.UNKNOWN:
    case DishType.NORMAL:
      return <NormalDishChooser {...props} />;
    case DishType.GROUP:
      return <GroupDishChooser {...props} />;
  }
};
