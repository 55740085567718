import { useCallback, useEffect } from "react";
import { Button } from "src/components/Button";
import { Flex } from "src/components/Layout";
import { Modal } from "src/components/Modal";
import { useForm } from "src/hooks/useForm";
import styled from "styled-components";

type OrderContractEditModalProps = {
  isOpen: boolean;
  order?: any;
  onSave: (formData: FormData) => void;
  onClose: () => void;
};

type FormData = {
  name?: string;
  phone?: string;
  email?: string;
  address?: string;
};

export const OrderContractEditModal = (props: OrderContractEditModalProps) => {
  const { isOpen, order, onSave, onClose } = props;

  const { formData, setFormData, onInputChange } = useForm<FormData>();

  const onOrderSave = useCallback(() => {
    onSave(formData);
  }, [formData, onSave]);

  useEffect(() => {
    if (isOpen) {
      setFormData(order);
    }
  }, [order, isOpen, setFormData]);

  return (
    <Modal
      isOpen={isOpen}
      title="Order Contact Edit"
      showClose
      onClose={onClose}
    >
      <Form>
        <div className="form-row">
          <div className="form-field-wrapper">
            <Flex gap={12} align="baseline">
              <FormLabel>Name</FormLabel>
              <input
                name="name"
                value={formData.name || ""}
                onChange={onInputChange}
                placeholder="Enter Name"
              />
            </Flex>
          </div>
        </div>
        <div className="form-row">
          <div className="form-field-wrapper">
            <Flex gap={12} align="baseline">
              <FormLabel>Phone</FormLabel>
              <input
                name="phone"
                value={formData.phone || ""}
                onChange={onInputChange}
                placeholder="Enter Phone"
              />
            </Flex>
          </div>
        </div>
        <div className="form-row">
          <div className="form-field-wrapper">
            <Flex gap={12} align="baseline">
              <FormLabel>Email</FormLabel>
              <input
                name="email"
                value={formData.email || ""}
                onChange={onInputChange}
                placeholder="Enter Email"
              />
            </Flex>
          </div>
        </div>
        <div className="form-row">
          <div className="form-field-wrapper">
            <Flex gap={12} align="baseline">
              <FormLabel>Address</FormLabel>
              {/* <div style={{ marginTop: 10 }}>{formData.address}</div> */}
              <input
                name="address"
                value={formData.address || ""}
                onChange={onInputChange}
                placeholder="Enter Address"
              />
            </Flex>
          </div>
        </div>
        <Flex justify="center">
          <StyledButton onClick={onOrderSave}>Save</StyledButton>
          <StyledButton variant="secondary" onClick={onClose}>
            Cancel
          </StyledButton>
        </Flex>
      </Form>
    </Modal>
  );
};

const Form = styled.div`
  font-size: 12px;
  width: 650px;
  margin-top: 24px;

  input {
    width: 400px;
  }
`;

const StyledButton = styled(Button)`
  margin: 24px;
`;

const FormLabel = styled.label`
  font-size: 12px;
  width: 170px;
  text-align: right;
  padding-right: 6px;
  flex-shrink: 0;
`;
