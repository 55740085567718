import React from 'react';
import './Post.scss';
import { faBookmark as faBookmarkSolid } from '@fortawesome/free-solid-svg-icons';
import {
  faBookmark as faBookmarkEmpty,
  faPencil,
  faTrash,
  faCheck,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { ProfileLink, Dish, PopUp, BookmarkStatus } from './';

const LOGGING = false;

export const TimeMonitor = ({ time1, time2, time3, time4, time5 }) => (
  <div className="time-monitor">
    <div className="stamp">
      <span className="note">loaded:</span>
      <span className="time"> {time1 ? time1.toISOString().substring(11, 23) : ''}</span>
    </div>
    <div className="stamp">
      <span className="note">client sent query:</span>
      <span className="time">{time2 ? time2.toISOString().substring(11, 23) : ''}</span>
    </div>
    <div className="stamp">
      <span className="note">server received query: </span>
      <span className="time">{time3 ? time3.substring(11, 23) : ''}</span>
    </div>
    <div className="stamp">
      <span className="note">server got mongo:</span>
      <span className="time">{time4 ? time4.substring(11, 23) : ''}</span>
    </div>
    <div className="stamp">
      <span className="note">client got server: </span>
      <span className="time">{time5 ? time5.toISOString().substring(11, 23) : ''}</span>
    </div>
  </div>
);

export const BookmarkCount = ({ bookmarkCounts }) =>
  bookmarkCounts > 0 ? (
    <p className="counts">
      <span>{bookmarkCounts}</span> {`bookmark${bookmarkCounts > 1 ? 's' : ''}`}
    </p>
  ) : null;

export const BookmarkCountByIcon = ({ bookmarkCounts }) =>
  bookmarkCounts > 0 ? (
    <p className="counts bookmark-by-icon">
      <span>{bookmarkCounts}</span> <FontAwesomeIcon icon={faBookmarkSolid} />
    </p>
  ) : null;

export const LikeCount = ({ onShowRecommenders, likes, showRecommenders, onHideRecommenders }) =>
  likes.length > 0 ? (
    <React.Fragment>
      <button className="counts likes" type="button" onClick={onShowRecommenders}>
        <span>{likes.length}</span> {`like${likes.length > 1 ? 's' : ''}`}
        <PopUp
          isPoppedUp={showRecommenders}
          componentToDisplay={
            <ul className="recommender-list">
              {likes.map((r, i) => (
                <ProfileLink key={i} user={r.user} />
              ))}
            </ul>
          }
          hidePopUp={onHideRecommenders}
          backgroundColor="transparent"
        />
      </button>
    </React.Fragment>
  ) : null;

export const CountSection = ({
  bookmarks,
  onShowRecommenders,
  showRecommenders,
  onHideRecommenders,
}) => (
  <div className="section counts-section">
    {/* <BookmarkCount
      bookmarkCounts={
        bookmarks.filter((b) => b.status === BookmarkStatus.BOOKMARKED).length
      }
    /> */}
    <LikeCount
      likes={bookmarks.filter((b) => b.status === BookmarkStatus.LIKED)}
      showRecommenders={showRecommenders}
      onShowRecommenders={onShowRecommenders}
      onHideRecommenders={onHideRecommenders}
    />
    {/* <BookmarkCountByIcon
      bookmarkCounts={
        bookmarks.filter((b) => b.status === BookmarkStatus.BOOKMARKED).length
      }
    /> */}
  </div>
);
const Post = ({
  currentUser,
  post,
  createdByUser,
  onSetBookmark,
  onCreateBookmark,
  onDelete,
  onShowRecommenders,
  onHideRecommenders,
  showRecommenders,
  showStatement,
  showRecommenderTitle,
}) => {
  LOGGING && console.log('Post rendering with: ', { post, createdByUser });
  const postActions = createdByUser ? (
    <div className="post-actions">
      <Link className="edit-post" to={`/post/${post._id}`}>
        <FontAwesomeIcon icon={faPencil} />
        <span>edit</span>
      </Link>
      <button type="button" className="delete-post" onClick={onDelete}>
        <FontAwesomeIcon icon={faTrash} />
        <span>delete</span>
      </button>
    </div>
  ) : null;
  let comments = [];
  if (
    post.recommendation &&
    post.recommendation.content &&
    post.recommendation.content.length > 0
  ) {
    comments.push(
      <p className="comment-entry" key={0}>
        {post.user ? (
          <Link className="author" to={`/profile/${post.user.email}`} key={3}>
            {post.user.firstName || post.user.email}
          </Link>
        ) : null}
        {post.recommendation.content}
      </p>
    );
  }

  const { bookmarks } = post;
  const bookmarkByUser = currentUser.isAuthenticated
    ? bookmarks && bookmarks.find((b) => b.user._id === currentUser.user._id)
    : null;

  // // const commentByUser = post.comments.find(
  // //   (c) => c.user._id === currentUser.user._id
  // // );

  // LOGGING &&
  //   console.log("Post rendering with: ", {
  //     // post,
  //     currentUser,
  //     bookmarks,
  //     bookmarkByUser,
  //     createdByUser,
  //   });

  // // const bookmarkCounts =
  // //   bookmarks.length > 0 ? (
  // //     <div className="want-to-try-it">
  // //       {`${bookmarks.length} foodie${
  // //         bookmarks.length > 1 ? "s bookmarked" : " bookmarked"
  // //       } it.`}
  // //     </div>
  // //   ) : null;

  // // const bookmarkCounts = bookmarks.filter(
  // //   (b) => b.status === BookmarkStatus.BOOKMARKED
  // // ).length;

  // // const recommendations = bookmarks.filter(
  // //   (b) => b.status === BookmarkStatus.LIKED
  // // );
  // // const recommendationCounts = recommendations.length;

  let buttons = [];

  // // only add bookmark button if it's not
  // // posted by the current user
  if (!createdByUser) {
    // only add bookmark button if it's not
    // posted by the current user
    const removeBookmark = (
      <button
        className="bookmark-button"
        type="button"
        onClick={onSetBookmark.bind(this, bookmarkByUser, BookmarkStatus.CANCELLED)}
        key={0}
      >
        <FontAwesomeIcon icon={faBookmarkSolid} />
        <span>Remove bookmark</span>
      </button>
    );

    const addBookmark = currentUser.isAuthenticated ? (
      <button
        className="bookmark-button"
        type="button"
        onClick={
          bookmarkByUser
            ? onSetBookmark.bind(this, bookmarkByUser, BookmarkStatus.BOOKMARKED)
            : onCreateBookmark.bind(this, currentUser.user._id, post._id)
        }
        key={1}
      >
        <FontAwesomeIcon icon={faBookmarkEmpty} />
        <span>Add bookmark</span>
      </button>
    ) : (
      <Link className="bookmark-button" to="/auth" key={0}>
        <FontAwesomeIcon icon={faBookmarkEmpty} />
        <span>Add bookmark</span>
      </Link>
    );
    const editBookmark = currentUser.isAuthenticated ? (
      <button
        className="bookmark-button"
        type="button"
        onClick={
          bookmarkByUser
            ? onSetBookmark.bind(this, bookmarkByUser, BookmarkStatus.BOOKMARKED)
            : onCreateBookmark.bind(this, currentUser.user._id, post._id)
        }
        key={1}
      >
        <FontAwesomeIcon icon={faCheck} />
        <span>I have tried it.</span>
      </button>
    ) : (
      <Link className="bookmark-button" to="/auth" key={0}>
        <FontAwesomeIcon icon={faBookmarkEmpty} />
        <span>Add bookmark</span>
      </Link>
    );
    //   const recommendBookmark = ( //eslint-disable-line no-unused-vars
    //     <Link
    //       className="bookmark-button"
    //       to={`/recommendation/${post._id}`}
    //       key={2}
    //     >
    //       <FontAwesomeIcon icon={faThumbsUp} />
    //       <span>I also like this place.</span>
    //     </Link>
    //   );

    if (bookmarkByUser) {
      const { status } = bookmarkByUser;

      // if bookmarkByUser is cancelled, add it
      if (status === BookmarkStatus.CANCELLED) {
        buttons.push(addBookmark);
        // if bookmarkByUser is bookmarked, remove it
      } else if (status === BookmarkStatus.BOOKMARKED) {
        buttons.push(removeBookmark);
        // if bookmarkByUser is ARCHIVED or LIKED, show bookmarked
      } else {
        buttons.push(editBookmark);
      }
    } else {
      // if no bookmark exists for the user, show the add button
      // buttons.push(recommendBookmark);
      buttons.push(addBookmark);
    }
  }

  // if (
  //   bookmarkByUser &&
  //   bookmarkByUser.review &&
  //   bookmarkByUser.review.content &&
  //   bookmarkByUser.status === BookmarkStatus.LIKED
  // ) {
  //   comments.push(
  //     <p className="comment-entry" key={1}>
  //       <Link
  //         className="author"
  //         to={`/profile/${bookmarkByUser.user.email}`}
  //         key={4}
  //       >
  //         {bookmarkByUser.user.firstName}
  //       </Link>
  //       {bookmarkByUser.review.content}
  //     </p>
  //   );
  // }

  // const recommendationsByOthers = currentUser.isAuthenticated
  //   ? recommendations.filter(
  //       (r) =>
  //         r.user._id !== currentUser.user._id && r.review && r.review.content
  //     )
  //   : recommendations.filter((r) => r.review && r.review.content);

  // const recommendationsComments = recommendationsByOthers.map((r) => (
  //   <p className="comment-entry" key={r._id}>
  //     <Link className="author" to={`/profile/${r.user.email}`} key={5}>
  //       {r.user.firstName}
  //     </Link>
  //     {r.review.content}
  //   </p>
  // ));

  // comments = comments.concat(recommendationsComments);
  /* eslint-disable */
  // const seeAllComments =
  //   post.comments.length > 0 ? (
  //     <Link className="see-all-reviews" to={`/post/${post._id}`}>{`See all ${
  //       post.comments.length + 1
  //     } recommendations`}</Link>
  //   ) : null;

  // const allComments = post.comments.map((c) => (
  //   <div className="comment-entry" key={c._id}>
  //     <div className="author">{c.user.firstName}</div>
  //     <div className="content">{c.content}</div>
  //   </div>
  // ));
  /* eslint-enable */

  // LOGGING &&
  //   console.log("Post rendering iwth: ", {
  //     post,
  //     currentUser,
  //     bookmarks,
  //     bookmarkByUser,
  //     createdByUser,
  //   });
  // return bookmarkByUser ? null : (
  //   <div className="post">
  //     <div
  //       className={`dish-wrapper ${post.imageURL ? "has-image" : "no-image"}`}
  //       // className={`dish-wrapper`}
  //     >
  //       {post.imageURL ? (
  //         <div
  //           className="dish-image"
  //           // style={{ backgroundImage: `url(${post.imageURL})` }}
  //         >
  //           <img src={post.imageURL} alt="new" />
  //           <div className="disclaimer-icon">
  //             <span className="i-icon">i</span>
  //             <span className="from-yelp">Photo from Yelp.</span>
  //           </div>
  //         </div>
  //       ) : null}
  //       <div
  //         className="section dish-border"
  //         // style={{ borderColor: post.user.profileColor }}
  //       >
  //         {/* {showRecommenderTitle ? (
  //           <div className="recommender statement">
  //             <ProfileLink user={post.user} />
  //             <span className="recommends">{" recommends"}</span>
  //           </div>
  //         ) : null} */}
  //         <Dish
  //           dish={post.dish}
  //           restaurant={post.restaurant}
  //           imageURL={post.imageURL}
  //         />
  //         {postActions}
  //       </div>
  //       {comments.length > 0 ? (
  //         <div className="section comments-section">{comments}</div>
  //       ) : null}
  //       {/* {bookmarks.filter((b) => b.status === BookmarkStatus.LIKED).length >
  //       0 ? (
  //         <CountSection
  //           bookmarks={bookmarks}
  //           onShowRecommenders={onShowRecommenders}
  //           showRecommenders={showRecommenders}
  //           onHideRecommenders={onHideRecommenders}
  //         />
  //       ) : null} */}

  //       {buttons.length > 0 ? (
  //         <div className="section buttons-section">{buttons}</div>
  //       ) : null}
  //     </div>
  //   </div>
  // );
  return (
    <div className="post">
      {showStatement ? (
        <div className="recommender statement">
          <ProfileLink user={post.user} />
          <span className="recommends">{' recommends'}</span>
        </div>
      ) : null}
      <div className={`dish-wrapper ${post.imageURL ? 'has-image' : 'no-image'}`}>
        {post.imageURL ? (
          <div className="dish-image">
            <img src={post.imageURL} alt="new" />
            <div className="disclaimer-icon">
              <span className="i-icon">i</span>
              <span className="from-yelp">Photo from Yelp.</span>
            </div>
          </div>
        ) : null}
        <div className="section dish-border">
          <Dish dish={post.dish} restaurant={post.restaurant} imageURL={post.imageURL} />
          {postActions}
        </div>
        {comments.length > 0 ? <div className="section comments-section">{comments}</div> : null}
        {buttons.length > 0 ? <div className="section buttons-section">{buttons}</div> : null}
      </div>
    </div>
  );
};

export default Post;
