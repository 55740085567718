import React, { ReactElement, useCallback } from 'react';
import { useAsync } from 'react-async';
import { GetRestaurantPaymentsForRestaurant } from 'src/api/restaurantPayments';
import { Loading } from '..';
import { RestaurantPaymentSummary } from './RestaurantPaymentSummary';

interface RestaurantPaymentSummaryContainerProps {
  restaurantId: string;
}

export const RestaurantPaymentSummaryContainer: React.FC<RestaurantPaymentSummaryContainerProps> =
  ({ restaurantId }): ReactElement => {
    const fetch = useCallback(async ({ restaurantId }) => {
      return await GetRestaurantPaymentsForRestaurant(restaurantId);
    }, []);

    const { data } = useAsync({ promiseFn: fetch, restaurantId });

    if (data == null) return <Loading />;

    return <RestaurantPaymentSummary restaurantPayments={data.restaurantPayments} />;
  };
