import { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { Loading } from "../../shared";

// @param awaitable async (dispatch, getState func) => {}. The server call
// @param loadingComponent [React.Node]. Component to render while loading
// @param children [React.Node]. The component to render after async function
export const AwaitServerResponseContainer = ({
  awaitable,
  loadingComponent,
  children,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const store = useStore();

  useEffect(() => {
    awaitable(dispatch, store.getState).then(() => setIsLoading(false));
  }, [awaitable, dispatch, store.getState]);

  return isLoading ? loadingComponent : children;
};

AwaitServerResponseContainer.defaultProps = {
  loadingComponent: null,
};
