import './CartThumbnail.scss';
import { faShoppingCart as logo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { LOGGING } from '../../../constants';

const CartThumbnail = ({ numberOfItems, cart, onBack }) => {
  LOGGING &&
    console.log('CartThumbnail rendering with:', {
      numberOfItems,
      cart,
    });

  const content = (
    <div
      style={{
        backgroundColor: 'black',
        borderRadius: '24px',
        color: 'white',
        padding: '8px',
      }}
      className="cart-content"
    >
      <FontAwesomeIcon icon={logo} />
      <span className="cart-length">{numberOfItems}</span>
    </div>
  );
  return onBack ? (
    <button onClick={onBack} className={`cart-thumbnail ${numberOfItems > 0 ? 'full' : 'empty'}`}>
      {content}
    </button>
  ) : (
    <Link className={`cart-thumbnail ${numberOfItems > 0 ? 'full' : 'empty'}`} to="/cart">
      {content}
    </Link>
  );
};

export default CartThumbnail;
