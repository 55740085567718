import React from "react";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { LoadingAuth } from "./Loading";

const NewUser = ({
  onEdit,
  onSave,
  // firstName,
  // lastName,
  email,
  password,
  passwordConfirm,
  error,
  isChangingOldUser,
}) => (
  <form className="form-container" onSubmit={onSave}>
    {error ? <div className="form-error">{error.message}</div> : null}
    <div className="form-body">
      <div className="form-header">
        {isChangingOldUser ? <label>Email</label> : null}
        <input
          name="email"
          defaultValue={email}
          onChange={onEdit}
          placeholder="email"
          className="form-field"
        />
        {/* <input
          name="firstName"
          placeholder="First Name (optional)"
          defaultValue={firstName}
          onChange={onEdit}
          className="form-field"
        />
        <input
          name="lastName"
          placeholder="Last Name (optional)"
          defaultValue={lastName}
          onChange={onEdit}
          className="form-field"
        /> */}

        {isChangingOldUser ? null : (
          <React.Fragment>
            <input
              name="password"
              placeholder="Password"
              defaultValue={password}
              onChange={onEdit}
              className="form-field"
              type="password"
            />
            <input
              name="passwordConfirm"
              placeholder="Re-Type Password"
              defaultValue={passwordConfirm}
              onChange={onEdit}
              className="form-field"
              type="password"
            />
          </React.Fragment>
        )}
      </div>

      {isChangingOldUser ? (
        <Link to="/changepassword" className="action-grey change-password">
          change password...
        </Link>
      ) : null}
    </div>
    <button type="submit">{isChangingOldUser ? "Save" : "sign up"}</button>
  </form>
);

const ExistingUser = ({
  onEdit,
  onSignIn,
  onToggleShowPassword,
  email,
  password,
  showPassword,
  errorMessage,
  loading,
}) =>
  loading ? (
    <LoadingAuth />
  ) : (
    <form className="new-user" onSubmit={onSignIn}>
      {errorMessage ? (
        <div className="error-message">{errorMessage}</div>
      ) : null}
      <div className="form-row">
        <input
          name="email"
          defaultValue={email}
          onChange={onEdit}
          placeholder="email"
          className="form-field"
        />
      </div>
      <div className="form-row password-row">
        <input
          name="password"
          placeholder="Password"
          defaultValue={password}
          onChange={onEdit}
          type={showPassword ? "text" : "password"}
          className="form-field"
        />
        <button type="button" onClick={onToggleShowPassword}>
          <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
        </button>
      </div>
      <button type="submit" className="main-action sign-in">
        log in
      </button>
      <div className="form-notes">
        <Link
          className="alternative-action forget-password"
          to="/forgetpassword"
        >
          forgot password?
        </Link>
      </div>
      <div className="form-notes already-signed-up">
        <span>Don't have an account?</span>
        <Link
          className="alternative-action sign-in"
          to={{
            pathname:
              // this.props.location?.state?.secondary?.next ||
              "/signup",
            // state: { next },
          }}
        >
          sign up
        </Link>
      </div>
    </form>
  );

export { NewUser, ExistingUser };
