import React, { ReactElement, useMemo } from 'react';
import { Column, useTable } from 'react-table';
import { SerializedRestaurantPayment } from 'src/api/restaurantPayments';
import styled from 'styled-components';

interface RestaurantPaymentSummaryProps {
  restaurantPayments: SerializedRestaurantPayment[];
}

type TableData = {
  id: string;
  dates: string;
  subtotal: string;
  tax: string;
  commission: string;
  adjustments: string;
  payout: string;
};

const Container = styled.div`
  display: flex;
`;

export const RestaurantPaymentSummary: React.FC<RestaurantPaymentSummaryProps> = ({
  restaurantPayments,
}): ReactElement => {
  const sortedRestaurantPayments = restaurantPayments.sort((a, b) =>
    b.endDate > a.endDate ? 1 : -1
  );

  const tableData = useMemo<TableData[]>(
    () =>
      sortedRestaurantPayments.map((rp) => {
        return {
          id: rp._id,
          dates: `${rp.startDate} - ${rp.endDate}`,
          subtotal: `$${rp.subtotal.toFixed(2)}`,
          tax: `$${rp.tax.toFixed(2)}`,
          commission: `-$${rp.commission.toFixed(2)}`,
          adjustments: `$${rp.adjustments.toFixed(2)}`,
          payout: `$${rp.payout.toFixed(2)}`,
        };
      }),
    [sortedRestaurantPayments]
  );

  const columns = useMemo<Column<TableData>[]>(
    () => [
      {
        Header: 'Payment ID',
        accessor: 'id',
      },
      {
        Header: 'Transaction Dates',
        accessor: 'dates',
      },
      {
        Header: 'Subtotal',
        accessor: 'subtotal',
      },
      {
        Header: 'Tax',
        accessor: 'tax',
      },
      {
        Header: 'Commission',
        accessor: 'commission',
      },
      {
        Header: 'Adjustments',
        accessor: 'adjustments',
      },
      {
        Header: 'Payout',
        accessor: 'payout',
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: tableData,
  });

  return (
    <Container>
      {sortedRestaurantPayments.length > 0 ? (
        <table {...getTableProps()} style={{ border: 'solid 1px black' }}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      background: 'aliceblue',
                      color: 'black',
                      fontWeight: 'bold',
                      padding: '8px',
                    }}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: '8px',
                          textAlign: 'right',
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div>No past payments...</div>
      )}
    </Container>
  );
};
