import { LOAD_ORDERS, RESET_ORDERS } from '../actionTypes';

const orders = (state = [], action) => {
  switch (action.type) {
    case LOAD_ORDERS:
      return [...action.orders];
    case RESET_ORDERS:
      return [];
    default:
      return state;
  }
};

export default orders;
