import { useCallback, useEffect, useRef, useState } from 'react';
import { GoogleApiWrapper } from 'google-maps-react';
import { confirmAlert } from 'react-confirm-alert';
import Geosuggest from 'react-geosuggest';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Flex } from 'src/components/Layout';
import { CONFIG_KEY } from 'src/constants';
import { ConfirmAlert } from 'src/screens/shared';
import { Navbar } from 'src/screens/tasting_menu';
import { getAddressList, updateAddressList } from 'src/store/actions';
import styled from 'styled-components';

type AddressType = 'white' | 'black';

const ConfigBase = () => {
  const currentUser = useSelector((state: any) => state.currentUser);
  const addressWhiteList = useSelector(
    (state: any) => state.config[CONFIG_KEY.ADDRESS_WHITE_LIST] || []
  );
  const addressBlackList = useSelector(
    (state: any) => state.config[CONFIG_KEY.ADDRESS_BLACK_LIST] || []
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAddressList());
  }, [dispatch]);

  const [addressWhite, setAddressWhite] = useState<string>('');
  const [addressBlack, setAddressBlack] = useState<string>('');

  const addressWhiteListGeosuggestEl = useRef<Geosuggest>(null);
  const addressBlackListGeosuggestEl = useRef<Geosuggest>(null);

  const addAddress = useCallback(
    (type: AddressType) => {
      switch (type) {
        case 'white':
          addressWhiteListGeosuggestEl.current?.clear();
          dispatch(updateAddressList([...addressWhiteList, addressWhite], addressBlackList));
          break;
        case 'black':
          addressBlackListGeosuggestEl.current?.clear();
          dispatch(updateAddressList(addressWhiteList, [...addressBlackList, addressBlack]));
          break;
      }
    },
    [dispatch, addressWhiteList, addressWhite, addressBlackList, addressBlack]
  );

  const removeAddress = useCallback(
    (removeAddress: string, type: AddressType) => {
      switch (type) {
        case 'white':
          dispatch(
            updateAddressList(
              addressWhiteList.filter((address: string) => address !== removeAddress),
              addressBlackList
            )
          );
          break;
        case 'black':
          dispatch(
            updateAddressList(
              addressWhiteList,
              addressBlackList.filter((address: string) => address !== removeAddress)
            )
          );
          break;
      }
    },
    [addressBlackList, addressWhiteList, dispatch]
  );

  const removeAddressConfirm = (address: string, type: AddressType) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmAlert
            onConfirm={() => removeAddress(address, type)}
            onClose={onClose}
            actionName="delete"
            message={undefined}
            yesWord={undefined}
            noWord={undefined}
            title={undefined}
          />
        );
      },
    });
  };

  if (!currentUser.isAuthenticated || !currentUser.user.isAdmin) {
    return <Redirect to="/" />;
  }

  return (
    <div className="page">
      <Navbar />
      <ConfigPageContainer>
        <h3>Add White Address</h3>
        <Flex align="center" gap={24}>
          <Geosuggest
            ref={addressWhiteListGeosuggestEl}
            placeholder="Enter White address"
            autoActivateFirstSuggest={true}
            onSuggestSelect={(suggest) => setAddressWhite(suggest?.label)}
            onChange={(address) => setAddressWhite(address)}
            placeDetailFields={['address_components', 'adr_address', 'name']}
          />
          <Button onClick={() => addAddress('white')}>Add</Button>
        </Flex>
        <h3>Address White List</h3>
        <Flex direction="column" gap={16}>
          {addressWhiteList.map((address: string) => (
            <Flex justify="space-between" align="center" gap={6} key={address}>
              <div>{address}</div>
              <Button onClick={() => removeAddressConfirm(address, 'white')}>Delete</Button>
            </Flex>
          ))}
        </Flex>
        <h3 style={{ marginTop: 64 }}>Add Black Address</h3>
        <Flex align="center" gap={24}>
          <Geosuggest
            ref={addressBlackListGeosuggestEl}
            placeholder="Enter Black address"
            autoActivateFirstSuggest={true}
            onSuggestSelect={(suggest) => setAddressBlack(suggest?.label)}
            onChange={(address) => setAddressBlack(address)}
            placeDetailFields={['address_components', 'adr_address', 'name']}
          />
          <Button onClick={() => addAddress('black')}>Add</Button>
        </Flex>
        <h3>Address Black List</h3>
        <Flex direction="column" gap={16}>
          {addressBlackList.map((address: string) => (
            <Flex justify="space-between" align="center" gap={6} key={address}>
              <div>{address}</div>
              <Button onClick={() => removeAddressConfirm(address, 'black')}>Delete</Button>
            </Flex>
          ))}
        </Flex>
      </ConfigPageContainer>
    </div>
  );
};

// TODO: need add to ui components
const ConfigPageContainer = styled.div`
  width: 100%;
  padding: 24px;
`;

const Button = styled.button`
  flex-shrink: 0;
  padding: 8px 16px;
  color: #fff;
  background: #000;
`;

export const Config = GoogleApiWrapper({
  // TODO: should add to env
  apiKey: 'AIzaSyBjfTfgJdRah5wXvVj8U7fH4ACoqKry9HI',
})(ConfigBase);
