import { useCallback, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import {
  askDriverAvailability,
  acceptDriverAvailability,
} from "src/store/actions";
import { cancelDriver } from "src/store/actions/adminDeliveryV2";
import { ADMIN_DELIVERY_V2_INVITE_DRIVER } from "src/store/actionTypes";
import { getCompleteWindowsForTheDay, getNextWindow } from "src/util/time";
import { LOGGING, Select } from ".";
import { DriverSectionV2 } from "./DriverSectionV2";

export const AskDriverList = ({ mealType }) => {
  // From Store

  const drivers = useSelector((state) => state.adminDeliveryV2.drivers);
  const meals = useSelector((state) =>
    state.adminDeliveryV2.meals.filter((m) => m.mealType === mealType)
  );
  // console.log('AskDriverList init meals:', { meals, filteredMeals, mealType });
  const store = useStore();
  const dispatch = useDispatch();
  // State
  const [showMealOptions, setShowMealOptions] = useState(false);
  const [inviteMealIndex, setInviteMealIndex] = useState(0);
  const [resetDriverId, setResetDriverId] = useState(null);

  const toggleShowOptions = useCallback(() => {
    setShowMealOptions(!showMealOptions);
  }, [showMealOptions]);
  const selectMeal = useCallback(
    (selectedIndex) => {
      setInviteMealIndex(selectedIndex);
      toggleShowOptions();
    },
    [toggleShowOptions]
  );

  const inviteDriver = useCallback(
    async (driverId) => {
      const inviteMeal = meals[inviteMealIndex];

      const windowsForDay = getCompleteWindowsForTheDay();
      const pickupTime = windowsForDay[mealType].driverPickup;

      const inviteRestaurant = {
        name: inviteMeal.restaurant.name,
        address: inviteMeal.restaurant.address,
      };
      await askDriverAvailability(
        driverId,
        inviteMeal._id,
        inviteRestaurant,
        pickupTime
      )(null, store.getState);
      dispatch({
        type: ADMIN_DELIVERY_V2_INVITE_DRIVER,
        driverId: driverId,
        restaurantName: inviteMeal.restaurant.name,
      });
    },
    [meals, inviteMealIndex, store, dispatch]
  );

  const acceptDriver = useCallback(
    async (driverId) => {
      const inviteMeal = meals[inviteMealIndex];

      const windowsForDay = getCompleteWindowsForTheDay();
      const pickupTime = windowsForDay[mealType].driverPickup;

      const inviteRestaurant = {
        name: inviteMeal.restaurant.name,
        address: inviteMeal.restaurant.address,
      };
      await acceptDriverAvailability(
        driverId,
        inviteMeal._id,
        inviteRestaurant,
        pickupTime
      )(null, store.getState);
      dispatch({
        type: ADMIN_DELIVERY_V2_INVITE_DRIVER,
        driverId: driverId,
        restaurantName: inviteMeal.restaurant.name,
      });
    },
    [meals, inviteMealIndex, store, dispatch]
  );

  // Driver Sections

  const onResetDriver = useCallback(
    async (driverId) => {
      LOGGING &&
        console.log("onResetDriver is called with driverId:", driverId);
      setResetDriverId(driverId);
      await cancelDriver(driverId)(store.dispatch, store.getState);
      LOGGING && console.log("onResetDriver done", driverId);
      setResetDriverId(null);
      //scroll drivers-availability-v2 to top
      // const scrollContainer = document.getElementsByClassName(
      //   "drivers-availability-v2"
      // )[0];
      // if (scrollContainer) {
      //   // scrollContainer.scrollTop = scrollContainer.scrollHeight;
      //   scrollContainer.scrollTo({
      //     top: scrollContainer.scrollHeight,
      //     behavior: "smooth", // Add smooth behavior
      //   });
      // }
    },
    [store, dispatch]
  );

  const acceptedDrivers = drivers.filter(
    (d) => d.askedAvailability === true && d.routeAcceptanceResponse === true
  );
  const rejectedDrivers = drivers.filter(
    (d) => d.askedAvailability === true && d.routeAcceptanceResponse === false
  );
  const expiredDrivers = drivers.filter(
    (d) => d.askedAvailability === true && d.askedAvailabilityExpired === true
  );
  const waitingDrivers = drivers.filter(
    (d) => d.askedAvailability === true && d.askedAvailabilityExpired === false
  );
  const unAskedDrivers = drivers.filter((d) => d.askedAvailability === false);
  console.log("AskDriverList rendering: ", { unAskedDrivers, drivers });
  return (
    <div className="drivers-availability-v2">
      <div className="available-drivers-title">
        <span>driver list</span>
      </div>
      <span style={{ borderBottom: "1px solid black", margin: "8px" }}>
        Select Restaurant to Invite
      </span>
      <Select
        selected={inviteMealIndex}
        onSelect={selectMeal}
        options={meals.map((_m, i) => i)}
        optionWords={meals.map((m) => m.restaurant.name)}
        showOptions={showMealOptions}
        onToggleShowOptions={toggleShowOptions}
        onHideShowOptions={toggleShowOptions}
      />
      <DriverSectionV2 drivers={acceptedDrivers} status="accepted" />
      <DriverSectionV2
        drivers={rejectedDrivers}
        status="rejected"
        resetDriver={onResetDriver}
        resetDriverId={resetDriverId}
      />
      <DriverSectionV2
        drivers={expiredDrivers}
        status="expired"
        resetDriver={onResetDriver}
        resetDriverId={resetDriverId}
      />
      <DriverSectionV2
        drivers={waitingDrivers}
        status="waiting"
        resetDriver={onResetDriver}
        resetDriverId={resetDriverId}
      />
      <DriverSectionV2
        drivers={unAskedDrivers}
        status="uninvited"
        onInvite={inviteDriver}
        inviteRestaurantName={meals[inviteMealIndex].restaurant.name}
        acceptDriver={acceptDriver}
      />
    </div>
  );
};
