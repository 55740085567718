import { combineArrays } from "src/util/combineArrays";
import { LOGGING } from "../../constants";
import { LOAD_TASKS } from "../actionTypes";
import { apiCall } from "../api";

export const loadTasks = (tasks) => ({
  type: LOAD_TASKS,
  tasks,
});

export const getTasksForRoute = (routeId) => {
  return async (dispatch, getState) => {
    const { currentUser } = getState();
    try {
      const tasks = await apiCall(
        "post",
        `/tasks/GetTasksForRoute`,
        {
          routeId: routeId,
        },
        currentUser
      );
      LOGGING && console.log("getTasksForRoute got tasks", tasks);
      dispatch(loadTasks(tasks || {}));
    } catch (err) {
      LOGGING && console.log("getTasksForRoute got err", err);
    }
  };
};

export const completeTaskForDriver = (taskId) => {
  return async (dispatch, getState) => {
    const { currentUser, tasks } = getState();

    // Update after API
    const responseTasks = await apiCall(
      "post",
      `/tasks/CompleteTaskForDriver`,
      {
        driverId: currentUser.user._id,
        taskId: taskId,
      },
      currentUser
    );
    const updatedTasks = combineArrays(
      tasks,
      responseTasks,
      (task) => task.taskId
    );
    dispatch(loadTasks(updatedTasks));
  };
};

export const skipTaskForDriver = ({ driverId, taskId }) => {
  return async (dispatch, getState) => {
    const { currentUser } = getState();

    LOGGING &&
      console.log("skipTaskForDriver called with: ", { driverId, taskId });

    const routes = await apiCall(
      "post",
      `/tasks/CompleteTaskForDriver`,
      {
        driverId: driverId,
        taskId: taskId,
        isSkip: true,
      },
      currentUser
    );
    return routes;
  };
};

export const deliverTaskForDriver = ({ driverId, taskId }) => {
  return async (dispatch, getState) => {
    const { currentUser } = getState();

    LOGGING &&
      console.log("deliverTaskForDriver called with: ", { driverId, taskId });

    const routes = await apiCall(
      "post",
      `/tasks/CompleteTaskForDriver`,
      {
        driverId: driverId,
        taskId: taskId,
        isDeliver: true,
      },
      currentUser
    );
    return routes;
  };
};
