import { LOAD_ROUTE_V2 } from '../actionTypes';

const routeV2 = (state = {}, action) => {
  switch (action.type) {
    case LOAD_ROUTE_V2:
      return action.route != null ? { ...action.route } : null;
    default:
      return state;
  }
};

export default routeV2;
