import React, { ReactElement } from "react";
import styled from "styled-components";
import { AnonymousRestaurantSlides } from "../home/CoverSliding";
import { SlideHeight } from "../home/CoverSliding";
import {
  Beige,
  Card,
  CenteredByColumn,
  Text12300,
  TextTitle,
  BorderGrey,
  ActionBlack,
  Grey,
} from "../Shared";

export interface ReferralInvitationProps {
  sender: string;
  form?: any;
  title?: string;
}

const CenterContainer = styled(Card)`
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid ${BorderGrey};
  /* margin-top: ${SlideHeight - 55}px; */
  z-index: 1;
  box-shadow: none;
  position: relative;
  width: 100%;
  @media only screen and (min-width: 800px) and (min-height: 700px) {
    margin-top: ${SlideHeight - 180}px;
  }
`;

const Button = styled(ActionBlack)`
  margin-top: 40px;
  padding: 20px 20px;
`;
const Border = styled(CenteredByColumn)`
  width: 100%;
  /* height: 100%; */
  border: 2px solid orange;
  padding: 20px; //30px calc(max(50vw - 200px, 20px));
  position: relative;
  overflow: hidden;
  flex-grow: 0;
  /* max-width: 410px; */
  form.new-user {
    width: 100%;
    margin-bottom: 0px;
    position: relative;
    .popup-container {
      position: fixed;
      top: 0px;
      left: 0px;
      .popup-overlay {
        /* background-color: transparent; */
      }
      .form-popup.get-verification-code {
        /* border: 1px solid ${Grey}; */
        position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0px;
        top: 0px;
      }
    }
  }
  @media only screen and (min-width: 800px) and (min-height: 700px) {
    /* width: 60vw; */
    /* padding: 40px 10vw; */
    form.new-user {
      max-width: 300px;
    }
  }
`;
const FreeFood = styled(Text12300)`
  font-size: 26px;
  /* font-size: 10px; */
  font-weight: 400;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  margin: 10px 0px;
  /* margin-bottom: 20px; */
`;
const Friend = styled(TextTitle)`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
  text-transform: none;
  margin-bottom: 10px;
`;
const Gift = styled(Card)`
  font-size: 45px;
  position: absolute;
  z-index: 1;
  /* bottom: -40px; */
  bottom: -25px;
  left: -20px;
  /* left: calc(50% - 40px); */
  transform: rotate(-30deg);
  height: 80px;
  width: 80px;
  text-align: center;
  line-height: 80px;
  background-color: orange;
  background-color: transparent;
  box-shadow: none;
  border-radius: 50%;
  @media only screen and (min-width: 800px) and (min-height: 700px) {
    font-size: 100px;
  }
`;
export const ReferralInvitation: React.FC<ReferralInvitationProps> = ({
  sender,
  title,
  form,
}: ReferralInvitationProps): ReactElement => {
  return (
    <CenterContainer>
      {/* <Gift>🎁</Gift> */}
      {/* <Border> */}
      {/* <AnonymousRestaurantSlides /> */}
      {/* <FreeFood>{title}</FreeFood> */}
      {/* <Friend>{sender} invited you to join Foodie Earth!</Friend> */}
      {/* <Button>Claim Free Food Now</Button> */}
      {form}
      {/* </Border> */}
    </CenterContainer>
  );
};
