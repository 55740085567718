import { useState } from 'react';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'src/components/Layout';
import styled from 'styled-components';
import { PayWithPaypal } from './PayWithPaypal';
import { PayWithPaypalProps } from './PayWithPaypal';
import { PayWithStripe, PayWithStripeProps } from './PayWithStripe';

export enum PayMethod {
  Stripe,
  Paypal,
}

type PayProps = {
  onChangePayMethod?: (payMethod: PayMethod) => void;
} & PayWithStripeProps &
  PayWithPaypalProps;

export const Pay = (props: PayProps) => {
  const {
    onChangePayMethod,
    newCard,
    newCardType,
    newCardError,
    onNewCardChange,
    onNewCardTypeChange,
    amount,
    onPaypalPaid,
  } = props;
  const [payMethod, setPayMethod] = useState<PayMethod>(PayMethod.Stripe);

  return (
    <>
      <PayMethodSelector>
        <Flex justify="center" gap={36}>
          <PayMethodItem
            $active={payMethod === PayMethod.Stripe}
            onClick={() => {
              setPayMethod(PayMethod.Stripe);
              onChangePayMethod?.(PayMethod.Stripe);
            }}
          >
            <Flex align="center" gap={6}>
              {payMethod === PayMethod.Stripe && <FontAwesomeIcon icon={faCheck} />}
              <span>pay by card</span>
            </Flex>
          </PayMethodItem>
          <PayMethodItem
            $active={payMethod === PayMethod.Paypal}
            onClick={() => {
              setPayMethod(PayMethod.Paypal);
              onChangePayMethod?.(PayMethod.Paypal);
            }}
          >
            <Flex align="center" gap={6}>
              {payMethod === PayMethod.Paypal && <FontAwesomeIcon icon={faCheck} />}
              <span>pay by paypal</span>
            </Flex>
          </PayMethodItem>
        </Flex>
      </PayMethodSelector>
      {payMethod === PayMethod.Stripe && (
        <PayWithStripe
          newCard={newCard}
          newCardType={newCardType}
          newCardError={newCardError}
          onNewCardChange={onNewCardChange}
          onNewCardTypeChange={onNewCardTypeChange}
        />
      )}
      {payMethod === PayMethod.Paypal && (
        <PayWithPaypal amount={amount} onPaypalPaid={onPaypalPaid} />
      )}
    </>
  );
};

const PayMethodSelector = styled.div`
  width: 100%;
  height: var(--subnav-height);
  margin: 20px 0 10px 0%;
`;

const PayMethodItem = styled.button<{ $active: boolean }>`
  height: 40px;
  box-sizing: border-box;
  width: auto;
  position: relative;
  box-sizing: border-box;
  padding: 0;
  color: var(--border-grey);
  border-bottom: 2px solid transparent;

  ${({ $active }) =>
    $active
      ? `
          color: black;
          border-bottom: 2px solid black;
        `
      : ''}
`;
