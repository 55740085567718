import { useCallback } from "react";
import { useDispatch, useStore } from "react-redux";
import {
  assignDriverToStop,
  assignStopNum,
  unassignDriverFromStop,
  unassignStopNum,
  selectTransit,
  deselectTransit,
} from "src/store/actions/adminDeliveryV2";
import { DriverStopSelectorList } from "./DriverStopSelectorList";

export const DriverStopSelectorListContainer = ({
  stopId,
  isTransit,
  eligibleDrivers,
}) => {
  const dispatch = useDispatch();
  const store = useStore();

  const assignDriverToStopCallback = useCallback(
    async (driverId) => {
      await assignDriverToStop(driverId, stopId)(dispatch, store.getState);
    },
    [stopId, dispatch, store.getState]
  );

  const assignStopNumCallback = useCallback(
    async (driverId, stopNum) => {
      await assignStopNum(driverId, stopId, stopNum)(dispatch, store.getState);
    },
    [stopId, dispatch, store.getState]
  );

  const unassignDriverFromStopCallback = useCallback(
    async (driverId) => {
      await unassignDriverFromStop(driverId, stopId)(dispatch, store.getState);
    },
    [stopId, dispatch, store.getState]
  );

  const unassignStopNumCallback = useCallback(
    async (driverId) => {
      await unassignStopNum(driverId, stopId)(dispatch, store.getState);
    },
    [stopId, dispatch, store.getState]
  );

  const selectTransitCallback = useCallback(
    async (stopId) => {
      await selectTransit(stopId)(dispatch, store.getState);
    },
    [stopId, dispatch, store.getState]
  );

  const deselectTransitCallback = useCallback(
    async (stopId) => {
      await deselectTransit(stopId)(dispatch, store.getState);
    },
    [stopId, dispatch, store.getState]
  );

  return (
    <DriverStopSelectorList
      stopId={stopId}
      isTransit={isTransit}
      eligibleDrivers={eligibleDrivers}
      onSelectDriver={assignDriverToStopCallback}
      onDeselectDriver={unassignDriverFromStopCallback}
      onSelectStopNum={assignStopNumCallback}
      onDeselectStopNum={unassignStopNumCallback}
      onSelectTransit={selectTransitCallback}
      onDeselectTransit={deselectTransitCallback}
    />
  );
};
