import { useEffect, useState } from 'react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { GiftCardTemplate } from 'src/components/GiftCard';
import { Cell, Grid } from 'src/components/Layout';
import { Navbar } from 'src/components/Navbar';
import { TabBar } from 'src/components/TabBar';
import { getGiftCards } from 'src/store/actions';
import styled from 'styled-components';

export const GiftCard = () => {
  const currentUser = useSelector((state: any) => state.currentUser);
  const giftCards = useSelector((state: any) => state.giftCard.giftCards);
  const dispatch = useDispatch();
  const [type, setType] = useState<string>('sent');

  useEffect(() => {
    dispatch(getGiftCards(type));
  }, [dispatch, type]);

  if (!currentUser.isAuthenticated) {
    return <Redirect to="/signup" />;
  }

  return (
    <div className="page">
      <StyledNavbar title="Gift Card" noShadow />
      <Container>
        <Link to="/giftCard/create">
          <CreateGiftCard icon={faPlus}>Create Gift Card</CreateGiftCard>
        </Link>
        <StyledTabBar items={['sent', 'received']} onSelected={setType} />
        {giftCards?.length > 0 && (
          <GiftCardsContainer>
            <Grid columns={1} gap={12}>
              {giftCards.map((giftCard: any) => (
                <Link to={`/giftCard/${giftCard._id}`} key={giftCard._id}>
                  <GiftCardRecord>
                    <Grid columns="80px 1fr" align="start" widthFull gap={4}>
                      <Cell span={2}>
                        <GiftCardTemplate template={giftCard.template} message={giftCard.message} />
                      </Cell>
                      <Cell>Name:</Cell>
                      <Cell justify="end">{giftCard.recipientName}</Cell>
                      <Cell>Email:</Cell>
                      <Cell justify="end">{giftCard.recipientEmail}</Cell>
                      <Cell>Time:</Cell>
                      <Cell justify="end">
                        {moment(giftCard.createdAt).format('MM-DD-YYYY hh:mm:ss')}
                      </Cell>
                      <Cell>Received:</Cell>
                      <Cell justify="end">{giftCard.received ? 'Yes' : 'No'}</Cell>
                    </Grid>
                  </GiftCardRecord>
                </Link>
              ))}
            </Grid>
          </GiftCardsContainer>
        )}
      </Container>
    </div>
  );
};

const StyledNavbar = styled(Navbar)`
  background-color: #fe4945;
  color: white;
  svg {
    color: white;
  }
`;

const StyledTabBar = styled(TabBar)`
  margin-top: 12px;
`;

const Container = styled.div`
  width: 100%;
  padding: 20px;
`;

const CreateGiftCard = styled(Button)`
  width: 100%;
  height: 80px;
  font-size: 16px;
`;

const GiftCardsContainer = styled.div`
  img {
    max-width: 100%;
  }
`;

const GiftCardRecord = styled.div`
  font-size: 13px;
  box-shadow: 0 4px 32px 0 rgb(28 29 33 / 12%);
  padding: 12px 24px;
  background-color: #ffffff;
  border-radius: 5px;
`;
