import { HTMLProps } from 'react';
import { faChevronLeft, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Flex } from '../Layout';

type NavbarProps = {
  title: string;
  noBack?: boolean;
  noShadow?: boolean;
  showClose?: boolean;
} & HTMLProps<HTMLDivElement>;

export const Navbar = (props: NavbarProps) => {
  const { title, noBack, noShadow, showClose, ...rest } = props;
  const history = useHistory();

  return (
    <Container $noShadow={noShadow} {...(rest as any)}>
      {!noBack && (
        <LeftButton onClick={history.goBack}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </LeftButton>
      )}
      <Flex justify="center" align="center" heightFull>
        {title}
      </Flex>
      {showClose && (
        <RightButton onClick={history.goBack}>
          <FontAwesomeIcon icon={faTimes} />
        </RightButton>
      )}
    </Container>
  );
};

const Container = styled.div<{ $noShadow?: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: var(--nav-height);
  background-color: white;
  box-sizing: border-box;
  font-weight: 500;
  z-index: 100;
  font-family: 'Theano Didot', serif;
  font-weight: 400;
  font-size: 22px;
  letter-spacing: 0.6px;
  text-transform: capitalize;

  ${({ $noShadow }) => ($noShadow ? '' : 'box-shadow: 0 4px 32px 0 rgb(28 29 33 / 12%);')}
`;

export const LeftButton = styled.button`
  width: var(--nav-height);
  height: var(--nav-height);
  position: absolute;
  padding: 0;
  left: 0;
  top: 0;
  z-index: 1;

  svg {
    font-size: 18px;
  }
`;

export const RightButton = styled.button`
  width: var(--nav-height);
  height: var(--nav-height);
  position: absolute;
  padding: 0;
  right: 0;
  top: 0;
  z-index: 1;

  svg {
    font-size: 18px;
  }
`;
