import { ReactElement } from 'react';
import { STANDARD_CONTENT_WIDTH } from 'src/constants/ui';
import styled from 'styled-components';
import { SimpleNavBar } from './tasting_menu/components/SimpleNavBar';
import { TermsOfService } from './tasting_menu/components/TermsOfService';

type Props = {};

const ContentContainer = styled.div`
  max-width: ${STANDARD_CONTENT_WIDTH}px;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

export const PageTermsOfService = (props: Props): ReactElement => {
  return (
    <div className="page">
      <SimpleNavBar title="Terms of Service" />
      <ContentContainer>
        <TermsOfService />
      </ContentContainer>
    </div>
  );
};
