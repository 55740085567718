import React, { ReactElement, useCallback, useState } from "react";
import { faHeart as faCheck } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faEmpty } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch, useStore } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "src/store";
import styled, { keyframes, css } from "styled-components";
import { toggleFavorite } from "../../../../store/actions";
import {
  card,
  Centered,
  textOverflow,
  NavHeight,
  text12300,
  centered,
  TextGrey,
} from "../Shared";
// Define a keyframe animation for entering from the top
const slideInFromTop = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 0.8;
  }
`;

// Define a keyframe animation for exiting to the top
const slideOutToTop = keyframes`
  0% {
    transform: translateY(0);
    opacity: 0.8;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

interface BannerContainerProps {
  visible: boolean;
}
const BannerContainer = styled(Centered)<BannerContainerProps>`
  ${card}
  /* display: ${(props) => (props.visible ? "flex" : "none")}; */
  background-color: rgba(5,5,5,0.8);
  color: white;
  box-shadow: none;
  margin: 0px;
  padding: 0px 20px;
  z-index: 200;
  position: fixed;
  top: 5px;
  left: 5px;
  width: calc(100vw - 10px);
  height: ${NavHeight - 10}px;
  justify-content: space-between;
  border-radius: 5px;
  ${text12300}
  span {
    /* background-color: navy; */
    ${centered}
    justify-content: flex-start;
    max-width: calc(100vw - 100px);
    position: relative;
    b {
      display: inline-block;
      /* font-weight: 500; */
      ${text12300}
      text-transform: capitalize;
      max-width: calc(100vw - 225px);
      ${textOverflow}
      letter-spacing: 1px;
      margin-right: 5px;
      font-weight: 400;
    }
  }
  a {
    color: white;
    border-bottom: 1px solid white;
    text-transform: capitalize;
    padding: 0px;
    line-height: 1.5;
    text-align: center;
  }

  transform: translateY(${(props) => (props.visible ? "0%" : "-100%")});
  opacity: ${(props) => (props.visible ? "1" : "0")};
  transition: all 400ms ease-in-out;
  @media only screen and (min-width: 500px) {
    max-width: 400px;
    left: calc(50vw - 200px);
  }
  @media only screen and (min-width: 1200px) {
    /* display: none; */
    /* background-color: rgba(255, 255, 255, 0.8);
    width: 35px;
    height: 35px;
    right: 7px;
    top: 7px;
    border-radius: 50%;
    z-index: 100;
    svg {
      font-size: 17px;
    } */
  }
`;

export interface FavoriteConfirmationProps {
  restaurantName: String;
  visible: boolean;
  status: boolean;
}
export const FavoriteConfirmation: React.FC<FavoriteConfirmationProps> = ({
  restaurantName,
  visible,
  status,
}: FavoriteConfirmationProps): ReactElement => {
  // const currentUser = useSelector((state: RootState) => state.currentUser);
  // const dispatch = useDispatch();
  // const store = useStore();
  // const [lockButton, setLockButton] = useState(false);
  // const isLoggedIn = currentUser?.isAuthenticated;
  // const [isFavorite, setIsFavorite] = useState(
  //   currentUser?.user?.favorites?.includes(restaurantId)
  // );

  // const toggle = useCallback(async () => {
  //   setIsFavorite(!isFavorite);
  //   setLockButton(true);
  //   await toggleFavorite(restaurantId)(dispatch, store.getState);
  //   setLockButton(false);
  // }, [currentUser]);
  return (
    <BannerContainer visible={visible}>
      <span>
        <b>{`${restaurantName}`}</b> is {`${status ? "added" : "removed"}`}
      </span>
      <Link to="/restaurants">view favorites</Link>
    </BannerContainer>
  );
};
