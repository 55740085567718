import { LOAD_TRACKING, RESET_TRACKING } from "../actionTypes";

const tracking = (state = [], action) => {
  switch (action.type) {
    case LOAD_TRACKING:
      return [...action.tracking];
    case RESET_TRACKING:
      return [];
    default:
      return state;
  }
};

export default tracking;
