import React, { ReactElement, useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useParams, useHistory, useLocation } from "react-router-dom";
import { ReadGiftById, ClaimGift } from "src/api/gifts";
import { RootState } from "src/store";
import { setCurrentUser } from "src/store/actions";
import { LOGGING } from "../shared";
import { Loading } from "./components";
interface PageGiftClaimProps {}
interface RouteParams {
  giftId: string;
}

export const PageGiftClaim: React.FC<PageGiftClaimProps> = (): ReactElement => {
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const { giftId } = useParams<RouteParams>();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [claimedGift, setClaimedGift] = useState(null);
  LOGGING && console.log("PageGiftClaim rendering with:", giftId, currentUser);
  const validateEmail = (gift) => {
    return gift?.recipientEmail === currentUser?.user?.email;
  };
  const fetchGift = useCallback(async () => {
    try {
      const result = await ReadGiftById(giftId);
      LOGGING && console.log("fetchGift got result:", result);
      await claimGift(result);
    } catch (err) {
      LOGGING && console.log("fetchGift got err:", err);
      toast.error(err.message);
      history.push("/");
    }
  }, [giftId]);

  useEffect(() => {
    if (!claimedGift) {
      fetchGift();
    }
  }, [fetchGift]);

  const claimGift = async (data) => {
    // Check if the user is authenticated
    LOGGING && console.log("claimGift called with:", { data, currentUser });
    if (currentUser.isAuthenticated) {
      // Check if the user's email matches the gift recipient email
      if (validateEmail(data)) {
        LOGGING && console.log("validateEmail called with:", data);
        //@ts-ignore
        if (data.claimTime > 0) {
          toast.error("The gift is claimed!");
          history.push("/");
          // setError({ message: "The gift is claimed!" });
        } else {
          // Mark the gift as claimed on the server side
          const result = await ClaimGift(giftId, currentUser?.user, data.sum);
          dispatch(setCurrentUser({ ...currentUser?.user, credit: result }));
          // Redirect to a success page or perform other actions if needed
          // For now, redirecting to the home page
          setClaimedGift({
            amount: data.sum,
          });
        }
      } else {
        // Show an error message if the user's email doesn't match the gift recipient email
        toast.error("Your email doesn't match the gift receipt email");
        history.push("/");

        // You might want to set an error state or show a notification to the user
      }
    } else {
      LOGGING && console.log("claimGift is not authorized location:", location);
      // Redirect to the authentication page and pass the gift object
      history.push({
        pathname: "/auth",
        state: {
          title: "💝To Claim Gift",
          next: location?.pathname,
        },
      });
    }
  };

  useEffect(() => {
    LOGGING && console.log("checking claimedGift", claimedGift);
    if (claimedGift?.amount) {
      LOGGING && console.log("calling toast.success");
      toast.success(`You have received $${claimedGift?.amount} gift!`);
    }
  }, [claimedGift]);
  LOGGING && console.log("rendering got:", { claimedGift });

  if (!claimedGift) {
    return <Loading />;
  } else {
    // return <div className="page">{mealLink}</div>;
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }
};
