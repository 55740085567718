import { LOGGING } from "src/constants";
import {
  SET_GIFT_CARD,
  SET_GIFT_CARDS,
  SET_GIFT_CARD_TEMPLATES,
} from "src/store/actionTypes";
import { apiCall } from "src/store/api";

export const setGiftCard = (giftCardRecord) => ({
  type: SET_GIFT_CARD,
  payload: giftCardRecord,
});

export const setGiftCards = (giftCardRecords) => ({
  type: SET_GIFT_CARDS,
  payload: giftCardRecords,
});

export const setGiftCardTemplates = (giftCardTemplates) => ({
  type: SET_GIFT_CARD_TEMPLATES,
  giftCardTemplates,
});

export const createGiftCard = (giftCard, options) => {
  return async (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING && console.log("createGiftCard called:", { currentUser, giftCard });
    try {
      const gift = await apiCall(
        "post",
        `/giftCards/${currentUser?.user?._id}`,
        giftCard
      );
      options?.success?.();
      return gift;
    } catch (err) {
      LOGGING && console.log("createGiftCard got err", err);
      options?.error?.(err);
    }
  };
};

export const receiveGiftCard = (giftCardId, options) => {
  return async (dispatch, getState) => {
    LOGGING && console.log("receiveGiftCard called:", { giftCardId });
    try {
      await apiCall("post", `/giftCard/receive`, {
        giftCardId,
      });
      options?.success?.();
    } catch (err) {
      LOGGING && console.log("getGiftCardRecord got err", err);
      options?.error?.(err);
    }
  };
};

export const getGiftCard = (giftCardId) => {
  return async (dispatch) => {
    LOGGING && console.log("getGiftCard called:", { giftCardId });
    try {
      const giftCardRecord = await apiCall("get", `/giftCard/${giftCardId}`);
      dispatch(setGiftCard(giftCardRecord));
    } catch (err) {
      LOGGING && console.log("getGiftCardRecord got err", err);
    }
  };
};

export const getGiftCards = (type) => {
  return async (dispatch, getState) => {
    try {
      const giftCards = await apiCall("get", `/my/giftCards/${type}`);
      dispatch(setGiftCards(giftCards));
    } catch (err) {
      LOGGING && console.log("getGiftCardRecords got err", err);
    }
  };
};
export const getGiftCardTemplates = () => {
  return async (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING &&
      console.log("getGiftCardTemplates called with currentUser:", currentUser);
    try {
      const result = await apiCall("get", `/giftCards/template`);
      LOGGING && console.log("getGiftCardTemplates got result:", result);
      dispatch(setGiftCardTemplates(result));
      return "0k";
    } catch (err) {
      LOGGING && console.log("getGiftCardTemplates got err", err);
    }
  };
};

export const getGiftCardTemplate = async (templateId) => {
  try {
    const result = await apiCall("put", "/giftCards/template", { templateId });
    LOGGING && console.log("getGiftCardTemplate got result:", result);
    return result; // Return the result instead of dispatching
  } catch (err) {
    LOGGING && console.log("getGiftCardTemplates got err", err);
    throw err; // Propagate the error
  }
};
export const createGift = (paypalCaptureId, newCard, saveCard, gift) => {
  //userId, paypalCaptureId, newCard, saveCard, amount, recipientName, recipientEmail, giftCardTemplateId
  const { amount, name, email, content, giftTemplateId } = gift;
  LOGGING &&
    console.log("createGift called:", {
      paypalCaptureId,
      newCard,
      saveCard,
      gift,
      content,
      amount,
      giftTemplateId,
    });

  return (dispatch, getState) => {
    const { currentUser } = getState();
    const { user } = currentUser;
    return apiCall(
      "put",
      `/giftCards/`,
      {
        paypalCaptureId,
        newCard,
        saveCard,
        recipientName: name,
        recipientEmail: email,
        message: content,
        giftCardTemplateId: giftTemplateId,
        amount,
        userId: user?._id,
      },
      getState().currentUser
    )
      .then((createdGift) => {
        LOGGING && console.log("createGift got", createdGift);
        // dispatch(setCurrentUser({ ...user, stripeInfo }));
        return createdGift;
      })
      .catch((err) => {
        LOGGING && console.log("createGift got err", err);
        throw err;
      });
  };
};
