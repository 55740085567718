import { LOGGING } from '.';
import { DriverStatus } from './DriverStatus';

export const DriverSection = ({ drivers, driverIds, status, onInvite, inviteRestaurantName }) => {
  const availableDriverIds = driverIds.filter((driverId) => Boolean(drivers[driverId]));
  LOGGING && console.log('DriverSection rendering with:', { drivers, driverIds, status });
  return (
    availableDriverIds.length > 0 && (
      <div className="driver-section">
        <div className="section-title">{status}</div>
        <div className="driver-list">
          {availableDriverIds.map((driverId) => (
            <DriverStatus
              driver={drivers[driverId]}
              driverId={driverId}
              status={status}
              key={driverId}
              onInvite={onInvite}
              inviteRestaurantName={inviteRestaurantName}
            />
          ))}
        </div>
      </div>
    )
  );
};
