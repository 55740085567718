import { LOGGING } from 'src/constants';
import { SET_CREDIT } from 'src/store/actionTypes';
import { apiCall } from 'src/store/api';

export const setCredit = (credit) => ({
  type: SET_CREDIT,
  payload: credit,
});

export const getCredit = (options) => {
  return async (dispatch) => {
    LOGGING && console.log('getGiftCards called');
    try {
      const credit = await apiCall('get', '/my/credit');
      dispatch(setCredit(credit));
      options?.success?.();
    } catch (err) {
      LOGGING && console.log('getGiftCards got err', err);
      options?.error?.(err);
    }
  };
};
