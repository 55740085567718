import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LOGGING, TeamMealList } from '.';
import { readTeamMeals } from '../../store/actions';
class PageTeamMealList extends Component {
  constructor() {
    super();
    LOGGING && console.log('PageTeamMealList constructor');
    this.state = {
      loading: true,
      view: 'upcoming',
      showOrderDetails: null,
      isEdittingDetails: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShowOrderDetailsFor = this.handleShowOrderDetailsFor.bind(this);
    this.handleHideOrderDetailsFor = this.handleHideOrderDetailsFor.bind(this);
    this.handleSetView = this.handleSetView.bind(this);
  }

  handleSetView(view, e) {
    LOGGING && console.log('handleSetView called with:', view);
    e.preventDefault();
    LOGGING && console.log('handleSetView called with:', view);
    this.setState({ view });
  }

  handleShowOrderDetailsFor(orderId, e) {
    e.preventDefault();
    const { orders } = this.props;
    const showOrderDetails = orders.find((o) => o._id === orderId);
    this.setState({ showOrderDetails, isEdittingDetails: false });
  }

  handleHideOrderDetailsFor(e) {
    e.preventDefault();
    this.setState({ showOrderDetails: null, isEdittingDetails: false });
  }

  handleClose() {
    LOGGING && console.log('PageTeamMealList handleClose');
    this.props.history.push('/');
  }

  componentDidMount() {
    LOGGING && console.log('PageTeamMealList componentdidmount');
    this.setState({ loading: true }, () => {
      this.props.readTeamMeals().then((meals) => {
        this.setState({ loading: false, meals }, () => {
          window.scrollTo(0, 0);
        });
      });
    });
  }

  render() {
    const { currentUser } = this.props;
    const { meals, view } = this.state;
    LOGGING &&
      console.log('PageTeamMealList rendering with', {
        props: this.props,
        state: this.state,
      });

    if (!currentUser.isAuthenticated) {
      return <Redirect to="/" />;
    }

    return (
      <div className="page">
        <TeamMealList
          meals={meals}
          view={view}
          onSetView={this.handleSetView}
          onBack={this.handleClose}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log('PageTeamMealList got redux state:', state);
  return {
    orders: state.orders,
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  readTeamMeals,
})(PageTeamMealList);
