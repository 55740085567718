import React, {
  ReactElement,
  useCallback,
  useState,
  useEffect,
  useRef,
} from "react";
import Switch from "react-switch";

import "react-toggle/style.css"; // for ES6 modules
import {
  faCheck,
  faAlarmClock as faReminder,
  faPencil as faEdit,
} from "@fortawesome/pro-light-svg-icons";
import {
  faChevronRight,
  faChevronDown,
  faChevronLeft,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "src/store";
import styled from "styled-components";
import {
  LOGGING,
  PopUp,
  LunchReminders,
  EarlyDinnerReminders,
  DinnerReminders,
  DayOfWeek,
} from "../..";
import { saveReminder } from "../../../../../store/actions";
import {
  ActionUnderline,
  ActionTransparent,
  Beige,
  Purple,
  card,
  Centered,
  centered,
  CenteredByColumn,
  CenteredByColumnLeft,
  Grey,
  MealHeight,
  text12300,
  Text12300,
  NavHeight,
  Pink,
  textTitle,
  TextTitle,
  centeredByColumn,
  TextGrey,
  BorderGrey,
} from "../../Shared";
import { text } from "@fortawesome/fontawesome-svg-core";
const margin = 30;
type ContentContainerProps = {
  showForm: boolean;
};
const ContentContainer = styled(CenteredByColumn)<ContentContainerProps>`
  position: ${(props) => (props.showForm ? "fixed" : "relative")};
  top: ${(props) => (props.showForm ? `${NavHeight}px` : "auto")};
  z-index: ${(props) => (props.showForm ? 101 : 1)};
  background-color: black;
  @media only screen and (min-width: 500px) {
  }
`;

const HeadLine = styled(Text12300)`
  font-weight: 400;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 1.1;
  margin-bottom: 5px;
  svg {
    margin-right: 10px;
    display: none;
  }
`;
const TagLine = styled.span`
  ${textTitle}
  font-size: 12px;
  text-transform: none;
`;
const Body = styled(CenteredByColumn)`
  position: relative;
`;
const Confirmation = styled(Text12300)`
  position: absolute;
  height: 40px;
  bottom: -60px;
`;
const ReminderContainer = styled(CenteredByColumn)`
  position: fixed;
  z-index: 103;
  background-color: black;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  /* justify-content: space-around; */
  /* height: ${NavHeight * 2}px; */
  /* box-shadow: 0px 4px 4px 0px rgba(28, 29, 33, 20%); */
`;

const Reminder = styled(CenteredByColumnLeft)`
  margin-bottom: 30px;
  position: relative;
  width: 80%;
  max-width: 300px;
  padding: 20px;
  background-color: #333;
`;

const ButtonClose = styled.button`
  color: white;
  position: fixed;
  left: 0px;
  top: 0px;
  font-size: 20px;
  width: ${NavHeight}px;
  height: ${NavHeight}px;
`;

const ReminderHeader = styled(Centered)`
  width: 100%;
  justify-content: space-between;
  /* padding: 0px 28px; */
`;
type ReminderTitleProps = { isOn: boolean };
const ReminderTitle = styled.span<ReminderTitleProps>`
  ${text12300}
  padding: 0px;
  font-size: 24px;
  height: 24px;
  line-height: 24px;
  text-transform: uppercase;
  color: ${(props) => (props.isOn ? "white" : `${TextGrey}`)};
`;
type ReminderOptionsProps = {};
const ReminderOptions = styled(CenteredByColumn)<ReminderOptionsProps>`
  width: calc(100vw - 2 * ${margin}px);
  max-width: 300px;
  height: 100px;
  overflow-y: scroll;
  position: relative;
  /* background-color: ${Beige}; */
  background-color: black;
  scroll-snap-type: y mandatory;
  padding: 40px 0px 20px 0px;
  justify-content: flex-start;
  /* overflow-x: scroll; */
  /* flex-wrap: wrap; */
  button:first-child {
    margin-top: 30px;
  }
`;
const Overlay = styled.div`
  width: 100%;
  height: 20px;
  z-index: 1;
  position: absolute;
`;
const TopOverlay = styled(Overlay)`
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  top: 30px;
`;
const BottomOverlay = styled(Overlay)`
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  bottom: 0px;
`;
const SelectDay = styled(Centered)`
  width: calc(100vw - 2 * ${margin}px - 20px);
  margin-bottom: 40px;
  position: relative;
  padding-top: 40px;
  max-width: 300px;
`;
const SelectHour = styled(SelectDay)``;
const SelectDayTitle = styled(Centered)`
  ${text12300}
  justify-content: flex-start;
  position: absolute;
  top: 10px;
  color: white;
  text-align: left;
  width: 100%;
  padding: 0 20px;
  /* border: 1px solid red; */
  box-sizing: border-box;
  line-height: 1.5;
  text-transform: capitalize;
  label {
    margin-left: 5px;
    color: grey;
  }
`;
type DayProps = { selected: boolean };
const Day = styled(Text12300)<DayProps>`
  background-color: ${(props) =>
    props.selected ? `${Purple}` : "transparent"};
  font-weight: 500;
  color: ${(props) => (props.selected ? "white" : `${TextGrey}`)};
  border: 1px solid ${(props) => (props.selected ? `${Purple}` : `${TextGrey}`)};
  text-transform: capitalize;
  width: 25px;
  line-height: 25px;
  text-align: center;
  margin: 0px 6px;
  border-radius: 50%;
`;
type ReminderOptionProps = {
  isSelected: boolean;
};
const ReminderOption = styled.button<ReminderOptionProps>`
  ${text12300}
  ${centered}  
  /* border: 1px solid purple; */
  width: calc(100vw - 2 * ${margin}px - 20px);
  max-width: 300px;
  /* border: 1px solid red;   */
  line-height: 32px;
  font-size: 32px;
  height: 32px;
  box-sizing: border-box;
  justify-content: flex-start;
  margin: 2px 0px;
  padding: 0px 20px;
  border-radius: 5px;
  position: relative;
  scroll-snap-align: center;
  color: ${(props) => (props.isSelected ? "white" : `#666`)};
  span {
    font-weight: ${(props) => (props.isSelected ? 500 : 200)};
    font-size: ${(props) => (props.isSelected ? "30px" : "26px")};
  }

  svg {
    position: absolute;
    right: 10px;
  }
  label {
    /* background-color: pink; */
    ${text12300}
    margin-left: 10px;
    text-transform: none;
  }
`;
const ButtonContainer = styled(Centered)`
  position: fixed;
  bottom: 40px;
`;
const Button = styled.button`
  width: 120px;
  height: 40px;
  border-color: orange;
  ${text12300}
  font-weight: 500;
  text-transform: uppercase;
`;
const CancelButton = styled(Button)`
  color: orange;
  border: 1px solid orange;
  margin-right: 20px;
`;
const SaveButton = styled(Button)`
  background-color: orange;
  color: black;
`;
const InitialReminder = {
  lunch: {
    isOn: false,
    hourOfDay: 9,
    daysOfWeek: [true, true, true, true, true, true, true],
  },
  earlyDinner: {
    isOn: false,
    hourOfDay: 14,
    daysOfWeek: [false, true, true, true, true, true, false],
  },
  dinner: {
    isOn: false,
    hourOfDay: 15,
    daysOfWeek: [true, true, true, true, true, true, true],
  },
};
const Entry = styled.button`
  ${centeredByColumn}
  height: ${NavHeight}px;
  width: 100vw;
  max-width: 500px;
  background-color: orange;
  > svg {
    position: absolute;
    right: 30px;
  }
`;
const MealReminderToggle = ({ isChecked, onToggle }) => (
  <Switch
    onChange={onToggle}
    checked={isChecked}
    uncheckedIcon={false}
    checkedIcon={false}
    onColor={"#5BC236"}
    offColor={"#888"}
  />
);

const DetailsContainer = styled(CenteredByColumn)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: black;
  z-index: 1;
`;
const MealType = styled(TextTitle)`
  color: white;
  width: 100%;
  text-align: center;
  top: 40px;
  position: fixed;
`;

const getDescription = (reminder, mealType) => {
  const meal = reminder[mealType];
  if (meal) {
    const { daysOfWeek, hourOfDay } = meal;
    const hourString = `${hourOfDay > 12 ? hourOfDay - 12 : hourOfDay}:00${
      hourOfDay < 12 ? "AM" : "PM"
    } ${hourOfDay > 16 ? " the day before" : ""}`;
    let dayString;
    const onDays = daysOfWeek.filter((d) => d).length;
    if (onDays === 7) {
      dayString = "day";
    } else if (
      daysOfWeek[1] &&
      daysOfWeek[2] &&
      daysOfWeek[3] &&
      daysOfWeek[4] &&
      daysOfWeek[5]
    ) {
      dayString = "weekday";
    }
    // else if (onDays > 4) {
    //   dayString =
    //     "day except " +
    //     daysOfWeek
    //       .map((d, i) => (!d ? DayOfWeek[i] : ""))
    //       .filter((s) => s !== "")
    //       .join(", ");
    // }
    else {
      dayString = daysOfWeek
        .map((d, i) => (d ? DayOfWeek[i].slice(0, 3) : ""))
        .filter((s) => s !== "")
        .join(", ");
    }
    return `every ${dayString} \n at ${hourString}.`;
  } else {
    return "Set Up";
  }
};
type ReminderDescriptionProps = { isOn: boolean };
const ReminderDescription = styled.button<ReminderDescriptionProps>`
  ${centeredByColumn}
  align-items: flex-start;
  color: ${(props) => (props.isOn ? "white" : `${TextGrey}`)};
  position: relative;
  padding: 0px;
  margin: 15px 0 0 0;
  width: 100%;

  span {
    width: 100%;
    ${text12300}
    text-align: left;
    line-height: 1.7;
    text-transform: none;
  }
  svg {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  label {
    margin-top: 30px;
    ${text12300}
    height: 30px;
    line-height: 30px;
    width: 80px;
    text-align: center;
    /* border: 1px solid white; */
    /* background-color: #888; */
    color: ${(props) => (props.isOn ? "white" : `${TextGrey}`)};
    border: 1px solid ${(props) => (props.isOn ? "white" : `${TextGrey}`)};
  }
`;
interface MealReminderDetailsProps {
  mealType: string;
  mealDays: any;
  mealReminders: any;
  selectedHour: any;
  // @ts-ignore
  setSelectedHour: (mealType: string, hourOfDay: number) => void;
  onCancel: (e: any) => void;
  onSave: (e: any) => void;
  onToggleDayOfWeek: (mealType: string, dayOfWeek: number) => void;
}
export const MealReminderDetails: React.FC<MealReminderDetailsProps> = ({
  mealType,
  mealDays,
  mealReminders,
  selectedHour,
  onToggleDayOfWeek,
  setSelectedHour,
  onCancel,
  onSave,
}: MealReminderDetailsProps): ReactElement => {
  // const reminderSelections = new Array(24).fill(0).map((_, i) => i);
  const reminderSelections =
    mealType === "lunch"
      ? [
          11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0, 1, 2, 3, 4, 5,
          6, 7, 8, 9, 10,
        ]
      : mealType === "earlyDinner"
      ? [
          5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
          0, 1, 2, 3, 4,
        ]
      : [
          5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
          0, 1, 2, 3, 4,
        ];
  const scrollRef = useRef(null);
  const selectedRef = useRef(null);
  const [centeredIndex, setCenteredIndex] = useState(null);

  const handleScroll = () => {
    const parent = scrollRef.current;
    const parentRect = parent.getBoundingClientRect();
    const children = Array.from(parent.children);

    // @ts-ignore
    children.forEach((child, i) => {
      // @ts-ignore
      const childRect = child.getBoundingClientRect();
      if (
        childRect.top >= parentRect.top &&
        childRect.bottom <= parentRect.bottom
      ) {
        setCenteredIndex(i);
        setSelectedHour(mealType, reminderSelections[i]);
        return;
      }
    });
  };
  useEffect(() => {
    if (selectedRef && selectedRef.current) {
      selectedRef.current.scrollIntoView({ block: "center" });
    }
  }, []);
  return (
    <DetailsContainer>
      <MealType>{`Edit ${mealType} Reminder`}</MealType>

      <SelectDay>
        <SelectDayTitle>days of week</SelectDayTitle>
        {DayOfWeek.map((d, dayIndex) => (
          <Day
            selected={mealDays[dayIndex]}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onToggleDayOfWeek(mealType, dayIndex);
            }}
            key={dayIndex}
          >
            {d[0]}
          </Day>
        ))}
      </SelectDay>
      <SelectHour>
        <SelectDayTitle>
          hour of day <label>(scroll to change)</label>{" "}
        </SelectDayTitle>
        <ReminderOptions ref={scrollRef} onScroll={handleScroll}>
          {reminderSelections.map((r, index) => (
            <ReminderOption
              ref={selectedHour === r ? selectedRef : null}
              isSelected={index === centeredIndex}
              key={index}
            >
              <span>
                {r > 12 ? r - 12 : r}:00
                {r >= 12 ? "PM" : "AM"}
              </span>
              {r >
              (mealType === "lunch"
                ? 10
                : mealType === "earlyDinner"
                ? 15
                : 16) ? (
                <label>for the next day</label>
              ) : null}
              {/* {selectedLunch === r.hour ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : null} */}
            </ReminderOption>
          ))}
        </ReminderOptions>
      </SelectHour>
      {/* <BottomOverlay></BottomOverlay> */}
      <ButtonContainer>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <SaveButton onClick={onSave}>Save</SaveButton>
      </ButtonContainer>
    </DetailsContainer>
  );
};
export interface SetUpReminderProps {
  onFreeze: () => void;
}
export const SetUpReminder: React.FC<SetUpReminderProps> = ({
  onFreeze,
}: SetUpReminderProps): ReactElement => {
  const currentUser = useSelector((state: RootState) => state.currentUser);
  LOGGING && console.log("SetUpReminder rendeirng currentUser:", currentUser);
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const [reminder, setReminder] = useState(
    currentUser?.user?.reminder?.earlyDinner?.daysOfWeek?.length > 0
      ? currentUser?.user?.reminder
      : InitialReminder
  );
  const [showForm, setShowForm] = useState(true);
  const [editLunch, setEditLunch] = useState(false);
  const [editEarlyDinner, setEditEarlyDinner] = useState(false);
  const [editDinner, setEditDinner] = useState(false);

  LOGGING && console.log("SetUpReminder rendeirng with:", reminder);

  const save = useCallback(async (mealType, updatedReminder) => {
    LOGGING &&
      console.log("SetUpReminder save called:", {
        reminder,
        updatedReminder,
        mealType,
      });
    // @ts-ignore
    await saveReminder(updatedReminder)(dispatch, store.getState);
    if (mealType === "lunch") {
      setEditLunch(false);
    } else if (mealType === "dinner") {
      setEditDinner(false);
    } else if (mealType === "earlyDinner") {
      setEditEarlyDinner(false);
    }
  }, []);

  // const onToggleForm = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   if (!showForm) {
  //     onFreeze();
  //   }
  //   setShowForm(!showForm);
  // };

  const onToggleReminder = useCallback(async (reminder, mealType) => {
    const meal = reminder[mealType];
    const { isOn } = meal;
    const updatedMeal = { ...meal, isOn: !isOn };
    const updatedReminder = { ...reminder, [mealType]: { ...updatedMeal } };
    await saveReminder(updatedReminder)(dispatch, store.getState);
    setReminder(updatedReminder);
  }, []);

  const onSelectHourOfDay = (mealType, hourOfDay) => {
    LOGGING &&
      console.log("onSelectHourOfDay called with:", { mealType, hourOfDay });
    const meal = reminder[mealType];
    const updatedMeal = { ...meal, hourOfDay };
    setReminder({ ...reminder, [mealType]: { ...updatedMeal } });
  };

  const onToggleDayOfWeek = (mealType, dayOfWeekIndex) => {
    const meal = reminder[mealType];
    const { daysOfWeek } = meal;
    const updatedDaysOfWeek = daysOfWeek.map((d, i) =>
      i === dayOfWeekIndex ? !d : d
    );
    const updatedMeal = { ...meal, daysOfWeek: [...updatedDaysOfWeek] };
    setReminder({ ...reminder, [mealType]: { ...updatedMeal } });
  };

  return (
    <ContentContainer showForm={showForm}>
      {/* <Entry onClick={onToggleForm}>
        <HeadLine>
          <FontAwesomeIcon icon={faReminder} />
          set up reminder
        </HeadLine>
        <TagLine>and never miss the train again</TagLine>
        <FontAwesomeIcon icon={showForm ? faChevronDown : faChevronRight} />
      </Entry> */}
      {showForm ? (
        <ReminderContainer>
          <ButtonClose
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              history.goBack();
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </ButtonClose>
          <Reminder>
            <ReminderHeader>
              <ReminderTitle isOn={reminder?.lunch?.isOn}>lunch</ReminderTitle>
              {reminder.lunch ? (
                <MealReminderToggle
                  isChecked={reminder?.lunch?.isOn}
                  onToggle={onToggleReminder.bind(this, reminder, "lunch")}
                />
              ) : null}
            </ReminderHeader>
            <ReminderDescription
              isOn={reminder?.lunch?.isOn}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setEditLunch(true);
              }}
            >
              <span>{getDescription(reminder, "lunch")}</span>
              <label>change</label>
            </ReminderDescription>
            {editLunch ? (
              <MealReminderDetails
                mealType="lunch"
                mealDays={reminder?.lunch?.daysOfWeek}
                mealReminders={LunchReminders}
                onToggleDayOfWeek={onToggleDayOfWeek}
                selectedHour={reminder?.lunch?.hourOfDay}
                // @ts-ignore
                setSelectedHour={onSelectHourOfDay}
                onCancel={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setEditLunch(false);
                }}
                onSave={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  save("lunch", reminder);
                }}
              />
            ) : null}
          </Reminder>
          <Reminder>
            <ReminderHeader>
              <ReminderTitle isOn={reminder?.earlyDinner?.isOn}>
                early dinner
              </ReminderTitle>
              {reminder.earlyDinner ? (
                <MealReminderToggle
                  isChecked={reminder?.earlyDinner?.isOn}
                  onToggle={onToggleReminder.bind(
                    this,
                    reminder,
                    "earlyDinner"
                  )}
                />
              ) : null}
            </ReminderHeader>
            <ReminderDescription
              isOn={reminder?.earlyDinner?.isOn}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setEditEarlyDinner(true);
              }}
            >
              <span>{getDescription(reminder, "earlyDinner")}</span>
              <label>change</label>
            </ReminderDescription>
            {editEarlyDinner ? (
              <MealReminderDetails
                mealType="earlyDinner"
                mealDays={reminder?.earlyDinner?.daysOfWeek}
                onToggleDayOfWeek={onToggleDayOfWeek}
                mealReminders={EarlyDinnerReminders}
                selectedHour={reminder?.earlyDinner?.hourOfDay}
                // @ts-ignore
                setSelectedHour={onSelectHourOfDay}
                onCancel={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setEditEarlyDinner(false);
                }}
                onSave={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  save("earlyDinner", reminder);
                }}
              />
            ) : null}
          </Reminder>
          <Reminder>
            <ReminderHeader>
              <ReminderTitle isOn={reminder?.dinner?.isOn}>
                dinner
              </ReminderTitle>
              {reminder.dinner ? (
                <MealReminderToggle
                  isChecked={reminder?.dinner?.isOn}
                  onToggle={onToggleReminder.bind(this, reminder, "dinner")}
                />
              ) : null}
            </ReminderHeader>
            <ReminderDescription
              isOn={reminder?.dinner?.isOn}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setEditDinner(true);
              }}
            >
              <span>{getDescription(reminder, "dinner")}</span>
              <label>change</label>
            </ReminderDescription>
            {editDinner ? (
              <MealReminderDetails
                mealType="dinner"
                mealDays={reminder?.dinner?.daysOfWeek}
                onToggleDayOfWeek={onToggleDayOfWeek}
                mealReminders={DinnerReminders}
                selectedHour={reminder?.dinner?.hourOfDay}
                // @ts-ignore
                setSelectedHour={onSelectHourOfDay}
                onCancel={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setEditDinner(false);
                }}
                onSave={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  save("dinner", reminder);
                }}
              />
            ) : null}
          </Reminder>
        </ReminderContainer>
      ) : null}
    </ContentContainer>
  );
};
