import "./Loading.scss";
const Loading = ({ transparent = false, text = "loading" }) => (
  <div className={`loading-top ${transparent ? "transparent" : ""}`}>
    <span>{text}</span>
  </div>
);
export const LoadingTop = ({ text = "loading" }) => (
  <div className="loading-top">
    <span>{text}</span>
  </div>
);
export const LoadingFull = ({ text = "loading" }) => (
  <div className="loading-full">
    <span>{text}</span>
  </div>
);
export const LoadingAuth = ({ text = "loading" }) => (
  <div className="loading-auth">
    <span>{text}</span>
  </div>
);
export default Loading;
