import React, { useMemo } from "react";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import "./WeeklyMenu.scss";
import { Link } from "react-router-dom";
import { Flex, Grid } from "src/components/Layout";
import { Shown } from "src/components/Shown";
// import { kCritialHourMinute } from 'src/util/time';
import {
  // LOGGING,
  // Announcement,
  // MealDate,
  CreateReferralCode,
  OrderingWithCode,
  MenuDateFormat,
  Loading,
  LOGGING,
} from "./";
import { CATimeZone } from "../../../constants";
import { isBeforeCutOffTimeForOrderTime } from "../../../util/time";

const MealTBD = ({ isLunch }) => (
  <div className={`meal-menu-container tbd ${isLunch ? "lunch" : "dinner"}`}>
    <span>{`${isLunch ? "Lunch" : "Dinner"} TBD`}</span>
  </div>
);
const MealMenu = ({ meal, orders, isLunch, onSelect, isClosed }) => {
  LOGGING && console.log("MealMenu rendering with:", { meal, orders, isLunch });
  const isPlanned = meal && meal.restaurant && meal.restaurant.name;
  if (!isPlanned) return null;
  //<MealTBD isLunch={isLunch} />;
  return (
    <button
      className={`meal-menu-container ${isLunch ? "lunch" : "dinner"} ${
        isClosed ? "closed-container" : ""
      }`}
      onClick={onSelect}
      disabled={isClosed || !isPlanned}
    >
      <div className="meal-type">{isLunch ? "lunch" : "dinner"}</div>
      <div className="meal-menu">
        {isClosed ? (
          <div className={`view-meal ${isClosed ? "closed" : ""}`}>closed</div>
        ) : meal && meal.restaurant ? (
          <div className="view-meal">
            {`last call ${isLunch ? "10:30A" : "4:30P"}`}{" "}
          </div>
        ) : null}
        {meal && meal.heroDish.imageURL ? (
          <img
            className="hero-dish-image"
            src={meal.restaurant.heroImage || meal.heroDish.imageURL}
            alt="dish"
          />
        ) : (
          <div className="hero-dish-image"></div>
        )}
        <div className="meal-text">
          <span className="restaurant-name">
            {isPlanned && meal.restaurant?.name
              ? meal.restaurant?.name?.split(",")[0]
              : "TBD"}
          </span>
        </div>
        {orders?.length > 0 && (
          <Link
            className="meal-menu-order"
            to={{
              pathname: `/order/${orders[0]._id}`,
              state: { from: "weekly" },
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Flex direction="column" align="center" justify="center" heightFull>
              <FontAwesomeIcon icon={faCheck} />
              <div>ordered</div>
            </Flex>
          </Link>
        )}
      </div>
    </button>
  );
};
const DayMenu = ({
  menu,
  dayIndex,
  onSelectMeal,
  dateKey,
  dishesLoaded,
  hiddenForMobile,
  selectedDeliveryZone,
}) => {
  const {
    time,
    lunchTime,
    dinnerTime,
    earlyDinnerTime,
    earlyDinner,
    lunch,
    dinner,
  } = menu || {};
  LOGGING && console.log("DayMenu rendering with:", { dayIndex, menu });
  const date = moment(time || lunchTime);
  const lunchGraceMinutes = (lunch || []).some(
    (meal) => meal.restaurant?.allowLateCutoff
  )
    ? 15
    : 0;
  const earlyDinnerGraceMinutes = (earlyDinner || []).some(
    (meal) => meal.restaurant?.allowLateCutoff
  )
    ? 15
    : 0;
  const dinnerGraceMinutes = (dinner || []).some(
    (meal) => meal.restaurant?.allowLateCutoff
  )
    ? 15
    : 0;

  const lunchIsClosed =
    lunchTime && !isBeforeCutOffTimeForOrderTime(lunchTime, lunchGraceMinutes);
  const earlyDinnerIsClosed =
    dinnerTime &&
    !isBeforeCutOffTimeForOrderTime(earlyDinnerTime, earlyDinnerGraceMinutes);
  const dinnerIsClosed =
    dinnerTime &&
    !isBeforeCutOffTimeForOrderTime(dinnerTime, dinnerGraceMinutes);

  const lunchForDeliveryZone = lunch?.filter((entry) =>
    entry.supportedDeliveryZone.includes(selectedDeliveryZone)
  )[0];
  const earlyDinnerForDeliveryZone = earlyDinner?.filter((entry) =>
    entry.supportedDeliveryZone.includes(selectedDeliveryZone)
  )[0];
  const dinnerForDeliveryZone = dinner?.filter((entry) =>
    entry.supportedDeliveryZone.includes(selectedDeliveryZone)
  )[0];

  const lunchOrders = lunch
    ?.map((entry) => entry.orders)
    .reduce((x, y) => x.concat(y), []);
  const earlyDinnerOrders = earlyDinner
    ?.map((entry) => entry.orders)
    .reduce((x, y) => x.concat(y), []);
  const dinnerOrders = dinner
    ?.map((entry) => entry.orders)
    .reduce((x, y) => x.concat(y), []);
  return (
    <div
      className={`day-menu ${
        (lunchIsClosed && dinnerIsClosed && earlyDinnerIsClosed) ||
        hiddenForMobile
          ? "hidden-for-mobile"
          : ""
      }`}
    >
      <div
        className={`day-menu-date desktop ${
          lunchIsClosed && dinnerIsClosed && earlyDinnerIsClosed
            ? "closed-date"
            : ""
        }`}
      >
        {date.format("D")}
      </div>
      <div
        className={`day-menu-date mobile ${
          lunchIsClosed && earlyDinnerIsClosed && dinnerIsClosed
            ? "closed-date"
            : ""
        }`}
      >
        {date.format("dddd, MMM Do")}
      </div>
      <div className="menu-content">
        <Grid xl={{ columns: 1 }} columns={2} gap={10}>
          <MealMenu
            meal={lunchForDeliveryZone}
            orders={lunchOrders}
            isClosed={lunchIsClosed}
            isLunch={true}
            onSelect={
              onSelectMeal ? onSelectMeal.bind(this, dayIndex, true) : undefined
            }
            dishesLoaded={dishesLoaded}
          />
          <MealMenu
            meal={earlyDinnerForDeliveryZone}
            orders={earlyDinnerOrders}
            isClosed={earlyDinnerIsClosed}
            isLunch={true}
            onSelect={
              onSelectMeal ? onSelectMeal.bind(this, dayIndex, true) : undefined
            }
            dishesLoaded={dishesLoaded}
          />
          <MealMenu
            meal={dinnerForDeliveryZone}
            orders={dinnerOrders}
            isClosed={dinnerIsClosed}
            isLunch={false}
            onSelect={
              onSelectMeal
                ? onSelectMeal.bind(this, dayIndex, false)
                : undefined
            }
            dishesLoaded={dishesLoaded}
          />
        </Grid>
      </div>
    </div>
  );
};

const WeeklyMenu = ({
  menu,
  onSelectMeal,
  dishesLoaded,
  selectedDeliveryZone,
  referralCode,
  showReferralCodeAnnouncement,
}) => {
  // LOGGING && console.log('WeeklyMenu rendering with menu:', menu);
  const today = moment().tz(CATimeZone).format(MenuDateFormat);
  const tomorrow = moment().tz(CATimeZone).add(1, "d").format(MenuDateFormat);
  const currentMonth = moment().format("MMMM Y");
  const currentDay = moment().day();
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  return (
    <div
      className={`weekly-menu ${
        referralCode || showReferralCodeAnnouncement ? "has-gift-card" : ""
      }`}
    >
      {/* <Announcement /> */}
      {referralCode ? <OrderingWithCode code={referralCode} /> : null}
      {showReferralCodeAnnouncement ? <CreateReferralCode /> : null}
      <Shown from="xl">
        <div className="weekly-header">
          <div className="weekly-menu-month">{currentMonth}</div>
          <div className="weekly-menu-days">
            <Grid
              xl={{ columns: 7, gap: 0 }}
              columns={1}
              justify="center"
              align="center"
              widthFull
            >
              {days.map((day) => (
                <div key={day} className="weekly-menu-day">
                  {day}
                </div>
              ))}
            </Grid>
          </div>
        </div>
        {/* <div className="weekly-header-placeholder"></div> */}
      </Shown>

      <div className="weekly-menu-content">
        {menu ? (
          <Grid
            xl={{ columns: 7, gap: 15 }}
            columns={1}
            justify="center"
            align="center"
          >
            {menu.map((day, index) => {
              let dateKey = moment(day.lunchTime)
                .tz(CATimeZone)
                .format(MenuDateFormat);
              // if (dateKey === today) {
              //   dateKey = 'today, ' + dateKey;
              // } else if (dateKey === tomorrow) {
              //   dateKey = 'tomorrow, ' + dateKey;
              // }
              return (
                <DayMenu
                  menu={day}
                  key={index}
                  dayIndex={index}
                  dateKey={dateKey}
                  onSelectMeal={onSelectMeal.bind(this, dateKey)}
                  dishesLoaded={dishesLoaded}
                  selectedDeliveryZone={selectedDeliveryZone}
                />
              );
            })}
            {new Array(7 - currentDay).fill(true).map((_, index) => (
              <DayMenu
                key={`tbd-${index}`}
                hiddenForMobile
                menu={{
                  time: moment()
                    .add(7 + index, "days")
                    .valueOf(),
                }}
              />
            ))}
            {/* <Announcement doubleLine={true} /> */}
          </Grid>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default WeeklyMenu;
