import React, { ReactElement, useCallback, useState, useEffect } from "react";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
import { faPiggyBank } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import styled, { keyframes } from "styled-components";
import { AnonymousRestaurantSlides } from "../../home/CoverForPopUp";
import {
  Centered,
  TextTitle,
  text12300,
  CenteredByColumn,
  Gold,
  Pink,
  Brown,
  Yellow,
  Peach,
  Purple,
  Blue,
  BackgroundPink,
  Text12300,
  textTitle,
} from "../../Shared";
export interface Membership30DTrialProps {
  onClick: () => {};
}

const ContentContainer = styled(CenteredByColumn)<{ height: number }>`
  left: max(40px, 50vw - 250px);
  top: max(40px, 50vh - 400px);
  width: calc(100vw - 80px);
  max-width: 500px;
  height: ${(props) => props.height - 140}px;
  max-height: 800px;
  background-color: white;
  flex-direction: column;
  position: fixed;
  justify-content: flex-start;
  padding-bottom: 20px;
  @media only screen and (max-width: 400px) {
    width: calc(100vw - 40px);
    max-width: 400px;
    left: max(20px, 50vw - 200px);
  }

  @media only screen and (max-width: 350px) {
    width: calc(100vw - 20px);
    max-width: 330px;
    left: max(10px, 50vw - 165px);
    height: calc(100vh - 20px);
    top: 10px;
  }
  @media only screen and (min-width: 1200px) {
  }
`;

const TextContainer = styled(CenteredByColumn)`
  width: calc(100vw - 110px);
  height: calc(100vh - 110px);
  border: 2px solid orange;
  position: relative;
  justify-content: flex-start;
  @media only screen and (min-width: 500px) {
    align-items: flex-start;
    width: 450px;
  }
  /* margin-right: 30px; */
  /* margin-bottom: 20px; */
`;
export const SeeDetails = styled.button`
  ${text12300}
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 24px;
  /* border: 1px solid black; */
  /* padding: 20px 30px; */
  margin: 20px 20px 10px 20px;
  text-transform: uppercase;
  background-color: black;
  color: white;
  flex-grow: 1;
  align-self: stretch;
  max-width: 300px;
  max-height: 80px;
  @media only screen and (min-width: 500px) {
    align-self: auto;
    flex-grow: 0;
    padding: 30px 40px;
  }
`;
const Title = styled(CenteredByColumn)`
  ${text12300}
  font-size: 24px;
  /* margin-top: 20px; */
  line-height: 1.1;
  text-transform: uppercase;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.6px;
  width: 100%;
  margin: 10px 0px;

  span.cross-out {
    text-decoration: line-through;
    color: grey;
  }
  @media only screen and (min-width: 500px) {
    text-align: left;
    font-size: 32px;
    /* width: 100%; */
  }
  @media only screen and (max-height: 600px) {
    margin: 0px;
  }
`;

const Tagline = styled.span`
  /* ${text12300} */
  ${textTitle}
  margin: 10px 0px 15px 0px;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.6px;
  /* text-transform: uppercase; */
  svg {
    color: ${Brown};
    margin-right: 5px;
    font-size: 20px;
    margin-bottom: 2px;
  }
`;
const Logo = styled(Centered)`
  position: absolute;
  left: -40px;
  bottom: -50px;
  font-size: 90px;
  transform: rotate(15deg);
`;
const NumberOfDays = styled(Centered)`
  .line-1 {
    font-size: 14px;
    color: #999;
    font-weight: 500;
    margin-right: 10px;
    text-decoration: line-through;
    position: absolute;
    right: calc(50% + 44px);
    margin-bottom: 3px;
  }
  position: relative;
  /* padding-left: 100px; */
  width: 100%;
  align-items: center;
  /* padding-right:20px; */
  @media only screen and (min-width: 1000px) {
    .line-1 {
      right: calc(50% + 54px);
    }
  }
`;

const Benefits = styled(CenteredByColumn)`
  margin-top: 20px;
`;
const Benefit = styled(Text12300)`
  /* ${text12300} */
  text-transform: capitalize;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  @media only screen and (min-width: 1000px) {
    /* font-size: 15px; */
    line-height: 2;
  }
`;
const Real = styled(Centered)`
  ${text12300}
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-top: 20px;
`;
const SavingHighlight = styled(CenteredByColumn)``;
const Icon = styled(Centered)`
  /* margin-bottom: 10px; */
  font-size: 60px;
  svg {
    /* color: ${Brown}; */
    --fa-secondary-opacity: 1;
    --fa-primary-color: ${Gold};
    --fa-secondary-color: ${BackgroundPink};
  }
`;
const RealSaving = styled(Centered)`
  ${text12300}
  text-align: center;
  padding: 0px 40px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
`;
const AverageSaving = styled(Centered)`
  ${text12300}
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.1;
  text-align: center;
  align-self: stretch;
  margin: 30px 20px 0px 20px;
`;
export const SkipButton = styled.button`
  ${text12300}
  font-weight: 400;
  text-transform: uppercase;
  border-bottom: 1px solid black;
  padding: 0px;
  margin: 20px 0 0 0;
`;
export const Membership30DTrial: React.FC<Membership30DTrialProps> = ({
  onClick,
}: Membership30DTrialProps): ReactElement => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <ContentContainer height={windowHeight}>
      <AnonymousRestaurantSlides noText={true} />
      <Tagline>
        <FontAwesomeIcon icon={faCrown} />
        Foodie Membership
      </Tagline>
      <Title>
        <NumberOfDays>
          <span className="line-1">7 day</span>
          <span className="line-2">30 day</span>
        </NumberOfDays>
        <span>free trial</span>
      </Title>
      <Benefits>
        <Benefit>20% cheaper food price</Benefit>
        <Benefit>$2 service fee + free delivery</Benefit>
        <Benefit>100+ member-only restaurants</Benefit>
      </Benefits>
      {/* <Real>$50~$60 average saving per month</Real> */}
      <SkipButton onClick={onClick.bind(this, 0)}>skip</SkipButton>
      <SeeDetails onClick={onClick.bind(this, -1)}>
        <span>see details</span>
      </SeeDetails>
    </ContentContainer>
  );
};
