import React, { ReactElement, useCallback, useState } from 'react';
import moment from 'moment-timezone';
import { RecordMealPayment } from 'src/api/meals';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { ObjectId } from 'src/constants/types';
import { useForm } from 'src/hooks';
import { moneyfy } from 'src/util/money';
import styled from 'styled-components';

export interface RecordMealPaymentButtonProps {
  mealId?: ObjectId;
  dueAmountByFoodie: number;
  recordedPayment?: number;
  recordedPayTime?: number;
}

type FormData = {
  mealId: string;
  payment?: number;
};

const Form = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-width: 400px;
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0;
`;

const FormLabel = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-right: 16px;
`;

const ButtonRow = styled(FormRow)`
  justify-content: center;
`;

export const RecordMealPaymentButton: React.FC<RecordMealPaymentButtonProps> = ({
  mealId,
  dueAmountByFoodie,
  recordedPayment,
  recordedPayTime,
}): ReactElement => {
  const [isRecordPaymentFormOpen, setIsRecordPaymentFormOpen] = useState(false);
  const [payment, setPayment] = useState(recordedPayment);
  const [payTime, setPayTime] = useState(recordedPayTime);

  const openForm = useCallback(() => {
    setIsRecordPaymentFormOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setIsRecordPaymentFormOpen(false);
  }, []);
  const { formData, onInputChange } = useForm<FormData>({
    mealId: mealId,
    payment: payment ?? moneyfy(dueAmountByFoodie) ?? 0,
  });
  const submitForm = useCallback(async () => {
    if (formData.payment != null) {
      const meal = await RecordMealPayment(mealId, formData.payment);
      setPayTime(meal.payTime);
      setPayment(meal.payment);
    }

    closeModal();
  }, [closeModal, formData.payment, mealId]);

  if (mealId == null) return null;

  const payTimeStr = moment(payTime).tz('America/Los_Angeles').format('ddd M/D/YY');
  return (
    <>
      <Button type="button" onClick={openForm}>
        {payment != null ? `$${payment} paid at ${payTimeStr}` : 'Record Meal Payment'}
      </Button>
      <Modal
        isOpen={isRecordPaymentFormOpen}
        title="Record Meal Payment"
        showClose={true}
        onClose={closeModal}
      >
        <Form>
          <FormRow>
            <FormLabel>Recorded Payment</FormLabel>
            <input
              name="payment"
              value={formData.payment ?? dueAmountByFoodie ?? 0}
              onChange={onInputChange}
            />
          </FormRow>
          <ButtonRow>
            <Button type="button" onClick={submitForm}>
              Record Payment
            </Button>
          </ButtonRow>
        </Form>
      </Modal>
    </>
  );
};
