import { LOGGING } from '../../constants';
import { LOAD_ROUTE, LOAD_ROUTES } from '../actionTypes';
import { apiCall } from '../api';
import { setCurrentUser } from './currentUser';

export const loadRoute = (route) => ({
  type: LOAD_ROUTE,
  route,
});

export const loadRoutes = (routes) => ({
  type: LOAD_ROUTES,
  routes,
});

export const startRoute = () => {
  return (dispatch, getState) => {
    const { route: oldRoute } = getState();
    LOGGING && console.log('startRoute called with oldRoute:', oldRoute);
    return apiCall('POST', `/routes/${oldRoute._id}`, {}, getState().currentUser)
      .then((route) => {
        LOGGING && console.log('startRoute got new route', route);
        dispatch(loadRoute(route));
      })
      .catch((err) => {
        LOGGING && console.log('startRoute got err', err);
      });
  };
};

export const endRoute = () => {
  LOGGING && console.log('endRoute called');
  return (dispatch, getState) => {
    const { route, currentUser } = getState();
    return apiCall('GET', `/routes/${route._id}`, {}, getState().currentUser)
      .then((route) => {
        LOGGING && console.log('endRoute got route', route);
        dispatch(loadRoute({}));
        dispatch(setCurrentUser({ ...currentUser.user, driverInfo: {} }));
      })
      .catch((err) => {
        LOGGING && console.log('endRoute got err', err);
      });
  };
};

export const fetchRoute = () => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    const mealID = currentUser.user.driverInfo?.meal;
    LOGGING && console.log('the mealID is: ', mealID);
    return apiCall('get', `/routes/driver/${currentUser.user._id}`, {}, getState().currentUser)
      .then((route) => {
        LOGGING && console.log('fetchRoute got result from backend:', route);
        dispatch(loadRoute(route || {}));
      })
      .catch((err) => {
        LOGGING && console.log('fetchRoute got err', err);
      });
  };
};

export const createRoute = ({
  driverId,
  restaurantId,
  orderIds,
  estimatedRouteTime,
  driverHourlyWage,
  mealID,
}) => {
  return (dispatch, getState) => {
    return apiCall(
      'post',
      `/routes/driver/${driverId}`,
      {
        restaurantId,
        orderIds,
        estimatedRouteTime,
        driverHourlyWage,
        mealID,
      },
      getState().currentUser
    )
      .then((route) => {
        LOGGING && console.log('createRouteForDriver got result from backend:', route);
        dispatch(loadRoute(route));
      })
      .catch((err) => {
        LOGGING && console.log('createRouteForDriver got err', err);
      });
  };
};

export const updateRoute = ({ restaurantId, orderIds }) => {
  return (dispatch, getState) => {
    const { route } = getState();
    return apiCall(
      'put',
      `/routes/${route._id}`,
      {
        restaurantId,
        orderIds,
      },
      getState().currentUser
    )
      .then((route) => {
        LOGGING && console.log('updateRoute got result from backend:');
        dispatch(loadRoute(route));
      })
      .catch((err) => {
        LOGGING && console.log('updateRoute got err', err);
      });
  };
};

export const readAllRoutes = (today) => {
  LOGGING && console.log('readAllRoutes called.', { today });
  return (dispatch, getState) => {
    return apiCall('get', `/routes/all${today ? '?today=true' : ''}`, {}, getState().currentUser)
      .then((routes) => {
        LOGGING && console.log('readAllRoutes got result from backend:', routes);
        dispatch(loadRoutes(routes || []));
        return routes || [];
      })
      .catch((err) => {
        LOGGING && console.log('fetchRoute got err', err);
      });
  };
};

export const payDriverForRoute = ({ routeId, message }) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();

    LOGGING &&
      console.log('payDriverForRoute called with:', {
        routeId,
        message,
        currentUser,
      });
    return apiCall(
      'post',
      `/routes/pay/${routeId}`,
      {
        userId: currentUser.user._id,
        message,
      },
      getState().currentUser
    );
  };
};
