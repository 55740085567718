const FormChangePassword = ({ onEdit, onSave, password, passwordConfirm, error, passwordOld }) => (
  <form className="form-container" onSubmit={onSave}>
    {error ? <div className="form-error">{error.message}</div> : null}
    <div className="form-body">
      <div className="form-header">
        <input
          name="passwordOld"
          type="password"
          defaultValue={passwordOld}
          onChange={onEdit}
          placeholder="Type old password"
          className="form-field"
        />
        <input
          name="password"
          type="password"
          defaultValue={password}
          onChange={onEdit}
          placeholder="Type new password"
          className="form-field"
        />
        <input
          name="passwordConfirm"
          type="password"
          defaultValue={passwordConfirm}
          onChange={onEdit}
          placeholder="Confirm new password"
          className="form-field"
        />
      </div>
    </div>
    <button type="submit">submit</button>
  </form>
);

export default FormChangePassword;
