import './FormEnterPassword.scss';
const FormEnterPassword = ({ password, onSubmit, onChange, error }) => (
  <form onSubmit={onSubmit} className="form-container enter-password">
    {error ? <div className="form-error">{error.message}</div> : null}
    <div className="form-body">
      <label className="form-title">enter password</label>
      <div className="form-header">
        <input
          defaultValue={password}
          onChange={onChange}
          name="password"
          type="password"
          className="form-field"
        />
      </div>
    </div>
    <button type="submit">submit</button>
  </form>
);
export default FormEnterPassword;
