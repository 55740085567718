import React, { useEffect, useRef } from "react";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Geosuggest from "react-geosuggest";
import PhoneInput from "react-phone-number-input";
import "./DeliveryInfo.scss";
import { PhoneFormat } from "./";
const DeliveryInfoCompany = ({ deliveryInfo }) => {
  const { address, phone, name, address_room_info } = deliveryInfo;
  return (
    <div className="form-section delivery-info paypal-delivery">
      <span className="form-section-title">Delivery</span>
      <div className="form-row">
        <div className="form-field-wrapper">
          <label>name</label>
          <span>{name}</span>
        </div>
      </div>
      <div className="form-row">
        <div className="form-field-wrapper">
          <label>address</label>
          <span>{address}</span>
          {address_room_info && address_room_info.length > 0 ? (
            <span>{address_room_info}</span>
          ) : null}
        </div>
      </div>
      <div className="form-row">
        <div className="form-field-wrapper">
          <label>admin phone</label>
          <span>{phone}</span>
        </div>
      </div>
    </div>
  );
};
const DeliveryInfoDisplay = ({ order, shortForm }) => (
  <React.Fragment>
    {order.user && order.user.firstName && order.user.lastName ? (
      shortForm ? null : (
        <div className="receipt-row">
          <label>
            <span>name</span>
          </label>
          <span>{`${order.name}`}</span>
        </div>
      )
    ) : order.name ? (
      <div className="receipt-row">
        <label>
          <span>name</span>
        </label>
        <span>{`${order.name}`}</span>
      </div>
    ) : null}
    <div className="receipt-row">
      <label>
        <span>address</span>
      </label>
      <span>{order.address}</span>
    </div>
    {order.note && (
      <div className="receipt-row">
        <label>
          <span>Instructions</span>
        </label>
        <span>{order.note}</span>
      </div>
    )}
    {order.phone && (
      <div className="receipt-row">
        <label>
          <span>phone</span>
        </label>
        <span>{order.phone}</span>
      </div>
    )}
    {order.email && (
      <div className="receipt-row email">
        <label>
          <span>email</span>
        </label>
        <span>{order.email}</span>
      </div>
    )}
  </React.Fragment>
);
const DeliveryInfoForm = ({
  deliveryInfo,
  onChange,
  onChangeNumber,
  onEditAddress,
  onSelectAddress,
  onToggleRingBell,
  error,
  isCorporate,
  user,
}) => {
  const nameRef = useRef();
  const addressRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();

  useEffect(() => {
    switch (error.param) {
      case "name":
        nameRef.current?.scrollIntoView();
        break;
      case "address":
        addressRef.current?.scrollIntoView();
        break;
      case "email":
        emailRef.current?.scrollIntoView();
        break;
      case "phone":
        phoneRef.current?.scrollIntoView();
        break;
      default:
        return;
    }
    // for header
    window.scrollBy(0, -50);
  }, [error.param]);

  return (
    <React.Fragment>
      <div className="form-section delivery-info">
        <div className="form-section-title">{`${
          isCorporate ? "1. " : ""
        }Contact`}</div>
        <div className="form-row">
          <div
            className={`form-field-wrapper ${
              error && error.param === "name" ? "wrong" : ""
            }`}
          >
            <input
              ref={nameRef}
              defaultValue={
                deliveryInfo.name ||
                (isCorporate ? user?.userGroup?.name : user?.name)
              }
              onChange={onChange.bind(this, "deliveryInfo")}
              name="name"
              placeholder={`enter ${isCorporate ? "company " : ""}name`}
            />
            {error.param === "name" && (
              <div className="error-message">
                {error && error.message ? error.message : ""}
              </div>
            )}
          </div>
        </div>
        <div className="form-row">
          <div
            className={`form-field-wrapper ${
              error && error.param === "email" ? "wrong" : ""
            }`}
          >
            <input
              ref={emailRef}
              defaultValue={deliveryInfo?.email || user?.email}
              onChange={onChange.bind(this, "deliveryInfo")}
              name="email"
              placeholder={`Enter ${isCorporate ? "admin " : ""}email`}
            />
            {error.param === "email" ? (
              <div className="error-message">
                {error && error.message ? error.message : ""}
              </div>
            ) : null}
          </div>
        </div>
        <div className="form-row">
          <div
            className={`form-field-wrapper ${
              error && error.param === "phone" ? "wrong" : ""
            }`}
          >
            <PhoneInput
              ref={phoneRef}
              placeholder={`Enter ${isCorporate ? "admin " : ""}phone`}
              value={deliveryInfo?.phone || user?.phone}
              name="phone"
              onChange={onChangeNumber.bind(this, "deliveryInfo", "phone")}
              defaultCountry="US"
            />
            {error.param === "phone" ? (
              <div className="error-message">
                {error && error.message ? error.message : ""}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="form-section delivery-info">
        <div className="form-section-title">{`${
          isCorporate ? "1. " : ""
        }Delivery`}</div>

        <div className="form-row">
          <div
            className={`form-field-wrapper ${
              error && error.param === "address" ? "wrong" : "address"
            }`}
          >
            <Geosuggest
              innerRef={addressRef}
              name="address"
              placeholder={`Enter ${isCorporate ? "company " : ""}address`}
              initialValue={deliveryInfo?.address || ""}
              onSuggestSelect={onSelectAddress}
              autoActivateFirstSuggest={true}
              onChange={onEditAddress}
              placeDetailFields={["address_components", "adr_address", "name"]}
            />
            {error.param === "address" && (
              <div className="error-message">
                {error && error.message ? error.message : ""}
              </div>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-field-wrapper address">
            <input
              defaultValue={deliveryInfo.address_room_info}
              onChange={onChange.bind(this, "deliveryInfo")}
              name="address_room_info"
              placeholder="Enter Room / apartment Info"
            />
          </div>
        </div>
      </div>

      <div className="form-section delivery-info">
        <div className="form-section-title">{`${
          isCorporate ? "2. " : ""
        }Instructions for Foodie Driver`}</div>
        <div className="form-row">
          <button
            className={`save-ring ${
              deliveryInfo.ring_doorbell ? "checked" : "unchecked"
            }`}
            onClick={onToggleRingBell}
          >
            <FontAwesomeIcon icon={faCheck} />
            <span>
              Yes, I would like my driver to knock or ring the doorbell.
            </span>
          </button>
        </div>
        <div className="form-row">
          <div
            className={`form-field-wrapper ${
              error && error.param === "note" ? "wrong" : ""
            }`}
          >
            <textarea
              defaultValue={deliveryInfo.note}
              onChange={onChange.bind(this, "deliveryInfo")}
              name="note"
              className="delivery-instructions"
              placeholder="e.g. leave on shoe rack, call at gate, etc."
            />
            {error.param === "note" ? (
              <div className="error-message">
                {error && error.message ? error.message : ""}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const DeliveryInfoFormPaypal = ({ deliveryInfo, onEdit, onToggleRingBell }) => (
  <div className="form-section delivery-info paypal-delivery">
    {/* <div className="form-row shipping-disclaimer">
      <span>
        We will deliver to the following address, regardless of your PayPal
        shipping address.
      </span>
    </div> */}
    <div className="form-section-title">
      <span>Delivery</span>
      <button className="edit-delivery" onClick={onEdit}>
        <FontAwesomeIcon icon={faPencil} />
      </button>
    </div>
    <div className="form-row">
      <div className="form-field-wrapper name">
        <label>name</label>
        <span>{deliveryInfo.name}</span>
      </div>
    </div>
    <div className="form-row">
      <div className="form-field-wrapper address">
        <label>address</label>
        <span>{deliveryInfo.address}</span>
      </div>
    </div>
    {deliveryInfo.address_room_info &&
    deliveryInfo.address_room_info.length > 0 ? (
      <div className="form-row">
        <div className="form-field-wrapper address-room-info">
          <span>{deliveryInfo.address_room_info}</span>
        </div>
      </div>
    ) : null}
    <div className="form-row">
      <div className="form-field-wrapper email">
        <label>email</label>
        <span>{deliveryInfo.email}</span>
      </div>
    </div>
    <div className="form-row">
      <div className="form-field-wrapper phone">
        <label>phone</label>
        <span>
          {deliveryInfo.phone && deliveryInfo.phone.trim().length > 0
            ? PhoneFormat(deliveryInfo.phone)
            : "(None)"}
        </span>
      </div>
    </div>
    <div className="form-row">
      <button
        className={`save-ring ${
          deliveryInfo.ring_doorbell ? "checked" : "unchecked"
        }`}
        onClick={onToggleRingBell}
      >
        <FontAwesomeIcon icon={faCheck} />
        <span>Yes, I would like my driver to knock or ring the doorbell.</span>
      </button>
    </div>
    <div className="form-row">
      <div className="form-field-wrapper note">
        <label>instructions</label>
        <span>{`${
          deliveryInfo.note && deliveryInfo.note.trim().length > 0
            ? deliveryInfo.note
            : "(None)"
        }`}</span>
      </div>
    </div>
  </div>
);

export {
  DeliveryInfoForm,
  DeliveryInfoDisplay,
  DeliveryInfoFormPaypal,
  DeliveryInfoCompany,
};
