import React, { ReactElement, useCallback, useState, useEffect } from "react";
import {
  faMapPin as faNearby,
  faPlusCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { set } from "lodash";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import Select from "react-dropdown-select";
import toast, { Toaster } from "react-hot-toast";
import { useSelector, useDispatch, useStore } from "react-redux";
import { Divider } from "src/components/Divider";
import { DayOfWeek, LOGGING } from "src/constants";
import { RootState } from "src/store";
import {
  fetchTopMeals,
  createMealTime,
  getLastMealTimes,
  getNearByRestaurants,
} from "src/store/actions";
import styled from "styled-components";
// import date picker from react-datepicker
import {
  CenteredByColumnLeft,
  Text12300,
  Centered,
  actionGrey,
  card,
  BorderGrey,
  text12300,
  Beige,
  ActionGrey,
  Peach,
  Purple,
  Brown,
  Blue,
  ActionBlack,
  centered,
  BackgroundGrey,
  CenteredByColumn,
} from "./components/Shared";
import { WindowStartsByMealType } from "../../constants";
import { PopUp } from "../shared";
const daysToFetch = 90;
const Date = styled(Centered)`
  width: 100%;
  ${text12300}
  text-transform: capitalize;
  label {
    font-weight: 500;
  }
  justify-content: space-between;
  /* border-bottom: 1px solid ${BorderGrey}; */
`;
const Revenue = styled(Date)``;
const Gmv = styled(Date)``;
const BlockContainer = styled(CenteredByColumnLeft)<{ isSelected?: boolean }>`
  ${card}
  width: 400px;
  padding: 20px;
  margin: 10px;
  border: 1px solid ${BorderGrey};
  align-self: stretch;
  justify-content: flex-start;
  padding-bottom: 80px;
  position: relative;
`;

const Blocks = styled(Centered)`
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
`;

const Meals = styled(CenteredByColumnLeft)`
  /* margin-top: 20px; */
  /* padding-top: 5px; */
  width: 100%;
`;
const MealContainer = styled(Centered)`
  /* align-items: flex-start; */
  width: 100%;
  justify-content: space-between;
  position: relative;
  margin-bottom: 5px;
`;
const ButtonAdd = styled.button`
  position: absolute;
  right: 10px;
  font-size: 15px;
  margin: 0px;
  padding: 0px;
`;
const Restaurant = styled(Centered)`
  ${text12300}
  width: 60%;
  justify-content: space-between;
  /* border: 2px solid purple; */
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  align-self: auto;
  span {
    display: inline-block;
    position: calc(100% - 70px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }
  label {
    /* font-weight: 500; */
    text-transform: capitalize;
    width: 70px;
    text-align: right;
    flex-grow: 0;
    flex-shrink: 0;
  }
`;
const RestaurantRevenue = styled(Centered)<{ highlight?: boolean }>`
  ${text12300}
  width: 20%;
  justify-content: ${(props) => (props.highlight ? "center" : "flex-end")};
  text-transform: capitalize;
  background-color: ${(props) => (props.highlight ? Brown : "transparent")};
  color: ${(props) => (props.highlight ? "white" : "black")};
  font-weight: ${(props) => (props.highlight ? 600 : 300)};
  /* text-align: ${(props) => (props.highlight ? "center" : "right")}; */
  margin-left: ${(props) => (props.highlight ? "10px" : "0px")};
  padding: 0px ${(props) => (props.highlight ? "5px" : "0px")};
`;
const RestaurantGmv = styled(RestaurantRevenue)`
  ${text12300}
`;
const SelectButton = styled.button<{ isSelected?: boolean }>`
  ${actionGrey}
  background-color: ${(props) => (props.isSelected ? "black" : "transparent")};
  color: ${(props) => (props.isSelected ? "white" : "black")};
  border: 1px solid ${(props) => (props.isSelected ? "white" : "black")};
  margin-right: 10px;
  margin-bottom: 0px;
  padding: 8px;
`;
const ActionBlock = styled(Centered)`
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: calc(100% - 40px);
  justify-content: space-between;
  /* justify-content: flex-end; */
  align-items: center;
  .react-datepicker-wrapper {
    input {
      margin: 0px;
      text-align: center;
    }
    margin: 0px 10px;
  }
  .react-dropdown-select-content {
    position: relative;
    height: 35px;
    ${centered}
    align-self: stretch;
    flex-grow: 1;
    span {
      position: absolute;
      ${text12300}
      font-weight: 500;
      text-transform: capitalize;
    }
    input {
      margin: 0px;
    }
  }
  .react-dropdown-select-dropdown-handle {
    margin: 0px;
    width: 20px;
  }
`;
const NearByList = styled(CenteredByColumnLeft)<{ top?: number; left }>`
  background-color: white;
  z-index: 100;
  position: fixed;
  left: ${(props) => props.left || 400}px;
  top: 20px; //${(props) => props.top || 0}px;
  padding: 10px;
  width: 400px;
  height: calc(100vh - 40px);
  overflow-y: hidden;
  justify-content: flex-start;
`;
const List = styled(CenteredByColumn)`
  width: 100%;
  overflow-y: auto;
  justify-content: flex-start;
`;
const SeeNearBy = styled.button`
  width: 25px;
  height: 25px;
  background-color: ${BackgroundGrey};
  margin-left: 10px;
  border-radius: 50%;
  padding: 0px;
  flex-shrink: 0;
`;
const RestaurantNearBy = styled(CenteredByColumn)`
  width: 100%;
  border-bottom: 1px solid ${BorderGrey};
  padding-bottom: 10px;
  margin-bottom: 10px;
  // when it's the last child no border
  &:last-child {
    border: none;
  }
`;
const RestaurantInfo = styled(Centered)`
  width: 100%;
  justify-content: flex-end;
  padding-left: 200px;
  position: relative;
`;
const RestaurantName = styled(Text12300)`
  width: 200px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  left: 0px;
`;
const RestaurantCuisines = styled(Centered)`
  width: 100%;
  justify-content: flex-start;
`;
const Cuisine = styled(Text12300)`
  margin-right: 5px;
  /* border: 1px solid black; */
  padding: 0px 5px;
  border-radius: 5px;
  background-color: ${Beige};
`;
const Distance = styled(Text12300)`
  margin-right: 15px;
`;
const GMV = styled(Text12300)`
  margin-right: 15px;
`;
const NearByFor = styled(Text12300)`
  font-weight: 500;
  text-transform: capitalize;
  border-bottom: 1px solid black;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
`;
const LastTime = styled(Text12300)``;
const Meal = ({
  meal,
  revenue,
  gmv,
  isSelected,
  onToggle,
  lastMealTime,
  onAddCandidate,
  nearBy,
  onAddNearBys,
  dayOfWeek,
  mealType,
}) => {
  const [showNearByFor, setShowNearByFor] = useState(null);
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const [reading, setReading] = useState(false);
  const onSeeNearBy = async (e) => {
    e.preventDefault();
    LOGGING && console.log("onSeeNearBy", meal?.restaurant?._id);

    setShowNearByFor(meal?.restaurant?.name);
    const rect = e.target.getBoundingClientRect();
    let position = rect.left + rect.width;
    if (position + 400 > window.innerWidth) {
      position = rect.left - 800;
    }
    setLeft(position);
    setTop(rect.top);
    if (!nearBy) {
      setReading(true);
      getNearByRestaurants({
        restaurantId: meal?.restaurant?._id,
        dayOfWeek: dayOfWeeks.findIndex((d) => d === dayOfWeek),
        mealType: mealType.toLowerCase(),
      })
        .then((res) => {
          LOGGING && console.log("getNearByRestaurants", res);
          onAddNearBys(meal?.restaurant?._id, res);
          setReading(false);
          // get the display postion of MealContainer
        })
        .catch((e) => {
          LOGGING && console.error("getNearByRestaurants", e);
        });
    }
  };
  LOGGING && console.log("Meal rendering with nearBy", { nearBy });
  return (
    <MealContainer>
      <SelectButton onClick={onToggle} isSelected={isSelected}></SelectButton>
      <Restaurant>
        <span>{meal?.restaurant?.name}</span>
        <label>{moment(lastMealTime).format("ddd M/D")}</label>
      </Restaurant>
      <RestaurantRevenue>
        {revenue ? `$${revenue.toFixed(0)}` : ""}
      </RestaurantRevenue>
      <RestaurantGmv>${gmv.toFixed(0)}</RestaurantGmv>
      <SeeNearBy onClick={onSeeNearBy}>
        <FontAwesomeIcon icon={faNearby} />
      </SeeNearBy>
      <PopUp
        isPoppedUp={showNearByFor && nearBy}
        componentToDisplay={
          <NearByList left={left} top={top}>
            <NearByFor>{showNearByFor} cluster</NearByFor>
            {reading ? (
              <span>Reading nearby restaurants...</span>
            ) : (
              <List>
                {nearBy?.map((restaurant, index) => {
                  return (
                    <RestaurantNearBy key={index}>
                      <RestaurantInfo>
                        <RestaurantName>
                          {restaurant?.restaurantName}
                        </RestaurantName>
                        <Distance>
                          {restaurant?.distance?.toFixed(1)} mi
                        </Distance>
                        <GMV>${restaurant?.gmv.toFixed(0)}</GMV>
                        <LastTime>
                          {moment(restaurant?.windowStart).format("ddd M/D")}
                        </LastTime>
                      </RestaurantInfo>
                      <RestaurantCuisines>
                        {restaurant?.cuisines?.map((c, i) => (
                          <Cuisine key={i}>{c.name}</Cuisine>
                        ))}
                        <ButtonAdd
                          onClick={onAddCandidate.bind(this, restaurant)}
                        >
                          <FontAwesomeIcon icon={faPlusCircle} />
                        </ButtonAdd>
                      </RestaurantCuisines>
                    </RestaurantNearBy>
                  );
                })}
              </List>
            )}
          </NearByList>
        }
        hidePopUp={() => setShowNearByFor(null)}
        backgroundColor={"rgba(0, 0, 0, 0.5)"}
      />
    </MealContainer>
  );
};

const AddToMeal = styled(ActionBlack)<{ disabled?: boolean }>`
  /* width: 100%; */
  height: 35px;
  width: 100px;
  text-transform: capitalize;
  text-align: center;
  background-color: ${(props) => (props.disabled ? "grey" : "black")};
`;

const Block = ({
  windowStart,
  gmv,
  revenue,
  restaurants,
  mealType,
  highlight,
  isSelected,
  selectedRestaurants,
  onToggleRestaurant,
  onClearRestaurants,
  nearbys,
  onAddNearBys,
}) => {
  const date = moment(windowStart).format("MM/DD");
  const dayOfWeek = moment(windowStart).format("dddd");
  const [selectedDate, setSelectedDate] = useState(
    moment()
      .startOf("week")
      .add(1, "week")
      .add(moment(windowStart).day(), "day")
      .toDate()
  );
  const [addingMeals, setAddingMeals] = useState(false);
  const [mealToCreate, setMealToCreate] = useState(mealType);
  const [candidates, setCandidates] = useState(restaurants);
  useEffect(() => {
    setSelectedDate(
      moment()
        .startOf("week")
        .add(1, "week")
        .add(moment(windowStart).day(), "day")
        .toDate()
    );
  }, [windowStart]);
  useEffect(() => {
    setMealToCreate(mealType);
  }, [mealType]);
  useEffect(() => {
    setCandidates(restaurants);
  }, [restaurants]);
  const onAddCandidate = (candidate, e) => {
    e.preventDefault();
    LOGGING &&
      console.log("onAddCandidate called with", { candidates, candidate });
    const { gmv, restaurantId, restaurantName, windowStart } = candidate;
    const newCandidate = {
      _id: restaurantId,
      LastTime: windowStart,
      gmv: gmv,
      meal: {
        restaurant: {
          _id: restaurantId,
          name: restaurantName,
        },
      },
    };
    setCandidates([...candidates, newCandidate]);
  };
  const onAddMeals = async (e) => {
    e.preventDefault();
    LOGGING && console.log("onAddMeals", selectedDate);
    setAddingMeals(true);
    const { hour, minute } = WindowStartsByMealType[mealToCreate];
    const newDate = moment(selectedDate).hour(hour).minute(minute).valueOf();
    createMealTime({ windowStart: newDate, restaurants: selectedRestaurants })
      .then(() => {
        toast.success("Meals added");
        onClearRestaurants();
        setAddingMeals(false);
      })
      .catch((e) => {
        setAddingMeals(false);
        toast.error("Error adding meals");
      });
  };
  LOGGING && console.log("Block", { restaurants });
  return (
    <BlockContainer isSelected={isSelected}>
      <Toaster />
      <Date>
        <label>{dayOfWeek}</label>
        <label>{mealType}</label>
        <span>{date}</span>
      </Date>
      <Divider />
      <Meals>
        <MealContainer>
          <Restaurant></Restaurant>
          <RestaurantRevenue>revenue</RestaurantRevenue>
          <RestaurantGmv>gmv</RestaurantGmv>
        </MealContainer>
        <MealContainer>
          <Restaurant>meal total</Restaurant>
          <RestaurantRevenue highlight={highlight === "revenue"}>
            ${revenue.toFixed(0)}
          </RestaurantRevenue>
          <RestaurantGmv highlight={highlight === "gmv"}>
            ${gmv.toFixed(0)}
          </RestaurantGmv>
        </MealContainer>
        <Divider />
        {candidates
          .sort((a, b) => b.gmv - a.gmv)
          .map((restaurant, index) => (
            <Meal
              key={index}
              index={index}
              {...restaurant}
              isSelected={selectedRestaurants.includes(
                restaurant?.meal?.restaurant?._id
              )}
              onToggle={onToggleRestaurant.bind(
                this,
                restaurant?.meal?.restaurant?._id
              )}
              onAddCandidate={onAddCandidate}
              nearBy={nearbys[restaurant?.meal?.restaurant?._id]}
              onAddNearBys={onAddNearBys}
              dayOfWeek={dayOfWeek}
              mealType={mealType}
            />
          ))}
      </Meals>
      <ActionBlock>
        <Select
          style={{
            width: "120px",
            height: "35px",
            padding: "0px 5px",
            border: "none",
            background: "#e5e5e5",
          }}
          options={mealTypes.map((m) => ({ label: m, value: m }))}
          values={[{ label: mealToCreate, value: mealToCreate }]}
          onChange={(values) => {
            setMealToCreate(values[0].value);
          }}
        />
        <DatePicker
          dateFormat="EEE M/d, yyyy"
          selected={selectedDate}
          // onClickOutside={setShowDateSelector.bind(this, false)}
          onChange={(date) => {
            setSelectedDate(date);
            // setShowDateSelector(false);
          }}
        />
        <AddToMeal onClick={onAddMeals} disabled={addingMeals}>
          {`${addingMeals ? "Adding..." : "Add"}`}
        </AddToMeal>
      </ActionBlock>
    </BlockContainer>
  );
};
const rankTypes = ["top", "bottom"];
const mealTypes = ["lunch", "earlyDinner", "dinner"];
const rankByTypes = ["gmv", "revenue"];

const SelectWidget = styled(Centered)`
  padding: 20px 0px 10px 20px;
  flex-wrap: wrap;
  justify-content: flex-start;
`;
const ButtonSelect = styled.button<{ active?: boolean }>`
  ${actionGrey}
  background-color: ${(props) =>
    props.active ? props.theme || "black" : "transparent"};
  color: ${(props) => (props.active ? "white" : props.theme || "black")};
  border: 1px solid
    ${(props) => (props.active ? "white" : props.theme || "black")};
  margin-right: 10px;
  /* margin-bottom: 10px; */
`;
const ButtonBatch = styled(ActionGrey)`
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 15px;
  text-transform: capitalize;
  border: 1px solid black;
`;
export interface PagePlannerProps {}
const dayOfWeeks = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const PagePlanner: React.FC<PagePlannerProps> =
  ({}: PagePlannerProps): ReactElement => {
    const topBlocks = useSelector((state: RootState) => state.topBlocks);
    // const [topBlocks, setTopBlocks] = useState([]);
    const dispatch = useDispatch();
    const store = useStore();
    const [reading, setReading] = useState(false);
    // const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState(dayOfWeeks);
    // const [selectedMealTypes, setSelectedMealTypes] = useState(mealTypes);
    const [selectedDayOfWeek, setSelectedDayOfWeek] = useState("Monday");
    const [selectedMealType, setSelectedMealType] = useState("lunch");
    const [selectedRankBy, setSelectedRankBy] = useState("gmv");
    const [selectedRankType, setSelectedRankType] = useState("top");
    const [selectedBlock, setSelectedBlock] = useState(null);
    const [selectedRestaurants, setSelectedRestaurants] = useState([]);
    const [nearBys, setNearBys] = useState({});
    const [shownBlocks, setShownBlocks] = useState([]);

    const onAddNearBys = (restaurantId, nearBy) => {
      setNearBys({ ...nearBys, [restaurantId]: nearBy });
    };
    const onToggleRestaurant = (blockId, restaurantId, e) => {
      e.preventDefault();
      LOGGING && console.log("onToggleRestaurant", restaurantId);
      if (selectedRestaurants.includes(restaurantId)) {
        setSelectedRestaurants(
          selectedRestaurants.filter((r) => r !== restaurantId)
        );
      } else {
        setSelectedRestaurants([...selectedRestaurants, restaurantId]);
        setSelectedBlock(blockId);
      }
    };

    // const onToggleDayOfWeek = (dayOfWeek) => {
    //   setSelectedDaysOfWeek(
    //     selectedDaysOfWeek.includes(dayOfWeek)
    //       ? selectedDaysOfWeek.filter((d) => d !== dayOfWeek)
    //       : selectedDaysOfWeek.concat(dayOfWeek)
    //   );
    // };
    const onSelectDayOfWeek = (dayOfWeek) => {
      setSelectedDayOfWeek(dayOfWeek);
    };
    // const onToggleMealType = (mealType) => {
    //   setSelectedMealTypes(
    //     selectedMealTypes.includes(mealType)
    //       ? selectedMealTypes.filter((m) => m !== mealType)
    //       : selectedMealTypes.concat(mealType)
    //   );
    // };
    const onSelectMealType = (mealType) => {
      setSelectedMealType(mealType);
    };
    useEffect(() => {
      // if (!topBlocks || topBlocks.length === 0) {
      getData();
      // }
    }, []); //

    useEffect(() => {
      LOGGING &&
        console.log("updating", {
          selectedDayOfWeek,
          selectedMealType,
          topBlocks,
        });
      const filteredBlocks = topBlocks.filter((block) => {
        const dayOfWeek = block.dayOfWeek;
        const mealType = block.mealType;
        return (
          // selectedDaysOfWeek.includes(dayOfWeek) &&
          // selectedMealTypes.includes(mealType)
          dayOfWeek === selectedDayOfWeek && mealType === selectedMealType
        );
      });
      LOGGING && console.log("filteredBlocks", filteredBlocks);
      const sortedBlocks = filteredBlocks.sort((a, b) => {
        const rankBy = selectedRankBy;
        const rankType = selectedRankType;
        const aRank = a[rankBy];
        const bRank = b[rankBy];
        return rankType === "top" ? bRank - aRank : aRank - bRank;
      });
      setShownBlocks(sortedBlocks);
    }, [
      // selectedDaysOfWeek,
      // selectedMealTypes,
      selectedDayOfWeek,
      selectedMealType,
      selectedRankBy,
      selectedRankType,
    ]);

    const getData = useCallback(async () => {
      setReading(true);
      await fetchTopMeals(daysToFetch)(dispatch, store.getState).then(
        async () => {
          LOGGING && console.log("fetchTopMeals got topBlocks", topBlocks);
          const restaurantIds = topBlocks.reduce((acc, block) => {
            const { restaurants } = block;
            const ids = restaurants
              .filter((r) => r?.meal?.restaurant)
              .map((r) => r?.meal?.restaurant?._id);
            return acc.concat(ids);
          }, []);
          // dedupe
          const uniqueRestaurantIds = Array.from(new Set(restaurantIds));
          // call getLastMealTimes
          await getLastMealTimes(uniqueRestaurantIds)(
            dispatch,
            store.getState
          ).then(() => {
            LOGGING && console.log("getLastMealTimes got topBlocks", topBlocks);
            setReading(false);
          });
        }
      );
    }, [dispatch, store]);

    LOGGING && console.log("rendering", { selectedRestaurants, shownBlocks });
    return reading ? (
      <div>Loading...</div>
    ) : (
      <div>
        {/*select rank type*/}
        <SelectWidget>
          {/* <ButtonBatch
            onClick={(e) => {
              e.preventDefault();
              setSelectedDaysOfWeek(dayOfWeeks);
            }}
          >
            select all days
          </ButtonBatch>
          <ButtonBatch
            onClick={(e) => {
              e.preventDefault();
              setSelectedDaysOfWeek([]);
            }}
          >
            clear all days
          </ButtonBatch> */}
          {dayOfWeeks.map((dayOfWeek, index) => (
            <ButtonSelect
              key={index}
              // active={selectedDaysOfWeek.includes(type)}
              active={selectedDayOfWeek === dayOfWeek}
              theme={Purple}
              onClick={(e) => {
                e.preventDefault();
                // onToggleDayOfWeek(type);
                onSelectDayOfWeek(dayOfWeek);
              }}
            >
              {dayOfWeek}
            </ButtonSelect>
          ))}
        </SelectWidget>
        <SelectWidget>
          {mealTypes.map((type) => (
            <ButtonSelect
              key={type}
              theme={Peach}
              // active={selectedMealTypes.includes(type)}
              active={selectedMealType === type}
              onClick={(e) => {
                e.preventDefault();
                // onToggleMealType(type);
                onSelectMealType(type);
              }}
            >
              {type}
            </ButtonSelect>
          ))}
          {rankByTypes.map((type) => (
            <ButtonSelect
              key={type}
              active={selectedRankBy === type}
              theme={Brown}
              onClick={(e) => {
                e.preventDefault();
                setSelectedRankBy(type);
              }}
            >
              {`rank by ${type}`}
            </ButtonSelect>
          ))}
          {rankTypes.map((type) => (
            <ButtonSelect
              key={type}
              theme={Blue}
              active={selectedRankType === type}
              onClick={(e) => {
                e.preventDefault();
                setSelectedRankType(type);
              }}
            >
              {`see ${type}`}
            </ButtonSelect>
          ))}
        </SelectWidget>
        <Blocks>
          {shownBlocks.map((block, id) => (
            <Block
              key={id}
              {...block}
              highlight={selectedRankBy}
              isSelect={selectedBlock === id}
              selectedRestaurants={selectedRestaurants}
              onToggleRestaurant={onToggleRestaurant.bind(this, id)}
              onClearRestaurants={setSelectedRestaurants.bind(this, [])}
              nearbys={nearBys}
              onAddNearBys={onAddNearBys}
            />
          ))}
        </Blocks>
      </div>
    );
  };
