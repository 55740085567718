import React, { ReactElement, useEffect, useState } from "react";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { indexOf, set } from "lodash";
import moment from "moment";
import { useDispatch, useStore } from "react-redux";
import { LOGGING } from "src/constants";
import { getUserOrderHistory, readRestaurantStats } from "src/store/actions";
import styled from "styled-components";
import { SearchRestaurants } from "./components/admin/SearchRestaurants";
import { SearchUsers } from "./components/admin/SearchUsers";
import {
  CenteredByColumn,
  CenteredByColumnLeft,
  Centered,
  Text12300,
  card,
  text12300,
  BackgroundPink,
  TextTitle,
  actionGrey,
  ActionGrey,
  BorderGrey,
  Purple,
} from "./components/Shared";
interface PageRestaurantStatsProps {}
const Container = styled(CenteredByColumnLeft)`
  position: fixed;
  justify-content: flex-start;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  overflow-y: hidden;
`;
const UserContainer = styled(CenteredByColumnLeft)`
  margin-top: 10px;
  width: 100%;
`;
const Tastes = styled(Text12300)`
  // cap at 2 lines
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const Name = styled(Text12300)``;
const RestaurantProfile = ({ restaurant }) => {
  const { name, cuisines } = restaurant || {};
  return (
    <UserContainer>
      <Name>{`${name}`}</Name>
      <Tastes>{`Cuisines: ${cuisines.map((t) => t.name).join(", ")}`}</Tastes>
    </UserContainer>
  );
};
const DishList = styled(CenteredByColumnLeft)`
  width: 100%;
  justify-content: flex-start;
`;
const Content = styled(CenteredByColumnLeft)`
  width: 100%;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  justify-content: flex-start;
`;
const RestaurantName = styled(ActionGrey)`
  ${text12300}
  svg {
    margin-left: 5px;
  }
  text-align: left;
`;
const RestaurantOrders = styled(CenteredByColumnLeft)`
  width: 100%;
  ${card}
`;
const OrderContainer = styled(Centered)`
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
`;
const Good = styled(CenteredByColumnLeft)`
  align-self: stretch;
`;
const Dish = styled(Text12300)``;
const DishSelection = styled(Centered)`
  margin-left: 20px;
  margin-bottom: 5px;
`;
const SelectionName = styled(Text12300)`
  background-color: ${BackgroundPink};
  padding: 0px 10px;
  border-radius: 5px;
  line-height: 1.8;
  margin-right: 10px;
`;
const DishSelectionItem = styled(Text12300)``;
const Date = styled(TextTitle)`
  font-size: 16px;
`;
const CartCreated = styled(Date)`
  font-size: 12px;
`;
const PayTime = styled(Date)`
  font-size: 12px;
`;
const Orderer = ({ orderer, count }) => {
  return (
    <OrderContainer>
      <label>{orderer}</label>
      <span>{count}</span>
    </OrderContainer>
  );
};
const DishSummary = ({ dish }) => {
  const [showDetails, setShowDetails] = useState(false);
  const { name, orderers, count } = dish || {};
  const onToggle = (e) => {
    e.preventDefault();
    setShowDetails(!showDetails);
  };
  return (
    <RestaurantOrders>
      <RestaurantName onClick={onToggle}>
        <span>
          {name}({count} orders, {Object.keys(orderers).length} customers)
        </span>

        <FontAwesomeIcon icon={showDetails ? faChevronUp : faChevronDown} />
      </RestaurantName>
      {showDetails
        ? Object.keys(orderers).map((orderer, index) => (
            <Orderer key={index} orderer={orderer} count={orderers[orderer]} />
          ))
        : null}
    </RestaurantOrders>
  );
};
const ScheduleContainer = styled(CenteredByColumn)`
  margin: 0px 0px 20px 0px;
  ${text12300}
  td {
    width: 30px;
    /* text-align:right; */
  }
  th {
    font-weight: 400;
  }
`;
const Count = styled.td<{ saturation?: number }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: ${(props) =>
    props.saturation ? (props.saturation > 50 ? "white" : "black") : "black"};
  text-align: center;
  background-color: ${(props) =>
    props.saturation
      ? `rgba(0,0,0,${props.saturation / 100})`
      : "rgba(0,0,0,0)"};
  font-weight: 500;
`;
const Schedules = ({ schedules }) => {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const meals = ["lunch", "earlyDinner", "dinner"];
  // get the max count
  const maxCount = Math.max(
    ...Object.values(schedules).map((schedule) =>
      Math.max(...Object.values(schedule))
    )
  );
  return (
    <ScheduleContainer>
      <table>
        <thead>
          <tr>
            <th></th>
            {days.map((day, index) => (
              <th key={index}>{day.slice(0, 3)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {meals.map((meal, index) => (
            <tr key={index}>
              <td>{meal}</td>
              {days.map((day, index) => (
                <Count
                  saturation={
                    schedules[day]?.[meal]
                      ? Math.round((schedules[day]?.[meal] / maxCount) * 100)
                      : 0
                  }
                >
                  {schedules[day]?.[meal] || 0}
                </Count>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </ScheduleContainer>
  );
};

interface DistributionProps {
  distribution: { [hour: number]: number };
}

//  // plot distribtion as a bar chart
// Y axis is the hour of day
// X axis is the count of orders
const DistributionContainer = styled(CenteredByColumn)`
  margin: 0px 0px 20px 0px;
  ${text12300}
`;
const DistributionHour = styled(Centered)`
  width: 20px;
  margin-right: 2px;
`;
const DistributionCount = styled(CenteredByColumn)`
  width: 20px;
  margin-right: 2px;
`;
const Number = styled(Text12300)`
  font-size: 12px;
`;
const Bar = styled.div<{ height?: number }>`
  width: 20px;
  height: ${(props) => (props.height ? `${props.height * 100}px` : "0px")};
  background-color: black;
`;

const Row = styled(Centered)`
  max-width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  &:last-child {
    border-top: 1px solid black;
  }
  /* overflow-x: auto; */
`;

// define distribution as DistributionProps
const Distribution = ({ distribution }: DistributionProps) => {
  // get the max count
  const maxCount = Math.max(...Object.values(distribution));
  return (
    <DistributionContainer>
      <TextTitle>ordering time</TextTitle>
      <Row>
        {Array.from({ length: 24 }, (_, i) => i).map((hour, index) => (
          <DistributionCount>
            <Number>{distribution[hour] || 0}</Number>
            <Bar
              height={
                distribution[hour] > 0 ? distribution[hour] / maxCount : 0
              }
            />
          </DistributionCount>
        ))}
      </Row>
      <Row>
        {Array.from({ length: 24 }, (_, i) => i).map((hour, index) => (
          <DistributionHour key={index}>{hour}</DistributionHour>
        ))}
      </Row>
    </DistributionContainer>
  );
};
// by month is displayed the same way as distribution
// but the data is by month
interface ByMonthProps {
  byMonth: { [month: string]: number };
}
const ByMonthContainer = styled(CenteredByColumn)`
  margin: 0px 0px 20px 0px;
  ${text12300}
  max-width: 100%;
  /* overflow-x: hidden; */
  align-items: flex-end;
`;
const ByMonthMonth = styled(Centered)`
  width: 20px;
  margin-right: 2px;
  font-size: 8px;
`;
const ByMonthCount = styled(CenteredByColumn)`
  width: 20px;
  margin-right: 2px;
`;
const ByMonth = ({ byMonth }: ByMonthProps) => {
  // get the max count
  const maxCount = Math.max(...Object.values(byMonth));
  // x-axis is the month, which is all strings that are key of byMonth, sort it
  const months = Object.keys(byMonth).sort();
  return (
    <ByMonthContainer>
      <TextTitle>ordering month</TextTitle>
      <Row>
        {months.map((month, index) => (
          <ByMonthCount>
            <Number>{byMonth[month] || 0}</Number>
            <Bar height={byMonth[month] > 0 ? byMonth[month] / maxCount : 0} />
          </ByMonthCount>
        ))}
      </Row>
      <Row>
        {months.map((month, index) => (
          <ByMonthMonth key={index}>{month}</ByMonthMonth>
        ))}
      </Row>
    </ByMonthContainer>
  );
};
const CustomerSummary = ({ customer, count }) => {
  return (
    <OrderContainer>
      <label>{customer}</label>
      <span>{count}</span>
    </OrderContainer>
  );
};
const ButtonContainer = styled(Centered)``;
const ButtonView = styled(TextTitle)<{ isSelected: boolean }>`
  ${text12300}
  color: black;
  border: 1px solid black;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
  ${(props) => props.isSelected && `background-color: black; color: white;`}
`;
export const PageRestaurantStats: React.FC<PageRestaurantStatsProps> =
  (): ReactElement => {
    const dispatch = useDispatch();
    const store = useStore();
    const [restaurant, setRestaurant] = useState(null);
    const [view, setView] = useState("dishes");
    const [dishes, setDishes] = useState([]);
    const [customers, setCustomers] = useState([]);
    const onSetView = (view, e) => {
      e.preventDefault();
      setView(view);
    };
    const onSelectRestaurant = (restaurantId: string) => {
      readRestaurantStats(restaurantId)(dispatch, store.getState).then(
        ({ dishes, orderers }) => {
          // setRestaurant(restaurant);
          setDishes(dishes);
          setCustomers(orderers);
          LOGGING && console.log("got orderers from server:", orderers);
        }
      );
    };

    return (
      <Container>
        <SearchRestaurants onSelect={onSelectRestaurant} />
        <ButtonContainer>
          <ButtonView
            onClick={(e) => onSetView("dishes", e)}
            isSelected={view === "dishes"}
          >
            by dish
          </ButtonView>
          <ButtonView
            onClick={(e) => onSetView("customers", e)}
            isSelected={view === "customers"}
          >
            by customer
          </ButtonView>
        </ButtonContainer>
        <Content>
          {restaurant && (
            <RestaurantProfile
              restaurant={restaurant}
              // orders={Object.keys(orders).length}
            />
          )}
          {view === "dishes" && (
            <DishList>
              {dishes.map((dish, index) => (
                <DishSummary key={index} dish={dish} />
              ))}
            </DishList>
          )}
          {view === "customers" && (
            <DishList>
              {Object.keys(customers).map((customer, index) => (
                <CustomerSummary
                  key={index}
                  customer={customer}
                  count={customers[customer]}
                />
              ))}
            </DishList>
          )}
        </Content>
      </Container>
    );
  };
