import React, { ReactElement, useCallback } from 'react';
import { useAsync } from 'react-async';
import { GetPastMealsForRestaurant } from 'src/api/meals';
import { Loading } from '..';
import { RestaurantPastMeals } from './RestaurantPastMeals';

interface RestaurantPastMealsContainerProps {
  restaurantId: string;
}

export const RestaurantPastMealsContainer: React.FC<RestaurantPastMealsContainerProps> = ({
  restaurantId,
}): ReactElement => {
  const fetch = useCallback(async ({ restaurantId }) => {
    return await GetPastMealsForRestaurant(restaurantId);
  }, []);

  const { data } = useAsync({ promiseFn: fetch, restaurantId });

  if (data == null) return <Loading />;

  return <RestaurantPastMeals meals={data.meals} />;
};
