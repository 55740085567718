import { SET_DRIVERS } from '../actionTypes';

const drivers = (state = [], action) => {
  switch (action.type) {
    case SET_DRIVERS:
      return [...action.payload];
    default:
      return state;
  }
};

export default drivers;
