import React, { ReactElement, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { SendAlert } from "src/api/referrals";
import { GiftCategoryName } from "src/constants";
import { RootState } from "src/store";
import { getGiftCardTemplates } from "src/store/actions";
import styled from "styled-components";
import { LOGGING, Loading, Back } from "../shared";
import { GiftTemplate } from "./components/customers/giftCards/GiftTemplate";
import { BreakPoint } from "./components/customers/mealMenu/MealHeader";
import {
  Centered,
  CenteredByColumn,
  CenteredByColumnLeft,
  ListTitle,
  Beige,
  text12300,
  textTitle,
  TextGrey,
} from "./components/Shared";
interface PageGiftsProps {}

const ContentContainer = styled(CenteredByColumn)`
  width: 100%;
  padding: 20px 0px 60px 0px;
  /* background-color: ${Beige}; */
  @media only screen and (min-width: ${BreakPoint}px) {
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-left: 10vw;
  }
`;

const CategoryContainer = styled(CenteredByColumnLeft)`
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0px 15px;
`;
const CategoryName = styled(Centered)`
  /* ${textTitle} */
  ${text12300}
  text-transform: uppercase;
  font-weight: 600;
  color: ${TextGrey};
  font-size: 16px;
  padding-left: 5px;
  /* margin-top: 10px; */
  /* margin-bottom: 5px; */
`;
const TemplateContainer = styled(Centered)`
  justify-content: flex-start;
  width: 100vw;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  margin-bottom: -4px;
  padding-right: 40px;
  a:last-child {
    margin-right: 100px !important;
  }
`;
const GiftCardCategory = ({ category, templates }) => (
  <CategoryContainer>
    <CategoryName>
      {GiftCategoryName[category] || category} ({templates.length})
    </CategoryName>
    <TemplateContainer>
      {templates?.map((template) => (
        // <CardThumbnail template={template} />
        <GiftTemplate background={template?.background} _id={template?._id} />
      ))}
    </TemplateContainer>
  </CategoryContainer>
);

export const PageGifts: React.FC<PageGiftsProps> = (): ReactElement => {
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const giftCardTemplates = useSelector(
    (state: RootState) => state.giftCardTemplates
  );
  const [alertSent, setAlertSent] = React.useState(false);
  const [loadedGiftCardTemplates, setLoadedGiftCardTemplates] =
    React.useState(false);

  LOGGING &&
    console.log("PageGifts rendering with1:", {
      loadedGiftCardTemplates,
      location,
      giftCardTemplates,
    });

  useEffect(() => {
    const fetchData = async () => {
      LOGGING &&
        console.log("fetchData called:", {
          loadedGiftCardTemplates,
          giftCardTemplates,
        });
      try {
        if (!loadedGiftCardTemplates) {
          LOGGING &&
            console.log("calling:", {
              loadedGiftCardTemplates,
              giftCardTemplates,
            });
          const result = await dispatch(getGiftCardTemplates());
          LOGGING && console.log("result:", result);
          setLoadedGiftCardTemplates(true);
        }
        if (!alertSent) {
          const name = `${currentUser?.user?.firstName} ${currentUser?.user?.lastName}`;
          SendAlert(name, `page gifts`);
          setAlertSent(true); // Set the state to indicate that the alert has been sent
        }
      } catch (error) {
        console.error("Error fetching gift card templates:", error);
      }
    };

    fetchData();
  }, [dispatch, giftCardTemplates]);
  if (!currentUser.isAuthenticated || !currentUser.user) {
    return (
      <Redirect
        to={{
          pathname: "/auth",
          state: {
            title: "To See Gifts",
            next: location.pathname,
          },
        }}
      />
    );
  }
  if (
    !giftCardTemplates ||
    Object.keys(giftCardTemplates).length === 0 ||
    Array.isArray(giftCardTemplates)
  )
    return <Loading />;

  LOGGING &&
    console.log(
      "pagegifts rendering2 with giftCardTemplates:",
      giftCardTemplates
    );
  LOGGING &&
    console.log(
      "pagegifts rendering2 with Object.keys(giftCardTemplates):",
      Object.keys(giftCardTemplates)
    );
  return (
    <div className="page" /*style={{ background: `${Beige}` }}*/>
      <ListTitle>
        <Back
          onClick={() => {
            history.push("/");
          }}
        />
        <span>Gift Cards</span>
      </ListTitle>

      <ContentContainer>
        {Object.keys(giftCardTemplates).map((category) => {
          LOGGING && console.log("GiftCardCategory looping category", category);
          LOGGING &&
            console.log(
              "GiftCardCategory looping giftCardTemplates[category]",
              giftCardTemplates[category]
            );
          return (
            <GiftCardCategory
              category={category}
              templates={giftCardTemplates[category]}
            />
          );
        })}
      </ContentContainer>
    </div>
  );
};
