import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resetPassword } from '../../store/actions';
import { Loading, LOGGING, ResetPassword } from './';

class PageResetPassword extends Component {
  constructor(props) {
    super();
    console.log(props);
    const query = new URLSearchParams(props.location.search);
    this.state = {
      user_id: query.get('user_id'),
      token: query.get('token'),
      password: '',
      confirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
      loading: false,
      isChanged: false,
      submitted: false,
      errorMessage: '',
    };
    this.handleEdit = this.handleEdit.bind(this);
    this.handleToggleShownPassword = this.handleToggleShownPassword.bind(this);
    this.handleToggleShownConfirmPassword = this.handleToggleShownConfirmPassword.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
  }

  handleToggleShownPassword(e) {
    e.preventDefault();
    this.setState({ showPassword: !this.state.showPassword });
  }

  handleToggleShownConfirmPassword(e) {
    e.preventDefault();
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  }

  handleEdit(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  handleResetPassword(e) {
    e.preventDefault();
    console.log('handleSubmit', e);
    this.setState({ errorMessage: '' });
    if (this.state.password === '') {
      this.setState({ errorMessage: 'Password can not be empty' });
      return false;
    }

    if (this.state.password !== this.state.confirmPassword) {
      this.setState({ errorMessage: 'Confirm password does not match' });
      return false;
    }

    this.setState({ loading: true }, () => {
      const { user_id, token, password } = this.state;
      this.props
        .resetPassword(user_id, token, password)
        .then(() => {
          this.setState({
            isChanged: true,
            loading: false,
            submitted: true,
          });
          setTimeout(() => {
            this.props.history.push('/');
          }, 1000);
        })
        .catch((error) => {
          this.setState({
            isChanged: false,
            loading: false,
            submitted: true,
          });
          LOGGING && console.log('forgetPassword error:', error);
        });
    });
  }

  render() {
    const {
      submitted,
      isChanged,
      password,
      confirmPassword,
      showPassword,
      showConfirmPassword,
      errorMessage,
      loading,
    } = this.state;
    LOGGING &&
      console.log('PageResetPassword got', {
        state: this.state,
        props: this.props,
      });
    return (
      <div className="page new-entity">
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {loading && <Loading />}
        <ResetPassword
          submitted={submitted}
          isChanged={isChanged}
          password={password}
          confirmPassword={confirmPassword}
          showPassword={showPassword}
          showConfirmPassword={showConfirmPassword}
          onToggleShownPassword={this.handleToggleShownPassword}
          onToggleShownConfirmPassword={this.handleToggleShownConfirmPassword}
          onEdit={this.handleEdit}
          onResetPassword={this.handleResetPassword}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log('PageResetPassword got redux state:', state);
  return {
    currentUser: state.currentUser,
  };
}
export default connect(mapStateToProps, { resetPassword })(PageResetPassword);
