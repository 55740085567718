import "./ReferralCodeEntry.scss";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import {
  faEnvelopeOpenText,
  faHeartCircle,
  faGift,
} from "@fortawesome/pro-duotone-svg-icons";
import { faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { ReferralCodeSlogan } from "./";

const OrderingWithCode = ({ code, hasError }) => (
  <div
    className={`referral-code-entry ordering-with-code ${
      hasError ? "has-error" : ""
    }`}
  >
    <FontAwesomeIcon icon={faGift} />
    <span>
      {code.discountType === "joint"
        ? `${code.discountPercentage}% off (up to $${code.discountDollar})`
        : code.discountDollar > 0
        ? `Enjoy $${code.discountDollar} off `
        : "Treat "}
      {code.user
        ? `from ${code.user.firstName} & Foodie`
        : code.code
        ? `with ${code.code}!`
        : `with ${code}`}
    </span>
  </div>
);

export const CreateReferralCode = () => (
  <Link
    to="/referralCode"
    className={`referral-code-entry create-referral-code`}
  >
    <div className="icon-wrapper">
      <FontAwesomeIcon icon={faEnvelopeOpenText} />
      <FontAwesomeIcon icon={faHeartCircle} />
    </div>
    <span>{ReferralCodeSlogan}</span>
  </Link>
);

export const ReferralCodeError = ({
  code,
  error,
  onRemoveCoupon,
  stickyError,
}) => (
  <div
    className={`referral-code-error ${
      Boolean(code) ? "with-referral-code" : "without-referral-code"
    }`}
  >
    <FontAwesomeIcon icon={faExclamationTriangle} />
    <span>{error}</span>
    {stickyError ? null : (
      <button onClick={onRemoveCoupon}>
        <FontAwesomeIcon icon={faTimesCircle} />
      </button>
    )}
  </div>
);

export default OrderingWithCode;
