import { LOGGING } from "../../constants";
import { LOAD_TOP_BLOCKS } from "../actionTypes";
import { apiCall } from "../api";
export const setTopBlocks = (topBlocks) => ({
  type: LOAD_TOP_BLOCKS,
  topBlocks,
});
export const readTopBlocks = () => {
  console.log("readTopBlocks called");
  return (dispatch, getState) => {
    return apiCall(
      "GET",
      `/meals/topBlocks`,
      { days: 3 },
      getState().currentUser
    )
      .then((topBlocks) => {
        LOGGING && console.log("readTopBlocks got", topBlocks);
        dispatch(setTopBlocks(topBlocks));
      })
      .catch((err) => {
        LOGGING && console.log("readTopBlocks got err", err);
        throw err;
      });
  };
};
