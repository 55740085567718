import { LOGGING, Select, MustSelect } from "./";
const DeliveryZoneByCitySelector = ({
  deliveryZoneByCity,
  onSelectCity,
  selectedCity,
  showOptions,
  onToggleShowOptions,
}) => {
  const cities = deliveryZoneByCity.map((d) => d.city);
  const cityIndices = deliveryZoneByCity.map((d, i) => i);
  // const selected = deliveryZoneByCity.find((opt) => opt?.city === selectedCity) || [];
  const selectedCityIndex = cities.findIndex((c) => c === selectedCity);
  LOGGING &&
    console.log("DeliveryZoneByCitySelector rendering with:", {
      deliveryZoneByCity,
      selectedCity,
      cities,
      showOptions,
      cityIndices,
      selectedCityIndex,
      // selected,
    });

  return (
    // <Select
    //   options={deliveryZoneByCity}
    //   onChange={onSelectCity}
    //   labelField="city"
    //   valueField="city"
    //   placeholder={'Select City To View Restaurant'}
    //   // values={selected}
    // />
    <Select
      selected={selectedCityIndex}
      onSelect={onSelectCity}
      options={cityIndices}
      optionWords={cities}
      showOptions={showOptions}
      onToggleShowOptions={onToggleShowOptions}
      onHideOptions={onToggleShowOptions}
      className={"view-mode selected"}
    />
  );
};

export const DeliveryZoneByCitySelectorUnselected = ({
  deliveryZoneByCity,
  onSelectCity,
}) => {
  LOGGING &&
    console.log("DeliveryZoneByCitySelectorUnselected rendering with:", {
      deliveryZoneByCity,
    });

  return (
    <MustSelect
      title={"select city to view menu"}
      subTitle={"You can switch city any time later."}
      options={Object.keys(deliveryZoneByCity).sort((a, b) =>
        a.localeCompare(b)
      )}
      onSelect={onSelectCity}
    />
  );
};

export default DeliveryZoneByCitySelector;
