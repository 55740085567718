import { HTMLProps } from 'react';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Flex } from '../Layout';

type ButtonProps = {
  variant?: 'primary' | 'secondary' | 'link';
  size?: 'small' | 'normal' | 'large' | 'medium';
  icon?: any;
  loading?: boolean;
} & Omit<HTMLProps<HTMLButtonElement>, 'size'>;

export const Button = (props: ButtonProps) => {
  const { icon, loading, children, disabled, ...rest } = props;
  return (
    <Container disabled={disabled || loading} {...(rest as any)}>
      <Flex justify="center" align="center" gap={10}>
        {icon && !loading && <FontAwesomeIcon icon={icon} />}
        {loading && <FontAwesomeIcon icon={faSpinnerThird} spin />}
        <Children>{children}</Children>
      </Flex>
    </Container>
  );
};

const Container = styled.button<ButtonProps>`
  font-weight: 500;
  letter-spacing: 0.6px;
  box-shadow: none;
  box-sizing: border-box;

  ${({ variant = 'primary' }) => {
    switch (variant) {
      case 'primary':
        return `
          color: white;
          background: black;
          opacity: 0.8;

          &:disabled {
            background: #888888;
          }
        `;
      case 'secondary':
        return `
          color: black;
          border: solid 1px black;
          opacity: 0.8;

          &:disabled {
            border-color: #888888;
            color: #888888;
          }
        `;

      case 'link':
        return `
          color: black;
          border-bottom: solid 1px black;
          padding: 0 5px !important;
          opacity: 0.8;

          &:disabled {
            border-color: #888888;
            color: #888888;
          }
        `;
    }
  }}

  ${({ size = 'normal' }) => {
    switch (size) {
      case 'small':
        return `
          font-size: 11px;
          padding: 0px 24px;
          height: 28px;
       `;
      case 'normal':
        return `
          font-size: 13px;
          padding: 0px 30px;
          height: 36px;
        `;
      case 'medium':
        return `
            font-size: 13px;
            padding: 0px 30px;
            height: 44px;
            text-transform: uppercase;
          `;
      case 'large':
        return `
          font-size: 15px;
          padding: 0px 36px;
          height: 44px;
        `;
    }
  }}
`;

const Children = styled.div`
  width: 100%;
`;
