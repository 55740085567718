import { SET_GIFT_CARD, SET_GIFT_CARDS } from '../actionTypes';

const INITIALIZE_STATE = {
  giftCard: null,
  giftCards: [],
};

const giftCard = (state = { INITIALIZE_STATE }, action) => {
  switch (action.type) {
    case SET_GIFT_CARD:
      return { ...state, giftCard: action.payload };
    case SET_GIFT_CARDS:
      return { ...state, giftCards: action.payload };
    default:
      return state;
  }
};

export default giftCard;
