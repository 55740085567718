import React, { Component } from "react";
import creditCardType from "credit-card-type";
import { GoogleApiWrapper } from "google-maps-react";
import { connect } from "react-redux";
import validator from "validator";
import { LOGGING, UserSetting, NewCard, SupportedDeliveryCities } from ".";
import { saveSetting, deleteCardFromSetting } from "../../store/actions";

class PageSetting extends Component {
  constructor(props) {
    super();
    const { currentUser } = props;
    if (typeof currentUser.user._id == "undefined") {
      props.history.push({
        pathname: "/signin",
        state: { next: "/setting" },
      });
    }
    LOGGING &&
      console.log("PageSetting constructor got currentUser:", currentUser);
    const { user } = currentUser;
    const {
      stripeInfo,
      firstName,
      lastName,
      deliveryInfo,
      email,
      phone,
      facebookId,
      googleId,
      address,
    } = user;
    let receiveMarketingEmail = user.receiveMarketingEmail;
    if (receiveMarketingEmail === undefined) {
      receiveMarketingEmail = true;
    }
    const existingCard =
      stripeInfo && stripeInfo.card ? { ...stripeInfo.card } : null;
    this.state = {
      loading: false,
      showCardAction: false,
      error: {},
      existingCard,
      addNewCard: false,
      receiveMarketingEmail: receiveMarketingEmail,
      newCard: NewCard,
      cardType: null,
      deliveryInfo: deliveryInfo || {
        phone: phone || "",
        name: `${firstName} ${lastName}`,
        address: address || "",
        address_room_info: "",
        address_supported_for_delivery: false,
        address_validated: false,
        email: email || "",
        note: "",
        ring_doorbell: false,
      },
      hasPassword: !facebookId && !googleId,
    };
    this.handleHome = this.handleHome.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeNumber = this.handleChangeNumber.bind(this);
    this.handleEditAddress = this.handleEditAddress.bind(this);
    this.handleSelectAddress = this.handleSelectAddress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleToggleShowCardAction =
      this.handleToggleShowCardAction.bind(this);
    this.handleToggleRingBell = this.handleToggleRingBell.bind(this);
    this.handleDeleteCard = this.handleDeleteCard.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleAddNewCard = this.handleAddNewCard.bind(this);
    this.handleCancelNewCard = this.handleCancelNewCard.bind(this);
    this.handleChangeReceiveMarketingEmail =
      this.handleChangeReceiveMarketingEmail.bind(this);
  }

  handleEditAddress(address) {
    const { deliveryInfo } = this.state;
    LOGGING &&
      console.log("PageSetting handleEditAddress called with ", address);
    const { error } = this.state;
    const updatedError = error && "address" === error.param ? {} : error;
    this.setState({
      deliveryInfo: {
        ...deliveryInfo,
        address,
        address_supported_for_delivery: false,
        address_validated: false,
      },
      error: updatedError,
    });
  }

  handleSelectAddress(address) {
    LOGGING &&
      console.log(
        "PageSetting called handleSelectAddress with address:",
        address
      );
    if (address) {
      const city = address.gmaps.address_components.find((c) =>
        c.types.includes("locality")
      ).short_name;
      const number = address.gmaps.address_components.find((c) =>
        c.types.includes("street_number")
      );
      const number_str = typeof number === "undefined" ? "" : number.short_name;
      const street = address.gmaps.address_components.find(
        (c) => c.types.includes("route") || c.types.includes("premise")
      ).short_name;
      const state = address.gmaps.address_components.find((c) =>
        c.types.includes("administrative_area_level_1")
      ).short_name;
      const zip = address.gmaps.address_components.find((c) =>
        c.types.includes("postal_code")
      ).short_name;
      const resultAddress = `${number_str} ${street}, ${city}, ${state} ${zip}`;
      // const placeId = address.gmaps.place_id;
      LOGGING && console.log("selected: ", resultAddress);
      const { deliveryInfo } = this.state;

      this.setState({
        deliveryInfo: {
          ...deliveryInfo,
          address: resultAddress,
          address_supported_for_delivery:
            SupportedDeliveryCities.indexOf(city) !== -1,
          address_validated: true,
        },
      });
    }
  }
  handleAddNewCard() {
    this.setState({ addNewCard: true });
  }
  handleCancelNewCard() {
    this.setState({ addNewCard: false });
  }
  handleClose() {
    LOGGING && console.log("PageSETTING handleClose");
    this.props.history.goBack();
  }
  handleDeleteCard(e) {
    e.preventDefault();
    this.setState({ loading: true, showCardAction: false });
    this.props.deleteCardFromSetting().then(() => {
      this.setState({
        loading: false,
        existingCard: null,
      });
    });
  }
  handleToggleShowCardAction(e) {
    e.preventDefault();
    const { showCardAction } = this.state;
    this.setState({ showCardAction: !showCardAction });
  }

  handleToggleRingBell(e) {
    LOGGING && console.log("handleToggleRingBell called");
    e.preventDefault();
    const { ring_doorbell } = this.state.deliveryInfo;
    LOGGING &&
      console.log("handleToggleRingBell called with ringBell:", ring_doorbell);
    if (ring_doorbell == null) {
      const { deliveryInfo } = this.state;
      this.setState({
        deliveryInfo: {
          ...deliveryInfo,
          ring_doorbell: true,
        },
      });
    } else {
      const { deliveryInfo } = this.state;
      this.setState({
        deliveryInfo: {
          ...deliveryInfo,
          ring_doorbell: !ring_doorbell,
        },
      });
    }
  }

  handleHome() {
    LOGGING && console.log("PageSetting handleHome");
    this.props.history.push("/");
  }
  handleChange(sectionName, e) {
    e.preventDefault();
    const { error } = this.state;
    const updatedField = { [e.target.name]: e.target.value };
    const oldFields = this.state[sectionName];
    LOGGING &&
      console.log("handleChange with:", {
        sectionName,
        oldFields,
        updatedField,
      });
    const updatedError = error && e.target.name === error.param ? {} : error;

    this.setState({
      [sectionName]: {
        ...oldFields,
        ...updatedField,
      },
      error: updatedError,
    });
  }

  handleChangeNumber = (sectionName, field, values) => {
    const { error } = this.state;
    const updatedField = { [field]: field === "phone" ? values : values.value };
    const oldFields = this.state[sectionName];
    LOGGING &&
      console.log("handleChangeNumber with:", {
        sectionName,
        error,
        field,
        values,
        updatedField,
        oldFields,
      });
    const updatedError = error && field === error.param ? {} : error;
    LOGGING && console.log("handleChangeNumber updatedError:", updatedError);
    if (field === "number") {
      const { lengths, gaps, code, type, niceType } = creditCardType(
        values.value
      )[0];

      let cardFormat = lengths.length === 1 ? Array(lengths[0]).fill("#") : [];
      gaps.reverse().forEach((position) => cardFormat.splice(position, 0, "-"));
      LOGGING &&
        console.log("handleChangeNumber creditCardType:", {
          lengths,
          gaps,
          code,
          type,
          niceType,
        });
      this.setState({
        [sectionName]: { ...oldFields, ...updatedField },
        error: updatedError,
        cardType: {
          format: null, //cardFormat.join(""),
          length: lengths.length === 1 ? lengths[0] : null,
          code: { ...code, format: Array(code.size).fill("#").join("") },
          type: values.value.length === 0 ? null : type,
          niceType: values.value.length === 0 ? null : niceType,
        },
      });
    } else {
      this.setState({
        [sectionName]: { ...oldFields, ...updatedField },
        error: updatedError,
      });
    }
  };

  handleChangeReceiveMarketingEmail(e) {
    this.setState({ receiveMarketingEmail: e.target.checked });
  }

  handleSubmit(e) {
    e.preventDefault();
    const {
      addNewCard,
      newCard,
      deliveryInfo,
      cardType,
      existingCard,
      receiveMarketingEmail,
    } = this.state;
    LOGGING &&
      console.log("handleSubmit called with: ", {
        addNewCard,
        newCard,
        deliveryInfo,
        cardType,
        existingCard,
        receiveMarketingEmail,
      });
    const {
      phone,
      address,
      address_room_info,
      name,
      email,
      address_supported_for_delivery,
      address_validated,
      note,
    } = deliveryInfo;
    if (typeof name === "undefined" || name.length === 0) {
      this.setState({
        error: { param: "name", message: "name can't be empty" },
      });
      return;
    }

    if (typeof email === "undefined" || email.length === 0) {
      this.setState({
        error: { param: "email", message: "email can't be empty" },
      });
      return;
    }

    if (!validator.isEmail(email)) {
      this.setState({
        error: { param: "email", message: "Please provide a valid email" },
      });
      return;
    }

    if (typeof phone === "undefined") {
      this.setState({
        error: { param: "phone", message: "phone can't be empty" },
      });
      return;
    }
    if (!validator.isMobilePhone(phone, ["en-US"])) {
      this.setState({
        error: { param: "phone", message: "Please provide a valid phone" },
      });
      return;
    }

    if (typeof address !== "undefined" && address.length > 0) {
      if (!address_validated) {
        this.setState({
          error: {
            param: "address",
            message:
              "Please select an address from the validated address list.",
          },
        });
        return;
      }
    }

    if (addNewCard) {
      const { number, cvc, exp_month, exp_year } = newCard;
      if (cardType && number.length < cardType.length) {
        this.setState({
          error: {
            param: "number",
            message: `${cardType.niceType} card has to be ${cardType.length}-digit`,
          },
        });
        return;
      }

      if (cardType && cvc.length < cardType.code.size) {
        this.setState({
          error: {
            param: "cvc",
            message: `${cardType.code.name} is ${cardType.code.size}-digit`,
          },
        });
        return;
      }
      if (
        exp_month.length < 2 ||
        Number(exp_month) > 12 ||
        Number(exp_month) < 1
      ) {
        this.setState({
          error: { param: "exp_month", message: "exp_month is invalid" },
        });
        return;
      }
      if (exp_year.length < 2 || 2000 + Number(exp_year) < 2020) {
        this.setState({
          error: { param: "exp_year", message: "exp_year is invalid" },
        });
        return;
      }
    }
    this.setState({ loading: true });
    LOGGING &&
      console.log(
        "saveSetting about to call: ",
        {
          existingCard,
          address,
          address_room_info,
          address_supported_for_delivery,
          address_validated,
          name,
          phone,
          email,
          note,
        },
        receiveMarketingEmail
      );
    this.props
      .saveSetting(
        addNewCard ? newCard : null,
        {
          address,
          address_room_info,
          address_supported_for_delivery,
          address_validated,
          phone,
          name,
          email,
          note,
        },
        receiveMarketingEmail
      )
      .then(() => {
        this.setState({ loading: false }, () => {
          this.props.history.push("/");
        });
      })
      .catch((error) => {
        LOGGING && console.log("saveSetting returned with error:", error);
        this.setState({ error, loading: false });
      });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      newCard,
      loading,
      error,
      existingCard,
      deliveryInfo,
      showCardAction,
      cardType,
      addNewCard,
      hasPassword,
    } = this.state;
    LOGGING &&
      console.log("PageSetting rendering with", {
        props: this.props,
        state: this.state,
      });
    return (
      <div className="page">
        {/* <Navbar /> */}
        <UserSetting
          loading={loading}
          existingCard={existingCard}
          hasPassword={hasPassword}
          addNewCard={addNewCard}
          onAddNewCard={this.handleAddNewCard}
          onCancelNewCard={this.handleCancelNewCard}
          newCard={newCard}
          onSubmit={this.handleSubmit}
          onChange={this.handleChange}
          onChangeNumber={this.handleChangeNumber}
          onToggleRingBell={this.handleToggleRingBell}
          receiveMarketingEmail={this.state.receiveMarketingEmail}
          onChangeReceiveMarketingEmail={this.handleChangeReceiveMarketingEmail}
          onEditAddress={this.handleEditAddress}
          onSelectAddress={this.handleSelectAddress}
          error={error}
          deliveryInfo={deliveryInfo}
          showCardAction={showCardAction}
          onToggleShowCardAction={this.handleToggleShowCardAction}
          onDeleteCard={this.handleDeleteCard}
          cardType={cardType}
          onBack={this.handleClose}
          onHome={this.handleHome}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log("PageSetting got redux state:", state);
  return {
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, { saveSetting, deleteCardFromSetting })(
  GoogleApiWrapper({
    apiKey: "AIzaSyBjfTfgJdRah5wXvVj8U7fH4ACoqKry9HI",
  })(PageSetting)
);
