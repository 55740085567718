import { useEffect } from "react";
import { useSelector } from "react-redux";
import { FoodieEvent, FoodieSource, TrackEvent } from "src/api/tracker";
import { RootState } from "src/store";

export const useTrackEventEffect = (
  event: FoodieEvent,
  source: FoodieSource
): void => {
  const currentUser = useSelector((state: RootState) => state.currentUser);

  useEffect(() => {
    TrackEvent(event, null, source, currentUser?.user?._id, null, 0);
  }, [currentUser?.user?._id, event, source]);
};
