import { BREAK_POINTS } from 'src/constants';
import styled from 'styled-components';

type CellResponsiveProps = {
  span?: number;
  rows?: number;
  justify?: 'start' | 'end' | 'center' | 'stretch';
  align?: 'start' | 'end' | 'center' | 'stretch';
  column?: string;
};

type CellProps = CellResponsiveProps & {
  xs?: CellResponsiveProps;
  sm?: CellResponsiveProps;
  md?: CellResponsiveProps;
  lg?: CellResponsiveProps;
  xl?: CellResponsiveProps;
  xxl?: CellResponsiveProps;
};

const parseResponsiveProps = (responsiveProps: CellResponsiveProps) => {
  const { span, rows, justify, align, column } = responsiveProps;
  return `
  ${span ? `grid-column-end: span ${span};` : ''}
  ${rows ? `grid-row-end: span ${rows};` : ''}
  ${justify ? `justify-self: ${justify};` : ''}
  ${align ? `align-self: ${align};` : ''}
  ${column ? `grid-column: ${column};` : ''}
`;
};

export const Cell = styled.div<CellProps>`
  ${({ xs, sm, md, lg, xl, xxl, ...commonResponsiveProps }) => `
    ${parseResponsiveProps(commonResponsiveProps)}

    @media (min-width: ${BREAK_POINTS.xs}px) {
      ${xs ? parseResponsiveProps(xs) : ''}
    }
    @media (min-width: ${BREAK_POINTS.sm}px) {
      ${sm ? parseResponsiveProps(sm) : ''}
    }
    @media (min-width: ${BREAK_POINTS.md}px) {
      ${md ? parseResponsiveProps(md) : ''}
    }
    @media (min-width: ${BREAK_POINTS.lg}px) {
      ${lg ? parseResponsiveProps(lg) : ''}
    }
    @media (min-width: ${BREAK_POINTS.xl}px) {
      ${xl ? parseResponsiveProps(xl) : ''}
    }
    @media (min-width: ${BREAK_POINTS.xxl}px) {
      ${xxl ? parseResponsiveProps(xxl) : ''}
    }
`};
`;
