import React from 'react';
import './Announcement.scss';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { ReferralSlogan } from './';

const Announcement = ({ doubleLine }) => (
  <Link to="/referrals" className={`announcement ${doubleLine ? 'double-line' : ''}`}>
    <FontAwesomeIcon className="heart" icon={faHeart} />
    {doubleLine ? (
      ReferralSlogan.split(',').map((line) => <span>{line}</span>)
    ) : (
      <span>{ReferralSlogan}</span>
    )}

    {doubleLine ? (
      <div className="open-announcement">invite</div>
    ) : (
      <FontAwesomeIcon className="open-announcement" icon={faChevronDown} />
    )}
  </Link>
);

export default Announcement;
