import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import toast, { Toaster } from "react-hot-toast";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { DishType } from "src/api/dish";
import { Button } from "src/components/Button";
import { Flex } from "src/components/Layout";
import { Modal } from "src/components/Modal";
import {
  addDishQuantityToCart,
  getGoodKeyFromCart,
  orderGoodsToCartMapGoods,
  removeDishQuantityFromCart,
} from "src/util/cart";
import {
  LOGGING,
  OrderDetails,
  OrderFormCheckout,
  OrderFormWithMenu,
  CheckoutBanner,
} from ".";
import {
  readOrder,
  cancelOrder,
  giveOrderFeedback,
  saveOrderFeedbackComment,
  changePaidOrder,
  fetchMeal,
  applyCouponCode,
} from "../../store/actions";
import { calcDueServer, orderGoodsToCartGoods } from "../../util/order";
import { ConfirmAlert, Loading } from "../shared";
import { PaypalButton } from "./components";
import { DishChooserContainer } from "./components/dish/DishChooserContainer";

class PageOrderDetails extends Component {
  constructor() {
    super();
    LOGGING && console.log("PageOrderDetails constructor");
    this.state = {
      loading: true,
      order: null,
      editedComments: new Map(),
      orderBeingEdittedInForm: null,
      orderBeingEdittedInMenu: null,
      showConfirmation: false,
      showPaypalConfirmation: false,
      confirmedDish: "",
      confirmedQuantity: 1,
      couponCodeError: "",
      selectedDish: null,
      from: "",
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleCancelOrder = this.handleCancelOrder.bind(this);

    this.handleUp = this.handleUp.bind(this);
    this.handleDown = this.handleDown.bind(this);

    this.handleDiscardFormEdit = this.handleDiscardFormEdit.bind(this);

    this.handleEditOrder = this.handleEditOrder.bind(this);
    this.handleCloseSelections = this.handleCloseSelections.bind(this);

    this.handleChangeDishQuantity = this.handleChangeDishQuantity.bind(this);
    this.handleChangeTips = this.handleChangeTips.bind(this);
    this.handleEditDishComment = this.handleEditDishComment.bind(this);
    this.handleEditComment = this.handleEditComment.bind(this);
    this.handleSaveOrder = this.handleSaveOrder.bind(this);
    this.handleConfirmSaveOrder = this.handleConfirmSaveOrder.bind(this);
    this.handleAddGoodsToCart = this.handleAddGoodsToCart.bind(this);
    this.handleShowMenu = this.handleShowMenu.bind(this);
    this.handleDiscardMenuEdit = this.handleDiscardMenuEdit.bind(this);
    this.handleConfirmDiscardMenuEdit =
      this.handleConfirmDiscardMenuEdit.bind(this);
    this.handleConfirmMenuEdit = this.handleConfirmMenuEdit.bind(this);
    this.handleHideConfirmation = this.handleHideConfirmation.bind(this);
    this.handleApplyCouponCode = this.handleApplyCouponCode.bind(this);
    this.handleHidePaypalConfirmation =
      this.handleHidePaypalConfirmation.bind(this);
    this.handlePaypalCapturing = this.handlePaypalCapturing.bind(this);
    this.handlePaypalAuthorize = this.handlePaypalAuthorize.bind(this);
    this.handleSaveOrderContracts = this.handleSaveOrderContracts.bind(this);
    this.handleGoToCategory = this.handleGoToCategory.bind(this);
    this.handleAddDishToCart = this.handleAddDishToCart.bind(this);
    this.handleRemoveDishFromCart = this.handleRemoveDishFromCart.bind(this);
    this.handleStartMembership = this.handleStartMembership.bind(this);
  }

  handleStartMembership() {
    this.setState({ loading: true }, () => {
      this.props.startMembership().then(() => {
        this.setState({ loading: false });
      });
    });
  }

  handleGoToCategory(categoryRef, e) {
    LOGGING &&
      console.log(
        "handleGoToCategory called with: ",
        categoryRef.current.offsetTop
      );
    e.preventDefault();
    window.scrollTo({
      top: categoryRef.current.offsetTop - 150,
      left: 0,
      behavior: "smooth",
    });
  }

  handleHidePaypalConfirmation() {
    this.setState({ showPaypalConfirmation: false });
  }

  handlePaypalCapturing() {
    this.setState({ loading: true, showPaypalConfirmation: false });
  }

  handlePaypalAuthorize(captureId) {
    const { orderBeingEdittedInForm } = this.state;
    this.setState(
      {
        orderBeingEdittedInForm: {
          ...orderBeingEdittedInForm,
          paypalCaptureId: captureId,
        },
      },
      () => {
        this.handleConfirmSaveOrder();
      }
    );
  }

  handleHideConfirmation(e) {
    e.preventDefault();
    this.setState({ showConfirmation: false });
  }

  handleShowMenu(e) {
    e.preventDefault();
    const { orderBeingEdittedInForm } = this.state;
    this.setState(
      {
        orderBeingEdittedInMenu: { ...orderBeingEdittedInForm },
        orderBeingEdittedInForm: null,
      },
      () => {
        window.scrollTo(0, 0);
      }
    );
  }

  handleConfirmDiscardMenuEdit() {
    LOGGING && console.log("discarding edits..");
    this.setState({ orderBeingEdittedInMenu: null }, () => {
      window.scrollTo(0, 0);
    });
  }

  handleDiscardMenuEdit(e) {
    e.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmAlert
            onConfirm={this.handleConfirmDiscardMenuEdit}
            onClose={onClose}
            message="Do you want to discard your edits?"
          />
        );
      },
    });
  }

  handleConfirmMenuEdit(e) {
    e.preventDefault();
    const { orderBeingEdittedInMenu } = this.state;
    this.setState(
      {
        orderBeingEdittedInForm: { ...orderBeingEdittedInMenu },
        orderBeingEdittedInMenu: null,
      },
      () => {
        window.scrollTo(0, 0);
        const { currentUser } = this.props;
        const { orderBeingEdittedInForm, order } = this.state;
        const { credit: originalCredit } = order;
        const { goods } = orderBeingEdittedInForm;
        const updatedGoods = goods.filter((g) => g.quantity > 0);
        const updatedClientSideGoods = orderGoodsToCartGoods(goods);

        calcDueServer(
          currentUser,
          {
            ...orderBeingEdittedInForm,
            goods: updatedGoods,
            clientSideGoods: updatedClientSideGoods,
            tips: null,
          },
          originalCredit
        ).then((updatedOrder) => {
          this.setState({
            orderBeingEdittedInForm: updatedOrder,
          });
        });
      }
    );
  }

  async handleApplyCouponCode(code) {
    const { orderBeingEdittedInForm, order } = this.state;
    const { credit: originalCredit } = order;
    this.props
      .applyCouponCode(
        orderBeingEdittedInForm,
        code,
        "order",
        false,
        originalCredit
      )
      .then((updateOrder) => {
        this.setState({
          couponCodeError: "",
          orderBeingEdittedInForm: updateOrder,
        });
      })
      .catch((err) => {
        this.setState({
          couponCodeError: err.message,
        });
      });
  }

  handleDiscardFormEdit(e) {
    e.preventDefault();
    this.setState({ orderBeingEdittedInForm: null }, () => {
      window.scrollTo(0, 0);
    });
  }

  handleCloseSelections() {
    this.setState({
      selectedDish: null,
    });
  }

  // goods are Good in PureDishChooser
  handleAddGoodsToCart(goodsToAdd) {
    LOGGING && console.log("handleAddGoodsToCart called with: ", goodsToAdd);
    const { orderBeingEdittedInMenu } = this.state;
    const { goods } = orderBeingEdittedInMenu;

    const updatedGoodsAsCartMap = goodsToAdd.reduce((currGoods, goodToAdd) => {
      return addDishQuantityToCart({
        goods: currGoods,
        dish: goodToAdd.dish,
        selections: goodToAdd.selections,
        price: goodToAdd.price,
        quantity: goodToAdd.quantity,
        comment: goodToAdd.comment,
      });
    }, orderGoodsToCartMapGoods(goods));
    this.updateOrderEdittedInMenuWithGoodsAsCartMap(updatedGoodsAsCartMap);
  }

  handleChangeDishQuantity(fromMenu, dish, change, e) {
    e?.preventDefault();
    LOGGING &&
      console.log("handleChangeDishQuantity called with: ", {
        fromMenu,
        dish,
        change,
      });
    if (fromMenu) {
      this.setState({
        selectedDish: dish,
      });
    } else {
      const { currentUser } = this.props;
      const { orderBeingEdittedInForm, order } = this.state;
      const { credit: originalCredit } = order;
      const { goods } = orderBeingEdittedInForm;
      const dishIndex = dish;
      goods[dishIndex].quantity += change;
      const updatedGoods = goods.filter((g) => g.quantity > 0);
      const updatedClientSideGoods = orderGoodsToCartGoods(updatedGoods);
      calcDueServer(
        currentUser,
        {
          ...orderBeingEdittedInForm,
          goods: updatedGoods,
          clientSideGoods: updatedClientSideGoods,
        },
        originalCredit
      ).then((updatedOrder) => {
        this.setState({
          orderBeingEdittedInForm: updatedOrder,
        });
      });
    }
  }

  handleChangeTips(tips, tipRate, tipCustom) {
    LOGGING &&
      console.log("handleChangeTips called with: ", {
        tips,
        tipRate,
        tipCustom,
      });
    const { currentUser } = this.props;
    const { orderBeingEdittedInForm, order } = this.state;
    const { credit: originalCredit } = order;
    calcDueServer(
      currentUser,
      {
        ...orderBeingEdittedInForm,
        tips,
        tipRate,
        tipCustom,
      },
      originalCredit
    ).then((updatedOrder) => {
      this.setState({
        orderBeingEdittedInForm: updatedOrder,
      });
    });
  }

  handleEditDishComment(dishIndex, e) {
    const comment = e.target.value;
    LOGGING &&
      console.log("handleEditDishComment called with: ", {
        dishIndex,
        comment,
      });
    const { orderBeingEdittedInForm } = this.state;
    const { goods } = orderBeingEdittedInForm;
    const updatedGoods = goods.map((g, index) =>
      index === dishIndex ? { ...g, comment } : { ...g }
    );
    const updatedOrder = {
      ...orderBeingEdittedInForm,
      goods: updatedGoods,
    };
    this.setState({ orderBeingEdittedInForm: updatedOrder });
  }

  handleEditOrder() {
    const { order } = this.state;
    const orderBeingEdittedInMenu = { ...order };
    this.setState({ orderBeingEdittedInMenu }, () => {
      window.scrollTo(0, 0);
    });
  }

  handleSaveOrderContracts(order) {
    this.setState({ loading: true }, () => {
      this.props
        .changePaidOrder(order)
        .then(() => {
          const { order } = this.state;
          this.props.history.push({
            pathname: "/orders",
            state: { gotoOrderId: order._id },
          });
        })
        .catch((err) => {
          toast.error(err.message);
        });
    });
  }

  handleSaveOrder(message, e) {
    LOGGING && console.log("handleSaveOrder called with:", message);
    e.preventDefault();
    const { order, orderBeingEdittedInForm } = this.state;
    // if paid by paypal and need paid additional amount, show paypal confirmation
    if (order.paidByPaypal && orderBeingEdittedInForm.due > order.due) {
      this.setState({
        showPaypalConfirmation: true,
      });
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmAlert
              onConfirm={this.handleConfirmSaveOrder}
              onClose={onClose}
              actionName="submit"
              message={message}
              yesWord="OK"
              noWord="Cancel"
            />
          );
        },
      });
    }
  }

  handleAddDishToCart(dish) {
    if (
      (dish.selections ?? []).length === 0 &&
      dish.dishType !== DishType.GROUP
    ) {
      const dishSelectionData = {
        dish,
        selections: [],
        price: dish.priceFoodieListed,
        quantity: 1,
        comment: "",
      };
      this.handleAddGoodsToCart([dishSelectionData]);
      return;
    }

    const { orderBeingEdittedInMenu } = this.state;
    const { goods } = orderBeingEdittedInMenu;
    const orderGoodsAsCartMap = orderGoodsToCartMapGoods(goods);
    const goodKey = getGoodKeyFromCart(orderGoodsAsCartMap, dish._id);
    const existingGoodInCart = orderGoodsAsCartMap[goodKey];
    if (existingGoodInCart != null) {
      const dishSelectionData = {
        ...existingGoodInCart,
        quantity: 1,
      };
      this.handleAddGoodsToCart([dishSelectionData]);
      return;
    }

    this.handleChangeDishQuantity(true, dish, 1, null);
  }

  handleRemoveDishFromCart(dish) {
    const { orderBeingEdittedInMenu } = this.state;
    const { goods } = orderBeingEdittedInMenu;
    const updatedGoodsAsCartMap = removeDishQuantityFromCart({
      goods: orderGoodsToCartMapGoods(goods),
      dish: dish,
      quantity: 1,
    });
    this.updateOrderEdittedInMenuWithGoodsAsCartMap(updatedGoodsAsCartMap);
  }

  updateOrderEdittedInMenuWithGoodsAsCartMap(goodsAsCartMap) {
    const { orderBeingEdittedInMenu } = this.state;
    const updatedGoods = Object.values(goodsAsCartMap);
    const updatedClientSideGoods = orderGoodsToCartGoods(updatedGoods);
    const updatedOrder = {
      ...orderBeingEdittedInMenu,
      goods: updatedGoods,
      clientSideGoods: updatedClientSideGoods,
    };

    this.setState({
      orderBeingEdittedInMenu: updatedOrder,
    });
  }

  handleConfirmSaveOrder(e) {
    const { orderBeingEdittedInForm } = this.state;
    LOGGING &&
      console.log(
        "handleSaveOrder called with orderBeingEdittedInForm:",
        orderBeingEdittedInForm
      );
    this.setState({ loading: true }, () => {
      this.props
        .changePaidOrder(orderBeingEdittedInForm)
        .then(() => {
          const { order } = this.state;
          this.props.history.push({
            pathname: "/orders",
            state: { gotoOrderId: order._id },
          });
        })
        .catch((err) => {
          toast.error(err.message);
        });
    });
  }

  handleUp(orderId, e) {
    console.log("handleUp", orderId, e);
    this.setState(
      { saving: { orderId, isBinary: true, binaryAnswer: true } },
      () => {
        this.props.giveOrderFeedback(orderId, true).then(() => {
          this.props.readOrders().then(() => {
            this.setState({ saving: null });
          });
        });
      }
    );
  }

  handleDown(orderId, e) {
    console.log("handleDown", orderId, e);
    this.setState(
      { saving: { orderId, isBinary: true, binaryAnswer: false } },
      () => {
        this.props.giveOrderFeedback(orderId, false).then(() => {
          this.props.readOrders().then(() => {
            this.setState({ saving: false });
          });
        });
      }
    );
  }

  handleEditComment(orderId, e) {
    console.log("handleEditComment", orderId, e);
    let editedComments = this.state.editedComments;
    editedComments.set(orderId, e.target.value);
    this.setState({ editedComments: editedComments });
  }

  handleSaveComment(orderId, e) {
    console.log("handleSaveComment", orderId, e);
    this.props.saveOrderFeedbackComment(
      orderId,
      this.state.editedComments.get(orderId)
    );
    this.setState({ saving: { orderId, isBinary: false } }, () => {
      this.props.readOrders().then(() => {
        this.setState({ saving: null });
      });
    });
  }

  handleClose() {
    const { from, order } = this.state;
    LOGGING &&
      console.log("PageOrderDetails handleClose called with", { from, order });
    // if from weekly view, back to weekly view
    if (from === "weekly") {
      this.props.history.goBack();
    } else {
      this.props.history.push({
        // pathname: `/${order.userGroup ? 'group-' : ''}orders`,
        pathname: "/orders",
        state: { gotoOrderId: order._id },
      });
    }
  }

  handleCancelOrder(orderId, isFreeOrder, e) {
    e.preventDefault();
    LOGGING && console.log("handleCancelOrder");
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmAlert
            onConfirm={this.handleConfirmCancelOrder.bind(
              this,
              orderId,
              isFreeOrder
            )}
            onClose={onClose}
            actionName="cancel"
          />
        );
      },
    });
  }

  handleConfirmCancelOrder(orderId, isFreeOrder) {
    LOGGING && console.log("cancelling..");
    this.setState({ cancelOrderId: orderId });
    this.props.cancelOrder(orderId, isFreeOrder).then(() => {
      this.props.history.push("/orders");
    });
  }

  componentDidMount() {
    const { orderId } = this.props.match.params;
    const { edit, from } = this.props.location.state || {};
    LOGGING &&
      console.log("PageOrderDetails componentdidmount with orderId:", orderId);
    this.setState({ loading: true, from }, () => {
      this.props.readOrder(orderId).then((order) => {
        const { cancelTime } = order;
        if (cancelTime) {
          this.props.history.push("/orders");
        } else {
          this.setState({ loading: false, order }, () => {
            if (edit) {
              this.handleEditOrder();
            }
            window.scrollTo(0, 0);
          });
        }
      });
    });
  }

  render() {
    const { currentUser } = this.props;
    const { deliveryInfo } = currentUser.user;
    const {
      loading,
      cancelOrderId,
      saving,
      order,
      orderBeingEdittedInForm,
      orderBeingEdittedInMenu,
      editedComments,
      showConfirmation,
      showPaypalConfirmation,
      confirmedDish,
      confirmedQuantity,
      couponCodeError,
      selectedDish,
    } = this.state;
    LOGGING &&
      console.log("PageOrderDetails rendering with", {
        props: this.props,
        state: this.state,
      });

    if (!currentUser.isAuthenticated) {
      return <Redirect to="/" />;
    }

    const isMember = currentUser?.user?.membership?.isActive;
    const isAuthenticated = currentUser.isAuthenticated;
    const countInCart =
      orderBeingEdittedInMenu?.goods.reduce(
        (a, b) => (b.dish._id === selectedDish?._id ? b.quantity : 0) + a,
        0
      ) || 0;

    return (
      <div className="page">
        <Toaster />
        {order === null || loading === true ? (
          <Loading />
        ) : (
          <>
            {orderBeingEdittedInMenu && (
              <React.Fragment>
                <OrderFormWithMenu
                  loading={loading}
                  order={orderBeingEdittedInMenu}
                  onChangeDishQuantity={this.handleChangeDishQuantity.bind(
                    this,
                    true
                  )}
                  onEditDishComment={this.handleEditDishComment}
                  //todo: delivery instructions can be modified
                  //onEditOrderComment={this.handleEditOrderComment}
                  onSaveOrder={this.handleSaveOrder}
                  onDiscard={this.handleDiscardMenuEdit}
                  onConfirm={this.handleConfirmMenuEdit}
                  onClickCountInCart={this.handleConfirmMenuEdit}
                  onGoToCategory={this.handleGoToCategory}
                  onAddDishToCart={this.handleAddDishToCart}
                  onRemoveDishFromCart={this.handleRemoveDishFromCart}
                  isMember={isMember}
                  isAuthenticated={isAuthenticated}
                  onStartMembership={this.handleStartMembership}
                />
                <CheckoutBanner
                  cart={orderBeingEdittedInMenu}
                  onNext={this.handleConfirmMenuEdit}
                  isMember={isMember}
                />
              </React.Fragment>
            )}
            {orderBeingEdittedInForm && (
              <>
                <OrderFormCheckout
                  loading={loading}
                  originalOrder={order}
                  order={orderBeingEdittedInForm}
                  couponCodeError={couponCodeError}
                  onCancelOrder={this.handleCancelOrder}
                  onChangeDishQuantity={this.handleChangeDishQuantity.bind(
                    this,
                    false
                  )}
                  onChangeTips={this.handleChangeTips}
                  onEditDishComment={this.handleEditDishComment}
                  //todo: delivery instructions can be modified
                  //onEditOrderComment={this.handleEditOrderComment}
                  onSaveOrder={this.handleSaveOrder}
                  onShowMenu={this.handleShowMenu}
                  onApplyCouponCode={this.handleApplyCouponCode}
                />
                <Modal isOpen={showPaypalConfirmation}>
                  <Flex
                    direction="column"
                    justify="center"
                    align="center"
                    gap={32}
                  >
                    <div>
                      You'll be charged an additional $
                      {(orderBeingEdittedInForm.due - order.due).toFixed(2)} for
                      the change
                    </div>
                    <PaypalButton
                      deliveryInfo={deliveryInfo}
                      amount={orderBeingEdittedInForm.due}
                      onCapturing={this.handlePaypalCapturing}
                      onAuthorize={this.handlePaypalAuthorize}
                    />
                    <Button
                      variant="secondary"
                      onClick={this.handleHidePaypalConfirmation}
                    >
                      Cancel
                    </Button>
                  </Flex>
                </Modal>
              </>
            )}
            {!orderBeingEdittedInForm && !orderBeingEdittedInMenu && (
              <OrderDetails
                order={order}
                onCancelOrder={this.handleCancelOrder}
                cancelOrderId={cancelOrderId}
                onEditOrder={this.handleEditOrder}
                onBack={this.handleClose}
                loading={loading}
                onUp={this.handleUp}
                editedComments={editedComments}
                onDown={this.handleDown}
                onEditComment={this.handleEditComment}
                onSaveComment={this.handleSaveComment}
                saving={saving}
                onSaveOrderContract={this.handleSaveOrderContracts}
                isMember={
                  currentUser?.user?.membership?.status === "trialing" ||
                  currentUser?.user?.membership?.status === "active"
                }
              />
            )}
            {selectedDish && (
              <DishChooserContainer
                modalify={true}
                dishId={selectedDish._id}
                onClose={this.handleCloseSelections}
                onAddGoodsToCart={this.handleAddGoodsToCart}
                onClickCountInCart={(e) => {
                  this.handleCloseSelections(e);
                  this.handleConfirmMenuEdit(e);
                }}
                countInCart={countInCart}
                isMember={isMember}
                isAuthenticated={currentUser?.isAuthenticated}
              />
            )}
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log("PageOrderDetails got redux state:", state);
  return {
    orders: state.orders,
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  readOrder,
  cancelOrder,
  giveOrderFeedback,
  saveOrderFeedbackComment,
  changePaidOrder,
  fetchMeal,
  applyCouponCode,
})(PageOrderDetails);
