import { InputHTMLAttributes } from 'react';
import { Flex } from 'src/components/Layout';
import styled from 'styled-components';

export type RadioProps = {
  label: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const Radio = (props: RadioProps) => {
  const { label, ...inputProps } = props;

  const radioId = `${inputProps.name}_${label}`;

  return (
    <Container $disabled={inputProps.disabled}>
      <Flex align="center" gap={12} inline>
        <input id={radioId} {...inputProps} type="radio" />
        <label htmlFor={radioId}>{label}</label>
      </Flex>
    </Container>
  );
};

const Container = styled.div<{ $disabled?: boolean }>`
  input {
    margin: 0;
  }

  label {
    font-size: 14px;
  }

  ${({ $disabled }) =>
    $disabled
      ? `
        label {
          color: #888888;
        }
      `
      : ''}
`;
