import React, { useState } from "react";
import { faHeart, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faSearch, faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Restaurant } from "src/screens/social_network/components";
import styled from "styled-components";
import { RestaurantUpcomingMealsContainer } from "../restaurants/RestaurantUpcomingMealsContainer";
import {
  Centered,
  Grey,
  BannerWhite,
  BackgroundGrey,
  TextGrey,
  BorderGrey,
  NavHeight,
  centered,
  text12300,
  Beige,
  Text12300,
  Card,
  CenteredByColumn,
  centeredByColumn,
  CenteredByColumnLeft,
  DownwardShadow,
  textTitle,
  SubNavHeight,
} from "../Shared";
import { Container } from "./SearchFavorites";

const Title = styled(Centered)`
  ${textTitle}
  width: 100vw;
  height: ${NavHeight}px;
  border-bottom: 1px solid ${BorderGrey};
  position: relative;
  button {
    position: absolute;
    height: 100%;
    width: ${NavHeight}px;
    right: 0px;
    top: 0px;
    svg {
      font-size: 24px;
    }
  }
`;
const FavoriteNotes = styled.div`
  width: 100%;
  height: ${NavHeight}px;
  background-color: ${Beige};
  padding: 20px 40px;
  /* padding-left: ${NavHeight}px;     */
  position: relative;
  ${centered}
  border-bottom: 1px solid ${BorderGrey};
  span {
    ${text12300}
    line-height: 1.6;
    text-align: center;
  }
  svg {
    position: absolute;
    font-size: 20px;
    left: 30px;
    top: 25px;
    color: rgb(234, 76, 137);
    display: none;
  }
`;
export const FilterByFavorite = ({ onClose }) => {
  return (
    <Container>
      <Title>
        <span>My Favorites</span>
        <button onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </Title>
      <FavoriteNotes>
        <FontAwesomeIcon icon={faHeart} />
        <span>You will get a reminder when your favorites are scheduled.</span>
      </FavoriteNotes>
    </Container>
  );
};
