import { useCallback, useMemo, useState } from 'react';
import {
  faBurgerSoda,
  faConciergeBell,
  faGlass,
  faPhone,
  faUser,
  faWrench,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'src/components/Layout';
import { PhoneFormat } from '.';
import { ConfirmAlert } from '..';

const SelectedItemDetails = ({ selectedItemName, quantity }) => {
  return (
    <div style={{ fontSize: '14px' }}>
      - {quantity > 1 ? <span>{`${quantity}x `}</span> : null}
      <span>{selectedItemName}</span>
    </div>
  );
};

const OrderItemDetails = ({ itemName, isDrink, quantity, selectedItems }) => {
  return (
    <div style={{ fontSize: '16px', fontWeight: 500, textAlign: 'left', marginLeft: '4px' }}>
      <div className="item-name">
        {'• '}
        {isDrink ? (
          <FontAwesomeIcon color="blue" icon={faGlass} style={{ marginRight: '4px' }} />
        ) : null}
        {quantity > 1 ? `${quantity}x ` : null}
        {itemName}
      </div>
      <div style={{ marginLeft: '12px' }}>
        {(selectedItems ?? []).map((selectedItem) => {
          if (selectedItem.item == null) {
            return null;
          }

          return (
            <SelectedItemDetails
              key={selectedItem._id}
              selectedItemName={selectedItem.item.name}
              quantity={selectedItem.count}
            />
          );
        })}
      </div>
    </div>
  );
};

const OrderItems = ({ orderItems }) => {
  return (
    <Flex direction="column" align="start">
      {orderItems
        .map((orderItem) => {
          const remainingQuantity = orderItem.quantity - (orderItem.cancelQuantity ?? 0);
          if (remainingQuantity === 0) return null;

          return (
            <OrderItemDetails
              key={orderItem._id}
              itemName={orderItem.dish.name}
              isDrink={orderItem.dish.isDrink}
              quantity={remainingQuantity}
              // Each orderItem (burger) can have multiple selections (fries, soda)
              // We flatten the selections since it's not relevant to display to driver
              // the type of selection. Just that the selectedItem (small fries) should be there.
              selectedItems={orderItem.selections
                .map((selection) => selection.selectedItems)
                .flat()}
            />
          );
        })
        .filter(Boolean)}
    </Flex>
  );
};

export const DriverOrderTask = ({
  orderId,
  name,
  orderHash,
  restaurantName,
  phone,
  instructions,
  orderItems,
  onComplete, // (orderId) => {}
  onCompleteButtonLabel,
  confirmMessageGenerator, // (orderName) => {}
}) => {
  const [confirmAlertMessage, setConfirmAlertMessage] = useState(null);
  const openConfirmAlert = useCallback((orderName) => {
    setConfirmAlertMessage(`You've picked up ${orderName}'s order?`);
  }, []);
  const closeConfirmAlert = useCallback(() => {
    setConfirmAlertMessage(null);
  }, []);
  const confirmMessage = useMemo(() => {
    return confirmMessageGenerator(name);
  }, [confirmMessageGenerator, name]);
  const completeOrderTask = useCallback(() => {
    onComplete(orderId);
  }, [onComplete, orderId]);

  return (
    <>
      <div className="receipt">
        <div className="driver-action order-order">
          <div className="driver-label">
            <FontAwesomeIcon icon={faUser} />
          </div>
          <div className="order-status">
            <span>
              <b>({orderHash})</b> {name}
            </span>
          </div>
        </div>
        {restaurantName ? (
          <div className="driver-action order-restaurant">
            <div className="driver-label">
              <FontAwesomeIcon icon={faConciergeBell} />
            </div>
            <div className="order-status">
              <span>{restaurantName}</span>
            </div>
          </div>
        ) : null}
        {phone ? (
          <div className="driver-action order-phone">
            <div className="driver-label">
              <FontAwesomeIcon icon={faPhone} />
            </div>
            <a className="order-status" href={`tel:${phone}`}>
              {PhoneFormat(phone)}
            </a>
          </div>
        ) : null}
        {instructions ? (
          <div className="driver-action order-note">
            <div className="driver-label">
              <FontAwesomeIcon icon={faWrench} />
            </div>
            <div className="order-status">
              <span>{instructions}</span>
            </div>
          </div>
        ) : null}
        <div className="driver-action meal">
          <div className="driver-label">
            <FontAwesomeIcon icon={faBurgerSoda} />
          </div>
          <div style={{ marginLeft: '5px' }}>
            <OrderItems orderItems={orderItems} />
          </div>
        </div>
        {onCompleteButtonLabel != null ? (
          <button className="deliver-button" onClick={openConfirmAlert}>
            {onCompleteButtonLabel}
          </button>
        ) : null}
        {confirmAlertMessage != null ? (
          <ConfirmAlert
            onConfirm={completeOrderTask}
            onClose={closeConfirmAlert}
            message={confirmMessage}
          />
        ) : null}
      </div>
    </>
  );
};

DriverOrderTask.defaultProps = {
  onConfirm: () => {},
  confirmMessageGenerator: () => {},
};
