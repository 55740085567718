import React, { ReactElement, useCallback, useEffect } from "react";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { useSelector, useDispatch, useStore } from "react-redux";
import { RootState } from "src/store";
import styled from "styled-components";
import { NavByDayFormat, HeroImage } from "../..";
import { visit } from "../../../../../store/actions";
import {
  Centered,
  CenteredByColumn,
  actionBlack,
  text12300,
  BorderGrey,
  textTitle,
  card,
  centered,
  actionUnderline,
  actionTransparent,
  Pink,
  EasterBlue,
  Lavendar,
  Blue,
  LightBlue,
} from "../../Shared";
import { CardThumbnail } from "../giftCards/GiftTemplate";
import { BreakPoint } from "../mealMenu/MealHeader";
export interface GiftCardAnnouncementProps {}
export interface ContentContainerProps {
  // src: string;
}
const HeroHight = 40;
/* background-image: url(${(props) => props.src});*/
const ContentContainer = styled(CenteredByColumn)<ContentContainerProps>`
  width: 100vw;
  height: ${HeroHight}vh; // ${HeroHight}px;
  /* background-position: center;
  background-size: cover;
  background-repeat: no-repeat; */
  position: relative;
  .container {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
  }
  @media only screen and (min-width: 1200px) {
    height: ${HeroHight}vh;
    .container {
      .pic {
        height: 100%;
      }
    }
  }
`;
const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
`;
const Content = styled(CenteredByColumn)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  /* color: white; */
  /* color: black; */
  padding: 20px 10vw;
  text-align: center;
`;

const Title = styled(Centered)`
  span {
    ${text12300}
    font-size: 32px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1.1;
  }

  margin-bottom: 10px;
  @media only screen and (min-width: ${BreakPoint}px) {
    margin-top: 40px;
  }
`;

const MealLink = styled.a`
  ${actionTransparent}
  z-index: 100;
  color: black;
  border-color: black;
  /* color: black;
  border-color: black; */
  margin-top: 0px;
  text-transform: uppercase;
`;

const HeroContainer = styled(Centered)`
  background-color: ${LightBlue};
  //background-color: ${EasterBlue}; //easter
  align-self: stretch;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
`;
const Card = ({ background, position }) => (
  <CardThumbnail background={background} scale={0.9} position={position} />
);

const CardsBanner = () => (
  <HeroContainer>
    <Card
      background="https://i.pinimg.com/originals/59/70/c5/5970c5e7e84fb7032cf794129091e0d0.jpg"
      position={{
        top: 90,
        left: 40,
        rotate: -35,
      }}
    />
    <Card
      background="https://i.pinimg.com/originals/6b/99/44/6b994436332ce5ab30eaca733ee7ed24.jpg"
      position={{
        top: -140,
        left: 70,
        rotate: 20,
      }}
    />
    <Card
      background="https://i.pinimg.com/originals/bc/dc/a6/bcdca64da3ae359fd745ae93aa458c6d.jpg"
      position={{
        top: 90,
        left: -120,
        rotate: -25,
      }}
    />
    <Card
      background="https://i.pinimg.com/originals/1e/80/7d/1e807dee5ae34941d15e8bc2ae5613f1.jpg"
      position={{
        top: 10,
        left: -70,
        rotate: 5,
      }}
    />
  </HeroContainer>
);

export const GiftCardAnnouncement: React.FC<GiftCardAnnouncementProps> =
  ({}): ReactElement => {
    // const dispatch = useDispatch();
    // useEffect(() => {
    //   console.log("MealHighlights rendered with:", highlight);
    //   dispatch(visit("competitor receipts"));
    // }, []);

    return (
      <ContentContainer>
        <Overlay></Overlay>
        <CardsBanner />
        <Content>
          {/* <RestaurantName>{`annoucing cards`}</RestaurantName> */}
          <Title>
            <span>sending love to dads</span>
          </Title>
          <MealLink href={`https://foodie.earth/gifts`}>
            see collections
          </MealLink>
        </Content>
      </ContentContainer>
    );
  };
