import React, { ReactElement, useState, useEffect } from "react";
import {
  faCheck,
  faPlus,
  faUsers,
  faGift,
  faUserPlus as faGroup,
  // faUsersMedical as faGroup
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import styled, { keyframes } from "styled-components";
import { CurrentList, LOGGING } from "../..";
import groupImage from "../../../../../group.png";
import {
  Beige,
  BorderGrey,
  Centered,
  Pink,
  Peach,
  Text12300,
  text12300,
  centered,
  CenteredByColumn,
  CenteredByColumnLeft,
  TextGrey,
  ActionBlack,
  textOverflow,
  textTitle,
  upper14500,
} from "../../Shared";

const BreakPoint = 800;
const animationDelay = 300;
interface ContentContainerProps {
  isMember?: boolean;
  hasGroupOrder?: boolean;
  isExclusive?: boolean;
}
const ContentContainer = styled(CenteredByColumnLeft)<ContentContainerProps>`
  width: 100%;
  padding: 20px;
  justify-content: flex-start;
  border-bottom: ${(props) =>
    props.hasGroupOrder ? "none" : `1px solid ${BorderGrey}`};
  @media only screen and (min-width: ${BreakPoint}px) {
    /* padding-left: 140px; */
    /* border: 1px solid red; */
    background-color: ${Beige};
    align-self: stretch;
    flex-grow: 0;
    width: auto;
    /* flex-grow: 1; */
    box-shadow: none;
    border-top: none;
    padding: 20px;
    border-bottom: none;
  }
`;

const ViralAction = styled.button<ContentContainerProps>`
  ${centered}
  padding: 0px 15px;
  height: 40px;
  margin-right: 15px;
  flex-wrap: nowrap;
  svg {
    margin-right: 10px;
  }
  span,
  label {
    ${text12300}
    text-transform: uppercase;
    font-weight: 500;
    white-space: nowrap;
  }
  &:last-child {
    margin-right: 0px;
  }
  @media only screen and (min-width: ${BreakPoint}px) {
    padding: 10px 15px;
    align-self: ${(props) => (props.isMember ? "stretch" : "auto")};
  }
`;
const GroupOrderAction = styled(ViralAction)`
  background-color: ${Peach};
  label {
    display: none;
    font-weight: 700;
  }
  span {
    font-weight: 700;
  }
  @media only screen and (min-width: ${BreakPoint}px) {
    align-self: stretch;
    /* flex-direction: column; */
    /* padding: 0px 30px; */
    margin-right: 0px;

    span {
      display: none;
    }
    label {
      display: flex;
    }
    /* svg {
      margin-right: 0px;
      margin-bottom: 20px;
      font-size: 30px;
    } */
  }
`;
const GiftOrderAction = styled(ViralAction)`
  background-color: ${Pink};
`;
const NewGroupOrder = ({ onClick, isMember }) => (
  <GroupOrderAction isMember={isMember} onClick={onClick}>
    <FontAwesomeIcon icon={faGroup} />
    <label>create group order</label>
    <span>group order</span>
  </GroupOrderAction>
);
const GiftOrder = ({}) => (
  <GiftOrderAction>
    <FontAwesomeIcon icon={faGift} />
    <span>gift card</span>
  </GiftOrderAction>
);
const Notes = styled(Text12300)`
  position: absolute;
  bottom: 16px;
  left: 20px;
`;
const AvailableActions = styled(Centered)<ContentContainerProps>`
  /* padding: 0px 20px 20px 20px; */
  /* padding-bottom: ${(props) => (props.isMember ? "50px" : "20px")}; */
  position: relative;
  align-self: stretch;
  justify-content: flex-start;

  @media only screen and (min-width: ${BreakPoint}px) {
    flex-grow: 1;
    /* align-items: flex-start;     */
    /* justify-content: center; */
    flex-direction: column;
    align-self: auto;
    /* justify-content: flex-end; */
    /* padding-right: 55px; */
    /* align-items: flex-start; */
  }
`;
export interface ViralActionsProps {
  isMember?: boolean;
  onShowGroupOrder?: () => void;
  onOpenInvite?: () => void;
  groupOrderId?: string;
  name?: string;
  groupOrderLeader?: any;
  isExclusive?: boolean;
}
export const ViralActions: React.FC<ViralActionsProps> = ({
  isMember,
  onShowGroupOrder,
  onOpenInvite,
  groupOrderId,
  groupOrderLeader,
  isExclusive,
}: ViralActionsProps): ReactElement => {
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const isLeader = groupOrderLeader?._id === currentUser?.user?._id;
  // LOGGING &&
  //   console.log("ViralActions rendering with:", {
  //     isMember,
  //     groupOrderLeader,
  //     currentUser,
  //     groupOrderId,
  //     isLeader,
  //   });
  //turn off viral features for non mmber for now.
  // if (!isMember) {
  //   return null;
  // }
  return groupOrderId ? (
    <ExistingGroupOrder
      leaderName={`${groupOrderLeader?.firstName}`}
      isLeader={isLeader}
      onOpenInvite={onOpenInvite}
      isFromCart={false}
    />
  ) : (
    <ContentContainer
      hasGroupOrder={groupOrderId !== null}
      isMember={isMember}
      isExclusive={isExclusive}
    >
      <AvailableActions isMember={isMember} isExclusive={isExclusive}>
        <NewGroupOrder onClick={onShowGroupOrder} isMember={isMember} />
        {/* {isMember ? (
          <GroupOrderMember>
            Yes, guests get your member benefits.
          </GroupOrderMember>
        ) : (
          <GroupOrderMember>
            Members' guests get the member benefits.
          </GroupOrderMember>
        )} */}
        {/* <GiftOrder /> */}
        {/* {isMember ? (
          <Notes>Friends enjoy your member benefits on this meal.</Notes>
        ) : null} */}
      </AvailableActions>
    </ContentContainer>
  );
};

const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export interface GroupOrderContainerProps {
  isOpen: boolean;
}
export const GroupOrderContainer = styled.div<GroupOrderContainerProps>`
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 103;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  animation: ${({ isOpen }) => (isOpen ? "none" : fadeOut)} ${animationDelay}ms
    ease-in-out;
`;
const imgHeight = 150;
const GroupIcon = styled.div`
  width: ${imgHeight}px;
  height: ${imgHeight}px;
  /* height: auto; */
  box-sizing: border-box;
  position: relative;
`;

const GroupOrderNotes = styled(CenteredByColumn)`
  ${upper14500}
  font-size: 26px;
  line-height: 1.3;
  margin-bottom: 20px;
`;
const GroupOrderMember = styled.span`
  ${text12300}
  font-size: 16px;
  font-weight: 400;
  /* text-transform: none; */
  /* line-height: 1.6;
  font-size: 12px;
  font-weight: 300;
  margin-left: 10px; */
  /* position: absolute; */
  /* top: 100%; */
  /* left: 0px; */
  /* white-space: nowrap; */
  @media only screen and (min-width: ${BreakPoint}px) {
    /* line-height: 1; */
    margin-top: 10px;
    text-align: center;
    align-self: stretch;
    margin-left: 0px;
  }
`;
const ImageContainer = styled.div`
  background-image: url(${groupImage});
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;
export interface GroupOrderContentProps {
  isOpen: boolean;
}

export const GroupOrderContent = styled(
  CenteredByColumn
)<GroupOrderContentProps>`
  position: fixed;
  left: 0px;
  bottom: 0px;
  background-color: white;
  padding: 20px;
  width: 100vw;
  min-height: 50vh;
  z-index: 1;
  justify-content: space-between;
  /* background-color: ${Peach}; */
  animation: ${({ isOpen }) => (isOpen ? slideIn : slideOut)}
    ${animationDelay}ms ease-in-out;
  @media only screen and (min-width: ${BreakPoint}px) {
    width: 500px;
    left: calc(50vw - 250px);
    height: 100vh;
    max-height: 500px;
    bottom: calc(max(0px, 50vh - 250px));
    animation: none;
  }
`;
const SelectorContainer = styled(CenteredByColumnLeft)``;
const selections = [
  "Everyone adds dishes, I pay the bill.",
  "Everyone adds dishes, pays their own bill.",
  "Everyone adds dishes, splits the bill.",
];
interface TypeContainerProps {
  isSelected: boolean;
}
const TypeContainer = styled.button<TypeContainerProps>`
  ${centered};
  justify-content: flex-start;
  padding-left: 20px;
  /* border: 1px solid black; */
  position: relative;
  span {
    text-align: left;
    ${text12300}
    text-transform: none;
    color: ${(props) => (props.isSelected ? "black" : `${TextGrey}`)};
    font-weight: ${(props) => (props.isSelected ? 500 : 300)};
  }
  svg {
    position: absolute;
    left: 0px;
    display: ${(props) => (props.isSelected ? "block" : "none")};
  }
`;
const Type = ({ index, isSelected, onSelect }) => (
  <TypeContainer isSelected={isSelected} onClick={onSelect}>
    <FontAwesomeIcon icon={faCheck} />
    <span>{selections[index]}</span>
  </TypeContainer>
);
const TypeSelector = ({ selected, onSelect }) => (
  <SelectorContainer>
    {selections.map((selection, index) => (
      <Type
        index={index}
        isSelected={selected === index}
        onSelect={onSelect.bind(this, index)}
      />
    ))}
  </SelectorContainer>
);
const StartOrder = styled(ActionBlack)`
  margin: 40px 0px;
  height: 60px;
  align-self: stretch;
  font-size: 16px;
  font-weight: 500;
`;
export const GroupOrderCard = ({ show, onClose, onStart }) => {
  const [localShow, setLocalShow] = useState(true);
  const [selected, setSelected] = useState(0);
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const isMember = currentUser?.user?.membership?.status === "active";
  useEffect(() => {
    setLocalShow(show); // state updated with same value
  }, [show]); //
  const handleClose = () => {
    // Introduce a delay of 500ms before actually closing
    // e.preventDefault();
    // e.stopPropagation();
    setLocalShow(false);
    setTimeout(() => {
      onClose();
    }, 100);
  };

  return (
    <GroupOrderContainer isOpen={show} onClick={handleClose}>
      <GroupOrderContent isOpen={localShow}>
        <GroupIcon>
          <ImageContainer />
        </GroupIcon>

        <GroupOrderNotes>
          <span>Guests add dishes,</span>
          <span>I pay.</span>
        </GroupOrderNotes>
        <GroupOrderMember>
          {isMember
            ? `Everyone gets my member benefits.`
            : `Members' guests get the member benefits.`}
        </GroupOrderMember>
        {/* {<TypeSelector selected={selected} onSelect={setSelected} />} */}
        <StartOrder onClick={onStart.bind(this, selected)}>
          start group order
        </StartOrder>
      </GroupOrderContent>
    </GroupOrderContainer>
  );
};

interface SharableContainerProps {
  isFromCart?: boolean;
  isLeader?: boolean;
}
const SharableContainer = styled(Centered)<SharableContainerProps>`
  padding: 15px 20px;
  justify-content: space-between;
  align-self: stretch;
  flex-grow: ${(props) => (props.isFromCart ? 0 : 1)};
  background-color: ${Peach};
  /* margin-bottom: 20px; */
  /* box-shadow: 0 2px 4px rgba(52, 41, 41, 0.2); */
  &:last-child {
    margin-bottom: 0px;
  }

  button {
    /* background-color: ${Peach}; */
    ${centered}
    justify-content: space-between;
    padding: 0px;
    width: 100%;
    box-shadow: none;
    border: none;
    flex-shrink: 0;
    label {
      ${text12300}
      padding: 5px 15px;
      text-transform: uppercase;
      font-weight: 500;
      background-color: black;
      color: white;
    }
    span {
      ${text12300}
      ${textOverflow}
    text-transform: uppercase;
      font-weight: 500;
      text-align: left;
      svg {
        margin-right: 5px;
      }
      color: black;
      background-color: transparent;
    }
    svg {
      margin-left: 10px;
    }
  }
  @media only screen and (min-width: ${BreakPoint}px) {
    /* flex-grow: 0; */
    background-color: ${Beige};
    align-items: flex-start;
    justify-content: ${(props) => (props.isFromCart ? "center" : "flex-start")};
    padding-top: ${(props) =>
      props.isFromCart || !props.isLeader ? "20px" : "47px"};
    padding-bottom: ${(props) =>
      props.isFromCart || props.isLeader ? "0px" : "20px"};
    padding-left: ${(props) =>
      props.isFromCart || props.isLeader ? "20px" : "75px"};
    align-self: ${(props) => (props.isFromCart ? "auto" : "stretch")};
    flex-grow: ${(props) => (props.isFromCart ? 0 : 1)};
    button {
      background-color: ${Peach};
      padding: 20px;
      width: 450px;
      span {
        margin-right: 20px;
      }
    }
  }
`;

export const ExistingGroupOrder = ({
  leaderName,
  isLeader,
  onOpenInvite,
  isFromCart,
}) => {
  LOGGING &&
    console.log("ExistingGroupOrder rendering with:", {
      leaderName,
      isLeader,
      isFromCart,
    });
  return (
    <SharableContainer isFromCart={isFromCart} isLeader={isLeader}>
      {/* {isLeader ? ( */}
      <button onClick={onOpenInvite}>
        <span>
          <FontAwesomeIcon icon={faUsers} />
          Group order by {leaderName}
        </span>
        <label>
          invite
          <FontAwesomeIcon icon={faPlus} />
        </label>
      </button>
      {/* ) : null} */}
    </SharableContainer>
  );
};
