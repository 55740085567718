import React from "react";
import "./Footer.scss";
import { faInstagramSquare } from "@fortawesome/free-brands-svg-icons";
import {
  faCopyright,
  faSms,
  faEnvelope,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { customerServicePhoneNumber } from "src/constants";
import nextdoorlogo from "./nextdoor.JPG";

export const AnonymousFooter = ({ isDesktop }) => (
  <div className={`anonymous-footer ${isDesktop ? "desktop" : "mobile"}`}>
    <Link to="/about" className="footer-field">
      About
    </Link>
    <Link to="/faq" className="footer-field">
      FAQ
    </Link>
    <span className="footer-field email">info@foodie.earth</span>
    <span className="footer-field phone">{customerServicePhoneNumber}</span>
    <span className="footer-field">
      <FontAwesomeIcon icon={faCopyright} />
      2023 foodie.earth
    </span>
  </div>
);
// import { faSms, faEnvelope } from "@fortawesome/pro-regular-svg-icons";
const Footer = () => (
  <div className="footer">
    <div className="footer-section">
      <Link to="/about" className="footer-field">
        about
      </Link>
      {/* <Link to="/locations" className="footer-field">
        service areas
      </Link> */}
      <Link to="/faq" className="footer-field">
        FAQ
      </Link>
    </div>
    <div className="footer-section">
      <span className="footer-field email">
        <FontAwesomeIcon icon={faEnvelope} />
        info@foodie.earth
      </span>
      <span className="footer-field phone">
        <FontAwesomeIcon icon={faSms} />
        {customerServicePhoneNumber}
      </span>
      <Link to="/social-network" className="footer-field social-network">
        <FontAwesomeIcon icon={faInstagramSquare} />
        <span>Follow Us on Instagram</span>
      </Link>
      <Link to="/nextdoor" className="footer-field social-network">
        <img
          src={nextdoorlogo}
          className="footer-field image"
          alt="NextDoor logo"
        />
        <span> Find us on Nextdoor </span>
      </Link>
    </div>
    <div className="footer-section">
      <Link to="/terms" className="footer-field">
        terms
      </Link>
      <Link to="/privacy" className="footer-field">
        privacy
      </Link>
      <span className="footer-field">
        <FontAwesomeIcon icon={faCopyright} />
        2023 foodie.earth
      </span>
    </div>
  </div>
);
export default Footer;
