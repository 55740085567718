import React, { ReactElement, useCallback } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { restaurantSaveMealMenu } from 'src/api/meals';
import { RootState } from 'src/store';
import { loadSelectedDishes } from 'src/store/actions/mealMenu';
import { SimpleNavBar } from '../SimpleNavBar';

type RestaurantMealMenuNavBarProps = {
  mealId: string;
};

export const RestaurantMealMenuNavBar: React.FC<RestaurantMealMenuNavBarProps> = ({
  mealId,
}): ReactElement => {
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const restaurantId = currentUser.user.restaurant._id;

  const selectedDishes = useSelector((state: RootState) => state.mealMenu?.selectedDishes);
  const dispatch = useDispatch();
  const history = useHistory();

  const publishMeal = useCallback(async () => {
    if (selectedDishes == null) {
      return;
    }

    const mealPayload = {
      _id: mealId,
      dishes: selectedDishes,
      restaurant: restaurantId,
    };

    const updatedMeal = await restaurantSaveMealMenu(mealPayload, currentUser);
    const updatedSelectedDishes = updatedMeal.dishes.map((d): string => d._id);
    dispatch(loadSelectedDishes(updatedSelectedDishes));

    toast.success('Menu published!');

    history.goBack();
  }, [currentUser, dispatch, history, mealId, restaurantId, selectedDishes]);

  return <SimpleNavBar title="Review Menu" actionLabel="Publish" actionCallback={publishMeal} />;
};
