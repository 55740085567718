import React from "react";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { LOGGING } from "..";
import {
  ActionUnderline,
  Beige,
  Centered,
  actionUnderline,
  card,
  text12300,
  text13500,
} from "../Shared";
const Container = styled(Centered)`
  margin-top: 20px;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* padding-top: 100px; */
  position: relative;
  min-width: calc(100vw - 80px);
`;
const ShowAll = styled.a`
  ${actionUnderline}
`;
const Notes = styled.span`
  ${text13500}
  text-align: left;
  text-transform: none;
`;
const width = 30;
const Letter = styled.a<{ isSlected: boolean }>`
  ${card}
  width: ${width}px;
  height: ${width}px;
  margin: 7px;
  text-align: center;
  line-height: ${width}px;
  // box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid black;
  background-color: ${(props) => (props.isSlected ? "black" : "white")};
  color: ${(props) => (props.isSlected ? "white" : "black")};
`;

const ToggleExpanded = styled(ActionUnderline)`
  position: absolute;
  top: 0px;
  ${text12300}
  padding: 0px;
  font-weight: 500;
  svg {
    margin-left: 5px;
  }
  /* height: 40px; */
`;

export const UserAlphabet = ({ initial }) => {
  // alphabet is an array of strings, each string is a letter of the alphabet
  const [expanded, setExpanded] = React.useState(false);
  const alphabet = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode(97 + i)
  );
  LOGGING && console.log("UserAlphabet initial:", initial);

  return (
    <Container>
      <ToggleExpanded onClick={() => setExpanded(!expanded)}>
        see list{" "}
        <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
      </ToggleExpanded>
      {expanded ? (
        <>
          <ShowAll href="/users/all">Show All</ShowAll>
          <Notes>Click on first letter of the first name</Notes>
          {alphabet.map((letter) => (
            <Letter href={`/users/${letter}`} isSlected={letter === initial}>
              {letter.toUpperCase()}
            </Letter>
          ))}
        </>
      ) : null}
    </Container>
  );
};
