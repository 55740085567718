import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { GiftCardTemplate } from 'src/components/GiftCard';
import { Flex } from 'src/components/Layout';
import { Navbar } from 'src/components/Navbar';
import { Loading } from 'src/screens/shared';
import { receiveGiftCard } from 'src/store/actions';
import { getGiftCard } from 'src/store/actions';
import styled from 'styled-components';

export const ReceiveGiftCard = () => {
  const currentUser = useSelector((state: any) => state.currentUser);
  const giftCard = useSelector((state: any) => state.giftCard.giftCard);
  const dispatch = useDispatch();
  const { giftCardId } = useParams<any>();
  const history = useHistory();
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const from = searchParams.get('from');
  let noBack = false;
  if (from === 'email') {
    noBack = true;
  }

  useEffect(() => {
    dispatch(getGiftCard(giftCardId));
  }, [dispatch, giftCardId]);

  const onReceive = () => {
    if (!currentUser.isAuthenticated) {
      history.push('/signup', {
        next: `/giftCard/${giftCardId}?from=email`,
        disableGuest: true,
      });
      return;
    }
    dispatch(
      receiveGiftCard(giftCardId, {
        success: () => {
          toast.success('Gift Card Received');
          history.push('/');
        },
        error: (err: any) => {
          toast.error(err.message);
        },
      })
    );
  };

  return (
    <div className="page">
      <Navbar title="Receive Gift Card" noBack={noBack} />
      {giftCard ? (
        <GiftCardRecord>
          <Flex direction="column" align="center" widthFull>
            <p>to {giftCard.recipientName}</p>
            <StyledGiftCardTemplate template={giftCard.template} message={giftCard.message} />
            <Received>
              {giftCard.received ? (
                'Gift card is received'
              ) : (
                <Button onClick={onReceive}>Receive Gift Card</Button>
              )}
            </Received>
          </Flex>
        </GiftCardRecord>
      ) : (
        <Loading />
      )}
    </div>
  );
};

const GiftCardRecord = styled.div`
  width: 100%;
  padding: 20px;

  p {
    text-align: center;
    font-size: 13px;
  }
`;

const StyledGiftCardTemplate = styled(GiftCardTemplate)`
  width: 100%;
`;

const Received = styled.div`
  margin: 40px 0;
`;
