import { useCallback } from 'react';
import { faExchange } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';
import { useForm } from 'src/hooks';
import styled from 'styled-components';
import { Button } from '../Button';
import { Select } from '../Form';
import { Flex } from '../Layout';
import { Modal, ModalProps } from '../Modal';

type DriverSwapModalProps = {
  driverOptions: {};
  onSwap: (driver1: string, driver2: string) => void;
} & ModalProps;

type FormData = {
  driver1: string;
  driver2: string;
};

export const DriverSwapModal = (props: DriverSwapModalProps) => {
  const { driverOptions, onSwap, ...rest } = props;

  const { onClose } = rest;

  const { formData, onInputChange } = useForm<FormData>({ driver1: '', driver2: '' });

  const onSwapWrapper = useCallback(() => {
    if (formData.driver1 === '' || formData.driver2 === '') {
      toast.error('please select driver');
      return;
    }
    if (formData.driver1 === formData.driver2) {
      toast.error('please select two different drivers');
      return;
    }
    onClose?.();
    onSwap(formData.driver1, formData.driver2);
  }, [formData.driver1, formData.driver2, onClose, onSwap]);

  return (
    <Modal title="Driver Swap" {...rest}>
      <Container>
        <Flex justify="center" gap={12} align="center">
          <Select
            name="driver1"
            options={{ '': 'select driver', ...driverOptions }}
            value={formData.driver1}
            onChange={onInputChange}
          />
          <FontAwesomeIcon icon={faExchange}></FontAwesomeIcon>
          <Select
            name="driver2"
            options={{ '': 'select driver', ...driverOptions }}
            value={formData.driver2}
            onChange={onInputChange}
          />
        </Flex>
      </Container>
      <Flex justify="center" gap={24}>
        <Button onClick={onSwapWrapper}>Swap</Button>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
      </Flex>
    </Modal>
  );
};

const Container = styled.div`
  margin: 24px 0;

  select {
    width: 200px;
  }
`;
