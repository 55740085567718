import "./StopsMap.scss";
import {
  faMapMarkerAlt as faMap,
  faCarSide,
  faUser,
  faHouse,
  faStop,
  faCheck,
  faDiamond,
  faGlass,
  faPhone as faMobile,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { LOGGING } from ".";
import { Centered, TextTitle, NavHeight } from "./Shared";
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100vw;
  /* margin-top: 10px; */
  padding: 0px 10px;
`;
const RouteContainer = styled(Centered)`
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;
const PickUpDriverContent = styled(Centered)`
  position: sticky;
  top: 0px;
  background-color: transparent;
  width: 100%;
  justify-content: flex-start;
  height: 50px;
  /* border-bottom: 1px solid grey; */
`;
const DriverContainer = styled(Centered)`
  flex-direction: column;
  align-items: flex-start;
  /* margin-top: 10px; */
  /* padding-left: 10px; */
  border: 1px solid #ccc;
  width: calc(100% - 20px);
  margin: 10px;
  box-shadow: 0 4px 32px 0 rgba(28, 29, 33, 0.25);
`;
const DriverStops = styled(Centered)`
  flex-direction: column;
  width: 100%;
  /* margin: 5px 0px; */
`;
const DriverInfo = styled(Centered)`
  justify-content: flex-start;
  align-items: flex-start;
  color: blue;
  background-color: black;
  padding: 10px;
  width: 100%;
  svg {
    margin-right: 5px;
  }
`;
const Stop = styled(Centered)`
  flex-direction: column;
  width: 100%;
  padding: 10px;
  &:nth-child(even) {
    background-color: #eee;
  }
`;
const StopName = styled(TextTitle)`
  color: green;
  font-size: 14px;
  text-align: left;
  width: 100%;

  svg {
    margin-right: 5px;
  }
`;
const RouteName = styled(TextTitle)`
  color: blue;
  svg {
    margin-right: 10px;
  }
`;
const DriverName = styled(TextTitle)`
  font-size: 14px;
  color: white;
`;
const DriverPhone = styled(TextTitle)`
  color: white;
  margin-left: 10px;
  font-size: 14px;
`;
const DriverStopCount = styled(TextTitle)`
  color: white;
  margin-left: 10px;
  font-size: 14px;
  text-transform: lowercase;
  svg {
    margin-right: 5px;
  }
`;

const DriverStop = ({ stop }) => {
  LOGGING && console.log("DriverStop rendering with stop:", stop);
  return (
    <Stop>
      <StopName>
        <FontAwesomeIcon icon={stop.stopType === "meal" ? faHouse : faUser} />
        {stop.names[0]}
        {stop.phones?.length > 0 ? ` ${stop.phones[0]}` : null}
      </StopName>
      <StopName>{stop.address}</StopName>
    </Stop>
  );
};

export const PickUpRoutes = ({ mealType, routes }) => {
  LOGGING && console.log("PickUpRoutes got routes:", routes);

  return (
    <ContentContainer>
      <PickUpDriverContent>
        <RouteName>{`${mealType.toUpperCase()} ROUTES`}</RouteName>
      </PickUpDriverContent>
      {routes.map((route) => {
        return (
          <DriverContainer>
            <DriverInfo>
              <DriverName>
                <FontAwesomeIcon icon={faCarSide} />
                {route.driverFirstName}
              </DriverName>
              {route.driverPhone ? (
                <DriverPhone>
                  <FontAwesomeIcon icon={faMobile} />
                  {route.driverPhone}
                </DriverPhone>
              ) : null}
              <DriverStopCount>
                <FontAwesomeIcon icon={faStop} />x {route.stops.length}
              </DriverStopCount>
            </DriverInfo>
            <DriverStops>
              {route.stops.map((s) => (
                <DriverStop stop={s} />
              ))}
            </DriverStops>
          </DriverContainer>
        );
      })}
    </ContentContainer>
  );
};
