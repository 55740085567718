import { CONFIG_KEY } from '../../constants';
import { SET_CONFIG_ADDRESS_LIST } from '../actionTypes';

const config = (state = {}, action) => {
  switch (action.type) {
    case SET_CONFIG_ADDRESS_LIST:
      return {
        ...state,
        ...{
          [CONFIG_KEY.ADDRESS_WHITE_LIST]: action.payload.addressWhiteList,
          [CONFIG_KEY.ADDRESS_BLACK_LIST]: action.payload.addressBlackList,
        },
      };
    default:
      return state;
  }
};
export default config;
