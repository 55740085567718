import React, { ReactElement, useCallback, useState } from 'react';
import { useAsync } from 'react-async';
import { GetNextUnconfirmedOrderForMeal } from 'src/api/meals';
import { SetRestaurantConfirmTimeForOrder } from 'src/api/orders';
import { Button } from 'src/components/Button';
import { ObjectId } from 'src/constants/types';
import styled from 'styled-components';
import { Loading } from '..';
import { OrderReceipt } from './OrderReceipt';

interface PrintOrderReceiptsOneByOneContainerProps {
  mealId: string;
}

const CenterTextContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const ButtonCenterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const PrintOrderReceiptsOneByOneContainer: React.FC<PrintOrderReceiptsOneByOneContainerProps> =
  ({ mealId }): ReactElement => {
    const [orderId, setOrderId] = useState<ObjectId | null>(null);

    const confirmOrder = useCallback(async () => {
      await SetRestaurantConfirmTimeForOrder(orderId);
      setOrderId(null);
    }, [orderId]);
    const fetch = useCallback(async ({ mealId }) => {
      const result = await GetNextUnconfirmedOrderForMeal(mealId);
      setOrderId(result.order?._id);
      return result;
    }, []);

    const { data } = useAsync({ promiseFn: fetch, watch: orderId, mealId });

    if (data == null) return <Loading />;

    return data.order != null ? (
      <>
        <OrderReceipt
          order={data.order}
          onCancelItem={null}
          onCancelSelectionItem={null}
          receiptFont={true}
        />
        <ButtonCenterContainer>
          <Button type="button" onClick={confirmOrder}>
            Next Order
          </Button>
        </ButtonCenterContainer>
      </>
    ) : (
      <CenterTextContainer>No more orders to print</CenterTextContainer>
    );
  };
