import { HTMLProps, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Flex } from '../Layout';

type TabBarProps = {
  items: string[];
  active?: string;
  onSelected: (item: string) => void;
} & HTMLProps<HTMLDivElement>;

export const TabBar = (props: TabBarProps) => {
  const { items, active, onSelected, ...rest } = props;

  const [selected, setSelected] = useState<string>(items[0]);

  useEffect(() => {
    if (active) {
      setSelected(active);
    }
  }, [active]);

  const onSubNavItemSelected = (item: string) => {
    setSelected(item);
    onSelected(item);
  };

  return (
    <div {...rest}>
      <Flex heightFull justify="center" align="center">
        {items.map((item) => (
          <TabBarItem
            onClick={() => onSubNavItemSelected(item)}
            key={item}
            $selected={item === selected}
          >
            {item}
          </TabBarItem>
        ))}
      </Flex>
    </div>
  );
};

const TabBarItem = styled.button<{ $selected: boolean }>`
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  letter-spacing: 1.8px;
  height: 50px;
  text-transform: uppercase;
  margin: 0px 20px;

  ${({ $selected }) =>
    $selected
      ? `
        font-weight: 700;
      `
      : ''}
`;
