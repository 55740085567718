import React, { ReactElement, useCallback } from "react";
import { useAsync } from "react-async";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { GetReferralLinkDetails } from "src/api/referrals";
import { LOGGING, Loading } from "..";
import { MyReferralCodeEventMember } from "./MyReferralCodeEventMember";
import { MyReferralCodeEventNonMember } from "./MyReferralCodeEventNonMember";
export interface MyReferralCodeEventContainerProps {}

export const MyReferralCodeEventContainer: React.FC<MyReferralCodeEventContainerProps> =
  ({}: MyReferralCodeEventContainerProps): ReactElement => {
    const currentUser = useSelector((state: any) => state.currentUser);

    const isMember = ["active", "trialing"].includes(
      currentUser?.user?.membership?.status
    );
    const isCanceledMember =
      currentUser?.user?.membership?.status === "canceled";

    const fetch = useCallback(async () => {
      return await GetReferralLinkDetails(currentUser?.user?._id);
    }, [currentUser?.user?._id]);

    const { data } = useAsync({ promiseFn: fetch });

    if (data == null) return <Loading />;
    const code = data?.couponCode?.code;
    LOGGING &&
      console.log("MyReferralCodeContainer got data from backend: ", {
        code,
        isMember,
        isCanceledMember,
      });

    if (code == null) return null;

    return isMember ? (
      <MyReferralCodeEventMember
        code={code}
        firstName={currentUser?.user?.firstName}
      />
    ) : (
      <MyReferralCodeEventNonMember />
    );
  };
