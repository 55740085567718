import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

export enum UserType {
  Unknown = 'Unknown',
  Guest = 'Guest',
  Driver = 'Driver',
  Ops = 'Ops',
  Restaurant = 'Restaurant',
  Admin = 'Admin',
  CustomerGroupAdmin = 'CustomerGroupAdmin',
  Customer = 'Customer',
}

export const useUserType = (): UserType => {
  const currentUser = useSelector((state: RootState) => state.currentUser);

  if (!currentUser.isAuthenticated) {
    return UserType.Guest;
  } else if (currentUser.user.isDriver) {
    return UserType.Driver;
  } else if (currentUser.user.isOps) {
    return UserType.Ops;
  } else if (currentUser.user.isRestaurant) {
    return UserType.Restaurant;
  } else if (currentUser.user.isAdmin) {
    return UserType.Admin;
  } else if (currentUser.user.isUserGroupAdmin) {
    return UserType.CustomerGroupAdmin;
  } else {
    return UserType.Customer;
  }
};
