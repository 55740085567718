import { faBullhorn as faAttention } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import "./DishInMenu.scss";

const DishImage = ({
  imageURL,
  name,
  optionIndex,
  optionTotal,
  countInCart,
  onClickCountInCart,
}) => {
  const history = useHistory();
  const defaultOnClickCountInCart = () => {
    history.push("/cart");
  };
  return (
    <div className="dish-image">
      <div className="option-index">
        <span>{`${optionIndex + 1} of ${optionTotal}`}</span>
      </div>
      <img src={imageURL} alt={name}></img>
      {/* {countInCart > 0 ? (
        <div className="count-in-cart" onClick={onClickCountInCart || defaultOnClickCountInCart}>
          <span className="count">{countInCart}</span>
          <span className="already">already</span>
          <span className="in-cart">in cart</span>
        </div>
      ) : null} */}
    </div>
  );
};
const DishBasic = ({ dish }) => (
  <div className="dish-info-upper">
    <div className="dish-name">{dish.shortName || dish.name}</div>

    {dish.preOrdered ? (
      <div className="dish-note">
        <FontAwesomeIcon icon={faAttention} />
        <span>limited supply</span>
      </div>
    ) : null}
    {dish.description ? (
      <div className="dish-description">{`${dish.description
        .slice(0, 1)
        .toUpperCase()}${dish.description.slice(1)}${
        dish.selectionDescription ? " " + dish.selectionDescription : ""
      }`}</div>
    ) : null}
  </div>
);
const DishPrice = ({ dish }) => (
  <div className="dish-info-lower">
    <div className="dish-price-wrapper">
      {/* <div className="dish-price">
        <span className="price-type">doordash</span>
        <div className="price-value doordash-price">
          {dish.price ? (
            <span>${dish.price.toFixed(2)}</span>
          ) : (
            <span className="not-delivered">Not delivered</span>
          )}
        </div>
      </div> */}
      <div className="dish-price">
        <div className="price-value our-price">
          {dish && dish.priceFoodieListed > 0 ? (
            <span>${dish.priceFoodieListed.toFixed(2)}</span>
          ) : (
            <span>$Variable</span>
          )}
        </div>
      </div>
    </div>
  </div>
);
const DishInfo = ({ dish }) => (
  <div className="dish-info">
    <DishBasic dish={dish} />
    <DishPrice dish={dish} />
  </div>
);

const DishComment = ({ preOrdered, onEditComment, dishInCart }) => (
  <div className={`dish-comment ${preOrdered ? "disabled" : ""}`}>
    <label>special requests (optional)</label>
    <textarea
      onChange={onEditComment}
      disabled={preOrdered}
      placeholder={
        preOrdered
          ? "To ensure timely deliveries, we don't take special requests for this restaurant."
          : "e.g. no onion, etc."
      }
      value={dishInCart ? dishInCart.comment : ""}
    />
  </div>
);

export { DishComment, DishInfo, DishImage };
