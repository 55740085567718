import "./Back.scss";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Back = ({
  onClick,
  icon = faChevronLeft,
  leftEnd = true,
  style = {},
}) => (
  <button
    className={`back-button ${leftEnd ? "left-end" : ""}`}
    type="button"
    onClick={onClick}
    style={style}
  >
    <FontAwesomeIcon icon={icon} />
  </button>
);
export default Back;
