import { on } from "events";
import React, { useEffect, useState } from "react";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { rest, set } from "lodash";
import { useSelector, useDispatch, useStore } from "react-redux";
import { LOGGING } from "src/constants";
import { RootState } from "src/store";
import styled from "styled-components";
import {
  ActionUnderline,
  BackgroundGrey,
  Beige,
  centered,
  Centered,
  text12300,
} from "../Shared";

const Container = styled(Centered)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  justify-content: flex-start;
  padding-left: 100px;
  /* padding-top: 10px;   */
`;
const ToggleShowCuisines = styled.button`
  svg {
    margin-left: 4px;
  }
  margin-right: 20px;
  ${text12300}
  position: absolute;
  top: 6px;
  left: 8px;
  background-color: ${BackgroundGrey};
  padding: 5px;
  border-radius: 10px;
  line-height: 1.5;
`;
const CuisineList = styled(Centered)`
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 10px;
`;
const Cuisine = styled.button<{ isHighlighted: boolean }>`
  ${centered}
  ${text12300}
    border: 1px solid black;
  margin: 0px 5px 5px 0px;
  padding: 0px 5px;
  border-radius: 5px;
  text-transform: capitalize;
  background-color: ${(props) => (props.isHighlighted ? "black" : "white")};
  color: ${(props) => (props.isHighlighted ? "white" : "black")};
  span {
    margin-right: 3px;
  }
  span:last-child {
    margin-right: 0px;
  }
`;
export const WeekCheck = ({
  week,
  cuisineFilters,
  onFilterByCuisine,
  onToggleShowCuisineFilter,
  showCuisineFilter,
}) => {
  const meals = useSelector((state: RootState) => state.meals);
  const [cuisines, setCuisines] = useState([]);
  const [cuisineSummary, setCuisineSummary] = useState({});
  const onToggleHighlighedCuisine = (cuisine, e) => {
    e.preventDefault();
    onFilterByCuisine(cuisine);
  };
  useEffect(() => {
    // LOGGING && console.log("WeekCheck rendering with", { week, meals });
    if (showCuisineFilter) {
      const windowStarts = week.reduce((acc, day) => {
        const { lunchWindowStart, earlyDinnerWindowStart, dinnerWindowStart } =
          day;
        return acc.concat([
          lunchWindowStart,
          earlyDinnerWindowStart,
          dinnerWindowStart,
        ]);
      }, []);
      const weekMeals = meals.filter((meal) =>
        windowStarts.includes(meal.windowStart)
      );
      // LOGGING && console.log("WeekCheck rendering with weekMeals", weekMeals);
      const cuisineSummary = weekMeals.reduce((acc, meal) => {
        const { restaurant } = meal;
        if (restaurant?.cuisines?.length > 0) {
          restaurant.cuisines.forEach((cuisine) => {
            acc[cuisine.name] = (acc[cuisine.name] || 0) + 1;
          });
        }
        return acc;
      }, {});
      // sort by count
      // LOGGING &&
      //   console.log("WeekCheck rendering with cuisineSummary", cuisineSummary);
      const sortedCusines = Object.keys(cuisineSummary).sort(
        (a, b) => cuisineSummary[b] - cuisineSummary[a]
      );
      // LOGGING &&
      //   console.log("WeekCheck rendering with sortedCusines", sortedCusines);
      setCuisines(sortedCusines);
      setCuisineSummary(cuisineSummary);
    }
  }, [showCuisineFilter]);
  // LOGGING && console.log("WeekCheck rendering with cuisineSummary", cuisines);
  return (
    <Container>
      <ToggleShowCuisines onClick={onToggleShowCuisineFilter}>
        cusines
        <FontAwesomeIcon
          icon={showCuisineFilter ? faChevronUp : faChevronDown}
        />
      </ToggleShowCuisines>
      {showCuisineFilter ? (
        <CuisineList>
          {cuisines.map((cuisine) => {
            return (
              <Cuisine
                key={cuisine}
                onClick={onToggleHighlighedCuisine.bind(this, cuisine)}
                isHighlighted={cuisineFilters?.includes(cuisine)}
              >
                <span>{cuisine}</span>
                <span>{cuisineSummary[cuisine]}</span>
              </Cuisine>
            );
          })}
        </CuisineList>
      ) : null}
    </Container>
  );
};
