import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { UserType, useUserType } from 'src/hooks/useUserType';
import { RootState } from 'src/store';
import styled from 'styled-components';
import { MealMenuAdminDataContainer } from '../mealMenu/MealMenuAdminDataContainer';
import { RestaurantMealMenuNavBar } from './RestaurantMealMenuNavBar';

interface PageRestaurantMealMenuProps {}
interface ParamType {
  mealId: string;
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 36px;
`;

export const PageRestaurantMealMenu: React.FC<PageRestaurantMealMenuProps> = (
  props
): ReactElement => {
  const { mealId } = useParams<ParamType>();
  const userType = useUserType();
  const currentUser = useSelector((state: RootState) => state.currentUser);

  if (userType !== UserType.Restaurant) {
    return <Redirect to="/" />;
  }

  return (
    <div className="page">
      <RestaurantMealMenuNavBar mealId={mealId} />
      <ContentContainer>
        <MealMenuAdminDataContainer
          restaurantId={currentUser.user.restaurant._id}
          mealId={mealId}
        />
      </ContentContainer>
    </div>
  );
};
