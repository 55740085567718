import React from 'react';
import './RestaurantMeals.scss';

const Meal = ({ dishes, type, date }) => (
  <div className="restaurant-meal">
    <div className="meal-time">{`${type} ${date}`}</div>
    <div className="meal-dishes">
      {dishes
        .filter((d) => d.quantity > 0)
        .sort((a, b) => (a.quantity > b.quantity ? -1 : a.quantity < b.quantity ? 1 : 0))
        .map((dish, index) => (
          <div className="meal-dish" key={index}>
            <div className="dish-content">
              <div className="dish-name">{dish.name}</div>
              <div className="dish-quantity">{dish.quantity}</div>
            </div>
            <div className="dish-special">
              {Object.keys(dish.special).map((comment) => (
                <div className="dish-comment">
                  <div className="comment">{comment}</div>
                  <div className="quantity">{dish.special[comment]}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
    </div>
  </div>
);

const RestaurantMeals = ({ meals }) => (
  <div className="restaurant-meals">
    {meals.map(({ dishes, mealName, date }, index) => (
      <Meal dishes={dishes} type={mealName} date={date} key={index} />
    ))}
  </div>
);

export default RestaurantMeals;
