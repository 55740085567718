import { LOGGING } from 'src/constants';
import { apiCall } from 'src/store/api';

export type SerializedRestaurantPayment = {
  _id: string;
  startDate: string;
  endDate: string;
  subtotal: number;
  tax: number;
  commission: number;
  adjustments: number;
  payout: number;
};
export const GetRestaurantPaymentsForRestaurant = async (
  restaurantId: string
): Promise<{ restaurantPayments: SerializedRestaurantPayment[] }> => {
  try {
    return await apiCall('POST', `/restaurantPayments/GetRestaurantPaymentsForRestaurant`, {
      restaurantId,
    });
  } catch (err) {
    LOGGING && console.log('GetRestaurantPaymentsForRestaurant got err', err);
    return { restaurantPayments: [] };
  }
};
