import { LOGGING } from '../../constants';
import { apiCall } from '../api';

// ==============================================================
// TODO: Move non-store related functions out to a separate file.
// ==============================================================

export const genTaxRateForMealId = async (mealId) => {
  LOGGING && console.log('getTaxRateForMealId called with mealId:', mealId);
  const result = await apiCall('POST', `/pricing/GetTaxRateForMeal`, { mealId: mealId });
  return result.taxRate;
};
