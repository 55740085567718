import React from "react";
import "./Setting.scss";
import {
  faPlus as faAdd,
  faTimes as faCancel,
  faPencil as faEdit,
  faUserCog,
  faEnvelope,
  faUser,
  faUserClock,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Loading, Back, DeliveryInfoForm, NewCardForm, ExistingCard } from ".";

const CommonSetting = ({
  user,
  addNewCard,
  cardType,
  deliveryInfo,
  error,
  existingCard,
  // loading,
  newCard,
  onAddNewCard,
  onCancelNewCard,
  onChange,
  onChangeNumber,
  onDeleteCard,
  onEditAddress,
  onSelectAddress,
  onToggleShowCardAction,
  onToggleRingBell,
  showCardAction,
  isCorporate,
}) => (
  <React.Fragment>
    <DeliveryInfoForm
      deliveryInfo={deliveryInfo}
      onChange={onChange}
      onChangeNumber={onChangeNumber}
      error={error}
      onEditAddress={onEditAddress}
      onToggleRingBell={onToggleRingBell}
      onSelectAddress={onSelectAddress}
      isCorporate={true}
      user={user}
    />
    <div className="form-section">
      <div className="form-section-title">
        {isCorporate ? <span>3. Payment</span> : <span>Payment</span>}
      </div>
      {existingCard && existingCard.brand && existingCard.last4 ? (
        <ExistingCard
          onToggleShowCardAction={onToggleShowCardAction}
          showCardAction={showCardAction}
          onDeleteCard={onDeleteCard}
          existingCard={existingCard}
        />
      ) : (
        <React.Fragment>
          {addNewCard && (
            <NewCardForm
              onChangeNumber={onChangeNumber}
              newCard={newCard}
              cardType={cardType}
              error={error}
            />
          )}
          <button
            className={`setting-action ${addNewCard ? "cancel-new-card" : ""}`}
            onClick={addNewCard ? onCancelNewCard : onAddNewCard}
          >
            <FontAwesomeIcon icon={addNewCard ? faCancel : faAdd} />
            {`${addNewCard ? "cancel" : "add"} new card`}
          </button>
        </React.Fragment>
      )}
    </div>
  </React.Fragment>
);

const UserSetting = ({
  addNewCard,
  cardType,
  deliveryInfo,
  error,
  existingCard,
  hasPassword,
  loading,
  newCard,
  onAddNewCard,
  onBack,
  onCancelNewCard,
  onChange,
  onChangeNumber,
  onChangeReceiveMarketingEmail,
  onDeleteCard,
  onEditAddress,
  onToggleRingBell,
  onHome,
  onSelectAddress,
  onSubmit,
  onToggleShowCardAction,
  receiveMarketingEmail,
  showCardAction,
}) => (
  <React.Fragment>
    {loading && <Loading />}
    <div className="list">
      <div className="list-title">
        <Back onClick={onBack} />
        <span>my settings</span>
      </div>
      <div className="list-body">
        <div className="checkout">
          <CommonSetting
            addNewCard={addNewCard}
            cardType={cardType}
            deliveryInfo={deliveryInfo}
            error={error}
            existingCard={existingCard}
            loading={loading}
            newCard={newCard}
            onAddNewCard={onAddNewCard}
            onCancelNewCard={onCancelNewCard}
            onChange={onChange}
            onChangeNumber={onChangeNumber}
            onDeleteCard={onDeleteCard}
            onEditAddress={onEditAddress}
            onToggleRingBell={onToggleRingBell}
            onSelectAddress={onSelectAddress}
            onToggleShowCardAction={onToggleShowCardAction}
            showCardAction={showCardAction}
          />
          <div className="form-section newsletter">
            <div className="form-section-title">
              <span>Newsletter</span>
            </div>
            <div className="setting-section">
              <label for="receiveMarketingEmail">
                <input
                  type="checkbox"
                  name="receiveMarketingEmail"
                  checked={receiveMarketingEmail}
                  onChange={onChangeReceiveMarketingEmail}
                />
                Receive daily menu email
              </label>
            </div>
          </div>
          {hasPassword && (
            <div className="form-section">
              <div className="form-section-title">
                <span>Password</span>
              </div>
              <Link className="setting-action" to="/changepassword">
                <FontAwesomeIcon icon={faEdit} />
                <span>change password</span>
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className="list-actions">
        <button onClick={onHome}>Cancel</button>
        <button onClick={onSubmit}>Save</button>
      </div>
    </div>
  </React.Fragment>
);

const GroupUsersSetting = ({
  groupUsers,
  newGroupUserEmails,
  onChangeNewGroupUserEmails,
  onInviteNewGroupUsers,
  inviteNewGroupUsersError,
  groupInvites,
  budget,
  serviceFee,
  onChange,
}) => {
  return (
    <>
      <div className="form-section budget">
        <div className="form-section-title">
          <span>4. Dinner/Lunch budget</span>
        </div>
        <div className="form-row">
          <div className="form-field-wrapper">
            <input
              value={budget.lunch || ""}
              name="lunch"
              placeholder="enter lunch budget"
              onChange={(e) => onChange("budget", e)}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-field-wrapper">
            <input
              value={budget.dinner || ""}
              name="dinner"
              placeholder="enter dinner budget"
              onChange={(e) => onChange("budget", e)}
            />
          </div>
        </div>
      </div>
      <div className="form-section budget">
        <div className="form-section-title">
          <span>5. Service Fee</span>
        </div>
        <div className="form-row">
          <div className="form-field-wrapper">
            <input
              value={serviceFee || ""}
              readOnly
              placeholder="Your service fee"
            />
          </div>
        </div>
      </div>
      <div className="form-section">
        <div className="form-section-title">
          <span>6. Team Members</span>
        </div>
        <div className="setting-section">
          {groupUsers
            .sort((a, b) =>
              a.isUserGroupAdmin && !b.isUserGroupAdmin ? -1 : 1
            )
            .map((user, i) => (
              <div className="user">
                <div className="user-field name">
                  <FontAwesomeIcon
                    icon={user.isUserGroupAdmin ? faUserCog : faUser}
                  />
                  {user.isPendingActivation
                    ? "pending response"
                    : `${user.firstName} ${user.lastName}`}
                  {user.isUserGroupAdmin && " (Group Admin)"}
                </div>
                <div className="user-field email">
                  <FontAwesomeIcon icon={faEnvelope} />
                  {user.email}
                </div>
              </div>
            ))}
          {groupInvites.map((invite, i) => (
            <div className="user pending">
              <div className="user-field name">
                <FontAwesomeIcon icon={faUserClock} />
                pending response
              </div>
              <div className="user-field email">
                <FontAwesomeIcon icon={faEnvelope} />
                {invite.email}
              </div>
            </div>
          ))}
        </div>
        <div className="user invite">
          <textarea
            value={newGroupUserEmails}
            onChange={onChangeNewGroupUserEmails}
            name="newGroupUserEmails"
            placeholder="emails separated by comma"
          />
          <div
            className={`error-message ${
              inviteNewGroupUsersError ? "has-error" : "no-error"
            }`}
          >
            {inviteNewGroupUsersError}
          </div>
          <button className="invite-users" onClick={onInviteNewGroupUsers}>
            Invite Users
          </button>
        </div>
      </div>
    </>
  );
};

const GroupSetting = ({
  adminUser,
  addNewCard,
  cardType,
  deliveryInfo,
  budget,
  serviceFee,
  error,
  existingCard,
  groupName,
  groupUsers,
  groupInvites,
  hasPassword,
  inviteNewGroupUsersError,
  loading,
  newCard,
  newGroupUserEmails,
  onAddNewCard,
  onBack,
  onCancelNewCard,
  onChange,
  onChangeNewGroupUserEmails,
  onChangeNumber,
  onDeleteCard,
  onEditAddress,
  onHome,
  onInviteNewGroupUsers,
  onSelectAddress,
  onSubmit,
  onToggleShowCardAction,
  onToggleRingBell,
  showCardAction,
}) => (
  <React.Fragment>
    {loading && <Loading />}
    <div className="list">
      <div className="list-title">
        <Back onClick={onBack} />
        <span>Manage Group {groupName} & Settings</span>
      </div>
      <div className="list-body">
        <div className="checkout">
          <CommonSetting
            user={adminUser}
            addNewCard={addNewCard}
            cardType={cardType}
            deliveryInfo={deliveryInfo}
            error={error}
            existingCard={existingCard}
            loading={loading}
            newCard={newCard}
            onAddNewCard={onAddNewCard}
            onCancelNewCard={onCancelNewCard}
            onChange={onChange}
            onChangeNumber={onChangeNumber}
            onDeleteCard={onDeleteCard}
            onEditAddress={onEditAddress}
            onToggleRingBell={onToggleRingBell}
            onSelectAddress={onSelectAddress}
            onToggleShowCardAction={onToggleShowCardAction}
            showCardAction={showCardAction}
            isCorporate={true}
          />
          <GroupUsersSetting
            groupUsers={groupUsers}
            groupName={groupName}
            groupInvites={groupInvites}
            newGroupUserEmails={newGroupUserEmails}
            onChangeNewGroupUserEmails={onChangeNewGroupUserEmails}
            onInviteNewGroupUsers={onInviteNewGroupUsers}
            inviteNewGroupUsersError={inviteNewGroupUsersError}
            budget={budget}
            serviceFee={serviceFee}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="list-actions">
        <button onClick={onHome}>Cancel</button>
        <button onClick={onSubmit}>Save</button>
      </div>
    </div>
  </React.Fragment>
);

export { UserSetting, GroupSetting };
