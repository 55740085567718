import "./CartList.scss";
import moment from "moment-timezone";
import { PhoneFormat } from ".";
const Cart = ({ cart, onFinishCart }) => {
  const totalItems = cart.goods.reduce((acc, g) => acc + g.quantity, 0);
  return (
    <div className="cart">
      {cart._id && <div className="cart-field id">{`${cart._id}`}</div>}
      {cart.createdAt && (
        <div className="cart-field created">{`Created: ${moment(
          cart.createdAt
        ).format("ddd MMM DD h:mmA")}`}</div>
      )}
      {cart.createdAt && (
        <div className="cart-field updated">{`Updated: ${moment(
          cart.updatedAt
        ).format("ddd MMM DD h:mmA")}`}</div>
      )}
      {cart.mealType && (
        <div className="cart-field meal type">{`${cart.mealType}`}</div>
      )}
      {cart.meal?.restaurant?.name && (
        <div className="cart-field restaurant">{`${cart.meal.restaurant.name}`}</div>
      )}
      {cart.goods && (
        <div className="cart-field goods">{`Total Items: ${totalItems}`}</div>
      )}
      {cart.due && <div className="cart-field due">{`Due: $${cart.due}`}</div>}
      <div className="cart-field name">{`${cart.user?.firstName} ${cart.user?.lastName}`}</div>
      {(cart.user?.phone ||
        (cart.user?.deliveryInfo && cart.user.deliveryInfo?.phone)) && (
        <div className="cart-field phone">
          {`${PhoneFormat(
            cart.user.phone ||
              (cart.user.deliveryInfo && cart.user.deliveryInfo.phone)
          )}`}
        </div>
      )}
      {(cart.user?.email ||
        (cart.user?.deliveryInfo && cart.user.deliveryInfo?.email)) && (
        <div className="cart-field email">{`${
          cart.user.email ||
          (cart.user.deliveryInfo && cart.user.deliveryInfo.email)
        }`}</div>
      )}
      {(cart.user?.address ||
        (cart.user?.deliveryInfo && cart.user.deliveryInfo?.address)) && (
        <div className="cart-field address">{`${
          cart.user.address ||
          (cart.user.deliveryInfo && cart.user.deliveryInfo.address)
        }`}</div>
      )}
      <button onClick={onFinishCart}>
        <span>Finish Cart</span>
      </button>
    </div>
  );
};

const CartList = ({ carts, onFinishCart }) => {
  return (
    <div className="cart-list">
      <div className="carts">
        {carts
          .sort((a, b) => a.window?.start - b.window?.start)
          .map((cart) => (
            <Cart cart={cart} onFinishCart={onFinishCart.bind(this, cart)} />
          ))}
      </div>
    </div>
  );
};

export default CartList;
