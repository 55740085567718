import React, { ReactElement, useCallback, useState, useEffect } from "react";
import { faHeart as faCheck } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faEmpty } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch, useStore } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "src/store";
import styled from "styled-components";
import { toggleFavorite } from "../../../../store/actions";
import { card, centered, MealHeight, Beige, NavHeight } from "../Shared";
import { FavoriteConfirmation } from "./FavoriteConfirmation";
const BreakPoint = 800;

interface ButtonContainerProps {
  isFavorite: boolean;
  position?: any;
  isNav?: boolean;
  isMobile?: boolean;
}
const ButtonContainer = styled.button<ButtonContainerProps>`
  ${card}
  background-color: transparent;
  box-shadow: none;
  margin: 0px;
  padding: 0px;
  position: absolute;
  /* top: ${NavHeight + 5}px;
  left: 20px; */
  top: 0px;
  right: ${(props) => (props.isNav ? `auto` : "0px")};
  left: ${(props) => (props.isNav ? `20px` : "auto")};
  z-index: 1;
  pointer-events: ${(props) => (props.disabled ? "none" : null)};
  width: ${(props) => (props.isNav ? `55px` : "45px")};
  height: ${(props) => (props.isNav ? `55px` : "45px")};
  display: ${(props) => (props.isMobile === false ? "none" : "flex")};
  align-items: center;
  justify-content: center;
  svg {
    font-size: 20px;
    color: ${(props) => (props.isFavorite ? "#ea4c89" : "grey")};
  }
  @media only screen and (min-width: ${BreakPoint}px) {
    display: flex;
    background-color: rgba(255, 255, 255, 0.8);
    width: 35px;
    height: 35px;
    left: ${(props) => props.position?.left || 7}px;
    top: ${(props) => props.position?.top || 7}px;
    /* left: auto; */
    border-radius: 50%;
    z-index: 99;
    svg {
      font-size: 17px;
    }
    display: ${(props) => (props.isMobile ? "none" : "flex")};
  }
`;

const AnonymousFavoriteButton = styled(Link)`
  ${centered}
  background-color: ${Beige};
  width: 25px;
  height: 25px;
  margin: 0px;
  padding: 0px;
  border-radius: 6px;
  position: absolute;
  top: 7px;
  right: 7px;
  svg {
    font-size: 20px;
    color: grey;
  }
`;
export interface FavoriteButtonProps {
  restaurantId: String;
  restaurantName: String;
  position?: any;
  isNav?: boolean;
  isMobile?: boolean;
}
export const FavoriteButton: React.FC<FavoriteButtonProps> = ({
  restaurantId,
  restaurantName,
  position,
  isNav,
  isMobile,
}: FavoriteButtonProps): ReactElement => {
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const [isFavorite, setIsFavorite] = useState(
    currentUser?.user?.favorites?.includes(String(restaurantId))
  );
  const dispatch = useDispatch();
  const store = useStore();
  const [lockButton, setLockButton] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const isLoggedIn = currentUser?.isAuthenticated;

  useEffect(() => {
    // hook called first render
    setIsFavorite(currentUser?.user?.favorites?.includes(String(restaurantId))); // state updated with same value
  }, [restaurantId, currentUser]); //

  const toggle = useCallback(
    async (restaurantId) => {
      setIsFavorite(!isFavorite);
      // setLockButton(true);
      await toggleFavorite(restaurantId)(dispatch, store.getState);
      setShowConfirmation(true);
      setTimeout(() => {
        setShowConfirmation(false);
      }, 1500);
      // setLockButton(false);
    },
    [currentUser]
  );

  return isLoggedIn ? (
    <>
      <FavoriteConfirmation
        restaurantName={restaurantName}
        visible={showConfirmation}
        status={isFavorite}
      />

      <ButtonContainer
        disabled={lockButton}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toggle(restaurantId);
        }}
        position={position}
        isFavorite={isFavorite}
        isNav={isNav}
        isMobile={isMobile}
      >
        <FontAwesomeIcon icon={isFavorite ? faCheck : faEmpty} />
      </ButtonContainer>
    </>
  ) : (
    <AnonymousFavoriteButton
      to={{
        pathname: "/signin",
        state: {
          title: "sign in to edit favorites",
          next: "/restaurants",
          secondary: { title: "Create an Account", next: "/signup" },
        },
      }}
    >
      <FontAwesomeIcon icon={faCheck} />
    </AnonymousFavoriteButton>
  );
};
