import { text } from "stream/consumers";
import React from "react";
import styled from "styled-components";
import { LOGGING } from "../tasting_menu";
import { text12300 } from "../tasting_menu/components/Shared";

const ToggleSwitch = ({ checked, onChange, dayOfWeek, mealType }) => {
  return (
    <SwitchContainer>
      <Input
        type="checkbox"
        id={`switch${dayOfWeek}${mealType}`}
        checked={checked}
        onChange={() => {
          LOGGING &&
            console.log(
              "ToggleSwitch.tsx: onChange: dayOfWeek: ",
              dayOfWeek,
              "mealType: ",
              mealType
            );
          onChange(dayOfWeek, mealType);
        }}
      />
      <Label htmlFor={`switch${dayOfWeek}${mealType}`} checked={checked}>
        <span>{checked ? "Yes" : "No"}</span>
        <Slider checked={checked} />
      </Label>
    </SwitchContainer>
  );
};

const SwitchContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 24px;
`;

const Input = styled.input`
  display: none;
`;

const Label = styled.label<{ checked: boolean }>`
  position: absolute;
  ${text12300}
  font-size: 10px;
  font-weight: 400;
  top: 0;
  left: 0;
  /* width: calc(100% - 20px); */
  height: 100%;
  width: 100%;
  background-color: ${({ checked }) => (checked ? "green" : "grey")};
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: ${({ checked }) => (checked ? "flex-start" : "flex-end")};
  padding: 0px;
  /* padding-left: ${({ checked }) => (checked ? "10px" : "0px")};
  padding-right: ${({ checked }) => (checked ? "0px" : "5px")}; */
  color: white;
  span {
    text-align: center;
    width: calc(100% - 20px);
  }
`;

const Slider = styled.div<{ checked: boolean }>`
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
  position: absolute;
  left: ${({ checked }) => (checked ? "auto" : "2px")};
  right: ${({ checked }) => (checked ? "2px" : "auto")};
`;

export default ToggleSwitch;
