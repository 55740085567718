import React from 'react';
import './BookmarkList.scss';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dish, CountSection, ProfileLink } from '.';
import { BookmarkStatus } from '../../../constants';

const BookmarkList = ({
  bookmarks,
  showRecommenders,
  onShowBookmarkDetails,
  onShowRecommenders,
  onHideRecommenders,
}) => (
  <div className="bookmark-list">
    {bookmarks.map((bookmark, index) => (
      <div key={index} className="bookmark-thumbnail">
        <div className="recommender statement">
          <ProfileLink user={bookmark.post.user} />
          <span className="recommends">{' recommends'}</span>
        </div>
        <Dish key={bookmark._id} dish={bookmark.post.dish} restaurant={bookmark.post.restaurant} />
        {bookmark.post.bookmarks.filter((b) => b.status === BookmarkStatus.LIKED).length > 0 ? (
          <CountSection
            bookmarks={bookmark.post.bookmarks}
            onShowRecommenders={onShowRecommenders.bind(this, bookmark._id)}
            showRecommenders={showRecommenders === bookmark._id}
            onHideRecommenders={onHideRecommenders}
          />
        ) : null}
        {bookmark.review && bookmark.review.content ? (
          <p className="review">
            <span className="author">{bookmark.user.firstName}</span>
            {bookmark.review.content}
          </p>
        ) : null}

        {onShowBookmarkDetails ? (
          <button
            className="bookmark-thumbnail-review"
            type="button"
            onClick={onShowBookmarkDetails.bind(this, bookmark._id)}
          >
            <FontAwesomeIcon icon={faPencil} />
            {`${bookmark.status < BookmarkStatus.REVIEWED ? 'Mark as tried' : 'edit my review'}`}
          </button>
        ) : null}
      </div>
    ))}
  </div>
);

export default BookmarkList;
