import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Flex } from '../Layout';

type SubNavProps = {
  items: string[];
  active?: string;
  onSelected: (item: string) => void;
};

export const SubNav = (props: SubNavProps) => {
  const { items, active, onSelected } = props;

  const [selected, setSelected] = useState<string>(items[0]);

  useEffect(() => {
    if (active) {
      setSelected(active);
    }
  }, [active]);

  const onSubNavItemSelected = (item: string) => {
    setSelected(item);
    onSelected(item);
  };

  return (
    <>
      <SubNavContainer>
        <Flex heightFull justify="center" align="center">
          {items.map((item) => (
            <SubNavItem
              onClick={() => onSubNavItemSelected(item)}
              key={item}
              $selected={item === selected}
            >
              {item}
            </SubNavItem>
          ))}
        </Flex>
      </SubNavContainer>
      <SubNavPlaceHolder />
    </>
  );
};

const SubNavContainer = styled.div<{ $withNav?: boolean }>`
  height: var(--subnav-height);
  border-bottom: 1px solid #d9d9d9;
  background-color: var(--background-beige);
  box-sizing: border-box;
  width: 100%;
  position: fixed;
  top: var(--nav-height);
  left: 0px;
  z-index: 2;
`;

const SubNavPlaceHolder = styled.div`
  width: 100%;
  height: var(--subnav-height);
`;

const SubNavItem = styled.button<{ $selected: boolean }>`
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  letter-spacing: 1.8px;
  height: 50px;
  text-transform: uppercase;
  margin: 0px 20px;

  ${({ $selected }) =>
    $selected
      ? `
        font-weight: 700;
      `
      : ''}
`;
