import { useCallback, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Flex } from 'src/components/Layout';
import { swapDrivers } from 'src/store/actions/adminDeliveryV2';
import { Select } from '.';

export const SwapDrivers = ({ onSubmit }) => {
  const drivers = useSelector((state) => state.adminDeliveryV2.drivers);
  const dispatch = useDispatch();
  const store = useStore();

  const [driverOneIndex, setDriverOneIndex] = useState(null);
  const [showDriverOneOptions, setShowDriverOneOptions] = useState(false);
  const selectDriverOneIndex = useCallback((index) => {
    setDriverOneIndex(index);
    setShowDriverOneOptions(false);
  }, []);
  const toggleShowDriverOneOptions = useCallback(() => {
    setShowDriverOneOptions(!showDriverOneOptions);
  }, [showDriverOneOptions]);

  const [driverTwoIndex, setDriverTwoIndex] = useState(null);
  const [showDriverTwoOptions, setShowDriverTwoOptions] = useState(false);
  const selectDriverTwoIndex = useCallback((index) => {
    setDriverTwoIndex(index);
    setShowDriverTwoOptions(false);
  }, []);
  const toggleShowDriverTwoOptions = useCallback(() => {
    setShowDriverTwoOptions(!showDriverTwoOptions);
  }, [showDriverTwoOptions]);

  const acceptedDrivers = drivers.filter(
    (d) => d.askedAvailability === true && d.routeAcceptanceResponse === true
  );
  const options = acceptedDrivers.map((driver) => {
    return { _id: driver._id, name: driver.firstName };
  });

  const swapDriversCallback = useCallback(async () => {
    if (driverOneIndex == null || driverTwoIndex == null) return;

    const driverOneId = options[driverOneIndex]._id;
    const driverTwoId = options[driverTwoIndex]._id;

    await swapDrivers(driverOneId, driverTwoId)(dispatch, store.getState);

    onSubmit();
  }, [options, dispatch, store.getState, onSubmit, driverOneIndex, driverTwoIndex]);

  return (
    <div
      style={{
        zIndex: 1,
        position: 'fixed',
        width: 'calc(100vw - 40px)',
        maxWidth: '500px',
        padding: '20px 20px',
        top: '200px',
        backgroundColor: 'white',
      }}
    >
      <h2>Swap Drivers</h2>
      <Flex direction="column" align="start">
        <div style={{ margin: '12px 0' }}>
          <Flex align="center">
            <span>Driver 1</span>
            <Select
              selected={driverOneIndex}
              onSelect={selectDriverOneIndex}
              options={options.map((_d, i) => i)}
              optionWords={options.map((d) => d.name)}
              showOptions={showDriverOneOptions}
              onToggleShowOptions={toggleShowDriverOneOptions}
              onHideShowOptions={toggleShowDriverOneOptions}
            />
          </Flex>
        </div>
        <div style={{ margin: '12px 0' }}>
          <Flex align="center">
            <span>Driver 2</span>
            <Select
              selected={driverTwoIndex}
              onSelect={selectDriverTwoIndex}
              options={options.map((_d, i) => i)}
              optionWords={options.map((d) => d.name)}
              showOptions={showDriverTwoOptions}
              onToggleShowOptions={toggleShowDriverTwoOptions}
              onHideShowOptions={toggleShowDriverTwoOptions}
            />
          </Flex>
        </div>
      </Flex>
      <button
        style={{
          marginTop: '20px',
          backgroundColor: 'black',
          color: 'white',
          padding: '12px 16px',
          borderRadius: '4px',
          fontSize: '16px',
        }}
        onClick={swapDriversCallback}
      >
        Submit
      </button>
    </div>
  );
};
