import { faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { Image, Name } from "./CartDetails";
import {
  CenteredByColumn,
  CenteredByColumnLeft,
  card,
  text12300,
  Centered,
  BorderGrey,
} from "../../Shared";
const iconSize = 17;
const Dish = styled(Centered)`
  width: 100%;
  justify-content: flex-start;
`;

const Peek = styled(CenteredByColumn)<{ show?: boolean }>`
  position: fixed;
  top: 115px;
  right: 70px;
  ${card}
  width: 250px;
  height: auto;
  z-index: 102;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 10px 10px 5px 10px;
  transition: all 0.5s;
  background-color: rgba(0, 0, 0, 0.85);
  opacity: ${(props) => (props.show ? 1 : 0)};
  color: white;
  border-radius: 5px;
  @media only screen and (max-width: 500px) {
    display: none;
  }
`;
const ButtonHidePeek = styled.button`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: transparent;
  border: none;
  color: black;
  font-size: 16px;
  position: absolute;
  color: white;
`;

const DishToPeek = styled(CenteredByColumnLeft)`
  align-items: flex-end;
  width: 100%;
  margin-bottom: 10px;
`;
const Icon = styled(Centered)`
  width: ${iconSize}px;
  height: ${iconSize}px;
  line-height: ${iconSize}px;
  border-radius: 50%;
  background-color: green;
  margin-left: 7px;
  font-size: 12px;
  color: white;
`;

const Added = styled(Centered)`
  ${text12300}
  text-transform: capitalize;
  font-size: 11px;
  width: 100%;
  justify-content: flex-end;
  border-bottom: 0.3px solid ${BorderGrey};
  margin-bottom: 10px;
  padding-bottom: 8px;
`;

export const JustAdded = ({ show, onHide, goodsToPeek }) => {
  const added = goodsToPeek?.reduce((a, b: any) => a + b?.quantity, 0);
  return (
    <Peek show={show}>
      <ButtonHidePeek onClick={onHide}>
        <FontAwesomeIcon icon={faTimes} />
      </ButtonHidePeek>
      <Added>
        {/*@ts-ignore */}
        {`${added} item${added > 1 ? "s" : ""} added`}
        <Icon>
          <FontAwesomeIcon icon={faCheck} />
        </Icon>
      </Added>

      {goodsToPeek.map((g) => (
        <DishToPeek>
          <Dish>
            <Image src={g?.dish?.imageURL} />
            <Name>
              {g?.quantity}
              <FontAwesomeIcon icon={faTimes} />
              {g?.dish?.name}
            </Name>
          </Dish>
          {/* <Prices
              priceFoodieListed={g?.dish?.priceFoodieListed}
              priceDoordashListed={g?.dish?.priceDoordashListed}
              priceFoodieNonMember={g?.dish?.priceFoodieNonMember}
              isMember={isMember}
              isItem
            /> */}
        </DishToPeek>
      ))}
    </Peek>
  );
};
