import {
  faChevronCircleDown,
  faChevronCircleUp,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/fontawesome-pro-regular';
import { faExclamation, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GoogleMapReact from 'google-map-react';
import { LOGGING } from '.';
import './StopsMap.scss';
export const ModeSelector = ({
  mode,
  onSelectMode,
  onToggleShowFilters,
  onToggleFilter,
  showFilters,
  showOperation,
  showCommission,
  showCommissionRange,
  numberOfShown,
}) => (
  <div className="mode-select">
    <button onClick={onSelectMode.bind(this, 0)} className={`mode ${mode === 0 ? 'selected' : ''}`}>
      <span>directory</span>
      <FontAwesomeIcon icon={faCheck} />
    </button>
    <button onClick={onSelectMode.bind(this, 1)} className={`mode ${mode === 1 ? 'selected' : ''}`}>
      <span>map</span>
      <FontAwesomeIcon icon={faCheck} />
    </button>
    {mode > 0 ? (
      <>
        <button className="show-filters" onClick={onToggleShowFilters}>
          <span>{`filters (${numberOfShown})`} </span>
          <FontAwesomeIcon icon={showFilters ? faChevronUp : faChevronDown} />
        </button>
        {showFilters ? (
          <div className="restaurant-filters">
            <button
              className={`${showOperation.needsWork ? 'selected' : 'unselected'}`}
              onClick={onToggleFilter.bind(this, 'Operation', 'needsWork')}
            >
              <span>operation needs work</span>
              {showOperation.needsWork ? <FontAwesomeIcon icon={faCheck} /> : null}
            </button>
            <button
              className={`${showOperation.done ? 'selected' : 'unselected'}`}
              onClick={onToggleFilter.bind(this, 'Operation', 'done')}
            >
              <span>operation done</span>
              {showOperation.done ? <FontAwesomeIcon icon={faCheck} /> : null}
            </button>
            <button
              className={`${showCommission.needsWork ? 'selected' : 'unselected'}`}
              onClick={onToggleFilter.bind(this, 'Commission', 'needsWork')}
            >
              <span>commission needs work</span>
              {showCommission.needsWork ? <FontAwesomeIcon icon={faCheck} /> : null}
            </button>
            <button
              className={`${showCommission.done ? 'selected' : 'unselected'}`}
              onClick={onToggleFilter.bind(this, 'Commission', 'done')}
            >
              <span>commission done</span>
              {showCommission.done ? <FontAwesomeIcon icon={faCheck} /> : null}
            </button>
            <button
              className={`${showCommissionRange.hasCommission ? 'selected' : 'unselected'}`}
              onClick={onToggleFilter.bind(this, 'CommissionRange', 'hasCommission')}
            >
              <span>has commission</span>
              {showCommissionRange.hasCommission ? <FontAwesomeIcon icon={faCheck} /> : null}
            </button>
            <button
              className={`${showCommissionRange.noCommission ? 'selected' : 'unselected'}`}
              onClick={onToggleFilter.bind(this, 'CommissionRange', 'noCommission')}
            >
              <span>no commission</span>
              {showCommissionRange.noCommission ? <FontAwesomeIcon icon={faCheck} /> : null}
            </button>
          </div>
        ) : null}
      </>
    ) : null}
  </div>
);

const RestaurantMarker = ({
  restaurantName,
  restaurantFullName,
  commission,
  show,
  needsCommissionWork,
  needsOperationWork,
  onToggleWork,
  onHide,
  onShow,
}) => {
  return (
    <div
      className={`address-marker restaurant-marker ${commission ? 'has-commission' : ''}  ${
        show ? 'show-details' : 'hide-details'
      }  `}
    >
      <button className="restaurant-details" onClick={show ? onHide : onShow}>
        <FontAwesomeIcon icon={show ? faChevronCircleUp : faChevronCircleDown} />
        <span className={`restaurant-name ${show ? 'long-name' : 'short-name'}`}>{`${
          show ? restaurantFullName : restaurantName
        }`}</span>
        <div className="needs-work">
          {needsCommissionWork ? <span>c</span> : null}
          {needsOperationWork ? <span>o</span> : null}
        </div>
        {!needsCommissionWork && !needsOperationWork ? (
          <div className="all-good">
            <FontAwesomeIcon icon={faCheck} />
          </div>
        ) : null}
        {commission > 0 ? <span className="commission">{commission}</span> : null}
      </button>

      {show && (
        <div className="restaurant-work-status">
          {commission ? (
            <span className="restaurant-commission">Commission: ${commission}%</span>
          ) : null}
          <button
            className={`work commission ${needsCommissionWork ? 'yes' : 'no'}`}
            onClick={onToggleWork.bind(this, 'Commission')}
          >
            <FontAwesomeIcon icon={needsCommissionWork ? faExclamation : faCheck} />
            <span>Commission</span>
          </button>
          <button
            className={`work operation ${needsOperationWork ? 'yes' : 'no'}`}
            onClick={onToggleWork.bind(this, 'Operation')}
          >
            <FontAwesomeIcon icon={needsOperationWork ? faExclamation : faCheck} />
            <span>Operation</span>
          </button>
        </div>
      )}
    </div>
  );
};

const RestaurantsMap2 = ({
  restaurants,
  center,
  zoom,
  showRestaurant,
  onShowRestaurant,
  onHideRestaurant,
  onToggleRestaurantWork,
}) => (
  // Important! Always set the container height explicitly
  <div className="stops-map restaurants-map">
    <GoogleMapReact
      bootstrapURLKeys={{
        key: 'AIzaSyB-0SwH76HPTJGm_mhM1Rg0ACcpYGpAt1Q',
      }}
      // defaultCenter={center}
      defaultZoom={zoom}
      center={[center.lat, center.lng]}
    >
      {restaurants.map((restaurant, index) => {
        if (!restaurant.map) {
          LOGGING && console.log('restaurant with no map:', restaurant);
        }
        return (
          <RestaurantMarker
            restaurantName={restaurant.name.trim().split(' ')[0]}
            restaurantFullName={restaurant.name}
            lat={restaurant.map ? restaurant.map.lat : center.lat}
            lng={restaurant.map ? restaurant.map.lng : center.lng}
            key={index}
            commission={restaurant.commission}
            show={restaurant._id === showRestaurant}
            onShow={onShowRestaurant.bind(this, restaurant._id)}
            onHide={onHideRestaurant}
            onToggleWork={onToggleRestaurantWork.bind(this, restaurant._id)}
            needsCommissionWork={restaurant.needsCommissionWork}
            needsOperationWork={restaurant.needsOperationWork}
          />
        );
      })}
    </GoogleMapReact>
  </div>
);

export default RestaurantsMap2;
