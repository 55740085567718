import React, { ReactElement, useCallback } from "react";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import {
  faCalendar,
  faCalendarAlt,
  faMapPin,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { useAsync } from "react-async";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { GetSurveyResults } from "src/api/restaurants";
import { UserType, useUserType } from "src/hooks/useUserType";
import { RootState } from "src/store";
import { ISOtoEpoch } from "src/util/time";
import styled from "styled-components";
import { LOGGING } from "../shared";
import { Loading } from "./components";
import {
  Centered,
  CenteredByColumn,
  CenteredByColumnLeft,
  Text12300,
  TextTitle,
  centered,
  card,
  Beige,
  BorderGrey,
  text12300,
} from "./components/Shared";
interface PageSurveyResultsProps {}

const ContentContainer = styled(Centered)`
  width: 100%;
  padding: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
const CloseButton = styled.a`
  position: fixed;
  top: 20px;
  right: 40px;
  svg {
    font-size: 25px;
  }
`;

const Entry = styled(CenteredByColumnLeft)`
  ${card}
  margin: 10px;
  width: 350px;
  padding: 20px;
  border: 1px solid ${BorderGrey};
  align-self: stretch;
  justify-content: flex-start;
`;

const Name = styled(TextTitle)`
  width: 100%;
  /* height: 80px; */
  /* line-height: 40px; */
`;
const City = styled.span`
  ${text12300}
  font-size: 16px;
  text-transform: capitalize;
  /* font-weight: 500; */
  svg {
    margin-right: 5px;
  }
  width: 100%;
`;
const Joined = styled(City)`
  margin-bottom: 20px;
  border-bottom: 1px solid black;
  position: relative;
  b {
    position: absolute;
    right: 0px;
    font-weight: 500;
    background-color: black;
    color: white;
    margin-left: 5px;
    padding: 0px 3px;
  }
`;
export const PageSurveyResults: React.FC<PageSurveyResultsProps> =
  (): ReactElement => {
    const currentUser = useSelector((state: RootState) => state.currentUser);
    // const userType = useUserType();

    const fetch = useCallback(async () => {
      return await GetSurveyResults(currentUser?.user?._id);
    }, [currentUser?.user?._id]);

    const { data } = useAsync({ promiseFn: fetch });
    LOGGING && console.log("PageFavoritesOverview got data:", data);
    if (data == null) return <Loading />;

    const { users } = data;
    LOGGING &&
      console.log("PageFavoritesOverview got:", {
        users,
      });

    return (
      <div className="page">
        <CloseButton href={`/`}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
        <h1>survey results</h1>
        <ContentContainer>
          {users.map((f) => (
            <Entry>
              <Name>{`${f.firstName} ${f.lastName}`}</Name>
              <City>
                <FontAwesomeIcon icon={faMapPin} />
                {f?.deliveryZone?.name}
              </City>
              <Joined>
                <FontAwesomeIcon icon={faCalendarAlt} />
                {`${moment(f.createdAt).format("MMM DD, YYYY")}`}
                <b>{`${moment(f.createdAt).valueOf()}`}</b>
              </Joined>
              {f.feedback.likeFoodie.answer.length > 0 ? (
                <>
                  <b>benefit</b>
                  <p>{f.feedback.likeFoodie.answer}</p>
                </>
              ) : null}
              {f?.feedback?.dislikeFoodie?.answer ? (
                <>
                  <b>improve</b>
                  <p>{f?.feedback?.dislikeFoodie?.answer}</p>
                </>
              ) : null}
              {f?.feedback?.mostBeneficiary?.answer?.length > 0 ? (
                <>
                  <b>who?</b>
                  <p>{f?.feedback?.mostBeneficiary?.answer}</p>
                </>
              ) : null}
            </Entry>
          ))}
        </ContentContainer>
      </div>
    );
  };
