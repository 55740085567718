import React, { ReactElement, useCallback } from "react";
import { useAsync } from "react-async";
import { GetDishListByIds } from "src/api/dish";
import { ObjectId } from "src/constants/types";
import { nonNullable } from "src/util/array";
import styled from "styled-components";
import { DishChooserFactory } from "./DishChooserFactory";
import { Good } from "./PureDishChooser";
import { Loading, LOGGING } from "..";

type Props = {
  dishIds: ObjectId[];
  onAddGoodsToCart: (goods: Good[]) => void;
  isMember?: boolean;
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ListItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 40px 0;
`;

const CenterContainer = styled.div`
  overflow: scroll;
  box-shadow: 0 4px 4px 4px rgba(28, 29, 33, 0.2);
  padding: 16px;
`;

export const DishListChooserContainer: React.FC<Props> = ({
  dishIds,
  onAddGoodsToCart,
  isMember,
}): ReactElement => {
  const fetch = useCallback(async () => {
    return await GetDishListByIds(dishIds ?? []);
  }, [dishIds]);
  const { data } = useAsync({ promiseFn: fetch, watch: dishIds });

  if (data == null) return <Loading />;

  const dishes = dishIds
    .map((dishId) => data.dishes?.find((dish) => dish._id === dishId))
    .filter(nonNullable);

  LOGGING &&
    console.log("DishListChooserContainer rendering with", { isMember });

  return (
    <Container>
      {dishes.map((dish) => {
        return (
          <ListItemContainer key={dish._id}>
            <CenterContainer>
              {/* @ts-ignore */}
              <DishChooserFactory
                dish={dish}
                countInCart={0}
                onAddGoodsToCart={onAddGoodsToCart}
                modalify={false}
                isMember={isMember}
                quantityCap={-1}
              />
            </CenterContainer>
          </ListItemContainer>
        );
      })}
    </Container>
  );
};
