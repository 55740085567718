import React, { ReactElement } from 'react';
import { UserType } from 'src/hooks/useUserType';
import { PopUp } from '..';
import { NavbarPopUpList } from './NavBarPopUpList';

interface NavbarPopUpProps {
  showUserMenu: boolean;
  userType: UserType;
  onSignOut: () => void;
  onToggleUserMenu: () => void;
}

export const NavbarPopUp: React.FC<NavbarPopUpProps> = ({
  showUserMenu,
  onSignOut,
  onToggleUserMenu,
  userType,
}): ReactElement => {
  return (
    <PopUp
      isPoppedUp={showUserMenu}
      componentToDisplay={<NavbarPopUpList userType={userType} onSignOut={onSignOut} />}
      hidePopUp={onToggleUserMenu}
      backgroundColor="transparent"
    />
  );
};
