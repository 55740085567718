import { useState, useEffect } from "react";
import { set } from "lodash";
import { useStore, useDispatch } from "react-redux";
import { readRestaurantsSimple } from "src/store/actions";
import styled from "styled-components";
import { LOGGING } from "../../../shared";
import {
  Centered,
  CenteredByColumnLeft,
  TextTitle,
  centered,
  BorderGrey,
  text12300,
  BackgroundGrey,
} from "../Shared";

// const searchWidth = "100vw";
const SearchBox = styled.input`
  height: 60px;
  line-height: 60px;
  align-self: stretch;
  flex-grow: 0;
  background-color: ${BackgroundGrey};
  padding: 10px;
  border-bottom: none;
  box-sizing: border-box;
  margin: 0px;
  position: sticky;
  top: 0px;
  border-bottom: none !important;
`;
const ContentContainer = styled(Centered)`
  padding: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  border: 1px solid ${BorderGrey};
  width: calc(100% - 80px);
  position: absolute;
  top: 100%;
  background-color: white;
  // boxshadow downward
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-top: none;
`;
const EntryContainer = styled.button`
  ${centered}
  width: 100%;
  justify-content: flex-start;
  background-color: white;
  padding: 10px 20px;
  margin: 0px;
  justify-content: space-between;
`;
const PageContainer = styled(CenteredByColumnLeft)`
  align-self: stretch;
  justify-content: flex-start;
  padding: 0px 40px;
  box-sizing: border-box;
  position: relative;
  z-index: 101;
`;
const Name = styled(TextTitle)`
  width: 100%;
  /* height: 80px; */
  /* line-height: 40px; */
  text-align: left;
  font-size: 20px;
`;
const Email = styled(Name)`
  ${text12300}
  font-size: 18px;
  font-weight: 400;
  text-transform: lowercase;
`;
const Phone = styled(Email)`
  text-align: right;
`;
const Entry = ({ restaurant, keyword, onSelect }) => {
  // highlight the keyword in the name, email, or phone number
  const nameIndex = restaurant?.name?.toLowerCase().indexOf(keyword);
  return (
    <EntryContainer
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onSelect(restaurant._id);
      }}
    >
      <Name>
        {nameIndex >= 0 ? (
          <>
            {restaurant?.name.substring(0, nameIndex)}
            <span style={{ background: "yellow" }}>
              {restaurant?.name?.substring(
                nameIndex,
                nameIndex + keyword.length
              )}
            </span>
            {restaurant?.name?.substring(nameIndex + keyword.length)}
          </>
        ) : (
          restaurant?.name
        )}
      </Name>
    </EntryContainer>
  );
};
export const SearchRestaurants = ({ onSelect }) => {
  const [restaurants, setRestaurants] = useState([]);
  const [filteredRestaurants, setFilteredRestaurants] = useState([]);
  const [keyword, setKeyword] = useState("");
  const dispatch = useDispatch();
  const store = useStore();
  useEffect(() => {
    readRestaurantsSimple()(dispatch, store.getState).then((res) => {
      setRestaurants(res);
    });
  }, []);

  const onSearch = (e) => {
    const keyword = e.target.value.toLowerCase();
    LOGGING && console.log("onSearch called keyword", keyword);
    setKeyword(keyword);
    const filteredRestaurants = restaurants.filter((restaurant) =>
      restaurant?.name?.toLowerCase().includes(keyword)
    );
    setFilteredRestaurants(filteredRestaurants);
  };
  const onChoose = (id) => {
    setKeyword("");
    setFilteredRestaurants([]);
    onSelect(id);
  };
  LOGGING &&
    console.log("SearchRestaurants rendering with restaurants:", restaurants);
  return (
    <PageContainer>
      <SearchBox
        placeholder="Search by restaurantName"
        value={keyword}
        onChange={onSearch}
      />
      {filteredRestaurants?.length > 0 ? (
        <ContentContainer>
          {filteredRestaurants.map((restaurant) => (
            // highlight the keyword in the name, email, or phone number
            <Entry
              key={restaurant._id}
              restaurant={restaurant}
              keyword={keyword}
              onSelect={onChoose}
            />
          ))}
        </ContentContainer>
      ) : null}
    </PageContainer>
  );
};
