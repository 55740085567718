import { ReactElement, useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { saveRestaurantHeroImage } from 'src/api/restaurants';
import { DishEditModal } from 'src/components/DishEditModal';
import { RootState } from 'src/store';
import { saveDish } from 'src/store/actions';
import {
  loadRestaurant,
  loadRestaurantDishes,
  loadSelectedDishes,
} from 'src/store/actions/mealMenu';
import { updateItemInArr } from 'src/util/array';
import { getSortedCategoryNamesFromDishes } from 'src/util/categories';
import { MealMenuAdmin } from './MealMenuAdmin';

type MealMenuAdminContainerProps = {
  restaurantDishes: any[];
  selectedDishes: string[];
  restaurant: any;
};

export const MealMenuAdminContainer: React.FC<MealMenuAdminContainerProps> = ({
  restaurantDishes,
  selectedDishes,
  restaurant,
}): ReactElement => {
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const dispatch = useDispatch();
  const store = useStore();

  const [isEditDishModalOpen, setIsEditDishModalOpen] = useState(false);
  const [dishBeingEditted, setDishBeingEditted] = useState(null);

  const categories: string[] = getSortedCategoryNamesFromDishes(restaurantDishes);

  const selectAllDishes = useCallback(() => {
    const selectedDishes = restaurantDishes.filter((d) => d.imageURL).map((d) => d._id);
    dispatch(loadSelectedDishes(selectedDishes));
  }, [dispatch, restaurantDishes]);
  const deselectAllDishes = useCallback(() => {
    dispatch(loadSelectedDishes([]));
  }, [dispatch]);
  const toggleDishAsSelected = useCallback(
    (dishId: string) => {
      if (selectedDishes.includes(dishId)) {
        dispatch(loadSelectedDishes(selectedDishes.filter((id) => id !== dishId)));
      } else {
        dispatch(loadSelectedDishes([...selectedDishes, dishId]));
      }
    },
    [dispatch, selectedDishes]
  );

  const selectAllDishesInCategory = useCallback(
    (categoryName: string) => {
      const newSelectedDishes = [
        ...selectedDishes,
        ...restaurantDishes
          .filter(
            (d) =>
              d.imageURL && d.categories.map((category) => category.name).includes(categoryName)
          )
          .map((d) => d._id),
      ];

      // Dedup
      dispatch(loadSelectedDishes(Array.from(new Set(newSelectedDishes))));
    },
    [dispatch, restaurantDishes, selectedDishes]
  );

  const deselectAllDishesInCategory = useCallback(
    (categoryName: string) => {
      dispatch(
        loadSelectedDishes(
          restaurantDishes
            .filter(
              (d) =>
                selectedDishes.includes(d._id) &&
                !d.categories.map((category) => category.name).includes(categoryName)
            )
            .map((d) => d._id)
        )
      );
    },
    [dispatch, restaurantDishes, selectedDishes]
  );
  const setHeroImage = useCallback(
    async (_restaurantId: string, imageUrl: string) => {
      const updatedRestaurant = await saveRestaurantHeroImage(
        restaurant._id,
        { heroImage: imageUrl },
        currentUser
      );
      dispatch(loadRestaurant(updatedRestaurant));
    },
    [currentUser, dispatch, restaurant._id]
  );

  const openEditDishModal = useCallback((dish: any) => {
    setIsEditDishModalOpen(true);
    setDishBeingEditted(dish);
  }, []);
  const closeEditDishModal = useCallback(() => {
    setIsEditDishModalOpen(false);
    setDishBeingEditted(null);
  }, []);

  const publishDishChanges = useCallback(
    async (dish: any) => {
      const updatedDish = await saveDish(dish)(null, store.getState);

      const updatedRestaurantDishes = updateItemInArr<any, string>(
        restaurantDishes,
        updatedDish,
        (el) => el._id
      );
      dispatch(loadRestaurantDishes(updatedRestaurantDishes));

      toast.success('Dish save success!');
      closeEditDishModal();
    },
    [closeEditDishModal, dispatch, restaurantDishes, store.getState]
  );

  return (
    <>
      <MealMenuAdmin
        restaurant={restaurant}
        dishes={restaurantDishes}
        selectedDishes={selectedDishes}
        categoriesForRestaurant={categories}
        onSelectAllDishes={selectAllDishes}
        onDeselectAllDishes={deselectAllDishes}
        onToggleDish={toggleDishAsSelected}
        onSelectAllDishesInCategory={selectAllDishesInCategory}
        onDeselectAllDishesInCategory={deselectAllDishesInCategory}
        onEditDish={openEditDishModal}
        onSetHeroImage={setHeroImage}
      />
      <DishEditModal
        isOpen={isEditDishModalOpen}
        dish={dishBeingEditted}
        onSave={publishDishChanges}
        onClose={closeEditDishModal}
      />
    </>
  );
};
