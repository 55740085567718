import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  getPickUpListForDay,
  getRoutesForDay,
} from "src/store/actions/adminDeliveryV2";
import { AwaitServerResponseContainer } from "./components/AwaitServerResponseContainer";
import { PickUp } from "./components/PickUp";
import Navbar from "./Navbar";

export const PageLeadDriver = () => {
  const fetchDataQuery = useCallback(async (dispatch, getState) => {
    await getPickUpListForDay()(dispatch, getState);
    await getRoutesForDay()(dispatch, getState);
  }, []);
  const currentUser = useSelector((state) => state.currentUser);
  const restaurants = useSelector((state) => state.pickUpRestaurants);
  const routes = useSelector((state) => state.routeList);
  console.log("PageLeadDriver got data:", { restaurants, routes });
  if (!currentUser.isAuthenticated || !currentUser.user.isLeadDriver) {
    return <Redirect to="/" />;
  }

  return (
    <div className="page">
      <Navbar driverView={true} />
      <AwaitServerResponseContainer awaitable={fetchDataQuery}>
        <PickUp />
      </AwaitServerResponseContainer>
    </div>
  );
};
