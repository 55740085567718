import { faFilter as faFiltered } from "@fortawesome/free-solid-svg-icons";
import {
  faCalculator,
  faCar,
  faBackward,
  faFilter,
  faSms,
  faTimesCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { Card } from "./Shared";

const Container = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: 8px 0;
  padding: 15px;
`;

export const AdminDeliveryV2DriverActions = ({
  color,
  name,
  startingMealFaIcon,
  numStops,
  eta,
  isFiltered,
  isRouteDirty,
  onClickFilter,
  onClickEstimateTime,
  onClickReverseRoute,
  onClickCancelDriver,
  onClickCreateRoute,
}) => {
  return (
    <Container>
      <div className="info-section">
        <div
          className="driver-name"
          style={
            numStops > 0
              ? {
                  backgroundColor: color,
                  color: "white",
                  border: "none",
                }
              : {
                  backgroundColor: "white",
                  color: color,
                  border: `1px solid ${color}`,
                }
          }
        >
          <FontAwesomeIcon icon={faCar} />
          <span>{name.substr(0, 3)}</span>
        </div>

        <span className="eta" style={{ color: color }}>
          <FontAwesomeIcon icon={startingMealFaIcon} />
        </span>
        <span className="number-of-stops">{`${numStops} Stop${
          numStops > 1 ? "s" : ""
        }`}</span>
        <span className="eta">{`${eta || "N/A"} min`}</span>
      </div>
      <div className="action-section">
        <button
          className={`driver-toggle ${isFiltered ? "display-driver-only" : ""}`}
          onClick={onClickFilter}
          style={
            isFiltered
              ? {
                  color: color,
                }
              : {}
          }
        >
          <FontAwesomeIcon icon={isFiltered ? faFiltered : faFilter} />
          <span>filter</span>
        </button>
        <button className="calculate-driver-time" onClick={onClickEstimateTime}>
          <FontAwesomeIcon icon={faCalculator} />
          <span>estimate</span>
        </button>
        <button className="calculate-driver-time" onClick={onClickReverseRoute}>
          <FontAwesomeIcon icon={faBackward} />
          <span>reverse</span>
        </button>
        <button className="calculate-driver-time" onClick={onClickCancelDriver}>
          <FontAwesomeIcon icon={faTimesCircle} />
          <span>cancel</span>
        </button>
        <button
          style={
            isRouteDirty
              ? null
              : { backgroundColor: "#90EE90", borderRadius: "8px" }
          }
          className="finalize-driver-route"
          onClick={onClickCreateRoute}
        >
          <FontAwesomeIcon icon={faSms} />
          <span>notify</span>
        </button>
      </div>
    </Container>
  );
};
