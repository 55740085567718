import Select from "react-dropdown-select";
import { LOGGING } from "./";
const DeliveryZonesSelector = ({
  deliveryZones,
  onSelectDeliveryZone,
  selectedDeliveryZone,
  supportMulti,
}) => {
  LOGGING &&
    console.log("DeliveryZonesSelector rendering with: ", {
      deliveryZones,
      selectedDeliveryZone,
    });
  const options = [
    {
      id: 1,
      name: "Leanne Graham",
    },
    {
      id: 2,
      name: "Ervin Howell",
    },
  ];
  return (
    <div style={{ marginTop: "20px", marginBottom: "20px" }}>
      city select placeholder
      <Select
        options={deliveryZones}
        onChange={onSelectDeliveryZone}
        labelField="name"
        valueField="_id"
        values={
          selectedDeliveryZone
            ? deliveryZones.filter((z) => selectedDeliveryZone.includes(z._id))
            : []
        }
        placeholder={`Select Delivery Zone${supportMulti && "s"}`}
        multi={supportMulti}
      />
    </div>
  );
};

export default DeliveryZonesSelector;
