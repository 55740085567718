import { LOGGING } from '../../constants';
import { SET_DAY_ORDERS } from '../actionTypes';
import { apiCall } from '../api';

export const setDayOrders = (dayOrders) => ({
  type: SET_DAY_ORDERS,
  dayOrders,
});

export const getDayOrders = (meals) => {
  LOGGING && console.log('getDayOrders called with mealIds:', meals);
  const calls = meals.map((m) =>
    apiCall('GET', `/meals/orders/${m._id}`).then((orders) => {
      return { ...m, orders };
    })
  );
  return (dispatch) =>
    Promise.all(calls.filter(Boolean))
      .then((ordersBymMeal) => {
        LOGGING && console.log('getDayOrders got ordersBymMeal', ordersBymMeal);
        dispatch(setDayOrders(ordersBymMeal));
      })
      .catch((err) => {
        LOGGING && console.log('getDayOrders got err', err);
      });
};

export const getTeamMealOrders = (mealId) => {
  LOGGING && console.log('getTeamMealOrders called with:', mealId);

  return (dispatch, getState) => {
    return apiCall('get', `/meals/orders/${mealId}`, {}, getState().currentUser)
      .then((result) => {
        LOGGING && console.log('getTeamMealOrders got from server:', result);
        result.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
        const windowStart = result[0].window.start;
        const restaurant = result[0].goods[0].dish.restaurant.name;
        const orders = result.map((o) => ({
          name: `${o.user.firstName} ${o.user.lastName}`,
          goods: o.goods,
          total: o.total,
          sum: o.sum,
          tax: o.tax,
          due: o.due,
          companyBudget: o.companyBudget,
          companyPay: o.companyPay,
          _id: o._id,
        }));
        LOGGING && console.log('getTeamMealOrders returning:', { orders, windowStart, restaurant });
        return { orders, windowStart, restaurant };
      })
      .catch((err) => {
        LOGGING && console.log('getTeamMealOrders got err', err);
      });
  };
};
