import React, { ReactElement, useCallback } from "react";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAsync } from "react-async";
import { useSelector } from "react-redux";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { ReadMealNameByOrderId } from "src/api/orders";
import { UserType, useUserType } from "src/hooks/useUserType";
import { RootState } from "src/store";
import styled from "styled-components";
import { LOGGING } from "../shared";
import { Loading } from "./components";
import {
  Centered,
  CenteredByColumn,
  CenteredByColumnLeft,
  Text12300,
  TextTitle,
  centered,
  card,
} from "./components/Shared";
interface PageGroupCartProps {}
interface RouteParams {
  orderId: string;
}

export const PageGroupCart: React.FC<PageGroupCartProps> = (): ReactElement => {
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const location = useLocation();
  const { orderId } = useParams<RouteParams>();
  LOGGING &&
    console.log(
      "PageGroupCart rendering with:",
      orderId,
      currentUser?.user?._id
    );
  const fetch = useCallback(async () => {
    return await ReadMealNameByOrderId(orderId);
  }, [orderId]);

  const { data: mealLink } = useAsync({ promiseFn: fetch });
  LOGGING && console.log("PageGroupCart got mealLink:", mealLink);
  if (mealLink == null) return <Loading />;
  LOGGING &&
    console.log(
      "PageGroupCart got:",
      `/meal/${mealLink}/groupOrder/${orderId}`
    );
  if (!currentUser.isAuthenticated) {
    const restaurantName = mealLink.split("/")[2].split("-").join(" ");
    return (
      <Redirect
        to={{
          pathname: "/auth",
          state: {
            title: `Login To See Group Order for \n${restaurantName}`,
            next: location.pathname,
            // groupOrder: {
            //   mealLink,
            //   orderId
            // }
          },
        }}
      />
    );
  }
  // return <div className="page">{mealLink}</div>;
  return <Redirect to={`/meal/${mealLink}/groupOrder/${orderId}`} />;
};
