import React, { Component } from 'react';
import { GoogleApiWrapper } from 'google-maps-react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { createPost, fetchPost, updatePost } from '../../store/actions';
import { Back, Loading, LOGGING } from './';
import { FormPost } from './components';

class PagePost extends Component {
  constructor(props) {
    super();
    this.state = {
      restaurant: null,
      placeId: null,
      userId: '',
      dish: '',
      comments: '',
      loading: false,
      error: null,
      picture: null,
    };
    this.handleSavePost = this.handleSavePost.bind(this);
    this.handleCancelPost = this.handleCancelPost.bind(this);
    this.handleEditRestaurant = this.handleEditRestaurant.bind(this);
    this.handleResetRestaurant = this.handleResetRestaurant.bind(this);
    this.handleSelectRestaurant = this.handleSelectRestaurant.bind(this);
    this.handleEditDish = this.handleEditDish.bind(this);
    this.handleEditComments = this.handleEditComments.bind(this);
    this.handleEditUser = this.handleEditUser.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }
  onDrop(picture) {
    this.setState({
      picture,
    });
  }

  handleSavePost(e) {
    e.preventDefault();
    const { placeId, dish, comments, restaurant } = this.state;
    const { currentUser } = this.props;
    const { postId } = this.props.match.params;
    LOGGING &&
      console.log('handleSavePost called with:', {
        placeId,
        dish,
        comments,
        restaurant,
        postId,
        dishTrim: dish.trim(),
        dishIsEmpty: dish.trim().length === 0,
      });
    if (!restaurant) {
      this.setState({ error: { message: "Restaurant/shop can't be empty" } });
    } else if (dish.trim().length === 0) {
      this.setState({ error: { message: "Dish/goods can't be empty" } });
    } else {
      this.setState({ loading: true }, () => {
        if (postId === 'new') {
          this.props
            .createPost({
              placeId,
              dish,
              comment: comments,
              userId: currentUser.user._id,
              restaurantName: restaurant,
            })
            .then((result) => {
              const { posts } = this.props;
              LOGGING && console.log('createPost got from backend:', { result, posts });
              this.setState({ loading: false }, () => {
                this.props.history.goBack();
              });
            });
        } else {
          this.props
            .updatePost({
              _id: postId,
              post: {
                placeId,
                dish,
                comment: comments,
                restaurantName: restaurant,
              },
            })
            .then((result) => {
              LOGGING && console.log('updatePost got result:', result);
              this.setState({ loading: false }, () => {
                this.props.history.goBack();
              });
            });
        }
      });
    }
  }

  handleCancelPost(e) {
    e.preventDefault();
    LOGGING && console.log('handleCancelPost called');
    this.props.history.goBack();
  }

  handleResetRestaurant(e) {
    e.preventDefault();
    this.setState({ placeId: null, restaurant: null });
  }
  handleEditRestaurant(restaurant) {
    this.setState({ restaurant });
  }

  handleSelectRestaurant(address) {
    LOGGING && console.log('PageRestaurant called handleSelectRestaurant with address:', address);
    if (address) {
      const city = address.gmaps.address_components.find((c) =>
        c.types.includes('locality')
      ).short_name;
      const number = address.gmaps.address_components.find((c) =>
        c.types.includes('street_number')
      ).short_name;
      const street = address.gmaps.address_components.find(
        (c) => c.types.includes('route') || c.types.includes('premise')
      ).short_name;
      const state = address.gmaps.address_components.find((c) =>
        c.types.includes('administrative_area_level_1')
      ).short_name;
      const zip = address.gmaps.address_components.find((c) =>
        c.types.includes('postal_code')
      ).short_name;

      const name = address.gmaps.name;
      const placeId = address.gmaps.place_id;
      LOGGING && console.log('selected: ', { name, city, number, street, state, zip });

      this.setState({
        restaurant: `${name}, ${city}`,
        placeId,
        isEdittingRestaurant: false,
      });
    }
  }

  handleEditDish(e) {
    e.preventDefault();
    this.setState({ dish: e.target.value });
  }

  handleEditComments(e) {
    e.preventDefault();
    this.setState({ comments: e.target.value });
  }

  handleEditUser(e) {
    e.preventDefault();
    this.setState({ userId: e.target.value });
  }
  componentDidMount() {
    LOGGING && console.log('PagePost componentDidMount with:', this.props);
    const { postId } = this.props.match.params;
    LOGGING && console.log('PagePost componentDidMount with postId:', postId);
    if (postId !== 'new') {
      this.props.fetchPost(postId).then((post) => {
        LOGGING && console.log('PagePost got post:', post);
        const { restaurant, placeId, dish, recommendation } = post;
        this.setState({
          restaurant: restaurant.name,
          placeId,
          dish,
          comments: recommendation.content || '',
        });
      });
    }
  }

  render() {
    const { currentUser } = this.props;
    const { restaurant, dish, comments, placeId, loading, error } = this.state;
    LOGGING &&
      console.log('PagePost got', {
        state: this.state,
        props: this.props,
      });

    if (!currentUser.isAuthenticated) return <Redirect to="/auth" />;
    return (
      <div className="page new-entity">
        {loading ? <Loading /> : null}
        <Back onClick={this.handleCancelPost} />
        <FormPost
          dish={dish}
          restaurant={restaurant}
          placeId={placeId}
          comments={comments}
          onSave={this.handleSavePost}
          onEditRestaurant={this.handleEditRestaurant}
          onSelectRestaurant={this.handleSelectRestaurant}
          onResetRestaurant={this.handleResetRestaurant}
          onEditDish={this.handleEditDish}
          onEditComments={this.handleEditComments}
          error={error}
        />
        {/* <ImageUploader
          withIcon={true}
          buttonText="Choose images"
          onChange={this.onDrop}
          imgExtension={[".jpg", ".gif", ".png", ".gif"]}
          maxFileSize={5242880}
        /> */}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, { createPost, fetchPost, updatePost })(
  GoogleApiWrapper({
    apiKey: 'AIzaSyBjfTfgJdRah5wXvVj8U7fH4ACoqKry9HI',
  })(PagePost)
);
