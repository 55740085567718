import React, { Component } from "react";
import { GoogleApiWrapper } from "google-maps-react";
import moment from "moment-timezone";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { MealVisited } from "src/api/meals";
import { FoodieEvent, FoodieSource, TrackEvent } from "src/api/tracker";
import weeklyMenu from "src/store/reducers/weeklyMenu";
import {
  LOGGING,
  Footer,
  NavbarSingleMeal,
  HalloweenWhitelist,
  // getCurrentDayDeliveryWindows,
  getViewableDays,
  getWeeklyMenuWindows,
  WeeklyMenuLink,
  DateNavbar,
  ScheduledMeals,
  DeliveryZoneByCitySelectorUnselected,
  // MealRefs,
  ShareBanner,
  SocialActions,
  MenuDateFormatLong,
  DefaultTimeZone,
  NominateDish,
  NominationBanner,
  NominateDishInFlow,
} from ".";
import {
  CompetitorComparisonBlocking,
  CompetitorComparisonFullScreen,
} from "./components/customers/banners/CompetitorComparison";
import { GiftCardAnnouncement } from "./components/customers/banners/GiftCardAnnouncement";
import { MealHighlights } from "./components/customers/banners/MealHighlights";
import { Membership30DTrial } from "./components/customers/banners/Membership30DTrial";
import { MembershipAnonymous } from "./components/customers/banners/MembershipLaunch";
import { MembershipOnboarding } from "./components/customers/banners/MembershipOnboarding";
import { MileageSaving } from "./components/customers/banners/MileageSaving";
import { QuickAccess } from "./components/customers/banners/QuickAccess";
import { SetUpReminder } from "./components/customers/banners/SetUpReminder";
import { TodaysOrders } from "./components/customers/banners/TodaysOrders";
import { UpcomingFavorites } from "./components/customers/banners/UpcomingFavorites";
import { LiveChat } from "./components/customers/livechat/chat";
import {
  Chat,
  ExpandedChatEntry,
  FoldedChatEntry,
} from "./components/customers/livechat/ChatEntry";
import { FoodieDraft } from "./components/customers/mealMenu/FoodieDraft";
import { TasteAndTime } from "./components/customers/survey/TasteAndTime";
import { FavoriteEntry } from "./components/favorites/MyFavorites";
import { CitySelector } from "./components/home/CitySelector";
import { MyDrinkBlockingNonMember } from "./components/referrals/MyReferralCodeEventNonMember";
import { NewUserCredit } from "./components/referrals/NewUserCredit";
import {
  SpringReferralAwardEntry,
  SummerReferralAwardEntry,
  RegularReferralEntry,
} from "./components/referrals/ReferralAward";
import {
  fetchWeeklyMeals,
  fetchWeeklyMealDetails,
  fetchDeliveryZoneByCity,
  setDeliveryCity,
  resetDeliveryCity,
  updateDeliveryCity,
  getReferralCode,
  emptyCart,
  saveNomination,
  submitNomination,
  readPastOrdersFromRestaurantByCustomer,
  getRestaurantsByAlphabet,
  readPastOrdersByCustomer,
  readFutureOrdersByCustomer,
  updateInteraction,
  readHighlight,
  fetchWeeklyMealDetailsLight,
  getFavorites,
  getTastes,
} from "../../store/actions";
import { PopUp, LoadingTop } from "../shared";

class PageHome extends Component {
  constructor(props) {
    LOGGING && console.log("PageHome constructor called with props:", props);
    super();
    const { currentUser, history } = props;
    const hour = moment().hour();
    LOGGING &&
      console.log(
        "PageHome constructor currentUser?.user?.tastes?.length < 3:",
        currentUser?.user?.tastes?.length < 3
      );
    LOGGING &&
      console.log(
        "PageHome constructor ![9, 10, 15, 16].includes(hour):",
        ![9, 10, 15, 16].includes(hour)
      );
    if (history.action === "POP") {
      history.replace();
    }
    const homeScrollY = this.props?.location?.state?.homeScrollY || 0;
    const hasGiftCard = currentUser?.features?.includes("GIFT_CARD");

    let nomination = [];
    if (
      props.nomination &&
      props.nomination != "undefined" &&
      props.nomination.length > 0
    ) {
      nomination = props.nomination;
    }
    this.state = {
      loading: weeklyMenu?.length > 0 ? false : "loading menu..",
      freeze: -1,
      readingCounter: 0,
      hasDrafts: currentUser?.user?.membership?.isActive,
      hasAnnouncement: false, //!hasGiftCard, // props?.currentUser?.isAuthenticated,
      hasGiftCard,
      showDeliveryCityOptions: false,
      viewableOptions: {},
      showConfirmation: false,
      showOrderConfirmation: false,
      showPreferences: false,
      // currentUser.isAuthenticated &&
      // !currentUser?.user?.preferences &&
      // (moment().hour() > 17 || moment().hour() < 7),
      dishesLoadedByDayIndex: null,
      selectedDeliveryZone: null,
      referralCode: null,
      showReferralCodeAnnouncement: false,
      showUserMenu: false,
      mealRefs: [],
      howItWorksRef: React.createRef(),
      showShare: false,
      showSocialActions: false,
      selectedDayIndex: 0,
      today: moment().tz(DefaultTimeZone).format(MenuDateFormatLong),
      tomorrow: moment()
        .tz(DefaultTimeZone)
        .add(1, "d")
        .format(MenuDateFormatLong),
      weekOfYear: moment().tz(DefaultTimeZone).format("w"),
      homeScrollY,
      showHowItWorks: true,
      viewMode: 0,
      quickAccess: "home",
      nomination: { ...nomination },
      showUserMenu: false,
      showNominationform: false,
      showSurvey: true,
      showTasteSurvey:
        (!currentUser?.user?.tastes || currentUser?.user?.tastes?.length < 3) &&
        ![9, 10, 15, 16].includes(hour),
      // create 7 refs for each day
      dayRefs: Array(7)
        .fill(0)
        .map((i) => React.createRef()),
    };

    this.handleHasDrafts = this.handleHasDrafts.bind(this);
    this.handleSelectViewMode = this.handleSelectViewMode.bind(this);
    this.handleGoToHowItWorks = this.handleGoToHowItWorks.bind(this);
    this.handleToggleShowSocialActions =
      this.handleToggleShowSocialActions.bind(this);
    this.handleChangeDeliveryCity = this.handleChangeDeliveryCity.bind(this);
    this.handleSelectDeliveryCity = this.handleSelectDeliveryCity.bind(this);
    this.handleToggleShowDeliveryCityOptions =
      this.handleToggleShowDeliveryCityOptions.bind(this);
    this.handleToggleUserMenu = this.handleToggleUserMenu.bind(this);
    this.handleToggleShowShare = this.handleToggleShowShare.bind(this);
    this.handleGoToDate = this.handleGoToDate.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleBackToTop = this.handleBackToTop.bind(this);
    this.handleToggleShowHowItWorks =
      this.handleToggleShowHowItWorks.bind(this);
    this.handleEditNomination = this.handleEditNomination.bind(this);
    this.handleSubmitNomination = this.handleSubmitNomination.bind(this);
    this.handleChangeRestaurantName =
      this.handleChangeRestaurantName.bind(this);
    this.handleSelectRestaurantAddress =
      this.handleSelectRestaurantAddress.bind(this);
    this.handleHideNominationConfirmation =
      this.handleHideNominationConfirmation.bind(this);
    this.handleFreezeBackground = this.handleFreezeBackground.bind(this);
    this.handleToggleShowNominationForm =
      this.handleToggleShowNominationForm.bind(this);
    this.handleSkipSurvey = this.handleSkipSurvey.bind(this);
    this.handleClickTrialNotification =
      this.handleClickTrialNotification.bind(this);
    this.handleClickComparison = this.handleClickComparison.bind(this);
    this.handleClickSummerTreats = this.handleClickSummerTreats.bind(this);
    this.handleSetReadingCounter = this.handleSetReadingCounter.bind(this);
    this.handleClosePage = this.handleClosePage.bind(this);
  }
  handleHasDrafts(hasDrafts) {
    this.setState({ hasDrafts });
  }
  handleClosePage() {
    this.setState({
      showPreferences: false,
    });
  }
  handleSetReadingCounter(readingCounter) {
    this.setState({ readingCounter });
    const { currentUser } = this.props;
    if (readingCounter > 3) {
      const selectedDeliveryZone =
        this.props.deliveryZoneByCity[this.props.deliveryCity];
      const viewableDays = getViewableDays();
      const { windowsForWeeklyMenu } = getWeeklyMenuWindows(viewableDays);

      this.setState({ loading: "reloading menu....." }, () => {
        // blocking loading
        this.props
          .fetchWeeklyMeals(
            windowsForWeeklyMenu,
            selectedDeliveryZone,
            currentUser?.user?._id
          )
          .then(() => {
            LOGGING && console.log("fetchWeeklyMeals done");
            this.props.readFutureOrdersByCustomer();
            this.setState({ loading: false }, () => {
              LOGGING && console.log("fetchWeeklyMeals done2");
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 1);
            });
          });
      });
    }
  }
  handleClickSummerTreats(value, e) {
    // value: -1: view details, 0: skip:
    e.preventDefault();
    e.stopPropagation();
    LOGGING && console.log("handleClickSummerTreats called with:", value);
    const { currentUser } = this.props;
    // skipped, record the time
    if (value === 0) {
      this.setState({ showFallTreats: false });
      this.props.updateInteraction(currentUser?.user?._id, {
        "interaction.seen_fall_treats": moment().valueOf(),
      });
      // viewed, mark as -1
    } else {
      this.props.updateInteraction(currentUser?.user?._id, {
        "interaction.seen_fall_treats": -1,
      });
      if (currentUser?.user?.membership?.isActive) {
        this.props.history.push(
          `/treat/${currentUser?.user?.socialInviteCode?.code}`
        );
      } else {
        this.props.history.push({
          pathname: "/membership",
          state: { next: "/referral-event" },
        });
      }
    }
  }

  handleClickTrialNotification(value, e) {
    // value: -1: view details, 0: skip:
    e.preventDefault();
    e.stopPropagation();

    const { currentUser } = this.props;
    // skipped, record the time
    if (value === 0) {
      this.setState({ showCompetitorComparison: false });
      this.props.updateInteraction(currentUser?.user?._id, {
        "interaction.seen_competitor_comparison": -1,
      });
      // viewed, mark as -1
    } else {
      this.props.history.push("/membership");
      this.setState({ showCompetitorComparison: false });
      this.props.updateInteraction(currentUser?.user?._id, {
        "interaction.seen_competitor_comparison": moment().valueOf(),
      });
    }
  }

  handleClickComparison(value, e) {
    // value: -1: view details, 0: skip:
    e.preventDefault();
    e.stopPropagation();

    const { currentUser } = this.props;
    // skipped, record the time
    if (value === 0) {
      this.setState({ showCompetitorComparison: false });
      this.props.updateInteraction(currentUser?.user?._id, {
        "interaction.seen_competitor_comparison": moment().valueOf(),
      });
      // viewed, mark as -1
    } else {
      this.props.history.push("/membership");
      this.props.updateInteraction(currentUser?.user?._id, {
        "interaction.seen_competitor_comparison": -1,
      });
    }
  }
  handleSkipSurvey(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showSurvey: false });
  }
  handleToggleShowNominationForm(e) {
    e.preventDefault();
    LOGGING && console.log("handleToggleShowNominationForm called!");
    const { showNominationform } = this.state;
    this.setState({ showNominationform: !showNominationform });
  }
  handleFreezeBackground() {
    const { freeze, homeScrollY } = this.state;
    LOGGING && console.log("handleFreezeBackground called with: ", freeze);
    if (freeze >= 0) {
      const resumeY = freeze;
      this.setState({ freeze: -1 }, () => {
        window.scrollTo(0, resumeY);
      });
    } else {
      this.setState({ freeze: homeScrollY, innerHeight: window.innerHeight });
    }
  }

  handleHideNominationConfirmation(e) {
    e.preventDefault();
    this.setState({
      showNominationConfirmation: false,
      nomination: { restaurant: { name: "" }, dish: "" },
    });
  }

  handleChangeRestaurantName(name) {
    LOGGING && console.log("handleChangeRestaurantName called with ", name);
    if (!this.props.currentUser?.isAuthenticated) {
      this.props.history.push({
        pathname: "/auth",
        state: {
          title: "Sign In To Nominate",
          next: "/",
          secondary: { title: "Create an Account", next: "/signup" },
        },
      });
    }
    let { nomination } = this.state;
    let { restaurant } = nomination;
    if (!nomination || nomination === "undefined" || nomination.length === 0) {
      nomination = [];
    }
    if (!restaurant || restaurant === "undefined" || restaurant.length === 0) {
      restaurant = [];
    }
    this.setState({
      nomination: { ...nomination, restaurant: { ...restaurant, name } },
      isSearching: true,
    });
  }

  handleSelectRestaurantAddress(address) {
    LOGGING && console.log("handleSelectAddress called with ", address);
    let newAddress = address,
      name = "";
    let { nomination } = this.state;
    if (!nomination || nomination === "undefined" || nomination.length === 0) {
      nomination = [];
    }
    if (address?.gmaps?.address_components) {
      const { gmaps } = address;
      const { address_components } = gmaps;
      const city = address_components.find((c) =>
        c.types.includes("locality")
      ).short_name;
      const number = address_components.find((c) =>
        c.types.includes("street_number")
      ).short_name;
      const street = address_components.find(
        (c) => c.types.includes("route") || c.types.includes("premise")
      ).short_name;
      const state = address_components.find((c) =>
        c.types.includes("administrative_area_level_1")
      ).short_name;
      const zip = address_components.find((c) =>
        c.types.includes("postal_code")
      ).short_name;

      newAddress = `${number} ${street}, ${city}, ${state} ${zip}`;
    }
    if (address?.gmaps?.name) {
      name = address.gmaps.name;
    }

    this.setState({
      nomination: {
        ...nomination,
        restaurant: { address: newAddress, name },
      },
      isSearching: false,
    });
  }

  handleSubmitNomination(e) {
    LOGGING && console.log("handleSubmitNomination called");
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      nominationErrorMessage: "",
    });

    const { nomination } = this.state;
    LOGGING &&
      console.log(
        "handleSubmitNomination called with nomination: ",
        nomination
      );
    if (nomination?.restaurant?.name === "") {
      this.setState({
        nominationErrorMessage: "Please provide the restaurant name.",
      });
      return;
    }
    this.setState({ loading: "submitting..." }, () => {
      this.props.saveNomination(nomination);
      this.setState({
        showNominationConfirmation: nomination,
        loading: false,
        showNominationform: false,
      });
      this.props.submitNomination().then((result) => {
        LOGGING && console.log("submitNomination got result: ", result);
        this.setState({
          showNominationConfirmation: nomination,
          loading: false,
          showNominationform: false,
        });
        setTimeout(() => {
          this.setState({
            showNominationConfirmation: false,
            nomination: { restaurant: { name: "" }, dish: "" },
          });
        }, 1000);
      });
    });
  }

  handleEditNomination(field, e) {
    let { nomination } = this.state;
    if (!nomination || nomination === "undefined" || nomination.length === 0) {
      nomination = [];
    }
    LOGGING &&
      console.log("handleEditNomination called with: ", {
        field,
        content: e.target.value,
      });
    this.setState({ nomination: { ...nomination, [field]: e.target.value } });
  }

  handleToggleShowHowItWorks(e) {
    e.preventDefault();
    const { showHowItWorks } = this.state;
    this.setState({ showHowItWorks: !showHowItWorks });
  }

  handleGoToHowItWorks(e) {
    e.preventDefault();
    const { howItWorksRef } = this.state;
    window.scrollTo({
      top: howItWorksRef?.current?.offsetTop + window.innerHeight() / 2,
      left: 0,
      behavior: "auto",
    });
  }
  handleSelectViewMode(viewMode, e) {
    e.preventDefault();
    this.setState({ viewMode });
  }
  handleBackToTop(e) {
    e.preventDefault();
    this.setState(
      {
        selectedDayIndex: 0,
      },
      () => {
        window.scrollTo(0, 0);
      }
    );
  }

  handleScroll() {
    const { selectedDayIndex, dayRefs } = this.state;
    const { weeklyMenu } = this.props;
    const weeklyMenuLength = weeklyMenu.filter(
      (m) => m.lunch.length > 0 && m.dinner.length > 0
    ).length;
    const currentScrollTop = window.pageYOffset,
      scrollY = window.scrollY;
    const currentDateTop = dayRefs?.[selectedDayIndex]?.current?.offsetTop;
    const currentDateBottom =
      selectedDayIndex < weeklyMenuLength - 1
        ? dayRefs?.[selectedDayIndex + 1]?.current?.offsetTop
        : currentDateTop + dayRefs?.[selectedDayIndex]?.current?.offsetHeight;

    this.setState({ homeScrollY: window.scrollY });
    if (
      selectedDayIndex < weeklyMenuLength - 1 &&
      currentScrollTop + 100 >= currentDateBottom
    ) {
      // LOGGING && console.log('handleScroll increasing selectedDayIndex by 1.');
      this.setState({
        selectedDayIndex: selectedDayIndex + 1,
      });
    } else if (
      selectedDayIndex > 0 &&
      currentScrollTop + 200 < currentDateTop
    ) {
      // LOGGING && console.log('handleScroll decreasing selectedDayIndex by 1.');
      this.setState({
        selectedDayIndex: selectedDayIndex - 1,
      });
    }
  }
  handleGoToDate(dayIndex, e) {
    // ? categories[c].refMobile : categories[c].refDesktop

    const { selectedDayIndex, dayRefs } = this.state;
    LOGGING &&
      console.log("handleGoToDate called with:", {
        selectedDayIndex,
        dayIndex,
        dayRefs,
        scrollingTo: dayRefs[dayIndex].current.offsetTop,
      });
    e.preventDefault();

    // scroll to the selected day ref to top of screen, and scroll 50vh more

    window.scrollTo({
      top:
        dayRefs[dayIndex].current.offsetTop +
        window.visualViewport.height * 0.6,
      left: 0,
      behavior: "auto",
    });
    this.setState({
      selectedDayIndex: dayIndex,
    });
  }
  handleToggleShowSocialActions(e) {
    e.preventDefault();
    LOGGING && console.log("Pagehome handleToggleShowSocialActions called");
    const { showSocialActions } = this.state;
    this.setState({ showSocialActions: !showSocialActions }, () => {
      window.scrollTo(0, 0);
    });
  }
  handleToggleShowShare(e) {
    e.preventDefault();
    LOGGING && console.log("Pagehome handleToggleShowShare called");
    const { showShare } = this.state;
    this.setState({ showShare: !showShare }, () => {
      window.scrollTo(0, 0);
    });
  }
  handleToggleUserMenu(e) {
    e.preventDefault();
    LOGGING && console.log("Pagehome handleToggleUserMenu called");
    const { showUserMenu } = this.state;
    this.setState({ showUserMenu: !showUserMenu });
  }
  handleToggleShowDeliveryCityOptions(e) {
    const { showDeliveryCityOptions } = this.state;
    LOGGING &&
      console.log(
        "handleToggleShowDeliveryCityOptions called with: ",
        showDeliveryCityOptions
      );
    e.preventDefault();

    this.setState({ showDeliveryCityOptions: !showDeliveryCityOptions });
  }

  handleSelectDeliveryCity(selected, e) {
    e.preventDefault();

    const { deliveryZoneByCity } = this.props;
    const deliveryCity = Object.keys(deliveryZoneByCity).sort((a, b) =>
      a.localeCompare(b)
    )[selected];
    const selectedDeliveryZone = deliveryZoneByCity[deliveryCity];
    LOGGING &&
      console.log("handleSelectDeliveryCity called with:", {
        deliveryCity,
        selected,
        deliveryZoneByCity,
        selectedDeliveryZone,
      });

    this.props.updateDeliveryCity(selectedDeliveryZone);
    this.setState({
      selectedDeliveryZone,
    });
    window.scrollTo(0, 0);
  }

  handleChangeDeliveryCity(selectedCityIndex) {
    const { deliveryZoneByCity, currentUser } = this.props;
    const viewableDays = getViewableDays();
    const { windowsForWeeklyMenu } = getWeeklyMenuWindows(viewableDays);

    LOGGING &&
      console.log("handleChangeDeliveryCity called with:", {
        selectedCityIndex,
        deliveryZoneByCity,
      });

    const deliveryCity = Object.keys(deliveryZoneByCity)[selectedCityIndex];
    const selectedDeliveryZone = deliveryZoneByCity[deliveryCity];

    this.props.updateDeliveryCity(selectedDeliveryZone);
    // this.props.emptyCart();
    this.setState(
      {
        selectedDeliveryZone,
        showDeliveryCityOptions: false,
        loading: "loading menu...",
      },
      () => {
        this.props
          .fetchWeeklyMeals(
            windowsForWeeklyMenu,
            selectedDeliveryZone,
            currentUser?.user?._id
          )
          .then(() => {
            this.setState({ loading: false }, () => {
              window.scrollTo(0, 0);
            });
          });
      }
    );
  }

  loadDeliveryZone() {
    // TODO: load default zone
    if (!this.state.selectedDeliveryZone) {
    }
  }

  trackPageHomeEvent(endAt, startAt) {
    const userId = this.props.currentUser?.user?._id;
    const uuid = `${FoodieEvent.PAGE_HOME}${startAt}`;
    if (this.props.location?.search?.includes("source=cards")) {
      TrackEvent(
        FoodieEvent.PAGE_HOME,
        uuid,
        FoodieSource.BUSINESS_CARDS,
        userId,
        null,
        endAt > startAt ? endAt - startAt : -1
      );
    } else if (this.props.location?.search?.includes("source=instagram")) {
      TrackEvent(
        FoodieEvent.PAGE_HOME,
        uuid,
        FoodieSource.INSTAGRAM,
        userId,
        null,
        endAt > startAt ? endAt - startAt : -1
      );
    } else if (this.props.location?.search?.includes("source=story")) {
      TrackEvent(
        FoodieEvent.PAGE_HOME,
        uuid,
        FoodieSource.STORY,
        userId,
        null,
        endAt > startAt ? endAt - startAt : -1
      );
    } else if (this.props.location?.search?.includes("source=promo")) {
      TrackEvent(
        FoodieEvent.PAGE_HOME,
        uuid,
        FoodieSource.PROMO,
        userId,
        null,
        endAt > startAt ? endAt - startAt : -1
      );
    } else {
      TrackEvent(
        FoodieEvent.PAGE_HOME,
        uuid,
        FoodieSource.UNKNOWN,
        userId,
        null,
        endAt > startAt ? endAt - startAt : -1
      );
    }
  }

  async componentDidMount() {
    const startAt = moment().valueOf();
    this.trackPageHomeEvent(-1, startAt);
    this.props.readHighlight();
    const homeScrollY = this.props?.location?.state?.homeScrollY || 0;
    window.addEventListener("scroll", this.handleScroll);

    const { currentUser, weeklyMenu, meals } = this.props;

    // showCompetitorComparison: used to decide if the user sees the 30 trial blocker
    const showCompetitorComparison =
      currentUser.isAuthenticated &&
      (!currentUser?.user?.membership?.status ||
        currentUser?.user?.membership?.status === "canceled") &&
      !currentUser?.user?.interaction?.seen_competitor_comparison;

    // selectedDeliveryZone: used to retrieve the correct weekly menu summary for home rendering
    let selectedDeliveryZone = "Palo Alto";
    const deliveryCity =
      this.props.deliveryCity ||
      this.props.currentUser?.user?.deliveryZone?.name;
    if (this.props.deliveryZoneByCity && deliveryCity) {
      selectedDeliveryZone = this.props.deliveryZoneByCity[deliveryCity];
    } else {
      await this.props.fetchDeliveryZoneByCity();
      selectedDeliveryZone =
        this.props.deliveryZoneByCity[this.props.deliveryCity];
    }

    // showCompetitorComparison
    // selectedDeliveryZone
    this.setState({
      showCompetitorComparison,
      selectedDeliveryZone,
    });

    const viewableDays = getViewableDays();
    const { windowsForWeeklyMenu, nextMeal } =
      getWeeklyMenuWindows(viewableDays);

    // update weekly summary which is used for home rendering
    let summaryOutdated = true;
    if (weeklyMenu && weeklyMenu.length > 0) {
      const menuFirstDay =
        moment().hour() > 16 ||
        (moment().hour() === 16 && moment().minute() > 30)
          ? moment().add(1, "d").startOf("day").valueOf()
          : moment().startOf("day").valueOf();
      summaryOutdated = weeklyMenu?.[0]?.lunchTime < menuFirstDay;
      // LOGGING &&  console.log("summary outdated check: ", { menuFirstDay, existingLunchTime: weeklyMenu[0].lunchTime });
    }
    // LOGGING &&
    //   console.log("PageHome componentDidMount with weeklyMenu:", {
    //     weeklyMenu,
    //     viewableDays,
    //     windowsForWeeklyMenu,
    //     nextMeal,
    //     summaryOutdated,
    //   });
    if (summaryOutdated) {
      this.setState(
        { loading: "loading menu.....", windowsForWeeklyMenu },
        () => {
          // blocking loading
          this.props
            .fetchWeeklyMeals(
              windowsForWeeklyMenu,
              selectedDeliveryZone,
              currentUser?.user?._id
            )
            .then(() => {
              LOGGING && console.log("fetchWeeklyMeals done");
              this.props.readFutureOrdersByCustomer();
              this.setState({ loading: false }, () => {
                LOGGING && console.log("fetchWeeklyMeals done2");
                this.trackPageHomeEvent(moment().valueOf(), startAt);
                setTimeout(() => {
                  window.scrollTo(0, 0);
                }, 1);
              });
            });
        }
      );
    } else {
      this.trackPageHomeEvent(moment().valueOf(), startAt);
      setTimeout(() => {
        window.scrollTo(0, homeScrollY);
      }, 1);
      // unblocking loading
      this.setState({ loading: false, windowsForWeeklyMenu });
      this.props
        .fetchWeeklyMeals(
          windowsForWeeklyMenu,
          selectedDeliveryZone,
          currentUser?.user?._id
        )
        .then(() => {
          this.props.readFutureOrdersByCustomer();
        });
    }

    // update weekly details which is not used for home rendering,
    // but buffered for single meal rendering
    let mealsOutdated = true;
    if (meals && meals.length > 0) {
      meals.sort((a, b) => a.windowStart - b.windowStart);
      mealsOutdated = meals[0].windowStart < moment().startOf("day").valueOf();
    }
    LOGGING &&
      console.log("PageHome componentDidMount with meals", {
        meals,
        mealsOutdated,
      });
    if (mealsOutdated) {
      // get next meal
      this.props.fetchWeeklyMealDetailsLight(
        [nextMeal],
        currentUser?.user?._id
      );
      // get next day that's not next meal
      this.props.fetchWeeklyMealDetailsLight(
        Object.values(windowsForWeeklyMenu[0]).filter((m) => m !== nextMeal),
        currentUser?.user?._id
      );
      // get next week that's not next day
      this.props.fetchWeeklyMealDetails(
        windowsForWeeklyMenu.slice(1),
        currentUser?.user?._id
      );
      window.scrollTo(0, homeScrollY || 0);
    }

    // read future orders for home rendering but not mandatory

    window.scrollTo(0, homeScrollY || 0);
    // expensive reads not for home rendering are disabled for now.
    // if (currentUser?.isAuthenticated) {
    // this.props.getRestaurantsByAlphabet();
    // this.props.readFutureOrdersByCustomer().then(() => {
    //   this.props.readPastOrdersByCustomer();
    // });
    //   this.props.readPastOrdersFromRestaurantByCustomer();
    // }
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const {
      loading,
      hasAnnouncement,
      // showShare,
      hasGiftCard,
      showDeliveryCityOptions,
      referralCode,
      showReferralCodeAnnouncement,
      selectedDeliveryZone,
      mealRefs,
      showSocialActions,
      selectedDayIndex,
      dayRefs,
      today,
      tomorrow,
      weekOfYear,
      howItWorksRef,
      homeScrollY,
      showHowItWorks,
      nominationErrorMessage,
      showNominationConfirmation,
      freeze,
      innerHeight,
      showUserMenu,
      showNominationform,
      nomination,
      showMembershipOnboarding,
      showPreferences,
      showCompetitorComparison,
      readingCounter,
      showTasteSurvey,
      windowsForWeeklyMenu,
      hasDrafts,
    } = this.state;

    const {
      currentUser,
      deliveryZoneByCity,
      weeklyMenu,
      orderHistoryByRestaurant,
      orders,
      carts,
      highlight,
    } = this.props;

    if (!currentUser.isAuthenticated || !currentUser.user) {
      // read params
      const params = new URLSearchParams(this.props.location?.search);
      const source = params.get("source");
      // MealVisited(`from ${source}!`, "someone");
      return <Redirect to="/home" />;
      // return <PageAnonymousLanding />;
    }
    if (currentUser.isAuthenticated && currentUser.user?.isDriver) {
      return <Redirect to="/driver" />;
    }
    if (currentUser.isAuthenticated && currentUser.user?.isRestaurant) {
      return <Redirect to="/restaurant-meals" />;
    }
    if (currentUser.isAuthenticated && currentUser.user?.isLeadDriver) {
      return <Redirect to="/leaddriver" />;
    }
    if (
      currentUser?.user === null ||
      currentUser?.user === "undefined" ||
      currentUser?.user?.isBlocked
    ) {
      return <Redirect to="/auth" />;
    }

    LOGGING &&
      console.log("PageHome render with:", {
        props: this.props,
        state: this.state,
      });

    const isMember = currentUser?.user?.membership?.isActive;
    // const showCompetitorComparison =
    //   currentUser.isAuthenticated &&
    //   (!currentUser?.user?.membership?.status ||
    //     currentUser?.user?.membership?.status === "canceled") &&
    //   !currentUser?.user?.interaction?.seen_competitor_comparison;
    // &&
    // currentUser?.user?.interaction?.seen_30D_trial &&
    // currentUser?.user?.interaction?.seen_30D_trial < yesterday;
    // const show30DTrial =
    //   currentUser.isAuthenticated &&
    //   !currentUser?.user?.membership?.status &&
    //   !currentUser?.user?.interaction?.seen_30D_trial;

    // const showMembershipOnboarding =
    //   currentUser.isAuthenticated &&
    //   (!currentUser?.user?.membership?.status ||
    //     currentUser?.user?.membership?.status === "canceled") &&
    //   !currentUser?.user?.interaction?.seen_member_onboarding;

    const showFallTreats =
      currentUser.isAuthenticated &&
      !currentUser?.user?.interaction?.seen_fall_treats;
    LOGGING && console.log("calculating showFallTreats:", showFallTreats);
    // LOGGING && console.log("calculating show30DTrial:",

    // Filter weeklyMenu by meal group
    // if (weeklyMenu?.length > 0) {
    //   weeklyMenu.forEach((dayMenu) => {
    //     dayMenu.lunch = dayMenu.lunch?.filter(
    //       (menu) =>
    //         menu.mealGroup === 0 ||
    //         currentUser.user?.mealGroup?.length === 0 ||
    //         currentUser.user?.mealGroup?.includes(menu.mealGroup)
    //     );
    //     dayMenu.earlyDinner = dayMenu.earlyDinner?.filter(
    //       (menu) =>
    //         menu.mealGroup === 0 ||
    //         currentUser.user?.mealGroup?.length == 0 ||
    //         currentUser.user?.mealGroup?.includes(menu.mealGroup)
    //     );
    //     dayMenu.dinner = dayMenu.dinner?.filter(
    //       (menu) =>
    //         menu.mealGroup === 0 ||
    //         currentUser.user?.mealGroup?.length === 0 ||
    //         currentUser.user?.mealGroup?.includes(menu.mealGroup)
    //     );
    //   });
    // }

    const lunchOrders = weeklyMenu?.map((m) =>
      m?.lunch?.map((entry) => entry.orders).reduce((x, y) => x.concat(y), [])
    );
    const dinnerOrders = weeklyMenu?.map((m) =>
      m?.dinner?.map((entry) => entry.orders).reduce((x, y) => x.concat(y), [])
    );
    const ordersByDate = lunchOrders?.map(
      (o, i) => o?.length + dinnerOrders[i].length
    );

    if (showTasteSurvey) {
      return <Redirect to="/taste" />;
    }

    return (
      <div
        className="page with-footer"
        style={freeze >= 0 ? { position: "fixed", top: `-${freeze}px` } : {}}
      >
        <Helmet>
          <title>Eat w/ neighbors, carpool delivery.</title>
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          <meta name="description" key="description" content={"free"} />
          <meta name="title" key="title" content={"foodie"} />
          <meta property="og:title" key="og:title" content={"foodie"} />
          <meta property="og:locale" key="og:locale" content="en_US" />
          <meta charSet="utf-8" />
          <meta property="og:type" key="og:type" content="website" />
          <meta
            property="og:description"
            key="og:description"
            content={"free"}
          />
          {/* <meta
            property="og:image"
            key="og:image"
            content={`https://images.pexels.com/photos/984888/pexels-photo-984888.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2`}
          /> */}
        </Helmet>
        <>
          <NavbarSingleMeal
            noShadow={true}
            onViewByWeekEmptyCart={this.handleViewByWeekEmptyCart}
            deliveryZoneByCity={Object.entries(deliveryZoneByCity).map(
              (entry) => {
                return { city: entry[0], deliveryZoneId: entry[1] };
              }
            )}
            onChangeDeliveryCity={this.handleChangeDeliveryCity}
            selectedDeliveryCity={currentUser.user?.deliveryZone?.name}
            showDeliveryCityOptions={showDeliveryCityOptions}
            onToggleShowDeliveryCityOptions={
              this.handleToggleShowDeliveryCityOptions
            }
            onToggleShowUserMenu={this.handleToggleUserMenu}
            showUserMenu={showUserMenu}
            dishesLoaded={true}
          />

          {/* {currentUser.isAuthenticated && currentUser?.user?.socialInviteCode?.code ? (
            <ShareBanner
              code={currentUser.user.socialInviteCode.code}
              firstName={currentUser?.user?.firstName}
              onShowSocialActions={this.handleToggleShowSocialActions}
            />
          ) : (
            <Hero2 onClick={this.handleGoToHowItWorks} />
          )} */}
          {/* <PopUp
            componentToDisplay={
              <MembershipOnboarding
                onClick={this.handleClickTrialNotification}
              />
            }
            isPoppedUp={
              currentUser.user?.deliveryZone?.name && showMembershipOnboarding
            }
            hidePopUp={this.handleClickTrialNotification.bind(this, 0)}
          /> */}
          {/* <PopUp
            componentToDisplay={
              <MyDrinkBlockingNonMember
                onClose={this.handleClickSummerTreats.bind(this, 0)}
                onProceed={this.handleClickSummerTreats.bind(this, 1)}
              />
            }
            isPoppedUp={
              currentUser.user?.deliveryZone?.name &&
              showFallTreats &&
              !showCompetitorComparison
            }
            hidePopUp={this.handleClickSummerTreats.bind(this, 0)}
          /> */}
          <PopUp
            isPoppedUp={
              currentUser.user?.deliveryZone?.name && showCompetitorComparison
            }
            componentToDisplay={
              <Membership30DTrial onClick={this.handleClickComparison} />
            }
            backgroundColor={"#333"}
            hidePopUp={this.handleClickComparison.bind(this, 0)}
          />
          <PopUp
            isPoppedUp={showSocialActions}
            componentToDisplay={
              <SocialActions code={currentUser?.user?.socialInviteCode?.code} />
            }
            backgroundColor={"#333"}
            hidePopUp={this.handleToggleShowSocialActions}
          />
          {/* <OrderBanner
            showConfirmation={showOrderConfirmation}
            onHideConfirmation={this.handleHideOrderConfirmation}
          /> */}

          {/* <PopUp
            isPoppedUp={showNominationform}
            componentToDisplay={
              <NominateDishInFlow
                nomination={nomination}
                nominationErrorMessage={nominationErrorMessage}
                onEditNomination={this.handleEditNomination}
                onSubmit={this.handleSubmitNomination}
                onChangeRestaurantName={this.handleChangeRestaurantName}
                onSelectRestaurantAddress={this.handleSelectRestaurantAddress}
                isMobile={true}
                showHeader={false}
              />
            }
            hidePopUp={this.handleToggleShowNominationForm}
            backgroundColor={"#333"}
          /> */}
          <PopUp
            isPoppedUp={!currentUser.user?.deliveryZone?.name}
            componentToDisplay={
              // <DeliveryZoneByCitySelectorUnselected
              //   deliveryZoneByCity={deliveryZoneByCity}
              //   onSelectCity={this.handleSelectDeliveryCity}
              // />
              <CitySelector />
              // <StopsMapV3/>
            }
            backgroundColor={"#333"}
          />
          {showNominationConfirmation ? (
            <NominationBanner
              showConfirmation={showNominationConfirmation}
              onHideConfirmation={this.handleHideNominationConfirmation}
            />
          ) : null}

          {currentUser.user?.deliveryZone?.name &&
            !showCompetitorComparison &&
            showPreferences && <TasteAndTime onClose={this.handleClosePage} />}
          {/* <SelectViewMode viewMode={viewMode} onSelect={this.handleSelectViewMode} /> */}
          {currentUser.user?.deliveryZone?.name ? (
            <>
              {/* {highlight?.windowStart ? null : <GiftCardAnnouncement />} */}
              {highlight?.windowStart ? (
                <MealHighlights highlight={highlight} />
              ) : null}
              {/* <MileageSaving /> */}
              {/* <SummerReferralAwardEntry /> */}
              <QuickAccess />
              {loading ? null : (
                <DateNavbar
                  dates={(weeklyMenu ?? [])
                    .filter(
                      (m) =>
                        (m.lunch ?? []).length > 0 &&
                        (m.dinner ?? []).length > 0
                    )
                    .map((m) => m?.lunchTime)}
                  selectedDayIndex={selectedDayIndex}
                  onGoToDate={this.handleGoToDate}
                  ordersByDate={ordersByDate}
                  showUserMenu={showUserMenu}
                />
              )}
              <NewUserCredit key={Math.random()} />
              {/* {hasDrafts ? (
                <FoodieDraft onSetHasDrafted={this.handleHasDrafts} />
              ) : ( */}
              <UpcomingFavorites windowsForWeeklyMenu={windowsForWeeklyMenu} />
              {/* )} */}
              {/* <FavoriteEntry /> */}
              {loading ? (
                <LoadingTop text={loading} />
              ) : (
                <>
                  <TodaysOrders />
                  <WeeklyMenuLink
                    orderHistoryByRestaurant={orderHistoryByRestaurant}
                    dayRefs={dayRefs}
                    menu={weeklyMenu}
                    onSetReadingCounter={this.handleSetReadingCounter}
                    readingCounter={readingCounter}
                    selectedDeliveryZone={selectedDeliveryZone}
                    hasAnnouncement={hasAnnouncement}
                    referralCode={referralCode}
                    showReferralCodeAnnouncement={showReferralCodeAnnouncement}
                    hasGiftCard={hasGiftCard}
                    dishesLoaded={true}
                    mealRefs={mealRefs}
                    isAuthenticated={currentUser.isAuthenticated}
                    today={today}
                    tomorrow={tomorrow}
                    weekOfYear={weekOfYear}
                    onBackToTop={this.handleBackToTop}
                    howItWorksRef={howItWorksRef}
                    homeScrollY={homeScrollY}
                    showHowItWorks={showHowItWorks}
                    onToggleShowHowItWorks={this.handleToggleShowHowItWorks}
                    showNominationform={showNominationform}
                    onToggleShowNominationForm={
                      this.handleToggleShowNominationForm
                    }
                    freeze={freeze}
                    onFreeze={this.handleFreezeBackground}
                    innerHeight={innerHeight}
                    isMember={isMember}
                    userLocation={currentUser?.user?.deliveryInfo?.map}
                    nomination={nomination}
                    nominationErrorMessage={nominationErrorMessage}
                    onEditNomination={this.handleEditNomination}
                    onSubmitNomination={this.handleSubmitNomination}
                    onChangeRestaurantName={this.handleChangeRestaurantName}
                    onSelectRestaurantAddress={
                      this.handleSelectRestaurantAddress
                    }
                    isMobile={true}
                    userName={`${currentUser?.user?.firstName} ${currentUser?.user?.lastName} ${currentUser?.user?._id}`}
                  />
                </>
              )}
            </>
          ) : null}
        </>
        {/* {currentUser.isAuthenticated && !currentUser.user.isAdmin ? (
          <QuickAccess
            selected={quickAccess}
            onSelect={this.handleSetQuickAccess}
          />
        ) : null} */}
        {/* <LiveChat hasShadow={true} /> */}
        {/* <ExpandedChatEntry /> */}
        {/* <FoldedChatEntry /> */}
        {/* <Chat /> */}
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    week: state.week,
    deliveryZoneByCity: state.deliveryZoneByCity,
    nomination: state.nomination,
    weeklyMenu: state.weeklyMenu.payLoad,
    meals: state.meals,
    orderHistoryByRestaurant: state.orderHistoryByRestaurant,
    // referralCode: state.referralCode.payLoad,
    restaurants: state.restaurants,
    cuisines: state.cuisines,
    orders: state.orders,
    carts: state.carts,
    highlight: state.highlight,
  };
}

// export const WrappedPageHome = GoogleApiWrapper({
//   apiKey: "AIzaSyBjfTfgJdRah5wXvVj8U7fH4ACoqKry9HI",
//   LoadingContainer: () => null,
// })(PageHome);

// export const ReactRouterWrappedPageHome = withRouter(PageHome);

export default connect(mapStateToProps, {
  fetchWeeklyMeals,
  fetchDeliveryZoneByCity,
  fetchWeeklyMealDetails,
  fetchWeeklyMealDetailsLight,
  setDeliveryCity,
  resetDeliveryCity,
  updateDeliveryCity,
  getReferralCode,
  emptyCart,
  saveNomination,
  submitNomination,
  readPastOrdersFromRestaurantByCustomer,
  getRestaurantsByAlphabet,
  readPastOrdersByCustomer,
  readFutureOrdersByCustomer,
  updateInteraction,
  readHighlight,
  getFavorites,
  getTastes,
  // })(ReactRouterWrappedPageHome);
})(PageHome);
