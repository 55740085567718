import './Avatar.scss';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const nameColor = (profileColor) => {
  const r = Number('0x' + profileColor.slice(1, 3));
  const g = Number('0x' + profileColor.slice(3, 5));
  const b = Number('0x' + profileColor.slice(5, 7));
  const foregroundColor = (r + g + b) / 3 > 200 ? 'black' : 'white';
  return foregroundColor;
};
const Avatar = ({ user }) =>
  user ? (
    user.profilePictureURL ? (
      <div
        className="avatar from-social-network"
        style={{
          backgroundImage: 'url(' + user.profilePictureURL + ')',
        }}
      ></div>
    ) : (
      <div
        className="avatar letter-only"
        style={
          user.profileColor
            ? {
                background: user.profileColor,
                color: nameColor(user.profileColor),
              }
            : {}
        }
      >
        <span className="initial">
          {user.firstName ? (
            user.firstName.replace(/ /g, '')[0]
          ) : user.email ? (
            user.email.replace(/ /g, '')[0]
          ) : (
            <FontAwesomeIcon icon={faUser} />
          )}
        </span>
      </div>
    )
  ) : null;

export const ProfileLink = ({ user }) =>
  user ? (
    <Link className="profile-link" to={`/profile/${user.email}`}>
      <Avatar user={user} />
      <span className="name">{`${user.firstName || user.email}`}</span>
    </Link>
  ) : null;
export default Avatar;
