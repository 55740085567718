import React, { ReactElement, useCallback, useState, useEffect } from "react";
import { capitalize } from "lodash";
import { useSelector, useDispatch, useStore } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { MealVisited } from "src/api/meals";
import { RootState } from "src/store";
import styled, { keyframes } from "styled-components";
import { SurveyRestaurants } from "./SurveyRestaurants";
import { TasteSurveyRestaurant } from "./TasteSurveyRestaurant";
import { Close, LOGGING, PopUp } from "../..";
import { saveFavorites } from "../../../../../store/actions";
import {
  BackgroundGrey,
  Beige,
  Centered,
  CenteredByColumn,
  NavHeight,
  TextTitle,
  actionGrey,
  actionBlack,
  text12300,
  card,
  BorderGrey,
  CenteredByColumnLeft,
  BackgroundBlue,
  GoogleMapBlank,
  Overlay,
  textTitle,
} from "../../Shared";
import { BottomBanner } from "../banners/BottomBanner";
const Container = styled(CenteredByColumn)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-color: white;
`;

const Nav = styled(CenteredByColumn)`
  width: 100vw;
  position: relative;
  padding: 20px 10px 10px 10px;
  background-color: white;
  label {
    margin: 10px 0px;
    ${text12300}
    font-size: 13px;
    line-height: 1.5;
    text-align: center;
    b {
      font-weight: 600;
    }
  }
  /* background-color: ${Beige}; */
  @media (min-width: 800px) {
    /* width: calc(100% - 650px); */
    padding-top: 0px;
    width: auto;
    background-color: transparent;
    align-items: flex-start;
    label {
      font-size: 16px;
      text-align: left;
    }
    /* padding-left: 40px; */
  }
`;
const HeaderTitle = styled(TextTitle)`
  font-size: 20px !important;
  text-transform: none;
  @media (min-width: 800px) {
    font-size: 24px !important;
  }
`;

const Button = styled.button`
  ${actionBlack}
  margin-top: 10px;
  flex-shrink: 0;
`;

const Header = ({ count, name }) => (
  <Nav>
    <HeaderTitle>{capitalize(name)}, select 3 or more you like.</HeaderTitle>
    {/* <label>{count} selected</label> */}
    <label>
      It will help us find restaurants you'll love.{" "}
      <b>Select the ones you like.</b>
    </label>
    {/* <Close onClick={onSkip} /> */}
    {/* <Button>continue</Button> */}
  </Nav>
);

const Body = styled(Centered)`
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  overflow-y: auto;
  justify-content: flex-start;
  /* margin-top: ${NavHeight}px; */
  /* padding: 2px; */
  padding-bottom: 200px;
  /* background-color: ${Beige}; */
  /* background-color: ${BackgroundBlue}; */
  background-color: ${GoogleMapBlank};
  @media (min-width: 800px) {
    flex-direction: row;
    padding: 40px 8vw 200px 8vw;
    align-items: flex-start;
    justify-content: space-between;
  }
`;
const List = styled(Centered)`
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 2px;
  @media (min-width: 800px) {
    max-width: 700px;
    justify-content: center;
  }
`;
const SavingMessage = styled(CenteredByColumn)`
  /* display: inline-block; */
  width: 100vw;
  height: 100vh;
  color: white;
  text-align: center;
  z-index: 200;
  text-transform: capitalize;
  span {
    ${textTitle}
    margin: 40px 0px;
  }
  @media screen and (min-width: 800px) {
    font-size: 40px;
  }
`;
// add a rotating animation
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
// create a rotating loading spinner
export const LoadingSpinner = styled.div`
  display: inline-block;
  width: 80px;
  height: 80px;
  &:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid white;
    border-color: white transparent white transparent;
    animation: ${rotate} 1.2s linear infinite;
  }
`;
export const SavingScreen = ({ message, isFullScreen = false }) => (
  <Overlay
    style={{
      zIndex: isFullScreen ? 999 : 100,
      backgroundColor: "rgba(0,0,0, 0.6)",
      height: `${isFullScreen ? "100vh" : "auto"}`,
      top: `${isFullScreen ? "0" : "auto"}`,
    }}
  >
    <SavingMessage>
      <LoadingSpinner />
      <span>{message}</span>
    </SavingMessage>
  </Overlay>
);

export interface TasteProps {
  onClose: () => void;
}
export const Taste: React.FC<TasteProps> = ({
  onClose,
}: TasteProps): ReactElement => {
  const currentUser = useSelector((state: RootState) => state.currentUser);
  LOGGING && console.log("currentUser: ", currentUser.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useStore();
  // const [favorites, setFavorites] = useState(SurveyRestaurants.reduce((obj, restaurant) => {
  //   obj[restaurant._id] = false;
  //   return obj;
  // }, {}));

  const [favorites, setFavorites] = useState(currentUser?.user?.tastes || []);
  const [saving, setSaving] = useState(false);

  const [selected, setSelected] = useState(currentUser?.user?.tastes || []);
  LOGGING &&
    console.log("selected: ", { SurveyRestaurants, favorites, selected });

  useEffect(() => {
    setSelected(
      SurveyRestaurants.filter((restaurant) =>
        favorites.includes(restaurant._id)
      )
    );
  }, [favorites]);
  useEffect(() => {
    MealVisited(
      "select-taste",
      `${currentUser?.user?.firstName} ${currentUser?.user?.lastName}`,
      "",
      "",
      0
    );
  }, []);

  const onToggleFavorite = async (restaurantId, e) => {
    e.stopPropagation();
    e.preventDefault();
    LOGGING &&
      console.log("toggleFavorite called with restaurantId: ", restaurantId);
    let newFavorites = [];
    if (favorites.includes(restaurantId)) {
      newFavorites = favorites.filter((id) => id !== restaurantId);
    } else {
      newFavorites = [...favorites, restaurantId];
    }
    setFavorites(newFavorites);
    setSelected(
      SurveyRestaurants.filter((restaurant) =>
        newFavorites.includes(restaurant._id)
      )
    );
    // toggleFavorite(restaurantId)(dispatch, store.getState).then(() => {
    //   LOGGING && console.log("Done toggling favorites.");
    //   /// selected equals the nuber of survey restaurants that are in the favorites array

    // });
  };

  return (
    <Container>
      {saving && <SavingScreen message={"creating menu for you..."} />}
      <Body>
        <Header
          // onSkip={onSkip}
          count={favorites.length}
          name={currentUser?.user?.firstName}
        />
        <List>
          {SurveyRestaurants.map((restaurant) => (
            //@ts-ignore
            <TasteSurveyRestaurant
              {...restaurant}
              key={restaurant._id}
              isFavorite={favorites.includes(restaurant._id)}
              onToggleFavorite={onToggleFavorite.bind(this, restaurant._id)}
            />
          ))}
        </List>
      </Body>
      {saving ? null : (
        <BottomBanner
          onClick={() => {
            setSaving(true);
            saveFavorites(favorites)(dispatch, store.getState).then(() => {
              LOGGING && console.log("Done saveFavorites.");
              MealVisited(
                `continued with ${selected.length} picks: ${selected
                  .map((s) => s.name)
                  .join(", ")}`,
                `${currentUser?.user?.firstName} ${currentUser?.user?.lastName}`,
                "",
                "",
                0
              );
              setSaving(false);
              history.push("/");
            });
          }}
          label={
            selected.length < 3 ? "Pick 3 or more to continue" : "Continue"
          }
          disabled={selected.length < 3 || saving}
          // tagline="You can make edits any time."
        />
      )}
    </Container>
  );
};
