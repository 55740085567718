import { faYelp } from "@fortawesome/free-brands-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { centeredByColumn, YelpRed } from "../../Shared";
import { Beige, BorderGrey, BreakPoint, NavHeight } from "../../Shared";
import { text12300, textTitle } from "../../Shared";
import { centered, Centered } from "../../Shared";

interface MembershipStatusProps {
  isMobile: boolean;
}
const YelpLink = styled(Link)<MembershipStatusProps>`
  ${centeredByColumn}
  flex-grow: 1;
  justify-content: flex-start;
  ${text12300}
  position: relative;
  display: ${(props) => (props.isMobile ? "flex" : "none")};
  align-self: stretch;
  span {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 1.6px;
    text-transform: capitalize;
  }
  > svg {
    margin: 0px 6px 0px 2px;
    position: absolute;
    right: 0px;
  }
  > a {
    position: absolute;
    right: 0px;
    top: 0px;
    font-size: 18px;
    ${centered}
    height: 100%;
    width: 45px;
    /* color: white; */
  }
  @media only screen and (min-width: ${BreakPoint}px) {
    /* position: absolute;
    left: -100px; */
    z-index: 1;
    /* width: 150px; */
    position: absolute;
    height: ${NavHeight}px;
    padding: 0px;
    margin: 0px;
    display: ${(props) => (props.isMobile ? "none" : "flex")};
    left: calc(${NavHeight}px + 20px);
    top: 15px;
    box-shadow: none;
    width: auto;
    background-color: ${Beige};
    padding: 0px 15px;
    box-shadow: 0px 0px 5px ${BorderGrey};
    border: 1px solid ${BorderGrey};
    flex-direction: column;
    > svg {
      top: 12px;
      right: 5px;
    }
    a {
      ${textTitle}
      position: absolute;
      white-space: nowrap;
      /* border-bottom: 1px solid black; */
      font-size: 14px;
      line-height: 1.5;
      height: 40px;
      padding: 0px 20px;
      width: auto;
      label {
        margin: 0px 8px;
      }
      /* display: none; */
      /* color: white; */
      /* background-color: ${YelpRed}; */
      /* width: 200px; */
    }
  }
`;
const YelpLogo = styled(Centered)`
  background-color: ${Beige};
  padding: 7px 10px;
  margin-right: 15px;
  border-radius: 7px;
  svg {
    font-size: 18px;
    margin-left: 5px;
    margin-bottom: 5px;
    color: ${YelpRed};
    transform: rotate(-5deg);
  }
  label {
    ${textTitle}
    /* font-family: Helvetica;
    font-weight: 700; */
    font-size: 16px;
    /* border-bottom: 1px solid white; */
    line-height: 1.2;
    /* margin-right: 8px; */
  }
`;
const YelpContent = styled(Centered)``;
export const Yelp = ({ restaurant, isMobile }) => {
  // {yelpUrl != null && restaurant?.yelp?.reviews && (
  return (
    <YelpLink
      isMobile={isMobile}
      to={{ pathname: restaurant?.contact?.yelpUrl }}
      target="_blank"
    >
      <YelpLogo>
        <label>Yelp</label>
        {/* <FontAwesomeIcon icon={faYelp} /> */}
      </YelpLogo>
      <YelpContent>
        <span>{`${restaurant?.yelp?.rating.toFixed(1)}`}</span>
        {/* <FontAwesomeIcon icon={faStar} /> */}
        <span>{` (${restaurant?.yelp?.reviews})`}</span>
      </YelpContent>
    </YelpLink>
  );
};
