import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { GetStatsForMeal } from "src/api/meals";
import styled from "styled-components";
import { LOGGING } from "..";
import { AdminDeliveryHeaderV2 } from "./AdminDeliveryHeaderV2";
import { AdminDeliveryV2ControlPanelContainer } from "./AdminDeliveryV2ControlPanelContainer";
import { AwaitServerResponseContainer } from "./AwaitServerResponseContainer";
import { MealMetricSummaryCardContainer } from "./MealMetricSummaryCardContainer";
import { PickupTimeSelect } from "./PickupTimeSelect";
import { RestaurantLegendsOnMap } from "./restaurants/RestaurantLegendsOnMap";
import { StopsMapV2 } from "./StopsMapV2";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 160px);
`;

const Panels = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  flex-grow: 1;
  flex-wrap: wrap;
`;

const LeftPanel = styled.div`
  height: 100%;
  min-width: 350px;
  flex-grow: 100;
`;

const RightPanel = styled.div`
  height: 100%;
  flex-grow: 1;
  width: 50%;
  max-width: 500px;
  position: relative;
`;

export const DeliveryV2 = () => {
  const stops = useSelector((state) => state.adminDeliveryV2.stops);
  console.log(
    "DeliveryV2 got stops",
    stops.filter((s) => s.stopType === "meal")
  );

  const [showDetails, onSetShowDetails] = useState(false);
  const [dailyStats, setDailyStats] = useState({});
  const onToggleShowDetails = () => {
    onSetShowDetails(!showDetails);
  };
  const lunchStops = stops.filter(
    (s) => s.mealType === "lunch" && s.stopType === "order"
  );
  const earlyDinnerStops = stops.filter(
    (s) => s.mealType === "earlyDinner" && s.stopType === "order"
  );
  const dinnerStops = stops.filter(
    (s) => s.mealType === "dinner" && s.stopType === "order"
  );

  const fetchDataQuery = useCallback(
    async (dispatch, getState) => {
      const dailyMealIdsToSummarize = stops
        .map((stop) => stop.meal)
        .filter(Boolean);
      const fetchedDailyMealStats = await GetStatsForMeal(
        dailyMealIdsToSummarize
      )(dispatch, getState);
      setDailyStats(fetchedDailyMealStats);
    },
    [stops]
  );
  LOGGING && console.log("dailyStats:", dailyStats);
  return (
    <AwaitServerResponseContainer awaitable={fetchDataQuery}>
      <Container>
        <AdminDeliveryHeaderV2
          numLunchStops={lunchStops.length}
          numEarlyDinnerStops={earlyDinnerStops.length}
          numDinnerStops={dinnerStops.length}
          dailyStats={dailyStats}
        />
        <Panels>
          <LeftPanel>
            {/* <RestaurantLegendsOnMap /> */}
            <StopsMapV2 />
          </LeftPanel>
          <RightPanel>
            <PickupTimeSelect />
            <MealMetricSummaryCardContainer
              showDetails={showDetails}
              onToggleShowDetails={onToggleShowDetails}
            />
            <AdminDeliveryV2ControlPanelContainer />
          </RightPanel>
        </Panels>
      </Container>
    </AwaitServerResponseContainer>
  );
};
