import styled from 'styled-components';

type FlexGap = number | string;

type FlexProps = {
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-around' | 'space-between';
  align?: 'start' | 'end' | 'center' | 'baseline' | 'stretch';
  gap?: FlexGap;
  direction?: 'row' | 'column';
  inline?: boolean;
  widthFull?: boolean;
  heightFull?: boolean;
};

const parseFlexGap = (flexGap: FlexGap): string => {
  return typeof flexGap === 'number' ? (flexGap > 0 ? `${flexGap}px` : '0') : flexGap;
};

/* Safari not support flexbox gap, use margin-left, margin-top instead, see: https://caniuse.com/?search=flexbox%20gap */
export const Flex = styled.div<FlexProps>`
  display: flex;

  ${({ justify, align, gap, direction, inline, widthFull, heightFull }) => `
    ${justify ? `justify-content: ${justify};` : ''}
    ${align ? `align-items: ${align};` : ''}
    ${direction ? `flex-direction: ${direction};` : ''}
    ${
      gap
        ? `
        > * + * {
          ${direction === 'column' ? 'margin-top' : 'margin-left'}: ${parseFlexGap(gap)};
        }
        `
        : ''
    }
    ${inline ? 'display: inline-flex;' : 'display: flex;'}
    ${widthFull ? 'width: 100%;' : ''}
    ${heightFull ? 'height: 100%;' : ''}
  `};
`;
