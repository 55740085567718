import "./SiteHeroImage.scss";

const SiteHeroImage = ({ images }) => {
  return (
    <div className="site-hero-image">
      {images.map((img, i) => (
        <div
          className="pic"
          id={`pic${i}`}
          style={{
            backgroundImage: `url(${img})`,
            animationDelay: `${i * 3}s`,
          }}
        />
      ))}
    </div>
  );
};
export default SiteHeroImage;
