import { useCallback } from 'react';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faHouse, faVolume, faVolumeSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { Flex } from 'src/components/Layout';
import { setTransientMessage } from 'src/store/actions/transientMessage';

export const DriverTaskDetails = ({
  taskNum,
  address,
  addressName,
  addressMap,
  ringDoorbell,
  totalNumTasks,
}) => {
  const dispatch = useDispatch();
  const copyAddressToClipboard = useCallback(() => {
    navigator.clipboard.writeText(address);
    setTransientMessage(dispatch, `Copied ${address}`, faCopy);
  }, [address, dispatch]);

  return (
    <div className="receipt">
      <div className="driver-action order-name">
        <div className="order-status">
          <span>{`Task ${taskNum + 1} of ${totalNumTasks}`}</span>
        </div>
      </div>
      <div className="driver-action order-address">
        <div className="driver-label">
          <FontAwesomeIcon icon={faHouse} />
        </div>
        <div className="order-status">
          <Flex direction="column">
            {addressName != null ? (
              <div className="order-status">
                <div>{addressName}</div>
              </div>
            ) : null}
            <button className="order-status" onClick={copyAddressToClipboard}>
              <div style={{ color: 'blue' }}>{address}</div>
            </button>
            <span>NOTE: Click the address to copy</span>
          </Flex>
        </div>
      </div>
      {ringDoorbell != null ? (
        <div className="driver-action doorbell-instructions">
          <div className="driver-label">
            <FontAwesomeIcon icon={ringDoorbell ? faVolume : faVolumeSlash} />
          </div>
          <div className="order-status">
            <span>
              {ringDoorbell
                ? 'Knock the door/ring the doorbell.'
                : 'Do NOT knock the door/ring the doorbell.'}
            </span>
          </div>
        </div>
      ) : null}
      {addressMap ? (
        <div className="driver-action order-address-map">
          <div className="map-container">
            <img src={addressMap} alt={address}></img>
          </div>
        </div>
      ) : null}
    </div>
  );
};
