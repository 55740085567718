import { LOGGING } from "src/constants";
import { ObjectId } from "src/constants/types";
import { setCurrentUser } from "src/store/actions";
import { apiCall } from "src/store/api";
export type ReadGiftByIdResponse = {
  gift: {
    email: string;
    amount: number;
  };
};

export const ReadGiftById = async (giftId: ObjectId): Promise<ObjectId> => {
  try {
    LOGGING && console.log("ReadGiftById called giftId:", giftId);
    const gift = await apiCall("GET", `/giftCards/claim/${giftId}`);
    LOGGING && console.log("ReadGiftById returning gift:", gift);
    return gift;
  } catch (err) {
    LOGGING && console.log("ReadGiftById got err", err);
    throw err;
  }
};

export const ClaimGift = async (
  giftId: ObjectId,
  user: any,
  sum: number
): Promise<ObjectId> => {
  try {
    const userId = user._id;
    LOGGING &&
      console.log("ClaimGift calling server with:", { userId, sum, giftId });
    const result = await apiCall("post", `/giftCards/claim/${giftId}`, {
      userId,
      sum,
    });
    LOGGING && console.log("ClaimGift returning result:", result);
    return result;
  } catch (err) {
    LOGGING && console.log("ClaimGift got err", err);
  }
};
