import React, { Component } from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { forgetPassword } from "../../store/actions";
import { ResetPassword } from "../tasting_menu/components/auth/GetPassword";
import { Loading, LOGGING, ForgetPassword, Back } from "./";
class PageForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props?.location?.state?.email || "",
      emailSent: false,
      loading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }
  handleCancel(e) {
    e.preventDefault();
    LOGGING && console.log("handleCancel called");
    this.props.history.goBack();
  }

  handleSubmit(email) {
    this.setState({ loading: true });
    this.props
      .forgetPassword(email)
      .then(() => {
        toast.success("Resent link sent!");
        this.props.history.push({
          pathname: "/login",
          state: { email: email },
        });
      })
      .catch((error) => {
        LOGGING && console.log("forgetPassword error:", error);
      });
  }

  render() {
    const { email, loading } = this.state;
    LOGGING &&
      console.log("PageForgetPassword got", {
        state: this.state,
        props: this.props,
      });
    return (
      <div className="page">
        <div className="auth-title">
          <Back onClick={this.handleCancel} />
          <span>Reset Password</span>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <ResetPassword
            defaultEmail={email}
            onSendResetLink={this.handleSubmit}
          />
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  LOGGING && console.log("PageForgetPassword got redux state:", state);
  return {
    currentUser: state.currentUser,
  };
}
export default connect(mapStateToProps, { forgetPassword })(PageForgetPassword);
