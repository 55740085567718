import { useEffect } from 'react';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { Button } from 'src/components/Button';
import { Flex } from 'src/components/Layout';
import { Modal } from 'src/components/Modal';
import { useForm } from 'src/hooks/useForm';
import { createCouponCode, updateCouponCode } from 'src/store/actions';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

type CouponModalProps = {
  isOpen: boolean;
  couponCode?: any;
  onClose: () => void;
};

type CouponCodeFormData = {
  code?: string;
  discountType?: 'dollar' | 'percentage';
  discountDollar?: string;
  discountPercentage?: string;
  commissionType?: 'dollar' | 'percentage';
  commissionDollar?: string;
  commissionPercentage?: string;
  minTotal?: number;
  mealId?: string;
  expireType?: 'none' | 'time' | 'count';
  expireTimeDate?: Date;
  expireTime?: string;
  expireCount?: string;
  onlyForNewUser?: string;
  notes?: string;
};

const INITIALIZE_VALUES: CouponCodeFormData = {
  code: '',
  discountType: 'dollar',
  discountDollar: '',
  discountPercentage: '',
  commissionType: 'dollar',
  commissionDollar: '',
  commissionPercentage: '',
  minTotal: 0,
  mealId: '',
  expireType: 'none',
  expireTime: '',
  expireCount: '',
  onlyForNewUser: '0',
  notes: '',
};

export const CouponModal = (props: CouponModalProps) => {
  const { isOpen, couponCode, onClose } = props;

  const CreateCouponCodeFormTransform = (formData: CouponCodeFormData) => {
    formData.code = formData.code?.toLocaleUpperCase();
  };

  const { formData, updateFormData, onInputChange } = useForm<CouponCodeFormData>(
    INITIALIZE_VALUES,
    { transform: CreateCouponCodeFormTransform }
  );

  useEffect(() => {
    if (couponCode) {
      updateFormData((formData) => {
        formData.code = couponCode.code || '';
        formData.discountType = couponCode.discountType || '';
        formData.discountDollar = couponCode.discountDollar || '';
        formData.discountPercentage = couponCode.discountPercentage || '';
        formData.commissionType = couponCode.commissionType || '';
        formData.commissionDollar = couponCode.commissionDollar || '';
        formData.commissionPercentage = couponCode.commissionPercentage || '';
        formData.minTotal = couponCode.minTotal || 0;
        formData.mealId = couponCode.mealId || '';
        formData.expireType = couponCode.expireType || '';
        formData.expireTimeDate = couponCode.expireTime
          ? moment(couponCode.expireTime).toDate()
          : undefined;
        formData.expireTime = couponCode.expireTime || '';
        formData.expireCount = couponCode.expireCount || '';
        formData.onlyForNewUser = couponCode.onlyForNewUser ? '1' : '0';
        formData.notes = couponCode.notes || '';
      });
    } else {
      updateFormData((formData) => {
        formData.code = INITIALIZE_VALUES.code;
        formData.discountType = INITIALIZE_VALUES.discountType;
        formData.discountDollar = INITIALIZE_VALUES.discountDollar;
        formData.discountPercentage = INITIALIZE_VALUES.discountPercentage;
        formData.commissionType = INITIALIZE_VALUES.commissionType;
        formData.commissionDollar = INITIALIZE_VALUES.commissionDollar;
        formData.commissionPercentage = INITIALIZE_VALUES.commissionPercentage;
        formData.minTotal = INITIALIZE_VALUES.minTotal;
        formData.mealId = INITIALIZE_VALUES.mealId;
        formData.expireType = INITIALIZE_VALUES.expireType;
        formData.expireTimeDate = INITIALIZE_VALUES.expireTimeDate;
        formData.expireTime = INITIALIZE_VALUES.expireTime;
        formData.expireCount = INITIALIZE_VALUES.expireCount;
        formData.onlyForNewUser = INITIALIZE_VALUES.onlyForNewUser;
        formData.notes = INITIALIZE_VALUES.notes;
      });
    }
  }, [couponCode, updateFormData]);

  const dispatch = useDispatch();

  const onExpireTimeChange = (date: Date) => {
    updateFormData((formData) => {
      formData.expireTimeDate = date;
    });
  };

  const saveCouponCode = async () => {
    const copiedFormData = { ...formData };
    if (copiedFormData.expireTimeDate) {
      copiedFormData.expireTime = String(moment(copiedFormData.expireTimeDate).valueOf());
      delete copiedFormData.expireTimeDate;
    }
    copiedFormData.onlyForNewUser = (copiedFormData.onlyForNewUser === '1' ? true : false) as any;
    if (couponCode) {
      dispatch(updateCouponCode(couponCode._id, copiedFormData));
    } else {
      dispatch(createCouponCode(copiedFormData));
    }
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      title={couponCode ? 'Update Coupon Code' : 'Create Coupon Code'}
      showClose
      onClose={onClose}
    >
      <CouponCodeForm>
        <div className="form-row">
          <div className="form-field-wrapper">
            <Flex gap={12} align="baseline">
              <FormLabel>Coupon Code</FormLabel>
              <input
                name="code"
                value={formData.code}
                onChange={onInputChange}
                placeholder="Enter Coupon Code"
              />
            </Flex>
          </div>
        </div>
        <div className="form-row">
          <div className="form-field-wrapper">
            <Flex gap={12} align="baseline">
              <FormLabel>Discount Type</FormLabel>
              <RadioWrapper>
                <Flex gap={24}>
                  <Flex align="center" gap={6}>
                    <input
                      id="discountTypeDollar"
                      type="radio"
                      name="discountType"
                      onChange={onInputChange}
                      value="dollar"
                      checked={formData.discountType === 'dollar'}
                    />
                    <label htmlFor="discountTypeDollar">Dollar</label>
                  </Flex>
                  <Flex align="center" gap={6}>
                    <input
                      id="discountTypePercentage"
                      type="radio"
                      name="discountType"
                      onChange={onInputChange}
                      value="percentage"
                      checked={formData.discountType === 'percentage'}
                    />
                    <label htmlFor="discountTypePercentage">Percentage</label>
                  </Flex>
                </Flex>
              </RadioWrapper>
            </Flex>
          </div>
        </div>
        {formData.discountType === 'dollar' && (
          <div className="form-row">
            <div className="form-field-wrapper">
              <Flex gap={12} align="baseline">
                <FormLabel>Discount Dollar($)</FormLabel>
                <input
                  type="number"
                  name="discountDollar"
                  value={formData.discountDollar}
                  onChange={onInputChange}
                  placeholder="Enter Discount Dollar"
                />
              </Flex>
            </div>
          </div>
        )}
        {formData.discountType === 'percentage' && (
          <div className="form-row">
            <div className="form-field-wrapper">
              <Flex gap={12} align="baseline">
                <FormLabel>Discount Percentage (%)</FormLabel>
                <input
                  type="number"
                  name="discountPercentage"
                  value={formData.discountPercentage}
                  onChange={onInputChange}
                  placeholder="Enter Discount Percentage"
                />
              </Flex>
            </div>
          </div>
        )}
        <div className="form-row">
          <div className="form-field-wrapper">
            <Flex gap={12} align="baseline">
              <FormLabel>Commission Type</FormLabel>
              <RadioWrapper>
                <Flex gap={24}>
                  <Flex align="center" gap={6}>
                    <input
                      id="commissionTypeDollar"
                      type="radio"
                      name="commissionType"
                      onChange={onInputChange}
                      value="dollar"
                      checked={formData.commissionType === 'dollar'}
                    />
                    <label htmlFor="commissionTypeDollar">Dollar</label>
                  </Flex>
                  <Flex align="center" gap={6}>
                    <input
                      id="commissionTypePercentage"
                      type="radio"
                      name="commissionType"
                      onChange={onInputChange}
                      value="percentage"
                      checked={formData.commissionType === 'percentage'}
                    />
                    <label htmlFor="commissionTypePercentage">Percentage</label>
                  </Flex>
                </Flex>
              </RadioWrapper>
            </Flex>
          </div>
        </div>
        {formData.commissionType === 'dollar' && (
          <div className="form-row">
            <div className="form-field-wrapper">
              <Flex gap={12} align="baseline">
                <FormLabel>Commission Dollar($)</FormLabel>
                <input
                  type="number"
                  name="commissionDollar"
                  value={formData.commissionDollar}
                  onChange={onInputChange}
                  placeholder="Enter Commission Dollar"
                />
              </Flex>
            </div>
          </div>
        )}
        {formData.commissionType === 'percentage' && (
          <div className="form-row">
            <div className="form-field-wrapper">
              <Flex gap={12} align="baseline">
                <FormLabel>Commission Percentage (%)</FormLabel>
                <input
                  type="number"
                  name="commissionPercentage"
                  value={formData.commissionPercentage}
                  onChange={onInputChange}
                  placeholder="Enter Commission Percentage"
                />
              </Flex>
            </div>
          </div>
        )}
        <div className="form-row">
          <div className="form-field-wrapper">
            <Flex gap={12} align="baseline">
              <FormLabel>Min Total</FormLabel>
              <input
                type="number"
                name="minTotal"
                value={formData.minTotal}
                onChange={onInputChange}
                placeholder="Enter Min Sum"
              />
            </Flex>
          </div>
        </div>
        <div className="form-row">
          <div className="form-field-wrapper">
            <Flex gap={12} align="baseline">
              <FormLabel>Meal ID</FormLabel>
              <input
                name="mealId"
                value={formData.mealId}
                onChange={onInputChange}
                placeholder="Enter Meal ID"
              />
            </Flex>
          </div>
        </div>
        <div className="form-row">
          <div className="form-field-wrapper">
            <Flex gap={12} align="baseline">
              <FormLabel>Expire Type</FormLabel>
              <RadioWrapper>
                <Flex gap={24}>
                  <Flex align="center" gap={6}>
                    <input
                      id="expireTypeNone"
                      type="radio"
                      name="expireType"
                      onChange={onInputChange}
                      value="none"
                      checked={formData.expireType === 'none'}
                    />
                    <label htmlFor="expireTypeNone">None</label>
                  </Flex>
                  <Flex align="center" gap={6}>
                    <input
                      id="expireTypeTime"
                      type="radio"
                      name="expireType"
                      onChange={onInputChange}
                      value="time"
                      checked={formData.expireType === 'time'}
                    />
                    <label htmlFor="expireTypeTime">Time</label>
                  </Flex>
                  <Flex align="center" gap={6}>
                    <input
                      id="expireTypeCount"
                      type="radio"
                      name="expireType"
                      onChange={onInputChange}
                      value="count"
                      checked={formData.expireType === 'count'}
                    />
                    <label htmlFor="expireTypeCount">Count</label>
                  </Flex>
                </Flex>
              </RadioWrapper>
            </Flex>
          </div>
        </div>
        {formData.expireType === 'time' && (
          <div className="form-row">
            <div className="form-field-wrapper">
              <Flex gap={12} align="baseline">
                <FormLabel>Expire Time</FormLabel>
                <DatePicker
                  selected={formData.expireTimeDate}
                  placeholderText="Enter Expire Time"
                  onChange={onExpireTimeChange}
                />
              </Flex>
            </div>
          </div>
        )}
        {formData.expireType === 'count' && (
          <div className="form-row">
            <div className="form-field-wrapper">
              <Flex gap={12} align="baseline">
                <FormLabel>Expire Count</FormLabel>
                <input
                  type="number"
                  name="expireCount"
                  value={formData.expireCount}
                  onChange={onInputChange}
                  placeholder="Enter Expire Count"
                />
              </Flex>
            </div>
          </div>
        )}
        <div className="form-row">
          <div className="form-field-wrapper">
            <Flex gap={12} align="baseline">
              <FormLabel>Only for new user</FormLabel>
              <RadioWrapper>
                <Flex gap={24}>
                  <Flex align="center" gap={6}>
                    <input
                      id="onlyForNewUserYes"
                      type="radio"
                      name="onlyForNewUser"
                      onChange={onInputChange}
                      value="1"
                      checked={formData.onlyForNewUser === '1'}
                    />
                    <label htmlFor="onlyForNewUserYes">Yes</label>
                  </Flex>
                  <Flex align="center" gap={6}>
                    <input
                      id="onlyForNewUserNo"
                      type="radio"
                      name="onlyForNewUser"
                      onChange={onInputChange}
                      value={0}
                      checked={formData.onlyForNewUser === '0'}
                    />
                    <label htmlFor="onlyForNewUserNo">No</label>
                  </Flex>
                </Flex>
              </RadioWrapper>
            </Flex>
          </div>
        </div>
        <div className="form-row">
          <div className="form-field-wrapper">
            <Flex gap={12} align="baseline">
              <FormLabel>Notes</FormLabel>
              <input
                name="notes"
                value={formData.notes}
                onChange={onInputChange}
                placeholder="Enter Notes"
              />
            </Flex>
          </div>
        </div>
        <Flex justify="center">
          <StyledButton onClick={saveCouponCode}>
            {couponCode ? 'Update Coupon Code' : 'Create Coupon Code'}
          </StyledButton>
        </Flex>
      </CouponCodeForm>
    </Modal>
  );
};

const CouponCodeForm = styled.div`
  font-size: 12px;
  width: 650px;
  margin-top: 24px;

  input {
    width: 400px;
  }

  input[type='radio'] {
    width: auto;
    margin: 0;
  }
`;

const StyledButton = styled(Button)`
  margin: 24px 0 0;
`;

const FormLabel = styled.label`
  font-size: 12px;
  width: 170px;
  text-align: right;
  padding-right: 6px;
  flex-shrink: 0;
`;

const RadioWrapper = styled.div`
  padding: 4px 0 18px;
`;
