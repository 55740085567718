import { SET_HIGHLIGHT, RESET_HIGHLIGHT } from "../actionTypes";

const DEFAULT_STATE = {};

const highlight = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_HIGHLIGHT:
      return { ...action.highlight };
    case RESET_HIGHLIGHT:
      return DEFAULT_STATE;
    default:
      return state;
  }
};

export default highlight;
