import { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LOGGING, Loading, UserListByGMV, Back } from '.';
import { readUsersByGMV } from '../../store/actions';

class PageTopUsers extends Component {
  constructor() {
    super();
    LOGGING && console.log('PageTopUsers constructor');
    this.state = {
      users: [],
      loading: true,
      rankBy: 'spend',
      period: '1m',
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleSelectPeriod = this.handleSelectPeriod.bind(this);
    this.handleSelectRankBy = this.handleSelectRankBy.bind(this);
  }

  handleSelectRankBy(rankBy, e) {
    e.preventDefault();
    LOGGING && console.log('handleSelectRankBy called with:', rankBy);
    const { users } = this.state;
    this.setState({ rankBy, users: users.sort((a, b) => (a[rankBy] - b[rankBy] > 0 ? -1 : 1)) });
  }

  handleSelectPeriod(period, e) {
    e.preventDefault();
    LOGGING && console.log('handleSelectPeriod called with:', period);
    this.setState({ loading: true }, () => {
      const { rankBy } = this.state;
      this.props.readUsersByGMV(period, rankBy).then((users) => {
        this.setState({ users, loading: false, period });
      });
    });
  }

  handleClose() {
    LOGGING && console.log('PageTopUsers handleClose');
    this.props.history.goBack();
  }

  componentDidMount() {
    LOGGING && console.log('PageTopUsers componentdidmount');
    this.setState({ loading: true }, () => {
      this.props.readUsersByGMV('1m', 'spend').then((users) => {
        this.setState({ users, loading: false });
      });
    });
  }

  render() {
    const { currentUser } = this.props;
    const { users, loading, period, rankBy } = this.state;
    LOGGING &&
      console.log('PageTopUsers rendering with', {
        props: this.props,
        state: this.state,
      });

    if (!currentUser.isAuthenticated || !currentUser.user.isAdmin) {
      return <Redirect to="/" />;
    }

    return (
      <div className="page">
        {/* <Navbar /> */}
        <Back onClick={this.handleClose} />
        {loading ? (
          <Loading />
        ) : (
          <UserListByGMV
            users={users}
            period={period}
            rankBy={rankBy}
            onSelectPeriod={this.handleSelectPeriod}
            onSelectRankBy={this.handleSelectRankBy}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log('PageTopUsers got redux state:', state);
  return {
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  readUsersByGMV,
})(PageTopUsers);
