import { useCallback } from "react";
import "./RestaurantDirectory.scss";
import { DishType } from "src/api/dish";
import styled from "styled-components";
import { LOGGING, Close } from ".";
import { DishTypeSelector } from "./dish/DishTypeSelector";
import { GroupDishEditForm } from "./dish/GroupDishEditForm";
import { NormalDishEditForm } from "./dish/NormalDishEditForm";
import { Centered, textTitle } from "./Shared";

const RestaurantName = styled(Centered)`
  ${textTitle}
`;

const DishTypeSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
`;

export const DishFormLabel = styled.label`
  font-size: 13px;
  font-weight: 500;
  margin: 8px 0;
`;

const DishDetails = ({
  restaurantId,
  dish,
  onCancel,
  onSave,
  onEdit,
  savingSelection,
  onAddSelectionItem,
  onEditSelectionItem,
  onSaveSelectionItem,
  onDeleteSelectionItem,
  onEditSelection,
  onSaveSelection,
  onDeleteSelection,
  selectedCategories,
  onSelectCategories,
  onSelectDishType,
  onSelectPreSelectedGoods,
  onToggleShowNewSelectionForm,
  onToggleShowExistingSelectionList,
  onEditSelections,
  isEdittingSelections,
  selectionsForRestaurant,
  onToggleUseSelection,
  onToggleEditSelection,
}) => {
  const selectDishType = useCallback(
    (nextDishType) => {
      onSelectDishType(nextDishType);
    },
    [onSelectDishType]
  );

  LOGGING &&
    console.log("DishDetails rendering for:", {
      dish,
    });

  const dishType = dish.dishType ?? DishType.NORMAL;

  const renderForm = useCallback(() => {
    switch (dishType) {
      case DishType.UNKNOWN:
      case DishType.NORMAL:
        return (
          <NormalDishEditForm
            dish={dish}
            onSave={onSave}
            onEdit={onEdit}
            selectedCategories={selectedCategories}
            onSelectCategories={onSelectCategories}
            onAddSelectionItem={onAddSelectionItem}
            onEditSelectionItem={onEditSelectionItem}
            onSaveSelectionItem={onSaveSelectionItem}
            onDeleteSelectionItem={onDeleteSelectionItem}
            onEditSelection={onEditSelection}
            onSaveSelection={onSaveSelection}
            onDeleteSelection={onDeleteSelection}
            onToggleShowNewSelectionForm={onToggleShowNewSelectionForm}
            onToggleShowExistingSelectionList={
              onToggleShowExistingSelectionList
            }
            isEdittingSelections={isEdittingSelections}
            onEditSelections={onEditSelections}
            selectionsForRestaurant={selectionsForRestaurant}
            onToggleUseSelection={onToggleUseSelection}
            onToggleEditSelection={onToggleEditSelection}
            savingSelection={savingSelection}
          />
        );
      case DishType.GROUP:
        return (
          <GroupDishEditForm
            restaurantId={restaurantId}
            dish={dish}
            onSave={onSave}
            onEdit={onEdit}
            selectedCategories={selectedCategories}
            onSelectCategories={onSelectCategories}
            onSelectPreSelectedGoods={onSelectPreSelectedGoods}
            onEditSelectionItem={onEditSelectionItem}
          />
        );
      default:
        throw new Error(`Unexpected dishType: ${dishType}`);
    }
  }, [
    dish,
    dishType,
    onEdit,
    onSave,
    onSelectCategories,
    onSelectPreSelectedGoods,
    restaurantId,
    selectedCategories,
    onToggleShowExistingSelectionList,
    onToggleShowNewSelectionForm,
  ]);

  return (
    <div className="list dish-details">
      <Close onClick={onCancel} />
      {/* <RestaurantName>
        <span>{dish.restaurant.name}</span>
        <Close onClick={onCancel} />
      </RestaurantName> */}
      {/* {dish?._id == null ? (
        <DishTypeSelectorContainer>
          <DishFormLabel>Dish Type</DishFormLabel>
          <DishTypeSelector selectedDishType={dishType} onSelectDishType={selectDishType} />
        </DishTypeSelectorContainer>
      ) : null} */}

      {renderForm(dishType)}
    </div>
  );
};

export default DishDetails;
