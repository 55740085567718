import { LOAD_TOP_BLOCKS, RESET_TOP_BLOCKS } from "../actionTypes";

const topBlocks = (state = [], action) => {
  switch (action.type) {
    case LOAD_TOP_BLOCKS:
      return [...action.topBlocks];
    case RESET_TOP_BLOCKS:
      return null;
    default:
      return state;
  }
};

export default topBlocks;
