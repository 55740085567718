import { ReactNode } from 'react';
import { BREAK_POINTS } from 'src/constants';
import useWindowDimensions from 'src/hooks/useWindowDimensions';

type ShownProps = {
  from?: keyof typeof BREAK_POINTS;
  to?: keyof typeof BREAK_POINTS;
  children?: ReactNode;
};

const MAX_WIDTH = Number.MAX_SAFE_INTEGER;

export const Shown = (props: ShownProps) => {
  const { from, to, children } = props;

  const fromWidth = from ? BREAK_POINTS[from] : 0;
  const toWidth = to ? BREAK_POINTS[to] : MAX_WIDTH;

  const { width } = useWindowDimensions();

  if (width < fromWidth || width > toWidth) {
    return null;
  }

  return children;
};
