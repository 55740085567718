import { useCallback } from "react";
import {
  faHouse,
  faBuilding,
  faCoffee,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DriverStopSelectorListContainer } from "./DriverStopSelectorListContainer";

export const StopMarker = ({
  stopId,
  isTransit,
  color,
  isNewUser,
  marker,
  stopNum,
  stopType,
  eta,
  onClick,
  showInBackground,
  label,
  eligibleDrivers,
  showDriverSelector,
  isApartment,
  hasDrink,
  stopTotal,
}) => {
  const toggleShowDriverListForStopUsingId = useCallback(() => {
    onClick(stopId);
  }, [stopId, onClick]);
  // console.log(`StopMarker ${label} ${isApartment ? 'apartment' : 'house'}`);
  return (
    <div className="stop-on-map">
      <div
        className={`address-marker home-marker ${
          showInBackground ? "show-in-background" : ""
        }`}
      >
        <button
          onClick={toggleShowDriverListForStopUsingId}
          className="toggle-details"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {stopType === "meal" ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                position: "absolute",
                alignItems: "center",
                justifyContent: "center",
                zIndex: -1,
              }}
            >
              <FontAwesomeIcon
                icon={faHouse}
                style={{ color: "red", fontSize: "32px" }}
              />
            </div>
          ) : null}
          <FontAwesomeIcon
            icon={marker}
            style={{ color, fontSize: `${isTransit ? "26px" : "20px"}` }}
          />
          {stopNum != null ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                position: "absolute",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1,
                color: "white",
                fontSize: "12px",
              }}
            >
              {String(stopNum + 1)}
            </div>
          ) : null}
        </button>
        <div className="order-name">
          <div
            className="order-firstName"
            style={{
              color,
              border: `1px solid ${isNewUser ? color : "transparent"}`,
              background: isNewUser ? "white" : "transparent",
            }}
          >
            {label}
            {isApartment ? <FontAwesomeIcon icon={faBuilding} /> : null}
            {hasDrink ? <FontAwesomeIcon icon={faCoffee} /> : null}
          </div>
          {/* {isApartment ? <div className="apartment">apartment</div> : null} */}
          {eta ? (
            <span
              className="order-eta"
              style={{ color }}
            >{`${eta}m $${stopTotal?.toFixed(0)}`}</span>
          ) : null}
        </div>
      </div>
      {showDriverSelector ? (
        <DriverStopSelectorListContainer
          stopId={stopId}
          isTransit={isTransit}
          eligibleDrivers={eligibleDrivers}
        />
      ) : null}
    </div>
  );
};
