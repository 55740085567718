import React, { ReactElement, useCallback, useState } from "react";
import { SerializedCategory } from "src/api/categories";
import {
  SerializedDish,
  SerializedGood,
  SerializedGoodSelectedItem,
  SerializedGoodSelection,
} from "src/api/dish";
import { ObjectId } from "src/constants/types";
import { arrayToDict, findAndReplaceArr } from "src/util/array";
import { getDishKey } from "src/util/order";
import styled from "styled-components";
import { CategorySelector } from "../admin/CategorySelector";
import { DishFormLabel } from "../DishDetails";
import { DishListChooserContainer } from "./DishListChooserContainer";
import { DishSelector } from "./DishSelector";
import { GoodsContainer } from "./GoodsContainer";
import { Good } from "./PureDishChooser";

type GroupDishEditFormProps = {
  restaurantId?: ObjectId;
  dish: SerializedDish;
  onSave: () => void;
  onEdit: () => void;
  selectedCategories: SerializedCategory[];
  onSelectCategories: (categories: SerializedCategory[]) => void;
  preSelectedGoods: SerializedGood[];
  onSelectPreSelectedGoods: (goods: SerializedGood[]) => void;
  isMember?: boolean;
};

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
`;

const Image = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 12px 0;
`;

const Input = styled.input`
  width: 100%;
  margin: 0;
  padding: 4px;
`;

const Button = styled.button`
  color: white;
  background-color: black;
  margin-top: 80px;
`;

export const GroupDishEditForm: React.FC<GroupDishEditFormProps> = ({
  restaurantId,
  dish,
  onSave,
  onEdit,
  selectedCategories,
  onSelectCategories,
  onSelectPreSelectedGoods,
}): ReactElement => {
  const [selectedDishIds, setSelectedDishIds] = useState([]);

  const selectDishIds = useCallback((selectedDishIds) => {
    setSelectedDishIds(selectedDishIds);
  }, []);

  const getNewPreSelectedGoods = useCallback(
    (goodsToChange: SerializedGood[]) => {
      const selectedGoods = dish.preSelectedGoods ?? [];

      return goodsToChange.reduce((currGoods, goodToChange) => {
        const dishKey = getDishKey(
          { _id: goodToChange.dish },
          goodToChange.selections
        );
        const existingGoods = arrayToDict(
          currGoods,
          (good) => getDishKey({ _id: good.dish }, good.selections),
          (good) => good
        );

        if (existingGoods[dishKey] == null) {
          return [...currGoods, goodToChange];
        } else {
          const existing = existingGoods[dishKey];
          existing.quantity += goodToChange.quantity;

          return findAndReplaceArr(currGoods, { ...existing }, (el) =>
            getDishKey({ _id: el.dish }, el.selections)
          );
        }
      }, selectedGoods);
    },
    [dish.preSelectedGoods]
  );

  const addGoodsToCart = useCallback(
    (goods: Good[]) => {
      const serializedGoods: SerializedGood[] = goods.map((good) => {
        return {
          _id: null,
          price: good.price,
          addedBy: good.addedBy,
          priceNonMember: good.priceNonMember,
          dish: good.dish._id,
          quantity: good.quantity,
          cancelQuantity: 0,
          comment: good.comment,
          selections: good.selections.map(
            (goodSelection): SerializedGoodSelection => {
              return {
                _id: null,
                dishSelection: goodSelection.dishSelection,
                selectedItems: goodSelection.selectedItems.map(
                  (goodSI): SerializedGoodSelectedItem => {
                    return {
                      _id: null,
                      item: goodSI.item,
                      count: goodSI.count,
                      cancelCount: 0,
                    };
                  }
                ),
              };
            }
          ),
        };
      });

      onSelectPreSelectedGoods(getNewPreSelectedGoods(serializedGoods));
    },
    [getNewPreSelectedGoods, onSelectPreSelectedGoods]
  );

  return (
    <Form>
      <Image src={dish.imageURL} alt="Dish item"></Image>
      <Row>
        <DishFormLabel>imageURL</DishFormLabel>
        <Input defaultValue={dish.imageURL} onChange={onEdit} name="imageURL" />
      </Row>
      <Row>
        <DishFormLabel>Name</DishFormLabel>
        <Input defaultValue={dish.name} onChange={onEdit} name="name" />
      </Row>
      <Row>
        <DishFormLabel>Description</DishFormLabel>
        <Input
          defaultValue={dish.description}
          onChange={onEdit}
          name="description"
        />
      </Row>
      <Row>
        <DishFormLabel>Categories</DishFormLabel>
        <CategorySelector
          restaurantId={dish.restaurant._id}
          selectedCategories={selectedCategories}
          onSelectCategories={onSelectCategories}
        />
      </Row>
      <Row>
        <DishFormLabel>Show Dish Chooser</DishFormLabel>
        <DishSelector
          restaurantId={restaurantId}
          selectedDishIds={selectedDishIds}
          onSelectDishIds={selectDishIds}
        />
      </Row>
      <DishListChooserContainer
        dishIds={selectedDishIds}
        onAddGoodsToCart={addGoodsToCart}
      />
      <GoodsContainer
        goods={dish.preSelectedGoods ?? []}
        onSelectPreSelectedGoods={onSelectPreSelectedGoods}
      />
      <Row>
        <DishFormLabel>Price</DishFormLabel>
        <span>${(dish.priceFoodieListed ?? 0).toFixed(2)}</span>
      </Row>
      <Button type="submit" onClick={onSave}>
        save
      </Button>
    </Form>
  );
};
