import {
  LOAD_ORDERS_HISTORY_BY_RESTAURANT,
  RESET_ORDERS_HISTORY_BY_RESTAURANT,
} from "../actionTypes";

const orderHistoryByRestaurant = (state = {}, action) => {
  switch (action.type) {
    case LOAD_ORDERS_HISTORY_BY_RESTAURANT:
      // console.log(
      //   "orderHistoryByRestaurant got:",
      //   action.orderHistoryByRestaurant
      // );
      return { ...action.orderHistoryByRestaurant };
    case RESET_ORDERS_HISTORY_BY_RESTAURANT:
      return {};
    default:
      return state;
  }
};

export default orderHistoryByRestaurant;
