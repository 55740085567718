import React from "react";
import "./DriverTasks.scss";
import "./DeliveryWindow.scss";
import { faPaypal } from "@fortawesome/free-brands-svg-icons";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faMoneyCheck } from "@fortawesome/pro-duotone-svg-icons";
import { faMoneyCheckAlt } from "@fortawesome/pro-light-svg-icons";
import {
  faArrowToTop,
  faCopy,
  faUser,
  // faUtensils,
  faHouse,
  faPhone,
  faCheck,
  faWrench,
  faPizzaSlice,
  faDoorClosed,
  faVolume,
  faVolumeSlash,
  faGlass,
  faTimesCircle,
  faClock,
  faCar,
  faMoneyBill,
  faDollarSign,
  faMoneyBillWave,
} from "@fortawesome/pro-regular-svg-icons";
import { faSackDollar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { last } from "lodash";
import moment from "moment-timezone";
import ImageUploader from "react-images-upload";
// import { getNameFromOrder } from 'src/constants';
import orders from "src/store/reducers/orders";
import { LOGGING, PhoneFormat } from ".";

const OrderDriverView = ({
  order,
  onDeliver,
  processingStop,
  onCopied,
  onUploadDeliveryImage,
}) => {
  const { user, goods } = order;
  LOGGING &&
    console.log("OrderDriverView rendering with: ", {
      order,
      goods,
    });

  let { phone, nameList, route } = order;
  let phoneList = phone ? [phone] : [user?.deliveryInfo?.phone];
  var doorbell_instructions = "";
  var doorbell_icon;
  LOGGING &&
    console.log("OrderDriverView phoneList: ", {
      phoneList,
    });

  if (nameList.length > 1) {
    if (nameList.every((o) => o.user?.userGroup)) {
      // If all users in a stop have a userGroup, display the group's admin number.
      phoneList = [phone];
    } else {
      // For cases where not all people belong to a userGroup
      if (nameList.every((o) => o.phone === nameList[0].phone)) {
        // If the phone number is the same for each order at a stop, display that one number in an array.
        phoneList = [nameList[0].phone];
      } else {
        // If the phone numbers are different, then turn phone into an array with phone numbers.
        phoneList = nameList.map((o) => o.phone);
      }
    }
  }
  if (user?.deliveryInfo?.ring_doorbell) {
    doorbell_instructions = "Knock the door/ring the doorbell.";
    doorbell_icon = faVolume;
  } else {
    doorbell_instructions = "Do NOT knock the door/ring the doorbell.";
    doorbell_icon = faVolumeSlash;
  }
  LOGGING &&
    console.log("OrderDriverView iterating phoneList: ", {
      phoneList,
    });
  phoneList
    ?.filter((p) => p)
    .map((phoneNum) =>
      phoneNum.includes("+1") ? phoneNum.slice(2) : phoneNum
    );

  return (
    <div className="receipt">
      <div className="driver-action order-name">
        {/* <div className="driver-label">
          <FontAwesomeIcon icon={faUser} />
        </div> */}
        <div className="order-status">
          <span>{`Stop #${order.stop + 1}`}</span>
        </div>
      </div>
      <div className="driver-action target-time">
        <div className="driver-label">
          <FontAwesomeIcon icon={faClock} />
        </div>
        {route?.startTime ? (
          <div className="order-status">
            <span>
              Expected by{" "}
              {(route?.startTime
                ? moment(route?.startTime + 60000 * order?.etaAtRestaurant)
                : "TBD"
              ).format("hh:mm A")}
              <br />
              {`(${order?.distanceAtDispatch?.toFixed(2)} mi driving)`}
            </span>
          </div>
        ) : (
          <div className="order-status">
            <span>
              Target Time: TBD
              <br />
              {`(${order?.distanceAtDispatch?.toFixed(2)} mi driving)`}
            </span>
          </div>
        )}
      </div>
      <div className="driver-action order-address">
        <div className="driver-label">
          <FontAwesomeIcon icon={faHouse} />
        </div>
        <button
          className="order-status"
          onClick={() => {
            navigator.clipboard.writeText(order.address);
            onCopied.call(this, "address");
          }}
        >
          <div>{order.address}</div>
        </button>
      </div>
      <div className="driver-action doorbell-instructions">
        <div className="driver-label">
          <FontAwesomeIcon icon={doorbell_icon} />
        </div>
        <div className="order-status">
          <span>{doorbell_instructions}</span>
        </div>
      </div>
      {order.note && (
        <div className="driver-action order-note">
          <div className="driver-label">
            <FontAwesomeIcon icon={faWrench} />
          </div>
          <div className="order-status">
            <span>{order.note}</span>
          </div>
        </div>
      )}
      {order.user && order.user.addressMap ? (
        <div className="driver-action order-address-map">
          <div className="map-container">
            <img src={order.user.addressMap} alt={order.address}></img>
          </div>
        </div>
      ) : null}

      {phoneList.length === 1 ? (
        // If phoneList only has one value, display it
        <div className="driver-action order-phone">
          <div className="driver-label">
            <FontAwesomeIcon icon={faPhone} />
          </div>
          <a className="order-status" href={`tel:+1${phoneList[0]}`}>
            {PhoneFormat(phoneList[0])}
          </a>
        </div>
      ) : null}
      {order.nameList.length > 1 ? (
        <div className="order-number-notice">
          There are<b>{` ${order.nameList.length} `}</b>orders at this stop!
        </div>
      ) : null}
      {order.nameList.map((o, i) => (
        <div className="driver-action-container" key={o._id}>
          <div className="driver-action order-order">
            <div className="driver-label">
              <FontAwesomeIcon icon={faUser} />
            </div>
            <div className="order-status">
              <span>
                {order?.nameList[i]?.name?.trim().length > 0
                  ? order.nameList[i].name
                  : `${order.nameList[i].user.firstName} ${order.nameList[i].user.lastName}`}
              </span>
            </div>
          </div>
          {phoneList.length > 1 ? (
            // If phoneList with multiple values, display each phone number (based on index of nameList)
            <div
              className={`driver-action order-phone ${
                i === phoneList.length - 1 ? "" : "divided"
              }`}
            >
              <div className="driver-label">
                <FontAwesomeIcon icon={faPhone} />
              </div>
              <a className="order-status" href={`tel:+1${phoneList[i]}`}>
                {PhoneFormat(phoneList[i])}
              </a>
            </div>
          ) : null}
        </div>
      ))}

      {/* {!order.dispatchTime && (
        <button className="dispatch-button" onClick={onDispatch}>
          {`${processingOrderId === order._id ? 'processing...' : 'Dispatch'}`}
        </button>
      )} */}
      {/*<div className="deliver-action-wrapper">*/}
      {/*order.dispatchTime && !order.hasDeliveryImage ? (
          <ImageUploader
            withIcon={false}
            buttonText="Upload delivery image..."
            onChange={onUploadDeliveryImage}
            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
            maxFileSize={5242880}
          />
        ) : order.deliveryImage ? (
          <div className="delivery-image">
            <img src={order.deliveryImage[0]} alt="" />
          </div>
        ) : null}*/}
      {!order.deliveryTime && order.dispatchTime && (
        <button
          className="deliver-button"
          onClick={onDeliver}
          disabled={processingStop === order.stop}
        >
          {`${
            processingStop === order.stop
              ? "Processing, do not click."
              : "Deliver"
          }`}
        </button>
      )}
      {/* </div> */}
      {order.deliveryTime && (
        <div className="driver-action order-delivery-status">
          <div className="driver-label">
            <FontAwesomeIcon icon={faCheck} />
          </div>
          <span className="order-status">
            <div>{moment(order.deliveryTime).format("LLLL")}</div>
          </span>
        </div>
      )}
    </div>
  );
};

const DriverTasks = ({
  orders,
  onDeliver,
  onDispatch,
  onUploadDeliveryImage,
  processingStop,
  onBackToTop,
  onCopied,
  message,
  isDelivered,
}) => {
  LOGGING && console.log(`DriverTasks rendering with orders:`, orders);

  return (
    <div className="list driver-tasks">
      {message ? (
        <div className="transient-message">
          <div>
            <FontAwesomeIcon icon={faCopy} />
            <span className="copied-content">{`${message} `} </span>
            {` copied`}
          </div>
        </div>
      ) : null}
      {(isDelivered
        ? orders.sort((a, b) => (a.deliveryTime > b.deliveryTime ? -1 : 1))
        : orders
            .sort((a, b) => (a.stop !== undefined ? -1 : 1))
            .sort((a, b) =>
              a.stop !== undefined && b.stop !== undefined && a.stop < b.stop
                ? -1
                : 1
            )
      ).map((order, i) => (
        <OrderDriverView
          key={i}
          index={i}
          totalNumber={orders.length}
          order={order}
          onDeliver={onDeliver.bind(this, order.stop, order.nameList)}
          onUploadDeliveryImage={onUploadDeliveryImage.bind(
            this,
            order.stop,
            order.nameList
          )}
          processingStop={processingStop}
          driverTotalStops={orders.length}
          onCopied={onCopied}
        />
      ))}
      <button onClick={onBackToTop} className="back-to-top">
        <FontAwesomeIcon icon={faArrowToTop} />
      </button>
    </div>
  );
};

const DriverSummary = ({ orders }) => {
  const days = [];
  const now = moment();
  for (
    let day = moment().startOf("week");
    day.isBefore(now);
    day.add(1, "day")
  ) {
    days.push(day.format("MM/DD"));
  }
  const routes = {};
  const validOrders = orders.filter((order) => Boolean(order.route));
  validOrders.forEach((order) => {
    if (!(order.route._id in routes)) {
      routes[order.route._id] =
        (order.route.endTime - order.route.startTime) / 1000 / 60;
    }
  });
  const avgDeliveryTime =
    Object.values(routes).length > 0
      ? Math.round(
          Object.values(routes).reduce((a, b) => a + b) /
            Object.values(routes).length
        )
      : null;
  return (
    <div className="driver-summary">
      <div className="driver-summary-date">
        {moment().startOf("week").format("MM/DD")} ~{" "}
        {moment().endOf("week").format("MM/DD")}
      </div>
      {avgDeliveryTime && (
        <div className="driver-summary-average">
          (Average delivery time {avgDeliveryTime} mins)
        </div>
      )}
      <div className="driver-summary-details">
        {days.map((day) => {
          const lunchOrders = validOrders.filter(
            (order) =>
              moment(order.window.start).format("MM/DD") === day &&
              moment(order.window.start).hours() <= 12
          );
          const dinnerOrders = validOrders.filter(
            (order) =>
              moment(order.window.start).format("MM/DD") === day &&
              moment(order.window.start).hours() > 12
          );
          return (
            <div className="driver-summary-row">
              <div class="driver-summary-day">{day}</div>
              {lunchOrders.length === 0 && dinnerOrders.length === 0 && (
                <div class="driver-no-route">No route</div>
              )}
              {lunchOrders.length > 0 && (
                <div>
                  Lunch - order count: {lunchOrders.length}, delivery time:{" "}
                  {Math.round(
                    (lunchOrders[0].route.endTime -
                      lunchOrders[0].route.startTime) /
                      1000 /
                      60
                  )}{" "}
                  mins
                </div>
              )}
              {dinnerOrders.length > 0 && (
                <div>
                  Dinner - order count: {dinnerOrders.length}, delivery time:{" "}
                  {Math.round(
                    (dinnerOrders[0].route.endTime -
                      dinnerOrders[0].route.startTime) /
                      1000 /
                      60
                  )}{" "}
                  mins
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const DriverStartingRestaurant = ({
  onStartFromRestaurant,
  orders,
  showOrderDetails,
  onToggleShowOrderDetails,
  processingStop,
}) => {
  const isOrderHaveDrink = (order) => {
    return order.goods.some((goods) => goods.dish.isDrink);
  };
  LOGGING &&
    console.log("DriverStartingRestaurant rendering with orders:", orders);
  return (
    <div className="list driver-tasks start-restaurant">
      <div className="receipt">
        {/* <div className="driver-action restaurant-name">
          <div className="driver-label">
            <FontAwesomeIcon icon={faUtensils} />
          </div>
          <div className="order-status">
            <span>{restaurant.name}</span>
          </div>
        </div>
        <div className="driver-action restaurant-address">
          <div className="driver-label">
            <FontAwesomeIcon icon={faHouse} />
          </div>
          <div className="order-status">
            <span>{restaurant.address}</span>
          </div>
        </div> */}
        <React.Fragment>
          {orders.map((order, i) => {
            let itemCount = 0;
            const itemTotal = order.goods.reduce(
              (a, b) => b.quantity - (b.cancelQuantity || 0) + a,
              0
            );

            return (
              <>
                <div className="driver-action order-name">
                  <div className="driver-label">
                    <FontAwesomeIcon icon={faUser} />
                  </div>
                  <button
                    className="show-orders"
                    onClick={onToggleShowOrderDetails.bind(this, order._id)}
                  >
                    <FontAwesomeIcon
                      icon={
                        showOrderDetails[order._id]
                          ? faChevronUp
                          : faChevronDown
                      }
                    />
                  </button>
                  <div className="order-status">
                    <span>
                      {order?.name?.trim().length > 0
                        ? order.name
                        : `${order.user.firstName} ${order.user.lastName}`}{" "}
                      {isOrderHaveDrink(order) && (
                        <FontAwesomeIcon color="blue" icon={faGlass} />
                      )}
                    </span>

                    <input type="checkbox" />
                  </div>
                </div>

                {showOrderDetails[order._id] ? (
                  <div className="driver-action order-order">
                    <div className="driver-label">
                      <FontAwesomeIcon icon={faPizzaSlice} />
                    </div>

                    <div className="order-status item-details">
                      <span className="item-total">{`${itemTotal} items`}</span>
                      <ul className="item-list">
                        {order.goods.map((e) =>
                          [...Array(e.quantity - (e.cancelQuantity || 0))].map(
                            (_, i) => (
                              <li key={i} className="item">
                                <span>{e.dish.name}</span>
                                <span className="item-count">
                                  {++itemCount}
                                </span>
                              </li>
                            )
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                ) : null}
              </>
            );
          })}
        </React.Fragment>
        <button
          className="deliver-button"
          disabled={!onStartFromRestaurant || processingStop === -1}
          onClick={onStartFromRestaurant}
        >
          <span>
            {processingStop === -1
              ? "Processing, do not click."
              : "I've picked up everyone's food!"}
          </span>
        </button>
      </div>
    </div>
  );
};
const DriverDone = ({ onDone }) => (
  <div className="list driver-tasks start-restaurant">
    <div className="receipt">
      <button className="deliver-button" onClick={onDone}>
        <span>I'm done!</span>
      </button>
    </div>
  </div>
);

const DriverNotification = ({
  needConfirmAvailability,
  availabilityResponse,
  onDisconfirmAvailability,
  onConfirmAvailability,
  driverInfo,
  driverHourlyWage,
  minRouteTime,
  maxRouteTime,
  route,
}) => {
  return (
    <div className="list driver-tasks availability">
      <div className="receipt">
        {driverInfo?.isDelivering ? (
          <div className="delivery-info">
            <div className="field pickup-time">
              <label>Instructions</label>
              <span>
                All details are subject to change until the route is finalized
                around{" "}
                <b>
                  {moment(driverInfo.pickUpTime)
                    .add(-1, "hour")
                    .format("h:mm A, ddd, MMM Do, YYYY")}
                </b>
              </span>
            </div>
            <div className="field restaurant-name">
              <label>Restaurant (May Change)</label>
              <span>{driverInfo.restaurantName.split(",")[0]}</span>
            </div>
            <div className="field pickup-time">
              <label>Address (May Change)</label>
              <span>{driverInfo.restaurantAddress}</span>
            </div>
            {route?.windowStart > moment().startOf("d") &&
            route?.estimatedRouteTime ? null : (
              <div className="field pickup-time">
                <label>Pick-Up (May Change)</label>
                <span>
                  <b>
                    {moment(driverInfo.pickUpTime)
                      .add(-15, "minutes")
                      .format("h:mm A")}{" "}
                    -{" "}
                    {moment(driverInfo.pickUpTime)
                      .add(15, "minutes")
                      .format("h:mm A, ddd, MMM Do, YYYY")}
                    .
                  </b>
                </span>
              </div>
            )}
            {!route.restaurant ? (
              <>
                <div className="field pickup-time">
                  <label>Route Information</label>
                  <span>
                    All routes will take between{" "}
                    <b>
                      {minRouteTime} - {maxRouteTime} minutes
                    </b>{" "}
                    to complete. Your exact route will be released later.
                  </span>
                </div>
                <div className="field pickup-time">
                  <label>Payment Information</label>
                  <span>
                    {/* You will be paid roughly <b>${driverHourlyWage}.00</b> a route depending on the
                    estimated driving time. All routes will pay between{' '} */}
                    You will be paid based on the driving time. All routes will
                    pay between{" "}
                    <b>
                      ${((minRouteTime * driverHourlyWage) / 60).toFixed(2)} - $
                      {((maxRouteTime * driverHourlyWage) / 60).toFixed(2)}.
                    </b>
                  </span>
                </div>
              </>
            ) : null}
          </div>
        ) : null}
        {needConfirmAvailability ? (
          <div className="message-to-driver">
            <span className="message">Would you like to deliver?</span>
            <div className="action-wrapper">
              <button className="reject" onClick={onDisconfirmAvailability}>
                No
              </button>
              <button className="accept" onClick={onConfirmAvailability}>
                Yes
              </button>
            </div>
          </div>
        ) : availabilityResponse === true ? (
          route && route.driverPaymentInfo && !route.endTime ? (
            <>
              <div className="route-summary title">
                <label>route summary</label>
              </div>
              <div className="route-summary">
                <div>
                  <FontAwesomeIcon size="lg" icon={faClock} fixedWidth />
                  <span className="category"> Time* </span>
                </div>
                <span className="value">{route?.estimatedRouteTime} mins</span>
              </div>
              <div className="route-summary">
                <div>
                  <FontAwesomeIcon size="lg" icon={faCar} fixedWidth />
                  <span className="category"> Mileage* </span>
                </div>
                <span className="value">
                  {route?.orders
                    ?.find(
                      (order) =>
                        order.stop ===
                        Math.max(...route?.orders.map((order) => order.stop))
                    )
                    ?.distanceAtRestaurant?.toFixed(2)}{" "}
                  mi
                </span>
              </div>
              <div className="route-summary">
                <div>
                  <FontAwesomeIcon size="lg" icon={faDollarSign} fixedWidth />
                  <span className="category"> Hourly Rate </span>
                </div>
                <span className="value">${driverHourlyWage?.toFixed(2)}</span>
              </div>
              <div className="route-summary">
                <div>
                  <FontAwesomeIcon size="lg" icon={faSackDollar} fixedWidth />
                  <span className="category"> Payment Amount </span>
                </div>
                <span className="value">
                  ${route?.driverPaymentInfo?.amount?.toFixed(2)}
                </span>
              </div>
              <div className="note">
                <span>*calculated using Google Maps</span>
              </div>
            </>
          ) : (
            <div className="message-to-driver">
              <span>
                Thank you for accepting the job! Your route will be available
                before the pick-up time.
              </span>
            </div>
          )
        ) : availabilityResponse === false ? (
          <div className="message-to-driver">
            <span className="message">
              Thank you for your response, see you next time!
            </span>
            {/* <div className="action-wrapper">
                <button className="accept" onClick={onDisconfirmAvailability}>
                  ok
                </button>
              </div> */}
          </div>
        ) : null}
      </div>
    </div>
  );
};
export {
  DriverTasks,
  DriverSummary,
  DriverStartingRestaurant,
  DriverDone,
  DriverNotification,
};
