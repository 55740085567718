import React from 'react';
import './ConfirmAlert.scss';

const ConfirmAlert = ({ onConfirm, onClose, actionName, message, yesWord, noWord, title }) => (
  <div className="confirm-window">
    <div className="confirm-alert">
      {title ? <h4>{title}</h4> : null}
      <p>{message || `Are you sure you want to ${actionName} this?`}</p>
      <div className="confirm-buttons">
        <button className="no-button" onClick={onClose}>
          <span>{noWord || 'No'}</span>
        </button>
        <button
          className="yes-button"
          onClick={() => {
            onConfirm && onConfirm();
            onClose && onClose();
          }}
        >
          <span>{yesWord || 'Yes'}</span>
        </button>
      </div>
    </div>
  </div>
);

export default ConfirmAlert;
