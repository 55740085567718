import React, { ReactElement, useCallback } from 'react';
import { useAsync } from 'react-async';
import Select from 'react-dropdown-select';
import { GetDishesByRestaurantId } from 'src/api/dish';
import { ObjectId } from 'src/constants/types';
import { arrayToDict } from 'src/util/array';
import { Loading } from '..';

type DishSelectorProps = {
  restaurantId?: ObjectId;
  selectedDishIds: ObjectId[];
  onSelectDishIds: (dishIds: ObjectId[]) => void;
};

export const DishSelector: React.FC<DishSelectorProps> = ({
  restaurantId,
  selectedDishIds,
  onSelectDishIds,
}): ReactElement => {
  const changeDishIds = useCallback(
    (selectedDishOptions) => {
      onSelectDishIds(selectedDishOptions.map((option) => option._id));
    },
    [onSelectDishIds]
  );

  const fetch = useCallback(async () => {
    if (restaurantId == null) return null;

    return await GetDishesByRestaurantId(restaurantId, true);
  }, [restaurantId]);
  const { data } = useAsync({ promiseFn: fetch, watch: restaurantId });

  if (data == null) return <Loading />;

  const options = data.dishes.map((d) => ({ _id: d._id, name: d.name }));
  const dishOptionByDishId = arrayToDict(
    data.dishes,
    (d) => d._id,
    (d) => ({ _id: d._id, name: d.name })
  );

  const values = selectedDishIds.map((dishId) => dishOptionByDishId[dishId]);

  return (
    <Select
      options={options}
      onChange={changeDishIds}
      labelField="name"
      valueField="_id"
      searchBy="name"
      values={values}
      multi={true}
    />
  );
};
