import React, { useCallback, useEffect, useState } from "react";
import "./Checkout.scss";
import { useAsync } from "react-async";
import { GetMealById, GetMealByIdResponse } from "src/api/meals";
import styled from "styled-components";
import { Loading } from ".";
import {
  DeliveryInfoCompany,
  DeliveryInfoForm,
  DeliveryInfoFormPaypal,
  PaypalButton,
  LOGGING,
} from "./";
import { ActionBlack } from "./Shared";
const PlaceOrderButton = styled(ActionBlack)``;

type CheckoutWithPaypalProps = CheckoutWithPaypalContainerProps & {
  orderCutoffTime: number;
};

const checkIsPastOrderCutoffTime = (orderCutoffTime: number): boolean => {
  return new Date().valueOf() > orderCutoffTime;
};

export const CheckoutWithPaypal: React.FC<CheckoutWithPaypalProps> = ({
  paypalInfo,
  deliveryInfo,
  onPaypalSuccess,
  onPaypalAuthorize,
  onPaypalCapturing,
  order,
  onChange,
  onChangeNumber,
  error,
  payPalOrderAddressConfirmed,
  onConfirmPaypalAddress,
  onEditPaypalAddress,
  onEditAddress,
  onToggleRingBell,
  onSelectPaypalAddress,
  isCompanyOrder,
  orderCutoffTime,
}) => {
  const [isPastOrderCutoffTime, setIsPastOrderCutoffTime] = useState(
    checkIsPastOrderCutoffTime(orderCutoffTime)
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsPastOrderCutoffTime(checkIsPastOrderCutoffTime(orderCutoffTime));
    }, 5000);

    return () => clearInterval(intervalId);
  }, [orderCutoffTime]);

  if (isPastOrderCutoffTime) {
    return (
      <span>
        Order is after cutoff. Please contact us at 404-520-0627 or 650-695-6180
        for assistance.
      </span>
    );
  }
  LOGGING &&
    console.log("CheckoutWithPaypal rendering with: ", {
      payPalOrderAddressConfirmed,
    });
  return (
    <div className="checkout">
      {isCompanyOrder ? (
        <DeliveryInfoCompany deliveryInfo={deliveryInfo} />
      ) : payPalOrderAddressConfirmed ? (
        <DeliveryInfoFormPaypal
          deliveryInfo={{ ...deliveryInfo }}
          onEdit={onEditPaypalAddress}
          onToggleRingBell={onToggleRingBell}
        />
      ) : (
        <DeliveryInfoForm
          deliveryInfo={{ ...deliveryInfo }}
          onChange={onChange}
          onChangeNumber={onChangeNumber}
          error={error}
          onEditAddress={onEditAddress}
          onSelectAddress={onSelectPaypalAddress}
          onToggleRingBell={onToggleRingBell}
          isCorporate={null} // Added to appease typescript
          user={null} // Added to appease typescript
        />
      )}
      {/* <div className="powered-by-paypal">
          <span>powered by</span>
          <img src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-100px.png" />
        </div> */}
      {payPalOrderAddressConfirmed ? (
        <PaypalButton
          deliveryInfo={deliveryInfo}
          paypalInfo={paypalInfo}
          onChange={onChange}
          onChangeNumber={onChangeNumber}
          onSuccess={onPaypalSuccess}
          onAuthorize={onPaypalAuthorize}
          amount={order.due === undefined ? order.total : order.due}
          onCapturing={onPaypalCapturing}
        />
      ) : (
        <PlaceOrderButton
          className="place-order"
          onClick={onConfirmPaypalAddress}
          disabled={!deliveryInfo.address_supported_for_delivery}
        >
          Save
        </PlaceOrderButton>
      )}
    </div>
  );
};

type CheckoutWithPaypalContainerProps = {
  paypalInfo: any;
  deliveryInfo: any;
  onPaypalSuccess: (paypalInfo: any) => void;
  onPaypalAuthorize: (captureId: string) => void;
  onPaypalCapturing: () => void;
  order: any;
  onChange: (sectionName: string, e: React.MouseEvent<HTMLElement>) => void;
  onChangeNumber: (sectionName: string, field: string, values: any[]) => void;
  error: any;
  payPalOrderAddressConfirmed: boolean;
  onConfirmPaypalAddress: (e: React.MouseEvent<HTMLElement>) => void;
  onEditPaypalAddress: (e: React.MouseEvent<HTMLElement>) => void;
  onEditAddress: (address: any) => void;
  onToggleRingBell: (e: React.MouseEvent<HTMLElement>) => void;
  onSelectPaypalAddress: (address: any) => void;
  isCompanyOrder: boolean;
};

export const CheckoutWithPaypalContainer: React.FC<CheckoutWithPaypalContainerProps> =
  (props) => {
    const fetch = useCallback(
      async ({ mealId }): Promise<GetMealByIdResponse> => {
        return await GetMealById(mealId);
      },
      []
    );

    const { order } = props;
    const { data } = useAsync({ promiseFn: fetch, mealId: order?.meal?._id });

    if (data == null) return <Loading />;

    return (
      <CheckoutWithPaypal
        {...props}
        orderCutoffTime={data?.meal?.orderCutoffTime}
      />
    );
  };
