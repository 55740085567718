import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { clearTransientMessage } from 'src/store/actions/transientMessage';

export const TransientMessage = () => {
  const transientMessage = useSelector((state) => state.transientMessage);
  const message = transientMessage?.message;
  const icon = transientMessage?.icon;

  const dispatch = useDispatch();

  if (message == null) {
    return null;
  }

  // Clear the message after 3 seconds.
  setTimeout(() => {
    clearTransientMessage(dispatch);
  }, 2000);

  return (
    <div
      style={{
        position: 'fixed',
        top: '100px',
        zIndex: '1',
        backgroundColor: '#333333',
        borderRadius: '10px',
        padding: '4px 12px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: '300px',
      }}
    >
      {icon != null ? (
        <FontAwesomeIcon
          style={{
            backgroundColor: '#61c776',
            color: 'white',
            padding: '8px',
            borderRadius: '100px',
          }}
          icon={icon}
        />
      ) : null}
      <div style={{ marginLeft: '12px', color: 'white' }}>{`${message}`}</div>
    </div>
  );
};
