import React, {
  ReactElement,
  useState,
  useCallback,
  useEffect,
  Component,
} from "react";
import { useAsync } from "react-async";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { GetPromoMeals } from "src/api/meals";
import { RootState } from "src/store";
import { LOGGING } from ".";
import { Loading } from "../tasting_menu/components";
import { ReferralAward } from "../tasting_menu/components/referrals/ReferralAward";

export interface PageSignUpByEmailReferralAwardProps {}

const PROMO = "MIDAUTUMN";

export const PageSignUpByEmailReferralAward: React.FC<PageSignUpByEmailReferralAwardProps> =
  (): ReactElement => {
    const params = useParams();
    //@ts-ignore
    const { socialInviteCode } = params;
    const promoCode = PROMO;
    const currentUser = useSelector((state: RootState) => state.currentUser);

    LOGGING &&
      console.log("PageSignUpByEmailReferralAward got data: ", {
        socialInviteCode,
        promoCode,
        currentUser,
      });

    const location = useLocation();
    const history = useHistory();
    useEffect(() => {
      const locationState: any = location?.state;
      if (locationState?.showOrderConfirmation == null) return;
      LOGGING &&
        console.log("useEffect called with:", { location, locationState });
      history.replace({ state: {} });
    }, [history, location.state]);

    const fetch = useCallback(async () => {
      return await GetPromoMeals(promoCode, currentUser?.user?._id);
    }, [socialInviteCode]);

    const { data } = useAsync({ promiseFn: fetch });
    LOGGING && console.log("PageSignUpByEmailReferralAward got data: ", data);
    if (data == null) return <Loading />;

    const { promo, promoMeals } = data;
    LOGGING &&
      console.log("PageSignUpByEmailReferralAward got promoMeals: ", {
        promo,
        promoMeals,
      });

    if (promoMeals.length === 0) {
      return <Redirect to={`/referrals/${socialInviteCode}`} />;
    }

    const dishes = promoMeals[0].dishes;
    const restaurant = promoMeals[0]?.restaurant?.name;
    LOGGING &&
      console.log("PageSignUpByEmailReferralAward got: ", {
        promo,
        promoMeals,
        dishes,
        restaurant,
        location,
      });

    return (
      <ReferralAward
        promo={promo}
        socialInviteCode={socialInviteCode}
        dishes={dishes}
        promoMeals={promoMeals}
        restaurant={restaurant}
      />
    );
  };

function mapStateToProps(state) {
  return {
    awardMeals: state.awardMeals,
  };
}

export default connect(mapStateToProps, {}, null, {
  forwardRef: true,
})(PageSignUpByEmailReferralAward);
