import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { DishType } from 'src/api/dish';
import { sumArr } from 'src/util/array';
import { LOGGING, Loading, DishDetails, PopUp } from '.';
import {
  saveDish,
  readDish,
  readRestaurantDetails,
  saveSelectionItem,
  createSelectionForDish,
  saveSelection,
  readRestaurantSelections,
} from '../../store/actions';
import { EditSelectionForm } from './components/dish/EditSelectionForm';
import { ExistingSelectionList } from './components/dish/ExistingSelectionList';

const NewSelectionTemplate = { name: '', minSelection: 0, maxSelection: 0, items: [] };
const NewSelectionItemTemplate = { name: '', price: 0, isDefaultChoice: 0, outOfStock: 0 };

class PageDishDetails extends Component {
  constructor() {
    super();
    LOGGING && console.log('PageDishDetails constructor');
    this.state = {
      loading: true,
      saving: '',
      newSelection: null,
      showExistingSelectionList: false,
      selectedExistingSelections: [],
      isEdittingSelections: false,
    };

    this.handleToggleEditSelection = this.handleToggleEditSelection.bind(this);
    this.handleSaveDish = this.handleSaveDish.bind(this);
    this.handleCancelDish = this.handleCancelDish.bind(this);
    this.handleChangeDishField = this.handleChangeDishField.bind(this);
    this.handleSelectCategories = this.handleSelectCategories.bind(this);
    this.handleSelectDishType = this.handleSelectDishType.bind(this);
    this.handleSelectPreSelectedGoods = this.handleSelectPreSelectedGoods.bind(this);
    this.handleEditSelectionItem = this.handleEditSelectionItem.bind(this);
    this.handleSaveSelectionItem = this.handleSaveSelectionItem.bind(this);
    this.handleDeleteSelectionItem = this.handleDeleteSelectionItem.bind(this);
    this.handleEditSelection = this.handleEditSelection.bind(this);
    this.handleSaveSelection = this.handleSaveSelection.bind(this);
    this.handleDeleteSelection = this.handleDeleteSelection.bind(this);
    this.handleToggleShowNewSelectionForm = this.handleToggleShowNewSelectionForm.bind(this);
    this.handleToggleShowExistingSelectionList =
      this.handleToggleShowExistingSelectionList.bind(this);
    this.handleAddSelectionItemInNewSelection =
      this.handleAddSelectionItemInNewSelection.bind(this);
    this.handleEditSelectionItemInEditSelection =
      this.handleEditSelectionItemInEditSelection.bind(this);
    this.handleDeleteSelectionItemInEditSelection =
      this.handleDeleteSelectionItemInEditSelection.bind(this);
    this.handleEditNewSelection = this.handleEditNewSelection.bind(this);
    this.handleSaveNewSelection = this.handleSaveNewSelection.bind(this);
    this.handleToggleExistingSelectionToDish = this.handleToggleExistingSelectionToDish.bind(this);
    this.handleEditSelections = this.handleEditSelections.bind(this);
    this.handleAddSelectionItemInEditSelection =
      this.handleAddSelectionItemInEditSelection.bind(this);
  }

  handleToggleEditSelection(selection, e) {
    e.preventDefault();
    const { dish } = this.state;
    LOGGING && console.log('handleToggleEditSelection called with:', { dish, selection });
    this.setState(
      {
        dish: { ...dish, editSelection: dish.editSelection ? null : { ...selection } },
      },
      () => {
        window.scrollTo(0, 0);
      }
    );
  }
  handleEditSelections(e) {
    e.preventDefault();
    this.setState({ isEdittingSelections: true });
  }
  handleToggleExistingSelectionToDish(selection, e) {
    // e.preventDefault();
    const { dish } = this.state;
    LOGGING &&
      console.log('handleToggleExistingSelectionToDish called with:', {
        selection,
        dish,
      });
    const { selections } = dish;
    const selectedExistingSelections = selections.map((s) => s._id);
    if (selectedExistingSelections.includes(selection._id)) {
      this.setState({
        dish: { ...dish, selections: selections.filter((s) => s._id !== selection._id) },
      });
    } else {
      this.setState({
        dish: { ...dish, selections: [...selections, selection] },
      });
    }
  }

  handleToggleShowExistingSelectionList(e) {
    e.preventDefault();

    const { showExistingSelectionList } = this.state;
    LOGGING &&
      console.log('handleToggleShowExistingSelectionList called iwth:', showExistingSelectionList);
    this.setState({ showExistingSelectionList: !showExistingSelectionList });
  }

  handleSaveNewSelection(e) {
    LOGGING && console.log('handleSaveNewSelection called');
    e.preventDefault();
    const { newSelection, dish } = this.state;

    this.props.createSelectionForDish(dish._id, newSelection).then((updatedDish) => {
      this.setState({
        dish: { ...updatedDish },
        newSelection: null,
      });
    });
  }
  handleEditNewSelection(e) {
    LOGGING && console.log('handleEditNewSelection called with:', e.target);
    const { newSelection } = this.state;
    this.setState({ newSelection: { ...newSelection, [e.target.name]: e.target.value } });
  }
  handleAddSelectionItemInEditSelection(e) {
    e.preventDefault();
    LOGGING && console.log('handleAddSelectionItemInEditSelection called');
    const { dish } = this.state;
    const { editSelection } = dish;
    this.setState({
      dish: {
        ...dish,
        editSelection: {
          ...editSelection,
          items: [...editSelection.items, NewSelectionItemTemplate],
        },
      },
    });
  }
  handleAddSelectionItemInNewSelection(e) {
    e.preventDefault();
    LOGGING && console.log('handleAddSelectionItemInNewSelection called');
    const { newSelection } = this.state;
    this.setState(
      {
        newSelection: { ...newSelection, items: [...newSelection.items, NewSelectionItemTemplate] },
      },
      () => {
        window.scrollTo(0, 0);
      }
    );
  }
  handleDeleteSelectionItemInEditSelection(index, e) {
    e.preventDefault();
    LOGGING && console.log('handleDeleteSelectionItemInEditSelection called:', index);
    const { dish } = this.state;
    const { editSelection } = dish;
    const { items } = editSelection;
    LOGGING && console.log('items before splice:', items);
    // items.splice(index, 1);
    const updatedItems = items.filter((item, itemIndex) => itemIndex !== index);
    LOGGING && console.log('items after splice:', updatedItems);
    this.setState({
      dish: { ...dish, editSelection: { ...editSelection, items: [...updatedItems] } },
    });
  }
  handleToggleShowNewSelectionForm(e) {
    e.preventDefault();
    const { dish } = this.state;
    this.setState({ dish: { ...dish, editSelection: { ...NewSelectionTemplate } } });
  }
  handleDeleteSelectionItem(selection, itemId, e) {
    LOGGING && console.log('handleDeleteSelectionItem called with:', { selection, itemId });
    e.preventDefault();
    const { dish } = this.state;
    const { items } = selection;
    const { selections } = dish;
    const updatedItems = items.filter((i) => i._id !== itemId).map((i) => i._id);
    this.props
      .saveSelection({ _id: selection._id, items: [...updatedItems] })
      .then((updatedSelection) => {
        this.setState({
          dish: {
            ...dish,
            selections: selections.map((s) =>
              s._id === selection._id ? { ...updatedSelection } : { ...s }
            ),
          },
        });
      });
  }
  handleSaveSelectionItem(selectionId, selectionItem, e) {
    LOGGING && console.log('handleSaveSelectionItem called with:', selectionItem);
    e.preventDefault();
    const { dish } = this.state;
    this.setState({ dish: { ...dish, savingItemId: String(selectionItem._id) } }, () => {
      this.props.saveSelectionItem(selectionItem).then((updatedItem) => {
        const { dish } = this.state;
        const { selections } = dish;
        const updatedSelections = selections.map((selection) =>
          selection._id === selectionId
            ? {
                ...selection,
                items: selection.items.map((item) =>
                  item._id === selectionItem._id ? { ...updatedItem } : { ...item }
                ),
              }
            : { ...selection }
        );
        this.setState({ dish: { ...dish, selections: [...updatedSelections], savingItemId: '' } });
      });
    });
  }
  handleEditSelectionItem(itemId, e) {
    LOGGING && console.log('handleEditSelectionItem called with:', itemId, e.target);
    const { dish } = this.state;
    const { editSelection } = dish;
    const updatedEditSelection = {
      ...editSelection,
      items: editSelection.items.map((item) =>
        item._id === itemId
          ? {
              ...item,
              [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
            }
          : { ...item }
      ),
    };
    LOGGING && console.log('handleEditSelectionItem updatedEditSelection:', updatedEditSelection);
    this.setState({ dish: { ...dish, editSelection: { ...updatedEditSelection } } });
  }
  handleEditSelectionItemInEditSelection(itemIndex, e) {
    e.preventDefault();
    LOGGING &&
      console.log('handleEditSelectionItemInEditSelection called with:', itemIndex, e.target);
    const { dish } = this.state;
    const { editSelection } = dish;
    const updatedEditSelection = {
      ...editSelection,
      items: editSelection.items.map((item, index) =>
        index === itemIndex
          ? {
              ...item,
              [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
            }
          : { ...item }
      ),
    };
    LOGGING && console.log('handleEditSelectionItem updatedEditSelection:', updatedEditSelection);
    this.setState({ dish: { ...dish, editSelection: { ...updatedEditSelection } } });
  }
  handleEditSelectionItemInNewSelection(itemIndex, e) {
    LOGGING &&
      console.log('handleEditSelectionItemInNewSelection called with:', itemIndex, e.target);
    const { newSelection } = this.state;
    const { items } = newSelection;
    const updatedItems = items.map((item, index) =>
      index === itemIndex
        ? {
            ...item,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
          }
        : { ...item }
    );
    LOGGING && console.log('handleEditSelectionItemInNewSelection updatedItems:', updatedItems);
    this.setState({ newSelection: { ...newSelection, items: [...updatedItems] } });
  }
  handleDeleteSelection(selectionId, e) {
    LOGGING && console.log('handleDeleteSelection called with:', { selectionId });
    e.preventDefault();
    const { dish } = this.state;
    const { selections, _id } = dish;
    const updatedSelections = selections.filter((s) => s._id !== selectionId).map((s) => s._id);
    this.props.saveDish({ ...dish, selections: [...updatedSelections] }).then((updatedDish) => {
      this.setState({ dish: { ...updatedDish } });
    });
  }
  handleSaveSelection(e) {
    e.preventDefault();
    const { dish, selectionsForRestaurant } = this.state;
    const { editSelection } = dish;
    LOGGING && console.log('handleSaveSelection called with:', dish);
    this.setState({ dish: { ...dish }, savingSelection: true }, () => {
      this.props.saveSelection(editSelection, dish).then(({ dish: udpatedDish, selection }) => {
        const { _id, name, minSelection, maxSelection, items } = selection;
        const updatedSelectionsForRestaurant = selectionsForRestaurant.map((s) =>
          String(s._id) === String(_id)
            ? {
                _id,
                name,
                minSelection,
                maxSelection,
                items,
              }
            : { ...s }
        );
        LOGGING &&
          console.log('handleSaveSelection got :', {
            updatedSelectionsForRestaurant,
            selectionsForRestaurant,
            selection,
            dish,
          });
        this.setState({
          dish: {
            ...udpatedDish,
            editSelection: null,
          },
          savingSelection: false,
          selectionsForRestaurant: [...updatedSelectionsForRestaurant],
        });
      });
    });
  }
  handleEditSelection(e) {
    LOGGING && console.log('handleEditSelection called with:', e.target);
    const { dish } = this.state;
    const { editSelection } = dish;
    LOGGING && console.log('handleEditSelection called with editSection:', editSelection);
    const updatedEditSelection = {
      ...editSelection,
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    };
    LOGGING && console.log('handleEditSelection updatedEditSelection:', updatedEditSelection);
    this.setState({ dish: { ...dish, editSelection: { ...updatedEditSelection } } });
  }
  handleChangeDishField(e) {
    LOGGING && console.log('handleChangeDishField called with:', e.target, e.target.type);
    const { dish } = this.state;
    const value = e.target.type === 'number' ? Number(e.target.value) : e.target.value;
    this.setState({ dish: { ...dish, [e.target.name]: value } });
  }

  handleSaveDish(e) {
    e.preventDefault();
    const { dish } = this.state;
    const { restaurant } = dish;
    LOGGING && console.log('handleSaveDish called with:', { dish, restaurant });
    this.setState({ loading: true }, () => {
      this.props.saveDish(dish).then((savedDish) => {
        LOGGING && console.log('handleSaveDish got savedDish:', savedDish);
        this.props.history.push(`/restaurant/${restaurant._id || restaurant}`);
      });
    });
  }

  handleCancelDish(e) {
    e.preventDefault();
    LOGGING && console.log('handleCancelDish called');
    this.props.history.goBack();
  }

  handleSelectCategories(categories) {
    const { dish } = this.state;
    this.setState({ dish: { ...dish, categories: categories } });
  }

  handleSelectDishType(dishType) {
    const { dish } = this.state;
    this.setState({ dish: { ...dish, dishType: dishType } });
  }

  handleSelectPreSelectedGoods(goods) {
    const { dish } = this.state;
    this.setState({
      dish: {
        ...dish,
        preSelectedGoods: goods,
        priceFoodieListed: sumArr(goods.map((good) => good.price * good.quantity)),
      },
    });
  }

  componentDidMount() {
    const { dishId, restaurantId } = this.props.match.params;
    LOGGING &&
      console.log('PageDishDetails componentdidmount with:', {
        dishId,
        restaurantId,
      });
    if (restaurantId) {
      this.props.readRestaurantDetails(restaurantId).then((restaurant) => {
        LOGGING && console.log('PageDishDetails readRestaurantDetails got restaurant:', restaurant);
        const { selectionsForRestaurant } = restaurant;
        this.setState(
          {
            dish: {
              name: '',
              description: '',
              imageURL: '',
              priceFoodieListed: '',
              restaurant,
              // dishType: DishType.NORMAL,
              selections: [],
            },
            selectionsForRestaurant,
            loading: false,
          },
          () => {
            window.scrollTo(0, 0);
          }
        );
      });
    } else {
      this.props.readDish(dishId).then((dish) => {
        const { restaurant } = dish;
        this.props.readRestaurantSelections(restaurant._id).then((selectionsForRestaurant) => {
          this.setState({ dish, selectionsForRestaurant, loading: false }, () => {
            window.scrollTo(0, 0);
          });
        });
      });
    }
  }

  render() {
    const { currentUser } = this.props;
    const {
      loading,
      dish,
      newSelection,
      showExistingSelectionList,
      selectionsForRestaurant,
      isEdittingSelections,
      savingSelection,
    } = this.state;

    LOGGING &&
      console.log('PageDishDetails rendering with:', {
        props: this.props,
        state: this.state,
        isOps: currentUser && currentUser.user && currentUser.user.isOps,
      });

    if (!currentUser.isAuthenticated || (!currentUser.user.isAdmin && !currentUser.user.isOps)) {
      return <Redirect to="/" />;
    }

    return (
      <div className="page">
        {loading ? (
          <Loading />
        ) : (
          <DishDetails
            // If dish is present, then we're editting
            // If dish is not present, then we're creating a dish
            restaurantId={dish.restaurant._id ?? this.props.match.params.restaurantId}
            dish={dish}
            onEdit={this.handleChangeDishField}
            onCancel={this.handleCancelDish}
            onSave={this.handleSaveDish}
            selectedCategories={dish.categories}
            onSelectCategories={this.handleSelectCategories}
            onSelectDishType={this.handleSelectDishType}
            onSelectPreSelectedGoods={this.handleSelectPreSelectedGoods}
            onAddSelectionItem={this.handleAddSelectionItemInEditSelection}
            onEditSelectionItem={this.handleEditSelectionItemInEditSelection}
            onDeleteSelectionItem={this.handleDeleteSelectionItemInEditSelection}
            onEditSelection={this.handleEditSelection}
            onSaveSelection={this.handleSaveSelection}
            onDeleteSelection={this.handleDeleteSelection}
            onToggleShowNewSelectionForm={this.handleToggleShowNewSelectionForm}
            onToggleShowExistingSelectionList={this.handleToggleShowExistingSelectionList}
            isEdittingSelections={isEdittingSelections}
            onEditSelections={this.handleEditSelections}
            selectionsForRestaurant={selectionsForRestaurant}
            onToggleUseSelection={this.handleToggleExistingSelectionToDish}
            onToggleEditSelection={this.handleToggleEditSelection}
            savingSelection={savingSelection}
            onSaveCategory={this.handleSaveNewCategory}
          />
        )}
        <PopUp
          isPoppedUp={showExistingSelectionList}
          componentToDisplay={
            <ExistingSelectionList
              selections={selectionsForRestaurant}
              dish={dish}
              onToggleSelection={this.handleToggleExistingSelectionToDish}
              onSave={this.handleSaveDish}
            />
          }
          hidePopUp={this.handleToggleShowExistingSelectionList}
          backgroundColor={'#333'}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log('PageDishDetails got redux state:', state);
  return {
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  saveDish,
  readDish,
  readRestaurantDetails,
  saveSelectionItem,
  saveSelection,
  createSelectionForDish,
  readRestaurantSelections,
})(PageDishDetails);
