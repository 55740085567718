import { LOGGING } from "src/constants";
import {
  SET_DELIVERY_ZONES,
  SET_DELIVERY_ZONE_BY_CITY,
} from "src/store/actionTypes";
import { apiCall } from "src/store/api";
import { setDeliveryCity } from "./deliveryCity";

export const setDeliveryZones = (deliveryZones) => ({
  type: SET_DELIVERY_ZONES,
  deliveryZones,
});

export const setDeliveryZoneByCity = (deliveryZoneByCity) => ({
  type: SET_DELIVERY_ZONE_BY_CITY,
  deliveryZoneByCity,
});

export const fetchDeliveryZones = () => {
  return async (dispatch, getState) => {
    LOGGING && console.log("fetchDeliveryZones called");
    try {
      const deliveryZones = await apiCall(
        "get",
        "/deliveryZones",
        getState().currentUser
      );
      LOGGING && console.log("fetchDeliveryZones get result:", deliveryZones);
      dispatch(setDeliveryZones(deliveryZones));
    } catch (err) {
      LOGGING && console.log("fetchDeliveryZones got err", err);
    }
  };
};

export const fetchDeliveryZoneByCity = () => {
  return async (dispatch, getState) => {
    // LOGGING && console.log('fetchDeliveryZoneByCity called');
    try {
      const deliveryZoneByCity = await apiCall("get", "/deliveryZones/byCity");
      // LOGGING &&
      //   console.log("fetchDeliveryZoneByCity get result:", deliveryZoneByCity);
      dispatch(setDeliveryZoneByCity(deliveryZoneByCity));
      const { deliveryCity } = getState();
      const deliveryCities = Object.keys(deliveryZoneByCity);
      // if deliveryCity not in deliveryCities, reset deliveryCity
      if (deliveryCity && !deliveryCities.includes(deliveryCity)) {
        dispatch(setDeliveryCity(null));
      }
    } catch (err) {
      LOGGING && console.log("fetchDeliveryZoneByCity got err", err);
    }
  };
};
