import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { signIn } from "src/store/actions";
import styled from "styled-components";
import {
  FormContainer,
  SignUpField,
  ButtonCreate,
  AnonymousActionsWrapper,
} from "./Shared";
import { LOGGING } from "..";
import { text12300 } from "../Shared";
const ForgotPassword = styled.button`
  ${text12300}
  margin: 20px;
  height: 20px;
  line-height: 20px;
  font-weight: 400;
  padding: 0px;
  border-bottom: 1px solid black;
  text-transform: capitalize;
`;

export const ResetPassword = ({ defaultEmail, onSendResetLink }) => {
  const [email, setEmail] = useState(defaultEmail);
  const handleSubmit = (e) => {
    e.preventDefault();
    onSendResetLink(email);
  };
  return (
    <FormContainer>
      <SignUpField
        fieldName="email"
        fieldValue={email}
        onChange={setEmail.bind(this)}
      />
      <ButtonCreate onClick={handleSubmit}>send reset link</ButtonCreate>
    </FormContainer>
  );
};

export const GetPassword = ({ email, parentError, onSetParentError, next }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const onSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    LOGGING &&
      console.log("GetPassoword onSignIn called with:", { email, password, e });
    try {
      await dispatch(signIn({ email, password }));
      setLoading(false);
      if (next) {
        history.push(next);
      } else {
        history.push("/");
      }
    } catch (error) {
      LOGGING && console.log("sign in got error:", error);
      setLoading(false);
      onSetParentError(error?.message);
    }
  };
  const handleChange = (value) => {
    setPassword(value);
    if (parentError?.length > 0) {
      onSetParentError(null);
    }
  };

  const handleForgotPasswordClick = (e) => {
    e.preventDefault();
    history.push("/forgetpassword", { email });
  };

  return (
    <AnonymousActionsWrapper small={true}>
      <SignUpField
        fieldName="password"
        fieldValue={password}
        // errorMessage={signInError}
        onChange={handleChange.bind(this)}
        isPassword={true}
        loading={loading}
      />
      <ButtonCreate disabled={loading} onClick={onSignIn}>
        {loading ? "logging in..." : "log in"}
      </ButtonCreate>
      <ForgotPassword onClick={handleForgotPasswordClick}>
        forgot password?
      </ForgotPassword>
    </AnonymousActionsWrapper>
  );
};
