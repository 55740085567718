import React from 'react'; // eslint-disable-line no-unused-vars
import './PopUp.scss';

const PopUp = ({ isPoppedUp, componentToDisplay, hidePopUp, backgroundColor }) => {
  return isPoppedUp ? (
    <div className="popup-container">
      <div
        className="popup-overlay"
        onClick={hidePopUp}
        style={
          backgroundColor
            ? {
                backgroundColor,
              }
            : null
        }
      ></div>
      {componentToDisplay}
    </div>
  ) : null;
};

export default PopUp;
