import { HTMLProps } from 'react';
import styled from 'styled-components';

type GiftCardTemplateProps = {
  template?: string;
  message?: string;
} & HTMLProps<HTMLDivElement>;

export const GIFT_CARD_TEMPLATES = ['a special present for you', 'merry christmas'];

export const GiftCardTemplate = (props: GiftCardTemplateProps) => {
  const { template, message, ...rest } = props;
  return (
    <div {...rest}>
      {template === 'a special present for you' && (
        <ASpecialPresentForYou>
          <h3>A Special Present For You</h3>
          <p>{message}</p>
        </ASpecialPresentForYou>
      )}
      {template === 'merry christmas' && (
        <MerryChristmas>
          <h3>Merry christmas</h3>
          <p>{message}</p>
        </MerryChristmas>
      )}
    </div>
  );
};

const ASpecialPresentForYou = styled.div`
  position: relative;
  padding: 20px;
  background: #0d1633;
  text-align: center;
  height: 180px;

  h3 {
    color: white;
    font-family: 'Theano Didot', serif;
    font-size: 24px;
  }

  p {
    color: white;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
  }
`;

const MerryChristmas = styled.div`
  position: relative;
  padding: 20px;
  background: #f8e2de;
  text-align: center;
  height: 180px;

  h3 {
    color: #c53e2b;
    font-family: 'Theano Didot', serif;
    font-size: 24px;
  }

  p {
    color: #c53e2b;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    text-align: center;
  }
`;
