import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import expireReducer from "redux-persist-expire";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native
import thunk from "redux-thunk";
import { getNextDeliveryTime, LOGGING } from "../constants";
import rootReducer from "./reducers";

const { secondsUntilExpire } = getNextDeliveryTime();
// LOGGING && console.log('configureStore got secondsUntilExpire: ', secondsUntilExpire);
const persistConfig = {
  key: "root",
  storage,
  transforms: [
    // expireReducer('posts', {
    //   persistedAtKey: 'loadedAt',
    //   expireSeconds: 60 * 1,
    //   expiredState: [], // Reset to empty array after expiry
    // }),
    // expireReducer('isLunch', {
    //   persistedAtKey: 'loadedAt',
    //   expireSeconds: secondsUntilExpire,
    //   expiredState: { payLoad: null, loadedAt: -1 }, // Reset to empty array after expiry
    // }),
    expireReducer("dishes", {
      persistedAtKey: "loadedAt",
      expireSeconds: secondsUntilExpire,
      expiredState: { payLoad: [], loadedAt: -1 }, // Reset to empty object after expiry
    }),
    expireReducer("meals", {
      persistedAtKey: "loadedAt",
      expireSeconds: Math.min(60, secondsUntilExpire),
      expiredState: [], // Reset to empty object after expiry
    }),
    // expireReducer('cart', {
    //   persistedAtKey: 'loadedAt',
    //   expireSeconds: secondsUntilExpire,
    //   // expireSeconds: 60, //just for testing, do not check in
    //   expiredState: {
    //     payLoad: { goods: {}, sum: 0, paymentIntentId: null },
    //     loadedAt: -1,
    //   },
    // }),
    // You can add more `expireReducer` calls here for different reducers
    // that you may want to expire
  ],
  blacklist: [
    "adminDeliveryV2",
    "mealMenu",
    "routeV2",
    "stops",
    "transientMessage",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
      : (f) => f
  )
);
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type FoodieDispatch = typeof store.dispatch;
