import moment from 'moment-timezone';
import { DEFAULT_MEAL_FILTER_ID } from 'src/screens/tasting_menu/components/MealFilter';
import {
  ADMIN_DELIVERY_V2_INVITE_DRIVER,
  ADMIN_DELIVERY_V2_SET_DRIVER_FILTER,
  ADMIN_DELIVERY_V2_SET_IS_LUNCH_FILTER,
  ADMIN_DELIVERY_V2_SET_MEAL_TYPE_FILTER,
  ADMIN_DELIVERY_V2_SET_MEAL_FILTER,
  LOAD_ADMIN_DELIVERY_V2_DRIVERS,
  LOAD_ADMIN_DELIVERY_V2_MEALS,
  LOAD_ADMIN_DELIVERY_V2_STOPS,
} from '../actionTypes';

const adminDeliveryV2 = (
  state = {
    stops: [],
    drivers: [],
    meals: [],
    filters: {
      meal: DEFAULT_MEAL_FILTER_ID,
      isLunch: false,
      mealType: 'lunch',
    },
  },
  action
) => {
  switch (action.type) {
    case ADMIN_DELIVERY_V2_INVITE_DRIVER:
      const drivers = state.drivers;
      const updatedDrivers = drivers.map((d) => {
        if (d._id === action.driverId) {
          return {
            ...d,
            askedAvailability: true,
            askedAvailabilityTime: moment().valueOf(),
            askedAvailabilityExpired: false,
            restaurantName: action.restaurantName,
          };
        }

        return d;
      });

      return { ...state, drivers: updatedDrivers };
    case ADMIN_DELIVERY_V2_SET_DRIVER_FILTER:
      return { ...state, filters: { ...state.filters, driver: action.driverId } };
    case ADMIN_DELIVERY_V2_SET_IS_LUNCH_FILTER:
      return { ...state, filters: { ...state.filters, isLunch: action.isLunch } };
    case ADMIN_DELIVERY_V2_SET_MEAL_TYPE_FILTER:
      return { ...state, filters: { ...state.filters, mealType: action.mealType } };
    case ADMIN_DELIVERY_V2_SET_MEAL_FILTER:
      return { ...state, filters: { ...state.filters, meal: action.mealId } };
    case LOAD_ADMIN_DELIVERY_V2_DRIVERS:
      return { ...state, drivers: action.drivers };
    case LOAD_ADMIN_DELIVERY_V2_MEALS:
      return { ...state, meals: action.meals };
    case LOAD_ADMIN_DELIVERY_V2_STOPS:
      const newState = { ...state, stops: action.stops };
      return newState;
    default:
      return state;
  }
};

export default adminDeliveryV2;
