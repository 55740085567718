import "./RestaurantList.scss";
import "./StopsMap.scss";
import React from "react";
import { faMapPin } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GoogleMapReact from "google-map-react";
import { rest } from "lodash";
import moment from "moment-timezone";
import { Button } from "src/components/Button";
import { Flex } from "src/components/Layout";
import { LOGGING, Back, CATimeZone, DeliveryZonesSelector } from ".";
import { MealMenuAdmin } from "./mealMenu/MealMenuAdmin";
export const EditMeal = ({
  restaurant,
  onSaveMeal,
  mealId,
  onClose,
  deliveryZones,
  selectedDeliveryZone,
  onCloseRestaurant,
  dishes,
  onToggleDish,
  selectedDishes,
  onSelectAllDishes,
  onDeselectAllDishes,
  onToggleSupportEarlyDelivery,
  selectedPreOrderedDishes,
  onChangePreOrderQuantity,
  categoriesForRestaurant,
  onSelectAllDishesInCategory,
  onDeselectAllDishesInCategory,
  onEditDish,
  onSelectDeliveryZone,
  onSetHeroImage,
  isLunch,
  day,
  cutOff,
  onEditCutOff,
}) => {
  LOGGING &&
    console.log("EditMeal rendering with: ", {
      deliveryZones,
      selectedDeliveryZone,
      day,
      mealId,
      isLunch,
      cutOff,
    });

  return (
    <React.Fragment>
      <div className="list-title edit-meal">
        <div className="restaurant-summary">
          <div className="restaurant-name">{`${
            restaurant ? restaurant.name.split(",")[0] : ""
          }`}</div>
          {restaurant && restaurant.preOrderOnly ? (
            <div className="restaurant-preorder">pre-order only</div>
          ) : null}
        </div>
        <div className="edit-cutoff">
          <label>cut off time</label>
          <input
            type="number"
            value={cutOff.hour}
            name="hour"
            step={1}
            onChange={onEditCutOff}
          />
          <span>:</span>
          <input
            type="number"
            value={cutOff.minute}
            name="minute"
            step={1}
            onChange={onEditCutOff}
          />
        </div>
        <div className="edit-cutoff">
          <label>cut off date</label>
          <input
            type="number"
            value={cutOff.year}
            name="year"
            step={1}
            onChange={onEditCutOff}
          />
          <span>-</span>
          <input
            type="number"
            value={cutOff.month}
            name="month"
            step={1}
            onChange={onEditCutOff}
          />
          <span>-</span>
          <input
            type="number"
            value={cutOff.date}
            name="date"
            step={1}
            onChange={onEditCutOff}
          />
        </div>
        <div className="save-dishes">
          <Flex align="center" gap={12}>
            <Button onClick={(e) => onSaveMeal(e, mealId)}>Save</Button>
          </Flex>
        </div>
        <Back onClick={onClose} />
      </div>
      <DeliveryZonesSelector
        supportMulti={true}
        deliveryZones={deliveryZones}
        onSelectDeliveryZone={onSelectDeliveryZone}
        selectedDeliveryZone={selectedDeliveryZone}
      />
      <MealMenuAdmin
        restaurant={restaurant}
        onClose={onCloseRestaurant}
        dishes={dishes}
        onToggleDish={onToggleDish}
        selectedDishes={selectedDishes}
        selectedPreOrderedDishes={selectedPreOrderedDishes}
        onSelectAllDishes={onSelectAllDishes}
        onDeselectAllDishes={onDeselectAllDishes}
        isLunch={isLunch}
        onToggleSupportEarlyDelivery={onToggleSupportEarlyDelivery}
        onChangePreOrderQuantity={onChangePreOrderQuantity}
        categoriesForRestaurant={categoriesForRestaurant}
        onSelectAllDishesInCategory={onSelectAllDishesInCategory}
        onDeselectAllDishesInCategory={onDeselectAllDishesInCategory}
        onEditDish={onEditDish}
        onSetHeroImage={onSetHeroImage}
      />
    </React.Fragment>
  );
};
const RestaurantMarker2 = ({
  restaurant,
  confirmed,
  onSelect,
  restaurantId,
  selected,
  considering,
}) => {
  if (selected) {
    LOGGING &&
      console.log("RestaurantMarker2 rendering with:", {
        history: restaurant?.history,
        historyLength: restaurant?.history.length - 1,
        lastMeal: restaurant?.history[restaurant.history.length - 1]?.stats,
        confirmed,
        selected,
      });
  }
  return selected ? (
    <div
      className={`address-marker restaurant-marker selected ${
        confirmed ? "confirmed" : ""
      }`}
    >
      <FontAwesomeIcon icon={faMapPin} />
      <div className="restaurant-notes">
        <span className="restaurant-gmv">
          $
          {restaurant?.history[
            restaurant.history.length - 1
          ]?.stats?.revenue.toFixed(0)}
        </span>
        {restaurant?.commission ? (
          <span className="restaurant-commission">
            {restaurant?.commission}%
          </span>
        ) : null}
      </div>
      <span className="restaurant-name">
        {restaurant.name && restaurant.name.split(",")[0]}
      </span>
    </div>
  ) : (
    <button
      className={`address-marker restaurant-marker ${
        considering ? "considering" : ""
      }`}
      onClick={onSelect.bind(this, restaurantId)}
    >
      <FontAwesomeIcon icon={faMapPin} />
      <div className="restaurant-notes">
        <span className="restaurant-gmv">
          $
          {restaurant?.history[
            restaurant?.history.length - 1
          ]?.stats?.revenue.toFixed(0)}
        </span>
        {restaurant?.commission ? (
          <span className="restaurant-commission">
            {restaurant?.commission}%
          </span>
        ) : null}
      </div>
      <span className="restaurant-name">
        {restaurant.name && restaurant.name.split(",")[0]}
      </span>
    </button>
  );
};

const RestaurantMap = ({
  restaurants,
  day,
  onSelectRestaurant,
  selectedRestaurants,
  consideringRestaurantIds,
  zoom,
  center,
  onBack,
  onSaveMeals,
  mealType,
}) => {
  LOGGING && console.log(`RestaurantMap got ${restaurants.length} restaurants`);
  const openRestaurants = restaurants.filter((r) => {
    const schedule = r.schedule?.filter(
      (s) => s.dayOfWeek === moment(day.date).day()
    );
    return schedule?.length > 0 && schedule[0][mealType];
  });
  LOGGING &&
    console.log(`RestaurantMap got ${openRestaurants.length} open restaurants`);

  LOGGING &&
    console.log("RestaurantMap rendering with:", {
      day,
      dayOfWeek: moment(day.date).day(),
      mealType,
      openRestaurants,
      selectedRestaurants,
      consideringRestaurantIds,
    });
  // const [notes, setNotes] = useState('');

  return (
    <div className="list restaurants">
      <div className="list-title">
        <div className="schedule-date">
          {day.date.format("dddd, MMMM D, YYYY")} {mealType}
        </div>
        <div className="save-dishes">
          <Flex align="center" gap={12}>
            <Button onClick={(e) => onSaveMeals(e, consideringRestaurantIds)}>
              Save
            </Button>
          </Flex>
        </div>
        <Back onClick={onBack} />
      </div>
      <div className="stops-map restaurants-map">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyB-0SwH76HPTJGm_mhM1Rg0ACcpYGpAt1Q",
          }}
          // defaultCenter={center}
          defaultZoom={zoom}
          center={[center.lat, center.lng]}
        >
          {openRestaurants.map((restaurant, index) => {
            if (!restaurant.map) {
              LOGGING && console.log("restaurant with no map:", restaurant);
            }

            return (
              <RestaurantMarker2
                restaurant={restaurant}
                key={index}
                onSelect={onSelectRestaurant}
                restaurantId={restaurant._id}
                selected={Object.keys(selectedRestaurants).includes(
                  restaurant._id
                )}
                confirmed={selectedRestaurants[restaurant._id]}
                considering={consideringRestaurantIds.includes(restaurant._id)}
                lat={restaurant.map ? restaurant.map.lat : center.lat}
                lng={restaurant.map ? restaurant.map.lng : center.lng}
              />
            );
          })}
        </GoogleMapReact>
      </div>
    </div>
  );
};

export default RestaurantMap;
