import React, {
  ReactElement,
  useCallback,
  useState,
  useEffect,
  useRef,
} from "react";
// import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import {
  faTimes,
  faPlusCircle,
  faShoppingCart,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { useAsync } from "react-async";
import { useSelector, useDispatch, useStore } from "react-redux";
import { Link } from "react-router-dom";
import { GetReferralCodeDetails } from "src/api/referrals";
import { FoodieEvent, FoodieSource } from "src/api/tracker";
import { useTrackEventCallback } from "src/hooks/useTrackEventCallback";
import { RootState } from "src/store";
import styled, { keyframes } from "styled-components";
import { LOGGING, getItemNumberInCart } from "..";
import { addGoodsToCart } from "../../../../store/actions";
import { GoodsContainer } from "../dish/GoodsContainer";
import {
  LightBlue,
  BadgeHeight,
  textTitle,
  centeredByColumn,
  Centered,
  CenteredByColumn,
  Card,
  card,
  Text12300,
  CenteredByColumnLeft,
  TextTitle,
  text12300,
  Beige,
  BorderGrey,
} from "../Shared";

interface ContainerProps {
  height: number;
  width: number;
}
const Container = styled(CenteredByColumnLeft)<ContainerProps>`
  /* width: calc(100vw - ${BadgeHeight}px - 10px); */
  /* padding-left: ${BadgeHeight}px; */
  justify-content: flex-start;
  max-height: ${(props) => props.height}px;
  /* overflow: scroll; */
  margin: 0px;
  /* position: absolute;
  top: 0px; */
  /* padding-top: 10px; */
  > div:last-child {
    margin-bottom: 50px;
  }
`;
const LongContainer = styled(CenteredByColumnLeft)`
  width: 100%;
  height: auto;
  justify-content: flex-start;
`;
const Order = styled(Card)`
  margin: 10px 10px 0 10px;
  /* margin-bottom: 10px; */
  /* width: calc(100vw - ${BadgeHeight}px - 20px);   */
  align-self: stretch;
  padding: 15px;
  /* padding-bottom: 50px; */
  /* margin-right: 10px; */
  ${centeredByColumn}
  align-items: flex-start;
  justify-content: flex-start;
  /* justify-content: ; */
  position: relative;
  /* height: 100%; */
`;
const OrderPlaceHolder = styled.div`
  height: 150px;
  line-height: 150px;
  width: 100px;
  background: transparent;
  color: transparent;
  display: block;
  position: relative;
`;
export interface DishImageProps {
  imageUrl: string;
}
const DishImage = styled.div<DishImageProps>`
  display: block;
  position: absolute;
  background-image: url(${(props) => props.imageUrl});
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  left: 0px;
  /* background-color: rgba(0, 0, 0, 0.5); */
`;
const ImageOverlay = styled.div`
  display: block;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  left: 0px;
  z-index: 1;
`;
const AddOrder = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
  ${text12300}
  background-color: black;
  color: white;
  font-weight: 500;
  border-radius: 5px;
  text-transform: none;
  width: 65px;
  height: 30px;
`;
const OrderDate = styled(TextTitle)`
  display: inline-block;
  /* font-weight: 500; */
  font-size: 16px;
  line-height: 1.6;
  max-width: calc(100% - 70px);
  margin-bottom: 2px;
`;
const OrderGoods = styled(CenteredByColumnLeft)`
  text-align: left;
  width: 100%;
  background-color: ${Beige};
  /* padding-top: 15px; */
  /* padding-right: 5px; */
  > div:last-child {
    margin-bottom: 0px;
    border: none;
  }
`;

const Quantity = styled(Text12300)``;
const Dish = styled(Text12300)`
  line-height: 1.4;
  font-size: 11px;
  /* margin-bottom: 5px; */
`;
const AddDish = styled.button`
  position: absolute;
  padding: 0px;
  margin: 0px;
  top: 16px;
  right: 0px;
  svg {
    font-size: 14px;
  }
`;
const DishName = styled(Text12300)`
  font-weight: 500;
  line-height: 1.3;
  /* margin-bottom: 20px; */
`;
const Selections = styled(CenteredByColumnLeft)`
  margin-top: 10px;
`;
const Selection = styled(CenteredByColumnLeft)``;
const SelectionName = styled(Text12300)``;
const SelectionItems = styled(CenteredByColumnLeft)`
  /* padding-left: 10px;   */
  margin-top: 5px;
`;
const SelectionItem = styled(Centered)`
  align-items: flex-start;
  line-height: 1.5;
  /* background-color: red; */
  svg {
    /* color: blue; */
    font-size: 3px;
    margin-top: 6px;
    margin-right: 5px;
  }
`;
const OrderSummary = styled(Centered)`
  /* color: grey; */
  ${text12300}

  justify-content: flex-start;
  svg {
    font-size: 3px;
    margin: 0px 4px;
  }
  margin-bottom: 10px;
`;
const OrderElapsed = styled(Text12300)`
  /* font-weight: 400; */
`;
const OrderCount = styled(Text12300)`
  /* font-weight: 400; */
`;

const GoodContainer = styled(CenteredByColumnLeft)`
  width: calc(100% - 20px);
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  padding: 15px 0px;
  margin: 0 10px;
  /* background-color: pink; */
  > svg {
    margin: 0px 3px 1px 3px;
    font-size: 8px;
  }
  padding-right: 28px;
  padding-left: 50px;
  min-height: 70px;
  border-bottom: 1px solid ${BorderGrey};
`;

const Good = ({ good, addDish }) => (
  <GoodContainer>
    <ImageOverlay></ImageOverlay>
    <DishImage imageUrl={good?.dish?.imageURL}></DishImage>
    <Dish>
      <DishName>
        {/* {good?.quantity - (good?.cancelQuantity || 0)}
            <FontAwesomeIcon icon={faTimes} /> */}
        {good?.dish?.name}
      </DishName>
      <Selections>
        {good?.selections
          .filter((s) => s.selectedItems.length > 0)
          .map((s) => (
            <Selection>
              {/* <SelectionName>{s.dishSelection.name}</SelectionName> */}
              <SelectionItems>
                {s.selectedItems.map((i) => (
                  <SelectionItem>
                    <FontAwesomeIcon icon={faCircle} />
                    {i.item.name}
                  </SelectionItem>
                ))}
              </SelectionItems>
            </Selection>
          ))}
      </Selections>
    </Dish>
    <AddDish
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        addDish(good);
      }}
    >
      <FontAwesomeIcon icon={faPlusCircle} />
    </AddDish>
  </GoodContainer>
);

interface OrderListProps {
  height: number;
  width: number;
  orders: any;
  addDish?: (any) => void;
  addOrder?: (any) => void;
  gotoOrderId?: string;
}
export const OrderList: React.FC<OrderListProps> = ({
  orders,
  height,
  width,
  addDish,
  addOrder,
  gotoOrderId,
}: OrderListProps): ReactElement => {
  // const containerRef = useRef(null);
  const focusRef = useRef(null);
  useEffect(() => {
    // Scroll to the middle of the container
    if (focusRef.current) {
      // console.log("focusRef.current:", focusRef.current);
      focusRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
  }, []);
  LOGGING && console.log("Container renderiing with: ", { height, width });
  return (
    <Container height={height} width={width}>
      {orders.map((o) => (
        <Order ref={String(o._id) === String(gotoOrderId) ? focusRef : null}>
          <OrderDate>{moment(o.window.start).format("ddd MM/DD/YY")}</OrderDate>
          <OrderSummary>
            {/* <OrderElapsed>{`${getItemNumberInCart(o)} items, ${moment(
              o.window.start
            ).fromNow()}.`}</OrderElapsed> */}

            <OrderElapsed>{moment(o.window.start).fromNow()}</OrderElapsed>
            <FontAwesomeIcon icon={faCircle} />
            <OrderCount>{getItemNumberInCart(o)} items</OrderCount>
          </OrderSummary>
          <AddOrder
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              addOrder(o);
            }}
          >
            Add all
          </AddOrder>
          {/* <OrderDate>{o._id}</OrderDate> */}
          <OrderGoods>
            {o?.goods.map((g) =>
              g.quantity > (g?.cancelQuantity || 0) ? (
                <>
                  {Array(g?.quantity - (g?.cancelQuantity || 0))
                    .fill(true)
                    .map((item, index) => (
                      <Good
                        key={index}
                        good={{ ...g, quantity: 1 }}
                        addDish={addDish}
                      />
                    ))}
                </>
              ) : null
            )}
          </OrderGoods>
        </Order>
      ))}
      <OrderPlaceHolder>hi</OrderPlaceHolder>
    </Container>
  );
};
