import moment from 'moment-timezone';
import { SET_REFERRAL_CODE, CLEAN_REFERRAL_CODE } from '../actionTypes';

const referralCode = (state = { payLoad: null, loadedAt: moment() }, action) => {
  switch (action.type) {
    case SET_REFERRAL_CODE:
      return { payLoad: action.referralCode, loadedAt: moment() };
    case CLEAN_REFERRAL_CODE:
      return { payLoad: null, loadedAt: moment() };
    default:
      return state;
  }
};

export default referralCode;
