import { setCurrentUser, loadOrders } from "./";
import { LOGGING } from "../../constants";
import { apiCall } from "../api";

export const getDriverInfo = () => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    const { user } = currentUser;
    return apiCall(
      "get",
      `/users/${user._id}`,
      {},
      getState().currentUser
    ).then((updatedUser) => {
      LOGGING && console.log("getDriverInfo got from server:", updatedUser);
      dispatch(setCurrentUser(updatedUser));
    });
  };
};
export const askDriverAvailability = (
  driverId,
  mealID,
  restaurant,
  pickUpTime
) => {
  LOGGING &&
    console.log(
      "askDriverAvailability is called with: ",
      driverId,
      mealID,
      restaurant,
      pickUpTime
    );
  return (dispatch, getState) => {
    return apiCall(
      "post",
      `/drivers/askAvailability/${driverId}`,
      {
        mealID,
        restaurant,
        pickUpTime,
      },
      getState().currentUser
    );
  };
};

export const acceptDriverAvailability = (
  driverId,
  mealID,
  restaurant,
  pickUpTime
) => {
  LOGGING &&
    console.log(
      "acceptDriverAvailability is called with: ",
      driverId,
      mealID,
      restaurant,
      pickUpTime
    );
  return (dispatch, getState) => {
    return apiCall(
      "post",
      `/drivers/acceptAvailabilityByAdmin/${driverId}`,
      {
        mealID,
        restaurant,
        pickUpTime,
      },
      getState().currentUser
    );
  };
};

export const confirmDriverAvailability = (response) => {
  LOGGING &&
    console.log("confirmDriverAvailability is called with: ", response);
  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall(
      "post",
      `/drivers/confirmAvailability/${currentUser.user._id}`,
      {
        response,
      },
      getState().currentUser
    ).then((driverInfo) => {
      dispatch(setCurrentUser({ ...currentUser.user, driverInfo }));
    });
  };
};

export const notifyPickMeal = (restaurantId, driverPickTimes) => {
  LOGGING &&
    console.log("notifyPickMeal is called with", restaurantId, driverPickTimes);
  return (dispatch, getState) => {
    return apiCall(
      "post",
      `/drivers/notifyPickMeal`,
      {
        restaurantId,
        driverPickTimes,
      },
      getState().currentUser
    );
  };
};

export const cancelDriver = (driverId, orderIds) => {
  LOGGING && console.log("cancelDriver is called with", { driverId, orderIds });
  return (dispatch, getState) => {
    return apiCall(
      "post",
      `/drivers/${driverId}`,
      {
        orderIds,
      },
      getState().currentUser
    ).then(() => {
      const { orders } = getState();
      const updatedOrders = orders.map((o) =>
        orderIds.includes(o._id)
          ? {
              ...o,
              driver: null,
              route: null,
              stop: null,
              etaAtRestaurant: null,
              etaAtDispatch: null,
            }
          : { ...o }
      );
      dispatch(loadOrders(updatedOrders));
    });
  };
};

export const calculateDriverRoutes = (windowStartTime, driverId) => {
  LOGGING &&
    console.log(
      "calculateDriverRoutes is called with ",
      windowStartTime,
      driverId
    );
  return (dispatch, getState) => {
    return apiCall(
      "post",
      `/driver/calculateRoutes`,
      {
        windowStartTime,
        driverId,
      },
      getState().currentUser
    );
  };
};

export const swapDrivers = (driver1, driver2, orders, options) => {
  LOGGING &&
    console.log("swapDrivers is called with ", { driver1, driver2, orders });
  return async (dispatch, getState) => {
    try {
      await await apiCall(
        "post",
        `/drivers/swap`,
        {
          driver1,
          driver2,
          orders,
        },
        getState().currentUser
      );
      options?.success?.();
    } catch (err) {
      LOGGING && console.log("swapDrivers got err", err);
      options?.error?.(err);
    }
  };
};
