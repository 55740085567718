import { ReactElement } from 'react';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components/Button';
import styled from 'styled-components';
import { ListTitle } from './Shared';

type SimpleNavBarProps = {
  title?: string;
  hideBackButton?: boolean;
  actionLabel?: string;
  actionCallback?: () => void;
};

const NavBar = styled.nav`
  width: 100%;
  padding: 24px;
`;

const InvisibleDivPlaceholder = styled.div`
  height: 1px;
  width: 1px;
  background-color: transparent;
`;

const Title = styled.div`
  font-family: 'Theano Didot', serif;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0.6px;
`;

export const SimpleNavBar: React.FC<SimpleNavBarProps> = ({
  title,
  hideBackButton,
  actionLabel,
  actionCallback,
}): ReactElement => {
  const history = useHistory();
  return (
    <NavBar>
      <ListTitle>
        {hideBackButton === true ? (
          <div />
        ) : (
          <button type="button" onClick={history.goBack}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        )}
        <span>{title}</span>
        {actionCallback != null ? (
          <Button type="button" onClick={actionCallback}>
            {actionLabel}
          </Button>
        ) : (
          <InvisibleDivPlaceholder />
        )}
      </ListTitle>
    </NavBar>
  );
};
