import { ReactElement, useCallback, useState } from 'react';
import { useAsync } from 'react-async';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { GetMealById } from 'src/api/meals';
import { GetRestaurantById } from 'src/api/restaurants';
import { RootState } from 'src/store';
import { fetchDishesForRestaurant } from 'src/store/actions';
import {
  loadRestaurant,
  loadRestaurantDishes,
  loadSelectedDishes,
} from 'src/store/actions/mealMenu';
import { Loading } from '..';
import { MealMenuAdminContainer } from './MealMenuAdminContainer';

type MealMenuAdminDataContainerProps = {
  restaurantId: string;
  mealId: string;
};

export const MealMenuAdminDataContainer: React.FC<MealMenuAdminDataContainerProps> = ({
  restaurantId,
  mealId,
}): ReactElement => {
  const store = useStore();
  const dispatch = useDispatch();

  const fetch = useCallback(
    async ({ restaurantId, mealId }) => {
      const restaurantResponse = await GetRestaurantById(restaurantId);
      const mealResponse = await GetMealById(mealId);
      const dishesResponse = await fetchDishesForRestaurant(restaurantId)(null, store.getState);

      dispatch(loadRestaurant(restaurantResponse.restaurant));
      dispatch(loadRestaurantDishes(dishesResponse));
      dispatch(loadSelectedDishes(mealResponse.meal.dishes));
    },
    [dispatch, store.getState]
  );

  useAsync({ promiseFn: fetch, restaurantId, mealId });
  const { restaurantDishes, selectedDishes, restaurant } = useSelector(
    (state: RootState) => state.mealMenu
  );

  if (restaurantDishes == null || selectedDishes == null || restaurant == null) return <Loading />;

  return (
    <MealMenuAdminContainer
      restaurantDishes={restaurantDishes}
      selectedDishes={selectedDishes}
      restaurant={restaurant}
    />
  );
};
