import { text } from "stream/consumers";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  faPaperPlane as faSend,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCommentDots as faChat,
  faTimes,
  //   faHeadset as faChat,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { set } from "lodash";
import moment from "moment-timezone";
import { useSelector, useDispatch, useStore } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import { RootState } from "src/store";
import {
  sendMessage,
  checkResponse,
  readMessages,
  markAsRead,
} from "src/store/actions";
import styled from "styled-components";
import {
  BackgroundGrey,
  Blue,
  BorderGrey,
  card,
  Centered,
  centered,
  centeredByColumn,
  CenteredByColumn,
  CenteredByColumnLeft,
  Gold,
  NavHeight,
  text12300,
  TextGrey,
  Yellow,
} from "./../../Shared";
import ScreenshotButton from "./screenshot";
import { LOGGING } from "../..";

const width = 60;
const RESPONSE_CHECK_FREQUENCY_IN_SECONDS = 10;
const RESPONSE_CHECK_TIMEOUT_IN_MINUTES = 1;

const LiveChatContainer = styled.a.attrs((props) => ({
  hasShadow: props.theme.hasShadow,
}))`
  /* margin: 10px 0px; */
  ${centered}
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 200;
  width: ${width}px;
  height: ${width}px;
  /* background-color: ${Yellow}; */
  border-radius: 50%;
  box-shadow: ${(props) =>
    props.theme.hasShadow ? " 0 8px 32px 0 rgba(28, 29, 33, 0.8)" : "none"};
  flex-direction: column;
  span {
    font-size: 8px;
    letter-spacing: 1px;
    font-weight: 500;
    /* position: absolute; */
    top: 6px;
  }
  svg {
    font-size: 23px;
    color: black;
    /* margin-top: 5px; */
  }
  /* @media only screen and (min-width: 800px) {
    display: none;
  } */
`;
export interface LiveChatProps {
  hasShadow: boolean;
}
const FoldedContainer = styled.button`
  ${centeredByColumn}
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: ${width}px;
  height: ${width}px;
  z-index: 100;
  border-radius: 50%;
  background-color: ${Yellow};
  box-shadow: 0 8px 32px 0 rgba(28, 29, 33, 0.8);
  span {
    font-size: 13px;
    font-weight: 500;
    margin-top: 3px;
  }
`;
const ExpandedContainer = styled(Centered)<{
  disableScroll?: boolean;
  isAdmin?: boolean;
}>`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  /* height: 100px; */
  background-color: white;
  z-index: 103;
  box-shadow: 0 8px 32px 0 rgba(28, 29, 33, 0.8);
  padding: 20px;
  display: ${(props) => (props.isAdmin ? "none" : "flex")};
  textarea {
    background-color: ${BackgroundGrey};
    text-align: left;
    flex-grow: 1;
    border: none;
    font-size: 16px;
    line-height: 20px;
    padding: 20px 60px 0px 20px;
    text-transform: none;
    margin: 0px;
    border-radius: 25px;
    flex-grow: 1;
    align-self: stretch;
    position: relative;
    box-sizing: border-box;
    &::placeholder {
      ${text12300}
      color: ${TextGrey};
      font-weight: 500;
      font-size: 16px;
      text-transform: none;
      line-height: 20px;
    }
  }
  /* textarea {
    // variable height textarea with max 4 lines
    background-color: ${BackgroundGrey};
    font-weight: 500;
    text-align: left;
    flex-grow: 1;
    border-bottom: none;
    font-size: 16px;
    padding: 0px 60px 0px 20px;
    text-transform: none;
    margin: 0px;
    border-radius: 25px;
    flex-grow: 1;
    align-self: stretch;
    resize: none;
    max-height: 80px;
    overflow: hidden;
    &::placeholder {
      text-transform: none;
      color: ${TextGrey};
    }

  } */

  /* svg {
        font-size: 20px;
        margin-left: 10px;
        position: absolute;
        right: 30px;
    } */
`;
const ActionIcon = styled.button`
  ${centered}
  position: absolute;
  right: 30px;
  bottom: 30px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #218aff;
  /* background-color: green; */
  svg {
    font-size: 20px;
    margin: 0px;
    /* color: white; */
  }
  &:disabled {
    svg {
      color: white;
    }
  }
`;
const FoldButton = styled.button`
  position: absolute;
  bottom: 120px;
  left: 12px;
  background-color: transparent;
  border: none;
  width: 50px;
  height: 50px;
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  margin: 0px;
  border-radius: 50%;
  border: 2px solid black;
  svg {
    font-size: 20px;
    /* color: ${TextGrey}; */
  }
`;
const OverlayContainer = styled(Centered)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 102;
`;
const TopContainer = styled.div``;
const Overlay = ({ onFold }): ReactElement => {
  return <OverlayContainer onClick={onFold}></OverlayContainer>;
};
const ResponseContainer = styled(Centered)<{ fromMe?: boolean }>`
  width: 100%;
  margin-top: 10px;
  justify-content: ${(props) => (props.fromMe ? "flex-end" : "flex-start")};
  span {
    background-color: ${(props) => (props.fromMe ? "#218aff" : "#d8d8d8")};
    ${text12300}
    font-size: 15px;
    font-weight: 600;
    color: ${(props) => (props.fromMe ? "white" : "black")};
    text-align: ${(props) => (props.fromMe ? "right" : "left")};
    padding: 15px;
    border-radius: 15px;
    max-width: 70%;
    line-height: 1.5;
    position: relative;
  }
`;
const UnreadSignal = styled.div`
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #218aff;
  top: 34px;
  left: 13px;
`;
const UnreadContainer = styled(CenteredByColumnLeft)<{ fromMe?: boolean }>`
  width: 100%;
  align-items: ${(props) => (props.fromMe ? "flex-end" : "flex-start")};
  justify-content: flex-start;
  align-self: stretch;
  padding-top: 0px;
  > label {
    ${text12300}
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 3px;
    position: relative;
  }
  padding-left: ${(props) => (props.fromMe ? "0px" : "15px")};
  > span {
    ${text12300}
    font-weight: 500;
    font-size: 18px;
    color: ${TextGrey};
    line-height: 1.2;
    background-color: ${(props) => (props.fromMe ? "#218aff" : "#d8d8d8")};
    color: ${(props) => (props.fromMe ? "white" : "black")};
    padding: 10px 15px;
    border-radius: 15px;
    // only show one line, overflow shows ellipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 30px);
  }
  svg {
    position: absolute;
    left: ${(props) => (props.fromMe ? "auto" : "35px")};
    right: ${(props) => (props.fromMe ? "18px" : "auto")};
    top: 30px;
    color: ${TextGrey};
    font-size: 40px;
  }
`;
const HistoryContainer = styled(CenteredByColumn)<{ bottomBarHeight?: Number }>`
  position: fixed;
  max-height: 30vh;
  min-height: 50px;
  bottom: ${(props) => `calc(${props.bottomBarHeight}px)`};
  width: 100%;
  left: 0px;
  padding: 0px 10px;
  background-color: white;
  /* border-bottom: 1px solid red; */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;
const Time = styled(Centered)<{ fromMe?: boolean }>`
  align-self: stretch;
  width: 100%;
  position: absolute;
  bottom: 20px;
  left: 0px;
  justify-content: ${(props) => (props.fromMe ? "flex-end" : "flex-start")};
  padding: 0px 20px;
  padding-left: ${(props) => (props.fromMe ? "0px" : "40px")};
  span,
  label {
    ${text12300};
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    color: ${TextGrey};
  }
  label {
    font-weight: 600;
    margin-right: 5px;
    margin-bottom: 0px;
  }
`;

const HistoryContent = styled(CenteredByColumn)`
  justify-content: flex-start;
  flex-grow: 1;
  align-self: stretch;
  padding-top: 50px;
  overflow-y: hidden;
`;
const ScrollableContainer = styled(CenteredByColumn)`
  width: 100%;
  overflow-y: auto;
  position: relative;
  justify-content: flex-start;
`;
const TopBar = styled(Centered)<{ keyboardHeight?: Number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 10px;
  ${text12300}
  font-weight: 600;
  font-size: 15px;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid ${BorderGrey};
  text-align: center;
  button {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: transparent;
    border: none;
    svg {
      font-size: 20px;
      color: ${TextGrey};
    }
  }
`;
const BottomActionBar = styled(Centered)<{ keyboardHeight?: Number }>`
  left: 0px;
  bottom: 0px;
  padding: 0px;
  flex-grow: 1;
  align-self: stretch;
  position: relative;
`;
const History = ({
  records,
  scrollRef,
  keyboardHeight,
  bottomBarHeight,
  onClose,
}) => {
  LOGGING && console.log("History bottomBarHeight: ", bottomBarHeight);
  LOGGING && console.log("History keyboardHeight: ", keyboardHeight);
  return (
    <HistoryContainer bottomBarHeight={bottomBarHeight || 0}>
      <HistoryContent>
        <TopBar>
          Ask Foodie
          <button onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </TopBar>
        <ScrollableContainer ref={scrollRef}>
          {records.map((r) => (
            <ResponseContainer fromMe={r.fromMe}>
              <span>{r.text}</span>
            </ResponseContainer>
          ))}
        </ScrollableContainer>
      </HistoryContent>
    </HistoryContainer>
  );
};
export const ExpandedChatEntry = ({ onSetExpand, expanded }) => {
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const [message, setMessage] = useState("");
  const [records, setRecords] = useState([]);
  const [messageId, setMessageId] = useState(null);
  const [disableScroll, setDisableScroll] = useState(false);
  const [unread, setUnread] = useState(null);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [keyboardShown, setKeyboardShown] = useState(false);
  const [bottomActionBarHeight, setBottomActionBarHeight] = useState(0);
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  const scrollRef = useRef(null);
  const inputRef = useRef(null);

  const dispatch = useDispatch();
  const store = useStore();
  const addToRecords = (text, fromMe) => {
    setRecords((prevRecords) => [...prevRecords, { text, fromMe }]);
  };
  const checkUntilResponse = () => {
    // console.log("checking for new messages", moment().format("HH:mm:ss"));
    checkResponse(messageId)(dispatch, store.getState).then((response) => {
      if (response) {
        LOGGING && console.log("got response! expanded:", expanded);
        LOGGING && console.log({ response });
        // setRecords([...records, { text: response, fromMe: false }]);
        addToRecords(response, false);
        setMessageId(null);
        setUnread({ text: response, fromMe: false, time: moment().valueOf() });
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        // onSetExpand(true);
      } else {
        console.log("no response yet, keep checking: ", messageId);
        if (messageId) {
          setTimeout(() => {
            checkUntilResponse();
          }, RESPONSE_CHECK_FREQUENCY_IN_SECONDS * 1000);
        }
      }
    });
  };
  const handleKeyboardShown = () => {
    setKeyboardShown(true);
    onSetExpand(true);
    const windowHeight = window.innerHeight;
    const screenHeight = window.screen.height;
    const viewportHeight = window.visualViewport.height;
    LOGGING && console.log("keyboard shown");
    // // // print the 3 heights line by line
    LOGGING && console.log("windowHeight", windowHeight);
    LOGGING && console.log("screenHeight", screenHeight);
    LOGGING && console.log("viewportHeight", viewportHeight);
  };
  const handleKeyboardHidden = () => {
    setKeyboardShown(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setKeyboardHeight(window.innerHeight - window.visualViewport.height);
    };
    window.visualViewport.addEventListener("resize", handleResize);

    return () => {
      window.visualViewport.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (messageId) {
      checkUntilResponse();
    }
  }, [messageId]);

  useEffect(() => {
    const textarea = inputRef.current;
    LOGGING &&
      console.log(
        "typing textarea",
        textarea.clientHeight,
        textarea.scrollHeight,
        textarea.scrollTop,
        textarea.innerHeight
      );
    if (textarea) {
      textarea.style.height = "auto";
      const scrollHeight = textarea.scrollHeight;
      const maxHeight = 4 * 20; // 4 lines * line-height (20px)
      textarea.style.height = `${Math.min(scrollHeight, maxHeight)}px`;
      LOGGING && console.log("setBottomActionBarHeight ", scrollHeight);
      setBottomActionBarHeight(Math.min(scrollHeight, maxHeight) + 40);
      if (scrollHeight > maxHeight) {
        textarea.scrollTop = scrollHeight;
      }
    }
  }, [message]);

  const handleScrollToBottom = (ref) => {
    ref.current.scrollTop = ref.current.scrollHeight;
  };

  useEffect(() => {
    if (scrollRef.current) {
      handleScrollToBottom(scrollRef);
    }
  }, [records, bottomActionBarHeight, expanded]);

  useEffect(() => {
    readMessages()(dispatch, store.getState).then((messages) => {
      LOGGING && console.log("readMessages got:", messages);
      setRecords(
        messages.map((m) => ({
          text: m.text,
          fromMe: m.isFromUser,
          _id: m._id,
        }))
      );
      const unreadMessages = messages.filter(
        (m) => !m.readTime && !m.isFromUser
      );
      LOGGING && console.log("unreadMessages got:", unreadMessages);
      if (unreadMessages.length > 0) {
        const { text, fromMe, sendTime, _id } =
          unreadMessages[unreadMessages.length - 1];
        setUnread({ text, fromMe, time: sendTime, _id });
      }
    });
  }, []);
  const handleSend = async (e) => {
    e.preventDefault();
    if (message.trim().length > 0) {
      setSendingMessage(true);
      sendMessage(message)(dispatch, store.getState).then(({ _id }) => {
        inputRef.current.focus();
        setMessageId(_id);
        setSendingMessage(false);
        // setTimeout(() => {
        //   onSetExpand(false);
        //   setUnread({ text: message, fromMe: true, time: moment().valueOf(), _id });
        // }, 300);
        setTimeout(() => {
          setMessageId(null);
        }, RESPONSE_CHECK_TIMEOUT_IN_MINUTES * 60 * 1000);
      });
      setMessage("");
      setRecords([...records, { text: message, fromMe: true }]);
      LOGGING &&
        console.log(
          " time to scroll history to bottom scrollRef.current",
          scrollRef.current.scrollHeight,
          scrollRef.current.scrollTop
        );
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };
  LOGGING && console.log("ChatEntry rendering with: ", { unread, records });
  let time = null;
  if (unread?.time) {
    const unreadTime = moment(unread.time);
    const now = moment();
    if (unreadTime.isSame(now, "day")) {
      time = unreadTime.format("h:mmA");
    } else if (unreadTime.isSame(now.subtract(1, "day"), "day")) {
      time = "Yesterday " + unreadTime.format("h:mmA");
    } else {
      time = unreadTime.format("ddd h:mmA");
    }
  }

  LOGGING &&
    console.log("ExpandedChatEntry rendering with ", unread, records, expanded);
  return (
    <ExpandedContainer
      disableScroll={disableScroll}
      isAdmin={currentUser.user.isAdmin}
    >
      {expanded ? (
        <History
          records={records}
          scrollRef={scrollRef}
          keyboardHeight={keyboardHeight}
          bottomBarHeight={bottomActionBarHeight}
          onClose={onSetExpand.bind(this, false)}
        />
      ) : null}
      {unread && !expanded ? (
        <UnreadContainer
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onSetExpand(true);
            setUnread(null);
            const unreadMessageIds = records
              .filter((r) => !r.readTime)
              .map((r) => r._id);
            markAsRead(unreadMessageIds)(dispatch, store.getState);
          }}
          fromMe={unread.fromMe}
        >
          {unread.fromMe ? null : <UnreadSignal></UnreadSignal>}
          <span>{unread.text}</span>
        </UnreadContainer>
      ) : (
        <>
          <textarea
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            ref={inputRef}
            value={message}
            placeholder="Type a message..."
            onFocus={onSetExpand.bind(this, true)}
          />
          <ActionIcon
            onClick={handleSend}
            disabled={message.trim().length === 0 || sendingMessage}
          >
            <FontAwesomeIcon icon={faSend} />
          </ActionIcon>
        </>
      )}
    </ExpandedContainer>
  );
};

export const FoldedChatEntry = ({ onExpand }) => (
  <FoldedContainer onClick={onExpand}>
    <FontAwesomeIcon icon={faChat} />
    <span>24/7</span>
  </FoldedContainer>
);

export const Chat = (): ReactElement => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      LOGGING && console.log("toggleExpanded");
      setExpanded((prevExpanded) => !prevExpanded);
    },
    [expanded]
  );
  return (
    <TopContainer>
      <ExpandedChatEntry onSetExpand={setExpanded} expanded={expanded} />
      {expanded ? <Overlay onFold={toggleExpanded} /> : null}
    </TopContainer>
  );
};
