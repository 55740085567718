import { LOAD_TRANSIENT_MESSAGE } from '../actionTypes';

const loadTransientMessage = (message, faIcon) => {
  if (message == null) {
    return {
      type: LOAD_TRANSIENT_MESSAGE,
      transientMessage: null,
    };
  }

  return {
    type: LOAD_TRANSIENT_MESSAGE,
    transientMessage: {
      message: message,
      icon: faIcon,
    },
  };
};

export const setTransientMessage = (dispatch, message, faIcon) => {
  dispatch(loadTransientMessage(message, faIcon));
};

export const clearTransientMessage = (dispatch) => {
  dispatch(loadTransientMessage());
};
