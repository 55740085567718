import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { LOGGING } from "src/constants";
import { RootState } from "src/store";
import styled from "styled-components";
import { CartSummary } from "./CartSummary";
import { Contact } from "./Contact";
import { Delivery } from "./Delivery";
import { Fees } from "./Fees";
import { PayButton } from "./PayButton";
import { Payment } from "./Payment";
import { Tip } from "./Tip";
import { Total } from "./Total";
import {
  CenteredByColumn,
  Centered,
  NavHeight,
  BorderGrey,
} from "../../Shared";
import { BreakPoint } from "../mealMenu/MealHeader";
export const LeftPanel = styled(CenteredByColumn)<{ isEditing?: boolean }>`
  width: ${(props) => (props.isEditing ? "auto" : "50%")};
  padding: 20px;
  justify-content: flex-start;
  align-self: stretch;
  border: 1px solid ${BorderGrey};
  position: relative;
  @media screen and (max-width: ${BreakPoint}px) {
    flex-grow: 1;
    width: auto;
    align-self: stretch;
    margin: 0px 0px 20px 0px;
    border: none;
    padding: 20px 10px 20px 10px;
    /* border-bottom: 1px solid ${BorderGrey}; */
    box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.1);
  }
`;
export const RightPanel = styled(LeftPanel)`
  border: none;
  padding: 0px;
  flex-grow: 1;
  margin-left: 20px;
  @media screen and (max-width: ${BreakPoint}px) {
    width: 100%;
    /* flex-grow: 1; */
    margin: 0px;
    padding: 0px 10px ${NavHeight + 20}px 10px;
  }
`;
export const CheckoutContainer = styled(Centered)`
  // full page
  width: 100vw;
  background-color: white;
  padding: 0 20px;
  box-sizing: border-box;
  padding: ${NavHeight + 20}px 70px;
  @media screen and (max-width: ${BreakPoint}px) {
    flex-direction: column;
    padding: ${NavHeight}px 0px;
  }
`;
export const PageCheckout = () => {
  const cart = useSelector((state: RootState) => state.cart.payLoad);
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const [saveCard, setSaveCard] = useState<boolean>(true);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [showCardAction, setShowCardAction] = useState<boolean>(false);
  const [error, setError] = useState({});
  const [paypalInfo, setPaypalInfo] = useState(null);
  const [payPalOrderAddressConfirmed, setPayPalOrderAddressConfirmed] =
    useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<string>("card");
  const [existingCard, setExistingCard] = useState(null);
  const [newCard, setNewCard] = useState({
    number: "",
    cvc: "",
    exp_month: "",
    exp_year: "",
  });
  const [cardType, setCardType] = useState(null);
  const [deliveryInfo, setDeliveryInfo] = useState(null);
  const [canMakeGroupOrder, setCanMakeGroupOrder] = useState(false);
  const [showOrderConfirmation, setShowOrderConfirmation] = useState(false);
  useEffect(() => {
    if (currentUser?.user) {
      const { firstName, lastName, email, phone, deliveryInfo, stripeInfo } =
        currentUser.user;

      setExistingCard(stripeInfo?.card);
      setName(`${firstName} ${lastName}`);
      setEmail(email);
      setPhone(phone);
      if (deliveryInfo) {
        const {
          address,
          address_room_info,
          address_supported_for_delivery,
          address_validated,
        } = deliveryInfo;
      }
    }
  }, [currentUser]);

  return (
    <CheckoutContainer>
      <LeftPanel>
        <CartSummary />
        <Contact />
        <Delivery />
        <Payment />
      </LeftPanel>
      <RightPanel>
        {/* <PayButton onSubmit={}/> */}
        <Fees />
        <Tip />
        <Total />
      </RightPanel>
    </CheckoutContainer>
  );
};
