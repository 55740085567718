import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { BreakPoint } from "../mealMenu/MealHeader";
const moveUpOut = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
`;
const CountWrapper = styled.div`
  display: inline-block;
  perspective: 1000px;
  position: relative;
  width: 18px;
  height: 18px;
  overflow: hidden;
  align-self: auto;
  flex-shrink: 0;
  flex-grow: 0;
  @media screen and (max-width: ${BreakPoint}px) {
    width: 25px;
    height: 25px;
  }
`;

const moveUpIn = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const Count = styled.label<{
  animate: boolean;
  animationType: "in" | "out";
}>`
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: green;
  color: white;
  font-size: 10px;
  line-height: 18px;
  /* margin-left: 5px; */
  text-align: center;
  border-radius: 50%;
  transform-origin: center bottom;
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 500 !important;
  letter-spacing: 0.6px !important;

  ${({ animate, animationType }) =>
    animate &&
    animationType === "out" &&
    css`
      animation: ${moveUpOut} 0.2s ease;
    `}
  ${({ animate, animationType }) =>
    animate &&
    animationType === "in" &&
    css`
      animation: ${moveUpIn} 0.2s ease;
    `}
    
  @media screen and (max-width: ${BreakPoint}px) {
    font-size: 16px;
    width: 25px;
    height: 25px;
    line-height: 25px;
  }
`;

export const Counter = ({ number }) => {
  const [prevNumber, setPrevNumber] = useState<number | null>(null);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (prevNumber !== null && number !== prevNumber) {
      setAnimate(true);
      setTimeout(() => {
        setAnimate(false);
        setPrevNumber(number);
      }, 500); // Duration of the animation
    } else {
      setPrevNumber(number);
    }
  }, [number]);

  return (
    <CountWrapper>
      {prevNumber !== null && (
        <>
          <Count animate={animate} animationType="out">
            {prevNumber}
          </Count>
          <Count animate={animate} animationType="in">
            {number}
          </Count>
        </>
      )}
    </CountWrapper>
  );
};
