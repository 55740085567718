import React, { useEffect, useState } from "react";
import { faBolt } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { current } from "immer";
import { min, set } from "lodash";
import moment from "moment-timezone";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useStore, useSelector } from "react-redux";
import { MealVisited } from "src/api/meals";
import { Divider } from "src/components/Divider";
import { RootState } from "src/store";
import { placeOrderFromDraftedCart } from "src/store/actions";
import { draftCart, readFutureOrdersByCustomerByMeal } from "src/store/actions";
import styled from "styled-components";
import { LOGGING, PopUp } from "../..";
import { ButtonClose } from "../../referrals/MyReferralCodeEventNonMember";
import {
  Centered,
  CenteredByColumn,
  Peach,
  actionGrey,
  PinkPeach,
  BackgroundGrey,
  BorderGrey,
  LightShadow,
  TextGrey,
  CenteredByColumnLeft,
  textTitle,
  text12300,
  card,
  TextTagline,
  TextTitle,
  Text12300,
  ActionBlack,
  ActionUnderline,
} from "../../Shared";
const Container = styled(Centered)<{ isEmpty?: boolean }>`
  background-color: ${BackgroundGrey};
  width: 100%;

  align-items: flex-start;
  justify-content: flex-start;
  padding-top: ${(props) => (props.isEmpty ? "0px" : "45px")};
  padding-left: 20px;
  padding-bottom: ${(props) => (props.isEmpty ? "0px" : "20px")};
  position: relative;
  overflow-x: hidden;
  @media (min-width: 500px) {
    margin-bottom: ${(props) => (props.isEmpty ? "0px" : "20px")};
  }
  @media (min-width: 800px) {
    padding-left: 13vw;
  }
  @media (min-width: 1200px) {
    padding-left: calc(9vw + 20px);
  }
`;
const CartList = styled(Centered)`
  width: 100%;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow-x: auto;
  padding-right: 10px;
`;
const DraftButton = styled.button`
  ${actionGrey}
  opacity: 1;
  background-color: ${PinkPeach};
  box-shadow: ${LightShadow};
  border: 1px solid ${BorderGrey};
  border-radius: 20px;
  position: relative;
  &:disabled {
    color: ${TextGrey};
    cursor: not-allowed;
  }
`;

// a small rotating 3/4 circle
const RotatingCircle = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid black;
  // the rotation should look like it's accelerating
  animation: rotate 1.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  clip-path: polygon(0 0, 100% 0, 100% 75%, 0 75%);
  margin-left: 10px;
  position: absolute;
  left: 100%;
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const CartContainer = styled(Centered)`
  margin-right: 10px;
  ${card}
  justify-content: flex-start;
  position: relative;
  align-self: stretch;
  border: 1px solid ${BorderGrey};
  @media screen and (min-width: 800px) {
    margin-right: 20px;
  }
`;
const imageDimension = 90;
const CartImage = styled.img`
  width: ${imageDimension}px;
  align-self: stretch;
  object-fit: cover;
  @media screen and (min-width: 800px) {
    width: 150px;
  }
`;
const CartText = styled(CenteredByColumnLeft)`
  width: 130px;
  padding: 10px;
  padding-bottom: 80px;
  position: relative;
  justify-content: flex-start;
  align-self: stretch;
  @media screen and (min-width: 800px) {
    width: 150px;
  }
`;
const ButtonDetails = styled(ActionUnderline)`
  position: absolute;
  bottom: 50px;
  padding: 0px;
  line-height: 2;
  font-size: 13px;
`;
const ButtonAccept = styled(ActionBlack)`
  position: absolute;
  /* right: 10px; */
  bottom: 10px;
  height: 30px;
  width: calc(100% - 20px);
  font-size: 14px;
  white-space: nowrap;
  padding: 0px;
  // when disabled use background color grey
  &:disabled {
    background-color: ${TextGrey};
  }
`;
const RestaurantName = styled(TextTitle)`
  font-size: 16px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

const DishList = styled(CenteredByColumnLeft)`
  width: 100%;
  // cap the height at 100px
`;
const Overlay = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 200;
`;
const Total = styled(Text12300)`
  /* width: 100%; */
  text-align: left;
  font-weight: 600;
  position: absolute;
  bottom: 50px;
  right: 10px;
  line-height: 2;
  font-size: 13px;
`;
const Dish = styled(Centered)`
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  span {
    // overflow elipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    ${text12300}
    text-align: left;
    line-height: 1.5;
    font-size: 13px;
    font-weight: 400;
  }
`;
const Header = styled(Centered)`
  text-transform: capitalize;
  position: absolute;
  top: 10px;
  left: 20px;

  span {
    svg {
      margin-right: 5px;
    }
    ${text12300}
    padding: 0px 10px;
    line-height: 25px;
    height: 25px;
    background-color: ${PinkPeach};
    font-weight: 500;
    border-radius: 12px;
  }
  @media (min-width: 800px) {
    left: 13vw;
  }
  @media (min-width: 1200px) {
    left: calc(9vw + 20px);
  }
`;

const ToggleShowMore = styled.button`
  margin: 0px;
  padding: 0px;
  ${text12300}
  /* background-color: pink; */
    /* position: absolute;
    right: 10px;
    bottom: 70px; */
    text-transform: none;
  border-bottom: 1px solid black;
`;
const OrderTime = styled(CenteredByColumn)`
  ${textTitle}
  position: absolute;
  left: 0px;
  bottom: 0px;
  text-transform: none;
  color: white;
  line-height: 1.3;
  font-size: 13px;
  width: ${imageDimension}px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.25);
  justify-content: flex-end;
  padding: 0px 5px 10px 5px;
  height: 100%;
  @media screen and (min-width: 800px) {
    width: 150px;
  }
`;
const CartDetailContainer = styled(CenteredByColumnLeft)`
  ${card}
  padding: 40px 20px 80px 20px;
  width: calc(100vw - 40px);
  max-height: calc(100vh - 40px);
  z-index: 999;
  position: fixed;
  top: 20px;
  left: 20px;
  justify-content: flex-start;
  overflow-y: hidden;
  > button:last-child {
    width: calc(100% - 40px);
    bottom: 20px;
    height: 55px;
  }
  @media screen and (min-width: 800px) {
    /*  */
    width: 500px;
    left: calc(50vw - 250px);
  }
`;
const Fees = styled(CenteredByColumn)`
  align-self: stretch;
  flex-grow: 1;
  /* border-top: 1px solid ${BorderGrey}; */
  margin-top: 10px;
  padding-top: 10px;
  overflow-y: auto;
  justify-content: flex-start;
`;
const Fee = styled(Centered)`
  ${text12300}
  font-size: 14px;
  justify-content: flex-end;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 5px;
  line-height: 1.5;

  /* padding: 10px 0px; */
  /* background-color: ${Peach}; */
  /* line-height: 1.5; */
  /* height: 15px; */
  label {
    text-transform: capitalize;
    margin-right: 10px;
    text-align: left;
    /* background-color:  ${Peach}; */
    /* align-self: stretch; */
    flex-grow: 1;
    font-weight: 400;
    text-align: right;
  }
  span {
    font-weight: 400;
    width: 60px;
    text-align: right;
  }
`;
const Item = styled(Fee)`
  label {
    text-align: left;
  }
`;
const More = styled(Text12300)`
  font-size: 13px;
  font-weight: 400;
`;
const ButtonHide = styled.button`
  position: fixed;
  right: 20px;
  top: 20px;
  margin: 0px;
  padding: 0px;
  width: 40px;
  height: 40px;
  flex-grow: 0;
  align-self: auto;
  svg {
    font-size: 20px;
  }
  @media screen and (min-width: 800px) {
    right: calc(50vw - 250px);
  }
`;
const CartDetail = ({ cart, placeOrder, onHide }) => (
  <Overlay>
    <CartDetailContainer>
      <ButtonHide
        onClick={(e) => {
          e.preventDefault();
          onHide();
        }}
      >
        {" "}
        <FontAwesomeIcon icon={faTimesCircle} />
      </ButtonHide>
      <RestaurantName>{cart?.restaurant}</RestaurantName>
      <Fees>
        {cart?.goods?.map((good) =>
          // check good.quanity, print dish quantity times
          Array.from({ length: good.quantity }).map((_, index) => (
            <Item>
              <label>{good.dish.name}</label>
              <span>${good.price.toFixed(2)}</span>
            </Item>
          ))
        )}
        <Divider />
        <Fee>
          <label>sum</label>
          <span>${cart.sum.toFixed(2)}</span>
        </Fee>
        <Fee>
          <label>tax</label>
          <span>${cart.tax.toFixed(2)}</span>
        </Fee>
        <Fee>
          <label>service fee</label>
          <span>${cart.serviceFee.toFixed(2)}</span>
        </Fee>

        <Fee>
          <label>{`tip${
            cart.tipRate ? ` (${cart.tipRate * 100}%)` : ""
          }`}</label>
          <span>${cart.tips.toFixed(2)}</span>
        </Fee>
        <Fee>
          <label>total</label>
          <span>${cart.total.toFixed(2)}</span>
        </Fee>
      </Fees>
      <ButtonAccept
        onClick={(e) => {
          e.preventDefault();
          onHide();
          placeOrder(cart);
        }}
      >
        re-order
      </ButtonAccept>
    </CartDetailContainer>
  </Overlay>
);
const Cart = ({ cart, placeOrder, userName, placing }) => {
  const hasMore = cart?.goods?.length > 1;
  const goods =
    cart?.goods?.length > 2 ? cart?.goods?.slice(0, 1) : cart?.goods;
  const more = cart?.goods?.length - goods.length;
  const { lastTime } = cart;
  const [showDetail, setShowDetail] = useState(false);
  let dateString = "";
  // if windowStart is more than a month a go, dateString is "...months ago"
  // if windowStart is more than a week ago, dateString is "...weeks ago"
  // if windowStart is more than a day ago, dateString is "...days ago"
  const timeElapsed = moment(lastTime).fromNow();
  if (timeElapsed.includes("year")) {
    dateString = timeElapsed;
  } else if (timeElapsed.includes("month")) {
    // change month to "mo" to save space
    dateString = timeElapsed; //.replace("months", "mo");
  } else if (timeElapsed.includes("week")) {
    dateString = timeElapsed;
  } else if (timeElapsed.includes("day")) {
    dateString = timeElapsed;
  } else {
    dateString = moment(lastTime).format("MMM DD");
  }

  return (
    <CartContainer>
      {showDetail ? (
        <CartDetail
          cart={cart}
          placeOrder={placeOrder}
          onHide={setShowDetail.bind(this, false)}
        />
      ) : null}

      <CartImage src={cart?.goods?.[0]?.dish?.imageURL} />
      <OrderTime>
        <label>Ordered</label>
        <span>{dateString}</span>
      </OrderTime>
      <CartText>
        <RestaurantName>{cart?.restaurant}</RestaurantName>
        {/* cap dishlist height to only show two dishes, if there's more
        than two dishes, show the first two and a +n more */}
        <DishList>
          {goods?.map((good) => (
            <Dish>
              <span>
                {good.quantity} x {good.dish.name}
              </span>
            </Dish>
          ))}
          {hasMore && more > 0 ? (
            <More>{`${more} more item${more > 1 ? "s" : ""}`}</More>
          ) : null}
        </DishList>

        <Total>${cart.total.toFixed(2)}</Total>
        <ButtonDetails
          onClick={(e) => {
            e.preventDefault();
            setShowDetail(true);
            MealVisited(
              `draft show detail ${cart?.restaurant}`,
              userName,
              "",
              "",
              0
            );
          }}
        >
          details
        </ButtonDetails>
        <ButtonAccept
          disabled={placing}
          onClick={(e) => {
            e.preventDefault();
            placeOrder(cart);
          }}
        >
          {`${placing ? "placing..." : "re-order"}`}
        </ButtonAccept>
      </CartText>
    </CartContainer>
  );
};
export const FoodieDraft = ({ onSetHasDrafted }) => {
  const [drafting, setDrafting] = useState(false);
  // get currentUser
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const [day, setDay] = useState(null);
  const [meal, setMeal] = useState(null);
  const [carts, setCarts] = useState(null); // [dishes, restaurant, reason, total
  const [showMore, setShowMore] = useState([]);
  const [placing, setPlacing] = useState([]);
  const dispatch = useDispatch();

  const getState = useStore().getState;

  const onDraftCart = (mealTime) => {
    // e.preventDefault();
    setDrafting(true);
    LOGGING && console.log("onDraftCart clicked with", mealTime);
    draftCart(mealTime)(dispatch, getState).then((draftedCarts) => {
      LOGGING && console.log("draftCart got", mealTime, draftedCarts);
      onSetHasDrafted(draftedCarts.length > 0);
      const message = `${draftedCarts.length} drafts ${draftedCarts
        .map((cart) => cart.restaurant)
        .join(", ")}`;
      MealVisited(
        message,
        `${currentUser?.user?.firstName} ${currentUser?.user?.lastName}`,
        "",
        "",
        0
      );
      setCarts(draftedCarts);
      setShowMore(draftedCarts.map(() => false));
      setPlacing(draftedCarts.map(() => false));
      setDrafting(false);
    });
  };

  const onToggleShowMore = (index, e) => {
    e.preventDefault();
    setShowMore(showMore.map((show, i) => (i === index ? !show : show)));
  };
  const onPlaceOrder = (index, cart) => {
    setPlacing(placing.map((place, i) => (i === index ? true : place)));
    placeOrderFromDraftedCart(cart)(dispatch, getState)
      .then((placedOrder) => {
        LOGGING && console.log("placeOrderFromDraftedCart got", placedOrder);
        readFutureOrdersByCustomerByMeal()(dispatch, getState);
        toast.success("Order placed successfully");
        setPlacing(placing.map((place, i) => (i === index ? false : place)));
      })
      .catch((error) => {
        LOGGING && console.error("placeOrderFromDraftedCart error", error);
        toast.error(error.message);
        setPlacing(placing.map((place, i) => (i === index ? false : place)));
      });
  };

  useEffect(() => {
    LOGGING &&
      console.log(
        "FoodieDraft mounted with currentUser: ",
        currentUser?.user?.stripeInfo
      );
    if (
      !currentUser?.user?.membership?.isActive ||
      !currentUser?.user?.stripeInfo?.paymentMethodId
    ) {
      return;
    }
    const now = moment().tz("America/Los_Angeles");
    const hour = now.hour();
    const minute = now.minute();
    let date = null;
    if (hour < 10 || (hour === 10 && minute < 30)) {
      date = now.format("YYYY-MM-DD");
      setDay("today");
      setMeal("lunch");
      onDraftCart(`${date} lunch`);
    } else if (hour < 15 || (hour === 15 && minute < 30)) {
      date = now.format("YYYY-MM-DD");
      setDay("today");
      setMeal("early dinner");
      onDraftCart(`${date} earlyDinner`);
    } else if (hour < 16 || (hour === 16 && minute < 30)) {
      date = now.format("YYYY-MM-DD");
      setDay("today");
      setMeal("dinner");
      onDraftCart(`${date} dinner`);
    } else {
      date = now.add(1, "days").format("YYYY-MM-DD");
      setDay("tomorrow");
      setMeal("lunch");
      onDraftCart(`${date} lunch`);
    }
  }, []);

  LOGGING && console.log("FoodieDraft rendered with", { carts, drafting });
  return (
    <Container isEmpty={!carts?.length}>
      {/* {mealTimes.map((meal) => (
        <DraftButton disabled={drafting} onClick={onDraftCart.bind(this, meal)}>
          {drafting && <RotatingCircle />}
          {`${drafting ? "drafting" : "get"} ${meal.split(" ")[1]}`}
        </DraftButton>
      ))} */}
      <Toaster />
      {carts?.length > 0 ? (
        <Header>
          <span>
            <FontAwesomeIcon icon={faBolt} />
            {`quick ${meal} for ${day}`}
          </span>
        </Header>
      ) : null}
      <CartList>
        {carts?.map((cart, index) => (
          <Cart
            cart={cart}
            key={index}
            placeOrder={onPlaceOrder.bind(this, index)}
            userName={`${currentUser?.user?.firstName} ${currentUser?.user?.lastName}`}
            placing={placing[index]}
          />
        ))}
      </CartList>
    </Container>
  );
};
