import { LOGGING } from '../../constants';
import { LOAD_BOOKMARKS, RESET_BOOKMARKS } from '../actionTypes';
import { apiCall } from '../api';
import { loadPosts } from './';
export const loadBookmarks = (bookmarks) => ({
  type: LOAD_BOOKMARKS,
  bookmarks,
});

export const resetBookmarks = () => ({
  type: RESET_BOOKMARKS,
});

export const fetchBookmarks = ({ userId, statuses, pageIndices }) => {
  LOGGING &&
    console.log('fetchBookmarks called with:', {
      userId,
      statuses,
      pageIndices,
    });
  return (dispatch, getState) => {
    const { bookmarks } = getState();
    return apiCall('PUT', `/bookmarks/${userId ? userId : ''}`, {
      statuses,
      pageIndices,
    })
      .then((newBookmarks) => {
        LOGGING && console.log('fetchBookmarks got newBookmarks', newBookmarks);
        if (pageIndices) {
          if (newBookmarks.length > 0) {
            dispatch(loadBookmarks([...bookmarks, ...newBookmarks]));
          } else {
            return 0;
          }
        } else {
          dispatch(loadBookmarks(newBookmarks));
        }
      })
      .catch((err) => {
        LOGGING && console.log('fetchBookmarks got err', err);
      });
  };
};

export const createBookmark = ({ userId, postId, review, status, updateAfterDone }) => {
  LOGGING &&
    console.log('createBookmark called with: ', {
      userId,
      postId,
      review,
      status,
      updateAfterDone,
    });
  return (dispatch, getState) => {
    const { posts, bookmarks } = getState();
    return apiCall('POST', `/bookmarks`, { userId, postId, review, status })
      .then((createdBookmark) => {
        LOGGING && console.log('createBookmark got createdBookmark', createdBookmark);
        const updatedPosts = posts.map((p) =>
          p._id === postId
            ? {
                ...p,
                bookmarks: [...p.bookmarks, createdBookmark],
              }
            : p
        );

        const updatedBookmarks = [...bookmarks, createdBookmark];
        if (updateAfterDone === 'bookmarks') {
          dispatch(loadBookmarks(updatedBookmarks));
        } else if (updateAfterDone === 'posts') {
          dispatch(loadPosts(updatedPosts));
        }
      })
      .catch((err) => {
        LOGGING && console.log('createBookmark got err', err);
      });
  };
};

export const updateBookmark = ({ _id, bookmark, updateAfterDone }) => {
  LOGGING &&
    console.log('updateBookmark called with: ', {
      _id,
      bookmark,
      updateAfterDone,
    });
  return (dispatch, getState) => {
    const { posts, bookmarks } = getState();
    return apiCall('POST', `/bookmarks/${_id}`, bookmark)
      .then((updatedBookmark) => {
        LOGGING && console.log('updateBookmark got updatedBookmark', updatedBookmark);
        const updatedPosts = posts.map((p) =>
          p._id === updatedBookmark.post._id
            ? {
                ...p,
                bookmarks: p.bookmarks.map((b) => (b._id === _id ? updatedBookmark : b)),
              }
            : p
        );
        const updatedBookmarks = bookmarks.map((b) =>
          b._id === updatedBookmark._id ? updatedBookmark : b
        );
        if (updateAfterDone === 'bookmarks') {
          dispatch(loadBookmarks(updatedBookmarks));
        } else if (updateAfterDone === 'posts') {
          dispatch(loadPosts(updatedPosts));
        }
      })
      .catch((err) => {
        LOGGING && console.log('updateBookmark got err', err);
      });
  };
};

export const reviewBookmark = (bookmark) => {
  LOGGING && console.log('reviewBookmark called with: ', { bookmark });
  return (dispatch, getState) => {
    const { bookmarks } = getState();
    const { review } = bookmark;
    const { _id } = review;
    LOGGING && console.log('reviewBookmark called with: ', { review });
    return apiCall(_id ? 'PUT' : 'POST', `/comments/${_id || ''}`, {
      bookmarkId: bookmark._id,
      content: bookmark.review.content,
    })
      .then((review) => {
        LOGGING && console.log('reviewBookmark got new review', review);
        dispatch(
          loadBookmarks(
            bookmarks.map((b) => (String(b._id) === String(bookmark._id) ? { ...b, review } : b))
          )
        );
      })
      .catch((err) => {
        LOGGING && console.log('createBookmark got err', err);
      });
  };
};
