import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import { fetchPosts, fetchRestaurant } from '../../store/actions';
import { Loading, Footer, ConfirmAlert, Back, LOGGING } from './';
import { Restaurant, Post } from './components';

class PageRestaurant extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
    this.handleSetBookmark = this.handleSetBookmark.bind(this);
    this.handleCreateBookmark = this.handleCreateBookmark.bind(this);
    this.handleDeletePost = this.handleDeletePost.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  handleGoBack(e) {
    e.preventDefault();
    LOGGING && console.log('handleGoBack called');
    this.props.history.goBack();
  }

  handleSetBookmark(bookmark, status, e) {
    e.preventDefault();
    LOGGING && console.log('handleSetBookmark called with bookmark: ', bookmark);
    const { _id } = bookmark;
    this.setState({ loading: true }, () => {
      this.props
        .updateBookmark({
          _id,
          bookmark: { status },
          updateAfterDone: 'posts',
        })
        .then(() => {
          this.setState({ loading: false });
        });
    });
  }

  handleCreateBookmark(userId, postId, e) {
    e.preventDefault();
    LOGGING && console.log('handleCreateBookmark called with: ', { userId, postId });
    this.setState({ loading: true }, () => {
      this.props.createBookmark({ userId, postId }).then(() => {
        this.setState({ loading: false });
      });
    });
  }

  handleDeletePost(postId, e) {
    e.preventDefault();
    LOGGING && console.log('handleDeletePost called with postId:', postId);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmAlert
            onConfirm={this.handleConfirmDeletePost.bind(this, postId)}
            onClose={onClose}
            actionName="delete"
          />
        );
      },
    });
  }

  componentDidMount() {
    const { placeId } = this.props.match.params;
    LOGGING && console.log('PageRestaurant componentDidMount with:', { placeId });
    this.setState({ loading: true }, () => {
      this.props.fetchRestaurant(placeId).then((restaurant) => {
        LOGGING && console.log('PageRestaurant componentDidMount got restaurant:', restaurant);
        this.props.fetchPosts({ restaurantId: restaurant._id, fresh: true }).then(() => {
          this.setState(
            {
              restaurant,
              loading: false,
            },
            () => {
              window.scrollTo(0, 0);
            }
          );
        });
      });
    });
  }

  render() {
    const { loading, restaurant } = this.state;
    const { currentUser, posts } = this.props;
    LOGGING &&
      console.log('PageRestaurant rendering with', {
        props: this.props,
        state: this.state,
      });

    return (
      <div className="page restaurant popup">
        <Back onClick={this.handleGoBack} />
        {loading ? (
          <Loading />
        ) : (
          <React.Fragment>
            <Restaurant restaurant={restaurant} />
            <div className="list-wrapper">
              {posts.map((post) => (
                <Post
                  key={post._id}
                  post={post}
                  currentUser={currentUser}
                  onSetBookmark={this.handleSetBookmark}
                  onCreateBookmark={this.handleCreateBookmark}
                  onDelete={this.handleDeletePost.bind(this, post._id)}
                  showStatement={false}
                  showRecommenderTitle={true}
                />
              ))}
            </div>
          </React.Fragment>
        )}
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log('PageRestaurant mapStateToProps got state:', state);
  return {
    posts: state.posts.payLoad,
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, { fetchRestaurant, fetchPosts })(PageRestaurant);
