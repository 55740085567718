import { apiCall } from '../api';

export const reportBug = (error, info, page) => {
  console.log('reportBug action called:', error, info, page);
  return apiCall('POST', `/bugReport`, {
    error: error.toString(),
    info,
    page,
  });
};
