import { LOGGING } from "../../constants";
import {
  LOAD_DISHES,
  RESET_DISHES,
  SET_SUPPORT_LUNCH_EARLY_DELIVERY,
} from "../actionTypes";
import { apiCall } from "../api";
export const loadDishes = (dishes) => ({
  type: LOAD_DISHES,
  dishes,
});

export const resetDishes = () => ({
  type: RESET_DISHES,
});

export const setSupportLunchEarlyDelivery = (supportLunchEarlyDelivery) => ({
  type: SET_SUPPORT_LUNCH_EARLY_DELIVERY,
  supportLunchEarlyDelivery,
});

export const fetchDishes = () => {
  LOGGING && console.log("fetchDishes");

  return (dispatch, getState) => {
    return apiCall("GET", `/dishes`, {}, getState().currentUser)
      .then((dishesByCategory) => {
        LOGGING &&
          console.log("fetchDishes got dishesByCategory:", dishesByCategory);
        dispatch(loadDishes(dishesByCategory));
      })
      .catch((err) => {
        LOGGING && console.log("fetchDishes got err", err);
      });
  };
};

export const readDish = (dishId) => {
  LOGGING && console.log("readDish:", dishId);

  return (dispatch, getState) => {
    return apiCall("GET", `/dishes/${dishId}`, {}, getState().currentUser)
      .then((dish) => {
        LOGGING && console.log("readDish got:", { dish });
        return dish;
      })
      .catch((err) => {
        LOGGING && console.log("readDish got err", err);
      });
  };
};

export const saveDish = (dish) => {
  LOGGING && console.log("saveDish called with:", dish);
  const { _id } = dish;
  return (dispatch, getState) => {
    return apiCall("post", `/dishes/${_id || ""}`, dish, getState().currentUser)
      .then((savedDish) => {
        LOGGING && console.log("saveDish got savedDish:", savedDish);
        return savedDish;
      })
      .catch((err) => {
        LOGGING && console.log("saveDish got err", err);
      });
  };
};
export const saveSelection = (selection, dish) => {
  LOGGING && console.log("saveSelection called with:", { dish, selection });
  const { categories } = dish;
  return (dispatch, getState) => {
    return apiCall(
      "post",
      `/dishes/selection/${dish._id}`,
      { categories, selection },
      getState().currentUser
    )
      .then(({ dish, selection }) => {
        LOGGING &&
          console.log("saveSelection got from server:", { dish, selection });
        return { dish, selection };
      })
      .catch((err) => {
        LOGGING && console.log("saveSelection got err", err);
      });
  };
};

export const createSelectionForDish = (dishId, selection) => {
  LOGGING &&
    console.log("createSelectionForDish called with:", { dishId, selection });
  return (dispatch, getState) => {
    return apiCall(
      "POST",
      `/dishes/selectionForDish/${dishId}`,
      selection,
      getState().currentUser
    )
      .then((updatedDish) => {
        LOGGING &&
          console.log("createSelectionForDish got from server:", updatedDish);
        return updatedDish;
      })
      .catch((err) => {
        LOGGING && console.log("createSelectionForDish got err", err);
      });
  };
};
export const saveSelectionItem = (selectionItem) => {
  LOGGING && console.log("saveSelectionItem called with:", { selectionItem });
  const { _id } = selectionItem;
  return (dispatch, getState) => {
    return apiCall(
      "post",
      `/dishes/selectionItem/${_id}`,
      selectionItem,
      getState().currentUser
    )
      .then((savedSelectionItem) => {
        LOGGING &&
          console.log("saveSelectionItem got from server:", savedSelectionItem);
        return savedSelectionItem;
      })
      .catch((err) => {
        LOGGING && console.log("saveDish got err", err);
      });
  };
};

export const fetchDishesForRestaurant = (restaurantId) => {
  LOGGING && console.log("fetchDishesForRestaurant: ", restaurantId);

  return (dispatch, getState) => {
    return apiCall(
      "GET",
      `/dishes/restaurant/${restaurantId}`,
      {},
      getState().currentUser
    )
      .then((dishes) => {
        LOGGING && console.log("fetchDishesForRestaurant got dishes:", dishes);
        return dishes;
      })
      .catch((err) => {
        LOGGING && console.log("fetchDishesForRestaurant got err", err);
      });
  };
};

export const fetchMenu = () => {
  LOGGING && console.log("fetchMenu");

  return (dispatch, getState) => {
    return apiCall("GET", `/dishes/choices/all`, {}, getState().currentUser)
      .then((menu) => {
        LOGGING && console.log("fetchMenu got menu:", menu);
        return menu;
      })
      .catch((err) => {
        LOGGING && console.log("fetchMenu got err", err);
      });
  };
};

export const fetchRandomDishes = (n) => {
  LOGGING && console.log("fetchRandomDishes called with n:", n);
  return (dispatch, getState) => {
    return apiCall("GET", `/dishes/fixed/${n}`, {}, getState().currentUser)
      .then((dishesByCategory) => {
        LOGGING &&
          console.log(
            "fetchRandomDishes got dishesByCategory:",
            dishesByCategory
          );
        dispatch(loadDishes(dishesByCategory));
      })
      .catch((err) => {
        LOGGING && console.log("fetchRandomDishes got err", err);
      });
  };
};

export const fetchDishChoices = (orderTime) => {
  LOGGING && console.log("fetchDishChoices called with orderTime:", orderTime);
  return (dispatch, getState) => {
    return apiCall(
      "GET",
      `/dishes/choices/${orderTime}`,
      {},
      getState().currentUser
    )
      .then((dishes) => {
        LOGGING && console.log("fetchDishChoices got dishes:", dishes);
        dispatch(loadDishes(dishes));
      })
      .catch((err) => {
        LOGGING && console.log("fetchDishChoices got err", err);
      });
  };
};

export const fetchDishChoicesFullDay = (orderTime) => {
  LOGGING &&
    console.log("fetchDishChoicesFullDay called with orderTime:", orderTime);
  return (dispatch, getState) => {
    return apiCall(
      "GET",
      `/dishes/fullday/${orderTime}`,
      {},
      getState().currentUser
    )
      .then((dishes) => {
        LOGGING && console.log("fetchDishChoicesFullDay got dishes:", dishes);
        dispatch(loadDishes(dishes));
      })
      .catch((err) => {
        LOGGING && console.log("fetchDishChoicesFullDay got err", err);
      });
  };
};

export const fetchDishesForCalendarDay = (
  lunchWindowStart,
  dinnerWindowStart
) => {
  LOGGING &&
    console.log("fetchDishesForCalendarDay called with:", {
      lunchWindowStart,
      dinnerWindowStart,
    });
  return (dispatch, getState) => {
    return apiCall(
      "GET",
      `/meals/calendar/${lunchWindowStart}/${dinnerWindowStart}`,
      {},
      getState().currentUser
    )
      .then((meal) => {
        LOGGING && console.log("fetchDishChoicesFullDay got meal:", meal);
        const { dishes, preOrderedDishes, supportLunchEarlyDelivery } = meal;
        dispatch(loadDishes([...preOrderedDishes, ...dishes]));
        dispatch(setSupportLunchEarlyDelivery(supportLunchEarlyDelivery));
        return meal;
      })
      .catch((err) => {
        LOGGING && console.log("fetchDishChoicesFullDay got err", err);
      });
  };
};

export const fetchDishesForCalendarWeek = (windows) => {
  LOGGING &&
    console.log("fetchDishesForCalendarWeek called with:", {
      windows,
    });
  return (dispatch, getState) => {
    return apiCall(
      "put",
      "/meals/calendar/weekly",
      { windows },
      getState().currentUser
    )
      .then((result) => {
        LOGGING &&
          console.log(
            "fetchDishesForCalendarWeek got result from backend:",
            result
          );
        LOGGING &&
          console.log(
            "fetchDishesForCalendarWeek now has redux state:",
            getState()
          );
        const { dishes: originalDishes } = getState();
        LOGGING &&
          console.log("fetchDishesForCalendarWeek now has:", {
            originalDishes,
          });
        const oldDishes = originalDishes?.payLoad || [];
        const { dishes, preOrderedDishes, supportLunchEarlyDelivery } = result;
        LOGGING &&
          console.log("fetchDishesForCalendarWeek now has preordered:", {
            preOrderedDishes,
          });
        const originalDishIds = oldDishes.map(
          (d) => d._id + d.supportedDeliveryZone.join("")
        );
        const newDishes = dishes.filter(
          (d) =>
            !originalDishIds.includes(d._id + d.supportedDeliveryZone.join(""))
        );
        const newPreOrderedDishes = preOrderedDishes.filter(
          (d) =>
            !originalDishIds.includes(d._id + d.supportedDeliveryZone.join(""))
        );
        dispatch(
          loadDishes([...oldDishes, ...newDishes, ...newPreOrderedDishes])
        );
        dispatch(setSupportLunchEarlyDelivery(supportLunchEarlyDelivery));
        return result;
      })
      .catch((err) => {
        LOGGING && console.log("fetchDishChoicesFullDay got err", err);
      });
  };
};

export const GetItemNamesByIds = (itemIds) => {
  LOGGING && console.log("GetItemNamesByIds called with:", itemIds);
  return (dispatch, getState) => {
    return apiCall(
      "POST",
      `/dishes/GetItemNamesByIds`,
      { itemIds },
      getState().currentUser
    )
      .then((itemNames) => {
        LOGGING && console.log("GetItemNamesByIds got itemNames:", itemNames);
        return itemNames;
      })
      .catch((err) => {
        LOGGING && console.log("GetItemNamesByIds got err", err);
      });
  };
};
