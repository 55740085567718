import React, { ReactElement, useState, useEffect } from "react";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useDispatch, useStore } from "react-redux";
import { useParams } from "react-router-dom";
import { LOGGING } from "src/constants";
import { getUserOrderHistory } from "src/store/actions";
import styled from "styled-components";
import {
  CenteredByColumn,
  CenteredByColumnLeft,
  Centered,
  Text12300,
  card,
  text12300,
  BackgroundPink,
  TextTitle,
  actionGrey,
  ActionGrey,
  BorderGrey,
  Purple,
} from "./components/Shared";
interface PageUserTasteProps {}
const Container = styled(CenteredByColumnLeft)`
  position: fixed;
  justify-content: flex-start;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  overflow-y: hidden;
`;
const UserContainer = styled(CenteredByColumnLeft)`
  margin-top: 10px;
  width: 100%;
`;
const Tastes = styled(Text12300)`
  // cap at 2 lines
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const Name = styled(Text12300)``;
const UserProfile = ({ user, restaurants }) => {
  const { firstName, lastName, tastes } = user || {};
  return (
    <UserContainer>
      <Name>{`${firstName} ${lastName} tried ${restaurants} restaurants`}</Name>
      <Tastes>{`Tastes: ${tastes.map((t) => t.name).join(", ")}`}</Tastes>
    </UserContainer>
  );
};
const OrderList = styled(CenteredByColumnLeft)`
  width: 100%;
  justify-content: flex-start;
`;
const Content = styled(CenteredByColumnLeft)`
  width: 100%;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  justify-content: flex-start;
`;
const RestaurantName = styled(ActionGrey)`
  ${text12300}
  svg {
    margin-left: 5px;
  }
  text-align: left;
`;
const RestaurantOrders = styled(CenteredByColumnLeft)`
  width: 100%;
  ${card}
`;
const OrderContainer = styled(CenteredByColumnLeft)`
  padding: 10px;
`;
const Good = styled(CenteredByColumnLeft)`
  align-self: stretch;
`;
const Dish = styled(Text12300)``;
const DishSelection = styled(Centered)`
  margin-left: 20px;
  margin-bottom: 5px;
`;
const SelectionName = styled(Text12300)`
  background-color: ${BackgroundPink};
  padding: 0px 10px;
  border-radius: 5px;
  line-height: 1.8;
  margin-right: 10px;
`;
const DishSelectionItem = styled(Text12300)``;
const Date = styled(TextTitle)`
  font-size: 16px;
`;
const CartCreated = styled(Date)`
  font-size: 12px;
`;
const PayTime = styled(Date)`
  font-size: 12px;
`;
const Order = ({ order }) => {
  const { goods, windowStart, createdAt, payTime } = order || {};
  return (
    <OrderContainer>
      {/* get los angeles time */}
      <Date>{moment(windowStart).format("ddd, MMMM Do YYYY, h:mmA")}</Date>
      <CartCreated>
        cart {moment(createdAt).format("h:mmA, ddd, MMMM Do")}
      </CartCreated>
      <PayTime>paid {moment(payTime).format("h:mmA, ddd, MMMM Do")}</PayTime>
      {goods.map((good, index) => {
        return (
          <Good key={index}>
            <Dish>
              {good.quantity} <FontAwesomeIcon icon={faTimes} />{" "}
              {good.dish.name}
            </Dish>
            {good?.selections?.map((selection, index) => (
              <DishSelection key={index}>
                <SelectionName>{selection.dishSelection.name}</SelectionName>
                <DishSelectionItem>
                  {selection.selectedItems
                    .map((selectedItem, index) => selectedItem?.item?.name)
                    .join(", ")}
                </DishSelectionItem>
              </DishSelection>
            ))}
          </Good>
        );
      })}
    </OrderContainer>
  );
};
const DetailsContainer = styled(Centered)`
  align-items: flex-start;
  width: 100%;
`;
const OrderHistory = styled(CenteredByColumnLeft)`
  width: 50%;
`;
const FavoriteDishes = styled(CenteredByColumnLeft)`
  width: 50%;
`;
const RestaurantSummary = ({ restaurant, orders }) => {
  const [showDetails, setShowDetails] = useState(false);
  const onToggle = (e) => {
    e.preventDefault();
    setShowDetails(!showDetails);
  };
  return (
    <RestaurantOrders>
      <RestaurantName onClick={onToggle}>
        <span>
          {restaurant}({orders.orderHistory.length})
        </span>

        <FontAwesomeIcon icon={showDetails ? faChevronUp : faChevronDown} />
      </RestaurantName>
      {showDetails ? (
        <DetailsContainer>
          <OrderHistory>
            {orders.orderHistory.map((order, index) => (
              <Order key={index} order={order} />
            ))}
          </OrderHistory>
          <FavoriteDishes>
            {Object.keys(orders.favoriteDishes).map((dish, index) => (
              <Dish key={index}>
                {dish} ({orders.favoriteDishes[dish]})
              </Dish>
            ))}
          </FavoriteDishes>
        </DetailsContainer>
      ) : null}
    </RestaurantOrders>
  );
};
const ScheduleContainer = styled(CenteredByColumn)`
  margin: 0px 0px 20px 0px;
  ${text12300}
  td {
    width: 30px;
    /* text-align:right; */
  }
  th {
    font-weight: 400;
  }
`;
const Count = styled.td<{ saturation?: number }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: ${(props) =>
    props.saturation ? (props.saturation > 50 ? "white" : "black") : "black"};
  text-align: center;
  background-color: ${(props) =>
    props.saturation
      ? `rgba(0,0,0,${props.saturation / 100})`
      : "rgba(0,0,0,0)"};
  font-weight: 500;
`;
const Schedules = ({ schedules }) => {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const meals = ["lunch", "earlyDinner", "dinner"];
  // get the max count
  const maxCount = Math.max(
    ...Object.values(schedules).map((schedule) =>
      Math.max(...Object.values(schedule))
    )
  );
  return (
    <ScheduleContainer>
      <table>
        <thead>
          <tr>
            <th></th>
            {days.map((day, index) => (
              <th key={index}>{day.slice(0, 3)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {meals.map((meal, index) => (
            <tr key={index}>
              <td>{meal}</td>
              {days.map((day, index) => (
                <Count
                  saturation={
                    schedules[day]?.[meal]
                      ? Math.round((schedules[day]?.[meal] / maxCount) * 100)
                      : 0
                  }
                >
                  {schedules[day]?.[meal] || 0}
                </Count>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </ScheduleContainer>
  );
};

interface DistributionProps {
  distribution: { [hour: number]: number };
}

//  // plot distribtion as a bar chart
// Y axis is the hour of day
// X axis is the count of orders
const DistributionContainer = styled(CenteredByColumn)`
  margin: 0px 0px 20px 0px;
  ${text12300}
`;
const DistributionHour = styled(Centered)`
  width: 20px;
  margin-right: 2px;
`;
const DistributionCount = styled(CenteredByColumn)`
  width: 20px;
  margin-right: 2px;
`;
const Number = styled(Text12300)`
  font-size: 12px;
`;
const Bar = styled.div<{ height?: number }>`
  width: 20px;
  height: ${(props) => (props.height ? `${props.height * 100}px` : "0px")};
  background-color: black;
`;

const Row = styled(Centered)`
  max-width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  &:last-child {
    border-top: 1px solid black;
  }
  /* overflow-x: auto; */
`;

// define distribution as DistributionProps
const Distribution = ({ distribution }: DistributionProps) => {
  // get the max count
  const maxCount = Math.max(...Object.values(distribution));
  return (
    <DistributionContainer>
      <TextTitle>ordering time</TextTitle>
      <Row>
        {Array.from({ length: 24 }, (_, i) => i).map((hour, index) => (
          <DistributionCount>
            <Number>{distribution[hour] || 0}</Number>
            <Bar
              height={
                distribution[hour] > 0 ? distribution[hour] / maxCount : 0
              }
            />
          </DistributionCount>
        ))}
      </Row>
      <Row>
        {Array.from({ length: 24 }, (_, i) => i).map((hour, index) => (
          <DistributionHour key={index}>{hour}</DistributionHour>
        ))}
      </Row>
    </DistributionContainer>
  );
};
// by month is displayed the same way as distribution
// but the data is by month
interface ByMonthProps {
  byMonth: { [month: string]: number };
}
const ByMonthContainer = styled(CenteredByColumn)`
  margin: 0px 0px 20px 0px;
  ${text12300}
  max-width: 100%;
  /* overflow-x: hidden; */
  align-items: flex-end;
`;
const ByMonthMonth = styled(Centered)`
  width: 20px;
  margin-right: 2px;
  font-size: 8px;
`;
const ByMonthCount = styled(CenteredByColumn)`
  width: 20px;
  margin-right: 2px;
`;
const ByMonth = ({ byMonth }: ByMonthProps) => {
  // get the max count
  const maxCount = Math.max(...Object.values(byMonth));
  // x-axis is the month, which is all strings that are key of byMonth, sort it
  const months = Object.keys(byMonth).sort();
  return (
    <ByMonthContainer>
      <TextTitle>ordering month</TextTitle>
      <Row>
        {months.map((month, index) => (
          <ByMonthCount>
            <Number>{byMonth[month] || 0}</Number>
            <Bar height={byMonth[month] > 0 ? byMonth[month] / maxCount : 0} />
          </ByMonthCount>
        ))}
      </Row>
      <Row>
        {months.map((month, index) => (
          <ByMonthMonth key={index}>{month}</ByMonthMonth>
        ))}
      </Row>
    </ByMonthContainer>
  );
};

type Params = {
  userId: string;
};

export const PageUserTasteDetail: React.FC<PageUserTasteProps> =
  (): ReactElement => {
    const dispatch = useDispatch();
    const { userId } = useParams<Params>();
    const store = useStore();
    const [user, setUser] = useState(null);
    const [orders, setOrders] = useState([]);
    const [schedules, setSchedules] = useState(null);
    const [distribution, setDistribution] = useState(null);
    const [byMonth, setByMonth] = useState(null);
    useEffect(() => {
      getUserOrderHistory(userId)(dispatch, store.getState).then(
        ({ user, orders, schedules }) => {
          setUser(user);
          setOrders(orders);
          setSchedules(schedules);
          // transform orders into an array that concatenates all orders
          // from all restaurants
          // and sort them by payTime
          // @ts-ignore
          const flatOrders = Object.values(orders).reduce(
            // @ts-ignore
            (acc, orders) => acc.concat(orders.orderHistory),
            []
          );
          LOGGING && console.log("got flatOrders", flatOrders);
          // get a distribution order's payTime, key hour of day, value count of orders
          // @ts-ignore
          const distribution = flatOrders.reduce((acc, order) => {
            //@ts-ignore
            const hour = moment(order?.payTime).hour();
            acc[hour] = acc[hour] ? acc[hour] + 1 : 1;
            return acc;
          }, {});

          //@ts-ignore
          const byMonth = flatOrders.reduce((acc, order) => {
            const month = moment(order?.payTime).format("YY.M");
            acc[month] = acc[month] ? acc[month] + 1 : 1;
            return acc;
          }, {});
          //sort byMonth by month
          const byMonthSorted = Object.keys(byMonth)
            .sort((a, b) => a.localeCompare(b))
            .reduce((acc, key) => {
              acc[key] = byMonth[key];
              return acc;
            }, {});
          setDistribution(distribution);
          setByMonth(byMonthSorted);
        }
      );
    }, []);

    return (
      <Container>
        <Content>
          {user && (
            <UserProfile user={user} restaurants={Object.keys(orders).length} />
          )}
          {schedules && <Schedules schedules={schedules} />}
          {byMonth && <ByMonth byMonth={byMonth} />}
          {distribution && <Distribution distribution={distribution} />}
          <OrderList>
            {Object.keys(orders).map((restaurant, index) => (
              <RestaurantSummary
                key={index}
                restaurant={restaurant}
                orders={orders[restaurant]}
              />
            ))}
          </OrderList>
        </Content>
      </Container>
    );
  };
