import React from 'react';
import { faTimesCircle, faUser, faUtensilsAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import './NominationList.scss';
import { trimRestaurantAddress } from '../PageNominations';

const Nomination = ({
  nomination,
  onFilterByUser,
  onFilterByRestaurant,
  onDelete,
  userFilterOn,
  restaurantFilterOn,
}) => {
  const { restaurant, dish, user, updatedAt, _id } = nomination;
  return (
    <div className="nomination">
      {user ? (
        <button
          className={`user ${userFilterOn ? 'filtered' : ''}`}
          onClick={onFilterByUser.bind(this, user._id)}
        >
          <FontAwesomeIcon icon={faUser} />
          <span className="user-name">{user.firstName}</span>
        </button>
      ) : null}
      <button className="delete-nomination" onClick={onDelete.bind(this, _id)}>
        <FontAwesomeIcon icon={faTimesCircle} />
      </button>
      {restaurant ? (
        <div className="restaurant">
          <button
            className={`restaurant-name ${restaurantFilterOn ? 'filtered' : ''}`}
            onClick={onFilterByRestaurant.bind(this, restaurant.name)}
          >
            <FontAwesomeIcon icon={faUtensilsAlt} />
            <span>{restaurant.name}</span>
          </button>
          <span className="restaurant-address">{restaurant.address}</span>
        </div>
      ) : null}
      {dish && dish.trim().length > 0 ? (
        <ul className="dishes">
          {dish
            .trim()
            .split(',')
            .map((d, index) => (
              <li className="dish-name" key={index}>
                {d}
              </li>
            ))}
        </ul>
      ) : null}

      <div className="time">
        <span className="nominated-time">
          {`Submitted ${moment(updatedAt).format('MMM DD, YYYY')}`}
        </span>
      </div>
    </div>
  );
};

const NominationList = ({
  nominations,
  onDelete,
  onFilterCurrentRestaurants,
  currentRestaurantsFilter,
  onFilterByUser,
  userFilter,
  onFilterByRestaurant,
  restaurantFilter,
}) => {
  const shown = nominations
    .filter((nomination) =>
      currentRestaurantsFilter
        ? !currentRestaurantsFilter.includes(
            trimRestaurantAddress(
              nomination?.restaurant?.address
                .toLowerCase()
                .replace('.', '')
                .replace('  ', ' ')
                .replace('#', '')
                .trim()
            )
          )
        : true
    )
    .filter((nomination) =>
      userFilter ? nomination.user && nomination.user._id === userFilter : true
    )
    .filter((nomination) =>
      restaurantFilter
        ? nomination?.restaurant && restaurantFilter === nomination?.restaurant?.name
        : true
    );

  return (
    <div>
      <div className={'hide-current-restaurants'}>
        <button
          className={`current-restaurants ${currentRestaurantsFilter ? 'filtered' : ''}`}
          onClick={onFilterCurrentRestaurants.bind(this)}
        >
          <span className={currentRestaurantsFilter ? 'hide' : ''}>hide current restaurants</span>
          <span className={currentRestaurantsFilter ? '' : 'hide'}>show all restaurants</span>
        </button>
      </div>
      <div className={`list nomination-list`}>
        {/* <div className="list-title">
          <span>{`nominations (${shown.length})`}</span>
          <Back onClick={onBack} />
        </div> */}
        {shown.map((nomination, index) => (
          <Nomination
            nomination={nomination}
            key={index}
            onFilterCurrentRestaurants={onFilterCurrentRestaurants}
            onFilterByUser={onFilterByUser}
            onFilterByRestaurant={onFilterByRestaurant}
            restaurantFilterOn={Boolean(restaurantFilter)}
            userFilterOn={Boolean(userFilter)}
            onDelete={onDelete}
          />
        ))}
      </div>
    </div>
  );
};

export default NominationList;
