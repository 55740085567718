import { LOAD_WEEK } from '../actionTypes';

const week = (state = [], action) => {
  switch (action.type) {
    case LOAD_WEEK:
      return [...action.week];
    default:
      return state;
  }
};

export default week;
