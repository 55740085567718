import { useSelector } from "react-redux";
import styled from "styled-components";
import { AdminDeliveryV2DriverActionsContainer } from "./AdminDeliveryV2DriverActionsContainer";

// 100 margin-bottom so the bottom nav doesn't cover the actions
const Container = styled.div`
  /* padding: 16px; */
  margin-bottom: 100px;
  background-color: transparent;
  box-shadow: none;
  padding: 0px;
  /* background-color: red; */
`;

export const AdminDeliveryV2ControlPanel = () => {
  const isLunch = useSelector((state) => state.adminDeliveryV2.filters.isLunch);
  const mealType = useSelector(
    (state) => state.adminDeliveryV2.filters.mealType
  );
  const drivers = useSelector((state) => state.adminDeliveryV2.drivers);
  console.log("AdminDeliveryV2ControlPanel:", { drivers });
  // === Filter Drivers ===
  let filteredDrivers = drivers;

  // Accepted drivers
  filteredDrivers = drivers.filter(
    (d) => d.askedAvailability === true && d.routeAcceptanceResponse === true
  );
  console.log("filteredDrivers1:", { filteredDrivers });
  // Correct meal time
  filteredDrivers = filteredDrivers.filter((d) => d.mealType === mealType);
  console.log("filteredDrivers2:", { filteredDrivers });

  return (
    <Container className="drivers-on-duty-v2">
      {filteredDrivers.map((driver) => {
        return (
          <AdminDeliveryV2DriverActionsContainer
            key={driver._id}
            driver={driver}
          />
        );
      })}
    </Container>
  );
};
