import { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LOGGING, ShareCard } from '.';
class PageShare extends Component {
  constructor(props) {
    LOGGING && console.log('PageShare constructor called with props:', props);
    super();
    this.state = {};
    this.handleClose = this.handleClose.bind(this);
    this.handleShareSuccess = this.handleShareSuccess.bind(this);
  }
  handleShareSuccess() {
    LOGGING && console.log('PageShare handleShareSuccess called');
    this.props.history.goBack();
  }
  handleClose(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { currentUser } = this.props;

    LOGGING &&
      console.log('PageShare rendering with', {
        props: this.props,
        state: this.state,
      });

    if (!currentUser.isAuthenticated || !currentUser?.user?.socialInviteCode?.code) {
      return <Redirect to="/" />;
    }
    return (
      <div className="page">
        <ShareCard
          onHideShare={this.handleClose}
          onSuccess={this.handleShareSuccess}
          code={currentUser?.user?.socialInviteCode?.code}
          firstName={currentUser?.user?.firstName}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log('PageShare got redux state:', state);
  return {
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {})(PageShare);
