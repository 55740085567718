import React, { ReactElement, useCallback, useState } from "react";
import {
  faArrowSquareRight,
  faCheck,
  faInfoCircle,
  faShareAll as faCopy,
} from "@fortawesome/pro-light-svg-icons";
import { faChevronLeft as faBack } from "@fortawesome/pro-regular-svg-icons";
import { faHome } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAsync } from "react-async";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { GetReferralLinkDetails, SendAlert } from "src/api/referrals";
import styled, { keyframes } from "styled-components";
import {
  Loading,
  IconImages,
  PopUp,
  SupportedSanJoseZipCodes,
  LOGGING,
  Close,
} from "..";
import {
  AnonymousRestaurantSlides,
  SlideHeight,
  largeHeight,
} from "../home/CoverSliding";
import {
  ActionGrey,
  Text12300,
  TextUPPER,
  TextTagline,
  Centered,
  Yellow,
  Beige,
  Grey,
  Pink,
  Card,
  card,
  textTitle,
  ALLCAPTEXT,
  TextTitle,
  SectionHeader,
  SectionNote,
  SectionTitle,
  text12300,
  CenteredByColumn,
  NavHeight,
  CenteredByColumnLeft,
  BorderGrey,
  actionBlack,
  centered,
  actionGrey,
  centeredByColumn,
  ActionBlack,
  actionUnderline,
} from "../Shared";
import { ShareActions } from "./ShareActions";

const heroHeight = 200;
export interface MyReferralCodeRegularProps {}
export interface HeroProps {
  src: string;
}

const ShareButtonText = styled(ActionGrey)`
  // margin: 5px;
  // margin-bottom: 10px;
  width: 100%;
  height: 60px;
  position: relative;
  & svg {
    /* position: absolute; */
    /* right: 20px; */
    font-size: 18px;
    margin-left: 10px;
  }
`;

const TextShareButtonText = styled(ShareButtonText)`
  background: black;
  /* color: black; */
  border: 1px solid black;
  /* background-color: transparent; */
  font-size: 13px;
  padding: 15px 30px;
  color: white;
`;

const copyButtonHeight = 100;
export const breakPoint = 500;
const ReferralLink = styled(ActionBlack)`
  margin-top: 30px;
  height: 55px;
  line-height: 1.5;
  padding: 0px 15px;
  width: 210px;
  justify-content: center;
  svg {
    /* display: none; */
    margin-left: 15px;
    font-size: 20px;
  }
  @media only screen and (min-width: ${breakPoint}px) {
    /* position: absolute;
    bottom: 30px; */
    display: none;
  }
`;
const HowItWorks = styled(CenteredByColumn)`
  /* margin-top: ${heroHeight}px;; */
  width: 100%;
  justify-content: flex-start;
  padding: 0px 20px;
  div:last-child {
    &:after {
      display: none;
    }
  }
  @media only screen and (min-width: ${breakPoint}px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
`;
const Step = styled(Centered)`
  width: 100%;
  margin-top: 40px;
  position: relative;
  ${card}
  &:after {
    width: 1px;
    height: 40px;
    content: "";
    border-left: 2px dashed orange;
    position: absolute;
    right: 20px;
    top: 100%;
  }
  max-width: ${breakPoint - 40}px;
  @media only screen and (min-width: ${breakPoint}px) {
    flex-direction: column;
    width: 250px;
    margin: 20px;
    align-self: stretch;
    justify-content: flex-start;
    &:after {
      width: 40px;
      height: 0px;
      content: "";
      border-top: 2px dashed orange;
      position: absolute;
      left: 100%;
      top: 60px;
    }
  }
`;
export interface StepImageProps {
  src: {
    portrait: string;
    landscape: string;
  };
}
const StepImage = styled(Centered)<StepImageProps>`
  width: 40%;
  /* background-image: url(${(props) => props.src.portrait}); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-self: stretch;
  background-color: orange;
  font-size: 80px;
  @media only screen and (min-width: ${breakPoint}px) {
    width: 100%;
    height: 120px;
    font-size: 80px;
    background-image: url(${(props) => props.src.landscape});
  }
`;
const StepCount = styled(TextTitle)`
  font-size: 20px;
  margin-bottom: 20px;
  text-transform: none;
  width: 100%;
  @media only screen and (min-width: ${breakPoint}px) {
    margin-bottom: 15px;
  }
`;
const StepContent = styled(CenteredByColumnLeft)`
  width: 60%;
  padding: 40px 30px;
  text-align: left;
  position: relative;
  @media only screen and (min-width: ${breakPoint}px) {
    width: 100%;
    text-align: center;
    padding: 20px 20px 70px 20px;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-grow: 1;
  }
`;
const StepExplain = styled.div`
  ${text12300}/* button {
    ${text12300}
    text-transform: lowercase;    
    
  } */
`;
const Title = styled(TextTitle)`
  font-size: 15px;
`;
const ClaimLink = styled(Link)`
  /* ${actionGrey} */
  ${text12300}
  font-weight: 500;
  /* ${card} */
  border-bottom: 1px solid black;
  margin-top: 20px;
  text-transform: uppercase;
  /* background-color: ${BorderGrey}; */
  /* border: 1px solid ${BorderGrey}; */
  /* background-color: ${BorderGrey}; */
  /* border-bottom: 1px solid black; */
  svg {
    margin-left: 10px;
    display: none;
  }
  @media only screen and (min-width: ${breakPoint}px) {
    position: absolute;
    bottom: 30px;
  }
`;
const CopyLink = styled(Centered)`
  /* border: 1px solid red; */
  span {
    background: rgba(228, 228, 228, 0.5);
    box-shadow: none;
    box-sizing: border-box;
    ${text12300}
    font-weight: 300;
    line-height: 40px;
    letter-spacing: 1px;
    align-self: stretch;
    padding: 0px 20px;
  }
  button {
    ${actionBlack}
    box-shadow: none;
    /* margin-left: 10px; */
  }
  margin-top: 20px;
  bottom: 30px;
  padding: 0px;
  display: none;
  @media only screen and (min-width: ${breakPoint}px) {
    display: flex;
    span {
      /* font-family: Arial, Helvetica, sans-serif; */
    }
  }
`;
export const CopyButton = styled.div`
  margin: 20px 0px 0px 0px;
  width: 100%;
  padding: 20px;

  > svg {
    /* display: none; */
    font-size: 20px;
    /* margin-left: 10px;       */
    /* margin-bottom: 0px; */
    position: absolute;
    right: 30px;
  }
`;
const VisitButton = styled(CopyButton)``;
const ShareLink = styled.a`
  margin: 10px 0px;
`;

export const SendReferralContentContainer = styled(CenteredByColumn)`
  width: 100vw;
  /* height: calc(100vh - ${NavHeight}px); */
  height: 100vh;
  text-align: center;
  line-height: 1.5;
  justify-content: center;
  /* padding-bottom: 100px; */
  background-color: ${Beige};
  padding-bottom: ${NavHeight}px;
`;
const BANNER_HEIGHT = 40;
const animation = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;
const HowItWorksTitle = styled.h1`
  ${text12300}
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 700;
  margin: 40px 0px 0 0;
  @media only screen and (min-width: ${breakPoint}px) {
    margin: 30px 0 10px 0;
  }
`;
const CopiedNote = styled(Card)`
  position: absolute;
  padding: 0px 30px;
  /* width: 100px; */
  background: green;
  color: white;
  ${text12300}
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: ${BANNER_HEIGHT}px;
  height: ${BANNER_HEIGHT}px;
  top: -10px;
  left: calc(50% - 70px);
  opacity: 0.9;
  animation: ${animation} 1s ease-in-out 0.5s 1 normal forwards;
`;
const ButtonClose = styled(Link)`
  position: absolute;
  left: 0px;
  top: 0px;
  width: ${NavHeight}px;
  height: ${NavHeight}px;
  ${centered}
  z-index: 2;
  /* background-color: rgba(0, 0,0, 0.2); */
  svg {
    font-size: 20px;
    color: white;
  }
`;

const HeroContainer = styled(Centered)`
  width: calc(100vw - 80px);
  position: relative;
`;
const imagePercent = 40;
const HeroImageOverlay = styled(Centered)`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: ${heroHeight}px;
  background-color: rgba(0, 0, 0, 0.3);
`;
const HeroImage = styled.div<StepImageProps>`
  position: absolute;
  width: 100vw;
  height: ${heroHeight}px;
  background-image: url(${(props) => props.src.portrait});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (min-width: ${breakPoint}px) {
    /* width: 200px;
    height: 200px; */
    height: ${heroHeight}px;
    background-image: url(${(props) => props.src.landscape});
  }
`;
const HeroText = styled(Centered)`
  width: calc(100% - 40px);
  min-width: 300px;
  /* margin-top: calc(${largeHeight}*0.25); */
  /* margin-top: ${NavHeight * 2}px; */
  /* position: absolute; */
  /* bottom: ${NavHeight}px; */
  /* top: calc(${NavHeight}px + 20px);
  bottom: auto; */
  padding: 15px;
  ${card}
  z-index: 2;
  align-items: stretch;
  background-color: rgba(255, 255, 255, 1);
  /* position: relative; */
  /* box-shadow: none; */
  border: 1px solid ${BorderGrey};
  @media only screen and (max-height: 600px) {
    top: ${NavHeight + 40}px; // calc(${largeHeight} / 2 + ${NavHeight / 2}px);
  }
  @media only screen and (min-width: ${breakPoint}px) {
    top: calc(${largeHeight} - ${NavHeight}px - 40px);
    bottom: auto;
    width: ${breakPoint - 40}px;
  }
  @media only screen and (min-width: 800px) and (min-height: 700px) {
    top: calc(${largeHeight} - 40px);
    width: 70vw;
    max-width: 700px;
  }
`;
const HeroTextBorder = styled(CenteredByColumn)`
  position: relative;
  border: 2px solid orange;
  justify-content: flex-start;
  align-self: stretch;
  flex-grow: 1;
  padding: 30px;
  h1 {
    ${textTitle}
    /* text-transform: uppercase; */
    font-size: 26px;
    line-height: 1.2;
    font-weight: 400;
    margin: 0px 0 20px 0;
  }

  b {
    /* expire date */
    ${text12300}
    font-size: 15px;
    font-weight: 700;
    padding: 0px 20px;
    margin-top: 10px;
    text-transform: uppercase;
  }

  > span {
    /* member only  */
    /* ${textTitle} */
    /* font-size: 18px; */
    ${text12300}
    font-size: 13px;
    line-height: 1.8;
    /* text-transform: capitalize; */
    text-transform: none;
    font-weight: 300;
    line-height: 1.4;
    br {
      display: none;
    }
  }
  @media only screen and (min-width: ${breakPoint}px) {
    > span {
      br {
        display: inline;
      }
    }
    padding: 40px 20px;
    h1 {
      br {
        /* display: none; */
      }
    }
  }
  @media only screen and (min-width: 800px) and (min-height: 700px) {
    padding: 40px 60px;
    h1 {
      br {
        /* display: none; */
      }
    }
  }
`;
const HeroSlogan = styled(Centered)`
  ${text12300}
  text-transform: uppercase;
  align-items: flex-end;
  @media only screen and (min-width: ${breakPoint}px) {
    width: 150px;
    height: 150px;
    background-color: white;
    border-radius: 50%;
    padding-bottom: 50px;
    position: fixed;
    right: calc(50vw + 40px);
    top: calc(${NavHeight}px + 40px);
  }
`;
const L1 = styled.span`
  font-weight: 500;
  font-size: 40px;
  line-height: 1;
`;
const L2 = styled.span`
  margin-left: 5px;
  line-height: 1;
  margin-bottom: 3px;
`;
const HeroExplain = styled(CenteredByColumnLeft)`
  margin-top: 20px;
  @media only screen and (min-width: ${breakPoint}px) {
    height: calc(50vh - ${NavHeight / 2}px);
    /* width: 50vw; */
    position: fixed;
    top: ${NavHeight}px;
    /* border: 1px solid black; */
  }
`;
const HeroItem = styled(Text12300)`
  text-align: left;
  align-self: stretch;
  line-height: 1.8;
  margin-bottom: 5px;
  padding-left: 10px;
  position: relative;
  font-size: 13px;
  &:before {
    content: "";
    width: 4px;
    height: 4px;
    background-color: black;
    border-radius: 50%;
    position: absolute;
    left: 0px;
    top: 9px;
  }
  b {
    font-weight: 600;
  }
`;
const InfoIcon = styled.button`
  margin: 0px;
  padding: 0px;
  ${textTitle}
  text-transform: lowercase;
  font-size: 14px;
  line-height: 10px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 5px;
  position: relative;
  top: 2px;
`;

const CuteStamp = styled.div`
  background-color: transparent;
  font-size: 70px;
  /* line-height: 80px; */
  margin-top: ${NavHeight}px;
  margin-bottom: 10px;
  /* position: absolute;
  left: -20px;
  bottom: -20px; */
  @media only screen and (min-width: ${breakPoint}px) {
    font-size: 100px;
  }
  // narrow screen
  @media only screen and (max-width: 350px) {
    left: -10px;
    bottom: -30px;
  }
`;
const DeliveryRange = styled(CenteredByColumn)`
  ${card}
  z-index: 2;
  ${text12300}
  padding: 20px;
  position: fixed;
  width: 300px;
  left: calc(50vw - 150px);
  top: ${NavHeight}px;
  ul {
    width: 100%;
    ${CenteredByColumnLeft}
    /* background-color: red; */
    background-color: white;
    li {
      align-self: stretch;
      text-align: left;
      svg {
        margin-right: 10px;
      }
    }
  }
  button {
    ${actionBlack}
    margin-top: 40px;
  }
`;
export const Button = styled(Link)`
  ${actionUnderline}
  margin-top: 40px;
  svg {
    margin-right: 10px;
  }
`;
const CheckStatus = styled(Link)`
  ${text12300}
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 30px 0px;
  line-height: 1.8;
  border-bottom: 1px solid black;
`;
export const MyReferralCodeRegular: React.FC<MyReferralCodeRegularProps> =
  ({}: MyReferralCodeRegularProps): ReactElement => {
    const [isCodeCopied, setIsCodeCopied] = useState(false);
    const currentUser = useSelector((state: any) => state.currentUser);
    const history = useHistory();
    const code = currentUser?.user?.socialInviteCode?.code;
    const link = `https://foodie.earth/referrals/${code}`;
    const relativelink = `/treat/${code}`;
    LOGGING && console.log("MyReferralCodeRegular rendering with code:", code);

    const name = `${currentUser?.user?.firstName} ${currentUser?.user?.lastName}`;
    const [showActions, setShowActions] = useState(false);
    const [showRange, setShowRange] = useState(false);
    const onCloseShare = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setShowActions(false);
    };
    const fetch = useCallback(async () => {
      return await GetReferralLinkDetails(currentUser?.user?._id);
    }, [currentUser?.user?._id]);

    const copyLink = useCallback(() => {
      navigator.clipboard.writeText(link);
      setIsCodeCopied(true);
      SendAlert(name, "copied referral link!");
      setTimeout(() => {
        setIsCodeCopied(false);
      }, 2000);
    }, [link]);

    const { data } = useAsync({ promiseFn: fetch });

    if (data == null) return <Loading />;

    const couponCode = data.couponCode;
    const shareContent = {
      url: link,
      title: "Join Foodie.earth",
      text: "",
    };

    const handleShareClick = async () => {
      try {
        if (navigator.share) {
          await navigator.share(shareContent);
        } else {
          console.log("Web Share API not supported in this browser.");
          // Fallback for browsers that don't support the Web Share API
          // You can implement your own sharing mechanism here.
        }
      } catch (error) {
        console.error("Error sharing:", error);
      }
    };

    if (couponCode == null) return null;
    return (
      <SendReferralContentContainer>
        <Close onClick={() => history.push("/")} />
        {/* <AnonymousRestaurantSlides
          title="get free food."
          subtitle="refer a friend,"
          isLarge={true}
        /> */}
        <CuteStamp>💰</CuteStamp>
        <HeroText>
          <HeroTextBorder>
            <h1>
              Gift 10 USD<br></br>Earn 10 USD
            </h1>
            <span>
              Get $10 in credits when a friend signs up and places an order.
              <br></br> They also get $10 off their 1st order.
            </span>
            {/* <CheckStatus to="/referral-status">past referrals</CheckStatus> */}
            <ReferralLink
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                // setShowActions(true);
                handleShareClick();
                SendAlert(name, "clicked refer button!");
              }}
            >
              Share Your Link
              <FontAwesomeIcon icon={faCopy} />
            </ReferralLink>
            {isCodeCopied ? <CopiedNote>Link copied!</CopiedNote> : null}
            <CopyLink>
              <span>{link}</span>
              <button onClick={copyLink}>copy link</button>
            </CopyLink>
            {/* <CuteStamp>🍔</CuteStamp> */}
          </HeroTextBorder>
        </HeroText>
        <Button to={"/"}>
          <FontAwesomeIcon icon={faHome} />
          Back to Home
        </Button>
        {/* <ShareActions link={link} firstName={currentUser?.user?.firstName} /> */}
      </SendReferralContentContainer>
    );
  };
