import { SET_DAY_ORDERS } from '../actionTypes';

const dayOrders = (state = [], action) => {
  switch (action.type) {
    case SET_DAY_ORDERS:
      return [...action.dayOrders];
    default:
      return state;
  }
};

export default dayOrders;
