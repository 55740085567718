import { LOGGING } from "src/constants";
import { ObjectId } from "src/constants/types";
import { apiCall } from "src/store/api";
import { SerializedCategory } from "./categories";
import { SerializedRestaurantDishSelection } from "./meals";
import { SerializedRestaurant } from "./restaurants";

export enum DishType {
  UNKNOWN = "UNKNOWN",
  NORMAL = "NORMAL",
  GROUP = "GROUP",
}

export type SerializedGood = {
  _id: ObjectId;
  price: number;
  // priceFoodieListed: number;
  priceNonMember: number;
  // priceDoordashListed: number;
  // priceFoodieNonMember: number;
  dish: ObjectId;
  selections: SerializedGoodSelection[];
  quantity: number;
  cancelQuantity: number;
  comment: string;
  addedBy: ObjectId;
};

export type SerializedGoodSelection = {
  _id: ObjectId;
  dishSelection: ObjectId;
  selectedItems: SerializedGoodSelectedItem[];
};

export type SerializedGoodSelectedItem = {
  _id: ObjectId;
  item: ObjectId;
  count: number;
  cancelCount: number;
};

export type SerializedDish = {
  _id: ObjectId;
  archived: boolean;
  categoryName: string;
  categories: SerializedCategory[];
  dataSource: string;
  description: string;
  imageURL: string;
  isLastSelected: boolean;
  lastSelectedIndex: number;
  name: string;
  platformId: string;
  priceDirect: number;
  priceFoodieListed: number;
  priceDoordashListed: number;
  priceFoodieNonMember: number;
  restaurant: SerializedRestaurant;
  selections: SerializedRestaurantDishSelection[];
  preSelectedGoods: SerializedGood[];
  dishType: DishType;
};
export type GetDishesByRestaurantIdResponse = {
  restaurantId: ObjectId;
  dishes: SerializedDish[];
};
export const GetDishesByRestaurantId = async (
  restaurantId: ObjectId,
  excludeGroupDishes?: boolean
): Promise<GetDishesByRestaurantIdResponse> => {
  try {
    const dishes = await apiCall("POST", "/dishes/GetDishesByRestaurantId", {
      restaurantId: restaurantId,
      excludeGroupDishes: excludeGroupDishes,
    });
    return { restaurantId: restaurantId, dishes: dishes };
  } catch (err) {
    LOGGING && console.log("GetDishesByRestaurantId got err", err);
    return { restaurantId: restaurantId, dishes: [] };
  }
};

export type GetDishListByIdsResponse = { dishes: SerializedDish[] };
export const GetDishListByIds = async (
  dishIds: ObjectId[]
): Promise<GetDishListByIdsResponse> => {
  try {
    if ((dishIds ?? []).length === 0) return { dishes: [] };

    return await apiCall("POST", "/dishes/GetDishListByIds", {
      dishIds: dishIds,
    });
  } catch (err) {
    LOGGING && console.log("GetDishListById got err", err);
    return { dishes: [] };
  }
};

export type GetDishByIdResponse = { dish: SerializedDish | null };
export const GetDishById = async (
  dishId: ObjectId
): Promise<GetDishByIdResponse> => {
  try {
    const dish = await apiCall("GET", `/dishes/${dishId}`);
    return { dish: dish };
  } catch (err) {
    LOGGING && console.log("GetDishById got err", err);
    return { dish: null };
  }
};

export type RemoveDishFromBestSellersResponse = {};
export const RemoveDishFromBestSellers = async (
  dishId: ObjectId
): Promise<RemoveDishFromBestSellersResponse> => {
  try {
    await apiCall("DELETE", `/dishes/bestsellers/${dishId}`);
    return "success";
  } catch (err) {
    LOGGING && console.log("GetDishById got err", err);
    return err;
  }
};
