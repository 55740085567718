import { LOGGING } from "src/constants";
import { ObjectId } from "src/constants/types";
import { apiCall } from "src/store/api";
export type ReadGroupCartResponse = {
  meal: any;
};
export type ReadMealNameByOrderIdResponse = {
  mealLink: string;
};
export const CancelOrderSelectionItem = async (
  orderId: string,
  goodId: string,
  goodSelectionId: string,
  selectedItemId: string,
  cancelCount: number,
  currentUser: any
): Promise<void> => {
  try {
    await apiCall(
      "post",
      `/orders/CancelOrderSelectionItem`,
      {
        orderId: orderId,
        goodId: goodId,
        goodSelectionId: goodSelectionId,
        selectedItemId: selectedItemId,
        cancelCount: cancelCount,
      },
      currentUser
    );
  } catch (err) {
    LOGGING && console.log("CancelOrderSelectionItem got err", err);
  }
};

export const SetRestaurantConfirmTimeForOrder = async (
  orderId: ObjectId
): Promise<void> => {
  try {
    await apiCall("POST", "/orders/SetRestaurantConfirmTimeForOrder", {
      orderId,
    });
  } catch (err) {
    LOGGING && console.log("SetRestaurantConfirmTimeForOrder got err", err);
  }
};

export const ReadMealNameByOrderId = async (
  orderId: ObjectId
): Promise<string> => {
  try {
    LOGGING && console.log("ReadMealNameByOrderId called orderId:", orderId);
    const mealLink = await apiCall(
      "GET",
      `/orders/ReadMealNameByOrderId/${orderId}`
    );
    LOGGING &&
      console.log("ReadMealNameByOrderId returning mealLink:", mealLink);
    return mealLink;
  } catch (err) {
    LOGGING && console.log("ReadMealNameByOrderId got err", err);
  }
};
