import React, { useState, useEffect, useRef } from "react";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { LOGGING } from "../..";
import { BorderGrey, CenteredByColumn, TextGrey } from "../../Shared";

const DishDescription = ({ description }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [textOverflows, setTextOverflows] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      const textHeight = textRef.current.scrollHeight;
      const lineHeight = parseFloat(
        window.getComputedStyle(textRef.current).lineHeight
      );
      const numberOfLines = textHeight / lineHeight;
      // LOGGING &&
      //   console.log("numberOfLines", {
      //     textRef,
      //     textHeight,
      //     numberOfLines,
      //     lineHeight,
      //   });
      setTextOverflows(numberOfLines > 3);
    }
  }, []);

  const handleToggleDescription = (e) => {
    e.preventDefault();
    e.stopPropagation();
    LOGGING && console.log("handleToggleDescription");
    setShowFullDescription(!showFullDescription);
  };

  //   const toggleShowMore = (e)=>{
  //         e.preventDefault();
  //         e.stopPropagation();
  //         console.log('more');
  //     }

  return (
    <DescriptionContainer>
      <Text ref={textRef} showFull={showFullDescription}>
        {description}
      </Text>
      {textOverflows ? (
        <MoreButton onClick={handleToggleDescription}>
          {`${showFullDescription ? "less" : "more"}...`}
          <FontAwesomeIcon
            icon={showFullDescription ? faChevronUp : faChevronDown}
          />
        </MoreButton>
      ) : null}
    </DescriptionContainer>
  );
};

const DescriptionContainer = styled(CenteredByColumn)`
  position: relative;
  align-items: flex-start;
`;

// const Text = styled('p')<{ showFull: boolean }>`
//   font-size: 10px;
//   line-height: 1.5;
//   text-transform: none;
//   /* white-space: nowrap; */
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display: block;
//   margin-bottom: 5px;
//   ${({ showFull }) =>
//     !showFull &&
//     `
//     white-space: initial;
//     text-overflow: ellipsis;
//     display: block;
//   `}
//   @supports (-webkit-line-clamp: 3) {
//     ${({ showFull }) =>
//       !showFull &&
//       `
//       white-space: initial;
//       display: -webkit-box;
//       -webkit-line-clamp: 3;
//       -webkit-box-orient: vertical;
//     `}
//   }
// `;
interface TextProps {
  showFull: boolean;
}

const Text = styled.p<TextProps>`
  font-size: 13px;
  line-height: 1.5;
  text-transform: none;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ showFull }) =>
    showFull ? "none" : "3"}; /* Show all lines if showFull is true */
  -webkit-box-orient: vertical;
  margin-bottom: 5px;
  position: relative;
  font-weight: 500;
  color: ${TextGrey};

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    background: linear-gradient(to bottom, transparent, white 50%);
    pointer-events: none;
    ${({ showFull }) => (showFull ? "display: none;" : "")}
  }
  @media only screen and (min-width: 500px) {
    font-size: 15px;
  }
`;
const MoreButton = styled.button`
  font-size: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 20px;
  margin: 0px 0 15px 0;
  padding: 0;
  border-bottom: 1px solid black;
  svg {
    margin-left: 5px;
    display: none;
  }
`;

const truncateDescription = (description) => {
  return description.length > 50
    ? `${description.slice(0, 50)}...`
    : description;
};

export default DishDescription;
