import React from "react";
import { Link } from "react-router-dom";
import { AuthMode, LOGGING } from "src/constants";
import styled from "styled-components";
import {
  SectionHeader,
  List,
  Testimonial,
  Container,
  Name,
} from "./Testimonials";
import {
  CenteredByColumn,
  text12300,
  card,
  Centered,
  textTitle,
  BorderGrey,
  NavHeight,
  actionGrey,
  Beige,
  CenteredByColumnLeft,
  BackgroundPink,
  actionUnderline,
  Lavendar,
  BreakPoint as breakPoint,
} from "../Shared";

type BenefitProps = {
  mode?: number;
  ref?: any;
};
const SectionTitleHeight = 50;
const Benefit = styled(CenteredByColumnLeft)<BenefitProps>`
  margin-bottom: 20px;
  width: 100%;
  padding: 0px 20px;
  z-index: 5;
  position: relative;
  /* background-color: ${BackgroundPink}; */
  /* background-color: white; */
  > div:last-child {
    margin-bottom: 0px;
    &:after {
      display: none;
    }
  }
  @media only screen and (min-width: ${breakPoint}px) {
    right: 0px;
    left: auto;
    /* left: 500px; */
    position: absolute;
    /* top: calc(${NavHeight}px); */
    /* top: 3vw; */
    top: 0px;
    padding-top: 40px;
    width: 25vw;
    min-width: 420px;
    margin-top: 0px;
    background-color: ${Beige};
    align-self: auto;
    flex-grow: 0;
    min-height: calc(100% + 20px);
  }
`;
const HowItWorksContainer = styled(Container)`
  background-color: ${Beige};
  padding: 20px;
  align-items: center;
  button {
    margin-bottom: 40px;
  }
  @media only screen and (min-width: 800px) {
    background-color: white;
    align-items: flex-start;
    padding: 40px calc(8vw + 20px);
    /* padding-top: 0px;
    padding-bottom: 40px; */
    margin-top: 0px;
  }
`;

type SizeProps = {
  MobileOnly?: boolean;
  DesktopOnly?: boolean;
};
const Section = styled(CenteredByColumnLeft)`
  margin-bottom: 10px;
  /* background-color: white; */
  align-self: stretch;
  align-content: stretch;
  flex-grow: 1;
  position: relative;
  @media only screen and (min-width: ${breakPoint}px) {
    border-bottom: 1px solid rgb(219, 217, 217);
    flex-grow: 0;
    align-self: auto;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0; /* Set margin-bottom to 0 for the last child */
    }
  }
`;

const SectionIcon = styled(Centered)<SizeProps>`
  font-size: 40px;
  padding: 0px;
  flex-shrink: 0;
  /* align-self: stretch; */
  display: ${(props) => (props.DesktopOnly ? "none" : "flex")};
  @media only screen and (min-width: ${breakPoint}px) {
    display: ${(props) => (props.MobileOnly ? "none" : "flex")};
  }
`;
const SectionContent = styled(CenteredByColumnLeft)`
  padding: 10px 0px 20px 0px;
  width: 100%;
  /* border: 1px solid red; */
  > button {
    /* display: inline-block; */
    background-color: white;
    ${text12300}
    padding: 0px;
    margin: 0px;
    font-size: 13px;
    line-height: 1.8;
    border-bottom: 1px solid black;
    text-transform: none;
    @media only screen and (min-width: ${breakPoint}px) {
      background-color: transparent;
      border: none;
      line-height: 1.6;
      border-bottom: 1px solid black;
      margin: 10px 0px;
    }
  }
`;
const SectionCount = styled(Centered)`
  ${textTitle}
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 5px;
`;

const SectionWords = styled.span<SizeProps>`
  ${text12300}
  text-align: left;
  font-size: 13px;
  line-height: 1.8;
  display: ${(props) => (props.DesktopOnly ? "none" : "block")};
  width: 100%;
  @media only screen and (min-width: ${breakPoint}px) {
    display: ${(props) => (props.MobileOnly ? "none" : "block")};
  }
`;
const Step = styled(Testimonial)`
  /* background-color: ${Lavendar}; */
  position: relative;
  /* padding: 0px 20px; */
  align-items: flex-start;
  margin-bottom: 10px;
  padding: 0px 20px;

  @media only screen and (min-width: ${breakPoint}px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 400px;
    padding: 5px 20px;
    margin: 20px 40px 20px 0px;
    align-self: stretch;
    flex-grow: 1;
    background-color: ${Lavendar};
    /* border: 1px solid ${BorderGrey}; */
    // when it's the first child of its parent, no margin-top
    &:first-child {
      margin-top: 20px;
      margin-left: 0px;
    }
  }
`;

const StepIcon = styled(SectionIcon)<SizeProps>`
  display: ${(props) => (props.DesktopOnly ? "none" : "flex")};
  position: absolute;
  /* right: 30px; */
  top: 10px;
  left: auto;
  right: 40px;
  font-size: 35px;
  @media only screen and (min-width: ${breakPoint}px) {
    /* position: absolute; */
    font-size: 50px;
    line-height: 50px;
    top: 20px;
    right: 20px;
    /* width: 100%; */
    background-color: transparent;
    /* padding: 20px 0 0 0; */
    display: ${(props) => (props.MobileOnly ? "none" : "flex")};
    /* display: flex; */
  }
`;
const StepContent = styled(SectionContent)`
  @media only screen and (min-width: ${breakPoint}px) {
    padding: 10px 0px 20px 0px;
    position: relative;
  }
`;
const StepCount = styled(Name)<SizeProps>`
  display: ${(props) => (props.DesktopOnly ? "none" : "block")};
  @media only screen and (min-width: ${breakPoint}px) {
    margin-bottom: 10px;
    display: ${(props) => (props.MobileOnly ? "none" : "block")};
    /* padding-left: 65px; */
    line-height: 50px;
  }
`;
const StepWords = styled(SectionWords)<SizeProps>`
  @media only screen and (min-width: ${breakPoint}px) {
    display: ${(props) => (props.MobileOnly ? "none" : "block")};
  }
`;

export const HowItWorks = React.forwardRef((props, ref) => {
  // LOGGING && console.log("HowItWorks rendering with: ", props);
  //@ts-ignore
  return (
    <HowItWorksContainer>
      <SectionHeader ref={ref}>How It works</SectionHeader>
      <List>
        <Step>
          {/* <StepIcon MobileOnly={true}>🤳</StepIcon> */}
          {/*
           */}
          <StepContent>
            <StepCount>1. choose</StepCount>
            <StepWords DesktopOnly={true}>
              from a few pre-selected restaurants each meal-time.
            </StepWords>
            <StepWords MobileOnly={true}>
              from a few<br></br>pre-selected restaurants<br></br>each
              meal-time.
            </StepWords>
          </StepContent>
          <StepIcon>👩🏻‍💻</StepIcon>
          {/* <StepIcon DesktopOnly={true}>👩🏻‍💻</StepIcon> */}
          {/* <StepIcon MobileOnly={true}>📱</StepIcon> */}
        </Step>
        <Step>
          <StepContent>
            <StepCount DesktopOnly={true}>2. Order | Edit | Cancel</StepCount>
            <StepCount MobileOnly={true}>2. Order | Cancel</StepCount>
            <StepWords>
              lunch before 10:30am,
              <br></br>express dinner before 3:30pm,
              <br></br>dinner before 4:30pm.
            </StepWords>
          </StepContent>
          <StepIcon>⏰</StepIcon>
        </Step>
        <Step>
          {/* <StepIcon>🏠🚙🏡🍔</StepIcon> */}

          <StepContent>
            <StepCount>3. Receive</StepCount>
            <StepWords>
              lunch 11:30-12:30pm,
              <br></br>express dinner 4:30-5:30pm,
              <br></br>dinner 6-7pm.
            </StepWords>
          </StepContent>
          <StepIcon>🚙</StepIcon>
        </Step>
        {/* @ts-ignore */}
        {props?.auth}
        {/* <Link to="/faq">FAQ</Link> */}
      </List>
    </HowItWorksContainer>
  );
});
