import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  readGrowth,
  readRoutes,
  readRestaurantsStats,
  resetStats,
  readUsersStats,
  readOrderStats,
  setStatsType,
} from '../../store/actions';
import { LOGGING, Navbar, Stats } from './';

class PageStats extends Component {
  constructor(props) {
    LOGGING && console.log('PageStats constructor called with props:', props);
    super();
    this.state = {
      loading: true,
      data: null,
      readyForDownload: false,
      growthWindow: 'sliding 30 days',
    };
    this.revenueRef = React.createRef();
    this.ordersRef = React.createRef();
    this.usersRef = React.createRef();
    this.returningRef = React.createRef();
    this.growthRef = React.createRef();
    this.stopsRef = React.createRef();
    this.etaRef = React.createRef();
    this.restaurantsRef = React.createRef();
    this.handleSelectStatsType = this.handleSelectStatsType.bind(this);
    this.handleSelectGrowthWindow = this.handleSelectGrowthWindow.bind(this);
    this.processGrowth = this.processGrowth.bind(this);
    this.processRoutes = this.processRoutes.bind(this);
    this.loadStats = this.loadStats.bind(this);
  }

  handleSelectGrowthWindow(growthWindow, e) {
    e.preventDefault();
    this.setState({ growthWindow, loading: true }, () => {
      this.props.readGrowth(growthWindow).then(() => {
        this.setState({ data: this.processGrowth(), loading: false });
      });
    });
  }
  processGrowth() {
    const { stats } = this.props;
    let { growthWindow } = this.state;
    growthWindow = growthWindow[0].toUpperCase() + growthWindow.substring(1);

    const { dates, data } = stats.growth;
    LOGGING && console.log('processGrowth got stats:', stats);
    const revenue = data.map((s) => s.revenue || 0);
    const orders = data.map((s) => s.orders);
    const totalCustomers = data.map((s) => s.totalCustomers);
    const activeCustomers = data.map((s) => s.activeCustomers);
    const returningOrders = data.map((s) => s.returningOrders || 0);
    const users = data.map((s) => s.users);
    const returningUsers = data.map((s) => s.returningUsers || 0);
    const returningOrdersPerUser = returningOrders.map((orders, index) =>
      returningUsers[index] ? orders / returningUsers[index] : 0
    );

    const growthData = {
      growth: {
        labels: dates,
        datasets: [
          // {
          //   label: `${growthWindow === 'Sliding 30 days' ? 'Sliding 30-D' : growthWindow}${
          //     growthWindow === 'Sliding 30 days' ? '' : 'ly'
          //   } GMV (USD)`,
          //   data: revenue,
          //   fill: false,
          //   backgroundColor: 'rgb(95, 92, 255)',
          //   borderColor: 'rgba(95, 92, 255)',
          //   yAxisID: 'y',
          // },
          {
            label: `${growthWindow === 'Sliding 30 days' ? 'Sliding 30-D' : ''}${
              growthWindow === 'Sliding 30 days' ? '' : `${growthWindow}ly`
            } Active Users`,
            data: activeCustomers,
            fill: false,
            backgroundColor: 'rgb(248, 114, 17)',
            borderColor: 'rgba(248, 114, 17, 0.2)',
            yAxisID: 'y1',
          },
          // {
          //   label: `Total Customers`,
          //   data: users,
          //   fill: false,
          //   backgroundColor: 'rgb(157, 220, 255)',
          //   borderColor: 'rgba(157, 220, 255, 0.2)',
          //   yAxisID: 'y1',
          // },
        ],
      },
      revenue: {
        labels: dates,
        datasets: [
          {
            label: `${growthWindow}${growthWindow === 'Sliding 30-D' ? '' : 'ly'} GMV (USD)`,
            data: revenue,
            fill: false,
            // backgroundColor: 'rgb(95, 92, 255)',
            // borderColor: 'rgba(95, 92, 255, 0.2)',
            backgroundColor: 'rgb(95, 92, 255)',
            borderColor: 'rgba(95, 92, 255, 0.2)',
            yAxisID: 'y',
          },
        ],
      },
      users: {
        labels: dates,
        datasets: [
          {
            label: 'Total Users',
            data: users,
            fill: false,
            backgroundColor: 'rgb(248, 114, 17)',
            borderColor: 'rgba(248, 114, 17, 0.2)',
            yAxisID: 'y',
          },
          // {
          //   label: `${growthWindow}ly Returning Users`,
          //   data: returningUsers,
          //   fill: false,
          //   backgroundColor: 'rgb(157, 220, 255)',
          //   borderColor: 'rgba(157, 220, 255, 0.2)',
          //   yAxisID: 'y',
          // },
        ],
      },
      returningOrdersPerUser: {
        labels: dates,
        datasets: [
          {
            label: `${growthWindow}${
              growthWindow === 'Sliding 30 days' ? '' : 'ly'
            }/Returning User`,
            data: returningOrdersPerUser,
            fill: false,
            backgroundColor: 'rgb(48, 150, 122)',
            borderColor: 'rgba(48, 150, 122, 0.2)',
            yAxisID: 'y',
          },
        ],
      },
      orders: {
        labels: dates,
        datasets: [
          {
            label: `${growthWindow}${growthWindow === 'Sliding 30 days' ? '' : 'ly'} Orders`,
            data: orders,
            fill: false,
            backgroundColor: 'rgb(255, 154, 215)',
            borderColor: 'rgba(255, 154, 215, 0.2)',
            yAxisID: 'y',
          },
          // {
          //   label: `${growthWindow}${
          //     growthWindow === 'Sliding 30 days' ? '' : 'ly'
          //   } Orders By Returning Users`,
          //   data: returningOrders,
          //   fill: false,
          //   backgroundColor: 'rgb(95, 92, 255)',
          //   borderColor: 'rgba(95, 92, 255, 0.2)',
          //   yAxisID: 'y',
          // },
        ],
      },
    };
    return growthData;
  }

  processRoutes() {
    const { stats } = this.props;
    LOGGING && console.log('processRoutes got stats:', stats);
    let { stops, eta } = stats.routes;
    const stopsSum = Object.values(stops).reduce((a, b) => a + b, 0);
    const etaSum = Object.values(eta).reduce((a, b) => a + b, 0);
    for (const n in stops) {
      stops[n] = stops[n] / stopsSum;
    }

    for (const n in eta) {
      eta[n] = eta[n] / etaSum;
    }
    LOGGING && console.log('processRoutes got labels:', Object.keys(stops));
    const routesData = {
      stops: {
        labels: Object.keys(stops),
        datasets: [
          {
            label: 'stops per route histogram',
            data: stops,
            fill: false,
            backgroundColor: 'rgb(255, 154, 215, 0.6)',
            yAxisID: 'yStops',
            xAxisID: 'xStops',
          },
        ],
      },
      eta: {
        labels: Object.keys(eta),
        datasets: [
          {
            label: 'ETA (min) of adjacent stops',
            data: eta,
            fill: false,
            backgroundColor: 'rgb(95, 92, 255, 0.6)',
            yAxisID: 'yETA',
            xAxisID: 'xETA',
          },
        ],
      },
    };

    return routesData;
  }

  processRestaurants() {
    const { stats } = this.props;
    LOGGING && console.log('processRestaurants got stats:', stats);
    let { lunchOrders, dinnerOrders } = stats.restaurants;
    LOGGING && console.log('processRestaurants got orders:', lunchOrders, dinnerOrders);
    LOGGING && console.log('processRestaurants got labels:', Object.keys(lunchOrders));
    // const labels = ["1~5", "6~10", "11~15", "16~20", "21~30"];
    const restaurantsData = {
      labels: Object.keys(lunchOrders),
      datasets: [
        {
          label: 'Lunch',
          data: lunchOrders,
          fill: true,
          backgroundColor: 'rgb(255, 154, 215, 0.6)',
          // yAxisID: "yRestaurants",
          // xAxisID: "xRestaurants",
        },
        {
          label: 'Dinner',
          data: dinnerOrders,
          fill: false,
          backgroundColor: 'rgb(25, 154, 115, 0.6)',
          // yAxisID: "yRestaurants",
          // xAxisID: "xRestaurants",
        },
      ],
    };

    return restaurantsData;
  }

  processUsers() {
    const { stats } = this.props;
    const { ordersByUser } = stats;
    LOGGING && console.log('processUsers got ordersByUser:', ordersByUser);
    const { dates, data } = ordersByUser;

    const usersData = Object.keys(data).map((name) => ({
      labels: dates.map((d) => d.split(':')[1]),
      datasets: [
        {
          label: name,
          data: data[name],
          fill: true,
          backgroundColor: 'rgb(255, 154, 215)',
          borderColor: 'rgba(255, 154, 215, 0.2)',
          yAxisID: 'yUsers',
          // yAxisID: 'yUsers',
          xAxisID: 'xUsers',
        },
      ],
    }));
    LOGGING && console.log('processUsers returning:', usersData);
    return usersData;
  }

  loadStats() {
    const { statsType } = this.props;
    this.setState({ loading: true }, () => {
      if (statsType === 'growth') {
        this.props.readGrowth(this.state.growthWindow).then(() => {
          this.setState({ data: this.processGrowth(), loading: false });
        });
      } else if (statsType === 'routes') {
        LOGGING && console.log('routes selected');
        this.props.readRoutes().then(() => {
          LOGGING && console.log('readRoutes done with', this.props.stats);
          this.setState({ data: this.processRoutes(), loading: false });
          this.setState({ loading: false });
        });
      } else if (statsType === 'restaurants') {
        LOGGING && console.log('routes selected');
        this.props.readRestaurantsStats().then(() => {
          LOGGING && console.log('readRestaurantsStats done with', this.props.stats);
          this.setState({ data: this.processRestaurants(), loading: false });
        });
      } else if (statsType === 'users') {
        LOGGING && console.log('users selected');
        this.props.readOrderStats().then((result) => {
          LOGGING && console.log('readOrderStats done with result:', result);
          LOGGING && console.log('readOrderStats done with:', this.props.stats);
          this.setState({ data: this.processUsers(), loading: false });
          this.setState({ loading: false });
        });
      }
    });
  }

  handleSelectStatsType(statsType, e) {
    e.preventDefault();
    LOGGING && console.log('handleSelectStatType called with:', statsType);
    this.props.setStatsType(statsType);
    this.loadStats();
    // if (stats[statsType]) {
    //   this.setState({ loading: true }, () => {
    //     this.setState(
    //       {
    //         statsType,
    //         data:
    //           statsType === 'routes'
    //             ? this.processRoutes()
    //             : statsType === 'growth'
    //             ? this.processGrowth()
    //             : statsType === 'restaurants'
    //             ? this.processRestaurants()
    //             : statsType === 'users'
    //             ? this.processUsers()
    //             : { type: statsType },
    //       },
    //       () => this.setState({ loading: false })
    //     );
    //   });

    //   return;
    // }
  }

  componentDidMount() {
    LOGGING && console.log('PageStats componentdidmount');
    // this.setState({ loading: true }, () => {
    //   this.props.resetStats();
    //   // this.props.readGrowth(this.state.growthWindow).then(() => {
    //   //   this.setState({ data: this.processGrowth(), loading: false });
    //   // });
    // });
    this.loadStats();
  }

  render() {
    LOGGING &&
      console.log('PageStats rendering with', {
        props: this.props,
        state: this.state,
      });
    const { loading, data, growthWindow } = this.state;
    const { statsType } = this.props;
    return (
      <div className="page">
        <Navbar noShadow={true} />
        <Stats
          loading={loading}
          data={data}
          statsType={statsType}
          growthWindow={growthWindow}
          onSelectGrowthWindow={this.handleSelectGrowthWindow}
          onSelectStatsType={this.handleSelectStatsType}
          refs={{
            orders: this.ordersRef,
            revenue: this.revenueRef,
            users: this.usersRef,
            returning: this.returningRef,
            growth: this.growthRef,
            stops: this.stopsRef,
            eta: this.etaRef,
            restaurants: this.restaurantsRef,
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log('PageStats got redux state:', state);
  return {
    stats: state.stats,
    statsType: state.statsType,
  };
}

export default connect(mapStateToProps, {
  readGrowth,
  readRoutes,
  readRestaurantsStats,
  resetStats,
  readUsersStats,
  readOrderStats,
  setStatsType,
})(PageStats);
