import { ChangeEvent, useCallback, useState } from 'react';
import { Draft, produce } from 'immer';
import { cloneDeep, set } from 'lodash';

type UseFormOptions<T> = {
  transform?: (formData: T) => void;
  onChange?: (name: string, value: any) => void;
};

export const useForm = <T extends object = object>(
  initialValue?: T,
  useFormOptions?: UseFormOptions<T>
) => {
  const [formData, setFormData] = useState<T>(initialValue ?? ({} as T));

  const updateFormData = useCallback((updatedFormDataFn: (formData: Draft<T>) => void) => {
    setFormData((formData) => {
      return produce<T>(formData, (draft) => {
        updatedFormDataFn(draft);
        useFormOptions?.transform?.(draft as T);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      const { name, value } = event.currentTarget;
      const copiedFormData = cloneDeep(formData);
      set(copiedFormData, name, value);
      useFormOptions?.transform?.(copiedFormData);
      setFormData(copiedFormData);
      useFormOptions?.onChange?.(name, value);
    },
    [formData, setFormData, useFormOptions]
  );

  return { formData, setFormData, updateFormData, onInputChange };
};
