import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import { fetchUserByName, fetchBookmarks, fetchPosts } from '../../store/actions';
import {
  BookmarkStatus,
  LOGGING,
  Profile,
  BookmarkList,
  Post,
  Loading,
  Footer,
  ConfirmAlert,
} from './';

class PageProfile extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      showUserMenu: false,
      likedByUser: [],
      postedByUser: [],
      userProfile: {},
      showTab: 'recommendations',
      showRecommenders: null,
    };
    this.handleSelectTab = this.handleSelectTab.bind(this);
    this.handleSetBookmark = this.handleSetBookmark.bind(this);
    this.handleCreateBookmark = this.handleCreateBookmark.bind(this);
    this.handleDeletePost = this.handleDeletePost.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleShowRecommenders = this.handleShowRecommenders.bind(this);
    this.handleHideRecommenders = this.handleHideRecommenders.bind(this);
  }
  handleShowRecommenders(postId, e) {
    e.preventDefault();
    LOGGING && console.log('handleShowRecommenders called with:', postId);
    this.setState({ showRecommenders: postId });
  }
  handleHideRecommenders(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showRecommenders: null });
  }
  handleGoBack(e) {
    e.preventDefault();
    // this.props.history.goBack();
    this.props.history.push('/');
  }
  handleSetBookmark(bookmark, status, e) {
    e.preventDefault();
    LOGGING && console.log('handleSetBookmark called with bookmark: ', bookmark);
    const { _id } = bookmark;
    this.setState({ loading: true }, () => {
      this.props
        .updateBookmark({
          _id,
          bookmark: { status },
          updateAfterDone: 'posts',
        })
        .then(() => {
          this.setState({ loading: false });
        });
    });
  }

  handleCreateBookmark(userId, postId, e) {
    e.preventDefault();
    LOGGING && console.log('handleCreateBookmark called with: ', { userId, postId });
    this.setState({ loading: true }, () => {
      this.props.createBookmark({ userId, postId }).then(() => {
        this.setState({ loading: false });
      });
    });
  }

  handleToggleUserMenu(e) {
    e.preventDefault();
    const { showUserMenu } = this.state;
    this.setState({ showUserMenu: !showUserMenu });
  }

  handleSignOut(e) {
    e.preventDefault();
    this.setState({ showUserMenu: false }, () => {
      this.props.signOut();
      this.props.history.push('/');
    });
  }
  handleSelectTab(tab, e) {
    e.preventDefault();
    this.setState({ showTab: tab });
  }
  handleDeletePost(postId, e) {
    e.preventDefault();
    LOGGING && console.log('handleDeletePost called with postId:', postId);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmAlert
            onConfirm={this.handleConfirmDeletePost.bind(this, postId)}
            onClose={onClose}
            actionName="delete"
          />
        );
      },
    });
  }

  componentDidMount() {
    const { userName } = this.props.match.params;
    LOGGING && console.log('PageProfile componentDidMount with:', { userName });
    this.setState({ loading: true }, () => {
      this.props.fetchUserByName(userName).then((user) => {
        LOGGING && console.log('PageProfile found user:', user);
        this.props.fetchBookmarks({ userId: user._id }).then(() => {
          this.props.fetchPosts({ userId: user._id, fresh: true }).then(() => {
            const { bookmarks, posts: postedByUser } = this.props;
            const likedByUser = bookmarks.filter((b) => b.status === BookmarkStatus.LIKED);
            LOGGING &&
              console.log('PageProfile componentDidMount got:', {
                postedByUser,
                likedByUser,
              });
            const showTab =
              postedByUser.payLoad.length > 0
                ? 'recommendations'
                : likedByUser.length > 0
                ? 'likes'
                : 'recommendations';

            this.setState(
              {
                loading: false,
                userProfile: user,
                likedByUser,
                postedByUser: postedByUser.payLoad,
                showTab,
              },
              () => {
                window.scrollTo(0, 0);
              }
            );
          });
        });
      });
    });
    // }
  }

  render() {
    const { loading, likedByUser, postedByUser, userProfile, showTab, showRecommenders } =
      this.state;
    const { currentUser } = this.props;
    LOGGING &&
      console.log('PageProfile rendering with', {
        props: this.props,
        state: this.state,
        likedByUser,
      });

    // if (!currentUser.isAuthenticated) return <Redirect to="/auth" />;
    return (
      <div className="page profile">
        {loading ? (
          <Loading />
        ) : (
          <React.Fragment>
            <Profile
              user={userProfile}
              likeCount={likedByUser.length}
              recommendationCount={postedByUser.length}
              showTab={showTab}
              onSelectTab={this.handleSelectTab}
              onBack={this.handleGoBack}
            />
            <div className="list-wrapper">
              {showTab === 'likes' ? (
                <BookmarkList
                  bookmarks={likedByUser}
                  onShowRecommenders={this.handleShowRecommenders}
                  onHideRecommenders={this.handleHideRecommenders}
                  showRecommenders={showRecommenders}
                />
              ) : (
                postedByUser.map((post) => (
                  <Post
                    key={post._id}
                    post={post}
                    currentUser={currentUser}
                    onSetBookmark={this.handleSetBookmark}
                    onCreateBookmark={this.handleCreateBookmark}
                    onDelete={this.handleDeletePost.bind(this, post._id)}
                    showRecommenderTitle={true}
                  />
                  // <Post key={post._id} post={post} currentUser={currentUser} />
                ))
              )}
            </div>
          </React.Fragment>
        )}
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log('PageProfile mapStateToProps got state:', state);
  return {
    posts: state.posts,
    bookmarks: state.bookmarks,
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  fetchUserByName,
  fetchBookmarks,
  fetchPosts,
})(PageProfile);
