import './OrderList.scss';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { Back, Loading, LOGGING, MealDate, CATimeZone, TeamOrderHighlight } from '.';
const MealHeader = ({ meal, isLunch }) => (
  <div className="meal-type">
    <MealDate time={moment(meal.windowStart).tz(CATimeZone)} />
    <span className="order-type">{`${isLunch ? 'Lunch' : 'Dinner'}`}</span>
  </div>
);
const MealHighlight = ({ meal }) => (
  <div className="receipt-section order-highlight">
    <div className="receipt-row restaurant">
      <label>restaurant</label>
      <span className="highlight">{meal.restaurant}</span>
    </div>
    <div className="receipt-row dishes">
      <label>orders</label>
      <span className="highlight">{`${meal.orders.length}`}</span>
    </div>
  </div>
);
const TeamMealThumbnail = ({ meal }) => {
  LOGGING &&
    console.log(
      'TeamMealThumbnail rendering meal:',
      moment(meal.windowStart).tz(CATimeZone).hour()
    );
  const isLunch = moment(meal.windowStart).tz(CATimeZone).hour() < 12;
  return (
    <div className={`order-thumbnail ${isLunch ? 'lunch' : 'dinner'}`}>
      <div className="receipt">
        <MealHeader meal={meal} isLunch={isLunch} />
        <div className="receipt-body">
          <MealHighlight meal={meal} />
        </div>
        <div className="order-action">
          <Link to={`/team/${meal._id}`} className="view-order">
            <span>view details</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

const TeamOrderDetail = ({ order, onShow, onHide }) => (
  <div className="order-details">
    <div className="receipt">
      <div className="receipt-body">
        <div className="receipt-section">
          <div className="receipt-row">
            <label>{order.name}</label>
            <button onClick={order.showDetails ? onHide : onShow}>
              <FontAwesomeIcon icon={order.showDetails ? faChevronUp : faChevronDown} />
            </button>
          </div>
        </div>
        {order.showDetails ? <TeamOrderHighlight order={order} /> : null}
      </div>
    </div>
  </div>
);
const TeamMealSummary = ({ restaurant, windowStart, orders }) => (
  <div className="order-details">
    <div className="receipt">
      <div className="receipt-body">
        <div className="receipt-section">
          <div className="receipt-row">
            <label>restaurant</label>
            <span className="highlight">{restaurant}</span>
          </div>
          <div className="receipt-row">
            <label>date</label>
            <span className="highlight">{moment(windowStart).format('ddd, MMM DD YYYY')}</span>
          </div>
          <div className="receipt-row">
            <label>meal</label>
            <span className="highlight">
              {moment(windowStart).hour() < 12 ? 'lunch' : 'dinner'}
            </span>
          </div>
          <div className="receipt-row">
            <label>orders</label>
            <span className="highlight">{orders}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export const TeamOrderList = ({
  orders,
  restaurant,
  windowStart,
  onBack,
  onShowDetails,
  onHideDetails,
}) => (
  <div className="list order-list">
    <div className="list-title">
      <span>
        {restaurant} | {new Date(windowStart).toDateString()}{' '}
      </span>
      <Back onClick={onBack} />
    </div>

    <TeamMealSummary restaurant={restaurant} windowStart={windowStart} orders={orders.length} />

    {orders ? (
      orders.map((o) => (
        <TeamOrderDetail
          order={o}
          onShow={onShowDetails.bind(this, o._id)}
          onHide={onHideDetails.bind(this, o._id)}
        />
      ))
    ) : (
      <Loading />
    )}
  </div>
);
const TeamMealList = ({ meals, onBack, view, onSetView }) => {
  LOGGING && console.log('TeamMealList rendering with:', { view, meals, onSetView });
  if (!meals) return <Loading />;
  // const today = moment(Date.now()).startOf('day').valueOf();
  const mealsToDisplay =
    view === 'upcoming'
      ? meals
          .filter(
            (m) =>
              // moment().diff(moment(m.windowStart), 'd') <= 0)
              moment().diff(moment(m.windowStart), 'h') <= 2.5
          )
          .sort((a, b) => (a.windowStart > b.windowStart ? 1 : -1))
      : meals
          .filter(
            (m) =>
              // moment().diff(moment(m.windowStart), 'd') > 0)
              moment().diff(moment(m.windowStart), 'h') > 2.5
          )
          .sort((a, b) => (a.windowStart < b.windowStart ? 1 : -1));
  return (
    <div className="list with-banner-on-top order-list">
      <div className="list-title">
        <Back onClick={onBack} />
        <span>{"My Team's Orders"}</span>
      </div>
      <div className="set-view">
        <button
          className={`view-button ${view === 'upcoming' ? 'selected' : ''}`}
          type="button"
          onClick={onSetView.bind(this, 'upcoming')}
        >
          <span>upcoming</span>
        </button>
        <button
          className={`view-button ${view === 'delivered' ? 'selected' : ''}`}
          type="button"
          onClick={onSetView.bind(this, 'delivered')}
        >
          <span>delivered</span>
        </button>
      </div>
      {mealsToDisplay.map((m) => (
        <TeamMealThumbnail meal={m} />
      ))}
    </div>
  );
};

export default TeamMealList;
