import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { StopsMap, CATimeZone, LOGGING } from "./";
import "./RestaurantHistory.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
const OneMeal = ({ meal, index, restaurant, onToggleShowMap }) => {
  const { orders, showMap, mealTime, daysSinceLast } = meal;
  const { summary, meals, mealType, weekDay } = mealTime;
  let combinedStops = [];
  LOGGING && console.log("OneMeal rendering with:", { summary, orders });
  orders
    .filter((o) => o.address)
    .forEach((stop) => {
      const { address, userGroup, name, user } = stop;
      const existingCombinedStopIndex = combinedStops.findIndex(
        (s) => s.address === address
      );
      if (existingCombinedStopIndex > -1) {
        if (stop.stop == null) {
          combinedStops[existingCombinedStopIndex].unassignedOrders = true;
        }
        combinedStops[existingCombinedStopIndex].nameList.push(stop._id);
      } else {
        combinedStops.push({
          ...stop,
          name: userGroup
            ? userGroup.name
            : name && name.trim().length > 0
            ? name
            : user?.firstName,
          nameList: [stop._id],
        });
      }
    });
  return (
    <div className="restaurant-meal">
      <div className="restaurant-summary">
        <div className="meal-summary">
          <span className="meal-time">
            {moment(meal.windowStart).tz(CATimeZone).format("ddd MMM DD, YYYY")}
          </span>
          <span className="meal-orders">{`${meal.orders.length} orders`}</span>
          <span className="meal-total">${meal.sales.toFixed(2)}</span>
          <button className="toggle-map" onClick={onToggleShowMap}>
            map
            <FontAwesomeIcon icon={showMap ? faChevronUp : faChevronDown} />
          </button>
        </div>
        {meal.showMap && (
          <div className="meal-stops">
            <StopsMap
              stops={combinedStops.map((o) => {
                const {
                  address,
                  map,
                  user,
                  name,
                  driver,
                  stop: stopIndex,
                  etaAtRestaurant: eta,
                  _id,
                  window,
                  nameList,
                  groupName,
                  unassignedOrders,
                } = o;

                return {
                  address,
                  map,
                  name,
                  groupName,
                  nameList,
                  driverColor: driver ? driver.profileColor : "grey",
                  driverId: driver ? driver._id : null,
                  selectedDriverTotalStops: driver
                    ? combinedStops.filter(
                        (o) => o.driver && o.driver._id === driver._id
                      ).length
                    : 0,
                  stopIndex,
                  eta,
                  _id,
                  unassignedOrders,
                };
              })}
              restaurant={restaurant}
              drivers={[]}
            />
          </div>
        )}
      </div>
      <div className={`block-summary  ${mealType}`}>
        <div
          className={`meal-time ${mealType}`}
        >{`${weekDay} ${mealType}`}</div>
        <div className="participant-meal">
          <span className="restaurant">summary</span>
          <span className={`revenue ${summary.volumn}`}>
            ${summary.revenue.toFixed(2)}
          </span>
          <span className="orders">{summary.orders}</span>
          {summary.profit ? (
            <span className={`profit ${summary.profitability}`}>
              ${summary.profit.toFixed(2)}
            </span>
          ) : null}
        </div>
        {meals.map((m) => (
          <div
            className={`participant-meal ${m._id === meal._id ? "self" : ""}`}
          >
            <div className="restaurant">
              {/* <span>{m.restaurant}</span> */}
              {m._id === meal._id ? (
                <span>{m.restaurant}</span>
              ) : (
                <Link
                  className="history-link"
                  to={`/restaurant/${m.restaurantId}/history`}
                >
                  {m.restaurant}
                </Link>
              )}
            </div>

            <span className="revenue">${m.revenue.toFixed(2)}</span>
            <span className="orders">{m.orders}</span>
            <span className="profit"></span>
          </div>
        ))}
        <span className="days-since-last">{`Gap: ${daysSinceLast} days`}</span>
      </div>
    </div>
    // </div>
  );
};
const RestaurantHistory = ({
  meals,
  restaurant,
  onToggleShowMap,
  total,
  average,
  onClose,
}) => {
  return (
    <div className="restaurant-history">
      <div className="restaurant-summary receipt">
        <button onClick={onClose} className="close-history">
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <div className="restaurant-name">{restaurant.name}</div>
        <div className="meal-count receipt-row">
          <label>deliveries</label>
          {meals.length}
        </div>
        <div className="meal-count receipt-row">
          <label>total</label>$
          {total.toLocaleString(undefined, { maximumFractionDigits: 2 })}
        </div>
        <div className="meal-count receipt-row">
          <label>average</label>$
          {average.toLocaleString(undefined, { maximumFractionDigits: 2 })}
        </div>
      </div>
      {meals.map((meal, index) => (
        <OneMeal
          meal={meal}
          index={index}
          key={index}
          onToggleShowMap={onToggleShowMap.bind(this, index)}
          restaurant={restaurant}
        />
      ))}
    </div>
  );
};

export default RestaurantHistory;
