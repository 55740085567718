import { useEffect, useState } from "react";
import { faCcVisa, faCcMastercard } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { ContactContainer, Container, Field, Title } from "./Contact";
import { Card, Centered } from "../../Shared";
const CardLogo = ({ brand }) => {
  return (
    <FontAwesomeIcon icon={brand === "visa" ? faCcVisa : faCcMastercard} />
  );
};
export const Payment = () => {
  const currentUser = useSelector((state: RootState) => state.currentUser);
  return (
    <ContactContainer>
      <Title>Payment</Title>
      <Field>
        <span>
          <CardLogo brand={currentUser?.user?.stripeInfo?.card?.brand} />
          ...{currentUser?.user?.stripeInfo?.card?.last4}
        </span>
      </Field>
    </ContactContainer>
  );
};
