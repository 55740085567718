// Combines arr1 and arr2. Arr2 takes precedence and overwrites values of arr1
// based on attrReducer
export const combineArrays = (arr1, arr2, attrReducer) => {
  const dict = {};

  arr2.forEach((el) => {
    const elAttr = attrReducer(el);
    dict[elAttr] = true;
  });

  const filteredArr1 = arr1.filter((el) => {
    const elAttr = attrReducer(el);
    if (dict[elAttr] === true) {
      return false;
    }

    return true;
  });

  return [...filteredArr1, ...arr2];
};
