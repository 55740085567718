import React, { ReactElement, useEffect, useState } from "react";
import {
  faHouse,
  faTruck,
  faChevronRight,
  faChevronLeft,
  faInfoCircle,
  faCheck,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { useAsync } from "react-async";
import { useSelector, useDispatch, useStore } from "react-redux";
import { Link } from "react-router-dom";
import { GetReferrerFromCode } from "src/api/referrals";
import { CheckoutBanner } from "src/screens/tasting_menu/components";
import { RootState } from "src/store";
import { addGoodsToCart } from "src/store/actions";
import styled, { keyframes } from "styled-components";
import { LOGGING, SampleRestaurantsMobile, CoverImages } from "../";
import { DishChooserContainer } from "../dish/DishChooserContainer";
import { breakPoint } from "../referrals/MyReferralCodeEventMember";
import {
  CenteredByColumn,
  Text12300,
  CenteredByColumnLeft,
  BorderGrey,
  Centered,
  Yellow,
  Beige,
  Grey,
  Pink,
  text12300,
  card,
  centeredByColumn,
  NavHeight,
  textTitle,
  centered,
  ActionBlack,
  actionBlack,
  actionGrey,
  TextTitle,
} from "../Shared";
const screenWidth = 250;
const screenHeightRatio = 2;
const screenShiftH = 100;
const screenShiftV = 15;
export interface ContentContainerProps {
  withNav?: boolean;
}
const ContentContainer = styled.div`
  position: relative;
  display: none;

  @media only screen and (min-width: 800px) {
    display: block;
    width: ${2 * screenShiftH + screenWidth}px;
    height: ${3 * screenShiftV + screenWidth * screenHeightRatio}px;
    margin-right: 20px;
  }
  @media only screen and (min-width: 840px) {
    margin-right: 40px;
  }
`;
const TagLineContainer = styled(Text12300)`
  /* background-color: red; */
`;
export const TagLine = () => (
  <TagLineContainer>
    {/* Getting restaurant food delivered for the same price as eating at restaurant. */}
    30% cheaper
  </TagLineContainer>
);
export interface ImageProps {
  src: string;
  index: number;
}
const Image = styled.div<ImageProps>`
  flex-shrink: 0;
  background-image: url(${(props) => props.src});
  width: ${screenWidth}px;
  height: ${screenWidth * screenHeightRatio}px;
  background-position: center;
  background-size: cover;
  display: block;
  position: absolute;
  top: ${(props) => `${props.index * screenShiftV}px`};
  left: ${(props) => `${props.index * screenShiftH}px`};
  z-index: ${(props) => props.index};
  background-color: transparent;
  border-radius: 40px;
  /* box-shadow: 0 16px 4px 4px rgba(28, 29, 33, 0.5); */
`;

export interface CoverSlidingProps {}
export const CoverSliding: React.FC<CoverSlidingProps> =
  ({}: CoverSlidingProps): ReactElement => {
    return (
      <ContentContainer>
        {CoverImages.map((image, index) => (
          <Image src={image} key={index} index={index}></Image>
        ))}
      </ContentContainer>
    );
  };

const SlideWidth = 180;
export const SlideHeight = 180;
const desktopRatio = 1.4;
const SlideMargin = 0;
const n = 43;

const slideAnimation = keyframes`
  0% {
    transform: translateX(0);
  }  
  100% {
    transform: translateX(calc(100vw - ${n * (SlideWidth + SlideMargin)}px));
  }
`;
const slideAnimationDesktop = keyframes`
  0% {
    transform: translateX(0);
  }  
  100% {
    transform: translateX(calc(100vw - ${
      n * (SlideWidth * desktopRatio + SlideMargin)
    }px));
  }
`;
const slideAnimationLargeDesktop = keyframes`
  0% {
    transform: translateX(0);
  }  
  100% {
    transform: translateX(calc(100vw - ${n} * 45vh));
  }
`;
const large = 100 - 110 * n;
const slideAnimationLarge = keyframes`
  0% {
    transform: translateX(0);
  }  
  100% {    
    transform: translateX(${large}vw);
  }
`;
export interface SlidesContainerProps {
  isLarge?: boolean;
}
// export const largeHeight = "max(45vh, calc(100vh - 400px))";
export const largeHeight = "45vh";
export const SlidesContainer = styled.div<SlidesContainerProps>`
  /* height: 10px; */
  display: ${(props) => (props.isLarge ? "none" : "flex")};
  height: ${(props) => (props.isLarge ? largeHeight : `${SlideHeight}px`)};
  width: 100vw;
  position: fixed;
  top: 0px;
  left: 0px;
  flex-direction: row;
  justify-content: flex-start;
  animation: ${(props) =>
      props.isLarge ? slideAnimationLarge : slideAnimation}
    ${n * 3}s linear infinite;
  /* border: 1px solid ${(props) => (props.isLarge ? "red" : "yellow")};     */
  @media only screen and (min-width: 800px) and (min-height: 700px) {
    display: flex;
    height: ${(props) =>
      props.isLarge ? largeHeight : `${SlideHeight * desktopRatio}px`};
    animation: ${(props) =>
        props.isLarge ? slideAnimationLargeDesktop : slideAnimationDesktop}
      ${n * 3}s linear infinite;
  }
`;
export interface RestaurantCoverProps {
  src: string;
  isLarge: boolean;
}
const RestaurantCover = styled.div<RestaurantCoverProps>`
  width: ${(props) => (props.isLarge ? `110vw` : `${SlideWidth}px`)};
  /* height: ${SlideHeight}px; */
  /* height: ${(props) => (props.isLarge ? `50vh` : `${SlideHeight}px`)}; */
  height: 100%;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  /* border-radius: 50%; */
  flex-shrink: 0;
  /* border: 1px; */
  position: relative;
  @media only screen and (min-width: 800px) and (min-height: 700px) {
    height: ${(props) =>
      props.isLarge ? largeHeight : `${SlideHeight * desktopRatio}px`};
    width: ${(props) =>
      props.isLarge ? largeHeight : `${SlideWidth * desktopRatio}px`};
  }
`;
export interface OverlayProps {
  isLarge: boolean;
}
const Overlay = styled(Centered)<OverlayProps>`
  width: 100%;
  /* height: ${SlideHeight}px; */
  height: 100%;
  /* border-radius: 50%; */
  flex-shrink: 0;
  /* border: 1px; */
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  ${text12300}
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 1px;
  text-align: center;
  line-height: 1.3;
  padding: 10px;
  @media only screen and (min-width: 800px) and (min-height: 700px) {
    height: ${(props) =>
      props.isLarge ? largeHeight : `${SlideHeight * desktopRatio}px`};
    width: ${(props) =>
      props.isLarge ? largeHeight : `${SlideWidth * desktopRatio}px`};
  }
`;
const Title = styled(CenteredByColumn)<OverlayProps>`
  position: absolute;
  left: 0px;
  top: 0px;
  /* height: ${largeHeight}; */
  display: ${(props) => (props.isLarge ? "none" : "flex")};
  color: white;
  z-index: 1;
  ${text12300}
  width: 100vw;
  text-align: center;
  text-transform: uppercase;
  /* padding-top: ${NavHeight}px; */
  height: ${largeHeight};
  padding-bottom: calc(${largeHeight} + 350px + ${NavHeight}px - 100vh);
  h1 {
    font-size: 32px;
    font-weight: 500;
    line-height: 1.2;
    margin: 0px;
    /* line-height: ${largeHeight};   */
  }
  h3 {
    font-size: 14px;
    margin: 0 0 10px 0;
    font-weight: 500;
    line-height: 1;
  }
  // small
  @media only screen and (max-height: 600px) {
    top: ${NavHeight / 2}px;
    height: auto;
    justify-content: center;
    padding-bottom: 0px;
    h1 {
      br {
        display: none;
      }
    }
  }
  // desktop
  @media only screen and (min-width: 800px) and (min-height: 700px) {
    padding-bottom: 0px;
    display: flex;
    height: ${largeHeight};
    h1 {
      br {
        display: none;
      }
    }
  }
`;
const TopContainer = styled.div`
  /* position: fixed; */
  /* width: 100%; */
  height: 100vh;
  top: 0px;
  left: 0px;
  overflow: hidden;
`;
export interface AnonymousRestaurantSlidesProps {
  title?: string;
  isLarge?: boolean;
  subtitle?: string;
  noText?: boolean;
}

export const AnonymousRestaurantSlides: React.FC<AnonymousRestaurantSlidesProps> =
  ({ isLarge, title, subtitle, noText }): ReactElement => {
    return (
      <TopContainer>
        {title && subtitle ? (
          <Title isLarge={isLarge}>
            <h3>{subtitle}</h3>
            <h1>
              get<br></br> free food.
            </h1>
          </Title>
        ) : null}
        <SlidesContainer isLarge={isLarge}>
          {SampleRestaurantsMobile.map(({ image, name }, index) => (
            <RestaurantCover key={index} src={image} isLarge={isLarge}>
              <Overlay isLarge={isLarge}>
                {title || noText ? null : name}
              </Overlay>
            </RestaurantCover>
          ))}
        </SlidesContainer>
      </TopContainer>
    );
  };
