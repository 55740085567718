import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { GetIsRouteDirtyForDriverAndStops } from "src/api/routes";
import { cancelDriver, createRoute } from "src/store/actions/adminDeliveryV2";
import {
  calculateEtaForDriver,
  reverseRoute,
} from "src/store/actions/adminDeliveryV2";
import { ADMIN_DELIVERY_V2_SET_DRIVER_FILTER } from "src/store/actionTypes";
import { mealMarkerByMealId } from "src/util/stopMap";
import { AdminDeliveryV2DriverActions } from "./AdminDeliveryV2DriverActions";

export const AdminDeliveryV2DriverActionsContainer = ({ driver }) => {
  const driverFilter = useSelector(
    (state) => state.adminDeliveryV2.filters?.driver
  );
  const stops = useSelector((state) => state.adminDeliveryV2.stops);
  const isLunch = useSelector((state) => state.adminDeliveryV2.filters.isLunch);
  const mealType = useSelector(
    (state) => state.adminDeliveryV2.filters.mealType
  );

  const dispatch = useDispatch();
  const store = useStore();

  // Filter
  const isFiltered = driverFilter === driver._id;
  const toggleFilterStopsByDriver = useCallback(() => {
    if (isFiltered) {
      dispatch({ type: ADMIN_DELIVERY_V2_SET_DRIVER_FILTER, driverId: null });
    } else {
      dispatch({
        type: ADMIN_DELIVERY_V2_SET_DRIVER_FILTER,
        driverId: driver._id,
      });
    }
  }, [dispatch, driver._id, isFiltered]);

  // Calculate ETA
  const calculateEtaForDriverCallback = useCallback(() => {
    calculateEtaForDriver(driver._id)(dispatch, store.getState);
  }, [driver._id, dispatch, store.getState]);

  // Reverse Route
  const reverseRouteCallback = useCallback(() => {
    reverseRoute(driver._id)(dispatch, store.getState);
  }, [driver._id, dispatch, store.getState]);

  // Cancel Driver
  const cancelDriverCallback = useCallback(() => {
    cancelDriver(driver._id)(dispatch, store.getState);
  }, [driver._id, dispatch, store.getState]);

  // === Stop details for each driver ===
  const stopsForMealtime = useMemo(() => {
    return stops.filter((stop) => stop.mealType === mealType);
  }, [stops, mealType]);
  const stopsForDriver = useMemo(() => {
    return stopsForMealtime.filter((stop) => {
      const eligibleDriverDetails = stop.eligibleDriverDetails;
      return eligibleDriverDetails.some(
        (d) => d.isSelected && d.driverId === driver._id
      );
    });
  }, [stopsForMealtime, driver._id]);
  const cumulativeStopTimesForDriver = stopsForDriver
    .map((stop) => stop.mapViewAttributes.estimatedCumulativeTimeSpent)
    .filter(Boolean);

  const eta = Math.max(...cumulativeStopTimesForDriver, 0);
  const numStops = stopsForDriver.length;
  const mealFaIcon = mealMarkerByMealId(stopsForMealtime)[driver.mealId];

  // === Dirty Route ===
  const [isRouteDirty, setIsRouteDirty] = useState(true);
  const genRefreshIsRouteDirty = useCallback(async () => {
    const routeDirty = await GetIsRouteDirtyForDriverAndStops(
      driver._id,
      stopsForDriver.map((stop) => stop._id)
    )(dispatch, store.getState);

    setIsRouteDirty(routeDirty);
  }, [stopsForDriver, driver._id, dispatch, store.getState]);

  // Something wrong here. Comment out and see.
  // useEffect(() => genRefreshIsRouteDirty(), [genRefreshIsRouteDirty]);
  // useEffect(() => genRefreshIsRouteDirty(), []);

  // Create Route
  const createRouteCallback = useCallback(async () => {
    await createRoute(driver._id)(dispatch, store.getState);
    await genRefreshIsRouteDirty();
  }, [driver._id, dispatch, store.getState, genRefreshIsRouteDirty]);

  return (
    <AdminDeliveryV2DriverActions
      key={driver._id}
      color={driver.color}
      name={driver.firstName}
      startingMealFaIcon={mealFaIcon}
      numStops={numStops}
      isFiltered={isFiltered}
      eta={eta}
      isRouteDirty={isRouteDirty}
      onClickFilter={toggleFilterStopsByDriver}
      onClickEstimateTime={calculateEtaForDriverCallback}
      onClickReverseRoute={reverseRouteCallback}
      onClickCancelDriver={cancelDriverCallback}
      onClickCreateRoute={createRouteCallback}
    />
  );
};
