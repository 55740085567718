import './Restaurant.scss';
const Restaurant = ({ restaurant }) => (
  <div className="restaurant-banner">
    <div className="restaurant-body">
      <div className="name">{restaurant.displayName}</div>
      <div className="address">{restaurant.address}</div>
      {/* <div className="city">{restaurant.city}</div> */}
      <div className="phone">{restaurant.phone}</div>
    </div>
  </div>
);

export default Restaurant;
