import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changePassword } from '../../store/actions';
import { LOGGING, Back, FormChangePassword } from './';
class PageChangePassword extends Component {
  constructor() {
    super();

    this.state = {
      passwordOld: '',
      password: '',
      passwordConfirm: '',
      error: null,
    };
    this.handleSave = this.handleSave.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleCancel(e) {
    e.preventDefault();
    LOGGING && console.log('handleCancel called');
    this.props.history.goBack();
  }

  handleSave(e) {
    e.preventDefault();
    const { currentUser } = this.props;
    const { user } = currentUser;
    const { passwordOld, password, passwordConfirm } = this.state;

    LOGGING &&
      console.log('handleSave got :', {
        user,
        passwordOld,
        password,
        passwordConfirm,
      });

    if (password !== passwordConfirm) {
      this.setState({ error: { message: "New passwords don't match" } });
    } else {
      this.props
        .changePassword({ userId: user._id, passwordOld, password })
        .then(() => {
          this.props.history.goBack();
        })
        .catch((error) => {
          LOGGING && console.log('changepassword got err from backend:', error);
          this.setState({ error });
        });
    }
  }

  handleEdit(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value }, () => {
      const { password, passwordConfirm } = this.state;
      if (
        password.length < passwordConfirm.length ||
        (password.length === passwordConfirm.length && password !== passwordConfirm)
      ) {
        this.setState({ error: { message: "New passwords don't match" } });
      }
    });
  }

  render() {
    const { passwordOld, password, passwordConfirm, error } = this.state;

    LOGGING &&
      console.log('PageChangePassword got', {
        state: this.state,
      });

    return (
      <div className="page new-entity">
        <div className="list">
          <div className="list-title">
            <Back onClick={this.handleCancel} />
            <span>Change Password</span>
          </div>
          {error ? <div>{error.message}</div> : null}
          <FormChangePassword
            onSave={this.handleSave}
            onEdit={this.handleEdit}
            passwordOld={passwordOld}
            password={password}
            passwordConfirm={passwordConfirm}
          />
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, { changePassword }, null, {
  forwardRef: true,
})(PageChangePassword);
