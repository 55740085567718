import { useSelector } from 'react-redux';
import Navbar from '../Navbar';
import { CurrentDriverTask } from './CurrentDriverTask';

export const RouteInProgress = () => {
  const tasks = useSelector((state) => state.tasks);
  const stopsInAscendingOrder = tasks.sort((a, b) => a.taskNum - b.taskNum);
  const filteredTasks = stopsInAscendingOrder.filter((task) => task.isComplete === false);
  const currentTask = filteredTasks[0];

  return (
    <>
      <Navbar driverView={true} numberOfStopsLeft={tasks.length} />
      {currentTask == null ? (
        <div style={{ padding: '50px' }}>
          You're done! Payment should be processed soon. If it hasn't please contact support
        </div>
      ) : (
        <CurrentDriverTask
          task={currentTask}
          totalNumTasks={filteredTasks.length + currentTask.taskNum}
        />
      )}
    </>
  );
};
