import React, { useRef, useEffect, useState } from "react";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import {
  faCommentDots as faTestimonials,
  faCog as faHowItWorks,
  faArrowAltUp,
  faArrowUp,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthMode } from "src/constants";
import { AnonymousFooter } from "src/screens/shared";
import styled, { keyframes } from "styled-components";
import { Benefits, BenefitsDesktop, breakPoint } from "./Benefits";
import { Easter } from "./Easter";
import { HowItWorks } from "./HowItWorks";
import Mileage from "./Mileage";
import { Testimonials } from "./Testimonials";
import {
  LOGGING,
  SampleRestaurantsMobile,
  SampleRestaurantsDesktop,
  CoverImages,
} from "..";
import logo from "../../../../logo_transparent.png";
import { PopUp } from "../../../shared";
import { CompetitorComparisonFullScreen } from "../customers/banners/CompetitorComparison";
import {
  CenteredByColumn,
  Text12300,
  CenteredByColumnLeft,
  BorderGrey,
  Centered,
  Yellow,
  TextGrey,
  Beige,
  Grey,
  Pink,
  text12300,
  card,
  centeredByColumn,
  NavHeight,
  textTitle,
  centered,
  ActionBlack,
  actionBlack,
  actionGrey,
  TextTitle,
  Gold,
  text13500,
  ActionGrey,
  SubNavHeight,
  BackgroundPink,
  SectionTitle,
  ALLCAPTEXT,
} from "../Shared";
const BottomHeaderHeight = "25vh";
interface NotesContainerProps {
  isDesktop?: boolean;
}
const Title = styled.span<NotesContainerProps>`
  ${text12300}
  font-size: 14px;
  line-height: 1.6;
  text-transform: uppercase;
  font-weight: 400;
  margin: 10px 0px;
  text-align: center;
  /* display: ${(props) => (props.isDesktop ? "none" : "flex")}; */
  /* padding: 0px 40px; */
  width: 100%;
  @media only screen and (min-width: ${breakPoint}px) {
    display: ${(props) => (props.isDesktop ? "flex" : "none")};
    line-height: 1.1;
    padding: 0px;
    /* text-align: left; */
  }
`;
export interface TagLineProps {
  isMobile: boolean;
}
const TextSessionOffset = 100;
const TagLine = styled.span<TagLineProps>`
  ${text12300}
  font-size: 40px;
  line-height: 1.2;
  font-weight: 400;
  /* text-transform: uppercase; */
  text-align: center;
  /* white-space: nowrap; */
  /* display: ${(props) => (props.isMobile ? "flex" : "none")}; */
  width: 100%;
  flex-wrap: wrap;
  svg {
    display: inline;
    margin: 0px 10px 0px 3px;
    font-size: 26px;
  }
  /* margin-bottom: 16px; */
  @media only screen and (max-width: 350px) {
    font-size: 35px;
  }
  @media only screen and (min-width: ${breakPoint}px) {
    /* display: ${(props) => (props.isMobile ? "none" : "flex")}; */
    font-size: 40px;
    font-weight: 500;
    text-align: left;
    width: 50%;
  }
`;

const Slogan = styled(CenteredByColumn)<TagLineProps>`
  ${text12300}
  font-size: 24px;
  line-height: 1.1;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  display: ${(props) => (props.isMobile ? "flex" : "none")};
  margin-bottom: 16px;
  @media only screen and (min-width: ${breakPoint}px) {
    display: ${(props) => (props.isMobile ? "none" : "flex")};
  }
`;

const Hero = styled.div`
  background-image: url("logo_transparent.png");
  width: 100px;
  height: 100px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  /* background-color: ${Beige}; */
  z-index: 3;
  border-radius: 50%;
`;

export interface ContentContainerProps {
  mode: number;
  freeze?: boolean;
}
const Header = styled(CenteredByColumn)`
  color: white;
  z-index: 3;
  margin-bottom: 10px;
  padding: 0px 20px;
  width: 100vw;
  img {
    width: 100%;
  }
  @media screen and (max-width: 80px) {
    margin-bottom: 0px;
  }
  @media only screen and (min-width: ${breakPoint}px) {
    /* position: absolute;
    left: 25vw */
    align-items: flex-start;
    padding: 0px;
    /* margin-left: 10vw; */
  }
  @media only screen and (max-width: 600px) {
  }
`;

const ContentContainer = styled(CenteredByColumn)<ContentContainerProps>`
  width: 100vw;
  height: calc(100vh - ${BottomHeaderHeight});
  position: relative;
  padding: ${NavHeight + 100}px 0px 150px 0px;
  overflow: ${(props) => (props.freeze ? "hidden" : "visible")};
  /* justify-content: flex-start; */
  @media only screen and (min-width: ${breakPoint}px) {
    min-height: calc(100vh - 150px);
    padding-top: 10px;
    align-items: flex-start;
    padding-left: 8vw;
  }
  @media only screen and (min-width: 1000px) {
    padding-top: ${NavHeight + 20}px;
  }
`;
const RestaurantName = styled(TextTitle)<{ visible?: boolean }>`
  color: white;
  position: absolute;
  bottom: 20px;
  z-index: 2;
  color: white;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 1s ease-in-out;
`;
const RestaurantContainer = styled(Centered)<{ visible?: boolean }>`
  height: calc(100vh - ${BottomHeaderHeight});
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  /* border: 5px solid yellow; */
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 1s ease-in-out;
`;
export interface RestaurantCoverProps {
  src: string;
  visible?: boolean;
  isMoble?: boolean;
}

const RestaurantCover = styled(Centered)<RestaurantCoverProps>`
  height: calc(100vh - ${BottomHeaderHeight});
  width: 100%;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  flex-shrink: 0;
  border: 1px;
  position: absolute;
  top: 0px;
  left: 0px;
  display: ${(props) => (props.isMoble ? "flex" : "none")};
  /* display: none; */
  @media only screen and (min-width: ${breakPoint}px) {
    display: ${(props) => (props.isMoble ? "none" : "flex")};
    height: 100%;
    background-position: left;
  }
`;
const ButtonWrapper = styled(CenteredByColumn)`
  position: fixed;
  /* width: 100vw; */
  bottom: 40px;
  z-index: 2;
`;
const Button = styled.a`
  ${text12300}
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  padding: 0px 30px;
  height: 60px;
  line-height: 60px;
  flex-grow: 1;
  align-self: stretch;
  text-align: center;
  color: black;
  opacity: 0.8;
`;
const ButtonSignUp = styled(Button)`
  background-color: ${Gold};
`;
const ButtonSignIn = styled(Button)`
  background-color: ${BorderGrey};
  margin-top: 20px;
`;

const SubTitle = styled(TextTitle)`
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  font-weight: 300;
  line-height: 1.4;
  text-transform: none;
  /* padding: 0px 20px; */
`;

// export const AnonymousHero = ({ mode }) => (
//   <Header>
//     <Title>restaurant food</Title>
//     <TagLine isMobile={true}>delivered by carpool</TagLine>
//     <TagLine isMobile={false}>delivered by carpool</TagLine>
//     <SubTitle>FREE delivery for everyone!</SubTitle>
//     <Mileage />
//   </Header>
// );

export const AnonymousHero = ({ mode }) => (
  <Header>
    {/* <Title isDesktop={false}>Restaurant food delivered by carpool</Title> */}
    {/* <Title isDesktop={true}>Discover restaurants</Title> */}
    {/* <TagLine isMobile={true}>Top-rated restaurants from up to 25 miles</TagLine> */}
    <TagLine isMobile={true}>
      Explore restaurants beyond your neighborhood
    </TagLine>
    {/* <TagLine isMobile={false}>popular restaurants</TagLine> */}
    {/* <Title isDesktop={true}>within 25 miles</Title> */}
    {/* <Title isDesktop={false}>not on doordash</Title> */}
    {/* <TagLine isMobile={true}>
      5<FontAwesomeIcon icon={faTimes} /> Farther
    </TagLine>
    <TagLine isMobile={true}>20~40% cheaper</TagLine>
    <TagLine isMobile={false}>
      5<FontAwesomeIcon icon={faTimes} /> Farther, 20~40% cheaper
    </TagLine> */}

    {/* <SubTitle>than Doordash and UberEats</SubTitle> */}
    {/* <Easter /> */}
  </Header>
);
export interface OverlayProps {
  // visible: boolean;
}
const Overlay = styled(Centered)<OverlayProps>`
  width: 100vw;
  height: calc(100vh - ${BottomHeaderHeight});
  /* width: 100%; */
  /* height: calc(100% - ${TextSessionOffset}px); */
  /* height: 100%; */
  flex-shrink: 0;
  border: 1px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  ${textTitle}
  font-size: 12px;
  /* ${text12300} */
  /* text-transform: uppercase; */
  /* font-weight: 500; */
  text-transform: none;
  font-size: 11px;
  letter-spacing: 1px;
  text-align: center;
  line-height: 1.3;
  padding: 10px;
  z-index: 1;
  @media only screen and (min-width: ${breakPoint}px) {
    height: 100%;
  }
`;

const NotesContainer = styled(CenteredByColumn)<NotesContainerProps>`
  position: absolute;
  left: 0px;
  /* top: calc(100% - ${TextSessionOffset}px); */
  top: 100%;
  width: 100vw;
  /* padding: 0px 15px; */
  background-color: ${Beige};
  display: ${(props) => (props.isDesktop ? "none" : "flex")};
  @media only screen and (min-width: ${breakPoint}px) {
    /* position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    background-color: transparent;
    padding: 0px; */
    display: ${(props) => (props.isDesktop ? "flex" : "none")};
    /* position: fixed; */
    /* position: relative;
    top: 0px;
    left: 0px; */
    /* z-index: 100; */
  }
`;
const NavButton = styled(ActionGrey)`
  width: auto;
  /* background-color: transparent; */
  span {
    flex-grow: 0;
    white-space: nowrap;
    font-size: 10px;
    letter-spacing: 1.2px;
    /* color: white; */
    line-height: 18px;
    /* padding-bottom: 2px; */
    svg {
      font-size: 11px;
      margin-right: 2px;
      /* line-height: 14px; */
      display: none;
    }
  }
  /* padding: 3px 0px; */
  padding: 5px 8px;
  margin-right: 16px;
  display: none;
  /* border: 1px solid white; */
  @media only screen and (max-width: 350px) {
    margin-right: 10px;
  }
  @media only screen and (min-width: ${breakPoint}px) {
    display: none;
    &:last-child {
      margin-right: 0px;
    }
    span {
      padding: 5px 10px;
      font-size: 12px;
    }
    margin-right: 20px;
  }
`;

const HowItWorksButton = ({ onClick }) => (
  <NavButton onClick={onClick}>
    <span>
      <FontAwesomeIcon icon={faHowItWorks} /> How It Works
    </span>
  </NavButton>
);

const TestimonialButton = ({ onClick }) => (
  <NavButton onClick={onClick}>
    <span>
      <FontAwesomeIcon icon={faTestimonials} /> Testimonials
    </span>
  </NavButton>
);
const BackgroundContainer = styled(CenteredByColumn)`
  width: 100vw;
  height: 100vh;
  /* overflow: hidden; */
  position: fixed;
  top: 0px;
  left: 0px;
`;
{
  /* <img src={logo} alt="Italian Trulli"></img> */
}
export interface LogoProps {
  isLogin: boolean;
}
const Logo = styled(Centered)<LogoProps>`
  position: absolute;
  width: 100vw;
  height: ${NavHeight}px;
  left: 0px;
  top: 0px;
  background-color: rgb(229, 231, 235, 0.2);
  z-index: 4;
  /* padding: 0px 10px; */
  /* justify-content: ${(props) => (props.isLogin ? "center" : "flex-end")}; */
  img {
    height: 40px;
    width: auto;
    /* position: ${(props) => (props.isLogin ? "relative" : "absolute")};
    left: ${(props) => (props.isLogin ? "0px" : "15px")}; */
  }
  @media only screen and (max-width: 350px) {
    img {
      left: 7px;
    }
  }
  @media only screen and (min-width: ${breakPoint}px) {
    width: 100vw;
    padding-right: 8vw;
    justify-content: flex-end;
    position: fixed;
    z-index: 7;
    top: 0px;
    left: 0px;
    img {
      position: absolute;
      left: 8vw;
    }
  }
`;
const NavContainer = styled(Centered)`
  width: 100%;
  height: ${SubNavHeight}px;
  ${card}
  background-color: ${Beige};
  /* background-color: ${BackgroundPink}; */
  border-bottom: 1px solid ${BorderGrey};
  position: sticky;
  top: 0px;
  z-index: 6;
  /* margin-bottom: 30px; */
  padding: 0px 10px;
  justify-content: space-evenly;
  @media only screen and (min-width: ${breakPoint}px) {
    display: none;
  }
`;
const NavChapter = styled.div`
  ${ALLCAPTEXT}
  font-size: 20px;
  padding-top: 30px;
  margin-bottom: 30px;
`;
interface NavItemProps {
  selected?: boolean;
}
const NavItem = styled.button<NavItemProps>`
  /* width: 33%; */
  padding: 0px;
  margin-right: 10px;
  ${centered}
  flex-grow: 0;
  align-self: auto;
  width: auto;
  /* min-width: fit-content; */
  &:last-child {
    margin-right: 0px;
  }
  span {
    white-space: pre-wrap;
    ${text13500}
    /* ${textTitle} */    
    font-weight: 500;
    color: ${(props) => (props.selected ? "black" : `${TextGrey}`)};
    /* display: block; */
    display: inline;
    line-height: 1.2;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    border-bottom: 2px solid
      ${(props) => (props.selected ? "black" : "transparent")};
    /* width: auto;
    align-self: auto;
    flex-grow: 0; */
  }
`;
const NotesNav = ({ show, refs }) => (
  <NavContainer>
    <NavItem
      selected={show === 0}
      onClick={(e) => {
        e.preventDefault();
        const scrollTop = window.innerHeight * 0.75;
        window.scrollTo({ top: scrollTop, behavior: "smooth" });
      }}
    >
      <span>Benefits</span>
    </NavItem>
    <NavItem
      selected={show === 1}
      onClick={(e) => {
        e.preventDefault();
        const scrollTop =
          window.innerHeight * 0.75 +
          refs[1].current.offsetTop -
          NavHeight -
          40;
        window.scrollTo({ top: scrollTop, behavior: "smooth" });
      }}
    >
      <span>How it Works</span>
    </NavItem>
    <NavItem
      selected={show === 2}
      onClick={(e) => {
        e.preventDefault();
        const scrollTop =
          window.innerHeight * 0.75 +
          refs[2].current.offsetTop -
          NavHeight -
          25;
        window.scrollTo({ top: scrollTop, behavior: "smooth" });
      }}
    >
      <span>testimonials</span>
    </NavItem>
  </NavContainer>
);
const BackToTop = styled.button`
  ${centered}
  ${card}
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 100;
  background-color: black;
  color: white;
  display: none;
  svg {
    font-size: 20px;
  }
`;
export const AnonymousBackground = ({
  componentToDisplay,
  mode,
  showModal,
}) => {
  const n_mobile = SampleRestaurantsMobile.length;
  const n_desktop = SampleRestaurantsDesktop.length;
  const [showNote, setShowNote] = useState(0);
  const onChangeShowNoteTo = (value, e) => {
    e.preventDefault();
    setShowNote(value);
  };
  const [currentSlideMobile, setCurrentSlideMobile] = useState(
    Math.floor(Math.random() * n_mobile)
  );
  const [currentSlideDesktop, setCurrentSlideDesktop] = useState(
    Math.floor(Math.random() * n_desktop)
  );
  const [comparePrice, setComparePrice] = useState(false);
  const howItWorksRefMobile = useRef(null);
  const benefitsRefMobile = useRef(null);
  const testimonialsRefMobile = useRef(null);
  const howItWorksRef = useRef(null);
  const testimonialsRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const offsets = [
        benefitsRefMobile?.current?.offsetTop,
        howItWorksRefMobile?.current?.offsetTop,
        testimonialsRefMobile?.current?.offsetTop,
      ];
      // LOGGING && console.log("handleScroll:", { offsets, scrollPosition });
      if (
        benefitsRefMobile?.current?.offsetTop &&
        howItWorksRefMobile?.current?.offsetTop &&
        testimonialsRefMobile?.current?.offsetTop
      ) {
        const activeIndex = offsets.findIndex((offset, index) => {
          const nextOffset = offsets[index + 1] || Infinity;
          return scrollPosition >= offset && scrollPosition < nextOffset;
        });
        if (activeIndex !== -1 && showNote !== activeIndex) {
          setShowNote(activeIndex);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showNote]);
  const scrollToTestimonials = (e) => {
    e.preventDefault();
    const scrollTop =
      window.innerHeight * 0.75 +
      testimonialsRef.current.offsetTop -
      NavHeight -
      25;
    window.scrollTo({ top: scrollTop, behavior: "smooth" });
  };
  const scrollToHowItWorks = (e) => {
    e.preventDefault();
    const scrollTop =
      window.innerHeight * 0.75 + howItWorksRef.current.offsetTop - NavHeight;
    window.scrollTo({ top: scrollTop, behavior: "smooth" });
  };
  const duration = 3000;
  const onShowComparePrice = () => {
    // e.preventDefault();
    LOGGING && console.log("onShowComparePrice called");
    setComparePrice(true);
  };
  const onHideComparePrice = (e) => {
    e.preventDefault();
    setComparePrice(false);
  };

  useEffect(() => {
    const slideShowInterval = setInterval(() => {
      setCurrentSlideMobile((currentSlideMobile + 1) % n_mobile);
      setCurrentSlideDesktop((currentSlideDesktop + 1) % n_desktop);
    }, duration);

    return () => clearInterval(slideShowInterval);
  }, [currentSlideMobile, currentSlideDesktop, duration, n_mobile, n_desktop]);

  // LOGGING &&
  //   console.log("rendering AnonymousBackground :", {
  //     showModal,
  //     mode,
  //   });
  return (
    <ContentContainer mode={mode} freeze={comparePrice}>
      {/* <Overlay></Overlay> */}
      <PopUp
        isPoppedUp={comparePrice}
        componentToDisplay={
          <CompetitorComparisonFullScreen onHide={onHideComparePrice} />
        }
        backgroundColor={"#333"}
        hidePopUp={onHideComparePrice}
      />
      <Logo isLogin={mode === AuthMode.SIGNIN || mode === AuthMode.SIGNUP}>
        <img src={logo} />
        <HowItWorksButton onClick={scrollToHowItWorks} />
        <TestimonialButton onClick={scrollToTestimonials} />
      </Logo>
      {SampleRestaurantsMobile.map(({ image, name }, index) => (
        <RestaurantContainer visible={index === currentSlideMobile}>
          <RestaurantCover
            key={index}
            src={image}
            // alt={`Slide ${index}`}
            isMoble={true}
            visible={index === currentSlideMobile}
          ></RestaurantCover>
          {/* <RestaurantName visible={index === currentSlideMobile}>{name}</RestaurantName> */}
        </RestaurantContainer>
      ))}
      {SampleRestaurantsDesktop.map(({ image, name }, index) => (
        <RestaurantContainer visible={index === currentSlideDesktop}>
          <RestaurantCover
            key={index}
            src={image}
            // alt={`Slide ${index}`}
            isMoble={false}
            visible={index === currentSlideDesktop}
          ></RestaurantCover>
          {/* <RestaurantName visible={index === currentSlideMobile}>{name}</RestaurantName> */}
        </RestaurantContainer>
      ))}
      <AnonymousHero mode={mode} />
      {componentToDisplay}
      <NotesContainer isDesktop={false}>
        <NotesNav
          show={showNote}
          refs={[benefitsRefMobile, howItWorksRefMobile, testimonialsRefMobile]}
        />

        {/* @ts-ignore */}
        <Benefits ref={benefitsRefMobile} auth={componentToDisplay} />
        {/* @ts-ignore */}
        {/* @ts-ignore */}
        {/* <HowItWorks ref={howItWorksRefMobile} auth={componentToDisplay} /> */}
        {/* @ts-ignore */}
        <HowItWorks ref={howItWorksRefMobile} auth={componentToDisplay} />
        {/* @ts-ignore */}
        <Testimonials ref={testimonialsRefMobile} auth={componentToDisplay} />
        <AnonymousFooter isDesktop={false} />
      </NotesContainer>
      <NotesContainer isDesktop={true}>
        <BenefitsDesktop />
        <HowItWorks ref={howItWorksRef} />
        <Testimonials ref={testimonialsRef} />
        {showModal ? null : <AnonymousFooter isDesktop={false} />}
      </NotesContainer>
      <BackToTop
        onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </BackToTop>
    </ContentContainer>
  );
};
