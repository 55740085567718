import React, { ReactElement, useCallback } from 'react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAsync } from 'react-async';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { GetMostOrderedDishes } from 'src/api/restaurants';
import { UserType, useUserType } from 'src/hooks/useUserType';
import { RootState } from 'src/store';
import styled from 'styled-components';
import { LOGGING } from '../shared';
import { Loading } from './components';
import {
  Centered,
  CenteredByColumn,
  CenteredByColumnLeft,
  Text12300,
  TextTitle,
  centered,
  card,
} from './components/Shared';
interface PageMostOrderedDishesProps {}

const ContentContainer = styled(Centered)`
  width: 100%;
  padding: 24px;
  align-items: flex-start;
  justify-content: flex-start;
`;
const CloseButton = styled.a`
  position: fixed;
  top: 20px;
  right: 40px;
  svg {
    font-size: 25px;
  }
`;
const List = styled(CenteredByColumnLeft)`
  ${card}
  margin: 10px;
  padding: 20px;
  width: 50%;
`;
const Entry = styled(CenteredByColumnLeft)`
  margin-bottom: 20px;
`;

const Name = styled(TextTitle)`
  margin-bottom: 10px;
`;
const SubList = styled(CenteredByColumnLeft)``;
const BulletPoint = styled(Text12300)`
  text-transform: capitalize;
  text-align: left;
`;
export const PageMostOrderedDishes: React.FC<PageMostOrderedDishesProps> = (): ReactElement => {
  const currentUser = useSelector((state: RootState) => state.currentUser);
  // const userType = useUserType();

  // if (userType !== UserType.Admin) {
  //   return <Redirect to="/" />;
  // }
  const fetch = useCallback(async () => {
    return await GetMostOrderedDishes(currentUser?.user?._id);
  }, [currentUser?.user?._id]);

  const { data } = useAsync({ promiseFn: fetch });
  LOGGING && console.log('PageMostOrderedDishes got data:', data);
  if (data == null) return <Loading />;

  const { dishes } = data;
  LOGGING && console.log('PageMostOrderedDishes got:', { dishes });

  return (
    <div className="page">
      <CloseButton href={`/`}>
        <FontAwesomeIcon icon={faTimes} />
      </CloseButton>
      <h1>Favorites</h1>
      <ContentContainer>
        <List>
          {dishes.map((d) => (
            <Entry>
              <Name>{`${d.name}`}</Name>
              <SubList>
                <BulletPoint>{`${d?.restaurant?.name}`}</BulletPoint>
                <BulletPoint>{`${d.ordered}`}</BulletPoint>
              </SubList>
            </Entry>
          ))}
        </List>
      </ContentContainer>
    </div>
  );
};
