import React, { Component } from 'react';
import { isMobilePhone } from 'class-validator';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LOGGING, Referrals } from '.';
import { createReferrals, checkReferralEligibility } from '../../store/actions';

class PageReferrals extends Component {
  constructor() {
    super();
    LOGGING && console.log('PageReferrals constructor');
    this.state = {
      friends: [{ name: '', contact: '', errorField: null }],
      errorMessage: null,
      showSentDetails: false,
      loading: false,
    };
    this.handleToggleShowSentDetails = this.handleToggleShowSentDetails.bind(this);
    this.handleAddFriend = this.handleAddFriend.bind(this);
    this.handleDeleteFriend = this.handleDeleteFriend.bind(this);
    this.handleEditFriend = this.handleEditFriend.bind(this);
    this.handleSendInvitation = this.handleSendInvitation.bind(this);
    this.referralsRef = React.createRef();
    this.handleEditPhone = this.handleEditPhone.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleToggleShowSentDetails(e) {
    e.preventDefault();
    const { showSentDetails } = this.state;
    this.setState({ showSentDetails: !showSentDetails });
  }
  handleClose(e) {
    e.preventDefault();
    this.props.history.push('/');
  }
  handleSendInvitation(e) {
    e.preventDefault();
    let { friends, errorMessage } = this.state;
    const { currentUser } = this.props;
    const { user } = currentUser;
    const { referrals } = user;
    const numInvited = referrals ? referrals.length : 0;
    for (let i = 0; i < friends.length; i++) {
      if (friends[i].name.trim().length === 0) {
        friends[i].errorField = 'name';
        errorMessage = `friend ${numInvited + i + 1}'s name is empty!`;
        this.setState({ friends, errorMessage });
        return;
      }

      if (!isMobilePhone(friends[i].contact)) {
        friends[i].errorField = 'contact';
        errorMessage = `friend ${numInvited + i + 1}'s mobile phone is invalid!`;
        this.setState({ friends, errorMessage });
        return;
      }
    }
    LOGGING && console.log('handleSendInvitation called with all correct data:', friends);
    this.setState({ loading: true });
    this.props
      .createReferrals(friends)
      .then(() => {
        LOGGING && console.log('createReferrals returned successfully');
        this.props.checkReferralEligibility().then(() => {
          this.setState({
            friends: [{ name: '', contact: '', errorField: null }],
            errorMessage: null,
            loading: false,
          });
        });
      })
      .catch((e) => {
        LOGGING && console.log('createReferrals returned with e:', e);
        const { message } = e;
        const errorContact = message.split(' ')[0];
        const updatedFriends = friends.map((f) =>
          f.contact.includes(errorContact) ? { ...f, errorField: 'contact' } : { ...f }
        );
        this.setState({
          friends: updatedFriends,
          errorMessage: message,
          loading: false,
        });
      });
  }

  handleEditPhone(index, value) {
    const { friends, errorMessage } = this.state;
    LOGGING && console.log('handleEditPhone called with: ', { friends, index, value });
    if (errorMessage) {
      this.setState({
        errorMessage: null,
      });
    }
    this.setState({
      friends: friends.map((f, i) =>
        i === index ? { ...f, contact: value, errorField: null } : { ...f, errorField: null }
      ),
    });
  }

  handleEditFriend(index, e) {
    const { friends } = this.state;
    e.preventDefault();
    LOGGING && console.log('handleEditFriend called with:', index, friends, e.target.name);
    const updatedFriends = friends.map((f, i) =>
      i === index
        ? {
            ...f,
            [e.target.name]: e.target.value,
            errorField: null,
          }
        : { ...f }
    );
    this.setState({ friends: [...updatedFriends], errorMessage: null });
  }
  handleAddFriend(e) {
    e.preventDefault();

    const { currentUser } = this.props;
    const { user } = currentUser;
    const { referrals } = user;
    const numInvited = referrals ? referrals.length : 0;
    const { friends } = this.state;
    const limit = user._id === '61d4ea3b4993d70016a66b08' ? 100 : 10;
    if (friends.length + numInvited < limit) {
      this.setState({ friends: [...friends, { name: '', contact: '' }] }, () => {
        this.referralsRef.current.scrollIntoView({ behavior: 'smooth' });
      });
    }
  }
  handleDeleteFriend(index, e) {
    e.preventDefault();
    const { friends } = this.state;
    this.setState({ friends: friends.filter((f, i) => i !== index) });
  }
  componentDidMount() {
    LOGGING && console.log('PageReferrals componentdidmount');
    this.setState({ loading: true });
    this.props.checkReferralEligibility().then(() => {
      this.setState({ loading: false });
    });
  }
  render() {
    const { currentUser } = this.props;
    const { friends, errorMessage, showSentDetails, loading } = this.state;
    LOGGING &&
      console.log('PageReferrals rendering with', {
        props: this.props,
        state: this.state,
      });
    const { user, isAuthenticated } = currentUser;
    if (!isAuthenticated || !user) {
      return (
        <Redirect
          to={{
            pathname: '/signin',
            state: { next: '/referrals' },
          }}
        />
      );
    }

    const { credit, creditSpent, referrals, ordersPlaced } = user;

    return (
      <div className="page">
        <Referrals
          loading={loading}
          friends={friends}
          onSendInvitation={this.handleSendInvitation}
          onAddFriend={this.handleAddFriend}
          onEditFriend={this.handleEditFriend}
          onEditPhone={this.handleEditPhone}
          onDeleteFriend={this.handleDeleteFriend}
          onClose={this.handleClose}
          referralsRef={this.referralsRef}
          errorMessage={errorMessage}
          referrals={referrals}
          credit={credit}
          creditSpent={creditSpent}
          showSentDetails={showSentDetails}
          onToggleShowSentDetails={this.handleToggleShowSentDetails}
          ordersPlaced={ordersPlaced}
          limit={user._id === '61d4ea3b4993d70016a66b08' ? 100 : 10}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log('PageReferrals got redux state:', state);
  return {
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  createReferrals,
  checkReferralEligibility,
})(PageReferrals);
