import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export enum AuthRole {
  Admin,
  Driver,
}

export const useAuth = (role: AuthRole) => {
  const currentUser = useSelector((state: any) => state.currentUser);
  const history = useHistory();

  if (!currentUser.isAuthenticated) {
    history.replace('/');
  }

  switch (role) {
    case AuthRole.Admin:
      if (!currentUser.user.isAdmin) {
        history.replace('/');
      }
      break;
    case AuthRole.Driver:
      if (!currentUser.user.isDriver) {
        history.replace('/');
      }
      break;
    default:
      break;
  }
};
