import React from "react";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert } from "react-confirm-alert";
import { useSelector, useDispatch, useStore } from "react-redux";
import { RootState } from "src/store";
import {
  growOneWeek,
  handpickOneWeek,
  fillOneWeek,
  resetOneWeek,
} from "src/store/actions";
import styled from "styled-components";
import { LOGGING, Nav, PopUp } from "..";
import { ConfirmAlert } from "../../components";
import { SavingScreen } from "../customers/survey/Taste";
import {
  MealHeight,
  MealWidthHeightRatio,
  centered,
  text12300,
  card,
  textTitle,
  LightBlue,
  Centered,
  BorderGrey,
  CenteredByColumn,
  CenteredByColumnLeft,
  NavHeight,
  Neon,
  Beige,
  ActionBlack,
} from "../Shared";
const Container = styled(Centered)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  justify-content: flex-start;
  padding-left: 8px;
`;
const ButtonWrapper = styled.button`
  background-color: ${LightBlue};
  ${text12300}
  font-weight: 500;
  height: 30px;
  line-height: 30px;
  margin: 15px 7px;
  padding: 0px 20px;
  border: 1px solid ${BorderGrey};
  align-self: stretch;
`;
const Wrapper = styled(CenteredByColumnLeft)`
  width: 30vw;
  background-color: white;
  z-index: 1000;
  position: fixed;
  top: 20px;
  height: calc(100vh - 40px);
  left: 50vw;
  padding: 20px 30px;
  align-items: flex-start;
  justify-content: flex-start;
  input {
    height: 40px;
    line-height: 40px;
    padding: 0px;
    border-bottom: 1px solid ${BorderGrey};
    flex-grow: 0;
    width: calc(100% - 130px);
  }
`;
const RestaurantsWrapper = styled(CenteredByColumnLeft)`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  margin-top: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  ${text12300}
`;
const RestaurantEntry = styled.button`
  ${text12300}
  background-color: ${Beige};
  border: 1px solid ${BorderGrey};
  text-transform: capitalize;
  padding: 0px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  line-height: 30px;
  height: 30px;
`;
const SelectedRestaurantList = styled(Centered)`
  margin-bottom: 10px;
  position: fixed;
  right: calc(50vw + 20px);
  top: 20px;
  justify-content: flex-end;
  flex-direction: row-reverse;
`;
const SelectedRestaurant = styled(Centered)`
  ${text12300}
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
  background-color: black;
  color: white;
  padding: 5px 12px;
  padding-right: 25px;
  border-radius: 5px;
  position: relative;
  svg {
    position: absolute;
    right: 8px;
    color: ${Neon};
  }
  margin: 5px;
`;
const ButtonDone = styled(ActionBlack)`
  position: fixed;
  top: 40px;
  right: calc(20vw + 20px);
`;
const RestaurantList = ({ onDone, loading = false }) => {
  // as typing goes, show the list of restaurants whose name prefix matches the input
  // hide the list when the input is empty
  const [input, setInput] = React.useState("");
  const [selectedRestaurants, setSelectedRestaurants] = React.useState([]); // [restaurantId, ...
  const restaurants = useSelector((state: RootState) => state.restaurants);
  const dispatch = useDispatch();
  const store = useStore();
  const onInput = (e) => {
    setInput(e.target.value);
  };
  const onRestaurantClick = (restaurant) => {
    // if the restaurant is already selected, remove it
    LOGGING && console.log("onRestaurantClick", { restaurant });
    setInput("");
    if (selectedRestaurants.includes((r) => r._id === restaurant._id)) {
      setSelectedRestaurants(
        selectedRestaurants.filter((r) => r._id !== restaurant._id)
      );
    } else {
      setSelectedRestaurants([...selectedRestaurants, restaurant]);
    }
  };
  const filteredRestaurants = restaurants.filter((restaurant) =>
    restaurant.name.toLowerCase().startsWith(input.toLowerCase())
  );
  return (
    <Wrapper>
      {loading && <SavingScreen message="Creating meals..." />}
      <SelectedRestaurantList>
        {selectedRestaurants.map((r) => (
          <SelectedRestaurant key={r._id}>
            <span>{r.name}</span>
            <FontAwesomeIcon icon={faCheck} />
          </SelectedRestaurant>
        ))}
      </SelectedRestaurantList>
      <input
        type="text"
        value={input}
        onChange={onInput}
        placeholder="Search for restaurants"
      />
      <ButtonDone onClick={onDone.bind(this, selectedRestaurants)}>
        done
      </ButtonDone>
      {input.length > 0 ? (
        <RestaurantsWrapper>
          {filteredRestaurants.map((restaurant) => (
            <RestaurantEntry
              key={restaurant.id}
              onClick={(e) => {
                e.preventDefault();
                onRestaurantClick(restaurant);
              }}
            >
              {restaurant.name}
            </RestaurantEntry>
          ))}
        </RestaurantsWrapper>
      ) : null}
    </Wrapper>
  );
};
export const HandPick = ({ day }) => {
  const dispatch = useDispatch();
  const store = useStore();
  const restaurants = useSelector((state: RootState) => state.restaurants);
  const [showRestaurantList, setShowRestaurantList] = React.useState(false);
  const [creatingMeals, setCreatingMeals] = React.useState(false);
  const [growingMeals, setGrowingMeals] = React.useState(false);
  const [fillingMeals, setFillingMeals] = React.useState(false);
  const [resettingMeals, setResettingMeals] = React.useState(false);
  const onDone = async (selectedRestaurants, e) => {
    e.preventDefault();

    // call planoneweek
    const { lunchWindowStart, dinnerWindowStart, earlyDinnerWindowStart } = day;
    const starting = {
      lunchWindowStart,
      dinnerWindowStart,
      earlyDinnerWindowStart,
    };
    // add the returned meals to redux state meals
    // call planOneWeek
    setCreatingMeals(true);
    await handpickOneWeek(starting, selectedRestaurants)(
      dispatch,
      store.getState
    );
    setCreatingMeals(false);
    setShowRestaurantList(false);
  };
  const onFill = async (e) => {
    e.preventDefault();
    // call planoneweek
    const { lunchWindowStart, dinnerWindowStart, earlyDinnerWindowStart } = day;
    const starting = {
      lunchWindowStart,
      dinnerWindowStart,
      earlyDinnerWindowStart,
    };
    setFillingMeals(true);
    await fillOneWeek(starting)(dispatch, store.getState);
    setFillingMeals(false);
  };
  const onReset = (e) => {
    e.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          //@ts-ignore
          <ConfirmAlert
            onConfirm={onConfirmReset}
            onClose={onClose}
            actionName="reset week"
          />
        );
      },
    });
  };
  const onConfirmReset = async () => {
    // call planoneweek
    const { lunchWindowStart, dinnerWindowStart, earlyDinnerWindowStart } = day;
    const starting = {
      lunchWindowStart,
      dinnerWindowStart,
      earlyDinnerWindowStart,
    };
    setResettingMeals(true);
    await resetOneWeek(starting)(dispatch, store.getState);
    setResettingMeals(false);
  };
  const onGrow = async (e) => {
    e.preventDefault();
    // call planoneweek
    const { lunchWindowStart, dinnerWindowStart, earlyDinnerWindowStart } = day;
    const starting = {
      lunchWindowStart,
      dinnerWindowStart,
      earlyDinnerWindowStart,
    };
    setGrowingMeals(true);
    await growOneWeek(starting)(dispatch, store.getState);
    setGrowingMeals(false);
  };
  const onShowRestaurantList = (e) => {
    e.preventDefault();
    setShowRestaurantList(true);
  };
  LOGGING &&
    console.log("PlanButton rendering with", {
      showRestaurantList,
      restaurants,
      day,
    });
  return (
    <Container>
      <ButtonWrapper onClick={onShowRestaurantList}>Hand Pick</ButtonWrapper>
      <PopUp
        isPoppedUp={showRestaurantList}
        hidePopUp={() => setShowRestaurantList(false)}
        componentToDisplay={
          <RestaurantList onDone={onDone} loading={creatingMeals} />
        }
        backgroundColor={"#333"}
      />
      <ButtonWrapper onClick={onFill}>Fill with Top</ButtonWrapper>
      <ButtonWrapper onClick={onGrow}>Grow</ButtonWrapper>
      <ButtonWrapper onClick={onReset}>Reset</ButtonWrapper>
      <PopUp
        isPoppedUp={growingMeals}
        hidePopUp={() => setGrowingMeals(false)}
        componentToDisplay={<SavingScreen message="growing around..." />}
        backgroundColor={"#333"}
      />
      <PopUp
        isPoppedUp={fillingMeals}
        hidePopUp={() => setFillingMeals(false)}
        componentToDisplay={
          <SavingScreen message="filling with top restaurants..." />
        }
        backgroundColor={"#333"}
      />
      <PopUp
        isPoppedUp={resettingMeals}
        hidePopUp={() => setResettingMeals(false)}
        componentToDisplay={<SavingScreen message="resetting this week..." />}
        backgroundColor={"#333"}
      />
    </Container>
  );
};
