import React, { ReactElement, useCallback, useState } from "react";
import "react-toggle/style.css"; // for ES6 modules
import {
  faHome,
  faCar as faDriver,
  faUtensils as faRestaurant,
  faDotCircle as faCenter,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GoogleMapReact from "google-map-react";
import styled from "styled-components";
import { LOGGING } from "../..";
import {
  Beige,
  Centered,
  centeredByColumn,
  text12300,
  BorderGrey,
  CenteredByColumn,
} from "../../Shared";
const MapContainer = styled.div`
  height: 150px;
  width: calc(100% - 2px);
  overflow: hidden;
  position: relative;
  left: 1px;
  /* margin-bottom: -30px; */
  border-bottom: 1px solid ${BorderGrey};
  box-sizing: content-box;
  z-index: 1;
  @media only screen and (min-width: 500px) {
    border: none;
  }
`;
const MapIcons = {
  current: faDriver,
  destination: faHome,
  departure: faRestaurant,
  center: faCenter,
};
type MarkerContainerProps = {
  type: String;
};
const MarkerContainer = styled(Centered)<MarkerContainerProps>`
  width: 20px;
  height: 20px;

  background-color: ${(props) =>
    props.type === "current"
      ? "red"
      : props.type === "center"
      ? "blue"
      : "black"};
  color: white;
  border-radius: 50%;
`;
const LocationMarker = ({ type }) => (
  <MarkerContainer type={type}>
    <FontAwesomeIcon icon={MapIcons[type]} />
  </MarkerContainer>
);

export interface TrackingMapProps {
  current: { lng: number; lat: number };
  destination: { lng: number; lat: number };
  departure: { lng: number; lat: number };
}
export const TrackingMap: React.FC<TrackingMapProps> = ({
  current,
  destination,
  departure,
}: TrackingMapProps): ReactElement => {
  const markers = [current, destination, departure];
  const bounds = {
    ne: { lat: -Infinity, lng: -Infinity },
    sw: { lat: Infinity, lng: Infinity },
  };

  markers
    .filter((m) => m.lat && m.lng)
    .forEach((marker) => {
      bounds.ne.lat = Math.max(bounds.ne.lat, marker.lat);
      bounds.ne.lng = Math.max(bounds.ne.lng, marker.lng);
      bounds.sw.lat = Math.min(bounds.sw.lat, marker.lat);
      bounds.sw.lng = Math.min(bounds.sw.lng, marker.lng);
    });
  // Calculate the center and zoom level
  const center = {
    lat: (bounds.ne.lat + bounds.sw.lat) / 2 - 0.02,
    lng: (bounds.ne.lng + bounds.sw.lng) / 2,
  };

  const boundWidth = bounds.ne.lng - bounds.sw.lng;
  const boundHeight = bounds.ne.lat - bounds.sw.lat;
  const zoom = boundWidth < 0.15 && boundHeight < 0.08 ? 11 : 10;

  // LOGGING &&
  //   console.log("TrackingMap rendering with:", {
  //     boundWidth,
  //     boundHeight,
  //     zoom,
  //     current,
  //     destination,
  //     departure,
  //   });

  return (
    <MapContainer>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyB-0SwH76HPTJGm_mhM1Rg0ACcpYGpAt1Q",
        }}
        defaultZoom={zoom}
        defaultCenter={center}
        options={{ fullscreenControl: false }}
      >
        {destination?.lng && destination?.lat ? (
          <LocationMarker
            key={1}
            // @ts-ignore
            lng={parseFloat(destination.lng)}
            // @ts-ignore
            lat={parseFloat(destination.lat)}
            type="destination"
          />
        ) : null}
        {departure?.lng && departure?.lat ? (
          <LocationMarker
            key={2}
            // @ts-ignore
            lng={parseFloat(departure.lng)}
            // @ts-ignore
            lat={parseFloat(departure.lat)}
            type="departure"
          />
        ) : null}
        {current?.lng && current?.lat ? (
          <LocationMarker
            key={0}
            // @ts-ignore
            lng={parseFloat(current.lng)}
            // @ts-ignore
            lat={parseFloat(current.lat)}
            type="current"
          />
        ) : null}
      </GoogleMapReact>
    </MapContainer>
  );
};
