import React, { Component } from 'react';
import { Privacy } from '.';

class PagePrivacy extends Component {
  constructor(props) {
    super();
    this.handleClose = this.handleClose.bind(this);
  }
  handleClose(e) {
    e.preventDefault();
    this.props.history.goBack();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="page">
        <Privacy onClose={this.handleClose} />
      </div>
    );
  }
}

export default PagePrivacy;
