import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { UserType, useUserType } from "src/hooks/useUserType";
import { RootState } from "src/store";
import styled from "styled-components";
import { RestaurantPastMealsContainer } from "./components/restaurants/RestaurantPastMealsContainer";
import { RestaurantUpcomingMealsContainer } from "./components/restaurants/RestaurantUpcomingMealsContainer";
import Navbar from "./Navbar";

interface PageRestaurantMealsProps {}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 36px;
`;

export const PageRestaurantMeals: React.FC<PageRestaurantMealsProps> =
  (): ReactElement => {
    const currentUser = useSelector((state: RootState) => state.currentUser);
    const userType = useUserType();

    if (userType !== UserType.Restaurant) {
      return <Redirect to="/" />;
    }

    const restaurantId = currentUser.user.restaurant._id;
    return (
      <div className="page">
        <Navbar noShadow={false} />
        <ContentContainer>
          <h1>Meals</h1>
          <div>
            <h2>Upcoming Meals</h2>
            <RestaurantUpcomingMealsContainer restaurantId={restaurantId} />
          </div>
          <div>
            <h2>Current Meal</h2>
            <a
              href={`https://foodie.earth/restaurant-current-meal/${restaurantId}`}
              style={{ textDecoration: "underline", color: "blue" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Click to Check Orders
            </a>
          </div>
          <div>
            <h2>Past Meals</h2>
            <RestaurantPastMealsContainer restaurantId={restaurantId} />
          </div>
        </ContentContainer>
      </div>
    );
  };
