import React from 'react';
import styled from 'styled-components';

const Button = styled.button<ButtonProps>`
  border-color: black;
  background-color: ${(props) => {
    switch (props.theme) {
      case 'secondary':
        return 'white';
      case 'transparent':
        return 'inherit';
      default:
        return 'black';
    }
  }};
  color: white;
  cursor: pointer;
  font-weight: 400;
  font-family: Arial;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: 1px;
  padding: 8px;
`;

export type FoodieButtonProps = ButtonProps & {
  children?: React.ReactNode;
  onClick?: () => void;
};

interface ButtonProps {
  type: 'button' | 'submit';
  theme?: 'primary' | 'secondary' | 'transparent';
}

export const FoodieButton: React.FC<FoodieButtonProps> = (props: FoodieButtonProps) => {
  return <Button {...props} />;
};

FoodieButton.defaultProps = {
  theme: 'primary',
};
