import React, { ReactElement, useEffect, useState } from "react";
import {
  faTimes,
  faPercent,
  faCheck,
  faAsterisk,
  faChevronLeft,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { PopUp } from "../shared";
import { PriceExample } from "./components/customers/banners/CompetitorComparison";
import { CompetitorComparisonFullScreen } from "./components/customers/banners/CompetitorComparison";
import {
  Centered,
  CenteredByColumn,
  CenteredByColumnLeft,
  Text12300,
  TextTitle,
  centered,
  card,
  WinterBlue,
  text12300,
  textTitle,
  Gold,
  Pink,
  Bronze,
  BackgroundPink,
  Beige,
  BorderGrey,
  NavHeight,
  actionBlack,
  BackgroundGrey,
  TextGrey,
} from "./components/Shared";
interface PagePriceComparisonProps {}
interface SectionRowProps {
  index?: number;
}
interface ColumnTitleProps {
  alignRight?: boolean;
}

const TableSection = styled(CenteredByColumn)`
  margin: 10px 0px;
  /* align-self: stretch; */
  background-color: white;
  border: 1px solid ${BorderGrey};
  padding: 10px;
  width: 100%;
  max-width: 500px;
`;
const OverallTable = styled(CenteredByColumn)`
  padding: ${NavHeight}px 20px 20px 20px;
  /* align-self: stretch; */
  /* border: 1px solid ${BorderGrey}; */
  background-color: ${Beige};
  min-height: 100vh;
  justify-content: flex-start;
  align-items: center;
`;
const Nav = styled(Centered)`
  position: fixed;
  height: ${NavHeight}px;
  width: 100vw;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.25);
`;
const buttonWidth = 45;
const buttonMargin = 15;
const BackButton = styled.button`
  position: fixed;
  right: ${buttonMargin}px;
  top: ${buttonMargin}px;
  width: ${buttonWidth}px;
  height: ${buttonWidth}px;
  margin: 0px;
  padding: 0px;
  font-size: 24px;
  z-index: 1;
  border-radius: 50%;
  /* border: 2px solid black; */
  background-color: ${TextGrey};
  color: white;
`;
const SectionTitle = styled(Centered)`
  ${text12300}
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1.5;
  border-bottom: 1px solid black;
  margin-bottom: 10px;
`;

const SectionRow = styled(Centered)<SectionRowProps>`
  justify-content: space-between;
  align-self: stretch;
  flex-grow: 1;
  ${text12300}
  line-height: 1.5;
  /* background-color: ${(props) =>
    props.index && props.index % 2 ? `${Beige}` : "transparent"}; */
  align-items: flex-start;
  &:not(:last-child) {
    border-bottom: 1px solid ${BorderGrey};
  }
  label {
    font-weight: 500;
    text-transform: capitalize;
  }
  padding: 10px 0px;
`;

const Title = styled(Centered)`
  ${textTitle}
  font-size: 20px;
  margin-bottom: 5px;
  // text-transform: none;
`;
const Status = styled.b`
  font-weight: 500;
  text-transform: capitalize;
`;

const Memberships = styled(CenteredByColumnLeft)``;
const MembershipStatus = styled.span`
  /* margin-left: 5px; */
  width: 10px;
  text-align: right;
`;
const YesMember = styled(MembershipStatus)`
  color: green;
`;
const NoMember = styled(MembershipStatus)`
  color: red;
`;

// const NoMember = styled.span`
//   color: red
// `
const Cheaper = styled.span`
  /* font-size: 18px;   */
  text-transform: uppercase;
  /* line-height: 20px; */
  font-size: 16px;
  line-height: 2;
  b {
    font-weight: 600;
    margin-right: 2px;
    /* padding-top: 2px; */
  }
  /* position: relative; */
  /* padding-right: 15px; */
  svg {
    font-size: 10px;
    position: absolute;
    right: 2px;
    top: 13px;
  }
`;

const MembershipRow = styled(Centered)`
  justify-content: space-between;
  align-self: stretch;
  label {
    font-weight: 300;
  }
`;

const ColumnTitle = styled(Centered)<ColumnTitleProps>`
  ${textTitle}
  line-height: 1.5;
  font-size: 14px;
  text-align: ${(props) => (props.alignRight ? "right" : "left")};
  display: none;
`;

const Footer = styled.span`
  ${text12300}
  line-height: 1.5;
  font-size: 10px;
  padding: 0px 8px;
  margin: 5px 0 20px 0;
  text-align: center;
  svg {
    font-size: 8px;
    margin-right: 3px;
    margin-bottom: 1px;
  }
`;

const Slogan = styled.span`
  ${text12300}
  text-transform: uppercase;
  font-weight: 500;
  font-size: 28px;
  line-height: 1.1;
  text-align: center;
  margin-bottom: 10px;
`;
const Tagline = styled.span`
  ${textTitle}
  text-transform: none;
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 400;
  line-height: 1.4;
`;
const TableTitle = styled.span`
  ${text12300}
  line-height: 1.75;
  border-bottom: 1px solid black;
  margin: 5px 0px;
  svg {
    font-size: 8px;
    line-height: 2;
    margin-left: 2px;
    margin-bottom: 1px;
  }
`;
const PriceComparisonTable = () => (
  <TableSection>
    <SectionRow index={1}>
      <Memberships>
        <MembershipRow>
          <label>foodie member</label>
          <YesMember>
            <FontAwesomeIcon icon={faCheck} />
          </YesMember>
        </MembershipRow>
        <MembershipRow>
          <label>dashpass</label>
          <NoMember>
            <FontAwesomeIcon icon={faTimes} />
          </NoMember>
        </MembershipRow>
      </Memberships>
      <Cheaper>
        <b>40%</b> cheaper
        {/* <FontAwesomeIcon icon={faAsterisk} /> */}
      </Cheaper>
    </SectionRow>
    <SectionRow index={2}>
      <Memberships>
        <MembershipRow>
          <label>foodie member</label>
          <NoMember>
            <FontAwesomeIcon icon={faTimes} />
          </NoMember>
        </MembershipRow>
        <MembershipRow>
          <label>dashpass</label>
          <NoMember>
            <FontAwesomeIcon icon={faTimes} />
          </NoMember>
        </MembershipRow>
      </Memberships>
      <Cheaper>
        <b>30%</b> cheaper
      </Cheaper>
    </SectionRow>
    <SectionRow index={3}>
      <Memberships>
        <MembershipRow>
          <label>foodie member</label>
          <YesMember>
            <FontAwesomeIcon icon={faCheck} />
          </YesMember>
        </MembershipRow>
        <MembershipRow>
          <label>dashpass</label>
          <YesMember>
            <FontAwesomeIcon icon={faCheck} />
          </YesMember>
        </MembershipRow>
      </Memberships>
      <Cheaper>
        <b>25%</b> cheaper
      </Cheaper>
    </SectionRow>
    <SectionRow index={4}>
      <Memberships>
        <MembershipRow>
          <label>foodie member</label>
          <NoMember>
            <FontAwesomeIcon icon={faTimes} />
          </NoMember>
        </MembershipRow>
        <MembershipRow>
          <label>dashpass</label>
          <YesMember>
            <FontAwesomeIcon icon={faCheck} />
          </YesMember>
        </MembershipRow>
      </Memberships>
      <Cheaper>
        <b>15%</b> cheaper
      </Cheaper>
    </SectionRow>
  </TableSection>
);
const ShowExample = styled.button`
  ${actionBlack}
  margin: 30px 0px;
`;
const OverallComparison2 = () => {
  const history = useHistory();
  const [showComparison, setShowComparison] = useState(false);
  const onShowComparison = () => {
    setShowComparison(true);
  };

  const onHideComparison = () => {
    setShowComparison(false);
  };
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <OverallTable>
      {/* <Nav> */}
      <BackButton type="button" onClick={() => history.goBack()}>
        <FontAwesomeIcon icon={faTimes} />
      </BackButton>
      {/* </Nav> */}
      <PopUp
        isPoppedUp={showComparison}
        componentToDisplay={
          <CompetitorComparisonFullScreen onHide={onHideComparison} />
        }
        backgroundColor={"#333"}
        hidePopUp={onHideComparison}
      />
      <Slogan>
        Foodie is <br></br>
        <b>much cheaper</b>
        <br></br>than doordash
      </Slogan>
      <Tagline>even if you have dashpass</Tagline>
      <TableTitle>How much cheaper?</TableTitle>
      <PriceComparisonTable />
      <Footer>
        {/* <FontAwesomeIcon icon={faAsterisk} /> */}
        Exact saving varies for each restaurant based on your location and
        basket size.
      </Footer>
      <ShowExample onClick={onShowComparison}>see an example</ShowExample>
      {/* <TableTitle>
        An example       
      </TableTitle>
      <PriceExample turnOnReceipt={()=>{}}/> */}
    </OverallTable>
  );
};

export const PagePriceComparison: React.FC<PagePriceComparisonProps> =
  (): ReactElement => {
    return <OverallComparison2 />;
  };
