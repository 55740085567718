import { getWindowForDay } from "src/util/time";
import { LOGGING } from "../../constants";
import { LOAD_ROUTE_V2 } from "../actionTypes";
import { apiCall } from "../api";

export const loadRoute = (route) => ({
  type: LOAD_ROUTE_V2,
  route,
});

export const getRouteForDriver = () => {
  return async (dispatch, getState) => {
    const { currentUser } = getState();
    const [startsAtMs, endsAtMs] = getWindowForDay();
    LOGGING &&
      console.log("getRouteForDriver calling for:", {
        currentUser,
        startsAtMs,
        endsAtMs,
      });
    try {
      const route = await apiCall(
        "post",
        `/routes/GetRouteForDriver`,
        {
          driverId: currentUser.user._id,
          startsAtMs: startsAtMs,
          endsAtMs: endsAtMs,
        },
        currentUser
      );
      dispatch(loadRoute(route));
    } catch (err) {
      LOGGING && console.log("getRouteForDriver got err", err);
    }
  };
};
