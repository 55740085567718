import {
  faCircle,
  faDiamond,
  faHexagon,
  faRectangleWide,
  faSquare,
  faStar,
  faTriangle,
} from '@fortawesome/pro-solid-svg-icons';

const MARKERS = [faTriangle, faSquare, faCircle, faStar, faDiamond, faHexagon, faRectangleWide];

// Given a list of stops, return a dictionary with MealID to FaIcon.
// @return { [MealId]: FaIcon }
export const mealMarkerByMealId = (stops) => {
  const uniqueMealIds = [...new Set(stops.map((s) => s.meal))];
  return Object.assign(
    {},
    ...uniqueMealIds.map((mealId, index) => {
      return { [mealId]: MARKERS[index % MARKERS.length] };
    })
  );
};
