import { faLeaf } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './DishVegetarianIcon.scss';

export const DishVegetarianIcon = ({ dish }) => {
  if (!dish.isVegetarian) {
    return null;
  }

  return (
    <div className="vegetarian-icon">
      <FontAwesomeIcon icon={faLeaf} />
    </div>
  );
};
