import { SET_COUPON_CODES } from '../actionTypes';

const couponCodes = (state = [], action) => {
  switch (action.type) {
    case SET_COUPON_CODES:
      return action.payload;
    default:
      return state;
  }
};

export default couponCodes;
