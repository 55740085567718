import "./Referrals.scss";
// import { faHeart } from '@fortawesome/free-solid-svg-icons';
import {
  faGift,
  faPiggyBank,
  faSackDollar,
  faEnvelopeOpenText,
  faHeartCircle,
} from "@fortawesome/pro-duotone-svg-icons";
import { faSms } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReferralCodeSlogan1, MinSpendForReferralCode, referralText } from "./";

const CodeRules = ({ name }) => (
  <div className="referral-code-section code-rules-wrapper">
    <div className="slogan">
      <div className="slogan-text">
        <FontAwesomeIcon icon={faEnvelopeOpenText} />
        <FontAwesomeIcon icon={faHeartCircle} />
        <span>{ReferralCodeSlogan1}</span>
      </div>
      {/* <div className="fancy-header"></div> */}
    </div>
    <ul className="code-rules">
      <p>
        <b>1.</b> Anyone who applies your unique code below to their 1st order
        ($20+) will get <b className="red">$5 off</b>.
      </p>
      <p>
        <b>2.</b> When the order is delivered, you will receive{" "}
        <b className="red">$5 credit</b>.
      </p>
      <p>
        <b>3.</b> You can share your code <b className="red">unlimited</b>{" "}
        times!
      </p>
      {/* <p>
        Share your personalized gift link or gift code with friends. Code usage is{' '}
        <b className="red">unlimited</b>!
      </p>
      <p>
        After signing up, they’ll get <b className="red">$5 off</b> on their first order of $20+ and
        you’ll
        <b className="red"> earn $5 </b>
        in Foodie credit for each friend that signs up!
      </p> */}
    </ul>
  </div>
);

const MagicLink = ({ code, name }) => (
  <div className="code-url link">
    <div className="link-instruction">
      <span>
        Your code is applied automatically to qualified orders made from the
        link below.
      </span>
    </div>
    <div className="code-url-body">
      <span>{`https://foodie.earth/g/${code}`}</span>
    </div>
  </div>
);

const MagicCode = ({ code, name }) => (
  <div className="code-url code">
    {/* <div className="code-name">
      <span>{`${name}'s gift code`}</span>
    </div> */}
    <div className="code-url-body">
      <span>{code}</span>
    </div>
  </div>
);
const CodeActions = ({ code, name }) => (
  <div className="referral-code-section code-actions">
    <div className="slogan">
      <div className="slogan-text">
        <FontAwesomeIcon icon={faGift} />
        {/* <span>your gift code</span> */}
      </div>
      {/* <div className="fancy-header"></div> */}
    </div>

    {/* <div className="code-section">
      <MagicLink code={code} name={name} />
      <CopyToClipboard text={`https://foodie.earth/g/${code}`} onCopy>
        <button>
          <FontAwesomeIcon icon={faCopy} />
          <span>copy link</span>
        </button>
      </CopyToClipboard>
    </div> */}
    {/* <div className="code-section">
      <MagicCode code={code} name={name} />
      <CopyToClipboard text={code} >
        <button>
          <FontAwesomeIcon icon={faCopy} />
          <span>copy code only</span>
        </button>
      </CopyToClipboard>
    </div> */}

    <MagicCode code={code} name={name} />

    <div className="code-section">
      <MagicLink code={code} name={name} />

      <a
        href={`sms:${
          /Android/i.test(navigator.userAgent) ? "?" : "&"
        }body=${referralText(code)}`}
      >
        <FontAwesomeIcon icon={faSms} />
        <span>share link</span>
      </a>
    </div>
  </div>
);

const CodeSummary = ({ referralCode }) => (
  <div className="referral-code-section code-actions">
    <div className="slogan">
      <div className="slogan-text">
        <FontAwesomeIcon icon={faPiggyBank} />
        <span>{`${referralCode?.code} summary`}</span>
      </div>
    </div>
    <div className="code-summary">
      <div className="code-summary-row">
        <label>usage</label>
        <span>{referralCode?.orderCount}</span>
      </div>
      <div className="code-summary-row">
        <label>earned</label>
        <span>${referralCode?.commission}</span>
      </div>
    </div>
  </div>
);

const ReferralCode = ({ totalSpend, socialInviteCode, name }) => (
  <div className="referrals code">
    <div className="referral-code-body">
      <CodeRules name={name} />
      {socialInviteCode /*&& totalSpend >= MinSpendForReferralCode*/ ? (
        <>
          <CodeActions code={socialInviteCode?.code} name={name} />
          <CodeSummary referralCode={socialInviteCode} name={name} />
        </>
      ) : (
        <NotEligible totalSpend={totalSpend} name={name} />
      )}
    </div>
  </div>
);
const NotEligible = ({ totalSpend, name }) => (
  <div className="not-eligible">
    <FontAwesomeIcon icon={faSackDollar} />
    {/* <div className="fancy-header"></div> */}
    <span>
      To qualify for <b>{`${name}'s gift link`}</b>, you need to have spent at
      least ${MinSpendForReferralCode}. You have only spent ${totalSpend} so
      far.
    </span>
  </div>
);

export default ReferralCode;
