import React, { ReactElement, useCallback, useEffect } from "react";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faHome } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAsync } from "react-async";
import toast, { Toaster } from "react-hot-toast";
// import { toast } from "react-toastify";
import { useSelector, useDispatch, useStore } from "react-redux";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { FoodieEvent, FoodieSource } from "src/api/tracker";
import { useTrackEventEffect } from "src/hooks/useTrackEventEffect";
import { saveNomination, submitNomination } from "src/store/actions";
import styled from "styled-components";
import { Close, LOGGING } from "../shared";
import { NominationForm } from "./components/MenuTwoChoices";
import { MyReferralCodeEventContainer } from "./components/referrals/MyReferralCodeEventContainer";
import {
  CenteredByColumn,
  NavHeight,
  actionUnderline,
  text12300,
} from "./components/Shared";
export const Button = styled(Link)`
  ${actionUnderline}
  margin-top: 60px;
  svg {
    margin-right: 10px;
  }
`;
const Container = styled(CenteredByColumn)`
  position: fixed;
  justify-content: flex-start;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding-top: ${NavHeight}px;
`;
const Cheesy = styled(CenteredByColumn)`
  width: 100%;
  padding: 20px;
  text-align: center;
  span {
    ${text12300}
  }
`;
const Icon = styled(CenteredByColumn)`
  font-size: 3rem;
  margin: 10px;
`;
export interface PagePostCheckoutProps {}

export const PagePostCheckout: React.FC<PagePostCheckoutProps> =
  (): ReactElement => {
    useTrackEventEffect(FoodieEvent.PAGE_POST_CHECKOUT, FoodieSource.UNKNOWN);
    const dispatch = useDispatch();
    const store = useStore();
    const [nomination, setNomination] = React.useState({
      restaurant: { name: "", address: "" },
    });
    const [nominationErrorMessage, setNominationErrorMessage] =
      React.useState("");
    const [restaurantName, setRestaurantName] = React.useState("");

    const location = useLocation();
    const history = useHistory();
    const onChangeRestaurantName = useCallback((restaurantName: string) => {
      setRestaurantName(restaurantName);
    }, []);

    const onSubmit = (e) => {
      e.preventDefault();
      e.stopPropagation();
      LOGGING && console.log("onSubmit called with ", nomination);
      if (!restaurantName) {
        setNominationErrorMessage("Please enter a restaurant name");
        return;
      }
      setNominationErrorMessage("");
      saveNomination(nomination)(dispatch);
      submitNomination()(dispatch, store.getState).then(() => {
        setNomination({ restaurant: { name: "", address: "" } });
        toast.success("nomination submited!");
      });
    };

    const onSelectRestaurantAddress = useCallback((address) => {
      console.log("onSelectRestaurantAddress called with ", address);
      let newAddress = address,
        name = "";

      if (address?.gmaps?.address_components) {
        const { gmaps } = address;
        const { address_components } = gmaps;
        const city = address_components.find((c) =>
          c.types.includes("locality")
        ).short_name;
        const number = address_components.find((c) =>
          c.types.includes("street_number")
        ).short_name;
        const street = address_components.find(
          (c) => c.types.includes("route") || c.types.includes("premise")
        ).short_name;
        const state = address_components.find((c) =>
          c.types.includes("administrative_area_level_1")
        ).short_name;
        const zip = address_components.find((c) =>
          c.types.includes("postal_code")
        ).short_name;

        newAddress = `${number} ${street}, ${city}, ${state} ${zip}`;
      }
      if (address?.gmaps?.name) {
        name = address.gmaps.name;
      }
      setNomination({
        ...nomination,
        restaurant: { address: newAddress, name },
      });
    }, []);
    useEffect(() => {
      LOGGING && console.log("about to toast");
      toast.success("Order placed!");
      // const locationState: any = location?.state;
      // if (locationState?.showOrderConfirmation == null) return;

      // history.replace({ state: {} });
      // toast.success("Order placed!");
    }, [history, location.state]);

    // return <MyReferralCodeEventContainer />;
    return (
      <Container>
        <Toaster />
        <Close onClick={() => history.push("/")} />
        <Cheesy>
          <Icon>🍣🥘🥗🌮🍔🍕🍱🧆🍜🥞🧁🍩</Icon>
          {/* <span>Share your local gem with the community.</span> */}
        </Cheesy>
        <NominationForm
          //@ts-ignore
          nomination={nomination}
          nominationErrorMessage={nominationErrorMessage}
          onChangeRestaurantName={onChangeRestaurantName}
          onSelectRestaurantAddress={onSelectRestaurantAddress}
          onSubmit={onSubmit}
        />
        <Button to={"/"}>
          <FontAwesomeIcon icon={faHome} />
          Back to Home
        </Button>
      </Container>
    );
  };
