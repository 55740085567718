// eslint-disable-next-line import/order
import React, { useCallback, useSelector } from "react";
import { RootState } from "src/store";
import "./CheckoutBanner.scss";
import { faShoppingCart } from "@fortawesome/pro-regular-svg-icons";
import { Link, useHistory } from "react-router-dom";
import { getMyGoods } from "src/util/order";
import { Close, LOGGING, getItemNumberInCart } from "./";
import { BottomBanner } from "./customers/banners/BottomBanner";
import toast, { Toaster } from "react-hot-toast";
// import {toast} from 'react-toastify';

const CheckoutBanner = ({
  cart,
  onNext,
  userId,
  isNotOrganizer,
  initializing,
}) => {
  const myGoods = getMyGoods(cart?.goods, userId);
  const numberOfItems = isNotOrganizer
    ? getItemNumberInCart({ ...cart, goods: { ...myGoods } })
    : getItemNumberInCart(cart);
  const history = useHistory();
  LOGGING &&
    console.log("CheckoutBanner rendering with:", {
      cart,
      userId,
      numberOfItems,
    });

  const defaultOnNext = useCallback(() => {
    history.push("/cart");
  }, [history]);
  const next = onNext ?? defaultOnNext;

  return (
    <div className="checkout-banner">
      {/* {isMember ? null : (
        <ToSaveByMembership
          cart={cart}
          card={card}
          onStartMembership={onStartMembership.bind(this, "ToSaveByMembership")}
          onResumeMembership={onResumeMembership}
          isAuthenticated={isAuthenticated}
          wasMember={wasMember}
          restaurantIsMemberOnly={restaurantIsMemberOnly}
        />
      )} */}

      <BottomBanner
        icon={faShoppingCart}
        label={initializing ? "connecting..." : "view cart"}
        rightElement={
          <div
            className={`cart-length ${numberOfItems > 0 ? "full" : "empty"} ${
              numberOfItems > 0 && !cart.user && !userId ? "loading" : "done"
            }`}
          >
            <div
              className={`length-border ${
                numberOfItems > 0 && !cart.user && !userId ? "loading" : "done"
              }`}
            ></div>
            <span>{numberOfItems}</span>
          </div>
        }
        disabled={initializing || (cart && !cart.user && !userId)}
        onClick={(e) => {
          if (Object.keys(cart.goods).length === 0) {
            e.preventDefault();
            toast.error("Cart is still empty!", {
              duration: 1000,
              position: "bottom-center",
            });
          } else {
            next(e);
          }
        }}
      />
    </div>
  );
};

const NominationBanner = ({ showConfirmation, onHideConfirmation }) => (
  <div
    className={`checkout-banner nomination-banner ${
      showConfirmation ? "show" : "hide"
    }`}
  >
    <span className="order-confirmation">Thank you for nominating</span>
    {showConfirmation ? <b>{`${showConfirmation.restaurant.name}`}</b> : null}
    <Close onClick={onHideConfirmation} />
  </div>
);

const OrderBanner = ({ showConfirmation, onHideConfirmation }) => (
  <div
    className={`checkout-banner order-banner ${
      showConfirmation ? "show" : "hide"
    }`}
  >
    <span className="order-confirmation">Thank you for your order!</span>
    <Close onClick={onHideConfirmation} />
    <Link
      className="cart-content"
      to={{ pathname: "/orders", state: { gotoOrderId: showConfirmation } }}
    >
      <span>view my orders</span>
    </Link>
  </div>
);

export { CheckoutBanner, NominationBanner, OrderBanner };
