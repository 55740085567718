import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useAsync } from 'react-async';
import { useHistory } from 'react-router-dom';
import { GetUpcomingMealsForRestaurant } from 'src/api/meals';
import { Loading } from '..';
import { RestaurantUpcomingMeals } from './RestaurantUpcomingMeals';

interface RestaurantUpcomingMealsContainerProps {
  restaurantId: string;
}

export const RestaurantUpcomingMealsContainer: React.FC<RestaurantUpcomingMealsContainerProps> = ({
  restaurantId,
}): ReactElement => {
  const [isDirty, setIsDirty] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => setIsDirty(true));

    return unlisten;
  }, [history]);

  const fetch = useCallback(async ({ restaurantId }) => {
    const result = await GetUpcomingMealsForRestaurant(restaurantId);

    setIsDirty(false);

    return result;
  }, []);

  const { data } = useAsync({ promiseFn: fetch, watch: isDirty, restaurantId });

  if (data == null) return <Loading />;

  return <RestaurantUpcomingMeals meals={data.meals} />;
};
