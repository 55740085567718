import { PURGE } from 'redux-persist';
import { LOAD_START, RESET_START } from '../actionTypes';

const start = (state = {}, action) => {
  switch (action.type) {
    case LOAD_START:
      return { ...action.start };
    case RESET_START:
      return {};
    case PURGE:
      return {};
    default:
      return state;
  }
};

export default start;
