import React, { ReactElement, useState } from "react";
import { faCreditCard, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import creditCardType from "credit-card-type";
import { useSelector, useDispatch } from "react-redux";
import {
  PayMethodWrapper,
  PayMethod,
  SubSection,
  SubSectionTitle,
  SelectWrapper,
  PayMethodSelect,
  NewCard,
} from "src/pages/Users/Membership";
import { Close, PaypalButton } from "src/screens/tasting_menu/components";
import { RootState } from "src/store";
import { createGift } from "src/store/actions/giftCard";
import styled, { keyframes } from "styled-components";
import { LOGGING, CreditCardIcons, NewCardForm } from "../..";
import {
  Centered,
  CenteredByColumn,
  Beige,
  BorderGrey,
  text12300,
  NavHeight,
} from "../../Shared";
import { BreakPoint } from "../mealMenu/MealHeader";
import { SubmitButton } from "./GiftTemplate";
const PaymentSelectWrapper = styled(SelectWrapper)`
  margin: 40px 0px 20px 0px;
`;
const ExistingCard = styled(NewCard)`
  justify-content: center;
  border: 1px solid ${BorderGrey};
  text-align: center;
`;
const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

const CheckoutForm = styled(CenteredByColumn)`
  width: 100vw;
  height: auto;
  padding: ${NavHeight}px 20px 80px 20px;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  justify-content: flex-start;
  transform: translateY(0);
  transition: transform 0.3s ease-in-out; /* Add transition property for sliding effect */

  &.slide-out {
    transform: translateY(100%);
  }
  @media only screen and (min-width: ${BreakPoint}px) {
    padding: 40px calc(50vw - 200px) 40px calc(50vw - 200px);
  }
`;
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
`;
const Total = styled(Centered)`
  align-self: stretch;
  justify-content: space-between;
  background-color: ${Beige};
  padding: 10px 20px;
  font-size: 16px;
  label {
    text-transform: capitalize;
  }
  span {
    font-weight: 500;
  }
`;

const LoadingOverlay = styled(Centered)`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  span {
    ${text12300}
    color: white;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
  }
`;

interface GiftCheckoutProps {
  gift: any;
  onFinish: (done: boolean) => void;
}

export const GiftCheckout: React.FC<GiftCheckoutProps> = ({
  gift,
  onFinish,
}: GiftCheckoutProps): ReactElement => {
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const dispatch = useDispatch();
  const card = currentUser?.user?.stripeInfo?.card;
  const [payMethod, setPayMethod] = useState<PayMethod>(PayMethod.Stripe);
  const [error, setError] = useState({});
  const [isSlidingOut, setIsSlidingOut] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newCard, setNewCard] = useState({
    number: "",
    cvc: "",
    exp_month: "",
    exp_year: "",
  });
  const [cardType, setCardType] = useState(null);
  const [saveCard, setSaveCard] = useState(true);
  const updateState = (stateName, newValue) => {
    // Use bracket notation to dynamically access the state and setter function
    LOGGING &&
      console.log(
        "updateState called with:",
        { stateName, newValue },
        `set${stateName.charAt(0).toUpperCase()}${stateName.slice(1)}`
      );
    const setStateFunction = eval(
      `set${stateName.charAt(0).toUpperCase()}${stateName.slice(1)}`
    );
    setStateFunction(newValue);
  };

  const createOneGift = async () => {
    try {
      setLoading(true);
      setError({});
      const result = await dispatch(createGift(null, newCard, saveCard, gift));
      // setLoading(false);
      // onFinish(true);
      LOGGING && console.log("result:", result);
      setIsSlidingOut(true);
      setTimeout(() => {
        onFinish(true);
      }, 300); //
    } catch (error) {
      console.error("Error fetching gift card templates:", error);
      setError(error);
      setLoading(false);
    }
  };

  LOGGING && console.log("GiftCheckout rendering with", { error, newCard });
  const onToggleSaveCard = (e) => {
    e.preventDefault();
    LOGGING && console.log("onToggleSaveCard called with saveCard", saveCard);
    setSaveCard(!saveCard);
  };
  const handlePaypalAuthorize = (captureId) => {
    LOGGING &&
      console.log("giftCheckout handlePaypalAuthorize called with:", captureId);
    createGift(captureId, null, null, gift);
    onFinish(true);
  };
  const handleChangeNumber = (sectionName, field, values) => {
    const oldFields = eval(`${sectionName}`);
    LOGGING &&
      console.log("handleChangeNumber called with", {
        sectionName,
        field,
        values,
        oldFields,
      });

    const updatedField = { [field]: values.value };
    // const oldFields = eval(
    //   `state${sectionName.charAt(0).toUpperCase()}${sectionName.slice(1)}`
    // );

    // const oldFields = this.state[sectionName];
    LOGGING &&
      console.log("handleChangeNumber with:", {
        sectionName,
        error,
        field,
        values,
        updatedField,
        oldFields,
      });

    //@ts-ignore
    const updatedError = error && field === error?.param ? {} : error;
    LOGGING && console.log("handleChangeNumber updatedError:", updatedError);
    if (field === "number") {
      const cardTypes = creditCardType(values.value);
      if (cardTypes.length === 0) {
        return;
      }
      const { lengths, gaps, code, type, niceType } = cardTypes[0];

      let cardFormat = lengths.length === 1 ? Array(lengths[0]).fill("#") : [];
      gaps.reverse().forEach((position) => cardFormat.splice(position, 0, "-"));
      LOGGING &&
        console.log("handleChangeNumber creditCardType:", {
          lengths,
          gaps,
          code,
          type,
          niceType,
        });
      setError(updatedError);
      setCardType({
        format: null, //cardFormat.join(""),
        length: lengths.length === 1 ? lengths[0] : null,
        code: { ...code, format: Array(code.size).fill("#").join("") },
        type: values.value.length === 0 ? null : type,
        niceType: values.value.length === 0 ? null : niceType,
      });
      updateState(sectionName, { ...oldFields, ...updatedField });
    } else {
      setError(updatedError);
      updateState(sectionName, { ...oldFields, ...updatedField });
    }
  };

  LOGGING && console.log("GiftCheckout rendering with saveCard:", saveCard);
  return (
    <Wrapper>
      {loading ? (
        <LoadingOverlay>
          <span>submitting...</span>
        </LoadingOverlay>
      ) : null}
      <CheckoutForm
        style={{
          transform: isSlidingOut ? "translateY(100%)" : "translateY(0)",
        }}
      >
        <Close onClick={onFinish.bind(this, false)} />
        <Total>
          <label>total</label>
          <span>${gift?.amount?.toFixed(2)}</span>
        </Total>
        <>
          {/* <SubSectionTitle>Payment Method</SubSectionTitle> */}
          <PaymentSelectWrapper>
            <PayMethodSelect
              theme={{ selected: payMethod === PayMethod.Stripe }}
              onClick={() => {
                setPayMethod(PayMethod.Stripe);
              }}
            >
              <span>pay by card</span>
              {payMethod === PayMethod.Stripe && (
                <FontAwesomeIcon icon={faCheck} />
              )}
            </PayMethodSelect>
            <PayMethodSelect
              theme={{ selected: payMethod === PayMethod.Paypal }}
              onClick={() => {
                setPayMethod(PayMethod.Paypal);
              }}
            >
              <span>pay by paypal</span>
              {payMethod === PayMethod.Paypal && (
                <FontAwesomeIcon icon={faCheck} />
              )}
            </PayMethodSelect>
          </PaymentSelectWrapper>

          {payMethod === PayMethod.Stripe ? (
            card?.brand && card?.last4 ? (
              <ExistingCard>
                <FontAwesomeIcon
                  className="brand-icon"
                  icon={CreditCardIcons[card.brand] || faCreditCard}
                />
                <span>{`...${card.last4} 
      ${
        card.exp_month && card.exp_year
          ? ` Exp. ${card.exp_month}/${card.exp_year}`
          : ""
      }`}</span>
              </ExistingCard>
            ) : (
              <NewCard>
                <div className="form-section">
                  <NewCardForm
                    onChangeNumber={handleChangeNumber}
                    newCard={newCard}
                    error={error}
                    cardType={cardType}
                  />
                </div>
                <button
                  className={`save-card ${saveCard ? "checked" : "unchecked"}`}
                  onClick={onToggleSaveCard}
                >
                  <FontAwesomeIcon icon={faCheck} />
                  <span>save card</span>
                </button>
              </NewCard>
            )
          ) : null}

          {payMethod === PayMethod.Paypal && (
            <SubSection center={true}>
              <PaypalButton
                amount={gift?.amount}
                isSubscribe={false}
                onAuthorize={handlePaypalAuthorize}
              />
            </SubSection>
          )}
        </>
        {payMethod === PayMethod.Stripe ? (
          <SubmitButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              createOneGift();
            }}
            disabled={loading}
          >
            {/* {loading ? "submitting..." : "Buy and Send"} */}
            buy and send
          </SubmitButton>
        ) : null}
      </CheckoutForm>
    </Wrapper>
  );
};
