import React from 'react';
import { faArrowToTop } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import './DriverList.scss';
import './DeliveryWindow.scss';
// import Select from 'react-dropdown-select';
import { arrayToDict, sumArr } from 'src/util/array';
import { receiptHourFormat } from '.';
import {
  DeliveryInfoDisplay,
  LOGGING,
  ordinal,
  StopsMap,
  SelectStopDriver,
  SelectStopIndex,
} from './';
import { AdminAcceptedDriverControls } from './AdminAcceptedDriverControls';
import { AdminDeliveryHeader } from './AdminDeliveryHeader';

const OrderOpsView = ({
  order,
  onSelectDriver,
  onResetDriver,
  drivers,
  displayDriverOnly,
  driverTotalStops,
  onSelectStop,
  isBottom,
  bottomRef,
}) => {
  let stopNames = {};
  for (let i = 0; i < driverTotalStops; i++) {
    stopNames[i] = { name: `${ordinal(i + 1)}` };
  }
  return displayDriverOnly === -1 || (order.driver && displayDriverOnly === order.driver._id) ? (
    <div className="receipt" ref={isBottom ? bottomRef : null}>
      <div className="driver-action ">
        <span className="driver-label">customer</span>
        <span className="order-status">{`${order.name} ${
          order.nameList.length > 1 ? `(${order.nameList.length})` : ''
        }`}</span>
      </div>
      <div className="driver-action">
        <span className="driver-label">Delivery Window</span>
        <span className="order-status">
          {moment(order.window.start).format(receiptHourFormat)}~{' '}
          {moment(order.window.end).format(receiptHourFormat)}
        </span>
      </div>
      {order.cancelTime && order.cancelTime > 0 ? (
        <div>cancelled</div>
      ) : (
        <React.Fragment>
          <div className="driver-action assign-driver">
            <span className="driver-label">driver</span>
            <SelectStopDriver
              selectedDriver={order.driver}
              onSelectDriver={onSelectDriver}
              drivers={drivers}
              onResetDriver={onResetDriver}
            />
          </div>
          {order.driver && (
            <div className="driver-action assign-stop">
              <span className="driver-label">Stop</span>
              <SelectStopIndex
                selectedStop={order.stop}
                onSelectStop={onSelectStop}
                driverTotalStops={driverTotalStops}
                driverColor={order.driver && order.driver.profileColor}
              />
            </div>
          )}
          {order.route && (
            <div className="driver-action route-info">
              <span className="driver-label">Route</span>
              <span>{order.route._id}</span>
            </div>
          )}
        </React.Fragment>
      )}
      <div className="receipt-section">
        <div className="receipt-subsection">
          <DeliveryInfoDisplay order={order} shortForm={true} />
        </div>
      </div>
    </div>
  ) : null;
};

const DriverList = ({
  allDriverIds,
  bottomRef,
  dinnerNumber,
  displayDriverOnly,
  drivers,
  driversOnDuty,
  driversWithRoute,
  isLunch,
  lunchNumber,
  onAskDriverAvailability,
  onBackToTop,
  onDeliver,
  onDispatch,
  onFilterDriver,
  onFinalizeDriverRoute,
  onFindDriverRoutes,
  onGetDriverTime,
  onMarkAsOrdered,
  onNotifyPickMeal,
  onResetDriverForOrder,
  onSelectDriverForOrder,
  onSelectMeal,
  onSelectRestaurant,
  onSelectStopForOrder,
  onToggleShowDriversForOrder,
  onToggleShowDriversForOrderOnMap,
  onToggleShowStopsForOrder,
  orders,
  processingOrderId,
  restaurantList,
  selectedRestaurant,
  showDriversForOrder,
  showDriversForOrderOnMap,
  showStopsForOrder,
  viewDishes,
  showRestaurantOptions,
  onSwap,
  onCancelDriver,
}) => {
  const restaurant = selectedRestaurant?.[0];
  const selectedRestaurantIndex = restaurantList.findIndex((r) => r._id === restaurant._id);
  LOGGING &&
    console.log('DriverList rendering with: ', {
      allDriverIds,
      drivers,
      driversOnDuty,
      orders,
      restaurant,
      restaurantList,
      selectedRestaurant,
      showRestaurantOptions,
      selectedRestaurantIndex,
    });

  const {
    acceptedDriverIds,
    waitingDriverIds,
    expiredDriverIds,
    rejectedDriverIds,
    unAskedDriverIds,
  } = allDriverIds;

  let acceptedDrivers = {},
    accetedDriverList = [];
  if (acceptedDriverIds && acceptedDriverIds.length > 0) {
    acceptedDriverIds.forEach((d) => {
      acceptedDrivers[d] = drivers[d];
      accetedDriverList.push({ _id: d, ...drivers[d] });
    });
  }
  const restaurantIndices = restaurantList.map((r, i) => i);
  let combinedStops = [];
  orders.forEach((stop) => {
    const { address, userGroup, name, user } = stop;
    const existingCombinedStopIndex = combinedStops.findIndex((s) => s.address === address);
    if (existingCombinedStopIndex > -1) {
      if (stop.stop == null) {
        combinedStops[existingCombinedStopIndex].unassignedOrders = true;
      }
      combinedStops[existingCombinedStopIndex].nameList.push(stop._id);
    } else {
      combinedStops.push({
        ...stop,
        name: userGroup ? userGroup.name : name && name.trim().length > 0 ? name : user?.firstName,
        nameList: [stop._id],
      });
    }
  });
  LOGGING && console.log('got:', { acceptedDriverIds, combinedStops });

  // ==== Driver Control Panel =====
  const driverStopDetails = acceptedDriverIds.map((driverId) => {
    const driverStops = combinedStops.filter((o) => o.driver && o.driver._id === driverId);
    return {
      driverId: driverId,
      numStops: driverStops.length,
      eta: Math.max(driverStops.reduce((a, b) => (b.etaAtDispatch || 0) + a + 2, 0) - 2, 0),
    };
  });
  const stopDetailsByDriverId = arrayToDict(
    driverStopDetails,
    (details) => details.driverId,
    (details) => details
  );
  // ==== END Driver Control Panel =====
  return (
    <div className="drivers-planning">
      <AdminDeliveryHeader
        isLunch={isLunch}
        numLunchOrders={lunchNumber}
        numDinnerOrders={dinnerNumber}
        drivers={drivers}
        acceptedDriverIds={acceptedDriverIds}
        waitingDriverIds={waitingDriverIds}
        expiredDriverIds={expiredDriverIds}
        rejectedDriverIds={rejectedDriverIds}
        unAskedDriverIds={unAskedDriverIds}
        restaurant={restaurant}
        selectedRestaurantIndex={selectedRestaurantIndex}
        restaurantIndices={restaurantIndices}
        restaurantList={restaurantList}
        onSelectMeal={onSelectMeal}
        onSelectRestaurant={onSelectRestaurant}
        onAskDriverAvailability={onAskDriverAvailability}
      />
      <div className="driver-planning-panel">
        <div className="left-panel">
          {combinedStops.length > 0 ? (
            <React.Fragment>
              <StopsMap
                drivers={acceptedDrivers}
                stops={combinedStops
                  .filter((o) =>
                    displayDriverOnly !== -1 ? o.driver && o.driver._id === displayDriverOnly : true
                  )
                  .map((o) => {
                    const {
                      address,
                      map,
                      user,
                      name,
                      driver,
                      stop: stopIndex,
                      etaAtRestaurant: eta,
                      _id,
                      window,
                      nameList,
                      groupName,
                      unassignedOrders,
                    } = o;

                    return {
                      address,
                      map,
                      name,
                      groupName,
                      nameList,
                      driverColor: driver ? driver.profileColor : 'grey',
                      driverId: driver ? driver._id : null,
                      selectedDriverTotalStops: driver
                        ? combinedStops.filter((o) => o.driver && o.driver._id === driver._id)
                            .length
                        : 0,
                      stopIndex,
                      eta,
                      _id,
                      isEarlyDelivery: moment(window.start).format(receiptHourFormat) === '11:00AM',
                      unassignedOrders,
                    };
                  })}
                restaurant={restaurant}
                showDriverListForOrder={showDriversForOrderOnMap}
                onToggleShowDriverListForOrder={onToggleShowDriversForOrderOnMap}
                onSelectDriverForOrder={onSelectDriverForOrder}
                onSelectStopForOrder={onSelectStopForOrder}
              />
            </React.Fragment>
          ) : null}
        </div>
        <div className="right-panel">
          <div
            className="drivers-on-duty-desktop-place-holder"
            // style={{ paddingTop: `${acceptedDriverIds.length * 45 + 90}px` }}
          ></div>
          <AdminAcceptedDriverControls
            drivers={drivers}
            acceptedDriverIds={acceptedDriverIds}
            stopDetailsByDriverId={stopDetailsByDriverId}
            displayDriverOnly={displayDriverOnly}
            driversWithRoute={driversWithRoute}
            restaurant={restaurant}
            orders={orders}
            isLunch={isLunch}
            onNotifyPickMeal={onNotifyPickMeal}
            onDriverSwap={onSwap}
            onFilterDriver={onFilterDriver}
            onFindDriverRoutes={onFindDriverRoutes}
            onGetDriverTime={onGetDriverTime}
            onCancelDriver={onCancelDriver}
            onFinalizeDriverRoute={onFinalizeDriverRoute}
          />
          <div className="ops-order-list">
            {combinedStops
              .sort((a, b) => (a.stop !== undefined ? -1 : 1))
              .sort((a, b) =>
                a.stop !== undefined && b.stop !== undefined && a.stop < b.stop ? -1 : 1
              )
              .map((order, i) => (
                <OrderOpsView
                  key={i}
                  displayDriverOnly={displayDriverOnly}
                  index={i}
                  totalNumber={combinedStops.length}
                  isBottom={i === combinedStops.length - 1}
                  bottomRef={bottomRef}
                  order={order}
                  onDispatch={onDispatch.bind(this, order._id)}
                  onDeliver={onDeliver.bind(this, order._id)}
                  processingOrderId={processingOrderId}
                  onSelectDriver={onSelectDriverForOrder.bind(this, order.nameList)}
                  onResetDriver={onResetDriverForOrder.bind(this, order._id)}
                  drivers={accetedDriverList}
                  showDrivers={showDriversForOrder === order._id}
                  onToggleShowDrivers={onToggleShowDriversForOrder.bind(this, order._id)}
                  driverTotalStops={
                    order.driver
                      ? combinedStops.filter((o) => o.driver && o.driver._id === order.driver._id)
                          .length
                      : 0
                  }
                  onSelectStop={onSelectStopForOrder.bind(this, order._id)}
                  showStops={showStopsForOrder === order._id}
                  onToggleShowStops={onToggleShowStopsForOrder.bind(this, order._id)}
                  showDishes={viewDishes}
                  onMarkAsOrdered={onMarkAsOrdered.bind(this, order._id)}
                />
              ))}
          </div>
        </div>

        <button onClick={onBackToTop} className="page-shortcut back-to-top">
          <FontAwesomeIcon icon={faArrowToTop} />
        </button>
      </div>
    </div>
  );
};

export default DriverList;
