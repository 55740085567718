import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchDishes } from '../../store/actions';
import { LOGGING, MenuCategories } from './';

class DailyMenu extends Component {
  constructor(props) {
    super();
    const { dish } = props;
    this.state = {
      dish,
      dishesByCategory: {},
      loading: false,
    };
    this.selectedRef = React.createRef();
    this.handleSelectDish = this.handleSelectDish.bind(this);
    this.handleToggleCategory = this.handleToggleCategory.bind(this);
  }

  handleSelectDish(selectedDish, e) {
    e.preventDefault();
    LOGGING && console.log('handleSelectDish called with: ', selectedDish);
    let { dishesByCategory } = this.state;
    this.setState({ dish: selectedDish, dishesByCategory });
  }
  handleToggleCategory(category, e) {
    e.preventDefault();
    let { dishesByCategory } = this.state;
    dishesByCategory[category].showDetails = !dishesByCategory[category].showDetails;
    this.setState({ dishesByCategory });
  }

  componentDidMount() {
    this.setState({ loading: true }, () => {
      this.props.fetchDishes().then(() => {
        const { dishes, dish } = this.props;
        let dishesByCategory = {};
        for (const category in dishes) {
          dishesByCategory[category] = {
            dishes: dishes[category],
            showDetails: dishes[category].find((d) => d._id === dish._id),
          };
        }
        this.setState({ loading: false, dishesByCategory }, () => {
          window.scrollTo({
            top: this.selectedRef.current.offsetTop - window.innerHeight / 3,
            left: 0,
            behavior: 'smooth',
          });
        });
      });
    });
  }
  render() {
    const { dish, dishesByCategory, loading } = this.state;
    const { menus, onClose, onSaveDish } = this.props;
    LOGGING &&
      console.log('DailyMenu rendering with:', {
        menus,
        dishesByCategory,
        loading,
      });
    return (
      <MenuCategories
        dishesByCategory={dishesByCategory}
        selectedDish={dish}
        loading={loading}
        onClose={onClose}
        selectedRef={this.selectedRef}
        onSelect={this.handleSelectDish}
        onSave={onSaveDish}
        onToggleCategory={this.handleToggleCategory}
      />
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log('DailyMenu got redux state:', state);
  return {
    dishes: state.dishes,
    menus: state.menus,
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, { fetchDishes }, null, {
  forwardRef: true,
})(DailyMenu);
