import { faGlassCheers } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import styled from "styled-components";
import { Container, Field } from "./Contact";
import { CenteredByColumn, Gold } from "../../Shared";
const FreeMealContainer = styled(Container)`
  align-self: stretch;
  background-color: ${Gold};
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  border: none;
  div {
    align-self: auto;
    flex-grow: 0;
  }
  svg {
    margin-left: 15px;
  }
`;
export const FreeMeal = () => {
  const cart = useSelector((state: RootState) => state.cart.payLoad);
  if (cart?.due > 0) return null;
  return (
    <FreeMealContainer>
      <Field>
        Enjoy your free meal!
        <FontAwesomeIcon icon={faGlassCheers} />
      </Field>
    </FreeMealContainer>
  );
};
