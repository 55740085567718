import _ from "lodash";
import moment from "moment-timezone";
import {
  addDishQuantityToCart,
  getGoodKeyFromCart,
  removeDishQuantityFromCart,
} from "src/util/cart";
import { LOGGING } from "../../constants";
import { calcDueServer, getDishKey } from "../../util/order";
import { LOAD_CART, RESET_CART } from "../actionTypes";
import { apiCall } from "../api";

export const loadCart = (cart) => ({
  type: LOAD_CART,
  cart,
});
export const resetCart = () => ({
  type: RESET_CART,
});
/*
cart: {
  window: { start, end, cost},
  goods: {[dishId]: {dish, quantity, comment}},
  sum,
  name,
  phone,
  address,
  paymentIntentId,
  payTime,
  orderId,
}
*/

export const emptyCart = () => {
  return async (dispatch, getState) => {
    const { cart } = getState();
    const {
      _id: cartId,
      groupOrderType,
      user,
      meal,
      window,
    } = cart?.payLoad || {};
    LOGGING &&
      console.log("emptyCart called:", {
        cart,
        cartId,
        groupOrderType,
        user,
        meal,
        window,
      });
    try {
      if (cartId) {
        return await apiCall("post", `/orders/emptyCart/${cartId}`, {
          groupOrderType,
          user: user?._id,
          meal: meal?._id,
          window,
        }).then((result) => {
          LOGGING && console.log("deleteCart got from server:", result);
          dispatch(resetCart());
          return { goods: {}, sum: 0, paymentIntentId: null };
        });
      } else {
        dispatch(resetCart());
        return { goods: {}, sum: 0, paymentIntentId: null };
      }
    } catch (err) {
      LOGGING && console.log("deleteCart got err", err);

      return { goods: {}, sum: 0, paymentIntentId: null };
    }
  };
};

export const updateDishInCart = ({ goodsKey, dish }) => {
  return async (dispatch, getState) => {
    const { currentUser, cart } = getState();
    const mealId = cart?.payLoad?.meal?._id;
    LOGGING &&
      console.log("updateDishInCart called with cart in reduxState:", cart);
    const { goods } = cart.payLoad;
    const updatedGoods = { ...goods, [goodsKey]: { ...dish } };
    const updatedCart = await calcDueServer(currentUser, {
      ...cart.payLoad,
      meal: mealId,
      goods: updatedGoods,
    });
    dispatch(loadCart(updatedCart));
    return updatedCart;
  };
};

export const setDishCommentInCart = ({ dishId, comment }) => {
  LOGGING &&
    console.log("setDishCommentInCart called with:", {
      dishId,
      comment,
    });

  return (dispatch, getState) => {
    const { cart } = getState();
    LOGGING && console.log("setDishCommentInCart got cart", cart);
    let { goods } = cart.payLoad;
    goods[dishId].comment = comment;
    const updatedCart = {
      ...cart.payLoad,
      goods,
    };
    LOGGING && console.log("setDishCommentInCart got updatedCart", updatedCart);
    dispatch(loadCart(updatedCart));
  };
};

export const addDishInCart = ({
  dish,
  selections,
  price,
  quantity,
  comment,
  window,
  referralCode,
  mealId,
}) => {
  LOGGING &&
    console.log("addDishInCart called with:", {
      dish,
      selections,
      window,
      referralCode,
    });

  return (dispatch, getState) => {
    const { cart } = getState();
    LOGGING && console.log("addDishInCart got cart:", cart);
    const {
      goods = {},
      window: oldWindow,
      meal: oldMeal,
      referralCode: oldReferralCode,
    } = cart.payLoad;
    const updateGoods = addDishQuantityToCart({
      goods,
      dish,
      selections,
      price,
      quantity,
      comment,
    });

    const updatedCart = {
      ...cart.payLoad,
      meal: oldMeal || mealId,
      goods: updateGoods,
      window: oldWindow || window,
      couponCode: oldReferralCode || referralCode,
    };
    dispatch(loadCart(updatedCart));
    return updatedCart;
  };
};

// @param goodsToAdd: Good from PureDishChooser.
//   good.dish is hydrated with the dish object not just dish ID.
export const addGoodsToCart = ({
  goodsToAdd,
  window,
  referralCode,
  mealId,
  isMember,
  promo,
}) => {
  LOGGING &&
    console.log("addGoodsToCart called with:", {
      goodsToAdd,
      window,
      referralCode,
      mealId,
      isMember,
      promo,
    });

  return (dispatch, getState) => {
    const { cart } = getState();
    LOGGING && console.log("addGoodsToCart got existing cart:", cart);
    const {
      goods = {},
      window: oldWindow,
      meal: oldMeal,
      referralCode: oldReferralCode,
      couponCode: oldCouponCode,
    } = cart.payLoad;

    const updatedGoods = goodsToAdd.reduce((currGoods, goodToAdd) => {
      return addDishQuantityToCart({
        goods: currGoods,
        dish: goodToAdd.dish,
        selections: goodToAdd.selections,
        price: goodToAdd.price,
        quantity: goodToAdd.quantity,
        comment: goodToAdd.comment,
        addedBy: goodToAdd.addedBy,
      });
    }, goods);

    const updatedCart = {
      ...cart.payLoad,
      meal: oldMeal || mealId,
      goods: updatedGoods,
      window: oldWindow || window,
      couponCode: oldReferralCode || referralCode || oldCouponCode,
      isMember: isMember,
      promo: promo,
      lastUpdate: moment().valueOf(),
    };
    console.log("addGoodsToCart got updatedCart:", updatedCart);

    // if _id exists, send to server
    dispatch(loadCart(updatedCart));
    console.log("addGoodsToCart returning updatedCart:", updatedCart);
    return updatedCart;
  };
};
export const changeGoodQuantityInCart = (goodsKey, delta) => {
  return (dispatch, getState) => {
    const { cart } = getState();
    const { goods } = cart.payLoad;
    const existingGoodInCart = goods[goodsKey];
    const newQuantity = existingGoodInCart.quantity + delta;
    if (newQuantity <= 0) {
      delete goods[goodsKey];
    } else {
      goods[goodsKey] = {
        ...existingGoodInCart,
        quantity: newQuantity,
      };
    }
    const updatedCart = {
      ...cart.payLoad,
      lastUpdate: moment().valueOf(),
      goods,
    };
    dispatch(loadCart(updatedCart));
  };
};

export const updateExistingGoodInCart = (goodsKey, updatedGood) => {
  return (dispatch, getState) => {
    const { cart } = getState();
    const { goods } = cart.payLoad;
    const updatedCart = {
      ...cart.payLoad,
      lastUpdate: moment().valueOf(),
      goods: { ...goods, [goodsKey]: updatedGood },
    };
    dispatch(loadCart(updatedCart));
  };
};
// @param: cart is optional. Only used when we need to pass in cart via memory and not use
// the redux version (OrderDetails page.)
export const removeDishFromCart = ({ dish, quantity }) => {
  return (dispatch, getState) => {
    const { cart } = getState();

    const updatedGoods = removeDishQuantityFromCart({
      goods: cart.payLoad?.goods,
      dish: dish,
      quantity: quantity,
    });

    const updatedCart = {
      ...cart.payLoad,
      lastUpdate: moment().valueOf(),
      goods: updatedGoods,
    };
    dispatch(loadCart(updatedCart));
    return updatedCart;
  };
};

export const changeDishQuantityInCart = ({ goodsKey, change, window }) => {
  LOGGING &&
    console.log("changeDishQuantityInCart called with:", {
      goodsKey,
      change,
      window,
    });

  return async (dispatch, getState) => {
    const { cart, currentUser } = getState();
    LOGGING && console.log("changeDishQuantityInCart got cart:", cart);
    const { goods } = cart.payLoad;
    const targetGoods = goods[goodsKey];
    let updatedGoods = [];

    if (targetGoods.quantity + change === 0) {
      updatedGoods = _.omit(goods, goodsKey);
    } else {
      updatedGoods = {
        ...goods,
        [goodsKey]: {
          ...targetGoods,
          quantity: targetGoods.quantity + change,
        },
      };
    }

    const updatedCart = await calcDueServer(currentUser, {
      ...cart.payLoad,
      goods: updatedGoods,
    });
    LOGGING &&
      console.log("changeDishQuantityInCart got updatedCart", updatedCart);
    if (updatedCart.sum === 0) {
      dispatch(resetCart());
    }
    dispatch(loadCart(updatedCart));
    return updatedCart;
  };
};

export const changeTips = (tips, tipRate, tipCustom) => {
  LOGGING &&
    console.log("changeTips called with:", {
      tips,
      tipRate,
      tipCustom,
    });

  return async (dispatch, getState) => {
    const { cart, currentUser } = getState();
    LOGGING && console.log("changeTips got cart:", cart);
    const { user } = cart.payLoad;
    const orderUser = user ? { user } : currentUser;
    const updatedCart = await calcDueServer(orderUser, {
      ...cart.payLoad,
      tips,
      tipRate,
      tipCustom,
    });
    LOGGING && console.log("changeTips got updatedCart:", updatedCart);
    dispatch(loadCart(updatedCart));
    return updatedCart;
  };
};

export const initTips = (tips) => {
  LOGGING &&
    console.log("initTips called with:", {
      tips,
    });

  return async (dispatch, getState) => {
    const { cart, currentUser } = getState();
    LOGGING && console.log("initTips got cart:", cart);
    const mealId = cart?.payLoad?.meal?._id;
    const updatedCart = await calcDueServer(currentUser, {
      ...cart.payLoad,
      tips,
      meal: mealId,
    });
    dispatch(loadCart(updatedCart));
    return updatedCart;
  };
};

export const applyCouponCode = (
  order,
  code,
  scene,
  resetCode,
  originalCredit
) => {
  return async (dispatch, getState) => {
    const { currentUser, cart } = getState();
    let mealId;
    try {
      LOGGING &&
        console.log("applyCouponCode called with:", {
          cart,
          order,
          code,
          scene,
          resetCode,
          originalCredit,
        });
      const couponCode = await apiCall(
        "PUT",
        `/orders/applyCouponCode/${order._id}`,
        {
          code,
          order,
          resetCode,
        },
        currentUser
      );
      switch (scene) {
        case "cart":
          mealId = cart?.payLoad?.meal?._id || cart?.payLoad?.meal;
          const updatedCart = await calcDueServer(currentUser, {
            ...cart.payLoad,
            // meal: mealId,
            couponCode,
          });
          LOGGING &&
            console.log("applyCouponCode cart:", {
              cart,
              updatedCart,
              mealId,
            });
          dispatch(loadCart(updatedCart));
          return updatedCart;
        case "order":
          mealId = order?.meal?._id || order.meal;
          const updatedOrder = await calcDueServer(
            currentUser,
            {
              ...order,
              couponCode,
              meal: mealId,
            },
            originalCredit
          );
          return updatedOrder;
        default:
          break;
      }
    } catch (err) {
      LOGGING && console.log("applyCouponCode got err", err);
      // dispatch(loadCart({ ...cart.payLoad, couponCode: null }));
      // dispatch(loadCart({ ...cart, discount: 0 }));
      throw err;
    }
  };
};

export const draftCart = (mealTime) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING &&
      console.log("draftCart called with:", {
        currentUser,
        mealTime,
      });
    return apiCall(
      "post",
      `/recommendations/draftMealByHistory`,
      {
        userId: currentUser?.user?._id,
        mealTime,
      },
      getState().currentUser
    )
      .then((draftedCarts) => {
        LOGGING && console.log("draftCart from backend:", draftedCarts);
        return draftedCarts;
      })
      .catch((err) => {
        LOGGING && console.log("draftCart got err", err);
      });
  };
};

export const getRecommendationByRestaurant = (restaurantId) => {
  return (dispatch, getState) => {
    LOGGING &&
      console.log("getRecommendationByRestaurant called with:", {
        restaurantId,
      });
    return apiCall(
      "post",
      `/recommendations/getRecommendationByRestaurant`,
      {
        restaurantId,
      },
      getState().currentUser
    )
      .then((recommendationCart) => {
        LOGGING &&
          console.log(
            "getRecommendationByRestaurant from backend:",
            recommendationCart
          );
        return recommendationCart;
      })
      .catch((err) => {
        LOGGING && console.log("getRecommendationByRestaurant got err", err);
      });
  };
};
