import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import styled from "styled-components";
import { Field, Container, Title, ContactContainer } from "./Contact";
import { ActionBlack, ActionUnderline } from "../../Shared";
import { BreakPoint } from "../mealMenu/MealHeader";
export const Delivery = () => {
  const cart = useSelector((state: RootState) => state.cart.payLoad);
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const [address, setAddress] = useState<string>("");
  const [address_room_info, setAddressRoomInfo] = useState<string>("");
  const [ringDoorbell, setRingDoorbell] = useState<boolean>(false);
  const [note, setNote] = useState<string>("");
  useEffect(() => {
    setAddress(cart?.address || currentUser?.user?.deliveryInfo?.address || "");
    setNote(cart?.note || currentUser?.user?.deliveryInfo?.note || "");
    setAddressRoomInfo(
      cart?.address_room_info ||
        currentUser?.user?.deliveryInfo?.address_room_info ||
        ""
    );
    setRingDoorbell(
      cart?.ring_doorbell ||
        currentUser?.user?.deliveryInfo?.ring_doorbell ||
        false
    );
  }, []);
  return (
    <ContactContainer>
      <Title>delivery info</Title>
      <Field>{address}</Field>
      <Field>{address_room_info}</Field>
      <Field>{note}</Field>
      <Field>{`Ring doorbell? ${ringDoorbell ? "Yes" : "No"}`}</Field>
    </ContactContainer>
  );
};
const EditDeliveryPayemntInfoButton = styled(ActionUnderline)`
  position: absolute;
  right: 20px;
  top: 20px;
  @media screen and (max-width: ${BreakPoint}px) {
    top: 25px;
    right: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.6;
    padding: 0px;
  }
`;
export const EditDeliveryPayemntInfo = ({ onEdit }) => (
  <EditDeliveryPayemntInfoButton onClick={onEdit}>
    edit
  </EditDeliveryPayemntInfoButton>
);
const SaveDeliveryPayemntInfoButton = styled(ActionBlack)`
  margin-top: 0px;
`;
export const SaveDeliveryPayemntInfo = ({ onSave }) => (
  <SaveDeliveryPayemntInfoButton onClick={onSave}>
    save
  </SaveDeliveryPayemntInfoButton>
);
