import React from 'react'; // eslint-disable-line no-unused-vars
import './ExpandToggle.scss';
import {
  faChevronDown as faChevronDownBold,
  faChevronUp as faChevronUpBold,
} from '@fortawesome/free-solid-svg-icons';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export const BubbleUpperRight = ({ content, className, onHide }) => (
  <React.Fragment>
    {onHide ? <div className="bubble-overlay" onClick={onHide}></div> : null}
    <div className={`bubble upper-right ${className}`}>{content}</div>
  </React.Fragment>
);

const ExpandToggle = ({
  toggleOffText,
  toggleOnText,
  onToggle,
  showDetails,
  bold = false,
  className,
  color,
}) => (
  <button
    type="button"
    className={`expand-toggle ${className || ''}`}
    style={color ? { backgroundColor: color, color: 'white' } : {}}
    onClick={onToggle}
  >
    <span>{showDetails ? toggleOffText : toggleOnText}</span>
    {bold ? (
      <FontAwesomeIcon icon={showDetails ? faChevronUpBold : faChevronDownBold} />
    ) : (
      <FontAwesomeIcon icon={showDetails ? faChevronUp : faChevronDown} />
    )}
  </button>
);
export default ExpandToggle;
