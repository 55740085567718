import React from "react";
import "./Referrals.scss";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import {
  faPaperPlane,
  faTrashAlt,
  faPlus,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PhoneInput from "react-phone-number-input";
import { Link } from "react-router-dom";
import {
  PhoneFormat,
  Back,
  LOGGING,
  MaxNumFriends,
  MinNumOrders,
  ReferralSlogan,
  Loading,
  SupportedSanJoseZipCodes,
} from "./";
const ReferralItem = ({ referral, index }) => {
  const { to, firstOrderTime, signUpTime } = referral;
  const { name, contact } = to;
  return (
    <div className="referral-item sent-referral">
      <label>friend {index + 1}</label>
      <div className="referral-content">
        <div className="referral-field">
          <span>name</span>
          <span>{name}</span>
        </div>
        <div className="referral-field">
          <span>mobile</span>
          <span>{PhoneFormat(contact)}</span>
        </div>
        <div className="referral-field">
          <span>status</span>
          <span>{`${
            firstOrderTime ? "order placed" : signUpTime ? "joined" : "invited"
          }`}</span>
        </div>
        <div className="referral-field">
          <span>your reward</span>
          <span>${`${firstOrderTime ? 10 : signUpTime ? 5 : 2}`}</span>
        </div>
      </div>
    </div>
  );
};

const Rewards = ({ credit, creditSpent }) => {
  const remaining = (credit || 0) - (creditSpent || 0);
  LOGGING &&
    console.log("Rewards rendering with:", { creditSpent, credit, remaining });
  return (
    <div className="referral-item rewards">
      <label>Your referral rewards</label>
      <ul className="referral-content reward-summary">
        <li className="referral-field">
          <span>earned</span>
          <span>{`$${(credit || 0).toFixed(2)}`}</span>
        </li>
        <li className="referral-field">
          <span>used</span>
          <span>{`-$${(creditSpent || 0).toFixed(2)}`}</span>
        </li>
        <li className="referral-field">
          <span>available</span>
          <span>{`$${remaining.toFixed(2)}`}</span>
        </li>
      </ul>
    </div>
  );
};

const SentReferralList = ({ referrals, credit, creditSpent, showDetails }) => (
  <div className="referrals-list sent-referrals">
    <Rewards credit={credit} creditSpent={creditSpent} />{" "}
    {showDetails
      ? referrals.map((r, i) => <ReferralItem referral={r} index={i} key={i} />)
      : null}
  </div>
);

const NewReferral = ({
  onEdit,
  onDelete,
  onEditPhone,
  friend,
  index,
  numInvited,
}) => {
  LOGGING && console.log("NewReferral rendering with friend::", friend);
  return (
    <div className="referral-item new-referral">
      <label>friend {index + numInvited + 1}</label>
      {index > 0 ? (
        <button className="delete-referral" onClick={onDelete}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      ) : null}
      <div
        className={`referral-content ${
          friend.errorField === "name" ? "has-error" : ""
        }`}
      >
        <input
          type="text"
          value={friend.name}
          placeholder="enter friend's name"
          onChange={onEdit}
          className="friend-name"
          name="name"
          autoComplete="new-friend"
        />
      </div>
      <div
        className={`referral-content ${
          friend.errorField === "contact" ? "has-error" : ""
        }`}
      >
        <PhoneInput
          placeholder="Enter friend's mobile phone"
          value={friend.contact}
          onChange={onEditPhone}
          className="friend-contact"
          defaultCountry="US"
        />
      </div>
    </div>
  );
};
const NewReferralList = ({
  onAddFriend,
  onEditPhone,
  onEditFriend,
  onDeleteFriend,
  friends,
  numInvited,
  errorMessage,
  referralsRef,
  onSend,
  limit,
}) => (
  <div className="referrals-list new-referrals">
    {friends.map((f, i) => (
      <NewReferral
        key={i}
        onEdit={onEditFriend.bind(this, i)}
        onEditPhone={onEditPhone.bind(this, i)}
        onDelete={onDeleteFriend.bind(this, i)}
        friend={f}
        index={i}
        numInvited={numInvited}
      />
    ))}
    {friends.length + numInvited < limit ? (
      <div className="add-friend-wrapper">
        <button className="add-friend" onClick={onAddFriend}>
          <span>add friend</span>
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
    ) : null}
    <span
      className={`error-message ${
        errorMessage && errorMessage.trim().length > 0
          ? "has-error"
          : "no-error"
      }`}
    >
      {errorMessage}
    </span>
    <button className="send-invite" ref={referralsRef} onClick={onSend}>
      <span>invite</span>
      <FontAwesomeIcon icon={faPaperPlane} />
    </button>
  </div>
);
const ReferralConditions = () => (
  <ul className="condition-list">
    <li className="condition-item">
      <span>
        <b>1.</b>
        {`You must have placed ${MinNumOrders} or more orders on foodie.earth.`}
      </span>
    </li>
    <li className="condition-item">
      <span>
        <b>2.</b>
        Your friend's address must be in one of these cities{" "}
      </span>
      <ul className="condition-section referral-cities">
        <li>Menlo Park</li>
        <li>Stanford</li>
        <li>Palo Alto</li>
        <li>East Palo Alto</li>
        <li>Los Altos</li>
        <li>Los Altos Hills</li>
        <li>Mountain View</li>
        <li>Sunnyvale</li>
        <li>Cupertino</li>
        <li>Santa Clara</li>
        <li>Milpitas</li>
        <li>Saratoga</li>
        <li>Campbell</li>
        <li>Los Gatos</li>
        <li>Monte Sereno</li>
        <li>Atherton</li>
        <li>San Jose ({`${SupportedSanJoseZipCodes.join(", ")}`})</li>
      </ul>
    </li>
    <li className="condition-item">
      <span>
        <b>3.</b>
        For each friend, you will receive{" "}
      </span>
      <ul className="condition-section referral-times">
        <li>$5 upon the invited friend's sign-up,</li>
        <li>$5 upon the invited friend's first order.</li>
      </ul>
    </li>
    <li className="condition-item">
      <span>
        <b>4.</b>
        {`You may invite up to ${MaxNumFriends} friends.`}
      </span>
    </li>
    <li className="condition-item">
      <span>
        <b>5.</b>
        Your friends will also receive $5 credit when they sign up.
      </span>
    </li>
  </ul>
);
const Referrals = ({
  friends,
  onEditFriend,
  onEditPhone,
  onDeleteFriend,
  onAddFriend,
  onSendInvitation,
  referralsRef,
  errorMessage,
  referrals,
  onClose,
  credit,
  creditSpent,
  showSentDetails,
  onToggleShowSentDetails,
  ordersPlaced,
  loading,
  limit,
}) => (
  <div className="referrals">
    <Link to="/referrals" className="referrals-title">
      <span>
        <FontAwesomeIcon icon={faHeart} />
        {ReferralSlogan}
      </span>
      <Back onClick={onClose} />
    </Link>
    <div className="referrals-body">
      {loading ? <Loading /> : null}
      <div className="referrals-section referral-conditions">
        <div className="referrals-section-title">referral terms</div>
        <ReferralConditions />
      </div>
      <div className="referrals-section your-referrals">
        <div className="referrals-section-title">Your referrals</div>
        {credit || (referrals && referrals.length > 0) ? (
          <div className="referrals-subsection sent-referrals">
            <div className="referrals-subsection-title">
              <span>{`sent (${
                referrals && referrals.length ? referrals.length : 0
              })`}</span>
              {referrals && referrals.length ? (
                <button
                  className="toggle-sent-details"
                  onClick={onToggleShowSentDetails}
                >
                  <FontAwesomeIcon
                    icon={showSentDetails ? faChevronUp : faChevronDown}
                  />
                </button>
              ) : null}
            </div>
            <SentReferralList
              referrals={referrals}
              credit={credit}
              creditSpent={creditSpent}
              showDetails={showSentDetails}
            />
          </div>
        ) : null}
        {(referrals?.length || 0) < limit && (
          <>
            <div className="referrals-subsection new-referrals">
              {/* {referrals && referrals.length > 0 ? ( */}
              <div className="referrals-subsection-title">
                <span>new</span>
              </div>
              {/* ) : null} */}
              {ordersPlaced >= MinNumOrders ? (
                <NewReferralList
                  friends={friends}
                  onAddFriend={onAddFriend}
                  onEditFriend={onEditFriend}
                  onDeleteFriend={onDeleteFriend}
                  onEditPhone={onEditPhone}
                  numInvited={referrals ? referrals.length : 0}
                  onSend={onSendInvitation}
                  referralsRef={referralsRef}
                  errorMessage={errorMessage}
                  limit={limit}
                />
              ) : (
                <span>{`You need to place ${
                  MinNumOrders - ordersPlaced
                } more orders to be eligible to send referrals.`}</span>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  </div>
);

export default Referrals;
