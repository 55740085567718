import React, { ReactElement } from 'react';
import { MealSerialized } from 'src/api/meals';
import styled from 'styled-components';
import { RestaurantMealCard } from './RestaurantMealCard';

export interface RestaurantUpcomingMealsProps {
  meals: MealSerialized[];
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const RestaurantUpcomingMeals: React.FC<RestaurantUpcomingMealsProps> = ({
  meals,
}): ReactElement => {
  return (
    <Container>
      {meals.length > 0 ? (
        meals.map((meal: MealSerialized) => <RestaurantMealCard key={meal._id} meal={meal} />)
      ) : (
        <div>
          <div style={{ marginBottom: '8px' }}>No upcoming meals...</div>
          <div>Contact (408) 520-0627 to request a meal</div>
        </div>
      )}
    </Container>
  );
};
