import exp from "constants";
import { LOGGING } from "../../constants";
import { apiCall } from "../api";

export const sendMessage = (text) => {
  LOGGING && console.log("sendMessage:", text);
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING && console.log("sendMessage currentUser:", currentUser);
    const userName = `${currentUser?.user?.firstName} ${currentUser?.user?.lastName}`;
    const userId = currentUser?.user?._id;
    LOGGING && console.log("calling backend with:", { text, userId, userName });
    return apiCall(
      "put",
      "/chats",
      { text, userId, userName },
      getState().currentUser
    )
      .then((sentMessage) => {
        LOGGING && console.log("sendMessage got:", sentMessage);
        return sentMessage;
      })
      .catch((err) => {
        LOGGING && console.log("sendMessage got err", err);
      });
  };
};

export const sendResponse = (messageId, text) => {
  LOGGING && console.log("sendResponse:", { messageId, text });
  return (dispatch, getState) => {
    return apiCall("post", "/chats", { text, replyTo: messageId })
      .then((sendResponse) => {
        LOGGING && console.log("sendResponse got:", sendResponse);
        return messageId;
      })
      .catch((err) => {
        LOGGING && console.log("sendMessage got err", err);
      });
  };
};

export const checkResponse = (messageId) => {
  LOGGING && console.log("checkResponse for message:", messageId);
  return (dispatch, getState) => {
    return apiCall("get", `/chats/checkResponse/${messageId}`)
      .then((foundResponse) => {
        LOGGING && console.log("checkResponse got:", foundResponse);
        return foundResponse?.text;
      })
      .catch((err) => {
        LOGGING && console.log("sendMessage got err", err);
      });
  };
};

export const readUnansweredMessages = () => {
  LOGGING && console.log("readUnansweredMessages");
  return (dispatch, getState) => {
    return apiCall("get", "/chats/checkUnanswered")
      .then((messages) => {
        LOGGING && console.log("readUnansweredMessages got:", messages);
        return messages;
      })
      .catch((err) => {
        LOGGING && console.log("readUnansweredMessages got err", err);
      });
  };
};

export const readMessages = () => {
  LOGGING && console.log("readMessages");
  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall("get", `/chats/${currentUser?.user?._id}`)
      .then((messages) => {
        LOGGING && console.log("readMessages got:", messages);
        return messages;
      })
      .catch((err) => {
        LOGGING && console.log("readMessages got err", err);
      });
  };
};

export const markAsRead = (messageIds) => {
  LOGGING && console.log("markAsRead:", messageIds);
  return (dispatch, getState) => {
    return apiCall("put", `/chats/markAsRead`, { messageIds })
      .then((result) => {
        LOGGING && console.log("markAsRead got:", result);
      })
      .catch((err) => {
        LOGGING && console.log("markAsRead got err", err);
      });
  };
};
