import React from "react";
// import { faChevronRight } from "@fortawesome/pro-regular-svg-icons"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { Centered } from "../../Shared";
import { BreakPoint } from "../mealMenu/MealHeader";
const iconSize = 60;
const IconContainer = styled(Centered)`
  width: ${iconSize}px;
  height: ${iconSize}px;
  /* background-color: white; */
  /* color: black; */
  color: white;
  border-radius: 50%;
  right: 0px;
  position: absolute;
  svg {
    font-size: 18px !important;
    margin: 0px;
  }
  @media screen and (max-width: ${BreakPoint}px) {
  }
`;
export const NextIcon = () => {
  return (
    <IconContainer>
      <FontAwesomeIcon icon={faChevronRight} />
    </IconContainer>
  );
};
