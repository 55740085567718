import { LOGGING } from '../../constants';
import { SET_NOMINATION, RESET_NOMINATION } from '../actionTypes';
import { apiCall } from '../api';

const setNomination = (nomination) => ({
  type: SET_NOMINATION,
  nomination,
});

const resetNomination = () => ({
  type: RESET_NOMINATION,
});

export function saveNomination(nomination) {
  return (dispatch) => {
    dispatch(setNomination(nomination));
  };
}

export const submitNomination = () => {
  return (dispatch, getState) => {
    const { currentUser, nomination } = getState();
    const userId = currentUser.user._id;
    return apiCall('post', '/nominations', { ...nomination, userId }, getState().currentUser)
      .then((result) => {
        LOGGING && console.log('submitNomination got result:', result);
        dispatch(resetNomination());
      })
      .catch((err) => {
        LOGGING && console.log('submitNomination got err', err);
      });
  };
};

export const readNominations = () => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    const userId = currentUser.user._id;
    LOGGING && console.log('readNominations got userId:', userId);
    return apiCall('put', '/nominations/', { userId }, getState().currentUser)
      .then((result) => {
        LOGGING && console.log('readNominations got result:', result);
        const emptyResult = result.filter((n) => !n.user);
        LOGGING && console.log('readNominations got emptyResult:', emptyResult);
        return result.filter((n) => n.user && n.restaurant.name.trim().length > 0);
      })
      .catch((err) => {
        LOGGING && console.log('readNominations got err', err);
      });
  };
};

export const deleteNomination = (nominationId) => {
  return (dispatch, getState) => {
    LOGGING && console.log('deleteNomination got nominationId:', nominationId);
    return apiCall('delete', `/nominations/${nominationId}`, {}, getState().currentUser)
      .then((result) => {
        LOGGING && console.log('readNominations got result:', result);
      })
      .catch((err) => {
        LOGGING && console.log('readNominations got err', err);
      });
  };
};
