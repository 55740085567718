import React, { useState } from "react";
import { faCcAmex } from "@fortawesome/free-brands-svg-icons";
import { faHouse, faCreditCard } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";
import { useSelector, useDispatch, useStore } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { RootState } from "src/store";
import { directOrder } from "src/store/actions";
import styled from "styled-components";
import { LOGGING } from "../..";
import {
  ActionBlack,
  ActionTransparent,
  ActionUnderline,
  Beige,
  BorderGrey,
  Card,
  Centered,
  NavHeight,
  centered,
  centeredByColumn,
  text12300,
} from "../../Shared";
/// import directOrder from action/index
import {} from "src/store";

const ContentContainer = styled(Card)`
  ${centeredByColumn}
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 2;
  padding: 15px 20px;
  box-shadow: 0 0px 16px 8px rgba(28, 29, 33, 0.3);
  @media (min-width: 440px) {
    padding: 15px calc(50vw - 200px);
  }
  border-top: 1px solid ${BorderGrey};
`;

const ExistingInfo = styled.button`
  ${centered}
  ${text12300}
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 15px;
  position: relative;
  line-height: 20px;
  svg {
    margin-right: 3px;
  }
  /* background-color: ${Beige}; */
  /* border: 1px solid ${BorderGrey};
  background-color: ${BorderGrey}; */
  /* padding: 0px 15px; */
  align-self: stretch;
  justify-content: flex-start;
  padding: 0px 5px;
  max-width: 400px;
`;
const Address = styled.span`
  margin-right: 10px;
`;
const Payment = styled.span``;
const PlaceOrder = styled(ActionBlack)`
  height: 60px;
  flex-grow: 1;
  align-self: stretch;
  font-size: 15px;
  label {
    font-weight: 600;
    margin-right: 20px;
  }
  max-width: 400px;
  //when disabled is true, opacity is 0.6
  opacity: ${(props) => (props.disabled === true ? 0.6 : 1)};
`;
const EditInfo = styled(ActionUnderline)`
  /* height: 50px; */
  /* width: 50px; */
  position: absolute;
  right: 5px;
  padding: 0px;
  line-height: 20px;
  height: 20px;
`;
export const DirectCheckout = ({ onEdit, onSubmit }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useStore();
  const cart = useSelector((state: RootState) => state.cart.payLoad);
  const address = cart?.user?.deliveryInfo?.address.split(",")[0];
  const [submitting, setSubmitting] = useState(false);
  const last4 = cart?.user?.stripeInfo?.card?.last4;
  const brand = cart?.user?.stripeInfo?.card?.brand;
  const due = cart?.due;
  LOGGING && console.log("DirectCheckout cart:", cart);
  const onPlaceOrder = (e) => {
    e.preventDefault();
    setSubmitting(true);
    onSubmit();
    directOrder()(dispatch, store.getState).then(() => {
      // toast.success("Order placed!");
      history.push("/post-checkout");
    });

    LOGGING && console.log("DirectCheckout onPlaceOrder");
  };
  return (
    <ContentContainer>
      <ExistingInfo onClick={onEdit}>
        <Address>
          <FontAwesomeIcon icon={faHouse} />
          <span>{address}</span>
        </Address>
        <Payment>
          <FontAwesomeIcon icon={faCreditCard} />
          ...{last4}
        </Payment>
        <EditInfo>Edit</EditInfo>
      </ExistingInfo>
      {/* <EditInfo>Proceed to checkout</EditInfo> */}
      <PlaceOrder onClick={onPlaceOrder} disabled={submitting}>
        {due ? <label>${due.toFixed(2)}</label> : null}{" "}
        {`place order${submitting ? "..." : ""}`}
      </PlaceOrder>
    </ContentContainer>
  );
};
