import React, { ReactElement, useState, useCallback, useEffect } from "react";
import {
  faCopy,
  faEllipsisH,
  faEnvelope,
  faSms,
  //
} from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EmailShareButton } from "react-share";
import { SendAlert } from "src/api/referrals";
import styled, { keyframes } from "styled-components";
import { LOGGING } from "../..";
import {
  Card,
  ActionGrey,
  BackgroundGrey,
  Beige,
  BorderGrey,
  Centered,
  centeredByColumn,
  CenteredByColumn,
  Peach,
  text12300,
  TextTitle,
  BackgroundDarkGreen,
  actionBlack,
} from "../../Shared";
import { GroupOrderContainer, GroupOrderContent } from "./ViralActions";
const BreakPoint = 800;
const CardHeight = 400;
const CardWidth = 550;
const InviteContent = styled(GroupOrderContent)`
  justify-content: flex-start;
  height: ${CardHeight}px;
  min-height: ${CardHeight}px;
  padding-top: 50px;
  @media only screen and (min-width: ${BreakPoint}px) {
    width: ${CardWidth}px;
    left: calc(50vw - ${CardWidth / 2}px);
    padding-top: ${CardHeight / 2}px;
  }
`;
const Title = styled(TextTitle)`
  font-size: 17px;
  margin-bottom: 10px;
  /* text-align: left; */
  text-transform: none;
  align-self: stretch;
  @media only screen and (min-width: ${BreakPoint}px) {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
`;
const ShareableLink = styled(Centered)`
  width: 100%;
  span {
    ${text12300}
    font-size: 10px;
    background-color: ${BackgroundGrey};
    padding: 8px 10px;
    align-self: stretch;
    flex-grow: 1;
  }
  button {
    display: none;
  }
  @media only screen and (min-width: ${BreakPoint}px) {
    button {
      display: block;
      ${actionBlack}
      margin-left: 10px;
    }
  }
`;

const Actions = styled(Centered)`
  padding: 20px 0px;
  margin-top: 40px;
  justify-content: space-evenly;
  align-self: stretch;
  @media only screen and (min-width: ${BreakPoint}px) {
    display: none;
  }
`;
const DesktopActions = styled(Actions)`
  display: none;
  @media only screen and (min-width: ${BreakPoint}px) {
    display: flex;
  }
`;
const ShareAction = styled(CenteredByColumn)`
  flex: 1;
  align-self: stretch;
  a,
  button {
    align-self: stretch;
    flex-grow: 1;
    ${centeredByColumn};
    padding: 0px;
    justify-content: space-between;
  }
  span {
    ${text12300}
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.8px;
    font-size: 11px;
  }
  svg {
    font-size: 16px;
    margin-bottom: 8px;
  }
  /* padding: 10px 0px; */
  /* border: 1px solid ${BorderGrey}; */
  /* background-color: ${Beige}; */
  /* background-color: ${Peach}; */
  /* margin-right: 10px; */
  border-right: 1px solid ${BorderGrey};
  &:last-child {
    border-right: none;
    /* margin-right: 0px; */
  }
`;
const IconWrapperWidth = 100;
const IconWidth = 50;
const IconContainer = styled(Centered)`
  position: absolute;
  background-color: ${Peach};
  font-size: ${IconWidth}px;
  width: ${IconWrapperWidth}px;
  height: ${IconWrapperWidth}px;
  line-height: ${IconWidth}px;
  text-align: center;
  border-radius: 50%;
  padding: 10px;
  /* bottom: ${CardHeight - 40}px; */
  top: -${IconWrapperWidth / 2 + 10}px;
  left: calc(50vw - ${IconWrapperWidth / 2}px);
  z-index: 2;
  /* box-shadow: 0 2px 4px rgba(52, 41, 41, 0.2); */
  transform: rotate(-0deg);
  @media only screen and (min-width: ${BreakPoint}px) {
    position: fixed;
    top: auto;
    left: calc(50vw - ${IconWrapperWidth / 2}px);
    bottom: calc(max(0px, ${CardHeight - IconWrapperWidth / 2 + 60}px));
  }
`;
const GroupOrderIcon = ({}) => (
  <IconContainer>
    {/* 🎉🛒🚗 */}
    🛒
  </IconContainer>
);
const ViewMenu = styled(ActionGrey)`
  /* margin: 40px 0px 20px 0px; */
  position: absolute;
  bottom: 80px;
  letter-spacing: 1.6px;
  padding: 15px 25px;
  @media only screen and (min-width: ${BreakPoint}px) {
    display: none;
  }
`;
const animation = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;
const CopiedNote = styled(Card)`
  position: fixed;
  bottom: ${CardHeight - 170}px;
  padding: 8px 0px;
  text-align: center;
  width: 140px;
  background: green;
  color: white;
  ${text12300}
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  left: calc(50% - 70px);
  opacity: 0.9;
  z-index: 2;
  animation: ${animation} 1s ease-in-out 0.5s 1 normal forwards;
  @media only screen and (min-width: ${BreakPoint}px) {
    bottom: ${CardHeight - 200}px;
  }
`;
const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  margin: 0px;
  padding: 0px;
  width: 40px;
  height: 40px;
`;
export interface InviteGroupOrderProps {
  show: boolean;
  onClose: () => void;
  groupOrderId: string;
  menuLink?: string;
  restaurantName: string;
  myName: string;
  organizerName?: string;
}

export const InviteGroupOrder: React.FC<InviteGroupOrderProps> = ({
  show,
  onClose,
  groupOrderId,
  // menuLink,
  restaurantName,
  myName,
  organizerName,
}: InviteGroupOrderProps): ReactElement => {
  // LOGGING &&
  //   console.log("InviteGroupOrde rendering:", { myName, restaurantName });
  const sharebaleLink = `https://foodie.earth/group/${groupOrderId}`;
  const textTitle = `Join ${organizerName}'s ${restaurantName} group cart from Foodie Earth`;
  const textBody = `${textTitle} here:\n${sharebaleLink}`;
  //   const sharebaleLink = `https://foodie.earth/${menuLink}/groupOrder/${groupOrderId}`;
  // LOGGING && console.log("InviteGroupOrder rendering with:", { sharebaleLink });

  const [localShow, setLocalShow] = useState(true);
  const [isCodeCopied, setIsCodeCopied] = useState(false);
  useEffect(() => {
    setLocalShow(show); // state updated with same value
  }, [show]); //
  const handleClose = () => {
    setLocalShow(false);
    setTimeout(() => {
      onClose();
    }, 100);
  };

  const notify = (type) => {
    SendAlert(myName, `share-by-${type} for groupOrder ${groupOrderId}!`);
  };
  const onCopyLink = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      navigator.clipboard.writeText(sharebaleLink);
      notify("copy");
      setIsCodeCopied(true);
      setTimeout(() => {
        setIsCodeCopied(false);
      }, 1000);
    },
    [sharebaleLink]
  );

  const onClickMore = async () => {
    const shareContent = {
      url: sharebaleLink,
      title: textTitle,
      text: "",
    };
    try {
      if (navigator.share) {
        await navigator.share(shareContent);
      } else {
        console.log("Web Share API not supported in this browser.");
        // Fallback for browsers that don't support the Web Share API
        // You can implement your own sharing mechanism here.
      }
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };
  // console.log("InviteGroupOrder rendering with:", { isCodeCopied });

  return (
    <GroupOrderContainer isOpen={show} onClick={handleClose}>
      {isCodeCopied ? <CopiedNote>Link copied!</CopiedNote> : null}

      <InviteContent isOpen={localShow}>
        <CloseButton onClick={handleClose}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
        <GroupOrderIcon />
        <Title>Invite others to add to your cart</Title>
        <ShareableLink>
          <span>{sharebaleLink} </span>
          <button onClick={onCopyLink}>Copy link</button>
        </ShareableLink>
        <Actions>
          <ShareAction>
            <a
              onClick={() => {
                notify("text");
              }}
              href={`sms:${
                /Android/i.test(navigator.userAgent) ? "?" : "&"
              }body=${textBody}`}
            >
              <FontAwesomeIcon icon={faSms} />
              <span>text</span>
            </a>
          </ShareAction>
          <ShareAction>
            <EmailShareButton
              body={textBody}
              subject={textTitle}
              url={sharebaleLink}
              beforeOnClick={() => {
                notify("email");
              }}
            >
              <FontAwesomeIcon icon={faEnvelope} />
              <span>Mail</span>
            </EmailShareButton>
          </ShareAction>

          <ShareAction>
            <button onClick={onCopyLink}>
              <FontAwesomeIcon icon={faCopy} />
              <span>Copy</span>
            </button>
          </ShareAction>
          <ShareAction>
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClickMore();
                notify("more");
              }}
            >
              <FontAwesomeIcon icon={faEllipsisH} />
              <span>more</span>
            </button>
          </ShareAction>
        </Actions>
        <ViewMenu>View group order</ViewMenu>
      </InviteContent>
    </GroupOrderContainer>
  );
};
