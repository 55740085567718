import React from "react";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { testimonialList } from "src/constants/testimonials";
import { AnonymousFooter } from "src/screens/shared";
import styled from "styled-components";
import {
  centeredByColumn,
  card,
  TextTitle,
  text12300,
  Centered,
  CenteredByColumnLeft,
  Lavendar,
  textTitle,
  CenteredByColumn,
  Beige,
  PinkPeach,
  ALLCAPTEXT,
  Peach,
  Blue,
  Lotus,
  BreakPoint as breakPoint,
  BorderGrey,
} from "../Shared";

export const SectionHeader = styled.div<{ ref }>`
  /* ${text12300}
  font-size: 26px;
  line-height: 1.2;
  font-weight: 400; */
  ${ALLCAPTEXT}
  text-transform: uppercase;
  /* margin: 0px 0px 40px 0px; */
  margin-top: 30px;
  margin-bottom: 20px;
  white-space: pre-wrap;
  text-align: center;
  /* position: absolute;
    top: 60px;
    z-index: 2; */
  width: calc(100% - 40px);
  /* display: none; */
  @media only screen and (min-width: ${breakPoint}px) {
    display: flex;
  }
`;
export const Container = styled.div`
  ${centeredByColumn}
  align-self: stretch;
  background-color: ${Lotus};
  z-index: 5;
  /* align-items: flex-start; */
  @media only screen and (min-width: ${breakPoint}px) {
    padding: 30px 8vw 70px 8vw;
    background-color: ${Beige};
    h1 {
      margin: 40px 0px 20px 0px;
    }
  }
`;
export const List = styled(CenteredByColumn)`
  padding: 0px;
  @media only screen and (min-width: ${breakPoint}px) {
    align-self: stretch;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 84vw;
    /* padding: 10px; */
    /* margin-right: 350px; */
    /* margin-bottom: 100px; */
  }
`;
export const Testimonial = styled(CenteredByColumnLeft)`
  ${card}
  margin: 20px 0px 10px 0px;
  align-self: stretch;
  padding: 10px 20px;
  /* background-color: ${Lavendar}; */
  /* background-color: ${PinkPeach}; */
  background-color: transparent;
  box-shadow: none;
  justify-content: flex-start;
  width: calc(100vw - 40px);
  // when it's the first child of its parent, no margin-top
  &:first-child {
    margin-top: 0px;
  }
  @media only screen and (min-width: ${breakPoint}px) {
    width: 350px;
    margin: 20px;
    padding: 20px;
    flex-grow: 1;
    max-width: 400px;
    /* border: 1px solid ${BorderGrey};     */
    background-color: ${Peach};
    &:first-child {
      margin-top: 20px;
    }
  }
`;
export const Name = styled(Centered)`
  ${textTitle}
  margin-bottom: 5px;
`;
const Info = styled(Centered)`
  ${text12300}
  justify-content: flex-start;
  /* margin-bottom: 8px; */
`;
const Location = styled.span`
  margin-right: 9px;
`;
const Joined = styled.span`
  margin-left: 9px;
`;
const Question = styled.span`
  margin-top: 20px;
  ${text12300}
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 5px;
`;
const Answer = styled.span`
  ${text12300}
  line-height: 20px;
  /* margin-bottom: 10px; */
`;
const Icon = styled(Centered)`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: black;
  color: white;
  margin-right: 7px;
  svg {
    font-size: 14px;
  }
`;
export const Testimonials = React.forwardRef((props, ref) => (
  //@ts-ignore
  <Container>
    <SectionHeader ref={ref}>Testimonial</SectionHeader>
    <List>
      {testimonialList.map((t, index) => (
        <>
          <Testimonial key={index}>
            <Name>
              <Icon>
                <FontAwesomeIcon icon={faUser} />
              </Icon>{" "}
              {t.name.split(" ")[0]}
            </Name>
            <Info>
              <Location>{t.city}</Location>|
              <Joined>Since {moment(t.joined).format("MMMM, YYYY")}</Joined>
            </Info>
            <Question>What's the main benefit you get from foodie?</Question>
            <Answer>{t.benefit}</Answer>
            <Question>Who would benefit from foodie?</Question>
            <Answer>{t.who}</Answer>
          </Testimonial>
          {/* @ts-ignore */}
          {index % 3 === 2 && props?.auth}
        </>
      ))}
    </List>
    <AnonymousFooter isDesktop={true} />
  </Container>
));
