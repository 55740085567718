import { useCallback, useState } from "react";
import GoogleMapReact from "google-map-react";
import { useSelector } from "react-redux";
import "./StopsMap.scss";
import { mealMarkerByMealId } from "src/util/stopMap";
import { DEFAULT_MEAL_FILTER_ID } from "./MealFilter";
import { RestaurantLegendsOnMap } from "./restaurants/RestaurantLegendsOnMap";
import { StopMarker } from "./StopMarker";
import { LOGGING } from "..";

export const StopsMapV2 = () => {
  const isLunch = useSelector((state) => state.adminDeliveryV2.filters.isLunch);
  const mealType = useSelector(
    (state) => state.adminDeliveryV2.filters.mealType
  );
  const allStops = useSelector((state) => state.adminDeliveryV2.stops);
  const stops = allStops.filter((s) => s.mealType === mealType);
  const [showDriverSelectorForStop, setShowDriverSelectorForStop] =
    useState(null);
  const toggleShowDriverListForStop = useCallback(
    (stopId) => {
      if (showDriverSelectorForStop === stopId) {
        setShowDriverSelectorForStop(null);
        return;
      }

      setShowDriverSelectorForStop(stopId);
    },
    [showDriverSelectorForStop]
  );
  const mealMarkers = mealMarkerByMealId(stops);
  LOGGING && console.log("StopsMapV2 got mealMarkers", { stops, mealMarkers });
  // Filter here
  let filteredStops = stops;
  const driverIdFilter = useSelector(
    (state) => state.adminDeliveryV2.filters?.driver
  );
  if (driverIdFilter != null) {
    filteredStops = filteredStops.filter((stop) => {
      const eligibleDriverDetails = stop.eligibleDriverDetails;
      return eligibleDriverDetails.some(
        (driverDetails) =>
          driverDetails.driverId === driverIdFilter && driverDetails.isSelected
      );
    });
  }

  const mealIdFilter = useSelector(
    (state) => state.adminDeliveryV2.filters?.meal
  );
  if (mealIdFilter != null && mealIdFilter !== DEFAULT_MEAL_FILTER_ID) {
    filteredStops = filteredStops.filter((stop) => {
      return stop.meal === mealIdFilter;
    });
  }
  const stopCounts = filteredStops.reduce((acc, stop) => {
    if (!acc[stop.meal]) {
      acc[stop.meal] = 0;
    }
    if (stop.stopType === "order") {
      acc[stop.meal]++;
    }
    return acc;
  }, {});

  return (
    <div style={{ height: "100%" }}>
      <RestaurantLegendsOnMap
        mealMarkers={mealMarkers}
        stops={filteredStops.filter((s) => s.stopType === "meal")}
        stopCounts={stopCounts}
      />
      {/* Important! Always set the container height explicitly */}
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyB-0SwH76HPTJGm_mhM1Rg0ACcpYGpAt1Q",
        }}
        defaultZoom={11}
        center={[37.3856971, -122.0934076]}
      >
        {filteredStops.map((stop) => {
          return (
            <StopMarker
              key={stop._id}
              lat={stop.mapLat}
              lng={stop.mapLng}
              stopId={stop._id}
              isTransit={stop.isTransit}
              label={stop.mapViewAttributes.label}
              color={stop.mapViewAttributes.color}
              isNewUser={stop.mapViewAttributes.isNewUser}
              isApartment={stop.mapViewAttributes.isApartment}
              hasDrink={stop.mapViewAttributes.hasDrink}
              stopTotal={stop.mapViewAttributes.stopTotal}
              stopNum={stop.mapViewAttributes.stopNum}
              marker={mealMarkers[stop.meal]}
              stopType={stop.stopType}
              eligibleDrivers={stop.eligibleDriverDetails}
              onClick={toggleShowDriverListForStop}
              showInBackground={
                showDriverSelectorForStop != null &&
                showDriverSelectorForStop !== stop._id
              }
              showDriverSelector={showDriverSelectorForStop === stop._id}
              eta={stop.mapViewAttributes.estimatedCumulativeTimeSpent}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
};
