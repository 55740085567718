import React, { ReactElement, useCallback } from "react";
import { useAsync } from "react-async";
import { useSelector } from "react-redux";
import { GetUserReferrals } from "src/api/referrals";
import { Loading } from "..";
import { MyReferralsTable } from "./MyReferralsTable";

export interface MyReferralsTableContainerProps {}

export const MyReferralsTableContainer: React.FC<MyReferralsTableContainerProps> =
  (): ReactElement => {
    const currentUser = useSelector((state: any) => state.currentUser);

    const fetch = useCallback(async () => {
      return await GetUserReferrals(currentUser?.user?._id);
    }, [currentUser?.user?._id]);

    const { data } = useAsync({ promiseFn: fetch });

    if (data == null) return <Loading />;
    console.log("MyReferralsTableContainer rendering:", data.referrals);
    return <MyReferralsTable referrals={data.referrals} />;
  };
