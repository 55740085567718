import { SET_DELIVERY_CITY, RESET_DELIVERY_CITY } from '../actionTypes';

const INITIALIZE_STATE = null;

const deliveryCity = (state = INITIALIZE_STATE, action) => {
  // console.log('deliveryCity called with state:', state);
  switch (action.type) {
    case SET_DELIVERY_CITY:
      return action.deliveryCity;
    case RESET_DELIVERY_CITY:
      return INITIALIZE_STATE;
    default:
      return state;
  }
};

export default deliveryCity;
