import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { Container, Field, Title } from "./Contact";

export const Total = () => {
  const cart = useSelector((state: RootState) => state.cart.payLoad);
  const { credit, total, due, discount } = cart;
  return (
    <>
      <Container>
        {credit > 0 || discount > 0 ? (
          <Field>
            <label>total</label>
            <span>${total?.toFixed(2)}</span>
          </Field>
        ) : null}
        {credit > 0 && (
          <Field>
            <label>credit</label>
            <span>-${credit?.toFixed(2)}</span>
          </Field>
        )}
        {discount > 0 && (
          <Field>
            <label>credit</label>
            <span>-${discount?.toFixed(2)}</span>
          </Field>
        )}
        <Field>
          <label>due</label>
          <span>${due?.toFixed(2)}</span>
        </Field>
      </Container>
    </>
  );
};
