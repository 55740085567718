import "./MenuCalendar.scss";
import {
  faChevronLeft,
  faChevronRight,
  faCheck,
  faPencil,
  faChevronDown,
  faChevronUp,
  faMoneyBill,
  faTrash,
  faTimesCircle,
  faTimes,
  faRecycle,
  faArrows,
  faFan,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import restaurants from "src/store/reducers/restaurants";
import { LOGGING, DayOfWeek, CityShortName, receiptDateFormat } from "./";
import { SavingScreen } from "./customers/survey/Taste";
import { HandPick } from "./mealMenu/Plan";
import { WeekCheck } from "./mealMenu/WeekCheck";

const WeekDays = () => (
  <div className="calendar-row week-days">
    {[0, 1, 2, 3, 4, 5, 6].map((dayOfWeek) => (
      <div className="daily-cell day-of-week" key={dayOfWeek}>
        <span>{DayOfWeek[dayOfWeek]}</span>
      </div>
    ))}
  </div>
);

export const RestaurantContact = ({
  meal,
  onToggleConfirm,
  onToggleAsian,
  onToggleNonAsian,
  onToggleAll,
  onSave,
  onCancel,
}) => {
  const {
    windowStart,
    isLunch,
    restaurant,
    confirmedWithRestaurant,
    mealGroup,
  } = meal;
  const mealName = isLunch ? "lunch" : "dinner";
  const date = moment(windowStart).format(receiptDateFormat);
  return (
    <div className="restaurant-contact">
      <div className="word">Confirming</div>
      <div className="meal-name">{`${mealName}, ${date}`}</div>
      <div className="word">with</div>
      <div className="restaurant-name">{restaurant?.name}</div>
      <div className="word">Contact</div>
      <div className="contact-info">
        {restaurant?.contact?.name && (
          <div className="contact-name">{restaurant?.contact?.name}</div>
        )}
        {restaurant?.contact?.mobile && (
          <div className="contact-mobile">{restaurant?.contact?.mobile}</div>
        )}
      </div>
      <div className="meal-name">
        <button
          className={`meal-status ${
            confirmedWithRestaurant ? "confirmed" : "unconfirmed"
          }`}
          onClick={onToggleConfirm}
        >
          <FontAwesomeIcon icon={faCheck} />
          <span>Confirmed with restaurant.</span>
        </button>
        {/* <button
          className={`meal-status ${
            mealGroup === 0 || mealGroup === 1 ? "confirmed" : "unconfirmed"
          }`}
          onClick={onToggleAsian}
        >
          <FontAwesomeIcon icon={faCheck} />
          <span>Available to Asian</span>
        </button>
        <button
          className={`meal-status ${
            mealGroup === 0 || mealGroup === 2 ? "confirmed" : "unconfirmed"
          }`}
          onClick={onToggleNonAsian}
        >
          <FontAwesomeIcon icon={faCheck} />
          <span>Available to Non-Asian</span>
        </button> */}
        <button
          className={`meal-status ${
            mealGroup === 0 ? "confirmed" : "unconfirmed"
          }`}
          onClick={onToggleAll}
        >
          <FontAwesomeIcon icon={faCheck} />
          <span>Available to All</span>
        </button>
      </div>
      <div className="action-wrapper">
        <button className="save-status" onClick={onSave}>
          save
        </button>
        <button className="cancel-status" onClick={onCancel}>
          cancel
        </button>
      </div>
    </div>
  );
};

const MealMenu = ({ mealType, meal, onEdit }) => {
  LOGGING && console.log("MealMenu rendering with: ", meal);
  return (
    <button
      className={`edit-restaurant ${
        meal.restaurant ? "" : "draft"
      } ${mealType}`}
      onClick={onEdit}
    >
      <div className="restaurant-name">
        <span>{`${
          (meal.restaurant && meal.restaurant.name.split(",")[0]) ||
          meal.notes ||
          ""
        } `}</span>
      </div>
      <span className="restaurant-delivery-zone">
        {meal.supportedDeliveryZone
          .map((zone) => CityShortName[zone.name])
          .join("-")}
      </span>

      {meal.supportEarlyDelivery && (
        <span className="early-delivery">
          Early_Delivery <FontAwesomeIcon icon={faCheck} />
        </span>
      )}
    </button>
  );
};
const MealSummary = ({ stats, estimatedGMV }) => {
  // if (!stats) return <div className="meal-summary"></div>;
  const revenue = stats?.revenue || 0;
  const profit = stats?.profit || 0;
  const gmv = stats?.gmv || 0;
  return (
    <div className="meal-summary">
      <div className="meal-revenue">
        <span>
          {`G'$${estimatedGMV.toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}`}
        </span>
        <span>
          {`G$${gmv.toLocaleString(undefined, { maximumFractionDigits: 0 })}`}
        </span>
        <span>
          R${revenue.toLocaleString(undefined, { maximumFractionDigits: 0 })}
        </span>
      </div>
      <div className={`meal-profit ${profit > 0 ? "positive" : "negative"}`}>
        <span className={`total`}>
          {`${profit > 0 ? "+" : "-"}$${Math.abs(profit).toLocaleString(
            undefined,
            {
              maximumFractionDigits: 0,
            }
          )}`}
        </span>
        <span className={`percent ${profit > 0 ? "positive" : "negative"}`}>
          {`(${((profit / revenue) * 100).toFixed(0)}%)`}
        </span>
      </div>
    </div>
  );
};
const MealThumbnail = ({
  mealType,
  meals,
  onClick,
  onQuickDeleteMeal,
  cuisineFilters,
}) => {
  LOGGING &&
    cuisineFilters &&
    console.log("MealThumbnail rendering with cuisineFilters:", {
      cuisineFilters,
      restaurants: meals.map((m) => m.restaurant.name).join(", "),
    });
  return meals ? (
    <>
      {meals.map((meal, i) =>
        meal.restaurant ? (
          <div
            className={`meal-thumbnail-container ${
              meal?.revenue ? "" : "deletable"
            }`}
          >
            <button
              className={`meal-thumbnail ${mealType} ${
                meal.confirmedWithRestaurant ? "confirmed" : "unconfirmed"
              } ${
                // if cuisineFilters is not empty,
                // and if one of the meal.restaurant.cuisines is in the cuisineFilters
                // then give it a class name of "cuisine-filtered"
                cuisineFilters?.length > 0 &&
                meal?.restaurant?.cuisines?.some((c) =>
                  cuisineFilters?.includes(c.name)
                )
                  ? "cuisine-filtered"
                  : ""
              }`}
              key={i}
              onClick={onClick.bind(this, meal)}
            >
              <span className="restaurant-name">
                {(meal.dishes?.length === 0 ? "!" : "") +
                  (meal.restaurant?.hasMarkup ? "$" : "") +
                  (meal.mealGroup === 3 ? "***" : "") +
                  meal.restaurant.name.split(",")[0]}
              </span>
              {meal.payment != null ? (
                <FontAwesomeIcon icon={faMoneyBill} />
              ) : null}
              {/* {meal?.restaurant?.commission ? (
                <span className="restaurant-commission">{`${meal.restaurant.commission} `}</span>
              ) : null} */}
              <span className="restaurant-commission">{`${
                meal?.restaurant?.commission || 0
              }/${meal?.estimates?.estimatedGMV?.toFixed(0) || 0}`}</span>
            </button>
            {meal?.revenue ? (
              <span className="delete-meal">
                {meal.revenue.toLocaleString(undefined, {
                  maximumFractionDigits: 0,
                })}
              </span>
            ) : (
              <button
                className="delete-meal"
                onClick={onQuickDeleteMeal.bind(this, meal?._id)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            )}
          </div>
        ) : (
          <div className={`meal-thumbnail ${mealType} draft`} key={i}>
            <span>{meal.notes}</span>
          </div>
        )
      )}
    </>
  ) : null;
};

const MealContainer = ({
  stats,
  estimatedGMV,
  meals,
  mealToSwap,
  isSwappable,
  windowStart,
  showMeals,
  onToggleSwapWindowStart,
  onEditMeal,
  onDeleteMeal,
  onSelectRestaurant,
  onShowRestaurantContact,
  mealType,
  onDeleteWindowStart,
  onFetchMealTime,
  onQuickDeleteMeal,
  cuisineFilters,
}) => (
  <div className={`meal-container ${mealToSwap ? "to-swap" : ""}`}>
    <div className={`meal-type ${mealType}`}>
      <span>{mealType.includes("early") ? "early" : mealType}</span>
      <button
        class="reload-meal"
        onClick={onFetchMealTime.bind(this, windowStart)}
      >
        reload
      </button>
      {isSwappable ? (
        <button
          onClick={onToggleSwapWindowStart}
          className={`select-to-swap ${mealToSwap ? "selected" : ""}`}
        >
          {/* <span>{`${mealToSwap ? "de" : ""}select block`}</span> */}
          <span>swap</span>
          {/* <FontAwesomeIcon icon={faFan} /> */}
        </button>
      ) : null}
      {onDeleteWindowStart && meals && meals.length > 0 && (
        <button
          onClick={onDeleteWindowStart.bind(this, windowStart)}
          className="delete-meals"
        >
          {/* <FontAwesomeIcon icon={faTrash} /> */}
          del
        </button>
      )}
    </div>
    <MealSummary stats={stats} estimatedGMV={estimatedGMV} />

    {showMeals ? (
      <div className={`meal-wrapper ${mealType}`}>
        {meals &&
          meals.map((meal, i) => (
            <div className="meal-container">
              <div className="meal-id">
                <span>{`${meal._id}`}</span>
              </div>
              <button onClick={onDeleteMeal.bind(this, meal)}>delete</button>
              <MealMenu
                mealType={mealType}
                meal={meal}
                onEdit={onEditMeal.bind(this, "lunch", meal)}
              />
            </div>
          ))}
        <button
          className={`select-restaurant ${mealType}`}
          onClick={onSelectRestaurant.bind(this, mealType)}
        >
          <span className="edit-dishes">
            add
            <FontAwesomeIcon icon={faPencil} />
          </span>
        </button>
      </div>
    ) : (
      <MealThumbnail
        mealType={mealType}
        key={0}
        meals={meals}
        onClick={onShowRestaurantContact}
        onQuickDeleteMeal={onQuickDeleteMeal}
        cuisineFilters={cuisineFilters}
      />
    )}
  </div>
);
const DailySummary = ({
  lunchStats,
  lunchEstimatedGMV,
  earlyDinnerStats,
  earlyDinnerEstimatedGMV,
  dinnerStats,
  dinnerEstimatedGMV,
}) => {
  const profit =
    (lunchStats?.profit || 0) +
    (earlyDinnerStats?.profit || 0) +
    (dinnerStats?.profit || 0);
  const revenue =
    (lunchStats?.revenue || 0) +
    (earlyDinnerStats?.revenue || 0) +
    (dinnerStats?.revenue || 0);
  const gmv =
    (lunchStats?.gmv || 0) +
    (earlyDinnerStats?.gmv || 0) +
    (dinnerStats?.gmv || 0);
  const gmvEstimates =
    (lunchEstimatedGMV || 0) +
    (earlyDinnerEstimatedGMV || 0) +
    (dinnerEstimatedGMV || 0);
  return (
    <div className="daily-summary">
      <div className="GMV">
        <label>G'</label>{" "}
        <span>
          $
          {gmvEstimates.toLocaleString(undefined, { maximumFractionDigits: 0 })}
        </span>
      </div>
      <div className="GMV">
        <label>G</label>{" "}
        <span>
          ${gmv.toLocaleString(undefined, { maximumFractionDigits: 0 })}
        </span>
      </div>
      <div className="revenue">
        <label>R</label>{" "}
        <span>
          ${revenue.toLocaleString(undefined, { maximumFractionDigits: 0 })}
        </span>
      </div>
      <div className={`profit ${profit > 0 ? "positive" : "negative"}`}>
        <label>P</label>
        <span>
          {profit > 0 ? "+" : "-"}${Math.abs(profit).toFixed(0)}
        </span>
      </div>
    </div>
  );
};
const DailyDetails = ({
  day,
  lunchMeals,
  earlyDinnerMeals,
  dinnerMeals,
  onEditMeal,
  onDeleteMeal,
  onSelectRestaurant,
  deliveryZones,
  showMeals,
  showRestaurantContact,
  onShowRestaurantContact,
  onHideRestaurantContact,
  lunchStats,
  earlyDinnerStats,
  dinnerStats,
  lunchEstimatedGMV,
  earlyDinnerEstimatedGMV,
  dinnerEstimatedGMV,
  onToggleSwapWindowStart,
  swapWindowStarts,
  onEarlyCutMeal,
  onDeleteWindowStart,
  onFetchMealTime,
  onQuickDeleteMeal,
  cuisineFilters,
}) => {
  LOGGING &&
    cuisineFilters &&
    console.log("DailyDetails rendering with cuisineFilters: ", cuisineFilters);
  const { lunchWindowStart, earlyDinnerWindowStart, dinnerWindowStart } = day;
  const lunchToSwap = swapWindowStarts.includes(lunchWindowStart);
  const lunchSwappable = lunchMeals.every((m) => !m.revenue);
  const earlyDinnerToSwap = swapWindowStarts.includes(earlyDinnerWindowStart);
  const earlyDinnerSwappable = earlyDinnerMeals.every((m) => !m.revenue);
  const dinnerToSwap = swapWindowStarts.includes(dinnerWindowStart);
  const dinnerSwappable = dinnerMeals.every((m) => !m.revenue);
  return (
    <div className={`daily-menu ${showMeals ? "show-meals" : "hide-meals"}`}>
      <MealContainer
        stats={lunchStats}
        estimatedGMV={lunchEstimatedGMV}
        meals={lunchMeals}
        mealToSwap={lunchToSwap}
        isSwappable={lunchSwappable}
        showMeals={showMeals}
        onToggleSwapWindowStart={onToggleSwapWindowStart.bind(
          this,
          lunchWindowStart
        )}
        onEditMeal={onEditMeal}
        onDeleteMeal={onDeleteMeal}
        onSelectRestaurant={onSelectRestaurant}
        onShowRestaurantContact={onShowRestaurantContact}
        mealType="lunch"
        windowStart={lunchWindowStart}
        onDeleteWindowStart={onDeleteWindowStart}
        onFetchMealTime={onFetchMealTime}
        onQuickDeleteMeal={onQuickDeleteMeal}
        cuisineFilters={cuisineFilters}
      />
      <MealContainer
        stats={earlyDinnerStats}
        estimatedGMV={earlyDinnerEstimatedGMV}
        meals={earlyDinnerMeals}
        mealToSwap={earlyDinnerToSwap}
        isSwappable={earlyDinnerSwappable}
        showMeals={showMeals}
        onToggleSwapWindowStart={onToggleSwapWindowStart.bind(
          this,
          earlyDinnerWindowStart
        )}
        onEditMeal={onEditMeal}
        onDeleteMeal={onDeleteMeal}
        onSelectRestaurant={onSelectRestaurant}
        onShowRestaurantContact={onShowRestaurantContact}
        mealType="earlyDinner"
        windowStart={earlyDinnerWindowStart}
        onDeleteWindowStart={onDeleteWindowStart}
        onFetchMealTime={onFetchMealTime}
        onQuickDeleteMeal={onQuickDeleteMeal}
        cuisineFilters={cuisineFilters}
      />
      <MealContainer
        stats={dinnerStats}
        estimatedGMV={dinnerEstimatedGMV}
        meals={dinnerMeals}
        mealToSwap={dinnerToSwap}
        isSwappable={dinnerSwappable}
        showMeals={showMeals}
        onToggleSwapWindowStart={onToggleSwapWindowStart.bind(
          this,
          dinnerWindowStart
        )}
        onEditMeal={onEditMeal}
        onDeleteMeal={onDeleteMeal}
        onSelectRestaurant={onSelectRestaurant}
        onShowRestaurantContact={onShowRestaurantContact}
        mealType="dinner"
        windowStart={dinnerWindowStart}
        onDeleteWindowStart={onDeleteWindowStart}
        onFetchMealTime={onFetchMealTime}
        onQuickDeleteMeal={onQuickDeleteMeal}
        cuisineFilters={cuisineFilters}
      />
    </div>
  );
};
const DailyMenu = ({
  lunchMeals,
  earlyDinnerMeals,
  dinnerMeals,
  day,
  onSelectRestaurant,
  onEditMeal,
  deliveryZones,
  onShowDayReceipts,
  showMeals,
  onShowMeals,
  onHideMeals,
  onDeleteMeal,
  onShowRestaurantContact,
  lunchStats,
  lunchEstimatedGMV,
  earlyDinnerStats,
  earlyDinnerEstimatedGMV,
  dinnerStats,
  dinnerEstimatedGMV,
  onToggleSwapWindowStart,
  swapWindowStarts,
  todayRef,
  onDeleteWindowStart,
  onFetchMealTime,
  onQuickDeleteMeal,
  cuisineFilters,
}) => {
  LOGGING &&
    cuisineFilters &&
    console.log("DailyMenu rendering with cuisineFilters:", cuisineFilters);
  const isToday = day.date.isSame(moment(), "day");
  return (
    <div
      ref={isToday ? todayRef : null}
      className={`daily-cell ${
        day.isToday ? "today" : day.isPast ? "past" : ""
      } ${showMeals ? "show-meals" : "hide-meals"} ${
        day.isShown ? "shown" : "unshown"
      }`}
    >
      {/* <Link className="date" to={`/preview/${day.date.valueOf()}`}> */}
      <div className="date">
        <DailySummary
          lunchStats={lunchStats}
          lunchEstimatedGMV={lunchEstimatedGMV}
          earlyDinnerStats={earlyDinnerStats}
          earlyDinnerEstimatedGMV={earlyDinnerEstimatedGMV}
          dinnerStats={dinnerStats}
          dinnerEstimatedGMV={dinnerEstimatedGMV}
        />
        <span className="date-number">{day.date.format("D")}</span>
        <span className="day-of-week">{day.date.format("ddd")}</span>
        <button
          className="toggle-day-meals"
          onClick={(showMeals ? onHideMeals : onShowMeals).bind(this, day)}
        >
          <FontAwesomeIcon icon={showMeals ? faChevronUp : faChevronDown} />
        </button>
      </div>
      {/* </Link> */}
      <DailyDetails
        day={day}
        lunchMeals={lunchMeals}
        earlyDinnerMeals={earlyDinnerMeals}
        dinnerMeals={dinnerMeals}
        lunchStats={lunchStats}
        earlyDinnerStats={earlyDinnerStats}
        dinnerStats={dinnerStats}
        lunchEstimatedGMV={lunchEstimatedGMV}
        earlyDinnerEstimatedGMV={earlyDinnerEstimatedGMV}
        dinnerEstimatedGMV={dinnerEstimatedGMV}
        onSelectRestaurant={onSelectRestaurant}
        onEditMeal={onEditMeal}
        deliveryZones={deliveryZones}
        showMeals={showMeals}
        onDeleteMeal={onDeleteMeal}
        onShowRestaurantContact={onShowRestaurantContact}
        onToggleSwapWindowStart={onToggleSwapWindowStart}
        swapWindowStarts={swapWindowStarts}
        onDeleteWindowStart={onDeleteWindowStart}
        onFetchMealTime={onFetchMealTime}
        onQuickDeleteMeal={onQuickDeleteMeal}
        cuisineFilters={cuisineFilters}
      />
      {showMeals ? (
        <button
          className="daily-revenue"
          type="button"
          onClick={onShowDayReceipts.bind(
            this,
            day,
            lunchMeals,
            earlyDinnerMeals,
            dinnerMeals
          )}
        >
          view receipts
        </button>
      ) : null}
    </div>
  );
};
const WeeklyMenu = ({
  week,
  meals,
  onSelectRestaurant,
  onEditMeal,
  onShowDayReceipts,
  deliveryZones,
  showDayMeals,
  onShowDayMeals,
  onHideDayMeals,
  onDeleteMeal,
  onShowRestaurantContact,
  onHideRestaurantContact,
  windowStartsWithStats,
  onToggleSwapWindowStart,
  swapWindowStarts,
  todayRef,
  planningWeekRef,
  planningWeekSunday,
  onDeleteWindowStart,
  onFetchMealTime,
  onQuickDeleteMeal,
  cuisineFilters,
  onFilterByCuisine,
  showCuisineFilter,
  onToggleShowCuisineFilter,
}) => {
  // LOGGING &&
  //   cuisineFilters &&
  //   console.log(" WeeklyMenu rendering with cuisineFilters:", cuisineFilters);
  const isPlanningWeek = week[0].date.isSame(planningWeekSunday, "day");
  return (
    <div
      className={`calendar-row week-menu ${
        isPlanningWeek ? "planning-week" : ""
      } ${showCuisineFilter ? "show-cuisine-filter" : ""}`}
      ref={isPlanningWeek ? planningWeekRef : null}
    >
      {/* {isPlanningWeek ? <HandPick day={week[0]} /> : null} */}
      <WeekCheck
        week={week}
        cuisineFilters={cuisineFilters}
        onFilterByCuisine={onFilterByCuisine}
        showCuisineFilter={showCuisineFilter}
        onToggleShowCuisineFilter={onToggleShowCuisineFilter}
      />
      {week.map((day, index) => (
        <DailyMenu
          todayRef={todayRef}
          day={day}
          key={index}
          lunchStats={
            windowStartsWithStats &&
            windowStartsWithStats.find(
              (s) => s.windowStart === day.lunchWindowStart
            )
          }
          lunchEstimatedGMV={meals
            .filter((m) => m.windowStart === day.lunchWindowStart)
            .reduce((acc, m) => acc + (m.estimates?.estimatedGMV || 0), 0)}
          lunchMeals={meals.filter(
            (m) => m.windowStart === day.lunchWindowStart
          )}
          earlyDinnerStats={
            windowStartsWithStats &&
            windowStartsWithStats.find(
              (s) => s.windowStart === day.earlyDinnerWindowStart
            )
          }
          earlyDinnerEstimatedGMV={meals
            .filter((m) => m.windowStart === day.earlyDinnerWindowStart)
            .reduce((acc, m) => acc + (m.estimates?.estimatedGMV || 0), 0)}
          earlyDinnerMeals={meals.filter(
            (m) => m.windowStart === day.earlyDinnerWindowStart
          )}
          dinnerStats={
            windowStartsWithStats &&
            windowStartsWithStats.find(
              (s) => s.windowStart === day.dinnerWindowStart
            )
          }
          dinnerEstimatedGMV={meals
            .filter((m) => m.windowStart === day.dinnerWindowStart)
            .reduce((acc, m) => acc + (m.estimates?.estimatedGMV || 0), 0)}
          dinnerMeals={meals.filter(
            (m) => m.windowStart === day.dinnerWindowStart
          )}
          onSelectRestaurant={onSelectRestaurant.bind(this, day)}
          onEditMeal={onEditMeal.bind(this, day)}
          onShowDayReceipts={onShowDayReceipts}
          deliveryZones={deliveryZones}
          showMeals={showDayMeals[day.lunchWindowStart]}
          onShowMeals={onShowDayMeals}
          onHideMeals={onHideDayMeals}
          onDeleteMeal={onDeleteMeal}
          onShowRestaurantContact={onShowRestaurantContact}
          onHideRestaurantContact={onHideRestaurantContact}
          onToggleSwapWindowStart={onToggleSwapWindowStart}
          swapWindowStarts={swapWindowStarts}
          onDeleteWindowStart={isPlanningWeek && onDeleteWindowStart}
          onFetchMealTime={onFetchMealTime}
          onQuickDeleteMeal={onQuickDeleteMeal}
          cuisineFilters={cuisineFilters}
        />
      ))}
    </div>
  );
};
export const MenuCalendarHeader = ({ onMoveMonth, currentMonth }) => (
  <div className="calendar-header">
    <div className="current-month">
      <button
        className="move-month prev-month"
        onClick={onMoveMonth.bind(this, -1)}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <span>{currentMonth}</span>
      <button
        className="move-month next-month"
        onClick={onMoveMonth.bind(this, 1)}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  </div>
);
const MenuCalendar = ({
  month,
  meals,
  onSelectRestaurant,
  currentMonth,
  onEditMeal,
  onDeleteMeal,
  onMoveMonth,
  onShowDayReceipts,
  deliveryZones,
  showDayMeals,
  onShowDayMeals,
  onHideDayMeals,
  onShowRestaurantContact,
  onHideRestaurantContact,
  windowStartsWithStats,
  onToggleSwapWindowStart,
  swapWindowStarts,
  onSwapMeals,
  onRefreshCache,
  onGoToPlanningWeek,
  onGoToToday,
  swapping,
  todayRef,
  planningWeekRef,
  planningWeekSunday,
  onDeleteWindowStart,
  onFetchMealTime,
  onQuickDeleteMeal,
  filterByTimeAndCuisine,
  onFilterByTimeAndCuisine,
  showCuisineFilter,
  onToggleShowCuisineFilter,
}) => {
  // LOGGING && console.log("MenuCalendar rendering with meals: ", meals);
  return (
    <div className="calendar">
      {swapping ? <SavingScreen message={"swapping meals"} /> : null}

      <div className="swap-meals">
        {swapWindowStarts[0] ? (
          <span>{moment(swapWindowStarts[0]).format("LLLL")}</span>
        ) : null}
        {swapWindowStarts[1] ? (
          <span> {moment(swapWindowStarts[1]).format("LLLL")}</span>
        ) : null}
        <button onClick={onGoToToday}>go to today</button>
        <button onClick={onGoToPlanningWeek}>go to planning week</button>
        <button onClick={onSwapMeals}>swap</button>
        <button onClick={onRefreshCache}>refresh cache</button>
      </div>
      {/* <WeekDays /> */}
      <div className="menu-calendar">
        {month.map((week, index) => (
          <WeeklyMenu
            onFetchMealTime={onFetchMealTime}
            planningWeekRef={planningWeekRef}
            planningWeekSunday={planningWeekSunday}
            windowStartsWithStats={windowStartsWithStats}
            week={week}
            key={index}
            meals={meals}
            onSelectRestaurant={onSelectRestaurant}
            onEditMeal={onEditMeal}
            onDeleteMeal={onDeleteMeal}
            onShowDayReceipts={onShowDayReceipts}
            deliveryZones={deliveryZones}
            showDayMeals={showDayMeals}
            onShowDayMeals={onShowDayMeals}
            onHideDayMeals={onHideDayMeals}
            onShowRestaurantContact={onShowRestaurantContact}
            onHideRestaurantContact={onHideRestaurantContact}
            onToggleSwapWindowStart={onToggleSwapWindowStart}
            swapWindowStarts={swapWindowStarts}
            todayRef={todayRef}
            onDeleteWindowStart={onDeleteWindowStart}
            onQuickDeleteMeal={onQuickDeleteMeal}
            cuisineFilters={filterByTimeAndCuisine[index]}
            onFilterByCuisine={onFilterByTimeAndCuisine.bind(this, index)}
            showCuisineFilter={showCuisineFilter[index]}
            onToggleShowCuisineFilter={onToggleShowCuisineFilter.bind(
              this,
              index
            )}
          />
        ))}
      </div>
    </div>
  );
};

export default MenuCalendar;
