import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { UserType, useUserType } from 'src/hooks/useUserType';
import { RootState } from 'src/store';
import styled from 'styled-components';
import { SimpleNavBar } from '../SimpleNavBar';
import { PrintOrderReceiptsOneByOneContainer } from './PrintOrderReceiptsOneByOneContainer';

interface PageRestaurantUpcomingMealOrderReceiptsProps {}
interface ParamType {
  mealId: string;
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 0px;
`;

export const PageRestaurantUpcomingMealOrderReceipts: React.FC<PageRestaurantUpcomingMealOrderReceiptsProps> =
  (props): ReactElement => {
    const { mealId } = useParams<ParamType>();
    const userType = useUserType();
    const currentUser = useSelector((state: RootState) => state.currentUser);

    if (userType !== UserType.Restaurant) {
      return <Redirect to="/" />;
    }

    const restaurantName = currentUser.user.restaurant.name;

    return (
      <div className="page">
        <SimpleNavBar title={restaurantName} />
        <ContentContainer>
          <PrintOrderReceiptsOneByOneContainer mealId={mealId} />
        </ContentContainer>
      </div>
    );
  };
