import axios from "axios";
import { LOGGING, TOKEN_KEY } from "../constants";

const serverPath =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_SERVER
    : process.env.REACT_APP_DEV_SERVER;

// const serverPath = "https://foodie-watch-api-staging.herokuapp.com/api";
LOGGING &&
  console.log("app is running with process.env.NODE_ENV:", process.env);
LOGGING && console.log("app is running with serverPath:", serverPath);

export function apiCall(method, path, data, currentUser, options) {
  const fullPath = serverPath + path;
  LOGGING &&
    console.log("apicall with serverPath", {
      serverPath,
      path,
      fullPath,
      currentUser,
    });
  const headers = {
    "Requester-Id": currentUser?.user?._id || "",
    "Requester-Token": localStorage.getItem(TOKEN_KEY) || "",
  };
  return new Promise((resolve, reject) => {
    axios
      .request({
        url: fullPath,
        method: method.toLowerCase(),
        data,
        headers,
        responseType: options?.responseType ?? "json", // default
      })
      .then((res) => {
        // LOGGING && console.log('api got res:', res);
        return resolve(res.data);
      })
      .catch((err) => {
        LOGGING && console.log("api got err:", err);
        return reject(err.response?.data?.error);
      });
  });
}
