import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import toast, { Toaster } from "react-hot-toast";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  readFutureOrdersByCustomer,
  readPastOrdersByCustomer,
  cancelOrder,
  giveOrderFeedback,
  saveOrderFeedbackComment,
  changePaidOrder,
} from "../../store/actions";
import { ConfirmAlert } from "../shared";
import { LOGGING, OrderList } from "./";

class PageOrderList extends Component {
  constructor() {
    super();
    LOGGING && console.log("PageOrderList constructor");
    this.state = {
      loadingFuture: true,
      loadingPast: true,
      saving: null,
      cancelOrderId: null,
      view: "upcoming",
      editedComments: new Map(),
      showOrderDetails: null,
      isEdittingDetails: false,
      gotoOrderId: -1,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleCancelOrder = this.handleCancelOrder.bind(this);
    this.handleSetView = this.handleSetView.bind(this);
    this.handleUp = this.handleUp.bind(this);
    this.handleDown = this.handleDown.bind(this);

    this.handleShowOrderDetailsFor = this.handleShowOrderDetailsFor.bind(this);
    this.handleHideOrderDetailsFor = this.handleHideOrderDetailsFor.bind(this);

    this.handleEditOrder = this.handleEditOrder.bind(this);
    this.handleEditDishComment = this.handleEditDishComment.bind(this);
    this.handleEditComment = this.handleEditComment.bind(this);
    this.handleSaveOrder = this.handleSaveOrder.bind(this);

    this.gotoOrderRef = React.createRef();
  }

  handleEditDishComment(dishId, e) {
    const comment = e.target.value;
    LOGGING &&
      console.log("handleEditDishComment called with: ", {
        dishId,
        comment,
      });
    const { showOrderDetails } = this.state;
    const { goods } = showOrderDetails;
    const updatedGoods = goods.map((g) =>
      g?.dish?._id === dishId ? { ...g, comment } : { ...g }
    );
    const updatedOrder = {
      ...showOrderDetails,
      goods: updatedGoods,
    };
    this.setState({ showOrderDetails: updatedOrder });
  }

  handleEditOrder(orderId, e) {
    e.preventDefault();
    const { orders } = this.props;
    const showOrderDetails = orders.find((o) => o._id === orderId);
    this.setState({ showOrderDetails, isEdittingDetails: true }, () => {
      window.scrollTo(0, 0);
    });
  }

  handleSaveOrder(e) {
    const { showOrderDetails } = this.state;
    LOGGING &&
      console.log("handleSaveOrder called with order:", showOrderDetails);
    this.setState({ loading: true }, () => {
      this.props
        .changePaidOrder(showOrderDetails)
        .then(() => {
          this.setState({ showOrderDetails: null, loading: false });
        })
        .catch((err) => {
          LOGGING && console.error("Error saving order", err);
          toast.error(err.message);
        });
    });
  }

  handleShowOrderDetailsFor(orderId, e) {
    e.preventDefault();
    const { orders } = this.props;
    const showOrderDetails = orders.find((o) => o._id === orderId);
    this.setState({ showOrderDetails, isEdittingDetails: false });
  }

  handleHideOrderDetailsFor(e) {
    e.preventDefault();
    this.setState({ showOrderDetails: null, isEdittingDetails: false });
  }

  handleUp(orderId, e) {
    console.log("handleUp", orderId, e);
    this.setState(
      { saving: { orderId, isBinary: true, binaryAnswer: true } },
      () => {
        this.props.giveOrderFeedback(orderId, true).then(() => {
          this.props.readOrders().then(() => {
            this.setState({ saving: null });
          });
        });
      }
    );
  }

  handleDown(orderId, e) {
    console.log("handleDown", orderId, e);
    this.setState(
      { saving: { orderId, isBinary: true, binaryAnswer: false } },
      () => {
        this.props.giveOrderFeedback(orderId, false).then(() => {
          this.props.readOrders().then(() => {
            this.setState({ saving: false });
          });
        });
      }
    );
  }

  handleEditComment(orderId, e) {
    console.log("handleEditComment", orderId, e);
    let editedComments = this.state.editedComments;
    editedComments.set(orderId, e.target.value);
    this.setState({ editedComments: editedComments });
  }

  handleSaveComment(orderId, e) {
    console.log("handleSaveComment", orderId, e);
    this.props.saveOrderFeedbackComment(
      orderId,
      this.state.editedComments.get(orderId)
    );
    this.setState({ saving: { orderId, isBinary: false } }, () => {
      this.props.readOrders().then(() => {
        this.setState({ saving: null });
      });
    });
  }

  handleClose() {
    LOGGING && console.log("PageCart handleClose");
    this.props.history.push("/");
  }

  handleCancelOrder(orderId, isFreeOrder, e) {
    e.preventDefault();
    LOGGING && console.log("handleCancelOrder");
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmAlert
            onConfirm={this.handleConfirmCancelOrder.bind(
              this,
              orderId,
              isFreeOrder
            )}
            onClose={onClose}
            actionName="cancel"
          />
        );
      },
    });
  }

  handleConfirmCancelOrder(orderId, isFreeOrder) {
    LOGGING && console.log("cancelling..");
    this.setState({ cancelOrderId: orderId });
    this.props.cancelOrder(orderId, isFreeOrder).then(() => {
      this.setState({ cancelOrderId: null, showOrderDetails: null });
    });
  }

  handleSetView(view, e) {
    e.preventDefault();
    this.setState({ view }, () => {
      window.scrollTo(0, 0);
    });
  }

  componentDidMount() {
    LOGGING &&
      console.log("PageOrderList componentdidmount with:", this.props.location);
    const { orders } = this.props;
    const ordersLoaded = orders && orders.length > 0;
    this.setState(
      { loadingFuture: !ordersLoaded, loadingPast: !ordersLoaded },
      () => {
        // ordersLoaded = true, will load orders while blocking rendering
        // ordersLoaded = false, will load orders while continueing rendering
        this.props.readFutureOrdersByCustomer().then(() => {
          window.scrollTo(0, 0);
          this.setState({ loadingFuture: false }, () => {
            this.props.readPastOrdersByCustomer().then(() => {
              this.setState(
                {
                  loadingPast: false,
                  gotoOrderId: this.props.location?.state?.gotoOrderId,
                },
                () => {
                  const { gotoOrderId } = this.state;
                  if (gotoOrderId && this.gotoOrderRef?.current) {
                    window.scrollTo({
                      top: this.gotoOrderRef.current.offsetTop - 100,
                      left: 0,
                      behavior: "smooth",
                    });
                    setTimeout(() => {
                      this.setState({
                        gotoOrderId: -1,
                      });
                      window.history.replaceState(
                        { ...this.props.location.state, gotoOrderId: -1 },
                        ""
                      );
                    }, 1000);
                  } else {
                    window.scrollTo(0, 0);
                  }
                }
              );
            });
          });
        });
      }
    );
  }

  render() {
    const { orders, currentUser } = this.props;
    const { loadingPast, loadingFuture, view, saving, gotoOrderId } =
      this.state;
    LOGGING &&
      console.log("PageOrders rendering with", {
        props: this.props,
        state: this.state,
      });

    if (!currentUser.isAuthenticated) {
      return <Redirect to="/" />;
    }

    return (
      <div className="page">
        <Toaster />
        <OrderList
          onSetView={this.handleSetView}
          view={view}
          orders={orders}
          gotoOrderId={gotoOrderId}
          gotoOrderRef={this.gotoOrderRef}
          onViewOrderDetails={this.handleShowOrderDetailsFor}
          onBack={this.handleClose}
          loadingPast={loadingPast}
          loadingFuture={loadingFuture}
          onUp={this.handleUp}
          editedComments={this.state.editedComments}
          onDown={this.handleDown}
          onEditComment={this.handleEditComment}
          onSaveComment={this.handleSaveComment}
          saving={saving}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log("PageOrderList got redux state:", state);
  return {
    orders: state.orders,
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  readFutureOrdersByCustomer,
  readPastOrdersByCustomer,
  cancelOrder,
  giveOrderFeedback,
  saveOrderFeedbackComment,
  changePaidOrder,
})(PageOrderList);
