import { faCircle, faStar, faTriangle, faSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GoogleMapReact from 'google-map-react';
import { Close, LOGGING } from '.';
import './StopsMap.scss';
export const RestaurantLegends = () => (
  <div className="restaurant-legends">
    <div className="meal-type past-meal">
      <FontAwesomeIcon icon={faTriangle} />
      <span>past meal</span>
    </div>
    <div className="meal-type same-day-meal">
      <FontAwesomeIcon icon={faSquare} />
      <span>today meal</span>
    </div>
    <div className="meal-type future-meal">
      <FontAwesomeIcon icon={faStar} />
      <span>future meal</span>
    </div>
    <div className="meal-type no-meal">
      <FontAwesomeIcon icon={faCircle} />
      <span>no meal</span>
    </div>
  </div>
);
const RestaurantMarker = ({
  restaurantName,
  restaurantFullName,
  isCenter,
  hover,
  lastMeal,
  isFuture,
  isPast,
  isSameDay,
  isLunch,
  opacity,
}) => {
  if (restaurantFullName.includes('ming tasty')) {
    console.log('restaurantName:', restaurantName);
  }
  return (
    <div
      className={`address-marker restaurant-marker on-map ${hover ? 'hover' : ''} ${
        lastMeal ? 'has-last-meal' : ''
      } ${isFuture ? 'future-meal' : isPast ? 'past-meal' : isSameDay ? 'same-day-meal' : 'no-meal'}
      ${isLunch ? 'lunch' : 'dinner'} 
      ${isCenter ? 'center' : ''} 
    }`}
      style={{ opacity }}
    >
      <FontAwesomeIcon
        icon={isFuture ? faStar : isPast ? faTriangle : isSameDay ? faSquare : faCircle}
      />
      <span className="short-name">{restaurantName}</span>
      <span className="full-name">{restaurantFullName}</span>
      {lastMeal ? <span className="last-meal">{lastMeal}</span> : null}
    </div>
  );
};

const RestaurantsMap = ({ restaurants, center, zoom, onClose, restaurantId }) => (
  // Important! Always set the container height explicitly
  <div className="stops-map restaurants-map">
    <Close onClick={onClose} />
    <GoogleMapReact
      bootstrapURLKeys={{
        key: 'AIzaSyB-0SwH76HPTJGm_mhM1Rg0ACcpYGpAt1Q',
      }}
      // defaultCenter={center}
      defaultZoom={zoom}
      center={[center.lat, center.lng]}
    >
      {restaurants.map((restaurant, index) => {
        if (restaurant.name.includes('ming tasty')) {
          LOGGING && console.log('restaurant found:', restaurant);
        }
        if (!restaurant.map) {
          LOGGING && console.log('restaurant with no map:', restaurant);
        }
        return (
          <RestaurantMarker
            restaurantName={restaurant.name.trim().split(' ')[0]}
            restaurantFullName={restaurant.name}
            lastMeal={restaurant.lastMeal}
            isPast={restaurant.isPast}
            isFuture={restaurant.isFuture}
            isSameDay={restaurant.isSameDay}
            isLunch={restaurant.isLunch}
            isCenter={restaurant._id === restaurantId}
            lat={restaurant.map ? restaurant.map.lat : center.lat}
            lng={restaurant.map ? restaurant.map.lng : center.lng}
            key={index}
            opacity={restaurant.opacity}
          />
        );
      })}
    </GoogleMapReact>
  </div>
);

export default RestaurantsMap;
