import { LOGGING } from "src/constants";
import { SET_DELIVERY_CITY, RESET_DELIVERY_CITY } from "src/store/actionTypes";
import { apiCall } from "src/store/api";
import { setCurrentUser } from "./currentUser";

export const setDeliveryCity = (deliveryCity) => ({
  type: SET_DELIVERY_CITY,
  deliveryCity,
});
export const resetDeliveryCity = () => ({
  type: RESET_DELIVERY_CITY,
  deliveryCity: null,
});

export const nominateDeliveryCity = (cityName) => {
  return async (dispatch, getState) => {
    const user = getState()?.currentUser?.user;
    if (!user) throw "invalid user!";
    const { _id, firstName, lastName } = user;
    const userName = `${firstName} ${lastName}`;
    LOGGING &&
      console.log("nominateDeliveryCity called with: ", {
        _id,
        cityName,
        userName,
      });
    try {
      const result = await apiCall(
        "post",
        `/users/nominateDeliveryZone/${_id}`,
        { cityName, userName },
        getState().currentUser
      );
      LOGGING && console.log("nominateDeliveryCity done with result:", result);
      return;
    } catch (err) {
      LOGGING && console.log("nominateDeliveryCity got err", err);
    }
  };
};

export const updateDeliveryCity = (deliveryCity) => {
  return async (dispatch, getState) => {
    const userId = getState().currentUser.user?._id;
    LOGGING &&
      console.log("updateDeliveryCity called with: ", { userId, deliveryCity });
    try {
      const user = await apiCall(
        "post",
        `/users/deliveryZone/${userId}`,
        { deliveryZone: deliveryCity },
        getState().currentUser
      );
      dispatch(setCurrentUser(user));
      LOGGING && console.log("updateDeliveryCity get updated user:", user);
    } catch (err) {
      LOGGING && console.log("updateDeliveryCity got err", err);
    }
  };
};
