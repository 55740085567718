import { PURGE } from 'redux-persist';
import { LOAD_BOOKMARKS, RESET_BOOKMARKS } from '../actionTypes';

const bookmarks = (state = [], action) => {
  switch (action.type) {
    case LOAD_BOOKMARKS:
      return [...action.bookmarks];
    case RESET_BOOKMARKS:
      return [];
    case PURGE:
      return [];
    default:
      return state;
  }
};

export default bookmarks;
