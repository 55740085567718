import React from "react";
import "./FAQ.scss";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { customerServicePhoneNumber } from "src/constants";
import { Back, SupportedSanJoseZipCodes } from "./";

export const ServiceAreas = ({ onClose }) => (
  <div className="list faq">
    <div className="list-title">
      <Back onClick={onClose} />
      <span>Service areas</span>
    </div>
    <div className="faq-section">
      <div className="answer">
        <span>
          We are currently delivering to addresses the following cities:
        </span>
        <ul>
          <li>
            <FontAwesomeIcon icon={faCheck} />
            Mountain View
          </li>
          <li>
            <FontAwesomeIcon icon={faCheck} />
            Los Altos
          </li>
          <li>
            <FontAwesomeIcon icon={faCheck} />
            Los Altos Hills
          </li>
          <li>
            <FontAwesomeIcon icon={faCheck} />
            Stanford
          </li>
          <li>
            <FontAwesomeIcon icon={faCheck} />
            Palo Alto
          </li>
          <li>
            <FontAwesomeIcon icon={faCheck} />
            East Palo Alto
          </li>
          <li>
            <FontAwesomeIcon icon={faCheck} />
            Menlo Park
          </li>
          <li>
            <FontAwesomeIcon icon={faCheck} />
            Sunnyvale
          </li>
          <li>
            <FontAwesomeIcon icon={faCheck} />
            Cupertino
          </li>
          <li>
            <FontAwesomeIcon icon={faCheck} />
            Santa Clara
          </li>
          <li>
            <FontAwesomeIcon icon={faCheck} />
            Saratoga
          </li>
          <li>
            <FontAwesomeIcon icon={faCheck} />
            San Jose ({`${SupportedSanJoseZipCodes.join(", ")}`})
          </li>
          <li>
            <FontAwesomeIcon icon={faCheck} />
            Campbell
          </li>
          <li>
            <FontAwesomeIcon icon={faCheck} />
            Milpitas
          </li>
          <li>
            <FontAwesomeIcon icon={faCheck} />
            Monte Sereno
          </li>
          <li>
            <FontAwesomeIcon icon={faCheck} />
            Atherton
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export const About = ({ onClose }) => (
  <div className="list faq about">
    <div className="list-title">
      <Back onClick={onClose} />
      <span>About</span>
    </div>
    <div className="faq-section">
      <div className="fancy-header"></div>
      <div className="answer">
        {/* <div className="capital-letter">T</div> */}
        <p>
          This service was founded by a lazy foodie, who just wanted to sit on
          her couch and have all the tasty food in the world delivered to her
          doorstep. She was tired of spending hours on Yelp and paying enormous
          fees from DoorDash.
        </p>
        <p>
          So here we are, a virtual food truck that features the most delicious
          restaurants in the Bay Area, nominated by enthusiastic foodies like
          you. We deliver food more efficiently than the taxi-style DoorDash and
          UberEats, because our customers live in the same neighborhoods, share
          similiar (and diversified) tastes, and have flexible schedules.
        </p>
        <p>
          As we heal from the COVID-19 pandemic, we stay close and connected by
          our love for food. We welcome everyone to join our fast-growing
          community and offer tips on the local gems in your town.
        </p>
        <div className="signature">
          <span>Yours sincerely,</span>
          <span>Team Foodie Earth</span>
        </div>
      </div>
    </div>
  </div>
);

const FAQ = ({ onClose }) => (
  <div className="list faq">
    {/*<div className="fancy-header"></div>*/}
    <div className="list-title">
      <Back onClick={onClose} />
      <span>FAQ</span>
    </div>
    <div className="faq-section">
      <div className="question">
        <span>How does Foodie Earth work?</span>
      </div>
      <div className="answer">
        <span>
          Every day for every city, we list only 3-5 pre-selected restaurants
          for ordering and deliveries, in three windows: 11:30am ~ 12:30pm,
          4:30pm ~ 5:30pm, and 6:00pm ~ 7:00pm. Our menu changes every week and
          features diverse cuisines from local restaurants. By sharing menus and
          delivery routes with your neighbors, you not only save on food
          delivery fees but also help reduce your carbon footprint!
        </span>
      </div>
    </div>
    <div className="faq-section">
      <div className="question">
        <span>Which areas do you deliver to?</span>
      </div>
      <div className="answer">
        <span>
          We currently serve Cupertino, Mountain View, Los Altos, Palo Alto,
          Menlo Park, Santa Clara, Milpitas, Sunnyvale, Saratoga, Los Gatos,
          Monte Sereno, Atherton, Los Altos Hills, San Jose (
          {`${SupportedSanJoseZipCodes.join(", ")}`}) and Campbell. As a
          fast-growing start-up, we're working on expansion and look forward to
          adding new delivery areas soon!
        </span>
      </div>
    </div>
    <div className="faq-section">
      <div className="question">
        <span>Will my food get cold with your delivery model?</span>
      </div>
      <div className="answer">
        <span>
          By optimizing our delivery routes, we minimize delivery times to under
          an hour so your food is delivered fresh and warm.
        </span>
      </div>
    </div>
    <div className="faq-section">
      <div className="question">
        <span>How many restaurants are in your network?</span>
      </div>
      <div className="answer">
        <span>We feature 500 local restaurants and counting! </span>
      </div>
    </div>
    <div className="faq-section">
      <div className="question">
        <span>what is the menu publish timeline?</span>
      </div>
      <div className="answer">
        <span>Menus are published on a rolling basis with a 7-day window.</span>
      </div>
    </div>
    <div className="faq-section">
      <div className="question">
        <span>How do I pay?</span>
      </div>
      <div className="answer">
        <span>
          You can pay by credit card or via PayPal. When you cancel an order,
          your card/PayPal is refunded automatically.
        </span>
      </div>
    </div>
    <div className="faq-section">
      <div className="question">
        <span>What is the deadline to order?</span>
      </div>
      <div className="answer">
        <span>
          You can order any time a given meal is available, which is usually a
          week in advance of the delivery date. If you are ordering the day of
          delivery, you need to order before 10:30am for lunch orders, before
          3:30pm for early dinner orders, and before 4:30pm for dinner orders.
        </span>
      </div>
    </div>
    <div className="faq-section">
      <div className="question">
        <span>When will I receive my order?</span>
      </div>
      <div className="answer">
        <span>
          Our expected delivery window is 11:30am ~ 12:30pm for lunch, 4:30pm ~
          5:30pm for early dinner, and 6:00pm ~ 7:00pm for dinner, but we can
          run early or late occasionally due to unexpected circumstances.
        </span>
        <span>
          Prioritizing orders for a certain time is currently not a feature we
          support.
        </span>
      </div>
    </div>
    <div className="faq-section">
      <div className="question">
        <span>How can I give feedback to help Foodie Earth improve? </span>
      </div>
      <div className="answer">
        <span>
          As we grow, we're always looking for new ways to improve. You can send
          feedback by emailing info@foodie.earth or texting{" "}
          {customerServicePhoneNumber}.
        </span>
      </div>
    </div>
    <div className="faq-section">
      <div className="question">
        <span>How and when can I cancel a meal?</span>
      </div>
      <div className="answer">
        <span>
          User Menu <FontAwesomeIcon icon={faChevronRight} /> My Orders{" "}
          <FontAwesomeIcon icon={faChevronRight} /> Upcoming{" "}
          <FontAwesomeIcon icon={faChevronRight} />
          Cancel.
        </span>
        <span>
          Usually, you can cancel your order before its ordering cutoff time. If
          your cancellation is processed, you will receive a full refund.
          However, we sometimes need to disable cancellations if the restaurant
          has already started working on your meal. After the kitchen starts to
          work on your meal, they reserve the right to cancel and refund items
          that become unavailable, in which case the items that are fulfilled by
          them cannot be refunded.
        </span>
      </div>
    </div>
    <div className="faq-section">
      <div className="question">
        <span>Can I get a refund for wrong/spilled/missing items?</span>
      </div>
      <div className="answer">
        <span>
          Yes, we usually refund wrong/spilled/missing items. Note that to get a
          refund, you need to (1) send a picture as proof (2) file the request
          within one hour of delivery.
        </span>
      </div>
    </div>
    <div className="faq-section">
      <div className="question">
        <span>What do I do if I am unsatisfied with my meal or delivery?</span>
      </div>
      <div className="answer">
        <span>
          We take pride in our work and want to make sure you get the best
          delivery experience possible. Please text our customer service at{" "}
          {customerServicePhoneNumber} describing the issue, and a
          representative will be with you shortly to make things right.
        </span>
      </div>
    </div>
    <div className="faq-section">
      <div className="question">
        <span>
          What is your policy when a restaurant/driver makes a mistake?
        </span>
      </div>
      <div className="answer">
        <span>
          When something goes wrong, we prioritize making things right with the
          customer in the form of a refund (equal to the value of the part(s) of
          the order with issues, subject to discretion based on the severity of
          said issues). If any problems arise by fault of our drivers, we will
          reach out to affected customers for resolution, then work with those
          drivers to prevent similar mistakes moving forward. If any issues
          arise on the part of the restaurant, we will refund the customer per
          the above policy, report the issues to the restaurant, and re-evaluate
          that partnership with regard to quality control.
        </span>
      </div>
    </div>
    <div className="faq-section">
      <div className="question">
        <span>
          How do you profit and remain successful as a business while offering
          free delivery?
        </span>
      </div>
      <div className="answer">
        <span>
          By optimizing delivery routes, ordering in batches, and cooperating
          with local restaurants, our per order delivery costs are very
          low—especially compared to other delivery services. We then couple
          those low operating costs with a small commission from restaurants and
          high order volume. Our priority and focus is fresh and affordable
          delivery for fellow foodies while supporting local restaurants!
        </span>
      </div>
    </div>
    <div className="faq-section">
      <div className="question">
        <span>Where do my tips go?</span>
      </div>
      <div className="answer">
        <span>
          Tips are pooled and then distributed evenly across working drivers to
          promote a positive non-competitive community. We also contribute to
          the tip pool to guarantee a minimum $35/hr for the initial hour of
          work each meal.
        </span>
      </div>
    </div>
    <div className="faq-section">
      <div className="question">
        <span>How does your referral program work?</span>
      </div>
      <div className="answer">
        <span>
          Share your code on the home page with up to twenty of your friends!
          Each time your friend uses your code on their first order, you each
          gain $10 in Foodie credit!
        </span>
      </div>
    </div>
  </div>
);

export default FAQ;
