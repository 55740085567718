import { useCallback } from 'react';
import { readDrivers } from 'src/store/actions';
import { getAdminDeliveryV2MealsForDay } from 'src/store/actions/adminDeliveryV2';
import { LOAD_ADMIN_DELIVERY_V2_DRIVERS } from 'src/store/actionTypes';
import { PopUp } from '.';
import { AskDriverList } from './AskDriverList';
import { AwaitServerResponseContainer } from './AwaitServerResponseContainer';

export const AskDriverListContainer = ({ isShown, mealType, hide }) => {
  const fetchData = useCallback(async (dispatch, getState) => {
    // Get all drivers
    const drivers = await readDrivers()(dispatch, getState);
    dispatch({
      type: LOAD_ADMIN_DELIVERY_V2_DRIVERS,
      drivers,
    });

    // Get meals for the day
    await getAdminDeliveryV2MealsForDay()(dispatch, getState);
  }, []);

  return (
    <AwaitServerResponseContainer awaitable={fetchData}>
      <PopUp
        isPoppedUp={isShown}
        componentToDisplay={<AskDriverList mealType={mealType} />}
        hidePopUp={hide}
      />
    </AwaitServerResponseContainer>
  );
};
