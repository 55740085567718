import moment from 'moment-timezone';
import { SET_WEEKLY_MENU, RESET_WEEKLY_MENU } from '../actionTypes';

const weeklyMenu = (state = { payLoad: null, loadedAt: moment() }, action) => {
  switch (action.type) {
    case SET_WEEKLY_MENU:
      return { payLoad: action.weeklyMenu, loadedAt: moment() };
    case RESET_WEEKLY_MENU:
      return { payLoad: [], loadedAt: moment() };
    default:
      return state;
  }
};

export default weeklyMenu;
