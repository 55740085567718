import "./Select.scss";
import {
  faCheck,
  faPlus,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BubbleUpperRight, LOGGING, PopUp } from "./";
import ExpandToggle from "./ExpandToggle";

const Select = ({
  selected,
  onSelect,
  options,
  optionWords,
  showOptions,
  onToggleShowOptions,
  onHideOptions,
  className,
  color,
  optionIcons,
}) => {
  const handleText =
    selected !== null && optionWords[selected]
      ? optionWords[selected].name || optionWords[selected]
      : "please select";
  LOGGING &&
    console.log("Select rendering with:", {
      selected,
      options,
      optionWords,
      showOptions,
      optionIcons,
    });
  return (
    <div className={`select-wrapper ${className}`}>
      <ExpandToggle
        onToggle={onToggleShowOptions}
        showDetails={showOptions}
        toggleOnText={handleText}
        toggleOffText={handleText}
        color={color}
      />
      {showOptions ? (
        <BubbleUpperRight
          className="select-option-list"
          onHide={onHideOptions}
          content={options.map((o, index) => (
            <button
              className={`select-option ${
                selected === o ? "selected-option" : ""
              }`}
              key={index}
              type="button"
              onClick={onSelect.bind(this, o)}
            >
              {optionIcons && optionIcons[o] ? (
                <FontAwesomeIcon icon={optionIcons[o]} />
              ) : null}
              {optionWords[o].name || optionWords[o]}
              {selected === o ? <FontAwesomeIcon icon={faCheck} /> : null}
            </button>
          ))}
        />
      ) : null}
    </div>
  );
};

export const SelectChip = ({ onToggle, isSelected, text }) => (
  <button
    type="button"
    onClick={onToggle}
    className={`select-chip ${isSelected ? "selected-chip" : ""}`}
  >
    <div className="select-chip-name">
      <span>{text}</span>
    </div>
    <div className="select-chip-status">
      <FontAwesomeIcon icon={isSelected ? faCheck : faPlus} />
    </div>
  </button>
);

export const MustSelect = ({ title, subTitle, onSelect, options }) => {
  const height = options.length * 50 + 110;
  LOGGING &&
    console.log("MustSelect rendering with:", { title, subTitle, options });
  return (
    <div className="must-select" /*style={{ height: `${height}px` }}*/>
      <div className="header">
        <span className="title">{title}</span>
        <span className="sub-title">{subTitle}</span>
      </div>
      {options.map((o, i) => (
        <button key={i} onClick={onSelect.bind(this, i)}>
          {o}
        </button>
      ))}
    </div>
  );
};
export const Change = ({
  options,
  onChange,
  selected,
  showOptions,
  onToggleShowOptions,
  className,
  icon,
}) => {
  LOGGING && console.log("Change rendering with icon:", icon);
  return (
    <div className={`change${className ? " " + className : ""}`}>
      <button className="selected" onClick={onToggleShowOptions}>
        <span>{selected}</span>
        {icon ? (
          <FontAwesomeIcon icon={icon} />
        ) : (
          <FontAwesomeIcon icon={showOptions ? faChevronUp : faChevronDown} />
        )}
      </button>
      <PopUp
        isPoppedUp={showOptions}
        componentToDisplay={
          <ul className="option-list">
            {options.map((option, index) => (
              <li key={index} className={option === selected ? "selected" : ""}>
                <button onClick={onChange.bind(this, index)}>
                  <span>{option}</span>
                  {option === selected ? (
                    <FontAwesomeIcon icon={faCheck} />
                  ) : null}
                </button>
              </li>
            ))}
          </ul>
        }
        hidePopUp={onToggleShowOptions}
        backgroundColor="transparent"
      />
    </div>
  );
};

export default Select;
