import React, { Component } from "react";
import { connect } from "react-redux";
import { LOGGING, NavSingleMeal, getItemNumberInCart } from ".";
import {
  signOut,
  emptyCart,
  getMarketingEmailTemplate,
} from "../../store/actions";

class NavbarSingleMeal extends Component {
  constructor(props) {
    super();
    this.state = {
      marketingEmailTemplate: "",
    };

    this.handleSignOut = this.handleSignOut.bind(this);
    const { currentUser } = props;
    if (
      currentUser &&
      currentUser.isAuthenticated &&
      currentUser.user.isAdmin
    ) {
      props.getMarketingEmailTemplate().then((marketingEmailTemplate) => {
        this.setState({ marketingEmailTemplate: marketingEmailTemplate });
      });
    }
  }
  handleSignOut(e) {
    e.preventDefault();
    this.props.signOut();
    this.setState({ showUserMenu: false });
  }

  render() {
    const { marketingEmailTemplate } = this.state;
    const {
      cart,
      currentUser,
      noShadow,
      // driverView,
      // numberOfStopsLeft,
      // onToggleShowViewSelections,
      showViewSelections,
      // selectedOption,
      // viewableOptions,
      // onSelectViewableOption,
      // viewByWeek,
      // onSelectViewByWeek,
      cartIsFull,
      onViewByWeekEmptyCart,
      dishesLoaded,
      deliveryZoneByCity,
      onChangeDeliveryCity,
      showDeliveryCityOptions,
      onToggleShowDeliveryCityOptions,
      // onSelectViewMode,
      showUserMenu,
      onToggleShowUserMenu,
    } = this.props;
    // LOGGING &&
    //   console.log('NavbarSingleMeal rendering with:', { showViewSelections, deliveryCity });
    return (
      <NavSingleMeal
        showUserMenu={showUserMenu}
        onSignOut={this.handleSignOut}
        currentUser={currentUser}
        numberOfItems={getItemNumberInCart(cart)}
        cart={cart}
        noShadow={noShadow}
        marketingEmailTemplate={marketingEmailTemplate}
        cartIsFull={cartIsFull}
        onViewByWeekEmptyCart={onViewByWeekEmptyCart}
        dishesLoaded={dishesLoaded}
        deliveryZoneByCity={deliveryZoneByCity}
        onChangeDeliveryCity={onChangeDeliveryCity}
        selectedDeliveryCity={currentUser.user?.deliveryZone?.name}
        onToggleShowDeliveryCityOptions={onToggleShowDeliveryCityOptions}
        showDeliveryCityOptions={showDeliveryCityOptions}
        onToggleUserMenu={onToggleShowUserMenu}
      />
    );
  }
}

function mapStateToProps(state) {
  // LOGGING && console.log('Nav got redux state:', state);
  return {
    cart: state.cart.payLoad || {},
    orders: state.orders,
    currentUser: state.currentUser,
  };
}

export default connect(
  mapStateToProps,
  { signOut, emptyCart, getMarketingEmailTemplate },
  null,
  {
    forwardRef: true,
  }
)(NavbarSingleMeal);
