import { PaypalButton } from "src/screens/tasting_menu/components";
import { Flex } from "../Layout";

export type PayWithPaypalProps = {
  amount?: number;
  isSubscribe?: boolean;
  noTrialPeriod?: boolean;
  onPaypalPaid: (paypalCaptureId: string) => void;
};

export const PayWithPaypal = (props: PayWithPaypalProps) => {
  const { amount, isSubscribe, noTrialPeriod, onPaypalPaid } = props;

  return (
    <Flex justify="center">
      <PaypalButton
        amount={amount}
        isSubscribe={isSubscribe}
        noTrialPeriod={noTrialPeriod}
        onAuthorize={onPaypalPaid}
      />
    </Flex>
  );
};
